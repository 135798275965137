import InboxEmailDto from 'emails/types/InboxEmailDto';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type ColumnDisplayNamesByFieldName = {
  [P in keyof InboxEmailDto]?: string;
};

export default function useColumnDisplayNamesByFieldName() {
  const { t } = useTranslation('emails');
  return useMemo<ColumnDisplayNamesByFieldName>(() => ({
    subject: t('use-column-display-names-by-field-name_subject', 'Subject'),
    creationDate: t('use-column-display-names-by-field-name_creation-date', 'Creation Date'),
    from: t('use-column-display-names-by-field-name_from', 'From'),
    to: t('use-column-display-names-by-field-name_to', 'To'),
    linkedIssueIds: t('use-column-display-names-by-field-name_linked-issue-ids', 'Linked Issues'),
    attachments: t('use-column-display-names-by-field-name_attachments', 'Attachments'),
    tagIds: t('use-column-display-names-by-field-name_tag-ids', 'Tags'),
    emailBody: t('use-column-display-names-by-field-name_email-body', 'Email Body'),
    cc: t('use-column-display-names-by-field-name_cc', 'CC'),
    editDate: t('use-column-display-names-by-field-name_edit-date', 'Edit Date'),
  }), [t]);
}
