import { useCallback } from 'react';
import useApiConfig from 'api/hooks/useApiConfig';
import useVisoplanApiContext from 'api/hooks/useVisoplanApiContext';
import ApiEndpoint from 'api/types/ApiEndpoint';
import UploadingEmailFileHandle from 'emails/types/UploadingEmailFileHandle';
import useCurrentProjectId from 'projects/hooks/useCurrentProjectId';
import EmailsUploadPayload from 'emails/types/EmailsUploadPayload';

export default function useEmailUploadHandler() {
  const { apiUrl } = useApiConfig();
  const { authProjectToken, authUserToken } = useVisoplanApiContext();
  const projectId = useCurrentProjectId();

  const handleUploadEmails = useCallback((payload: EmailsUploadPayload, onProgress?: (uploadHandle: UploadingEmailFileHandle) => void, onFinish?: (uploadHandle: UploadingEmailFileHandle) => void, onError?: (uploadHandle: UploadingEmailFileHandle) => void) => {
    if (!authProjectToken || !authUserToken || !projectId) return [];
    const { files } = payload;
    return Array.from(files).map((file) => {
      const data = new FormData();
      data.append('file', file, file.name);

      const xhr = new XMLHttpRequest();
      const url = `${apiUrl}/${ApiEndpoint.InboxEmail}/upload`;
      xhr.open('POST', url);
      xhr.setRequestHeader('Authorization', `Bearer ${authUserToken.bearer}`);
      xhr.setRequestHeader('X-Project-Authorization', `Bearer ${authProjectToken.bearer}`);
      xhr.setRequestHeader('Visoplan-Client-Id', 'Visoplan Webclient');
      xhr.setRequestHeader('Visoplan-Webclient-Request-Source', 'Current');

      const uploadingFileHandle: UploadingEmailFileHandle = {
        fileName: file.name,
        total: file.size,
        loaded: 0,
        request: xhr,
        isCompleted: false,
        isFailed: false,
        projectId,
      };
      xhr.upload.onprogress = (event) => {
        uploadingFileHandle.loaded = event.loaded;
        if (onProgress) onProgress(uploadingFileHandle);
      };
      xhr.upload.onerror = () => {
        uploadingFileHandle.isFailed = true;
        if (onError) onError(uploadingFileHandle);
      };
      xhr.onreadystatechange = () => {
        if (xhr.readyState === xhr.DONE) {
          uploadingFileHandle.isCompleted = true;
          if (onProgress) onProgress(uploadingFileHandle);
          if (onFinish) onFinish(uploadingFileHandle);
        }
      };
      xhr.send(data);

      return uploadingFileHandle;
    });
  }, [apiUrl, authProjectToken, authUserToken, projectId]);

  return handleUploadEmails;
}
