import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, useTheme } from '@mui/material';
import { mdiCheck, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import ISxProps from 'common/types/ISxProps';
import useModelsInteractionContext from 'models/hooks/useModelsInteractionContext';
import LinkedComponentListItem, { ComponentLinkAction } from 'issues/components/LinkedComponentListItem';
import useViewer3dContext from 'models/hooks/useViewer3dContext';

export default function StagedLinkedComponentsPanel({
  sx,
}: ISxProps) {
  const { t } = useTranslation('models');
  const theme = useTheme();
  const { selectedGlobalIds, getNode } = useViewer3dContext();
  const { setInteractionMode, linkedComponentItems, setLinkedComponentItems } = useModelsInteractionContext();
  const onClickClose = useCallback(() => {
    setInteractionMode(undefined);
  }, [setInteractionMode]);
  const onClickRemoveLinkedComponentItem = useCallback((globalId: string) => {
    setLinkedComponentItems(linkedComponentItems.filter((item) => item.globalId !== globalId));
  }, [setLinkedComponentItems, linkedComponentItems]);
  const onClickAddSelectedComponents = useCallback(() => {
    const linkedComponentItemsByGlobalId = new Map(linkedComponentItems.map((item) => [item.globalId, item]));
    selectedGlobalIds.forEach((globalId) => {
      const node = getNode(globalId);
      const alreadyLinkedItem = linkedComponentItemsByGlobalId.get(globalId);
      if (node && !alreadyLinkedItem?.nodeLoaded) {
        linkedComponentItemsByGlobalId.set(globalId, {
          globalId,
          nodeLoaded: true,
          modelFileId: node.modelFileId,
          elementName: node.elementName ?? '',
        });
      } else if (!node && !alreadyLinkedItem) {
        linkedComponentItemsByGlobalId.set(globalId, {
          globalId,
          nodeLoaded: false,
          elementName: undefined,
        });
      }
    });
    setLinkedComponentItems(Array.from(linkedComponentItemsByGlobalId.values()));
  }, [getNode, linkedComponentItems, selectedGlobalIds, setLinkedComponentItems]);
  return (
    <Box id="StagedLinkedComponentsPanel" sx={{ p: 2, display: 'flex', overflow: 'hidden', flexDirection: 'column', gap: 1, backgroundColor: theme.palette.primary.main, ...sx }}>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          p: 1,
          gap: 1,
          backgroundColor: theme.palette.background.default,
          borderRadius: '8px',
          boxShadow: 'inset 0px 0px 8px -4px rgba(0,0,0,0.4)',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onClickAddSelectedComponents}
          sx={{ pl: 1.5, gap: 1 }}
          disabled={!selectedGlobalIds?.length}
        >
          <Icon path={mdiPlus} size={1} />
          {t('stage-linked-components-panel_add-selected-components-button-label', 'Add selected components')}
        </Button>
        <Box sx={{ overflow: 'auto', flexGrow: 1 }}>
          <Box sx={{ gap: 2, flexWrap: 'wrap', display: 'flex' }}>
            {linkedComponentItems.map((linkedComponentItem) => (
              <LinkedComponentListItem
                key={linkedComponentItem.globalId}
                globalId={linkedComponentItem.globalId}
                elementName={linkedComponentItem.elementName}
                onClickRemove={onClickRemoveLinkedComponentItem}
                sx={{ flexShrink: 0, width: '100%', maxWidth: 400 }}
                nodeLoaded
                pendingAction={ComponentLinkAction.LeavePersisted}
              />
            ))}
          </Box>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={onClickClose}
          sx={{ pl: 1.5, gap: 1 }}
        >
          <Icon path={mdiCheck} size={1} />
          {t('viewpoint-management-dockview-panel_close-button-label', 'Finish')}
        </Button>
      </Box>
    </Box>
  );
}
