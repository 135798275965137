const enum IssuePrintViewColumns {
  IssueNumber = 'issue-number',
  Title = 'title',
  Status = 'status',
  Type = 'type',
  Priority = 'priority',
  Tags = 'tags',
  Assignee = 'assignee',
  Reviewer = 'reviewer',
  Creator = 'creator',
  Created = 'created',
  Updated = 'updated',
  Due = 'due',
  Start = 'start',
  Disciplines = 'disciplines',
  Buildings = 'buildings',
  Floors = 'floors',
  ThumbnailImage = 'thumbnail-image',
  Description = 'description',
  Images = 'images',
  Locations = 'locations',
  Viewpoints = 'viewpoints',
  Emails = 'emails',
  Documents = 'documents',
  Comments = 'comments',
}

export default IssuePrintViewColumns;
