import React, { useCallback, useMemo, useRef } from 'react';
import { Box } from '@mui/material';
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, ColGroupDef, GetRowIdParams, IRowNode } from '@ag-grid-community/core';
import { useTranslation } from 'react-i18next';
import CollaboratorsDataGridRow from 'collaborators/types/CollaboratorsDataGridRow';
import CheckboxCell from 'collaborators/components/cell-renderers/CheckboxCell';
import CheckboxHeaderCell from 'collaborators/components/cell-renderers/CheckboxHeaderCell';
import EmailCell from 'collaborators/components/cell-renderers/EmailCell';
import NameCell from 'collaborators/components/cell-renderers/NameCell';
import GroupCell from 'collaborators/components/cell-renderers/GroupCell';
import RoleCell from 'collaborators/components/cell-renderers/RoleCell';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import useCollaboratorsSelectionContext from 'collaborators/hooks/useCollaboratorSelectionContext';
import useCollaboratorGroupsQuery from 'collaborators-groups/hooks/useCollaboratorGroupsQuery';
import RemoveCell from 'collaborators/components/cell-renderers/RemoveCell';
import useCollaboratorsFilterContext from 'collaborators/hooks/useCollaboratorFilterContext';
import AG_GRID_LOCALE_DE from 'setup/agGrid.locale.de';
import AG_GRID_LOCALE_EN from 'setup/agGrid.locale.en';
import useCollaboratorsOdataQuery from 'collaborators/hooks/useCollaboratorsOdataQuery';
import useRolesOdataQuery from 'collaborators/hooks/useRolesOdataQuery';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';

export default function CollaboratorsDataGrid() {
  const { t } = useTranslation('collaborators');
  const gridRef = useRef<AgGridReact<CollaboratorsDataGridRow>>(null);
  const { data: currentProject } = useCurrentProjectQuery();
  const { data: userGroups } = useCollaboratorGroupsQuery();
  const { data: collaborators } = useCollaboratorsOdataQuery({ filter: { isDeleted: false } });
  const collaboratorGroupSetsByCollaboratorId = useMemo(() => (collaborators ? new Map<string, Set<string>>(collaborators.map((c) => [c.id, new Set<string>(c.collaboratorGroupIds)])) : undefined), [collaborators]);
  const { data: roles } = useRolesOdataQuery({});
  const rowData = useMemo<CollaboratorsDataGridRow[] | undefined>(() => {
    if (!collaborators || !roles || !currentProject) return undefined;
    const roleDefinitionsById = new Map(roles.map((r) => [r.id, r]));
    return collaborators.map((collaborator) => ({
      ...collaborator,
      userGroups: userGroups?.filter((g) => collaboratorGroupSetsByCollaboratorId?.get(collaborator.id)?.has(g.id)),
      role: roleDefinitionsById.get(collaborator.roleId),
    }));
  }, [collaboratorGroupSetsByCollaboratorId, collaborators, currentProject, roles, userGroups]);
  const defaultColDef = useMemo<ColDef<CollaboratorsDataGridRow>>(() => ({
    resizable: true,
    lockPosition: true,
    filterParams: {
      buttons: ['reset'],
    },
  }), []);
  const { setSelectedCollaboratorIds } = useCollaboratorsSelectionContext();
  const { quickFilterString, setFilteredCollaboratorIds } = useCollaboratorsFilterContext();

  const columnDefs = useMemo<(ColDef<CollaboratorsDataGridRow> | ColGroupDef<CollaboratorsDataGridRow>)[]>(() => [
    {
      resizable: false,
      sortable: false,
      width: 54,
      minWidth: 24,
      headerClass: 'no-hover no-border no-padding',
      cellClass: 'no-separator',
      cellStyle: { paddingLeft: 0, paddingRight: 0 },
      cellRenderer: CheckboxCell,
      headerComponent: CheckboxHeaderCell,
    },
    {
      colId: 'name',
      headerName: t('collaborators-data-grid_name-column-header', 'Name'),
      cellRenderer: NameCell,
      filter: 'agTextColumnFilter',
      sortable: true,
      valueGetter: ({ data }) => (data ? `${data.firstName} ${data.lastName}` : undefined),
      initialWidth: 240,
    },
    {
      colId: 'company',
      headerName: t('collaborators-data-grid_company-column-header', 'Company'),
      valueGetter: ({ data }) => data?.company.name,
      filter: 'agSetColumnFilter',
      sortable: true,
    },
    {
      colId: 'address',
      headerName: t('collaborators-data-grid_address-column-header', 'Address'),
      valueGetter: ({ data }) => {
        if (!data) return undefined;
        if (data.company.city && data.company.zipcode && data.company.street) return `${data.company.street}, ${data.company.zipcode} ${data.company.city}`;
        return [data.company.street, data.company.zipcode, data.company.city].filter(Boolean).join(' ') || undefined;
      },
      filter: 'agSetColumnFilter',
      sortable: true,
    },
    {
      colId: 'email',
      headerName: t('collaborators-data-grid_email-column-header', 'E-Mail'),
      field: 'email',
      cellRenderer: EmailCell,
      filter: 'agTextColumnFilter',
      sortable: true,
    },
    {
      colId: 'phone',
      headerName: t('collaborators-data-grid_phone-column-header', 'Telephone'),
      field: 'phone',
      filter: 'agTextColumnFilter',
      sortable: true,
    },
    {
      colId: 'group',
      headerName: t('collaborators-data-grid_group-column-header', 'User Groups'),
      cellRenderer: GroupCell,
      filter: 'agSetColumnFilter',
      sortable: true,
      width: 360,
      valueGetter: ({ data }) => data?.userGroups?.map((g) => g.name),
    },
    {
      colId: 'role',
      headerName: t('collaborators-data-grid_role-column-header', 'Role'),
      cellRenderer: RoleCell,
      filter: 'agSetColumnFilter',
      sortable: true,
      valueGetter: ({ data }) => data?.role?.name,
    },
    {
      resizable: false,
      flex: 1,
      minWidth: 200,
      headerClass: 'no-hover',
      cellRenderer: RemoveCell,
    },
  ], [t]);

  const getRowId = useCallback(({ data }: GetRowIdParams<CollaboratorsDataGridRow>) => data.id, []);

  const onModelUpdated = useCallback(() => {
    // deselect rows that become hidden, e.g. by a filter
    const filteredCollaboratorIds = new Set<string>();
    gridRef.current?.api.forEachNodeAfterFilterAndSort((rowNode: IRowNode<CollaboratorsDataGridRow>) => {
      if (rowNode.data?.id) filteredCollaboratorIds.add(rowNode.data.id);
    });
    setFilteredCollaboratorIds(Array.from(filteredCollaboratorIds));
    setSelectedCollaboratorIds((prev) => prev.filter((id) => filteredCollaboratorIds.has(id)));
  }, [setFilteredCollaboratorIds, setSelectedCollaboratorIds]);

  const { i18n } = useTranslation();
  const localeText = useMemo<{ [key: string]: string; }>(() => (i18n.language.toLocaleLowerCase().startsWith('de') ? AG_GRID_LOCALE_DE : AG_GRID_LOCALE_EN), [i18n.language]);

  return (
    <Box id="CollaboratorsDataGrid" sx={{ height: '100%' }} className="ag-theme-material ag-theme-visoplan table-view-data-grid">
      <AgGridReact<CollaboratorsDataGridRow>
        localeText={localeText}
        rowHeight={54}
        rowData={rowData}
        getRowId={getRowId}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        ref={gridRef}
        maxConcurrentDatasourceRequests={1}
        rowSelection="multiple"
        suppressCellFocus
        suppressRowHoverHighlight
        suppressRowClickSelection
        enableCellTextSelection
        onModelUpdated={onModelUpdated}
        modules={[SetFilterModule]}
        quickFilterText={quickFilterString}
      />
    </Box>
  );
}
