import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Box } from '@mui/material';
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, GetRowIdParams, IRowNode, RowClickedEvent } from '@ag-grid-community/core';
import { ViewportRowModelModule } from '@ag-grid-enterprise/viewport-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import FilterTreeCellRenderer from 'documents-filter/components/FilterTreeCellRenderer';
import QueryFilterDto from 'query-filters/types/QueryFilterDto';
import useQueryFilterFilterContext from 'documents-filter/hooks/useQueryFilterFilterContext';
import QueryFilterSortMode from 'query-filters/types/QueryFilterSortMode';
import useDocumentQueryFiltersOdataQuery from 'query-filters/hooks/useDocumentQueryFiltersOdataQuery';
import QueryFilterKind from 'query-filters/types/QueryFilterKind';
import useDocumentsViewNavigationContext from 'documents/hooks/useDocumentsViewNavigationContext';

export default function FilterDataGrid() {
  const gridRef = useRef<AgGridReact<QueryFilterDto<QueryFilterKind.Document>>>(null);
  const { data: filters } = useDocumentQueryFiltersOdataQuery({});
  const { documentScope, setDocumentScope } = useDocumentsViewNavigationContext();
  const {
    isFilterActive, filterPredicate, sortMode,
  } = useQueryFilterFilterContext();
  const documentFilters = useMemo(() => {
    const entities = filters?.filter((f) => !!f.uiFilterSettings);
    if (isFilterActive && entities) {
      return entities.filter(filterPredicate);
    }
    return entities;
  }, [filterPredicate, filters, isFilterActive]);
  const updateSelection = useCallback(() => {
    if (!gridRef.current?.api) return;
    const { api } = gridRef.current;
    if (documentScope.key === DocumentScopeKey.Filter && !!documentScope.id) {
      api.forEachNode((node) => {
        node.setSelected(node.id === documentScope.id);
      });
    } else {
      api.deselectAll();
    }
  }, [documentScope]);

  useEffect(() => {
    updateSelection();
  }, [updateSelection]);

  const onRowDataUpdated = useCallback(() => {
    updateSelection();
  }, [updateSelection]);

  const onRowClicked = useCallback(({ data }: RowClickedEvent<QueryFilterDto<QueryFilterKind.Document>>) => {
    if (!data) return;
    setDocumentScope({ key: DocumentScopeKey.Filter, id: data.id });
  }, [setDocumentScope]);

  const columnDefs = useMemo<ColDef<QueryFilterDto<QueryFilterKind.Document>>[]>(() => ([
    {
      cellRenderer: FilterTreeCellRenderer,
      suppressSizeToFit: true,
      flex: 1,
      resizable: false,
      sort: sortMode === QueryFilterSortMode.NameDesc || sortMode === QueryFilterSortMode.CreationDateDesc ? 'desc' : 'asc',
      comparator: (valueA: any, valueB: any, nodeA: IRowNode<QueryFilterDto<QueryFilterKind.Document>>, nodeB: IRowNode<QueryFilterDto<QueryFilterKind.Document>>) => {
        if (!nodeA.data?.name) return -1;
        if (!nodeB.data?.name) return 1;
        if (sortMode === QueryFilterSortMode.NameAsc || sortMode === QueryFilterSortMode.NameDesc) {
          return nodeA.data.name.localeCompare(nodeB.data.name);
        }
        if (sortMode === QueryFilterSortMode.CreationDateAsc || sortMode === QueryFilterSortMode.CreationDateDesc) {
          return new Date(nodeA.data.creationDate).getTime() - new Date(nodeB.data.creationDate).getTime();
        }
        return 0;
      },
    }]), [sortMode]);

  const getRowId = useCallback((params: GetRowIdParams<QueryFilterDto<QueryFilterKind.Document>>) => params.data.id ?? '', []);

  // TypeScript does not like those AG Grid modules so we use an explicit any variable to suppress the error without ts-ignore
  const modules: any = useMemo(() => [ViewportRowModelModule, RowGroupingModule], []);

  return (
    <Box sx={{ height: '100%' }} className="ag-theme-material ag-theme-visoplan tree-view-data-grid">
      <AgGridReact<QueryFilterDto<QueryFilterKind.Document>>
        ref={gridRef}
        rowData={documentFilters}
        getRowId={getRowId}
        groupDisplayType="singleColumn"
        rowSelection="single"
        columnDefs={columnDefs}
        suppressRowClickSelection
        suppressCellFocus
        onRowClicked={onRowClicked}
        modules={modules}
        onRowDataUpdated={onRowDataUpdated}
      />
    </Box>
  );
}
