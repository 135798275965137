import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export default function EmailsIcon({
  htmlColor,
  fontSize,
}: Pick<SvgIconProps, 'htmlColor' | 'fontSize'>) {
  return (
    <SvgIcon htmlColor={htmlColor} fontSize={fontSize} viewBox="0 0 16 16">
      <path d="M14.8811 3.79703L8.64643 0.176319C8.44523 0.0603141 8.2241 0 8 0C7.7759 0 7.55477 0.0603141 7.35357 0.176319L1.11893 3.79703C0.783215 3.98849 0.500186 4.28725 0.302285 4.65908C0.104384 5.03091 -0.000379627 5.46076 1.03366e-06 5.89936V13.6492C1.03366e-06 14.9455 0.907858 16 2.02393 16H13.9761C15.0921 16 16 14.9455 16 13.6492V5.89936C16.0004 5.46076 15.8956 5.03091 15.6977 4.65908C15.4998 4.28725 15.2168 3.98849 14.8811 3.79703ZM7.87071 1.37777C7.91095 1.35457 7.95518 1.3425 8 1.3425C8.04482 1.3425 8.08905 1.35457 8.12928 1.37777L14.1786 4.88976L8.03571 8.458C7.99547 8.4812 7.95125 8.49326 7.90643 8.49326C7.86161 8.49326 7.81738 8.4812 7.77714 8.458L1.72679 4.94559L7.87071 1.37777Z" />
    </SvgIcon>
  );
}
