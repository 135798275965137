import useIssuesFilterContext from 'issues/hooks/useIssuesFilterContext';
import IssueDto from 'issues/types/IssueDto';
import IssueFilterOptions from 'issues/types/IssueFilterOptions';
import IssueFilterType from 'issues/types/IssueFilterType';
import LabelType from 'labels/types/LabelType';
import { useMemo } from 'react';

type IssueFilterOptionsByFieldName = {
  [P in keyof IssueDto]?: IssueFilterOptions;
};

export default function useIssueFilterOptionsByFieldName() {
  const { queryFilterState } = useIssuesFilterContext();
  const { appliedFilterState } = queryFilterState;
  return useMemo<IssueFilterOptionsByFieldName>(() => ({
    issueNumber: { filter: { filterType: IssueFilterType.NumberFilter, value: appliedFilterState.issueNumber }, fieldName: 'issueNumber', filterField: 'issueNumber', sortPath: 'issueNumber' },
    title: { filter: { filterType: IssueFilterType.StringFilter, value: appliedFilterState.title }, fieldName: 'title', filterField: 'title', sortPath: 'title' },
    createAuthor: { filter: { filterType: IssueFilterType.CollaboratorIdFilter, value: appliedFilterState.createAuthor }, fieldName: 'createAuthor', filterField: 'createAuthor', sortPath: 'createAuthor/lastName' },
    assignedUsers: { filter: { filterType: IssueFilterType.CollaboratorIdsFilter, value: appliedFilterState.assignedCollaboratorIds }, fieldName: 'assignedUsers', filterField: 'assignedCollaboratorIds' },
    editAuthor: { filter: { filterType: IssueFilterType.CollaboratorIdFilter, value: appliedFilterState.editAuthor }, fieldName: 'editAuthor', filterField: 'editAuthor', sortPath: 'editAuthor/lastName' },
    reviewer: { filter: { filterType: IssueFilterType.CollaboratorIdFilter, value: appliedFilterState.reviewer }, fieldName: 'reviewer', filterField: 'reviewer', sortPath: 'reviewer/lastName' },
    creationDate: { filter: { filterType: IssueFilterType.DateRangeFilter, value: appliedFilterState.creationDate }, fieldName: 'creationDate', filterField: 'creationDate', sortPath: 'creationDate' },
    editDate: { filter: { filterType: IssueFilterType.DateRangeFilter, value: appliedFilterState.editDate }, fieldName: 'editDate', filterField: 'editDate', sortPath: 'editDate' },
    dueDate: { filter: { filterType: IssueFilterType.DateRangeFilter, value: appliedFilterState.dueDate }, fieldName: 'dueDate', filterField: 'dueDate', sortPath: 'dueDate' },
    startingDate: { filter: { filterType: IssueFilterType.DateRangeFilter, value: appliedFilterState.startingDate }, fieldName: 'startingDate', filterField: 'startingDate', sortPath: 'startingDate' },
    description: { filter: { filterType: IssueFilterType.StringFilter, value: appliedFilterState.description }, fieldName: 'description', filterField: 'description', sortPath: 'description' },
    disciplines: { filter: { filterType: IssueFilterType.LabelIdsFilter, value: appliedFilterState.disciplines, labelType: LabelType.Discipline }, fieldName: 'disciplines', filterField: 'disciplines' },
    buildings: { filter: { filterType: IssueFilterType.LabelIdsFilter, value: appliedFilterState.buildings, labelType: LabelType.Building }, fieldName: 'buildings', filterField: 'buildings' },
    floors: { filter: { filterType: IssueFilterType.LabelIdsFilter, value: appliedFilterState.floors, labelType: LabelType.Floor }, fieldName: 'floors', filterField: 'floors' },
    tags: { filter: { filterType: IssueFilterType.LabelIdsFilter, value: appliedFilterState.tags, labelType: LabelType.Tag }, fieldName: 'tags', filterField: 'tags' },
    workPhase: { filter: { filterType: IssueFilterType.LabelIdFilter, value: appliedFilterState.workPhase, labelType: LabelType.WorkPhase }, fieldName: 'workPhase', filterField: 'workPhase', sortPath: 'workPhase' },
    issueStatus: { filter: { filterType: IssueFilterType.LabelIdsFilter, value: appliedFilterState.status, labelType: LabelType.IssueStatus }, fieldName: 'issueStatus', filterField: 'status', sortPath: 'status' },
    issueType: { filter: { filterType: IssueFilterType.LabelIdFilter, value: appliedFilterState.type, labelType: LabelType.IssueType }, fieldName: 'issueType', filterField: 'type', sortPath: 'type' },
    issuePriority: { filter: { filterType: IssueFilterType.LabelIdFilter, value: appliedFilterState.priority, labelType: LabelType.IssuePriority }, fieldName: 'issuePriority', filterField: 'priority', sortPath: 'priority' },
    linkedComponentsGlobalIds: { filter: { filterType: IssueFilterType.StringsFilter, value: appliedFilterState.linkedComponentsGlobalIds }, fieldName: 'linkedComponentsGlobalIds', filterField: 'linkedComponentsGlobalIds' },
    visibility: { filter: { filterType: IssueFilterType.VisibilityFilter, value: appliedFilterState.visibility }, fieldName: 'visibility', filterField: 'visibility', sortPath: 'visibility' },
  }), [appliedFilterState]);
}
