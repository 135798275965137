import React, { useCallback, useMemo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useDocumentViewerContext from 'documents-details/hooks/useDocumentViewerContext';
import useIssuesOdataQuery from 'issues/hooks/useIssuesOdataQuery';
import IssueItemCard from 'issues/components/IssueItemCard';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import AnnotationItem from 'documents-annotations/types/AnnotationItem';
import LinkIssueButton from 'documents-details/components/LinkIssueButton';
import { useTranslation } from 'react-i18next';
import IssueQuickFilterBar from 'issues/components/IssueQuickFilterBar';
import useIssuesFilterContext from 'issues/hooks/useIssuesFilterContext';
import useDocumentVersionQuery from 'documents/hooks/useDocumentVersionQuery';
import useDocumentDetailsSelectionContext from 'documents-details/hooks/useDocumentDetailsSelectionContext';

interface LinkedIssuesTabProps extends ISxProps {
  onClickIssueItem: (issueId: string) => void,
}

export default function LinkedIssuesTab({
  sx,
  onClickIssueItem,
}: LinkedIssuesTabProps) {
  const theme = useTheme();
  const { t } = useTranslation('documents-details');
  const { odataQuery } = useIssuesFilterContext();
  const { documentVersionId } = useDocumentDetailsSelectionContext();
  const { data: documentVersion } = useDocumentVersionQuery(documentVersionId);
  const documentVersionOdataQuery = useMemo(() => {
    if (odataQuery && 'filter' in odataQuery && documentVersion) {
      const { filter } = odataQuery;
      const documentVersionFilter = { and: [filter, { linkedDocuments: { any: documentVersion.normalizedName } }] };
      return { ...odataQuery, filter: documentVersionFilter };
    }
    return undefined;
  }, [documentVersion, odataQuery]);
  const { data: issues } = useIssuesOdataQuery(documentVersionOdataQuery);

  const { annotationItems, setSelectedAnnotationNames } = useDocumentViewerContext();

  const annotationItemsByIssueId = useMemo(() => {
    const resultMap = new Map<string, AnnotationItem[]>();
    if (!annotationItems) return resultMap;
    annotationItems.forEach((annotationItem) => {
      if (!annotationItem.visoplanAnnotation?.linkedIssueId) return;
      const prevAnnotations = resultMap.get(annotationItem.visoplanAnnotation.linkedIssueId) ?? [];
      resultMap.set(annotationItem.visoplanAnnotation.linkedIssueId, prevAnnotations.concat(annotationItem));
    });
    return resultMap;
  }, [annotationItems]);

  const onClickIssue = useCallback((issueId: string) => {
    onClickIssueItem(issueId);
    const items = annotationItemsByIssueId.get(issueId);
    if (items) {
      const names = items.map((item) => item.viewerAnnotation.Id);
      setSelectedAnnotationNames(names);
    }
  }, [annotationItemsByIssueId, onClickIssueItem, setSelectedAnnotationNames]);

  return (
    <Box id="LinkedIssuesTab" sx={{ ...sx, display: 'flex', flexDirection: 'column', overflow: 'hidden', pt: 1 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, gap: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h4" sx={{ flexGrow: 1 }}>{t('linked-issues-tab_tab-header', 'Linked Issues')}</Typography>
          <LinkIssueButton />
        </Box>
        <IssueQuickFilterBar />
      </Box>
      <Box sx={{ flex: '1 1 0', display: 'flex', background: theme.palette.grey[200], flexDirection: 'column', overflow: 'auto', p: 2, gap: 2, boxShadow: 'inset 0px 24px 24px -24px rgba(0,0,0,0.1)' }}>
        {!!issues && issues.map((issue) => <IssueItemCard key={issue.id} issue={issue} onClick={onClickIssue} />)}
        {!issues && <CenteredCircularProgress />}
      </Box>
    </Box>
  );
}
