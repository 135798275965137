import {
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
} from '@mui/material';
import { t } from 'i18next';
import React, { useMemo } from 'react';
import ValidationApiResponse from 'upload/types/ValidationApiResponse';
import ValidateTableRow from 'upload/components/DocumentUploadWizard/ValidateTableRow';

interface ValidationResultTableProps {
  validationApiResponseBody: ValidationApiResponse,
  fileNamesThatAreFolders?: string[],
  discardStagedFiles?: (fileNames: string[]) => void,
}

export default function ValidateTable({
  validationApiResponseBody,
  fileNamesThatAreFolders,
  discardStagedFiles,
}: ValidationResultTableProps) {
  const fileNamesThatAreFoldersSet = useMemo(() => new Set(fileNamesThatAreFolders), [fileNamesThatAreFolders]);
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('upload-wizard_validate-name-column-header', 'Name')}</TableCell>
            <TableCell sx={{ width: '450px' }}>{t('upload-wizard_validate-information-column-header', 'Information')}</TableCell>
            <TableCell sx={{ width: '150px' }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {validationApiResponseBody.precheckResults.map((precheckResult) => (
            <ValidateTableRow key={precheckResult.fileName} isFolder={fileNamesThatAreFoldersSet.has(precheckResult.fileName)} precheckResult={precheckResult} onDiscard={discardStagedFiles ? () => discardStagedFiles([precheckResult.fileName]) : undefined} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

ValidateTable.defaultProps = {
  discardStagedFiles: undefined,
};
