import { useMemo } from 'react';
import { QueryOptions } from 'odata-query';
import { IServerSideDatasource, IServerSideGetRowsParams } from '@ag-grid-community/core';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useIdsQuery from 'api/hooks/useIdsQuery';
import { useQueryClient } from '@tanstack/react-query';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';

export default function useOdataRowRangeAgGridDatasource<TEndpoint extends ApiEndpoint, TDto extends { id: string }>(endpoint: TEndpoint, odataQuery: Partial<QueryOptions<TDto>> | undefined) {
  const { data: ids, dataUpdatedAt } = useIdsQuery<TEndpoint, TDto>(endpoint, odataQuery);
  const totalCount = ids?.length;

  const { getOdataQueryKey } = useDefaultEntityQueryKeys(endpoint);
  const queryClient = useQueryClient();
  const datasource: IServerSideDatasource = useMemo(() => ({
    getRows: async (params: IServerSideGetRowsParams<TDto>) => {
      const { request, success, fail } = params;
      if (request.startRow === undefined || request.endRow === undefined || request.startRow < 0 || request.endRow <= request.startRow) {
        fail();
        return;
      }
      try {
        const range = { skip: request.startRow, top: request.endRow - request.startRow };
        const queryKey = odataQuery ? getOdataQueryKey({ ...odataQuery, ...range }) : ([] as const);
        const rowData = await queryClient.fetchQuery<TDto[]>({ queryKey });
        success({ rowData, rowCount: totalCount });
      } catch (e) {
        fail();
      }
    },
  }), [getOdataQueryKey, totalCount, odataQuery, queryClient]);

  return useMemo(() => ({
    datasource,
    dataUpdatedAt,
  }), [dataUpdatedAt, datasource]);
}
