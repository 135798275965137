import React, { ChangeEvent, ReactNode, useCallback, useMemo } from 'react';
import { Box, Checkbox, Divider, FormControl, FormControlLabel, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import { useTranslation } from 'react-i18next';
import NamingSchemeFileType from 'naming-schemes/types/NamingSchemeFileType';

export interface NamingSchemeSettingsFormState {
  name: string,
  allowMetaMapping: boolean,
  applyFileTypes: string[],
  useFullFileName: boolean,
}

interface MutateNamingSchemeSettingsPanelProps extends ISxProps {
  value: NamingSchemeSettingsFormState,
  onChange: (value: NamingSchemeSettingsFormState) => void,
}

export default function MutateNamingSchemeSettingsPanel({
  sx,
  value,
  onChange,
}: MutateNamingSchemeSettingsPanelProps) {
  const { t } = useTranslation('naming-schemes');
  const applyFileTypesSet = useMemo(() => new Set(value.applyFileTypes), [value.applyFileTypes]);
  const allFileTypesSelected = useMemo(() => !!applyFileTypesSet.size && Object.values(NamingSchemeFileType).every((fileType) => applyFileTypesSet.has(fileType)), [applyFileTypesSet]);
  const allFileTypesSelectionIndeterminate = useMemo(() => !!applyFileTypesSet.size && !allFileTypesSelected, [allFileTypesSelected, applyFileTypesSet.size]);
  const onChangeNameTextField = useCallback((event: ChangeEvent<HTMLInputElement>) => onChange({ ...value, name: event.target.value }), [onChange, value]);
  const onChangeAllowMetaMappingCheckbox = useCallback((event: ChangeEvent<HTMLInputElement>) => onChange({ ...value, allowMetaMapping: event.target.checked }), [onChange, value]);
  const onChangeUseFullFilenameCheckbox = useCallback((event: ChangeEvent<HTMLInputElement>) => onChange({ ...value, useFullFileName: event.target.checked }), [onChange, value]);
  const onChangeApplyFileTypesSelect = useCallback((event: SelectChangeEvent<string[]>, child: ReactNode) => {
    if (!Array.isArray(event.target.value)) return;
    if (child && typeof child === 'object' && 'props' in child && child.props?.id === 'SelectAllFileTypes') {
      // "Select All" clicked
      const applyFileTypes = allFileTypesSelected ? [] : Object.values(NamingSchemeFileType);
      onChange({ ...value, applyFileTypes });
    } else {
      // individual menu item clicked
      onChange({ ...value, applyFileTypes: event.target.value });
    }
  }, [allFileTypesSelected, onChange, value]);
  return (
    <Box id="MutateNamingSchemeSettingsPanel" sx={{ display: 'flex', flexDirection: 'column', gap: 2, ...sx }}>
      <TextField
        label={t('mutate-naming-scheme-settings-panel_name-textfield-label', 'Name')}
        id="mutate-naming-scheme-settings-panel_name-textfield"
        value={value.name}
        onChange={onChangeNameTextField}
      />
      <FormControlLabel
        control={<Checkbox checked={value.allowMetaMapping} onChange={onChangeAllowMetaMappingCheckbox} />}
        label={t('mutate-naming-scheme-settings-panel_allow-meta-mapping-checkbox-label', 'Transfer Meta Data')}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <FormControl sx={{ alignSelf: 'stretch' }}>
          <InputLabel id="mutate-naming-scheme-settings-panel_file-type-select-label" shrink>{t('mutate-naming-scheme-settings-panel_allowed-file-types-select-label', 'Allowed File Types')}</InputLabel>
          <Select<typeof value.applyFileTypes>
            multiple
            value={value.applyFileTypes}
            onChange={onChangeApplyFileTypesSelect}
            input={<OutlinedInput label={t('mutate-naming-scheme-settings-panel_allowed-file-types-select-label', 'Allowed File Types')} />}
            displayEmpty
            notched
            renderValue={(selected) => {
              if (!selected.length) {
                return <em>{t('mutate-naming-scheme-settings-panel_allowed-file-types-select-label-empty-placeholder', 'Any File Type')}</em>;
              }
              return selected.join(', ');
            }}
          >
            <MenuItem id="SelectAllFileTypes">
              <Checkbox checked={allFileTypesSelected} indeterminate={allFileTypesSelectionIndeterminate} />
              <ListItemText primary={t('mutate-naming-scheme-settings-panel_allowed-file-types-select-label-select-all-label', 'Select All')} />
            </MenuItem>
            <Divider />
            {Object.values(NamingSchemeFileType).map((fileType) => (
              <MenuItem key={fileType} value={fileType}>
                <Checkbox checked={applyFileTypesSet.has(fileType)} />
                <ListItemText primary={fileType} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={<Checkbox checked={value.useFullFileName} onChange={onChangeUseFullFilenameCheckbox} />}
          label={t('mutate-naming-scheme-settings-panel_use-full-filename-checkbox-label', 'File extension is part of the encoding')}
        />
      </Box>
    </Box>
  );
}
