import useLabelsOdataQuery from 'labels/hooks/useLabelsOdataQuery';
import LabelDto from 'labels/types/LabelDto';
import LabelType from 'labels/types/LabelType';
import { QueryOptions } from 'odata-query';

export const PROJECT_BUILDINGS_ODATA_QUERY: Partial<QueryOptions<LabelDto>> = { filter: { type: LabelType[LabelType.Building], isDeleted: false }, orderBy: 'order asc' };

export default function useProjectBuildingsQuery() {
  return useLabelsOdataQuery(PROJECT_BUILDINGS_ODATA_QUERY);
}
