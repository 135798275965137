import React, { useCallback, useRef, useState } from 'react';
import { CustomCellRendererProps } from '@ag-grid-community/react';
import IssueDto from 'issues/types/IssueDto';
import { Box, Button, Popover, useTheme } from '@mui/material';
import Color from 'color';
import useIssueCommentsQuery from 'issues/hooks/useIssueCommentsQuery';
import IssueCommentItem from 'issues/components/IssueCommentItem';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';

export default function CommentsCell({
  value,
  data: issue,
}: CustomCellRendererProps<IssueDto, IssueDto['commentIds']>) {
  const theme = useTheme();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const onClickOpenPopover = useCallback(() => setPopoverOpen(true), []);
  const onClosePopover = useCallback(() => setPopoverOpen(false), []);
  const { data: comments } = useIssueCommentsQuery(issue && popoverOpen ? issue.commentIds : undefined);
  return (
    <>
      <Button
        id="CommentsCell"
        variant="contained"
        color="secondary"
        ref={buttonRef}
        onClick={onClickOpenPopover}
        disabled={!value?.length}
        sx={{
          backgroundColor: Color(theme.palette.info.main).lightness(92).hex(),
          fontWeight: 600,
          height: '24px',
          minWidth: 'unset',
          px: 1,
          py: 0,
        }}
      >
        {`${value?.length ?? ''}`}
      </Button>
      <Popover open={popoverOpen} anchorEl={buttonRef.current} onClose={onClosePopover} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
        <Box sx={{ p: 2, minWidth: 300, display: 'flex', flexDirection: 'column', gap: 2 }}>
          {!comments && <CenteredCircularProgress sx={{ m: 2 }} />}
          {!!comments && comments.map((comment) => (
            <IssueCommentItem comment={comment} />
          ))}
        </Box>
      </Popover>
    </>
  );
}
