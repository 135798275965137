import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import IssueDto from 'issues/types/IssueDto';

export default function CreatorCell({
  data,
}: ICellRendererParams<IssueDto, string | undefined>) {
  const creator = useMemo(() => {
    if (!data || !data.createAuthor) return undefined;
    return `${data.createAuthor.firstName} ${data.createAuthor.lastName}`;
  }, [data]);
  return (
    <Box id="CreatorCell">
      {creator}
    </Box>
  );
}
