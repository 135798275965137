import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Button, Menu, MenuItem, useTheme } from '@mui/material';
import ListOptionsIcon from 'icons/ListOptionsIcon';
import { useTranslation } from 'react-i18next';
import useDocumentFilterContext from 'documents/hooks/useDocumentFilterContext';
import SaveFilterDialogDocuments, { SaveFilterDialogResult } from 'query-filters/components/SaveFilterDialogDocuments';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import useDocumentsViewNavigationContext from 'documents/hooks/useDocumentsViewNavigationContext';
import RoleAction from 'projects/types/RoleAction';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';

export default function FilterOptionsButtonMenu() {
  const { t } = useTranslation('documents-filter');
  const { setDocumentScope } = useDocumentsViewNavigationContext();
  const buttonRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const onCloseMenu = useCallback(() => setIsMenuOpen(false), []);
  const [isSaveAsNewModalOpen, setIsSaveAsNewModalOpen] = useState(false);
  const {
    saveLocalEditsToLoadedQueryFilter,
    saveQueryFilterAsNew,
    deleteLoadedQueryFilter,
    loadedQueryFilter,
    loadedQueryFilterHasLocalEdits,
  } = useDocumentFilterContext();
  const theme = useTheme();

  const allowedActions = useAllowedActions();
  const canEditLoadedFilter = useMemo(() => (loadedQueryFilter && allowedActions ? loadedQueryFilter.isPrivate || allowedActions.has(RoleAction.DocumentFilter) : undefined), [allowedActions, loadedQueryFilter]);
  const canDeleteLoadedFilter = useMemo(() => (loadedQueryFilter && allowedActions ? loadedQueryFilter.isPrivate || allowedActions.has(RoleAction.DocumentFilter_Delete) : undefined), [allowedActions, loadedQueryFilter]);

  const onClickSaveChangesToFilter = useCallback(() => {
    saveLocalEditsToLoadedQueryFilter();
    setIsMenuOpen(false);
  }, [saveLocalEditsToLoadedQueryFilter]);

  const onClickSaveAsNewFilter = useCallback(() => {
    setIsSaveAsNewModalOpen(true);
    setIsMenuOpen(false);
  }, []);

  const onClickDeleteLoadedFilter = useCallback(() => {
    deleteLoadedQueryFilter();
    setIsMenuOpen(false);
    setDocumentScope({ key: DocumentScopeKey.Filter });
  }, [deleteLoadedQueryFilter, setDocumentScope]);

  const onCloseSaveAsNewModal = useCallback(async (result: SaveFilterDialogResult | undefined) => {
    setIsSaveAsNewModalOpen(false);
    if (result) {
      const createdEntity = await saveQueryFilterAsNew(result.name, result.isPrivate, undefined);
      if (createdEntity) {
        setDocumentScope({ key: DocumentScopeKey.Filter, id: createdEntity.id });
      }
    }
  }, [saveQueryFilterAsNew, setDocumentScope]);

  return (
    <>
      <Button onClick={() => setIsMenuOpen((prev) => !prev)} sx={{ color: theme.palette.text.primary }} ref={buttonRef} id="filter-options-menu-button_button-label">
        <ListOptionsIcon sx={{ mr: '-2px' }} />
        {t('filter-options-menu-button_button-label', 'Filter Options')}
      </Button>
      <Menu
        anchorEl={buttonRef.current}
        open={isMenuOpen}
        onClose={onCloseMenu}
      >
        <MenuItem onClick={onClickSaveChangesToFilter} disabled={!loadedQueryFilter || !loadedQueryFilterHasLocalEdits || !canEditLoadedFilter}>
          {t('filter-options-menu-button_menu-save-changes-to-filter', 'Save changes to filter')}
        </MenuItem>
        <MenuItem onClick={onClickSaveAsNewFilter}>
          {t('filter-options-menu-button_menu-save-as-new-filter', 'Save as new filter')}
        </MenuItem>
        <MenuItem onClick={onClickDeleteLoadedFilter} disabled={!loadedQueryFilter || !canDeleteLoadedFilter}>
          {t('filter-options-menu-button_menu-delete-filter', 'Delete filter')}
        </MenuItem>
      </Menu>
      {!!isSaveAsNewModalOpen && <SaveFilterDialogDocuments onClose={onCloseSaveAsNewModal} />}
    </>
  );
}
