import React, { useCallback } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DateRangePicker from 'common/components/DateRangePicker';
import DocumentFilterState from 'documents-filter/types/DocumentFilterState';
import DateRange from 'common/types/DateRange';

interface TimeFilterSectionProps {
  value: DocumentFilterState,
  onChange: (state: DocumentFilterState) => void,
}

export default function TimeFilterSection({
  value,
  onChange,
}: TimeFilterSectionProps) {
  const { t } = useTranslation('documents-filter');

  const onChangeUploadDateRange = useCallback((v: DateRange | undefined) => {
    onChange({ ...value, uploadDateRange: v });
  }, [onChange, value]);

  const onChangeCreationDateRange = useCallback((v: DateRange | undefined) => {
    onChange({ ...value, creationDateRange: v });
  }, [onChange, value]);

  return (
    <>
      <Typography variant="h4" sx={{ mt: 3 }}>{t('filter-drawer_time-header', 'Time')}</Typography>
      <Typography variant="h5" sx={{ mt: 2 }}>{t('filter-drawer_upload-date-header', 'Upload Date')}</Typography>
      <DateRangePicker value={value.uploadDateRange} onChange={onChangeUploadDateRange} noFutureDates />
      <Typography variant="h5" sx={{ mt: 2 }}>{t('filter-drawer_creation-date-header', 'Creation Date')}</Typography>
      <DateRangePicker value={value.creationDateRange} onChange={onChangeCreationDateRange} noFutureDates />
    </>
  );
}
