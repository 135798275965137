import React, {
  useContext,
  useRef, useState,
} from 'react';
import {
  Button, Menu, MenuItem, useTheme, Radio,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ISxProps from 'common/types/ISxProps';
import SortIcon from '@mui/icons-material/Sort';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import FolderFilterContext, { FolderFilterContextState } from 'documents-folders/contexts/FolderFilterContext';
import FolderSortMode from 'documents-folders/types/FolderSortMode';

interface FolderSortButtonMenuProps extends ISxProps {
}

export default function FolderSortButtonMenu({
  sx,
}: FolderSortButtonMenuProps) {
  const { t } = useTranslation('documents-folders');
  const theme = useTheme();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { sortMode, setSortMode } = useContext<FolderFilterContextState>(FolderFilterContext);

  return (
    <>
      <Button
        id="FolderSortMenuButton"
        onClick={() => setIsMenuOpen(true)}
        sx={{
          ...sx, color: theme.palette.text.primary, minWidth: 'unset', px: 1,
        }}
        ref={buttonRef}
        variant="outlined"
      >
        <SortIcon sx={{ mr: '-2px', color: theme.palette.text.secondary }} />
      </Button>
      <Menu
        anchorEl={buttonRef.current}
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      >
        <MenuItem onClick={() => setSortMode(FolderSortMode.CreationDateDesc)}>
          <Radio checked={sortMode === FolderSortMode.CreationDateDesc} sx={{ ml: 0 }} />
          {t('folder-explorer_sort-select-creation-date', 'Creation Date')}
          <ArrowDownwardIcon fontSize="small" sx={{ ml: 1 }} />
        </MenuItem>
        <MenuItem onClick={() => setSortMode(FolderSortMode.CreationDateAsc)}>
          <Radio checked={sortMode === FolderSortMode.CreationDateAsc} sx={{ ml: 0 }} />
          {t('folder-explorer_sort-select-creation-date', 'Creation Date')}
          <ArrowUpwardIcon fontSize="small" sx={{ ml: 1 }} />
        </MenuItem>
        <MenuItem onClick={() => setSortMode(FolderSortMode.NameDesc)}>
          <Radio checked={sortMode === FolderSortMode.NameDesc} sx={{ ml: 0 }} />
          {t('folder-explorer_sort-select-name', 'Name')}
          <ArrowDownwardIcon fontSize="small" sx={{ ml: 1 }} />
        </MenuItem>
        <MenuItem onClick={() => setSortMode(FolderSortMode.NameAsc)}>
          <Radio checked={sortMode === FolderSortMode.NameAsc} sx={{ ml: 0 }} />
          {t('folder-explorer_sort-select-name', 'Name')}
          <ArrowUpwardIcon fontSize="small" sx={{ ml: 1 }} />
        </MenuItem>
      </Menu>
    </>
  );
}
