function partiallyReplaceCachedEntityList<T extends ({ id: string } | undefined)>(cachedEntities: T[], updatedEntities: T[]) {
  const updatedCachedEntities = [...cachedEntities];
  const updatedEntitiesById = new Map<string, T>(updatedEntities.filter((e) => !!e).map((e) => [e!.id, e]));
  cachedEntities.forEach((e, i) => {
    if (!e) return;
    const updatedEntity = updatedEntitiesById.get(e.id);
    if (!updatedEntity) return;
    updatedCachedEntities[i] = updatedEntity;
  });
  return updatedCachedEntities;
}

export default function useCachedEntityListPartialReplace() {
  return partiallyReplaceCachedEntityList;
}
