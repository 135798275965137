import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Typography, useTheme, CircularProgress } from '@mui/material';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import ISxProps from 'common/types/ISxProps';
import LocalizedDateCalendar from 'common/components/LocalizedDateCalendar';
import Icon from '@mdi/react';
import { mdiArrowLeftBold, mdiArrowRightBold } from '@mdi/js';
import useIssuesOdataQuery from 'issues/hooks/useIssuesOdataQuery';
import { range } from 'lodash';
import IssueItemCard from 'issues/components/IssueItemCard';
import DueIssuesPickersDay from 'issues/components/DueIssuesPickersDay';
import useIsuesCalendarOdataQuery from 'issues/hooks/useIssuesCalendarOdataQuery';
import IssuesCalendarDayColumn, { IssuesCalendarDayColumnProps } from 'issues/components/IssuesCalendarDayColumn';
import useIssuesSelectionContext from 'issues/hooks/useIssuesSelectionContext';
import useIssueQueryData from 'issues/hooks/useIssueQueryData';
import useClosedIssueStatus from 'issues/hooks/useClosedIssueStatus';
import useIssuesOdataInfiniteQuery from 'issues/hooks/useIssuesOdataInfiniteQuery';
import useIssuesIdsQuery from 'issues/hooks/useIssuesIdsQuery';

interface IssuesCalendarProps extends ISxProps {
  compact?: boolean,
}

export default function IssuesCalendar({
  sx,
  compact,
}: IssuesCalendarProps) {
  const { t, i18n } = useTranslation('issues');
  const theme = useTheme();
  const getIssue = useIssueQueryData();
  const [selectedDate, setSelectedDate] = useState<Moment>(moment());
  const { selectedIssueIds, setSelectedIssueIds } = useIssuesSelectionContext();
  const selectedIssueId = useMemo(() => selectedIssueIds[0], [selectedIssueIds]);
  const issuesOdataQuery = useIsuesCalendarOdataQuery(selectedDate);
  const { data: issues } = useIssuesOdataQuery(issuesOdataQuery);
  const closedIssueStatus = useClosedIssueStatus();
  const week = useMemo(() => selectedDate?.week(), [selectedDate]);
  const onChangeSelectedDate = useCallback((value: Moment | undefined) => setSelectedDate(moment(value)), []);
  const onClickPreviousWeek = useCallback(() => setSelectedDate((prev) => moment(prev).subtract(1, 'week')), []);
  const onClickNextWeek = useCallback(() => setSelectedDate((prev) => moment(prev).add(1, 'week')), []);
  const onClickIssue = useCallback((issueId: string) => {
    setSelectedIssueIds([issueId]);
  }, [setSelectedIssueIds]);

  useEffect(() => {
    if (selectedIssueId) {
      getIssue(selectedIssueId).then((issue) => setSelectedDate(moment(issue.dueDate)));
    }
  }, [getIssue, selectedIssueId]);

  const weekItems = useMemo(() => {
    const weekdays = compact ? [selectedDate.weekday()] : range(0, 7);
    const itemsByWeekday = new Map<number, Pick<IssuesCalendarDayColumnProps, 'dayName' | 'dateString' | 'dueIssues' | 'isSelected' | 'onSelect'>>(weekdays.map((weekday) => {
      const date = moment(selectedDate).weekday(weekday);
      return [
        weekday,
        {
          dayName: date.locale(i18n.language).format('dddd'),
          dateString: date.toDate().toLocaleDateString('de-DE'),
          dueIssues: issues ? [] : undefined,
          isSelected: date.isSame(selectedDate, 'day'),
          onSelect: () => setSelectedDate(date),
        },
      ];
    }));
    issues?.forEach((issue) => {
      if (issue.dueDate && moment(issue.dueDate).isSame(selectedDate, 'week')) {
        const dueDateMoment = moment(issue.dueDate);
        const weekday = dueDateMoment.weekday();
        const item = itemsByWeekday.get(weekday);
        if (item?.dueIssues) {
          item.dueIssues.push(issue);
        }
      }
    });
    return Array.from(itemsByWeekday.values());
  }, [compact, selectedDate, issues, i18n.language]);

  const overdueIssuesOdataQuery = useMemo(() => (closedIssueStatus ? { filter: { dueDate: { le: moment().subtract(1, 'day').endOf('day').toDate() }, issueStatus: { ne: closedIssueStatus.id } }, orderBy: 'dueDate asc' } : undefined), [closedIssueStatus]);
  const { data: overdueIssueIds } = useIssuesIdsQuery(overdueIssuesOdataQuery);
  const { data: overdueIssuesInfiniteData, isFetchingNextPage, fetchNextPage, hasNextPage } = useIssuesOdataInfiniteQuery(overdueIssuesOdataQuery);
  const overdueIssues = useMemo(() => overdueIssuesInfiniteData?.pages.filter(Boolean).flatMap((page) => page).map((issue) => issue!), [overdueIssuesInfiniteData?.pages]);

  const observerTarget = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && overdueIssues?.length !== overdueIssueIds?.length) {
        fetchNextPage();
      }
    }, { threshold: 1 });
    const target = observerTarget.current;
    if (target) {
      observer.observe(target);
    }
    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, [fetchNextPage, observerTarget, hasNextPage, overdueIssues?.length, overdueIssueIds?.length]);

  return (
    <Box id="IssuesCalendar" sx={{ ...sx, display: 'grid', gridTemplateColumns: '340px 1fr auto', gridTemplateRows: 'auto 1fr' }}>
      <Box sx={{ p: 2, display: 'flex', borderBottom: `1px solid ${theme.palette.divider}`, backgroundColor: theme.palette.background.default }}>
        <Typography variant="h4">{t('issues-calendar_header', 'Due Issues')}</Typography>
      </Box>
      {!compact && (
        <Box sx={{ gridColumn: '2/-1', display: 'flex', gap: 2, justifyContent: 'center', alignItems: 'center', borderBottom: `1px solid ${theme.palette.divider}`, px: 2, backgroundColor: theme.palette.background.default }}>
          <Button variant="outlined" onClick={onClickPreviousWeek} sx={{ minWidth: 'unset' }}>
            <Icon path={mdiArrowLeftBold} size={1} />
          </Button>
          <Typography variant="h5">{t('issues-calendar_week-header', 'Calendar Week {{week}}', { week })}</Typography>
          <Button variant="outlined" onClick={onClickNextWeek} sx={{ minWidth: 'unset' }}>
            <Icon path={mdiArrowRightBold} size={1} />
          </Button>
        </Box>
      )}
      <Box sx={{ gridRow: '2/-1', gridColumn: '1/2', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <LocalizedDateCalendar
          value={selectedDate}
          onChange={onChangeSelectedDate}
          sx={{ flexShrink: 0 }}
          showDaysOutsideCurrentMonth
          displayWeekNumber
          slots={{ day: DueIssuesPickersDay }}
        />
        <Box sx={{ flexGrow: 1, overflow: 'auto', minHeight: 0, display: 'flex', flexDirection: 'column', p: 2, gap: 2, borderTop: `1px solid ${theme.palette.divider}` }}>
          <Typography variant="h5">
            {t('issues-calendar_overdue-issues-header', 'Overdue Issues')}
            {!(overdueIssues && overdueIssueIds) && <CircularProgress size={12} sx={{ ml: 2 }} />}
            {!!(overdueIssueIds && overdueIssueIds) && ` (${overdueIssueIds.length})`}
          </Typography>
          {!!overdueIssues && overdueIssues.map((issue) => (
            <IssueItemCard
              key={issue.id}
              issue={issue}
              onClick={onClickIssue}
              selected={selectedIssueId === issue.id}
            />
          ))}
          <Box ref={observerTarget} />
          {isFetchingNextPage && <Box sx={{ display: 'flex', justifyContent: 'center' }}><CircularProgress size={24} /></Box>}
        </Box>
      </Box>
      <Box sx={{ gridRow: '2/-1', gridColumn: '2/3', overflow: 'auto', px: 1, display: 'flex', boxShadow: 'inset 0px 0px 16px 0px rgba(0,0,0,0.1)', minHeight: 0 }}>
        <Box sx={{ flexGrow: 1, display: 'flex', minHeight: 0, minWidth: 1024 }}>
          {weekItems.map(({ dayName, dateString, dueIssues, isSelected, onSelect }) => (
            <IssuesCalendarDayColumn
              key={dayName}
              dayName={dayName}
              dateString={dateString}
              dueIssues={dueIssues}
              isSelected={isSelected}
              onSelect={onSelect}
              onClickIssue={onClickIssue}
              selectedIssueId={selectedIssueId}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}
