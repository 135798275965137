import React, { useCallback, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import IssueDetailsPanel from 'issues/components/IssueDetailsPanel';
import useIssuesSelectionContext from 'issues/hooks/useIssuesSelectionContext';
import ModelsDialog from 'models/components/ModelsDialog';
import { useTranslation } from 'react-i18next';
import ViewpointDto from 'issues/types/ViewpointDto';
import ModelsInteractionContextProvider from 'models/contexts/ModelsInteractionContextProvider';
import EditViewpointsDialog from 'models/components/EditViewpointsDialog';
import useModelFileIdsLinkedToIssue from 'issues/hooks/useModelFileIdsLinkedToIssue';
import EditComponentsDialog from 'models/components/EditComponentsDialog';
import PdfAnnotationDto from 'documents-annotations/types/PdfAnnotationDto';
import usePdfAnnotationNameParser from 'documents-annotations/hooks/usePdfAnnotationNameParser';

export default function IssueDetailsSidebar() {
  const { t } = useTranslation('issues');
  const navigate = useNavigate();
  const { selectedIssueIds, setSelectedIssueIds, multiselectActive } = useIssuesSelectionContext();
  const getModelFileIdsLinkedToIssue = useModelFileIdsLinkedToIssue();
  const issueId = useMemo(() => (!multiselectActive ? selectedIssueIds[0] : undefined), [multiselectActive, selectedIssueIds]);

  const onClose = useCallback(() => {
    setSelectedIssueIds([]);
  }, [setSelectedIssueIds]);
  const onClickIssueItem = useCallback((clickedIssueId: string) => {
    setSelectedIssueIds([clickedIssueId]);
  }, [setSelectedIssueIds]);
  const onClickDocumentVersionItem = useCallback((clickedDocumentVersionId: string) => {
    if (issueId) {
      navigate(`./document/${clickedDocumentVersionId}/issue/${issueId}`);
    } else {
      navigate(`./document/${clickedDocumentVersionId}`);
    }
  }, [issueId, navigate]);
  const onClickEmailItem = useCallback((clickedEmailId: string) => {
    navigate(`../../../emails/${clickedEmailId}`);
  }, [navigate]);

  const [modelsDialogData, setModelsDialogData] = useState<{ viewpoint: ViewpointDto, modelFileIds: string[], issueId: string | undefined } | undefined>(undefined);
  const onClickViewpointItem = useCallback(async (viewpoint: ViewpointDto) => {
    if (!issueId) return;
    const modelFileIds = await getModelFileIdsLinkedToIssue(issueId);
    setModelsDialogData({
      viewpoint,
      modelFileIds,
      issueId,
    });
  }, [getModelFileIdsLinkedToIssue, issueId]);
  const setModelsViewSelectedIssueId = useCallback((value: string | undefined) => setModelsDialogData((prev) => (prev ? { ...prev, issueId: value } : undefined)), []);
  const onCloseModelsDialog = useCallback(() => setModelsDialogData(undefined), []);

  const [editViewpointsDialogOpen, setEditViewpointsDialogOpen] = useState<boolean>(false);
  const onClickEditViewpoints = useCallback(() => {
    setEditViewpointsDialogOpen(true);
  }, []);
  const onCloseEditViewpointsDialog = useCallback(() => setEditViewpointsDialogOpen(false), []);

  const [editComponentsDialogOpen, setEditComponentsDialogOpen] = useState<boolean>(false);
  const onClickEditComponents = useCallback(() => {
    setEditComponentsDialogOpen(true);
  }, []);
  const onCloseEditComponentsDialog = useCallback(() => setEditComponentsDialogOpen(false), []);

  const parseAnnotationName = usePdfAnnotationNameParser();
  const onClickAnnotationItem = useCallback((annotation: PdfAnnotationDto) => {
    if (!issueId) return;
    const annotationName = parseAnnotationName(annotation);
    navigate(`./document/${annotation.documentVersionId}/issue/${issueId}`, { state: { annotationName } });
  }, [issueId, navigate, parseAnnotationName]);

  return (
    <Box
      id="IssueDetailsSidebar"
      sx={{
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'flex-start',
        justifyContent: 'flex-start',
      }}
    >
      <IssueDetailsPanel
        issueId={issueId}
        onClose={onClose}
        onClickIssueItem={onClickIssueItem}
        onClickDocumentVersionItem={onClickDocumentVersionItem}
        onClickEmailItem={onClickEmailItem}
        onClickViewpointItem={onClickViewpointItem}
        onClickEditViewpoints={onClickEditViewpoints}
        onClickEditComponents={onClickEditComponents}
        onClickAnnotationItem={onClickAnnotationItem}
      />
      {!!modelsDialogData && (
        <ModelsInteractionContextProvider>
          <ModelsDialog
            title={t('issue-details-sidebar_models-dialog-title', 'Model')}
            initiallySelectedViewpoint={modelsDialogData.viewpoint}
            initiallySelectedModelFileIds={modelsDialogData.modelFileIds}
            onClose={onCloseModelsDialog}
            selectedIssueId={modelsDialogData.issueId}
            setSelectedIssueId={setModelsViewSelectedIssueId}
          />
        </ModelsInteractionContextProvider>
      )}
      {!!editViewpointsDialogOpen && issueId && (
        <ModelsInteractionContextProvider>
          <EditViewpointsDialog issueId={issueId} onClose={onCloseEditViewpointsDialog} showNestedModelsDialog />
        </ModelsInteractionContextProvider>
      )}
      {!!editComponentsDialogOpen && issueId && (
        <ModelsInteractionContextProvider>
          <EditComponentsDialog issueId={issueId} onClose={onCloseEditComponentsDialog} showNestedModelsDialog />
        </ModelsInteractionContextProvider>
      )}
      <Outlet />
    </Box>
  );
}
