import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import { useCallback } from 'react';

export default function useProjectDeleteMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases: projectQueryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.Project);
  const { queryKeyBases: userQueryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.User);
  const { queryKeyBases: collaboratorsQueryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.Collaborator);
  const mutationFn = useCallback(async (id: string) => {
    await axiosInstance.delete<boolean>(`${ApiEndpoint.Project}/${id}`);
  }, [axiosInstance]);
  const onSuccess = useCallback(() => {
    projectQueryKeyBases.forEach((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
    userQueryKeyBases.forEach((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
    collaboratorsQueryKeyBases.forEach((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
  }, [collaboratorsQueryKeyBases, projectQueryKeyBases, queryClient, userQueryKeyBases]);
  return useMutation<void, unknown, string>({
    mutationFn,
    onSuccess,
  });
}
