import React from 'react';
import { Box } from '@mui/material';
import HeaderBox from 'common/components/HeaderBox';
import TwoFactorAuthRequiredDialog from 'users/components/TwoFactorAuthRequiredDialog';

interface TwoFactorAuthRequiredWizardProps {
  onClose: (confirmed: boolean) => void,
}

export default function TwoFactorAuthRequiredWizard({
  onClose,
}: TwoFactorAuthRequiredWizardProps) {
  return (
    <Box id="TwoFactorAuthRequiredWizard">
      <HeaderBox sx={{ height: 56, flexShrink: 0, display: 'flex', gap: 4, boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.1)', position: 'relative' }}>
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
          <img src="/img/visoplan-logo.svg" alt="logo" style={{ width: '140px' }} />
        </Box>
      </HeaderBox>
      <TwoFactorAuthRequiredDialog onClose={onClose} hideBackdrop />
    </Box>
  );
}
