import React, { useCallback, useRef } from 'react';
import { Box, Link, Typography, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import useEmailUploadDropRef from 'emails/hooks/useEmailUploadDropRef';
import useEmailsUploadContext from 'emails/hooks/useEmailsUploadContext';
import EmailsUploadStatusDisplay from 'emails/components/EmailsUploadWizard/EmailsUploadStatusDisplay';
import EmailUploadFileInput from 'emails/components/EmailUploadFileInput';

export default function EmailDropTarget() {
  const { t } = useTranslation('emails');
  const inputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const {
    uploadingFileHandles,
  } = useEmailsUploadContext();

  const onClickUploadLink = useCallback(() => {
    inputRef.current?.showPicker();
  }, []);

  // handle files from drag-and-drop
  const { canDropEmails, emailUploadDropRef } = useEmailUploadDropRef();

  return (
    <Box
      id="EmailDropTarget"
      ref={emailUploadDropRef}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        borderWidth: '1px',
        borderStyle: 'dashed',
        height: 48,
        borderColor: canDropEmails ? theme.palette.primary.main : theme.palette.grey[400],
      }}
    >
      {uploadingFileHandles.length
        ? <EmailsUploadStatusDisplay />
        : (
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              alignSelf: 'stretch',
              alignItems: 'center',
              justifyContent: 'center',
              mx: 2,
            }}
          >
            <FileUploadIcon sx={{ color: canDropEmails ? theme.palette.primary.main : theme.palette.text.primary, mr: 1 }} />
            <Typography>
              <Trans
                t={t}
                i18nKey="email-drop-target_drop-prompt"
                defaults="<strong>Drag & Drop</strong> files to <uploadLink>upload e-mails</uploadLink>"
                components={{ strong: <strong />, uploadLink: <Link onClick={onClickUploadLink} /> }}
              />
              <EmailUploadFileInput inputRef={inputRef} />
            </Typography>
          </Box>
        )}
    </Box>
  );
}
