import React from 'react';
import {
  Box, Typography, useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ISxProps from 'common/types/ISxProps';

interface EmailDropOverlayProps extends ISxProps {
}

export default function EmailDropOverlay({
  sx,
}: EmailDropOverlayProps) {
  const { t } = useTranslation('emails');
  const theme = useTheme();
  return (
    <Box
      id="EmailDropOverlay"
      sx={{
        display: 'flex',
        backgroundColor: theme.palette.background.default,
        padding: 2,
        ...sx,
      }}
    >
      <Box sx={{
        borderWidth: '1px',
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        alignSelf: 'stretch',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <CloudUploadIcon fontSize="large" sx={{ color: theme.palette.primary.main }} />
        <Typography sx={{ fontSize: '1rem', fontWeight: 700 }}>{t('email-drop-overlay_drop-description', 'Drag and drop emails')}</Typography>
      </Box>
    </Box>
  );
}
