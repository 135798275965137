import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import useEmailsOdataQuery from 'emails/hooks/useEmailsOdataQuery';
import LinkedEmailPanelItem from 'issues/components/LinkedEmailPanelItem';
import Icon from '@mdi/react';
import { mdiArrowUp, mdiEmail, mdiFileDocument, mdiLinkPlus, mdiSubdirectoryArrowRight } from '@mdi/js';
import UpdateIssueDto from 'issues/types/UpdateIssueDto';
import useIssueUpdateMutation from 'issues/hooks/useIssueUpdateMutation';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useIssuesUpdateManyMutation from 'issues/hooks/useIssuesUpdateManyMutation';
import UpdateManyIssuesDto from 'issues/types/UpdateManyIssuesDto';
import LinkedIssuePanelItem from 'issues/components/LinkedIssuePanelItem';
import useIssuesOdataQuery from 'issues/hooks/useIssuesOdataQuery';
import LinkIssueModal from 'issues/components/LinkIssueModal';
import IssueSelectLinkedDocumentsDialog from 'issues/components/IssueSelectLinkedDocumentsDialog';
import useDocumentVersionsQuery from 'documents/hooks/useDocumentVersionsQuery';
import LinkedDocumentVersionPanelItem from 'issues/components/LinkedDocumentVersionPanelItem';
import LinkEmailModal from 'issues/components/LinkEmailModal';
import useIssueEditingRoleRight from 'issues/hooks/useIssueEditingRoleRight';
import useClosedIssueStatus from 'issues/hooks/useClosedIssueStatus';

interface IssueLinkedItemsPanelProps {
  issueId: string,
  onClickEmailItem?: ((emailId: string) => void) | undefined,
  onClickIssueItem?: ((issueId: string) => void) | undefined,
  onClickDocumentVersionItem?: ((documentVersionId: string) => void) | undefined,
  nonInteractive?: boolean | undefined,
}

export default function IssueLinkedItemsPanel({
  issueId,
  onClickEmailItem,
  onClickIssueItem,
  onClickDocumentVersionItem,
  nonInteractive,
}: IssueLinkedItemsPanelProps) {
  const { t } = useTranslation('issues');
  const theme = useTheme();
  const { data: issue } = useIssueQuery(issueId);
  const { data: parentIssue } = useIssueQuery(issue?.parentId);
  const { data: childIssues } = useIssuesOdataQuery({ filter: { parentId: issueId } });
  const linkedEmailsOdataFilter = useMemo(() => (issue ? { filter: { id: { in: issue.linkedEmailIds } } } : undefined), [issue]);
  const { data: linkedEmails } = useEmailsOdataQuery(linkedEmailsOdataFilter);
  const linkedDocumentVersionsOdataFilter = useMemo(() => (issue ? { filter: { id: { in: issue.linkedDocumentVersionIds } } } : undefined), [issue]);
  const { data: linkedDocumentVersions } = useDocumentVersionsQuery(linkedDocumentVersionsOdataFilter);
  const { mutateAsync: mutateIssueAsync } = useIssueUpdateMutation();
  const { mutateAsync: mutateManyIssuesAsync } = useIssuesUpdateManyMutation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const getRequestErrorMessage = useRequestErrorMessage();
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);

  const disabledIssueIds = useMemo(() => (issue ? new Set([issue.id, issue.parentId, ...issue.childrenIds].filter(Boolean).map((id) => id!)) : undefined), [issue]);

  const addLinkButtonRef = useRef<HTMLButtonElement>(null);
  const [linkItemMenuOpen, setLinkItemMenuOpen] = useState(false);
  const onClickAddLinkButton = useCallback(() => setLinkItemMenuOpen(true), []);
  const onCloseAddLinkMenu = useCallback(() => setLinkItemMenuOpen(false), []);

  const [linkParentIssueDialogOpen, setLinkParentIssueDialogOpen] = useState(false);
  const onClickLinkParentIssue = useCallback(() => {
    setLinkParentIssueDialogOpen(true);
    setLinkItemMenuOpen(false);
  }, []);
  const onCloseLinkParentIssueDialog = useCallback(async (selectedIssueIds: string[]) => {
    try {
      if (selectedIssueIds.length === 1) {
        const updateDto: UpdateIssueDto = {
          id: issueId,
          parentId: { value: selectedIssueIds[0] },
        };
        await mutateIssueAsync(updateDto);
      }
      setLinkParentIssueDialogOpen(false);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [getRequestErrorMessage, issueId, mutateIssueAsync]);

  const [linkChildIssuesDialogOpen, setLinkChildIssuesDialogOpen] = useState(false);
  const onClickLinkChildIssues = useCallback(() => {
    setLinkChildIssuesDialogOpen(true);
    setLinkItemMenuOpen(false);
  }, []);
  const onCloseLinkChildIssuesDialog = useCallback(async (selectedIssueIds: string[]) => {
    try {
      if (selectedIssueIds?.length) {
        const updateDto: UpdateManyIssuesDto = {
          ids: selectedIssueIds,
          timeStamp: new Date().toISOString(),
          parentId: { value: issueId },
        };
        await mutateManyIssuesAsync(updateDto);
      }
      setLinkChildIssuesDialogOpen(false);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [getRequestErrorMessage, issueId, mutateManyIssuesAsync]);

  const [linkDocumentDialogOpen, setLinkDocumentDialogOpen] = useState(false);
  const onClickLinkDocument = useCallback(() => {
    setLinkDocumentDialogOpen(true);
    setLinkItemMenuOpen(false);
  }, []);
  const onCloseIssueSelectLinkedDocumentsDialog = useCallback(async (selectedDocumentVersionIds: string[] | undefined) => {
    if (!issue) throw new Error('dependency error');
    try {
      if (selectedDocumentVersionIds) {
        const nextLinkedDocumentVersionIds = Array.from(new Set((issue.linkedDocumentVersionIds ?? []).concat(...selectedDocumentVersionIds)));
        await mutateIssueAsync({
          id: issueId,
          linkedDocumentVersionIds: { value: nextLinkedDocumentVersionIds },
        });
      }
      setLinkDocumentDialogOpen(false);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [getRequestErrorMessage, issue, issueId, mutateIssueAsync]);

  const disabledEmailIds = useMemo(() => (issue ? new Set(issue.linkedEmailIds) : undefined), [issue]);
  const [linkEmailDialogOpen, setLinkEmailDialogOpen] = useState(false);
  const onClickLinkEmail = useCallback(() => {
    setLinkEmailDialogOpen(true);
    setLinkItemMenuOpen(false);
  }, []);
  const onCloseLinkEmailDialog = useCallback(async (selectedEmailIds: string | undefined) => {
    if (!issue) throw new Error('dependency error');
    try {
      if (selectedEmailIds) {
        const nextLinkedEmailIds = Array.from(new Set((issue.linkedEmailIds ?? []).concat(selectedEmailIds)));
        await mutateIssueAsync({
          id: issueId,
          linkedEmailIds: { value: nextLinkedEmailIds },
        });
      }
      setLinkEmailDialogOpen(false);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [getRequestErrorMessage, issue, issueId, mutateIssueAsync]);

  const canEdit = useIssueEditingRoleRight(issueId);

  const closedIssueStatus = useClosedIssueStatus();
  const addLinkButtonDisabled = useMemo(() => issue?.issueStatus === closedIssueStatus?.id, [closedIssueStatus, issue?.issueStatus]);

  return (
    <Box id="IssueLinkedItemsPanel">
      <Box sx={{
        backgroundColor: theme.palette.background.default,
        borderRadius: '8px',
        boxShadow: '0px 1px 4px -1px rgba(0,0,0,0.3)',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        pt: 2,
      }}
      >
        <Box sx={{ px: 2, display: 'flex', alignItems: 'flex-start', gap: 2 }}>
          <Typography variant="h4">
            {t('issue-linked-items-panel_title', 'Links')}
          </Typography>
          {!nonInteractive && !!canEdit && (
          <Button onClick={onClickAddLinkButton} ref={addLinkButtonRef} disabled={addLinkButtonDisabled} variant="contained" color="secondary" size="small" sx={{ ml: 'auto' }}>
            <Icon path={mdiLinkPlus} size={1} />
          </Button>
          )}
          <Menu open={linkItemMenuOpen} onClose={onCloseAddLinkMenu} anchorEl={addLinkButtonRef.current}>
            <MenuItem sx={{ gap: 1 }} onClick={onClickLinkParentIssue}>
              <Icon path={mdiArrowUp} size={0.75} />
              {t('issue-linked-items-panel_link-item-option-parent-issue', 'Parent Issue')}
            </MenuItem>
            <MenuItem sx={{ gap: 1 }} onClick={onClickLinkChildIssues}>
              <Icon path={mdiSubdirectoryArrowRight} size={0.75} />
              {t('issue-linked-items-panel_link-item-option-child-issue', 'Child Issue')}
            </MenuItem>
            <MenuItem sx={{ gap: 1 }} onClick={onClickLinkDocument}>
              <Icon path={mdiFileDocument} size={0.75} />
              {t('issue-linked-items-panel_link-item-option-document', 'Document')}
            </MenuItem>
            <MenuItem sx={{ gap: 1 }} onClick={onClickLinkEmail}>
              <Icon path={mdiEmail} size={0.75} />
              {t('issue-linked-items-panel_link-item-option-email', 'Email')}
            </MenuItem>
          </Menu>
          {!!linkParentIssueDialogOpen && <LinkIssueModal title={t('issue-linked-items-panel_select-parent-issue-modal-title', 'Select Parent Issue')} disabledIssueIds={disabledIssueIds} onClose={onCloseLinkParentIssueDialog} />}
          {!!linkChildIssuesDialogOpen && <LinkIssueModal title={t('issue-linked-items-panel_link-existing-issue-modal-title', 'Link Child Issues')} disabledIssueIds={disabledIssueIds} multiple onClose={onCloseLinkChildIssuesDialog} />}
          {!!linkDocumentDialogOpen && <IssueSelectLinkedDocumentsDialog issueId={issueId} onClose={onCloseIssueSelectLinkedDocumentsDialog} />}
          {!!linkEmailDialogOpen && <LinkEmailModal onClose={onCloseLinkEmailDialog} disabledEmailIds={disabledEmailIds} />}
        </Box>
        {!(
          issue?.parentId
          || issue?.childrenIds?.length
          || issue?.linkedEmailIds?.length
          || issue?.linkedDocumentVersionIds?.length
        ) && (
          <Box sx={{ px: 2, pb: 2 }}><em>{t('issue-linked-items-panel_links-panel-empty-text', 'No linked items')}</em></Box>
        )}
        {!!parentIssue && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h5" sx={{ px: 2 }}>{t('issue-linked-items-panel_parent-issue-header', 'Parent Issue')}</Typography>
            <LinkedIssuePanelItem iconPath={mdiArrowUp} issue={parentIssue} onClick={onClickIssueItem} />
          </Box>
        )}
        {!!childIssues?.length && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h5" sx={{ px: 2 }}>
              {t('issue-linked-items-panel_child-issues-header', 'Child Issues')}
            </Typography>
            {childIssues.map((childIssue) => (
              <LinkedIssuePanelItem key={childIssue.id} iconPath={mdiSubdirectoryArrowRight} issue={childIssue} onClick={onClickIssueItem} />
            ))}
          </Box>
        )}
        {!!linkedDocumentVersions?.length && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h5" sx={{ px: 2 }}>
              {t('issue-linked-items-panel_documents-header', 'Documents')}
            </Typography>
            {linkedDocumentVersions.map((linkedDocumentVersion) => (
              <LinkedDocumentVersionPanelItem key={linkedDocumentVersion.id} documentVersion={linkedDocumentVersion} onClick={onClickDocumentVersionItem} />
            ))}
          </Box>
        )}
        {!!linkedEmails?.length && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h5" sx={{ px: 2 }}>
              {t('issue-linked-items-panel_emails-header', 'Emails')}
            </Typography>
            {linkedEmails.map((linkedEmail) => (
              <LinkedEmailPanelItem key={linkedEmail.id} email={linkedEmail} onClick={onClickEmailItem} />
            ))}
          </Box>
        )}
      </Box>
      {!!errorMessage && (
        <Dialog open onClose={onCloseErrorMessage}>
          <DialogTitle>{t('issue-linked-items-panel_error-dialog-title', 'Error')}</DialogTitle>
          <DialogContent><Alert severity="error">{errorMessage}</Alert></DialogContent>
          <DialogActions><Button variant="contained" onClick={onCloseErrorMessage}>{t('issue-linked-items-panel_error-dialog-close-button', 'Close')}</Button></DialogActions>
        </Dialog>
      )}
    </Box>
  );
}
