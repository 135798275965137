import React, { useCallback, useMemo, useState } from 'react';
import { Button, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiContentCopy } from '@mdi/js';

interface CopyButtonProps {
  value: string | undefined,
}

export default function CopyButton({
  value,
}: CopyButtonProps) {
  const { t } = useTranslation('emails');
  const [copiedMessageActive, setCopiedMessageActive] = useState<boolean>(false);
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const tooltipOpenResult = useMemo(() => tooltipOpen || copiedMessageActive, [copiedMessageActive, tooltipOpen]);
  const onOpen = useCallback(() => setTooltipOpen(true), []);
  const onClose = useCallback(() => setTooltipOpen(false), []);
  const tooltipText = useMemo(() => {
    if (copiedMessageActive) return t('copy-button_copy-button-feedback', 'Copied!');
    return t('copy-button_title', 'Copy to clipboard');
  }, [copiedMessageActive, t]);
  const onClick = useCallback(() => {
    if (!value) return;
    navigator.clipboard.writeText(value);
    setCopiedMessageActive(true);
    setTimeout(() => setCopiedMessageActive(false), 2500);
  }, [value]);
  return (
    <Tooltip title={tooltipText} open={tooltipOpenResult} onOpen={onOpen} onClose={onClose}>
      <Button sx={{ minWidth: 'unset', px: 1 }} variant="contained" color="secondary" onClick={onClick}>
        <Icon path={mdiContentCopy} size={0.75} />
      </Button>
    </Tooltip>
  );
}
