import React, { useMemo } from 'react';
import classNames from 'classnames';

interface LoaderProps {
  isLoading: boolean;
  isSmall?: boolean;
}

export default function Loader({ isLoading, isSmall }: LoaderProps) {
  const className = useMemo(() => classNames(
    'loader loader-spin',
    isSmall && 'loader--small',
    isLoading && 'loader--active',
  ), [isSmall, isLoading]);

  return (
    <div className={className} />
  );
}

Loader.defaultProps = {
  isSmall: false,
};
