import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Box, CircularProgress, Snackbar, useTheme } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import LabelChip from 'labels/components/LabelChip';
import LabelDto from 'labels/types/LabelDto';
import { useDrop } from 'react-dnd';
import useIssuesSelectionContext from 'issues/hooks/useIssuesSelectionContext';
import useIssuesBoardDragAndDropContext from 'issues/hooks/useIssuesBoardDragAndDropContext';
import DraggableIssueItemCard from 'issues/components/DraggableIssueItemCard';
import useIssueUpdateMutation from 'issues/hooks/useIssueUpdateMutation';
import IssueDto from 'issues/types/IssueDto';
import useIssueItemClickHandler from 'issues/hooks/useIssueItemClickHandler';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import useIssuesOdataInfiniteQuery from 'issues/hooks/useIssuesOdataInfiniteQuery';
import useIssuesFilterContext from 'issues/hooks/useIssuesFilterContext';
import useClosedIssueStatus from 'issues/hooks/useClosedIssueStatus';
import useGetIssueStatusEditingRoleRight from 'issues/hooks/useGetIssueStatusEditingRoleRight';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';
import RoleAction from 'projects/types/RoleAction';

interface IssuesBoardColumnProps extends ISxProps {
  status: LabelDto,
}

export default function IssuesBoardColumn({
  status,
  sx,
}: IssuesBoardColumnProps) {
  const theme = useTheme();
  const closedIssueStatus = useClosedIssueStatus();
  const getIssueStatusEditingRoleRight = useGetIssueStatusEditingRoleRight();
  const isClosedColumn = status.id === closedIssueStatus?.id;
  const allowedActions = useAllowedActions();
  const { selectedIssueIds } = useIssuesSelectionContext();
  const selectedIssueIdsSet = useMemo(() => new Set(selectedIssueIds), [selectedIssueIds]);
  const { setTemporaryStatusId, draggedIssueId, statusLabelIdByissueIdTempOverrideMap } = useIssuesBoardDragAndDropContext();
  const { odataQuery } = useIssuesFilterContext();
  const { data: statusIssuesInfiniteData, isFetchingNextPage, fetchNextPage } = useIssuesOdataInfiniteQuery(
    { ...odataQuery, filter: { ...(Array.isArray(odataQuery?.filter) ? { and: odataQuery.filter } : typeof odataQuery?.filter !== 'string' ? odataQuery?.filter : {}), issueStatus: status.id }, orderBy: 'editDate desc' },
  );
  const { data: draggedIssue } = useIssueQuery(draggedIssueId, { placeholderData: (previousData) => previousData });

  const columnIssues = useMemo(() => {
    if (!statusIssuesInfiniteData) return undefined;
    const statusIssues = statusIssuesInfiniteData.pages.filter(Boolean).flatMap((page) => page).map((issue) => issue!);
    if (draggedIssueId && draggedIssue) {
      if (draggedIssue.issueStatus !== status.id && statusLabelIdByissueIdTempOverrideMap.get(draggedIssueId) === status.id) {
        return [draggedIssue, ...statusIssues];
      }
    }
    return statusIssues;
  }, [statusIssuesInfiniteData, draggedIssue, status.id, statusLabelIdByissueIdTempOverrideMap, draggedIssueId]);

  const { mutateAsync, isPending: isMutationPending } = useIssueUpdateMutation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const getRequestErrorMessage = useRequestErrorMessage();
  const onClickIssueItem = useIssueItemClickHandler();
  const onClickIssueItemCard = useCallback(async (issueId: string, ctrlKey?: boolean | undefined) => onClickIssueItem(issueId, ctrlKey), [onClickIssueItem]);
  const canDrop = useCallback((issue: IssueDto) => {
    if (!allowedActions) return false;
    return (getIssueStatusEditingRoleRight(issue) ?? false) && (!isClosedColumn || allowedActions.has(RoleAction.IssueManagement_StatusEditing_SetClosed));
  }, [allowedActions, getIssueStatusEditingRoleRight, isClosedColumn]);

  const [, drop] = useDrop<IssueDto>(() => ({
    accept: 'IssueItemCard',
    canDrop,
    drop: async ({ id, issueStatus } : IssueDto) => {
      try {
        if (issueStatus !== status.id) {
          setTemporaryStatusId(id, status.id);
          await mutateAsync({
            id,
            status: { value: status.id },
          });
        }
      } catch (error) {
        setErrorMessage(getRequestErrorMessage(error));
      }
    },
    hover: async (issue : IssueDto) => {
      if (canDrop(issue)) {
        setTemporaryStatusId(issue.id, status.id);
      } else {
        setTemporaryStatusId(issue.id, undefined);
      }
    },
  }), [setTemporaryStatusId, status.id, isMutationPending, mutateAsync, canDrop]);

  const observerTarget = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        fetchNextPage();
      }
    }, { threshold: 1 });
    const target = observerTarget.current;
    if (target) {
      observer.observe(target);
    }
    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, [fetchNextPage, observerTarget]);

  return (
    <Box
      id="IssuesBoardColumn"
      sx={{ display: 'grid', gridTemplateRows: 'auto 1fr', gap: 1, ...sx }}
      key={status.id}
      ref={drop}
    >
      <LabelChip label={status} sx={{ maxWidth: '100%' }} />
      <Box sx={{
        background: theme.palette.grey[200],
        p: 2,
        borderRadius: '6px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        gap: 2,
        maxWidth: '100%',
        overflow: 'hidden',
        boxShadow: 'inset 0px 0px 8px -4px rgba(0,0,0,0.2)',
      }}
      >
        {!!columnIssues && columnIssues.map((issue) => (
          <DraggableIssueItemCard
            key={issue.id}
            issue={issue}
            onClick={onClickIssueItemCard}
            selected={selectedIssueIdsSet.has(issue.id)}
            isDragSource={issue.id === draggedIssueId && issue.issueStatus === status.id}
          />
        ))}
        <Box ref={observerTarget} />
        {isFetchingNextPage && <Box sx={{ display: 'flex', justifyContent: 'center' }}><CircularProgress size={24} /></Box>}
      </Box>
      {!!errorMessage && (
        <Snackbar open autoHideDuration={6000} onClose={onCloseErrorMessage}><Alert severity="error" onClose={onCloseErrorMessage}>{errorMessage}</Alert></Snackbar>
      )}
    </Box>
  );
}
