import { QueryKey } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDetailsQuery, { DetailsUseQueryArgs } from 'api/hooks/useDetailsQuery';
import { DetailsByIdQueryKey } from 'api/hooks/useQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import { useCallback } from 'react';

export default function useResourceQuery(...[argId, options]: DetailsUseQueryArgs<ApiEndpoint.Resource, string>) {
  const axiosInstance = useAxiosInstance();
  const resourceDetailsByIdQueryFn = useCallback(async ({ queryKey }: { queryKey: QueryKey }) => {
    const [, , , id] = queryKey as DetailsByIdQueryKey<ApiEndpoint.Resource>;
    const url = `${ApiEndpoint.Resource}/${id}`;
    const response = await axiosInstance.get<Blob>(url, { responseType: 'blob' });
    const href = URL.createObjectURL(response.data);
    return href;
  }, [axiosInstance]);
  return useDetailsQuery(
    ApiEndpoint.Resource,
    argId,
    {
      ...options,
      queryFn: resourceDetailsByIdQueryFn,
    },
  );
}
