import { useMutation } from '@tanstack/react-query';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDocumentVersionUpdateMutationFn from 'documents/hooks/useDocumentVersionUpdateMutationFn';
import DocumentVersionDto from 'documents/types/DocumentVersionDto';
import DocumentVersionTagsUpdateDto from 'documents/types/DocumentVersionTagsUpdateDto';

export default function useDocumentVersionTagsUpdateMutation() {
  const mutationFn = useDocumentVersionUpdateMutationFn<DocumentVersionTagsUpdateDto>(ApiEndpoint.DocumentVersionTags);
  return useMutation<DocumentVersionDto, unknown, DocumentVersionTagsUpdateDto>({ mutationFn });
}
