import { ComponentsProps, ComponentsVariants } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

interface MuiTabComponent {
  defaultProps?: ComponentsProps['MuiTab'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTab'];
  variants?: ComponentsVariants['MuiTab'];
}

const VisoTab: MuiTabComponent = {
  styleOverrides: {
    root: ({ theme }) => ({
      textTransform: 'unset',
      minHeight: '24px',
      color: theme.palette.text.primary,
      fontWeight: 400,
      '&.Mui-selected': {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.text.primary,
        fontWeight: 600,
      },
    }),
  },
};

export default VisoTab;
