import useToday from 'common/hooks/useToday';
import useClosedIssueStatus from 'issues/hooks/useClosedIssueStatus';
import useResolvedIssueStatus from 'issues/hooks/useResolvedIssueStatus';
import IssueDto from 'issues/types/IssueDto';
import { useMemo } from 'react';

export default function useIssueOverdueCheck(issue: IssueDto | undefined) {
  const today = useToday();
  const closedIssueStatus = useClosedIssueStatus();
  const resolvedIssueStatus = useResolvedIssueStatus();
  const isCurrent = useMemo(() => !!issue?.issueStatus && closedIssueStatus?.id !== issue.issueStatus && resolvedIssueStatus?.id !== issue.issueStatus, [closedIssueStatus?.id, issue?.issueStatus, resolvedIssueStatus?.id]);
  const dueDay = useMemo(() => {
    if (!issue?.dueDate) return undefined;
    const parsedDueDate = new Date(issue.dueDate);
    return new Date(parsedDueDate.getFullYear(), parsedDueDate.getMonth(), parsedDueDate.getDate());
  }, [issue]);
  return useMemo(() => {
    if (!dueDay || !today) return undefined;
    return isCurrent && dueDay < today;
  }, [dueDay, isCurrent, today]);
}
