import { AmbientLight, Color, DirectionalLight, Object3D, Scene } from "three";
import { IfcComponent, ViewerOptions } from "../../base-types";
import { IFCModel } from "../../IFCModel";
import { IfcContext } from "./context";

export class IfcScene extends IfcComponent {
  scene: Scene;
  defaultBackgroundColor = new Color(0xf2f3f5);

  constructor(private context: IfcContext) {
    super(context);
    this.scene = new Scene();

    this.setupScene(context.options);
    this.setupLights();
  }

  add(item: Object3D) {
    this.scene.add(item);
  }

  remove(item: Object3D) {
    this.scene.remove(item);
  }

  removeModel(model: IFCModel) {
    let index = this.context.items.ifcModels.indexOf(model);
    if (index >= 0) this.context.items.ifcModels.splice(index, 1);
    if (model.solid != null) {
      index = this.context.items.pickableIfcModels.indexOf(model.solid);
      if (index >= 0) this.context.items.pickableIfcModels.splice(index, 1);
    }

    if (model.parent) model.removeFromParent();
  }

  setBackground(color: string) {
    this.scene.background = new Color(color);
  }

  private setupScene(options?: ViewerOptions) {
    this.scene.background =
      options?.backgroundColor || this.defaultBackgroundColor;
  }

  private setupLights() {
    const light1 = new DirectionalLight(0xffffe6, 0.7);
    light1.position.set(0.8, -0.5, -0.5);
    this.scene.add(light1);

    const light2 = new DirectionalLight(0xffffe6, 0.9);
    light2.position.set(-0.8, -1.0, 0.5);
    this.scene.add(light2);

    const ambientLight = new AmbientLight(0xffffff, 0.2);
    this.scene.add(ambientLight);
  }
}
