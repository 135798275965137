import type { Theme, ComponentsProps, ComponentsVariants } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

interface MuiRadioComponent {
  defaultProps?: ComponentsProps['MuiRadio'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiRadio'];
  variants?: ComponentsVariants['MuiRadio'];
}

const VisoRadio: MuiRadioComponent = {
  styleOverrides: {
    root: () => ({
      padding: '2px 2px',
      margin: '0 7px',
    }),
  },
};

export default VisoRadio;
