import React, { useMemo, useState } from 'react';
import { Menu, MenuItem, Box, FormControl, InputLabel, useTheme } from '@mui/material';
import { mdiEyeOff } from '@mdi/js';
import _ from 'lodash';
import useIssuesOdataQuery from 'issues/hooks/useIssuesOdataQuery';
import ISxProps from 'common/types/ISxProps';
import IssueStatusMultiselect from 'issues/components/IssueStatusMultiselect';
import { useTranslation } from 'react-i18next';
import { QueryOptions } from 'odata-query';
import IssueDto from 'issues/types/IssueDto';
import IconChip from 'labels/components/IconChip';
import useProjectIssueStatusesQuery from 'issues/hooks/useProjectIssueStatusesQuery';
import LabelChip from 'labels/components/LabelChip';

interface IssuesFilterableListMenuProps extends ISxProps {
  anchorEl: Element | ((element: Element) => Element) | null | undefined,
  open: boolean,
  onClose: () => void,
  issuesOdataQuery: Partial<QueryOptions<IssueDto>> | undefined,
  totalIssueCount: number | undefined,
  onClickItem: (issueId: string) => void,
  disabled?: boolean,
}

export default function IssuesFilterableListMenu({
  sx,
  anchorEl,
  open,
  onClose,
  issuesOdataQuery,
  totalIssueCount,
  disabled,
  onClickItem,
}: IssuesFilterableListMenuProps) {
  const theme = useTheme();
  const { t } = useTranslation('issues');
  const { data: issues } = useIssuesOdataQuery(issuesOdataQuery);
  const { data: statuses } = useProjectIssueStatusesQuery();
  const [statusFilterLabelIds, setStatusFilterLabelIds] = useState<string[]>([]);

  const issuesFilteredByStatus = useMemo(() => {
    if (!issues || !statusFilterLabelIds.length) return issues;
    const statusFilterLabelIdsSet = new Set(statusFilterLabelIds);
    return issues.filter((i) => !!i.issueStatus && statusFilterLabelIdsSet.has(i.issueStatus));
  }, [statusFilterLabelIds, issues]);

  const items = useMemo(() => {
    if (!issuesFilteredByStatus || !statuses) return undefined;
    const issueStatusesById = new Map(statuses.map((status) => [status.id, status]));
    return issuesFilteredByStatus.filter((issue) => !!issue.issueStatus && issueStatusesById.has(issue.issueStatus)).map((issue) => ({
      issue,
      statusLabel: issueStatusesById.get(issue.issueStatus!)!,
    }));
  }, [issuesFilteredByStatus, statuses]);

  const inaccessibleIssuesCount = useMemo(() => (statusFilterLabelIds.length === 0 && totalIssueCount !== undefined && !!issuesFilteredByStatus ? Math.max(totalIssueCount - issuesFilteredByStatus.length, 0) : undefined), [statusFilterLabelIds.length, issuesFilteredByStatus, totalIssueCount]);

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={onClose} sx={sx} PaperProps={{ sx }}>
      <Box sx={{ p: 1 }}>
        <FormControl fullWidth>
          <InputLabel>{t('issues-filterable-list-menu_filter-input-label', 'Filter by status')}</InputLabel>
          <IssueStatusMultiselect value={statusFilterLabelIds} onChange={setStatusFilterLabelIds} disabled={disabled} label={t('issues-filterable-list-menu_filter-input-label', 'Filter by status')} />
        </FormControl>
      </Box>
      {!!items && items.map(({ issue, statusLabel }) => (
        <MenuItem key={issue.id} onClick={() => onClickItem(issue.id)} sx={{ display: 'flex', gap: 1 }}>
          <Box sx={{ borderRadius: '4px', backgroundColor: theme.palette.grey[700], color: theme.palette.background.default, fontWeight: 600, px: '4px', textAlign: 'center' }}>{issue.issueNumber}</Box>
          <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {issue.title}
          </Box>
          <LabelChip label={statusLabel} sx={{ ml: 'auto' }} />
        </MenuItem>
      ))}
      {_.range(0, inaccessibleIssuesCount).map((i) => (
        <MenuItem key={`inaccessible_${i}`} disabled sx={{ display: 'flex', gap: 1 }}>
          <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontStyle: 'italic', color: theme.palette.grey[700] }}>
            {t('issues-filterable-list-menu_inaccessible-issue-placeholder', 'Inaccessbile issue')}
          </Box>
          <IconChip mdiIconPath={mdiEyeOff} sx={{ ml: 'auto' }} />
        </MenuItem>
      ))}
    </Menu>
  );
}
