import { TOGGLE_UPLOAD_ISSUE } from "./ActionTypes";

const uploadIssue = (state = [], action) => {
  switch (action.type) {
    case TOGGLE_UPLOAD_ISSUE:
      return { ...state, upload_issue: action.payload };

    default:
      return state;
  }
};

export default uploadIssue;
