import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export default function CollaboratorsIcon({
  htmlColor,
  fontSize,
}: Pick<SvgIconProps, 'htmlColor' | 'fontSize'>) {
  return (
    <SvgIcon htmlColor={htmlColor} fontSize={fontSize} viewBox="0 0 16 16">
      <path d="M7.63639 8C8.74751 8 9.69195 7.61111 10.4697 6.83333C11.2475 6.05555 11.6364 5.11112 11.6364 4C11.6364 2.88888 11.2475 1.94445 10.4697 1.16667C9.69195 0.388885 8.74751 0 7.63639 0C6.52528 0 5.58084 0.388885 4.80306 1.16667C4.02528 1.94445 3.63639 2.88888 3.63639 4C3.63639 5.11112 4.02528 6.05555 4.80306 6.83333C5.58084 7.61111 6.52528 8 7.63639 8ZM8 10.1816C6.33333 10.1816 4.58334 10.5453 2.75 11.2725C0.916657 11.9729 0 12.9022 0 14.0604V15.9998H16V14.0604C16 12.9022 15.0833 11.9662 13.25 11.2523C11.4167 10.5385 9.66668 10.1816 8 10.1816Z" />
    </SvgIcon>
  );
}
