import { useQueryClient } from '@tanstack/react-query';
import { QueryOptions } from 'odata-query';
import { useCallback } from 'react';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';

export default function useOdataQueryData<TEndpoint extends ApiEndpoint, TDto>(endpoint: TEndpoint) {
  const queryClient = useQueryClient();
  const { getOdataQueryKey } = useDefaultEntityQueryKeys(endpoint);
  return useCallback(async (odataQuery: Partial<QueryOptions<TDto>>) => {
    const queryKey = getOdataQueryKey(odataQuery);
    return await queryClient.fetchQuery<TDto[]>({ queryKey });
  }, [getOdataQueryKey, queryClient]);
}
