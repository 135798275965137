import React, { useContext, useRef } from 'react';
import {
  Box, Link, SxProps, Theme, Typography, useTheme,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import DocumentUploadContext, { DocumentUploadContextState } from 'upload/contexts/DocumentUploadContext';
import UploadStatusDisplay from 'upload/components/UploadStatusDisplay';
import useDocumentUploadDropRef from 'upload/hooks/useDocumentUploadDropRef';
import useCurrentProjectId from 'projects/hooks/useCurrentProjectId';
import FileUploadIcon from '@mui/icons-material/FileUpload';

interface DocumentDropTargetProps {
  sx?: SxProps<Theme>,
  folderId: string,
}

export default function DocumentDropTarget({
  sx,
  folderId,
}: DocumentDropTargetProps) {
  const { t } = useTranslation('upload');
  const inputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const projectId = useCurrentProjectId();
  const {
    uploadingFileHandles,
    openWizard,
    addToDocumentsStagedForUpload,
  } = useContext<DocumentUploadContextState>(DocumentUploadContext);

  // handle files from the file-dialog after clicking on the "upload as" link
  const stageFilesForUpload = (files: File[] | null) => {
    if (!files?.length) return;
    if (!projectId) return;
    addToDocumentsStagedForUpload({
      files,
      folderId,
    });
    openWizard();
  };

  // handle files from drag-and-drop
  const { canDropDocuments, documentUploadDropRef } = useDocumentUploadDropRef(folderId);

  return (
    <Box
      id="DocumentDropTarget"
      ref={documentUploadDropRef}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        borderWidth: '1px',
        borderStyle: 'dashed',
        height: 48,
        borderColor: canDropDocuments ? theme.palette.primary.main : theme.palette.grey[400],
        backgroundColor: canDropDocuments ? theme.palette.secondary.light : undefined,
        ...sx,
      }}
    >
      {uploadingFileHandles.length
        ? <UploadStatusDisplay sx={{ flexGrow: 1 }} />
        : (
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              alignSelf: 'stretch',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FileUploadIcon sx={{ color: canDropDocuments ? theme.palette.primary.main : theme.palette.text.primary, mr: 1 }} />
            <Typography>
              <Trans i18nKey="drop-target_prompt" t={t}>
                {' '}
                <b>Drag & Drop</b>
                {' files to '}
                <Link onClick={() => inputRef.current?.showPicker()}>upload documents</Link>
              </Trans>
              <input ref={inputRef} hidden accept="*" multiple type="file" onChange={(e) => stageFilesForUpload(e.target?.files ? Array.from(e.target.files) : null)} />
            </Typography>
          </Box>
        )}
    </Box>
  );
}

DocumentDropTarget.defaultProps = {
  sx: undefined,
};
