import React, { useCallback, useMemo, useState } from 'react';
import { Box, styled, useTheme } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import { useTranslation } from 'react-i18next';
import MainMenuItem from 'projects/components/MainMenuItem';
import { useLocation } from 'react-router-dom';
import DashboardIcon from 'img/components/DashboardIcon';
import DocumentsIcon from 'img/components/DocumentsIcon';
import useCurrentProjectId from 'projects/hooks/useCurrentProjectId';
import IssuesIcon from 'img/components/IssuesIcon';
import EmailsIcon from 'img/components/EmailsIcon';
import CollaboratorsIcon from 'img/components/CollaboratorsIcon';
import SettingsIcon from 'img/components/SettingsIcon';
import CollapseHorizontalIcon from 'img/components/CollapseHorizontalIcon';
import ExpandHorizontalIcon from 'img/components/ExpandHorizontalIcon';
import useLegacySettingsNotificationDialogContext from 'settings/hooks/useLegacySettingsNotificationDialogContext';
import useCurrentCollaboratorRole from 'collaborators/hooks/useCurrentCollaboratorRole';
import RoleAction from 'projects/types/RoleAction';
import useVisibleSettingsTabs from 'settings/hooks/useVisibleSettingsTabs';
import ModelsMainMenuIcon from 'img/components/ModelsMainMenuIcon';

const WidthTransitionBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: 60,
  '&.expanded': {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: 250,
  },
}));

const OpcacityTransitionBox = styled(Box)(({ theme }) => ({
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  opacity: 0,
  '&.expanded': {
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.enteringScreen,
    }),
    opacity: 1,
  },
}));

const OffsetTransitionBox = styled(Box)(({ theme }) => ({
  transition: theme.transitions.create('padding-left', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  paddingLeft: 0,
  '&.expanded': {
    transition: theme.transitions.create('padding-left', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    paddingLeft: 32,
  },
}));

interface MainMenuProps extends ISxProps {
}

enum MainMenuDestination {
  Dashboard = 'dashboard',
  Documents = 'documents',
  Issues = 'issues',
  Emails = 'emails',
  Models = 'models',
  Collaborators = 'collaborators',
  Settings = 'settings',
}

const pathnameRegex = /\/projects\/[^/]+\/([^/]+)/;

export default function MainMenu({
  sx,
}: MainMenuProps) {
  const theme = useTheme();
  const { t } = useTranslation('projects');
  const currentUserRole = useCurrentCollaboratorRole();
  const visibleSettingsTabs = useVisibleSettingsTabs();
  const [expanded, setExpanded] = useState(false);
  const onClickToggleExpanded = useCallback(() => setExpanded((prev) => !prev), []);
  const projectId = useCurrentProjectId();
  const location = useLocation();
  const activeDestination = useMemo(() => {
    const pathnameMatch = location.pathname.match(pathnameRegex);
    if (pathnameMatch && pathnameMatch.length > 0) {
      return pathnameMatch[1] as MainMenuDestination;
    }
    return undefined;
  }, [location]);

  const { navigateWithConfirmation } = useLegacySettingsNotificationDialogContext();

  const onClickDashboardItem = useCallback(() => {
    navigateWithConfirmation(`/projects/${projectId}/dashboard`);
  }, [navigateWithConfirmation, projectId]);

  const onClickDocumentsItem = useCallback(() => {
    navigateWithConfirmation(`/projects/${projectId}/documents`);
  }, [navigateWithConfirmation, projectId]);

  const onClickIssuesItem = useCallback(() => {
    navigateWithConfirmation(`/projects/${projectId}/issues`);
  }, [navigateWithConfirmation, projectId]);

  const onClickEmailsItem = useCallback(() => {
    navigateWithConfirmation(`/projects/${projectId}/emails`);
  }, [navigateWithConfirmation, projectId]);

  const onClickModelsItem = useCallback(() => {
    navigateWithConfirmation(`/projects/${projectId}/models`);
  }, [navigateWithConfirmation, projectId]);

  const onClickCollaboratorsItem = useCallback(() => {
    navigateWithConfirmation(`/projects/${projectId}/collaborators`);
  }, [navigateWithConfirmation, projectId]);

  const onClickSettingsItem = useCallback(() => {
    navigateWithConfirmation(`/projects/${projectId}/settings`);
  }, [navigateWithConfirmation, projectId]);

  return (
    <WidthTransitionBox
      id="MainMenu"
      sx={{
        ...sx,
        borderRightColor: theme.palette.divider,
        borderRightStyle: 'solid',
        borderRightWidth: 1,
        position: 'relative',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
      className={expanded ? 'expanded' : undefined}
    >
      <OffsetTransitionBox
        sx={{
          borderBottomColor: theme.palette.divider,
          borderBottomStyle: 'solid',
          borderBottomWidth: 1,
          height: 56,
          pt: '4px',
          px: '11px',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          flexShrink: 0,
        }}
        className={expanded ? 'expanded' : undefined}
      >
        <Box>
          <img src="/img/visoplan-logo_graphic.svg" alt="Visoplan Logo Graphics" style={{ height: '21px' }} />
        </Box>
        <OpcacityTransitionBox className={expanded ? 'expanded' : undefined}>
          <img src="/img/visoplan-logo_text.svg" alt="Visoplan" style={{ height: '21px' }} />
        </OpcacityTransitionBox>
      </OffsetTransitionBox>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', flexGrow: 1 }}>
        <Box sx={{ height: 60, borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: theme.palette.divider, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <MainMenuItem
            label={expanded ? t('main-menu_item-collapse-menu', 'Collapse Menu') : t('main-menu_item-expand-menu', 'Expand Menu')}
            onClick={onClickToggleExpanded}
            Icon={expanded ? CollapseHorizontalIcon : ExpandHorizontalIcon}
            expanded={expanded}
            active={false}
          />
        </Box>
        {!!currentUserRole?.allowedActions?.has(RoleAction.Dashboard) && (
          <MainMenuItem
            label={t('main-menu_item-dashboard', 'Dashboard')}
            onClick={onClickDashboardItem}
            Icon={DashboardIcon}
            expanded={expanded}
            active={activeDestination === MainMenuDestination.Dashboard}
          />
        )}
        {!!currentUserRole?.allowedActions?.has(RoleAction.Documents) && (
          <MainMenuItem
            label={t('main-menu_item-documents', 'Documents')}
            onClick={onClickDocumentsItem}
            Icon={DocumentsIcon}
            expanded={expanded}
            active={activeDestination === MainMenuDestination.Documents}
          />
        )}
        {!!currentUserRole?.allowedActions?.has(RoleAction.Issues) && (
          <MainMenuItem
            label={t('main-menu_item-issues', 'Issues')}
            onClick={onClickIssuesItem}
            Icon={IssuesIcon}
            expanded={expanded}
            active={activeDestination === MainMenuDestination.Issues}
          />
        )}
        {!!currentUserRole?.allowedActions?.has(RoleAction.Email_View) && (
          <MainMenuItem
            label={t('main-menu_item-emails', 'Emails')}
            onClick={onClickEmailsItem}
            Icon={EmailsIcon}
            expanded={expanded}
            active={activeDestination === MainMenuDestination.Emails}
          />
        )}
        {!!currentUserRole?.allowedActions?.has(RoleAction._3DModel) && (
          <MainMenuItem
            label={t('main-menu_item-models', 'Models')}
            onClick={onClickModelsItem}
            Icon={ModelsMainMenuIcon}
            expanded={expanded}
            active={activeDestination === MainMenuDestination.Models}
          />
        )}
        {!!currentUserRole?.allowedActions?.has(RoleAction.Project_CollaboratorManagement) && (
          <MainMenuItem
            label={t('main-menu_item-collaborators', 'Collaborators')}
            onClick={onClickCollaboratorsItem}
            Icon={CollaboratorsIcon}
            expanded={expanded}
            active={activeDestination === MainMenuDestination.Collaborators}
          />
        )}
        {!!currentUserRole?.allowedActions?.has(RoleAction.Project_Management) && !!visibleSettingsTabs?.size && (
          <MainMenuItem
            label={t('main-menu_item-settings', 'Settings')}
            onClick={onClickSettingsItem}
            Icon={SettingsIcon}
            expanded={expanded}
            active={activeDestination === MainMenuDestination.Settings}
            sx={{ mt: 'auto', mb: 4 }}
          />
        )}
      </Box>
    </WidthTransitionBox>
  );
}
