import React, { useMemo } from 'react';
import { Box, ButtonBase, useTheme } from '@mui/material';
import Icon from '@mdi/react';
import ISxProps from 'common/types/ISxProps';
import InboxEmailDto from 'emails/types/InboxEmailDto';
import { mdiEmail, mdiLinkOff } from '@mdi/js';

interface LinkedEmailPanelItemProps extends ISxProps {
  email: InboxEmailDto,
  onClick?: ((issueId: string) => void) | undefined,
  onClickRemove?: ((issueId: string) => void) | undefined,
  disabled?: boolean | undefined,
}

export default function LinkedEmailPanelItem({
  sx,
  email,
  onClick,
  onClickRemove,
  disabled,
}: LinkedEmailPanelItemProps) {
  const theme = useTheme();
  const onClickButton = useMemo(() => (onClick ? () => onClick(email.id) : undefined), [email, onClick]);
  const onClickRemoveButton = useMemo(() => (onClickRemove ? () => onClickRemove(email.id) : undefined), [email, onClickRemove]);
  return (
    <Box
      id="LinkedEmailPanelItem"
      sx={{
        ...sx,
        display: 'flex',
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: theme.palette.divider,
      }}
    >
      <ButtonBase
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'stretch',
          textAlign: 'left',
          p: 1,
          pl: 2,
          gap: 1,
          '&:hover': {
            backgroundColor: theme.palette.grey[200],
          },
          overflow: 'hidden',
        }}
        onClick={onClickButton}
        disabled={!onClickButton}
        title={email.subject}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Icon path={mdiEmail} size={0.75} color={theme.palette.grey[700]} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 1,
            flexGrow: 1,
            pointerEvents: 'none',
            fontSize: 14,
            overflow: 'hidden',
          }}
        >
          <Box sx={{ whiteSpace: 'nowrap', flexGrow: 0, flexShrink: 0, textOverflow: 'ellipsis', overflow: 'hidden' }}>{email.subject}</Box>
        </Box>
      </ButtonBase>
      {!!onClickRemove && (
        <ButtonBase
          sx={{
            ...sx,
            display: 'flex',
            alignItems: 'stretch',
            textAlign: 'left',
            p: 0.5,
            gap: 1,
            '&:hover': {
              backgroundColor: theme.palette.grey[200],
            },
          }}
          onClick={onClickRemoveButton}
          disabled={!onClickRemoveButton || disabled}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Icon path={mdiLinkOff} size={0.75} color={theme.palette.grey[700]} />
          </Box>
        </ButtonBase>
      )}
    </Box>
  );
}
