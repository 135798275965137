import React, { useMemo } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import DocumentsDataGridRow from 'documents/types/DocumentDataGridRow';
import FolderBreadcrumbs from 'documents-folders/components/FolderBreadcrumbs';
import { Box } from '@mui/material';
import useFolderIconName from 'documents-folders/hooks/useFolderIconName';
import useFolder from 'documents-folders/hooks/useFolder';

export default function FolderCellRenderer({
  value,
}: ICellRendererParams<DocumentsDataGridRow, string>) {
  const folderId = useMemo(() => value ?? undefined, [value]);
  const folder = useFolder(folderId);
  const folderIcon = useFolderIconName(folder?.accessType);
  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={{
        width: '24px', height: '24px', float: 'left', mt: '6px',
      }}
      >
        {folderIcon && (
          <svg viewBox="0 -6 32 32">
            <use xlinkHref={`/img/sprite.svg#${folderIcon}`} />
          </svg>
        )}
      </Box>
      <FolderBreadcrumbs reverse folderId={folderId} isLeafFolderClickable sx={{ flexGrow: 1, flexShrink: 0, height: '100%' }} />
    </Box>
  );
}
