import React, { useCallback, useMemo, useState } from 'react';
import { Box, Divider, FormControlLabel, FormGroup, Typography, Checkbox, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InboxEmailAttachmentDto from 'emails/types/InboxEmailAttachmentDto';
import DOMPurify from 'dompurify';
import { useParams } from 'react-router-dom';
import useEmailsQuery from 'emails/hooks/useEmailsQuery';
import EmailAttachmentItem from './EmailAttachmentItem';
import UploadEmailAttachmentsModal from './UploadEmailAttachmentsModal';

export default function EmailsDetailView() {
  const { t } = useTranslation('emails');

  const { data: emails } = useEmailsQuery();

  const { emailId } = useParams<'emailId'>();

  const email = useMemo(() => emails?.find((e) => e.id === emailId), [emailId, emails]);

  const [showUploadEmailAttachmentsModal, setShowUploadEmailAttachmentsModal] = useState<boolean>(false);
  const [selectedAttachments, setSelectedAttachments] = useState<InboxEmailAttachmentDto[]>([]);

  const onCloseEmailAttachmentsModal = useCallback(() => {
    setShowUploadEmailAttachmentsModal(false);
  }, []);

  const handleAttachmentSelection = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const attachmentId = event.target.value;
    if (event.target.checked) {
      const foundAttachment = email?.attachments.find((attachment) => attachment.id === attachmentId);
      if (foundAttachment) {
        setSelectedAttachments((currentSelectedAttachments) => [...currentSelectedAttachments, foundAttachment]);
      }
    } else {
      setSelectedAttachments((currentSelectedAttachments) => currentSelectedAttachments.filter((attachment) => attachment.id !== attachmentId));
    }
  }, [email]);

  const handleAddToDocuments = useCallback(() => {
    setShowUploadEmailAttachmentsModal(true);
  }, []);

  const sanitizedHtml = useMemo(() => (email ? DOMPurify.sanitize(email.emailBody) : ''), [email]);

  return (
    email ? (
      <Box
        id="EmailsDetailView"
        sx={{
          height: '90vh',
          display: 'flex',
          flexDirection: 'column',
          p: 4,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ maxWidth: '50%', display: 'flex' }}>
              <strong>
                {t('emails-detail-view_sent-from-label', 'From:')}
              &nbsp;
              </strong>
              {email.from}
            </Box>
            <Box sx={{ maxWidth: '50%' }}>
              <strong>
                {t('emails-detail-view_sent-to-label', 'To:')}
              &nbsp;
              </strong>
              {email.to.map((recipient) => recipient).join(', ')}
            </Box>
          </Box>
          <Divider />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
            <Box pr={4}>
              <Typography variant="h1">{email.subject}</Typography>
            </Box>
            <Box sx={{ minWidth: 'fit-content' }}>
              {new Date(email.creationDate).toLocaleString()}
            </Box>
          </Box>
          <Box sx={{ overflow: 'auto', flex: '1 1 auto' }}>
            <article
              dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
            />
          </Box>
          {email.attachments.length > 0 && (
          <>
            <Divider />
            <Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <strong>
                  {t('emails-detail-view_attachments-label', 'Attachments:')}
                &nbsp;
                </strong>
                <Button disabled={selectedAttachments.length === 0} variant="contained" onClick={handleAddToDocuments}>{t('emails-detail-view_attachments-button-label', 'Add to documents')}</Button>
              </Box>
              <FormGroup sx={{ display: 'flex', flexDirection: 'row', overflow: 'scroll', flexWrap: 'nowrap' }}>
                {email.attachments.map((attachment) => (
                  <FormControlLabel control={<Checkbox onChange={handleAttachmentSelection} />} value={attachment.id} label={<EmailAttachmentItem attachment={attachment} />} />
                ))}
              </FormGroup>
            </Box>
          </>
          )}
        </Box>
        {showUploadEmailAttachmentsModal && <UploadEmailAttachmentsModal initialAttachments={selectedAttachments} emailId={email.id} onClose={onCloseEmailAttachmentsModal} />}
      </Box>
    ) : null
  );
}
