import React, { useMemo } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { Box } from '@mui/material';
import InboxEmailDto from 'emails/types/InboxEmailDto';

export default function EmailBodyCell({
  value,
}: ICellRendererParams<InboxEmailDto, string | undefined>) {
  const emailBody = useMemo(() => {
    if (!value) return undefined;
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(value, 'text/html');
    return parsedHtml.body.textContent?.trim() || '';
  }, [value]);
  return (
    <Box id="EmailBodyCell">
      {emailBody}
    </Box>
  );
}
