import type { Theme, ComponentsProps, ComponentsVariants } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { tableRowClasses } from '@mui/material/TableRow';
import { grey } from '@mui/material/colors';

interface MuiTableRowComponent {
  defaultProps?: ComponentsProps['MuiTableRow'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTableRow'];
  variants?: ComponentsVariants['MuiTableRow'];
}

const VisoTableRow: MuiTableRowComponent = {
  styleOverrides: {
    root: () => ({
      [`&.${tableRowClasses.root}:nth-of-type(odd):not(.${tableRowClasses.head})`]: {
        backgroundColor: grey[50],
      },
    }),
  },
};

export default VisoTableRow;
