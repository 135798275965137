import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Alert, Box, Button, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Typography, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useIssuesSelectionContext from 'issues/hooks/useIssuesSelectionContext';
import RoundIconButton from 'common/components/RoundIconButton';
import { useTranslation } from 'react-i18next';
import IssueDto from 'issues/types/IssueDto';
import useIssuesUpdateManyMutation from 'issues/hooks/useIssuesUpdateManyMutation';
import useProjectIssuePrioritiesQuery from 'issues/hooks/useProjectIssuePrioritiesQuery';
import useProjectIssueStatusesQuery from 'issues/hooks/useProjectIssueStatusesQuery';
import useProjectIssueTypesQuery from 'issues/hooks/useProjectIssueTypesQuery';
import useProjectBuildingsQuery from 'labels/hooks/useProjectBuildingsQuery';
import useProjectDisciplinesQuery from 'labels/hooks/useProjectDisciplinesQuery';
import useProjectFloorsQuery from 'labels/hooks/useProjectFloorsQuery';
import useProjectWorkPhasesQuery from 'labels/hooks/useProjectWorkPhasesQuery';
import TagCreateSelect from 'tags/components/TagCreateSelect';
import IssueVisibility from 'issues/types/IssueVisibility';
import useCollaboratorGroupsOdataQuery from 'collaborators-groups/hooks/useCollaboratorGroupsOdataQuery';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import ISxProps from 'common/types/ISxProps';
import IChildren from 'common/types/IChildren';
import LocalizedDatePicker from 'common/components/LocalizedDatePicker';
import moment, { Moment } from 'moment';
import AssignmentSelect from 'issues/components/AssignmentSelect';
import useCollaboratorsOdataQuery from 'collaborators/hooks/useCollaboratorsOdataQuery';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';
import RoleAction from 'projects/types/RoleAction';

enum TagUpdateMode {
  Add = 'add',
  Replace = 'replace',
}

interface PanelProps extends ISxProps, IChildren {
}

function Panel({
  sx,
  children,
}: PanelProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        borderRadius: '8px',
        boxShadow: '0px 0px 8px -4px rgba(0,0,0,0.2)',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        p: 1.5,
        pt: 1,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

type FormState = Partial<Pick<IssueDto, 'title' | 'description' | 'issueType' | 'issuePriority' | 'issueStatus' | 'tags' | 'disciplines' | 'buildings' | 'floors' | 'workPhase' | 'visibility' | 'allowedCollaboratorGroups' | 'dueDate' | 'startingDate' | 'assignedCollaboratorIds' | 'reviewerId'> & { addTags: boolean }>;

export default function IssuesMutliselectSidebar() {
  const { t } = useTranslation('issues');
  const theme = useTheme();
  const { selectedIssueIds, setSelectedIssueIds } = useIssuesSelectionContext();

  // TODO #18521: Mass Edit Assignment Workflows
  // const { odataQuery: allFilteredIssuesOdataQuery } = useIssuesFilterContext();
  // const conditionalOdataQuery = useMemo(() => (multiselectedIssueIds?.length < 300 ? { filter: { id: { in: multiselectedIssueIds } } } : allFilteredIssuesOdataQuery), [allFilteredIssuesOdataQuery, multiselectedIssueIds]);
  // const { data: issues } = useIssuesOdataQuery(conditionalOdataQuery);
  // const commonStatus = useMemo(() => {
  //   if (!issues) return undefined;
  //   const selectedIssues = issues.filter((issue) => multiselectedIssueIdsSet.has(issue.id));
  //   const statusesSet = new Set(selectedIssues.filter((issue) => issue.issueStatus).map((issue) => issue.issueStatus!));
  //   if (statusesSet.size === 1) return Array.from(statusesSet.values())[0];
  //   return undefined;
  // }, [issues, multiselectedIssueIdsSet]);

  const onClose = useCallback(async () => {
    setSelectedIssueIds([]);
  }, [setSelectedIssueIds]);

  const { mutateAsync: updateManyIssuesAsync, isPending: isLoadingMutation } = useIssuesUpdateManyMutation();
  const [formState, setFormState] = useState<FormState>({ addTags: true });
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const getRequestErrorMessage = useRequestErrorMessage();
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);

  const onConfirm = useCallback(async () => {
    try {
      if (formState && selectedIssueIds.length) {
        await updateManyIssuesAsync({
          ids: selectedIssueIds,
          timeStamp: new Date().toISOString(),
          ...(formState.issueStatus ? { status: { value: formState.issueStatus } } : {}),
          ...(formState.issueType ? { type: { value: formState.issueType } } : {}),
          ...(formState.startingDate ? { startingDate: { value: formState.startingDate } } : {}),
          ...(formState.dueDate ? { dueDate: { value: formState.dueDate } } : {}),
          ...(formState.issuePriority ? { priority: { value: formState.issuePriority } } : {}),
          ...(formState.assignedCollaboratorIds ? { assignedUserIds: { value: formState.assignedCollaboratorIds } } : {}),
          ...(formState.reviewerId ? { reviewerId: { value: formState.reviewerId } } : {}),
          ...(formState.visibility !== undefined ? { visibility: { value: formState.visibility } } : {}),
          ...(formState.allowedCollaboratorGroups && formState.visibility === IssueVisibility.Restricted ? { allowedGroups: { value: formState.allowedCollaboratorGroups }, visibility: { value: IssueVisibility.Restricted } } : {}),
          ...((formState.tags || !formState.addTags) ? { tags: { value: formState.tags ?? [] }, addTags: formState.addTags } : {}),
          ...(formState.workPhase ? { workPhase: { value: formState.workPhase } } : {}),
          ...(formState.disciplines ? { disciplines: { value: formState.disciplines } } : {}),
          ...(formState.buildings ? { buildings: { value: formState.buildings } } : {}),
          ...(formState.floors ? { floors: { value: formState.floors } } : {}),
        });
      }
      await onClose();
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [formState, getRequestErrorMessage, onClose, selectedIssueIds, updateManyIssuesAsync]);

  const allowedActions = useAllowedActions();
  const canEditStatus = useMemo(() => allowedActions?.has(RoleAction.IssueManagement_StatusEditing), [allowedActions]);
  const canClose = useMemo(() => allowedActions?.has(RoleAction.IssueManagement_StatusEditing_SetClosed), [allowedActions]);

  const { data: issueTypes } = useProjectIssueTypesQuery();
  const { data: issuePriorities } = useProjectIssuePrioritiesQuery();
  const { data: issueStatuses } = useProjectIssueStatusesQuery();
  const filteredStatuses = useMemo(() => (canClose ? issueStatuses : issueStatuses?.filter((s) => !(s.isDefault && s.originalName === 'Closed'))), [canClose, issueStatuses]);
  const { data: disciplines } = useProjectDisciplinesQuery();
  const { data: buildings } = useProjectBuildingsQuery();
  const { data: floors } = useProjectFloorsQuery();
  const { data: workPhases } = useProjectWorkPhasesQuery();
  const { data: groups } = useCollaboratorGroupsOdataQuery({ filter: { isDeleted: false } });
  const { data: collaborators } = useCollaboratorsOdataQuery({ filter: { isDeleted: false } });

  const onChangeIssueStatus = useCallback((event: SelectChangeEvent<string>) => setFormState((prev) => (prev ? { ...prev, issueStatus: event.target.value } : prev)), []);
  const onChangeIssueType = useCallback((event: SelectChangeEvent<string>) => setFormState((prev) => (prev ? { ...prev, issueType: event.target.value } : prev)), []);
  const onChangeIssuePriority = useCallback((event: SelectChangeEvent<string>) => setFormState((prev) => (prev ? { ...prev, issuePriority: event.target.value } : prev)), []);
  const onChangeTags = useCallback((tagIds: string[]) => setFormState((prev) => (prev ? { ...prev, tags: tagIds } : prev)), []);
  const onChangeTagUpdateMode = useCallback((event: ChangeEvent<HTMLInputElement>) => setFormState((prev) => (prev ? { ...prev, addTags: event.target.value === TagUpdateMode.Add } : prev)), []);
  const onChangeDisciplines = useCallback((event: SelectChangeEvent<string[]>) => setFormState((prev) => (prev && Array.isArray(event.target.value) ? { ...prev, disciplines: event.target.value } : prev)), []);
  const onChangeBuildings = useCallback((event: SelectChangeEvent<string[]>) => setFormState((prev) => (prev && Array.isArray(event.target.value) ? { ...prev, buildings: event.target.value } : prev)), []);
  const onChangeFloors = useCallback((event: SelectChangeEvent<string[]>) => setFormState((prev) => (prev && Array.isArray(event.target.value) ? { ...prev, floors: event.target.value } : prev)), []);
  const onChangeWorkPhase = useCallback((event: SelectChangeEvent<string>) => setFormState((prev) => (prev ? { ...prev, workPhase: event.target.value } : prev)), []);
  const onChangeVisibility = useCallback((event: SelectChangeEvent<string>) => setFormState((prev) => (prev ? { ...prev, visibility: parseInt(event.target.value, 10) as IssueVisibility } : prev)), []);
  const onChangeAllowedGroups = useCallback((event: SelectChangeEvent<string[]>) => setFormState((prev) => (prev && Array.isArray(event.target.value) ? { ...prev, allowedCollaboratorGroups: event.target.value } : prev)), []);
  const onChangeAssignedCollaborators = useCallback((collaboratorIds: string[]) => setFormState((prev) => (prev ? { ...prev, assignedCollaboratorIds: collaboratorIds } : prev)), []);
  const onChangeReviewerId = useCallback((event: SelectChangeEvent<string>) => setFormState((prev) => (prev ? { ...prev, reviewerId: event.target.value } : prev)), []);
  const onChangeDueDate = useCallback((momentDate: Moment | undefined) => setFormState((prev) => ({ ...prev, dueDate: momentDate?.toDate().toISOString() })), [setFormState]);
  const onChangeStartingDate = useCallback((momentDate: Moment | undefined) => setFormState((prev) => ({ ...prev, startingDate: momentDate?.toDate().toISOString() })), [setFormState]);

  const dueDateDisplayValue = useMemo(() => (formState?.dueDate ? moment(new Date(formState.dueDate)) : undefined), [formState]);
  const startingDateDisplayValue = useMemo(() => (formState?.startingDate ? moment(new Date(formState.startingDate)) : undefined), [formState]);
  const tagsDisplayValue = useMemo(() => formState?.tags ?? [], [formState?.tags]);
  const tagUpdateModeDisplayValue = useMemo(() => (formState ? formState.addTags ? TagUpdateMode.Add : TagUpdateMode.Replace : undefined), [formState]);
  const tagUpdateWarningVisible = useMemo(() => !!formState && !formState.addTags && !formState.tags?.length, [formState]);
  const assignedCollaboratorIdsDisplayValue = useMemo(() => formState?.assignedCollaboratorIds ?? [], [formState?.assignedCollaboratorIds]);
  const allowedCollaboratorGroupsDisplayValue = useMemo(() => formState?.allowedCollaboratorGroups ?? [], [formState?.allowedCollaboratorGroups]);
  const disciplinesDisplayValue = useMemo(() => formState?.disciplines ?? [], [formState?.disciplines]);
  const buildingsDisplayValue = useMemo(() => formState?.buildings ?? [], [formState?.buildings]);
  const floorsDisplayValue = useMemo(() => formState?.floors ?? [], [formState?.floors]);

  return (
    <Box
      id="IssueMultiselectSidebar"
      sx={{
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'flex-start',
        justifyContent: 'flex-start',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ flex: '0 0 0', height: 73, display: 'flex', gap: 2, justifyItems: 'flexStart', alignItems: 'center', p: 3 }}>
          {!!onClose && <RoundIconButton Icon={ArrowBackIcon} onClick={onClose} sx={{ flexShrink: 0 }} />}
          <Box>
            <Typography variant="h5" sx={{ pt: '1px' }}>{t('issues-multiselect-sidebar_title', 'Edit Multiple Issues')}</Typography>
            <Typography>{t('issues-multiselect-sidebar_subtitle', '{{count}} issues selected', { count: selectedIssueIds.length })}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            p: 1.5,
          }}
        >
          {!!errorMessage && <Alert severity="error" onClose={onCloseErrorMessage}>{errorMessage}</Alert>}
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button id="IssueMultiselectSidebarCancelButton" variant="contained" color="secondary" onClick={onClose}>{t('issues-multiselect-sidebar_cancel-button-label', 'Cancel')}</Button>
            <Button id="IssueMultiselectSidebarConfirmButton" variant="contained" color="primary" onClick={onConfirm} sx={{ flexGrow: 1 }}>{t('issues-multiselect-sidebar_confirm-button-label', 'Apply')}</Button>
          </Box>
        </Box>
      </Box>
      <Box sx={{
        overflow: 'auto',
        boxShadow: 'inset 0px 0px 8px -4px rgba(0,0,0,0.2)',
        backgroundColor: theme.palette.grey[100],
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        p: 2,
      }}
      >
        <Panel>
          <Typography variant="h4">
            {t('issues-multiselect-sidebar_general-panel-header', 'General')}
          </Typography>
          <Box sx={{ pt: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
            {!!canEditStatus && (
              <FormControl>
                <InputLabel id="issues-multiselect-sidebar_issue-status-select-label">
                  {t('issues-multiselect-sidebar_issue-status-select-label', 'Status')}
                </InputLabel>
                <Select
                  id="issues-multiselect-sidebar_issue-status-select"
                  labelId="issues-multiselect-sidebar_issue-status-select-label"
                  value={formState?.issueStatus ?? ''}
                  disabled={!formState || isLoadingMutation}
                  onChange={onChangeIssueStatus}
                  label={t('issues-multiselect-sidebar_issue-status-select-label', 'Status')}
                >
                  <MenuItem value="" sx={{ fontStyle: 'italic' }}>{t('issues-multiselect-sidebar_status-select-empty-value-label', 'None')}</MenuItem>
                  {!!filteredStatuses && filteredStatuses.map((status) => <MenuItem key={status.id} value={status.id}>{status.name}</MenuItem>)}
                </Select>
              </FormControl>
            )}
            <FormControl>
              <InputLabel id="issues-multiselect-sidebar_issue-type-select-label">
                {t('issues-multiselect-sidebar_issue-type-select-label', 'Type')}
              </InputLabel>
              <Select
                id="issues-multiselect-sidebar_issue-type-select"
                labelId="issues-multiselect-sidebar_issue-type-select-label"
                value={formState?.issueType ?? ''}
                disabled={!formState || isLoadingMutation}
                onChange={onChangeIssueType}
                label={t('issues-multiselect-sidebar_issue-type-select-label', 'Type')}
              >
                {!!issueTypes && issueTypes.map((type) => <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>)}
              </Select>
            </FormControl>
          </Box>
        </Panel>
        <Panel>
          <Typography variant="h4">
            {t('issues-multiselect-sidebar_schedule-panel-header', 'Schedule')}
          </Typography>
          <Box sx={{ pt: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <LocalizedDatePicker
                label={t('issues-multiselect-sidebar_start-date-label', 'Start Date')}
                value={startingDateDisplayValue}
                onChange={onChangeStartingDate}
              />
              <LocalizedDatePicker
                label={t('issues-multiselect-sidebar_due-date-label', 'Due Date')}
                value={dueDateDisplayValue}
                onChange={onChangeDueDate}
              />
            </Box>
            <FormControl sx={{ flex: '1 1 0' }}>
              <InputLabel id="issues-multiselect-sidebar_issue-priority-select-label">
                {t('issues-multiselect-sidebar_issue-priority-select-label', 'Priority')}
              </InputLabel>
              <Select
                id="issues-multiselect-sidebar_issue-priority-select"
                labelId="issues-multiselect-sidebar_issue-priority-select-label"
                value={formState?.issuePriority ?? ''}
                onChange={onChangeIssuePriority}
                label={t('issues-multiselect-sidebar_issue-priority-select-label', 'Priority')}
              >
                <MenuItem value="" sx={{ fontStyle: 'italic' }}>{t('issues-multiselect-sidebar_priority-select-empty-value-label', 'None')}</MenuItem>
                {!!issuePriorities && issuePriorities.map((priority) => <MenuItem key={priority.id} value={priority.id}>{priority.name}</MenuItem>)}
              </Select>
            </FormControl>
          </Box>
        </Panel>
        <Panel>
          <Typography variant="h4">
            {t('issues-multiselect-sidebar_general-panel-header', 'Responsibility')}
          </Typography>
          <Box sx={{ pt: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <AssignmentSelect sx={{ flex: '1 1 0' }} value={assignedCollaboratorIdsDisplayValue} onChange={onChangeAssignedCollaborators} />
            <FormControl sx={{ flex: '1 1 0' }}>
              <InputLabel id="issues-multiselect-sidebar_reviewer-select-label">
                {t('issues-multiselect-sidebar_reviewer-select-label', 'Reviewer')}
              </InputLabel>
              <Select
                id="issues-multiselect-sidebar_reviewer-select"
                labelId="issues-multiselect-sidebar_reviewer-select-label"
                value={formState?.reviewerId ?? ''}
                onChange={onChangeReviewerId}
                label={t('issues-multiselect-sidebar_reviewer-select-label', 'Reviewer')}
              >
                <MenuItem value="" sx={{ fontStyle: 'italic' }}>{t('issues-multiselect-sidebar_reviewer-select-empty-value-label', 'None')}</MenuItem>
                {!!collaborators && collaborators.map((collaborator) => <MenuItem key={collaborator.id} value={collaborator.id}>{`${collaborator.firstName} ${collaborator.lastName}`}</MenuItem>)}
              </Select>
            </FormControl>
            <FormControl sx={{ flex: '1 1 0' }}>
              <InputLabel id="issues-multiselect-sidebar_visibility-select-label">
                {t('issues-multiselect-sidebar_visibility-select-label', 'Visibility')}
              </InputLabel>
              <Select
                id="issues-multiselect-sidebar_visibility-select"
                labelId="issues-multiselect-sidebar_visibility-select-label"
                value={`${formState?.visibility ?? ''}`}
                onChange={onChangeVisibility}
                label={t('issues-multiselect-sidebar_visibility-select-label', 'Visibility')}
              >
                <MenuItem value={`${IssueVisibility.Public}`}>{t('issues-multiselect-sidebar_visibility-select-value-public-label', 'Public')}</MenuItem>
                <MenuItem value={`${IssueVisibility.Private}`}>{t('issues-multiselect-sidebar_visibility-select-value-private-label', 'Private')}</MenuItem>
                <MenuItem value={`${IssueVisibility.Restricted}`}>{t('issues-multiselect-sidebar_visibility-select-value-restricted-label', 'Group Based')}</MenuItem>
              </Select>
            </FormControl>
            {formState?.visibility === IssueVisibility.Restricted && (
            <FormControl sx={{ flex: '1 1 0' }}>
              <InputLabel id="issues-multiselect-sidebar_visibility-groups-select-label">
                {t('issues-multiselect-sidebar_visibility-groups-select-label', 'Groups')}
              </InputLabel>
              <Select
                id="issues-multiselect-sidebar_visibility-groups-select"
                labelId="issues-multiselect-sidebar_visibility-groups-select-label"
                value={allowedCollaboratorGroupsDisplayValue}
                onChange={onChangeAllowedGroups}
                multiple
                label={t('issues-multiselect-sidebar_visibility-groups-select-label', 'Groups')}
              >
                {!!groups && groups.map((group) => (
                  <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            )}
          </Box>
        </Panel>
        <Panel>
          <Typography variant="h4">
            {t('issues-multiselect-sidebar_meta-data-panel-header', 'Meta Data')}
          </Typography>
          <Box sx={{ pt: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
              <FormControl>
                <InputLabel id="issues-multiselect-sidebar_tags-create-select-label" htmlFor="issues-multiselect-sidebar_tag-create-select">{t('issues-multiselect-sidebar_tags-create-select-label', 'Tags')}</InputLabel>
                <TagCreateSelect
                  id="issues-multiselect-sidebar_tags-create-select"
                  value={tagsDisplayValue}
                  onChange={onChangeTags}
                  label={t('issues-multiselect-sidebar_tags-create-select-label', 'Tags')}
                  variant="outlined"
                />
              </FormControl>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="issues-multiselect-sidebar_tag-update-mode-radio-group-header"
                  value={tagUpdateModeDisplayValue ?? ''}
                  onChange={onChangeTagUpdateMode}
                  name="radio-buttons-group"
                >
                  <FormControlLabel value={TagUpdateMode.Add} control={<Radio />} label={t('issues-multiselect-sidebar_tag-update-mode-add', 'Add Tags')} />
                  <FormControlLabel value={TagUpdateMode.Replace} control={<Radio />} label={t('issues-multiselect-sidebar_tag-update-mode-replace', 'Replace Tags')} />
                </RadioGroup>
              </FormControl>
              {!!tagUpdateWarningVisible && <Alert severity="warning">{t('issues-multiselect-sidebar_tag-replace-empty-warning', 'Selecting "replace tags" with an empty list of tags will remove all tags from the selected items.')}</Alert>}
            </Box>
            <FormControl sx={{ flex: '1 1 0' }}>
              <InputLabel id="issues-multiselect-sidebar_workphase-select-label">
                {t('issues-multiselect-sidebar_workphase-select-label', 'Work Phase')}
              </InputLabel>
              <Select
                id="issues-multiselect-sidebar_workphase-select"
                labelId="issues-multiselect-sidebar_workphase-select-label"
                value={formState?.workPhase ?? ''}
                onChange={onChangeWorkPhase}
                label={t('issues-multiselect-sidebar_workphase-select-label', 'Work Phase')}
              >
                <MenuItem value="" sx={{ fontStyle: 'italic' }}>{t('issues-multiselect-sidebar_work-phase-select-empty-value-label', 'None')}</MenuItem>
                {!!workPhases && workPhases.map((workPhase) => <MenuItem key={workPhase.id} value={workPhase.id}>{workPhase.name}</MenuItem>)}
              </Select>
            </FormControl>
          </Box>
        </Panel>
        <Panel>
          <Typography variant="h4">
            {t('issues-multiselect-sidebar_general-panel-header', 'Model Association')}
          </Typography>
          <Box sx={{ pt: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <FormControl sx={{ flex: '1 1 0' }}>
              <InputLabel id="issues-multiselect-sidebar_disciplines-select-label">
                {t('issues-multiselect-sidebar_disciplines-select-label', 'Disciplines')}
              </InputLabel>
              <Select
                id="issues-multiselect-sidebar_disciplines-select"
                labelId="issues-multiselect-sidebar_disciplines-select-label"
                multiple
                value={disciplinesDisplayValue}
                onChange={onChangeDisciplines}
                label={t('issues-multiselect-sidebar_discipline-select-label', 'Disciplines')}
              >
                {!!disciplines && disciplines.map((discipline) => <MenuItem key={discipline.id} value={discipline.id}>{discipline.name}</MenuItem>)}
              </Select>
            </FormControl>
            <FormControl sx={{ flex: '1 1 0' }}>
              <InputLabel id="issues-multiselect-sidebar_buildings-select-label">
                {t('issues-multiselect-sidebar_buildings-select-label', 'Buildings')}
              </InputLabel>
              <Select
                id="issues-multiselect-sidebar_buildings-select"
                labelId="issues-multiselect-sidebar_buildings-select-label"
                multiple
                value={buildingsDisplayValue}
                onChange={onChangeBuildings}
                label={t('issues-multiselect-sidebar_building-select-label', 'Buildings')}
              >
                {!!buildings && buildings.map((building) => <MenuItem key={building.id} value={building.id}>{building.name}</MenuItem>)}
              </Select>
            </FormControl>
            <FormControl sx={{ flex: '1 1 0' }}>
              <InputLabel id="issues-multiselect-sidebar_floors-select-label">
                {t('issues-multiselect-sidebar_floors-select-label', 'Floors')}
              </InputLabel>
              <Select
                id="issues-multiselect-sidebar_floors-select"
                labelId="issues-multiselect-sidebar_floors-select-label"
                multiple
                value={floorsDisplayValue}
                onChange={onChangeFloors}
                label={t('issues-multiselect-sidebar_floor-select-label', 'Floors')}
              >
                {!!floors && floors.map((floor) => <MenuItem key={floor.id} value={floor.id}>{floor.name}</MenuItem>)}
              </Select>
            </FormControl>
          </Box>
        </Panel>
      </Box>
    </Box>
  );
}
