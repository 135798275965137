import React, { useState } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { useTranslation } from 'react-i18next';
import { Alert, AlertTitle, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Portal, Snackbar, Typography, useTheme } from '@mui/material';
import Color from 'color';
import { useDrop } from 'react-dnd';
import DocumentListDto from 'documents-lists/types/DocumentListDto';
import DocumentVersionDragAndDropPayload from 'documents-folders/types/DocumentVersionDragAndDropPayload';
import useAddDocumentVersionsToPlanlist from 'documents-lists/hooks/useAddDocumentVersionsToPlanlist';
import useDocumentSelectionContext from 'documents/hooks/useDocumentSelectionContext';

export default function PlanlistTreeCellRenderer({
  data,
}: ICellRendererParams<DocumentListDto, string | undefined>) {
  const { t } = useTranslation('documents-lists');
  const theme = useTheme();
  const {
    addDocumentVersionsToPlanlist,
    errorMessage,
    clearErrorMessage,
    successMessage,
    clearSuccessMessage,
  } = useAddDocumentVersionsToPlanlist();
  const [isPlanlistCurrentlyDraggedOver, setIsPlanlistCurrentlyDraggedOver] = useState(false);
  const { setSelectedDocumentVersionIds } = useDocumentSelectionContext();

  const [, dropRef] = useDrop<DocumentVersionDragAndDropPayload>(() => ({
    accept: 'DocumentVersionDragAndDropPayload',
    drop: async ({ documentVersionIds }) => {
      if (data) {
        addDocumentVersionsToPlanlist(documentVersionIds, data.id);
        setSelectedDocumentVersionIds([]);
      }
    },
    collect: (monitor) => {
      setIsPlanlistCurrentlyDraggedOver(monitor.isOver());
    },
  }), []);

  return (
    <Box
      id="PlanlistTreeCellRenderer"
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        pr: 1,
        pl: 2,
        backgroundColor: isPlanlistCurrentlyDraggedOver ? Color(theme.palette.secondary.light).lightness(98).hex() : 'unset',
      }}
      ref={dropRef}
    >
      {!!data && (
        <Box sx={{ minWidth: 0, flexGrow: 1 }}>
          <Typography
            title={data?.name}
            sx={{
              textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap',
            }}
          >
            {data?.name}
          </Typography>
          <Typography sx={{ fontSize: 12, color: theme.palette.text.secondary }}>
            {t('planlist-item_document-count', '{{count}} documents', { count: data.documentVersionIds.length })}
          </Typography>
        </Box>
      )}
      {successMessage && (
      <Portal>
        <Snackbar open onClose={clearSuccessMessage} autoHideDuration={6000}>
          <Alert onClose={clearSuccessMessage} severity="success" sx={{ width: '100%' }}>
            <AlertTitle>{t('planlist-tree-cell-renderer_success-title', 'Success')}</AlertTitle>
            {successMessage}
          </Alert>
        </Snackbar>
      </Portal>
      )}
      {errorMessage && (
      <Dialog open>
        <DialogTitle>{t('planlist-tree-cell-renderer_error-title', 'Error')}</DialogTitle>
        <DialogContent>
          <Alert severity="error" sx={{ width: '100%' }}>{errorMessage}</Alert>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={clearErrorMessage}>{t('planlist-tree-cell-renderer_error-close', 'Close')}</Button>
        </DialogActions>
      </Dialog>
      )}
    </Box>
  );
}
