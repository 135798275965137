import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from 'setup/components/App';
import 'scss/main.scss';
import 'setup/i18n';
import 'setup/chartjs';
import 'setup/agGrid';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<StrictMode><App /></StrictMode>);
