import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Button } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import AddIcon from '@mui/icons-material/Link';
import { useTranslation } from 'react-i18next';
import LinkIssueModal from 'issues/components/LinkIssueModal';
import useDocumentViewerContext from 'documents-details/hooks/useDocumentViewerContext';
import useDocumentVersionQuery from 'documents/hooks/useDocumentVersionQuery';
import FileType from 'documents/types/FileType';
import useIssueUpdateMutation from 'issues/hooks/useIssueUpdateMutation';
import useIssueQueryData from 'issues/hooks/useIssueQueryData';
import { useQueryClient } from '@tanstack/react-query';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import useDocumentDetailsSelectionContext from 'documents-details/hooks/useDocumentDetailsSelectionContext';

interface LinkIssueButtonProps extends ISxProps {
}

export default function LinkIssueButton({
  sx,
}: LinkIssueButtonProps) {
  const { t } = useTranslation('documents-details');
  const queryClient = useQueryClient();
  const { queryKeyBases: documentVersionsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentVersion);
  const { setIssueIdCurrentlyLinking } = useDocumentViewerContext();
  const { documentVersionId, setIssueId } = useDocumentDetailsSelectionContext();
  const { data: documentVersion } = useDocumentVersionQuery(documentVersionId);
  const getIssueQueryData = useIssueQueryData();
  const { mutateAsync: updateIssue } = useIssueUpdateMutation();
  const linkIssueButtonRef = useRef<HTMLButtonElement>(null);

  const [linkIssueDialogOpen, setLinkIssueDialogOpen] = useState<boolean>(false);
  const onClickLinkIssue = useCallback(() => {
    setLinkIssueDialogOpen(true);
  }, []);

  const onCloseLinkIssueDialog = useCallback(async (value: string[]) => {
    if (!documentVersion || !documentVersionId) return;
    const selectedIssueId = value[0];
    setLinkIssueDialogOpen(false);
    if (!selectedIssueId) return;
    if (documentVersion.fileType === FileType.Pdf || documentVersion.fileType === FileType.DWG || (documentVersion.fileType === FileType.Image && !documentVersion.originalFileName.endsWith('.svg'))) {
      setIssueIdCurrentlyLinking(selectedIssueId);
    } else {
      // if the viewer is currently not displaying a document (i.e. unsupported file format), immediately persist the link without an annotation
      const issue = await getIssueQueryData(selectedIssueId);
      await updateIssue({
        id: selectedIssueId,
        linkedDocumentVersionIds: { value: Array.from(new Set([...issue.linkedDocumentVersionIds, documentVersionId])) },
      });
      documentVersionsQueryKey.forEach((queryKey) => queryClient.invalidateQueries({ queryKey }));
      setIssueId(selectedIssueId);
    }
  }, [documentVersion, documentVersionId, documentVersionsQueryKey, getIssueQueryData, queryClient, setIssueId, setIssueIdCurrentlyLinking, updateIssue]);

  const issueIdsAlreadyLinkedToAnyVersion = useMemo(() => (documentVersion ? new Set(documentVersion.documentLinkedIssueIds) : undefined), [documentVersion]);

  return (
    <>
      <Button id="LinkIssueButton" onClick={onClickLinkIssue} ref={linkIssueButtonRef} variant="contained" color="primary" sx={{ ...sx, pl: 1.5, gap: 1 }}>
        <AddIcon />
        {t('link-issue-button_link-issue-button-label', 'Link Issue')}
      </Button>
      {linkIssueDialogOpen && <LinkIssueModal title={t('link-issue-button_link-issue-modal-title', 'Link Issue')} onClose={onCloseLinkIssueDialog} disabledIssueIds={issueIdsAlreadyLinkedToAnyVersion} />}
    </>
  );
}
