import type { Theme, ComponentsProps, ComponentsVariants } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { linearProgressClasses } from '@mui/material/LinearProgress';

interface MuiLinearProgressComponent {
  defaultProps?: ComponentsProps['MuiLinearProgress'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiLinearProgress'];
  variants?: ComponentsVariants['MuiLinearProgress'];
}

const VisoLinearProgress: MuiLinearProgressComponent = {
  styleOverrides: {
    root: ({ theme }) => ({
      height: 10,
      borderRadius: 5,
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.secondary.dark,
      },
      [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.primary,
      },
    }),
  },
};

export default VisoLinearProgress;
