import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import RoleDto from 'collaborators/types/RoleDto';
import useCollaboratorsOdataQuery from 'collaborators/hooks/useCollaboratorsOdataQuery';

interface RoleCollaboratorsListPopupProps {
  role: RoleDto,
  onClose: () => void,
}

export default function RoleCollaboratorsListPopup({
  role,
  onClose,
}: RoleCollaboratorsListPopupProps) {
  const { t } = useTranslation('settings');
  const { data: roleCollaborators } = useCollaboratorsOdataQuery({ filter: { roleId: role.id } });

  return (
    <Dialog open>
      <DialogTitle>{t('role-collaborators-list-popup_title', 'Collaborators with the role "{{roleName}}"', { roleName: role.name })}</DialogTitle>
      <DialogContent sx={{ minHeight: '84px', width: '420px' }}>
        {roleCollaborators?.length ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('role-collaborators-list-popup_name-column-header', 'Name')}</TableCell>
                <TableCell>{t('role-collaborators-list-popup_email-column-header', 'E-Mail')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roleCollaborators?.map((c) => (
                <TableRow>
                  <TableCell>{`${c.firstName} ${c.lastName}`}</TableCell>
                  <TableCell>{c.email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : <Typography>{t('role-collaborators-list-popup_empty-role-message', 'There are no project collaborators with this role.')}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>{t('role-collaborators-list-popup_close-button-label', 'Close')}</Button>
      </DialogActions>
    </Dialog>
  );
}
