import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import CreateProjectNamingSchemeSettingsDto from 'naming-schemes/types/CreateProjectNamingSchemeSettingsDto';

export default function useUpdateNamingSchemeSettingsMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.NamingSettings);
  const mutationFn = useCallback(async (dto: CreateProjectNamingSchemeSettingsDto) => {
    await axiosInstance.put(`${ApiEndpoint.NamingSettings}`, dto);
  }, [axiosInstance]);
  const onSuccess = useCallback(() => {
    queryKeyBases.forEach((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
  }, [queryClient, queryKeyBases]);
  return useMutation<void, unknown, CreateProjectNamingSchemeSettingsDto>({
    mutationFn,
    onSuccess,
  });
}
