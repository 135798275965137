import { useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import DocumentVersionDto from 'documents/types/DocumentVersionDto';
import useCachedEntityListPartialReplace from 'api/hooks/useCachedEntityListPartialReplace';
import DocumentVersionsRowRangeData from 'documents/types/DocumentVersionsRowRangeData';
import { useCallback } from 'react';

export default function useDocumentVersionUpdateMutationFn<TUpdateDto extends { id: string }>(endpoint: ApiEndpoint) {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const partiallyReplaceCachedEntityList = useCachedEntityListPartialReplace();
  const { listQueryKeyBases, odataQueryKeyBase } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentVersion);
  return useCallback(async (dto: TUpdateDto) => {
    if (!dto.id) throw new Error('Missing ID in update DTO');
    const { data: documentVersionDto } = await axiosInstance.put<DocumentVersionDto>(endpoint, dto);
    if (!documentVersionDto) throw new Error('Empty API response');
    // update id entpoint cache
    queryClient.setQueriesData<DocumentVersionDto[] | undefined>({ queryKey: odataQueryKeyBase }, ((cachedData: DocumentVersionDto[] | undefined) => {
      if (!cachedData) return undefined;
      return partiallyReplaceCachedEntityList(cachedData, [documentVersionDto]);
    }));
    // update row range (data grid) cache
    queryClient.setQueriesData<DocumentVersionsRowRangeData | undefined>({ queryKey: listQueryKeyBases }, ((cachedData: DocumentVersionsRowRangeData | undefined) => {
      if (!cachedData) return undefined;
      return {
        ...cachedData,
        entities: partiallyReplaceCachedEntityList(cachedData.entities, [documentVersionDto]),
      };
    }));
    return documentVersionDto;
  }, [axiosInstance, endpoint, listQueryKeyBases, odataQueryKeyBase, partiallyReplaceCachedEntityList, queryClient]);
}
