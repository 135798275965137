import {
  BufferGeometry,
  Color,
  DoubleSide,
  IUniform,
  LineBasicMaterial,
  LineSegments,
  Material,
  MeshLambertMaterial,
  Plane,
  ShaderMaterial,
  Vector3,
} from "three";
import { clamp } from "three/src/math/MathUtils";
import {
  GlobalIdsMap,
  IdAttrName,
  IfcState,
  Normal,
  Position,
  COLORGROUP_ID,
  ColorIdsMap,
  ColorMatMap,
  ColorGroup,
} from "./BaseDefinitions";
import { BvhManager } from "./BvhManager";
import { IFCModel } from "./IFCModel";
import { VisoSolidMesh } from "./VisoSolideMesh";
import { PickSubset } from "./PickSubset";
import { GeometryAttributes, SubsetUtils } from "./SubsetUtils";
import { rgb } from 'color';

const vShader = `
#include <clipping_planes_pars_vertex>

void main(){
  #include <begin_vertex>
  #include <project_vertex>
  #include <clipping_planes_vertex>
}
`;

const fShader = `
uniform vec3 u_color;
uniform vec3 u_section;

#include <clipping_planes_pars_fragment>

void main(){
  #include <clipping_planes_fragment>

  #ifdef DOUBLE_SIDED
  gl_FragColor = (gl_FrontFacing) ? vec4(u_section, 1.0) : vec4(u_color, 1.0);
  #else
  gl_FragColor = vec4(u_color, 1.0);
  #endif
}
`;

export const transMat: Material = new MeshLambertMaterial({
  color: 0x808080,
  opacity: 0.5,
  transparent: true,
  side: DoubleSide,
});
export const TRANSCOLOR: string = "transparent";

const wireMat: Material = new LineBasicMaterial({
  color: 0x808080,
  opacity: 0.5,
  transparent: true,
});

export class ColorSubset {
  private colorIds: ColorIdsMap = {};
  private colorMats: ColorMatMap = {};
  private model: IFCModel | null = null;
  private planes: Plane[] = [];

  constructor(
    private state: IfcState,
    private BVH: BvhManager,
    private picker: PickSubset
  ) { }

  getModel() {
    return this.model;
  }

  setClippingPlanes(planes: Plane[]) {
    wireMat.clippingPlanes = planes.length ? planes : null;
    this.planes = planes;

    this.updateSectionColor();
  }

  reset() {
    this.colorIds = {};
    if (this.model != null) {
      this.model.globalIds = {};
      this.clearSolideGeometry();
    }
    this.colorMats = {};
  }

  dispose() {
    Object.keys(this.colorMats).forEach((color) => {
      this.colorMats[color].dispose();
    });

    this.colorIds = {};
    this.colorMats = {};
    if (this.model != null) this.model.dispose();

    this.model = null;
  }

  refresh() {
    const colorIds = this.refactorColorIds();

    if (Object.keys(colorIds).length) {
      this.setGeometry(colorIds);
    } else {
      if (this.model != null) this.model.visible = false;
    }

    return this.model;
  }

  transparentElements(isOn: boolean) {
    if (isOn) {
      this.addElements(TRANSCOLOR);
    } else {
      this.removeTransparentElements();
    }

    return this.refresh();
  }

  addColorElements(color: string) {
    this.resetColorElements(false);
    this.addElements(color);
    return this.refresh();
  }

  resetColorElements(isRefresh: boolean = true) {
    const selectedIds = this.picker.getAllSelectedIds();
    selectedIds.forEach((i) => {
      Object.keys(this.colorIds).forEach((color) => {
        if (color !== TRANSCOLOR) {
          Object.keys(this.colorIds[color]).forEach((key) => {
            const index = this.colorIds[color][key].indexOf(i);
            if (index != -1) {
              this.colorIds[color][key].splice(index, 1);
            }

            if (this.colorIds[color][key].length == 0)
              delete this.colorIds[color][key];
          });

          if (Object.keys(this.colorIds[color]).length == 0)
            delete this.colorIds[color];
        }
      });
    });

    if (isRefresh) this.refresh();
    return this.model;
  }

  getAllColorIds() {
    const ids = new Set<string>();
    Object.keys(this.colorIds).forEach((color) => {
      Object.keys(this.colorIds[color]).forEach((key) => {
        this.colorIds[color][key].forEach((i) => ids.add(i));
      });
    });

    return ids;
  }

  getColorIds() {
    const ids = new Set<string>();
    Object.keys(this.colorIds).forEach((color) => {
      if (color != TRANSCOLOR) {
        Object.keys(this.colorIds[color]).forEach((key) => {
          this.colorIds[color][key].forEach((i) => ids.add(i));
        });
      }
    });

    return ids;
  }

  isolateElements() {
    const colorIds: ColorIdsMap = {};
    const selectedId = this.picker.selectedGlobalIds;
    Object.keys(selectedId).forEach((key) => {
      Object.keys(this.colorIds).forEach((color) => {
        if (this.colorIds[color][key]) {
          const ids = this.colorIds[color][key].filter((i) =>
            selectedId[key].includes(i)
          );
          if (ids.length) {
            if (!colorIds[color]) colorIds[color] = {};

            colorIds[color][key] = ids;
          }
        }
      });
    });

    this.colorIds = colorIds;
    this.refresh();
  }

  isolateAndTranslateElements() {
    this.addElements(TRANSCOLOR);
    this.isolateElements();

    return this.model;
  }

  resetElements() {
    this.removeTransparentElements();
    this.resetColorElements();
  }

  getTranslateState() {
    if (!this.colorIds[TRANSCOLOR]) return true;

    const transIds = this.getTransparentIds();
    if (transIds == null || transIds.size == 0) return true;

    const selectedIds = this.picker.getAllSelectedIds();

    let result = true;
    selectedIds.forEach((i) => {
      result &&= transIds.has(i);
    });

    return !result;
  }

  handleIfcVisibleComponent(opening: boolean, space: boolean) {
    const colorIds: ColorIdsMap = {};
    Object.keys(this.colorIds).forEach((color) => {
      let globalIds: GlobalIdsMap = {};
      Object.keys(this.colorIds[color]).forEach((key) => {
        let items = this.colorIds[color][key];
        if (!opening) {
          items = items.filter(
            (i) => !this.state.models[key].openingIds.includes(i)
          );
        }

        if (!space) {
          items = items.filter(
            (i) => !this.state.models[key].spaceIds.includes(i)
          );
        }

        if (items.length) {
          globalIds[key] = items;
        }
      });

      colorIds[color] = globalIds;
    });

    this.colorIds = colorIds;
    this.setGeometry(colorIds);
  }

  showColorSubset(colorMap: ColorIdsMap, colorMat: ColorMatMap) {
    this.colorIds = colorMap;
    this.colorMats = colorMat;

    if (Object.keys(this.colorIds).length) {
      this.setGeometry(this.colorIds);
    }
  }

  hideOtherTypes(id: string, globalIds: any[]) {
    Object.keys(this.colorIds).forEach((color) => {
      if (this.colorIds[color][id]) {
        const gIds = this.colorIds[color][id].filter(
          (gId) => !globalIds.includes(gId)
        );
        if (gIds.length) {
          this.colorIds[color][id] = gIds;
        } else {
          delete this.colorIds[color][id];
        }

        if (Object.keys(this.colorIds[color]).length == 0)
          delete this.colorIds[color];
      }
    });

    this.setGeometry(this.colorIds);
  }

  getTransparentIds() {
    if (!this.colorIds[TRANSCOLOR]) return null;
    const result = new Set<string>();
    Object.keys(this.colorIds[TRANSCOLOR]).forEach((key) => {
      this.colorIds[TRANSCOLOR][key].forEach((i) => result.add(i));
    });

    return result;
  }

  getColorGroups() {
    const result: ColorGroup[] = [];
    Object.keys(this.colorIds).forEach((clr) => {
      if (clr != TRANSCOLOR) {
        const ids = new Set<string>();
        Object.keys(this.colorIds[clr]).forEach((key) => {
          this.colorIds[clr][key].forEach((i) => ids.add(i));
        });

        const material = this.colorMats[clr] as ShaderMaterial;
        const colorUniform = material.uniforms.u_color as IUniform<Color>;
        const color =  rgb({
          r: colorUniform.value.r * 255,
          g: colorUniform.value.g * 255,
          b: colorUniform.value.b * 255,
        }).hex();
        const componentIDs = Array.from(ids);

        result.push({ color, componentIDs });
      }
    });

    return result;
  }

  updateSectionColor() {
    const colorKeys = Object.keys(this.colorMats);
    if (colorKeys.length) {
      colorKeys.forEach((key) => {
        const material = this.colorMats[key];
        if (material instanceof ShaderMaterial) {
          material.uniforms.u_section.value = this.state.sectionColor ? this.state.sectionColor : material.uniforms.u_color.value;
        }
      });
    }

    this.refresh();
  }

  removeModels(modelIds: string[]) {
    Object.keys(this.colorIds).forEach((color) => {
      const idsMap = this.colorIds[color];
      modelIds.forEach((mId) => {
        if (idsMap[mId]) {
          delete idsMap[mId];
        }
      });
    });

    this.refresh();
  }

  private getVisoColor(color: Color, opacity: number | undefined) {
    const colorString = `#${color.getHexString()}`;
    return opacity ? `${colorString}${getHexString(opacity)}` : colorString;

    // The number 0 between 1 to Hex String
    function getHexString(value: number) {
      return ("00" + clamp(value * 255, 0, 255).toString(16)).slice(-2);
    }
  }

  private addElements(color: string) {
    this.createColorMaterials(color);
    const selectedIds = this.picker.selectedGlobalIds;

    if (this.colorIds[color]) {
      Object.keys(selectedIds).forEach((key) => {
        if (this.colorIds[color][key]) {
          selectedIds[key].forEach((i) => {
            if (!this.colorIds[color][key].includes(i)) {
              this.colorIds[color][key].push(i);
            }
          });
        } else {
          this.colorIds[color][key] = selectedIds[key];
        }
      });
    } else {
      this.colorIds[color] = {};
      Object.keys(selectedIds).forEach((key) => {
        this.colorIds[color][key] = [];
        selectedIds[key].forEach((i) => this.colorIds[color][key].push(i));
      });
    }
  }

  private removeTransparentElements() {
    if (this.colorIds[TRANSCOLOR]) {
      const selectedIds = this.picker.selectedGlobalIds;

      Object.keys(selectedIds).forEach((key) => {
        if (this.colorIds[TRANSCOLOR][key]) {
          selectedIds[key].forEach((i) => {
            const index = this.colorIds[TRANSCOLOR][key].indexOf(i);
            if (index !== -1) {
              this.colorIds[TRANSCOLOR][key].splice(index, 1);
            }
          });

          if (this.colorIds[TRANSCOLOR][key].length == 0) {
            delete this.colorIds[TRANSCOLOR][key];
          }
        }
      });

      if (Object.keys(this.colorIds[TRANSCOLOR]).length == 0) {
        delete this.colorIds[TRANSCOLOR];
      }
    }
  }

  private createColorMaterials(color: string) {
    if (this.colorMats[color]) return;

    if (color === TRANSCOLOR) {
      this.colorMats[color] = transMat;
    } else {
      const uColor = new Color(color);
      const material = new ShaderMaterial({
        vertexShader: vShader,
        fragmentShader: fShader,
        uniforms: {
          u_color: { value: uColor },
          u_section: { value: this.state.sectionColor ? this.state.sectionColor : uColor },
        },
        side: DoubleSide,
        clipping: true,
      });

      this.colorMats[color] = material;
    }
  }

  private setGeometry(colorIds: ColorIdsMap) {
    if (this.model == null) {
      this.model = new IFCModel();
      this.model.modelFileId = COLORGROUP_ID;
      this.state.models[COLORGROUP_ID] = this.model;
    }

    if (this.model.solid == null) {
      this.model.solid = new VisoSolidMesh(
        new BufferGeometry(),
        new Material(),
        COLORGROUP_ID
      );
      this.model.solid.frustumCulled = false;
      this.model.add(this.model.solid);
    }

    if (this.model.wireframe == null) {
      this.model.wireframe = new LineSegments(new BufferGeometry(), wireMat);
      this.model.wireframe.frustumCulled = false;
      this.model.add(this.model.wireframe);
    }

    SubsetUtils.resetExpressId();
    this.model.solid.geometry.clearGroups();

    const attributes: GeometryAttributes[] = [];
    const materials: Material[] = [];

    let offset = 0;
    let i = 0;
    Object.keys(colorIds).forEach((color) => {
      const attr = this.getColorAttributes(colorIds[color]);
      if (attr != null) {
        const colorAttributes = SubsetUtils.mergeGeometryAttributes(attr);

        attributes.push(colorAttributes);
        const material = this.colorMats[color].clone();
        if (this.planes) {
          material.clippingPlanes = this.planes;
        }
        materials.push(material);

        const count = colorAttributes.index.count;
        if (this.model != null && this.model.solid != null) {
          this.model.solid.geometry.addGroup(offset, count, i);

          offset += count;
          i++;
        }
      }
    });

    if (attributes.length) {
      this.model.solid.material = materials;
      const attribute = SubsetUtils.mergeGeometryAttributes(attributes);
      this.model.solid.geometry.setAttribute(Position, attribute.position);
      this.model.solid.geometry.setAttribute(Normal, attribute.normal);
      this.model.solid.geometry.setAttribute(IdAttrName, attribute.expressId);
      this.model.solid.geometry.setIndex(attribute.index);

      if (this.BVH) this.BVH.applyThreeMeshBVH(this.model.solid.geometry);

      this.model.wireframe.geometry.setAttribute(Position, attribute.position);
      this.model.wireframe.geometry.setIndex(attribute.wireframe);

      this.model.globalIds = attribute.expressIdMap;

      this.model.visible = true;
    } else {
      this.clearSolideGeometry();
    }
  }

  private clearSolideGeometry() {
    if (this.model) {
      this.model.visible = false;
      if (this.model.solid) {
        this.model.solid.geometry.clearGroups();
        this.model.solid.geometry.setIndex([0]);
        this.model.solid.material = transMat;
      }
    }
  }

  private getColorAttributes(globalIds: GlobalIdsMap) {
    const attributes: GeometryAttributes[] = [];
    Object.keys(globalIds).forEach((key) => {
      if (globalIds[key].length) {
        const attribute = SubsetUtils.getGeometryAttributes(
          this.state,
          key,
          globalIds[key],
          true
        );
        if (attribute != null) {
          attributes.push(attribute);
        }
      }
    });

    return attributes.length ? attributes : null;
  }

  private refactorColorIds() {
    const colorIds: ColorIdsMap = {};
    const selectedIds = this.picker.selectedGlobalIds;

    Object.keys(this.colorIds).forEach((color) => {
      let globalIds: GlobalIdsMap = {};
      Object.keys(this.colorIds[color]).forEach((key) => {
        globalIds[key] = selectedIds[key]
          ? this.colorIds[color][key].filter((i) => !selectedIds[key].includes(i))
          : this.colorIds[color][key];

        if (
          color != TRANSCOLOR &&
          this.colorIds[TRANSCOLOR] &&
          this.colorIds[TRANSCOLOR][key]
        ) {
          globalIds[key] = globalIds[key].filter(
            (i) => !this.colorIds[TRANSCOLOR][key].includes(i)
          );
        }
      });

      colorIds[color] = globalIds;
    });

    return colorIds;
  }
}
