import React, {
  ForwardedRef, createContext, forwardRef, useContext, useMemo,
} from 'react';
import { Input, OutlinedInput } from '@mui/material';
import ChipSelectInputComponent from 'common/components/ChipSelectInputComponent';
import DisplayEntity from 'common/types/DisplayEntity';
import ISxProps from 'common/types/ISxProps';

export type ChipMultiselectVariant = 'outlined' | 'standard';

interface ChipMultiselectProps extends ISxProps {
  id?: string,
  label?: string,
  value: string[] | undefined,
  onChange: (values: string[]) => void,
  entities: DisplayEntity[] | undefined,
  disabled?: boolean,
  variant?: ChipMultiselectVariant,
  enableSetEmptyValue?: boolean,
  disabledEntityIds?: string[],
  suppressSelectAll?: boolean,
  abbreviated?: boolean,
}

interface ChipMultiselectContextState {
  entities: DisplayEntity[] | undefined,
  enableSetEmptyValue?: boolean,
  disabledEntityIds?: string[],
  suppressSelectAll?: boolean,
  abbreviated?: boolean,
}
const ChipMultiselectContext = createContext<ChipMultiselectContextState>({ entities: [] });

interface ChipMultiselectInputComponentProps {
  value: string[] | undefined,
  onChange: (nextValue: string[] | undefined) => void,
  disabled?: boolean,
}

interface InputElement {
  focus(): void;
  value?: string[];
}

const ChipMultiselectInputComponent = forwardRef((
  {
    value,
    onChange,
    disabled,
  }: ChipMultiselectInputComponentProps,
  ref: ForwardedRef<InputElement>,
) => {
  const {
    entities, enableSetEmptyValue, disabledEntityIds, suppressSelectAll, abbreviated,
  } = useContext<ChipMultiselectContextState>(ChipMultiselectContext);
  React.useImperativeHandle(ref, () => ({
    focus: () => {},
    value: value ?? [],
  }));
  return (
    <ChipSelectInputComponent
      value={value}
      onChange={onChange}
      disabled={disabled}
      entities={entities}
      disabledEntityIds={disabledEntityIds}
      enableSetEmptyValue={enableSetEmptyValue}
      suppressSelectAll={suppressSelectAll}
      abbreviated={abbreviated}
    />
  );
});

export default function ChipMultiselect({
  id,
  sx,
  label,
  value,
  onChange,
  disabled,
  entities,
  variant,
  enableSetEmptyValue,
  disabledEntityIds,
  suppressSelectAll,
  abbreviated,
}: ChipMultiselectProps) {
  const state = useMemo(() => ({
    entities, enableSetEmptyValue, disabledEntityIds, suppressSelectAll, abbreviated,
  }), [entities, enableSetEmptyValue, disabledEntityIds, suppressSelectAll, abbreviated]);
  const Component = variant === 'standard' ? Input : OutlinedInput;
  return (
    <ChipMultiselectContext.Provider value={state}>
      <Component
        id={id}
        sx={{
          height: '33px', pt: 0.5, pl: (variant === 'standard' ? 0 : 1), pr: '2px', ...sx,
        }}
        label={label}
        value={value}
        onChange={onChange as any} // MUI does not expect us to use a non HTML-input component here so we have to sneak around the event handler type check
        disabled={disabled}
        slots={{
          input: ChipMultiselectInputComponent,
        }}
        notched={enableSetEmptyValue}
      />
    </ChipMultiselectContext.Provider>
  );
}
