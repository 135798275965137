import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import DocumentsDataGridRow from 'documents/types/DocumentDataGridRow';
import useProjectDocumentStatusesQuery from 'documents/hooks/useProjectDocumentStatusesQuery';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import ColoredChip from 'common/components/ColoredChip';
import useDocumentVersionStatusUpdateMutation from 'documents/hooks/useDocumentVersionStatusUpdateMutation';
import DocumentVersionStatusUpdateDto from 'documents/types/DocumentVersionStatusUpdateDto';
import useCurrentCollaboratorRole from 'collaborators/hooks/useCurrentCollaboratorRole';
import RoleAction from 'projects/types/RoleAction';
import useCurrentCollaboratorFolderAccessLevel from 'documents-folders/hooks/useCurrentCollaboratorFolderAccessLevel';
import CollaboratorFolderAccessLevel from 'documents-folders/types/CollaboratorFolderAccessLevel';

export default function StatusCell({
  data,
  value,
}: ICellRendererParams<DocumentsDataGridRow, string | undefined>) {
  const { data: documentStatuses } = useProjectDocumentStatusesQuery();
  const valueLabelDto = useMemo(() => (value ? documentStatuses?.find((l) => l.id === value) : undefined), [documentStatuses, value]);
  const currentUserRole = useCurrentCollaboratorRole();
  const [displayValue, setDisplayValue] = useState<string>(value ?? '');
  useEffect(() => {
    if (!value) return;
    setDisplayValue(value);
  }, [value]);
  const { mutateAsync, isPending } = useDocumentVersionStatusUpdateMutation();
  const canSetStatusToPublished = useMemo(() => currentUserRole?.allowedActions?.has(RoleAction.DocStatus_ChangeToPublished), [currentUserRole?.allowedActions]);
  const isLockedAtPublished = useMemo(() => {
    const canChangeFromPublished = currentUserRole?.allowedActions?.has(RoleAction.DocStatus_ChangeFromPublished);
    if (canChangeFromPublished) return false;
    if (!valueLabelDto) return true;
    return valueLabelDto?.originalName === 'Published';
  }, [currentUserRole?.allowedActions, valueLabelDto]);
  const hiddenStatusIds = useMemo(() => new Set<string>(documentStatuses?.filter((s) => s.isDefault && (s.originalName === 'Archived' || s.originalName === 'Other' || (!canSetStatusToPublished && s.originalName === 'Published'))).map((s) => s.id)), [canSetStatusToPublished, documentStatuses]);
  const accessLevel = useCurrentCollaboratorFolderAccessLevel(data?.folderId);
  const disabled = useMemo(() => !documentStatuses?.length || isPending || data?.isArchived || !currentUserRole?.allowedActions?.has(RoleAction.Document_StatusEditing) || accessLevel !== CollaboratorFolderAccessLevel.ReadWrite || isLockedAtPublished, [accessLevel, currentUserRole?.allowedActions, data?.isArchived, documentStatuses?.length, isPending, isLockedAtPublished]);

  const onChange = useCallback(async (event: SelectChangeEvent<string>) => {
    if (!documentStatuses || !data || isPending) return;
    const selectedStatusId = event.target.value;
    setDisplayValue(selectedStatusId);
    const persistDto: DocumentVersionStatusUpdateDto = {
      id: data.id,
      documentStatus: selectedStatusId,
    };
    await mutateAsync(persistDto);
  }, [data, documentStatuses, isPending, mutateAsync]);

  return (
    <Select
      value={displayValue}
      disabled={disabled}
      onChange={onChange}
      variant="standard"
      sx={{
        width: '100%', height: '24px', pb: '2px', position: 'relative', top: '-1px',
      }}
    >
      {documentStatuses?.map((documentStatus) => (
        <MenuItem value={documentStatus.id} key={documentStatus.id} sx={{ display: hiddenStatusIds.has(documentStatus.id) ? 'none' : undefined }}>
          <ColoredChip entity={documentStatus} />
        </MenuItem>
      ))}
    </Select>
  );
}
