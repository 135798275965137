import React, { useContext, useMemo } from 'react';
import {
  Box, FormControl, InputLabel, Button,
} from '@mui/material';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { useTranslation } from 'react-i18next';
import ISxProps from 'common/types/ISxProps';
import ChipMultiselect from 'common/components/ChipMultiselect';
import LabelDto from 'labels/types/LabelDto';
import DocumentOptionsContext, { DocumentOptionsContextState } from 'documents/contexts/DocumentOptionsContext';
import DocumentAbbreviationDisplayMode from 'documents/types/DocumentAbbreviationDisplayMode';

interface MassEditMetaDataSelectProps extends ISxProps {
  availableEntities: LabelDto[] | undefined,
  label: string,
  selectedIds: string[] | undefined,
  onChange: (ids: string[] | undefined) => void,
  disabled?: boolean,
}

export default function MassEditMultiSelectFormControl({
  availableEntities,
  label,
  selectedIds,
  onChange,
  disabled,
  sx,
}: MassEditMetaDataSelectProps) {
  const { t } = useTranslation('documents');
  const { abbreviationDisplayMode } = useContext<DocumentOptionsContextState>(DocumentOptionsContext);
  const abbreviated = useMemo(() => abbreviationDisplayMode === DocumentAbbreviationDisplayMode.Abbreviation, [abbreviationDisplayMode]);
  return (
    <Box sx={{ ...sx, display: 'flex', alignItems: 'flex-end' }}>
      <FormControl sx={{ flexGrow: 1 }}>
        <InputLabel id="mass-edit-drawer_metadata-select-label" shrink>{label}</InputLabel>
        <ChipMultiselect
          entities={availableEntities}
          label={label}
          value={selectedIds}
          disabled={disabled}
          onChange={onChange}
          enableSetEmptyValue
          abbreviated={abbreviated}
        />
      </FormControl>
      <Box title={t('mass-edit-drawer_metadata-select_set-unchanged-button-tooltip', 'Set field unchanged')}>
        <Button
          sx={{ minWidth: 'unset', ml: 1, px: 1 }}
          color="secondary"
          variant="contained"
          onClick={() => onChange(undefined)}
          disabled={selectedIds === undefined}
        >
          <BackspaceIcon />
        </Button>
      </Box>
    </Box>
  );
}
