import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AddPanelPositionOptions, DockviewApi } from 'dockview';
import IChildren from 'common/types/IChildren';
import ModelsPanelDockviewContext, { ModelsPanelDockviewContextState, ModelsPanelDockviewPanelId } from 'models/contexts/ModelsPanelDockviewContext';
import useModelsPanelDockviewPanelTitleTranslations from 'models/hooks/useModelsPanelDockviewPanelTitleTranslations';
import RoleAction from 'projects/types/RoleAction';
import useCollaboratorPermissionQueryData from 'collaborators/hooks/useCollaboratorPermissionQueryData';

export default function ModelsPanelDockviewContextProvider({
  children,
}: IChildren) {
  const apiRef = useRef<DockviewApi>();
  const [visiblePanelIds, setVisiblePanelIds] = useState<Set<ModelsPanelDockviewPanelId>>(new Set());

  const setDockviewApi = useCallback((dockviewApi: DockviewApi) => {
    apiRef.current = dockviewApi;
    const updateVisiblePanels = () => setVisiblePanelIds(new Set(dockviewApi.panels.map((p) => p.id as ModelsPanelDockviewPanelId)));
    dockviewApi.onDidRemovePanel(() => updateVisiblePanels());
    dockviewApi.onDidAddPanel(() => updateVisiblePanels());
  }, []);

  const panelTitleTranslations = useModelsPanelDockviewPanelTitleTranslations();
  const getCollaboratorPermission = useCollaboratorPermissionQueryData();

  const showPanel = useCallback((panelId: ModelsPanelDockviewPanelId) => {
    if (!apiRef.current) throw new Error('dependency error');
    const existingPanel = apiRef.current.getPanel(panelId);
    if (existingPanel?.api.isVisible) return existingPanel;

    let position: AddPanelPositionOptions | undefined;
    if (apiRef.current.getPanel(ModelsPanelDockviewPanelId.Viewer3d)) {
      if (panelId === ModelsPanelDockviewPanelId.Issues) {
        position = { referencePanel: ModelsPanelDockviewPanelId.Viewer3d, direction: 'right' };
      }
      if (panelId === ModelsPanelDockviewPanelId.ModelSelection) {
        position = { referencePanel: ModelsPanelDockviewPanelId.Viewer3d, direction: 'left' };
      }
    }

    if (panelId === ModelsPanelDockviewPanelId.Viewer3d) {
      if (apiRef.current.getPanel(ModelsPanelDockviewPanelId.ModelSelection)) {
        position = { referencePanel: ModelsPanelDockviewPanelId.ModelSelection, direction: 'right' };
      } else {
        position = { direction: 'left' };
      }
    }

    if (panelId === ModelsPanelDockviewPanelId.ComponentDetails) {
      if (apiRef.current.getPanel(ModelsPanelDockviewPanelId.Issues)) {
        position = { referencePanel: ModelsPanelDockviewPanelId.Issues, direction: 'within' };
      } else {
        position = { direction: 'right' };
      }
    }

    if (panelId === ModelsPanelDockviewPanelId.Plan2d || panelId === ModelsPanelDockviewPanelId.SectionPlanes || panelId === ModelsPanelDockviewPanelId.Measurements) {
      const toolsPaneReference = apiRef.current.getPanel(ModelsPanelDockviewPanelId.Plan2d) ?? apiRef.current.getPanel(ModelsPanelDockviewPanelId.SectionPlanes) ?? apiRef.current.getPanel(ModelsPanelDockviewPanelId.Measurements);
      position = toolsPaneReference
        ? { referencePanel: toolsPaneReference.id, direction: 'within' }
        : apiRef.current.getPanel(ModelsPanelDockviewPanelId.Issues)
          ? { referencePanel: ModelsPanelDockviewPanelId.Issues, direction: 'below' }
          : undefined;
    }

    return apiRef.current.addPanel({
      id: panelId,
      component: panelId,
      title: panelTitleTranslations[panelId],
      position,
    });
  }, [panelTitleTranslations]);

  useEffect(() => {
    apiRef.current?.panels.forEach((panel) => {
      const title = panelTitleTranslations[panel.id as ModelsPanelDockviewPanelId];
      panel.setTitle(title);
      panel.update({ params: {} });
    });
  }, [panelTitleTranslations]);

  const hidePanel = useCallback((panelId: ModelsPanelDockviewPanelId) => {
    if (!apiRef.current) throw new Error('dependency error');
    const panel = apiRef.current.getPanel(panelId);
    if (!panel) return;
    apiRef.current.removePanel(panel);
  }, []);

  const togglePanelVisible = useCallback((panelId: ModelsPanelDockviewPanelId) => {
    if (visiblePanelIds.has(panelId)) {
      hidePanel(panelId);
    } else {
      showPanel(panelId);
    }
  }, [hidePanel, showPanel, visiblePanelIds]);

  const resetLayoutToDefault = useCallback(async () => {
    if (!apiRef.current) throw new Error('dependency error');
    apiRef.current.panels.forEach((panel) => {
      if (panel.id !== ModelsPanelDockviewPanelId.Viewer3d) {
        panel.api.close();
      }
    });
    if (!apiRef.current.getPanel(ModelsPanelDockviewPanelId.Viewer3d)) {
      showPanel(ModelsPanelDockviewPanelId.Viewer3d);
    }
    const collaboratorPermission = await getCollaboratorPermission();
    const issuesListPanel = showPanel(ModelsPanelDockviewPanelId.Issues);
    const componentDetailsPanel = showPanel(ModelsPanelDockviewPanelId.ComponentDetails);
    const modelSelectionPanel = showPanel(ModelsPanelDockviewPanelId.ModelSelection);

    const plan2dPanel = showPanel(ModelsPanelDockviewPanelId.Plan2d);
    showPanel(ModelsPanelDockviewPanelId.SectionPlanes);
    showPanel(ModelsPanelDockviewPanelId.Measurements);

    plan2dPanel.group.api.setSize({ height: 350 });
    plan2dPanel.api.setActive();
    modelSelectionPanel.group.api.setSize({ width: 350 });
    modelSelectionPanel.api.setSize({ width: 350 });
    issuesListPanel.group.api.setSize({ width: 350 });
    issuesListPanel.api.setSize({ width: 350 });
    issuesListPanel.api.setActive();
    componentDetailsPanel.api.setSize({ width: 350 });

    const viewIssuesAction = collaboratorPermission.actions.find((a) => a.action === RoleAction.Issues);
    if (!viewIssuesAction?.isAllowed) {
      // we need the issues list panel to construct the default layout. but if the user can't access issues,
      // we hide close the dockview panel again
      issuesListPanel.api.close();
    }
  }, [getCollaboratorPermission, showPanel]);

  const setPanelActive = useCallback((panelId: ModelsPanelDockviewPanelId) => {
    if (!apiRef.current) throw new Error('dependency error');
    const panel = apiRef.current.getPanel(panelId);
    if (!panel) return;
    panel.api.setActive();
  }, []);

  const state = useMemo<ModelsPanelDockviewContextState>(() => ({
    dockviewApi: apiRef.current,
    setDockviewApi,
    visiblePanelIds,
    showPanel,
    hidePanel,
    togglePanelVisible,
    setPanelActive,
    resetLayoutToDefault,
  }), [hidePanel, resetLayoutToDefault, setDockviewApi, setPanelActive, showPanel, togglePanelVisible, visiblePanelIds]);
  return (
    <ModelsPanelDockviewContext.Provider value={state}>
      {children}
    </ModelsPanelDockviewContext.Provider>
  );
}
