import Bounds from 'models/types/Bounds';
import StoreyMap from 'models/types/StoreyMap';
import { Vector3 } from "three";

export class VisoStoreyMap {
  resourceId?: string;
  name?: string;
  center: Vector3 = new Vector3();
  size: Vector3 = new Vector3();
  image?: string;
  modelFileId?: string;
  dto?: StoreyMap;
}
