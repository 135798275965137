import i18n from 'i18next';

/** actions * */
export const PROJECT_NAME = 'PROJECT_NAME';
export const ADD_MEMBERS = 'ADD_MEMBERS';
export const ADD_DISCIPLINES = 'ADD_DISCIPLINES';
export const ADD_BUILDINGS = 'ADD_BUILDINGS';
export const ADD_LAYERS = 'ADD_LAYERS';
export const ADD_WORK_STAGES = 'ADD_WORK_STAGES';

/** names of the elements */
export const INPUT_PROJECT_NAME = 'input_project_name';
export const INPUT_PROJECT_ABBREVIATION = 'input_project_abbreviation';

export const INPUT_MEMBER_EMAIL = 'input_member_email';
export const INPUT_MEMBER_ROLE = 'input_member_role';
export const BTN_ADD_COLLABORATOR = 'btn_add_collaborator';
export const BTN_MEMBER_NEXT = 'btn_member_next';
export const BTN_MEMBER_BACK = 'btn_member_back';
export const BTN_MEMER_ITEM_REMOVE = 'btn_member_item_remove';

export const INPUT_DISCIPLINE_NAME = 'input_discipline_name';
export const INPUT_DISCIPLINE_ABBREVIATION = 'input_discipline_abbreviation';
export const BTN_ADD_DISCIPLINE = 'btn_add_discipline';
export const BTN_DISCIPLINE_NEXT = 'btn_discipline_next';
export const BTN_DISCIPLINE_BACK = 'btn_discipline_back';
export const BTN_DISCIPLINE_ITEM_REMOVE = 'btn_discipline_item_remove';

export const INPUT_BUILDING_NAME = 'input_building_name';
export const INPUT_BUILDING_NUMBER = 'input_building_number';
export const BTN_ADD_BUILDING = 'btn_add_building';
export const BTN_BUILDING_NEXT = 'btn_building_next';
export const BTN_BUILDING_BACK = 'btn_building_back';
export const BTN_BUILDING_ITEM_REMOVE = 'btn_building_item_remove';

export const INPUT_LAYER_NAME = 'input_layer_name';
export const INPUT_LAYER_ABBREVIATION = 'input_layer_abbreviation';
export const BTN_ADD_STOREY = 'btn_add_storey';
export const BTN_LAYER_NEXT = 'btn_layer_next';
export const BTN_LAYER_BACK = 'btn_layer_back';
export const BTN_LAYER_ITEM_REMOVE = 'btn_layer_item_remove';

export const BTN_WORK_STAGE_NEXT = 'btn_work_stage_next';
export const BTN_WORK_STAGE_BACK = 'btn_work_stage_back';

// Mode
export const MODE_CREATE = 0;
export const MODE_EDIT = 1;
export const MODE_CREATE_COPY = 2;

export const PER_PAGE_RECORDS = 20;
export const PER_COLUMN_RECORDS = 4;

export const ABBREVIATION_MAX_LENGTH = 10;

// issue add mode
export const MODE_ADD_NONE = 10;
export const MODE_ADD_COMPONENT = 11;
export const MODE_ADD_VIEWPOINT = 12;

// 3D viewer screen
export const VIEWER_OFFSET_X = 62;
export const VIEWER_OFFSET_Y = 55;
export const VIEWER_MARGIN = 10;

// issue modal mode
export const IssueModalMode = {
  HIDE: 0,
  CREATE: 1,
  EDIT: 2,
};

export const SettingTabIndex = {
  projectRole: 1,
  userGroups: 2,
  projectEmail: 3,
  email: 4,
  tag: 5,
  encoding: 6,
  document: 7,
  issue: 8,
  bimq: 9,
};

export const IconBackColors = [
  '#0026FF',
  '#FE0000',
  '#FE6A00',
  '#FFD800',
  '#806B00',
  '#803400',
  '#800001',
  '#01FFFF',
  '#0094FE',
  '#B100FE',
  '#FF006E',
  '#017F7E',
  '#00497E',
  '#590080',
  '#7F0037',
  '#A8A8A8',
  '#545454',
  '#000000',
];

export const disciplineColors = [
  '#CE0000',
  '#00B09B',
  '#CA00CE',
  '#CE4A00',
  '#005FCE',
  '#15834E',
  '#CE7C00',
  '#0015CE',
  '#171583',
  '#CEA100',
  '#4200CE',
  '#000000',
];

export const FolderDetailUserColors = ['#7CC794', '#F9B47C', '#869FFF'];

export const downloadData = [
  {
    id: 0,
    img: '/img/sprite.svg#visoplan',
    category: 0, // 0: software, 1: plugin
    title: 'Visoplan',
    capacity: '190.0',
    link: 'https://update.visoplan.de/%1$s/Setup.exe',
  },
  {
    id: 1,
    img: '/img/sprite.svg#solibri',
    category: 1,
    title: 'Solibri',
    capacity: '40.8',
    link: 'https://get.visoplan.de/solibri/%1$s/Solibri_Plugin.exe',
  },
  {
    id: 2,
    img: '/img/sprite.svg#revit',
    category: 1,
    title: 'Revit',
    versions: [
      {
        id: 24,
        title: 'Revit 24',
        capacity: '4.8',
        link: 'https://get.visoplan.de/revit/%1$s/Revit_Plugin_2024.exe',
      },
      {
        id: 23,
        title: 'Revit 23',
        capacity: '4.8',
        link: 'https://get.visoplan.de/revit/%1$s/Revit_Plugin_2023.exe',
      },
      {
        id: 22,
        title: 'Revit 22',
        capacity: '4.8',
        link: 'https://get.visoplan.de/revit/%1$s/Revit_Plugin_2022.exe',
      },
      {
        id: 21,
        title: 'Revit 21',
        capacity: '4.8',
        link: 'https://get.visoplan.de/revit/%1$s/Revit_Plugin_2021.exe',
      },
      {
        id: 20,
        title: 'Revit 20',
        capacity: '4.8',
        link: 'https://get.visoplan.de/revit/%1$s/Revit_Plugin_2020.exe',
      },
      {
        id: 19,
        title: 'Revit 19',
        capacity: '4.8',
        link: 'https://get.visoplan.de/revit/%1$s/Revit_Plugin_2019.exe',
      },
    ],
  },
  {
    id: 3,
    img: '/img/sprite.svg#archicad',
    category: 1,
    title: 'ArchiCAD',
    versions: [
      {
        id: 0,
        title: 'Archicad 27 (Windows)',
        capacity: '0.4',
        link: 'https://get.visoplan.de/archicad/%1$s/ArchiCAD_Plugin_27.exe',
      },
      {
        id: 1,
        title: 'Archicad 27 (Mac Intel)',
        capacity: '0.4',
        link: 'https://get.visoplan.de/archicad/%1$s/ArchiCAD_Plugin_27_Mac.pkg',
      },
      {
        id: 2,
        title: 'Archicad 27 (Mac Silicon)',
        capacity: '0.4',
        link: 'https://get.visoplan.de/archicad/%1$s/ArchiCAD_Plugin_27_Mac_Apple_Silicon.pkg',
      },
      {
        id: 3,
        title: 'Archicad 26 (Windows)',
        capacity: '0.4',
        link: 'https://get.visoplan.de/archicad/%1$s/ArchiCAD_Plugin_26.exe',
      },
      {
        id: 4,
        title: 'Archicad 26 (Mac Intel)',
        capacity: '0.4',
        link: 'https://get.visoplan.de/archicad/%1$s/ArchiCAD_Plugin_26_Mac.pkg',
      },
      {
        id: 5,
        title: 'Archicad 26 (Mac Silicon)',
        capacity: '0.4',
        link: 'https://get.visoplan.de/archicad/%1$s/ArchiCAD_Plugin_26_Mac_Apple_Silicon.pkg',
      },
      {
        id: 6,
        title: 'Archicad 25 (Windows)',
        capacity: '0.4',
        link: 'https://get.visoplan.de/archicad/%1$s/ArchiCAD_Plugin_25.exe',
      },
      {
        id: 7,
        title: 'Archicad 25 (Mac Intel)',
        capacity: '0.4',
        link: 'https://get.visoplan.de/archicad/%1$s/ArchiCAD_Plugin_25_Mac.pkg',
      },
      {
        id: 9,
        title: 'Archicad 24 (Windows)',
        capacity: '0.41',
        link: 'https://get.visoplan.de/archicad/%1$s/ArchiCAD_Plugin_24.exe',
      },
      {
        id: 10,
        title: 'Archicad 24 (Mac Intel)',
        capacity: '0.4',
        link: 'https://get.visoplan.de/archicad/%1$s/ArchiCAD_Plugin_24_Mac.pkg',
      },
      {
        id: 12,
        title: 'Archicad 23 (Windows)',
        capacity: '0.39',
        link: 'https://get.visoplan.de/archicad/%1$s/ArchiCAD_Plugin_23.exe',
      },
      {
        id: 13,
        title: 'Archicad 23 (Mac Intel)',
        capacity: '0.4',
        link: 'https://get.visoplan.de/archicad/%1$s/ArchiCAD_Plugin_23_Mac.pkg',
      },
    ],
  },
  {
    id: 4,
    img: '/img/sprite.svg#navisworks',
    category: 1,
    title: 'Navisworks',
    versions: [
      {
        id: 24,
        title: 'Navisworks 2024',
        capacity: '4.9',
        link: 'https://get.visoplan.de/navisworks/%1$s/Navisworks_Plugin_2024.exe',
      },
      {
        id: 23,
        title: 'Navisworks 2023',
        capacity: '4.9',
        link: 'https://get.visoplan.de/navisworks/%1$s/Navisworks_Plugin_2023.exe',
      },
      {
        id: 22,
        title: 'Navisworks 2022',
        capacity: '4.4',
        link: 'https://get.visoplan.de/navisworks/%1$s/Navisworks_Plugin_2022.exe',
      },
      {
        id: 21,
        title: 'Navisworks 2021',
        capacity: '4.4',
        link: 'https://get.visoplan.de/navisworks/%1$s/Navisworks_Plugin_2021.exe',
      },
      {
        id: 20,
        title: 'Navisworks 2020',
        capacity: '4.4',
        link: 'https://get.visoplan.de/navisworks/%1$s/Navisworks_Plugin_2020.exe',
      },
      {
        id: 19,
        title: 'Navisworks 2019',
        capacity: '4.4',
        link: 'https://get.visoplan.de/navisworks/%1$s/Navisworks_Plugin_2019.exe',
      },
    ],
  },
];

export const SyncDataType = {
  Project: 0,
  Issue: 1,
  Comment: 2,
  Model: 3,
  Document: 4,
  Activity: 5,
  DocumentVersion: 6,
  BuildingMetaData: 7,
  DisciplineMetaData: 8,
  FloorMetaData: 9,
  IssueLog: 10,
  FolderTree: 11,
  Folder: 12,
  Process: 13,
  Tag: 14,
  DocumentList: 15,
  IssueType: 16,
  IssueStatus: 17,
  IssuePriority: 18,
  InboxEmail: 19,
};

export const SyncOperationType = {
  Create: 0,
  Update: 1,
  Delete: 2,
};

export const InvokeType = {
  WatchProjectAsync: 0,
  WatchCurrentUserAsync: 1,
  StopWatchingProjectAsync: 2,
  StopWatchingCurrentUserAsync: 3,
};

export const SubscribeType = {
  OnProjectChangedSummaryAsync: 0,
  OnUserChangedAsync: 1,
  OnUserAddedToProjectAsync: 2,
  OnUserRemovedFromProjectAsync: 3,
};

export const ApiErrorMessages = {
  phone: 'Phone is invalid',
};

export const ImageAbbreviaionProjectIds = [
  '618a4d0a731ec49823766ea0',
  '62135a20cb4743f7ed62e62e',
  '634d3b38140d91a81bf80271',
];

export const getFileTypes = () => [
  { value: 0, label: i18n.t('video', 'Video'), original: 'Video' },
  { value: 1, label: i18n.t('audio', 'Audio'), original: 'Audio' },
  { value: 2, label: i18n.t('image', 'Image'), original: 'Image' },
  { value: 3, label: i18n.t('word', 'Word'), original: 'Word' },
  {
    value: 4,
    label: i18n.t('powerpoint', 'PowerPoint'),
    original: 'PowerPoint',
  },
  { value: 5, label: i18n.t('pdf', 'PDF'), original: 'Pdf' },
  { value: 6, label: i18n.t('ifc', 'IFC'), original: 'Ifc' },
  { value: 7, label: i18n.t('excel', 'Excel'), original: 'Excel' },
  { value: 8, label: i18n.t('dwg', 'DWG'), original: 'DWG' },
  { value: 9, label: i18n.t('dxf', 'DXF'), original: 'DXF' },
  {
    value: 10,
    label: i18n.t('archicad', 'ArchiCAD'),
    original: 'ArchiCAD',
  },
  { value: 11, label: i18n.t('revit', 'Revit'), original: 'Revit' },
  { value: 12, label: i18n.t('smc', 'SMC'), original: 'SMC' },
  {
    value: 13,
    label: i18n.t('naviworks', 'Navisworks'),
    original: 'Navisworks',
  },
  { value: 14, label: i18n.t('misc', 'Misc'), original: 'Misc' },
];

export const FileTypeIndexes = {
  Video: 0,
  Audio: 1,
  Image: 2,
  Word: 3,
  PowerPoint: 4,
  Pdf: 5,
  Ifc: 6,
  Excel: 7,
  DWG: 8,
  DXF: 9,
  ArchiCAD: 10,
  Revit: 11,
  SMC: 12,
  Navisworks: 13,
  Misc: 14,
};

export const ActivityLogTypes = {
  IssueCreated: 0,
  IssueStatusUpdated: 1,
  ReportUploaded: 2,
  ReportBcfExported: 3,
  ReportPdfExported: 4,
  ModelUploaded: 5,
  DocumentUploaded: 6,
  DocumentDownloaded: 7,
  DocumentSetDiscipline: 8,
  DocumentSetBuilding: 9,
  DocumentSetFloor: 10,
  DocumentSetFolder: 11,
  DocumentSetWorkPhase: 12,
  IssueTitleUpdated: 13,
  IssuePriorityUpdated: 14,
  IssueTypeUpdated: 15,
  IssueDueDateUpdated: 16,
  IssueDescriptionUpdated: 17,
  IssueDisciplinesUpdated: 18,
  IssueCommentCreated: 19,
  DocumentShareLinkCreated: 20,
  ProjectUserAdded: 21,
  ProjectUserRemoved: 22,
  ProjectNameUpdated: 23,
  ProjectAbbreviationUpdated: 24,
  ProjectBuildingRemoved: 25,
  ProjectBuildingUpdated: 26,
  ProjectBuildingAdded: 27,
  ProjectDisciplineRemoved: 28,
  ProjectDisciplineUpdated: 29,
  ProjectDisciplineAdded: 30,
  ProjectLayerRemoved: 31,
  ProjectLayerUpdated: 32,
  ProjectLayerAdded: 33,
  ProjectUserRoleChanged: 34,
  DocumentDownloadedViaSharedLink: 35,
  FolderCreated: 36,
  SubFolderCreated: 37,
  DocAddedToFolder: 38,
  DocMovedToFolder: 39,
  PrivateDocMovedToPublicFolder: 40,
  FolderDeleted: 41,
  SubFolderDeleted: 42,
  DocumentCommentCreated: 43,
  DocumentCommentDeleted: 44,
  DocumentTagAdded: 45,
  DocumentTagDeleted: 46,
  ModelTagAdded: 47,
  ModelTagDeleted: 48,
  DocMovedToArchive: 49,
  IssueFloorsUpdated: 50,
  IssueBuildingsUpdated: 51,
  IssueTagAdded: 52,
  IssueTagDeleted: 53,
  IssueStartingDateUpdated: 54,
  IssueWorkPhaseUpdated: 55,
  IssueVisibilityUpdated: 56,
  IssueAllowedRolesAdded: 57,
  IssueLinkedEmailAdded: 58,
  IssueLinkedEmailDeleted: 59,
  EmailReceived: 60,
  EmailTagAdded: 61,
  EmailTagDeleted: 62,
  DocumentCreatedFromEmailAttachment: 63,
  ProcessCreated: 64,
  ProcessUpdated: 65,
  ProcessDeleted: 66,
  ProcessStatusUpdated: 67,
  ProcessPropertyMapUpdated: 68,
  TagAdded: 69,
  TagDeleted: 70,
  TagUpdated: 71,
  RoleDefinitionAdded: 72,
  RoleDefinitionUpdated: 73,
  RoleDefinitionRemoved: 74,
  DocRestoredFromArchive: 75,
  NamingSchemeCreated: 76,
  NamingSchemeUpdated: 77,
  NamingSchemeDeleted: 78,
  ProjectNamingSettingsUpdate: 79,
  DocumentListCreated: 80,
  DocumentListDeleted: 81,
  DocumentListUpdated: 82,
  DocumentDownloadedForPreview: 83,
  QueryFilterCreated: 84,
  QueryFilterUpdated: 85,
  QueryFilterDeleted: 86,
  DocStatusEntityCreated: 87,
  DocStatusEntityUpdated: 88,
  DocStatusEntityDeleted: 89,
  DocumentVersionStatusUpdated: 90,
  IssueStatusEntityCreated: 91,
  IssueStatusEntityUpdated: 92,
  IssueStatusEntityDeleted: 93,
  IssueStatusIdUpdated: 94,
  IssueTypeEntityCreated: 95,
  IssueTypeEntityUpdated: 96,
  IssueTypeEntityDeleted: 97,
  IssuePriorityEntityCreated: 98,
  IssuePriorityEntityUpdated: 99,
  IssuePriorityEntityDeleted: 100,
  IssueTypeIdUpdated: 101,
  IssuePriorityIdUpdated: 102,
  PdfAnnotationCreated: 103,
  PdfAnnotationUpdated: 104,
  PdfAnnotationDeleted: 105,
  InboxRenamed: 106,
  InboxEmailUploaded: 107,
  PdfAnnotationForDocumentUpdated: 108,
};

export const DocumentOptionVersions = {
  All: 0,
  Actual: 1,
};

export const DocumentOptionColumns = {
  Abbreviation: 0,
  FullLength: 1,
};

export const CalendarViewCategory = {
  month: 0,
  week: 1,
};

export const IssueCalendarCategory = {
  dueDate: 0,
  startDate: 1,
  activeIssue: 2,
};

export const availableLanguages = [
  { code: 'de-DE', text: 'DE' },
  { code: 'en-US', text: 'EN' },
];

export const getColumnsForDocuments = () => [
  {
    id: 1,
    key: 'name',
    name: i18n.t('Document Name', {
      ns: 'docFields',
      defaultValue: 'Document Name',
    }),
    value: 'Original File Name',
    disable: true,
  },
  {
    id: 2,
    key: 'versionNumber',
    name: i18n.t('Version', { ns: 'docFields', defaultValue: 'Version' }),
    value: 'Version',
  },
  {
    id: 3,
    key: 'fileType',
    name: i18n.t('Format', { ns: 'docFields', defaultValue: 'Format' }),
    value: 'Format',
  },
  {
    id: 4,
    key: 'docStatus',
    name: i18n.t('Status', { ns: 'docFields', defaultValue: 'Status' }),
    value: 'Status',
  },
  {
    id: 5,
    key: 'archived_date',
    name: i18n.t('upload_date', {
      ns: 'docFields',
      defaultValue: 'Upload Date',
    }),
    value: 'Upload Date',
  },
  {
    id: 6,
    key: 'category',
    name: i18n.t('Category', { ns: 'docFields', defaultValue: 'Category' }),
    value: 'Discipline',
  },
  {
    id: 7,
    key: 'building',
    name: i18n.t('Building', { ns: 'docFields', defaultValue: 'Building' }),
    value: 'Building',
  },
  {
    id: 8,
    key: 'floor',
    name: i18n.t('Floor', { ns: 'docFields', defaultValue: 'Floor' }),
    value: 'Floor',
  },
  {
    id: 9,
    key: 'workphase',
    name: i18n.t('workphase', { ns: 'docFields', defaultValue: 'Work Phase' }),
    value: 'Work Phase',
  },
  {
    id: 10,
    key: 'linkedIssueIds',
    name: i18n.t('Issues', { ns: 'docFields', defaultValue: 'Issues' }),
    value: 'Linked Issues',
  },
  {
    id: 11,
    key: 'editDate',
    name: i18n.t('edit_date', { ns: 'docFields', defaultValue: 'Edit Date' }),
    value: 'Edit Date',
  },
  {
    id: 12,
    key: 'creator',
    name: i18n.t('uploaded_by', {
      ns: 'docFields',
      defaultValue: 'Uploaded by',
    }),
    value: 'Uploaded by',
  },
  {
    id: 13,
    key: 'editor',
    name: i18n.t('last_edited_by', {
      ns: 'docFields',
      defaultValue: 'Last Edited by',
    }),
    value: 'Last Edited by',
  },
  {
    id: 14,
    key: 'commentIds',
    name: i18n.t('Comments', { ns: 'docFields', defaultValue: 'Comments' }),
    value: 'Comments',
  },
  {
    id: 15,
    key: 'creation_date',
    name: i18n.t('creation_date', {
      ns: 'docFields',
      defaultValue: 'Creation Date',
    }),
    value: 'Creation Date',
  },
  {
    id: 16,
    key: 'fileSize',
    name: i18n.t('file_size', { ns: 'docFields', defaultValue: 'File Size' }),
    value: 'File Size',
  },
  {
    id: 17,
    key: 'tagIds',
    name: i18n.t('Tags', { ns: 'docFields', defaultValue: 'Tags' }),
    value: 'Tags',
  },
];

export const getColumnsForEmail = () => [
  {
    id: 1,
    key: 'subject',
    name: i18n.t('subject', 'Subject'),
    value: 'Subject',
    disable: true,
  },
  {
    id: 2,
    key: 'creationDate',
    name: i18n.t('date', 'Date'),
    value: 'Creation Date',
  },
  {
    id: 3, key: 'from', name: i18n.t('sent_by', 'Sent by'), value: 'Sent by',
  },
  {
    id: 4,
    key: 'to',
    name: i18n.t('recipient', 'Recipient'),
    value: 'Recipient',
  },
  {
    id: 5,
    key: 'linkedIssueIds',
    name: i18n.t('issues', 'Issues'),
    value: 'Issue',
  },
  {
    id: 6,
    key: 'attachments',
    name: i18n.t('attachments', 'Attachments'),
    value: 'Attachment',
  },
  {
    id: 7, key: 'tagIds', name: i18n.t('tags', 'Tags'), value: 'Tags',
  },
  {
    id: 8, key: 'emailBody', name: i18n.t('text', 'Text'), value: 'Text',
  },
  {
    id: 9, key: 'cc', name: i18n.t('cc', 'CC'), value: 'CC',
  },
];

export const getColumnsForProperty = () => [
  {
    id: 1,
    key: 'componentType',
    name: i18n.t('component_type', 'Component Type'),
    value: 'componentType',
    disable: true,
  },
  {
    id: 2,
    key: 'property',
    name: i18n.t('property_name', 'Property Name'),
    value: 'property',
  },
  {
    id: 3,
    key: 'pSet',
    name: i18n.t('pset', 'PSet'),
    value: 'pSet',
  },
  {
    id: 4,
    key: 'ifcType',
    name: i18n.t('property_type', 'Property Type'),
    value: 'ifcType',
  },
  {
    id: 5,
    key: 'unit',
    name: i18n.t('unit', 'Unit'),
    value: 'unit',
  },
  {
    id: 6,
    key: 'disciplineIds',
    name: i18n.t('discipline', 'Discipline'),
    value: 'disciplineIds',
  },
  {
    id: 7,
    key: 'buildingIds',
    name: i18n.t('building', 'Building'),
    value: 'buildingIds',
  },
  {
    id: 8,
    key: 'floorIds',
    name: i18n.t('floor', 'Floor'),
    value: 'floorIds',
  },
  {
    id: 9,
    key: 'baseType',
    name: i18n.t('base_type', 'Base Type'),
    value: 'baseType',
  },
  {
    id: 10,
    key: 'values',
    name: i18n.t('value', 'Value'),
    value: 'values',
  },
];

export const getColumnsForIssues = () => [
  {
    id: 1,
    key: 'issueNumber',
    name: i18n.t('id', 'ID'),
    value: 'Number',
  },
  {
    id: 2,
    key: 'title',
    name: i18n.t('issue_name', 'Issue Name'),
    value: 'Name',
    disable: true,
  },
  {
    id: 3,
    key: 'description',
    name: i18n.t('description', 'Description'),
    value: 'Description',
  },
  {
    id: 4,
    key: 'issueStatus',
    name: i18n.t('status', 'Status'),
    value: 'Status',
  },
  {
    id: 5,
    key: 'dueDate',
    name: i18n.t('due_date', 'Due date'),
    value: 'Due Date',
  },
  {
    id: 6,
    key: 'disciplines',
    name: i18n.t('category', 'Category'),
    value: 'Discipline',
  },
  {
    id: 7, key: 'models', name: i18n.t('models', 'Models'), value: 'Models',
  },
  {
    id: 8,
    key: 'issuePriority',
    name: i18n.t('priority', 'Priority'),
    value: 'Priority',
  },
  {
    id: 9,
    key: 'assignedUsers',
    name: i18n.t('assigned_to', 'Assigned to'),
    value: 'Assigned to',
  },
  {
    id: 10,
    key: 'reviewer',
    name: i18n.t('reviewer', 'Reviewer'),
    value: 'Reviewer',
  },
  {
    id: 11,
    key: 'workPhaseId',
    name: i18n.t('workphase', 'Workphase'),
    value: 'Workphase',
  },
  {
    id: 12,
    key: 'editDate',
    name: i18n.t('edit_date', 'Edit Date'),
    value: 'Edit Date',
  },
  {
    id: 13,
    key: 'createAuthor',
    name: i18n.t('creator', 'Creator'),
    value: 'Creator',
  },
  {
    id: 14,
    key: 'creationDate',
    name: i18n.t('creation_date', 'Creation date'),
    value: 'Creation date',
  },
  {
    id: 15,
    key: 'startingDate',
    name: i18n.t('start_date', 'Start Date'),
    value: 'Start Date',
  },
  {
    id: 16,
    key: 'editAuthor',
    name: i18n.t('last_edited_by', 'Last Edited by'),
    value: 'Last Edited by',
  },
  {
    id: 17, key: 'floors', name: i18n.t('floor', 'Storey'), value: 'Storey',
  },
  {
    id: 18,
    key: 'buildings',
    name: i18n.t('building', 'Building'),
    value: 'Building',
  },
  {
    id: 19,
    key: 'viewpointIds',
    name: i18n.t('viewpoints', 'Viewpoints'),
    value: 'Viewpoints',
  },
  {
    id: 20, key: 'source', name: i18n.t('source', 'Source'), value: 'Source',
  },
  {
    id: 21,
    key: 'linkedDocumentVersionIds',
    name: i18n.t('documents', 'Documents'),
    value: 'Documents (amount of Documents)',
  },
  {
    id: 22,
    key: 'visibility',
    name: i18n.t('visibility', 'Visibility'),
    value: 'Visibility',
  },
  {
    id: 23,
    key: 'linkedEmailIds',
    name: i18n.t('linked_emails', 'Linked Emails'),
    value: 'Linked Emails',
  },
  {
    id: 24,
    key: 'commentIds',
    name: i18n.t('comments', 'Comments'),
    value: 'Comments',
  },
  {
    id: 25, key: 'tagIds', name: i18n.t('tags', 'Tags'), value: 'Tags',
  },
];

// eslint-disable-next-line @typescript-eslint/naming-convention
export const Timeframe_Categories = {
  today: 0,
  yesterday: 1,
  this_week: 2,
  last_week: 3,
  this_month: 4,
  last_month: 5,
};

export const getTimeframeOptions = () => ([
  { value: Timeframe_Categories.today, label: i18n.t('today', 'Today') },
  { value: Timeframe_Categories.yesterday, label: i18n.t('yesterday', 'Yesterday') },
  { value: Timeframe_Categories.this_week, label: i18n.t('this_week', 'This Week') },
  { value: Timeframe_Categories.last_week, label: i18n.t('last_week', 'Last Week') },
  { value: Timeframe_Categories.this_month, label: i18n.t('this_month', 'This Month') },
  { value: Timeframe_Categories.last_month, label: i18n.t('last_month', 'Last Month') },
]);

export const ESCAPE_KEY_CODE = 27;

// eslint-disable-next-line @typescript-eslint/naming-convention
export const Archived_View_Status = {
  none: -1,
  private: 0,
  public: 1,
};

export enum NormalButtonCategory {
  primary = 'btn--primary',
  secondary = 'btn--secondary',
}

export const IDLength = 24;
