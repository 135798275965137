import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import LabelChip from 'labels/components/LabelChip';
import useLabelsOdataQuery from 'labels/hooks/useLabelsOdataQuery';
import { Box, Chip, CircularProgress, styled, Tooltip, tooltipClasses, TooltipProps, useTheme } from '@mui/material';
import IssueDto from 'issues/types/IssueDto';
import LabelIdsChipsCellTooltip from 'issues/components/LabelIdsChipsCellTooltip';

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
    padding: '4px',
    borderRadius: '8px',
    border: `2px solid ${theme.palette.grey[300]}`,
    boxShadow: '0px 0px 24px -8px rgba(0,0,0,0.2)',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
}));

export default function LabelIdsChipsCell({
  value,
}: ICellRendererParams<IssueDto, string[]>) {
  const theme = useTheme();
  const { data: labels } = useLabelsOdataQuery({ filter: { id: { in: value } } });
  const chipListRef = useRef<HTMLDivElement>(null);
  const chipListWrapperRef = useRef<HTMLDivElement>(null);
  const contentWrapper = useRef<HTMLDivElement>(null);

  const itemsMapRef = useRef<Map<string, HTMLDivElement> | null>(null);
  const getItemsMap = useCallback(() => {
    // Initialize the Map on first usage.
    if (!itemsMapRef.current) itemsMapRef.current = new Map<string, HTMLDivElement>();
    return itemsMapRef.current;
  }, []);
  const [visibleTagIdsSet, setVisibleTagIdsSet] = useState<ReadonlySet<string> | undefined>(undefined);

  const intersectionObserver = useMemo(() => new IntersectionObserver((entries) => {
    setVisibleTagIdsSet((prev) => {
      const nextVisibleTagIdsSet = new Set<string>(prev ? Array.from(prev) : []);
      entries.forEach((e) => {
        const tagId = e.target.getAttribute('data-id');
        if (tagId) {
          if (e.isIntersecting) nextVisibleTagIdsSet.add(tagId);
          else nextVisibleTagIdsSet.delete(tagId);
        }
      });
      return nextVisibleTagIdsSet;
    });
  }, { root: contentWrapper.current }), [contentWrapper]);

  const chipRefCallback = useCallback((id: string, node: HTMLDivElement | null) => {
    const map = getItemsMap();
    if (node && map) {
      map.set(id, node);
      intersectionObserver.observe(node); // when a new chip is selected, start observing it's visibility
    } else {
      map.delete(id);
    }
  }, [getItemsMap, intersectionObserver]);

  const labelItems = useMemo(() => labels?.map((label) => ({
    label,
    ref: (element: HTMLDivElement | null) => chipRefCallback(label.id, element),
  })), [chipRefCallback, labels]);

  const showOnlyCount = false;
  const hiddenChipsCount = useMemo(() => (labels && visibleTagIdsSet ? labels.length - visibleTagIdsSet.size : undefined), [labels, visibleTagIdsSet]);

  if (!value?.length) return null;
  if (!labelItems) return <CircularProgress size={12} />;
  return (
    <NoMaxWidthTooltip enterDelay={500} title={<LabelIdsChipsCellTooltip labels={labels} sx={{ maxWidth: 400 }} />}>
      <Box
        sx={{
          display: 'inline-flex', position: 'absolute', top: 0, left: 16, right: 16, bottom: 0, overflow: 'visible',
        }}
        ref={contentWrapper}
      >
        <Box
          sx={{
            minWidth: 0, width: '100%', overflow: 'hidden', boxSizing: 'border-box',
          }}
          ref={chipListWrapperRef}
        >
          <Box
            ref={chipListRef}
            sx={{
              display: 'inline-flex', columnGap: '4px', rowGap: 2, flexWrap: 'wrap', visibility: showOnlyCount ? 'hidden' : undefined,
            }}
          >
            {labelItems.map(({ label, ref }) => <Box ref={ref} data-id={label.id} key={label.id}><LabelChip label={label} suppressTooltip /></Box>)}
          </Box>
        </Box>
        {!!hiddenChipsCount && (
          <Box sx={{ pl: 0.5 }}>
            <Chip label={`+${hiddenChipsCount}`} size="small" sx={{ '.MuiChip-label': { paddingLeft: '4px', paddingRight: '4px', fontWeight: 600, color: theme.palette.grey[700] } }} />
          </Box>
        )}
      </Box>
    </NoMaxWidthTooltip>
  );
}
