import FolderFilterContext, { FolderFilterContextState } from 'documents-folders/contexts/FolderFilterContext';
import Folder from 'documents-folders/types/Folder';
import FolderSortMode from 'documents-folders/types/FolderSortMode';
import React, {
  ReactNode, useState, useMemo, useCallback, useEffect,
} from 'react';

interface FolderFilterContextProviderProps {
  children?: ReactNode,
}

export default function FolderFilterContextProvider({
  children,
}: FolderFilterContextProviderProps) {
  const [searchString, setSearchString] = useState<string | undefined>(undefined);
  const isFilterActive = useMemo(() => !!searchString?.length, [searchString]);
  const filterPredicate = useCallback((folder: Folder) => !searchString || folder.name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()), [searchString]);
  const [sortMode, setSortMode] = useState<FolderSortMode>(localStorage.getItem('folderSortMode') as FolderSortMode ?? FolderSortMode.CreationDateDesc);

  useEffect(() => {
    const storedSortMode = localStorage.getItem('folderSortMode') as FolderSortMode | null;
    if (sortMode !== storedSortMode) localStorage.setItem('folderSortMode', sortMode);
  }, [sortMode]);

  const state: FolderFilterContextState = useMemo(() => ({
    isFilterActive,
    searchString,
    setSearchString,
    filterPredicate,
    sortMode,
    setSortMode,
  }), [filterPredicate, searchString, isFilterActive, sortMode, setSortMode]);

  return (
    <FolderFilterContext.Provider value={state}>
      {children}
    </FolderFilterContext.Provider>
  );
}
