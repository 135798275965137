import React, { useCallback, useState } from 'react';
import { Box, Button, Typography, styled, useTheme } from '@mui/material';
import Icon from '@mdi/react';
import { mdiFilterOff, mdiContentSaveAlert, mdiDelete, mdiContentSavePlus, mdiTableFilter } from '@mdi/js';
import useIssueQueryFiltersOdataQuery from 'query-filters/hooks/useIssueQueryFiltersOdataQuery';
import useIssuesFilterContext from 'issues/hooks/useIssuesFilterContext';
import SaveFilterDialog from 'query-filters/components/SaveFilterDialog';
import { useTranslation } from 'react-i18next';
import useLanguageCode from 'common/hooks/useLanguageCode';
import DeleteFilterDialog from 'query-filters/components/DeleteFilterDialog';
import InlineTypography from 'common/components/InlineTypography';
import CollapseHorizontalIcon from 'img/components/CollapseHorizontalIcon';
import ExpandHorizontalIcon from 'img/components/ExpandHorizontalIcon';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';

const StyledButtonWithIcon = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'left',
  columnGap: '12px',
  borderRadius: '4px',
  fontWeight: 400,
  color: theme.palette.text.primary,
  '&.active': {
    fontWeight: 600,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
  },
}));

interface IssuesFilterNavigationSidebarProps {
  collapsed: boolean,
  onChangeCollapsed: (collapsed: boolean) => void,
}

export default function IssuesFilterNavigationSidebar({
  collapsed,
  onChangeCollapsed,
}: IssuesFilterNavigationSidebarProps) {
  const { t } = useTranslation('issues');
  const theme = useTheme();
  const { queryFilterState, setQueryFilterId, queryFilterId } = useIssuesFilterContext();
  const {
    loadedQueryFilter,
    loadedQueryFilterHasLocalEdits,
    isAppliedFilterStateEmpty,
    resetFilterState,
  } = queryFilterState;
  const onClickResetQueryFilter = useCallback(() => {
    setQueryFilterId(undefined);
    resetFilterState();
  }, [resetFilterState, setQueryFilterId]);
  const languageCode = useLanguageCode();
  const { data: queryFilters } = useIssueQueryFiltersOdataQuery({ orderBy: `name/${languageCode} asc` });
  const [saveFilterDialogOpen, setSaveFilterDialogOpen] = useState<boolean>(false);
  const onClickSaveSaveAsNewFilter = useCallback(() => setSaveFilterDialogOpen(true), []);
  const onCloseSaveFilterDialog = useCallback(async () => {
    setSaveFilterDialogOpen(false);
  }, []);
  const [deleteFilterDialogOpen, setDeleteFilterDialogOpen] = useState<boolean>(false);
  const onClickDeleteLoadedFilter = useCallback(() => setDeleteFilterDialogOpen(true), []);
  const onCloseDeleteFilterDialog = useCallback(() => setDeleteFilterDialogOpen(false), []);
  const onClickQueryFilter = useCallback((id: string | undefined) => {
    setQueryFilterId(id);
  }, [setQueryFilterId]);

  const onClickToggleCollapsed = useCallback(() => onChangeCollapsed(!collapsed), [collapsed, onChangeCollapsed]);

  return (
    <Box
      id="IssuesFilterNavigationSidebar"
      sx={{
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'flex-start',
        justifyContent: 'flex-start',
      }}
    >
      <Box sx={{ px: collapsed ? 0.5 : 2, py: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box sx={{ flex: '0 0 0', display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: collapsed ? 'center' : 'space-between' }}>
            {!collapsed && <Typography variant="h5" sx={{ pt: '1px' }}>{t('issues-filter-navigation-sidebar_title', 'Views')}</Typography>}
            <Button size="small" color="inherit" sx={{ minWidth: 'unset' }} onClick={onClickToggleCollapsed}>
              {!collapsed && <CollapseHorizontalIcon fontSize="small" htmlColor={theme.palette.grey[700]} />}
              {!!collapsed && <ExpandHorizontalIcon fontSize="small" htmlColor={theme.palette.grey[700]} />}
            </Button>
          </Box>
          {!collapsed && (
            <Box sx={{ display: 'flex', gap: 0.5 }}>
              <Button
                onClick={onClickSaveSaveAsNewFilter}
                disabled={isAppliedFilterStateEmpty}
                variant="contained"
                color="primary"
                size="small"
                sx={{ minWidth: 'unset' }}
                title={t('issues-filter-navigation-sidebar_save-as-new-filter-button-title', 'Save as new filter')}
              >
                <Icon path={mdiContentSavePlus} size={0.75} />
              </Button>
              <Button
                onClick={onClickDeleteLoadedFilter}
                disabled={!loadedQueryFilter || loadedQueryFilterHasLocalEdits}
                variant="contained"
                color="primary"
                size="small"
                sx={{ minWidth: 'unset' }}
                title={t('issues-filter-navigation-sidebar_delete-filter-button-title', 'Delete')}
              >
                <Icon path={mdiDelete} size={0.75} />
              </Button>
            </Box>
          )}
        </Box>
        {!collapsed && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {((!loadedQueryFilter && !isAppliedFilterStateEmpty) || loadedQueryFilterHasLocalEdits) && (
            <StyledButtonWithIcon
              onClick={onClickSaveSaveAsNewFilter}
              className="active"
              title={t('issues-filter-navigation-sidebar_unsaved-filter', 'Unsaved View')}
            >
              <Icon path={mdiContentSaveAlert} size={0.8} />
              <InlineTypography sx={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
              >
                {t('issues-filter-navigation-sidebar_unsaved-filter', 'Unsaved View')}
              </InlineTypography>
            </StyledButtonWithIcon>
            )}
            <StyledButtonWithIcon
              onClick={onClickResetQueryFilter}
              className={!queryFilterId && isAppliedFilterStateEmpty ? 'active' : undefined}
              title={t('issues-filter-navigation-sidebar_all-issues-button-label', 'All Issues')}
            >
              <Icon path={mdiFilterOff} size={0.8} />
              <InlineTypography sx={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
              >
                {t('issues-filter-navigation-sidebar_all-issues-button-label', 'All Issues')}
              </InlineTypography>
            </StyledButtonWithIcon>
            {!queryFilters && <CenteredCircularProgress sx={{ mt: 2 }} />}
            {!!queryFilters && queryFilters.map((queryFilter) => (
              <StyledButtonWithIcon
                key={queryFilter.id}
                onClick={() => onClickQueryFilter(queryFilter.id)}
                className={queryFilterId === queryFilter.id && !loadedQueryFilterHasLocalEdits ? 'active' : undefined}
                title={queryFilter.name[languageCode]}
              >
                <Icon path={mdiTableFilter} size={0.8} />
                <InlineTypography sx={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
                >
                  {queryFilter.name[languageCode]}
                </InlineTypography>
              </StyledButtonWithIcon>
            ))}
          </Box>
        )}
        {!!saveFilterDialogOpen && <SaveFilterDialog onClose={onCloseSaveFilterDialog} />}
        {!!deleteFilterDialogOpen && <DeleteFilterDialog onClose={onCloseDeleteFilterDialog} />}
      </Box>
    </Box>
  );
}
