import useCurrentCollaboratorQuery from 'collaborators/hooks/useCurrentCollaboratorQuery';
import CollaboratorFolderAccessLevel from 'documents-folders/types/CollaboratorFolderAccessLevel';
import FolderAccessType from 'documents-folders/types/FolderAccessType';
import FolderDto from 'documents-folders/types/FolderDto';
import { useCallback } from 'react';

export default function useFolderAccessLevel() {
  const { data: currentCollaborator } = useCurrentCollaboratorQuery();
  return useCallback((folder: FolderDto) => {
    if (!folder.hasFolderAccess || !currentCollaborator) return CollaboratorFolderAccessLevel.None;
    const readOnlyGroupIdsSet = new Set<string>(folder.readOnlyGroupsIds);
    const readOnlyCollaboratorIdsSet = new Set<string>(folder.readOnlyCollaboratorIds);
    const addedGroupIdsSet = new Set<string>(folder.accessGroupsIds);
    const addedCollaboratorIdsSet = new Set<string>(folder.accessCollaboratorIds);
    if (folder.accessType === FolderAccessType.Private) {
      const isCreator = currentCollaborator.id === folder.creatorId;
      return isCreator ? CollaboratorFolderAccessLevel.ReadWrite : CollaboratorFolderAccessLevel.None;
    }
    if (folder.accessType === FolderAccessType.Public) {
      const readOnly = readOnlyCollaboratorIdsSet.has(currentCollaborator.id) || currentCollaborator.collaboratorGroupIds.some((id) => readOnlyGroupIdsSet.has(id));
      return readOnly ? CollaboratorFolderAccessLevel.Read : CollaboratorFolderAccessLevel.ReadWrite;
    }
    if (folder.accessType === FolderAccessType.Restricted) {
      const hasUserWriteRight = addedCollaboratorIdsSet.has(currentCollaborator.id) && !readOnlyCollaboratorIdsSet.has(currentCollaborator.id);
      if (hasUserWriteRight) return CollaboratorFolderAccessLevel.ReadWrite;
      const hasGroupWriteRight = currentCollaborator.collaboratorGroupIds.some((groupId) => addedGroupIdsSet.has(groupId) && !readOnlyGroupIdsSet.has(groupId));
      if (hasGroupWriteRight) return CollaboratorFolderAccessLevel.ReadWrite;
      const hasAccess = addedCollaboratorIdsSet.has(currentCollaborator.id) || currentCollaborator.collaboratorGroupIds.some((id) => addedGroupIdsSet.has(id));
      if (hasAccess) return CollaboratorFolderAccessLevel.Read;
      return CollaboratorFolderAccessLevel.None;
    }
    return CollaboratorFolderAccessLevel.None;
  }, [currentCollaborator]);
}
