import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import EmailSettingsDto from 'emails/types/EmailSettingsDto';
import UpdateEmailSettingsDto from 'emails/types/UpdateEmailSettingsDto';
import EmailSettings from 'emails/types/EmailSettings';
import { useCallback } from 'react';

export default function useEmailSettingsUpdateMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.EmailSettings);
  const mutationFn = useCallback(async (dto: UpdateEmailSettingsDto) => {
    const { data } = await axiosInstance.put<EmailSettingsDto>(`${ApiEndpoint.EmailSettings}`, dto);
    return data;
  }, [axiosInstance]);
  const onSuccess = useCallback(() => {
    queryKeyBases.forEach((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
  }, [queryClient, queryKeyBases]);
  return useMutation<EmailSettingsDto, unknown, UpdateEmailSettingsDto, EmailSettings>({
    mutationFn,
    onSuccess,
  });
}
