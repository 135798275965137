import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import UserLanguageUpdateDto from 'users/types/UserLanguageUpdateDto';
import ErrorResponseData from 'api/types/ErrorResponseData';
import UserDto from 'users/types/UserDto';

export default function useCurrentUserLanguageMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (dto: UserLanguageUpdateDto) => {
      await axiosInstance.patch<undefined | ErrorResponseData>('/user/current/language', dto);
    },
    onMutate: async (dto: UserLanguageUpdateDto) => {
      const previousCurrentUser = queryClient.getQueryData<UserDto>(['user', 'current']);
      const optimisticallyUpdatedUser = { ...previousCurrentUser };
      optimisticallyUpdatedUser.langType = dto.langType;
      queryClient.setQueryData(['user', 'current'], optimisticallyUpdatedUser);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user', 'current'] });
    },
  });
}
