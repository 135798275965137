import { useQueryClient } from '@tanstack/react-query';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import { useCallback } from 'react';

export default function useDetailsQueryData<TEndpoint extends ApiEndpoint, TDto>(endpoint: TEndpoint) {
  const queryClient = useQueryClient();
  const { getDetailsByIdQueryKey } = useDefaultEntityQueryKeys(endpoint);
  return useCallback(async (id: string) => {
    const queryKey = getDetailsByIdQueryKey(id);
    return await queryClient.fetchQuery<TDto>({ queryKey });
  }, [getDetailsByIdQueryKey, queryClient]);
}
