import React, { useMemo } from 'react';
import ISxProps from 'common/types/ISxProps';
import {
  Tooltip, TooltipProps, tooltipClasses, Button, styled, useTheme, List, ListSubheader, ListItem, Box,
} from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PersonIcon from '@mui/icons-material/Person';
import useCollaboratorGroupsQuery from 'collaborators-groups/hooks/useCollaboratorGroupsQuery';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import { useTranslation } from 'react-i18next';
import useCollaboratorsQuery from 'collaborators/hooks/useCollaboratorsQuery';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    borderColor: theme.palette.text.secondary,
    borderWidth: '1px',
    borderStyle: 'solid',
    padding: '4px 8px',
    boxShadow: '0 0 4px 4px rgba(141, 141, 141, 0.125)',
    fontSize: '12px',
  },
}));

interface GroupMemberListTooltipButtonProps extends ISxProps {
  groupId: string,
}

export default function GroupMemberListTooltipButton({
  sx,
  groupId,
}: GroupMemberListTooltipButtonProps) {
  const { t } = useTranslation('collaborators-groups');
  const theme = useTheme();
  const { data: userGroups } = useCollaboratorGroupsQuery();
  const group = useMemo(() => userGroups?.find((g) => g.id === groupId), [userGroups, groupId]);
  const { data: collaborators } = useCollaboratorsQuery();

  const groupCollaborators = useMemo(() => collaborators?.filter((c) => c.collaboratorGroupIds.includes(groupId)), [collaborators, groupId]);
  return (
    <StyledTooltip
      enterDelay={250}
      enterNextDelay={250}
      title={!!group && !!groupCollaborators ? (
        <List>
          <ListSubheader>
            {t('group-member-list-tooltip_title', 'Collaborators in "{{groupName}}"', { groupName: group.name })}
          </ListSubheader>
          {groupCollaborators.length > 0 && groupCollaborators.map((c) => (
            <ListItem key={c.id}>
              <PersonIcon sx={{ mr: 1 }} fontSize="small" />
              {`${c.firstName} ${c.lastName}`}
            </ListItem>
          ))}
          {groupCollaborators.length === 0 && <Box sx={{ p: 1, pl: 2, fontStyle: 'italic' }}>{t('group-member-list-tooltip_empty-group-list', 'Empty')}</Box>}
        </List>
      ) : <CenteredCircularProgress />}
    >
      <Button
        size="small"
        sx={{
          py: 0.5, px: 0.5, minWidth: 'unset', ...sx,
        }}
      >
        <FormatListBulletedIcon
          fontSize="small"
          htmlColor={theme.palette.text.secondary}
        />
      </Button>
    </StyledTooltip>
  );
}
