import Folder from 'documents-folders/types/Folder';
import FolderSortMode from 'documents-folders/types/FolderSortMode';
import React, { Dispatch, SetStateAction } from 'react';

export interface FolderFilterContextState {
  isFilterActive: boolean,
  searchString: string | undefined,
  setSearchString: Dispatch<SetStateAction<string | undefined>>,
  filterPredicate: (folder: Folder) => boolean,
  sortMode: FolderSortMode,
  setSortMode: Dispatch<SetStateAction<FolderSortMode>>,
}

const defaultValue: FolderFilterContextState = {
  isFilterActive: false,
  searchString: undefined,
  setSearchString: () => {},
  filterPredicate: () => true,
  sortMode: FolderSortMode.CreationDateDesc,
  setSortMode: () => {},
};

const FolderFilterContext = React.createContext(defaultValue);

export default FolderFilterContext;
