import React from 'react';
import { Box, useTheme } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import IChildren from 'common/types/IChildren';

interface CreateIssuePanelProps extends IChildren, ISxProps {
  id?: string,
}

export default function CreateIssuePanel({
  children,
  sx,
  id,
}: CreateIssuePanelProps) {
  const theme = useTheme();
  return (
    <Box
      id={id ?? 'CreateIssuePanel'}
      sx={{
        backgroundColor: theme.palette.background.default,
        borderRadius: '8px',
        boxShadow: '0px 0px 8px -4px rgba(0,0,0,0.2)',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        p: 1.5,
        pt: 1,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
