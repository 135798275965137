import NotFound from 'error/NotFound';
import React, { useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';

export default function IssuesRedirectHub() {
  const { projectId, issueId } = useParams<'projectId' | 'issueId'>();
  const to = useMemo(() => (projectId && issueId ? `/projects/${projectId}/issues/list/issue/${issueId}` : undefined), [issueId, projectId]);
  if (!to) return <NotFound />;
  return <Navigate to={to} />;
}
