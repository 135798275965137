import { useQuery } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useFolderTreeQueryKey from 'documents-folders/hooks/useFolderTreeQueryKey';
import Folder from 'documents-folders/types/Folder';
import FolderDto from 'documents-folders/types/FolderDto';
import buildQuery from 'odata-query';
import { useCallback } from 'react';

interface FolderStackItem {
  dto: FolderDto,
  parentFolderEntity: Folder | undefined,
}

export default function useFolderTreeQuery() {
  const odataQueryString = buildQuery({ filter: { isTopLevel: true } });
  const queryKey = useFolderTreeQueryKey();
  const axiosInstance = useAxiosInstance();

  const processFolderTree = useCallback((dto: FolderDto) => {
    const foldersById = new Map<string, Folder>();
    const rootFolderDto = dto;
    const folderStack: FolderStackItem[] = [{ dto, parentFolderEntity: undefined }];
    while (folderStack.length > 0) {
      const pivotItem = folderStack.pop();
      if (pivotItem) {
        const folder: Folder = {
          ...pivotItem.dto,
          path: [...(pivotItem.parentFolderEntity?.path ?? []), pivotItem.dto.id],
          parent: pivotItem.parentFolderEntity,
          childFolders: [],
          lastDocumentUpdateDateParsed: pivotItem.dto.lastDocumentUpdateDate ? new Date(pivotItem.dto.lastDocumentUpdateDate) : undefined,
          editDateParsed: new Date(pivotItem.dto.editDate),
          creationDateParsed: new Date(pivotItem.dto.creationDate),
          rootFolderAccessType: rootFolderDto.accessType,
        };
        pivotItem.parentFolderEntity?.childFolders?.push(folder);
        foldersById.set(folder.id, folder);
        folderStack.push(...pivotItem.dto.childFolders?.map((childDto) => ({ dto: childDto, parentFolderEntity: folder })) ?? []);
      }
    }
    const folderEntity = foldersById.get(dto.id);
    if (!folderEntity) throw new Error('Error parsing FolderDto');
    return folderEntity;
  }, []);

  return useQuery({
    queryKey: queryKey ?? [],
    queryFn: async () => {
      const url = `/${ApiEndpoint.Folder}${odataQueryString}&includeDescendants=true`;
      const response = await axiosInstance.get<FolderDto[]>(url);
      const folders = response.data.map(processFolderTree);
      const foldersById = new Map<string, Folder>();
      const stack: Folder[] = [...folders];
      while (stack.length > 0) {
        const pivot = stack.pop();
        if (pivot) {
          foldersById.set(pivot.id, pivot);
          if (pivot.childFolders) {
            stack.push(...pivot.childFolders);
          }
        }
      }
      return {
        foldersById,
        folders,
      };
    },
    structuralSharing: false,
  });
}
