import React, { useCallback, useMemo, useRef, useState } from 'react';
import { CustomCellRendererProps } from '@ag-grid-community/react';
import IssueDto from 'issues/types/IssueDto';
import { Button, Menu, MenuItem, useTheme } from '@mui/material';
import Color from 'color';
import { useNavigate } from 'react-router-dom';
import useEmailsOdataQuery from 'emails/hooks/useEmailsOdataQuery';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';

export default function EmailsCell({
  value,
  data: issue,
}: CustomCellRendererProps<IssueDto, IssueDto['linkedEmailIds']>) {
  const theme = useTheme();
  const navigate = useNavigate();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const onClickOpenMenu = useCallback(() => setMenuOpen(true), []);
  const onCloseMenu = useCallback(() => setMenuOpen(false), []);
  const linkedEmailsOdataFilter = useMemo(() => (issue && menuOpen ? { filter: { id: { in: issue.linkedEmailIds } } } : undefined), [issue, menuOpen]);
  const { data: linkedEmails } = useEmailsOdataQuery(linkedEmailsOdataFilter);
  const linkedEmailItems = useMemo(() => linkedEmails?.map((email) => ({
    email,
    onClick: () => navigate(`../../emails/${email.id}`),
  })), [linkedEmails, navigate]);
  return (
    <>
      <Button
        id="EmailsCell"
        variant="contained"
        color="secondary"
        ref={buttonRef}
        onClick={onClickOpenMenu}
        disabled={!value?.length}
        sx={{
          backgroundColor: Color(theme.palette.info.main).lightness(92).hex(),
          fontWeight: 600,
          height: '24px',
          minWidth: 'unset',
          px: 1,
          py: 0,
        }}
      >
        {`${value?.length ?? ''}`}
      </Button>
      <Menu open={menuOpen} anchorEl={buttonRef.current} onClose={onCloseMenu}>
        {!linkedEmailItems && <CenteredCircularProgress sx={{ m: 2 }} />}
        {!!linkedEmailItems && linkedEmailItems.map(({ email, onClick }) => (
          <MenuItem key={email.id} onClick={onClick}>
            {email.subject}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
