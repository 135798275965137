import DateRangeKind from 'common/types/DateRangeKind';
import DocumentFilterSettingsDto from 'query-filters/types/DocumentFilterSettingsDto';
import DocumentFilterState from 'documents-filter/types/DocumentFilterState';
import QueryFilterUpdateDto from 'query-filters/types/QueryFilterUpdateDto';
import QueryFilterCreateDto from 'query-filters/types/QueryFilterCreateDto';
import FolderSearchScope from 'documents-folders/types/FolderSearchScope';
import { useMemo } from 'react';
import QueryFilterKind from 'query-filters/types/QueryFilterKind';

function getFilterStateFromDocumentFilterSettings(documentFilterSettings: DocumentFilterSettingsDto | undefined): DocumentFilterState {
  if (!documentFilterSettings) {
    return {
      disciplineLabelIds: [],
      buildingLabelIds: [],
      floorLabelIds: [],
      workPhaseLabelIds: [],
      creatorCollaboratorIds: [],
      editorCollaboratorIds: [],
      fileTypes: [],
      tagLabelIds: [],
      documentStatusLabelIds: [],
    };
  }
  return {
    uploadDateRange: documentFilterSettings.uploadDateTimeFrame !== undefined
      ? {
        dateRangeKind: DateRangeKind.Relative,
        timeFrame: documentFilterSettings.uploadDateTimeFrame,
      }
      : documentFilterSettings.uploadFrom !== undefined || documentFilterSettings.uploadTo !== undefined
        ? {
          dateRangeKind: DateRangeKind.Custom,
          from: documentFilterSettings.uploadFrom ? new Date(documentFilterSettings.uploadFrom) : undefined,
          to: documentFilterSettings.uploadTo ? new Date(documentFilterSettings.uploadTo) : undefined,
        }
        : undefined,
    creationDateRange: documentFilterSettings.creationDateTimeFrame !== undefined
      ? {
        dateRangeKind: DateRangeKind.Relative,
        timeFrame: documentFilterSettings.creationDateTimeFrame,
      }
      : documentFilterSettings.createdFrom !== undefined || documentFilterSettings.createdTo !== undefined
        ? {
          dateRangeKind: DateRangeKind.Custom,
          from: documentFilterSettings.createdFrom ? new Date(documentFilterSettings.createdFrom) : undefined,
          to: documentFilterSettings.createdTo ? new Date(documentFilterSettings.createdTo) : undefined,
        }
        : undefined,
    disciplineLabelIds: documentFilterSettings.disciplines ?? [],
    buildingLabelIds: documentFilterSettings.buildings ?? [],
    floorLabelIds: documentFilterSettings.floors ?? [],
    workPhaseLabelIds: documentFilterSettings.workPhases ?? [],
    creatorCollaboratorIds: documentFilterSettings.creatorId ?? [],
    editorCollaboratorIds: documentFilterSettings.editorId ?? [],
    fileTypes: documentFilterSettings.fileType ?? [],
    versionNumber: documentFilterSettings.versionNumber,
    tagLabelIds: documentFilterSettings.tags ?? [],
    documentStatusLabelIds: documentFilterSettings.documentStatus ?? [],
    linkedIssuesScope: documentFilterSettings.linkedIssuesScope,
    folderId: documentFilterSettings.folderSearchScope !== FolderSearchScope.AllFolders ? documentFilterSettings.folderId : undefined,
    includeSubfolders: documentFilterSettings.folderSearchScope === FolderSearchScope.SelectedAndSubFolders,
    tagComparisonMode: documentFilterSettings.tagComparisonMode,
  };
}

function getUpdateDtoFromFilterState(id: string, filterState: DocumentFilterState, projectId: string, name: string, isPrivate: boolean): QueryFilterUpdateDto<QueryFilterKind.Document> {
  return {
    id,
    isPrivate,
    name,
    uiFilterSettings: {
      projectId,
      creatorId: filterState.creatorCollaboratorIds?.length ? filterState.creatorCollaboratorIds : undefined,
      editorId: filterState.editorCollaboratorIds?.length ? filterState.editorCollaboratorIds : undefined,
      disciplines: filterState.disciplineLabelIds?.length ? filterState.disciplineLabelIds : undefined,
      buildings: filterState.buildingLabelIds?.length ? filterState.buildingLabelIds : undefined,
      workPhases: filterState.workPhaseLabelIds?.length ? filterState.workPhaseLabelIds : undefined,
      versionNumber: filterState.versionNumber,
      folderId: filterState.folderId,
      tags: filterState.tagLabelIds?.length ? filterState.tagLabelIds : undefined,
      floors: filterState.floorLabelIds?.length ? filterState.floorLabelIds : undefined,
      uploadFrom: filterState.uploadDateRange?.dateRangeKind === DateRangeKind.Custom ? filterState.uploadDateRange.from?.toISOString() : undefined,
      uploadTo: filterState.uploadDateRange?.dateRangeKind === DateRangeKind.Custom ? filterState.uploadDateRange.to?.toISOString() : undefined,
      fileType: filterState.fileTypes?.length ? filterState.fileTypes : undefined,
      createdFrom: filterState.creationDateRange?.dateRangeKind === DateRangeKind.Custom ? filterState.creationDateRange.from?.toISOString() : undefined,
      createdTo: filterState.creationDateRange?.dateRangeKind === DateRangeKind.Custom ? filterState.creationDateRange.to?.toISOString() : undefined,
      documentStatus: filterState.documentStatusLabelIds?.length ? filterState.documentStatusLabelIds : undefined,
      folderSearchScope: filterState.folderId ? filterState.includeSubfolders ? FolderSearchScope.SelectedAndSubFolders : FolderSearchScope.SelectedFolder : FolderSearchScope.AllFolders,
      creationDateTimeFrame: filterState.creationDateRange?.dateRangeKind === DateRangeKind.Relative ? filterState.creationDateRange?.timeFrame : undefined,
      uploadDateTimeFrame: filterState.uploadDateRange?.dateRangeKind === DateRangeKind.Relative ? filterState.uploadDateRange?.timeFrame : undefined,
      linkedIssuesScope: filterState.linkedIssuesScope,
      tagComparisonMode: filterState.tagComparisonMode,
    },
  };
}

function getCreateDtoFromFilterState(filterState: DocumentFilterState, projectId: string, name: string, isPrivate: boolean): QueryFilterCreateDto<QueryFilterKind.Document> {
  return {
    isPrivate,
    name,
    uiFilterSettings: {
      projectId,
      creatorId: filterState.creatorCollaboratorIds?.length ? filterState.creatorCollaboratorIds : undefined,
      editorId: filterState.editorCollaboratorIds?.length ? filterState.editorCollaboratorIds : undefined,
      disciplines: filterState.disciplineLabelIds?.length ? filterState.disciplineLabelIds : undefined,
      buildings: filterState.buildingLabelIds?.length ? filterState.buildingLabelIds : undefined,
      workPhases: filterState.workPhaseLabelIds?.length ? filterState.workPhaseLabelIds : undefined,
      versionNumber: filterState.versionNumber,
      folderId: filterState.folderId,
      tags: filterState.tagLabelIds?.length ? filterState.tagLabelIds : undefined,
      floors: filterState.floorLabelIds?.length ? filterState.floorLabelIds : undefined,
      uploadFrom: filterState.uploadDateRange?.dateRangeKind === DateRangeKind.Custom ? filterState.uploadDateRange.from?.toISOString() : undefined,
      uploadTo: filterState.uploadDateRange?.dateRangeKind === DateRangeKind.Custom ? filterState.uploadDateRange.to?.toISOString() : undefined,
      fileType: filterState.fileTypes?.length ? filterState.fileTypes : undefined,
      createdFrom: filterState.creationDateRange?.dateRangeKind === DateRangeKind.Custom ? filterState.creationDateRange.from?.toISOString() : undefined,
      createdTo: filterState.creationDateRange?.dateRangeKind === DateRangeKind.Custom ? filterState.creationDateRange.to?.toISOString() : undefined,
      documentStatus: filterState.documentStatusLabelIds?.length ? filterState.documentStatusLabelIds : undefined,
      folderSearchScope: filterState.folderId ? filterState.includeSubfolders ? FolderSearchScope.SelectedAndSubFolders : FolderSearchScope.SelectedFolder : FolderSearchScope.AllFolders,
      creationDateTimeFrame: filterState.creationDateRange?.dateRangeKind === DateRangeKind.Relative ? filterState.creationDateRange?.timeFrame : undefined,
      uploadDateTimeFrame: filterState.uploadDateRange?.dateRangeKind === DateRangeKind.Relative ? filterState.uploadDateRange?.timeFrame : undefined,
      linkedIssuesScope: filterState.linkedIssuesScope,
      tagComparisonMode: filterState.tagComparisonMode,
    },
  };
}

export default function useDocumentFilterConverter() {
  return useMemo(() => ({ getCreateDtoFromFilterState, getUpdateDtoFromFilterState, getFilterStateFromDocumentFilterSettings }), []);
}
