import { useMemo } from 'react';
import useCurrentCollaboratorQuery from 'collaborators/hooks/useCurrentCollaboratorQuery';
import useRolesOdataQuery from 'collaborators/hooks/useRolesOdataQuery';
import useCollaboratorPermissionQuery from 'collaborators/hooks/useCollaboratorPermissionQuery';

export default function useCurrentCollaboratorRole() {
  const { data: permission } = useCollaboratorPermissionQuery();
  const { data: currentCollaborator } = useCurrentCollaboratorQuery();
  const { data: roles } = useRolesOdataQuery({ filter: { isDeleted: false } });

  return useMemo(() => {
    if (!currentCollaborator || !roles || !permission) return undefined;
    const role = roles.find((r) => r.id === currentCollaborator.roleId);
    if (!role) return undefined;
    const allowedActions = new Set(permission.actions.filter((a) => a.isAllowed).map((a) => a.action));
    return {
      ...role,
      allowedActions,
    };
  }, [currentCollaborator, permission, roles]);
}
