import type { Theme, ComponentsProps, ComponentsVariants } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

interface MuiBackdropComponent {
  defaultProps?: ComponentsProps['MuiBackdrop'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiBackdrop'];
  variants?: ComponentsVariants['MuiBackdrop'];
}

const VisoBackdrop: MuiBackdropComponent = {
  styleOverrides: {
    root: () => ({
      backgroundColor: 'rgba(238, 238, 238, 0.7)',
      backdropFilter: 'blur(2px)',
    }),
    invisible: () => ({
      backgroundColor: 'transparent',
      backdropFilter: 'none',
    }),
  },
};

export default VisoBackdrop;
