import React from 'react';
import ChipMultiselect from 'common/components/ChipMultiselect';
import useProjectDocumentStatusesQuery from 'documents/hooks/useProjectDocumentStatusesQuery';

interface DocumentStatusSelectProps {
  label: string,
  value: string[],
  onChange: (selectedTagIds: string[]) => void,
  disabled?: boolean,
}

export default function DocumentStatusMultiselect({
  label,
  value,
  onChange,
  disabled,
}: DocumentStatusSelectProps) {
  const { data: documentStatuses } = useProjectDocumentStatusesQuery();
  return (
    <ChipMultiselect
      label={label}
      value={value}
      onChange={onChange}
      disabled={disabled}
      entities={documentStatuses}
    />
  );
}
