import React, { ComponentType, useMemo } from 'react';
import { Box, ButtonBase, Typography, SvgIconProps, styled, useTheme } from '@mui/material';
import ISxProps from 'common/types/ISxProps';

const OpacityTransitionTypography = styled(Typography)(({ theme }) => ({
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  opacity: 0,
  position: 'relative',
  '&.expanded': {
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.enteringScreen,
    }),
    opacity: 1,
  },
}));

interface MainMenuItemProps extends ISxProps {
  Icon: ComponentType<Pick<SvgIconProps, 'htmlColor' | 'fontSize'>>,
  label: string,
  expanded: boolean,
  active: boolean,
  onClick: () => void,
}

export default function MainMenuItem({
  sx,
  Icon,
  label,
  expanded,
  active,
  onClick,
}: MainMenuItemProps) {
  const theme = useTheme();
  const htmlColor = useMemo(() => (active ? theme.palette.primary.main : theme.palette.grey[600]), [active, theme]);
  return (
    <ButtonBase
      id="MainMenuItem"
      onClick={onClick}
      title={label}
      sx={{
        ...sx,
        backgroundColor: active ? theme.palette.secondary.light : undefined,
        justifyItems: 'flex-start',
        justifyContent: 'flex-start',
        alignItems: 'center',
        textAlign: 'left',
        '&:hover': {
          backgroundColor: !active ? theme.palette.grey[200] : undefined,
        },
        pl: '21px',
        height: 60,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Icon htmlColor={htmlColor} fontSize="small" />
      </Box>
      <Box sx={{ flex: '1 1 0', overflow: 'hidden', pb: '2px' }}>
        <OpacityTransitionTypography
          className={expanded ? 'expanded' : undefined}
          sx={{
            color: htmlColor,
            pl: 3,
            whiteSpace: 'nowrap',
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          {label}
        </OpacityTransitionTypography>
      </Box>
      <Box sx={{ ml: 'auto', width: 6, height: '100%', backgroundColor: active ? theme.palette.primary.main : undefined }} />
    </ButtonBase>
  );
}
