import type { Theme, ComponentsProps, ComponentsVariants } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

interface MuiOutlinedInputComponent {
  defaultProps?: ComponentsProps['MuiOutlinedInput'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiOutlinedInput'];
  variants?: ComponentsVariants['MuiOutlinedInput'];
}

const VisoOutlinedInput: MuiOutlinedInputComponent = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontSize: theme.typography.fontSize,
    }),
    input: () => ({
      padding: '8px 11px',
    }),
    multiline: () => ({
      padding: 0,
    }),
    notchedOutline: () => ({
      '> legend > span': {
        paddingRight: '7px',
      },
    }),
  },
};

export default VisoOutlinedInput;
