import i18next from 'i18next';

// This file is deprecated. To make requests, use react-query's useQuery hook and use axiosInstace from the useAxios hook instead.

const MAX_IDS_PER_REQUEST = 300; // 300 ids per request with 24+1 chars per id incl. comma => 7500 chars leaves 500 chars for the base api URL + endpoint

const urls = getConfig();

// for these deprecated legacy constants we need to slice the "api" off at the end of the correct URLs
export const API_URL = urls.apiUrl.slice(0, -3);
export const SYNC_URL = urls.apiUrl.slice(0, -3);

export const GET = (url, params = {}, base_url = API_URL, bearerToken, headerOverrides = {}) => {
  return checkToken('GET', base_url + url, params, bearerToken, headerOverrides);
};

export const POST = (url, params = {}, base_url = API_URL) => {
  return checkToken('POST', base_url + url, params);
};

export const POSTNoCheckToken = (url, params = {}, base_url = API_URL) => {
  return query('POST', base_url + url, params);
};

export const PUT = (url, params = {}, base_url = API_URL) => {
  return checkToken('PUT', base_url + url, params);
};

export const PATCH = (url, params = {}, base_url = API_URL) => {
  return checkToken('PATCH', base_url + url, params);
};

export const DELETE = (url, params = {}, base_url = API_URL) => {
  return checkToken('DELETE', base_url + url, params);
};

sessionStorage.removeItem('projectTokenBearer');
sessionStorage.removeItem('userTokenBearer');

const waitForStorageItemAsync = async (key) => {
  const delays = [100, 500, 2000, 5000];
  for (let i = 0; i < delays.length; i++) {
    const delay = delays[i];
    if (sessionStorage[key] !== undefined) {
      return sessionStorage[key];
    }
    await new Promise((resolve) => setTimeout(resolve, delay));
  }
};

const checkToken = async (method, url, params, bearerToken, headerOverrides = {}) => {
  const token = await waitForStorageItemAsync('userTokenBearer');
  const bearer = `Bearer ${token}`;
  const projectTokenBearer = await waitForStorageItemAsync('projectTokenBearer');
  if (projectTokenBearer && projectTokenBearer !== '') {
    headerOverrides = { ...headerOverrides, 'X-Project-Authorization': `bearer ${projectTokenBearer}`}
  }
  return query(method, url, params, { Authorization: bearer, ...headerOverrides });
};

/**
 * @param {String} method
 * @param {String} url
 * @param {Object} params
 * @param {Object} headers
 * @return {Promise<Response>}
 */
const query = (method, url, params = {}, headers = {}) => {
  const query_options = {
    method,
    headers: new Headers({
      'content-type': 'application/json',
      'Visoplan-Client-Id': 'Visoplan Webclient',
      'Visoplan-Webclient-Request-Source': 'Legacy',
      'content-language': i18next.language,
      ...headers,
    }),
  };

  if (method === 'GET') {
    if (Object.keys(params).length) {
      url += '?';

      Object.keys(params).forEach((param_name) => {
        if (params[param_name]) {
          if (typeof params[param_name] === 'object') {
            Object.values(params[param_name]).forEach((item) => {
              url += param_name + '[]=' + item + '&';
            });
          } else {
            url += param_name + '=' + params[param_name] + '&';
          }
        }
      });

      // Remove last `&`
      url = url.slice(0, -1);
    }
  } else {
    query_options['body'] = JSON.stringify(params);
  }

  return fetch(url, query_options).then((response) => {
    if (response.status === 401) {
      sessionStorage.projectTokenBearer = undefined;
      if (window.location.pathname !== '/login' && window.location.pathname !== '/projects') {
        window.location.href = '/projects';
      }
      return;
    };

    const contentType = response.headers.get('content-type');

    if (contentType) {
      if (contentType.indexOf('application/json') !== -1) {
        return response
          .json()
          .then((body) => ({ status: response.status, body }));
      } else if (contentType.indexOf('application/octet-stream') !== -1) {
        return response
          .blob()
          .then((body) => ({ status: response.status, body }));
      }
    }

    return response.text().then((body) => ({ status: response.status, body }));
  });
};

export async function getChunkedEntitiesByIds(endpoint, ids) {
  const entities = new Array(ids.length);
  for (let i = 0; i < ids.length; i += MAX_IDS_PER_REQUEST) {
    const idsChunk = ids.slice(i, i + MAX_IDS_PER_REQUEST);
    const result = await GET(`${endpoint}/${idsChunk}`);
    if (result.status === 200 && Array.isArray(result.body)) {
      for (let k = 0; k < result.body.length; k++) {
        entities[i + k] = result.body[k];
      }
    } else {
      return [];
    }
  }
  return entities;
}
