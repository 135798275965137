import React, { useCallback, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useDocumentListDetailsQuery from 'documents-lists/hooks/useDocumentListDetailsQuery';
import useCurrentPlanlistId from 'documents-lists/hooks/useCurrentPlanlistId';
import useDocumentSelectionContext from 'documents/hooks/useDocumentSelectionContext';
import useDocumentListUpdateDocumentsMutation from 'documents-lists/hooks/useDocumentListUpdateDocumentsMutation';
import DocumentListUpdateDocumentsDto from 'documents-lists/types/DocumentListUpdateDocumentsDto';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';

interface ConfirmRemoveFromPlanlistDialogProps {
  open: boolean,
  onClose: (successMessage: string | undefined) => void,
}

export default function ConfirmRemoveFromPlanlistDialog({
  open,
  onClose,
}: ConfirmRemoveFromPlanlistDialogProps) {
  const { t } = useTranslation('documents-lists');
  const planlistId = useCurrentPlanlistId();
  const { data: planlist } = useDocumentListDetailsQuery(planlistId);
  const { selectedDocumentVersionIds, selectedDocumentVersionIdsSet, resetSelection } = useDocumentSelectionContext();
  const { mutateAsync: mutateUpdateAsync, isPending: isLoadingUpdate } = useDocumentListUpdateDocumentsMutation();
  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const onClickConfirmRemove = useCallback(async () => {
    if (!planlist?.documentVersionIds || selectedDocumentVersionIdsSet.size === 0) return;
    const remainingDocumentVersionIds = planlist.documentVersionIds.filter((id) => !selectedDocumentVersionIdsSet.has(id));
    try {
      if (!planlist) throw new Error('Failed to remove documents from planlist (planlist object not defined)');
      const updateDto: DocumentListUpdateDocumentsDto = {
        id: planlist.id,
        documentVersionIds: remainingDocumentVersionIds,
      };
      await mutateUpdateAsync(updateDto);
      resetSelection();
      onClose(t('remove-from-planlist-dialog_success-message', 'Successfully removed {{count}} documents to the planlist {{planlist}}.', { count: selectedDocumentVersionIdsSet.size, planlist: planlist.name }));
    } catch (error) {
      const reason = getRequestErrorMessage(error) ?? 'Unknown';
      setErrorMessage(t('remove-from-planlist-dialog_create-error-message', 'Removing documents from the planlist failed. Reason: {{reason}}', { count: selectedDocumentVersionIdsSet.size, reason }));
    }
  }, [getRequestErrorMessage, mutateUpdateAsync, onClose, planlist, resetSelection, selectedDocumentVersionIdsSet, t]);
  return (
    <Dialog open={open}>
      <DialogTitle>{t('planlist-remove-document_confirm-dialog-title', 'Remove {{count}} Document from Planlist', { count: selectedDocumentVersionIds.length })}</DialogTitle>
      <DialogContent>
        <Alert severity="warning">
          {t('planlist-remove-document_confirm-dialog-text', 'Are you sure you want to remove {{count}} documents from the planlist "{{planlistName}}"?', { count: selectedDocumentVersionIds.length, planlistName: planlist?.name })}
        </Alert>
        {errorMessage && <Alert severity="error" sx={{ mt: 1 }}>{errorMessage}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={() => onClose(undefined)} disabled={isLoadingUpdate}>
          {t('planlist-remove-document_confirm-dialog-cancel-button-label', 'Cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={onClickConfirmRemove} sx={{ ml: 'auto' }} disabled={isLoadingUpdate}>
          {t('planlist-remove-document_confirm-dialog-confirm-button-label', 'Remove')}
          {isLoadingUpdate && <CircularProgress size={12} sx={{ ml: 1 }} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
