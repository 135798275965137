import buildQuery from "odata-query";

import * as Request from "../Helpers/Request";

export const defaultDocumentColumns = [
  { name: "Original File Name", width: 182 },
  { name: "Format", width: 81 },
  { name: "Upload Date", width: 128 },
  { name: "Version", width: 80 },
  { name: "Discipline", width: 140 },
  { name: "Building", width: 139 },
  { name: "Work Phase", width: 141 },
  { name: "Uploaded by", width: 135 },
  { name: "Tags", width: 193 },
];

export const defaultDocumentColumnsForPlanList = [
  { name: "Original File Name", width: 247 },
  { name: "Format", width: 83 },
  { name: "Upload Date", width: 87 },
  { name: "Version", width: 71 },
  { name: "Discipline", width: 142 },
  { name: "Building", width: 117 },
  { name: "Work Phase", width: 132 },
  { name: "Tags", width: 180 },
];

export const defaultDocumentColumnsForIssueLinkPopup = [
  { name: "Original File Name", width: 256 },
  { name: "Version", width: 87 },
  { name: "Format", width: 79 },
  { name: "Status", width: 110 },
  { name: "Upload Date", width: 88 },
  { name: "Discipline", width: 104 },
  { name: "Linked Issues", width: 70 },
  { name: "Comments", width: 136 },
];

export const DragItemType = 'document';

export const downloadDocument = (ids, downloadName) => {
  return Request.POST(`api/documentVersion/download`, ids).then(
    async (result) => {
      // Download file
      if (result.status === 200) {
        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(result.body);
        a.download = downloadName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    }
  );
};

export const getDocumentVersionsByProjectId = async (
  projectId,
  top,
  skip,
  filter,
  orderBy
) => {
  const query = buildQuery({ filter, top, skip, orderBy: orderBy ?? 'uploadDate desc' });
  const result = await Request.GET(`api/documentVersion${query}`);
  return result.status === 200 ? result.body : [];
};

export const getDocumentVersions = (ids) => {
  return Request.GET(`api/documentVersion/${ids}`).then((result) =>
    result.status === 200 ? result.body : null
  );
};

export const deleteDocumentVersions = (ids) => {
  return Request.DELETE("api/documentVersion", ids).then((result) =>
    result.status === 200 ? result.body : null
  );
};

export const getDocumentComments = (id) => {
  return Request.GET(`api/documentComment/${id}`).then((result) =>
    result.status === 200 ? result.body : []
  );
};

export const createDocumentComments = (data) => {
  return Request.POST(`api/documentComment`, data).then((result) =>
    result.status === 200 ? result.body[0] : null
  );
};

export const deleteDocumentComment = (id) => {
  return Request.DELETE(`api/documentComment/${id}`).then((result) =>
    result.status === 200 ? result.body : null
  );
};

export async function archiveDocumentVersions(versionIds) {
  const result = await Request.PATCH(
    `api/documentVersion/archive/${versionIds}`
  );
  return result.status === 200;
}

export async function getArchivedDocuments(projectId) {
  const result = await Request.GET("api/documentVersion/archive", {
    projectId,
  });
  return result.status === 200 ? result.body : [];
}

export async function restoreArchivedDocuments(versionIds) {
  const result = await Request.PATCH(
    `api/documentVersion/restore/${versionIds}`
  );
  return result.status === 200;
}

export const getDocuments = (ids) => {
  return Request.GET(`api/document/${ids}`).then((result) =>
    result.status === 200 ? result.body : null
  );
};

export async function getDocumentList(ids) {
  const result = await Request.GET(`api/documentList/${ids}`);
  return result.status === 200 ? result.body : null;
}

export async function createDocumentListItem(data) {
  const result = await Request.POST("api/documentList", data);
  return result.status === 200 ? result.body : null;
}

export async function updateDocumentListItem(id, data) {
  const result = await Request.PUT(`api/documentList/${id}`, data);
  return result.status === 200 ? result.body : null;
}

export async function deleteDocumentListItem(id) {
  const result = await Request.DELETE(`api/documentList/${id}`);
  return result.status === 200;
}

export async function printOrder(data) {
  const result = await Request.POST("api/printOrder", data);
  return result.status === 200;
}

export async function editDocuments(params) {
  const result = await Request.PATCH(`api/documentVersion/many`, params);
  return result.status === 200 ? result.body : null;
}

export async function updateDocTag(id, tagIds) {
  const params = {
    ids: [id],
    tagIds: {
      value: tagIds,
    },
  };
  return await editDocuments(params);
}
