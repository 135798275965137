import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import React from 'react';
import { Navigate } from 'react-router-dom';
import useVisoplanApiContext from 'api/hooks/useVisoplanApiContext';

export default function HomePage() {
  const { isSignedIn, openedProjectId } = useVisoplanApiContext();

  if (isSignedIn === undefined || openedProjectId === undefined) return <CenteredCircularProgress />;
  if (isSignedIn === false) return <Navigate replace to="/login" />;
  if (openedProjectId) return <Navigate replace to={`/projects/${openedProjectId}/dashboard`} />;
  return <Navigate replace to="/projects" />;
}
