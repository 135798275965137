import { useMemo } from 'react';
import DynamicLayoutKey from 'dynamic-layout/types/DynamicLayoutKey';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import useDynamicLayout from 'dynamic-layout/hooks/useDynamicLayout';
import useDocumentsViewNavigationContext from 'documents/hooks/useDocumentsViewNavigationContext';

export default function useDocumentsDynamicLayout() {
  const { documentScope } = useDocumentsViewNavigationContext();
  const columnNamesForcedHidden = useMemo(() => (documentScope ? new Set(documentScope.key === DocumentScopeKey.Folder ? ['folderId'] : []) : new Set([])), [documentScope]);
  const columnNamesForcedVisible = useMemo(() => new Set(['name']), []);
  return useDynamicLayout(DynamicLayoutKey.DEFAULT_DOCS_LAYOUT, columnNamesForcedHidden, columnNamesForcedVisible);
}
