enum IssueFilterType {
  StringFilter = 'string-filter',
  StringsFilter = 'strings-filter',
  DateRangeFilter = 'date-range-filter',
  NumberFilter = 'number-filter',
  CollaboratorIdFilter = 'collaborator-id-filter',
  CollaboratorIdsFilter = 'collaborators-ids-filter',
  LabelIdsFilter = 'label-ids-filter',
  LabelIdFilter = 'label-id-filter',
  VisibilityFilter = 'visibility-filter',
}

export default IssueFilterType;
