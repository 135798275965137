import { useMemo } from 'react';
import { InfiniteData, UseInfiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query';
import { QueryOptions } from 'odata-query';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys, { OdataInfiniteQueryKey } from 'api/hooks/useQueryKeys';

type OdataInfiniteQueryOptions<TEndpoint extends ApiEndpoint, TDto> = Omit<UseInfiniteQueryOptions<TDto[] | undefined, unknown, InfiniteData<TDto[] | undefined>, TDto[] | undefined, OdataInfiniteQueryKey<TEndpoint, TDto> | readonly [], number>, 'queryFn'>;

export type OdataUseInfiniteQueryArgs<TEndpoint extends ApiEndpoint, TDto> = [odataQuery: Partial<QueryOptions<TDto>> | undefined, options?: Partial<Omit<OdataInfiniteQueryOptions<TEndpoint, TDto>, 'queryKey'>>];

export default function useOdataInfiniteQuery<TEndpoint extends ApiEndpoint, TDto>(endpoint: TEndpoint, ...[odataQuery, options]: OdataUseInfiniteQueryArgs<TEndpoint, TDto>) {
  const { getOdataInfiniteQueryKey } = useDefaultEntityQueryKeys(endpoint);
  const queryKey = useMemo(() => (odataQuery ? getOdataInfiniteQueryKey(odataQuery) : ([] as const)), [odataQuery, getOdataInfiniteQueryKey]);
  const queryOptions = useMemo<OdataInfiniteQueryOptions<TEndpoint, TDto>>(() => ({
    ...options,
    queryKey,
    initialPageParam: 0,
    getNextPageParam: (lastPage: TDto[] | undefined, allPages: (TDto[] | undefined)[], lastPageParam: number) => lastPageParam + 1,
    enabled: (options?.enabled !== false) && (queryKey.length > 0),
  }), [queryKey, options]);
  return useInfiniteQuery(queryOptions);
}
