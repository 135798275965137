import React, { useMemo, useState } from 'react';
import IChildren from 'common/types/IChildren';
import IssuesFilterContext, { IssuesFilterContextState } from 'issues/contexts/IssuesFilterContext';
import IssuesSortMode from 'issues/types/IssuesSortMode';
import useQueryFilterPersistableState from 'query-filters/hooks/useQueryFilterPersistableState';
import QueryFilterKind from 'query-filters/types/QueryFilterKind';

export const defaultSortMode: IssuesSortMode = 'editDate desc';

interface IssuesFilterContextProviderProps extends IChildren {
  queryFilterId: string | undefined,
  setQueryFilterId: (value: string | undefined) => void,
}

export default function IssuesFilterContextProvider({
  children,
  queryFilterId,
  setQueryFilterId,
}: IssuesFilterContextProviderProps) {
  const [sortMode, setSortMode] = useState<IssuesSortMode>(defaultSortMode);

  const queryFilterState = useQueryFilterPersistableState<QueryFilterKind.Issue>({ filterId: queryFilterId !== 'all' ? queryFilterId : undefined, queryFilterKind: QueryFilterKind.Issue });
  const odataQuery = useMemo(() => ({ filter: queryFilterState.odataFilter, orderBy: sortMode }), [queryFilterState.odataFilter, sortMode]);

  const value = useMemo<IssuesFilterContextState>(() => ({
    queryFilterState,
    sortMode,
    setSortMode,
    queryFilterId,
    setQueryFilterId,
    odataQuery,
  }), [queryFilterState, sortMode, queryFilterId, setQueryFilterId, odataQuery]);
  return <IssuesFilterContext.Provider value={value}>{children}</IssuesFilterContext.Provider>;
}
