import React, { ChangeEvent, useCallback, useEffect, useState, KeyboardEvent, useRef, useMemo } from 'react';
import { Alert, Box, Button, Checkbox, Divider, FormControlLabel, TextField, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useVisoplanApiContext from 'api/hooks/useVisoplanApiContext';
import i18n from 'setup/i18n';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { INCORRECT_ONE_TIME_PASSWORD_ERROR, SignInResult } from 'api/contexts/VisoplanApiProvider';
import OtpInput from 'users/components/OtpInput';
import { useQueryClient } from '@tanstack/react-query';

export default function LoginPage() {
  const { t } = useTranslation('users');
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);

  const [email, setEmail] = useState('');
  const onChangeEmail = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setErrorMessage(undefined);
  }, []);
  const [password, setPassword] = useState('');
  const onChangePassword = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setErrorMessage(undefined);
  }, []);
  const [rememberMe, setRememberMe] = useState(false);
  const onChangeRememberMe = useCallback((event: ChangeEvent<HTMLInputElement>) => setRememberMe(event.target.checked), []);

  const [twoFactorPromptOpen, setTwoFactorPromptOpen] = useState(false);
  const [oneTimePassword, setOneTimePassword] = useState('');
  const onChangeOneTimePassword = useCallback((value: string) => setOneTimePassword(value), []);

  const { signInUsingCredentials, signInUsingTwoFactorCredentials } = useVisoplanApiContext();
  const onClickLogin = useCallback(async () => {
    try {
      const signInResult = await signInUsingCredentials({ mail: email, password, remember: rememberMe });
      if (signInResult === SignInResult.Authenticated) {
        await queryClient.resetQueries();
        if (location?.state?.from) {
          navigate(location?.state?.from);
        } else {
          navigate('/projects');
        }
      } else if (signInResult === SignInResult.TwoFactorAuthenticationRequired) {
        setTwoFactorPromptOpen(true);
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        setErrorMessage(t('login-page_invalid-credentials-error-message', 'This combination of e-mail and password is not correct.'));
      } else {
        setErrorMessage(getRequestErrorMessage(error));
      }
    }
  }, [email, getRequestErrorMessage, location?.state?.from, navigate, password, queryClient, rememberMe, signInUsingCredentials, t]);

  const confirmOneTimePasswordDisabled = useMemo(() => oneTimePassword.length < 6, [oneTimePassword.length]);
  const onClickConfirmOneTimePassword = useCallback(async () => {
    try {
      await signInUsingTwoFactorCredentials({ mail: email, password, remember: rememberMe, twoFactorCode: oneTimePassword });
      await queryClient.resetQueries();
      if (location?.state?.from) {
        navigate(location?.state?.from);
      } else {
        navigate('/projects');
      }
    } catch (error) {
      if (error instanceof Error && error.message === INCORRECT_ONE_TIME_PASSWORD_ERROR) {
        setErrorMessage(t('login-page_invalid-one-time-password-error-message', 'This one-time password is not correct.'));
      } else {
        setErrorMessage(getRequestErrorMessage(error));
      }
    }
  }, [email, getRequestErrorMessage, location?.state?.from, navigate, oneTimePassword, password, queryClient, rememberMe, signInUsingTwoFactorCredentials, t]);

  const onClickSignUp = useCallback(() => {
    navigate('/register');
  }, [navigate]);

  const passwordTextFieldRef = useRef<HTMLInputElement>(null);
  const onEmailTextFieldKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key.toLowerCase() === 'enter' && passwordTextFieldRef.current) {
      passwordTextFieldRef.current.focus();
    }
  }, []);

  const onPasswordTextFieldKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key.toLowerCase() === 'enter') {
      onClickLogin();
    }
  }, [onClickLogin]);

  const onOtpTextFieldKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key.toLowerCase() === 'enter') {
      onClickConfirmOneTimePassword();
    }
  }, [onClickConfirmOneTimePassword]);

  const onClickForgotPassword = useCallback(() => navigate('/reset-password/request'), [navigate]);

  useEffect(() => {
    i18n.changeLanguage(navigator.language.startsWith('de') ? 'de-DE' : 'en-US');
  }, []);

  return (
    <Box
      id="LoginPage"
      sx={{
        backgroundColor: theme.palette.secondary.light,
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
        p: 2,
      }}
      >
        <Box sx={{
          maxWidth: 420,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <img src="/img/visoplan-logo.svg" alt="logo" style={{ width: '250px' }} />
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.background.default,
            boxShadow: '0px 2px 8px -3px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
          }}
          >
            {!twoFactorPromptOpen && (
            <>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 5,
                gap: 2,
              }}
              >
                <TextField
                  id="LoginPageEmailInput"
                  value={email}
                  onChange={onChangeEmail}
                  label={t('login-page_email-text-field-label', 'Email')}
                  autoComplete="username"
                  name="login-page_email-text-field"
                  InputLabelProps={{ shrink: true }}
                  onKeyDown={onEmailTextFieldKeyDown}
                />
                <TextField
                  id="LoginPagePasswordInput"
                  value={password}
                  onChange={onChangePassword}
                  label={t('login-page_password-text-field-label', 'Password')}
                  name="login-page_password-text-field"
                  autoComplete="current-password"
                  type="password"
                  InputLabelProps={{ shrink: true }}
                  onKeyDown={onPasswordTextFieldKeyDown}
                  inputRef={passwordTextFieldRef}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                  <FormControlLabel
                    control={<Checkbox checked={rememberMe} onChange={onChangeRememberMe} />}
                    label={t('login-page_remember-me-checkbox-label', 'Remember me')}
                  />
                  <Button
                    onClick={onClickForgotPassword}
                    variant="outlined"
                    size="small"
                  >
                    {t('login-page_forgot-password-button-label', 'Forgot Password?')}
                  </Button>
                </Box>
                {!!errorMessage && <Alert severity="error" onClose={onCloseErrorMessage}>{errorMessage}</Alert>}
                <Button
                  onClick={onClickLogin}
                  variant="contained"
                  color="primary"
                  id="LoginPageConfirmButton"
                  sx={{ mt: 2 }}
                >
                  {t('login-page_login-button-label', 'Log In')}
                </Button>
              </Box>
              <Divider orientation="horizontal" />
              <Box sx={{ p: 4, textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 0.5, alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 600 }}>{t('login-page_sign-up-text', 'You do not have an account yet?')}</Typography>
                <Button
                  onClick={onClickSignUp}
                  variant="contained"
                  color="secondary"
                >
                  {t('login-page_sign-up-button-label', 'Sign Up')}
                </Button>
              </Box>
            </>
            )}
            {!!twoFactorPromptOpen && (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 5,
              gap: 2,
            }}
            >
              <Typography variant="h4">{t('login-page_one-time-password-prompt-header', 'Two-Factor Authentication')}</Typography>
              {!!errorMessage && <Alert severity="error" onClose={onCloseErrorMessage}>{errorMessage}</Alert>}
              {!errorMessage && <Alert severity="info">{t('login-page_one-time-password-prompt-message', 'Please enter the one-time password as a second factor')}</Alert>}
              <OtpInput
                value={oneTimePassword}
                onChange={onChangeOneTimePassword}
                onKeyDown={onOtpTextFieldKeyDown}
              />
              <Button
                onClick={onClickConfirmOneTimePassword}
                disabled={confirmOneTimePasswordDisabled}
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
              >
                {t('login-page_confirm-otp-button-label', 'Confirm')}
              </Button>
            </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
