import React, { useMemo } from 'react';
import { Box, TextField, useTheme } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import { useTranslation } from 'react-i18next';
import CopyButton from 'common/components/CopyButton';

interface ProjectEmailInfoPanelProps extends ISxProps {
}

export default function ProjectEmailInfoPanel({
  sx,
}: ProjectEmailInfoPanelProps) {
  const { t } = useTranslation('emails');
  const theme = useTheme();
  const { data: currentProject } = useCurrentProjectQuery();
  const projectEmailAddress = useMemo(() => currentProject?.inboxEmailAddress ?? '', [currentProject]);
  return (
    <Box id="ProjectEmailInfoPanel" sx={{ ...sx, backgroundColor: theme.palette.secondary.light, p: 1, borderRadius: '8px' }}>
      <TextField
        label={t('project-email-info-panel', 'Project Email Address')}
        title={projectEmailAddress}
        value={projectEmailAddress}
        InputProps={{
          readOnly: true,
          endAdornment: <CopyButton value={projectEmailAddress} />,
          style: { paddingRight: '4px' },
        }}
      />
    </Box>
  );
}
