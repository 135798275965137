import React, { useCallback, useMemo } from 'react';
import { Box, Button, useTheme } from '@mui/material';
import { mdiCamera, mdiCancel, mdiCheck, mdiMapMarker, mdiMapMarkerRemoveOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { useTranslation } from 'react-i18next';
import ISxProps from 'common/types/ISxProps';
import useModelsInteractionContext from 'models/hooks/useModelsInteractionContext';
import useViewer3dContext from 'models/hooks/useViewer3dContext';
import ViewpointListItem from 'issues/components/ViewpointListItem';
import useModelSelectionContext from 'models/hooks/useModelSelectionContext';

interface StagedViewpointsPanelProps extends ISxProps {
}

export default function StagedViewpointsPanel({
  sx,
}: StagedViewpointsPanelProps) {
  const { t } = useTranslation('models');
  const theme = useTheme();
  const { modelFileIds, isLoading } = useModelSelectionContext();
  const { createViewpoint, deleteViewpointMarker, isViewpointMarkerPlaced, setIsPlacingViewpointMarker, isPlacingViewpointMarker } = useViewer3dContext();
  const { setInteractionMode, viewpointItems, setViewpointItems } = useModelsInteractionContext();
  const onClickClose = useCallback(() => {
    setInteractionMode(undefined);
  }, [setInteractionMode]);
  const onClickRemoveViewpoint = useCallback((id: string) => {
    setViewpointItems(viewpointItems.filter((item) => item.uuid !== id));
  }, [setViewpointItems, viewpointItems]);
  const onClickCreateViewpoint = useCallback(async () => {
    const viewpoint = await createViewpoint();
    if (!viewpoint) throw new Error('Failed to create viewpoint');
    setViewpointItems(viewpointItems.concat(viewpoint));
  }, [createViewpoint, setViewpointItems, viewpointItems]);
  const onClickPlaceViewpointMarker = useCallback(() => {
    setIsPlacingViewpointMarker(true);
  }, [setIsPlacingViewpointMarker]);
  const onClickDeleteViewpointMarker = useCallback(() => {
    deleteViewpointMarker();
  }, [deleteViewpointMarker]);
  const onClickCancelPlaceViewpointMarker = useCallback(() => {
    setIsPlacingViewpointMarker(false);
  }, [setIsPlacingViewpointMarker]);

  const viewpointListItems = useMemo(() => [...viewpointItems].reverse().map((item) => ({
    id: item.uuid,
    imageDataUrl: item.imageDataUrl,
    onClickRemove: () => onClickRemoveViewpoint(item.uuid),
  })), [onClickRemoveViewpoint, viewpointItems]);

  return (
    <Box id="StagedViewpointsPanel" sx={{ p: 2, display: 'flex', overflow: 'hidden', flexDirection: 'column', gap: 1, backgroundColor: theme.palette.primary.main, ...sx }}>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          p: 1,
          gap: 1,
          backgroundColor: theme.palette.background.default,
          borderRadius: '8px',
          boxShadow: 'inset 0px 0px 8px -4px rgba(0,0,0,0.4)',
        }}
      >
        <Box sx={{ display: 'flex', gap: 1 }}>
          {!isPlacingViewpointMarker && (
            <>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={onClickPlaceViewpointMarker}
                sx={{ pl: 1, gap: 1, flexGrow: 1, whiteSpace: 'nowrap' }}
                disabled={modelFileIds.length === 0 || isLoading}
              >
                <Icon path={mdiMapMarker} size={0.75} />
                {t('staged-viewpoints-panel_place-marker-button-label', 'Place Marker')}
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={onClickDeleteViewpointMarker}
                sx={{ pl: 1, gap: 1, whiteSpace: 'nowrap' }}
                disabled={modelFileIds.length === 0 || isLoading || !isViewpointMarkerPlaced}
              >
                <Icon path={mdiMapMarkerRemoveOutline} size={0.75} />
                {t('staged-viewpoints-panel_delete-marker-button-label', 'Delete Marker')}
              </Button>
            </>
          )}
          {isPlacingViewpointMarker && (
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={onClickCancelPlaceViewpointMarker}
              sx={{ pl: 1, gap: 1, flexGrow: 1, whiteSpace: 'nowrap' }}
            >
              <Icon path={mdiCancel} size={0.75} />
              {t('staged-viewpoints-panel_cancel-place-marker-button-label', 'Cancel Placing Marker')}
            </Button>
          )}
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={onClickCreateViewpoint}
          sx={{ pl: 1.5, gap: 1 }}
          disabled={modelFileIds.length === 0 || isLoading || isPlacingViewpointMarker}
        >
          <Icon path={mdiCamera} size={1} />
          {t('staged-viewpoints-panel_create-viewpoint-button-label', 'Create Viewpoint')}
        </Button>
        <Box sx={{ overflow: 'auto', flexGrow: 1 }}>
          <Box sx={{ gap: 2, flexWrap: 'wrap', display: 'flex' }}>
            {viewpointListItems.map((item) => (
              <ViewpointListItem
                key={item.id}
                viewpointItem={item}
                sx={{ flexShrink: 0, width: '100%', maxWidth: 400 }}
              />
            ))}
          </Box>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={onClickClose}
          sx={{ pl: 1.5, gap: 1 }}
        >
          <Icon path={mdiCheck} size={1} />
          {t('staged-viewpoints-panel_close-button-label', 'Finish')}
        </Button>
      </Box>
    </Box>
  );
}
