import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import DocumentUploadProvider from 'upload/contexts/DocumentUploadProvider';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import useVisoplanApiContext from 'api/hooks/useVisoplanApiContext';
import MainMenu from 'projects/components/MainMenu';
import MainHeader from 'projects/components/MainHeader';
import EmailsUploadContextProvider from 'emails/contexts/EmailsUploadContextProvider';

export default function ProjectLayout() {
  const { openedProjectId } = useVisoplanApiContext();
  const { isLoading: isLoadingProject } = useCurrentProjectQuery();

  if (isLoadingProject || !openedProjectId) return <CenteredCircularProgress />;

  return (
    <DocumentUploadProvider>
      <EmailsUploadContextProvider>
        <Box sx={{ height: '100%', overflow: 'hidden', display: 'flex' }}>
          <MainMenu />
          <Box sx={{ overflow: 'hidden', flex: '1 1 0', display: 'flex', flexDirection: 'column' }}>
            <MainHeader />
            <Box sx={{ overflow: 'hidden', flexGrow: 1, display: 'flex' }}>
              <Outlet />
            </Box>
          </Box>
        </Box>
      </EmailsUploadContextProvider>
    </DocumentUploadProvider>
  );
}
