import React, { Suspense } from 'react';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'setup/appInsights';
import {
  Alert, AlertTitle, Box, Link,
} from '@mui/material';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import { Trans, Translation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

function ErrorComponent() {
  const { pathname } = useLocation();
  return (
    <main className="main custom-scrollbar">
      <Suspense fallback={<CenteredCircularProgress />}>
        <Translation ns="error">
          {(t) => (
            <Box
              sx={{
                height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
              }}
            >
              <Alert severity="error">
                <AlertTitle>{t('router-error-boundary_title', 'Error')}</AlertTitle>
                <Trans
                  t={t}
                  i18nKey="router-error-boundary_message"
                  components={{
                    r: <Link href={pathname} />,
                    b: <Link href="/" />,
                  }}
                  defaults="An unexpected error occured while loading the page. You can try to <r>reload the page</r> or go <b>back to the project overview</b>."
                />
              </Alert>
            </Box>
          )}
        </Translation>
      </Suspense>
    </main>
  );
}

function RouterErrorBoundary() {
  return (
    <AppInsightsErrorBoundary onError={ErrorComponent} appInsights={reactPlugin}>
      <ErrorComponent />
    </AppInsightsErrorBoundary>
  );
}
export default RouterErrorBoundary;
