import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import PasswordResetRequestDto from 'users/types/PasswordResetRequestDto';

export default function usePasswordResetRequestMutation() {
  const axiosInstance = useAxiosInstance();
  const mutationFn = useCallback(async (dto: PasswordResetRequestDto) => {
    await axiosInstance.post(`${ApiEndpoint.ResetPassword}/request`, dto);
  }, [axiosInstance]);
  return useMutation<void, unknown, PasswordResetRequestDto>({
    mutationFn,
  });
}
