import { Core } from '@pdftron/webviewer';
import { useMemo } from 'react';

function getAnnotationFillColor(annotation: Core.Annotations.Annotation) {
  if ('FillColor' in annotation && typeof annotation.FillColor === 'object') {
    const fillColor = annotation.FillColor as Core.Annotations.Color | null;
    if (fillColor?.A) {
      return fillColor;
    }
  }
  return undefined;
}

function setAnntotationFillColor(annotation: Core.Annotations.Annotation, color: Core.Annotations.Color) {
  if ('FillColor' in annotation && typeof annotation.FillColor === 'object') {
    // eslint-disable-next-line no-param-reassign
    annotation.FillColor = color;
  }
}

function getAnnotationColor(annotation: Core.Annotations.Annotation) {
  const color = annotation.Color as Core.Annotations.Color | null;
  return color?.A ? color : undefined;
}

function setAnntotationColor(annotation: Core.Annotations.Annotation, color: Core.Annotations.Color) {
  // eslint-disable-next-line no-param-reassign
  annotation.Color = color;
}

export default function useAnnotationFillColor() {
  return useMemo(() => ({
    getAnnotationFillColor,
    setAnntotationFillColor,
    getAnnotationColor,
    setAnntotationColor,
  }), []);
}
