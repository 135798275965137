import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import ViewpointDto from 'issues/types/ViewpointDto';
import CreateViewpointDto from 'issues/types/CreateViewpointDto';
import { useCallback } from 'react';

export default function useViewpointsCreateMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases: viewpointQueryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.Viewpoint);
  const { queryKeyBases: issueQueryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.Issue);
  const mutationFn = useCallback(async (dtos: CreateViewpointDto[]) => {
    const { data: viewpointDtos } = await axiosInstance.post<ViewpointDto[]>(`${ApiEndpoint.Viewpoint}`, dtos);
    return viewpointDtos;
  }, [axiosInstance]);
  const onSuccess = useCallback(() => {
    viewpointQueryKeyBases.forEach((queryKey) => queryClient.invalidateQueries({ queryKey }));
    issueQueryKeyBases.forEach((queryKey) => queryClient.invalidateQueries({ queryKey }));
  }, [issueQueryKeyBases, queryClient, viewpointQueryKeyBases]);
  return useMutation<ViewpointDto[], unknown, CreateViewpointDto[]>({
    mutationFn,
    onSuccess,
  });
}
