import React, {
  Suspense, useCallback, useEffect, useMemo, useState,
} from 'react';
import { Translation } from 'react-i18next';
import {
  Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@mui/material';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import UploadEmailAttachmentItem from 'emails/components/UploadEmailAttachmentItem';
import UploadEmailAttachmentsAddedFeedbackDialog from 'emails/components/UploadEmailAttachmentsAddedFeedbackDialog';
import { POST } from 'Helpers/Request';
import useValidateDocumentUploads from 'upload/hooks/useValidateDocumentUploads';
import DocumentUploadPayloadCandidate from 'upload/types/DocumentUploadPayloadCandidate';
import ValidationApiResponse from 'upload/types/ValidationApiResponse';
import InboxEmailAttachmentDto from 'emails/types/InboxEmailAttachmentDto';
import StagedEmailAttachment from 'emails/types/StagedEmailAttachment';

interface UploadEmailAttachmentsModalProps {
  emailId: string,
  initialAttachments: InboxEmailAttachmentDto[],
  onClose: () => void,
}

export default function UploadEmailAttachmentsModal({
  emailId,
  initialAttachments,
  onClose,
}: UploadEmailAttachmentsModalProps) {
  const { validateUploadPayloadCandidate } = useValidateDocumentUploads();
  const [isInitialValidationDone, setIsInitialValidationDone] = useState(false);
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);
  const [isValidateRequestInFlight, setIsValidateRequestInFlight] = useState(false);
  const [isAttachRequestInFlight, setIsAttachRequestInFlight] = useState(false);
  const [stagedAttachments, setStagedAttachments] = useState<StagedEmailAttachment[]>(initialAttachments.map((initialAttachment) => ({
    ...initialAttachment,
    newName: initialAttachment.name,
    folderId: null,
    isValid: false,
    validationApiResponseBody: null,
  })));

  const areAllStagedAttachmentsValid = useMemo(() => {
    if (!stagedAttachments.length) return false;
    return stagedAttachments.every((attachment) => attachment.validationApiResponseBody?.allSuccess);
  }, [stagedAttachments]);

  const updateAttachment = (attachmentId: string, valuesToUpdate: { folderId?: string, newName?: string, validationApiResponseBody?: ValidationApiResponse }) => {
    setStagedAttachments((currentlyStagedAttachments) => currentlyStagedAttachments.map((stagedAttachment) => {
      if (stagedAttachment.id !== attachmentId) return stagedAttachment;
      return {
        ...stagedAttachment,
        ...valuesToUpdate,
      };
    }));
  };

  const updateAttachmentFolderId = useCallback((attachmentId: string, folderId: string) => updateAttachment(attachmentId, { folderId }), []);
  const updateAttachmentNewName = useCallback((attachmentId: string, newName: string) => updateAttachment(attachmentId, { newName }), []);

  const validate = useCallback(() => {
    setIsValidateRequestInFlight(true);
    stagedAttachments.forEach(async (stagedAttachment) => {
      const validationPayload: DocumentUploadPayloadCandidate = {
        files: [{ name: stagedAttachment.newName }],
        folderId: stagedAttachment.folderId ?? '',
      };
      const validationApiResponseBody = await validateUploadPayloadCandidate(validationPayload);
      updateAttachment(stagedAttachment.id, { validationApiResponseBody });
      setIsValidateRequestInFlight(false);
    });
  }, [validateUploadPayloadCandidate, stagedAttachments]);

  // initial validation
  useEffect(() => {
    if (isInitialValidationDone || !stagedAttachments.every((attachment) => !!attachment.folderId)) return;
    setIsInitialValidationDone(true);
    validate();
  }, [initialAttachments, isInitialValidationDone, stagedAttachments, validate]);

  const onUploadClicked = useCallback(() => {
    setIsAttachRequestInFlight(true);
    const attachRequestData = stagedAttachments.map((stagedAttachment) => ({
      emailId,
      attachmentId: stagedAttachment.id,
      newName: stagedAttachment.newName,
      folderId: stagedAttachment.folderId,
    }));
    POST('api/inbox-email/attach', attachRequestData).then((result: { status: number }) => {
      setIsAttachRequestInFlight(false);
      if (result?.status === 200) {
        setIsFeedbackDialogOpen(true);
      } else {
        onClose();
      }
    });
  }, [stagedAttachments, emailId, onClose]);

  const onCloseFeedbackDialog = useCallback(() => {
    setIsFeedbackDialogOpen(false);
    onClose();
  }, [onClose]);

  const discardAttachment = useCallback(
    (attachmentId: string) => setStagedAttachments((currentlyStagedAttachments) => currentlyStagedAttachments.filter((stagedAttachment) => stagedAttachment.id !== attachmentId)),
    [],
  );

  return (
    <>
      <Dialog id="UploadEmailAttachmentsModal" open PaperProps={{ sx: { width: '590px', height: '634px' } }}>
        <Suspense fallback={<CenteredCircularProgress />}>
          <Translation ns="emails">
            {(t) => (
              <>
                <DialogTitle>{t('upload-attachments_title', 'Upload {{count}} Documents', { count: stagedAttachments.length })}</DialogTitle>
                <DialogContent className="custom-scrollbar">
                  {stagedAttachments.map((stagedAttachment) => (
                    <UploadEmailAttachmentItem
                      key={stagedAttachment.id}
                      stagedAttachment={stagedAttachment}
                      onDiscard={() => discardAttachment(stagedAttachment.id)}
                      onChangeFolderId={updateAttachmentFolderId}
                      onChangeNewName={updateAttachmentNewName}
                      sx={{ mb: 5 }}
                    />
                  ))}
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose} variant="contained" color="secondary" disabled={isValidateRequestInFlight || isAttachRequestInFlight}>
                    {t('upload-attachments_cancel', 'Cancel')}
                  </Button>
                  <Button sx={{ ml: 'auto' }} onClick={validate} variant="contained" color="secondary" disabled={isValidateRequestInFlight || isAttachRequestInFlight}>
                    {t('upload-attachments_validate', 'Validate')}
                    {isValidateRequestInFlight && <CircularProgress size={12} sx={{ ml: 1 }} />}
                  </Button>
                  <Button sx={{ ml: 1 }} onClick={onUploadClicked} variant="contained" color="primary" disabled={isValidateRequestInFlight || isAttachRequestInFlight || !areAllStagedAttachmentsValid}>
                    {t('upload-attachments_confirm', 'Upload')}
                    {isAttachRequestInFlight && <CircularProgress size={12} sx={{ ml: 1 }} />}
                  </Button>
                </DialogActions>
              </>
            )}
          </Translation>
        </Suspense>
      </Dialog>
      {isFeedbackDialogOpen && <UploadEmailAttachmentsAddedFeedbackDialog onClose={onCloseFeedbackDialog} documentCount={stagedAttachments.length} />}
    </>
  );
}
