import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { Alert, Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, SelectChangeEvent, Typography, useTheme } from '@mui/material';
import RichTextField from 'rich-text/components/RichTextField';
import useIssueCommentCreateMutation from 'issues/hooks/useCommentCreateMutation';
import RichTextEditorContextProvider from 'rich-text/contexts/RichTextEditorContextProvider';
import useRichTextEditorContext from 'rich-text/hooks/useRichTextEditorContext';
import PersistIssueCommentDto from 'issues/types/PersistIssueCommentDto';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import { useTranslation } from 'react-i18next';
import useIssueUpdateMutation from 'issues/hooks/useIssueUpdateMutation';
import UpdateIssueDto from 'issues/types/UpdateIssueDto';
import IssueVisibility from 'issues/types/IssueVisibility';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import AssignmentSelect from 'issues/components/AssignmentSelect';
import useCollaboratorsOdataQuery from 'collaborators/hooks/useCollaboratorsOdataQuery';
import InlineTypography from 'common/components/InlineTypography';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import useClosedIssueStatus from 'issues/hooks/useClosedIssueStatus';
import useReleaseIssueType from 'issues/hooks/useReleaseIssueType';
import UpdateManyDocumentVersionsDto from 'documents/types/DocumentVersionsUpdateManyDto';
import useDocumentVersionMassEditMutation from 'documents/hooks/useDocumentVersionMassEditMutation';
import useProjectDocumentStatusesQuery from 'documents/hooks/useProjectDocumentStatusesQuery';
import LabelChip from 'labels/components/LabelChip';
import useDocumentVersionsQuery from 'documents/hooks/useDocumentVersionsQuery';
import useFolderTreeQuery from 'documents-folders/hooks/useFolderTreeQuery';
import useFolderAccessLevel from 'documents-folders/hooks/useFolderAccessLevel';
import CollaboratorFolderAccessLevel from 'documents-folders/types/CollaboratorFolderAccessLevel';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';
import RoleAction from 'projects/types/RoleAction';
import _ from 'lodash';

type IssueResponsibilityActionPanelProps = {
  onClose: () => void,
  id: string,
  issueId: string,
  title: string,
  confirmButtonLabel: string,
  commentDefaultText?: string | undefined,
  targetStatusId?: string,
  commentMandatory?: boolean,
  targetVisibility?: IssueVisibility,
} & ({
  canSelectAssigneesAndReviewer?: false | undefined,
  defaultAssignedCollaboratorIds?: undefined,
  defaultReviewerId?: undefined,
} | {
  canSelectAssigneesAndReviewer: true,
  defaultAssignedCollaboratorIds?: string[] | undefined,
  defaultReviewerId?: string | undefined,
});

function IssueResponsibilityActionPanel({
  onClose,
  id,
  issueId,
  title,
  confirmButtonLabel,
  commentDefaultText,
  targetStatusId,
  commentMandatory,
  canSelectAssigneesAndReviewer,
  defaultAssignedCollaboratorIds,
  defaultReviewerId,
  targetVisibility,
}: IssueResponsibilityActionPanelProps) {
  const { t } = useTranslation('issues');
  const theme = useTheme();
  const { editor, text, setIsDisabled } = useRichTextEditorContext();
  const { mutateAsync: createComment, isPending: isLoadingCommentMutation } = useIssueCommentCreateMutation();
  const { mutateAsync: updateIssue, isPending: isLoadingIssueMutation } = useIssueUpdateMutation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { data: collaborators } = useCollaboratorsOdataQuery({});
  const [selectedAssignedUserIds, setSelectedAssignedUserIds] = useState<string[]>(defaultAssignedCollaboratorIds ?? []);
  const onChangeSelectedAssignedUserIds = useCallback((collaboratorIds: string[]) => setSelectedAssignedUserIds(collaboratorIds), []);
  const [selectedReviewerId, setSelectedReviewerId] = useState<string | undefined>(defaultReviewerId);
  const onChangeSelectedReviewerId = useCallback((event: SelectChangeEvent<string>) => setSelectedReviewerId(event.target.value || undefined), []);
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const getRequestErrorMessage = useRequestErrorMessage();
  const { data: issue } = useIssueQuery(issueId);
  const closedIssueStatus = useClosedIssueStatus();
  const releaseIssueType = useReleaseIssueType();
  const { mutateAsync: mutateLinkedDocumentVersions } = useDocumentVersionMassEditMutation();
  const { data: statuses } = useProjectDocumentStatusesQuery();
  const documentVersionStatuses = useMemo(() => statuses?.filter((status) => status.originalName !== 'Archived'), [statuses]);
  const { data: linkedDocumentVersions } = useDocumentVersionsQuery(issue ? { filter: { id: { in: issue.linkedDocumentVersionIds } } } : undefined);

  const { data: folderTreeData } = useFolderTreeQuery();
  const getFolderAccessLevel = useFolderAccessLevel();
  const linkedDocumentVersionsWithWriteAccess = useMemo(() => linkedDocumentVersions?.filter((documentVersion) => {
    if (!folderTreeData) return false;
    const folder = folderTreeData.foldersById.get(documentVersion.folderId);
    if (!folder) return false;
    const folderAccessLevel = getFolderAccessLevel(folder);
    const hasWriteAccess = folderAccessLevel === CollaboratorFolderAccessLevel.ReadWrite;
    return hasWriteAccess;
  }), [folderTreeData, getFolderAccessLevel, linkedDocumentVersions]);

  const allowedActions = useAllowedActions();

  const [documentVersionStatusUpdateEnabled, setDocumentVersionStatusUpdateEnabled] = useState(false);
  const [documentVersionsTargetStatusId, setDocumentVersionsTargetStatusId] = useState<string>('');

  const onClickConfirm = useCallback(async () => {
    if (!editor || !issueId) return;
    setErrorMessage(undefined);
    setIsDisabled(true);

    try {
      // update issue
      const assignedUserIds = selectedAssignedUserIds.length ? { value: selectedAssignedUserIds } : undefined;
      const updateIssueDto: UpdateIssueDto = {
        id: issueId,
        status: targetStatusId ? { value: targetStatusId } : undefined,
        assignedCollaboratorIds: assignedUserIds,
        reviewerId: selectedReviewerId ? { value: selectedReviewerId } : undefined,
        visibility: targetVisibility ? { value: targetVisibility } : undefined,
      };
      await updateIssue(updateIssueDto);

      // create comment
      const commentText = (text?.trim() || commentDefaultText) || '';
      if (commentText.length) {
        const persistIssueCommentDto: PersistIssueCommentDto = {
          issueId: { value: issueId },
          text: { value: commentText },
        };
        await createComment(persistIssueCommentDto);
        editor.commands.clearContent();
      }

      // update linked documents status
      if (targetStatusId === closedIssueStatus?.id && issue?.issueType === releaseIssueType?.id && documentVersionStatusUpdateEnabled) {
        if (linkedDocumentVersions?.length) {
          const dto: UpdateManyDocumentVersionsDto = {
            ids: linkedDocumentVersions.map((version) => version.id),
            documentStatusId: { value: documentVersionsTargetStatusId ?? null },
          };
          await mutateLinkedDocumentVersions(dto);
        }
      }
      setIsDisabled(false);
      onClose();
    } catch (error: any) {
      setIsDisabled(false);
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [editor, issueId, setIsDisabled, selectedAssignedUserIds, targetStatusId, selectedReviewerId, targetVisibility, updateIssue, text, commentDefaultText, closedIssueStatus?.id, issue?.issueType, releaseIssueType?.id, documentVersionStatusUpdateEnabled, onClose, createComment, linkedDocumentVersions, documentVersionsTargetStatusId, mutateLinkedDocumentVersions, getRequestErrorMessage]);

  const onClickCancel = useCallback(() => {
    if (!editor) return;
    editor.commands.clearContent();
    onClose();
  }, [editor, onClose]);

  const onChangeDocumentVersionsStatusUpdateEnabled = useCallback((event: SyntheticEvent, checked: boolean) => {
    setDocumentVersionStatusUpdateEnabled(checked);
  }, []);

  const onChangeDocumentVersionsTargetStatusId = useCallback((event: SelectChangeEvent<string>) => {
    setDocumentVersionsTargetStatusId(event.target.value);
  }, []);

  const linkedDocumentsStatusUpdateDisabled = useMemo(() => {
    if (!linkedDocumentVersions?.length) return true;
    const publishedDocumentVersionStatus = documentVersionStatuses?.find((status) => status.originalName === 'Published');
    if (!allowedActions?.has(RoleAction.DocStatus_ChangeFromPublished) && linkedDocumentVersions.find((version) => version.status === publishedDocumentVersionStatus?.id)) return true;
    if (!_.isEqual(linkedDocumentVersions, linkedDocumentVersionsWithWriteAccess)) return true;
    return false;
  }, [allowedActions, documentVersionStatuses, linkedDocumentVersions, linkedDocumentVersionsWithWriteAccess]);

  const availableTargetStatuses = useMemo(() => documentVersionStatuses?.filter((status) => !(status.originalName === 'Published' && !allowedActions?.has(RoleAction.DocStatus_ChangeToPublished))), [allowedActions, documentVersionStatuses]);

  if (!issueId) return null;
  if (!collaborators) return <CenteredCircularProgress />;
  return (
    <Box
      id={id}
      sx={{
        backgroundColor: theme.palette.background.default,
        borderRadius: '8px',
        boxShadow: '0px 1px 4px -1px rgba(0,0,0,0.3)',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Typography variant="h4">{title}</Typography>
      {!!canSelectAssigneesAndReviewer && !!collaborators && (
        <>
          <AssignmentSelect value={selectedAssignedUserIds} onChange={onChangeSelectedAssignedUserIds} />
          <FormControl sx={{ flexGrow: 1 }}>
            <InputLabel id="issue-responsibility-action-panel_reviewer-select-label">
              {t('issue-responsibility-action-panel_reviewer-select-label', 'Reviewer')}
            </InputLabel>
            <Select
              id="issue-responsibility-action-panel_reviewer-select"
              value={selectedReviewerId ?? ''}
              onChange={onChangeSelectedReviewerId}
              label={t('issue-responsibility-action-panel_reviewer-select-label', 'Reviewer')}
            >
              <MenuItem value="" sx={{ fontStyle: 'italic' }}>{t('issue-responsibility-action-panel_no-reviewer-item', 'No Reviewer')}</MenuItem>
              {!!collaborators && collaborators.map((collaborator) => (
                <MenuItem key={collaborator.id} value={collaborator.id} sx={{ ...(!!collaborator.isDeleted && { display: 'none' }) }}>
                  <InlineTypography sx={{ ...(!!collaborator.isDeleted && { textDecoration: 'line-through' }) }}>{`${collaborator.firstName} ${collaborator.lastName}`}</InlineTypography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, minHeight: canSelectAssigneesAndReviewer ? 130 : 180 }}>
        <RichTextField
          label={t('issue-responsibility-action-panel_comment-textfield-label', 'Comment')}
        />
        {targetStatusId === closedIssueStatus?.id && issue?.issueType === releaseIssueType?.id && allowedActions?.has(RoleAction.Document_StatusEditing) && (
        <FormGroup sx={{ display: 'flex', gap: 2, pt: 1 }}>
          <FormControlLabel
            control={<Checkbox checked={documentVersionStatusUpdateEnabled} disabled={linkedDocumentsStatusUpdateDisabled} />}
            onChange={onChangeDocumentVersionsStatusUpdateEnabled}
            label={t('issue-responsibility-action-panel_update-linked-documents-checkbox', 'Update status of linked documents')}
          />
          {documentVersionStatusUpdateEnabled && (
          <FormControl fullWidth>
            <InputLabel>{t('issue-responsibility-action-panel_linked-documents-status-select', 'Status')}</InputLabel>
            <Select
              value={documentVersionsTargetStatusId}
              onChange={onChangeDocumentVersionsTargetStatusId}
              label="Status"
            >
              {availableTargetStatuses?.map((status) => (
                <MenuItem key={status.id} value={status.id}>
                  <LabelChip label={status} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          )}
        </FormGroup>
        )}
        {!!errorMessage && (
        <Alert severity="error" onClose={onCloseErrorMessage}>{errorMessage}</Alert>
        )}
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={onClickCancel}
          disabled={isLoadingCommentMutation || isLoadingIssueMutation}
        >
          {t('issue-responsibility-action-panel_cancel-button-label', 'Cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onClickConfirm}
          sx={{ flexGrow: 1 }}
          disabled={isLoadingCommentMutation || isLoadingIssueMutation || (!!commentMandatory && text.trim().length === 0)}
        >
          {confirmButtonLabel}
        </Button>
      </Box>
    </Box>
  );
}

export default function IssueResponsibilityActionPanelWrapper(props: IssueResponsibilityActionPanelProps) {
  return (
    <RichTextEditorContextProvider>
      <IssueResponsibilityActionPanel {...props} />
    </RichTextEditorContextProvider>
  );
}
