import IssuePrintViewColumns from 'issues/types/IssuePrintViewColumns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type PrintColumnTranslations = {
  [key in IssuePrintViewColumns]: string;
};

export default function usePrintColumnTranslations() {
  const { t } = useTranslation('issues');
  return useMemo<PrintColumnTranslations>(() => ({
    [IssuePrintViewColumns.IssueNumber]: t('use-print-column-translations_issue-number', 'Issue Number'),
    [IssuePrintViewColumns.Title]: t('use-print-column-translations_title', 'Title'),
    [IssuePrintViewColumns.Status]: t('use-print-column-translations_status', 'Status'),
    [IssuePrintViewColumns.Type]: t('use-print-column-translations_type', 'Type'),
    [IssuePrintViewColumns.Priority]: t('use-print-column-translations_priority', 'Priority'),
    [IssuePrintViewColumns.Tags]: t('use-print-column-translations_tags', 'Tags'),
    [IssuePrintViewColumns.Assignee]: t('use-print-column-translations_assignee', 'Assignee'),
    [IssuePrintViewColumns.Reviewer]: t('use-print-column-translations_reviewer', 'Reviewer'),
    [IssuePrintViewColumns.Creator]: t('use-print-column-translations_creator', 'Creator'),
    [IssuePrintViewColumns.Created]: t('use-print-column-translations_created', 'Created'),
    [IssuePrintViewColumns.Updated]: t('use-print-column-translations_updated', 'Updated'),
    [IssuePrintViewColumns.Due]: t('use-print-column-translations_due', 'Due'),
    [IssuePrintViewColumns.Start]: t('use-print-column-translations_start', 'Start'),
    [IssuePrintViewColumns.Disciplines]: t('use-print-column-translations_disciplines', 'Disciplines'),
    [IssuePrintViewColumns.Buildings]: t('use-print-column-translations_buildings', 'Buildings'),
    [IssuePrintViewColumns.Floors]: t('use-print-column-translations_floors', 'Floors'),
    [IssuePrintViewColumns.ThumbnailImage]: t('use-print-column-translations_thumbnail-image', 'Thumbnail Image'),
    [IssuePrintViewColumns.Description]: t('use-print-column-translations_description', 'Description'),
    [IssuePrintViewColumns.Images]: t('use-print-column-translations_images', 'Images'),
    [IssuePrintViewColumns.Locations]: t('use-print-column-translations_locations', 'Locations'),
    [IssuePrintViewColumns.Viewpoints]: t('use-print-column-translations_viewpoints', 'Viewpoints'),
    [IssuePrintViewColumns.Emails]: t('use-print-column-translations_emails', 'Emails'),
    [IssuePrintViewColumns.Documents]: t('use-print-column-translations_documents', 'Documents'),
    [IssuePrintViewColumns.Comments]: t('use-print-column-translations_comments', 'Comments'),
  }), [t]);
}
