import type { Theme, ComponentsProps, ComponentsVariants } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

interface MuiDialogTitleComponent {
  defaultProps?: ComponentsProps['MuiDialogTitle'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiDialogTitle'];
  variants?: ComponentsVariants['MuiDialogTitle'];
}

const VisoDialogTitle: MuiDialogTitleComponent = {
  styleOverrides: {
    root: () => ({
      fontSize: 20,
      fontWeight: 700,
      paddingTop: '28px',
      paddingLeft: '32px',
      paddingRight: '32px',
    }),
  },
};

export default VisoDialogTitle;
