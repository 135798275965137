import React from 'react';
import { useTranslation } from 'react-i18next';
import IssueResponsibilityActionPanel from 'issues/components/IssueResponsibilityActionPanel';
import useOpenIssueStatus from 'issues/hooks/useOpenIssueStatus';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import useIssueQuery from 'issues/hooks/useIssueQuery';

interface IssueReopenPanelProps {
  issueId: string,
  onClose: () => void,
}

export default function IssueReopenPanel({
  issueId,
  onClose,
}: IssueReopenPanelProps) {
  const { t } = useTranslation('issues');
  const openIssueStatus = useOpenIssueStatus();
  const { data: issue } = useIssueQuery(issueId);
  if (!openIssueStatus || !issue) return <CenteredCircularProgress />;
  return (
    <IssueResponsibilityActionPanel
      id="ReopenPanel"
      issueId={issueId}
      title={t('issue-reopen-panel_header', 'Assign and Reopen')}
      onClose={onClose}
      confirmButtonLabel={t('issue-reopen-panel_confirm-button-label', 'Reopen')}
      commentDefaultText={t('issue-reopen-panel_comment-default-text', 'Assignment changed and re-opened.')}
      targetStatusId={openIssueStatus.id}
      canSelectAssigneesAndReviewer
      defaultAssignedCollaboratorIds={issue.assignedCollaboratorIds}
      defaultReviewerId={issue.reviewerId}
      commentMandatory
    />
  );
}
