import React, { useCallback, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Alert, AlertTitle } from '@mui/material';
import useFolderDeleteMutation from 'documents-folders/hooks/useFolderDeleteMutation';
import useFolder from 'documents-folders/hooks/useFolder';
import { useTranslation } from 'react-i18next';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useCurrentFolderId from 'documents-folders/hooks/useCurrentFolderId';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import useDocumentsViewNavigationContext from 'documents/hooks/useDocumentsViewNavigationContext';

interface ConfirmDeleteFolderDialogProps {
  folderId: string,
  onClose: () => void,
}

export default function ConfirmDeleteFolderDialog({
  folderId,
  onClose,
}: ConfirmDeleteFolderDialogProps) {
  const { t } = useTranslation('documents-folders');
  const { mutateAsync: deleteFolder, isPending: isLoadingDelete } = useFolderDeleteMutation();
  const folder = useFolder(folderId);
  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { setDocumentScope } = useDocumentsViewNavigationContext();
  const currentFolderId = useCurrentFolderId();

  const onConfirm = useCallback(async () => {
    const redirect = !!currentFolderId && currentFolderId === folderId;
    try {
      await deleteFolder(folderId);
      if (redirect) {
        setDocumentScope({ key: DocumentScopeKey.ProjectDocuments });
      }
      onClose();
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [currentFolderId, deleteFolder, folderId, getRequestErrorMessage, onClose, setDocumentScope]);

  return (
    <Dialog id="ConfirmDeleteFolderDialog" open>
      <DialogTitle>{t('confirm-delete-folder-dialog_title', 'Delete folder "{{folderName}}"?', { folderName: folder?.name })}</DialogTitle>
      <DialogContent>
        {t('confirm-delete-folder-dialog_message', 'Do you really want to delete the folder "{{folderName}}"?', { folderName: folder?.name })}
        {!!errorMessage && (
        <Alert sx={{ mt: 2 }} severity="error">
          <AlertTitle>{t('confirm-delete-folder-dialog_error-alert-title', 'Failed to delete folder', { folderName: folder?.name })}</AlertTitle>
          {errorMessage}
        </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          id="ConfirmDeleteFolderDialogConfirmButton"
          variant="contained"
          color="primary"
          onClick={onConfirm}
          disabled={isLoadingDelete}
          sx={{ mr: 4 }}
        >
          {t('confirm-delete-folder-dialog_confirm-button-label', 'Yes, delete folder')}
          {isLoadingDelete && <CircularProgress size={12} sx={{ ml: 1 }} />}
        </Button>
        <Button
          id="ConfirmDeleteFolderDialogCancelButton"
          variant="contained"
          color="secondary"
          onClick={() => onClose()}
          sx={{ ml: 'auto', minWidth: '120px' }}
        >
          {t('confirm-delete-folder-dialog_cancel-button-label', 'No, Cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
