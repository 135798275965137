import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import PageWrapper from 'common/components/PageWrapper';
import DocumentsPageHeader from 'documents/components/DocumentsPageHeader';
import DocumentFilterContextProvider from 'documents-filter/contexts/DocumentFilterContextProvider';
import DocumentsBrowser from 'documents/components/DocumentsBrowser';
import DocumentSelectionContext, { DocumentSelectionContextState } from 'documents/contexts/DocumentSelectionContext';
import DocumentOptionsContextProvider from 'documents/contexts/DocumentOptionsContextProvider';
import DocumentScope from 'documents/types/DocumentScope';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import DocumentDetailsSelectionContextProvider from 'documents-details/contexts/DocumentDetailsSelectionContextProvider';
import DocumentViewNavigationContextProvider from 'documents/contexts/DocumentViewNavigationContextProvider';
import { DocumentDetailsSelectionChange } from 'documents-details/contexts/DocumentDetailsSelectionContext';

const emptyStringSet = new Set<string>();

export default function DocumentsPage() {
  const [selectedDocumentVersionIds, setSelectedDocumentVersionIds] = useState<string[]>([]);
  const selectedDocumentVersionIdsSet = useMemo(() => new Set(selectedDocumentVersionIds), [selectedDocumentVersionIds]);
  const resetSelection = useCallback(() => setSelectedDocumentVersionIds([]), []);
  const documentSelectionContextValue = useMemo<DocumentSelectionContextState>(() => ({
    selectedDocumentVersionIds,
    selectedDocumentVersionIdsSet,
    setSelectedDocumentVersionIds,
    resetSelection,
    nonDeselectableVersionIds: emptyStringSet,
    nonSelectableVersionIds: emptyStringSet,
  }), [resetSelection, selectedDocumentVersionIds, selectedDocumentVersionIdsSet]);

  const { documentScopeKey, documentScopeId, documentVersionId, issueId } = useParams<'documentScopeKey' | 'documentScopeId' | 'documentVersionId' | 'issueId'>();

  const documentScope = useMemo<DocumentScope>(() => ({
    key: (documentScopeKey ?? DocumentScopeKey.ProjectDocuments) as DocumentScopeKey,
    id: documentScopeId,
  }), [documentScopeId, documentScopeKey]);

  const getDocumentViewNavigationRoute = useCallback((nextDocumentScope: DocumentScope) => {
    const parts = [`../${nextDocumentScope.key}`];
    if (nextDocumentScope.id) {
      parts.push(`${nextDocumentScope.id}`);
    }
    return parts.join('/');
  }, []);

  const navigate = useNavigate();
  const setDocumentVersionAndIssueId = useCallback((change: DocumentDetailsSelectionChange) => {
    const nextDocumentVersionId = change.documentVersionId === null ? undefined : (change.documentVersionId ?? documentVersionId);
    const nextIssueId = change.issueId === null ? undefined : (change.issueId ?? issueId);

    if (nextDocumentVersionId === documentVersionId && nextIssueId === issueId) {
      return;
    }

    const parts = ['.'];
    if (nextDocumentVersionId) {
      parts.push(`preview/${nextDocumentVersionId}`);
      if (nextIssueId) {
        parts.push(`issue/${nextIssueId}`);
      }
    }

    const route = parts.join('/');
    navigate(route);
  }, [documentVersionId, issueId, navigate]);

  return (
    <PageWrapper>
      <DocumentViewNavigationContextProvider getRoute={getDocumentViewNavigationRoute} documentScope={documentScope}>
        <DocumentDetailsSelectionContextProvider
          documentVersionId={documentVersionId}
          issueId={issueId}
          setDocumentVersionAndIssueId={setDocumentVersionAndIssueId}
        >
          <DocumentSelectionContext.Provider value={documentSelectionContextValue}>
            <DocumentFilterContextProvider>
              <DocumentOptionsContextProvider>
                <Box sx={{
                  display: 'grid',
                  gridTemplateRows: '60px 1fr',
                  height: '100%',
                }}
                >
                  <DocumentsPageHeader />
                  <DocumentsBrowser />
                </Box>
              </DocumentOptionsContextProvider>
            </DocumentFilterContextProvider>
          </DocumentSelectionContext.Provider>
        </DocumentDetailsSelectionContextProvider>
      </DocumentViewNavigationContextProvider>
    </PageWrapper>
  );
}
