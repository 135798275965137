import React from 'react';
import { useTranslation } from 'react-i18next';
import StampSettingsDto from 'documents/types/StampSettingsDto';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import { Box, SvgIcon } from '@mui/material';

interface StampExampleProps {
  stampSettings: StampSettingsDto | null,
}

export default function StampExample({
  stampSettings,
}: StampExampleProps) {
  const { t } = useTranslation('settings');
  const { data: currentProject } = useCurrentProjectQuery();
  if (!currentProject) throw new Error('currentProject is missing');
  const now = new Date();
  const timeZoneName = new Intl.DateTimeFormat(stampSettings?.locale, { timeZoneName: 'short' }).formatToParts(now).find((part) => part.type === 'timeZoneName')?.value || '';
  return (
    <Box id="StampExample" sx={{ width: '225px', minHeight: '80px', border: '1px solid black' }}>
      <Box sx={{ lineHeight: '22.5px', borderBottom: '1px solid black', mb: '6px', display: 'flex', alignItems: 'center', fontSize: '10px', fontWeight: 'bold', gap: 1 }} className="stamp-example-projectname">
        <SvgIcon sx={{ marginLeft: '5px', marginTop: '5px' }}>
          <svg width="12" height="18" viewBox="0 0 52 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.3692 11.499L0 31.2917H22.7376L11.3692 11.499Z" fill="#004D95" />
            <path fillRule="evenodd" clipRule="evenodd" d="M25.6327 20.7507L14.2634 0.958008H37.0019L25.6327 20.7507Z" fill="#20C1CD" />
            <path fillRule="evenodd" clipRule="evenodd" d="M40.6307 11.499L29.2615 31.2917H51.9999L40.6307 11.499Z" fill="#AA007B" />
          </svg>
        </SvgIcon>
        <Box>{currentProject.name}</Box>
      </Box>
      <Box sx={{ display: 'flex', fontSize: '9px', lineHeight: '14px', marginLeft: '5px' }} className="stamp-example-attribute">
        <Box sx={{ width: '75px' }} className="stamp-example-attribute-name">
          {t('stamplabel_download', { defaultValue: 'Download', ns: 'pdfPreview', lng: stampSettings?.locale })}
        </Box>
        <Box sx={{ fontWeight: 'bold' }} className="stamp-example-attribute-value">
          {`${now.toLocaleString(stampSettings?.locale, { dateStyle: 'medium', timeStyle: 'short' })} ${timeZoneName}`}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', fontSize: '9px', lineHeight: '14px', marginLeft: '5px' }} className="stamp-example-attribute">
        <Box sx={{ width: '75px' }} className="stamp-example-attribute-name">
          {t('stamplabel_status', { defaultValue: 'Status', ns: 'pdfPreview', lng: stampSettings?.locale })}
        </Box>
        <Box sx={{ fontWeight: 'bold' }} className="stamp-example-attribute-value">
          {t('stamp_example_status', { defaultValue: 'Shared', lng: stampSettings?.locale })}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', fontSize: '9px', lineHeight: '14px', marginLeft: '5px' }} className="stamp-example-attribute">
        <Box sx={{ width: '75px' }} className="stamp-example-attribute-name">
          {t('stamplabel_version', { defaultValue: 'Version', ns: 'pdfPreview', lng: stampSettings?.locale })}
        </Box>
        <Box sx={{ fontWeight: 'bold' }} className="stamp-example-attribute-value">12</Box>
      </Box>
    </Box>
  );
}
