import React, { Suspense } from 'react';
import useNewQueryClient from 'api/hooks/useNewQueryClient';
import { QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from '@mui/material';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import { DndProvider } from 'react-dnd';
import { Provider } from 'react-redux';
import store from 'redux/store';
import theme from 'theme';
import MainContainer from 'setup/components/MainContainer';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import VisoGlobalStyles from 'theme/VisoGlobalStyles';
import VisoplanApiProvider from 'api/contexts/VisoplanApiProvider';
import CustomHtml5Backend from 'common/components/CustomHtml5Backend';

export default function Layout() {
  const queryClient = useNewQueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Provider store={store}>
        <VisoplanApiProvider>
          <ThemeProvider theme={theme}>
            <DndProvider backend={CustomHtml5Backend}>
              <Suspense fallback={<CenteredCircularProgress />}>
                <VisoGlobalStyles />
                <MainContainer />
              </Suspense>
            </DndProvider>
          </ThemeProvider>
        </VisoplanApiProvider>
      </Provider>
    </QueryClientProvider>
  );
}
