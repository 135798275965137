import React, { useCallback, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { Resizable } from 're-resizable';
import { Direction } from 're-resizable/lib/resizer';
import EmailsDetailView from 'emails/components/EmailsDetailView';
import { Outlet, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RoundIconButton from 'common/components/RoundIconButton';
import { t } from 'i18next';

export default function EmailsSideBar() {
  const [sidebarDefaultWidth, setSidebarDefaultWidth] = useState(() => {
    const storedWidth = localStorage.getItem('email-details_default-sidebar-width');
    if (storedWidth?.length) {
      const parsedWidth = parseInt(storedWidth, 10);
      if (!Number.isNaN(parsedWidth)) return parsedWidth;
    }
    return 350;
  });

  const onResizeStopSidebar = useCallback((event: MouseEvent | TouchEvent, direction: Direction, elementRef: HTMLElement) => {
    localStorage.setItem('email-details_default-sidebar-width', `${elementRef.clientWidth}`);
    setSidebarDefaultWidth(elementRef.clientWidth);
  }, []);

  const navigate = useNavigate();

  const handleClose = useCallback(() => { navigate('../'); }, [navigate]);

  return (
    <Resizable
      style={{ boxShadow: '0px 0px 16px 0px rgba(0,0,0,0.1)' }}
      enable={{ top: false, right: false, bottom: false, left: true, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
      defaultSize={{ width: sidebarDefaultWidth, height: 'auto' }}
      minWidth="320px"
      handleComponent={{ left: <Box sx={{ height: '100%', width: '8px', backgroundColor: 'transparent' }}><Box sx={{ height: '100%' }} /></Box> }}
      handleStyles={{ left: { left: 0 } }}
      onResizeStop={onResizeStopSidebar}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '90vh' }}>
        <Box sx={{ pl: 2, minHeight: 80, display: 'flex', gap: 2, justifyItems: 'flexStart', alignItems: 'center' }}>
          <RoundIconButton Icon={ArrowBackIcon} onClick={handleClose} sx={{ flexShrink: 0 }} />
          <Typography variant="h4">{t('emails-detail-view_default-title', 'Email')}</Typography>
        </Box>
        <Divider />
        <Box sx={{ flexGrow: 1, overflow: 'scroll' }}>
          <EmailsDetailView />
        </Box>
        <Divider />
        <Box sx={{ flexGrow: 1 }}>
          <Outlet />
        </Box>
      </Box>
    </Resizable>
  );
}
