import Vector3 from 'common/types/Vector3';
import { VisoNode } from 'Components/Models/Types/BaseDefinitions';
import { ViewerSetting } from 'Components/Models/Types/components';
import { IfcPlane } from 'Components/Models/Types/components/display/clipping-planes/planes';
import { IfcDimensionLine } from 'Components/Models/Types/components/display/dimensions/dimension-line';
import { IfcViewerAPI } from 'Components/Models/Types/ifc-viewer-api';
import { VisoModelFile } from 'Components/Models/Types/VisoModelFile';
import FloorMapSetting from 'models/types/FloorMapSetting';
import Viewer3dInteractionMode from 'models/types/Viewer3dInteractionMode';
import ViewpointBubbleItem from 'models/types/ViewpointBubbleItem';
import ViewpointItem from 'models/types/ViewpointItem';
import { createContext, MutableRefObject, RefObject } from 'react';

export interface Viewer3dContextState {
  viewer3dApiRef: MutableRefObject<IfcViewerAPI | undefined>,
  initializeViewerInstance: (canvasContainerRef: RefObject<HTMLElement>) => void,
  selectedGlobalIds: string[],
  setSelectedGlobalIds: (value: string[]) => void,
  selectComponentUnderCursor: (mutliselect: boolean) => Promise<void>,
  clippingPlanes: IfcPlane[],
  selectedClippingPlane: IfcPlane | undefined,
  setSelectedClippingPlane: (value: IfcPlane | undefined) => void,
  addClippingPlane: (direction: Vector3) => void,
  addClippingPlaneAtCursor: () => void,
  removeClippingPlane: (plane: IfcPlane) => void,
  updateClippingPlanes: () => void,
  resetViewer: () => void,
  viewer3dSettings: ViewerSetting,
  setViewer3dSettings: (nextValue: ViewerSetting) => void,
  floorPlanSettings: FloorMapSetting,
  setFloorPlanSettings: (nextValue: FloorMapSetting) => void,
  interactionMode: Viewer3dInteractionMode,
  setInteractionMode: (nextMode: Viewer3dInteractionMode) => void,
  measurements: IfcDimensionLine[], // TODO: move measurements and section planes into interaction context, maybe even include model selection in that context
  deleteMeasurement: (measurement: IfcDimensionLine) => void,
  createMeasurementPoint: () => void,
  highlightComponentUnderCursor: () => Promise<void>,
  clearComponentHighlight: () => void,
  zoomToSelectedComponents: () => Promise<void>,
  hideSelectedComponents: () => void,
  isolateSelectedComponents: () => void,
  toggleSelectedComponentsTransparent: () => void,
  resetSelectedComponentsPresentation: () => void,
  setSelectedComponentsColor: (hex: string) => void,
  resetSelectedComponentsColor: () => void,
  hideComponentsOfDifferentTypeThanSelected: () => void,
  isolateSelectedComponentsFloors: () => void,
  createViewpoint: () => Promise<ViewpointItem | undefined>,
  getNode: (globalId: string) => VisoNode | undefined,
  setViewpointBubbles: (value: ViewpointBubbleItem[]) => void,
  deleteViewpointMarker: () => void,
  isViewpointMarkerPlaced: boolean,
  setIsViewpointMarkerPlaced: (value: boolean) => void,
  isPlacingViewpointMarker: boolean,
  setIsPlacingViewpointMarker: (value: boolean) => void,
  modelFiles: VisoModelFile[] | undefined,
  setModelFiles: (value: VisoModelFile[] | undefined) => void,
  refreshLoadedModelFiles: () => void,
}

const defaultState: Viewer3dContextState = {
  viewer3dApiRef: undefined!,
  initializeViewerInstance: () => {},
  selectedGlobalIds: [],
  setSelectedGlobalIds: () => {},
  selectComponentUnderCursor: () => Promise.reject(),
  clippingPlanes: [],
  selectedClippingPlane: undefined,
  setSelectedClippingPlane: () => {},
  addClippingPlane: () => { throw new Error('Using default Viewer3dContextState'); },
  addClippingPlaneAtCursor: () => { throw new Error('Using default Viewer3dContextState'); },
  removeClippingPlane: () => { throw new Error('Using default Viewer3dContextState'); },
  updateClippingPlanes: () => { throw new Error('Using default Viewer3dContextState'); },
  resetViewer: () => {},
  viewer3dSettings: undefined!,
  setViewer3dSettings: () => {},
  floorPlanSettings: undefined!,
  setFloorPlanSettings: () => {},
  interactionMode: undefined!,
  setInteractionMode: () => {},
  measurements: [],
  deleteMeasurement: () => {},
  createMeasurementPoint: () => {},
  highlightComponentUnderCursor: () => Promise.reject(),
  clearComponentHighlight: () => {},
  zoomToSelectedComponents: () => Promise.reject(),
  hideSelectedComponents: () => {},
  isolateSelectedComponents: () => {},
  toggleSelectedComponentsTransparent: () => {},
  setSelectedComponentsColor: () => {},
  resetSelectedComponentsPresentation: () => {},
  resetSelectedComponentsColor: () => {},
  hideComponentsOfDifferentTypeThanSelected: () => {},
  isolateSelectedComponentsFloors: () => {},
  createViewpoint: () => Promise.reject(),
  getNode: () => undefined,
  setViewpointBubbles: () => undefined,
  deleteViewpointMarker: () => {},
  isViewpointMarkerPlaced: false,
  setIsViewpointMarkerPlaced: () => {},
  isPlacingViewpointMarker: false,
  setIsPlacingViewpointMarker: () => {},
  modelFiles: undefined,
  setModelFiles: () => {},
  refreshLoadedModelFiles: () => {},
};

const Viewer3dContext = createContext<Viewer3dContextState>(defaultState);
export default Viewer3dContext;
