import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IssuesIcon({
  htmlColor,
  fontSize,
}: Pick<SvgIconProps, 'htmlColor' | 'fontSize'>) {
  return (
    <SvgIcon htmlColor={htmlColor} fontSize={fontSize} viewBox="0 0 18 18">
      <path d="M16.9189 1.67583C16.509 1.49247 16.0179 1.53206 15.6511 1.77829C14.2355 2.72849 12.8659 2.34119 10.7487 1.63056C8.67373 0.934223 6.09148 0.0676866 3.54289 1.77829C3.23819 1.98282 3.05884 2.30377 3.05884 2.64461V9.09473C3.05884 9.50498 3.31796 9.88004 3.72817 10.0635C4.13838 10.247 4.62927 10.2072 4.99596 9.96105C6.41156 9.01085 7.78116 9.39814 9.89836 10.1088C11.0795 10.5051 12.4248 10.9566 13.8348 10.9566C14.902 10.9566 16.0063 10.698 17.1042 9.96105C17.4089 9.75652 17.5883 9.43556 17.5883 9.09473V2.64461C17.5883 2.23435 17.3291 1.8593 16.9189 1.67583Z" />
      <path d="m 2.0325645,1.815 c 0,-0.138 -0.1126442,-0.25 -0.2498249,-0.25 H 1.2819744 c -0.1371807,0 -0.249825,0.112 -0.249825,0.25 v 15.152 c 0,0.138 0.1126443,0.25 0.249825,0.25 h 0.5007652 c 0.1371807,0 0.2498249,-0.112 0.2498249,-0.25 z" />
    </SvgIcon>
  );
}
