import IssuePrintViewColumns from 'issues/types/IssuePrintViewColumns';
import IssuesDocument from 'issues/types/IssuesDocument';
import { Dispatch, SetStateAction, createContext } from 'react';

export interface IssuePrintContextState {
  issuesDocument: IssuesDocument,
  setIssuesDocument: Dispatch<SetStateAction<IssuesDocument>>,
  columnsIncludedInPrint: Set<IssuePrintViewColumns>,
  setColumnsIncludedInPrint: Dispatch<SetStateAction<Set<IssuePrintViewColumns>>>,
  gridTotalPages: number,
  setGridTotalPages: Dispatch<SetStateAction<number>>,
  isPrinting: boolean,
  setIsPrinting: Dispatch<SetStateAction<boolean>>,
}

const defaultState: IssuePrintContextState = {
  issuesDocument: { headerTitle: '', leftPageHead: '', rightPageHead: '', showPageNumbers: true, footerPosition: 'left', pageNumberPosition: 'right', printTable: true, headerHeight: 0 },
  setIssuesDocument: () => {},
  columnsIncludedInPrint: new Set<IssuePrintViewColumns>(),
  setColumnsIncludedInPrint: () => {},
  gridTotalPages: 0,
  setGridTotalPages: () => {},
  isPrinting: false,
  setIsPrinting: () => {},
};

const IssuePrintContext = createContext<IssuePrintContextState>(defaultState);
export default IssuePrintContext;
