import { Vector3 } from "three";
import { VisoObjectMap } from "./BaseDefinitions";
import { VisoStoreyMap } from "./VisoStoreyMap";

export class VisoModelFile {
  id?: string;
  nodes: VisoObjectMap = {};
  storeyMaps: Array<VisoStoreyMap> = [];
  center?: Vector3;
  size?: Vector3;
  disciplineMetaDataId?: string;
  buildingMetaDataId?: string;
  floorMetaDataId?: string;
}
