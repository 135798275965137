import React, { ReactNode, Suspense } from 'react';
import { reactPlugin } from 'setup/appInsights';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import ErrorAlert from 'error/ErrorAlert';

interface SuspenseWithErrorBoundaryProps {
  children?: ReactNode;
  suspenseFallback: ReactNode;
}

function SuspenseWithErrorBoundary({
  children,
  suspenseFallback,
}: SuspenseWithErrorBoundaryProps) {
  return (
    <AppInsightsErrorBoundary onError={ErrorAlert} appInsights={reactPlugin}>
      <Suspense fallback={suspenseFallback}>{children}</Suspense>
    </AppInsightsErrorBoundary>
  );
}
export default SuspenseWithErrorBoundary;
