import { useCallback } from 'react';
import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import RoleDto from 'collaborators/types/RoleDto';
import RoleUpdateActionsDto from 'collaborators/types/RoleUpdateActionsDto';
import RoleActionDto from 'collaborators/types/RoleActionDto';

type Context = [QueryKey, RoleDto[] | undefined][];

export default function useRoleUpdateActionsMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { listQueryKeyBases, getDetailsByIdQueryKey, queryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.Role);
  const mutationFn = useCallback(async (dto: RoleUpdateActionsDto) => {
    const { data } = await axiosInstance.patch<RoleDto>(`${ApiEndpoint.Role}/actions`, dto);
    return data;
  }, [axiosInstance]);
  const onMutate = useCallback((dto: RoleUpdateActionsDto) => {
    queryKeyBases.forEach((queryKeyBase) => queryClient.cancelQueries({ queryKey: queryKeyBase }));
    // optimistic update
    const updateMap = new Map(dto.actions.map((a) => [a.action, a.isAllowed]));
    const updateAction = (a: RoleActionDto): RoleActionDto => ({
      ...(updateMap.has(a.action) ? { ...a, isAllowed: updateMap.get(a.action) } : a),
      childActions: a.childActions.map(updateAction),
    });
    const updateRole = (role: RoleDto) => ({
      ...role,
      actions: role.actions.map(updateAction),
    });
    const listQueriesData = listQueryKeyBases.flatMap((listQueryKeyBase) => queryClient.getQueriesData<RoleDto[]>({ queryKey: listQueryKeyBase }));
    listQueryKeyBases.forEach((listQueryKeyBase) => queryClient.setQueriesData<RoleDto[]>({ queryKey: listQueryKeyBase }, (prev) => {
      if (!prev) return prev;
      return prev.map((role) => (role.id === dto.id ? updateRole(role) : role));
    }));
    const detailsQueryKey = getDetailsByIdQueryKey(dto.id);
    queryClient.setQueryData<RoleDto>(detailsQueryKey, (role) => (role ? updateRole(role) : role));
    const context: Context = [
      ...listQueriesData,
      [detailsQueryKey, queryClient.getQueryData(detailsQueryKey)],
    ];
    return context;
  }, [getDetailsByIdQueryKey, listQueryKeyBases, queryClient, queryKeyBases]);
  const onError = useCallback((error: unknown, variables: RoleUpdateActionsDto, context: Context | undefined) => {
    if (context) {
      context.forEach(([queryKey, queryData]) => {
        if (!queryData) return;
        queryClient.setQueryData(queryKey, queryData);
      });
      queryKeyBases.forEach((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
    }
  }, [queryClient, queryKeyBases]);
  const onSuccess = useCallback(() => {
    queryKeyBases.forEach((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
  }, [queryClient, queryKeyBases]);
  return useMutation<RoleDto, unknown, RoleUpdateActionsDto, Context>({
    mutationFn,
    onMutate,
    onError,
    onSuccess,
  });
}
