import useFolderTreeQuery from 'documents-folders/hooks/useFolderTreeQuery';
import { useMemo } from 'react';

export default function useSiblingFolderNames(parentFolderId: string | undefined) {
  const { data: folderTreeData } = useFolderTreeQuery();
  return useMemo(() => {
    if (!folderTreeData) return undefined;
    const siblingFolders = folderTreeData.folders.filter((f) => (parentFolderId ? f.parentId === parentFolderId : f.isTopLevel));
    return new Set(siblingFolders.map((f) => f.name));
  }, [folderTreeData, parentFolderId]);
}
