import React, { useContext } from 'react';
import ISxProps from 'common/types/ISxProps';
import { TextField } from '@mui/material';
import RichTextInputComponent from 'rich-text/components/RichTextInputComponent';
import RichTextEditorContext, { RichTextEditorContextState } from 'rich-text/contexts/RichTextEditorContext';

interface RichTextFieldProps extends ISxProps {
  label?: string,
}

export default function RichTextField({
  sx,
  label,
}: RichTextFieldProps) {
  const { isFocused, isDisabled, text } = useContext<RichTextEditorContextState>(RichTextEditorContext);
  return (
    <TextField
      sx={sx}
      variant="outlined"
      label={label}
      InputLabelProps={{ shrink: isFocused || text.trim().length !== 0 }}
      InputProps={{ inputComponent: RichTextInputComponent, sx: { height: '100%' } }}
      focused={isFocused}
      disabled={isDisabled}
      fullWidth
      multiline
      rows={4}
    />
  );
}
