import { useMemo } from 'react';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import useDefaultEntityQueryKeys, { ListByIdsQueryKey } from 'api/hooks/useQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';

type LisyByIdsQueryOptions<TEndpoint extends ApiEndpoint, TDto> = UseQueryOptions<TDto[] | undefined, unknown, TDto[] | undefined, ListByIdsQueryKey<TEndpoint> | readonly []>;

export type LisyByIdsUseQueryArgs<TEndpoint extends ApiEndpoint, TDto> = [ids: string[] | undefined, options?: Omit<LisyByIdsQueryOptions<TEndpoint, TDto>, 'intitialData' | 'queryKey'> | undefined];

export default function useListByIdsQuery<TEndpoint extends ApiEndpoint, TDto extends { id: string } | string>(endpoint: TEndpoint, ...[ids, options]: LisyByIdsUseQueryArgs<TEndpoint, TDto>) {
  const { getListsByIdsQueryKey } = useDefaultEntityQueryKeys(endpoint);
  const queryOptions = useMemo<LisyByIdsQueryOptions<TEndpoint, TDto>>(() => ({
    ...options,
    queryKey: ids ? getListsByIdsQueryKey(ids) : ([] as const),
    enabled: (options?.enabled !== false) && ids !== undefined,
  }), [getListsByIdsQueryKey, ids, options]);
  return useQuery(queryOptions);
}
