import Vector3 from 'common/types/Vector3';
import { useCallback, useMemo } from 'react';

export default function useVector3Math() {
  const distanceSqr = useCallback((a: Vector3, b: Vector3) => {
    const dx = (a.x - b.x);
    const dy = (a.y - b.y);
    const dz = (a.z - b.z);
    return (dx * dx) + (dy * dy) + (dz * dz);
  }, []);
  return useMemo(() => ({
    distanceSqr,
  }), [distanceSqr]);
}
