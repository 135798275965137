import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { useTranslation } from 'react-i18next';
import { Box, Chip, Typography, useTheme } from '@mui/material';
import QueryFilterDto from 'query-filters/types/QueryFilterDto';
import QueryFilterKind from 'query-filters/types/QueryFilterKind';

export default function FilterTreeCellRenderer({
  data,
}: ICellRendererParams<QueryFilterDto<QueryFilterKind.Document>, string | undefined>) {
  const { t, i18n } = useTranslation('documents-filter');
  const theme = useTheme();

  return (
    <Box sx={{
      display: 'flex', alignItems: 'center', height: '100%',
    }}
    >
      {!!data && (
        <>
          <Box sx={{ display: 'flex' }}>
            <Chip size="small" label={data.isPrivate ? t('filter-visibility_private', 'Private') : t('filter-visibility_public', 'Public')} sx={{ mr: 1 }} color={data.isPrivate ? 'info' : 'success'} />
          </Box>
          <Box sx={{ minWidth: 0, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            <Typography
              title={data?.name}
              sx={{
                textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap',
              }}
            >
              {data?.name}
            </Typography>
            <Typography sx={{ fontSize: 12, color: theme.palette.text.secondary }}>
              {t('filters-tree_filter-item-created-at', 'Created at {{date}}', { date: new Date(data.creationDate).toLocaleDateString(i18n.language, { dateStyle: 'medium' }) })}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
}
