import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Checkbox, Menu, MenuList, MenuItem, useTheme } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useIssuesDynamicLayout from 'dynamic-layout/hooks/useIssuesDynamicLayout';
import useColumnDisplayNamesByFieldName from 'issues/hooks/useColumnDisplayNamesByFieldName';
import Icon from '@mdi/react';
import { mdiTableEdit } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import IssueDto from 'issues/types/IssueDto';

interface IssuesDataGridColumnOptionsButtonMenuProps extends ISxProps {
}

export default function IssuesDataGridColumnOptionsButtonMenu({
  sx,
}: IssuesDataGridColumnOptionsButtonMenuProps) {
  const { t } = useTranslation('issues');
  const headerColumnNamesByFieldName = useColumnDisplayNamesByFieldName();
  const fieldNames = Object.keys(headerColumnNamesByFieldName) as (keyof IssueDto)[];
  const theme = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const btnRef = useRef<HTMLButtonElement>(null);
  const { columnWidthsByColumnName, columnNamesForcedHidden, columnNamesForcedVisible, persistAsync, isLoadingMutation, isLoadingQuery } = useIssuesDynamicLayout();
  const isLoading = useMemo(() => isLoadingMutation || isLoadingQuery, [isLoadingMutation, isLoadingQuery]);
  const [visibleColumnFieldNames, setVisibleColumnFieldNames] = useState<string[] | undefined>(columnWidthsByColumnName ? fieldNames.filter((fieldName) => columnWidthsByColumnName.has(fieldName)) : undefined);
  const selectionSet = useMemo<Set<string>>(() => new Set<string>(visibleColumnFieldNames), [visibleColumnFieldNames]);

  const toggleColumn = useCallback(async (fieldNameToToggle: string) => {
    if (!columnWidthsByColumnName || !visibleColumnFieldNames) return;
    const updatedVisibleColumnFieldNames = columnWidthsByColumnName.has(fieldNameToToggle) ? visibleColumnFieldNames.filter((f) => f !== fieldNameToToggle) : [...visibleColumnFieldNames, fieldNameToToggle];
    const updatedColumns = updatedVisibleColumnFieldNames.map((pivotFieldName) => ({ fieldName: pivotFieldName, width: columnWidthsByColumnName.get(pivotFieldName) ?? 150 }));
    setVisibleColumnFieldNames(updatedVisibleColumnFieldNames);
    await persistAsync(updatedColumns);
  }, [columnWidthsByColumnName, persistAsync, visibleColumnFieldNames]);

  useEffect(() => {
    setVisibleColumnFieldNames(columnWidthsByColumnName ? Array.from(columnWidthsByColumnName.keys()) : undefined);
  }, [columnWidthsByColumnName]);

  return (
    <>
      <Button variant="outlined" size="small" onClick={() => setIsMenuOpen(true)} sx={{ color: theme.palette.text.primary, pl: 0.5, gap: 0.5, ...sx }} ref={btnRef} id="IssuesDataGridColumnOptionsButtonMenuButton">
        <Icon path={mdiTableEdit} size={0.75} />
        {t('issues-data-grid-column-options-button-menu_label', 'Columns')}
      </Button>
      <Menu
        anchorEl={btnRef.current}
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      >
        <MenuList
          dense
        >
          {fieldNames.map((fieldName) => (
            <MenuItem key={fieldName} onClick={() => toggleColumn(fieldName)} disabled={isLoading || columnNamesForcedHidden?.has(fieldName) || columnNamesForcedVisible?.has(fieldName)}>
              <Checkbox checked={!!((selectionSet.has(fieldName) && !columnNamesForcedHidden?.has(fieldName)) || columnNamesForcedVisible?.has(fieldName))} />
              {headerColumnNamesByFieldName[fieldName] ?? fieldName}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
}
