import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { DockviewGroupPanelFloatingChangeEvent, DockviewReact, DockviewReadyEvent, IDockviewPanelHeaderProps, IDockviewPanelProps, SerializedDockview } from 'dockview';
import Icon from '@mdi/react';
import { Resizable } from 're-resizable';
import { mdiClose, mdiOpenInNew } from '@mdi/js';
import ISxProps from 'common/types/ISxProps';
import IssuesFilterContextProvider from 'issues/contexts/IssuesFilterContextProvider';
import { ModelsPanelDockviewPanelId } from 'models/contexts/ModelsPanelDockviewContext';
import Viewer3dDockviewPanel from 'models/components/Viewer3dDockviewPanel';
import ModelSelectionDockviewPanel from 'models/components/ModelSelectionDockviewPanel';
import ModelIssuesDockviewPanel from 'models/components/ModelIssuesDockviewPanel';
import SectionPlanesDockviewPanel from 'models/components/SectionPlanesDockviewPanel';
import MeasurementsDockviewPanel from 'models/components/MeasurementsDockviewPanel';
import Plan2dDockviewPanel from 'models/components/Plan2dDockviewPanel';
import useModelsPanelDockviewContext from 'models/hooks/useModelsPanelDockviewContext';
import useModelsInteractionContext from 'models/hooks/useModelsInteractionContext';
import StagedViewpointsPanel from 'models/components/StagedViewpointsPanel';
import { ModelsInteractionMode } from 'models/contexts/ModelsInteractionContext';
import StagedLinkedComponentsPanel from 'models/components/StagedLinkedComponentsPanel';
import ComponentDetailsDockviewPanel from 'models/components/ComponentDetailsDockviewPanel';
import RoleAction from 'projects/types/RoleAction';
import useCollaboratorPermissionQueryData from 'collaborators/hooks/useCollaboratorPermissionQueryData';

const MODELS_PANEL_DOCKVIEW_LAYOUT_LOCAL_STORAGE_KEY = 'models-panel-dockview-layout';

interface ModelsPanelProps extends ISxProps {
}

const components: Record<ModelsPanelDockviewPanelId, React.FunctionComponent<IDockviewPanelProps>> = {
  [ModelsPanelDockviewPanelId.Viewer3d]: Viewer3dDockviewPanel,
  [ModelsPanelDockviewPanelId.ModelSelection]: ModelSelectionDockviewPanel,
  [ModelsPanelDockviewPanelId.Issues]: ModelIssuesDockviewPanel,
  [ModelsPanelDockviewPanelId.SectionPlanes]: SectionPlanesDockviewPanel,
  [ModelsPanelDockviewPanelId.Measurements]: MeasurementsDockviewPanel,
  [ModelsPanelDockviewPanelId.Plan2d]: Plan2dDockviewPanel,
  [ModelsPanelDockviewPanelId.ComponentDetails]: ComponentDetailsDockviewPanel,
};

function Tab({ api, containerApi }: IDockviewPanelHeaderProps) {
  const theme = useTheme();
  const panel = containerApi.getPanel(api.id);
  const onClickFloat = useCallback(() => {
    if (!panel) return;
    containerApi.addFloatingGroup(panel);
  }, [containerApi, panel]);
  const onClickClose = useCallback(() => {
    api.close();
  }, [api]);
  const [locationType, setLocationType] = useState<'grid' | 'floating' | 'popout'>(api.location.type);
  const [active, setActive] = useState<boolean>(api.isActive);
  const [visible, setVisible] = useState<boolean>(api.isVisible);
  useEffect(() => {
    const handleLocationChange = api.onDidLocationChange(({ location }: DockviewGroupPanelFloatingChangeEvent) => setLocationType(location.type));
    const handleActiveChange = api.onDidActiveChange(({ isActive }) => setActive(isActive));
    const handleVisibleChange = api.onDidVisibilityChange(({ isVisible }) => setVisible(isVisible));
    return () => {
      handleLocationChange.dispose();
      handleActiveChange.dispose();
      handleVisibleChange.dispose();
    };
  }, [api]);
  const indicatorColor = useMemo(() => {
    if (active) return theme.palette.primary.main;
    if (visible) return theme.palette.grey[600];
    return theme.palette.grey[400];
  }, [active, theme.palette.grey, theme.palette.primary.main, visible]);
  return (
    <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', p: 0.5, gap: 1, borderBottom: `4px solid ${indicatorColor}` }}>
      <Typography sx={{ mr: 'auto', px: 2, fontWeight: 600 }}>{api.title}</Typography>
      {locationType !== 'floating' && <Button variant="outlined" sx={{ minWidth: 'unset', p: 0.25 }} onClick={onClickFloat}><Icon path={mdiOpenInNew} rotate={locationType === 'popout' ? 180 : undefined} size={0.75} /></Button>}
      <Button variant="outlined" sx={{ minWidth: 'unset', p: 0.25 }} onClick={onClickClose}><Icon path={mdiClose} size={0.75} /></Button>
    </Box>
  );
}

const tabComponents = {
  default: (props: IDockviewPanelHeaderProps) => <Tab {...props} />,
};

export default function ModelsPanel({
  sx,
}: ModelsPanelProps) {
  const theme = useTheme();
  const [queryFilterId, setQueryFilterId] = useState<string | undefined>();
  const { setDockviewApi, resetLayoutToDefault } = useModelsPanelDockviewContext();
  const { interactionMode } = useModelsInteractionContext();
  const getCollaboratorPermission = useCollaboratorPermissionQueryData();
  const onDockviewReady = useCallback(async ({ api }: DockviewReadyEvent) => {
    setDockviewApi(api);
    let storedLayout: SerializedDockview | undefined;
    const storedLayoutJson = localStorage.getItem(MODELS_PANEL_DOCKVIEW_LAYOUT_LOCAL_STORAGE_KEY);
    try {
      storedLayout = storedLayoutJson ? JSON.parse(storedLayoutJson) as SerializedDockview : undefined;
      if (storedLayout) {
        api.fromJSON(storedLayout);
      } else {
        resetLayoutToDefault();
      }
    } catch {
      localStorage.removeItem(MODELS_PANEL_DOCKVIEW_LAYOUT_LOCAL_STORAGE_KEY);
      resetLayoutToDefault();
    }

    api.onDidLayoutChange(() => {
      localStorage.setItem(MODELS_PANEL_DOCKVIEW_LAYOUT_LOCAL_STORAGE_KEY, JSON.stringify(api.toJSON()));
    });
    const openedIssuePanel = api.getPanel(ModelsPanelDockviewPanelId.Issues);
    if (openedIssuePanel) {
      const collaboratorPermission = await getCollaboratorPermission();
      const viewIssuesAction = collaboratorPermission.actions.find((a) => a.action === RoleAction.Issues);
      if (viewIssuesAction && !viewIssuesAction.isAllowed) {
        openedIssuePanel.api.close();
      }
    }
  }, [getCollaboratorPermission, resetLayoutToDefault, setDockviewApi]);

  return (
    <Box
      id="ModelsPanel"
      sx={{
        boxShadow: 'inset 0px 0px 6px -2px rgba(0,0,0,0.1)',
        height: '100%',
        overflow: 'hidden',
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        ...(interactionMode ? { border: `4px solid ${theme.palette.primary.main}` } : {}),
        ...sx,
      }}
    >
      <Box sx={{ overflow: 'hidden' }}>
        <IssuesFilterContextProvider queryFilterId={queryFilterId} setQueryFilterId={setQueryFilterId}>
          <DockviewReact onReady={onDockviewReady} components={components} tabComponents={tabComponents} className="dockview-theme-light" defaultTabComponent={tabComponents.default} />
        </IssuesFilterContextProvider>
      </Box>
      {!!interactionMode && (
        <Resizable
          style={{ boxShadow: '0px 0px 16px 0px rgba(0,0,0,0.1)', overflow: 'hidden' }}
          enable={{ top: false, right: false, bottom: false, left: true, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
          defaultSize={{ width: '390px', height: 'auto' }}
          minWidth="390px"
          handleComponent={{ left: <Box sx={{ height: '100%', width: '8px', backgroundColor: 'transparent' }}><Box sx={{ height: '100%' }} /></Box> }}
          handleStyles={{ left: { left: 0 } }}
        >
          {interactionMode === ModelsInteractionMode.ViewpointManagement && (
            <StagedViewpointsPanel sx={{ height: '100%' }} />
          )}
          {interactionMode === ModelsInteractionMode.LinkedComponentManagement && (
            <StagedLinkedComponentsPanel sx={{ height: '100%' }} />
          )}
        </Resizable>
      )}
    </Box>
  );
}
