import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Alert, Snackbar, CircularProgress, AlertTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ProjectUpdateNameDto from 'projects/types/ProjectUpdateNameDto';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import useProjectUpdateNameMutation from 'projects/hooks/useProjectUpdateNameMutation';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';

interface RenameProjectDialogProps {
  open: boolean,
  onClose: () => void,
}

export default function RenameProjectDialog({
  open,
  onClose,
}: RenameProjectDialogProps) {
  const { t } = useTranslation('settings');
  const { data: currentProject } = useCurrentProjectQuery();
  const [name, setName] = useState<string>('');
  const onChangeName = useCallback((e: ChangeEvent<HTMLInputElement>) => setName(e.target.value), []);
  const [abbreviation, setAbbreviation] = useState<string>('');
  const onChangeAbbreviation = useCallback((e: ChangeEvent<HTMLInputElement>) => setAbbreviation(e.target.value), []);
  const { mutateAsync, isPending } = useProjectUpdateNameMutation();
  const canConfirm = useMemo(() => !!currentProject && name?.length && abbreviation?.length && !isPending, [abbreviation?.length, currentProject, isPending, name?.length]);
  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);
  const onCloseSuccessMessage = useCallback(() => setIsSuccessMessageVisible(false), []);
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const onClickConfirm = useCallback(async () => {
    if (!currentProject || !name || !abbreviation) return;
    const projectUpdateNameDto: ProjectUpdateNameDto = {
      id: currentProject.id,
      name,
      abbreviation,
    };
    try {
      await mutateAsync(projectUpdateNameDto);
      setIsSuccessMessageVisible(true);
      onClose();
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [abbreviation, currentProject, getRequestErrorMessage, mutateAsync, name, onClose]);
  const onClickCancel = useCallback(() => {
    onClose();
  }, [onClose]);
  useEffect(() => {
    if (open && currentProject) {
      setErrorMessage(undefined);
      setName(currentProject.name);
      setAbbreviation(currentProject.abbreviation);
    }
  }, [open, currentProject]);
  return (
    <>
      <Dialog open={open} id="RenameProjectDialog">
        <DialogTitle>{t('rename-project-dialog_header', 'Rename Project')}</DialogTitle>
        <DialogContent sx={{
          display: 'flex', flexDirection: 'column', gap: 2, minWidth: 450,
        }}
        >
          <TextField value={name} onChange={onChangeName} sx={{ mt: 1 }} label={t('edit-project-name-tab-pane_project-name-text-field-label', 'Project Name')} />
          <TextField value={abbreviation} onChange={onChangeAbbreviation} label={t('edit-project-name-tab-pane_project-abbreviation-text-field-label', 'Abbreviation')} />
          {!!errorMessage && (
          <Alert severity="error" sx={{ mt: 2 }} onClose={onCloseErrorMessage}>
            {errorMessage}
          </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClickCancel} variant="contained" color="secondary" sx={{ mr: 'auto' }}>
            {t('rename-project-dialog_cancel-button-label', 'Cancel')}
          </Button>
          <Button disabled={!canConfirm} onClick={onClickConfirm} variant="contained" color="primary">
            {isPending && <CircularProgress sx={{ mr: 1 }} size={12} />}
            {t('rename-project-dialog_confirm-button-label', 'Save')}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={isSuccessMessageVisible} onClose={onCloseSuccessMessage} autoHideDuration={6000} id="RenameProjectDialogSuccessSnackbar">
        <Alert severity="success" onClose={onCloseSuccessMessage}>
          <AlertTitle>{t('rename-project-dialog_success-alert-title', 'Success')}</AlertTitle>
          {t('rename-project-dialog_success-alert-text', 'The project was renamed')}
        </Alert>
      </Snackbar>
    </>
  );
}
