import type { Theme, ComponentsProps, ComponentsVariants } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

interface MuiFormControlLabelComponent {
  defaultProps?: ComponentsProps['MuiFormControlLabel'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiFormControlLabel'];
  variants?: ComponentsVariants['MuiFormControlLabel'];
}

const VisoFormControlLabel: MuiFormControlLabelComponent = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: 0,
      fontSize: theme.typography.fontSize,
      justifyContent: 'normal',
    }),
  },
};

export default VisoFormControlLabel;
