import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import VerticalDivider from 'common/styled/VerticalDivider';
import HeaderBox from 'common/components/HeaderBox';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import RenameProjectDialog from 'settings/components/RenameProjectDialog';
import useCurrentCollaboratorRole from 'collaborators/hooks/useCurrentCollaboratorRole';

export default function SettingsPageHeader() {
  const { t } = useTranslation('settings');
  const { data: currentProject } = useCurrentProjectQuery();
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
  const onClickRename = useCallback(() => setIsRenameDialogOpen(true), []);
  const onCloseRenameDialog = useCallback(() => setIsRenameDialogOpen(false), []);
  const currentUserRole = useCurrentCollaboratorRole();
  const canRename = useMemo(() => !!currentUserRole?.isAdmin, [currentUserRole]);
  return (
    <HeaderBox id="SettingsPageHeader">
      <Typography id="SettingsPageHeaderTitle" variant="h2" sx={{ width: 292, pl: 4, letterSpacing: '-0.05rem' }}>{t('settings-page-header_title', 'Project Settings')}</Typography>
      <VerticalDivider sx={{ height: 36 }} />
      <Typography variant="h4" sx={{ ml: 4 }}>
        {currentProject?.name}
      </Typography>
      {canRename && (
      <Button onClick={onClickRename} sx={{ ml: 2 }} variant="contained" color="secondary" size="small">
        {t('settings-page-header_rename-project-button-label', 'Rename')}
      </Button>
      )}
      <RenameProjectDialog open={isRenameDialogOpen} onClose={onCloseRenameDialog} />
    </HeaderBox>
  );
}
