import type { Theme, ComponentsProps, ComponentsVariants } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

interface MuiDialogContentComponent {
  defaultProps?: ComponentsProps['MuiDialogContent'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiDialogContent'];
  variants?: ComponentsVariants['MuiDialogContent'];
}

const VisoDialogContent: MuiDialogContentComponent = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontSize: theme.typography.fontSize,
      paddingLeft: '32px',
      paddingRight: '32px',
    }),
  },
};

export default VisoDialogContent;
