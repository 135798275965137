import IssueDto from 'issues/types/IssueDto';
import IssuesSortMode from 'issues/types/IssuesSortMode';
import { QueryOptions } from 'odata-query';
import QueryFilterPersistableState from 'query-filters/types/QueryFilterPersistableState';
import QueryFilterKind from 'query-filters/types/QueryFilterKind';
import { Dispatch, SetStateAction, createContext } from 'react';

export interface IssuesFilterContextState {
  queryFilterState: QueryFilterPersistableState<QueryFilterKind.Issue>,
  queryFilterId: string | undefined,
  setQueryFilterId: (value: string | undefined) => void,
  sortMode: IssuesSortMode,
  setSortMode: Dispatch<SetStateAction<IssuesSortMode>>,
  odataQuery: Partial<QueryOptions<IssueDto>> | undefined,
}

const defaultState: IssuesFilterContextState = {
  queryFilterState: undefined!,
  queryFilterId: undefined,
  setQueryFilterId: () => {},
  sortMode: undefined!,
  setSortMode: () => {},
  odataQuery: undefined,
};

const IssuesFilterContext = createContext<IssuesFilterContextState>(defaultState);
export default IssuesFilterContext;
