interface ApiConfig {
  environment: 'internal' | 'review' | 'prerelease' | 'release',
  apiUrl: string,
  bimQUrl: string,
  graphQlUrl: string,
  pdfTronUrl: string,
  syncUrl: string,
  appInsightsConnectionString: string,
}

// @ts-ignore (getConfig() is defined in the separately loaded, plain javascript file config.js, that is different per environment)
const config = getConfig() as ApiConfig;

export default function useApiConfig() {
  return config;
}
