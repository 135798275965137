import React, { useMemo, useRef } from 'react';
import { Box, Skeleton } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import { AgCharts } from 'ag-charts-react';
import { AgChartOptions, AgDonutSeriesTooltipRendererParams } from 'ag-charts-community';
import useIssuesOdataQuery from 'issues/hooks/useIssuesOdataQuery';
import LabelType from 'labels/types/LabelType';
import useLabelsOdataQuery from 'labels/hooks/useLabelsOdataQuery';

interface IssueDonutDatum {
  name: string,
  count: number,
  color: string,
}

interface IssueLabelsDonutChartProps extends ISxProps {
  labelType: LabelType.IssueStatus | LabelType.IssueType | LabelType.IssuePriority,
  title: string,
}

const labelFieldMap: { [type in LabelType.IssueStatus | LabelType.IssueType | LabelType.IssuePriority]: 'issueStatus' | 'issueType' | 'issuePriority' } = {
  [LabelType.IssueStatus]: 'issueStatus',
  [LabelType.IssueType]: 'issueType',
  [LabelType.IssuePriority]: 'issuePriority',
};

export default function IssueLabelsDonutChart({
  labelType,
  title,
  sx,
}: IssueLabelsDonutChartProps) {
  const donutContainerRef = useRef<HTMLDivElement>(null);
  const { data: issues } = useIssuesOdataQuery({});
  const { data: labels } = useLabelsOdataQuery({ filter: { type: LabelType[labelType], isDeleted: false }, orderBy: 'order asc' });
  const labelField = useMemo(() => labelFieldMap[labelType], [labelType]);
  const chartOptions = useMemo<AgChartOptions | undefined>(() => {
    if (!issues || !labels) return undefined;
    const counts = new Map(labels.map((label) => [label.id, 0]));
    issues.forEach((issue) => {
      if (issue[labelField] && counts.has(issue[labelField])) {
        counts.set(issue[labelField]!, counts.get(issue[labelField])! + 1);
      }
    });
    const data: IssueDonutDatum[] = labels.filter((label) => counts.get(label.id)).map((label) => ({
      name: label.name,
      count: counts.get(label.id) ?? 0,
      color: label.color ?? '#000000',
    }));
    const options: AgChartOptions = {
      data,
      series: [
        {
          type: 'donut',
          title: { text: title },
          legendItemKey: 'name',
          tooltip: {
            renderer: ({ datum }: AgDonutSeriesTooltipRendererParams<IssueDonutDatum>) => ({ content: `${datum.name}: ${datum.count}` }),
          },
          angleKey: 'count',
          innerRadiusRatio: 0.7,
          fills: data.map(({ color }) => color),
        },
      ],
      container: donutContainerRef.current,
      legend: {
        maxHeight: 100,
        item: {
          label: {
            formatter: ({ itemId }) => `${data[itemId].name} (${data[itemId].count})`,
          },
        },
      },
      padding: { left: 0, right: 0, top: 0, bottom: 0 },
    };
    return options;
  }, [issues, labels, title, labelField]);
  return (
    <Box id="IssueLabelsDonutChart" sx={sx}>
      {!chartOptions && <Box sx={{ display: 'flex', flexDirection: 'column', aspectRatio: '1 / 1', p: 8 }}><Skeleton variant="circular" sx={{ width: '100%', height: '100%' }} /></Box>}
      {!!chartOptions && (
        <Box sx={{ overflow: 'hidden' }} ref={donutContainerRef}>
          <AgCharts options={chartOptions} />
        </Box>
      )}
    </Box>
  );
}
