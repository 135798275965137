import React, { useCallback, useContext } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import DocumentsDataGridRow from 'documents/types/DocumentDataGridRow';
import { useTranslation } from 'react-i18next';
import useCurrentCollaboratorRole from 'collaborators/hooks/useCurrentCollaboratorRole';
import RoleAction from 'projects/types/RoleAction';
import useCurrentCollaboratorFolderAccessLevel from 'documents-folders/hooks/useCurrentCollaboratorFolderAccessLevel';
import CollaboratorFolderAccessLevel from 'documents-folders/types/CollaboratorFolderAccessLevel';
import LabelType from 'labels/types/LabelType';
import LabelDto from 'labels/types/LabelDto';
import useLabelsOdataQuery from 'labels/hooks/useLabelsOdataQuery';
import { useMutation } from '@tanstack/react-query';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDocumentVersionUpdateMutationFn from 'documents/hooks/useDocumentVersionUpdateMutationFn';
import DocumentVersionDto from 'documents/types/DocumentVersionDto';
import DocumentOptionsContext, { DocumentOptionsContextState } from 'documents/contexts/DocumentOptionsContext';
import DocumentAbbreviationDisplayMode from 'documents/types/DocumentAbbreviationDisplayMode';

type LabelCellProps<TUpdateDto extends { id: string }> = Partial<ICellRendererParams<DocumentsDataGridRow, string | undefined>> & {
  labelType: LabelType,
  apiEndpoint: ApiEndpoint,
  getUpdateDto: (labelId: string | null) => TUpdateDto,
};

export default function LabelCell<TUpdateDto extends { id: string }>({
  data,
  value: selectedLabelId,
  labelType,
  apiEndpoint,
  getUpdateDto,
}: LabelCellProps<TUpdateDto>) {
  const { t } = useTranslation('documents');
  const currentUserRole = useCurrentCollaboratorRole();
  const { data: labels } = useLabelsOdataQuery({ filter: { type: LabelType[labelType] }, orderBy: 'order asc' });
  const mutationFn = useDocumentVersionUpdateMutationFn<TUpdateDto>(apiEndpoint);
  const { mutateAsync, isPending } = useMutation<DocumentVersionDto, unknown, TUpdateDto>({ mutationFn });
  const accessLevel = useCurrentCollaboratorFolderAccessLevel(data?.folderId);
  const { abbreviationDisplayMode } = useContext<DocumentOptionsContextState>(DocumentOptionsContext);
  const getLabelCaption = useCallback((labelDto: LabelDto) => (
    (abbreviationDisplayMode === DocumentAbbreviationDisplayMode.Abbreviation && labelDto.abbreviation)
      ? labelDto.abbreviation
      : labelDto.name
  ), [abbreviationDisplayMode]);

  const onChange = useCallback(async (event: SelectChangeEvent<string>) => {
    if (!data) return;
    const updateDto = getUpdateDto(event.target.value || null);
    await mutateAsync(updateDto);
  }, [data, getUpdateDto, mutateAsync]);

  return (
    <Select
      value={selectedLabelId ?? ''}
      disabled={!labels?.length || isPending || data?.isArchived || !currentUserRole?.allowedActions?.has(RoleAction.Document_Editing) || accessLevel !== CollaboratorFolderAccessLevel.ReadWrite}
      onChange={onChange}
      variant="standard"
      sx={{ width: '100%' }}
    >
      <MenuItem value=""><i>{t('label-cell_value-none', 'Unassigned')}</i></MenuItem>
      {labels?.map((label) => (
        <MenuItem value={label.id} key={label.id}>{getLabelCaption(label)}</MenuItem>
      ))}
    </Select>
  );
}
