import axios, { AxiosInstance, CancelTokenSource } from "axios";
import throttledQueue from "throttled-queue";
import { VisoDataType } from "./viso-types";
import { ModelFileDecoder } from "./ModelFileDecoder";

const CHUNK_SIZE = 140;

export class VisoplanService {
  private api: AxiosInstance;
  private cancelToken: CancelTokenSource;

  constructor(baseURL: string) {
    this.api = axios.create({ baseURL });
    this.cancelToken = axios.CancelToken.source();
  }

  async getMsgpack(dataType: VisoDataType, ids: string[], token: { bearer: string }) {
    const throttle = throttledQueue(10, 1000);
    const subIds = this.splitIds(ids);
    const promises = subIds.map((ids) =>
      throttle(() => {
        return this.api?.get(`${dataType}/${ids}`, {
          headers: {
            Authorization: `bearer ${token.bearer}`,
            Accept: "application/msgpack",
            'visoplan-client-id': 'Visoplan Webclient',
            'visoplan-webclient-request-source': 'Legacy 3D Viewer',
          },
          responseType: "arraybuffer",
          cancelToken: this.cancelToken.token,
        });
      })
    );

    let data: any[] = [];
    const decoder = new ModelFileDecoder();
    const responses = await Promise.all(promises);
    responses.forEach((res) => {
      if (res) {
        const object = decoder.decode(
          res?.data,
          dataType,
          res?.headers["content-type"].includes("application/msgpack")
        );
        data = [...data, ...object];
      }
    });

    return data;
  }

  async getResourceStream(id: string, token: any) {
    return await this.api?.get(`resource/${id}`, {
      headers: {
        Authorization: `bearer ${token.bearer}`,
        Accept: "application/octet-stream",
        'visoplan-client-id': 'Visoplan Webclient',
        'visoplan-webclient-request-source': 'Legacy 3D Viewer',
      },
      responseType: "arraybuffer",
      cancelToken: this.cancelToken.token,
    });
  }

  async postImageAsync(projectId: string, image: FormData, token: any) {
    return await this.api?.post(`resource/${projectId}`, image, {
      headers: {
        Authorization: `bearer ${token.bearer}`,
        ContentType: "multipart/form-data",
        'visoplan-client-id': 'Visoplan Webclient',
        'visoplan-webclient-request-source': 'Legacy 3D Viewer',
      },
      cancelToken: this.cancelToken.token,
    });
  }

  createNewCancelToken() {
    this.cancelToken = axios.CancelToken.source();
  }

  cancelRequest() {
    this.cancelToken.cancel("Cancel request by User.");
  }

  private splitIds(ids: string[]) {
    return ids.reduce((result: any[], item: string, index: number) => {
      const chunkIndex = Math.floor(index / CHUNK_SIZE);
      if (!result[chunkIndex]) {
        result[chunkIndex] = [];
      }

      result[chunkIndex].push(item);
      return result;
    }, []);
  }
}
