import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import DynamicLayout from 'dynamic-layout/types/DynamicLayout';
import DynamicLayoutKey from 'dynamic-layout/types/DynamicLayoutKey';
import DynamicLayoutDto from 'dynamic-layout/types/DynamicLayoutDto';

export default function useDynamicLayoutQuery(dynamicLayoutKey: DynamicLayoutKey) {
  const endpoint = ApiEndpoint.DynamicLayout;

  const axiosInstance = useAxiosInstance();
  const { singletonListQueryKey: listsByCurrentProjectQueryKey } = useDefaultEntityQueryKeys(endpoint);
  const queryKey = useMemo(() => [listsByCurrentProjectQueryKey, dynamicLayoutKey], [dynamicLayoutKey, listsByCurrentProjectQueryKey]);
  return useQuery<DynamicLayout | undefined>({
    queryKey,
    queryFn: async () => {
      const { data } = await axiosInstance.get<DynamicLayoutDto>(`/${endpoint}/${dynamicLayoutKey}`);
      return data;
    },
  });
}
