import React, { useMemo } from 'react';
import $TSFixMe from 'common/types/FixMeAny';

interface ModalProps {
  children: JSX.Element,
  style?: object,
  className?: string,
  addChannel?: $TSFixMe,
  infoChannel?: $TSFixMe,
}

function Modal({
  children,
  style,
  className = '',
  addChannel,
  infoChannel,
}: ModalProps) {
  const outerDivClassName = useMemo(() => {
    if (addChannel !== undefined) return addChannel;
    if (infoChannel !== undefined) return infoChannel;

    return `modal-backdrop ${className}`;
  }, [addChannel, infoChannel, className]);

  const innerDivClassName = useMemo(() => {
    if (addChannel !== undefined) return 'modal-create-channel';
    if (infoChannel !== undefined) return 'modal-for-info';

    return 'modal-content';
  }, [addChannel, infoChannel]);

  return (
    <div
      className={outerDivClassName}
    >
      <div
        className={`${innerDivClassName} custom-scrollbar`}
        style={style || {}}
      >
        {children}
      </div>
    </div>
  );
}

Modal.defaultProps = {
  style: undefined,
  addChannel: undefined,
  infoChannel: undefined,
};

export default Modal;
