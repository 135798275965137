import i18n from 'i18next';
import moment from 'moment';
import { isEmpty } from 'lodash';

import { FileTypeIndexes, getFileTypes, Timeframe_Categories } from './Common';
import FolderAccessType from 'documents-folders/types/FolderAccessType';

const OneMB = 1048576;
const OneKB = 1024;

export const replaceArrayItem = (arr, value, index) => {
  const buffer = Object.assign([], arr);
  if (typeof value !== 'object') {
    buffer.splice(index, 1, value);
    return buffer;
  } else {
    return buffer.map((item, idx) =>
      idx === index ? { ...item, ...value } : item
    );
  }
};
export const removeArrayItem = (arr, index) => {
  const buffer = Object.assign([], arr);
  buffer.splice(index, 1);
  return buffer;
};
export const addArrayItem = (arr = [], value) => {
  const buffer = [...arr, value];
  return buffer;
};

export const emailPattern = new RegExp(
  /^(('[\w-\s]+')|([\w-]+(?:\.[\w-]+)*)|('[\w-\s]+')([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
);

export const toggleDropdown = (event) => {
  event.currentTarget.parentElement.classList.toggle('opened');
};

export const hasUpperCase = (str) => {
  return str.toLowerCase() !== str;
};

export const hasLowerCase = (str) => {
  return str.toUpperCase() !== str;
};

export const hasNumber = (str) => {
  return /\d/.test(str);
};

/**
 * @param {Array} array
 * @return {[{}]}
 */
export const convertArrayForSelect = (array) => {
  let buffer = [{ value: null, label: i18n.t('all', 'All') }];
  array.map((a) =>
    buffer.push(typeof a === 'object' ? a : { value: a, label: a })
  );
  return buffer;
};

/**
 * Capitalize the first letter of each word
 * @param {String} phrase
 * @return {String}
 */
export const capitalizeFirstLetter = (phrase) => {
  const buffer = phrase.split(' ');
  buffer.map(
    (category, i) =>
      (buffer[i] = category[0].toUpperCase() + category.substr(1))
  );
  return buffer.join(' ');
};

export const sortArrayByKey = (buffer, key) => {
  buffer.sort((a, b) => {
    if (a[key] < b[key]) return 1;
    else if (a[key] > b[key]) return -1;
    else return 0;
  });

  return buffer;
};

export const sortObjectArrayByItem = (buffer, key) => {
  buffer.sort(([, a], [, b]) => {
    if (a[key] < b[key]) return 1;
    else if (a[key] > b[key]) return -1;
    else return 0;
  });

  return buffer;
};

export const formatFileSize = (size) => {
  return parseFloat(size / OneMB);
};

export const formatFileSize1 = (size) => {
  const mbSize = parseFloat(size / OneMB).toFixed(2);
  if (mbSize <= 0) return `${parseFloat(size / OneKB).toFixed(2)}KB`;
  return `${mbSize}MB`;
};

export const getFolderTranslation = (text) => {
  if (text && text.includes('private folder'))
    return text.replaceAll(
      'private folder',
      i18n.t('private folder', 'private folder')
    );
  else return i18n.t(text) ? i18n.t(text) : text;
};

export const downloadFile = (data, fileName) => {
  let a = document.createElement('a');
  a.href = window.URL.createObjectURL(data);
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const getErrorMessage = (response) => {
  let errorMsg = '';
  if (response.status === 400 && response.body.errorMessages.length > 0)
    errorMsg = response.body.errorMessages[0].errorMessage;
  return errorMsg;
};

export const selectInfoText = (
  folder,
  privateInfo,
  restrictedInfo,
  publicInfo,
  current_project,
) => {
  if (folder.accessType === FolderAccessType.Private) {
    return privateInfo;
  }
  if (
    !folder.isDefault &&
    folder.users.length < current_project.userPreviews.length
  ) {
    return restrictedInfo;
  }
  return publicInfo;
};

export const selectStyles = (other = false, getStatusBackground) => ({
  option: (styles, { data }) => {
    return {
      ...styles,
      display: !other && data.value === 0 ? 'none !important' : 'block',

      ':before': {
        backgroundColor: getStatusBackground(data.value),
        borderRadius: '50%',
        content: '\'\'',
        display: 'block',
        marginRight: 10,
        height: 7,
        width: 7,
      },
    };
  },
  singleValue: (styles, { data }) => {
    return {
      ...styles,
      ...{
        alignItems: 'center',
        display: 'flex',
        lineHeight: 'normal',

        ':before': {
          backgroundColor: getStatusBackground(data.value),
          borderRadius: '50%',
          content: '\'\'',
          display: 'block',
          marginRight: 10,
          height: 7,
          width: 7,
        },
      },
    };
  },
});

export const isAllowImagePreview = (document) => {
  if (!document?.name) return false;

  const lowerCaseDocumentName = document.name.toLowerCase();
  const isAvailableToPreview =
    lowerCaseDocumentName.endsWith(".png") ||
    lowerCaseDocumentName.endsWith(".jpg") ||
    lowerCaseDocumentName.endsWith(".jpeg");
  const fileTypes = getFileTypes();

  return (
    document?.fileType === fileTypes[FileTypeIndexes.Image].value &&
    isAvailableToPreview
  );
};

export const isAllowPDFPreview = (document) => {
  const fileTypes = getFileTypes();

  return (
    document?.fileType === fileTypes[FileTypeIndexes.Pdf].value ||
    (document?.fileType === fileTypes[FileTypeIndexes.DWG].value &&
      document?.previewFileId)
  );
};

export const changeStatus = (func, delay) => {
  func(true);
  setTimeout(() => {
    func(false);
  }, delay);
};

// current_user_role needs to be deprecated
// Because it is used in many places, I just fixed temporarily
export const getCurrentUserRole = (project, userId) => {
  const { collaboratorRoles, collaboratorRoleDefinitions } = project;
  const currentCollaborator = collaboratorRoles.find(({ collaboratorId }) => collaboratorId === userId);
  const currentCollaboratorRoleDefinition = collaboratorRoleDefinitions.find(({ id }) => id === currentCollaborator.roleDefinitionId);
  return currentCollaboratorRoleDefinition.name === 'Admin' ? 0 : 1;
};

export const getChangedIds = (entry) => {
  let ids = [];

  if (entry.addedIds?.length) ids = entry.addedIds;
  else if (entry.updatedIds?.length) ids = entry.updatedIds;
  else if (entry.deleteIds?.length) ids = entry.deleteIds;
  return ids;
};

export const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    var r = parseInt(result[1], 16);
    var g = parseInt(result[2], 16);
    var b = parseInt(result[3], 16);
    return r + ',' + g + ',' + b; //return 23,14,45 -> reformat if needed
  }
  return null;
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const uploadFiles = (
  file,
  token,
  url,
  setProgress = null,
  setApiError = null
) => {
  return new Promise((resolve, reject) => {
    const data = new FormData();
    data.append('file', file);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.setRequestHeader('Authorization', 'Bearer ' + sessionStorage.getItem('userTokenBearer'));
    xhr.setRequestHeader('X-Project-Authorization', 'Bearer ' + sessionStorage.getItem('projectTokenBearer'));
    xhr.setRequestHeader('Visoplan-Client-Id', 'Visoplan Webclient');
    xhr.setRequestHeader('Visoplan-Webclient-Request-Source', 'Legacy (utils.js/uploadFiles)');

    xhr.upload.onprogress = (event) => {
      if (setProgress) {
        setProgress(((event.loaded / event.total) * 100).toFixed(0));
      }
    };

    xhr.onreadystatechange = async () => {
      if (xhr.status !== 200 && xhr.readyState !== 4) {
        const error = JSON.parse(xhr.response);
        return (
          reject({
            [file.path]: i18n.t(
              'error_during_upload_file',
              'An error occurred during file upload, try again'
            ),
          }),
          setApiError && setApiError((prevState) => [...prevState, error])
        );
      }
      if (xhr.status === 200 && xhr.readyState === 4) {
        return resolve(JSON.parse(xhr.response));
      }
    };
    xhr.send(data);
  });
};

export const getNameLinkMapParams = (content, users) => {
  const param = {
    value: {},
  };
  users.forEach((user) => {
    if (content?.includes(`@[${user.firstName} ${user.lastName}]`)) {
      param.value[`${user.id}`] = `${user.firstName} ${user.lastName}`;
    }
  });
  if (isEmpty(param.value)) return null;
  return param;
};

export const getTextFromHtml = (htmlContent) => {
  return htmlContent
    .replace(/<[^>]*>/g, '')
    .replaceAll('&nbsp;', '')
    .trim();
};

export const howManyVisibleTags = (id) => {
  const doc = document.getElementById(id);
  let count = 0;
  if (doc?.offsetWidth < 160) {
    count = 1;
  } else if (160 <= doc?.offsetWidth && doc?.offsetWidth < 250) {
    count = 2;
  } else if (250 <= doc?.offsetWidth && doc?.offsetWidth < 330) {
    count = 3;
  } else if (330 <= doc?.offsetWidth && doc?.offsetWidth < 400) {
    count = 4;
  } else if (400 <= doc?.offsetWidth && doc?.offsetWidth < 480) {
    count = 5;
  } else if (480 <= doc?.offsetWidth && doc?.offsetWidth < 550) {
    count = 6;
  } else {
    count = 7;
  }
  return count;
};

const escape = (s) => {
  const lookup = {
    '<': "&lt;",
    '>': "&gt;"
  };

  return s.replace(
      /[<>]/g,
      c => lookup[c]
  );
}

export const convertVersionNumber2Letter = (num) => {
  if (num < 0) return '';
  if (num === 0) return '__';

  const firstNum = num / 26 - 1;
  const secNum = (num % 26) - 1;
  if (firstNum > 25) return '##';
  else {
    const firstLetter = firstNum >= 0 ? String.fromCharCode(65 + firstNum) : ''; //'A' ascii code: 41H (65)
    const secLetter = String.fromCharCode(65 + secNum);
    return `${firstLetter}${secLetter}`;
  }
};

export const getConvertedVersionNumberFromLetter = (letter) => {
  if (!letter) return null;
  if (letter == '__') return 0;
  if (letter === '##') return 677;
  if (letter.includes('#') || letter.includes('_')) return -1;

  const firstNum = letter[1] === undefined ? -1 : letter.charCodeAt(0) - 65;
  const secNum =
    letter[1] === undefined
      ? letter.charCodeAt(0) - 65
      : letter.charCodeAt(1) - 65;
  return (firstNum + 1) * 26 + (secNum + 1);
};

export const getReducedStatusOptions = (statusOptions, isRemoveAll = false) => {
  const options = isRemoveAll ? statusOptions.slice(1) : statusOptions;
  //remove Other and Archived statuses
  return options.filter(
    (item) => item.originalName !== 'Other' && item.originalName !== 'Archived'
  );
};

export const getFormattedColumnInfo = (data, offsetWidth) => {
  let numberOfUnvisibleData = 0;
  let formattedStr = (data || [])
    .map((d) => typeof d === 'object' ? d.name || `${d.firstName} ${d.lastName}` : d)
    .join(", ");
  const availableWidth = offsetWidth - 40; //padding left and right -> 20px
  const oneLetterWidth = 5.5;
  if (formattedStr.length * oneLetterWidth > availableWidth) {
    const availableLength = Math.floor(
      (availableWidth - oneLetterWidth * 3 - 15) / oneLetterWidth
    );
    formattedStr = formattedStr.substr(0, availableLength) + '...';
    numberOfUnvisibleData = data.length - formattedStr.split(', ').length;
  }
  return {
    formattedStr,
    numberOfUnvisibleData,
  };
};

export const getChildrenFoldersData = (results, folders) => {
  folders.forEach((folder) => {
    results.push(folder);
    if (folder?.folderChildren?.length) {
      getChildrenFoldersData(results, folder.folderChildren);
    }
  });
};

export const getPeriodByTimeFrame = (timeframe) => {
  let period = {
    from: '',
    to: ''
  }

  switch (timeframe) {
    case Timeframe_Categories.today:
      {
        period = {
          from: moment().toDate(),
          to: moment().toDate()
        };
        break;
      }
    case Timeframe_Categories.yesterday:
      {
        period = {
          from: moment().subtract(1, 'days').toDate(),
          to: moment().subtract(1, 'days').toDate()
        };
        break;
      }
    case Timeframe_Categories.this_week:
      {
        period = {
          from: moment().startOf('week').toDate(),
          to: moment().endOf('week').toDate()
        }
        break;
      }
    case Timeframe_Categories.last_week:
      {
        period = {
          from: moment().subtract(1, 'weeks').startOf('week').toDate(),
          to: moment().subtract(1, 'weeks').endOf('week').toDate()
        }
        break;
      }
    case Timeframe_Categories.this_month:
      {
        period = {
          from: moment().startOf('month').toDate(),
          to: moment().endOf('month').toDate()
        }
        break;
      }
    case Timeframe_Categories.last_month:
      {
        period = {
          from: moment().subtract(1, 'months').startOf('month').toDate(),
          to: moment().subtract(1, 'months').endOf('month').toDate()
        }
        break;
      }
    default:
      break;
  }

  return period;
}

/**
 * In the documents folder panel, differently with folders, 'All Documents', 'My Uploads' and 'Archive' are named with category.
 * When clicking those categories, the route is changed and folder_id route param becomes 'all', 'my-uploads', 'archive'
 * @param {*} folder_id route param 
 * @returns boolean
 */
export const isFolderIdSpecialViewCategoryId = (folder_id) => {
  if (!folder_id) return false;
  return folder_id === 'all' || folder_id === 'my-uploads' || folder_id === 'archive';
}

export const mergeObjectArrayInSameKey = (arrayParam) => {
  const output = [];
  let temp = {};

  arrayParam.forEach((item) => {
    const tempKey = Object.keys(temp)[0];
    const itemKey = Object.keys(item)[0];
    
    if (tempKey === itemKey) {
      temp = { [tempKey]: [...Object.values(temp)[0], ...Object.values(item)[0]] };
    }
    else {
      if (!isEmpty(temp)) {
        output.push(temp);
        temp = {};
      }
      temp = { [itemKey]: Object.values(item)[0] };
    }
  });

  if (!isEmpty(temp))
    output.push(temp);

  return output;
}
