import React, { useEffect } from 'react';
import { Box, useTheme } from '@mui/material';
import PageWrapper from 'common/components/PageWrapper';
import SettingsPageHeader from 'settings/components/SettingsPageHeader';
import SettingsTabs from 'settings/components/SettingsTabs';
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import useVisibleSettingsTabs from 'settings/hooks/useVisibleSettingsTabs';
import useCurrentSettingsTab from 'settings/types/useCurrentSettingsTab';
import useCurrentProjectId from 'projects/hooks/useCurrentProjectId';

export default function SettingsPage() {
  const theme = useTheme();
  const projectId = useCurrentProjectId();
  const outletContext = useOutletContext(); // we need to pass on the outlet context for the legacy settings panes
  const visibleTabs = useVisibleSettingsTabs();
  const { currentSettingsTab } = useCurrentSettingsTab();
  const navigate = useNavigate();
  useEffect(() => {
    if (!visibleTabs || !currentSettingsTab) return;
    if (!visibleTabs.has(currentSettingsTab)) {
      navigate(`/projects/${projectId}/dashboard`, { replace: true });
    }
  }, [currentSettingsTab, navigate, projectId, visibleTabs]);

  return (
    <PageWrapper>
      <Box sx={{
        display: 'grid',
        gridTemplateRows: '60px 1fr',
        height: '100%',
      }}
      >
        <SettingsPageHeader />
        <Box sx={{ display: 'flex', overflow: 'hidden', mt: 3, backgroundColor: theme.palette.background.default }}>
          <SettingsTabs sx={{ flex: '0 0 auto', width: 292 }} />
          <Box sx={{ flex: '1 1 auto', overflow: 'auto', boxShadow: 3, display: 'grid' }} className="project-settings">
            <Outlet context={outletContext} />
          </Box>
        </Box>
      </Box>
    </PageWrapper>
  );
}
