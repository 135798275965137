import { useCallback } from 'react';
import PasswordValidationResponseData from 'users/types/PasswordValidationResponseData';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';

export default function usePasswordApi() {
  const axiosInstance = useAxiosInstance();

  const changePassword = useCallback(async (oldPassword: string, newPassword: string) => {
    try {
      const response = await axiosInstance.put(ApiEndpoint.AuthUserPassword, { oldPassword, newPassword });
      return response.status === 200;
    } catch (error) {
      return false;
    }
  }, [axiosInstance]);

  const validatePassword = useCallback(async (password: string) => {
    const { data } = await axiosInstance.post<PasswordValidationResponseData>(ApiEndpoint.AuthUserValidatePassword, JSON.stringify(password));
    return data;
  }, [axiosInstance]);

  return { changePassword, validatePassword };
}
