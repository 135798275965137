import { createContext } from 'react';

export interface DocumentSelectionContextState {
  selectedDocumentVersionIds: string[],
  selectedDocumentVersionIdsSet: Set<string>,
  setSelectedDocumentVersionIds: (ids: string[]) => void,
  nonDeselectableVersionIds: Set<string> | undefined,
  nonSelectableVersionIds: Set<string> | undefined,
  resetSelection: () => void,
}

const defaultValue: DocumentSelectionContextState = {
  selectedDocumentVersionIds: [],
  selectedDocumentVersionIdsSet: new Set<string>(),
  setSelectedDocumentVersionIds: () => {},
  nonDeselectableVersionIds: new Set<string>(),
  nonSelectableVersionIds: new Set<string>(),
  resetSelection: () => {},
};

const DocumentSelectionContext = createContext(defaultValue);

export default DocumentSelectionContext;
