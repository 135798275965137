import { combineReducers } from 'redux';

import projects from 'projects/Reducer';
import sidebar from 'Components/Sidebar/Reducer';
import uploadIssue from 'Components/Upload/UploadIssue/Reducer';
import project from 'Containers/ProjectContainer/Reducer';

export default combineReducers({
  projects,
  sidebar,
  uploadIssue,
  project,
});
