import { useMutation } from '@tanstack/react-query';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDocumentVersionUpdateMutationFn from 'documents/hooks/useDocumentVersionUpdateMutationFn';
import DocumentVersionDto from 'documents/types/DocumentVersionDto';
import DocumentVersionDisciplinesUpdateDto from 'documents/types/DocumentVersionDisciplinesUpdateDto';

export default function useDocumentVersionDisciplinesUpdateMutation() {
  const mutationFn = useDocumentVersionUpdateMutationFn<DocumentVersionDisciplinesUpdateDto>(ApiEndpoint.DocumentVersionDisciplines);
  return useMutation<DocumentVersionDto, unknown, DocumentVersionDisciplinesUpdateDto>({ mutationFn });
}
