import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Box, debounce, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import IssuesSortButtonMenu from 'issues/components/IssuesSortButtonMenu';
import { useTranslation } from 'react-i18next';
import useIssueQueryFiltersOdataQuery from 'query-filters/hooks/useIssueQueryFiltersOdataQuery';
import useIssuesFilterContext from 'issues/hooks/useIssuesFilterContext';
import useLanguageCode from 'common/hooks/useLanguageCode';

interface IssueQuickFilterBarProps extends ISxProps {
}

export default function IssueQuickFilterBar({
  sx,
}: IssueQuickFilterBarProps) {
  const { t } = useTranslation('issues');

  const { setQueryFilterId, queryFilterId, queryFilterState } = useIssuesFilterContext();
  const { setQuickFilterString, quickFilterString } = queryFilterState;
  const languageCode = useLanguageCode();
  const [quickFilterStringDisplayValue, setQuickFilterDisplayValue] = useState<string>(quickFilterString);
  const { data: queryFilters } = useIssueQueryFiltersOdataQuery({});

  const debouncedsSetTitleFilter = useMemo(() => debounce((value: string) => {
    setQuickFilterString(value);
  }, 600), [setQuickFilterString]);

  const onChangeQuickFilter = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setQuickFilterDisplayValue(event.target.value);
    debouncedsSetTitleFilter(event.target.value);
  }, [debouncedsSetTitleFilter]);

  const onChangeIssuesFilterSelect = useCallback((event: SelectChangeEvent<string>) => {
    setQueryFilterId(event.target.value || undefined);
  }, [setQueryFilterId]);

  return (
    <Box id="IssueQuickFilterBar" sx={{ ...sx, display: 'flex', gap: 1, zIndex: 0 }}>
      <TextField
        label={t('issue-quick-filter-bar_quick-filter-textfield-label', 'Quick Filter')}
        value={quickFilterStringDisplayValue}
        onChange={onChangeQuickFilter}
        sx={{ flex: '1 1 0', minWidth: 100 }}
      />
      <FormControl sx={{ flex: '1 1 0', minWidth: 100, maxWidth: 180 }}>
        <InputLabel id="issue-quick-filter-bar_view-select-label">
          {t('issue-quick-filter-bar_view-select-label', 'Saved View')}
        </InputLabel>
        {!!queryFilters && (
          <Select
            id="issue-quick-filter-bar_view-select"
            value={queryFilterId ?? 'all'}
            onChange={onChangeIssuesFilterSelect}
            label={t('issue-quick-filter-bar_view-select-label', 'Saved View')}
          >
            {queryFilters && <MenuItem value="all">{t('issue-quick-filter-bar_all-issues', 'All Issues')}</MenuItem>}
            {queryFilters.map((queryFilter) => <MenuItem key={queryFilter.id} value={queryFilter.id}>{queryFilter.name[languageCode]}</MenuItem>)}
          </Select>
        )}
      </FormControl>
      <IssuesSortButtonMenu sx={{ flex: '0 0 0' }} />
    </Box>
  );
}
