import React, { useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useDocumentViewerContext from 'documents-details/hooks/useDocumentViewerContext';
import InternalAnnotationItem from 'documents-details/components/InternalAnnotationItem';
import { Core } from '@pdftron/webviewer';

interface InternalAnnotationsTabProps extends ISxProps {
  hideDate?: boolean,
}

export default function InternalAnnotationsTab({
  sx,
  hideDate,
}: InternalAnnotationsTabProps) {
  const { annotationItems } = useDocumentViewerContext();
  const internalAnnotations = useMemo(
    () => annotationItems
      ?.filter((item) => !item.visoplanAnnotation && item.viewerAnnotation.Listable)
      .map((item) => ({
        viewerAnnotation: item.viewerAnnotation,
        originalTimestampModified: new Date(item.viewerAnnotation.getCustomData('originalDateModified')).getTime(),
      }))
      .sort((a, b) => a.originalTimestampModified - b.originalTimestampModified)
      .map((item) => item.viewerAnnotation),
    [annotationItems],
  );
  const { setSelectedAnnotationNames, selectedAnnotationNames } = useDocumentViewerContext();
  const onClickAnnotationItem = useCallback((annotation: Core.Annotations.Annotation) => {
    setSelectedAnnotationNames([annotation.Id]);
  }, [setSelectedAnnotationNames]);
  const selectedNamesSet = useMemo(() => (selectedAnnotationNames ? new Set(selectedAnnotationNames) : undefined), [selectedAnnotationNames]);
  return (
    <Box id="InternalAnnotationsTab" sx={{ ...sx, display: 'flex', flexDirection: 'column', overflow: 'auto', pt: 1 }}>
      <Box sx={{ flex: '1 1 0', display: 'flex', flexDirection: 'column', overflow: 'auto', p: 2, gap: 2 }}>
        {!!internalAnnotations && internalAnnotations.map((internalAnnotation) => (
          <InternalAnnotationItem key={internalAnnotation.Id} annotation={internalAnnotation} onClick={onClickAnnotationItem} selected={selectedNamesSet?.has(internalAnnotation.Id)} hideDate={hideDate} />
        ))}
      </Box>
    </Box>
  );
}
