import React from 'react';
import { useTranslation } from 'react-i18next';
import IssueResponsibilityActionPanel from 'issues/components/IssueResponsibilityActionPanel';
import useClosedIssueStatus from 'issues/hooks/useClosedIssueStatus';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';

interface ApproveReviewPanelProps {
  issueId: string,
  onClose: () => void,
}

export default function ApproveReviewPanel({
  issueId,
  onClose,
}: ApproveReviewPanelProps) {
  const { t } = useTranslation('issues');
  const closedIssueStatus = useClosedIssueStatus();
  if (!closedIssueStatus) return <CenteredCircularProgress />;
  return (
    <IssueResponsibilityActionPanel
      id="ApproveReviewPanel"
      issueId={issueId}
      title={t('approve-review-panel_header', 'Review: Complete')}
      onClose={onClose}
      confirmButtonLabel={t('approve-review-panel_confirm-button-label', 'Complete')}
      commentDefaultText={t('approve-review-panel_comment-default-text', 'Entry reviewed and completed.')}
      targetStatusId={closedIssueStatus.id}
      commentMandatory
    />
  );
}
