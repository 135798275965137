import { Box, GlobalStyles, Typography } from '@mui/material';
import useDocumentVersionsQuery from 'documents/hooks/useDocumentVersionsQuery';
import useEmailsOdataQuery from 'emails/hooks/useEmailsOdataQuery';
import IssueCommentItem from 'issues/components/IssueCommentItem';
import PrintPreviewFooter from 'issues/components/PrintPreviewFooter';
import PrintPreviewHeader from 'issues/components/PrintPreviewHeader';
import IssuePrintContext from 'issues/contexts/IssuePrintContext';
import useIssueCommentsQuery from 'issues/hooks/useIssueCommentsQuery';
import useProjectIssuePrioritiesQuery from 'issues/hooks/useProjectIssuePrioritiesQuery';
import useProjectIssueStatusesQuery from 'issues/hooks/useProjectIssueStatusesQuery';
import useProjectIssueTypesQuery from 'issues/hooks/useProjectIssueTypesQuery';
import IssueDto from 'issues/types/IssueDto';
import useLabelsOdataQuery from 'labels/hooks/useLabelsOdataQuery';
import { ITEM_ROOT } from 'odata-query';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CollaboratorDto from 'collaborators/types/CollaboratorDto';
import theme from 'theme';
import LabelChip from 'labels/components/LabelChip';
import IconChip from 'labels/components/IconChip';
import { mdiAccountCheck, mdiAccountHardHat, mdiCalendar, mdiEmail, mdiFileDocument } from '@mdi/js';
import Icon from '@mdi/react';
import useProjectBuildingsQuery from 'labels/hooks/useProjectBuildingsQuery';
import useProjectDisciplinesQuery from 'labels/hooks/useProjectDisciplinesQuery';
import useProjectFloorsQuery from 'labels/hooks/useProjectFloorsQuery';
import FileType from 'documents/types/FileType';
import useResourcesQuery from 'resources/hooks/useResourcesQuery';
import _ from 'lodash';
import usePdfAnnotationsQuery from 'documents-annotations/hooks/usePdfAnnotationsQuery';
import useViewpointsQuery from 'issues/hooks/useViewpointsQuery';
import IssuePrintViewColumns from 'issues/types/IssuePrintViewColumns';

interface IssueDetailsPrintProps {
  issue: IssueDto,
  pageNumber: number,
  onFinishedLoading: () => void,
}

export default function IssueDetailsPrint({ issue, pageNumber, onFinishedLoading }: IssueDetailsPrintProps) {
  const { t } = useTranslation('issues');
  const { columnsIncludedInPrint } = useContext(IssuePrintContext);
  const { data: comments, isLoading: commentsIsLoading } = useIssueCommentsQuery(issue.commentIds);
  const { data: statuses, isLoading: statusesIsLoading } = useProjectIssueStatusesQuery();
  const { data: types, isLoading: typesIsLoading } = useProjectIssueTypesQuery();
  const { data: priorities, isLoading: prioritiesIsLoading } = useProjectIssuePrioritiesQuery();
  const { data: disciplines, isLoading: disciplinesIsLoading } = useProjectDisciplinesQuery();
  const { data: buildings, isLoading: buildingsIsLoading } = useProjectBuildingsQuery();
  const { data: floors, isLoading: floorsIsLoading } = useProjectFloorsQuery();

  const pdfAnnotationsOdataQuery = useMemo(() => ({ filter: { linkedIssueId: { [ITEM_ROOT]: { eq: issue.id } } } }), [issue.id]);
  const { data: annotations, isLoading: annotationsIsLoading } = usePdfAnnotationsQuery(pdfAnnotationsOdataQuery);
  const annotationThumbnailIds = useMemo(() => annotations?.map((annotation) => annotation.thumbnailImageId || '') || [], [annotations]);
  const validAnnotationThumbnailIds = useMemo(() => annotationThumbnailIds.filter((id) => id !== ''), [annotationThumbnailIds]);
  const { data: annotationImages, isLoading: annotationImagesIsLoading } = useResourcesQuery(validAnnotationThumbnailIds);

  const { data: viewpoints } = useViewpointsQuery({ filter: { id: { in: issue.viewpointIds } } });
  const viewpointThumbnailIds = useMemo(() => viewpoints?.map((viewpoint) => viewpoint.thumbnailImageId || '') || [], [viewpoints]);
  const validViewpointThumbnailIds = useMemo(() => viewpointThumbnailIds.filter((id) => id !== ''), [viewpointThumbnailIds]);
  const { data: viewpointImages, isLoading: viewpointImagesIsLoading } = useResourcesQuery(validViewpointThumbnailIds);

  const { data: documents, isLoading: documentsIsLoading } = useDocumentVersionsQuery({ filter: { id: { [ITEM_ROOT]: { in: issue.linkedDocumentVersionIds } } } });
  const docsWithImages = useMemo(() => documents?.filter((doc) => doc.fileType === FileType.Image), [documents]);
  const docsWithoutImages = useMemo(() => documents?.filter((doc) => doc.fileType !== FileType.Image), [documents]);

  const { data: images, isLoading: imagesIsLoading } = useResourcesQuery(docsWithImages?.map((doc) => doc.fileId) || []);

  const { data: emails, isLoading: emailsIsLoading } = useEmailsOdataQuery({ filter: { id: { [ITEM_ROOT]: { in: issue.linkedEmailIds } } } });
  const { data: tags, isLoading: tagsIsLoading } = useLabelsOdataQuery({ filter: { id: { in: issue.tags } } });

  const status = useMemo(() => statuses?.find((s) => s.id === issue.issueStatus), [statuses, issue.issueStatus]);
  const type = useMemo(() => types?.find((issueType) => issueType.id === issue.issueType), [types, issue.issueType]);
  const priority = useMemo(() => priorities?.find((p) => p.id === issue.issuePriority), [priorities, issue.issuePriority]);
  const foundDisciplines = useMemo(() => issue.disciplines.map((disciplineId) => disciplines?.find((d) => d.id === disciplineId)).filter((d) => d !== undefined), [disciplines, issue.disciplines]);
  const foundBuildings = useMemo(() => issue.buildings.map((buildingId) => buildings?.find((b) => b.id === buildingId)).filter((b) => b !== undefined), [buildings, issue.buildings]);
  const foundFloors = useMemo(() => issue.floors.map((floorId) => floors?.find((f) => f.id === floorId)).filter((f) => f !== undefined), [floors, issue.floors]);

  const orderedComments = useMemo(() => {
    if (!comments) return undefined;
    const items = comments.map((comment) => ({ comment, creationDateTimestamp: new Date(comment.creationDate).getTime() }));
    const sortedComments = items.sort((a, b) => b.creationDateTimestamp - a.creationDateTimestamp).map(({ comment }) => comment);
    return sortedComments;
  }, [comments]);

  const formatedDate = useCallback((date: string | undefined) => {
    if (!date) return '';
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString('de-DE');
  }, []);

  const formatedName = useCallback((user: CollaboratorDto) => `${user.firstName} ${user.lastName}`, []);

  // always only put 2 images in one array. This makes the layout more stable and page breaks should work better
  const formatedImages = useMemo(() => _.chunk(images, 2), [images]);

  // always only put 2 annotationImages in one array. This makes the layout more stable and page breaks should work better
  const formatedAnnotationImages = useMemo(() => _.chunk(annotationImages, 2), [annotationImages]);

  // always only put 2 viewpointImages in one array. This makes the layout more stable and page breaks should work better
  const formatedViewpointImages = useMemo(() => _.chunk(viewpointImages, 2), [viewpointImages]);

  useEffect(() => {
    if (!annotationImagesIsLoading && !viewpointImagesIsLoading && !commentsIsLoading && !statusesIsLoading && !typesIsLoading && !prioritiesIsLoading && !disciplinesIsLoading && !buildingsIsLoading && !floorsIsLoading && !documentsIsLoading && !imagesIsLoading && !emailsIsLoading && !tagsIsLoading) {
      onFinishedLoading();
    }
  }, [buildingsIsLoading, commentsIsLoading, disciplinesIsLoading, documentsIsLoading, emailsIsLoading, floorsIsLoading, imagesIsLoading, annotationsIsLoading, onFinishedLoading, prioritiesIsLoading, statusesIsLoading, tagsIsLoading, typesIsLoading, annotationImagesIsLoading, issue.issueNumber, annotationThumbnailIds, annotationImages, viewpointImagesIsLoading]);

  return (
    <>
      <GlobalStyles styles={{
        '.dontBreak': {
          pageBreakInside: 'avoid',
          breakInside: 'avoid',
        },
        '.pageBreakAfter': {
          pageBreakAfter: 'always',
          breakAfter: 'page',
        },
      }}
      />
      <Box id="IssueDetailsPrint" sx={{ width: '210mm', minHeight: '297mm', p: 2 }}>
        <PrintPreviewHeader />
        <Box className="dontBreak" sx={{ border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2, mt: 2, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {columnsIncludedInPrint.has(IssuePrintViewColumns.IssueNumber) && (
            <Box sx={{ mr: 1, borderRadius: '4px', backgroundColor: theme.palette.grey[700], color: theme.palette.background.default, fontWeight: 600, fontSize: 20, p: '2px', px: '4px', textAlign: 'center' }}>
              {issue.issueNumber}
            </Box>
            )}
            {columnsIncludedInPrint.has(IssuePrintViewColumns.Title) && <Typography variant="h4">{issue.title}</Typography>}
          </Box>
          {columnsIncludedInPrint.has(IssuePrintViewColumns.Description) && <Typography sx={{ pt: 2 }} variant="body1">{issue.description}</Typography>}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {(columnsIncludedInPrint.has(IssuePrintViewColumns.Created) || columnsIncludedInPrint.has(IssuePrintViewColumns.Creator)) && (
            <Typography sx={{ py: 1, fontWeight: theme.typography.fontWeightLight }} variant="body2">{t('issue-details-print_creation-label-created', 'Created')}</Typography>
            )}
            {columnsIncludedInPrint.has(IssuePrintViewColumns.Created) && (
            <Typography sx={{ py: 1, fontWeight: theme.typography.fontWeightLight }} variant="body2">
              &nbsp;
              {t('issue-details-print_creation-date', `at ${formatedDate(issue.creationDate)}`)}
            </Typography>
            )}
            {columnsIncludedInPrint.has(IssuePrintViewColumns.Creator) && issue.createAuthor && (
            <Typography sx={{ py: 1, fontWeight: theme.typography.fontWeightLight }} variant="body2">
              &nbsp;
              {t('issue-details-print_create-author', `by ${issue.createAuthor.firstName} ${issue.createAuthor.lastName}`)}
            </Typography>
            )}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
            {columnsIncludedInPrint.has(IssuePrintViewColumns.Status) && <LabelChip label={status} />}
            {columnsIncludedInPrint.has(IssuePrintViewColumns.Type) && <LabelChip label={type} />}
            {columnsIncludedInPrint.has(IssuePrintViewColumns.Priority) && <LabelChip label={priority} />}
            {columnsIncludedInPrint.has(IssuePrintViewColumns.Tags) && tags?.map((tag) => <LabelChip key={tag.id} sx={{ mr: 1 }} label={tag} />)}
          </Box>
        </Box>
        {(columnsIncludedInPrint.has(IssuePrintViewColumns.Start) || columnsIncludedInPrint.has(IssuePrintViewColumns.Due) || columnsIncludedInPrint.has(IssuePrintViewColumns.Updated)) && (
        <Box className="dontBreak" sx={{ display: 'flex', justifyContent: 'space-between', border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2, mt: 1, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
          {columnsIncludedInPrint.has(IssuePrintViewColumns.Start) && !!issue.startingDate && (
          <Box>
            <Typography sx={{ mb: 0.5 }} variant="h5">{t('issue-details-print_starting-date-label', 'Start')}</Typography>
            <IconChip text={formatedDate(issue.startingDate)} color={theme.palette.success.main} mdiIconPath={mdiCalendar} />
          </Box>
          )}
          {columnsIncludedInPrint.has(IssuePrintViewColumns.Due) && !!issue.dueDate && (
          <Box>
            <Typography sx={{ mb: 0.5 }} variant="h5">{t('issue-details-print_due-date-label', 'Due')}</Typography>
            <IconChip text={formatedDate(issue.dueDate)} color={theme.palette.error.main} mdiIconPath={mdiCalendar} />
          </Box>
          )}
          {columnsIncludedInPrint.has(IssuePrintViewColumns.Updated) && !!issue.editDate && (
            <Box>
              <Typography sx={{ mb: 0.5 }} variant="h5">{t('issue-details-print_last-edit-date-label', 'Last edit')}</Typography>
              <IconChip text={formatedDate(issue.editDate)} color={theme.palette.info.main} mdiIconPath={mdiCalendar} />
            </Box>
          )}
        </Box>
        )}
        <Box className="dontBreak" sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          {columnsIncludedInPrint.has(IssuePrintViewColumns.Disciplines) && !!foundDisciplines.length && (
          <Box sx={{ width: '31%', height: 'min-content', border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2, mt: 2 }}>
            <Typography variant="h5">{t('issue-details-print_disciplines-label', 'Disciplines')}</Typography>
            {foundDisciplines.map((discipline) => (
              <Box key={discipline.id} sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1, p: 1, backgroundColor: theme.palette.grey[200], borderRadius: 1 }}>
                <>
                  <Typography variant="body1">{discipline.abbreviation}</Typography>
                  <LabelChip
                    sx={{
                      height: 'auto',
                      '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }}
                    label={discipline}
                  />
                </>
              </Box>
            ))}
          </Box>
          )}
          {columnsIncludedInPrint.has(IssuePrintViewColumns.Buildings) && !!foundBuildings.length && (
          <Box sx={{ width: '31%', height: 'min-content', border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2, mt: 2 }}>
            <Typography variant="h5">{t('issue-details-print_buildings-label', 'Buildings')}</Typography>
            {foundBuildings.map((building) => (
              <Box key={building.id} sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1, p: 1, backgroundColor: theme.palette.grey[200], borderRadius: 1 }}>
                <>
                  <Typography variant="body1">{building.abbreviation}</Typography>
                  <LabelChip
                    sx={{
                      height: 'auto',
                      '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }}
                    label={building}
                  />
                </>
              </Box>
            ))}
          </Box>
          )}
          {columnsIncludedInPrint.has(IssuePrintViewColumns.Floors) && !!foundFloors.length && (
          <Box sx={{ width: '31%', height: 'min-content', border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2, mt: 2 }}>
            <Typography variant="h5">{t('issue-details-print_floors-label', 'Floors')}</Typography>
            {foundFloors.map((floor) => (
              <Box key={floor.id} sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1, p: 1, backgroundColor: theme.palette.grey[200], borderRadius: 1 }}>
                <>
                  <Typography variant="body1">{floor.abbreviation}</Typography>
                  <LabelChip
                    sx={{
                      height: 'auto',
                      '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }}
                    label={floor}
                  />
                </>
              </Box>
            ))}
          </Box>
          )}
        </Box>
        <Box className="dontBreak" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          {columnsIncludedInPrint.has(IssuePrintViewColumns.Assignee) && !!issue.assignedUsers?.length && (
          <Box sx={{ width: '48%', border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2, mt: 2 }}>
            <Typography variant="h5">{t('issue-details-print_assignees-label', 'Assignees')}</Typography>
            {issue.assignedUsers.map((user) => (
              <Box key={user.id} sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1, p: 1, backgroundColor: theme.palette.grey[200], borderRadius: 1 }}>
                <Icon size={1} path={mdiAccountHardHat} />
                <Typography variant="body1">{formatedName(user)}</Typography>
              </Box>
            ))}
          </Box>
          )}
          {columnsIncludedInPrint.has(IssuePrintViewColumns.Reviewer) && !!issue.reviewer && (
          <Box sx={{ width: '48%', border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2, mt: 2, height: 'min-content' }}>
            <Typography variant="h5">{t('issue-details-print_reviewer-label', 'Reviewer')}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1, p: 1, backgroundColor: theme.palette.grey[200], borderRadius: 1 }}>
              <Icon size={1} path={mdiAccountCheck} />
              <Typography variant="body1">{formatedName(issue.reviewer)}</Typography>
            </Box>
          </Box>
          )}
        </Box>
        {columnsIncludedInPrint.has(IssuePrintViewColumns.Documents) && !!docsWithoutImages?.length && (
        <Box className="dontBreak" sx={{ border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2, mt: 2 }}>
          <Typography variant="h5">{t('issue-details-print_documents-label', 'Documents')}</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
            {docsWithoutImages.map((doc) => (
              <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: theme.palette.grey[200], borderRadius: 1, p: 1 }}>
                <Icon size={1} path={mdiFileDocument} />
                <Typography sx={{ ml: 1 }} key={doc.id} variant="body1">{doc.name}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
        )}
        {columnsIncludedInPrint.has(IssuePrintViewColumns.Emails) && !!emails?.length && (
        <Box className="dontBreak" sx={{ border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2, mt: 2 }}>
          <Typography variant="h5">{t('issue-details-print_emails-label', 'Emails')}</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
            {emails.map((email) => (
              <Box key={email.id} sx={{ display: 'flex', alignItems: 'center', p: 1, backgroundColor: theme.palette.grey[200], borderRadius: 1 }}>
                <Icon size={1} path={mdiEmail} />
                <Typography sx={{ ml: 1 }} variant="body1">{email.subject}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
        )}
        {columnsIncludedInPrint.has(IssuePrintViewColumns.Comments) && !!orderedComments?.length && (
          <Box className="dontBreak" sx={{ border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2, my: 2 }}>
            <Typography variant="h5">{t('issue-details-print_comments-label', 'Comments')}</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
              {orderedComments.map((comment) => (
                <IssueCommentItem key={comment.id} comment={comment} />
              ))}
            </Box>
          </Box>
        )}
        {/** always only put 2 images in one row then create a new one. This way page breaks should work better */}
        {columnsIncludedInPrint.has(IssuePrintViewColumns.Images) && !!images?.length && (
        <Box className="dontBreak" sx={{ border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2, mt: 2 }}>
          <Typography variant="h5">{t('issue-details-print-preview_issue-images', 'Images')}</Typography>
          {formatedImages.map((row) => (
            <Box className="dontBreak" sx={{ display: 'flex', gap: 1, mt: 1 }} key={row[0]}>
              {row.map((image) => (
                <img src={image} alt="issue" width="50%" height="450px" style={{ padding: 1, borderRadius: '10px', objectFit: 'contain', border: `solid 1px ${theme.palette.grey[400]}` }} key={image} />
              ))}
            </Box>
          ))}
        </Box>
        )}
        {/** always only put 2 annotationImages in one row then create a new one. This way page breaks should work better */}
        {columnsIncludedInPrint.has(IssuePrintViewColumns.Locations) && !!annotationImages?.length && (
        <Box className="dontBreak" sx={{ border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2, mt: 2 }}>
          <Typography variant="h5">{t('issue-details-print-preview_issue-locations', 'Locations')}</Typography>
          {formatedAnnotationImages.map((row) => (
            <Box className="dontBreak" sx={{ display: 'flex', gap: 1, mt: 1 }} key={row[0]}>
              {row.map((image) => (
                <img src={image} alt="issue" width="50%" height="450px" style={{ padding: 1, borderRadius: '10px', objectFit: 'contain', border: `solid 1px ${theme.palette.grey[400]}` }} key={image} />
              ))}
            </Box>
          ))}
        </Box>
        )}
        {/** always only put 2 viewpointImages in one row then create a new one. This way page breaks should work better */}
        {columnsIncludedInPrint.has(IssuePrintViewColumns.Viewpoints) && !!viewpointImages?.length && (
        <Box className="dontBreak" sx={{ border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2, mt: 2 }}>
          <Typography variant="h5">{t('issue-details-print-preview_issue-viewpoints', 'Viewpoints')}</Typography>
          {formatedViewpointImages.map((row) => (
            <Box className="dontBreak" sx={{ display: 'flex', gap: 1, mt: 1 }} key={row[0]}>
              {row.map((image) => (
                <img src={image} alt="issue" width="50%" height="450px" style={{ padding: 1, borderRadius: '10px', objectFit: 'contain', border: `solid 1px ${theme.palette.grey[400]}` }} key={image} />
              ))}
            </Box>
          ))}
        </Box>
        )}
        <Box className="pageBreakAfter">
          <PrintPreviewFooter pageNumber={pageNumber} />
        </Box>
      </Box>
    </>
  );
}
