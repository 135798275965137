import React, {
  ReactNode, useMemo, useState,
} from 'react';
import DocumentStatisticsContext, { DocumentStatisticsContextState } from 'documents/contexts/DocumentStatisticsContext';
import RowRange from 'documents/types/RowRange';

interface DocumentStatisticsContextProviderProps {
  children?: ReactNode,
}

export default function DocumentStatisticsContextProvider({
  children,
}: DocumentStatisticsContextProviderProps) {
  const [displayedRowRange, setDisplayedRowRange] = useState<RowRange | undefined>(undefined);
  const [currentDocumentVersionIds, setCurrentDocumentVersionIds] = useState<string[] | undefined>(undefined);

  const state = useMemo<DocumentStatisticsContextState>(() => ({
    displayedRowRange,
    setDisplayedRowRange,
    currentDocumentVersionIds,
    setCurrentDocumentVersionIds,
  }), [displayedRowRange, currentDocumentVersionIds]);

  return (
    <DocumentStatisticsContext.Provider value={state}>
      {children}
    </DocumentStatisticsContext.Provider>
  );
}
