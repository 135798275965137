import React, {
  ReactNode, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import VisoplanApiContext, { VisoplanApiContextState } from 'api/contexts/VisoplanApiContext';
import axios, { AxiosInstance } from 'axios';
import { useTranslation } from 'react-i18next';
import useApiConfig from 'api/hooks/useApiConfig';
import { GraphQLClient } from 'graphql-request';
import CredentialsDto from 'users/types/CredentialsDto';
import useAsyncState from 'common/hooks/useAsyncState';
import AuthUserTokenDto from 'users/types/AuthUserTokenDto';
import AuthProjectTokenDto from 'users/types/AuthProjectTokenDto';
import { useNavigate } from 'react-router-dom';
import IChildren from 'common/types/IChildren';
import useVisoplanApiQueryDefaults from 'api/hooks/useVisoplanApiQueryDefaults';
import TwoFactorCredentialsDto from 'users/types/TwoFactorCredentialsDto';
import ApiEndpoint from 'api/types/ApiEndpoint';

const AUTH_USER_TOKEN_LOCAL_STORAGE_KEY = 'auth-user-token-bearer';
const LAST_OPENED_PROJECT_LOCAL_STORAGE_KEY = 'last-opened-project-id';

interface RequestHeaders {
  [index: string] : string,
}

type AuthParams = {
  bearer?: undefined,
  credentials?: undefined,
  twoFactorCredentials?: undefined,
  oneTimePassword?: undefined,
  projectId?: undefined,
} | {
  bearer: string,
  credentials?: undefined,
  twoFactorCredentials?: undefined,
  oneTimePassword?: undefined,
  projectId?: undefined,
} | {
  bearer?: undefined,
  credentials: CredentialsDto,
  twoFactorCredentials?: undefined,
  oneTimePassword?: undefined,
  projectId?: undefined,
} | {
  bearer?: undefined,
  credentials?: undefined,
  twoFactorCredentials: TwoFactorCredentialsDto,
  oneTimePassword?: undefined,
  projectId?: undefined,
} | {
  bearer?: undefined,
  credentials?: undefined,
  twoFactorCredentials?: undefined,
  oneTimePassword: string,
  projectId?: undefined,
} | {
  bearer?: undefined,
  credentials?: undefined,
  twoFactorCredentials?: undefined,
  oneTimePassword?: undefined,
  projectId: string,
};

interface VisoplanApiProviderProps {
  children: ReactNode,
}

export enum SignInResult {
  Authenticated = 'authenticated',
  TwoFactorAuthenticationRequired = 'two-factor-authentication-required',
}

export const INCORRECT_ONE_TIME_PASSWORD_ERROR = 'incorrect-one-time-password-error';

const defaultHeaders = {
  'Content-Type': 'application/json',
  'Visoplan-Client-Id': 'Visoplan Webclient',
  'Visoplan-Webclient-Request-Source': 'VisoplanApiProvider',
};

function VisoplanApiContextDefaultsProvider({ children }: IChildren) {
  // this hook sets default query functions in the tanstack query client.
  // we need a separate nested component for this in order for the query functions to have access to the VisoplanApiContext
  useVisoplanApiQueryDefaults();
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export default function VisoplanApiProvider({
  children,
}: VisoplanApiProviderProps) {
  const { apiUrl, graphQlUrl } = useApiConfig();
  const navigate = useNavigate();

  const [graphQlClient] = useState<GraphQLClient>(new GraphQLClient(`${graphQlUrl}/`, {
    headers: defaultHeaders,
  }));

  const { i18n } = useTranslation();
  const [headers, setHeaders] = useAsyncState<RequestHeaders>(defaultHeaders);
  useEffect(() => {
    setHeaders((prev) => ({ ...prev, 'Content-Language': i18n.language }));
  }, [i18n.language, setHeaders]);

  const [openedProjectId, setOpenedProjectIdAsync] = useAsyncState<string | null | undefined>(null); // undefined=loading; null=no open project
  const [authProjectToken, setAuthProjectTokenAsync] = useAsyncState<AuthProjectTokenDto | undefined>(undefined);
  const clearProjectToken = useCallback(async () => {
    await setAuthProjectTokenAsync(undefined);
    localStorage.removeItem(LAST_OPENED_PROJECT_LOCAL_STORAGE_KEY);
    await setHeaders((prev) => {
      const next = { ...prev };
      if (next['X-Project-Authorization'] !== undefined) delete next['X-Project-Authorization'];
      if (next['X-Project'] !== undefined) delete next['X-Project'];
      return next;
    });
    await setOpenedProjectIdAsync(null);
  }, [setAuthProjectTokenAsync, setHeaders, setOpenedProjectIdAsync]);

  const onAxiosError = useCallback(async (error: any) => {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      const isFailedLoginAttempt = error.config?.method === 'post' && error.config.url === '/auth-user';
      if (!isFailedLoginAttempt) {
        await clearProjectToken();
        navigate('/projects');
      }
    }
    throw error;
  }, [clearProjectToken, navigate]);

  const [axiosInstance] = useState<AxiosInstance>(() => {
    const instance = axios.create({
      baseURL: apiUrl,
      timeout: 10000,
      headers: defaultHeaders,
    });
    instance.interceptors.response.use(undefined, onAxiosError);
    return instance;
  });

  useEffect(() => {
    axiosInstance.defaults.headers.common = headers;
    graphQlClient.setHeaders(headers);
  }, [axiosInstance, graphQlClient, headers]);

  const [isSignedIn, setIsSignedInAsync] = useAsyncState<boolean | undefined>(undefined);
  const [authUserToken, setAuthUserToken] = useState<AuthUserTokenDto | undefined>(undefined);

  const updateToken = useCallback(async ({ credentials, twoFactorCredentials, oneTimePassword, projectId, bearer }: AuthParams) => {
    // (re-)open project
    if (projectId) {
      await setOpenedProjectIdAsync((prev) => (prev === projectId ? prev : undefined));
      try {
        const { data: projectToken } = await axiosInstance.get<AuthProjectTokenDto>(`/auth-project/open/${projectId}`);
        localStorage.setItem(LAST_OPENED_PROJECT_LOCAL_STORAGE_KEY, projectId);
        await setAuthProjectTokenAsync(projectToken);
        await setHeaders((prev) => ({
          ...prev,
          'X-Project-Authorization': `bearer ${projectToken.bearer}`,
          'X-Project': projectId,
        }));
        await setOpenedProjectIdAsync(projectId);
        return true;
      } catch (error) {
        await clearProjectToken();
        throw error;
      }
    }

    // sign in / refresh user token
    let nextUserToken: AuthUserTokenDto | undefined;
    if (credentials) {
      const { data: userToken } = await axiosInstance.post<AuthUserTokenDto>('/auth-user', credentials);
      nextUserToken = userToken;
    } else if (twoFactorCredentials) {
      const { data: userToken } = await axiosInstance.post<AuthUserTokenDto>('/auth-user/totp', twoFactorCredentials);
      nextUserToken = userToken;
    } else if (oneTimePassword) {
      const { data: userToken } = await axiosInstance.patch<AuthUserTokenDto>(ApiEndpoint.Totp, `"${oneTimePassword}"`);
      nextUserToken = userToken;
    } else {
      const options = bearer ? { headers: { ...axiosInstance.defaults.headers.common, Authorization: `bearer ${bearer}` } } : undefined;
      const { data: userToken } = await axiosInstance.get<AuthUserTokenDto>('/auth-user', options);
      nextUserToken = userToken;
    }
    if (!nextUserToken) throw new Error('No user token received.');
    if (nextUserToken.isAuthenticated) {
      setAuthUserToken(nextUserToken);
      localStorage.setItem(AUTH_USER_TOKEN_LOCAL_STORAGE_KEY, nextUserToken.bearer);
      const Authorization = `bearer ${nextUserToken.bearer}`;
      await setHeaders((prev) => ({ ...prev, Authorization }));
      await setIsSignedInAsync(true);
      return true;
    }
    return false;
  }, [axiosInstance, clearProjectToken, setAuthProjectTokenAsync, setHeaders, setIsSignedInAsync, setOpenedProjectIdAsync]);

  const signInUsingCredentials = useCallback(async (credentials: CredentialsDto) => {
    const authenticated = await updateToken({ credentials });
    return authenticated ? SignInResult.Authenticated : SignInResult.TwoFactorAuthenticationRequired;
  }, [updateToken]);

  const signInUsingTwoFactorCredentials = useCallback(async (twoFactorCredentials: TwoFactorCredentialsDto) => {
    const authenticated = await updateToken({ twoFactorCredentials });
    if (!authenticated) {
      throw new Error(INCORRECT_ONE_TIME_PASSWORD_ERROR);
    }
  }, [updateToken]);

  const signInUsingTokenBearer = useCallback(async (bearer: string) => {
    await updateToken({ bearer });
  }, [updateToken]);

  const activateTwoFactorAuth = useCallback(async (oneTimePassword: string) => {
    await updateToken({ oneTimePassword });
  }, [updateToken]);

  const openProject = useCallback(async (projectId: string) => {
    await updateToken({ projectId });
  }, [updateToken]);

  const signOut = useCallback(async () => {
    await setIsSignedInAsync(false);
    await axiosInstance.delete('/auth-user/signout');
    localStorage.removeItem(AUTH_USER_TOKEN_LOCAL_STORAGE_KEY);
    await setHeaders((prev) => {
      if (!prev.Authorization) return prev;
      const next = { ...prev };
      delete next.Authorization;
      return next;
    });
    await clearProjectToken();
  }, [axiosInstance, clearProjectToken, setHeaders, setIsSignedInAsync]);

  const leaveProject = useCallback(async () => {
    if (!openedProjectId) return;
    await axiosInstance.delete('/auth-project/leave');
    await clearProjectToken();
  }, [axiosInstance, clearProjectToken, openedProjectId]);

  const refreshToken = useCallback(async (projectId: string | undefined) => {
    await updateToken(projectId ? { projectId } : {});
  }, [updateToken]);

  const tryAutoSignIn = useCallback(async () => {
    if (authUserToken && new Date(authUserToken.expires) > new Date()) return true;

    // if not already signed in, try auto-sign-in using a token from local storage
    const storedTokenBearer = localStorage.getItem(AUTH_USER_TOKEN_LOCAL_STORAGE_KEY);
    if (!storedTokenBearer) return false;
    try {
      await signInUsingTokenBearer(storedTokenBearer);
      return true;
    } catch (error) {
      localStorage.removeItem(AUTH_USER_TOKEN_LOCAL_STORAGE_KEY);
      return false;
    }
  }, [authUserToken, signInUsingTokenBearer]);

  // In dev env and StrictMode, react executes all hooks twice. They say "just ignore one of the requests", but
  // that's not possible in this case because the token changes. So we have to work around this using a ref
  const autoSignInReactStrictModeDeduplication = useRef(false);
  useEffect(() => {
    if (!autoSignInReactStrictModeDeduplication.current) {
      autoSignInReactStrictModeDeduplication.current = true;
      tryAutoSignIn().then(setIsSignedInAsync); // auto sign-in on init
    }
  }, [tryAutoSignIn, setIsSignedInAsync]);

  // workaround for legacy API request code (see Helpers/Request/index.js)
  useEffect(() => {
    if (authProjectToken && new Date(authProjectToken.expires) > new Date()) {
      sessionStorage.setItem('projectTokenBearer', authProjectToken.bearer);
    } else {
      sessionStorage.setItem('projectTokenBearer', '');
    }
  }, [authProjectToken]);
  useEffect(() => {
    if (authUserToken && new Date(authUserToken.expires) > new Date()) {
      sessionStorage.setItem('userTokenBearer', authUserToken.bearer);
    } else {
      sessionStorage.setItem('userTokenBearer', '');
    }
  }, [authUserToken]);

  const state = useMemo<VisoplanApiContextState>(() => ({
    axiosInstance,
    graphQlClient,
    authProjectToken,
    authUserToken,
    signInUsingCredentials,
    signInUsingTwoFactorCredentials,
    signInUsingTokenBearer,
    isSignedIn,
    openedProjectId,
    signOut,
    openProject,
    leaveProject,
    clearProjectToken,
    activateTwoFactorAuth,
    refreshToken,
  }), [axiosInstance, graphQlClient, authProjectToken, authUserToken, signInUsingCredentials, signInUsingTwoFactorCredentials, signInUsingTokenBearer, isSignedIn, openedProjectId, signOut, openProject, leaveProject, clearProjectToken, activateTwoFactorAuth, refreshToken]);

  return (
    <VisoplanApiContext.Provider value={state}>
      <VisoplanApiContextDefaultsProvider>
        {children}
      </VisoplanApiContextDefaultsProvider>
    </VisoplanApiContext.Provider>
  );
}
