import type { Theme, ComponentsProps, ComponentsVariants } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

interface MuiInputBaseComponent {
  defaultProps?: ComponentsProps['MuiInputBase'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiInputBase'];
  variants?: ComponentsVariants['MuiInputBase'];
}

const VisoInputBase: MuiInputBaseComponent = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontSize: theme.typography.fontSize,
      fontFamily: 'Open Sans, sans-serif ',
      ':not(.MuiInputBase-multiline)': {
        height: '36px !important',
      },
    }),
    inputSizeSmall: ({ theme }) => ({
      fontSize: theme.typography.fontSize - 1,
      paddingBottom: 2,
      paddingTop: 3,
    }),
  },
};

export default VisoInputBase;
