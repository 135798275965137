import FolderAccessType from 'documents-folders/types/FolderAccessType';
import { useMemo } from 'react';

export default function useFolderIconName(accessType: FolderAccessType | undefined) {
  return useMemo(() => {
    if (!Number.isFinite(accessType)) return undefined;
    if (accessType === FolderAccessType.Private) return 'folder-private';
    if (accessType === FolderAccessType.Restricted) return 'folder-restricted';
    return 'folder';
  }, [accessType]);
}
