import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import usePasswordApi from 'users/hooks/usePasswordApi';
import PasswordValidationPanel from 'users/components/PasswordValidationPanel';

interface ChangePasswordDialogProps {
  onClose: () => void,
}

export default function ChangePasswordDialog({
  onClose,
}: ChangePasswordDialogProps) {
  const { t } = useTranslation('users');
  const getRequestErrorMessage = useRequestErrorMessage();
  const { changePassword } = usePasswordApi();
  const [isLoadingPasswordMutation, setIsLoadingPasswordMutation] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const [successFeedbackDialogOpen, setSuccessFeedbackDialogOpen] = useState(false);
  const onCloseSuccessFeedbackDialog = useCallback(() => onClose(), [onClose]);

  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordRepetition, setNewPasswordRepetition] = useState<string>('');
  const passwordModified = useMemo(() => Boolean(newPassword.length || newPasswordRepetition.length), [newPassword.length, newPasswordRepetition.length]);
  const [currentPasswordError, setCurrentPasswordError] = useState<string | undefined>();
  const [newPasswordRepetitionError, setNewPasswordRepetitionError] = useState<string | undefined>(undefined);
  const [newPasswordValid, setNewPasswordValid] = useState<boolean | undefined>(undefined);
  const onChangeNewPasswordValid = useCallback((valid: boolean | undefined) => setNewPasswordValid(valid), []);
  const newPasswordHasError = useMemo(() => !!newPassword.length && newPasswordValid === false, [newPassword.length, newPasswordValid]);

  const confirmDisabled = useMemo(() => Boolean(
    isLoadingPasswordMutation
    || !currentPassword.length
    || !newPassword.length
    || !newPasswordRepetition.length
    || !newPasswordValid
    || !!newPasswordRepetitionError,
  ), [isLoadingPasswordMutation, currentPassword.length, newPassword.length, newPasswordRepetition.length, newPasswordValid, newPasswordRepetitionError]);

  const onChangeCurrentPassword = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setCurrentPassword(event.target.value);
    setCurrentPasswordError(undefined);
  }, []);

  const onChangeNewPassword = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
  }, []);

  const onChangeNewPasswordRepetition = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setNewPasswordRepetition(event.target.value);
    setNewPasswordRepetitionError(undefined);
  }, []);

  const onClickConfirm = useCallback(async () => {
    if (!passwordModified) return;
    setIsLoadingPasswordMutation(true);
    try {
      if (newPassword !== newPasswordRepetition) {
        setNewPasswordRepetitionError(t('account-settings-dialog_repetition-missmatch-error-message', 'The new password and the repetition must be equal.'));
        setIsLoadingPasswordMutation(false);
        return;
      }
      const success = await changePassword(currentPassword, newPassword);
      if (!success) {
        setCurrentPasswordError(t('account-settings-dialog_current-password-wrong-error-message', 'Does not match your current password.'));
        setIsLoadingPasswordMutation(false);
        return;
      }
      setSuccessFeedbackDialogOpen(true);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    } finally {
      setIsLoadingPasswordMutation(false);
    }
  }, [changePassword, currentPassword, getRequestErrorMessage, newPassword, newPasswordRepetition, passwordModified, t]);

  return (
    <Dialog open id="ChangePasswordDialog" PaperProps={{ sx: { maxWidth: '1000px' } }}>
      <DialogTitle>{t('change-password-dialog_title', 'Change Password')}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', gap: 4, pt: 1 }}>
          <PasswordValidationPanel newPassword={newPassword} onChangeNewPasswordValid={onChangeNewPasswordValid} sx={{ flexShrink: 0, width: '500px' }} />
          <Box sx={{ flexShrink: 0, width: '300px', display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              label={t('account-settings-dialog_current-password-label', 'Current password')}
              value={currentPassword ?? ''}
              onChange={onChangeCurrentPassword}
              disabled={isLoadingPasswordMutation}
              error={!!currentPasswordError}
              helperText={currentPasswordError}
              type="password"
              name="password"
              autoComplete="current-password"
            />
            <TextField
              label={t('account-settings-dialog_new-password-label', 'New password')}
              value={newPassword ?? ''}
              onChange={onChangeNewPassword}
              disabled={isLoadingPasswordMutation}
              error={newPasswordHasError}
              type="password"
              name="new-password"
              autoComplete="new-password"
            />
            <TextField
              label={t('account-settings-dialog_repeat-new-password-label', 'Repeat new password')}
              value={newPasswordRepetition ?? ''}
              onChange={onChangeNewPasswordRepetition}
              disabled={isLoadingPasswordMutation}
              error={!!newPasswordRepetitionError}
              helperText={newPasswordRepetitionError}
              type="password"
            />
          </Box>
        </Box>
        {!!errorMessage && <Alert severity="error" sx={{ mt: 2 }} onClose={onCloseErrorMessage}>{errorMessage}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button id="ChangePasswordDialogCloseButton" variant="contained" color="secondary" onClick={onClose} sx={{ mr: 'auto' }}>
          {t('change-password-dialog_cancel-button-label', 'Cancel')}
        </Button>
        <Button id="ChangePasswordDialogConfirmButton" variant="contained" color="primary" onClick={onClickConfirm} disabled={confirmDisabled}>
          {t('change-password-dialog_confirm-button-label', 'Confirm')}
        </Button>
        {!!successFeedbackDialogOpen && (
          <Dialog open>
            <DialogTitle>{t('change-password-dialog_success-feedback-dialog-title', 'Password Changed')}</DialogTitle>
            <DialogContent>
              <Alert severity="success">{t('change-password-dialog_success-feedback-dialog-title', 'The password for your user account has been changed.')}</Alert>
            </DialogContent>
            <DialogActions>
              <Button id="ChangePasswordDialogSuccessFeedbackDialogCloseButton" variant="contained" color="primary" onClick={onCloseSuccessFeedbackDialog}>{t('change-password-dialog_success-feedback-dialog-close-button-label', 'Close')}</Button>
            </DialogActions>
          </Dialog>
        )}
      </DialogActions>
    </Dialog>
  );
}
