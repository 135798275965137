import React, { useMemo, useState } from 'react';
import IChildren from 'common/types/IChildren';
import CollaboratorsFilterContext, { CollaboratorsFilterContextState } from 'collaborators/contexts/CollaboratorsFilterContext';

export default function CollaboratorsFilterContextProvider({
  children,
}: IChildren) {
  const [quickFilterString, setQuickFilterString] = useState<string>('');
  const [filteredCollaboratorIds, setFilteredCollaboratorIds] = useState<string[] | undefined>(undefined);
  const value = useMemo<CollaboratorsFilterContextState>(() => ({
    quickFilterString,
    setQuickFilterString,
    filteredCollaboratorIds,
    setFilteredCollaboratorIds,
  }), [filteredCollaboratorIds, quickFilterString]);
  return <CollaboratorsFilterContext.Provider value={value}>{children}</CollaboratorsFilterContext.Provider>;
}
