import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState, KeyboardEvent } from 'react';
import { Alert, Box, Button, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useLabelCreateMutation from 'labels/hooks/useLabelCreateMutation';
import CreateLabelDto from 'labels/types/CreateLabelDto';
import LabelType from 'labels/types/LabelType';
import useLabelsOdataQuery from 'labels/hooks/useLabelsOdataQuery';
import IssueSettingsIconPicker from 'settings/components/IssueSettingsIconPicker';
import IssueSettingsColorPicker from 'settings/components/IssueSettingsColorPicker';
import useLabelIcons from 'settings/hooks/usLabelIcons';
import useLabelTypeProperties from 'settings/hooks/useLabelTypeProperties';
import LabelDto from 'labels/types/LabelDto';
import useLabelTypeTranslation from 'labels/hooks/useLabelTypeTranslation';

export enum MutationMode {
  Create,
  Edit,
}

type CreateLabelPanelProps = {
  labelType: LabelType,
  onClose: () => void,
  onCreate: (label: LabelDto) => void,
};

export default function CreateLabelPanel({
  labelType,
  onClose,
  onCreate,
}: CreateLabelPanelProps) {
  const { t } = useTranslation('settings');

  const icons = useLabelIcons();

  const { data: labels } = useLabelsOdataQuery({ filter: { type: LabelType[labelType], isDeleted: false } });

  const { mutateAsync: createAsync, isPending: isLoadingCreate } = useLabelCreateMutation();

  const getRequestErrorMessage = useRequestErrorMessage();
  const abbreviationTextFieldRef = useRef<HTMLInputElement>(null);
  const nameTextFieldRef = useRef<HTMLInputElement>(null);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string | undefined>(undefined);
  const onChangeName = useCallback((e: ChangeEvent<HTMLInputElement>) => setName(e.target.value), []);
  const [abbreviation, setAbbreviation] = useState<string | undefined>(undefined);
  const onChangeAbbreviation = useCallback((e: ChangeEvent<HTMLInputElement>) => setAbbreviation(e.target.value), []);
  const [icon, setIcon] = useState<string | undefined>(undefined);
  const [color, setColor] = useState<string | undefined>(undefined);
  const { hasColor, hasIcon, hasAbbreviation } = useLabelTypeProperties(labelType);
  const labelExists = useMemo(() => {
    if (!name) return false;
    return labels?.map((l) => l.name).includes(name.trim());
  }, [labels, name]);
  const abbreviationExists = useMemo(() => {
    if (!abbreviation) return false;
    return labels?.map((l) => l.abbreviation).includes(abbreviation.trim());
  }, [abbreviation, labels]);
  const addButtonDisabled = useMemo(() => {
    if (isLoadingCreate) return true;
    if (!name?.trim().length) return true;
    if (!abbreviation?.trim().length && hasAbbreviation) return true;
    if (!icon?.trim().length && hasIcon) return true;
    if (!color?.trim().length && hasColor) return true;
    return false;
  }, [isLoadingCreate, name, abbreviation, hasAbbreviation, icon, hasIcon, color, hasColor]);

  const randomColor = useCallback(() => {
    const c = Math.floor(Math.random() * 0xFFFFFF).toString(16);
    return `#${c.padStart(6, '0')}`;
  }, []);

  const randomIcon = useCallback(() => {
    const allIcons = Array.from(icons.defaultIcons.keys()).concat(Array.from(icons.otherIcons.keys()));
    const randomIndex = Math.floor(Math.random() * allIcons.length);
    return allIcons[randomIndex];
  }, [icons.defaultIcons, icons.otherIcons]);

  const resetForm = useCallback(() => {
    setName(undefined);
    setAbbreviation(undefined);
    setColor(randomColor);
    setIcon(randomIcon);
    nameTextFieldRef.current?.focus();
  }, [randomColor, randomIcon]);

  const onClickConfirm = useCallback(async () => {
    if (!name || (!abbreviation && hasAbbreviation)) return;
    if (labelType === LabelType.IssueType && (!icon || !color)) return;
    setErrorMessage(undefined);
    try {
      if (labelExists) throw new Error(t('create-label-panel_name-exists-error', 'Name already exists'));
      if (abbreviationExists) throw new Error(t('create-label-panel_abbreviation-exists-error', 'Abbreviation already exists'));
      const createDto: CreateLabelDto = {
        name: name.trim(),
        abbreviation: abbreviation?.trim(),
        type: labelType,
        color: hasColor ? color : undefined,
        icon: hasIcon ? icon : undefined,
      };
      const labelDto = await createAsync(createDto);
      resetForm();
      onCreate(labelDto);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [name, abbreviation, hasAbbreviation, labelType, icon, color, labelExists, t, abbreviationExists, hasColor, hasIcon, createAsync, resetForm, onCreate, getRequestErrorMessage]);

  // init values
  useEffect(() => {
    setName(undefined);
    setAbbreviation(undefined);
    setColor(randomColor);
    setIcon(randomIcon);
    nameTextFieldRef.current?.focus();
  }, [randomColor, randomIcon]);

  const onNameTextFieldKeyDown = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key.toLowerCase() !== 'enter') return;
    if (hasAbbreviation) {
      abbreviationTextFieldRef.current?.focus();
      return;
    }
    if (!addButtonDisabled) {
      onClickConfirm();
    }
  }, [addButtonDisabled, hasAbbreviation, onClickConfirm]);

  const onAbbreviationTextFieldKeyDown = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key.toLowerCase() === 'enter' && !addButtonDisabled) {
      onClickConfirm();
    }
  }, [addButtonDisabled, onClickConfirm]);

  const getLabelTypeTranslation = useLabelTypeTranslation();
  const labelTypeName = useMemo(() => getLabelTypeTranslation(labelType), [getLabelTypeTranslation, labelType]);

  return (
    <Box id="CreateLabelPanel" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="h5">{t('create-label-panel_title', 'Create New {{labelTypeName}}', { labelTypeName })}</Typography>
      <TextField
        id="CreateLabelPanelNameTextField"
        value={name ?? ''}
        onChange={onChangeName}
        onKeyDown={onNameTextFieldKeyDown}
        label={t('create-label-panel_name-textfield-label', 'Name')}
        inputRef={nameTextFieldRef}
      />
      {hasAbbreviation && (
      <TextField
        id="CreateLabelPanelAbbreviationTextField"
        value={abbreviation ?? ''}
        onChange={onChangeAbbreviation}
        onKeyDown={onAbbreviationTextFieldKeyDown}
        label={t('create-label-panel_abbreviation-textfield-label', 'Abbreviation')}
        inputRef={abbreviationTextFieldRef}
      />
      )}
      <Box sx={{ display: 'flex', gap: 1 }}>
        {hasIcon && (
          <IssueSettingsIconPicker icon={icon} onChange={setIcon} />
        )}
        {hasColor && (
          <IssueSettingsColorPicker color={color} onChange={setColor} />
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button id="CreateLabelPanelCancelButton" variant="contained" color="secondary" onClick={onClose}>
          {t('create-label-panel_close-button-label', 'Cancel')}
        </Button>
        <Button id="CreateLabelPanelConfirmButton" variant="contained" color="primary" onClick={onClickConfirm} disabled={addButtonDisabled}>
          {t('create-label-panel_create-button-label', 'Confirm')}
        </Button>
      </Box>
      {!!errorMessage && (
        <Alert severity="error" onClose={() => setErrorMessage(undefined)}>{errorMessage}</Alert>
      )}
    </Box>
  );
}
