import { useMemo } from "react";

const useResourceIds = ({
  users,
  allDisciplines,
  floors,
  buildings,
  allDocuments,
  tagOptions,
}) => {
  // Resources by Id
  const arrayToObjectById = (array, labelKey, valueKey) => Object.fromEntries((array ?? []).map((item) => [item[valueKey], item[labelKey]]));
  const usersById = useMemo(
    () => arrayToObjectById(users, "label", "value"),
    [users]
  );
  const disciplinesById = useMemo(
    () => arrayToObjectById(allDisciplines, "name", "id"),
    [allDisciplines]
  );
  const floorsById = useMemo(
    () => arrayToObjectById(floors, "name", "id"),
    [floors]
  );
  const buildingsById = useMemo(
    () => arrayToObjectById(buildings, "name", "id"),
    [buildings]
  );
  const documentsById = useMemo(
    () => arrayToObjectById(allDocuments, "name", "id"),
    [allDocuments]
  );
  const tagsById = useMemo(
    () => arrayToObjectById(tagOptions, "label", "value"),
    [tagOptions]
  );

  return {
    usersById,
    disciplinesById,
    buildingsById,
    floorsById,
    documentsById,
    tagsById,
  };
};

export default useResourceIds;
