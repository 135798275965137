import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Checkbox, Menu, MenuList, MenuItem, useTheme } from '@mui/material';
import ListOptionsIcon from 'icons/ListOptionsIcon';
import ISxProps from 'common/types/ISxProps';
import useDocumentsDynamicLayout from 'dynamic-layout/hooks/useDocumentsDynamicLayout';
import useColumnDisplayNamesByFieldName from 'documents/hooks/useColumnDisplayNamesByFieldName';
import DocumentVersionDto from 'documents/types/DocumentVersionDto';

interface ColumnOptionsMenuButtonProps extends ISxProps {
}

const fieldNames = [
  'name',
  'versionNumber',
  'fileType',
  'status',
  'uploadDate',
  'disciplines',
  'building',
  'floor',
  'workPhase',
  'linkedIssueIds',
  'editDate',
  'creator',
  'editor',
  'commentIds',
  'creationDate',
  'fileSize',
  'tags',
  'folderId',
];

export default function ColumnOptionsButtonMenu({
  sx,
}: ColumnOptionsMenuButtonProps) {
  const theme = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const btnRef = useRef<HTMLButtonElement>(null);
  const { columnWidthsByColumnName, columnNamesForcedHidden, columnNamesForcedVisible, persistAsync, isLoadingMutation, isLoadingQuery } = useDocumentsDynamicLayout();
  const isLoading = useMemo(() => isLoadingMutation || isLoadingQuery, [isLoadingMutation, isLoadingQuery]);
  const [visibleColumnFieldNames, setVisibleColumnFieldNames] = useState<string[] | undefined>(columnWidthsByColumnName ? fieldNames.filter((fieldName) => columnWidthsByColumnName.has(fieldName)) : undefined);
  const selectionSet = useMemo<Set<string>>(() => new Set<string>(visibleColumnFieldNames), [visibleColumnFieldNames]);

  const toggleColumn = useCallback(async (fieldNameToToggle: string) => {
    if (!columnWidthsByColumnName || !visibleColumnFieldNames) return;
    const updatedVisibleColumnFieldNames = columnWidthsByColumnName.has(fieldNameToToggle) ? visibleColumnFieldNames.filter((f) => f !== fieldNameToToggle) : [...visibleColumnFieldNames, fieldNameToToggle];
    const updatedColumns = updatedVisibleColumnFieldNames.map((pivotFieldName) => ({ fieldName: pivotFieldName, width: columnWidthsByColumnName.get(pivotFieldName) ?? 150 }));
    setVisibleColumnFieldNames(updatedVisibleColumnFieldNames);
    await persistAsync(updatedColumns);
  }, [columnWidthsByColumnName, persistAsync, visibleColumnFieldNames]);

  useEffect(() => {
    setVisibleColumnFieldNames(columnWidthsByColumnName ? Array.from(columnWidthsByColumnName.keys()) : undefined);
  }, [columnWidthsByColumnName]);

  const headerNamesByColumnNames = useColumnDisplayNamesByFieldName();

  return (
    <>
      <Button onClick={() => setIsMenuOpen(true)} sx={{ ...sx, color: theme.palette.text.primary, minWidth: 'unset' }} ref={btnRef} id="ColumnOptionsMenuButton">
        <ListOptionsIcon />
      </Button>
      <Menu
        anchorEl={btnRef.current}
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      >
        <MenuList
          dense
        >
          {fieldNames.map((fieldName) => (
            <MenuItem key={fieldName} onClick={() => toggleColumn(fieldName)} disabled={isLoading || columnNamesForcedHidden?.has(fieldName) || columnNamesForcedVisible?.has(fieldName)}>
              <Checkbox checked={(selectionSet.has(fieldName) && !columnNamesForcedHidden?.has(fieldName)) || columnNamesForcedVisible?.has(fieldName)} />
              {headerNamesByColumnNames[fieldName as keyof DocumentVersionDto]}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
}
