import React, {
  useCallback,
  useContext,
  useRef, useState,
} from 'react';
import {
  Button, Menu, MenuItem, useTheme, Radio,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ISxProps from 'common/types/ISxProps';
import SortIcon from '@mui/icons-material/Sort';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ShareRecipientsSortMode from 'share/types/ShareRecipientsSortMode';
import ShareRecipientsFilterContext, { ShareRecipientsFilterContextState } from 'share/contexts/ShareRecipientsFilterContext';

interface ShareRecipientsSortButtonMenuProps extends ISxProps {
}

export default function ShareRecipientsSortButtonMenu({
  sx,
}: ShareRecipientsSortButtonMenuProps) {
  const { t } = useTranslation('share');
  const theme = useTheme();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { sortMode, setSortMode } = useContext<ShareRecipientsFilterContextState>(ShareRecipientsFilterContext);

  const onClickNameDescMode = useCallback(() => {
    setSortMode(ShareRecipientsSortMode.NameDesc);
  }, [setSortMode]);

  const onClickNameAscMode = useCallback(() => {
    setSortMode(ShareRecipientsSortMode.NameAsc);
  }, [setSortMode]);

  const onCloseMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const onOpenMenu = useCallback(() => {
    setIsMenuOpen(true);
  }, []);

  return (
    <>
      <Button
        id="ShareRecipientsSortMenuButton"
        onClick={onOpenMenu}
        sx={{
          ...sx, color: theme.palette.text.primary, minWidth: 'unset', px: 1,
        }}
        ref={buttonRef}
        variant="outlined"
      >
        <SortIcon sx={{ mr: '-2px', color: theme.palette.text.secondary }} />
      </Button>
      <Menu
        anchorEl={buttonRef.current}
        open={isMenuOpen}
        onClose={onCloseMenu}
      >
        <MenuItem onClick={onClickNameDescMode}>
          <Radio checked={sortMode === ShareRecipientsSortMode.NameDesc} sx={{ ml: 0 }} />
          {t('share-recipients-sort-button-menu_sort-select-name', 'Name')}
          <ArrowDownwardIcon fontSize="small" sx={{ ml: 1 }} />
        </MenuItem>
        <MenuItem onClick={onClickNameAscMode}>
          <Radio checked={sortMode === ShareRecipientsSortMode.NameAsc} sx={{ ml: 0 }} />
          {t('share-recipients-sort-button-menu_sort-select-name', 'Name')}
          <ArrowUpwardIcon fontSize="small" sx={{ ml: 1 }} />
        </MenuItem>
      </Menu>
    </>
  );
}
