import React, { useCallback, useMemo, useState } from 'react';
import { Alert, Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useCurrentUserQuery from 'users/hooks/useCurrentUserQuery';
import TwoFactorAuthSetupDialog from 'users/components/TwoFactorAuthSetupDialog';
import DeactivateTwoFactorAuthDialog from 'users/components/DeactivateTwoFactorAuthDialog';
import ChangePasswordDialog from 'users/components/ChangePasswordDialog';
import Icon from '@mdi/react';
import { mdiAccountDetails, mdiCellphoneKey, mdiKey } from '@mdi/js';
import ChangePersonalInfoDialog from 'users/components/ChangePersonalInfoDialog';

interface AccountSettingsDialogProps {
  onClose: () => void,
}
export default function AccountSettingsDialog({
  onClose,
}: AccountSettingsDialogProps) {
  const { t } = useTranslation('users');
  const { data: currentUser } = useCurrentUserQuery();
  const theme = useTheme();

  const twoFactorEnabled = useMemo(() => currentUser?.twoFactorEnabled, [currentUser?.twoFactorEnabled]);

  const [twoFactorAuthSetupDialogOpen, setTwoFactorAuthSetupDialogOpen] = useState(false);
  const onClickOpenTwoFactorAuthSetupDialogOpen = useCallback(() => setTwoFactorAuthSetupDialogOpen(true), []);
  const onCloseTwoFactorAuthSetupDialogOpen = useCallback(() => setTwoFactorAuthSetupDialogOpen(false), []);

  const onClickCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const [changePersonalDataDialogOpen, setChangePersonalDataDialogOpen] = useState(false);
  const onClickChangePersonalData = useCallback(() => setChangePersonalDataDialogOpen(true), []);
  const onCloseChangePersonalDataDialog = useCallback(() => setChangePersonalDataDialogOpen(false), []);

  const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false);
  const onClickChangePassword = useCallback(() => setChangePasswordDialogOpen(true), []);
  const onCloseChangePasswordDialog = useCallback(() => setChangePasswordDialogOpen(false), []);

  const [deactivateTwoFactorAuthDialogOpen, setDeactivateTwoFactorAuthDialogOpen] = useState(false);
  const onClickDeactivateTwoFactorAuthDialogOpen = useCallback(() => setDeactivateTwoFactorAuthDialogOpen(true), []);
  const onCloseDeactivateTwoFactorAuthDialog = useCallback(async () => {
    setDeactivateTwoFactorAuthDialogOpen(false);
  }, []);

  return (
    <Dialog id="AccountSettingsDialog" open PaperProps={{ sx: { width: '600px', maxWidth: 'unset' } }}>
      <DialogTitle>{t('account-settings-dialog_title', 'Account Settings')}</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Divider />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="h4">{t('account-settings-dialog_two-factor-auth-header', 'Personal Info')}</Typography>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Avatar sx={{ backgroundColor: theme.palette.info.main, width: 52, height: 52 }}>
              {currentUser ? `${currentUser.firstName[0]}${currentUser.lastName[0]}` : ''}
            </Avatar>
            <Box>
              <Typography>{currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : ''}</Typography>
              <Typography>{currentUser ? currentUser.email : ''}</Typography>
              <Typography>{`${t('account-settings-dialog_phone-label', 'Phone')}: ${currentUser?.phone ?? '-'}`}</Typography>
            </Box>
            <Box sx={{ ml: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 1 }}>
              <Button id="AccountSettingsDialogChangePersonalDataButton" onClick={onClickChangePersonalData} variant="outlined" sx={{ pl: 1, gap: 1 }}>
                <Icon path={mdiAccountDetails} size={1} />
                {t('account-settings-dialog_change-personal-data-button-label', 'Change Personal Data')}
              </Button>
              {!!changePersonalDataDialogOpen && <ChangePersonalInfoDialog onClose={onCloseChangePersonalDataDialog} />}
              <Button id="AccountSettingsDialogChangePasswordButton" onClick={onClickChangePassword} variant="outlined" sx={{ pl: 1, gap: 1 }}>
                <Icon path={mdiKey} size={1} />
                {t('account-settings-dialog_change-password-button-label', 'Change Password')}
              </Button>
              {!!changePasswordDialogOpen && <ChangePasswordDialog onClose={onCloseChangePasswordDialog} />}
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="h4">{t('account-settings-dialog_two-factor-auth-header', 'Two-Factor Authentication')}</Typography>
          {twoFactorEnabled === false && (
            <Alert severity="warning">
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'flex-start' }}>
                <Typography>{t('account-settings-dialog_two-factor-auth-setup-description', 'Two-factor authentication is not set up for your account.')}</Typography>
                <Button variant="contained" sx={{ flexShrink: 0, pl: 1, gap: 1 }} color="warning" onClick={onClickOpenTwoFactorAuthSetupDialogOpen}>
                  <Icon path={mdiCellphoneKey} size={1} />
                  {t('account-settings-dialog_two-factor-auth-setup-button-label', 'Set up now')}
                </Button>
              </Box>
              {twoFactorAuthSetupDialogOpen && <TwoFactorAuthSetupDialog onClose={onCloseTwoFactorAuthSetupDialogOpen} />}
            </Alert>
          )}
          {!!twoFactorEnabled && (
            <Alert severity="success">
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'flex-start' }}>
                <Typography>{t('account-settings-dialog_two-factor-auth-deactivate-description', 'Two-factor authentication is enabled for your account.')}</Typography>
                <Button variant="outlined" sx={{ flexShrink: 0 }} onClick={onClickDeactivateTwoFactorAuthDialogOpen}>
                  {t('account-settings-dialog_two-factor-auth-deactivate-button-label', 'Disable')}
                </Button>
              </Box>
              {deactivateTwoFactorAuthDialogOpen && <DeactivateTwoFactorAuthDialog onClose={onCloseDeactivateTwoFactorAuthDialog} />}
            </Alert>
          )}
        </Box>
        <Divider />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={onClickCancel}
          sx={{ mr: 'auto' }}
        >
          {t('account-settings-dialog_close-button-label', 'Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
