import { Box3, Clock, Color, Material, Mesh, Object3D, Plane, Vector2, Vector3 } from "three";
import { IfcCamera } from "./camera/camera";
import { IfcRaycaster } from "./raycaster";
import { IfcRenderer } from "./renderer/renderer";
import { IfcScene } from "./scene";
import { Animator } from "./animator";
import { IfcEvent, IfcEvents } from "./ifcEvent";
import {
  CameraProjections,
  IfcComponent,
  Items,
  NavigationModes,
  ViewerOptions,
} from "../../base-types";

export class IfcContext {
  options: ViewerOptions;
  items: Items;
  ifcCamera: IfcCamera;
  boundingBox: Box3 = new Box3();
  sectionLineColor = new Color(0xffff00);
  sectionSpaceColor = new Color(0xff0000);
  readonly scene: IfcScene;
  readonly renderer: IfcRenderer;
  readonly events: IfcEvents;
  private clippingPlanes: Plane[];
  private readonly clock: Clock;
  private readonly ifcCaster: IfcRaycaster;
  private readonly ifcAnimator: Animator;

  private _isDirty = false;

  get isDirty(): boolean {
    return this._isDirty;
  }

  set isDirty(value: boolean) {
    this._isDirty = value;
  }

  constructor(options: ViewerOptions) {
    if (!options.container) throw new Error("Could not get container element!");
    this.options = options;
    this.events = new IfcEvents();
    this.items = this.newItems();
    this.scene = new IfcScene(this);
    this.renderer = new IfcRenderer(this);

    this.ifcCamera = new IfcCamera(this);
    this.events.publish(IfcEvent.onCameraReady);

    this.clippingPlanes = [];
    this.ifcCaster = new IfcRaycaster(this);
    this.clock = new Clock(true);
    this.ifcAnimator = new Animator();
    this.setupWindowRescale();
    this.render();
  }

  getScene() {
    return this.scene.scene;
  }

  getRenderer() {
    return this.renderer.basicRenderer;
  }

  getRenderer2D() {
    return this.renderer.renderer2D;
  }

  getCamera() {
    return this.ifcCamera.activeCamera;
  }

  getIfcCamera() {
    return this.ifcCamera;
  }

  getDomElement() {
    return this.getRenderer().domElement;
  }

  getDomElement2D() {
    return this.getRenderer2D().domElement;
  }

  getContainerElement() {
    return this.options.container;
  }

  getDimensions() {
    const element = this.getContainerElement();
    return new Vector2(element.clientWidth, element.clientHeight);
  }

  getClippingPlanes() {
    return this.clippingPlanes;
  }

  getAnimator() {
    return this.ifcAnimator;
  }

  getCenter(mesh: Mesh) {
    mesh.geometry.computeBoundingBox();
    if (!mesh.geometry.index) return new Vector3();
    const indices = mesh.geometry.index.array;
    const position = mesh.geometry.attributes.position;

    const threshold = 20;
    let xCoords = 0;
    let yCoords = 0;
    let zCoords = 0;
    let counter = 0;

    for (let i = 0; i < indices.length || i < threshold; i++) {
      xCoords += position.getX(indices[i]);
      yCoords += position.getY(indices[i]);
      zCoords += position.getZ(indices[i]);
      counter++;
    }

    return new Vector3(xCoords / counter, yCoords / counter, zCoords / counter);
  }

  // eslint-disable-next-line no-undef
  addComponent(component: IfcComponent) {
    this.items.components.push(component);
  }

  addClippingPlane(plane: Plane) {
    this.clippingPlanes.push(plane);
  }

  removeClippingPlane(plane: Plane) {
    const index = this.clippingPlanes.indexOf(plane);
    this.clippingPlanes.splice(index, 1);
  }

  removeAllCiipPlanes() {
    this.clippingPlanes = [];
  }

  castRay(items: Object3D[]) {
    return this.ifcCaster.castRay(items);
  }

  castRayIfc() {
    return this.ifcCaster.castRayIfc();
  }

  fitToFrame() {
    this.ifcCamera.fitModelToFrame();
  }

  updateAspect() {
    this.ifcCamera.updateAspect();
    this.renderer.adjustRendererSize();
  }

  setNavigationMode(mode: NavigationModes) {
    this.ifcCamera.setNavigationMode(mode);
  }

  setCameraProjection(projection: CameraProjections) {
    this.ifcCamera.projection = projection;
  }

  setBackground(color: string) {
    this.scene.setBackground(color);
  }

  dispose() {
    this.ifcCamera.dispose();
  }

  calculateBoundingBox() {
    if (this.items.ifcModels.length == 0) return;
    let minx = Infinity;
    let miny = Infinity;
    let minz = Infinity;
    let maxx = -Infinity;
    let maxy = -Infinity;
    let maxz = -Infinity;

    for (let i = 0; i < this.items.ifcModels.length; i++) {
      const model = this.items.ifcModels[i];
      if (model.center && model.size) {
        const halfSize = model.size.clone().multiplyScalar(0.5);
        const min = model.center.clone().sub(halfSize);
        const max = model.center.clone().add(halfSize);

        minx = Math.min(minx, min.x);
        miny = Math.min(miny, min.y);
        minz = Math.min(minz, min.z);
        maxx = Math.max(maxx, max.x);
        maxy = Math.max(maxy, max.y);
        maxz = Math.max(maxz, max.z);
      }
    }

    this.boundingBox.set(
      new Vector3(minx, miny, minz),
      new Vector3(maxx, maxy, maxz)
    );
  }

  updateSectionColor(color: Color) {
    this.sectionSpaceColor = color;
    if (this.clippingPlanes.length) {
      this.items.pickableIfcModels.forEach((model) => {
        if (Array.isArray(model.material)) {
          model.material.forEach((mat) => this.updateMaterialSectionColor(mat, color));
        } else {
          this.updateMaterialSectionColor(model.material, color);
        }
      });
    }
  }

  removeModels(modelIds: string[]) {
    modelIds.forEach((mId) => {
      let index = this.items.ifcModels.findIndex((m) => m.modelFileId == mId);
      if (index > -1) {
        this.items.ifcModels.splice(index, 1);
      }

      index = this.items.pickableIfcModels.findIndex((m) => m.modelId == mId);
      if (index > -1) {
        this.items.pickableIfcModels.splice(index, -1);
      }
    })
  }

  isModelLoaded() {
    return this.items.ifcModels.length > 0;
  }

  private updateMaterialSectionColor(material: Material, color: Color) {
    if (material.userData.shader) {
      material.userData.shader.uniforms.u_section.value = color;
    }
  }

  private render = () => {
    requestAnimationFrame(this.render);
    this.updateAllComponents();
  };

  private updateAllComponents() {
    const delta = this.clock.getDelta();
    this.items.components.forEach((component) => component.update(delta));
  }

  private setupWindowRescale() {
    window.addEventListener("resize", () => {
      this.updateAspect();
    });
  }

  private newItems(): Items {
    return {
      components: [],
      ifcModels: [],
      pickableIfcModels: [],
      hoverMesh: null,
    };
  }
}
