import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export default function CollapseHorizontalIcon({
  htmlColor,
  fontSize,
}: Pick<SvgIconProps, 'htmlColor' | 'fontSize'>) {
  return (
    <SvgIcon htmlColor={htmlColor} fontSize={fontSize} viewBox="0 0 16 16">
      <path d="M 2.8,1.6 V 14.4 H 1.2 V 1.6 Z m 12,7.2 V 7.2 h -8 V 4.8 L 3.6,8 6.8,11.2 V 8.8 Z" />
    </SvgIcon>
  );
}
