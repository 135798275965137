import React, { useCallback, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ProjectNamingGroupSeparatorType from 'naming-schemes/types/ProjectNamingGroupSeparatorType';

interface EditNamingSchemeGroupSeparatorDialogProps {
  initialValue: ProjectNamingGroupSeparatorType | undefined,
  onClose: (value: ProjectNamingGroupSeparatorType | undefined) => Promise<void> | void,
}

export default function EditNamingSchemeGroupSeparatorDialog({
  initialValue,
  onClose,
}: EditNamingSchemeGroupSeparatorDialogProps) {
  const { t } = useTranslation('naming-schemes');
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState<ProjectNamingGroupSeparatorType | undefined>(initialValue);
  const onChangeSeparator = useCallback((event: SelectChangeEvent<ProjectNamingGroupSeparatorType>) => {
    if (typeof event.target.value === 'string') return;
    setValue(event.target.value);
  }, []);
  const onClickCancel = useCallback(() => onClose(undefined), [onClose]);
  const onClickConfirm = useCallback(async () => {
    try {
      setIsLoading(true);
      await onClose(value);
    } finally {
      setIsLoading(false);
    }
  }, [onClose, value]);
  return (
    <Dialog open id="EditNamingSchemeGroupSeparatorDialog">
      <DialogTitle>{t('edit-naming-scheme-group-separator-dialog_title', 'Select Group Separator')}</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 1, display: 'flex', flexDirection: 'column' }}>
          <FormControl>
            <InputLabel id="'edit-naming-scheme-group-separator-dialog_separator-select-label">{t('edit-naming-scheme-group-separator-dialog_separator-select-label', 'Separator')}</InputLabel>
            <Select<ProjectNamingGroupSeparatorType>
              value={value}
              label={t('edit-naming-scheme-group-separator-dialog_separator-select-label', 'Separator')}
              onChange={onChangeSeparator}
            >
              <MenuItem value={ProjectNamingGroupSeparatorType.Dash}>{t('edit-naming-scheme-group-separator-dialog_separator-type-dash', 'Dash "-"')}</MenuItem>
              <MenuItem value={ProjectNamingGroupSeparatorType.Underscore}>{t('edit-naming-scheme-group-separator-dialog_separator-type-underscore', 'Underscore "_"')}</MenuItem>
              <MenuItem value={ProjectNamingGroupSeparatorType.Dot}>{t('edit-naming-scheme-group-separator-dialog_dot-type-dash', 'Dot "."')}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ gap: 2, justifyContent: 'space-between' }}>
        <Button variant="contained" color="secondary" onClick={onClickCancel}>
          {t('edit-naming-scheme-group-separator-dialog_cancel-button-label', 'Cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={onClickConfirm} disabled={value === undefined || isLoading}>
          {!!isLoading && <CircularProgress size={12} sx={{ mr: 1 }} />}
          {t('edit-naming-scheme-group-separator-dialog_confirm-button-label', 'Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
