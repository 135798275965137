import React, { useMemo } from 'react';
import { Button } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import { useTranslation } from 'react-i18next';
import useCollaboratorsSelectionContext from 'collaborators/hooks/useCollaboratorSelectionContext';
import useCollaboratorsQuery from 'collaborators/hooks/useCollaboratorsQuery';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';

interface SendCollaboratorsEmailButtonProps extends ISxProps {
}

export default function SendCollaboratorsEmailButton({
  sx,
}: SendCollaboratorsEmailButtonProps) {
  const { t } = useTranslation('collaborators');
  const { data: currentProject } = useCurrentProjectQuery();
  const { data: collaborators } = useCollaboratorsQuery();
  const { selectedCollaboratorIds } = useCollaboratorsSelectionContext();
  const selectedCollaboratorIdsSet = useMemo(() => new Set<string>(selectedCollaboratorIds), [selectedCollaboratorIds]);
  const selectedCollaborators = useMemo(() => collaborators?.filter((c) => selectedCollaboratorIdsSet.has(c.id)), [collaborators, selectedCollaboratorIdsSet]);
  const href = useMemo(() => {
    if (!currentProject || !selectedCollaborators) return undefined;
    let result = `mailto:${selectedCollaborators.map((collaborator) => collaborator.email).join(';')}?subject=${currentProject.name} | `;
    if (currentProject.inboxEmailAddress) {
      result += `&cc=${currentProject.inboxEmailAddress}`;
    }
    return result;
  }, [currentProject, selectedCollaborators]);
  return (
    <Button
      id="SendCollaboratorsEmailButton"
      variant="contained"
      color="secondary"
      href={href}
      sx={sx}
      disabled={!currentProject || !selectedCollaborators?.length}
    >
      {t('collaborators-page-header_send-emails-button-caption', 'Send {{count}} Emails', { count: selectedCollaborators?.length })}
    </Button>
  );
}
