import React from 'react';
import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import { t } from 'i18next';
import usePropertyGroupsQuery from 'models/hooks/usePropertyGroupsQuery';

interface ComponentDetailsPropertiesPanelProps extends ISxProps {
  propertyGroupIds: string[],
}

export default function ComponentDetailsPropertiesPanel({
  sx,
  propertyGroupIds,
}: ComponentDetailsPropertiesPanelProps) {
  const { data: propertyGroups } = usePropertyGroupsQuery(propertyGroupIds);
  return (
    <Box id="ComponentDetailsPropertiesPanel" sx={{ flex: '1 1 0', overflow: 'auto', display: 'flex', flexDirection: 'column', gap: 2, p: 2, boxShadow: 'inset 0px 24px 24px -24px rgba(0,0,0,0.1)', ...sx }}>
      <Typography variant="h4">{t('component-details-panel_properties-section-header', 'Properties')}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, flexGrow: 1 }}>
        {!propertyGroups && <CenteredCircularProgress />}
        {!!propertyGroups && propertyGroups.map((propertyGroup) => (
          <Box key={propertyGroup.id} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="h5">{propertyGroup.name}</Typography>
            <Table>
              <TableBody>
                {propertyGroup.properties.map((property) => (
                  <TableRow key={`${property.name}_${property.value}`}>
                    <TableCell component="td" scope="row" width="50%">{property.name}</TableCell>
                    <TableCell component="td" scope="row">
                      {property.value
                        ? `${property.value}${property.unit ? ` ${property.unit}` : ''}${property.description ? ` (${property.description})` : ''}`
                        : property.description ?? ''}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
