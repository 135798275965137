import React, {
  useRef, useState,
} from 'react';
import {
  Button, Menu, MenuItem, useTheme, Radio,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ISxProps from 'common/types/ISxProps';
import SortIcon from '@mui/icons-material/Sort';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import useQueryFilterFilterContext from 'documents-filter/hooks/useQueryFilterFilterContext';
import QueryFilterSortMode from 'query-filters/types/QueryFilterSortMode';

interface QueryFilterSortButtonMenuProps extends ISxProps {
}

export default function QueryFilterSortButtonMenu({
  sx,
}: QueryFilterSortButtonMenuProps) {
  const { t } = useTranslation('documents-filter');
  const theme = useTheme();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { sortMode, setSortMode } = useQueryFilterFilterContext();

  return (
    <>
      <Button
        onClick={() => setIsMenuOpen(true)}
        sx={{
          ...sx, color: theme.palette.text.primary, minWidth: 'unset', px: 1,
        }}
        ref={buttonRef}
        id="QueryFilterSortMenuButton"
        variant="outlined"
      >
        <SortIcon sx={{ mr: '-2px', color: theme.palette.text.secondary }} />
      </Button>
      <Menu
        anchorEl={buttonRef.current}
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      >
        <MenuItem onClick={() => setSortMode(QueryFilterSortMode.CreationDateDesc)}>
          <Radio checked={sortMode === QueryFilterSortMode.CreationDateDesc} sx={{ ml: 0 }} />
          {t('filter-explorer_sort-select-creation-date', 'Creation Date')}
          <ArrowDownwardIcon fontSize="small" sx={{ ml: 1 }} />
        </MenuItem>
        <MenuItem onClick={() => setSortMode(QueryFilterSortMode.CreationDateAsc)}>
          <Radio checked={sortMode === QueryFilterSortMode.CreationDateAsc} sx={{ ml: 0 }} />
          {t('filter-explorer_sort-select-creation-date', 'Creation Date')}
          <ArrowUpwardIcon fontSize="small" sx={{ ml: 1 }} />
        </MenuItem>
        <MenuItem onClick={() => setSortMode(QueryFilterSortMode.NameDesc)}>
          <Radio checked={sortMode === QueryFilterSortMode.NameDesc} sx={{ ml: 0 }} />
          {t('filter-explorer_sort-select-name', 'Name')}
          <ArrowDownwardIcon fontSize="small" sx={{ ml: 1 }} />
        </MenuItem>
        <MenuItem onClick={() => setSortMode(QueryFilterSortMode.NameAsc)}>
          <Radio checked={sortMode === QueryFilterSortMode.NameAsc} sx={{ ml: 0 }} />
          {t('filter-explorer_sort-select-name', 'Name')}
          <ArrowUpwardIcon fontSize="small" sx={{ ml: 1 }} />
        </MenuItem>
      </Menu>
    </>
  );
}
