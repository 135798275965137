import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Box, Button, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18n from 'setup/i18n';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useRegistrationApi from 'users/hooks/useRegistrationApi';
import { useNavigate, useParams } from 'react-router-dom';

export default function ActivateAccountPage() {
  const { t } = useTranslation('users');
  const theme = useTheme();
  const navigate = useNavigate();

  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const [activated, setActivated] = useState<boolean | undefined>(undefined);

  const { signUpActivate } = useRegistrationApi();

  const activate = useCallback(async (code: string | undefined) => {
    try {
      if (!code) throw new Error(t('activate-account-page_no-activation-code-provided-error', 'Account activation failed because no activation code was provided'));
      await signUpActivate(code);
      setActivated(true);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [getRequestErrorMessage, signUpActivate, t]);

  const onClickConfirm = useCallback(() => {
    navigate('/projects');
  }, [navigate]);

  const onClickConfirmError = useCallback(() => {
    navigate('/register');
  }, [navigate]);

  const { activationCode } = useParams<'activationCode'>();
  const activationAlreadyRanOnceRef = useRef(false); // react strict-mode deduplication
  useEffect(() => {
    if (activationAlreadyRanOnceRef.current) return;
    activationAlreadyRanOnceRef.current = true;
    activate(activationCode);
  }, [activate, activationCode]);

  useEffect(() => {
    i18n.changeLanguage(navigator.language.startsWith('de') ? 'de-DE' : 'en-US');
  }, []);

  return (
    <Box
      id="ActivateAccountPage"
      sx={{
        backgroundColor: theme.palette.secondary.light,
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
        p: 2,
      }}
      >
        <Box sx={{
          maxWidth: 800,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <img src="/img/visoplan-logo.svg" alt="logo" style={{ width: '250px' }} />
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundColor: theme.palette.background.default,
            boxShadow: '0px 2px 8px -3px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
            p: 5,
            gap: 4,
          }}
          >
            {!!activated && (
              <>
                <Typography variant="h1">{t('account-activation-page_title', 'Account Activated')}</Typography>
                <Alert severity="success">{t('account-activation-page_success-message', 'Your account has been activated.')}</Alert>
                <Button
                  onClick={onClickConfirm}
                  variant="contained"
                  color="primary"
                >
                  {t('account-activation-page_confirm-button-label', 'Launch Visoplan')}
                </Button>
              </>
            )}
            {!!errorMessage && (
              <>
                <Typography variant="h1">{t('account-activation-page_error-title', 'Error Activating Account')}</Typography>
                <Alert severity="error">{errorMessage}</Alert>
                <Button onClick={onClickConfirmError} variant="contained" color="primary" sx={{ alignSelf: 'flex-end' }}>
                  {t('account-activation-page_error-confirm-button-label', 'Back to Sign Up')}
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
