import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { mdiAccountGroup } from '@mdi/js';
import Icon from '@mdi/react';
import ISxProps from 'common/types/ISxProps';
import AssignGroupDialog from 'issues/components/AssignGroupDialog';
import useCollaboratorsOdataQuery from 'collaborators/hooks/useCollaboratorsOdataQuery';
import InlineTypography from 'common/components/InlineTypography';

interface AssignmentSelectProps extends ISxProps {
  value: string[],
  onChange: (nextValue: string[]) => void,
}

export default function AssignmentSelect({
  value,
  onChange,
  sx,
}: AssignmentSelectProps) {
  const { t } = useTranslation('issues');
  const { data: collaborators } = useCollaboratorsOdataQuery({});
  const nonDeletedCollaborators = useMemo(() => collaborators?.filter((c) => !c.isDeleted), [collaborators]);
  const nonDeletedCollaboratorIdsSet = useMemo(() => (nonDeletedCollaborators ? new Set(nonDeletedCollaborators.map((c) => c.id)) : undefined), [nonDeletedCollaborators]);
  const onChangeAssigneeSelect = useCallback((event: SelectChangeEvent<string[]>) => {
    if (!Array.isArray(event.target.value)) return;
    onChange(event.target.value);
  }, [onChange]);

  const [assignGroupDialogOpen, setAssignGroupDialogOpen] = useState(false);
  const onClickAssignGroup = useCallback(() => setAssignGroupDialogOpen(true), []);
  const onCloseAssignGroupDialog = useCallback((result?: { selectedGroupId: string, groupCollaboratorIds: string[] }) => {
    if (result && nonDeletedCollaboratorIdsSet) {
      const nonDeletedGroupCollaboratorIds = result.groupCollaboratorIds.filter((id) => nonDeletedCollaboratorIdsSet.has(id));
      onChange(Array.from(new Set([...value, ...nonDeletedGroupCollaboratorIds])));
    }
    setAssignGroupDialogOpen(false);
  }, [nonDeletedCollaboratorIdsSet, onChange, value]);

  return (
    <Box id="AssignmentSelect" sx={{ display: 'flex', gap: 1, ...sx }}>
      <FormControl sx={{ flexGrow: 1 }}>
        <InputLabel id="assignment-select_assignee-select-label">
          {t('assignment-select_assignee-select-label', 'Assigned')}
        </InputLabel>
        <Select
          id="assignment-select_assignee-select"
          labelId="assignment-select_assignee-select-label"
          multiple
          value={value}
          onChange={onChangeAssigneeSelect}
          label={t('assignment-select_assignee-select-label', 'Assigned')}
        >
          {!!collaborators && collaborators.map((collaborator) => (
            <MenuItem key={collaborator.id} value={collaborator.id} sx={{ ...(!!collaborator.isDeleted && { display: 'none' }) }}>
              <InlineTypography sx={{ ...(!!collaborator.isDeleted && { textDecoration: 'line-through' }) }}>{`${collaborator.firstName} ${collaborator.lastName}`}</InlineTypography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="secondary"
        onClick={onClickAssignGroup}
      >
        <Icon path={mdiAccountGroup} size={1} />
      </Button>
      {!!assignGroupDialogOpen && <AssignGroupDialog onClose={onCloseAssignGroupDialog} />}
    </Box>
  );
}
