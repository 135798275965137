import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Box, Button, ButtonBase, Menu, MenuItem, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiArrangeBringForward, mdiArrangeSendBackward, mdiArrowExpandDown, mdiArrowExpandLeft, mdiArrowExpandRight, mdiArrowExpandUp, mdiAxisArrow, mdiDeleteOutline, mdiPlus } from '@mdi/js';
import InlineTypography from 'common/components/InlineTypography';
import useViewer3dContext from 'models/hooks/useViewer3dContext';

export default function SectionPlanesDockviewPanel() {
  const { t } = useTranslation('models');
  const theme = useTheme();

  const { addClippingPlane, removeClippingPlane, clippingPlanes, setSelectedClippingPlane, selectedClippingPlane } = useViewer3dContext();

  const clippingPlaneItems = useMemo(() => clippingPlanes.map((clippingPlane, index) => ({
    uuid: crypto.randomUUID(),
    name: t('section-planes-dockview-panel_section-plane-item-label', 'Section Plane {{number}}', { number: index + 1 }),
    onClickToggleEdit: () => (clippingPlane !== selectedClippingPlane ? setSelectedClippingPlane(clippingPlane) : setSelectedClippingPlane(undefined)),
    onClickDelete: () => removeClippingPlane(clippingPlane),
    selected: !!selectedClippingPlane && clippingPlane === selectedClippingPlane,
  })), [clippingPlanes, removeClippingPlane, selectedClippingPlane, setSelectedClippingPlane, t]);

  const [isAddSectionPlaneMenuOpen, setIsAddSectionPlaneMenuOpen] = useState<boolean>(false);
  const onClickAddSectionPlane = useCallback(() => setIsAddSectionPlaneMenuOpen(true), []);
  const onCloseAddSectionPlaneMenu = useCallback(() => setIsAddSectionPlaneMenuOpen(false), []);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const onClickAddSectionPlaneLeft = useCallback(() => {
    addClippingPlane({ x: 1, y: 0, z: 0 });
    setIsAddSectionPlaneMenuOpen(false);
  }, [addClippingPlane]);
  const onClickAddSectionPlaneRight = useCallback(() => {
    addClippingPlane({ x: -1, y: 0, z: 0 });
    setIsAddSectionPlaneMenuOpen(false);
  }, [addClippingPlane]);
  const onClickAddSectionPlaneTop = useCallback(() => {
    addClippingPlane({ x: 0, y: -1, z: 0 });
    setIsAddSectionPlaneMenuOpen(false);
  }, [addClippingPlane]);
  const onClickAddSectionPlaneBottom = useCallback(() => {
    addClippingPlane({ x: 0, y: 1, z: 0 });
    setIsAddSectionPlaneMenuOpen(false);
  }, [addClippingPlane]);
  const onClickAddSectionPlaneFront = useCallback(() => {
    addClippingPlane({ x: 0, y: 0, z: -1 });
    setIsAddSectionPlaneMenuOpen(false);
  }, [addClippingPlane]);
  const onClickAddSectionPlaneBack = useCallback(() => {
    addClippingPlane({ x: 0, y: 0, z: 1 });
    setIsAddSectionPlaneMenuOpen(false);
  }, [addClippingPlane]);

  return (
    <Box id="SectionPlanesDockviewPanel" sx={{ width: '100%', height: '100%', overflow: 'auto', p: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Button variant="contained" color="secondary" ref={buttonRef} onClick={onClickAddSectionPlane} sx={{ pl: 1.5, gap: 0.5 }}>
        <Icon path={mdiPlus} size={0.75} />
        {t('section-planes-dockview-panel_add-section-plane-button-label', 'Add Section Plane')}
      </Button>
      {clippingPlaneItems.map(({ uuid, onClickToggleEdit, onClickDelete, name, selected }) => (
        <Box key={uuid} sx={{ display: 'flex', borderRadius: '4px', gap: 1, flexWrap: 'wrap' }}>
          <ButtonBase onClick={onClickToggleEdit} sx={{ pl: 1.5, gap: 1, flexGrow: 1, justifyContent: 'flex-start', borderRadius: '4px', backgroundColor: selected ? theme.palette.secondary.light : undefined, ':hover': { backgroundColor: 'rgba(0,0,0,0.05)' } }}>
            <Icon path={mdiAxisArrow} size={1} />
            {name}
          </ButtonBase>
          <Button onClick={onClickDelete} sx={{ minWidth: 'unset', px: 1 }} variant="outlined">
            <Icon path={mdiDeleteOutline} size={1} />
          </Button>
        </Box>
      ))}
      <Menu open={isAddSectionPlaneMenuOpen} anchorEl={buttonRef.current} onClose={onCloseAddSectionPlaneMenu}>
        <MenuItem sx={{ gap: 1 }} onClick={onClickAddSectionPlaneLeft}>
          <Icon path={mdiArrowExpandLeft} size={0.75} />
          <InlineTypography sx={{ flexGrow: 1 }}>
            {t('section-planes-dockview-panel_add-section-plane-button-left', 'Left')}
          </InlineTypography>
        </MenuItem>
        <MenuItem sx={{ gap: 1 }} onClick={onClickAddSectionPlaneRight}>
          <Icon path={mdiArrowExpandRight} size={0.75} />
          <InlineTypography sx={{ flexGrow: 1 }}>
            {t('section-planes-dockview-panel_add-section-plane-button-right', 'Right')}
          </InlineTypography>
        </MenuItem>
        <MenuItem sx={{ gap: 1 }} onClick={onClickAddSectionPlaneTop}>
          <Icon path={mdiArrowExpandUp} size={0.75} />
          <InlineTypography sx={{ flexGrow: 1 }}>
            {t('section-planes-dockview-panel_add-section-plane-button-top', 'Top')}
          </InlineTypography>
        </MenuItem>
        <MenuItem sx={{ gap: 1 }} onClick={onClickAddSectionPlaneBottom}>
          <Icon path={mdiArrowExpandDown} size={0.75} />
          <InlineTypography sx={{ flexGrow: 1 }}>
            {t('section-planes-dockview-panel_add-section-plane-button-bottom', 'Bottom')}
          </InlineTypography>
        </MenuItem>
        <MenuItem sx={{ gap: 1 }} onClick={onClickAddSectionPlaneFront}>
          <Icon path={mdiArrangeBringForward} size={0.75} />
          <InlineTypography sx={{ flexGrow: 1 }}>
            {t('section-planes-dockview-panel_add-section-plane-button-front', 'Front')}
          </InlineTypography>
        </MenuItem>
        <MenuItem sx={{ gap: 1 }} onClick={onClickAddSectionPlaneBack}>
          <Icon path={mdiArrangeSendBackward} size={0.75} />
          <InlineTypography sx={{ flexGrow: 1 }}>
            {t('section-planes-dockview-panel_add-section-plane-button-back', 'Back')}
          </InlineTypography>
        </MenuItem>
      </Menu>
    </Box>
  );
}
