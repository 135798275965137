import React, { useCallback } from 'react';
import EmailsDataGrid from 'emails/components/EmailsDataGrid';
import { Box } from '@mui/material';
import PageWrapper from 'common/components/PageWrapper';
import EmailsPageHeader from 'emails/components/EmailsPageHeader';
import EmailsFilterContextProvider from 'emails/contexts/EmailsFilterContextProvider';
import EmailColumnOptionsButtonMenu from 'emails/components/EmailColumnOptionsButtonMenu';
import { useNavigate, useParams } from 'react-router-dom';

export default function EmailsPage() {
  const { emailId } = useParams<'emailId'>();
  const navigate = useNavigate();
  const onChangeSelectedEmailId = useCallback((nextSelectedEmailId: string | undefined) => {
    if (nextSelectedEmailId) {
      navigate(`./${nextSelectedEmailId}`);
    } else {
      navigate('.');
    }
  }, [navigate]);
  return (
    <PageWrapper id="EmailsPage">
      <EmailsFilterContextProvider>
        <Box sx={{
          display: 'grid',
          gridTemplateRows: '60px 1fr',
          height: '100%',
        }}
        >
          <EmailsPageHeader />
          <EmailsDataGrid selectedEmailId={emailId} onChangeSelectedEmailId={onChangeSelectedEmailId} />
          <EmailColumnOptionsButtonMenu sx={{ position: 'absolute', right: '16px', top: '70px' }} />
        </Box>
      </EmailsFilterContextProvider>
    </PageWrapper>
  );
}
