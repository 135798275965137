import DynamicLayoutKey from 'dynamic-layout/types/DynamicLayoutKey';
import useDynamicLayout from 'dynamic-layout/hooks/useDynamicLayout';

const legacyColumnNameMap = new Map<string, string>([
  ['Type', 'issueType'],
  ['Number', 'issueNumber'],
  ['No.', 'issueNumber'],
  ['Image', 'thumbnailImageId'],
  ['Name', 'title'],
  ['Title', 'title'],
  ['Description', 'description'],
  ['Status', 'issueStatus'],
  ['Due Date', 'dueDate'],
  ['Discipline', 'disciplines'],
  ['Disciplines', 'disciplines'],
  ['Models', 'models'],
  ['Priority', 'issuePriority'],
  ['Assigned to', 'assignedUsers'],
  ['Assigned To', 'assignedUsers'],
  ['Reviewer', 'reviewer'],
  ['Workphase', 'workPhase'],
  ['Work Phase', 'workPhase'],
  ['Edit Date', 'editDate'],
  ['Creator', 'createAuthor'],
  ['Created By', 'createAuthor'],
  ['Start Date', 'startingDate'],
  ['Last Edited by', 'editAuthor'],
  ['Last Edited By', 'editAuthor'],
  ['Storey', 'floors'],
  ['Floor', 'floors'],
  ['Floors', 'floors'],
  ['Building', 'buildings'],
  ['Buildings', 'buildings'],
  ['Viewpoints', 'viewpointIds'],
  ['Source', 'source'],
  ['Creation date', 'creationDate'],
  ['Creation Date', 'creationDate'],
  ['Documents (amount of Documents)', 'linkedDocumentVersionIds'],
  ['Visibility', 'visibility'],
  ['Comments', 'commentIds'],
  ['Tags', 'tags'],
  ['Linked Emails', 'linkedEmailIds'],
  ['Comments', 'commentIds'],
  ['Updated / New', ''],
]);

export default function useIssuesDynamicLayout() {
  return useDynamicLayout(DynamicLayoutKey.DEFAULT_ISSUE_LAYOUT, undefined, undefined, legacyColumnNameMap);
}
