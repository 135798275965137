import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useCurrentProjectId from 'projects/hooks/useCurrentProjectId';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import { useCallback } from 'react';

export default function useFolderUpdateMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const projectId = useCurrentProjectId();
  if (!projectId) throw new Error('projectId missing');
  const { queryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.Folder);
  const mutationFn = useCallback(async (folderId: string) => {
    const { data: success } = await axiosInstance.delete<boolean>(`${ApiEndpoint.Folder}/${folderId}`);
    return success;
  }, [axiosInstance]);
  const onSuccess = useCallback(() => {
    queryKeyBases.forEach((queryKey) => queryClient.invalidateQueries({ queryKey }));
  }, [queryClient, queryKeyBases]);
  return useMutation<boolean | undefined, unknown, string>({
    mutationFn,
    onSuccess,
  });
}
