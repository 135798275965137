import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, Alert, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, Select, MenuItem, Divider, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useDocumentListsOdataQuery from 'documents-lists/hooks/useDocumentListsOdataQuery';
import CreatePlanlistDialog from 'documents-lists/components/CreatePlanlistDialog';
import useAddDocumentVersionsToPlanlist from 'documents-lists/hooks/useAddDocumentVersionsToPlanlist';

interface AddToPlanlistDialogProps {
  onClose: (planlistIdAddedTo: string | undefined) => void,
  documentVersionIds: string[],
}

export default function AddToPlanlistDialog({
  onClose,
  documentVersionIds,
}: AddToPlanlistDialogProps) {
  const { t } = useTranslation('documents-lists');
  const { data: planlists, isLoading: isLoadingPlanlists } = useDocumentListsOdataQuery({});
  const {
    addDocumentVersionsToPlanlist,
    errorMessage,
    clearErrorMessage,
    successMessage,
    isPending: isLoadingUpdate,
  } = useAddDocumentVersionsToPlanlist();
  const isLoading = useMemo(() => isLoadingUpdate || isLoadingPlanlists, [isLoadingPlanlists, isLoadingUpdate]);
  const [targetPlanlistId, setTargetPlanlistId] = useState<string | undefined>(undefined);
  const [createPlanlistDialogOpen, setCreatePlanlistDialogOpen] = useState(false);
  const onClickCreatePlanlist = useCallback(() => setCreatePlanlistDialogOpen(true), []);
  const onCloseCreatePlanlistDialog = useCallback((createdPlanlistId: string | undefined) => {
    setCreatePlanlistDialogOpen(false);
    onClose(createdPlanlistId);
  }, [onClose]);

  useEffect(() => {
    setTargetPlanlistId((prev) => {
      if (prev || !planlists?.length) return prev;
      return planlists[0].id;
    });
  }, [planlists]);

  const onConfirm = useCallback(async () => {
    if (!targetPlanlistId) return;
    await addDocumentVersionsToPlanlist(documentVersionIds, targetPlanlistId);
  }, [addDocumentVersionsToPlanlist, documentVersionIds, targetPlanlistId]);

  const onCloseSuccessDialog = useCallback(() => onClose(targetPlanlistId), [onClose, targetPlanlistId]);

  const onCancel = useCallback(() => onClose(undefined), [onClose]);

  return (
    <Dialog
      id="AddToPlanlistDialog"
      open
      scroll="paper"
      PaperProps={{ style: { width: '400px', minHeight: '240px' } }}
    >
      <DialogTitle>{t('add-to-planlist-dialog_title', 'Add {{count}} documents to a planlist', { count: documentVersionIds.length })}</DialogTitle>
      <Divider />
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, py: 3 }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <FormControl size="small" sx={{ flexGrow: 1 }}>
              <InputLabel id="add-to-planlist-dialog_existing-planlist-select-label">
                {t('add-to-planlist-dialog_existing-planlist-select-label', 'Existing Plan List')}
              </InputLabel>
              <Select
                id="add-to-planlist-dialog_existing-planlist-select"
                labelId="add-to-planlist-dialog_existing-planlist-select-label"
                value={targetPlanlistId ?? ''}
                onChange={(e) => setTargetPlanlistId(e.target.value)}
                label={t('add-to-planlist-dialog_existing-planlist-select-label', 'Existing Plan List')}
                disabled={isLoading}
                sx={{ flexGrow: 1 }}
              >
                {planlists?.map((p) => <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>)}
              </Select>
            </FormControl>
            <Button id="AddToPlanlistDialogAddButton" variant="contained" color="primary" onClick={onConfirm} disabled={isLoading || !targetPlanlistId} sx={{ gap: 1 }}>
              {isLoadingUpdate && <CircularProgress size={12} />}
              {t('add-to-planlist-dialog_confirm-button-label', 'Add')}
            </Button>
          </Box>
          <Divider><Chip label={t('add-to-planlist-dialog_divider-label', 'Or')} /></Divider>
          <Button id="AddToPlanlistDialogCreateNewPlanlistButton" variant="contained" color="primary" onClick={onClickCreatePlanlist} disabled={isLoading}>
            {t('add-to-planlist-dialog_create-new-planlist-button-label', 'Create New Planlist')}
          </Button>
          {!!errorMessage && <Alert severity="error" onClose={clearErrorMessage}>{errorMessage}</Alert>}
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between', pt: 3 }}>
        <Button id="AddToPlanlistDialogCancelButton" variant="contained" color="secondary" onClick={onCancel}>
          {t('add-to-planlist-dialog_cancel-button-label', 'Cancel')}
        </Button>
      </DialogActions>
      <CreatePlanlistDialog open={createPlanlistDialogOpen} documentVersionIds={documentVersionIds} onClose={onCloseCreatePlanlistDialog} canGoBack />
      {successMessage && (
        <Dialog open>
          <DialogTitle>{t('add-to-planlist-dialog_success-dialog-title', 'Documents Added to Planlist')}</DialogTitle>
          <DialogContent>
            <Alert severity="success">{successMessage}</Alert>
          </DialogContent>
          <DialogActions>
            <Button id="AddToPlanlistDialogSuccessCloseButton" variant="contained" color="primary" onClick={onCloseSuccessDialog}>{t('add-to-planlist-dialog_success-dialog-close-button-label', 'Close')}</Button>
          </DialogActions>
        </Dialog>
      )}
    </Dialog>
  );
}
