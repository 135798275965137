import React, { useCallback, useState } from 'react';
import { Alert, Button, CircularProgress, Portal, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ISxProps from 'common/types/ISxProps';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import Icon from '@mdi/react';
import { mdiDownload } from '@mdi/js';

interface ActivityLogDownloadButtonProps extends ISxProps {
}

export default function ActivityLogDownloadButton({
  sx,
}: ActivityLogDownloadButtonProps) {
  const axios = useAxiosInstance();
  const { t } = useTranslation('projects');
  const [isPreparingDownload, setIsPreparingDownload] = useState<boolean>(false);
  const [feedbackMessageOpen, setFeedbackMessageOpen] = useState<boolean>(false);
  const onCloseFeedbackMessage = useCallback(() => setFeedbackMessageOpen(false), []);
  const onClickDownload = useCallback(async () => {
    setIsPreparingDownload(true);
    const { data } = await axios.get<Blob>(`/activities/export/${(new Date().getTimezoneOffset() / 60) * -1}`, { responseType: 'blob', timeout: Number.POSITIVE_INFINITY });
    if (!data) throw new Error('Download failed');
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(data);
    a.download = 'activities.csv';
    document.body.appendChild(a);
    a.click();
    a.remove();
    setIsPreparingDownload(false);
    setFeedbackMessageOpen(true);
  }, [axios]);
  return (
    <>
      <Button
        id="ActivityLogDownloadButton"
        onClick={onClickDownload}
        variant="outlined"
        size="small"
        sx={{ ...sx, gap: 0.5, px: 0.5 }}
      >
        {!isPreparingDownload && <Icon path={mdiDownload} size={0.75} />}
        {isPreparingDownload && <CircularProgress size={12} />}
        {t('activity-export-button-label', 'Activity Log')}
      </Button>
      <Portal container={document.body}>
        <Snackbar id="ActivityLogDownloadButtonSnackbar" open={feedbackMessageOpen} onClose={onCloseFeedbackMessage} autoHideDuration={6000}>
          <Alert severity="info">
            {t('activity-log-download-button_download-feedback-message', 'Activity log download started.')}
          </Alert>
        </Snackbar>
      </Portal>
    </>
  );
}
