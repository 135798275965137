import { QueryObserverOptions } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import { useCallback, useMemo } from 'react';
import { SetupTwoFactorAuthQueryKey } from 'users/hooks/useSetupTwoFactorAuthQueryKey';
import TwoFactorSetupDto from 'users/types/TwoFactorSetupDto';

export type SetupTwoFactorAuthQueryOptions = Partial<Omit<QueryObserverOptions<TwoFactorSetupDto | undefined, unknown, TwoFactorSetupDto | undefined, TwoFactorSetupDto | undefined, SetupTwoFactorAuthQueryKey>, 'queryKey'>>;

export default function useSetupTwoFactorAuthQueryOptions() {
  const axiosInstance = useAxiosInstance();
  const queryFn = useCallback(async () => {
    const { data } = await axiosInstance.get<TwoFactorSetupDto>(ApiEndpoint.Totp);
    return data;
  }, [axiosInstance]);
  return useMemo<SetupTwoFactorAuthQueryOptions>(() => ({
    queryFn,
  }), [queryFn]);
}
