import React from 'react';
import { Box, useTheme } from '@mui/material';
import { mdiFileMultipleOutline, mdiFileOutline } from '@mdi/js';
import Icon from '@mdi/react';
import ISxProps from 'common/types/ISxProps';
import { useTranslation } from 'react-i18next';

interface DocumentVersionsDragAndDropPayloadPreviewProps extends ISxProps {
  documentVersionsCount: number,
}

export default function DocumentVersionsDragAndDropPayloadPreview({
  sx,
  documentVersionsCount,
}: DocumentVersionsDragAndDropPayloadPreviewProps) {
  const { t } = useTranslation('documents');
  const theme = useTheme();
  return (
    <Box
      id="DocumentVersionsDragAndDropPayloadPreview"
      sx={{
        position: 'absolute',
        zIndex: 9999,
        left: 0,
        top: 0,
        backgroundColor: theme.palette.background.default,
        borderRadius: '8px',
        py: 2,
        px: 3,
        boxShadow: '0px 0px 24px -6px rgba(0,0,0,0.3)',
        pointerEvents: 'none',
        fontWeight: 600,
        display: 'flex',
        gap: 2,
        alignItems: 'center',
        ...sx,
      }}
    >
      <Icon path={documentVersionsCount === 1 ? mdiFileOutline : mdiFileMultipleOutline} size={1} />
      {t('drag-handle-cell_payload-preview', '{{count}} Documents', { count: documentVersionsCount })}
    </Box>
  );
}
