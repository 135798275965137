import CredentialsDto from 'users/types/CredentialsDto';
import { AxiosInstance } from 'axios';
import { GraphQLClient } from 'graphql-request';
import { createContext } from 'react';
import AuthProjectTokenDto from 'users/types/AuthProjectTokenDto';
import AuthUserTokenDto from 'users/types/AuthUserTokenDto';
import { SignInResult } from 'api/contexts/VisoplanApiProvider';
import TwoFactorCredentialsDto from 'users/types/TwoFactorCredentialsDto';

export interface VisoplanApiContextState {
  axiosInstance: AxiosInstance,
  graphQlClient: GraphQLClient,
  authProjectToken: AuthProjectTokenDto | undefined,
  authUserToken: AuthUserTokenDto | undefined,
  signInUsingCredentials: (credentials: CredentialsDto) => Promise<SignInResult>,
  signInUsingTwoFactorCredentials: (credentials: TwoFactorCredentialsDto) => Promise<void>,
  signInUsingTokenBearer: (bearer: string) => Promise<void>,
  isSignedIn: boolean | undefined,
  signOut: () => Promise<void>,
  openProject: (projectId: string) => Promise<void>,
  openedProjectId: string | null | undefined,
  leaveProject: () => Promise<void>,
  clearProjectToken: () => Promise<void>,
  activateTwoFactorAuth: (oneTimePassword: string) => Promise<void>,
  refreshToken: (projectId: string | undefined) => Promise<void>,
}

const defaultState: VisoplanApiContextState = {
  axiosInstance: undefined!,
  graphQlClient: undefined!,
  authProjectToken: undefined,
  authUserToken: undefined,
  signInUsingCredentials: () => undefined!,
  signInUsingTwoFactorCredentials: () => undefined!,
  signInUsingTokenBearer: () => undefined!,
  isSignedIn: undefined,
  signOut: () => undefined!,
  openProject: () => undefined!,
  openedProjectId: undefined,
  leaveProject: () => undefined!,
  clearProjectToken: () => undefined!,
  activateTwoFactorAuth: () => undefined!,
  refreshToken: () => undefined!,
};

const ApiContext = createContext<VisoplanApiContextState>(defaultState);
export default ApiContext;
