import { IfcManager } from "./ifc-manager";

export enum UnitType {
  LENGTHUNIT = "LENGTHUNIT",
  AREAUNIT = "AREAUNIT",
  VOLUMEUNIT = "VOLUMEUNIT",
}

export const UnitScale: { [unit: string]: number } = {
  MILLI: 0.001,
  CENTI: 0.01,
  DECI: 0.1,
  NONE: 1,
  DECA: 10,
  HECTO: 100,
  KILO: 1000,
};

export class IfcUnits {
  allUnits: { [modelID: number]: any } = {};
  // @ts-ignore
  private ifc: IfcManager;

  constructor(ifc: IfcManager) {
    this.ifc = ifc;
  }

  async getUnits(modelID: number, type: UnitType) {
    if (!this.allUnits[modelID]) {
      await this.getUnitsOfModel(modelID);
    }
    return this.allUnits[modelID][type];
  }

  async getUnitsOfModel(modelID: number) {
    this.allUnits[modelID] = {};
  }
}
