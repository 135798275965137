import React, { useCallback, useState } from 'react';
import { Box } from '@mui/material';
import IssueDetailsPanel from 'issues/components/IssueDetailsPanel';
import { useNavigate, useParams } from 'react-router-dom';
import { Resizable } from 're-resizable';
import { Direction } from 're-resizable/lib/resizer';

export default function EmailsLinkedIssuesDetails() {
  const { issueId } = useParams<'issueId'>();
  const navigate = useNavigate();

  const [dividerBarDefaultHeight, setDividerBarDefaultHeight] = useState(() => {
    const storedWidth = localStorage.getItem('email-details_default-divider-bar-height');
    if (storedWidth?.length) {
      const parsedWidth = parseInt(storedWidth, 10);
      if (!Number.isNaN(parsedWidth)) return parsedWidth;
    }
    return 350;
  });

  const onResizeStopDividerBar = useCallback((event: MouseEvent | TouchEvent, direction: Direction, elementRef: HTMLElement) => {
    localStorage.setItem('email-details_default-divider-bar-height', `${elementRef.clientHeight}`);
    setDividerBarDefaultHeight(elementRef.clientHeight);
  }, []);

  const onCloseIssueDetailsPanel = useCallback(() => {
    navigate('..');
  }, [navigate]);

  return (
    <Box id="EmailsLinkedIssuesDetails">
      <Resizable
        style={{ boxShadow: '0px 0px 16px 0px rgba(0,0,0,0.1)' }}
        enable={{ top: true, right: false, bottom: false, left: false, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
        defaultSize={{ width: 'auto', height: dividerBarDefaultHeight }}
        minHeight="200px"
        handleComponent={{ left: <Box sx={{ width: '100%', height: '8px', backgroundColor: 'transparent' }}><Box sx={{ height: '100%' }} /></Box> }}
        handleStyles={{ left: { left: 0 } }}
        onResizeStop={onResizeStopDividerBar}
      >
        <IssueDetailsPanel issueId={issueId} onClose={onCloseIssueDetailsPanel} />
      </Resizable>
    </Box>
  );
}
