import React, { ReactNode, Suspense } from 'react';
import {
  Box, styled, SxProps, Theme,
} from '@mui/material';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';

const WidthTransitionBox = styled(Box)(({ theme }) => ({
  transition: theme.transitions.create(['width', 'border-left-width', 'opacity'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: theme.palette.background.default,
  borderLeftStyle: 'solid',
  borderLeftColor: theme.palette.divider,
  borderLeftWidth: 0,
  opacity: 0,
  '&.open': {
    transition: theme.transitions.create(['width', 'border-left-width', 'opacity'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shortest,
    }),
    borderLeftWidth: '1px',
    opacity: 1,
  },
}));

interface RightUtilityDrawerProps {
  id?: string,
  isOpen: boolean,
  children?: ReactNode,
  sx?: SxProps<Theme>,
  openWidth: number,
}

export default function RightUtilityDrawer({
  id,
  isOpen,
  children,
  sx,
  openWidth,
}: RightUtilityDrawerProps) {
  return (
    <WidthTransitionBox id={id} sx={{ ...sx, width: isOpen ? openWidth : 0 }} className={isOpen ? 'open' : undefined}>
      <Suspense fallback={<CenteredCircularProgress />}>
        {children}
      </Suspense>
    </WidthTransitionBox>
  );
}

RightUtilityDrawer.defaultProps = {
  children: undefined,
  sx: undefined,
};
