import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Alert, Box, LinearProgress, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useBcfUploadHandler from 'issues/hooks/useBcfUploadHandler';

interface BcfImportProgressDialogProps {
  onClose: () => void,
}

export default function BcfImportProgressDialog({
  onClose,
}: BcfImportProgressDialogProps) {
  const { t } = useTranslation('issues');
  const theme = useTheme();
  const getRequestErrorMessage = useRequestErrorMessage();
  const uploadBcfFiles = useBcfUploadHandler();

  const inputRef = useRef<HTMLInputElement>(null);
  const pickerOpenRef = useRef<boolean>(false); // tracks if the picker is open to avoid it being opened twice due to React's strict mode executing all effects twice

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [completed, setCompleted] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileNames, setFileNames] = useState<string[]>([]);

  const finished = !errorMessage && completed;
  const inProgress = !errorMessage && !completed && progress > 0;
  const pending = !errorMessage && !completed && progress === 0;

  const color = useMemo(() => {
    if (completed) return 'success';
    if (errorMessage) return 'error';
    return 'info';
  }, [completed, errorMessage]);

  useEffect(() => {
    if (!inputRef.current || pickerOpenRef.current) return;
    pickerOpenRef.current = true; // strict mode deduplication
    inputRef.current.showPicker();
  }, []);

  const onChangeFileInput = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files) {
      onClose();
      return;
    }
    try {
      const files = Array.from(e.target.files);
      setFileNames(files.map((file) => file.name));
      await uploadBcfFiles(files, (value: number) => setProgress(value));
      setProgress(1);
      setCompleted(true);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [getRequestErrorMessage, onClose, uploadBcfFiles]);
  return (
    <>
      <input ref={inputRef} hidden accept=".bcf" multiple type="file" onChange={onChangeFileInput} />
      {!!fileNames.length && (
        <Dialog open id="BcfImportProgressDialog">
          <DialogTitle>
            {t('bcf-import-progress-dialog_title', 'BCF Upload')}
          </DialogTitle>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {!!inProgress && (
              <Alert severity="info">
                {t('bcf-import-progress-dialog_import-in-progress-message', 'Importing {{count}} BCF files...', { count: fileNames.length })}
              </Alert>
            )}
            {!!pending && (
              <Alert severity="info">
                {t('bcf-import-progress-dialog_import-pending-message', 'Preparing the import of {{count}} BCF files...', { count: fileNames.length })}
              </Alert>
            )}
            {!!finished && (
              <Alert severity="success">
                {t('bcf-import-progress-dialog_import-finished-message', 'Imported {{count}} BCF files.', { count: fileNames.length })}
              </Alert>
            )}
            {!!errorMessage && (
              <Alert severity="error">
                {errorMessage}
              </Alert>
            )}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, minWidth: 250 }}>
              <LinearProgress variant={pending ? 'indeterminate' : 'determinate'} value={progress * 100} sx={{ flexGrow: 1 }} color={color} />
              <Typography sx={{
                color: theme.palette[color].main,
                fontWeight: 500,
                pl: 1,
              }}
              >
                {(completed ? '100%' : `${Math.floor(errorMessage ? 0 : progress)} %`)}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions sx={{ gap: 2, justifyContent: 'flex-end' }}>
            <Button variant="contained" color="primary" onClick={onClose} disabled={completed && !!errorMessage}>
              {t('bcf-import-progress-dialog_confirm-button-label', 'Close')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
