import { IfcManager } from "../ifc-manager";
import { IfcContext } from "../../context";
import { HoverSelection } from "./hoverSelection";
import { PickSelection } from "./pickSelection";
import { LiteEvent } from "../../../utils/LiteEvent";
import { ColorSelection } from "./colorSelection";
import { SelectionData, VisoNode } from "../../../BaseDefinitions";

export class IfcSelector {
  public readonly onHover = new LiteEvent<VisoNode>();
  public readonly onSelect = new LiteEvent<SelectionData>();
  public readonly onColorSelect = new LiteEvent<Array<string>>();

  private preselection: HoverSelection;
  private selection: PickSelection;
  private colorSelection: ColorSelection;

  constructor(private context: IfcContext, ifc: IfcManager) {
    this.preselection = new HoverSelection(context, ifc.visoLoader);
    this.selection = new PickSelection(context, ifc.visoLoader);
    this.colorSelection = new ColorSelection(context, ifc.visoLoader);

    this.preselection.onFound.on((data) => this.onHover.trigger(data));
    this.selection.onFound.on((data) => this.onSelect.trigger(data));
    this.colorSelection.onColorSelect.on((data) =>
      this.onColorSelect.trigger(data)
    );
  }

  /**
   * Highlights the item pointed by the cursor.
   */
  async prePickIfcItem() {
    if (this.context.ifcCamera.isLocked) {
      this.preselection.hideSelection();
    } else {
      const found = this.context.castRayIfc();
      if (!found) {
        this.preselection.hideSelection();
      } else {
        await this.preselection.pick(found);
      }
    }
  }

  /**
   * Highlights the item pointed by the cursor and gets is properties.
   * @ctrl whether Ctrl key is pressed.
   * @focusSelection If true, animate the perspectiveCamera to focus the current selection
   */
  async pickIfcItem(ctrl: boolean, focusSelection = false) {
    if (this.context.ifcCamera.isLocked) return;
    this.preselection.hideSelection();
    const found = this.context.castRayIfc();
    if (!found) {
      if (ctrl) {
        return;
      } else {
        this.selection.unpick();
      }
    } else {
      await this.selection.pick(found, ctrl, focusSelection);
    }
  }

  getHoveredComponentGlobalId() {
    return this.preselection.getSelectedGlobalId();
  }

  hideHoverSelection() {
    this.preselection.hideSelection();
  }

  /**
   * Unapplies the picking material.
   */
  unpickIfcItems() {
    this.selection.unpick();
  }

  resetSelection() {
    this.selection.reset();
    this.colorSelection.reset();
  }

  zoomToSelectedComponent() {
    this.selection.zoomToSelectedComponent();
  }

  hideSelectedComponent() {
    this.selection.hideSelectedComponent();
  }

  transparentComponent(isOn: boolean) {
    this.colorSelection.transparentComponent(isOn);
  }

  setColorComponent(color: string) {
    this.colorSelection.setColorComponent(color);
  }

  resetColorComponent() {
    this.colorSelection.resetColorComponent();
  }

  isolateAndTranslateElements() {
    this.colorSelection.isolateAndTransparentElements();
  }

  removeComponentFromSelection(component: VisoNode) {
    this.selection.removeComponentFromSelection(component);
  }

  setVisualModel(visualModel: any) {
    const { colorModel, pickModel } = visualModel;
    this.colorSelection.setVisualModel(colorModel);
    this.selection.setVisualModel(pickModel);
  }

  pickComponents(components: VisoNode[]) {
    this.selection.pickComponents(components);
  }
}
