import { HTML5Backend } from 'react-dnd-html5-backend';

export default function CustomHtml5Backend(manager: any) {
  const backend = HTML5Backend(manager);
  // @ts-ignore
  const orgTopDropCapture = backend.handleTopDropCapture;

  // @ts-ignore
  backend.handleTopDropCapture = (ev) => {
    orgTopDropCapture.call(backend, ev);
    if (ev.dataTransfer.items) {
      const folderNames: string[] = [];
      [...ev.dataTransfer.items].forEach((item) => {
        if (item.kind === 'file' && 'webkitGetAsEntry' in item) {
          const file = item.webkitGetAsEntry() as FileSystemEntry;
          if (file.isDirectory) {
            folderNames.push(file.name);
          }
        }
      });
      // @ts-ignore
      if (backend.currentNativeSource?.item) {
        // @ts-ignore
        backend.currentNativeSource.item.folderNames = folderNames;
      }
    }
  };

  return backend;
}
