import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import useModelsInteractionContext from 'models/hooks/useModelsInteractionContext';
import { ModelsInteractionMode } from 'models/contexts/ModelsInteractionContext';
import ModelsDialog from 'models/components/ModelsDialog';
import useModelFileIdsLinkedToIssue from 'issues/hooks/useModelFileIdsLinkedToIssue';
import useIssueUpdateMutation from 'issues/hooks/useIssueUpdateMutation';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import UpdateIssueDto from 'issues/types/UpdateIssueDto';
import LinkedComponentListItem, { ComponentLinkAction } from 'issues/components/LinkedComponentListItem';
import useViewer3dContext from 'models/hooks/useViewer3dContext';

interface EditComponentsDialogProps {
  issueId: string,
  onClose: () => void,
  showNestedModelsDialog?: boolean,
}

export default function EditComponentsDialog({
  issueId,
  onClose,
  showNestedModelsDialog,
}: EditComponentsDialogProps) {
  const { t } = useTranslation('models');
  const getModelFileIdsLinkedToIssue = useModelFileIdsLinkedToIssue();
  const { data: issue } = useIssueQuery(issueId);
  const { getNode } = useViewer3dContext();
  const { interactionMode, setInteractionMode, linkedComponentItems, setLinkedComponentItems } = useModelsInteractionContext();
  const { mutateAsync, isPending } = useIssueUpdateMutation();
  const listItems = useMemo(() => {
    if (!issue) return undefined;
    return [
      ...(issue.linkedComponentsGlobalIds ?? []).map((globalId) => ({ globalId, node: getNode(globalId) })).map(({ globalId, node }) => ({
        globalId,
        elementName: node?.elementName,
        nodeLoaded: !!node,
        pendingAction: ComponentLinkAction.LeavePersisted,
        onClickRemove: undefined,
      })),
      ...linkedComponentItems.map(({ globalId, elementName }) => ({
        globalId,
        elementName,
        nodeLoaded: true,
        pendingAction: ComponentLinkAction.Add,
        onClickRemove: () => setLinkedComponentItems((prev) => prev.filter((prevItem) => prevItem.globalId !== globalId)),
      })),
    ];
  }, [getNode, issue, linkedComponentItems, setLinkedComponentItems]);
  const [dialogSelectedIssueId, setDialogSelectedIssueId] = useState<string | undefined>(undefined);
  const [intialModelFileIds, setInitialModelFileIds] = useState<string[] | undefined>(undefined);
  const onClickAdd = useCallback(async () => {
    const modelFileIds = await getModelFileIdsLinkedToIssue(issueId);
    setInitialModelFileIds(modelFileIds);
    setInteractionMode(ModelsInteractionMode.LinkedComponentManagement);
  }, [getModelFileIdsLinkedToIssue, issueId, setInteractionMode]);
  const onClickConfirm = useCallback(async () => {
    if (linkedComponentItems?.length) {
      const globalIdsByModelFileId = new Map<string, string[]>();
      linkedComponentItems.forEach((item) => {
        if (!item.modelFileId) return;
        const componentGlobalIds: string[] = globalIdsByModelFileId.get(item.modelFileId) ?? [];
        componentGlobalIds.push(item.globalId);
        globalIdsByModelFileId.set(item.modelFileId, componentGlobalIds);
      });
      const nextLinkedComponentGlobalIds = Array.from(new Set([...(issue?.linkedComponentsGlobalIds ?? []), ...linkedComponentItems.map((item) => item.globalId)]));
      const updateIssueDto: UpdateIssueDto = {
        id: issueId,
        linkedComponentsGlobalIds: { value: nextLinkedComponentGlobalIds },
        ...(globalIdsByModelFileId.size ? { modelFileComponentIds: { value: Object.fromEntries(globalIdsByModelFileId) } } : undefined),
      };
      await mutateAsync(updateIssueDto);
    }
    setLinkedComponentItems([]);
    onClose();
  }, [issue?.linkedComponentsGlobalIds, issueId, linkedComponentItems, mutateAsync, onClose, setLinkedComponentItems]);
  const onClickCancel = useCallback(() => onClose(), [onClose]);
  return (
    <>
      <Dialog open id="EditComponentsDialog" PaperProps={{ sx: { maxWidth: 'unset', width: 800 } }}>
        <DialogTitle component="div" sx={{ display: 'flex', gap: 2, pb: 3, justifyContent: 'space-between' }}>
          <Typography variant="h2">
            {t('edit-components-dialog_title', 'Edit Linked Components')}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={onClickAdd}
            sx={{ gap: 0.5, pl: 0.5 }}
          >
            <Icon path={mdiPlus} size={0.75} />
            {t('edit-components-dialog_add-button-label', 'Add')}
          </Button>
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'auto' }}>
            {!!listItems && listItems.map(({ globalId, elementName, nodeLoaded, pendingAction }) => (
              <LinkedComponentListItem
                key={globalId}
                globalId={globalId}
                elementName={elementName}
                nodeLoaded={nodeLoaded}
                pendingAction={pendingAction}
                sx={{ pl: 4 }}
              />
            ))}
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ display: 'flex', gap: 2, pt: 3, justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onClickCancel}
          >
            {t('edit-components-dialog_cancel-button-label', 'Cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onClickConfirm}
            disabled={isPending}
          >
            {!!isPending && <CircularProgress size={12} />}
            {t('edit-components-dialog_confirm-button-label', 'Confirm')}
          </Button>
        </DialogActions>
      </Dialog>
      {!!showNestedModelsDialog && interactionMode === ModelsInteractionMode.LinkedComponentManagement && !!intialModelFileIds && (
        <ModelsDialog
          title={t('edit-components-dialog_add-components-dialog-title', 'Add Components')}
          selectedIssueId={dialogSelectedIssueId}
          setSelectedIssueId={setDialogSelectedIssueId}
          initiallySelectedModelFileIds={intialModelFileIds}
        />
      )}
    </>
  );
}
