import React from 'react';
import { Typography } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import HeaderBox from 'common/components/HeaderBox';
import { useTranslation } from 'react-i18next';

interface DashboardPageHeaderProps extends ISxProps {
}

export default function DashboardPageHeader({
  sx,
}: DashboardPageHeaderProps) {
  const { t } = useTranslation('dashboard');
  return (
    <HeaderBox id="DashboardPageHeader" sx={{ px: 4, py: 1, minHeight: 60, display: 'flex', justifyContent: 'space-between', gap: 2, boxShadow: 'inset 2px 2px 4px -2px rgba(0,0,0,0.1), 0px 0px 8px -4px rgba(0,0,0,0.2)', ...sx }}>
      <Typography variant="h2">{t('dashboard-page_title', 'Dashboard')}</Typography>
    </HeaderBox>
  );
}
