import TimeFrame from 'query-filters/types/TimeFrame';
import moment from 'moment';
import { useCallback } from 'react';

export default function useTimeFrameFromToDates() {
  return useCallback((timeFrameValue: TimeFrame) => {
    let fromValue: Date | undefined;
    let toValue: Date | undefined;
    if (timeFrameValue === TimeFrame.Today) {
      fromValue = moment().startOf('day').toDate();
      toValue = moment().endOf('day').toDate();
    } else if (timeFrameValue === TimeFrame.Yesterday) {
      fromValue = moment().startOf('day').subtract(1, 'days').toDate();
      toValue = moment().endOf('day').subtract(1, 'days').toDate();
    } else if (timeFrameValue === TimeFrame.ThisWeek) {
      fromValue = moment().startOf('week').toDate();
      toValue = moment().endOf('week').toDate();
    } else if (timeFrameValue === TimeFrame.LastWeek) {
      fromValue = moment().startOf('week').subtract(1, 'weeks').toDate();
      toValue = moment().endOf('week').subtract(1, 'weeks').toDate();
    } else if (timeFrameValue === TimeFrame.ThisMonth) {
      fromValue = moment().startOf('month').toDate();
      toValue = moment().endOf('month').toDate();
    } else if (timeFrameValue === TimeFrame.LastMonth) {
      fromValue = moment().startOf('month').subtract(1, 'months').toDate();
      toValue = moment().endOf('month').subtract(1, 'months').toDate();
    } else if (timeFrameValue === TimeFrame.Past) {
      toValue = moment().startOf('day').toDate();
    } else if (timeFrameValue === TimeFrame.Tomorrow) {
      fromValue = moment().startOf('day').add(1, 'days').toDate();
      toValue = moment().endOf('day').add(1, 'days').toDate();
    } else if (timeFrameValue === TimeFrame.NextWeek) {
      fromValue = moment().startOf('week').add(1, 'weeks').toDate();
      toValue = moment().endOf('week').add(1, 'weeks').toDate();
    } else if (timeFrameValue === TimeFrame.NextMonth) {
      fromValue = moment().startOf('month').add(1, 'months').toDate();
      toValue = moment().endOf('month').add(1, 'months').toDate();
    } else if (timeFrameValue === TimeFrame.Future) {
      fromValue = moment().startOf('day').add(1, 'days').toDate();
    }
    return { fromValue, toValue };
  }, []);
}
