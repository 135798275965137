import React, { useCallback, useState } from 'react';
import { Box, Button, Modal, Typography, useTheme } from '@mui/material';
import EmailsDataGrid from 'emails/components/EmailsDataGrid';
import EmailsFilterContextProvider from 'emails/contexts/EmailsFilterContextProvider';
import { useTranslation } from 'react-i18next';
import EmailsSearchTextField from 'emails/components/EmailsSearchTextField';
import HorizontalDivider from 'common/styled/HorizontalDivider';

type LinkEmailModalProps = {
  disabledEmailIds?: Set<string> | undefined,
  onClose: (selectedEmailId: string | undefined) => void,
};

export default function LinkEmailModal({
  onClose,
  disabledEmailIds,
}: LinkEmailModalProps) {
  const { t } = useTranslation('issues');
  const theme = useTheme();
  const [selectedEmailId, setSelectedEmailId] = useState<string | undefined>(undefined);
  const onClickCancel = useCallback(() => onClose(undefined), [onClose]);
  const onClickConfirm = useCallback(() => onClose(selectedEmailId), [onClose, selectedEmailId]);
  return (
    <Modal id="LinkEmailModal" open disablePortal sx={{ height: '100%', display: 'flex', alignItems: 'stretch' }}>
      <EmailsFilterContextProvider>
        <Box sx={{
          flex: '1 1 0',
          m: 6,
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: theme.palette.divider,
          borderRadius: 4,
          backgroundColor: theme.palette.background.default,
          display: 'flex',
          flexDirection: 'column',
        }}
        >
          <Box sx={{ flex: '1 1 0', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ flex: '0 0 0', p: 2, gap: 5, display: 'flex', alignItems: 'center' }}>
              <Typography variant="h3">{t('link-email-modal_title', 'Link Email')}</Typography>
              <EmailsSearchTextField />
            </Box>
            <HorizontalDivider />
            <Box sx={{ flex: '1 1 0', overflow: 'hidden', display: 'flex' }}>
              <Box sx={{ flex: '1 1 0', overflow: 'hidden', backgroundColor: '#F7F8FB' }}>
                <EmailsDataGrid disabledEmailIds={disabledEmailIds} selectedEmailId={selectedEmailId} onChangeSelectedEmailId={setSelectedEmailId} />
              </Box>
            </Box>
            <HorizontalDivider />
            <Box sx={{ flex: '0 0 0', display: 'flex', justifyContent: 'flex-end', gap: 4, p: 2 }}>
              <Button variant="contained" color="secondary" onClick={onClickCancel}>{t('link-existing-issue-dialog_cancel-button-label', 'Cancel')}</Button>
              <Button variant="contained" color="primary" onClick={onClickConfirm} disabled={!selectedEmailId} sx={{ minWidth: 120 }}>{t('link-existing-issue-dialog_confirm-button-label', 'Confirm')}</Button>
            </Box>
          </Box>
        </Box>
      </EmailsFilterContextProvider>
    </Modal>
  );
}
