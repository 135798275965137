import ViewpointDto from 'issues/types/ViewpointDto';
import { createContext, Dispatch, SetStateAction } from 'react';

export interface ModelSelectionContextState {
  modelFileIds: string[],
  stagedModelFileIdsSet: Set<string>,
  stageModelFileId: (id: string) => void,
  unstageModelFileId: (id: string) => void,
  loadStagedModelFileIds: () => void,
  loadModelFileIds: (id: string[]) => void,
  stagedModelFileIdsChanged: boolean,
  isLoading: boolean,
  setIsLoading: (value: boolean) => void,
  getLoadingPromise: () => Promise<void>,
  selectedViewpoint: ViewpointDto | undefined,
  setSelectedViewpoint: Dispatch<SetStateAction<ViewpointDto | undefined>>,
  selectedIssueId: string | undefined,
  setSelectedIssueId: (value: string | undefined) => void,
}

const defaultState: ModelSelectionContextState = {
  modelFileIds: [],
  stagedModelFileIdsSet: new Set(),
  stageModelFileId: () => {},
  unstageModelFileId: () => {},
  loadStagedModelFileIds: () => {},
  loadModelFileIds: () => {},
  stagedModelFileIdsChanged: false,
  isLoading: false,
  setIsLoading: () => {},
  getLoadingPromise: () => Promise.resolve(),
  selectedViewpoint: undefined,
  setSelectedViewpoint: () => {},
  selectedIssueId: undefined,
  setSelectedIssueId: () => {},
};

const ModelSelectionContext = createContext<ModelSelectionContextState>(defaultState);
export default ModelSelectionContext;
