import {
  DoubleSide,
  Mesh,
  MeshBasicMaterial,
  PlaneBufferGeometry,
  Texture,
  Vector3,
} from "three";
import { IfcComponent } from "..";
import { VisoplanService } from "../../visoplan-service";
import { VisoStoreyMap } from "../../VisoStoreyMap";
import { IfcContext } from "../context";

export class IfcStorey extends IfcComponent {
  private mesh?: Mesh;
  private texture: Texture;
  private material: MeshBasicMaterial;

  constructor(private context: IfcContext, private service: VisoplanService) {
    super(context);

    this.texture = new Texture();
    this.material = new MeshBasicMaterial({
      map: this.texture,
      side: DoubleSide,
      transparent: true,
      depthTest: false,
      depthWrite: false,
      opacity: 0.3,
    });
  }

  setStorey(storey: VisoStoreyMap, token: any) {
    this.reset();
    const scope = this;

    if (storey.resourceId) {
      if (storey.image) {
        setImage(storey.image);
      } else {
        this.service
          .getResourceStream(storey.resourceId, token)
          .then((res) => {
            const image = this.convertImage(res?.data);
            setImage(image);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }

    function setImage(image: string) {
      const img = new Image();
      img.onload = function () {
        scope.texture.image = img;
        scope.texture.needsUpdate = true;
      };

      img.src = image;

      const geometry = new PlaneBufferGeometry(storey.size.x, storey.size.y);

      scope.mesh = new Mesh(geometry, scope.material);
      scope.mesh.lookAt(0, 1, 0);

      const pos = new Vector3(
        storey.center.x,
        storey.center.z - 0.5 * storey.size.z,
        -storey.center.y
      );
      scope.mesh.position.copy(pos);

      scope.context.getScene().add(scope.mesh);
    }
  }

  dispose(): void {
    this.reset();

    this.texture.dispose();
    this.material.dispose();
  }

  reset() {
    if (this.mesh) {
      if (this.mesh.parent) this.mesh.removeFromParent();
      this.mesh.geometry.dispose();

      this.mesh = undefined;
    }
  }

  private convertImage(data: any) {
    let binary = "";
    const bytes = new Uint8Array(data);
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    return `data:image/png;base64,${btoa(binary)}`;
  }
}
