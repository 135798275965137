import React, { useEffect, useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import Loader from 'Components/Loader';
import useVisoplanApiContext from 'api/hooks/useVisoplanApiContext';

type TokenUrlParams = 'tokenHeader' | 'tokenPayload' | 'tokenSignature' | 'route';

export default function AutoLoginContainer() {
  const { isSignedIn, signInUsingTokenBearer } = useVisoplanApiContext();
  const {
    tokenHeader,
    tokenPayload,
    tokenSignature,
    route,
  } = useParams<TokenUrlParams>();

  const to = useMemo(() => (route ? `/${route.replaceAll('-', '/')}` : undefined), [route]);

  useEffect(() => {
    if (isSignedIn === false && tokenHeader && tokenPayload && tokenSignature) {
      signInUsingTokenBearer(`${tokenHeader}.${tokenPayload}.${tokenSignature}`);
    }
  }, [isSignedIn, signInUsingTokenBearer, tokenHeader, tokenPayload, tokenSignature]);

  if (isSignedIn && to) return <Navigate to={to} />;
  return <Loader isLoading />;
}
