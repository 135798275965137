import { Color, GridHelper, Vector3 } from "three";
import { IfcComponent } from "../../base-types";
import { IfcContext } from "../context";

const VISOGRIDCOLOR = "#4b6ffc";

export class IfcGrid extends IfcComponent {
  grid?: GridHelper;
  private isVisible = false;

  constructor(private context: IfcContext) {
    super(context);
  }

  setGrid(
    size?: number,
    divisions?: number,
    colorCenterLine?: Color,
    colorGrid?: Color
  ) {
    this.dispose();

    this.grid = new GridHelper(size, divisions, colorCenterLine, colorGrid);

    this.addGrid();
  }

  setVisible(visible: boolean) {
    if (this.isVisible == visible) return;
    this.isVisible = visible;
    this.handleGrid();
  }

  handleGrid() {
    this.dispose();

    if (this.isVisible) {
      const boxSize = this.context.boundingBox.getSize(new Vector3());
      let size = Math.round(Math.max(boxSize.x, boxSize.z));
      size = size ? size + 4 : 10;
      this.grid = new GridHelper(size, size, VISOGRIDCOLOR, VISOGRIDCOLOR);

      const center = this.context.boundingBox.getCenter(new Vector3());
      center.setY(0);
      this.grid.position.copy(center);

      this.addGrid();
    }
  }

  dispose(): void {
    if (this.grid) {
      if (this.grid.parent) this.grid.removeFromParent();
      this.grid.geometry.dispose();
      this.grid = undefined;
    }
  }

  private addGrid() {
    if (this.grid) {
      this.grid.renderOrder = 0;
      const scene = this.context.getScene();
      scene.add(this.grid);
    }
  }
}
