import { createContext } from 'react';
import UploadingEmailFileHandle from 'emails/types/UploadingEmailFileHandle';
import EmailsUploadPayload from 'emails/types/EmailsUploadPayload';

export interface EmailsUploadContextState {
  stagedPayload: EmailsUploadPayload | null;
  uploadingFileHandles: UploadingEmailFileHandle[];
  addToEmailsStagedForUpload: (payload: EmailsUploadPayload) => void;
  startUploadingStagedPayload: () => void;
  discardStagedFiles: (fileNames: string[]) => void;
  clear: () => void;
  openWizard: () => void;
}

const defaultState: EmailsUploadContextState = {
  stagedPayload: null,
  uploadingFileHandles: [],
  addToEmailsStagedForUpload: () => {},
  startUploadingStagedPayload: () => {},
  discardStagedFiles: () => {},
  clear: () => {},
  openWizard: () => {},
};

const EmailsUploadContext = createContext<EmailsUploadContextState>(defaultState);
export default EmailsUploadContext;
