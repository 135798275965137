import React from 'react';
import { Box, useTheme } from '@mui/material';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import useProjectIssueStatusesQuery from 'issues/hooks/useProjectIssueStatusesQuery';
import IssuesBoardColumn from 'issues/components/IssuesBoardColumn';
import IssuesBoardDragAndDropContextProvider from 'issues/contexts/IssuesBoardDragAndDropContextProvider';

export default function IssuesBoard() {
  const theme = useTheme();
  const { data: issueStatuses } = useProjectIssueStatusesQuery();

  if (!issueStatuses) return <CenteredCircularProgress />;

  return (
    <Box
      id="IssuesBoard"
      sx={{
        flexGrow: 1,
        display: 'grid',
        gridTemplateRows: '1fr',
        p: 2,
        background: theme.palette.background.default,
        overflow: 'auto',
        boxShadow: 'inset 2px 2px 4px -2px rgba(0,0,0,0.1)',
      }}
    >
      <Box sx={{
        flexGrow: 1,
        flexShrink: 0,
        display: 'grid',
        gridTemplateColumns: `repeat(${issueStatuses.length}, minmax(260px, 1fr))`,
        columnGap: 2,
      }}
      >
        <IssuesBoardDragAndDropContextProvider>
          {issueStatuses.map((status) => <IssuesBoardColumn key={status.id} status={status} />)}
        </IssuesBoardDragAndDropContextProvider>
      </Box>
    </Box>
  );
}
