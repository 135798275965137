import TimeFrame from 'query-filters/types/TimeFrame';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function useTimeFrameTranslations() {
  const { t } = useTranslation('common');

  type TimeFrameTranslations = {
    [key in TimeFrame]: string;
  };

  return useMemo<TimeFrameTranslations>(() => ({
    [TimeFrame.Today]: t('use-time-frame-translations_today', 'Today'),
    [TimeFrame.Yesterday]: t('use-time-frame-translations_yesterday', 'Yesterday'),
    [TimeFrame.ThisWeek]: t('use-time-frame-translations_this-week', 'This Week'),
    [TimeFrame.LastWeek]: t('use-time-frame-translations_last-week', 'Last Week'),
    [TimeFrame.ThisMonth]: t('use-time-frame-translations_this-month', 'This Month'),
    [TimeFrame.LastMonth]: t('use-time-frame-translations_last-month', 'Last Month'),
    [TimeFrame.Past]: t('use-time-frame-translations_past', 'Past'),
    [TimeFrame.Tomorrow]: t('use-time-frame-translations_tomorrow', 'Tomorrow'),
    [TimeFrame.NextWeek]: t('use-time-frame-translations_next-week', 'Next Week'),
    [TimeFrame.NextMonth]: t('use-time-frame-translations_next-month', 'Next Month'),
    [TimeFrame.Future]: t('use-time-frame-translations_future', 'Future'),
  }), [t]);
}
