import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, SxProps, Theme, Typography, useTheme } from '@mui/material';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useFolder from 'documents-folders/hooks/useFolder';
import Folder from 'documents-folders/types/Folder';
import ISxProps from 'common/types/ISxProps';
import useDocumentsViewNavigationContext from 'documents/hooks/useDocumentsViewNavigationContext';

interface FolderBreadcrumbsProps extends ISxProps {
  folderId: string | undefined,
  isLeafFolderClickable?: boolean,
  reverse?: boolean,
  suppressInteraction?: boolean,
  leafNodeSx?: SxProps<Theme>,
}

export default function FolderBreadcrumbs({
  sx,
  folderId,
  isLeafFolderClickable,
  reverse,
  suppressInteraction,
  leafNodeSx,
}: FolderBreadcrumbsProps) {
  const theme = useTheme();
  const { setDocumentScope } = useDocumentsViewNavigationContext();
  const folder = useFolder(folderId);
  const listWrapperRef = useRef<HTMLDivElement>(null);

  const ancestorFoldersAndSelf = useMemo(() => {
    const result: Folder[] = [];
    if (folder) {
      let pivot: Folder | undefined = folder;
      while (pivot) {
        result.push(pivot);
        pivot = pivot?.parent;
      }
    }
    return result.reverse();
  }, [folder]);

  const [visibleFolderIdsSet, setVisibleFolderIdsSet] = useState<ReadonlySet<string>>(() => new Set<string>());
  const intersectionObserver = useMemo(() => new IntersectionObserver((entries) => {
    setVisibleFolderIdsSet((prev) => {
      const nextVisibleFolderIdsSet = new Set<string>(Array.from(prev));
      entries.forEach((e) => {
        const dataId = e.target.getAttribute('data-id');
        if (dataId) {
          if (e.isIntersecting) nextVisibleFolderIdsSet.add(dataId);
          else nextVisibleFolderIdsSet.delete(dataId);
        }
      });
      return nextVisibleFolderIdsSet;
    });
  }, { root: listWrapperRef.current }), []);
  const itemsMapRef = useRef<Map<string, HTMLDivElement> | null>(null);
  const getItemsMap = useCallback(() => {
    // Initialize the Map on first usage.
    if (!itemsMapRef.current) itemsMapRef.current = new Map<string, HTMLDivElement>();
    return itemsMapRef.current;
  }, []);
  const itemRefCallback = useCallback((id: string, node: HTMLDivElement | null) => {
    const map = getItemsMap();
    if (node && map) {
      map.set(id, node);
      intersectionObserver.observe(node); // when a new chip is selected, start observing it's visibility
    } else {
      map.delete(id);
    }
  }, [getItemsMap, intersectionObserver]);
  useEffect(() => {
    if (!itemsMapRef.current) return undefined;
    const itemNodes = Array.from(itemsMapRef.current.values());
    if (!itemNodes || !listWrapperRef.current) return undefined;
    itemNodes.forEach((n) => intersectionObserver.observe(n));
    return () => itemNodes.forEach((n) => intersectionObserver.unobserve(n));
  }, [intersectionObserver]);

  const leafFolder = useMemo(() => (ancestorFoldersAndSelf[ancestorFoldersAndSelf.length - 1]), [ancestorFoldersAndSelf]);
  if (ancestorFoldersAndSelf.length === 0) return <Box />;
  return (
    <Box sx={{
      ...sx, display: 'flex', alignItems: 'center', flexDirection: reverse ? 'row-reverse' : 'row', justifyContent: reverse ? 'flex-end' : 'flex-start',
    }}
    >
      <Box
        ref={listWrapperRef}
        sx={{
          display: 'flex', height: '33px', alignItems: 'center', overflow: 'hidden', boxSizing: 'border-box', flexDirection: reverse ? 'row-reverse' : 'row', flexShrink: 1,
        }}
      >
        {ancestorFoldersAndSelf.map((folderItem, index) => (
          index < ancestorFoldersAndSelf.length - 1 && (
          <Box
            sx={{
              display: 'flex', alignItems: 'center', flexShrink: 0, flexDirection: reverse ? 'row-reverse' : 'row',
            }}
            key={folderItem.id}
            data-id={folderItem.id}
            component="div"
            ref={(node) => itemRefCallback(folderItem.id, node as HTMLDivElement)}
          >
            {!suppressInteraction && (
            <Button
              variant="text"
              onClick={() => setDocumentScope({ key: DocumentScopeKey.Folder, id: folderItem.id })}
              disabled={!folderItem.hasFolderAccess}
              sx={{
                color: theme.palette.text.primary,
                fontWeight: 400,
                px: 1,
                minWidth: 'unset',
                textOverflow: 'ellipsis',
              }}
            >
              {folderItem.name}
            </Button>
            )}
            {suppressInteraction && (
            <Typography
              sx={{
                color: theme.palette.text.primary,
                fontWeight: 400,
                px: 1,
                minWidth: 'unset',
                textOverflow: 'ellipsis',
              }}
            >
              {folderItem.name}
            </Typography>
            )}
            {reverse ? <ChevronLeftIcon sx={{ my: 'auto' }} /> : <ChevronRightIcon sx={{ my: 'auto' }} />}
          </Box>
          )))}
      </Box>
      {visibleFolderIdsSet.size < ancestorFoldersAndSelf.length - 1 && (
        <>
          {reverse ? <ChevronLeftIcon sx={{ my: 'auto' }} /> : <ChevronRightIcon sx={{ my: 'auto' }} />}
          <Typography sx={{ color: theme.palette.text.primary, pl: 0.5 }}>…</Typography>
          {reverse ? <ChevronLeftIcon sx={{ my: 'auto' }} /> : <ChevronRightIcon sx={{ my: 'auto' }} />}
        </>
      )}
      {!isLeafFolderClickable || suppressInteraction
        ? (
          <Typography sx={{
            color: theme.palette.text.primary, fontWeight: 700, pl: 0.5, pr: 1, flexShrink: 0, ...leafNodeSx,
          }}
          >
            {leafFolder.name}
          </Typography>
        )
        : (
          <Button
            variant="text"
            onClick={() => setDocumentScope({ key: DocumentScopeKey.Folder, id: leafFolder.id })}
            sx={{
              color: theme.palette.text.primary,
              fontWeight: 600,
              flexShrink: 0,
              px: 1,
              minWidth: 'unset',
              ...leafNodeSx,
            }}
          >
            {leafFolder.name}
          </Button>
        )}
    </Box>
  );
}
