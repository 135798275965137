import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useEditor } from '@tiptap/react';
import IChildren from 'common/types/IChildren';
import RichTextEditorContext, { RichTextEditorContextState } from 'rich-text/contexts/RichTextEditorContext';
import Mention from '@tiptap/extension-mention';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import MentionList from 'rich-text/components/MentionList';
import useMentionSuggestion from 'rich-text/hooks/useMentionSuggestion';
import { useTheme } from '@mui/material';

const initialContent = '<p></p>';

export default function RichTextEditorContextProvider({
  children,
}: IChildren) {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [text, setText] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { suggestion, suggestionState, onCancel } = useMentionSuggestion();
  const theme = useTheme();

  const editor = useEditor({
    extensions: [
      Document,
      Paragraph.configure({
        HTMLAttributes: {
          style: 'margin: 0;',
        },
      }),
      Text,
      Mention.configure({
        HTMLAttributes: {
          class: 'mention',
          style: `color: ${theme.palette.secondary.contrastText}`,
        },
        suggestion,
      }),
    ],
    content: initialContent,
    editorProps: {
      attributes: {
        class: 'custom-scrollbar',
        style: 'height: 100%; overflow-y: auto;',
      },
    },
    editable: true,
    onUpdate: ({ editor: e }) => {
      setText(e.getText({
        textSerializers: {
          mention: ({ node }) => {
            const idAttr: string = node.attrs.id;
            const [mentionKind, mentionedId] = idAttr.split('_');
            return `@${mentionKind}[${node.attrs.label}](${mentionedId})`;
          },
        },
      }));
    },
    onFocus: ({ editor: e }) => {
      setIsFocused(e.isFocused);
    },
    onBlur: ({ editor: e }) => {
      setIsFocused(e.isFocused);
    },
  }, [suggestion, theme]);

  useEffect(() => {
    editor?.setEditable(!isDisabled);
  }, [editor, isDisabled]);

  const state = useMemo<RichTextEditorContextState>(() => ({
    editor,
    isFocused,
    isDisabled,
    text,
    setIsDisabled,
  }), [text, editor, isDisabled, isFocused]);

  return (
    <RichTextEditorContext.Provider value={state}>
      {!!suggestionState && <MentionList suggestionState={suggestionState} onCancel={onCancel} />}
      {children}
    </RichTextEditorContext.Provider>
  );
}
