import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { IconButton, InputAdornment, TextField, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import useDocumentFilterContext from 'documents/hooks/useDocumentFilterContext';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import ISxProps from 'common/types/ISxProps';
import useDocumentsViewNavigationContext from 'documents/hooks/useDocumentsViewNavigationContext';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    paddingRight: theme.spacing(0.5),
  },
}));

interface DocumentsSearchTextFieldProps extends ISxProps {
}

export default function DocumentsSearchTextField({
  sx,
}: DocumentsSearchTextFieldProps) {
  const { t } = useTranslation('documents');
  const {
    searchString, setSearchString, lastDocumentScopeBeforeFilterBecameActive, isAppliedFilterStateEmpty,
  } = useDocumentFilterContext();
  const { documentScope, setDocumentScope } = useDocumentsViewNavigationContext();
  const [searchStringBufferDisplayValue, setSearchStringDisplayValue] = useState(searchString ?? '');

  useEffect(() => {
    setSearchStringDisplayValue(searchString ?? '');
  }, [searchString]);

  useEffect(() => {
    if (documentScope.key !== DocumentScopeKey.Filter) {
      setSearchStringDisplayValue('');
      setSearchString(undefined);
    }
  }, [documentScope.key, setSearchString]);

  const debouncedSetSearchString = useMemo(() => debounce((value: string | undefined) => {
    setSearchString(value);
  }, 600), [setSearchString]);

  const onChangeSearchString = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (documentScope.key !== DocumentScopeKey.Filter) {
      setDocumentScope({ key: DocumentScopeKey.Filter });
    }
    setSearchStringDisplayValue(e.target.value);
    debouncedSetSearchString(e.target.value || undefined);
  }, [debouncedSetSearchString, documentScope, setDocumentScope]);

  const onResetSearchString = useCallback(() => {
    setSearchString(undefined);
    if (isAppliedFilterStateEmpty) {
      setDocumentScope(lastDocumentScopeBeforeFilterBecameActive ?? { key: DocumentScopeKey.ProjectDocuments });
    }
  }, [isAppliedFilterStateEmpty, lastDocumentScopeBeforeFilterBecameActive, setDocumentScope, setSearchString]);

  const IconComponent = useMemo(() => (searchString?.length ? CloseIcon : SearchIcon), [searchString?.length]);

  return (
    <StyledTextField
      id="DocumentsSearchTextField"
      label={t('documents-search-text-field-label', 'Search Document')}
      value={searchStringBufferDisplayValue}
      onChange={onChangeSearchString}
      sx={{ ...sx, width: 400, ml: 3 }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={onResetSearchString}
              disabled={!searchString?.length}
              sx={{ p: 0.5 }}
            >
              <IconComponent color="action" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
