import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import useVisoplanApiContext from 'api/hooks/useVisoplanApiContext';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import IChildren from 'common/types/IChildren';
import useSync from 'api/hooks/useSync';
import LegacySettingsNotificationDialogContextProvider from 'settings/contexts/LegacySettingsNotificationDialogContextProvider';
import useCurrentUserQuery from 'users/hooks/useCurrentUserQuery';
import ApiEndpoint from 'api/types/ApiEndpoint';
import axios from 'axios';
import TwoFactorAuthRequiredWizard from 'users/components/TwoFactorAuthRequiredWizard';
import moment from 'moment';

function SyncProvider({ children }: IChildren) {
  useSync();
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

const TOKEN_REFRESH_MIN_AGE_SECONDS = 3600; // max 1 refresh on click per hour

export default function AuthenticatedRootLayout() {
  const { projectId: routeProjectId } = useParams<'projectId'>();
  const { isSignedIn, openedProjectId, openProject, leaveProject, authProjectToken, authUserToken, refreshToken, signOut } = useVisoplanApiContext();
  const { data: currentUser } = useCurrentUserQuery();
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const openProjectRef = useRef<string | undefined>(undefined);
  const [twoFactorAuthRequiredWizardOpen, setTwoFactorAuthRequiredWizardOpen] = useState(false);

  const tokenRefreshedDateRef = useRef(new Date());

  // logout timer => sign out when the token expires
  useEffect(() => {
    const expires = authProjectToken?.expires ?? authUserToken?.expires;
    if (expires) {
      const remainingMilliseconds = moment(expires).diff(moment(), 'milliseconds');
      if (remainingMilliseconds > 2147483640) return () => {}; // remaining milliseconds exceeds maximum delay of setTimeout (about 24.8 days) => don't set a timer
      const id = setTimeout(() => {
        signOut();
      }, remainingMilliseconds);
      return () => {
        clearTimeout(id);
      };
    }
    return () => {};
  }, [authProjectToken, authUserToken, signOut]);

  // if the current token is old enough, reset the logout timer on any click by requesting a new token
  useEffect(() => {
    const handler = async () => {
      const age = moment().diff(moment(tokenRefreshedDateRef.current), 'seconds');
      if (!tokenRefreshedDateRef.current || age > TOKEN_REFRESH_MIN_AGE_SECONDS) {
        refreshToken(routeProjectId);
        tokenRefreshedDateRef.current = new Date();
      }
    };
    window.addEventListener('click', handler);
    return () => {
      window.removeEventListener('click', handler);
    };
  }, [refreshToken, routeProjectId]);

  const updateOpenedProject = useCallback(async () => {
    if (!currentUser) return;
    if (!routeProjectId && openProjectRef.current) {
      // the app is not in a project but we have a project token => leave project
      openProjectRef.current = undefined;
      await leaveProject();
      queryClient.resetQueries({ predicate: (query) => query.queryKey[0] !== ApiEndpoint.User });
      return;
    }
    if (routeProjectId && !openProjectRef.current) {
      if (routeProjectId && !openProjectRef.current) {
        // the app is in a project but we don't have a project token (or the wrong one) => open project
        try {
          openProjectRef.current = routeProjectId;
          await openProject(routeProjectId);
        } catch (error) {
          if (axios.isAxiosError(error) && error.response?.status === 403) {
            // this project requires two factor authentication but the current token was not created using two factor authentication
            if (currentUser.twoFactorEnabled) {
              navigate('/login');
            } else {
              setTwoFactorAuthRequiredWizardOpen(true);
            }
          } else {
            navigate('/projects'); // if opening the project from the URL fails, navigate to project list
          }
        }
      }
    }
  }, [currentUser, leaveProject, navigate, openProject, queryClient, routeProjectId]);

  const onCloseTwoFactorAuthRequriedWizard = useCallback((confirmed: boolean) => {
    setTwoFactorAuthRequiredWizardOpen(false);
    if (confirmed) {
      updateOpenedProject();
    } else {
      navigate('/projects');
    }
  }, [navigate, updateOpenedProject]);

  useEffect(() => {
    if (!currentUser) return; // init
    if (isSignedIn === false) {
      queryClient.clear();
      return;
    }
    updateOpenedProject(); // make sure we've got the correct project token for the projectId from the URL
  }, [currentUser, updateOpenedProject, isSignedIn, queryClient, routeProjectId, openedProjectId]);

  useEffect(() => {
    if (openedProjectId === undefined) return;
    openProjectRef.current = openedProjectId ?? undefined;
  }, [openedProjectId]);

  if (isSignedIn === true) {
    return (
      <SyncProvider>
        {((openedProjectId || undefined) === (routeProjectId || undefined)) && (
          <LegacySettingsNotificationDialogContextProvider>
            <Outlet />
          </LegacySettingsNotificationDialogContextProvider>
        )}
        {!!twoFactorAuthRequiredWizardOpen && (
          <TwoFactorAuthRequiredWizard onClose={onCloseTwoFactorAuthRequriedWizard} />
        )}
      </SyncProvider>
    );
  }

  if (isSignedIn === false) return <Navigate to="/login" replace state={{ from: location }} />;
  return <CenteredCircularProgress />;
}
