import React from "react";
import { getGeneralSvg } from "../SVGs";

export default function IconButton({ className = "", iconId, title, onClick }) {
  return (
    <button className={`icon-btn ${className}`} onClick={onClick}>
      {getGeneralSvg(iconId)}
      {title}
    </button>
  );
}
