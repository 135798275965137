import React, { useMemo } from 'react';
import { Box, Button } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import { useTranslation } from 'react-i18next';
import useCurrentCollaboratorQuery from 'collaborators/hooks/useCurrentCollaboratorQuery';
import useDraftIssueStatus from 'issues/hooks/useDraftIssueStatus';
import { mdiSend } from '@mdi/js';
import Icon from '@mdi/react';

interface PublishActionButtonsProps extends ISxProps {
  issueId: string,
  onClickPublish: () => void,
}

export default function PublishActionButtons({
  sx,
  issueId,
  onClickPublish,
}: PublishActionButtonsProps) {
  const { t } = useTranslation('issues');
  const { data: currentCollaborator } = useCurrentCollaboratorQuery();
  const { data: issue, isLoading: isLoadingIssue } = useIssueQuery(issueId);
  const isCurrentUserCreator = useMemo(() => (issue?.createAuthorId && currentCollaborator && issue.createAuthorId === currentCollaborator.id), [currentCollaborator, issue]);
  const draftIssueStatus = useDraftIssueStatus();

  const canDoPublishActions = useMemo(() => {
    if (!issue?.issueStatus || !draftIssueStatus) return undefined;
    return issue.issueStatus === draftIssueStatus.id && isCurrentUserCreator;
  }, [isCurrentUserCreator, issue, draftIssueStatus]);

  if (!issueId || !canDoPublishActions) return null;
  return (
    <Box id="PublishActionButtons" sx={{ ...sx }}>
      <Button
        variant="contained"
        color="secondary"
        onClick={onClickPublish}
        disabled={!canDoPublishActions || isLoadingIssue}
        sx={{ gap: 1 }}
        size="small"
      >
        <Icon path={mdiSend} size={0.5} />
        {t('publish-action-buttons_publish', 'Assign and publish')}
      </Button>
    </Box>
  );
}
