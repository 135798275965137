import React, { useMemo } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import useCurrentCollaboratorRole from 'collaborators/hooks/useCurrentCollaboratorRole';
import RoleAction from 'projects/types/RoleAction';
import ProjectSettingsProjectEmailAddressSection from 'settings/components/ProjectSettingsProjectEmailAddressSection';
import ProjectSettingsTwoFactorAuthSection from 'settings/components/ProjectSettingsTwoFactorAuthSection';
import ProjectAdvancedSettingsSection from 'settings/components/ProjectAdvancedSettingsSection';
import useCurrentCollaboratorQuery from 'collaborators/hooks/useCurrentCollaboratorQuery';
import useCollaboratorsQuery from 'collaborators/hooks/useCollaboratorsQuery';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';

export default function ProjectSettingsTabPane() {
  const { t } = useTranslation('settings');
  const currentUserRole = useCurrentCollaboratorRole();
  const { data: currentCollaborator } = useCurrentCollaboratorQuery();
  const { data: collaborators } = useCollaboratorsQuery();
  const { data: currentProject } = useCurrentProjectQuery();

  const creatorIsInProject = useMemo(() => {
    if (!currentProject || !currentCollaborator || !collaborators) return undefined;
    const creator = collaborators.find((c) => c.id === currentProject.creatorId);
    if (!creator) return false;
    return creator.isDeleted;
  }, [collaborators, currentCollaborator, currentProject]);

  const canDelete = useMemo(() => {
    if (!currentProject || !currentCollaborator || !currentUserRole) return undefined;
    if (currentProject.creatorId === currentCollaborator.id) return true;
    if (creatorIsInProject && currentUserRole.isAdmin) return true;
    return false;
  }, [creatorIsInProject, currentCollaborator, currentUserRole, currentProject]);

  if (!currentUserRole) return <CenteredCircularProgress />;
  return (
    <Box
      id="ProjectSettingsTabPane"
      sx={{
        height: '100%',
        py: 4,
        pr: 4,
        display: 'grid',
        gridTemplateColumns: 'minmax(auto, 200px) auto 4fr',
      }}
    >
      <Box />
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 1000,
        minWidth: 600,
        gap: 6,
        py: 4,
        px: 8,
      }}
      >
        <Box>
          <Typography variant="h2" sx={{ mb: 1, mr: 'auto' }}>{t('project-settings-tab-pane_title', 'Project Settings')}</Typography>
        </Box>
        {!!currentUserRole.allowedActions?.has(RoleAction.Project_Edit_2Fa) && (
          <>
            <Divider />
            <ProjectSettingsTwoFactorAuthSection />
          </>
        )}
        {!!currentUserRole.allowedActions?.has(RoleAction.Inbox_Edit) && (
          <>
            <Divider />
            <ProjectSettingsProjectEmailAddressSection />
          </>
        )}
        {canDelete && (
        <>
          <Divider />
          <ProjectAdvancedSettingsSection />
        </>
        )}
      </Box>
    </Box>
  );
}
