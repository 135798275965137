import React, { useEffect, useMemo, useState } from 'react';
import { TextField } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useUserByEmailQuery from 'users/hooks/useUserByEmailQuery';
import CollaboratorDto from 'collaborators/types/CollaboratorDto';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

interface CollaboratorSearchByEmailTextFieldProps extends ISxProps {
  text: string,
  onChangeText: (nextValue: string) => void,
  onChangeCollaborator: (collaborator: CollaboratorDto | undefined) => void,
  helperText?: string | undefined,
  error?: boolean | undefined,
}

const validEmailRegex = /^.+@.+$/i;

export default function CollaboratorSearchByEmailTextField({
  text,
  onChangeText,
  onChangeCollaborator,
  sx,
  helperText,
  error,
}: CollaboratorSearchByEmailTextFieldProps) {
  const { t } = useTranslation('collaborators');
  const [searchString, setSearchString] = useState<string>('');
  const debouncedSetSearchString = useMemo(() => debounce((value: string) => {
    setSearchString(value);
  }, 600), [setSearchString]);
  const isSearchStringValidEmail = useMemo(() => !!searchString.match(validEmailRegex), [searchString]);
  const { data, isError: isUserNotFoundError } = useUserByEmailQuery(isSearchStringValidEmail ? searchString : undefined);
  useEffect(() => {
    onChangeCollaborator(data);
  }, [onChangeCollaborator, data]);
  useEffect(() => {
    if (!text.length) setSearchString('');
    else debouncedSetSearchString(text);
  }, [debouncedSetSearchString, text]);

  const userNotFoundHelperText = useMemo(() => (isUserNotFoundError && validEmailRegex ? t('collaborator-search-by-email-textfield_user-not-found-error-message', 'User does not exist in Visoplan. Please contact support@visoplan.de to and ask to create a new user.') : undefined), [isUserNotFoundError, t]);

  return (
    <TextField
      id="CollaboratorSearchByEmailTextField"
      label={t('collaborator-search-by-email-text-field_label', 'Add Collaborator')}
      value={text}
      onChange={(e) => onChangeText(e.target.value)}
      sx={sx}
      error={error || !!userNotFoundHelperText}
      helperText={[helperText, userNotFoundHelperText].join('')}
    />
  );
}
