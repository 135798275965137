import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { createPortal } from 'react-dom';
import ISxProps from 'common/types/ISxProps';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDocumentViewerContext from 'documents-details/hooks/useDocumentViewerContext';
import { useTranslation } from 'react-i18next';
import useStampInsertionDownloadFunction from 'documents-details/hooks/useStampInsertionDownloadFunction';
import useDocumentVersionNumberString from 'documents/hooks/useDocumentVersionNumberString';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import LabelDto from 'labels/types/LabelDto';
import buildQuery from 'odata-query';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import useDocumentVersionQueryData from 'documents/hooks/useDocumentVersionQueryData';
import useLabelQueryData from 'labels/hooks/useLabelQueryData';

export enum CompareMode {
  Text = 'text',
  Image = 'image',
}

interface DocumentViewerProps extends ISxProps {
}

export default function DocumentViewer({
  sx,
}: DocumentViewerProps) {
  const { t, i18n } = useTranslation('documents-details');
  const theme = useTheme();
  const viewerRef = useRef<HTMLDivElement>(null);
  const {
    webViewerInstance,
    issueIdCurrentlyLinking,
    compareDocumentVersionId,
    initializeViewer,
    disposeViewer,
  } = useDocumentViewerContext();
  const customHeaderItemsRef = useRef<HTMLElement>(document.createElement('div'));

  const initializedRef = useRef<boolean>(false);
  useEffect(() => {
    if (initializedRef.current) return undefined;
    if (viewerRef.current && customHeaderItemsRef) {
      initializeViewer(viewerRef.current, customHeaderItemsRef.current);
    }
    initializedRef.current = true;
    return () => {
      disposeViewer();
    };
  }, [disposeViewer, initializeViewer]);

  const [includeLocationsInDownload, setIncludeLocationsInDownload] = useState<boolean>(false);
  const getVersionString = useDocumentVersionNumberString();

  const { data: currentProject } = useCurrentProjectQuery();
  const axiosInstance = useAxiosInstance();
  const getStampInsertionDownloadFunction = useStampInsertionDownloadFunction();
  const getDocumentVersion = useDocumentVersionQueryData();

  const getLabel = useLabelQueryData();

  const onClickDownload = useCallback(async () => {
    if (!webViewerInstance || !currentProject) return;
    const doc = webViewerInstance.Core.documentViewer.getDocument();

    const docId = doc.getDocumentId();
    const documentVersion = await getDocumentVersion(docId);

    // if enabled, insert a document stamp on the downloaded document by setting a custom doc.getFileData
    if (currentProject.stampSettings?.isStampInsertionOnDownloadEnabled) {
      const { locale } = currentProject.stampSettings;
      const versionString = `${getVersionString(documentVersion.versionNumber)}`;
      let statusString = '';
      if (documentVersion.status) {
        if (locale === i18n.language) {
          const statusLabel = await getLabel(documentVersion.status);
          statusString = statusLabel.name;
        } else {
          // download status in the language that was configured in document settings.
          const odataQueryString = buildQuery<LabelDto>({ filter: { id: documentVersion.status } });
          const { data } = await axiosInstance.get<LabelDto[]>(`/${ApiEndpoint.Label}${odataQueryString}`, { headers: { 'Content-Language': locale } });
          statusString = data[0].name;
        }
      }
      doc.getFileData = getStampInsertionDownloadFunction(webViewerInstance, statusString, versionString);
    }

    let xfdfString: string | undefined;
    // If the user selected to NOT include visoplan anntations in the download, we generate an XFDF override with only the original annotations.
    if (!includeLocationsInDownload) {
      const hasInternalAnnotations = webViewerInstance.Core.annotationManager.getAnnotationsList().some((a) => a.isInternal());
      if (hasInternalAnnotations) {
        // This extracts a XFDF string for the doc's internal annotations (i.e. those annotations that were in it when it was uploaded, without visoplan's issue locations)
        const xfdfInfo = await doc.extractXFDF();
        xfdfString = xfdfInfo.xfdfString;
      } else {
        xfdfString = '<?xml version="1.0" encoding="UTF-8"?><xfdf xmlns="http://ns.adobe.com/xfdf/" xml:space="preserve"><annots></annots></xfdf>';
      }
    }
    webViewerInstance.UI.downloadPdf({ xfdfString });
  }, [axiosInstance, currentProject, getDocumentVersion, getLabel, getStampInsertionDownloadFunction, getVersionString, i18n.language, includeLocationsInDownload, webViewerInstance]);

  const onChangeIncludeLocationsInDownload = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setIncludeLocationsInDownload(event.target.checked);
  }, [setIncludeLocationsInDownload]);

  return (
    <>
      <Box
        id="DocumentViewer"
        sx={{
          ...sx,
          ...(issueIdCurrentlyLinking ? { borderWidth: 4, borderStyle: 'solid', borderColor: theme.palette.info.main } : {}),
        }}
        ref={viewerRef}
      />
      {/* TODO: display the circular progress while loading a document */}
      {!webViewerInstance && <CenteredCircularProgress />}
      {!!customHeaderItemsRef.current && createPortal(
        !issueIdCurrentlyLinking && (
        <div
          id="DocumentViewerDownloadButton"
          style={{
            padding: '4px 16px',
            display: 'flex',
            gap: '16px',
          }}
        >
          <button
            type="button"
            onClick={onClickDownload}
            className="viso-webviewer-button secondary"
          >
            {t('document-viewer_download-button-label', 'Download')}
          </button>
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <input
              type="checkbox"
              id="include-locations-in-download-checkbox"
              checked={!compareDocumentVersionId && includeLocationsInDownload}
              onChange={onChangeIncludeLocationsInDownload}
              style={compareDocumentVersionId ? {} : { cursor: 'pointer' }}
              disabled={!!compareDocumentVersionId}
            />
            <label
              htmlFor="include-locations-in-download-checkbox"
              style={compareDocumentVersionId ? { color: theme.palette.text.disabled } : { cursor: 'pointer' }}
            >
              {t('document-viewer_include-locations-in-download-checkbox-label', 'with locations')}
            </label>
          </div>
        </div>
        ),
        customHeaderItemsRef.current,
      )}
    </>
  );
}
