import { createContext } from 'react';
import DocumentUploadPayload from '../types/DocumentUploadPayload';
import UploadingFileHandle from '../types/UploadingFileHandle';

export interface DocumentUploadContextState {
  stagedPayload: DocumentUploadPayload | null;
  uploadingFileHandles: UploadingFileHandle[];
  addToDocumentsStagedForUpload: (payload: DocumentUploadPayload) => void;
  startUploadingStagedPayload: () => void;
  discardStagedFiles: (fileNames: string[]) => void;
  clear: () => void;
  openWizard: () => void;
}

const defaultState: DocumentUploadContextState = {
  stagedPayload: null,
  uploadingFileHandles: [],
  addToDocumentsStagedForUpload: () => {},
  startUploadingStagedPayload: () => {},
  discardStagedFiles: () => {},
  clear: () => {},
  openWizard: () => {},
};

const DocumentUploadContext = createContext<DocumentUploadContextState>(defaultState);
export default DocumentUploadContext;
