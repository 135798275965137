import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';

import * as Utils from '../../Helpers/utils';

const MenuList = ({ children, ...rest }) => {
  return (
    <components.MenuList {...rest}>
      {children}
    </components.MenuList>
  );
};

const MenuListWithResetValue = ({ children, selectProps, ...rest }) => {
  const value = { value: 'reset', label: 'Reset Value', id: 'reset' };
  const { t } = useTranslation();
  const { onChange } = selectProps;

  return (
    <components.MenuList {...rest}>
      <label
        className='reset-value'
        onClick={() => onChange(value)}
      >
        {t('reset_value', 'Reset Value')}
      </label>
      {children}
    </components.MenuList>
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span />
    </components.DropdownIndicator>
  );
};

const MultiOption = (props) => {
  return (
    <div>
      <components.Option
        {...props}
        className={'option-checkbox-' + props.value}
      >
        <input
          type='checkbox'
          checked={props.isSelected}
          onChange={() => null}
        />
        <label className='text-ellipsis'>{props.label}</label>
      </components.Option>
    </div>
  );
};

const formatOptionLabel = ({ label, icon, access }) => {
  return (
    <div className='folder-option-item'>
      {icon ? (
        <svg className={'icon folder-option-icon icon-' + access}>
          <use xlinkHref={'/img/sprite.svg#' + icon} />
        </svg>
      ) : (
        <></>
      )}
      {label}
    </div>
  );
};

const multiStyles = {
  menu: ({ styles }) => {
    return {
      ...styles,
      position: 'absolute',
      width: '100%',
    };
  },
  menuList: ({ styles }) => {
    return {
      ...styles,
      minWidth: '110px',
      overflow: 'auto',
    };
  },
  option: (styles) => {
    return {
      ...styles,
      ...{
        display: 'flex',
        padding: '0 10px',
        margin: '10px 0',
        height: '24px',
        justifyContent: 'flex-start',
        alignItems: 'center',
        input: {
          width: '15px',
          height: '15px',
          margin: '0 15px 0 0',
        },
        label: {
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 'normal',
          padding: '0',
          margin: '0',
        },
      },
    };
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      ...{
        height: '32px',
        display: 'flex',
        flexWrap: 'nowrap',
        '> div': {
          height: '20px',
        },
        '.num-wrapper': {
          borderRadius: '6px',
          padding: '0 3px',
          lineHeight: '20px',
        },
      },
    };
  },
};

// Styles for `react-select`
const statusStyles = {
  menu: ({ styles }) => {
    return {
      ...styles,
      position: 'absolute',
      width: '100%',
    };
  },
  menuList: ({ styles }) => {
    return {
      ...styles,
      minWidth: '110px',
      overflow: 'auto',
    };
  },
  option: (styles, { data }) => {
    return {
      ...styles,
      ...{
        display: 'flex',
        padding: '0 10px',
        margin: '10px 0',
        height: '24px',
        justifyContent: 'flex-start',
        alignItems: 'center',
        input: {
          width: '15px',
          height: '15px',
          margin: '0 15px 0 0',
        },
        label: {
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 'normal',
          padding: '0',
          margin: '0',
        },
      },
      label: {
        padding: '4px 10px',
        borderRadius: '4px',
        background: `rgba(${Utils.hexToRgb(data.color)}, 0.2) !important`,
        color: data.color,
        fontSize: '12px',
        fontWeight: 'normal',
      },
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      ...{
        div: {
          background: `rgba(${Utils.hexToRgb(data.color)}, 0.2) !important`,
          color: data.color,
        },
      },
    };
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      ...{
        height: '32px',
        display: 'flex',
        flexWrap: 'nowrap',
        '> div': {
          height: '20px',
        },
        '.num-wrapper': {
          borderRadius: '6px',
          padding: '0 3px',
          lineHeight: '20px',
        },
      },
    };
  },
};

const normalStyles = (isStatus) => ({
  menu: ({ styles }) => {
    return {
      ...styles,
      position: 'absolute',
      width: '100%',
    };
  },
  menuList: ({ styles }) => {
    return {
      ...styles,
      minWidth: '110px',
      overflow: 'auto',
    };
  },
  option: (styles) => {
    return {
      ...styles,
      ...{
        fontSize: '14px !important',
      },
    };
  },
  singleValue: (styles, { data }) => {
    return {
      ...styles,
      ...{
        color: `${isStatus ? data.color : '#646f82'}!important`,
        fontSize: '14px !important',
      },
    };
  },
});

const attachmentStyles = {
  menu: ({ styles }) => {
    return {
      ...styles,
      position: 'absolute',
      width: '100%',
    };
  },
  menuList: ({ styles }) => {
    return {
      ...styles,
      minWidth: '110px',
      overflow: 'auto',
    };
  },
  option: (styles, { data }) => {
    return {
      ...styles,
      ...{
        width: '100%',
        paddingRight: '10px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        ':after': {
          backgroundColor: `${data.version ? '#D5E1FF' : 'transparent'}`,
          borderRadius: '3px',
          content: `'${
            data.version ? `V.${data.version}` : data.access ? data.access : ''
          }'`,
          display: 'block',
          padding: `${data.version || data.access ? '5px 7px' : '0px'}`,
          fontSize: '10px',
          color: '#4B6FFC',
        },
      },
    };
  },
  singleValue: (styles, { data }) => {
    return {
      ...styles,
      ...{
        width: '100%',
        paddingRight: '10px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        ':after': {
          backgroundColor: '#D5E1FF',
          borderRadius: '3px',
          content: `'${data.version ? `V.${data.version}` : ''}'`,
          display: 'block',
          padding: `${data.version ? '5px 7px' : '0px'}`,
          fontSize: '10px',
          color: '#4B6FFC',
        },
      },
    };
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      ...{
        'input': {
          opacity: '1 !important',
        }
      },
    };
  },
};

const SingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      <span
        className={'label'}
        style={{ backgroundColor: `${props.data.color}50` }}
      >
        {props.data.label}
      </span>
    </components.SingleValue>
  );
};

const UserOption = (props) => {
  return (
    <components.Option {...props}>
      {(!!props.data?.firstName || !!props.data?.lastName) && (
        <span className='user--abbreviation'>
          {props.data.firstName
            ? props.data.firstName.charAt(0).toUpperCase()
            : ''}
          {props.data.lastName
            ? props.data.lastName.charAt(0).toUpperCase()
            : ''}
        </span>
      )}
      {props.data?.label ?? ''}
    </components.Option>
  );
};

const UserSingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      {(!!props.data?.firstName || !!props.data?.lastName) && (
        <span className='user--abbreviation'>
          {props.data.firstName
            ? props.data.firstName.charAt(0).toUpperCase()
            : ''}
          {props.data.lastName
            ? props.data.lastName.charAt(0).toUpperCase()
            : ''}
        </span>
      )}
      {props.data?.label ?? ''}
    </components.SingleValue>
  );
};

const userStyles = {
  menu: ({ styles }) => {
    return {
      ...styles,
      position: 'absolute',
      width: '100%',
    };
  },
  menuList: ({ styles }) => {
    return {
      ...styles,
      minWidth: '110px',
      overflow: 'auto',
    };
  },
  option: (styles) => {
    return {
      ...styles,
      ...{
        display: 'flex',
        alignItems: 'center',
        color: '#646f82',
        fontSize: '14px !important',
        '.user--abbreviation': {
          width: '24px',
          height: '24px',
          minWidth: '24px',
          minHeight: '24px',
          borderRadius: '50%',
          lineHeight: 1,
          padding: 0,
          backgroundColor: '#4b6ffc',
          color: 'white',
          marginRight: '4px',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
    };
  },
  singleValue: (styles) => {
    return {
      ...styles,
      ...{
        display: 'flex',
        alignItems: 'center',
        color: '#646f82',
        fontSize: '14px !important',
        '.user--abbreviation': {
          width: '24px',
          height: '24px',
          minWidth: '24px',
          minHeight: '24px',
          borderRadius: '50%',
          lineHeight: 1,
          padding: 0,
          backgroundColor: '#4b6ffc',
          color: 'white',
          marginRight: '4px',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
    };
  },
};

const REASON_TO_SKIP = ['set-value', 'menu-close', 'input-blur']; // outside component

const CustomSelect = (props) => {
  const { t } = useTranslation();
  const {
    values = [],
    isDisabled,
    onChange,
    value,
    isMulti,
    isOptional,
    isError = false,
    name = '',
    isGrey = false,
    isModel = false,
    isStatus = false,
    isBackgroundVisible = true,
    isFolderOption = false,
    menuPlacement = 'auto',
    initialSearchKey = '',
    pickSearchKey,
    isHideSelectedOptions = true,
    classNames = '',
    isClearable = false,
    isUser = false,
    isSearchable = true,
    isMassEdit = false,
    isIncludeAll = false,
  } = props;
  const [searchKey, setSearchKey] = useState(initialSearchKey);
  const [verifyInputValue, setVerifyInputValue] = useState('');
  const [prevValue, setPrevValue] = useState([]); //it is used for only multi select with 'All' value
  
  const style = useMemo(() => {
    if (name === 'status') return statusStyles;
    if (name === 'attachment') return attachmentStyles;
    if (isMulti) return multiStyles;
    if (isUser) return userStyles;
    return normalStyles(isStatus);
  }, [name, isMulti, isStatus, isUser]);

  const className = useMemo(() => {
    let name = 'custom-select select-width-full select--light';
    if (isGrey) name += ' select--grey';
    else if (isModel) name += ' select--model';

    if (!isBackgroundVisible) name += ' select--no-background';
    if (isClearable) name += ' select--clearable';
    if (!Array.isArray(value) && !value?.value)
      name += ' indicator-clearable-none';
    if (isMulti) {
      if (value?.length > 1) name += ' select--multi limit-max-width';
      else name += ' select--multi';
    }
    return name;
  }, [
    isGrey,
    isModel,
    isBackgroundVisible,
    isMulti,
    isStatus,
    isClearable,
    value,
  ]);

  const components = useMemo(() => {
    if (isMulti) return { DropdownIndicator, Option: MultiOption };
    if (isStatus) return { DropdownIndicator, SingleValue };
    if (isUser)
      return {
        DropdownIndicator,
        Option: UserOption,
        SingleValue: UserSingleValue,
      };
    return { DropdownIndicator };
  }, [isMulti, isStatus, isUser]);

  const handleInputChanged = useCallback(
    (input, reason) => {
      if (pickSearchKey && REASON_TO_SKIP.includes(reason.action)) return;

      setSearchKey(input);
      if (pickSearchKey) pickSearchKey(input);
    },
    [pickSearchKey]
  );

  useEffect(() => {
    if (isMulti && value?.length) {
      setVerifyInputValue(value[0].label);
    } else if (!isMulti) {
      if (value?.label) setVerifyInputValue(value?.label);
      else if (searchKey) setVerifyInputValue(searchKey);
    } else {
      setVerifyInputValue('');
    }
  }, [isMulti, value, searchKey]);

  const options = useMemo(() => {
    const buffer = Array.isArray(values) ? [...values] : Object.values(values);
    return isIncludeAll
      ? [{ value: null, label: t('all', 'All')}, ...buffer]
      : buffer;
  }, [values, isIncludeAll]);

  const onSelectChange = (val) => {
    if (!isIncludeAll || !isMulti || !val?.length) {
      onChange(val);
      return;
    }

    let updatedValue = [], selectedValue = null, deSelectedValue = null;
    if (val.length < prevValue.length)
      deSelectedValue = prevValue.find(item => !val.find(it => it.value === item.value)) // unselected item
    else
      selectedValue = val.find(item => !prevValue.find(it => it.value === item.value)) // selected item
    // when 'All' value is included in multi select
    if (selectedValue?.value === null) //if 'All' value is selected
      updatedValue = [...options];
    else if (deSelectedValue?.value === null) // if 'All' value is deselected
      updatedValue = [];
    else
      updatedValue = val.filter(item => item.value !== null);

    setPrevValue(updatedValue);
    onChange(updatedValue);
  };

  return (
    <div className={className}>
      <Select
        options={options}
        className={`select__input${
          isError ? ' select-error' : ''
        } ${classNames}`}
        classNamePrefix='select'
        name={name}
        components={{
          ...components,
          MenuList: isMassEdit ? MenuListWithResetValue : MenuList,
        }}
        value={value}
        hideSelectedOptions={isHideSelectedOptions && !isMulti && !isStatus}
        closeMenuOnSelect={!isMulti}
        styles={style}
        placeholder={<div>{t('select_ellipsis', 'select...')}</div>}
        onChange={onSelectChange}
        isDisabled={isDisabled}
        isMulti={isMulti}
        inputValue={searchKey}
        onInputChange={handleInputChanged}
        isSearchable={isMulti ? false : isSearchable}
        isClearable={isClearable}
        formatOptionLabel={isFolderOption && formatOptionLabel}
        menuPlacement={menuPlacement}
      />
      {Array.isArray(value) && value?.length > 1 ? (
        <span className='values-more'>{`+ ${value?.length - 1}`}</span>
      ) : (
        <></>
      )}
      <input
        tabIndex={-1}
        autoComplete='off'
        className='input--hidden'
        value={verifyInputValue}
        onChange={() => {}}
        required={!isOptional}
      />
    </div>
  );
};

export default CustomSelect;
