import React from 'react';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import { useTranslation } from 'react-i18next';

interface DocumentVersionArchivedErrorDialogProps extends ISxProps {
  onClose: () => void,
}

export default function DocumentVersionArchivedErrorDialog({
  onClose,
}: DocumentVersionArchivedErrorDialogProps) {
  const { t } = useTranslation('documents');
  return (
    <Dialog id="DocumentVersionArchivedErrorDialog" open>
      <DialogTitle>
        {t('document-version-archived-error-dialog_title', 'Document preview unavailable')}
      </DialogTitle>
      <DialogContent>
        <Alert severity="error">
          {t('document-version-archived-error-dialog_error-message', 'This document version can not be viewed because it has been archived.')}
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          {t('document-version-archived-error-dialog_close-button-label', 'Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
