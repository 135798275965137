import {
  BufferAttribute,
  BufferGeometry,
  DynamicDrawUsage,
  Line3,
  LineSegments,
  Matrix4,
  Plane,
  Vector3,
} from "three";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { LineSegments2 } from "three/examples/jsm/lines/LineSegments2";
import { LineSegmentsGeometry } from "three/examples/jsm/lines/LineSegmentsGeometry";
import { COLORGROUP_ID, Position, SELECT_ID } from "../../../BaseDefinitions";
import { IfcContext } from "../../context";

export class ClippingEdge {
  private inverseMatrix = new Matrix4();
  private localPlane = new Plane();
  private tempLine = new Line3();
  private tempVector = new Vector3();
  private tempMatrix = new Matrix4();

  private basicEdge = new LineSegments();
  private edgeLines: LineSegments2;
  private edgeGeometry: BufferGeometry;

  constructor(private clippingPlane: Plane, private context: IfcContext) {
    // create line geometry with enough data to hold 100000 segments
    this.edgeGeometry = new BufferGeometry();
    const posAttr = new BufferAttribute(new Float32Array(300000), 3, false);
    posAttr.setUsage(DynamicDrawUsage);
    this.edgeGeometry.setAttribute(Position, posAttr);

    const material = new LineMaterial({
      linewidth: 0.0025,
    });
    material.color = this.context.sectionLineColor;

    this.edgeLines = new LineSegments2(new LineSegmentsGeometry(), material);
    this.edgeLines.frustumCulled = false;

    this.edgeLines.renderOrder = 3;

    this.context.getScene().add(this.edgeLines);

    this.updateClippingPlanes();
  }

  dispose() {
    this.basicEdge.geometry.dispose();
    this.basicEdge.removeFromParent();
    if (!!this.edgeGeometry.boundsTree) this.edgeGeometry.disposeBoundsTree();
    this.edgeGeometry.dispose();
    if (!!this.edgeLines.geometry.boundsTree)
      this.edgeLines.geometry.disposeBoundsTree();
    this.edgeLines.geometry.dispose();
    this.edgeLines.material.dispose();
    this.edgeLines.removeFromParent();
  }

  updateClippingPlanes() {
    this.edgeLines.material.clippingPlanes = this.context.getClippingPlanes();
    this.drawEdge();
  }

  // https://github.com/gkjohnson/three-mesh-bvh/blob/master/example/clippedEdges.js
  drawEdge() {
    const scope = this;

    let index = 0;
    const posAttr = this.edgeGeometry.attributes.position;
    posAttr.array.fill(0);

    this.context.items.ifcModels.forEach((model) => {
      if (!!model.solid) {
        setPosition(model.solid.geometry, model.solid.matrixWorld);
      }

      model.instancedMeshes.forEach((instanceMesh) => {
        for (let i = 0, n = instanceMesh.count; i < n; i++) {
          instanceMesh.getMatrixAt(i, this.tempMatrix);
          setPosition(instanceMesh.geometry, this.tempMatrix);
        }
      });
    });

    this.context.items.pickableIfcModels
      .filter((m) => m.modelId == SELECT_ID || m.modelId == COLORGROUP_ID)
      .forEach((model) => {
        setPosition(model.geometry, model.matrixWorld);
      });

    this.edgeLines.geometry.setDrawRange(0, index);
    posAttr.needsUpdate = true;

    if (!Number.isNaN(this.edgeGeometry.attributes.position.array[0])) {
      this.basicEdge.geometry = this.edgeGeometry;
      this.edgeLines.geometry.fromLineSegments(this.basicEdge);
      this.edgeLines.position
        .copy(this.clippingPlane.normal)
        .multiplyScalar(0.001);
    }

    function setPosition(geometry: BufferGeometry, matrix: Matrix4) {
      if (!geometry.boundsTree) {
        return;
        //throw new Error("Boundstree not found for clipping edges");
      }

      scope.inverseMatrix.copy(matrix).invert();
      scope.localPlane
        .copy(scope.clippingPlane)
        .applyMatrix4(scope.inverseMatrix);

      geometry.boundsTree.shapecast({
        intersectsBounds: (box: any) => {
          return scope.localPlane.intersectsBox(box) as any;
        },

        intersectsTriangle: (tri: any) => {
          // check each triangle edge to see if it intersects with the plane. If so then
          // add it to the list of segments.
          let count = 0;
          scope.tempLine.start.copy(tri.a);
          scope.tempLine.end.copy(tri.b);
          if (
            scope.localPlane.intersectLine(scope.tempLine, scope.tempVector)
          ) {
            const result = scope.tempVector.applyMatrix4(matrix);
            posAttr.setXYZ(index, result.x, result.y, result.z);
            count++;
            index++;
          }

          scope.tempLine.start.copy(tri.b);
          scope.tempLine.end.copy(tri.c);
          if (
            scope.localPlane.intersectLine(scope.tempLine, scope.tempVector)
          ) {
            const result = scope.tempVector.applyMatrix4(matrix);
            posAttr.setXYZ(index, result.x, result.y, result.z);
            count++;
            index++;
          }

          scope.tempLine.start.copy(tri.c);
          scope.tempLine.end.copy(tri.a);
          if (
            scope.localPlane.intersectLine(scope.tempLine, scope.tempVector)
          ) {
            const result = scope.tempVector.applyMatrix4(matrix);
            posAttr.setXYZ(index, result.x, result.y, result.z);
            count++;
            index++;
          }

          // If we only intersected with one or three sides then just remove it. This could be handled
          // more gracefully.
          if (count !== 2) {
            index -= count;
          }
        },
      });
    }
  }

  updateColor() {
    this.edgeLines.material.color = this.context.sectionLineColor;
  }
}
