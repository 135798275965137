import React, { useMemo } from 'react';
import useEmailsUploadContext from 'emails/hooks/useEmailsUploadContext';
import { useTranslation } from 'react-i18next';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, styled } from '@mui/material';
import UploadingFileHandleProgressBar from 'upload/components/DocumentUploadWizard/UploadingFileHandleProgressBar';

const EllipsisCell = styled(TableCell)(() => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: 0,
}));

interface EmailsUploadProgressPageProps {
  onFinished: () => void,
  onMinimize: () => void,
}

export default function EmailsUploadProgressPage({
  onFinished,
  onMinimize,
}: EmailsUploadProgressPageProps) {
  const { t } = useTranslation('emails');
  const { uploadingFileHandles } = useEmailsUploadContext();
  const areAllUploadsSettled = useMemo(() => uploadingFileHandles.every((handle) => handle.isCompleted || handle.isFailed), [uploadingFileHandles]);
  return (
    <>
      <DialogTitle id="EmailsUploadProgressPage" sx={{ display: 'flex' }}>
        <Box sx={{ flexGrow: 1 }}>{t('emails-upload-progress-page_progress-title', 'Upload {{count}} e-mails', { count: uploadingFileHandles.length })}</Box>
        <Button variant="outlined" onClick={onMinimize}>{t('emails-upload-progress-page_progress-minimize', 'Minimize')}</Button>
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('emails-upload-progress-page_progress-name-column-header', 'Name')}</TableCell>
              <TableCell sx={{ width: '200px' }}>{t('emails-upload-progress-page_progress-status-column-header', 'Status')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {uploadingFileHandles.map((uploadingFileHandle) => (
              <TableRow key={uploadingFileHandle.fileName}>
                <EllipsisCell component="th" scope="row" sx={{ pl: 1 }} title={uploadingFileHandle.fileName}>{uploadingFileHandle.fileName}</EllipsisCell>
                <TableCell sx={{ pr: 1 }}>
                  <UploadingFileHandleProgressBar uploadingFileHandle={uploadingFileHandle} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          id="EmailsUploadProgressPageFinishUploadButton"
          disabled={!areAllUploadsSettled}
          onClick={onFinished}
          sx={{ ml: 'auto' }}
        >
          {t('emails-upload-progress-page_finish-upload-button-label', 'Finish Upload')}
        </Button>
      </DialogActions>
    </>
  );
}
