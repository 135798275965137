import React, {
  useCallback, useMemo,
} from 'react';
import {
  IconButton, InputAdornment, TextField, styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import useEmailsFilterContext from 'emails/hooks/useEmailFilterContext';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    paddingRight: theme.spacing(0.5),
  },
}));

export default function EmailsSearchTextField() {
  const { t } = useTranslation('emails');
  const { quickFilterString, setQuickFilterString } = useEmailsFilterContext();

  const onChangeQuickFilterString = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setQuickFilterString(e.target.value);
  }, [setQuickFilterString]);

  const onClickReset = useCallback(() => {
    setQuickFilterString('');
  }, [setQuickFilterString]);

  const IconComponent = useMemo(() => (quickFilterString?.length ? CloseIcon : SearchIcon), [quickFilterString?.length]);

  return (
    <StyledTextField
      id="EmailsSearchTextField"
      label={t('emails-search-text-field-label', 'Search E-Mail')}
      value={quickFilterString}
      onChange={onChangeQuickFilterString}
      sx={{ width: 400, ml: 3 }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={onClickReset}
              disabled={!quickFilterString.length}
              sx={{ p: 0.5 }}
            >
              <IconComponent color="action" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
