import React, {
  useCallback, useMemo, useState,
} from 'react';
import { Button, Tooltip } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import DeleteIcon from '@mui/icons-material/Delete';
import useCurrentFolderId from 'documents-folders/hooks/useCurrentFolderId';
import ConfirmDeleteFolderDialog from 'documents-folders/components/ConfirmDeleteFolderDialog';
import { useTranslation } from 'react-i18next';
import useFolder from 'documents-folders/hooks/useFolder';
import FolderAccessType from 'documents-folders/types/FolderAccessType';

interface DeleteFolderDialogButtonProps extends ISxProps {
}

export default function DeleteFolderDialogButton({
  sx,
}: DeleteFolderDialogButtonProps) {
  const { t } = useTranslation('documents-folders');
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
  const currentFolderId = useCurrentFolderId();
  const folder = useFolder(currentFolderId);
  const canDelete = useMemo(() => {
    if (!folder) return undefined;
    if (folder.documentCount > 0 || folder.childFolderIds.length > 0) return false; // can't delete non-empty folders
    if (folder.accessType === FolderAccessType.Private && !folder.parentId) return false; // can't delete the toplevel private folder
    return true;
  }, [folder]);

  const onClickDeleteFolder = useCallback(() => {
    setIsConfirmDeleteDialogOpen(true);
  }, []);

  const onCloseDeleteFolderDialog = useCallback(() => {
    setIsConfirmDeleteDialogOpen(false);
  }, []);

  return (
    <>
      <Tooltip id="DeleteFolderDialogButtonTooltip" title={t('delete-folder-menu-button_tooltip', 'Delete folder')}>
        <span>
          <Button
            id="DeleteFolderDialogButton"
            onClick={onClickDeleteFolder}
            sx={{ ...sx, minWidth: 'unset', px: 1 }}
            variant="contained"
            disabled={!currentFolderId || !canDelete}
          >
            <DeleteIcon />
          </Button>
        </span>
      </Tooltip>
      {isConfirmDeleteDialogOpen && currentFolderId && <ConfirmDeleteFolderDialog folderId={currentFolderId} onClose={onCloseDeleteFolderDialog} />}
    </>
  );
}
