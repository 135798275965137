import { useMemo } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import DynamicLayoutKey from 'dynamic-layout/types/DynamicLayoutKey';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import DynamicLayout from 'dynamic-layout/types/DynamicLayout';
import UpsertDynamicLayoutDto from 'dynamic-layout/types/UpsertDynamicLayoutDto';
import DynamicLayoutDto from 'dynamic-layout/types/DynamicLayoutDto';
import useCurrentProjectId from 'projects/hooks/useCurrentProjectId';

export default function useDynamicLayoutUpsertMutation(dynamicLayoutKey: DynamicLayoutKey) {
  const axiosInstance = useAxiosInstance();
  const currentProjectId = useCurrentProjectId();
  const queryClient = useQueryClient();
  const { singletonListQueryKey: listsByCurrentProjectQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.DynamicLayout);
  const queryKey = useMemo(() => [listsByCurrentProjectQueryKey, dynamicLayoutKey], [dynamicLayoutKey, listsByCurrentProjectQueryKey]);
  return useMutation<DynamicLayout | undefined, unknown, UpsertDynamicLayoutDto>({
    mutationFn: async (dto: UpsertDynamicLayoutDto) => {
      const { data: updatedDynamicLayoutDto } = await axiosInstance.put<DynamicLayoutDto>(`${ApiEndpoint.DynamicLayout}`, dto);
      if (updatedDynamicLayoutDto) return undefined;
      queryClient.setQueryData(queryKey, updatedDynamicLayoutDto); // update cache
      return updatedDynamicLayoutDto;
    },
    onMutate: (dto: UpsertDynamicLayoutDto) => {
      // optimistic update
      if (!currentProjectId) return;
      const predictedResultDto: DynamicLayoutDto = {
        ...dto,
        projectId: currentProjectId,
        collaboratorId: '',
      };
      queryClient.setQueryData(queryKey, predictedResultDto);
    },
  });
}
