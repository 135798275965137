export const SET_IS_SAVE_ROLE_SETTING = "SET_IS_SAVE_ROLE_SETTING";
export const SET_IS_SAVE_PROJECT_EMAIL_SETTING =
  "SET_IS_SAVE_PROJECT_EMAIL_SETTING";
export const SET_IS_SAVE_EMAIL_SETTING = "SET_IS_SAVE_EMAIL_SETTING";
export const SET_IS_SAVE_TAGS_SETTING = "SET_IS_SAVE_TAGS_SETTING";
export const SET_IS_SAVE_ENCODING_SETTING = "SET_IS_SAVE_ENCODING_SETTING";
export const SET_IS_SAVE_DOCUMENTS_SETTING = "SET_IS_SAVE_DOCUMENTS_SETTING";
export const SET_IS_SAVE_ISSUES_SETTING = "SET_IS_SAVE_ISSUES_SETTING";
export const SET_SIGNALR_CONNECTION_STATE = "SET_SIGNALR_CONNECTION_STATE";
export const SET_MODEL_LEAVE_CONFIRM = "SET_MODEL_LEAVE_CONFIRM";
export const SET_CANCEL_ISSUE_CREATION = "SET_CANCEL_ISSUE_CREATION";
export const SET_CONVERT_MODEL_PROGRESS = "SET_CONVERT_MODEL_PROGRESS";
export const SET_SYNC_DATA = "SET_SYNC_DATA";
export const SET_FILTER_VALUES_FROM_OTHER_PAGE = "SET_FILTER_VALUES_FROM_OTHER_PAGE";
export const SET_IS_CHATTING = "SET_IS_CHATTING";
export const SET_TO_IFC_FOLDER = "SET_TO_IFC_FOLDER";
export const SET_DOC_OPTION = "SET_DOC_OPTION";
