import { mdiAccountCheck, mdiAccountHardHat, mdiCalendar, mdiEmail, mdiFileDocument, mdiHelp } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Divider, GlobalStyles, Paper, Typography } from '@mui/material';
import CollaboratorDto, { CollaboratorCompanyDto } from 'collaborators/types/CollaboratorDto';
import IssueCommentItem from 'issues/components/IssueCommentItem';
import PrintPreviewFooter from 'issues/components/PrintPreviewFooter';
import PrintPreviewHeader from 'issues/components/PrintPreviewHeader';
import IssuePrintContext from 'issues/contexts/IssuePrintContext';
import CommentDto from 'issues/types/CommentDto';
import IssuePrintViewColumns from 'issues/types/IssuePrintViewColumns';
import IconChip from 'labels/components/IconChip';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import theme from 'theme';

const mockCompanyDto: CollaboratorCompanyDto = {
  name: 'Company Name',
  id: '1',
  street: '123 Street',
  zipcode: '12345',
  city: 'City',
};

const mockCollaboratorDto: CollaboratorDto = {
  id: '1',
  email: 'test@example.com',
  firstName: 'John',
  lastName: 'Doe',
  phone: '123-456-7890',
  company: mockCompanyDto,
  roleId: 'role1',
  isDeleted: false,
  collaboratorGroupIds: ['1', '2'],
};

const mockCommentDto: CommentDto = {
  id: '1',
  timeStamp: '2021-09-01T00:00:00Z',
  issueId: '1',
  createAuthorId: 'author1',
  editAuthorId: 'author2',
  text: 'Comment text',
  creationDate: new Date().toISOString(),
  editDate: new Date().toISOString(),
  createAuthor: mockCollaboratorDto,
  editAuthor: mockCollaboratorDto,
  temp: false,
};

export default function IssueDetailsPrintPreview() {
  const { t } = useTranslation('issues');
  const { columnsIncludedInPrint } = useContext(IssuePrintContext);
  const formatedDate = useCallback((date: string | undefined) => {
    if (!date) return '';
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString('de-DE');
  }, []);
  return (
    <>
      <GlobalStyles styles={{
        '.dontBreak': {
          pageBreakInside: 'avoid',
          breakInside: 'avoid',
        },
        '.pageBreakAfter': {
          pageBreakAfter: 'always',
          breakAfter: 'page',
        },
      }}
      />
      <Paper id="NewIssueDetailsPrintPreview" sx={{ width: '210mm', minHeight: '297mm', p: 2 }}>
        <PrintPreviewHeader />
        <Box className="dontBreak" sx={{ border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2, mt: 2, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {columnsIncludedInPrint.has(IssuePrintViewColumns.IssueNumber) && (
            <Box sx={{ mr: 1, borderRadius: '4px', backgroundColor: theme.palette.grey[700], color: theme.palette.background.default, fontWeight: 600, fontSize: 20, p: '2px', px: '4px', textAlign: 'center' }}>
              1
            </Box>
            )}
            {columnsIncludedInPrint.has(IssuePrintViewColumns.Title) && <Typography variant="h4">{t('issue-details-print-preview_issue-title', 'Issue Title')}</Typography>}
          </Box>
          {columnsIncludedInPrint.has(IssuePrintViewColumns.Description) && <Typography sx={{ pt: 2 }} variant="body1">{t('issue-details-print-preview_description', 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.')}</Typography>}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {(columnsIncludedInPrint.has(IssuePrintViewColumns.Created) || columnsIncludedInPrint.has(IssuePrintViewColumns.Creator)) && (
            <Typography sx={{ py: 1, fontWeight: theme.typography.fontWeightLight }} variant="body2">{t('issue-details-print-preview_creation-label-created', 'Created')}</Typography>
            )}
            {columnsIncludedInPrint.has(IssuePrintViewColumns.Created) && (
            <Typography sx={{ py: 1, fontWeight: theme.typography.fontWeightLight }} variant="body2">
              &nbsp;
              {t('issue-details-print-preview_creation-date', `at ${formatedDate(new Date().toISOString())}`)}
            </Typography>
            )}
            {columnsIncludedInPrint.has(IssuePrintViewColumns.Creator) && (
            <Typography sx={{ py: 1, fontWeight: theme.typography.fontWeightLight }} variant="body2">
              &nbsp;
              {t('issue-details-print-preview_create-author', 'by Max Mustermann')}
            </Typography>
            )}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
            {columnsIncludedInPrint.has(IssuePrintViewColumns.Status) && <IconChip text="Status" color={theme.palette.error.main} mdiIconPath={mdiHelp} />}
            {columnsIncludedInPrint.has(IssuePrintViewColumns.Type) && <IconChip text="Type" color={theme.palette.info.main} mdiIconPath={mdiHelp} />}
            {columnsIncludedInPrint.has(IssuePrintViewColumns.Priority) && <IconChip text="Priority" color={theme.palette.success.main} mdiIconPath={mdiHelp} />}
            {columnsIncludedInPrint.has(IssuePrintViewColumns.Tags) && <IconChip text="Tag" color={theme.palette.warning.main} mdiIconPath={mdiHelp} />}
          </Box>
        </Box>
        {(columnsIncludedInPrint.has(IssuePrintViewColumns.Start) || columnsIncludedInPrint.has(IssuePrintViewColumns.Due) || columnsIncludedInPrint.has(IssuePrintViewColumns.Updated)) && (
        <Box className="dontBreak" sx={{ display: 'flex', justifyContent: 'space-between', border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2, mt: 1, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
          {columnsIncludedInPrint.has(IssuePrintViewColumns.Start) && (
          <Box>
            <Typography sx={{ mb: 0.5 }} variant="h5">{t('issue-details-print-preview_starting-date-label', 'Start')}</Typography>
            <IconChip text={formatedDate(new Date().toISOString())} color={theme.palette.success.main} mdiIconPath={mdiCalendar} />
          </Box>
          )}
          {columnsIncludedInPrint.has(IssuePrintViewColumns.Due) && (
          <Box>
            <Typography sx={{ mb: 0.5 }} variant="h5">{t('issue-details-print-preview_due-date-label', 'Due')}</Typography>
            <IconChip text={formatedDate(new Date().toISOString())} color={theme.palette.error.main} mdiIconPath={mdiCalendar} />
          </Box>
          )}
          {columnsIncludedInPrint.has(IssuePrintViewColumns.Updated) && (
          <Box>
            <Typography sx={{ mb: 0.5 }} variant="h5">{t('issue-details-print-preview_last-edit-date-label', 'Last edit')}</Typography>
            <IconChip text={formatedDate(new Date().toISOString())} color={theme.palette.info.main} mdiIconPath={mdiCalendar} />
          </Box>
          )}
        </Box>
        )}
        <Box className="dontBreak" sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          {columnsIncludedInPrint.has(IssuePrintViewColumns.Disciplines) && (
          <Box sx={{ minWidth: '31%', border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2, mt: 2 }}>
            <Typography variant="h5">{t('issue-details-print-preview_disciplines-label', 'Disciplines')}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1, p: 1, backgroundColor: theme.palette.grey[200], borderRadius: 1 }}>
              <Typography variant="body1">ARC</Typography>
              <Divider sx={{ borderColor: '#000000' }} orientation="vertical" flexItem />
              <IconChip text="Architektur" />
            </Box>
          </Box>
          )}
          {columnsIncludedInPrint.has(IssuePrintViewColumns.Buildings) && (
          <Box sx={{ minWidth: '31%', border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2, mt: 2 }}>
            <Typography variant="h5">{t('issue-details-print-preview_buildings-label', 'Buildings')}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1, p: 1, backgroundColor: theme.palette.grey[200], borderRadius: 1 }}>
              <Typography variant="body1">E1</Typography>
              <Divider sx={{ borderColor: '#000000' }} orientation="vertical" flexItem />
              <IconChip text="Etappe 1" />
            </Box>
          </Box>
          )}
          {columnsIncludedInPrint.has(IssuePrintViewColumns.Floors) && (
          <Box sx={{ minWidth: '31%', border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2, mt: 2 }}>
            <Typography variant="h5">{t('issue-details-print-preview_floors-label', 'Floors')}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1, p: 1, backgroundColor: theme.palette.grey[200], borderRadius: 1 }}>
              <Typography variant="body1">FUN</Typography>
              <Divider sx={{ borderColor: '#000000' }} orientation="vertical" flexItem />
              <IconChip text="Fundament" />
            </Box>
          </Box>
          )}
        </Box>
        <Box className="dontBreak" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          {columnsIncludedInPrint.has(IssuePrintViewColumns.Assignee) && (
          <Box sx={{ width: '48%', border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2, mt: 2 }}>
            <Typography variant="h5">{t('issue-details-print-preview_assignees-label', 'Assignees')}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1, p: 1, backgroundColor: theme.palette.grey[200], borderRadius: 1 }}>
              <Icon size={1} path={mdiAccountHardHat} />
              <Typography variant="body1">Max Mustermann</Typography>
            </Box>
          </Box>
          )}
          {columnsIncludedInPrint.has(IssuePrintViewColumns.Reviewer) && (
          <Box sx={{ width: '48%', border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2, mt: 2, height: 'min-content' }}>
            <Typography variant="h5">{t('issue-details-print-preview_assignees-label', 'Reviewer')}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1, p: 1, backgroundColor: theme.palette.grey[200], borderRadius: 1 }}>
              <Icon size={1} path={mdiAccountCheck} />
              <Typography variant="body1">Karla Kolumna</Typography>
            </Box>
          </Box>
          )}
        </Box>
        {columnsIncludedInPrint.has(IssuePrintViewColumns.Documents) && (
          <Box className="dontBreak" sx={{ border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2, mt: 2 }}>
            <Typography variant="h5">{t('issue-details-print-preview_issue-documents', 'Documents')}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1, p: 1, backgroundColor: theme.palette.grey[200], borderRadius: 1 }}>
              <Icon size={1} path={mdiFileDocument} />
              <Typography variant="body1">Document 1</Typography>
            </Box>
          </Box>
        )}
        {columnsIncludedInPrint.has(IssuePrintViewColumns.Emails) && (
        <Box className="dontBreak" sx={{ border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2, mt: 2 }}>
          <Typography variant="h5">{t('issue-details-print-preview_issue-emails', 'Emails')}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1, p: 1, backgroundColor: theme.palette.grey[200], borderRadius: 1 }}>
            <Icon size={1} path={mdiEmail} />
            <Typography variant="body1">Email 1</Typography>
          </Box>
        </Box>
        )}
        {columnsIncludedInPrint.has(IssuePrintViewColumns.Comments) && (
          <Box className="dontBreak" sx={{ border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2, my: 2 }}>
            <Typography variant="h5">{t('issue-details-print-preview_issue-comments', 'Comments')}</Typography>
            <Box sx={{ mt: 1 }}>
              <IssueCommentItem comment={mockCommentDto} />
            </Box>
          </Box>
        )}
        {columnsIncludedInPrint.has(IssuePrintViewColumns.Images) && (
        <Box className="dontBreak" sx={{ border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2, mt: 2 }}>
          <Typography variant="h5">{t('issue-details-print-preview_issue-images', 'Images')}</Typography>
          <Box sx={{ display: 'flex', mt: 1, justifyContent: 'space-between' }}>
            <Box sx={{ width: '220px', height: '220px', borderRadius: '4px', backgroundColor: 'grey.300' }} />
            <Box sx={{ width: '220px', height: '220px', borderRadius: '4px', backgroundColor: 'grey.300' }} />
            <Box sx={{ width: '220px', height: '220px', borderRadius: '4px', backgroundColor: 'grey.300' }} />
          </Box>
        </Box>
        )}
        {columnsIncludedInPrint.has(IssuePrintViewColumns.Locations) && (
        <Box className="dontBreak" sx={{ border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2, mt: 2 }}>
          <Typography variant="h5">{t('issue-details-print-preview_issue-locations', 'Locations')}</Typography>
          <Box sx={{ display: 'flex', mt: 1, justifyContent: 'space-between' }}>
            <Box sx={{ width: '220px', height: '220px', borderRadius: '4px', backgroundColor: 'grey.300' }} />
            <Box sx={{ width: '220px', height: '220px', borderRadius: '4px', backgroundColor: 'grey.300' }} />
            <Box sx={{ width: '220px', height: '220px', borderRadius: '4px', backgroundColor: 'grey.300' }} />
          </Box>
        </Box>
        )}
        {columnsIncludedInPrint.has(IssuePrintViewColumns.Viewpoints) && (
        <Box className="dontBreak" sx={{ border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2, mt: 2 }}>
          <Typography variant="h5">{t('issue-details-print-preview_issue-viewpoints', 'Viewpoints')}</Typography>
          <Box sx={{ display: 'flex', mt: 1, justifyContent: 'space-between' }}>
            <Box sx={{ width: '220px', height: '220px', borderRadius: '4px', backgroundColor: 'grey.300' }} />
            <Box sx={{ width: '220px', height: '220px', borderRadius: '4px', backgroundColor: 'grey.300' }} />
            <Box sx={{ width: '220px', height: '220px', borderRadius: '4px', backgroundColor: 'grey.300' }} />
          </Box>
        </Box>
        )}
        <Box className="pageBreakAfter">
          <PrintPreviewFooter pageNumber={1} />
        </Box>
      </Paper>
    </>
  );
}
