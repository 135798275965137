import type { Theme, ComponentsProps, ComponentsVariants } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

interface MuiButtonComponent {
  defaultProps?: ComponentsProps['MuiButton'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiButton'];
  variants?: ComponentsVariants['MuiButton'];
}

const VisoButton: MuiButtonComponent = {
  styleOverrides: {
    root: () => ({
      fontWeight: 600,
      textTransform: 'none',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
      },
      '&:focus': {
        boxShadow: 'none',
      },
    }),
    outlinedPrimary: ({ theme }) => ({
      borderColor: 'rgba(0, 0, 0, 0.23)', // same color as the OutlinedInput's border
      '&:not(:hover):not(.Mui-disabled)': {
        color: theme.palette.text.primary,
      },
    }),
    sizeSmall: () => ({
      height: '24px',
      fontSize: 14,
    }),
  },
};

export default VisoButton;
