import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  Box, Checkbox, Chip, Divider, IconButton, Menu, MenuItem, useTheme,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import useChipVisibility from 'common/hooks/useChipVisibility';
import { useTranslation } from 'react-i18next';
import DisplayEntity from 'common/types/DisplayEntity';
import Color from 'color';

interface ChipSelectInputComponentProps {
  value: string[] | undefined,
  onChange: (nextValue: string[] | undefined) => void,
  disabled?: boolean,
  entities: DisplayEntity[] | undefined,
  enableSetEmptyValue?: boolean,
  disabledEntityIds?: string[],
  suppressSelectAll?: boolean,
  abbreviated?: boolean,
}

export default function ChipSelectInputComponent({
  value,
  onChange,
  disabled,
  entities,
  enableSetEmptyValue,
  disabledEntityIds,
  suppressSelectAll,
  abbreviated,
}: ChipSelectInputComponentProps) {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const defaultColor = Color(theme.palette.grey[700]);
  const inputWrapperRef = useRef<HTMLDivElement>(null);
  const chipListRef = useRef<HTMLDivElement>(null);
  const chipListWrapperRef = useRef<HTMLDivElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const selectedIdsSet = useMemo<ReadonlySet<string>>(() => new Set(value), [value]);
  const selectedEntities = useMemo(() => entities?.filter((entity) => selectedIdsSet.has(entity.id)), [selectedIdsSet, entities]);
  const disabledEntityIdsSet = useMemo(() => (disabledEntityIds ? new Set(disabledEntityIds) : undefined), [disabledEntityIds]);

  const getLabel = useCallback((entity: DisplayEntity | undefined) => {
    if (!entity) return undefined;
    if (abbreviated && entity.abbreviation) return entity.abbreviation;
    return entity.name;
  }, [abbreviated]);

  const onRemoveChip = useCallback((idToRemove: string) => {
    if (!value) return;
    onChange(value.filter((id) => idToRemove !== id));
  }, [onChange, value]);

  const onAddChip = useCallback((idToAdd: string) => {
    onChange(Array.from(new Set([...(value ?? []), idToAdd])));
  }, [onChange, value]);

  const onToggleChip = useCallback((id: string) => {
    if (selectedIdsSet.has(id)) onRemoveChip(id);
    else onAddChip(id);
  }, [onAddChip, onRemoveChip, selectedIdsSet]);

  const onClearAll = useCallback(() => {
    if (entities && disabledEntityIds?.length) {
      const selectedDisabledIds = disabledEntityIds.filter((id) => selectedIdsSet.has(id));
      onChange(selectedDisabledIds);
    } else {
      onChange([]);
    }
  }, [disabledEntityIds, entities, onChange, selectedIdsSet]);

  const onSelectAll = useCallback(() => {
    if (!entities) {
      onChange([]);
    } else if (disabledEntityIds?.length) {
      const unselectedDisabledIds = new Set(disabledEntityIds.filter((id) => !selectedIdsSet.has(id)));
      onChange(entities.map((x) => x.id).filter((id) => !unselectedDisabledIds.has(id)));
    } else {
      onChange(entities.map((x) => x.id));
    }
  }, [disabledEntityIds, entities, onChange, selectedIdsSet]);

  const onSetEmptyValue = useCallback(() => {
    onChange([]);
    setIsMenuOpen(false);
  }, [onChange]);

  const onClickOpenMenu = useCallback(() => {
    if (disabled) return;
    setIsMenuOpen(true);
  }, [disabled]);

  const { chipRefCallback, visibleTagsCount, showOnlyCount } = useChipVisibility(chipListRef.current, chipListWrapperRef.current, inputWrapperRef.current, !selectedEntities?.length);

  const isAllSelected = useMemo(() => {
    if (!entities?.length) return false;
    return entities.every((e) => disabledEntityIdsSet?.has(e.id) || selectedIdsSet.has(e.id));
  }, [disabledEntityIdsSet, entities, selectedIdsSet]);
  const isNothingSelected = useMemo(() => {
    if (!entities) return false;
    return entities.every((e) => disabledEntityIdsSet?.has(e.id) || !selectedIdsSet.has(e.id));
  }, [disabledEntityIdsSet, entities, selectedIdsSet]);

  return (
    <>
      <Box
        sx={{
          display: 'inline-flex', height: '20px', minWidth: '42px', width: '100%',
        }}
        onClick={onClickOpenMenu}
        ref={inputWrapperRef}
      >
        <Box
          sx={{
            minWidth: 0, width: '100%', height: '24px', overflow: 'hidden', boxSizing: 'border-box',
          }}
          ref={chipListWrapperRef}
        >
          <Box
            ref={chipListRef}
            sx={{
              display: 'inline-flex', columnGap: '4px', rowGap: '16px', flexWrap: 'wrap', visibility: showOnlyCount ? 'hidden' : undefined,
            }}
          >
            {!!selectedEntities && (
              !!enableSetEmptyValue && value && value.length === 0
                ? <em>{t('chip-select-input_set-empty-value-item-label', 'Set empty value')}</em>
                : value !== undefined
                  ? selectedEntities.map((entity) => ((
                    <Chip
                      data-id={entity.id}
                      key={entity.id}
                      label={getLabel(entity)}
                      size="small"
                      sx={{ color: entity?.color ?? defaultColor.hex(), backgroundColor: Color(entity.color ?? defaultColor).alpha(0.18).hexa(), minWidth: '42px' }}
                      onDelete={!disabledEntityIdsSet?.has(entity.id) ? () => onRemoveChip(entity.id) : undefined}
                      disabled={disabled}
                      ref={(node) => chipRefCallback(entity.id, node)}
                    />
                  )))
                  : <em>{t('chip-select-input_unchanged-label', 'Unchanged')}</em> ?? <Box />
            )}
          </Box>
        </Box>
        {!!selectedEntities && (visibleTagsCount < selectedEntities.length || showOnlyCount) && (
          <Chip
            color="info"
            label={showOnlyCount ? selectedEntities.length : `+${selectedEntities.length - visibleTagsCount}`}
            size="small"
            sx={{ ml: 0.5 }}
          />
        )}
        <IconButton
          disableRipple
          sx={{ px: 0, mb: '3px', ml: 'auto' }}
          onClick={onClickOpenMenu}
          disabled={disabled}
        >
          {isMenuOpen
            ? <ArrowDropUpIcon />
            : <ArrowDropDownIcon />}
        </IconButton>
      </Box>
      <Menu anchorEl={inputWrapperRef.current} open={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
        {enableSetEmptyValue && <MenuItem onClick={onSetEmptyValue}>Set empty value</MenuItem>}
        {!suppressSelectAll && [
          <MenuItem key="select-all" onClick={!isAllSelected ? onSelectAll : onClearAll} disabled={disabled}>
            <Checkbox
              indeterminate={!isAllSelected && !isNothingSelected}
              checked={isAllSelected}
              sx={{ ml: 0 }}
            />
            {t('chip-select-input_select-all-item-label', 'Select All')}
          </MenuItem>,
          <Divider key="select-all-divider" />,
        ]}
        {entities?.map((entity) => (
          <MenuItem key={entity.id} value={entity.id} onClick={() => onToggleChip(entity.id)} disabled={disabled || disabledEntityIdsSet?.has(entity.id)}>
            <Checkbox checked={selectedIdsSet.has(entity.id)} sx={{ ml: 0 }} />
            <Chip
              label={getLabel(entity)}
              size="small"
              sx={{ color: entity?.color ?? defaultColor.hex(), backgroundColor: Color(entity.color ?? defaultColor).alpha(0.18).hexa(), minWidth: '42px' }}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
