import React, { useCallback, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Alert, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface DeleteEncodingGroupConfirmDialogProps {
  groupName: string,
  onClose: (confirmed: boolean) => Promise<void> | void,
}

export default function DeleteEncodingGroupConfirmDialog({
  groupName,
  onClose,
}: DeleteEncodingGroupConfirmDialogProps) {
  const { t } = useTranslation('naming-schemes');
  const onClickCancel = useCallback(() => onClose(false), [onClose]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const onClickConfirm = useCallback(async () => {
    try {
      setIsLoading(true);
      await onClose(true);
    } finally {
      setIsLoading(false);
    }
  }, [onClose]);
  return (
    <Dialog open id="DeleteEncodingGroupConfirmDialog">
      <DialogTitle>{t('delete-encoding-group-confirm-dialog_title', 'Remove Encoding Group')}</DialogTitle>
      <DialogContent>
        <Alert severity="warning">{t('delete-encoding-group-confirm-dialog_warning-message', 'Do you want to delete the encoding group "{{groupName}}"?', { groupName })}</Alert>
      </DialogContent>
      <DialogActions sx={{ gap: 2, justifyContent: 'space-between' }}>
        <Button variant="contained" color="secondary" onClick={onClickCancel}>
          {t('delete-encoding-group-confirm-dialog_cancel-button-label', 'Cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={onClickConfirm} disabled={isLoading}>
          {!!isLoading && <CircularProgress size={12} sx={{ mr: 1 }} />}
          {t('delete-encoding-group-confirm-dialog_confirm-button-label', 'Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
