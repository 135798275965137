import React from 'react';
import useProjectTagsQuery from 'tags/hooks/useProjectTagsQuery';
import ChipMultiselect from 'common/components/ChipMultiselect';

interface TagMultiselectProps {
  label: string,
  value: string[],
  onChange: (values: string[]) => void,
  disabled?: boolean,
  abbreviated?: boolean,
}

export default function TagMultiselect({
  label,
  value,
  onChange,
  disabled,
  abbreviated,
}: TagMultiselectProps) {
  const { data: tags } = useProjectTagsQuery();
  return (
    <ChipMultiselect
      label={label}
      value={value}
      onChange={onChange}
      disabled={disabled}
      entities={tags}
      abbreviated={abbreviated}
    />
  );
}
