import React, { ReactNode, Suspense } from 'react';
import {
  Box, styled, SxProps, Theme,
} from '@mui/material';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';

const HeightTransitionBox = styled(Box)(({ theme }) => ({
  transition: theme.transitions.create(['height', 'border-top-width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: theme.palette.background.default,
  borderTopStyle: 'solid',
  borderTopColor: theme.palette.divider,
  borderTopWidth: 0,
  '&.open': {
    transition: theme.transitions.create(['height', 'border-top-width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderTopWidth: '1px',
  },
}));

interface BottomUtilityDrawerProps {
  isOpen: boolean,
  children?: ReactNode,
  sx?: SxProps<Theme>,
  openHeight: number,
}

export default function BottomUtilityDrawer({
  isOpen,
  children,
  sx,
  openHeight,
}: BottomUtilityDrawerProps) {
  return (
    <HeightTransitionBox id="BottomUtilityDrawer" sx={{ ...sx, height: isOpen ? openHeight : 0 }} className={isOpen ? 'open' : undefined}>
      {isOpen && (
        <Suspense fallback={<CenteredCircularProgress />}>
          {children}
        </Suspense>
      )}
    </HeightTransitionBox>
  );
}
