import { useMemo } from 'react';
import IssueDto from 'issues/types/IssueDto';
import moment, { Moment } from 'moment';
import { QueryOptions } from 'odata-query';

export default function useIsuesCalendarOdataQuery(date: Moment) {
  return useMemo<Partial<QueryOptions<IssueDto>>>(() => ({
    filter: {
      dueDate: {
        ge: moment(date).startOf('month').startOf('week').toDate(),
        le: moment(date).endOf('month').endOf('week').toDate(),
      },
    },
  }), [date]);
}
