import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Box, Modal, useTheme } from '@mui/material';
import DocumentsBrowser from 'documents/components/DocumentsBrowser';
import DocumentFilterContextProvider from 'documents-filter/contexts/DocumentFilterContextProvider';
import useDocumentSelectionContext from 'documents/hooks/useDocumentSelectionContext';
import DocumentUiConfigContext, { DocumentUiConfigContextState } from 'documents/contexts/DocumentUiConfigContext';
import IssueLinkDocumentsDialogHeader from 'issues/components/IssueLinkDocumentsDialogHeader';
import HorizontalDivider from 'common/styled/HorizontalDivider';
import DocumentScope from 'documents/types/DocumentScope';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import DocumentDetailsSelectionContextProvider from 'documents-details/contexts/DocumentDetailsSelectionContextProvider';
import DocumentViewNavigationContext, { DocumentViewNavigationContextState } from 'documents/contexts/DocumentViewNavigationContext';
import { DocumentDetailsSelectionChange } from 'documents-details/contexts/DocumentDetailsSelectionContext';

interface IssueLinkDocumentsDialogProps {
  onClose: (confirmed: boolean) => void,
}

export default function IssueLinkDocumentsDialog({
  onClose,
}: IssueLinkDocumentsDialogProps) {
  const { t } = useTranslation('issues');
  const theme = useTheme();
  const { selectedDocumentVersionIds, nonDeselectableVersionIds } = useDocumentSelectionContext();
  const addCount = useMemo(() => {
    const idsToAdd = selectedDocumentVersionIds.filter((id) => !nonDeselectableVersionIds?.has(id));
    return idsToAdd.length;
  }, [nonDeselectableVersionIds, selectedDocumentVersionIds]);
  const documentsUiConfig = useMemo<DocumentUiConfigContextState>(() => ({
    suppressFolderManipulation: true,
    suppressFooterDrawer: true,
  }), []);

  const [issueId, setIssueId] = useState<string | undefined>(undefined);
  const [documentVersionId, setDocumentVersionId] = useState<string | undefined>(undefined);

  const [documentScope, setDocumentScope] = useState<DocumentScope>({ key: DocumentScopeKey.ProjectDocuments });
  const documentViewNavigationContextState = useMemo<DocumentViewNavigationContextState>(() => ({
    documentScope,
    setDocumentScope,
  }), [documentScope]);

  const setDocumentVersionAndIssueId = useCallback((change: DocumentDetailsSelectionChange) => {
    if (change.documentVersionId !== undefined) {
      setDocumentVersionId(change.documentVersionId ?? undefined);
    }
    if (change.issueId !== undefined) {
      setIssueId(change.issueId ?? undefined);
    }
  }, []);

  return (
    <Modal id="IssueLinkDocumentsDialog" open disablePortal sx={{ height: '100%', display: 'flex', alignItems: 'stretch' }}>
      <Box sx={{
        flex: '1 1 0',
        m: 6,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.divider,
        borderRadius: 4,
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
      }}
      >
        <DocumentUiConfigContext.Provider value={documentsUiConfig}>
          <DocumentViewNavigationContext.Provider value={documentViewNavigationContextState}>
            <DocumentDetailsSelectionContextProvider
              documentVersionId={documentVersionId}
              issueId={issueId}
              setDocumentVersionAndIssueId={setDocumentVersionAndIssueId}
            >
              <DocumentFilterContextProvider>
                <Box sx={{ height: '100%', display: 'grid', gridTemplateRows: 'auto 1fr' }}>
                  <IssueLinkDocumentsDialogHeader />
                  <DocumentsBrowser />
                </Box>
              </DocumentFilterContextProvider>
            </DocumentDetailsSelectionContextProvider>
          </DocumentViewNavigationContext.Provider>
        </DocumentUiConfigContext.Provider>
        <HorizontalDivider />
        <Box sx={{ flex: '0 0 0', display: 'flex', justifyContent: 'flex-end', gap: 4, p: 2 }}>
          <Button onClick={() => onClose(false)} color="secondary" variant="contained">{t('link-documents-dialog-cancel', 'Cancel')}</Button>
          <Button onClick={() => onClose(true)} color="primary" variant="contained">{t('link-documents-dialog-confirm', 'Add {{count}} documents', { count: addCount })}</Button>
        </Box>
      </Box>
    </Modal>
  );
}
