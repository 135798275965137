import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import { useCallback } from 'react';
import CreateNamingSchemeDto from 'naming-schemes/types/CreateNamingSchemeDto';
import NamingSchemeDto from 'naming-schemes/types/NamingSchemeDto';

export default function useNamingSchemeCreateMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.NamingScheme);
  const mutationFn = useCallback(async (dto: CreateNamingSchemeDto) => {
    const { data: issueDtos } = await axiosInstance.post<NamingSchemeDto>(`${ApiEndpoint.NamingScheme}`, dto);
    return issueDtos;
  }, [axiosInstance]);
  const onSuccess = useCallback(() => {
    queryKeyBases.forEach((queryKey) => queryClient.invalidateQueries({ queryKey }));
  }, [queryClient, queryKeyBases]);
  return useMutation<NamingSchemeDto, unknown, CreateNamingSchemeDto>({
    mutationFn,
    onSuccess,
  });
}
