import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import DocumentVersionDto from 'documents/types/DocumentVersionDto';
import UpdateManyDocumentVersionsDto from 'documents/types/DocumentVersionsUpdateManyDto';
import useFolderTreeQueryKey from 'documents-folders/hooks/useFolderTreeQueryKey';
import { useCallback } from 'react';

export default function useDocumentVersionMassEditMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentVersion);
  const folderTreeQueryKey = useFolderTreeQueryKey();
  const mutationFn = useCallback(async (updateDto: UpdateManyDocumentVersionsDto) => {
    if (!updateDto.ids?.length) return;
    const { data: documentVersionDtos } = await axiosInstance.patch<DocumentVersionDto[]>(`${ApiEndpoint.DocumentVersion}/many`, updateDto);
    if (!documentVersionDtos?.length) throw new Error('Incomplete API response');
  }, [axiosInstance]);
  const onSuccess = useCallback(async (data: void, dto: UpdateManyDocumentVersionsDto) => {
    if (dto.folderId) {
      await queryClient.invalidateQueries({ queryKey: folderTreeQueryKey });
    }
    await Promise.all(queryKeyBases.map((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase })));
  }, [folderTreeQueryKey, queryClient, queryKeyBases]);
  return useMutation<void, unknown, UpdateManyDocumentVersionsDto>({
    mutationFn,
    onSuccess,
  });
}
