import { Box, Button, DialogActions, DialogContent, DialogTitle, Link } from '@mui/material';
import EmailUploadFileInput from 'emails/components/EmailUploadFileInput';
import EmailsValidateTable from 'emails/components/EmailsUploadWizard/EmailsValidateTable';
import useEmailsUploadContext from 'emails/hooks/useEmailsUploadContext';
import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface EmailsValidateStagedUploadsPageProps {
  onCancel: () => void,
}

export default function EmailsValidateStagedUploadsPage({
  onCancel,
}: EmailsValidateStagedUploadsPageProps) {
  const { t } = useTranslation('emails');
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    stagedPayload,
    discardStagedFiles,
    startUploadingStagedPayload,
  } = useEmailsUploadContext();

  const areAllStagedDocumentsValid = useMemo(() => {
    if (!stagedPayload) return undefined;
    if (!stagedPayload.files.length) return false;
    return !stagedPayload.fileNamesThatAreFolders?.length && !stagedPayload.fileNamesThatAreInvalid?.length;
  }, [stagedPayload]);

  const onClickDiscardInvalid = useCallback(() => {
    if (!stagedPayload) throw new Error('Dependency error');
    discardStagedFiles((stagedPayload.fileNamesThatAreFolders ?? []).concat(stagedPayload.fileNamesThatAreInvalid ?? []));
  }, [discardStagedFiles, stagedPayload]);

  const onClickConfirm = useCallback(() => {
    startUploadingStagedPayload();
  }, [startUploadingStagedPayload]);

  const onClickUploadMore = useCallback(() => {
    inputRef.current?.showPicker();
  }, []);

  return (
    <>
      <DialogTitle id="EmailsValidateStagedUploadsPage" sx={{ display: 'flex' }}>
        <Box sx={{ flexGrow: 1 }}>{t('emails-validate-staged-uploads-page_title', 'Upload {{count}} e-mails', { count: stagedPayload?.files.length ?? 0 })}</Box>
        <Link component="button" onClick={onClickUploadMore} sx={{ fontWeight: 600 }}>{t('emails-validate-staged-uploads-page_upload-more', 'Upload More')}</Link>
        <EmailUploadFileInput inputRef={inputRef} />
      </DialogTitle>
      <DialogContent>
        <EmailsValidateTable />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" id="EmailsValidateStagedUploadsPageCancelUploadButton" color="secondary" onClick={onCancel}>{t('emails-validate-staged-uploads-page_cancel', 'Cancel')}</Button>
        <Button variant="contained" id="EmailsValidateStagedUploadsPageDiscardInvalidButton" color="secondary" disabled={areAllStagedDocumentsValid} onClick={onClickDiscardInvalid} sx={{ ml: 'auto' }}>{t('emails-validate-staged-uploads-page_discard-invalid', 'Discard Invalid')}</Button>
        <Button variant="contained" id="EmailsValidateStagedUploadsPageStartUploadButton" disabled={!areAllStagedDocumentsValid} onClick={onClickConfirm} sx={{ ml: 1 }}>{t('emails-validate-staged-uploads-page_start-upload', 'Upload')}</Button>
      </DialogActions>
    </>
  );
}
