import { useMutation, useQueryClient } from '@tanstack/react-query';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import { useCallback } from 'react';
import DmsSettingsDto from 'documents/types/DmsSettingsDto';
import DmsSettingsUpdateDto from 'documents/types/DmsSettingsUpdateDto';

export default function useDmsSettingsUpdateMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.DmsSettings);
  const mutationFn = useCallback(async (dto: DmsSettingsUpdateDto) => {
    const { data } = await axiosInstance.patch<DmsSettingsDto>(`${ApiEndpoint.DmsSettings}`, dto);
    return data;
  }, [axiosInstance]);
  const onSuccess = useCallback(() => {
    queryKeyBases.forEach((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
  }, [queryClient, queryKeyBases]);
  return useMutation<DmsSettingsDto, unknown, DmsSettingsUpdateDto>({
    mutationFn,
    onSuccess,
  });
}
