import React, { useContext, useMemo } from 'react';
import {
  Box, Typography, useTheme,
} from '@mui/material';
import useFolder from 'documents-folders/hooks/useFolder';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import { useTranslation } from 'react-i18next';
import ArchivePrivatePublicSwitch from 'documents/components/ArchivePrivatePublicSwitch';
import TimeAgo from 'javascript-time-ago';
import DocumentStatisticsContext, { DocumentStatisticsContextState } from 'documents/contexts/DocumentStatisticsContext';
import useCurrentCollaboratorRole from 'collaborators/hooks/useCurrentCollaboratorRole';
import RoleAction from 'projects/types/RoleAction';
import FolderBreadcrumbs from 'documents-folders/components/FolderBreadcrumbs';
import useCurrentPlanlistId from 'documents-lists/hooks/useCurrentPlanlistId';
import useDocumentListDetailsQuery from 'documents-lists/hooks/useDocumentListDetailsQuery';
import useDocumentsViewNavigationContext from 'documents/hooks/useDocumentsViewNavigationContext';

export default function DocumentsDataGridHeader() {
  const { t, i18n } = useTranslation('documents');
  const theme = useTheme();
  const timeAgo = useMemo(() => new TimeAgo(i18n.language), [i18n.language]);
  const { documentScope } = useDocumentsViewNavigationContext();
  const { displayedRowRange, currentDocumentVersionIds } = useContext<DocumentStatisticsContextState>(DocumentStatisticsContext);
  const folder = useFolder(documentScope.key === DocumentScopeKey.Folder ? documentScope.id : undefined);
  const planlistId = useCurrentPlanlistId();
  const { data: planlist } = useDocumentListDetailsQuery(planlistId);
  const currentUserRole = useCurrentCollaboratorRole();
  const headerText = useMemo(() => {
    if (documentScope.key === DocumentScopeKey.Archive) return t('data-grid-header_archive', 'Archive');
    if (documentScope.key === DocumentScopeKey.Filter) return t('data-grid-header_filter-results', 'Filter Results');
    if (documentScope.key === DocumentScopeKey.Folder) return folder?.name;
    if (documentScope.key === DocumentScopeKey.MyUploads) return t('data-grid-header_my-uploads', 'My Uploads');
    if (documentScope.key === DocumentScopeKey.ProjectDocuments) return t('data-grid-header_all-documents', 'All Documents');
    if (documentScope.key === DocumentScopeKey.Planlist) return planlist?.name;
    return '';
  }, [documentScope, t, folder, planlist]);
  return (
    <Box
      sx={{
        pr: 3,
        pl: '53px',
        py: 1,
        minHeight: 60,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.divider,
        display: 'flex',
        alignItems: 'center',
      }}
      className="inset-shadow-left-27"
    >
      {documentScope.key === DocumentScopeKey.Folder
        ? <FolderBreadcrumbs folderId={documentScope.id} leafNodeSx={{ fontSize: 14 }} />
        : <Typography variant="h4">{headerText}</Typography>}
      <Box sx={{
        display: 'flex', alignItems: 'baseline', flexGrow: 1, flexShrink: 0, pl: 2,
      }}
      >
        {documentScope.key === DocumentScopeKey.Archive && !!currentUserRole?.allowedActions?.has(RoleAction.Document_GetArchived) && <ArchivePrivatePublicSwitch sx={{ ml: 3 }} />}
        <Typography sx={{ ml: 'auto', flexShrink: 0 }}>
          {currentDocumentVersionIds?.length !== 0 && displayedRowRange
            ? t('data-grid-header_document-count', 'Documents {{start}} ~ {{end}} of {{total}}', {
              start: displayedRowRange.firstRowIndex + 1,
              end: displayedRowRange.firstRowIndex + 1 + displayedRowRange.rowCount,
              count: displayedRowRange.rowCount,
              total: currentDocumentVersionIds?.length ?? 'more',
            })
            : t('data-grid-header_document-count-no-documents', 'No documents to display')}
        </Typography>
        {documentScope.key === DocumentScopeKey.Folder && (
          <Typography sx={{ ml: 3, flexShrink: 0 }}>
            {folder?.lastDocumentUpdateDateParsed
              ? t('data-grid-header_last-updated', 'Last updated {{timeAgo}}', { timeAgo: timeAgo.format(folder.lastDocumentUpdateDateParsed) })
              : t('data-grid-header_last-updated_never', 'Last updated: never')}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
