import React, { useCallback } from 'react';
import ISxProps from 'common/types/ISxProps';
import HeaderBox from 'common/components/HeaderBox';
import { useTranslation } from 'react-i18next';
import { Box, Button, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import Icon from '@mdi/react';
import { mdiSelectMultiple, mdiViewColumn, mdiViewList, mdiPrinter } from '@mdi/js';
import { useNavigate, useParams } from 'react-router-dom';
import useIssuesSelectionContext from 'issues/hooks/useIssuesSelectionContext';
import IssuesFilterSelect from 'issues/components/IssuesFilterSelect';
import IssuesViewMode from 'issues/types/IssuesViewMode';
import CreateIssueButton from 'issues/components/CreateIssueButton';
import BcfImportButton from 'issues/components/BcfImportButton';
import BcfExportButton from 'issues/components/BcfExportButton';
import RoleAction from 'projects/types/RoleAction';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';

interface IssuesPageHeaderProps extends ISxProps {
  onClickPrint: () => void,
  onSelectViewMode: (value: IssuesViewMode) => void,
}

export default function IssuesPageHeader({
  sx,
  onClickPrint,
  onSelectViewMode,
}: IssuesPageHeaderProps) {
  const { t } = useTranslation('issues');
  const { viewMode } = useParams<'viewMode'>();
  const allowedActions = useAllowedActions();

  const onChangeViewMode = useCallback((event: React.MouseEvent<HTMLElement>, value: IssuesViewMode) => {
    onSelectViewMode(value);
  }, [onSelectViewMode]);

  const { multiselectActive, setMultiselectActive } = useIssuesSelectionContext();
  const onClickSelectMutliple = useCallback(async () => {
    setMultiselectActive?.(true);
  }, [setMultiselectActive]);

  const navigate = useNavigate();
  const onConfirmCreateIssueDialog = useCallback((createdIssueId: string | undefined) => {
    navigate(`./issue/${createdIssueId}`);
  }, [navigate]);

  return (
    <HeaderBox id="IssuesPageHeader" sx={{ ...sx, px: 4, py: 1, minHeight: 60, display: 'flex', justifyContent: 'space-between', gap: 2, boxShadow: 'inset 2px 2px 4px -2px rgba(0,0,0,0.1)' }}>
      <Typography variant="h2">{t('issues-page-header_title', 'Issues')}</Typography>
      <IssuesFilterSelect disabled={multiselectActive} sx={{ maxWidth: 500, flexGrow: 1 }} />
      <Box sx={{ display: 'flex', gap: 1 }}>
        {!multiselectActive && (
          <>
            {!!allowedActions?.has(RoleAction.IssueManagement_Creation) && <CreateIssueButton onConfirmCreateIssueDialog={onConfirmCreateIssueDialog} />}
            {!!setMultiselectActive && (
              <Button id="IssuesPageHeaderSelectMultipleButton" variant="outlined" sx={{ pl: 1.5, gap: 0.5 }} onClick={onClickSelectMutliple}>
                <Icon path={mdiSelectMultiple} size={1} />
                {t('issues-page-header_select-multiple-button-label', 'Select Multiple')}
              </Button>
            )}
            {!!allowedActions?.has(RoleAction.IssueManagement_BcfUpload) && <BcfImportButton />}
            {!!allowedActions?.has(RoleAction.IssueManagement_Export) && (
              <>
                <BcfExportButton />
                <Button id="IssuesPageHeaderPrintIssueButton" variant="outlined" sx={{ pl: 1.5, gap: 0.5 }} onClick={onClickPrint}>
                  <Icon path={mdiPrinter} size={1} />
                  {t('issues-page-header_print-button-label', 'Print')}
                </Button>
              </>
            )}
            <ToggleButtonGroup
              value={viewMode}
              exclusive
              onChange={onChangeViewMode}
              size="small"
            >
              <ToggleButton id="IssuesPageHeaderViewModeToggleButtonList" value={IssuesViewMode.List} title={t('issues-page-header_view-mode-toggle-button-list', 'List')}>
                <Icon path={mdiViewList} size={1} />
              </ToggleButton>
              <ToggleButton id="IssuesPageHeaderViewModeToggleButtonBoard" value={IssuesViewMode.Board} title={t('issues-page-header_view-mode-toggle-button-board', 'Board')}>
                <Icon path={mdiViewColumn} size={1} />
              </ToggleButton>
              {/* <ToggleButton id="IssuesPageHeaderViewModeToggleButtonTimeline" value={IssuesViewMode.Timeline} title={t('issues-page-header_view-mode-toggle-button-timeline', 'Timeline')}>
                <Icon path={mdiChartGantt} size={1} />
              </ToggleButton> */}
            </ToggleButtonGroup>
          </>
        )}
      </Box>
    </HeaderBox>
  );
}
