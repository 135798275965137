import React, { Suspense } from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@mui/material';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import { Translation } from 'react-i18next';

interface ShareDocumentsFeedbackDialogProps extends JSX.IntrinsicAttributes {
  documentCount: number,
  onClose: () => void,
}

export default function ShareDocumentsFeedbackDialog({
  documentCount,
  onClose,
}: ShareDocumentsFeedbackDialogProps) {
  return (
    <Dialog
      open
      PaperProps={{ style: { width: '324px', height: '230px' } }}
    >
      <Suspense fallback={<CenteredCircularProgress />}>
        <Translation ns="share">
          {(t) => (
            <>
              <DialogTitle sx={{ textAlign: 'center' }}>{t('completed-feedback_success-title', '{{count}} Documents Shared', { count: documentCount })}</DialogTitle>
              <DialogContent sx={{ textAlign: 'center' }}>{t('completed-feedback_success-content', 'You successfully shared {{count}} documents.', { count: documentCount })}</DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={onClose}
                  fullWidth
                >
                  {t('completed-feedback_success-action-close', 'Done')}
                </Button>
              </DialogActions>
            </>
          )}
        </Translation>
      </Suspense>
    </Dialog>
  );
}
