import React, { useCallback, useState } from 'react';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TwoFactorAuthSetupDialog from 'users/components/TwoFactorAuthSetupDialog';
import { mdiArrowLeftBold, mdiCellphoneKey } from '@mdi/js';
import Icon from '@mdi/react';

interface TwoFactorAuthenticationRequiredDialogProps {
  onClose: (confirmed: boolean) => void,
  hideBackdrop?: boolean,
}

export default function TwoFactorAuthRequiredDialog({
  onClose,
  hideBackdrop,
}: TwoFactorAuthenticationRequiredDialogProps) {
  const { t } = useTranslation('users');
  const [twoFactorAuthSetupDialog, setTwoFactorAuthSetupDialog] = useState(false);
  const onConfirm = useCallback(() => setTwoFactorAuthSetupDialog(true), []);
  const onCloseTwoFactorAuthSetupDialog = useCallback((confirmed: boolean) => {
    setTwoFactorAuthSetupDialog(false);
    onClose(confirmed);
  }, [onClose]);
  const onCancel = useCallback(() => onClose(false), [onClose]);
  return (
    <Dialog open id="TwoFactorAuthRequiredDialog" hideBackdrop={hideBackdrop}>
      <DialogTitle>{t('two-factor-auth-required-dialog_title', 'Two-Factor Authentication Required')}</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Alert severity="error">
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ whiteSpace: 'pre-wrap' }}>
              {t('two-factor-auth-required-dialog_message', 'This project is configured to only be accessible to users who have set up two-factor authentication on their account.\nDo you want to set up two-factor authentication now in order to access this project?')}
            </Box>
            <Button variant="contained" color="primary" onClick={onConfirm} sx={{ pl: 1, gap: 1 }}>
              <Icon path={mdiCellphoneKey} size={1} />
              {t('two-factor-auth-required-dialog_confirm-button-label', 'Set up now')}
            </Button>
          </Box>
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onCancel} sx={{ mr: 'auto', pl: 1, gap: 1 }}>
          <Icon path={mdiArrowLeftBold} size={1} />
          {t('two-factor-auth-required-dialog_cancel-button-label', 'Project Overview')}
        </Button>
        {!!twoFactorAuthSetupDialog && <TwoFactorAuthSetupDialog onClose={onCloseTwoFactorAuthSetupDialog} hideBackdrop={hideBackdrop} />}
      </DialogActions>
    </Dialog>
  );
}
