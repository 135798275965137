import React, {
  useCallback, useContext, useMemo,
} from 'react';
import {
  IconButton, InputAdornment, TextField, styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import CollaboratorsFilterContext, { CollaboratorsFilterContextState } from 'collaborators/contexts/CollaboratorsFilterContext';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    paddingRight: theme.spacing(0.5),
  },
}));

export default function CollaboratorsSearchTextField() {
  const { t } = useTranslation('collaborators');
  const { quickFilterString, setQuickFilterString } = useContext<CollaboratorsFilterContextState>(CollaboratorsFilterContext);

  const onChangeQuickFilterString = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setQuickFilterString(e.target.value);
  }, [setQuickFilterString]);

  const onClickReset = useCallback(() => {
    setQuickFilterString('');
  }, [setQuickFilterString]);

  const IconComponent = useMemo(() => (quickFilterString?.length ? CloseIcon : SearchIcon), [quickFilterString?.length]);

  return (
    <StyledTextField
      id="CollaboratorsSearchTextField"
      label={t('collaborators-search-text-field-label', 'Search Document')}
      value={quickFilterString}
      onChange={onChangeQuickFilterString}
      sx={{ width: 400, ml: 3 }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={onClickReset}
              disabled={!quickFilterString.length}
              sx={{ p: 0.5 }}
            >
              <IconComponent color="action" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
