import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Switch, Typography } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useModelSelectionContext from 'models/hooks/useModelSelectionContext';
import FloorModelFilesGroup from 'models/types/FloorModelFilesGroup';
import LabelChip from 'labels/components/LabelChip';
import { useTranslation } from 'react-i18next';

interface FloorModelFilesGroupItemProps extends ISxProps {
  group: FloorModelFilesGroup,
}

export default function FloorModelFilesGroupItem({
  sx,
  group,
}: FloorModelFilesGroupItemProps) {
  const { t } = useTranslation('models');
  const { stageModelFileId, stagedModelFileIdsSet, unstageModelFileId } = useModelSelectionContext();
  const [selectedModelFileId, setSelectedModelFileId] = useState<string | undefined>(group.floorModelFiles[0]?.id);
  const staged = useMemo(() => !!selectedModelFileId && stagedModelFileIdsSet.has(selectedModelFileId), [selectedModelFileId, stagedModelFileIdsSet]);
  const onChangeStaged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (!selectedModelFileId) return;
    if (event.target.checked) stageModelFileId(selectedModelFileId);
    else unstageModelFileId(selectedModelFileId);
  }, [selectedModelFileId, stageModelFileId, unstageModelFileId]);
  const onChangeSelect = useCallback((event: SelectChangeEvent<string>) => {
    setSelectedModelFileId((prevSelectedModelFileId) => {
      const nextSelectedModelFileId = event.target.value;
      if (prevSelectedModelFileId && stagedModelFileIdsSet.has(prevSelectedModelFileId)) {
        unstageModelFileId(prevSelectedModelFileId);
        stageModelFileId(nextSelectedModelFileId);
      }
      return nextSelectedModelFileId;
    });
  }, [stageModelFileId, stagedModelFileIdsSet, unstageModelFileId]);
  const selectedModelCaption = useMemo(() => {
    if (!selectedModelFileId) return undefined;
    const selectedModelFile = group.floorModelFiles.find((mf) => mf.id === selectedModelFileId);
    if (selectedModelFile) return `${selectedModelFile.name} (${new Date(selectedModelFile.creationDate).toLocaleString('de-DE')})`;
    return undefined;
  }, [group, selectedModelFileId]);
  return (
    <Box id="FloorModelFilesGroupItem" sx={{ ...sx, display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'baseline' }}>
        <LabelChip label={group.floor} abbreviated />
        <Typography variant="h6">{group.floor.name}</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <FormControl fullWidth>
          <InputLabel id="floor-model-files-group-item_version-select-label">{t('floor-model-files-group-item_version-select-label', 'Version')}</InputLabel>
          <Select
            labelId="floor-model-files-group-item_version-select-label"
            id="floor-model-files-group-item_version-select"
            value={selectedModelFileId ?? ''}
            label={t('floor-model-files-group-item_version-select-label', 'Version')}
            onChange={onChangeSelect}
            title={selectedModelCaption}
          >
            {!!group.floorModelFiles && group.floorModelFiles.map((mf) => <MenuItem key={mf.id} value={mf.id}>{`${mf.name} (${new Date(mf.creationDate).toLocaleString('de-DE')})`}</MenuItem>)}
          </Select>
        </FormControl>
        <Switch onChange={onChangeStaged} checked={staged} />
      </Box>
    </Box>
  );
}
