import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import { useTranslation } from 'react-i18next';
import useEmailSettingsQuery from 'emails/hooks/useEmailSettingsQuery';
import EmailIntervalType from 'projects/types/EmailIntervalType';
import EmailIntervalSelect from 'emails/components/EmailIntervalSelect';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import UpdateEmailSettingsDto from 'emails/types/UpdateEmailSettingsDto';
import useEmailSettingsUpdateMutation from 'emails/hooks/useEmailSettingsUpdateMutation';
import EmailIntervalDisplayValue from 'emails/types/EmailIntervalDisplayValue';

interface EmailSettingsModalProps {
  onClose: () => void,
}

export default function EmailSettingsDialog({
  onClose,
}: EmailSettingsModalProps) {
  const { t } = useTranslation('emails');
  const { data: currentProject } = useCurrentProjectQuery();
  const { data: emailSettings } = useEmailSettingsQuery();
  const [documentEmailInterval, setDocumentEmailInterval] = useState<EmailIntervalDisplayValue>(EmailIntervalDisplayValue.Unknown);
  const [issueEmailInterval, setIssueEmailInterval] = useState<EmailIntervalDisplayValue>(EmailIntervalDisplayValue.Unknown);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const getRequestErrorMessage = useRequestErrorMessage();
  const { mutateAsync, isPending: isLoadingMutation } = useEmailSettingsUpdateMutation();

  const getIntervalDisplayValue = useCallback((enabled: boolean | undefined, interval: EmailIntervalType | undefined) => {
    if (enabled === false) return EmailIntervalDisplayValue.Never;
    if (interval === EmailIntervalType.Daily) return EmailIntervalDisplayValue.Daily;
    if (interval === EmailIntervalType.Weekly) return EmailIntervalDisplayValue.Weekly;
    return EmailIntervalDisplayValue.Unknown;
  }, []);

  useEffect(() => {
    if (documentEmailInterval === EmailIntervalDisplayValue.Unknown) setDocumentEmailInterval(getIntervalDisplayValue(emailSettings?.docEmailEnabled, emailSettings?.docEmailInterval));
    if (issueEmailInterval === EmailIntervalDisplayValue.Unknown) setIssueEmailInterval(getIntervalDisplayValue(emailSettings?.issueEmailEnabled, emailSettings?.issueEmailInterval));
  }, [emailSettings, documentEmailInterval, issueEmailInterval, getIntervalDisplayValue]);

  const getIntervalType = useCallback((displayvalue: EmailIntervalDisplayValue | string | undefined) => {
    if (displayvalue === EmailIntervalDisplayValue.Weekly) return EmailIntervalType.Weekly;
    return EmailIntervalType.Daily;
  }, []);

  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const onCloseSuccessDialog = useCallback(() => {
    setIsSuccessDialogOpen(false);
    onClose();
  }, [onClose]);

  const onConfirm = useCallback(async () => {
    try {
      const updateDto: UpdateEmailSettingsDto = {
        docEmailEnabled: documentEmailInterval !== EmailIntervalDisplayValue.Never,
        docEmailInterval: getIntervalType(documentEmailInterval),
        issueEmailEnabled: issueEmailInterval !== EmailIntervalDisplayValue.Never,
        issueEmailInterval: getIntervalType(issueEmailInterval),
      };
      await mutateAsync(updateDto);
      setIsSuccessDialogOpen(true);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [documentEmailInterval, getIntervalType, getRequestErrorMessage, issueEmailInterval, mutateAsync]);

  return (
    <>
      <Dialog id="EmailSettingsDialog" open PaperProps={{ sx: { minWidth: '600px', minHeight: '400px' } }}>
        <DialogTitle>
          {t('email-settings-dialog_title', 'E-Mail Settings ({{projectName}})', { projectName: currentProject?.name })}
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="h5" sx={{ mt: 2 }}>{t('email-settings-dialog_document-management-section-header', 'Document Management')}</Typography>
          <EmailIntervalSelect
            label={t('email-settings-dialog_document-email-interval-select-label', 'Email Interval')}
            value={documentEmailInterval}
            onChange={setDocumentEmailInterval}
          />
          <Typography variant="h5" sx={{ mt: 2 }}>{t('email-settings-dialog_issue-management-section-header', 'Issue Management')}</Typography>
          <EmailIntervalSelect
            label={t('email-settings-dialog_issue-email-interval-select-label', 'Email Interval')}
            value={issueEmailInterval}
            onChange={setIssueEmailInterval}
          />
          {!!errorMessage && (
            <Alert severity="error">{errorMessage}</Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={onClose}>{t('email-settings-dialog_cancel-button-label', 'Cancel')}</Button>
          <Button variant="contained" color="primary" onClick={onConfirm} disabled={isLoadingMutation} sx={{ ml: 'auto' }}>
            {isLoadingMutation && <CircularProgress size={12} sx={{ mr: 1 }} />}
            {t('email-settings-dialog_confirm-button-label', 'Save')}
          </Button>
        </DialogActions>
      </Dialog>
      {!!isSuccessDialogOpen && (
        <Dialog open onClose={onCloseSuccessDialog}>
          <DialogTitle>
            {t('email-settings-dialog_success-dialog-title', 'E-Mail Settings saved')}
          </DialogTitle>
          <DialogContent>
            <Alert severity="success">
              {t('email-settings-dialog_success-dialog-message', 'Your E-Mail preferences have been saved.')}
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={onCloseSuccessDialog} sx={{ ml: 'auto' }}>{t('email-settings-dialog_success-dialog-close-button-label', 'Close')}</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
