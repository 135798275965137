import React, { useCallback } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import DocumentsDataGridRow from 'documents/types/DocumentDataGridRow';
import DocumentVersionBuildingUpdateDto from 'documents/types/DocumentVersionBuildingUpdateDto';
import LabelCell from 'documents/components/cell-renderers/LabelCell';
import LabelType from 'labels/types/LabelType';
import ApiEndpoint from 'api/types/ApiEndpoint';

export default function BuildingCell({
  data,
  value,
}: ICellRendererParams<DocumentsDataGridRow, string | undefined>) {
  const getUpdateDto = useCallback((labelId: string | null) => {
    if (!data) throw new Error('Missing data.id');
    return {
      id: data.id,
      building: labelId,
    };
  }, [data]);

  return (
    <LabelCell<DocumentVersionBuildingUpdateDto>
      data={data}
      value={value}
      labelType={LabelType.Building}
      apiEndpoint={ApiEndpoint.DocumentVersionBuilding}
      getUpdateDto={getUpdateDto}
    />
  );
}
