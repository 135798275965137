import React, { useCallback, useMemo, useRef, useState } from 'react';
import { CustomCellRendererProps } from '@ag-grid-community/react';
import IssueDto from 'issues/types/IssueDto';
import { Button, Menu, MenuItem, useTheme } from '@mui/material';
import Color from 'color';
import useDocumentVersionsQuery from 'documents/hooks/useDocumentVersionsQuery';
import { useNavigate } from 'react-router-dom';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';

export default function DocumentsCell({
  value,
  data: issue,
}: CustomCellRendererProps<IssueDto, IssueDto['linkedDocumentVersionIds']>) {
  const theme = useTheme();
  const navigate = useNavigate();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const onClickOpenMenu = useCallback(() => setMenuOpen(true), []);
  const onCloseMenu = useCallback(() => setMenuOpen(false), []);
  const linkedDocumentVersionsOdataFilter = useMemo(() => (issue && menuOpen ? { filter: { id: { in: issue.linkedDocumentVersionIds } } } : undefined), [issue, menuOpen]);
  const { data: linkedDocumentVersions } = useDocumentVersionsQuery(linkedDocumentVersionsOdataFilter);
  const linkedDocumentVersionItems = useMemo(() => (!!issue && !!linkedDocumentVersions ? linkedDocumentVersions.map((documentVersion) => ({
    documentVersion,
    onClick: () => navigate(`./issue/${issue.id}/document/${documentVersion.id}/issue/${issue.id}`),
  })) : undefined), [issue, linkedDocumentVersions, navigate]);
  return (
    <>
      <Button
        id="DocumentsCell"
        variant="contained"
        color="secondary"
        ref={buttonRef}
        onClick={onClickOpenMenu}
        disabled={!value?.length}
        sx={{
          backgroundColor: Color(theme.palette.info.main).lightness(92).hex(),
          fontWeight: 600,
          height: '24px',
          minWidth: 'unset',
          px: 1,
          py: 0,
        }}
      >
        {`${value?.length ?? ''}`}
      </Button>
      <Menu open={menuOpen} anchorEl={buttonRef.current} onClose={onCloseMenu}>
        {!linkedDocumentVersionItems && <CenteredCircularProgress sx={{ m: 2 }} />}
        {!!linkedDocumentVersionItems && linkedDocumentVersionItems.map(({ documentVersion, onClick }) => (
          <MenuItem key={documentVersion.id} onClick={onClick}>
            {documentVersion.originalFileName}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
