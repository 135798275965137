import React, { useCallback } from 'react';
import PageWrapper from 'common/components/PageWrapper';
import { Box } from '@mui/material';
import ModelsPageHeader from 'models/components/ModelsPageHeader';
import ModelsPanel from 'models/components/ModelsPanel';
import ModelSelectionContextProvider from 'models/contexts/ModelSelectionContextProvider';
import ModelsPanelDockviewContextProvider from 'models/contexts/ModelsPanelDockviewContextProvider';
import Viewer3dContextProvider from 'models/contexts/Viewer3dContextProvider';
import ModelsInteractionContextProvider from 'models/contexts/ModelsInteractionContextProvider';
import { useNavigate, useParams } from 'react-router-dom';

export default function ModelsPage() {
  const { issueId } = useParams<'issueId'>();
  const navigate = useNavigate();
  const setSelectedIssueId = useCallback((value: string | undefined) => {
    if (value) {
      navigate(`./issue/${value}`);
    } else {
      navigate('./');
    }
  }, [navigate]);
  return (
    <PageWrapper id="ModelsPage">
      <Box sx={{
        display: 'grid',
        gridTemplateRows: '60px 1fr',
        height: '100%',
      }}
      >
        <ModelSelectionContextProvider selectedIssueId={issueId} setSelectedIssueId={setSelectedIssueId}>
          <ModelsInteractionContextProvider>
            <ModelsPanelDockviewContextProvider>
              <Viewer3dContextProvider>
                <ModelsPageHeader />
                <ModelsPanel />
              </Viewer3dContextProvider>
            </ModelsPanelDockviewContextProvider>
          </ModelsInteractionContextProvider>
        </ModelSelectionContextProvider>
      </Box>
    </PageWrapper>
  );
}
