import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { Box, Switch, Typography, useTheme } from '@mui/material';
import RoleAction from 'projects/types/RoleAction';
import RoleActionDto from 'collaborators/types/RoleActionDto';
import { Variant } from '@mui/material/styles/createTypography';

export enum MutationState {
  Pending = 'pending',
  Success = 'success',
  Error = 'error',
}

export type StateMap = Partial<Record<RoleAction, MutationState>>;

interface RoleActionNodeProps {
  roleActionDto: RoleActionDto,
  label: string,
  onChange: (roleAction: RoleAction, isAllowed: boolean) => void,
  stateMap: StateMap,
  depth: number,
  disabled?: boolean | undefined,
}

export default function RoleActionNode({
  roleActionDto,
  label,
  onChange,
  stateMap,
  depth,
  disabled,
}: RoleActionNodeProps) {
  const theme = useTheme();
  const switchColor = useMemo(() => {
    const state = stateMap[roleActionDto.action];
    if (state === MutationState.Success) return 'success';
    if (state === MutationState.Error) return 'error';
    if (state === MutationState.Pending) return 'secondary';
    return undefined;
  }, [roleActionDto.action, stateMap]);
  const displayValue = useMemo(() => roleActionDto.isAllowed ?? roleActionDto.defaultValue, [roleActionDto.defaultValue, roleActionDto.isAllowed]);
  const headingVariant = useMemo<Variant>(() => {
    if (depth === 0) return 'h4';
    return 'body1';
  }, [depth]);
  const onChangeSwitch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange(roleActionDto.action, event.target.checked);
  }, [onChange, roleActionDto]);
  return (
    <>
      {depth === 0 && <Box sx={{ gridColumn: '1 / 3', height: 20 }} />}
      <Box id="RoleActionNode" sx={{ display: 'flex', gap: 2, alignItems: 'center', pl: depth * 4 }}>
        <Typography variant={headingVariant}>{label}</Typography>
        <Typography sx={{ borderBottom: `2px solid ${theme.palette.grey[300]}`, flexGrow: 1 }} />
      </Box>
      <Switch checked={displayValue} onChange={onChangeSwitch} disabled={disabled} color={switchColor} />
    </>
  );
}
