import useIssueQueryData from 'issues/hooks/useIssueQueryData';
import { useCallback } from 'react';

export default function useModelFileIdsLinkedToIssue() {
  const getIssue = useIssueQueryData();
  return useCallback(async (issueId: string) => {
    const issue = await getIssue(issueId);
    const modelFileIds = Object.keys(issue.modelFileComponentIds);
    return modelFileIds;
  }, [getIssue]);
}
