import React from 'react';
import { MenuItem, Select, SelectProps } from '@mui/material';
import useFolderTreeQuery from 'documents-folders/hooks/useFolderTreeQuery';
import Folder from 'documents-folders/types/Folder';

const getFolderTreeMenuItems: (folderChild: Folder, depth: number) => React.ReactNode = (folderChild: Folder, depth: number) => [
  <MenuItem key={folderChild.id} value={folderChild.id} sx={{ pl: depth + 1 }} disabled={!folderChild.hasFolderAccess}>
    <svg style={{ width: '16px', height: '12px', marginRight: '4px' }}>
      <use xlinkHref="/img/sprite.svg#folder" />
    </svg>
    {folderChild.name}
  </MenuItem>,
  ...(folderChild.childFolders?.map((child) => getFolderTreeMenuItems(child, depth + 1)) ?? []),
];

export default function FolderSelect<T extends string[] | string = string>(props: SelectProps<T>) {
  const { data: folderTreeData } = useFolderTreeQuery();
  return (
    <Select {...props}>
      {folderTreeData?.folders.map((topLevelFolder) => getFolderTreeMenuItems(topLevelFolder, 0))}
    </Select>
  );
}
