import React, { useCallback, useMemo } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Link, styled } from '@mui/material';
import { t } from 'i18next';
import useEmailsUploadContext from 'emails/hooks/useEmailsUploadContext';
import theme from 'theme';

const EllipsisCell = styled(TableCell)(() => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: 0,
}));

export default function EmailsValidateTable() {
  const {
    stagedPayload,
    discardStagedFiles,
  } = useEmailsUploadContext();

  const fileNamesThatAreFoldersSet = useMemo(() => {
    if (!stagedPayload) return null;
    return new Set(stagedPayload.fileNamesThatAreFolders);
  }, [stagedPayload]);
  const isFolder = useCallback((fileName: string) => fileNamesThatAreFoldersSet?.has(fileName) ?? false, [fileNamesThatAreFoldersSet]);

  const fileNamesThatAreInvalid = useMemo(() => {
    if (!stagedPayload) return null;
    return new Set(stagedPayload.fileNamesThatAreInvalid);
  }, [stagedPayload]);
  const isInvalidFilename = useCallback((fileName: string) => fileNamesThatAreInvalid?.has(fileName) ?? false, [fileNamesThatAreInvalid]);

  const discardFile = useCallback((fileName: string) => {
    discardStagedFiles([fileName]);
  }, [discardStagedFiles]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('emails-validate-table_name-column-header', 'Name')}</TableCell>
            <TableCell sx={{ width: '120px' }}>{t('emails-validate-table_size-column-header', 'Size')}</TableCell>
            <TableCell sx={{ width: '370px' }}>{t('emails-validate-table_information-column-header', 'Information')}</TableCell>
            <TableCell sx={{ width: '110px' }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {stagedPayload?.files.map((file) => (
            <TableRow key={file.name}>
              <TableCell>{file.name}</TableCell>
              <TableCell>
                {!isFolder(file.name) && !!isInvalidFilename(file.name) && `${(file.size / 1024).toFixed(0)} KB`}
              </TableCell>
              <EllipsisCell sx={{ color: theme.palette.error.main }}>
                {!!isFolder(file.name) && t('emails-validate-table_folder-item-invalid-payload-error-message', 'Error! Folder upload is not supported.')}
                {!!isInvalidFilename(file.name) && !isFolder(file.name) && t('emails-validate-table_invalid-payload-error-message', 'Error! Only .msg and .eml files are supported.')}
              </EllipsisCell>
              <TableCell align="right"><Link onClick={() => discardFile(file.name)}>{t('emails-validate-table_discard-file', 'Discard File')}</Link></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
