import React, { useCallback, useMemo, useState } from 'react';
import ISxProps from 'common/types/ISxProps';
import { Box, Typography, useTheme } from '@mui/material';
import useIssuesFilterContext from 'issues/hooks/useIssuesFilterContext';
import useCollaboratorsOdataQuery from 'collaborators/hooks/useCollaboratorsOdataQuery';
import IconChip from 'labels/components/IconChip';
import { mdiAccountBadge, mdiAccountCheck, mdiAccountEdit, mdiAccountHardHat, mdiCalendarEdit, mdiCalendarEnd, mdiCalendarPlus, mdiCalendarStart, mdiEye, mdiFlag, mdiHome, mdiLayers, mdiPoundBox, mdiStateMachine, mdiTag, mdiText, mdiTextLong, mdiThermometer, mdiWrench } from '@mdi/js';
import IssueFilterSettingsDto from 'query-filters/types/IssueFilterSettingsDto';
import useLabelsOdataQuery from 'labels/hooks/useLabelsOdataQuery';
import LabelChip from 'labels/components/LabelChip';
import { useTranslation } from 'react-i18next';
import useTimeFrameTranslations from 'common/hooks/useTimeFrameTranslations';
import IssuesDataGridColumnOptionsButtonMenu from 'issues/components/IssuesDataGridColumnOptionsButtonMenu';
import { useParams } from 'react-router-dom';
import { isEqual } from 'lodash';
import useColumnDisplayNamesByFieldName from 'issues/hooks/useColumnDisplayNamesByFieldName';
import IssueFilterDialog from 'issues/components/IssueFilterDialog';
import useIssueVisibilityTranslations from 'issues/hooks/useIssueVisibilityTranslations';
import IssueVisibility from 'issues/types/IssueVisibility';
import IssueFilterOptions from 'issues/types/IssueFilterOptions';
import useIssueFilterOptionsByFieldName from 'issues/hooks/useIssueFilterOptionsByFieldName';
import useIssuesIdsQuery from 'issues/hooks/useIssuesIdsQuery';

interface IssuesPageHeaderProps extends ISxProps {
}

export default function IssuesFilterPanel({
  sx,
}: IssuesPageHeaderProps) {
  const theme = useTheme();
  const { viewMode } = useParams<'viewMode'>();
  const { t } = useTranslation();
  const { queryFilterState, odataQuery } = useIssuesFilterContext();
  const { data: issueIds } = useIssuesIdsQuery(odataQuery);
  const { data: collaborators } = useCollaboratorsOdataQuery({ });
  const collaboratorsById = useMemo(() => (collaborators ? new Map(collaborators.map((collaborator) => [collaborator.id, collaborator])) : undefined), [collaborators]);
  const { appliedFilterState, emptyFilterState, setAppliedFilterState } = queryFilterState;
  const { data: labels } = useLabelsOdataQuery({});
  const labelsById = useMemo(() => (labels ? new Map(labels.map((label) => [label.id, label])) : undefined), [labels]);
  const columnDisplayNamesByFieldName = useColumnDisplayNamesByFieldName();

  const assignedCollaborators = useMemo(() => (collaboratorsById && !isEqual(appliedFilterState.assignedCollaboratorIds, emptyFilterState.assignedCollaboratorIds) ? appliedFilterState.assignedCollaboratorIds?.filter((id) => collaboratorsById.has(id)).map((id) => collaboratorsById?.get(id)!) : undefined), [appliedFilterState.assignedCollaboratorIds, collaboratorsById, emptyFilterState.assignedCollaboratorIds]);
  const onClickRemoveAssignedCollaborator = useCallback((collaboratorId: string) => {
    const assignedCollaboratorIds = (appliedFilterState.assignedCollaboratorIds ?? []).filter((id) => id !== collaboratorId);
    const nextAppliedFilterState: IssueFilterSettingsDto = {
      ...appliedFilterState,
      assignedCollaboratorIds: assignedCollaboratorIds.length ? assignedCollaboratorIds : emptyFilterState.assignedCollaboratorIds,
    };
    setAppliedFilterState(nextAppliedFilterState);
  }, [appliedFilterState, emptyFilterState.assignedCollaboratorIds, setAppliedFilterState]);

  const reviewer = useMemo(() => (collaboratorsById && appliedFilterState.reviewer && appliedFilterState.reviewer !== emptyFilterState.reviewer ? collaboratorsById.get(appliedFilterState.reviewer) : undefined), [appliedFilterState.reviewer, collaboratorsById, emptyFilterState.reviewer]);
  const onClickRemoveReviewer = useCallback(() => {
    setAppliedFilterState({ ...appliedFilterState, reviewer: emptyFilterState.reviewer });
  }, [appliedFilterState, emptyFilterState.reviewer, setAppliedFilterState]);

  const createAuthor = useMemo(() => (collaboratorsById && appliedFilterState.createAuthor && appliedFilterState.createAuthor !== emptyFilterState.createAuthor ? collaboratorsById.get(appliedFilterState.createAuthor) : undefined), [appliedFilterState.createAuthor, collaboratorsById, emptyFilterState.createAuthor]);
  const onClickRemoveCreateAuthor = useCallback(() => {
    setAppliedFilterState({ ...appliedFilterState, createAuthor: emptyFilterState.createAuthor });
  }, [appliedFilterState, emptyFilterState.createAuthor, setAppliedFilterState]);

  const editAuthor = useMemo(() => (collaboratorsById && appliedFilterState.editAuthor && appliedFilterState.editAuthor !== emptyFilterState.editAuthor ? collaboratorsById.get(appliedFilterState.editAuthor) : undefined), [appliedFilterState.editAuthor, collaboratorsById, emptyFilterState.editAuthor]);
  const onClickRemoveEditAuthor = useCallback(() => {
    setAppliedFilterState({ ...appliedFilterState, editAuthor: emptyFilterState.editAuthor });
  }, [appliedFilterState, emptyFilterState.editAuthor, setAppliedFilterState]);

  const statusLabels = useMemo(() => (labelsById && !isEqual(appliedFilterState.status, emptyFilterState.status) ? appliedFilterState.status?.filter((id) => labelsById.has(id)).map((id) => labelsById?.get(id)!) : undefined), [appliedFilterState.status, emptyFilterState.status, labelsById]);
  const onClickRemoveStatus = useCallback((labelId: string) => {
    const nextStatuses = (appliedFilterState.status ?? []).filter((id) => id !== labelId);
    setAppliedFilterState({ ...appliedFilterState, status: nextStatuses.length ? nextStatuses : emptyFilterState.status });
  }, [appliedFilterState, emptyFilterState.status, setAppliedFilterState]);

  const priorityLabel = useMemo(() => (appliedFilterState.priority && appliedFilterState.priority !== emptyFilterState.priority ? labelsById?.get(appliedFilterState.priority) : undefined), [appliedFilterState.priority, emptyFilterState.priority, labelsById]);
  const onClickRemovePriority = useCallback(() => {
    setAppliedFilterState({ ...appliedFilterState, priority: emptyFilterState.priority });
  }, [appliedFilterState, emptyFilterState.priority, setAppliedFilterState]);

  const typeLabel = useMemo(() => (appliedFilterState.type && appliedFilterState.type !== emptyFilterState.type ? labelsById?.get(appliedFilterState.type) : undefined), [appliedFilterState.type, emptyFilterState.type, labelsById]);
  const onClickRemoveType = useCallback(() => {
    setAppliedFilterState({ ...appliedFilterState, type: emptyFilterState.type });
  }, [appliedFilterState, emptyFilterState.type, setAppliedFilterState]);

  const tagLabels = useMemo(() => (labelsById && !isEqual(appliedFilterState.tags, emptyFilterState.tags) ? appliedFilterState.tags?.filter((id) => labelsById.has(id)).map((id) => labelsById?.get(id)!) : undefined), [appliedFilterState.tags, emptyFilterState.tags, labelsById]);
  const onClickRemoveTag = useCallback((labelId: string) => {
    const nextTags = (appliedFilterState.tags ?? []).filter((id) => id !== labelId);
    setAppliedFilterState({ ...appliedFilterState, tags: nextTags.length ? nextTags : emptyFilterState.tags });
  }, [appliedFilterState, emptyFilterState.tags, setAppliedFilterState]);

  const disciplineLabels = useMemo(() => (labelsById && !isEqual(appliedFilterState.disciplines, emptyFilterState.disciplines) ? appliedFilterState.disciplines?.filter((id) => labelsById.has(id)).map((id) => labelsById?.get(id)!) : undefined), [appliedFilterState.disciplines, emptyFilterState.disciplines, labelsById]);
  const onClickRemoveDiscipline = useCallback((labelId: string) => {
    const nextDisciplines = (appliedFilterState.disciplines ?? []).filter((id) => id !== labelId);
    setAppliedFilterState({ ...appliedFilterState, disciplines: nextDisciplines.length ? nextDisciplines : emptyFilterState.disciplines });
  }, [appliedFilterState, emptyFilterState.disciplines, setAppliedFilterState]);

  const buildingLabels = useMemo(() => (labelsById && !isEqual(appliedFilterState.buildings, emptyFilterState.buildings) ? appliedFilterState.buildings?.filter((id) => labelsById.has(id)).map((id) => labelsById?.get(id)!) : undefined), [appliedFilterState.buildings, emptyFilterState.buildings, labelsById]);
  const onClickRemoveBuilding = useCallback((labelId: string) => {
    const nextBuildings = (appliedFilterState.buildings ?? []).filter((id) => id !== labelId);
    setAppliedFilterState({ ...appliedFilterState, buildings: nextBuildings.length ? nextBuildings : emptyFilterState.buildings });
  }, [appliedFilterState, emptyFilterState.buildings, setAppliedFilterState]);

  const floorLabels = useMemo(() => (labelsById && !isEqual(appliedFilterState.floors, emptyFilterState.floors) ? appliedFilterState.floors?.filter((id) => labelsById.has(id)).map((id) => labelsById?.get(id)!) : undefined), [appliedFilterState.floors, emptyFilterState.floors, labelsById]);
  const onClickRemoveFloor = useCallback((labelId: string) => {
    const nextFloors = (appliedFilterState.floors ?? []).filter((id) => id !== labelId);
    setAppliedFilterState({ ...appliedFilterState, floors: nextFloors.length ? nextFloors : emptyFilterState.floors });
  }, [appliedFilterState, emptyFilterState.floors, setAppliedFilterState]);

  const workPhaseLabel = useMemo(() => (appliedFilterState.workPhase && appliedFilterState.workPhase !== emptyFilterState.workPhase ? labelsById?.get(appliedFilterState.workPhase) : undefined), [appliedFilterState.workPhase, emptyFilterState.workPhase, labelsById]);
  const onClickRemoveWorkPhase = useCallback(() => {
    setAppliedFilterState({ ...appliedFilterState, workPhase: emptyFilterState.workPhase });
  }, [appliedFilterState, emptyFilterState.workPhase, setAppliedFilterState]);

  const issueNumberFilter = useMemo(() => (appliedFilterState && appliedFilterState.issueNumber !== emptyFilterState.issueNumber ? `${appliedFilterState.issueNumber}` : undefined), [appliedFilterState, emptyFilterState.issueNumber]);
  const onDeleteIssueNumberFilter = useCallback(() => {
    setAppliedFilterState({ ...appliedFilterState, issueNumber: emptyFilterState.issueNumber });
  }, [appliedFilterState, emptyFilterState.issueNumber, setAppliedFilterState]);

  const titleFilter = useMemo(() => (appliedFilterState && appliedFilterState.title !== emptyFilterState.title ? appliedFilterState.title : undefined), [appliedFilterState, emptyFilterState.title]);
  const onDeleteTitleFilter = useCallback(() => {
    setAppliedFilterState({ ...appliedFilterState, title: emptyFilterState.title });
  }, [appliedFilterState, emptyFilterState.title, setAppliedFilterState]);

  const descriptionFilter = useMemo(() => (appliedFilterState && appliedFilterState.description !== emptyFilterState.description ? appliedFilterState.description : undefined), [appliedFilterState, emptyFilterState.description]);
  const onDeleteDescriptionFilter = useCallback(() => {
    setAppliedFilterState({ ...appliedFilterState, description: emptyFilterState.description });
  }, [appliedFilterState, emptyFilterState.description, setAppliedFilterState]);

  const linkedComponentsGlobalIdsFilter = useMemo(() => (appliedFilterState && !isEqual(appliedFilterState.linkedComponentsGlobalIds, emptyFilterState.linkedComponentsGlobalIds) ? appliedFilterState.linkedComponentsGlobalIds : undefined), [appliedFilterState, emptyFilterState.linkedComponentsGlobalIds]);
  const onDeleteLinkedComponentsGlobalIdsFilter = useCallback((globalIdToDelete: string) => {
    if (!appliedFilterState.linkedComponentsGlobalIds) return;
    const nextLinkedComponentsGlobalIds = appliedFilterState.linkedComponentsGlobalIds.filter((globalId) => globalId !== globalIdToDelete);
    setAppliedFilterState({ ...appliedFilterState, linkedComponentsGlobalIds: nextLinkedComponentsGlobalIds.length ? nextLinkedComponentsGlobalIds : emptyFilterState.linkedComponentsGlobalIds });
  }, [appliedFilterState, emptyFilterState.linkedComponentsGlobalIds, setAppliedFilterState]);

  const visibilityTranslations = useIssueVisibilityTranslations();
  const visibilityFilter = useMemo(() => {
    if (appliedFilterState.visibility !== undefined && (appliedFilterState.visibility?.visibility !== emptyFilterState.visibility?.visibility || appliedFilterState.visibility?.groupIds !== emptyFilterState.visibility?.groupIds)) {
      if (appliedFilterState.visibility.visibility === IssueVisibility.Restricted && appliedFilterState.visibility.groupIds?.length) {
        return `${visibilityTranslations[IssueVisibility.Restricted]}: ${appliedFilterState.visibility.groupIds.join(', ')}`;
      }
      return visibilityTranslations[appliedFilterState.visibility.visibility];
    }
    return undefined;
  }, [appliedFilterState.visibility, emptyFilterState.visibility, visibilityTranslations]);
  const onDeleteVisibilityFilter = useCallback(() => {
    setAppliedFilterState({ ...appliedFilterState, visibility: emptyFilterState.visibility });
  }, [appliedFilterState, emptyFilterState.visibility, setAppliedFilterState]);

  const timeFrameTranslations = useTimeFrameTranslations();
  const [editedFilterOptions, setEditedFilterOptions] = useState<IssueFilterOptions | undefined>(undefined);
  const onClickEditFilter = useCallback((issueFilterOptions: IssueFilterOptions | undefined) => setEditedFilterOptions(issueFilterOptions), []);
  const onCloseFilterDialog = useCallback(() => setEditedFilterOptions(undefined), []);

  const issueFilterOptionsByFieldName = useIssueFilterOptionsByFieldName();

  type DateFilterField = {
    fieldName: 'creationDate' | 'editDate' | 'startingDate' | 'dueDate',
    icon: string,
  };
  const dateFilters = useMemo(() => {
    const fields: DateFilterField[] = [
      { fieldName: 'creationDate', icon: mdiCalendarPlus },
      { fieldName: 'editDate', icon: mdiCalendarEdit },
      { fieldName: 'startingDate', icon: mdiCalendarStart },
      { fieldName: 'dueDate', icon: mdiCalendarEnd },
    ];
    return fields.filter(({ fieldName: prop }) => Boolean(appliedFilterState[prop])).map(({ fieldName: prop, icon }) => {
      const { timeFrame, after, before } = appliedFilterState[prop]!;
      const text = (timeFrame !== undefined)
        ? timeFrameTranslations[timeFrame]
        : (after && before)
          ? `${new Date(after).toLocaleDateString('de-DE')} - ${new Date(before).toLocaleDateString('de-DE')}`
          : after
            ? t('issues-filter-panel_date-filter-after', '{{date}} or later', { date: new Date(after).toLocaleDateString('de-DE') })
            : before
              ? t('issues-filter-panel_date-filter-before', '{{date}} or earlier', { date: new Date(before).toLocaleDateString('de-DE') })
              : '';
      const tooltip = `${columnDisplayNamesByFieldName[prop]}: ${text}`;
      return {
        key: prop,
        text,
        tooltip,
        icon,
        onDelete: () => setAppliedFilterState({ ...appliedFilterState, [prop]: undefined }),
        onClick: () => onClickEditFilter(issueFilterOptionsByFieldName[prop]),
      };
    });
  }, [appliedFilterState, columnDisplayNamesByFieldName, issueFilterOptionsByFieldName, onClickEditFilter, setAppliedFilterState, t, timeFrameTranslations]);

  return (
    <Box
      id="IssuesFilterPanel"
      sx={{
        ...sx,
        px: 4,
        height: 50,
        display: 'flex',
        alignItems: 'center',
        gap: 3,
        boxShadow: 'inset 2px 2px 4px -2px rgba(0,0,0,0.1)',
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Box sx={{ display: 'flex', gap: 1, flexGrow: 1 }}>
        {!!issueNumberFilter && (
          <IconChip
            text={issueNumberFilter}
            tooltip={`${columnDisplayNamesByFieldName.issueNumber}: ${issueNumberFilter}`}
            mdiIconPath={mdiPoundBox}
            onDelete={onDeleteIssueNumberFilter}
            onClick={() => onClickEditFilter(issueFilterOptionsByFieldName.issueNumber)}
          />
        )}
        {!!titleFilter && (
          <IconChip
            text={titleFilter}
            tooltip={`${columnDisplayNamesByFieldName.title}: ${titleFilter}`}
            mdiIconPath={mdiText}
            onDelete={onDeleteTitleFilter}
            onClick={() => onClickEditFilter(issueFilterOptionsByFieldName.title)}
          />
        )}
        {!!descriptionFilter && (
          <IconChip
            text={descriptionFilter}
            tooltip={`${columnDisplayNamesByFieldName.description}: ${descriptionFilter}`}
            mdiIconPath={mdiTextLong}
            onDelete={onDeleteDescriptionFilter}
            onClick={() => onClickEditFilter(issueFilterOptionsByFieldName.description)}
          />
        )}
        {!!linkedComponentsGlobalIdsFilter && (linkedComponentsGlobalIdsFilter.map((globalId) => (
          <IconChip
            key={`globalId_${globalId}`}
            text={globalId}
            mdiIconPath={mdiWrench}
            onDelete={() => onDeleteLinkedComponentsGlobalIdsFilter(globalId)}
            onClick={() => onClickEditFilter(issueFilterOptionsByFieldName.linkedComponentsGlobalIds)}
          />
        )))}
        {!!createAuthor && (
          <IconChip
            text={`${createAuthor.firstName} ${createAuthor.lastName}`}
            tooltip={`${columnDisplayNamesByFieldName.createAuthor}: ${createAuthor.firstName} ${createAuthor.lastName}`}
            mdiIconPath={mdiAccountBadge}
            onDelete={onClickRemoveCreateAuthor}
            onClick={() => onClickEditFilter(issueFilterOptionsByFieldName.createAuthor)}
          />
        )}
        {!!assignedCollaborators && (assignedCollaborators.map((assignedCollaborator) => (
          <IconChip
            key={`assignee_${assignedCollaborator.id}`}
            tooltip={`${columnDisplayNamesByFieldName.assignedUsers}: ${assignedCollaborator.firstName} ${assignedCollaborator.lastName}`}
            text={`${assignedCollaborator.firstName} ${assignedCollaborator.lastName}`}
            mdiIconPath={mdiAccountHardHat}
            onDelete={() => onClickRemoveAssignedCollaborator(assignedCollaborator.id)}
            onClick={() => onClickEditFilter(issueFilterOptionsByFieldName.assignedUsers)}
          />
        ))
        )}
        {!!editAuthor && (
          <IconChip
            text={`${editAuthor.firstName} ${editAuthor.lastName}`}
            tooltip={`${columnDisplayNamesByFieldName.editAuthor}: ${editAuthor.firstName} ${editAuthor.lastName}`}
            mdiIconPath={mdiAccountEdit}
            onDelete={onClickRemoveEditAuthor}
            onClick={() => onClickEditFilter(issueFilterOptionsByFieldName.editAuthor)}
          />
        )}
        {!!reviewer && (
          <IconChip
            text={`${reviewer.firstName} ${reviewer.lastName}`}
            tooltip={`${columnDisplayNamesByFieldName.reviewer}: ${reviewer.firstName} ${reviewer.lastName}`}
            mdiIconPath={mdiAccountCheck}
            onDelete={onClickRemoveReviewer}
            onClick={() => onClickEditFilter(issueFilterOptionsByFieldName.reviewer)}
          />
        )}
        {!!dateFilters && dateFilters.map(({ key, text, tooltip, icon, onDelete, onClick }) => (
          <IconChip
            key={key}
            text={text}
            tooltip={tooltip}
            mdiIconPath={icon}
            onDelete={onDelete}
            onClick={onClick}
          />
        ))}
        {!!disciplineLabels && (disciplineLabels.map((disciplineLabel) => (
          <LabelChip
            key={disciplineLabel.id}
            label={disciplineLabel}
            mdiIconPath={mdiWrench}
            onDelete={() => onClickRemoveDiscipline(disciplineLabel.id)}
            onClick={() => onClickEditFilter(issueFilterOptionsByFieldName.disciplines)}
          />
        )))}
        {!!buildingLabels && (buildingLabels.map((buildingLabel) => (
          <LabelChip
            key={buildingLabel.id}
            label={buildingLabel}
            mdiIconPath={mdiHome}
            onDelete={() => onClickRemoveBuilding(buildingLabel.id)}
            onClick={() => onClickEditFilter(issueFilterOptionsByFieldName.buildings)}
          />
        )))}
        {!!floorLabels && (floorLabels.map((floorLabel) => (
          <LabelChip
            key={floorLabel.id}
            label={floorLabel}
            mdiIconPath={mdiLayers}
            onDelete={() => onClickRemoveFloor(floorLabel.id)}
            onClick={() => onClickEditFilter(issueFilterOptionsByFieldName.floors)}
          />
        )))}
        {!!tagLabels && (tagLabels.map((tagLabel) => (
          <LabelChip
            key={tagLabel.id}
            label={tagLabel}
            mdiIconPath={mdiTag}
            onDelete={() => onClickRemoveTag(tagLabel.id)}
            onClick={() => onClickEditFilter(issueFilterOptionsByFieldName.tags)}
          />
        )))}
        {!!workPhaseLabel && (
          <LabelChip
            label={workPhaseLabel}
            mdiIconPath={mdiFlag}
            onDelete={onClickRemoveWorkPhase}
            onClick={() => onClickEditFilter(issueFilterOptionsByFieldName.workPhase)}
          />
        )}
        {!!statusLabels && (statusLabels.map((statusLabel) => (
          <LabelChip
            key={statusLabel.id}
            label={statusLabel}
            mdiIconPath={mdiStateMachine}
            onDelete={() => onClickRemoveStatus(statusLabel.id)}
            onClick={() => onClickEditFilter(issueFilterOptionsByFieldName.issueStatus)}
          />
        )))}
        {!!priorityLabel && (
          <LabelChip
            label={priorityLabel}
            mdiIconPath={mdiThermometer}
            onDelete={onClickRemovePriority}
            onClick={() => onClickEditFilter(issueFilterOptionsByFieldName.issuePriority)}
          />
        )}
        {!!typeLabel && (
          <LabelChip
            label={typeLabel}
            onDelete={onClickRemoveType}
            onClick={() => onClickEditFilter(issueFilterOptionsByFieldName.issueType)}
          />
        )}
        {!!visibilityFilter && (
          <IconChip
            text={visibilityFilter}
            tooltip={`${columnDisplayNamesByFieldName.visibility}: ${visibilityFilter}`}
            mdiIconPath={mdiEye}
            onDelete={onDeleteVisibilityFilter}
            onClick={() => onClickEditFilter(issueFilterOptionsByFieldName.visibility)}
          />
        )}
      </Box>
      {!!issueIds && <Typography>{t('issues-filter-panel_issue-count', '{{count}} matching issues', { count: issueIds.length })}</Typography>}
      {viewMode === 'list' && <IssuesDataGridColumnOptionsButtonMenu />}
      {!!editedFilterOptions && (
        <IssueFilterDialog
          filterOptions={editedFilterOptions}
          onClose={onCloseFilterDialog}
        />
      )}
    </Box>
  );
}
