import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import IssueResponsibilityActionPanel from 'issues/components/IssueResponsibilityActionPanel';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import useInReviewIssuesStatus from 'issues/hooks/useInReviewIssueStatus';
import useClosedIssueStatus from 'issues/hooks/useClosedIssueStatus';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';

interface CompleteAssignmentPanelProps {
  issueId: string,
  onClose: () => void,
}

export default function IssueCompleteAssignmentPanel({
  issueId,
  onClose,
}: CompleteAssignmentPanelProps) {
  const { t } = useTranslation('issues');
  const { data: issue } = useIssueQuery(issueId);
  const inReviewIssueStatus = useInReviewIssuesStatus();
  const closedIssueStatus = useClosedIssueStatus();

  const commentDefaultText = useMemo(() => {
    if (issue?.reviewer) {
      return t('complete-assignment-panel_comment-default-text-with-reviewer', 'Assignment completed and submitted for review to {{reviewerName}}.', { reviewerName: `${issue.reviewer.firstName} ${issue.reviewer.lastName}` });
    }
    return t('complete-assignment-panel_comment-default-text-without-reviewer', 'Assignment completed.');
  }, [issue, t]);
  const title = useMemo(() => {
    if (issue?.reviewer) {
      return t('complete-assignment-panel_header', 'Submit for review');
    }
    return t('complete-assignment-panel_header', 'Complete assignment');
  }, [issue?.reviewer, t]);
  const targetStatusId = useMemo(() => (issue?.reviewer ? inReviewIssueStatus?.id : closedIssueStatus?.id), [closedIssueStatus, inReviewIssueStatus, issue]);

  if (!targetStatusId || !issue) return <CenteredCircularProgress />;
  return (
    <IssueResponsibilityActionPanel
      id="CompleteAssignmentPanel"
      issueId={issueId}
      title={title}
      onClose={onClose}
      confirmButtonLabel={t('complete-assignment-panel_complete-button-label', 'Complete')}
      commentDefaultText={commentDefaultText}
      targetStatusId={targetStatusId}
      commentMandatory
    />
  );
}
