import React, { Suspense, useCallback, useState } from 'react';
import { Box, Button, styled } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ISxProps from 'common/types/ISxProps';
import IChildren from 'common/types/IChildren';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import { Resizable } from 're-resizable';
import { Direction } from 're-resizable/lib/resizer';

const FoldOutButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.palette.divider,
  color: theme.palette.text.secondary,
  borderRadius: '5px',
  width: '23px',
  height: '38px',
  position: 'absolute',
  right: '-11px',
  padding: 0,
  minWidth: 'unset',
  zIndex: 1,
  top: '50%',
  '&:hover': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
  },
}));

interface LeftUtilityDrawerProps extends ISxProps, IChildren {
}

export default function LeftUtilityDrawer({
  children,
  sx,
}: LeftUtilityDrawerProps) {
  const [isOpen, setIsOpen] = useState(true);

  const [sidebarDefaultWidth, setSidebarDefaultWidth] = useState(() => {
    const storedWidth = localStorage.getItem('documents-data-grid_default-left-sidebar-width');
    if (storedWidth?.length) {
      const parsedWidth = parseInt(storedWidth, 10);
      if (!Number.isNaN(parsedWidth)) return parsedWidth;
    }
    return 350;
  });

  const onResizeStopSidebar = useCallback((event: MouseEvent | TouchEvent, direction: Direction, elementRef: HTMLElement) => {
    localStorage.setItem('documents-data-grid_default-left-sidebar-width', `${elementRef.clientWidth}`);
    setSidebarDefaultWidth(elementRef.clientWidth);
  }, []);

  return (
    <Resizable
      enable={{ top: false, right: true, bottom: false, left: false, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
      defaultSize={{ width: sidebarDefaultWidth, height: 'auto' }}
      maxWidth={isOpen ? undefined : '32px'}
      minWidth={isOpen ? '250px' : '32px'}
      handleComponent={{ right: <Box sx={{ height: '100%', width: '8px', backgroundColor: 'transparent' }}><Box sx={{ height: '100%' }} /></Box> }}
      handleStyles={{ right: { right: 0 } }}
      onResizeStop={onResizeStopSidebar}
    >
      <Box sx={{ ...sx, height: '100%' }}>
        <Suspense fallback={<CenteredCircularProgress />}>
          {isOpen && children}
        </Suspense>
      </Box>
      <FoldOutButton onClick={() => setIsOpen((prev) => !prev)}>
        {isOpen
          ? <ChevronLeftIcon />
          : <ChevronRightIcon />}
      </FoldOutButton>
    </Resizable>
  );
}
