import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export default function ProjectOverviewIcon({
  htmlColor,
  fontSize,
}: Pick<SvgIconProps, 'htmlColor' | 'fontSize'>) {
  return (
    <SvgIcon htmlColor={htmlColor} fontSize={fontSize} viewBox="0 0 16 16">
      <path d="m 7.79625,4.0037675 v -1.59 c 0,-0.89 -1.08,-1.34 -1.71,-0.71 l -5.59,5.59 c -0.39,0.39 -0.39,1.02 0,1.41 l 5.59,5.5899995 c 0.63,0.63 1.71,0.19 1.71,-0.7 v -1.59 h 7 c 0.55,0 1,-0.45 1,-1 V 5.0037675 c 0,-0.55 -0.45,-1 -1,-1 z" />
    </SvgIcon>
  );
}
