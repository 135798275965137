import React, { useCallback } from 'react';
import InboxEmailAttachmentDto from 'emails/types/InboxEmailAttachmentDto';
import DownloadIcon from '@mui/icons-material/Download';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, IconButton, Typography } from '@mui/material';
import useResourceQuery from 'resources/hooks/useResourceQuery';

interface EmailAttachmentItemProps {
  attachment: InboxEmailAttachmentDto;
}

export default function EmailAttachmentItem({ attachment }: EmailAttachmentItemProps) {
  const { data: attachmentUrl } = useResourceQuery(attachment.fileId);

  const downloadAttachment = useCallback(() => {
    const link = document.createElement('a');
    link.href = attachmentUrl || '';
    link.download = attachment.name;
    link.click();
  }, [attachment.name, attachmentUrl]);

  return (
    <Box
      id="EmailAttachmentItem"
      sx={{
        p: 1,
        mt: 2,
        mr: 1,
        display: 'flex',
        alignItems: 'center',
        border: '1px solid rgba(0, 0, 0, .25)',
        gap: 1,
      }}
    >
      <UploadFileIcon fontSize="large" color="primary" />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h6">{attachment.name}</Typography>
        <Typography sx={{ opacity: '50%' }} variant="subtitle1">{`${Math.round(attachment.contentLength / 1024)}KB`}</Typography>
      </Box>
      <Box>
        <IconButton onClick={downloadAttachment}><DownloadIcon color="primary" /></IconButton>
      </Box>
    </Box>
  );
}
