import { Box, Divider, ToggleButton } from '@mui/material';
import IssuePrintContext from 'issues/contexts/IssuePrintContext';
import usePrintColumnTranslations from 'issues/hooks/usePrintColumnTranslations';
import IssuePrintViewColumns from 'issues/types/IssuePrintViewColumns';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

const detailsOnlyColumns = new Set<IssuePrintViewColumns>([
  IssuePrintViewColumns.Description,
  IssuePrintViewColumns.Comments,
  IssuePrintViewColumns.Documents,
  IssuePrintViewColumns.Emails,
  IssuePrintViewColumns.Images,
  IssuePrintViewColumns.Locations,
  IssuePrintViewColumns.Viewpoints,
]);
const tableOnlyColumns = new Set([IssuePrintViewColumns.ThumbnailImage]);

const allColumns = new Set<IssuePrintViewColumns>([
  IssuePrintViewColumns.IssueNumber,
  IssuePrintViewColumns.Title,
  IssuePrintViewColumns.Status,
  IssuePrintViewColumns.Type,
  IssuePrintViewColumns.Priority,
  IssuePrintViewColumns.Tags,
  IssuePrintViewColumns.Assignee,
  IssuePrintViewColumns.Reviewer,
  IssuePrintViewColumns.Creator,
  IssuePrintViewColumns.Created,
  IssuePrintViewColumns.Updated,
  IssuePrintViewColumns.Due,
  IssuePrintViewColumns.Start,
  IssuePrintViewColumns.Disciplines,
  IssuePrintViewColumns.Buildings,
  IssuePrintViewColumns.Floors,
  IssuePrintViewColumns.ThumbnailImage,
  IssuePrintViewColumns.Description,
  IssuePrintViewColumns.Images,
  IssuePrintViewColumns.Locations,
  IssuePrintViewColumns.Viewpoints,
  IssuePrintViewColumns.Emails,
  IssuePrintViewColumns.Documents,
  IssuePrintViewColumns.Comments,
]);

export default function IssuePrintColumnSelection() {
  const { t } = useTranslation('issues');
  const { columnsIncludedInPrint, setColumnsIncludedInPrint, issuesDocument } = useContext(IssuePrintContext);

  const printColumnTranslations = usePrintColumnTranslations();

  const isEnabled = useCallback((columnName: IssuePrintViewColumns) => {
    if (detailsOnlyColumns.has(columnName) && issuesDocument.printTable) return false;
    if (tableOnlyColumns.has(columnName) && !issuesDocument.printTable) return false;
    return true;
  }, [issuesDocument.printTable]);

  const isSelected = useCallback((columnName: IssuePrintViewColumns) => {
    if (!isEnabled(columnName)) return false;
    return columnsIncludedInPrint.has(columnName);
  }, [columnsIncludedInPrint, isEnabled]);

  const onClickField = useCallback((columnName: IssuePrintViewColumns) => {
    if (columnsIncludedInPrint.has(columnName)) {
      setColumnsIncludedInPrint((prevColumnNames) => {
        const newColumnNames = new Set(prevColumnNames);
        newColumnNames.delete(columnName);
        return newColumnNames;
      });
    } else {
      setColumnsIncludedInPrint((prevColumnNames) => {
        const newColumnNames = new Set(prevColumnNames);
        newColumnNames.add(columnName);
        return newColumnNames;
      });
    }
  }, [columnsIncludedInPrint, setColumnsIncludedInPrint]);

  const onToggleAll = useCallback((selectAll: boolean) => {
    if (selectAll) {
      setColumnsIncludedInPrint((prevColumnNames) => {
        const newColumnNames = new Set(prevColumnNames);
        allColumns.forEach((columnName) => {
          if (isEnabled(columnName)) {
            newColumnNames.add(columnName);
          }
        });
        return newColumnNames;
      });
    } else {
      setColumnsIncludedInPrint((prevColumnNames) => {
        const newColumnNames = new Set(prevColumnNames);
        allColumns.forEach((columnName) => {
          if (isEnabled(columnName)) {
            newColumnNames.delete(columnName);
          }
        });
        return newColumnNames;
      });
    }
  }, [isEnabled, setColumnsIncludedInPrint]);

  return (
    <Box id="IssuePrintColumnSelection" sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, flexWrap: 'wrap' }}>
        {Array.from(allColumns).map((columnName) => (
          <ToggleButton
            key={columnName}
            size="small"
            color="primary"
            value={columnName}
            selected={isSelected(columnName)}
            disabled={!isEnabled(columnName)}
            onClick={() => onClickField(columnName)}
          >
            {printColumnTranslations[columnName]}
          </ToggleButton>
        ))}
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <ToggleButton value="all" onClick={() => onToggleAll(true)}>{t('issue-print-column-selection_select-all', 'Select All')}</ToggleButton>
        <ToggleButton value="none" onClick={() => onToggleAll(false)}>{t('issue-print-column-selection_deselect-all', 'Deselect All')}</ToggleButton>
      </Box>
    </Box>
  );
}
