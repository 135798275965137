import { Color, Vector3 } from "three";
import { VisualState } from "./BaseDefinitions";

type Vector3d = { x: number, y: number, z: number };

export interface CameraInfo {
  isPerspective: boolean;
  location: Vector3d;
  direction: Vector3d;
  up: Vector3d;
  factor: number;
}

export interface Section {
  location: Vector3;
  normal: Vector3;
}

export interface VisoViewpoint {
  camera: CameraInfo;
  sections?: Section[];
  visualState?: VisualState;
  bubble?: Vector3;
  image?: string;
  imageId?: string;
}

export interface BubbleInfo {
  title: string;
  issueId: string;
  color: Color;
  position: Vector3;
  linkedComponentsGlobalIds: string[];
}

export enum VisoDataType {
  shape = "shape",
  style = "style",
  modelfile = "modelfile",
}

//bubble action state
export enum BubbleState {
  BUBBLE_NONE = "none_bubble",
  BUBBLE_CREATING = "creating_bubble",
  BUBBLE_CREATED = "created_bubble",
}
