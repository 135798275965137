import React, { ChangeEvent, useCallback, useState } from 'react';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import usePasswordResetRequestMutation from 'users/hooks/usePasswordResetRequestMutation';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function PasswordResetRequestDialog() {
  const { t } = useTranslation('users');
  const navigate = useNavigate();
  const { mutateAsync: mutatePasswordRequestAsync } = usePasswordResetRequestMutation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const getRequestErrorMessage = useRequestErrorMessage();
  const [email, setEmail] = useState('');
  const onChangeEmail = useCallback((event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value), []);
  const [wasResetLinkSent, setWasResetLinkSent] = useState(false);
  const onClickConfirm = useCallback(async () => {
    try {
      setErrorMessage(undefined);
      await mutatePasswordRequestAsync({ email });
      setWasResetLinkSent(true);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [email, getRequestErrorMessage, mutatePasswordRequestAsync]);
  const onClickCancel = useCallback(() => navigate('/login'), [navigate]);

  return (
    <Dialog open id="PasswordResetRequestDialog" hideBackdrop>
      <DialogTitle>{t('password-reset-request-dialog_title', 'Reset Password')}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {!wasResetLinkSent && (
            <>
              <Alert severity="info">{t('password-reset-request-dialog_reset-link-instructions', 'Enter the email address associated with your account and confirm to receive a password reset email.')}</Alert>
              <TextField
                value={email}
                onChange={onChangeEmail}
                label={t('password-reset-request-dialog_email-text-field-label', 'Account Email Address')}
              />
            </>
          )}
          {!!errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          {!!wasResetLinkSent && (
            <Alert severity="info">{t('password-reset-request-dialog_reset-link-sent-message', 'An email has been sent to the specified address. Please click the link inside that email to create a new password. You can close this browser window.')}</Alert>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {!wasResetLinkSent && (
          <>
            <Button variant="contained" color="secondary" onClick={onClickCancel} sx={{ mr: 'auto' }}>
              {t('password-reset-request-dialog_cancel-button-label', 'Cancel')}
            </Button>
            <Button variant="contained" color="primary" onClick={onClickConfirm}>
              {t('password-reset-request-dialog_confirm-button-label', 'Request Reset Link')}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
