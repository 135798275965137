import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, AlertTitle, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import FolderAccessType from 'documents-folders/types/FolderAccessType';
import FolderAccessUpdateDto from 'documents-folders/types/FolderAccessUpdateDto';
import UpdateFolderNameDto from 'documents-folders/types/UpdateFolderNameDto';
import useFolder from 'documents-folders/hooks/useFolder';
import useFolderUpdateAccessMutation from 'documents-folders/hooks/useFolderUpdateAccessMutation';
import useFolderUpdateNameMutation from 'documents-folders/hooks/useFolderUpdateNameMutation';
import useFolderIconName from 'documents-folders/hooks/useFolderIconName';
import FolderFormValues from 'documents-folders/types/FolderFormValues';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import FolderSettingsPanel from 'documents-folders/components/FolderSettingsPanel';
import FolderBreadcrumbs from 'documents-folders/components/FolderBreadcrumbs';
import HorizontalDivider from 'common/styled/HorizontalDivider';
import useCurrentCollaboratorQuery from 'collaborators/hooks/useCurrentCollaboratorQuery';
import useCollaboratorGroupsQuery from 'collaborators-groups/hooks/useCollaboratorGroupsQuery';
import { useQueryClient } from '@tanstack/react-query';
import useFolderTreeQueryKey from 'documents-folders/hooks/useFolderTreeQueryKey';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useSiblingFolderNames from 'documents-folders/hooks/useSiblingFolderNames';
import InfoIcon from '@mui/icons-material/Info';
import EffectiveAccessRightsDialog from 'documents-folders/components/EffectiveAccessRightsDialog';
import _ from 'lodash';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import useDocumentsViewNavigationContext from 'documents/hooks/useDocumentsViewNavigationContext';
import useCollaboratorsQuery from 'collaborators/hooks/useCollaboratorsQuery';

interface EditFolderDialogProps {
  folderId: string,
  onClose: () => void;
}

export default function EditFolderDialog({
  folderId,
  onClose,
}: EditFolderDialogProps) {
  const { t } = useTranslation('documents-folders');
  const folder = useFolder(folderId);
  const folderIcon = useFolderIconName(folder?.accessType);
  const { mutateAsync: mutateFolderAccessAsync, isPending: isLoadingAccessUpdate } = useFolderUpdateAccessMutation();
  const { mutateAsync: mutateFolderNameAsync, isPending: isLoadingNameUpdate } = useFolderUpdateNameMutation();
  const { setDocumentScope } = useDocumentsViewNavigationContext();
  const { data: currentCollaborator } = useCurrentCollaboratorQuery();
  const { data: collaborators } = useCollaboratorsQuery();
  const collaboratorIdsSet = useMemo(() => (collaborators ? new Set(collaborators.map((c) => c.id)) : undefined), [collaborators]);
  const { data: collaboratorGroups } = useCollaboratorGroupsQuery();
  const collaboratorGroupIdsSet = useMemo(() => (collaboratorGroups ? new Set(collaboratorGroups.map((g) => g.id)) : undefined), [collaboratorGroups]);
  const currentCollaboratorCollaboratorGroupIds = useMemo(() => {
    if (!currentCollaborator || !collaboratorGroups) return undefined;
    return new Set<string>(currentCollaborator.collaboratorGroupIds);
  }, [currentCollaborator, collaboratorGroups]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const getRequestErrorMessage = useRequestErrorMessage();

  const [folderFormValues, setFolderFormValues] = useState<FolderFormValues | undefined>(undefined);
  useEffect(() => {
    if (folderFormValues || !folder || !collaboratorIdsSet || !collaboratorGroupIdsSet) return; // init
    setFolderFormValues({
      name: folder.name,
      inheritAccess: folder.inheritAccess,
      accessType: folder.accessType,
      permissions: folder?.accessType === FolderAccessType.Restricted ? {
        accessCollaboratorIds: folder?.accessCollaboratorIds.filter((id) => collaboratorIdsSet.has(id)) ?? [],
        readOnlyCollaboratorIds: folder?.readOnlyCollaboratorIds.filter((id) => collaboratorIdsSet.has(id)) ?? [],
        accessGroupsIds: folder?.accessGroupsIds.filter((id) => collaboratorGroupIdsSet.has(id)) ?? [],
        readOnlyGroupsIds: folder?.readOnlyGroupsIds.filter((id) => collaboratorGroupIdsSet.has(id)) ?? [],
      } : folder?.accessType === FolderAccessType.Public ? {
        accessCollaboratorIds: [],
        readOnlyCollaboratorIds: folder?.readOnlyCollaboratorIds.filter((id) => collaboratorIdsSet.has(id)) ?? [],
        accessGroupsIds: [],
        readOnlyGroupsIds: folder?.readOnlyGroupsIds.filter((id) => collaboratorGroupIdsSet.has(id)) ?? [],
      } : {
        accessCollaboratorIds: [],
        readOnlyCollaboratorIds: [],
        accessGroupsIds: [],
        readOnlyGroupsIds: [],
      },
    });
  }, [collaboratorIdsSet, folder, folderFormValues, collaboratorGroupIdsSet]);

  const onChangeFolderForm = useCallback((values: FolderFormValues) => {
    setFolderFormValues((prevValues) => {
      if (!prevValues) return prevValues;
      if (!prevValues.inheritAccess && values.inheritAccess && folder?.parent) {
        // if we set inheritAccess to true, we want to display the parent folder's access config
        if (folder.parent?.accessType === FolderAccessType.Restricted) {
          // if the parent is restricted, we want to display the parent's access id selection with disabled checkboxes
          return {
            ...values,
            accessType: folder.parent?.accessType,
            accessCollaboratorIds: folder.parent?.accessCollaboratorIds ?? [],
          };
        }
        // if the parent is not restricted, we deliberately keep the current accessCollaboratorIds list
        // to avoid clearing the user's selection. We have to clear this list from the dto for non-restricted folders on submitting the form (see submit handler).
        return {
          ...values,
          accessType: folder.parent?.accessType,
        };
      }
      return values;
    });
  }, [folder]);

  const queryClient = useQueryClient();
  const folderTreeQueryKey = useFolderTreeQueryKey();

  const hasReadAccessToNewFolder = useMemo(() => {
    if (!folderFormValues || !currentCollaborator || !currentCollaboratorCollaboratorGroupIds) return undefined;
    if (folderFormValues.accessType !== FolderAccessType.Restricted) return true;
    const userHasIndividualReadAccess = folderFormValues.permissions.accessCollaboratorIds.some((id) => id === currentCollaborator.id);
    const userHasGroupReadAccess = folderFormValues.permissions.accessGroupsIds.some((id) => currentCollaboratorCollaboratorGroupIds.has(id));
    return userHasIndividualReadAccess || userHasGroupReadAccess;
  }, [currentCollaborator, currentCollaboratorCollaboratorGroupIds, folderFormValues]);

  const isPermissionConfigRestrictedAndEmpty = useMemo(() => {
    if (!folderFormValues) return undefined;
    return folderFormValues.accessType === FolderAccessType.Restricted && (!folderFormValues.permissions.accessCollaboratorIds.length && !folderFormValues.permissions.accessGroupsIds.length);
  }, [folderFormValues]);
  const existingSiblingFolderNames = useSiblingFolderNames(folder?.parent?.id);
  const canConfirm = useMemo(() => (
    !isLoadingNameUpdate
    && !isLoadingAccessUpdate
    && folderFormValues
    && folderFormValues.name.length
    && !isPermissionConfigRestrictedAndEmpty
    && (folder && (folderFormValues.name === folder.name || existingSiblingFolderNames?.has(folderFormValues.name) === false))
  ), [isLoadingNameUpdate, isLoadingAccessUpdate, folderFormValues, isPermissionConfigRestrictedAndEmpty, folder, existingSiblingFolderNames]);

  const persistFolderName = useCallback(async () => {
    if (!folderFormValues || !folder) throw new Error('dependency error');
    if (folderFormValues.name !== folder.name) {
      const updateFolderNameDto: UpdateFolderNameDto = {
        id: folder.id,
        name: folderFormValues.name,
      };
      await mutateFolderNameAsync(updateFolderNameDto);
    }
  }, [folder, folderFormValues, mutateFolderNameAsync]);

  const persistFolderAccess = useCallback(async () => {
    if (!folderFormValues || !folder || !currentCollaborator || !currentCollaboratorCollaboratorGroupIds) throw new Error('dependency error');

    const {
      accessType,
      inheritAccess,
      permissions,
    } = folderFormValues;
    const {
      accessCollaboratorIds,
      accessGroupsIds,
      readOnlyCollaboratorIds,
      readOnlyGroupsIds,
    } = permissions;

    // Check if the user changed the permissions. If yes, create an access-update dto and send it to the API.
    // The isEqual array comparisons don't check for set-equality, but we're only really interested if the user touched those settings at all.

    // if nothing changed we can just return early
    if (inheritAccess === folder.inheritAccess
      && accessType === folder.accessType
      && _.isEqual(accessCollaboratorIds, folder.accessCollaboratorIds)
      && _.isEqual(accessGroupsIds, folder.accessGroupsIds)
      && _.isEqual(readOnlyCollaboratorIds, folder.readOnlyCollaboratorIds)
      && _.isEqual(readOnlyGroupsIds, folder.readOnlyGroupsIds)
    ) {
      return;
    }

    // we only need to send this property and the following ones if the folder is not set to "inherit access".
    const accessTypeUpdate = !inheritAccess ? { accessType } : undefined;

    // read-only restrictions are only relevant for public and restricted folders
    const readOnlyCollaboratorIdsUpdate = !inheritAccess && accessType !== FolderAccessType.Private ? { readOnlyCollaboratorIds } : undefined;
    const readOnlyGroupsIdsUpdate = !inheritAccess && accessType !== FolderAccessType.Private ? { readOnlyGroupsIds } : undefined;

    // explicit access rights are only relevant for restricted folders
    const accessCollaboratorIdsUpdate = !inheritAccess && accessType === FolderAccessType.Restricted ? { accessIds: accessCollaboratorIds } : undefined;
    const accessGroupsIdsUpdate = !inheritAccess && accessType === FolderAccessType.Restricted ? { accessGroupsIds } : undefined;

    if (folderFormValues.inheritAccess !== folder.inheritAccess
      || accessTypeUpdate
      || accessCollaboratorIdsUpdate
      || accessGroupsIdsUpdate
      || readOnlyCollaboratorIdsUpdate
      || readOnlyGroupsIdsUpdate
    ) {
      const folderAccessUpdateDto: FolderAccessUpdateDto = {
        id: folder.id,
        inheritAccess: folderFormValues.inheritAccess,
        ...accessTypeUpdate,
        ...accessCollaboratorIdsUpdate,
        ...accessGroupsIdsUpdate,
        ...readOnlyCollaboratorIdsUpdate,
        ...readOnlyGroupsIdsUpdate,
      };
      await mutateFolderAccessAsync(folderAccessUpdateDto);
      if (!hasReadAccessToNewFolder) {
        setDocumentScope({ key: DocumentScopeKey.ProjectDocuments });
        queryClient.invalidateQueries({ queryKey: [folderTreeQueryKey] });
      }
    }
  }, [currentCollaborator, currentCollaboratorCollaboratorGroupIds, folder, folderFormValues, folderTreeQueryKey, hasReadAccessToNewFolder, mutateFolderAccessAsync, queryClient, setDocumentScope]);

  const onConfirm = useCallback(async () => {
    try {
      await persistFolderName();
      await persistFolderAccess();
      onClose();
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [getRequestErrorMessage, onClose, persistFolderAccess, persistFolderName]);

  const showAccessWarning = useMemo(() => hasReadAccessToNewFolder === false && !isPermissionConfigRestrictedAndEmpty, [hasReadAccessToNewFolder, isPermissionConfigRestrictedAndEmpty]);

  const [isEffectiveAccessDialogOpen, setIsEffectiveAccessDialogOpen] = useState(false);
  const onClickShowEffectiveAccessRightsDialog = useCallback(() => setIsEffectiveAccessDialogOpen(true), []);
  const onCloseEffectiveAccessDialog = useCallback(() => setIsEffectiveAccessDialogOpen(false), []);

  return (
    <Dialog
      id="EditFolderDialog"
      open
      PaperProps={{ sx: { width: '750px', maxWidth: 'unset', maxHeight: '100vh' } }}
    >
      <DialogTitle sx={{ display: 'flex', pb: 0 }}>
        {folderIcon && (
          <Box sx={{ width: '50px', height: '50px' }}>
            <svg viewBox="0 0 32 32">
              <use xlinkHref={`/img/sprite.svg#${folderIcon}`} />
            </svg>
          </Box>
        )}
        {t('edit-folder-dialog_title', 'Edit Folder')}
      </DialogTitle>
      <HorizontalDivider />
      <DialogContent sx={{ pt: 1 }}>
        <FolderBreadcrumbs folderId={folderId} suppressInteraction />
        {folder ? (
          <FolderSettingsPanel
            folderId={folderId}
            parentFolderId={!folder.isTopLevel ? folder.parentId : undefined}
            folderFormValues={folderFormValues}
            onChange={onChangeFolderForm}
            sx={{ mt: 1 }}
            disabled={isLoadingNameUpdate || isLoadingAccessUpdate}
          />
        ) : <CenteredCircularProgress sx={{ height: '280px' }} />}
        {!!errorMessage && (
        <Alert severity="error">
          <AlertTitle>{t('edit-folder-dialog_error-title', 'Error')}</AlertTitle>
          {errorMessage}
        </Alert>
        )}
        {!!showAccessWarning && (
        <Alert severity="warning" sx={{ mt: 1 }}>
          <AlertTitle>{t('edit-folder-dialog_access-warning-title', 'Warning')}</AlertTitle>
          {t('edit-folder-dialog_access-warning-message', 'With the current permission configuration you will lose access to the new folder.')}
        </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          id="EditFolderDialogCancelButton"
          variant="contained"
          color="secondary"
          onClick={onClose}
          sx={{ mr: 'auto' }}
        >
          {t('edit-folder-dialog_cancel-button-label', 'Cancel')}
        </Button>
        <Button
          id="EditFolderDialogShowEffectiveAccessRightsButton"
          variant="text"
          color="primary"
          disabled={!folderFormValues}
          onClick={onClickShowEffectiveAccessRightsDialog}
          sx={{ pl: 1, ml: 'auto' }}
        >
          <InfoIcon sx={{ mr: 0.5 }} />
          {t('folder-settings-panel_effective-access-rights-button-label', 'Show me who will have access')}
        </Button>
        {isEffectiveAccessDialogOpen && <EffectiveAccessRightsDialog folderFormValues={folderFormValues} onClose={onCloseEffectiveAccessDialog} />}
        <Button
          id="EditFolderDialogConfirmButton"
          variant="contained"
          color="primary"
          onClick={onConfirm}
          disabled={!canConfirm || isLoadingNameUpdate || isLoadingAccessUpdate}
          sx={{ minWidth: '160px', ml: 1 }}
        >
          {t('edit-folder-dialog_confirm-button-label', 'Save Changes')}
          {(isLoadingNameUpdate || isLoadingAccessUpdate) && <CircularProgress size={12} sx={{ ml: 1 }} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
