import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DocumentSettingsStatusesSection from 'settings/components/DocumentSettingsStatusesSection';
import DocumentSettingsVersionsSection from 'settings/components/DocumentSettingsVersionsSection';
import DocumentSettingsDocumentStampSection from 'settings/components/DocumentSettingsDocumentStampSection';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';
import RoleAction from 'projects/types/RoleAction';

export default function DocumentSettingsTabPane() {
  const { t } = useTranslation('settings');
  const allowedActions = useAllowedActions();
  return (
    <Box
      id="DocumentSettingsTabPane"
      sx={{
        height: '100%',
        py: 4,
        pr: 4,
        display: 'grid',
        gridTemplateColumns: 'minmax(auto, 200px) auto 4fr',
      }}
    >
      <Box />
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 1000,
        minWidth: 600,
        gap: 6,
        py: 4,
        px: 8,
      }}
      >
        <Box>
          <Typography variant="h2" sx={{ mb: 1, mr: 'auto' }}>{t('document-settings-tab-pane_title', 'Document Settings')}</Typography>
        </Box>
        {!!allowedActions?.has(RoleAction.Label_Special) && (
          <>
            <Divider />
            <DocumentSettingsStatusesSection />
          </>
        )}
        {!!allowedActions?.has(RoleAction.Project_Edit_DmsSettings) && (
          <>
            <Divider />
            <DocumentSettingsVersionsSection />
          </>
        )}
        {!!allowedActions?.has(RoleAction.DocStamp_Edit) && (
          <>
            <Divider />
            <DocumentSettingsDocumentStampSection />
          </>
        )}
      </Box>
    </Box>
  );
}
