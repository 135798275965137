import { Intersection } from "three";
import { SelectionData, VisoNode } from "../../../BaseDefinitions";
import { IFCModel } from "../../../IFCModel";
import { LiteEvent } from "../../../utils/LiteEvent";
import { VisoplanLoader } from "../../../VisoplanLoader";
import { IfcContext } from "../../context";

export class PickSelection {
  public readonly onFound = new LiteEvent<SelectionData>();
  private model: IFCModel | null = null;

  constructor(private context: IfcContext, private loader: VisoplanLoader) { }

  pick = async (item: Intersection, ctrl: boolean, focusSelection: boolean) => {
    if (item.faceIndex == null) return;

    const globalId = this.loader.ifcManager.getGlobalId(item);

    if (globalId == null) return;

    // @ts-ignore
    const modelId = item.object.modelId;
    const model = this.loader.ifcManager.pickComponent(modelId, globalId, ctrl);

    this.setModel(model);

    if (focusSelection) {
      this.zoomToSelectedComponent();
    }

    this.onFound.trigger(this.loader.ifcManager.subsets.getSelectedData());
  };

  unpick = () => {
    if (this.model) {
      if (this.model.visible == false) return;
      this.loader.ifcManager.hidePickComponent();
      this.trigerZeroData();
    }
  };

  reset() {
    this.model = null;
    this.trigerZeroData();
  }

  zoomToSelectedComponent() {
    if (this.model?.solid) {
      this.context.ifcCamera.targetItem(this.model.solid);
    }
  }

  hideSelectedComponent() {
    this.loader.ifcManager.subsets.hideSelectedComponent();
    this.trigerZeroData();
  }

  removeComponentFromSelection(component: VisoNode) {
    this.loader.ifcManager.removeComponentFromSelection(component);
    this.onFound.trigger(this.loader.ifcManager.subsets.getSelectedData());
  }

  setVisualModel(model: IFCModel) {
    this.setModel(model);
    this.onFound.trigger(this.loader.ifcManager.subsets.getSelectedData());
  }

  pickComponents(components: VisoNode[]) {
    if (components.length) {
      this.setModel(this.loader.ifcManager.subsets.pickComponents(components));
      this.onFound.trigger(this.loader.ifcManager.subsets.getSelectedData());
    }
  }

  private trigerZeroData() {
    const data: SelectionData = { count: 0, isTrans: true, components: [] };
    this.onFound.trigger(data);
  }

  private setModel(model: IFCModel | null) {
    if (this.model == null && model != null) {
      this.model = model;
      this.context.getScene().add(this.model);
      if (this.model.solid) {
        this.context.items.pickableIfcModels.push(this.model.solid);
      }
    }
  }
}
