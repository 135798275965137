import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useMemo } from 'react';
import useSetupTwoFactorAuthQueryKey, { SetupTwoFactorAuthQueryKey } from 'users/hooks/useSetupTwoFactorAuthQueryKey';
import useSetupTwoFactorAuthQueryOptions from 'users/hooks/useSetupTwoFactorAuthQueryOptions';
import TwoFactorSetupDto from 'users/types/TwoFactorSetupDto';

// TODO: can this be a default singleton details query?
export default function useSetupTwoFactorAuthQuery(options?: Omit<UseQueryOptions<TwoFactorSetupDto | undefined, unknown, TwoFactorSetupDto | undefined, SetupTwoFactorAuthQueryKey>, 'queryKey' | 'queryFn'> | undefined) {
  const queryKey = useSetupTwoFactorAuthQueryKey();
  const setupTwoFactorAuthQueryOptions = useSetupTwoFactorAuthQueryOptions();
  const queryOptions = useMemo<UseQueryOptions<TwoFactorSetupDto | undefined, unknown, TwoFactorSetupDto | undefined, SetupTwoFactorAuthQueryKey>>(() => ({
    queryKey,
    ...setupTwoFactorAuthQueryOptions,
    ...options,
  }), [setupTwoFactorAuthQueryOptions, options, queryKey]);
  return useQuery(queryOptions);
}
