import { useEffect, useState } from 'react';

/** Returns a ```Date``` object representing the beginning of the current day in local time.
 * This hook uses ```setInterval``` to periodically check and update the returned Date object whenever the current day actually changes. */
export default function useToday() {
  const [today, setToday] = useState(() => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
  });
  useEffect(() => {
    const handle = setInterval(() => {
      const now = new Date();
      const candidate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      setToday((prev) => (candidate > prev ? candidate : prev));
    }, 60000); // check once per minute if day changed
    return () => clearInterval(handle);
  }, [today]);
  return today;
}
