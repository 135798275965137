import { LineSegments, Mesh, Vector3 } from "three";
import { IFCManager } from "./IFCManager";
import { VisoInstanceMesh } from "./VisoInstanceMesh";
import { VisoSolidMesh } from "./VisoSolideMesh";

/**
 * Represents an IFC model. This object is returned by the `IFCLoader` after loading an IFC.
 * @geometry `THREE.Buffergeometry`, see Three.js documentation.
 * @materials `THREE.Material[]`, see Three.js documentation.
 * @manager contains all the logic to work with IFC.
 */
export class IFCModel extends Mesh {
  ifcManager: IFCManager | null = null;
  solid: VisoSolidMesh | null = null;
  wireframe: LineSegments | null = null;
  instancedMeshes: VisoInstanceMesh[] = [];
  globalIds: { [expresId: number]: string } = {};
  openingIds: string[] = []; // List of Global Id for IFC Opening Element
  spaceIds: string[] = []; // List of Global Id for IFC Space Element
  center?: Vector3;
  size?: Vector3;
  storeys?: any;
  modelFileId: string = "";

  setIFCManager(manager: IFCManager) {
    this.ifcManager = manager;
  }

  dispose() {
    if (this.solid != null) {
      this.solid.geometry?.dispose();
    }

    if (this.wireframe != null) {
      this.wireframe.geometry?.dispose();
    }

    this.instancedMeshes.forEach((m) => {
      m.geometry?.dispose();
    });
  }
}
