enum FileType {
  Video = 0,
  Audio,
  Image,
  Word,
  PowerPoint,
  Pdf,
  Ifc,
  Excel,
  DWG,
  DXF,
  ArchiCAD,
  Revit,
  SMC,
  Navisworks,
  Misc,
}

export default FileType;
