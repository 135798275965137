import React, { ReactNode, useState, useMemo, useEffect } from 'react';
import DocumentFilterContext, { DocumentFilterContextState } from 'documents-filter/contexts/DocumentFilterContext';
import useDocumentFilterPersistableState from 'documents-filter/hooks/useDocumentFilterPersistableState';
import useDocumentScopeOdataQuery from 'documents-filter/hooks/useDocumentScopeOdataQuery';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import DocumentSortDefinition from 'documents-filter/types/DocumentSortDefinition';
import DocumentListMode from 'documents-filter/types/DocumentListMode';
import DocumentScope from 'documents/types/DocumentScope';
import useDocumentsViewNavigationContext from 'documents/hooks/useDocumentsViewNavigationContext';

interface DocumentFilterContextProviderProps {
  children?: ReactNode,
}

export default function DocumentFilterContextProvider({
  children,
}: DocumentFilterContextProviderProps) {
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const { documentScope } = useDocumentsViewNavigationContext();
  const filterId = useMemo(() => (documentScope.key === DocumentScopeKey.Filter ? documentScope.id : undefined), [documentScope]);
  const documentFilterPersistableState = useDocumentFilterPersistableState({ filterId });
  const [searchString, setSearchString] = useState<string | undefined>(undefined);
  const [sortDefinitions, setSortDefinitions] = useState<DocumentSortDefinition[]>([]);
  const [documentListMode, setDocumentListMode] = useState<DocumentListMode>(localStorage.getItem('documentListMode') as DocumentListMode ?? DocumentListMode.CurrentVersionOnly);
  const odataQuery = useDocumentScopeOdataQuery(documentFilterPersistableState.appliedFilterState, searchString, sortDefinitions, documentListMode);
  const [lastDocumentScopeBeforeFilterBecameActive, setLastDocumentScopeBeforeFilterBecameActive] = useState<DocumentScope | undefined>(undefined);

  useEffect(() => {
    const storedListMode = localStorage.getItem('documentListMode') as DocumentListMode | null;
    if (documentListMode !== storedListMode) localStorage.setItem('documentListMode', documentListMode);
  }, [documentListMode]);

  const { resetFilterState } = documentFilterPersistableState;
  useEffect(() => {
    if (documentScope.key !== DocumentScopeKey.Filter) {
      setLastDocumentScopeBeforeFilterBecameActive(documentScope);
      resetFilterState();
    }
  }, [documentScope, resetFilterState]);

  const state: DocumentFilterContextState = useMemo(() => ({
    ...documentFilterPersistableState,
    isFilterDrawerOpen,
    setIsFilterDrawerOpen,
    searchString,
    setSearchString,
    odataQuery,
    sortDefinitions,
    setSortDefinitions,
    documentListMode,
    setDocumentListMode,
    lastDocumentScopeBeforeFilterBecameActive,
  }), [documentFilterPersistableState, isFilterDrawerOpen, odataQuery, searchString, sortDefinitions, documentListMode, setDocumentListMode, lastDocumentScopeBeforeFilterBecameActive]);

  return (
    <DocumentFilterContext.Provider value={state}>
      {children}
    </DocumentFilterContext.Provider>
  );
}
