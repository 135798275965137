import React, { useCallback, useState } from 'react';
import { Button } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import CreateIssueDialog from 'issues/components/CreateIssueDialog';
import useModelsInteractionContext from 'models/hooks/useModelsInteractionContext';
import ModelsDialog from 'models/components/ModelsDialog';
import { ModelsInteractionMode } from 'models/contexts/ModelsInteractionContext';
import ModelsInteractionContextProvider from 'models/contexts/ModelsInteractionContextProvider';

interface IssuesPageCreateIssueButtonProps extends ISxProps {
  onConfirmCreateIssueDialog: (createdIssueId: string | undefined, keepOpen: boolean) => void,
}

function Content({
  sx,
  onConfirmCreateIssueDialog,
}: IssuesPageCreateIssueButtonProps) {
  const { t } = useTranslation('issues');
  const { interactionMode } = useModelsInteractionContext();
  const [createIssueDialogOpen, setCreateIssueDialogOpen] = useState(false);
  const [createIssueSelectedIssueId, setCreateIssueSelectedIssueId] = useState<string | undefined>(undefined);
  const onClickCreateIssue = useCallback(() => setCreateIssueDialogOpen(true), []);
  const onCancelDialog = useCallback(() => setCreateIssueDialogOpen(false), []);
  const onConfirmDialog = useCallback((createdIssueId: string | undefined, keepOpen: boolean) => {
    if (!keepOpen) {
      setCreateIssueDialogOpen(false);
    }
    onConfirmCreateIssueDialog(createdIssueId, keepOpen);
  }, [onConfirmCreateIssueDialog]);

  return (
    <>
      <Button variant="contained" color="secondary" sx={{ pl: 1.5, gap: 0.5, ...sx }} onClick={onClickCreateIssue}>
        <Icon path={mdiPlus} size={1} />
        {t('issues-page-header_create-issue-button-label', 'Create Issue')}
      </Button>
      {!!createIssueDialogOpen && (
        <CreateIssueDialog
          open={createIssueDialogOpen}
          onCancel={onCancelDialog}
          onConfirm={onConfirmDialog}
        />
      )}
      {!!interactionMode && (
        <ModelsDialog
          selectedIssueId={createIssueSelectedIssueId}
          setSelectedIssueId={setCreateIssueSelectedIssueId}
          title={interactionMode === ModelsInteractionMode.ViewpointManagement
            ? t('issues-page-create-issue-button_manage-viewpoints-dialog-title', 'Manage Viewpoints')
            : t('issues-page-create-issue-button_manage-linked-components-dialog-title', 'Manage Linked Components')}
        />
      )}
    </>
  );
}

export default function IssuesPageCreateIssueButton(props: IssuesPageCreateIssueButtonProps) {
  return (
    <ModelsInteractionContextProvider>
      <Content {...props} />
    </ModelsInteractionContextProvider>
  );
}
