import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import LabelChip from 'labels/components/LabelChip';
import IssueDto from 'issues/types/IssueDto';
import useProjectIssuePrioritiesQuery from 'issues/hooks/useProjectIssuePrioritiesQuery';

export default function PriorityChipCell({
  value,
}: ICellRendererParams<IssueDto, String>) {
  const { data: priorities } = useProjectIssuePrioritiesQuery();
  if (!priorities) return null;
  const priority = priorities.find((s) => s.id === value);
  if (!priority) return null;
  return <LabelChip key={priority.id} sx={{ mr: 1 }} label={priority} />;
}
