import React, { useMemo } from 'react';
import { useTheme } from '@mui/material';
import useResourcesQuery from 'resources/hooks/useResourcesQuery';
import Lightbox from 'yet-another-react-lightbox';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

interface ImageGalleryDialogProps {
  resourceIds: string[] | undefined,
  onClose: () => void,
}

export default function ImageGalleryDialog({
  resourceIds,
  onClose,
}: ImageGalleryDialogProps) {
  const theme = useTheme();
  const { data: resources } = useResourcesQuery(resourceIds);
  const slides = useMemo(() => resources?.map((r) => ({ src: r })), [resources]);

  return (
    <Lightbox
      open
      close={onClose}
      slides={slides}
      plugins={[Thumbnails]}
      styles={{
        thumbnail: {
          '--yarl__thumbnails_thumbnail_border_color': theme.palette.grey[700],
          '--yarl__thumbnails_thumbnail_active_border_color': theme.palette.primary.main,
        },
      }}
    />
  );
}
