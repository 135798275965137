import { useMemo } from 'react';
import { QueryObserverOptions, useQuery } from '@tanstack/react-query';
import { QueryOptions } from 'odata-query';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys, { IdsQueryKey } from 'api/hooks/useQueryKeys';

type IdsQueryOptions<TEndpoint extends ApiEndpoint, TDto extends { id: string }> = Omit<QueryObserverOptions<Pick<TDto, 'id'>[] | undefined, unknown, string[] | undefined, Pick<TDto, 'id'>[] | undefined, IdsQueryKey<TEndpoint, TDto> | readonly []>, 'queryFn'>;

export type IdsUseQueryArgs<TEndpoint extends ApiEndpoint, TDto extends { id: string }> = [odataQuery: Partial<QueryOptions<TDto>> | undefined, options?: Partial<Omit<IdsQueryOptions<TEndpoint, TDto>, 'queryKey'>>];

export default function useIdsQuery<TEndpoint extends ApiEndpoint, TDto extends { id: string }>(endpoint: TEndpoint, ...[odataQuery, options]: IdsUseQueryArgs<TEndpoint, TDto>) {
  const { getIdsQueryKey } = useDefaultEntityQueryKeys(endpoint);
  const queryKey = useMemo(() => (odataQuery ? getIdsQueryKey({ ...odataQuery, select: ['id'] }) : ([] as const)), [odataQuery, getIdsQueryKey]);
  const queryOptions = useMemo<IdsQueryOptions<TEndpoint, TDto>>(() => ({
    ...options,
    queryKey,
    select: (data) => data?.map((dto) => dto.id),
    enabled: (options?.enabled !== false) && (queryKey.length > 0),
  }), [queryKey, options]);
  return useQuery(queryOptions);
}
