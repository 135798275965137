import RowRange from 'documents/types/RowRange';
import { Dispatch, SetStateAction, createContext } from 'react';

export interface DocumentStatisticsContextState {
  displayedRowRange: RowRange | undefined,
  setDisplayedRowRange: Dispatch<SetStateAction<RowRange | undefined>>,
  currentDocumentVersionIds: string[] | undefined,
  setCurrentDocumentVersionIds: Dispatch<SetStateAction<string[] | undefined>>,
}

const defaultValue: DocumentStatisticsContextState = {
  displayedRowRange: undefined,
  setDisplayedRowRange: () => {},
  currentDocumentVersionIds: [],
  setCurrentDocumentVersionIds: () => {},
};

const DocumentStatisticsContext = createContext<DocumentStatisticsContextState>(defaultValue);
export default DocumentStatisticsContext;
