import PdfAnnotationDto from 'documents-annotations/types/PdfAnnotationDto';
import { useCallback } from 'react';

const parser = new DOMParser();

export default function usePdfAnnotationNameParser() {
  return useCallback((pdfAnnotation: PdfAnnotationDto) => {
    const xfdfDocument = parser.parseFromString(pdfAnnotation.xfdfString, 'application/xml');
    const root = xfdfDocument.documentElement;
    const annots = root.children[0];
    if (!annots || annots.nodeName !== 'annots' || !annots.hasChildNodes()) {
      return undefined;
    }
    const namedChildElements = Array.from(annots.children).filter((child) => child.hasAttribute('name'));
    const annotationName = namedChildElements[0].getAttribute('name');

    return annotationName ?? undefined;
  }, []);
}
