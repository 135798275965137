import {
  BufferAttribute,
  BufferGeometry,
  DoubleSide,
  LineBasicMaterial,
  LineSegments,
  Matrix4,
  Mesh,
  MeshLambertMaterial,
} from "three";
import { mergeBufferGeometries } from "three/examples/jsm/utils/BufferGeometryUtils";
import { IfcContext } from "../context";

const mat = new MeshLambertMaterial({
  color: 0xe56700,
  emissive: 0xe56700,
  opacity: 0.2,
  transparent: true,
  depthTest: false,
  side: DoubleSide,
});
const wireMat = new LineBasicMaterial({
  color: 0xe56700,
  depthTest: false,
  transparent: true,
});

export class HistoryNode {
  private mesh?: Mesh;
  private wireframe?: LineSegments;

  constructor(private context: IfcContext) {}

  showHistoryNode(shape: any, transform: any) {
    this.removeHistoryNode();

    const { solid, wire } = this.getBufferGeometry(shape, transform);
    this.mesh = new Mesh(solid, mat);
    this.wireframe = new LineSegments(wire, wireMat);

    this.mesh.add(this.wireframe);
    this.mesh.renderOrder = 99999;
    this.context.getScene().add(this.mesh);
  }

  removeHistoryNode() {
    if (this.mesh) {
      this.wireframe?.geometry.dispose();
      this.mesh.geometry.dispose();
      this.context.getScene().remove(this.mesh);
      this.mesh = undefined;
      this.wireframe = undefined;
    }
  }

  private getBufferGeometry(shape: any, transform: any) {
    const posData = shape.vertices;
    const norData = shape.normals;

    const geometries: BufferGeometry[] = [];
    for (let i = 0; i < shape.triangles.length; i++) {
      const indexData = new Uint32Array(shape.triangles[i]);
      const geometry = new BufferGeometry();

      geometry.setAttribute(
        "position",
        new BufferAttribute(posData.slice(), 3)
      );
      geometry.setAttribute("normal", new BufferAttribute(norData.slice(), 3));

      geometry.setIndex(new BufferAttribute(indexData, 1));

      geometries.push(geometry);
    }

    const solid = mergeBufferGeometries(geometries);

    const transMat = this.getTransformMatrix(transform);
    solid.applyMatrix4(transMat);

    const wire = new BufferGeometry();
    wire.setAttribute("position", new BufferAttribute(posData.slice(), 3));
    wire.setIndex(new BufferAttribute(shape.wireframe.slice(), 1));

    wire.applyMatrix4(transMat);

    return { solid, wire };
  }

  private getTransformMatrix(e: any) {
    const matrix = new Matrix4();
    matrix.set(
      e[0],
      e[8],
      -e[4],
      e[12],
      e[2],
      e[10],
      -e[6],
      e[14],
      -e[1],
      -e[9],
      e[5],
      -e[13],
      e[3],
      e[11],
      e[7],
      e[15]
    );

    return matrix;
  }
}
