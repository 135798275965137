import type { Theme, ComponentsProps, ComponentsVariants } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { tableCellClasses } from '@mui/material/TableCell';

interface MuiTableCellComponent {
  defaultProps?: ComponentsProps['MuiTableCell'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTableCell'];
  variants?: ComponentsVariants['MuiTableCell'];
}

const VisoTableCell: MuiTableCellComponent = {
  styleOverrides: {
    root: () => ({
      padding: '6px 4px',
      lineHeight: 1,
      border: 'none',
      verticalAlign: 'middle',
      height: '35px',
      [`&.${tableCellClasses.sizeSmall}`]: {
        height: '24px',
      },
    }),
    head: () => ({
      fontWeight: 700,
    }),
  },
};

export default VisoTableCell;
