import React, { useCallback, useRef, useContext, useEffect } from 'react';
import { Box } from '@mui/material';
import { AgGridReact } from '@ag-grid-community/react';
import { GridApi, ColumnMovedEvent, ColumnResizedEvent, GetRowIdParams, SortChangedEvent, ViewportChangedEvent } from '@ag-grid-community/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import useDocumentFilterContext from 'documents/hooks/useDocumentFilterContext';
import useOdataRowRangeAgGridDatasource from 'api/hooks/useOdataRowRangeAgGridDatasource';
import DocumentsDataGridRow from 'documents/types/DocumentDataGridRow';
import useDocumentsDataGridColumnDefinitions from 'documents/hooks/useDocumentsDataGridColumnDefinitions';
import useDocumentsDynamicLayout from 'dynamic-layout/hooks/useDocumentsDynamicLayout';
import DocumentSortDefinition from 'documents-filter/types/DocumentSortDefinition';
import DocumentStatisticsContext, { DocumentStatisticsContextState } from 'documents/contexts/DocumentStatisticsContext';
import DocumentVersionDto from 'documents/types/DocumentVersionDto';
import { isEqual } from 'lodash';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDocumentVersionsIdsQuery from 'documents/hooks/useDocumentVersionsIdsQuery';
import useDocumentSelectionContext from 'documents/hooks/useDocumentSelectionContext';

const modules = [ServerSideRowModelModule];

export default function DocumentsDataGrid() {
  const gridRef = useRef<AgGridReact<DocumentsDataGridRow>>(null);
  const { odataQuery, setSortDefinitions } = useDocumentFilterContext();
  const { selectedDocumentVersionIds, setSelectedDocumentVersionIds } = useDocumentSelectionContext();
  const { columnDefs, defaultColumnDef } = useDocumentsDataGridColumnDefinitions();
  const { setDisplayedRowRange, setCurrentDocumentVersionIds, currentDocumentVersionIds } = useContext<DocumentStatisticsContextState>(DocumentStatisticsContext);

  const {
    datasource,
    dataUpdatedAt,
  } = useOdataRowRangeAgGridDatasource<ApiEndpoint.DocumentVersion, DocumentVersionDto>(ApiEndpoint.DocumentVersion, odataQuery);
  const { data: ids } = useDocumentVersionsIdsQuery(odataQuery);

  // make sure nothing is selected that is not part of the currently viewed list of document versions
  // (e.g. from editing a metadata field we are currently filtering by)
  useEffect(() => {
    if (!currentDocumentVersionIds) return;
    const currentDocumentVersionIdsSet = new Set(currentDocumentVersionIds);
    const filteredSelection = selectedDocumentVersionIds.filter((id) => currentDocumentVersionIdsSet.has(id));
    if (isEqual(filteredSelection, selectedDocumentVersionIds)) return;
    setSelectedDocumentVersionIds(filteredSelection);
  }, [selectedDocumentVersionIds, currentDocumentVersionIds, setSelectedDocumentVersionIds]);

  useEffect(() => {
    // cache invalidation (from sync or mutations)
    if (gridRef.current?.api) {
      gridRef.current.api.refreshServerSide();
    }
  }, [dataUpdatedAt]);

  const getRowId = useCallback((params: GetRowIdParams<DocumentsDataGridRow>) => params.data?.id, []);

  const { persistAsync } = useDocumentsDynamicLayout();
  const onColumnEvent = useCallback((gridApi: GridApi) => {
    const columns = gridApi.getAllDisplayedColumns();
    const updatedColumns = columns.filter((c) => !!c.getColDef().field).map((c) => ({ fieldName: c.getColDef().field!, width: c.getActualWidth() }));
    persistAsync(updatedColumns);
  }, [persistAsync]);

  const onColumnResized = useCallback(({ source, finished, api }: ColumnResizedEvent<DocumentsDataGridRow>) => {
    if (source.startsWith('ui') && finished) onColumnEvent(api);
  }, [onColumnEvent]);

  const onColumnMoved = useCallback(({ source, finished, api }: ColumnMovedEvent<DocumentsDataGridRow>) => {
    if (source.startsWith('ui') && finished) onColumnEvent(api);
  }, [onColumnEvent]);

  const onSortChanged = useCallback((event: SortChangedEvent<DocumentsDataGridRow>) => {
    const updatedSortDefinitions: DocumentSortDefinition[] = event.api.getColumnState().filter((columnState) => !!columnState.sort).map((columnState) => ({ fieldName: columnState.colId as keyof DocumentVersionDto, descending: columnState.sort === 'desc' }));
    setSortDefinitions(updatedSortDefinitions);
  }, [setSortDefinitions]);

  useEffect(() => {
    setCurrentDocumentVersionIds(ids);
  }, [ids, setCurrentDocumentVersionIds]);

  const onViewportChanged = useCallback((e: ViewportChangedEvent<DocumentsDataGridRow>) => {
    setDisplayedRowRange({ firstRowIndex: e.firstRow, rowCount: e.lastRow - e.firstRow });
  }, [setDisplayedRowRange]);

  return (
    <Box sx={{ height: '100%', backgroundColor: '#F7F8FB' }} className="ag-theme-material ag-theme-visoplan table-view-data-grid inset-shadow-top-left-27-20">
      <AgGridReact<DocumentsDataGridRow>
        rowHeight={54}
        ref={gridRef}
        rowModelType="serverSide"
        serverSideDatasource={datasource}
        maxConcurrentDatasourceRequests={1}
        getRowId={getRowId}
        defaultColDef={defaultColumnDef}
        columnDefs={columnDefs}
        onSortChanged={onSortChanged}
        rowSelection="multiple"
        suppressCellFocus
        suppressRowHoverHighlight
        suppressRowClickSelection
        onColumnResized={onColumnResized}
        onColumnMoved={onColumnMoved}
        onViewportChanged={onViewportChanged}
        modules={modules}
      />
    </Box>
  );
}
