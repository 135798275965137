import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import PlanlistDataGrid from 'documents-lists/components/PlanlistDataGrid';
import PlanlistDocumentDropZone from 'documents-lists/components/PlanlistDocumentDropZone';
import useDocumentUiConfigContext from 'documents/hooks/useDocumentUiConfigContext';
import useCurrentCollaboratorRole from 'collaborators/hooks/useCurrentCollaboratorRole';
import { useTranslation } from 'react-i18next';
import RoleAction from 'projects/types/RoleAction';
import CreatePlanlistDialogButton from 'documents-lists/components/CreatePlanlistDialogButton';
import EditPlanlistDialogButton from 'documents-lists/components/EditPlanlistDialogButton';
import DeletePlanlistMenuButton from 'documents-lists/components/DeletePlanlistMenuButton';
import PlanlistFilterToolbar from 'documents-lists/components/PlanlistFilterToolbar';
import PlanlistFilterContextProvider from 'documents-lists/contexts/PlanlistFilterContextProvider';
import { useDrop } from 'react-dnd';
import DocumentVersionDragAndDropPayload from 'documents-folders/types/DocumentVersionDragAndDropPayload';

interface PlanlistTreePanelProps extends ISxProps {
}

export default function PlanlistTreePanel({
  sx,
}: PlanlistTreePanelProps) {
  const { t } = useTranslation('documents-lists');
  const currentUserRole = useCurrentCollaboratorRole();
  const { suppressFolderManipulation } = useDocumentUiConfigContext();

  const [isOver, setIsOver] = useState(false);
  const [, dropRef] = useDrop<DocumentVersionDragAndDropPayload>(() => ({
    accept: 'DocumentVersionDragAndDropPayload',
    collect: (monitor) => {
      setIsOver(monitor.isOver());
    },
  }), []);

  return (
    <Box
      id="PlanlistTreePanel"
      ref={dropRef}
      sx={{
        ...sx, display: 'flex', flexDirection: 'column', pr: 1, gap: 1,
      }}
    >
      <PlanlistFilterContextProvider>
        <Box sx={{ px: 2, mb: 1 }}>
          <Box sx={{
            display: 'flex', alignItems: 'center', pt: 2, pb: 1,
          }}
          >
            <Typography variant="h4">{t('planlist-tree-panel_header', 'Plan Lists')}</Typography>
            {!suppressFolderManipulation && (
            <Box sx={{
              ml: 'auto', display: 'flex', columnGap: 1,
            }}
            >
              <CreatePlanlistDialogButton />
              <EditPlanlistDialogButton />
              {!!currentUserRole?.allowedActions?.has(RoleAction.DocumentList_Delete) && <DeletePlanlistMenuButton />}
            </Box>
            )}
          </Box>
          <PlanlistFilterToolbar />
        </Box>
        <PlanlistDataGrid sx={{ flexGrow: 1 }} />
        <PlanlistDocumentDropZone sx={{ position: 'absolute', bottom: 0, right: 0, display: isOver ? 'block' : 'none' }} />
      </PlanlistFilterContextProvider>
    </Box>
  );
}
