import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ButtonBase, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import useEmailsUploadContext from 'emails/hooks/useEmailsUploadContext';
import ISxProps from 'common/types/ISxProps';

interface EmailsUploadStatusDisplayProps extends ISxProps {
}

export default function EmailsUploadStatusDisplay({
  sx,
}: EmailsUploadStatusDisplayProps) {
  const { uploadingFileHandles, openWizard } = useEmailsUploadContext();
  const { t } = useTranslation('emails');

  const totalProgress = useMemo(() => {
    const totalSize = uploadingFileHandles.reduce((prev, curr) => prev + curr.total, 0);
    if (totalSize === 0) return uploadingFileHandles.length > 0 ? 99 : 0;
    const totalLoaded = uploadingFileHandles.reduce((prev, curr) => prev + curr.loaded, 0) || 0;
    return Math.min(99, (100 * totalLoaded) / totalSize); // we never show 100% until all uploads are actually done
  }, [uploadingFileHandles]);

  const allCompleted = useMemo(() => uploadingFileHandles.every((handle) => handle.isCompleted), [uploadingFileHandles]);
  const allSettled = useMemo(() => uploadingFileHandles.every((handle) => handle.isCompleted || handle.isFailed), [uploadingFileHandles]);

  // don't render if there is no upload in progress
  if (uploadingFileHandles.length === 0) return null;

  return (
    <ButtonBase
      id="EmailsUploadStatusDisplay"
      onClick={openWizard}
      sx={{
        flexGrow: 1,
        px: 2,
        height: '100%',
        display: 'block',
        ...sx,
      }}
    >
      <Box sx={{ width: '100%', px: 2 }}>
        <Typography>
          {allCompleted
            ? t('emails-upload-status-display_caption-done', 'Uploading {{count}} file(s) done', { count: uploadingFileHandles.length })
            : allSettled
              ? t('emails-upload-status-display_caption-errors', 'Uploading finished with errors')
              : t('emails-upload-status-display_caption-progress', 'Uploading {{count}} file(s) ({{percent}}%)', { count: uploadingFileHandles.length, percent: totalProgress.toFixed(0) })}
        </Typography>
      </Box>
      <Box sx={{ width: '100%', px: 2 }}>
        <LinearProgress variant="determinate" value={totalProgress} />
      </Box>
    </ButtonBase>
  );
}
