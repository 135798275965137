import NamingSchemeDateType from 'naming-schemes/types/NamingSchemeDateType';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function useNamingSchemeDateTypeTranslations() {
  const { t } = useTranslation('naming-schemes');
  return useMemo(() => ({
    [NamingSchemeDateType.DDMMYY]: t('mutate-naming-scheme-date-group-panel_date-option-ddmmyy', 'DDMMYY'),
    [NamingSchemeDateType.DDMMYYYY]: t('mutate-naming-scheme-date-group-panel_date-option-ddmmyyyy', 'DDMMYYYY'),
    [NamingSchemeDateType.DD_MM_YY]: t('mutate-naming-scheme-date-group-panel_date-option-dd-mm-yy', 'DD_MM_YY'),
    [NamingSchemeDateType.DD_MM_YYYY]: t('mutate-naming-scheme-date-group-panel_date-option-dd-mm-yyyy', 'DD_MM_YYYY'),
    [NamingSchemeDateType.YYMMDD]: t('mutate-naming-scheme-date-group-panel_date-option-yymmdd', 'YYMMDD'),
    [NamingSchemeDateType.YYYYMMDD]: t('mutate-naming-scheme-date-group-panel_date-option-yyyymmdd', 'YYYYMMDD'),
    [NamingSchemeDateType.YYYY_MM_DD]: t('mutate-naming-scheme-date-group-panel_date-option-yyyy-mm-dd', 'YYYY_MM_DD'),
    [NamingSchemeDateType.YY_MM_DD]: t('mutate-naming-scheme-date-group-panel_date-option-yy-mm-dd', 'YY_MM_DD'),
  }), [t]);
}
