import React, { useCallback, useMemo, useState } from 'react';
import { Alert, Box, Button, Typography, useTheme } from '@mui/material';
import Icon from '@mdi/react';
import { mdiPen } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import Color from 'color';
import CloseIcon from '@mui/icons-material/Close';
import RoundIconButton from 'common/components/RoundIconButton';
import IssueDetailsGeneralPanel from 'issues/components/IssueDetailsGeneralPanel';
import IssueResponsibilityPanel from 'issues/components/IssueResponsibilityPanel';
import IssueCompleteAssignmentPanel from 'issues/components/IssueCompleteAssignmentPanel';
import IssueRejectAssignmentPanel from 'issues/components/IssueRejectAssignmentPanel';
import IssueRejectReviewPanel from 'issues/components/IssueRejectReviewPanel';
import ApproveReviewPanel from 'issues/components/ApproveReviewPanel';
import IssuePublishPanel from 'issues/components/IssuePublishPanel';
import IssueCommentsPanel from 'issues/components/IssueCommentsPanel';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import IssueLinkedImagesPanel from 'issues/components/IssueLinkedImagesPanel';
import IssueManageAnnotationsPanel from 'issues/components/IssueManageAnnotationsPanel';
import useDocumentViewerContext from 'documents-details/hooks/useDocumentViewerContext';
import LabelDto from 'labels/types/LabelDto';
import useProjectIssueStatusesQuery from 'issues/hooks/useProjectIssueStatusesQuery';
import EditIssueGeneralDialog from 'issues/components/EditIssueGeneralDialog';
import IssueLinkedViewpointsPanel from 'issues/components/IssueLinkedViewpointsPanel';
import IssueLinkedComponentsPanel from 'issues/components/IssueLinkedComponentsPanel';
import IssueAssignPanel from 'issues/components/IssueAssignPanel';
import IssueReopenPanel from 'issues/components/IssueReopenPanel';
import IssueLinkedItemsPanel from 'issues/components/IssueLinkedItemsPanel';
import ViewpointDto from 'issues/types/ViewpointDto';
import useIssueEditingRoleRight from 'issues/hooks/useIssueEditingRoleRight';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';
import RoleAction from 'projects/types/RoleAction';
import useClosedIssueStatus from 'issues/hooks/useClosedIssueStatus';
import PdfAnnotationDto from 'documents-annotations/types/PdfAnnotationDto';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';

interface IssueDetailsPanelProps {
  issueId: string | undefined,
  onClickDocumentVersionItem?: ((documentVersionId: string) => void) | undefined,
  onClickEmailItem?: ((emailId: string) => void) | undefined,
  onClickIssueItem?: ((issueId: string) => void) | undefined,
  onClickViewpointItem?: ((viewpoint: ViewpointDto) => void) | undefined,
  onClickEditViewpoints?: (() => void) | undefined,
  onClickEditComponents?: (() => void) | undefined,
  onClickAnnotationItem?: ((annotation: PdfAnnotationDto) => void) | undefined,
  onClose?: (() => void) | undefined,
  nonInteractive?: boolean | undefined,
  title?: string | undefined,
}

export default function IssueDetailsPanel({
  issueId,
  onClickDocumentVersionItem,
  onClickEmailItem,
  onClickIssueItem,
  onClickViewpointItem,
  onClickEditViewpoints,
  onClickEditComponents,
  onClickAnnotationItem,
  onClose,
  nonInteractive,
  title,
}: IssueDetailsPanelProps) {
  const { t } = useTranslation('issues');
  const theme = useTheme();
  const { data: issue, error } = useIssueQuery(issueId);
  const { data: statuses } = useProjectIssueStatusesQuery();
  const { issueIdCurrentlyLinking } = useDocumentViewerContext();

  const [completingAssignment, setCompletingAssignment] = useState(false);
  const onClickCompleteAssignment = useCallback(() => setCompletingAssignment(true), []);
  const onCloseCompleteAssignmentPanel = useCallback(() => setCompletingAssignment(false), []);

  const [rejectingAssignment, setRejectingAssignment] = useState(false);
  const onClickRejectAssignment = useCallback(() => setRejectingAssignment(true), []);
  const onCloseRejectAssignmentPanel = useCallback(() => setRejectingAssignment(false), []);

  const [rejectingReview, setRejectingReview] = useState(false);
  const onClickRejectReview = useCallback(() => setRejectingReview(true), []);
  const onCloseRejectReviewPanel = useCallback(() => setRejectingReview(false), []);

  const [approvingReview, setApprovingReview] = useState(false);
  const onClickApproveReview = useCallback(() => setApprovingReview(true), []);
  const onCloseApproveReviewPanel = useCallback(() => setApprovingReview(false), []);

  const [publishing, setPublishing] = useState(false);
  const onClickPublish = useCallback(() => setPublishing(true), []);
  const onClosePublishPanel = useCallback(() => setPublishing(false), []);

  const [assigning, setAssigning] = useState(false);
  const onClickAssign = useCallback(() => setAssigning(true), []);
  const onCloseAssignPanel = useCallback(() => setAssigning(false), []);

  const [reopening, setReopening] = useState(false);
  const onClickReopen = useCallback(() => setReopening(true), []);
  const onCloseReopenPanel = useCallback(() => setReopening(false), []);

  const isIssueActionInProgress = useMemo(
    () => completingAssignment
    || rejectingAssignment
    || rejectingReview
    || approvingReview
    || publishing
    || assigning
    || reopening,
    [approvingReview, assigning, completingAssignment, publishing, rejectingAssignment, rejectingReview, reopening],
  );

  const issueStatusLabelDto = useMemo(() => statuses?.find((status: LabelDto) => (status.id === issue?.issueStatus)), [issue?.issueStatus, statuses]);

  const statusBackgroundColor = useMemo(() => {
    if (!issue?.issueStatus || !statuses) return theme.palette.secondary.light;
    return Color(issueStatusLabelDto?.color).lightness(97).hex();
  }, [issue?.issueStatus, issueStatusLabelDto?.color, statuses, theme.palette.secondary.light]);

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const onClickEdit = useCallback(() => setEditDialogOpen(true), []);
  const onCloseEditDialog = useCallback(() => setEditDialogOpen(false), []);

  const closedIssueStatus = useClosedIssueStatus();
  const canEdit = useIssueEditingRoleRight(issueId) && issueStatusLabelDto !== closedIssueStatus;
  const allowedActions = useAllowedActions();
  const canOpen3dView = useMemo(() => allowedActions?.has(RoleAction._3DModel_3D_View), [allowedActions]);

  const getRequestErrorMessage = useRequestErrorMessage();
  const errorMessage = useMemo(() => (error ? getRequestErrorMessage(error) : undefined), [error, getRequestErrorMessage]);

  if (!issueId) return null;
  if (!issue) {
    if (error) {
      return (
        <Box sx={{ p: 2 }}>
          <Alert severity="error" onClose={onClose}>{errorMessage}</Alert>
        </Box>
      );
    }
    return <CenteredCircularProgress />;
  }
  return (
    <Box
      id="IssueDetailsPanel"
      sx={{
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {!issueIdCurrentlyLinking && (
        <Box sx={{ flex: '0 0 0', height: 73, display: 'flex', gap: 2, justifyItems: 'flexStart', alignItems: 'center', p: 3 }}>
          {!!onClose && <RoundIconButton Icon={CloseIcon} onClick={onClose} sx={{ flexShrink: 0 }} />}
          <Typography variant="h5" sx={{ pt: '1px' }}>{title ?? t('issue-details-panel_default-title', 'Issue Details')}</Typography>
          {!nonInteractive && canEdit && (
            <Button onClick={onClickEdit} id="IssueDetailsPanelEditButton" variant="contained" color="secondary" sx={{ ml: 'auto', pl: 1.5, gap: 1 }}>
              <Icon path={mdiPen} size={0.75} />
              {t('issue-details-panel_edit-button-label', 'Edit')}
            </Button>
          )}
          {!!editDialogOpen && <EditIssueGeneralDialog onClose={onCloseEditDialog} issueId={issueId} />}
        </Box>
      )}
      <Box sx={{ backgroundColor: statusBackgroundColor, overflow: 'auto', flexGrow: 1, display: 'flex', flexDirection: 'column', pb: 2, px: 2, gap: 2 }}>
        <IssueDetailsGeneralPanel issueId={issueId} />
        {!isIssueActionInProgress && (
          <IssueResponsibilityPanel
            issueId={issueId}
            onClickCompleteAssignment={onClickCompleteAssignment}
            onClickRejectAssignment={onClickRejectAssignment}
            onClickRejectReview={onClickRejectReview}
            onClickApproveReview={onClickApproveReview}
            onClickPublish={onClickPublish}
            onClickAssign={onClickAssign}
            onClickReopen={onClickReopen}
            nonInteractive={nonInteractive}
          />
        )}
        {!!completingAssignment && <IssueCompleteAssignmentPanel issueId={issueId} onClose={onCloseCompleteAssignmentPanel} />}
        {!!rejectingAssignment && <IssueRejectAssignmentPanel issueId={issueId} onClose={onCloseRejectAssignmentPanel} />}
        {!!rejectingReview && <IssueRejectReviewPanel issueId={issueId} onClose={onCloseRejectReviewPanel} />}
        {!!approvingReview && <ApproveReviewPanel issueId={issueId} onClose={onCloseApproveReviewPanel} />}
        {!!publishing && <IssuePublishPanel issueId={issueId} onClose={onClosePublishPanel} />}
        {!!assigning && <IssueAssignPanel issueId={issueId} onClose={onCloseAssignPanel} />}
        {!!reopening && <IssueReopenPanel issueId={issueId} onClose={onCloseReopenPanel} />}
        <IssueManageAnnotationsPanel issueId={issueId} nonInteractive={nonInteractive} onClickAnnotationItem={onClickAnnotationItem} />
        <IssueLinkedImagesPanel issueId={issueId} />

        <IssueLinkedItemsPanel issueId={issueId} onClickIssueItem={onClickIssueItem} onClickEmailItem={onClickEmailItem} onClickDocumentVersionItem={onClickDocumentVersionItem} nonInteractive={nonInteractive} />

        {!!canOpen3dView && (
          <>
            <IssueLinkedViewpointsPanel issueId={issueId} onClickViewpointItem={onClickViewpointItem} onClickEdit={onClickEditViewpoints} />
            <IssueLinkedComponentsPanel issueId={issueId} onClickEdit={onClickEditComponents} />
          </>
        )}
        <IssueCommentsPanel issueId={issueId} nonInteractive={nonInteractive} />
      </Box>
    </Box>
  );
}
