import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Typography, Button, Divider } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProjectDeletionSection from 'settings/components/ProjectDeletionSection';

export default function ProjectAdvancedSettingsSection() {
  const { t } = useTranslation('settings');
  const [expanded, setExpanded] = useState(false);
  return (
    <Box
      id="ProjectAdvancedSettingsSection"
      sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}
    >
      <Box sx={{ display: 'flex', gap: 4, alignItems: 'center' }}>
        <Box sx={{ flex: '1 1 0', display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <Typography variant="h4">{t('project-advanced-settings-section_title', 'Advanced Settings')}</Typography>
        </Box>
        <Box sx={{ flex: '0 0 0', display: 'flex', maxWidth: '520px' }}>
          <Button
            id="ProjectAdvancedSettingsSectionShowButton"
            variant="contained"
            sx={{ display: 'flex', gap: 1, pl: 1 }}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded
              ? (
                <>
                  <Icon size={1} path={mdiChevronUp} />
                  {t('project-advanced-settings-section_button-label-hide', 'Hide')}
                </>
              )
              : (
                <>
                  <Icon size={1} path={mdiChevronDown} />
                  {t('project-advanced-settings-section_button-label-show', 'Show')}
                </>
              )}
          </Button>
        </Box>
      </Box>
      {expanded && (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Divider />
        <ProjectDeletionSection />
      </Box>
      )}
    </Box>
  );
}
