import type { Theme, ComponentsProps, ComponentsVariants } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

interface MuiAlertTitleComponent {
  defaultProps?: ComponentsProps['MuiAlertTitle'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiAlertTitle'];
  variants?: ComponentsVariants['MuiAlertTitle'];
}

const VisoAlertTitle: MuiAlertTitleComponent = {
  styleOverrides: {
    root: () => ({
      fontWeight: 600,
      lineHeight: 2,
    }),
  },
};

export default VisoAlertTitle;
