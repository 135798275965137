import React, { useCallback, useState } from 'react';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useDeactivateTwoFactorMutation from 'users/hooks/useDeactivateTwoFactorMutation';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import { useQueryClient } from '@tanstack/react-query';
import useVisoplanApiContext from 'api/hooks/useVisoplanApiContext';

interface DisableTwoFactorAuthDialogProps {
  onClose: () => void,
}

export default function DeactivateTwoFactorAuthDialog({
  onClose,
}: DisableTwoFactorAuthDialogProps) {
  const { t } = useTranslation('users');
  const { signOut } = useVisoplanApiContext();
  const queryClient = useQueryClient();
  const { mutateAsync } = useDeactivateTwoFactorMutation();
  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const onConfirm = useCallback(async () => {
    try {
      await mutateAsync();
      setSuccessDialogOpen(true);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [getRequestErrorMessage, mutateAsync]);
  const onCloseSuccessDialog = useCallback(async () => {
    await signOut();
    queryClient.clear();
    onClose();
  }, [onClose, queryClient, signOut]);
  return (
    <Dialog open id="DisableTwoFactorAuthDialog">
      <DialogTitle>{t('deactivate-two-factor-auth-dialog_title', 'Deactivate Two-Factor Authentication')}</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Alert severity="warning">
          {t('deactivate-two-factor-auth-dialog_warning-message', 'Are you sure that you want to deactivate two-factor authentication? You will lose access to projects that are configured to require two-factor authentication.')}
        </Alert>
        {!!errorMessage && (
          <Alert severity="error">
            {errorMessage}
          </Alert>
        )}
        {!!successDialogOpen && (
          <Dialog open>
            <DialogTitle>
              {t('deactivate-two-factor-auth-dialog_success-dialog-title', 'Two-factor Authentication Deactivated')}
            </DialogTitle>
            <DialogContent>
              <Alert severity="info">
                {t('deactivate-two-factor-auth-dialog_success-dialog-message', 'Two-factor authentication is now inactive for your user account.')}
              </Alert>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                onClick={onCloseSuccessDialog}
              >
                {t('deactivate-two-factor-auth-dialog_success-dialog-close-button', 'Close')}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={onClose}
          sx={{ mr: 'auto' }}
        >
          {t('deactivate-two-factor-auth-dialog_cancel-button-label', 'Cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onConfirm}
        >
          {t('deactivate-two-factor-auth-dialog_confirm-button-label', 'Deactivate')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
