import React, { useMemo, useState } from 'react';
import IChildren from 'common/types/IChildren';
import ModelsInteractionContext, { ModelsInteractionContextState, ModelsInteractionMode } from 'models/contexts/ModelsInteractionContext';
import ViewpointItem from 'models/types/ViewpointItem';
import LinkedComponentItem from 'models/types/LinkedComponentItem';

export default function ModelsInteractionContextProvider({
  children,
}: IChildren) {
  const [interactionMode, setInteractionMode] = useState<ModelsInteractionMode | undefined>(undefined);
  const [viewpointItems, setViewpointItems] = useState<ViewpointItem[]>([]);
  const [linkedComponentItems, setLinkedComponentItems] = useState<LinkedComponentItem[]>([]);
  const modelsInteractionContextState = useMemo<ModelsInteractionContextState>(() => ({
    interactionMode,
    setInteractionMode,
    viewpointItems,
    setViewpointItems,
    linkedComponentItems,
    setLinkedComponentItems,
  }), [interactionMode, linkedComponentItems, viewpointItems]);
  return (
    <ModelsInteractionContext.Provider value={modelsInteractionContextState}>
      {children}
    </ModelsInteractionContext.Provider>
  );
}
