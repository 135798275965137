import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import IssueFilterType from 'issues/types/IssueFilterType';
import CollaboratorSelect from 'collaborators/components/CollaboratorSelect';
import useCollaboratorsOdataQuery from 'collaborators/hooks/useCollaboratorsOdataQuery';
import ChipMultiselect from 'common/components/ChipMultiselect';
import useLabelsOdataQuery from 'labels/hooks/useLabelsOdataQuery';
import LabelType from 'labels/types/LabelType';
import LabelChip from 'labels/components/LabelChip';
import IssueVisibility from 'issues/types/IssueVisibility';
import DateFilterPanel from 'issues/components/DateFilterPanel';
import IssueFilterOptions, { IssueFilter } from 'issues/types/IssueFilterOptions';
import useIssuesFilterContext from 'issues/hooks/useIssuesFilterContext';
import useColumnDisplayNamesByFieldName from 'issues/hooks/useColumnDisplayNamesByFieldName';
import DateFilterSettingsDto from 'query-filters/types/DateFilterSettingsDto';

type IssueFilterByDateDialogProps = {
  onClose: () => void,
  filterOptions: IssueFilterOptions,
};

export default function IssueFilterDialog({
  onClose,
  filterOptions,
}: IssueFilterByDateDialogProps) {
  const [formState, setFormState] = useState<IssueFilter>(filterOptions.filter);
  const { queryFilterState } = useIssuesFilterContext();
  const { appliedFilterState, setAppliedFilterState } = queryFilterState;
  const labelType = formState.filterType === IssueFilterType.LabelIdFilter || formState.filterType === IssueFilterType.LabelIdsFilter ? formState.labelType : undefined;
  const { t } = useTranslation('issues');
  const { data: collaborators } = useCollaboratorsOdataQuery({}, { enabled: formState?.filterType === IssueFilterType.CollaboratorIdFilter });
  const { data: labels } = useLabelsOdataQuery(labelType !== undefined ? { filter: { type: LabelType[labelType] }, orderBy: 'order asc' } : undefined);
  const onClickConfirm = useCallback(() => {
    if (formState.value !== undefined) {
      setAppliedFilterState({ ...appliedFilterState, [filterOptions.filterField]: formState.value });
    }
    onClose();
  }, [appliedFilterState, filterOptions.filterField, formState.value, onClose, setAppliedFilterState]);
  const onClickCancel = useCallback(() => onClose(), [onClose]);
  const columnDisplayNamesByFieldName = useColumnDisplayNamesByFieldName();
  const columnDisplayName = useMemo(() => columnDisplayNamesByFieldName[filterOptions.fieldName] ?? filterOptions.fieldName, [columnDisplayNamesByFieldName, filterOptions.fieldName]);

  const onChangeDateRangeFilter = useCallback((value: DateFilterSettingsDto | undefined) => {
    if (!value) return;
    setFormState({ filterType: IssueFilterType.DateRangeFilter, value });
  }, []);

  return (
    <Dialog id="IssueFilterDialog" open PaperProps={{ sx: { minWidth: 400 } }}>
      <DialogTitle>{t('issue-filter-dialog_title', 'Filter: {{columnName}}', { columnName: columnDisplayName })}</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 1, display: 'flex', flexDirection: 'column' }}>
          {formState?.filterType === IssueFilterType.StringFilter && (
            <TextField
              label={columnDisplayName}
              value={formState.value ?? ''}
              onChange={(event) => setFormState({ ...formState, value: event.target.value })}
            />
          )}
          {formState?.filterType === IssueFilterType.StringsFilter && (
            <TextField
              label={columnDisplayName}
              value={formState.value ?? ''}
              onChange={(event) => setFormState({ ...formState, value: event.target.value.split(',').map((v) => v.trim()) })}
            />
          )}
          {formState?.filterType === IssueFilterType.NumberFilter && (
            <TextField
              label={columnDisplayName}
              value={formState.value ?? ''}
              type="number"
              onChange={(event) => setFormState({ ...formState, value: Number.parseInt(event.target.value, 10) })}
            />
          )}
          {formState?.filterType === IssueFilterType.DateRangeFilter && (
            <DateFilterPanel
              value={formState.value}
              onChange={onChangeDateRangeFilter}
            />
          )}
          {formState?.filterType === IssueFilterType.CollaboratorIdFilter && !!collaborators && (
            <FormControl sx={{ flexGrow: 1 }}>
              <InputLabel id="issue-filter-dialog_collaborator-select-label">{columnDisplayName}</InputLabel>
              <Select
                label={columnDisplayName}
                id="issue-filter-dialog_collaborator-select"
                labelId="issue-filter-dialog_collaborator-select-label"
                value={formState.value ?? ''}
                onChange={(e) => setFormState({ ...formState, value: e.target.value })}
              >
                {collaborators.map((collaborator) => <MenuItem key={collaborator.id} value={collaborator.id}>{`${collaborator.firstName} ${collaborator.lastName}`}</MenuItem>)}
              </Select>
            </FormControl>
          )}
          {formState?.filterType === IssueFilterType.CollaboratorIdsFilter && (
            <FormControl sx={{ flexGrow: 1 }}>
              <InputLabel id="issue-filter-dialog_collaborators-select-label">{columnDisplayName}</InputLabel>
              <CollaboratorSelect
                label={columnDisplayName}
                value={formState.value ?? []}
                onChange={(value) => setFormState({ ...formState, value })}
              />
            </FormControl>
          )}
          {formState?.filterType === IssueFilterType.LabelIdFilter && !!labels && (
            <FormControl sx={{ flexGrow: 1 }}>
              <InputLabel id="issue-filter-dialog_label-select-label">{columnDisplayName}</InputLabel>
              <Select<string>
                label={columnDisplayName}
                id="issue-filter-dialog_label-select"
                labelId="issue-filter-dialog_label-select-label"
                value={formState.value ?? ''}
                onChange={(e) => setFormState({ ...formState, value: e.target.value })}
              >
                {!!labels && labels.map((label) => <MenuItem key={label.id} value={label.id}><LabelChip label={label} /></MenuItem>)}
              </Select>
            </FormControl>
          )}
          {formState?.filterType === IssueFilterType.LabelIdsFilter && !!labels && (
            <FormControl sx={{ flexGrow: 1, mt: 1 }}>
              <InputLabel id="issue-filter-dialog_labels-select-label">{columnDisplayName}</InputLabel>
              <ChipMultiselect
                id="issue-filter-dialog_labels-select"
                label={columnDisplayName}
                value={formState.value ?? []}
                onChange={(value) => setFormState({ ...formState, value })}
                entities={labels}
              />
            </FormControl>
          )}
          {formState?.filterType === IssueFilterType.VisibilityFilter && (
            <FormControl sx={{ flexGrow: 1, mt: 1 }}>
              <InputLabel id="issue-filter-dialog_visibility-select-label">{columnDisplayName}</InputLabel>
              <Select<`${IssueVisibility}`>
                label={columnDisplayName}
                id="issue-filter-dialog_visibility-select"
                labelId="issue-filter-dialog_visibility-select-label"
                value={`${formState.value?.visibility ?? ''}`}
                onChange={(e) => setFormState({ ...formState, value: { ...formState.value, visibility: parseInt(e.target.value, 10) as IssueVisibility } })}
              >
                <MenuItem value={`${IssueVisibility.Public}`}>{t('issue-filter-dialog_visibility-select-value-public', 'Public')}</MenuItem>
                <MenuItem value={`${IssueVisibility.Private}`}>{t('issue-filter-dialog_visibility-select-value-private', 'Private')}</MenuItem>
                <MenuItem value={`${IssueVisibility.Restricted}`}>{t('issue-filter-dialog_visibility-select-value-group-based', 'Group Based')}</MenuItem>
              </Select>
            </FormControl>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="contained" color="secondary" onClick={onClickCancel}>
          {t('issue-filter-dialog_cancel-button-label', 'Cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={onClickConfirm}>
          {t('issue-filter-dialog_confirm-button-label', 'Set Filter')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
