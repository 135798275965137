enum LabelType {
  Tag,
  IssueStatus,
  IssuePriority,
  IssueType,
  DocumentStatus,
  Discipline,
  Building,
  Floor,
  WorkPhase,
}

export default LabelType;
