import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import DocumentVersionDto from 'documents/types/DocumentVersionDto';
import DocumentVersionImportDto from 'documents/types/DocumentVersionImportDto';
import { useCallback } from 'react';

export default function useDocumentVersionIfcImportMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentVersion);

  const mutationFn = useCallback(async (documentVersionImportDto: DocumentVersionImportDto) => {
    const { data } = await axiosInstance.patch<DocumentVersionDto>(`${ApiEndpoint.DocumentVersion}/import`, documentVersionImportDto);
    return data;
  }, [axiosInstance]);

  const onSuccess = useCallback(() => {
    queryKeyBases.forEach((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
  }, [queryClient, queryKeyBases]);

  return useMutation<DocumentVersionDto | undefined, unknown, DocumentVersionImportDto>({
    mutationFn,
    onSuccess,
  });
}
