import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import useModelsInteractionContext from 'models/hooks/useModelsInteractionContext';
import { ModelsInteractionMode } from 'models/contexts/ModelsInteractionContext';
import ModelsDialog from 'models/components/ModelsDialog';
import useModelFileIdsLinkedToIssue from 'issues/hooks/useModelFileIdsLinkedToIssue';
import ViewpointListItem from 'issues/components/ViewpointListItem';
import useIssueViewpointItems from 'issues/hooks/useIssueViewpointItems';
import CreateViewpointDto from 'issues/types/CreateViewpointDto';
import useResourceUploadHandler from 'resources/hooks/useResourceUploadHandler';
import useViewpointCreateMutation from 'issues/hooks/useViewpointsCreateMutation';
import UpdateIssueDto from 'issues/types/UpdateIssueDto';
import useIssueUpdateMutation from 'issues/hooks/useIssueUpdateMutation';

interface EditViewpointsDialogProps {
  issueId: string,
  onClose: () => void,
  showNestedModelsDialog?: boolean,
}

export default function EditViewpointsDialog({
  issueId,
  onClose,
  showNestedModelsDialog,
}: EditViewpointsDialogProps) {
  const { t } = useTranslation('models');
  const getModelFileIdsLinkedToIssue = useModelFileIdsLinkedToIssue();
  const uploadResources = useResourceUploadHandler();
  const existingViewpointItems = useIssueViewpointItems(issueId);
  const { interactionMode, setInteractionMode, viewpointItems: stagedViewpointItems, setViewpointItems } = useModelsInteractionContext();
  const { mutateAsync: createViewpointsAsync, isPending: isLoadingViewpointCreateMutation } = useViewpointCreateMutation();
  const { mutateAsync: updateIssueAsync, isPending: isLoadingIssueUpdaetMutation } = useIssueUpdateMutation();
  const isLoadingMutation = isLoadingIssueUpdaetMutation || isLoadingViewpointCreateMutation;
  const listItems = useMemo(() => {
    if (!existingViewpointItems) return undefined;
    return [
      ...existingViewpointItems.map((item) => ({
        id: item.viewpoint.id,
        imageDataUrl: item.imageDataUrl,
        onClickRemove: undefined,
      })),
      ...stagedViewpointItems.map((item) => ({
        id: item.uuid,
        imageDataUrl: item.imageDataUrl,
        onClickRemove: () => setViewpointItems((prev) => prev.filter((v) => v.uuid !== item.uuid)),
      })),
    ];
  }, [existingViewpointItems, setViewpointItems, stagedViewpointItems]);
  const [dialogSelectedIssueId, setDialogSelectedIssueId] = useState<string | undefined>(undefined);
  const [intialModelFileIds, setInitialModelFileIds] = useState<string[] | undefined>(undefined);
  const onClickAdd = useCallback(async () => {
    const modelFileIds = await getModelFileIdsLinkedToIssue(issueId);
    setInitialModelFileIds(modelFileIds);
    setInteractionMode(ModelsInteractionMode.ViewpointManagement);
  }, [getModelFileIdsLinkedToIssue, issueId, setInteractionMode]);
  const onClickConfirm = useCallback(async () => {
    if (stagedViewpointItems?.length) {
      const modelFileIds = stagedViewpointItems.flatMap((item) => item.modelFileIds);
      if (modelFileIds.length) {
        const updateIssueDto: UpdateIssueDto = {
          id: issueId,
          modelFileComponentIds: { value: Object.fromEntries(modelFileIds.map((id) => [id, []])) },
        };
        await updateIssueAsync(updateIssueDto);
      }
      const createViewpointDtos: CreateViewpointDto[] = await Promise.all(stagedViewpointItems.map(async (viewpointItem, index) => {
        const screenshotFileName = `${issueId}_viewpoint-${index}.png`;
        const screenshotBlob = await (await fetch(viewpointItem.imageDataUrl)).blob();
        const [screenshotResourceId] = await uploadResources([{ blob: screenshotBlob, fileName: screenshotFileName }]);
        return {
          issueId: { value: issueId },
          imageId: { value: screenshotResourceId },
          imageName: { value: screenshotFileName },
          index: { value: index },
          creatingTool: { value: 'Visoplan-Web' },
          visualStates: { value: viewpointItem.visualStates },
          cameraType: { value: viewpointItem.cameraType },
          location: { value: viewpointItem.location },
          direction: { value: viewpointItem.direction },
          up: { value: viewpointItem.up },
          cameraFactor: { value: viewpointItem.cameraFactor },
          visibility: { value: viewpointItem.visibility },
          sections: { value: viewpointItem.sections },
          lines: { value: viewpointItem.lines },
        };
      }));
      await createViewpointsAsync(createViewpointDtos);
    }
    setViewpointItems([]);
    onClose();
  }, [createViewpointsAsync, issueId, onClose, setViewpointItems, stagedViewpointItems, updateIssueAsync, uploadResources]);
  const onClickCancel = useCallback(() => onClose(), [onClose]);
  return (
    <>
      <Dialog open id="EditViewpointsDialog" PaperProps={{ sx: { maxWidth: 'unset', width: 800 } }}>
        <DialogTitle component="div" sx={{ display: 'flex', gap: 2, justifyContent: 'space-between' }}>
          <Typography variant="h2">
            {t('edit-viewpoints-dialog_title', 'Edit Viewpoints')}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={onClickAdd}
            sx={{ gap: 0.5, pl: 0.5 }}
          >
            <Icon path={mdiPlus} size={0.75} />
            {t('edit-viewpoints-dialog_add-button-label', 'Add')}
          </Button>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box sx={{ py: 2, display: 'flex', gap: 2, flexWrap: 'wrap', height: '100%', overflow: 'auto' }}>
            {!!listItems && listItems.map((item) => (
              <ViewpointListItem
                key={item.id}
                viewpointItem={item}
                sx={{ width: '200px', maxHeight: '112px' }}
              />
            ))}
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ display: 'flex', gap: 2, justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onClickCancel}
          >
            {t('edit-viewpoints-dialog_cancel-button-label', 'Cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onClickConfirm}
            disabled={isLoadingMutation}
          >
            {!!isLoadingMutation && <CircularProgress size={12} />}
            {t('edit-viewpoints-dialog_confirm-button-label', 'Confirm')}
          </Button>
        </DialogActions>
      </Dialog>
      {!!showNestedModelsDialog && interactionMode === ModelsInteractionMode.ViewpointManagement && !!intialModelFileIds && (
        <ModelsDialog
          title={t('edit-viewpoints-dialog_add-viewpoints-dialog-title', 'Add Viewpoints')}
          selectedIssueId={dialogSelectedIssueId}
          setSelectedIssueId={setDialogSelectedIssueId}
          initiallySelectedModelFileIds={intialModelFileIds}
        />
      )}
    </>
  );
}
