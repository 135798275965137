import {
  BufferGeometry,
  DoubleSide,
  LineBasicMaterial,
  LineSegments,
  Material,
  Mesh,
  MeshLambertMaterial,
  Plane,
} from "three";
import { IfcState, Normal, Position, SELECT_ID } from "./BaseDefinitions";
import { BvhManager } from "./BvhManager";
import { SubsetUtils } from "./SubsetUtils";

const solidMat: Material = new MeshLambertMaterial({
  color: 0x83d8e5,
  side: DoubleSide,
});
const wireMat: Material = new LineBasicMaterial({
  color: 0xb8f5ff,
  transparent: true,
  depthTest: false,
});

export class HoverSubset {
  private hoverMesh: Mesh | null = null;
  private wireframe: LineSegments | null = null;

  constructor(private state: IfcState, private BVH: BvhManager) { }

  setClippingPlanes(planes: Plane[]) {
    solidMat.clippingPlanes = planes.length ? planes : null;
    wireMat.clippingPlanes = planes.length ? planes : null;
  }

  hoverComponent(modelId: string, globalId: string) {
    const model = this.state.models[modelId];
    if (!model || globalId === "" || modelId === SELECT_ID) {
      return null;
    }

    const attributes = SubsetUtils.getGeometryAttributes(this.state, modelId, [
      globalId,
    ]);
    if (attributes) {
      if (this.hoverMesh == null && this.wireframe == null) {
        this.hoverMesh = new Mesh(new BufferGeometry(), solidMat);
        this.wireframe = new LineSegments(new BufferGeometry(), wireMat);

        this.hoverMesh.add(this.wireframe);
        this.hoverMesh.renderOrder = Infinity;
        this.hoverMesh.frustumCulled = false;
        this.wireframe.frustumCulled = false;
      }

      if (this.hoverMesh) {
        this.hoverMesh.geometry.setAttribute(Position, attributes.position);
        this.hoverMesh.geometry.setAttribute(Normal, attributes.normal);
        this.hoverMesh.geometry.setIndex(attributes.index);

        if (this.BVH) this.BVH.applyThreeMeshBVH(this.hoverMesh.geometry);
      }

      if (this.wireframe) {
        this.wireframe.geometry.setAttribute(Position, attributes.position);
        this.wireframe.geometry.setIndex(attributes.wireframe);
      }

      return this.hoverMesh;
    } else {
      return null;
    }
  }

  removeHoverMesh() {
    if (this.wireframe) {
      this.wireframe.geometry.dispose();
      this.wireframe = null;
    }

    if (this.hoverMesh) {
      this.hoverMesh.geometry.dispose();
      this.hoverMesh = null;
    }
  }

  hide() {
    if (this.hoverMesh) {
      this.hoverMesh.visible = false;
    }
  }
}
