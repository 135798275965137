import React, { useCallback, useState } from 'react';
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useIssuesFilterContext from 'issues/hooks/useIssuesFilterContext';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useLanguageCode from 'common/hooks/useLanguageCode';
import Icon from '@mdi/react';
import { mdiDelete } from '@mdi/js';

interface DeleteFilterDialogProps {
  onClose: () => void,
}

export default function DeleteFilterDialog({
  onClose,
}: DeleteFilterDialogProps) {
  const { t } = useTranslation('query-filters');
  const { queryFilterState, setQueryFilterId } = useIssuesFilterContext();
  const { deleteLoadedQueryFilter, loadedQueryFilter } = queryFilterState;
  const languageCode = useLanguageCode();

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const getRequestErrorMessage = useRequestErrorMessage();

  const [loading, setLoading] = useState<boolean>(false);
  const onConfirm = useCallback(async () => {
    try {
      setLoading(true);
      await deleteLoadedQueryFilter();
      setQueryFilterId(undefined);
      onClose();
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    } finally {
      setLoading(false);
    }
  }, [deleteLoadedQueryFilter, getRequestErrorMessage, onClose, setQueryFilterId]);

  return (
    <Dialog open id="DeleteFilterDialog">
      <DialogTitle>
        {t('delete-filter-dialog_header', 'Delete Filter')}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Alert severity="warning">{t('delete-filter-dialog_are-you-sure-prompt', 'Do you want to permanently delete the filter "{{filterName}}"?', { filterName: loadedQueryFilter?.name[languageCode] })}</Alert>
          {!!errorMessage && (
            <Alert severity="error" onClose={onCloseErrorMessage}>{errorMessage}</Alert>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button id="delete-filter-dialog_cancel" onClick={onClose} variant="contained" color="secondary" sx={{ mr: 'auto' }}>
          {t('delete-filter-dialog_cancel-button-label', 'Cancel')}
        </Button>
        <Button id="delete-filter-dialog_confirm" onClick={onConfirm} variant="contained" color="primary" disabled={loading} sx={{ pl: 1, gap: 1 }}>
          {!!loading && <CircularProgress size={12} />}
          {!loading && <Icon path={mdiDelete} size={1} />}
          {t('delete-filter-dialog_confirm-button-label', 'Delete Filter')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
