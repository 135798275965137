import React, { useCallback, useMemo, useState } from 'react';
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useIssuesFilterContext from 'issues/hooks/useIssuesFilterContext';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';

enum QueryFilterVisibility {
  Private,
  Public,
}

interface SaveFilterDialogProps {
  onClose: () => void,
}

export default function SaveFilterDialog({
  onClose,
}: SaveFilterDialogProps) {
  const { t } = useTranslation('query-filters');
  const { queryFilterState, setQueryFilterId } = useIssuesFilterContext();
  const {
    saveQueryFilterAsNew,
  } = queryFilterState;

  const [name, setName] = useState('');
  const [visibility, setVisibility] = useState<QueryFilterVisibility>(QueryFilterVisibility.Private);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const getRequestErrorMessage = useRequestErrorMessage();

  const inputValid = useMemo(() => name?.length > 0, [name]);

  const [loading, setLoading] = useState<boolean>(false);
  const onConfirm = useCallback(async () => {
    try {
      setLoading(true);
      const createdEntity = await saveQueryFilterAsNew(name, visibility === QueryFilterVisibility.Private, undefined);
      if (createdEntity?.id) {
        setQueryFilterId(createdEntity.id);
      }
      onClose();
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    } finally {
      setLoading(false);
    }
  }, [getRequestErrorMessage, name, onClose, saveQueryFilterAsNew, setQueryFilterId, visibility]);

  return (
    <Dialog open id="SaveFilterDialog">
      <DialogTitle>
        {t('save-filter-dialog_header', 'Save As New Filter')}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ py: 2, display: 'flex', alignItems: 'flex-end' }}>
          <TextField
            id="save-filter-dialog_filter-name-label"
            label={t('save-filter-dialog_filter-name-label', 'Filter Name')}
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ width: 200, mr: 6, mb: '2px' }}
          />
          <FormControl>
            <Typography id="save-filter-dialog_filter-query-visibility-header" variant="h5">{t('save-filter-dialog_filter-query-visibility-header', 'Visibility')}</Typography>
            <RadioGroup
              row
              aria-labelledby="save-filter-dialog_filter-query-visibility-header"
              value={visibility}
              onChange={(e) => setVisibility(parseInt(e.target.value, 10))}
              name="radio-buttons-group"
            >
              <FormControlLabel value={QueryFilterVisibility.Private} control={<Radio />} label={t('save-filter-dialog_filter-query-visibility-private', 'Private')} />
              <FormControlLabel value={QueryFilterVisibility.Public} control={<Radio />} label={t('save-filter-dialog_filter-query-visibility-public', 'Public')} />
            </RadioGroup>
          </FormControl>
        </Box>
        {!!errorMessage && (
          <Alert severity="error" onClose={onCloseErrorMessage}>{errorMessage}</Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button id="save-filter-dialog_cancel" onClick={onClose} variant="contained" color="secondary" sx={{ mr: 'auto' }}>
          {t('save-filter-dialog_cancel-button-label', 'Cancel')}
        </Button>
        <Button id="save-filter-dialog_confirm" onClick={onConfirm} variant="contained" color="primary" disabled={!inputValid || loading} sx={{ gap: 1 }}>
          {!!loading && <CircularProgress size={12} />}
          {t('save-filter-dialog_confirm-button-label', 'Save Filter')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
