import React, { useCallback, useMemo } from 'react';
import { Box, Divider, FormControl, Select, MenuItem, Alert, Button, InputLabel } from '@mui/material';
import { t } from 'i18next';
import useDocumentVersionNumberString from 'documents/hooks/useDocumentVersionNumberString';
import FileType from 'documents/types/FileType';
import useDocumentViewerContext from 'documents-details/hooks/useDocumentViewerContext';
import useDocumentDetailsSelectionContext from 'documents-details/hooks/useDocumentDetailsSelectionContext';
import useDocumentVersionQuery from 'documents/hooks/useDocumentVersionQuery';
import useDocumentVersionsQuery from 'documents/hooks/useDocumentVersionsQuery';
import { CompareMode } from 'documents-details/components/DocumentViewer';

interface DocumentVersionSelectProps {
  onChange: (documentVersionId: string) => void,
}

export default function DocumentVersionSelect({
  onChange,
}: DocumentVersionSelectProps) {
  const getVersionNumberString = useDocumentVersionNumberString();
  const { documentVersionId } = useDocumentDetailsSelectionContext();
  const { data: documentVersion } = useDocumentVersionQuery(documentVersionId);
  const { data: availableDocumentVersions } = useDocumentVersionsQuery(documentVersion ? { filter: { normalizedName: documentVersion.normalizedName, isArchived: false }, orderBy: 'uploadDate desc' } : undefined);

  const { setIssueId } = useDocumentDetailsSelectionContext();
  const { setSelectedAnnotationNames, issueIdCurrentlyLinking, compareDocumentVersions } = useDocumentViewerContext();
  const onClickCompare = useCallback(() => {
    if (!availableDocumentVersions || !documentVersionId) return;
    const indexOf = availableDocumentVersions?.findIndex((v) => v.id === documentVersionId);
    const compareIndex = (indexOf < availableDocumentVersions.length - 1) ? indexOf + 1 : (indexOf > 0) ? indexOf - 1 : indexOf;
    const compareDocumentVersion = availableDocumentVersions[compareIndex];
    setSelectedAnnotationNames([]);
    setIssueId(undefined);
    compareDocumentVersions(documentVersionId, compareDocumentVersion.id, CompareMode.Text);
  }, [availableDocumentVersions, setSelectedAnnotationNames, setIssueId, compareDocumentVersions, documentVersionId]);

  const documentVersionIdDisplayValue = useMemo(() => (availableDocumentVersions && documentVersionId ? documentVersionId : ''), [availableDocumentVersions, documentVersionId]);

  if (!documentVersion) return null;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, borderRadius: 2 }}>
      {documentVersion?.isNewest
        ? <Alert severity="info">{t('document-version-select_document-versions-header-new', 'You are viewing the newest version.')}</Alert>
        : <Alert severity="warning">{t('document-version-select_document-versions-header-old', 'You are viewing an old version!')}</Alert>}
      <Divider orientation="vertical" flexItem sx={{ borderColor: 'black' }} />
      <FormControl>
        <InputLabel>{t('document-version-details-panel_select-label', 'Document version')}</InputLabel>
        <Select
          label={t('document-version-details-panel_select-label', 'Document version')}
          value={documentVersionIdDisplayValue}
          onChange={(e) => onChange(e.target.value as string)}
          disabled={!!issueIdCurrentlyLinking}
        >
          {availableDocumentVersions?.map((version) => (
            <MenuItem key={version.id} value={version.id}>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                {t('document-version-details-panel_select-value-version-number', 'V. {{versionNumber}} ({{uploadDate}})', { versionNumber: getVersionNumberString(version.versionNumber), uploadDate: new Date(version.uploadDate).toLocaleString('de-DE') })}
                <Divider orientation="vertical" flexItem />
                {t('document-version-details-panel_select-value-annotation-count', '{{annotationsCount}} locations', { annotationsCount: version.pdfAnnotationIds.length })}
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {(documentVersion.fileType === FileType.Pdf || documentVersion.fileType === FileType.DWG || (documentVersion.fileType === FileType.Image && !documentVersion.originalFileName.endsWith('.svg'))) && (
      <Button
        variant="contained"
        color="secondary"
        onClick={onClickCompare}
        disabled={!!issueIdCurrentlyLinking}
      >
        {t('document-version-details-panel_compare-versions-button-label', 'Compare')}
      </Button>
      )}
    </Box>
  );
}
