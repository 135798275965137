import React, { useMemo, forwardRef } from 'react';
import {
  Box, CircularProgress, Typography, useTheme, styled,
} from '@mui/material';
import CommentDto from 'issues/types/CommentDto';
import ISxProps from 'common/types/ISxProps';
import InlineTypography from 'common/components/InlineTypography';
import useCurrentCollaboratorQuery from 'collaborators/hooks/useCurrentCollaboratorQuery';

const HighlightableBox = styled(Box)(({ theme }) => ({
  transition: theme.transitions.create('background-color', {
    easing: theme.transitions.easing.sharp,
    duration: '2s',
  }),
  backgroundColor: theme.palette.grey[200],
  '&.highlighted': {
    backgroundColor: theme.palette.success.light,
  },
  '&.current-users-comment': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const mentionSplitRegex = /(@(?:Collaborator|CollaboratorGroup)\[[^\]]+\]\([^)]+\))/i;
const mentionLabelRegex = /@(?:Collaborator|CollaboratorGroup)\[([^\]]+)\]\(([^)]+)\)/i;

interface IssueCommentItemProps extends ISxProps {
  comment: CommentDto,
  highlighted?: boolean,
}

export default forwardRef<HTMLDivElement, IssueCommentItemProps>(({
  sx,
  comment,
  highlighted,
}, ref) => {
  const theme = useTheme();
  const { data: currentCollaborator } = useCurrentCollaboratorQuery();
  const isCurrentUsersComment = useMemo(() => (currentCollaborator ? comment.createAuthor.id === currentCollaborator.id : undefined), [comment, currentCollaborator]);

  const commentComponents = useMemo(() => {
    if (!currentCollaborator || !comment.text) return null;
    const parts = comment.text.split(mentionSplitRegex);
    return parts.map((part) => {
      const labelMatch = part.match(mentionLabelRegex);
      if (labelMatch) {
        return (
          <InlineTypography
            key={crypto.randomUUID()}
            sx={{ fontWeight: 600, color: labelMatch[2] === currentCollaborator.id ? theme.palette.secondary.contrastText : undefined }}
          >
            {`@${labelMatch[1]}`}
          </InlineTypography>
        );
      }
      return <InlineTypography key={crypto.randomUUID()}>{part}</InlineTypography>;
    });
  }, [currentCollaborator, comment.text, theme.palette.secondary.contrastText]);

  return (
    <HighlightableBox
      ref={ref}
      key={comment.id}
      sx={{
        p: 1, borderRadius: 1, ...sx,
      }}
      className={[
        ...(highlighted ? ['highlighted'] : []),
        ...(isCurrentUsersComment ? ['current-users-comment'] : []),
      ].join(' ')}
    >
      <Box sx={{ display: 'flex', alignItems: 'top' }}>
        <Typography sx={{ fontWeight: 700 }}>{`${comment.createAuthor.firstName} ${comment.createAuthor.lastName}`}</Typography>
        {comment.id.startsWith('temp_')
          ? <CircularProgress size={10} sx={{ ml: 'auto ' }} />
          : (
            <Typography sx={{ fontSize: 10, ml: 'auto ' }}>
              {new Date(comment.creationDate).toLocaleString('de-DE')}
            </Typography>
          )}
      </Box>
      <Box sx={{
        mt: 0.5, lineHeight: 1.25, fontSize: 12, whiteSpace: 'break-spaces', wordBreak: 'break-word',
      }}
      >
        {commentComponents}
      </Box>
    </HighlightableBox>
  );
});
