import type { Theme, ComponentsProps, ComponentsVariants } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

interface MuiMenuItemComponent {
  defaultProps?: ComponentsProps['MuiMenuItem'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiMenuItem'];
  variants?: ComponentsVariants['MuiMenuItem'];
}

const VisoMenuItem: MuiMenuItemComponent = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontSize: theme.typography.fontSize,
      '&.Mui-disabled': {
        opacity: 0.6,
      },
    }),
  },
};

export default VisoMenuItem;
