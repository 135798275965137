import DocumentVersionDto from 'documents/types/DocumentVersionDto';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type ColumnDisplayNamesByFieldName = {
  [P in keyof DocumentVersionDto]?: string;
};

export default function useColumnDisplayNamesByFieldName() {
  const { t } = useTranslation('documents');
  return useMemo<ColumnDisplayNamesByFieldName>(() => ({
    name: t('use-column-display-names-by-field-name_name', 'Name'),
    versionNumber: t('use-column-display-names-by-field-name_version-number', 'Version Number'),
    fileType: t('use-column-display-names-by-field-name_file-type', 'File Type'),
    status: t('use-column-display-names-by-field-name_status', 'Status'),
    uploadDate: t('use-column-display-names-by-field-name_upload-date', 'Upload Date'),
    disciplines: t('use-column-display-names-by-field-name_disciplines', 'Disciplines'),
    building: t('use-column-display-names-by-field-name_building', 'Building'),
    floor: t('use-column-display-names-by-field-name_floor', 'Floor'),
    workPhase: t('use-column-display-names-by-field-name_work-phase', 'Work Phase'),
    linkedIssueIds: t('use-column-display-names-by-field-name_linked-issue-ids', 'Linked Issues'),
    editDate: t('use-column-display-names-by-field-name_edit-date', 'Edit Date'),
    creator: t('use-column-display-names-by-field-name_creator', 'Creator'),
    editor: t('use-column-display-names-by-field-name_editor', 'Editor'),
    commentIds: t('use-column-display-names-by-field-name_comment-ids', 'Comments'),
    creationDate: t('use-column-display-names-by-field-name_creation-date', 'Creation Date'),
    fileSize: t('use-column-display-names-by-field-name_file-size', 'File Size'),
    tags: t('use-column-display-names-by-field-name_tags', 'Tags'),
    folderId: t('use-column-display-names-by-field-name_folder-id', 'Folder'),
  }), [t]);
}
