import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Alert, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DocumentCommentCreationPanel from 'documents-details/components/DocumentCommentCreationPanel';
import DocumentCommentItem from 'documents-details/components/DocumentCommentItem';
import useCurrentCollaboratorRole from 'collaborators/hooks/useCurrentCollaboratorRole';
import RoleAction from 'projects/types/RoleAction';
import useDocumentCommentsOdataQuery from 'documents/hooks/useDocumentCommentsOdataQuery';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import ISxProps from 'common/types/ISxProps';
import useDocumentDetailsSelectionContext from 'documents-details/hooks/useDocumentDetailsSelectionContext';

interface DocumentCommentsTabProps extends ISxProps {
}

export default function DocumentCommentsTab({
  sx,
}: DocumentCommentsTabProps) {
  const { t } = useTranslation('documents-details');
  const currentUserRole = useCurrentCollaboratorRole();
  const { documentVersionId, highlightedDocumentCommentId, setHighlightedDocumentCommentId } = useDocumentDetailsSelectionContext();
  const commentsOdataQuery = useMemo(() => (documentVersionId ? { filter: { documentVersionId }, orderBy: 'creationDate desc' } : undefined), [documentVersionId]);
  const { data: comments } = useDocumentCommentsOdataQuery(commentsOdataQuery, { refetchInterval: 3000 });
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isOpenAnimationCompleted, setIsOpenAnimationCompleted] = useState<boolean>(false);

  const [itemsMap] = useState(new Map<string, HTMLDivElement>());
  const chipRefCallback = useCallback((id: string, node: HTMLDivElement | null) => {
    if (node) {
      itemsMap.set(id, node);
    } else {
      itemsMap.delete(id);
    }
  }, [itemsMap]);

  useEffect(() => {
    const handle = setTimeout(() => setIsOpenAnimationCompleted(true), 300);
    return () => clearTimeout(handle);
  }, []);

  useEffect(() => {
    setIsOpenAnimationCompleted(false);
    const handle = setTimeout(() => setIsOpenAnimationCompleted(true), 100);
    return () => clearTimeout(handle);
  }, [comments?.length]);

  useEffect(() => {
    if (isOpenAnimationCompleted) {
      setHighlightedDocumentCommentId(undefined);
    }
  }, [isOpenAnimationCompleted, setHighlightedDocumentCommentId]);

  return (
    <Box sx={{ ...sx, p: 2, pt: 3, gap: 2, display: 'flex', flexDirection: 'column' }}>
      {!!currentUserRole?.allowedActions?.has(RoleAction.Document_CommentEditing) && (
        <Box sx={{
          height: '180px', display: 'flex', flexDirection: 'column',
        }}
        >
          {!!errorMessage && (
          <Alert
            severity="error"
            sx={{ mb: 2, maxHeight: '100px', overflowY: 'auto' }}
            onClose={() => setErrorMessage(undefined)}
            title={t('document-comment-drawer_send-error-title', 'Error')}
          >
            {errorMessage}
          </Alert>
          )}
          <DocumentCommentCreationPanel
            suppressSubmit={!comments}
            setErrorMessage={setErrorMessage}
            sx={{ flexGrow: 1 }}
          />
        </Box>
      )}
      <Box
        sx={{
          flex: '1 1 0',
          overflowY: 'auto',
        }}
        className="custom-scrollbar"
      >
        {!!comments && comments?.map((comment, index) => (
          <DocumentCommentItem
            key={comment.id}
            comment={comment}
            sx={{ my: 1 }}
            setErrorMessage={setErrorMessage}
            ref={(node) => chipRefCallback(comment.id, node)}
            highlighted={comment.id === highlightedDocumentCommentId}
            isLastComment={index === 0}
          />
        ))}
        {!comments && <CenteredCircularProgress />}
      </Box>
    </Box>
  );
}
