import React, { useCallback } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useDocumentsViewNavigationContext from 'documents/hooks/useDocumentsViewNavigationContext';
import ArchiveScopeId from 'documents/types/ArchiveScopeId';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';

interface ArchivePrivatePublicSwitchProps extends ISxProps {
}

export default function ArchivePrivatePublicSwitch({
  sx,
}: ArchivePrivatePublicSwitchProps) {
  const { documentScope, setDocumentScope } = useDocumentsViewNavigationContext();
  const onChange = useCallback((event: React.MouseEvent<HTMLElement>, value: string | null) => {
    if (value !== null) {
      setDocumentScope({ key: DocumentScopeKey.Archive, id: value });
    }
  }, [setDocumentScope]);
  return (
    <ToggleButtonGroup
      value={documentScope.id}
      exclusive
      onChange={onChange}
      sx={{ ...sx }}
    >
      <ToggleButton id="ArchivePrivatePublicSwitchPrivateSwitch" value={ArchiveScopeId.Private}>Private</ToggleButton>
      <ToggleButton id="ArchivePrivatePublicSwitchPublicSwitch" value={ArchiveScopeId.Public}>Public</ToggleButton>
    </ToggleButtonGroup>
  );
}
