import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import useEmailUploadHandler from 'emails/hooks/useEmailUploadHandler';
import useEmailsUploadContext from 'emails/hooks/useEmailsUploadContext';
import RoleAction from 'projects/types/RoleAction';
import useCurrentCollaboratorRole from 'collaborators/hooks/useCurrentCollaboratorRole';

export default function useEmailUploadDropRef() {
  const emailUploadHandler = useEmailUploadHandler();
  const currentUserRole = useCurrentCollaboratorRole();

  const { addToEmailsStagedForUpload, openWizard } = useEmailsUploadContext();

  const [{ isActive: canDropEmails }, emailUploadDropRef] = useDrop<{ files: File[] }, void, { isActive: boolean }>(() => ({
    accept: [NativeTypes.FILE],
    canDrop: () => !!currentUserRole?.allowedActions?.has(RoleAction.Email_Upload),
    collect: (monitor) => ({ isActive: monitor.canDrop() && monitor.isOver() }),
    drop(item: { files: File[], folderNames?: string[] } | undefined) {
      if (item?.files?.length) {
        addToEmailsStagedForUpload({
          files: item.files,
          fileNamesThatAreFolders: item.folderNames,
        });
        openWizard();
      }
    },
  }), [currentUserRole, emailUploadHandler]);

  return { canDropEmails, emailUploadDropRef };
}
