import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import useViewer3dContext from 'models/hooks/useViewer3dContext';
import useResourceQuery from 'resources/hooks/useResourceQuery';
import StoreyMap from 'models/types/StoreyMap';
import Vector3 from 'common/types/Vector3';
import { IDockviewPanelProps } from 'dockview';
import Bounds from 'models/types/Bounds';
import { debounce } from 'lodash';
import observerSvg from 'img/svg/observer.svg';
import viewfieldSvg from 'img/svg/viewfield.svg';
import boundsArrowSvg from 'img/svg/bounds-arrow.svg';
import { useTranslation } from 'react-i18next';

const RAD2DEG = 57.29578049;

interface StoreyMapItem {
  uuid: string,
  dto: StoreyMap,
}

export default function Plan2dDockviewPanel({ api }: IDockviewPanelProps) {
  const { t } = useTranslation('models');
  const { modelFiles, viewer3dApiRef } = useViewer3dContext();
  const [selectedStoreyMapItem, setSelectedStoreyMapItem] = useState<StoreyMapItem | undefined>();
  const selectedStoreyMapItemRef = useRef<StoreyMapItem | undefined>(undefined);
  const storeyMapItems = useMemo<StoreyMapItem[] | undefined>(() => modelFiles?.flatMap((modelFile) => modelFile.storeyMaps.filter((storeyMap) => storeyMap.dto).map((storeyMap) => ({
    dto: storeyMap.dto!,
    uuid: crypto.randomUUID(),
  }))) ?? [], [modelFiles]);
  const onChangeStoreyMapSelect = useCallback((event: SelectChangeEvent<string>) => {
    setSelectedStoreyMapItem(storeyMapItems?.find((item) => item.uuid === event.target.value));
  }, [storeyMapItems]);
  const { data: imageDataUrl } = useResourceQuery(selectedStoreyMapItem?.dto.resourceId);
  const storeyBoundsRef = useRef<Bounds | undefined>(undefined);
  const cameraTransformRef = useRef<{ position: Vector3, direction: Vector3 } | undefined>(undefined);
  const planRectRef = useRef<{ x: number, y: number, width: number, height: number } | undefined>(undefined);
  const imgRef = useRef<HTMLImageElement>(null);
  const imgContainerRef = useRef<HTMLDivElement>(null);
  const pivotDivRef = useRef<HTMLDivElement>(null);
  const viewfieldImgRef = useRef<HTMLImageElement>(null);
  const boundsArrowImgRef = useRef<HTMLImageElement>(null);

  const updateStoreyBounds = useCallback(() => {
    const storeyMap = selectedStoreyMapItemRef.current?.dto;
    if (!storeyMap) {
      storeyBoundsRef.current = undefined;
      return;
    }
    storeyBoundsRef.current = storeyMap.storeyBounds?.center && storeyMap.storeyBounds.size
      ? {
        center: {
          x: storeyMap.storeyBounds.center.x,
          y: storeyMap.storeyBounds.center.z,
          z: -storeyMap.storeyBounds.center.y,
        },
        size: {
          x: storeyMap.storeyBounds.size.x,
          y: storeyMap.storeyBounds.size.z,
          z: storeyMap.storeyBounds.size.y,
        },
      }
      : undefined;
  }, []);

  const updateSelectedStoreyMapByCameraPosition = useCallback(() => {
    if (!storeyMapItems?.length) {
      setSelectedStoreyMapItem(undefined);
      selectedStoreyMapItemRef.current = undefined;
      updateStoreyBounds();
      return;
    }

    const nextSelectedStoreyMapItem = storeyMapItems.find((storeyMapItem, index) => {
      const storeyMap = storeyMapItem.dto;
      if (!cameraTransformRef.current || !storeyMap?.storeyBounds) return false;
      const storeyMinY = storeyMap.storeyBounds.center.z - storeyMap.storeyBounds.size.z;
      const storeyMaxY = storeyMap.storeyBounds.center.z + storeyMap.storeyBounds.size.z;
      if (index === 0 && cameraTransformRef.current.position.y < storeyMinY) return true; // camera is below bottom storey => return bottom storey
      if (index === storeyMapItems.length - 1 && cameraTransformRef.current.position.y > storeyMaxY) return true; // camera is above top storey => return top storey
      return cameraTransformRef.current.position.y > storeyMinY && cameraTransformRef.current.position.y < storeyMaxY; // camera is within this storey, return it
    });

    if (nextSelectedStoreyMapItem) {
      setSelectedStoreyMapItem(nextSelectedStoreyMapItem);
      selectedStoreyMapItemRef.current = nextSelectedStoreyMapItem;
      updateStoreyBounds();
    }
  }, [storeyMapItems, updateStoreyBounds]);

  useEffect(() => {
    updateSelectedStoreyMapByCameraPosition();
  }, [updateSelectedStoreyMapByCameraPosition]);

  const updatePivotPosition2d = useCallback(() => {
    if (!cameraTransformRef.current || !planRectRef.current || !storeyBoundsRef.current || !imgContainerRef.current) return;
    const { position } = cameraTransformRef.current;
    const position2d = {
      x: planRectRef.current.x + (planRectRef.current.width * ((position.x - storeyBoundsRef.current.center.x + (storeyBoundsRef.current.size.x * 0.5)) / storeyBoundsRef.current.size.x)),
      y: planRectRef.current.y + (planRectRef.current.height * ((position.z - storeyBoundsRef.current.center.z + (storeyBoundsRef.current.size.z * 0.5)) / storeyBoundsRef.current.size.z)),
    };

    const imageContainerSize = { width: imgContainerRef.current.clientWidth, height: imgContainerRef.current.clientHeight };
    if (position2d.x >= 0 && position2d.x <= imageContainerSize.width && position2d.y >= 0 && position2d.y <= imageContainerSize.height) {
      pivotDivRef.current?.style.setProperty('transform', `translate(${position2d.x}px,${position2d.y}px)`);
      if (pivotDivRef.current?.style.visibility !== null) {
        pivotDivRef.current?.style.setProperty('visibility', null);
      }
      if (boundsArrowImgRef.current?.style.visibility !== 'hidden') {
        boundsArrowImgRef.current?.style.setProperty('visibility', 'hidden');
      }
    } else {
      if (pivotDivRef.current?.style.visibility !== 'hidden') {
        pivotDivRef.current?.style.setProperty('visibility', 'hidden');
      }
      const center = { x: imageContainerSize.width * 0.5, y: imageContainerSize.height * 0.5 };
      const aspect = imageContainerSize.width / imageContainerSize.height;
      const delta = {
        x: position2d.x - center.x,
        y: position2d.y - center.y,
      };
      const deltaAspectIsSmaller = Math.abs(delta.x / delta.y) < aspect;
      const sx = imageContainerSize.width - 16;
      const sy = imageContainerSize.height - 16;
      const arrowPos = {
        x: deltaAspectIsSmaller ? center.x + (0.5 * sy * delta.x) / Math.abs(delta.y) : center.x + 0.5 * sx * Math.sign(delta.x),
        y: deltaAspectIsSmaller ? center.y + 0.5 * sy * Math.sign(delta.y) : center.y + (0.5 * sx * delta.y) / Math.abs(delta.x),
      };
      const arrowAngle = 180 - Math.atan2(delta.x, delta.y) * RAD2DEG;
      boundsArrowImgRef.current?.style.setProperty('transform', `translate(${arrowPos.x - 8}px,${arrowPos.y - 8}px)rotate(${arrowAngle}deg)`);
      if (boundsArrowImgRef.current?.style.visibility !== null) {
        boundsArrowImgRef.current?.style.setProperty('visibility', null);
      }
    }
  }, []);

  const updateViewfieldOrientation2d = useCallback(() => {
    if (!cameraTransformRef.current) return;
    const { direction } = cameraTransformRef.current;
    const angle = RAD2DEG * Math.atan2(direction.x, -direction.z);
    viewfieldImgRef.current?.style.setProperty('transform', `translate(-9px,-6px) rotate(${angle}deg) translate(0,-17px)`);
  }, []);

  const updatePlanRect = useCallback(() => {
    if (!imgRef.current || !imgContainerRef.current) return;
    const imgRect = imgRef.current.getBoundingClientRect();
    const imgContainerRect = imgContainerRef.current.getBoundingClientRect();
    planRectRef.current = {
      width: imgRect.width ?? 0,
      height: imgRect.height ?? 0,
      x: imgRect.x - imgContainerRect.x,
      y: imgRect.y - imgContainerRect.y,
    };
  }, []);

  const onChangeCameraTransform = useCallback((transform: { position: Vector3, direction: Vector3 } | null) => {
    cameraTransformRef.current = transform ?? undefined;
    updatePivotPosition2d();
    updateViewfieldOrientation2d();
    updateSelectedStoreyMapByCameraPosition();
  }, [updateViewfieldOrientation2d, updatePivotPosition2d, updateSelectedStoreyMapByCameraPosition]);

  useEffect(() => {
    const viewer = viewer3dApiRef.current;
    if (!viewer || !imgRef.current || !imgContainerRef.current) return undefined;
    viewer.context.ifcCamera.onChange.on(onChangeCameraTransform);

    const resizeObserver = new ResizeObserver(debounce(() => {
      updatePlanRect();
      updatePivotPosition2d();
    }, 250, { maxWait: 1000 }));
    resizeObserver.observe(imgRef.current);
    resizeObserver.observe(imgContainerRef.current);

    return () => {
      viewer.context.ifcCamera.onChange.off(onChangeCameraTransform);
      resizeObserver.disconnect();
    };
  }, [api, updatePivotPosition2d, viewer3dApiRef, onChangeCameraTransform, updatePlanRect]);

  return (
    <Box id="Plan2dDockviewPanel" sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ p: 2, display: 'flex' }}>
        {!!storeyMapItems && (
          <FormControl sx={{ flexGrow: 1 }}>
            <InputLabel id="plan2d-dockview-panel_storey-select-label">{t('plan2d-dockview-panel_storey-select-label', 'Storey')}</InputLabel>
            <Select<string>
              value={selectedStoreyMapItem?.uuid ?? ''}
              label={t('plan2d-dockview-panel_storey-select-label', 'Storey')}
              onChange={onChangeStoreyMapSelect}
              disabled={!storeyMapItems.length}
            >
              {storeyMapItems.map(({ dto, uuid }) => (
                <MenuItem key={uuid} value={uuid}>{dto.storeyName ?? ''}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>
      <Box sx={{ justifyContent: 'center', alignItems: 'center', position: 'relative', flexGrow: 1 }}>
        <Box ref={imgContainerRef} sx={{ visibility: selectedStoreyMapItem?.dto.resourceId ? 'visible' : 'hidden', position: 'absolute', top: 8, left: 8, right: 8, bottom: 8, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img ref={imgRef} src={imageDataUrl ?? ''} alt={selectedStoreyMapItem?.dto.storeyName ?? ''} style={{ maxWidth: '100%', maxHeight: '100%' }} />
          <Box ref={pivotDivRef} sx={{ position: 'absolute', top: 0, left: 0 }}>
            <img src={observerSvg} alt="Position on the 2D Plan" style={{ position: 'absolute', transform: 'translate(-5.5px, 0px)' }} />
            <img ref={viewfieldImgRef} src={viewfieldSvg} alt="Look direction on the 2D Plan" style={{ position: 'absolute' }} />
          </Box>
          <img ref={boundsArrowImgRef} src={boundsArrowSvg} alt="Position on the 2D Plan" style={{ width: '15px', height: '9px', position: 'absolute', left: 0, top: 0 }} />
        </Box>
      </Box>
    </Box>
  );
}
