import type { Theme, ComponentsProps, ComponentsVariants } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

interface MuiTooltipComponent {
  defaultProps?: ComponentsProps['MuiTooltip'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTooltip'];
  variants?: ComponentsVariants['MuiTooltip'];
}

const VisoTooltip: MuiTooltipComponent = {
  styleOverrides: {
    tooltip: ({ theme }) => ({
      fontSize: theme.typography.fontSize,
    }),
  },
};

export default VisoTooltip;
