import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useCurrentUserQuery from 'users/hooks/useCurrentUserQuery';
import LangType from 'users/types/LangType';

export default function useLanguageSwitch() {
  const { data: currentUser, isLoading } = useCurrentUserQuery();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (isLoading) return;
    if (currentUser) {
      const userLocale = currentUser?.langType === LangType.German ? 'de-DE' : 'en-US';
      i18n.changeLanguage(userLocale);
    } else {
      const browserLocale = navigator.language.startsWith('de') ? 'de-DE' : 'en-US';
      i18n.changeLanguage(browserLocale);
    }
  }, [isLoading, currentUser, i18n]);
}
