import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Box, Button, Snackbar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import NewProjectDialog from 'projects/components/NewProjectDialog';
import ProjectListItem from 'projects/components/ProjectListItem';
import ProjectDto from 'projects/types/ProjectDto';
import { useNavigate } from 'react-router-dom';
import HeaderBox from 'common/components/HeaderBox';
import VerticalDivider from 'common/styled/VerticalDivider';
import UserDropdownMenuButton from 'users/components/UserDropdownMenuButton';
import PluginsDownloadButton from 'projects/components/PluginsDownloadButton';
import useProjectsOdataQuery from 'projects/hooks/useProjectsOdataQuery';

export default function ProjectsPage() {
  const { t } = useTranslation('projects');
  const navigate = useNavigate();
  const { data: projects } = useProjectsOdataQuery({ orderBy: 'creationDate desc' });
  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const [newProjectDialogOpen, setNewProjectDialogOpen] = useState<boolean>(false);

  const onClickProjectItem = useCallback(async (project: ProjectDto) => {
    try {
      navigate(`./${project.id}/dashboard`);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [getRequestErrorMessage, navigate]);

  const onClickNewProject = useCallback(() => {
    setNewProjectDialogOpen(true);
  }, []);

  const onCloseNewProjectDialog = useCallback(() => {
    setNewProjectDialogOpen(false);
  }, []);

  useEffect(() => {
    document.title = `${t('projects-page_window-title', 'Projects - Visoplan')}`;
  }, [t]);

  if (!projects) return <CenteredCircularProgress />;

  return (
    <Box id="ProjectsPage" sx={{ height: '100%', display: 'flex', flexDirection: 'column', backgroundColor: '#f5f5fa' }}>
      <HeaderBox sx={{ height: 56, flexShrink: 0, display: 'flex', gap: 4, boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.1)', position: 'relative' }}>
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
          <img src="/img/visoplan-logo.svg" alt="logo" style={{ width: '140px' }} />
        </Box>
        <VerticalDivider sx={{ height: 36 }} />
        <Typography variant="h2" sx={{ flexGrow: 1 }}>
          {t('projects-page_title', 'Projects')}
          {!!projects && ` (${projects.length})`}
        </Typography>
        <Box sx={{ display: 'flex', alignSelf: 'stretch', alignItems: 'center', gap: 2, px: 2 }}>
          <PluginsDownloadButton
            variant="outlined"
          />
          <Button
            id="ProjectsPageNewProjectButton"
            onClick={onClickNewProject}
            variant="contained"
            color="primary"
            sx={{ ml: 'auto', gap: 0.5, pl: 1 }}
          >
            <Icon path={mdiPlus} size={0.75} />
            {t('projects-page_new-project-button-label', 'New Project')}
          </Button>
          <UserDropdownMenuButton sx={{ alignSelf: 'stretch' }} hideEmailSettingsMenuItem />
        </Box>
      </HeaderBox>
      <Box
        sx={{
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(380px, 1fr))',
            gridAutoRows: 'minmax(100px, auto)',
            gap: 4,
            p: 4,
          }}
        >
          {projects.map((project) => (
            <ProjectListItem
              key={project.id}
              project={project}
              onClick={onClickProjectItem}
            />
          ))}
        </Box>
      </Box>
      {!!newProjectDialogOpen && <NewProjectDialog open onClose={onCloseNewProjectDialog} />}
      {!!errorMessage && (
        <Snackbar onClose={onCloseErrorMessage}>
          <Alert severity="error" onClose={onCloseErrorMessage}>{errorMessage}</Alert>
        </Snackbar>
      )}
    </Box>
  );
}
