import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import ComponentDetailsPanel from 'models/components/ComponentDetailsPanel';
import { IDockviewPanelProps } from 'dockview';

export default function ComponentDetailsDockviewPanel({
  api,
}: IDockviewPanelProps) {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  useEffect(() => {
    setIsVisible(api.isVisible);
    const handle = api.onDidVisibilityChange((e) => {
      setIsVisible(e.isVisible);
    });
    return () => {
      handle.dispose();
    };
  }, [api]);
  if (!isVisible) return null;
  return (
    <Box id="ComponentDetailsDockviewPanel" sx={{ width: '100%', height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
      <ComponentDetailsPanel sx={{ flexGrow: 1 }} />
    </Box>
  );
}
