import { Box, styled } from '@mui/material';

const VerticalDivider = styled(Box)(({ theme }) => ({
  width: 1,
  borderRightWidth: '1px',
  borderRightStyle: 'solid',
  borderRightColor: theme.palette.divider,
}));

export default VerticalDivider;
