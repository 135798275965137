import {
  Box, Button, DialogActions, DialogContent, DialogTitle, Link,
} from '@mui/material';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ValidateTable from 'upload/components/DocumentUploadWizard/ValidateTable';
import ValidationApiResponse from 'upload/types/ValidationApiResponse';
import DocumentUploadContext, { DocumentUploadContextState } from 'upload/contexts/DocumentUploadContext';
import useValidateDocumentUploads from 'upload/hooks/useValidateDocumentUploads';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';

interface ValidateStagedUploadsPageProps {
  cancel: () => void,
}

export default function ValidateStagedUploadsPage({
  cancel,
}: ValidateStagedUploadsPageProps) {
  const { t } = useTranslation('upload');
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    addToDocumentsStagedForUpload,
    stagedPayload,
    discardStagedFiles,
    startUploadingStagedPayload,
  } = useContext<DocumentUploadContextState>(DocumentUploadContext);

  const { validateUploadPayloadCandidate } = useValidateDocumentUploads();
  const [isValidating, setIsValidating] = useState(false);
  const [validationApiResponseBody, setValidationApiResponseBody] = useState<ValidationApiResponse>({ precheckResults: [], allSuccess: false });

  useEffect(() => {
    if (!stagedPayload) return;
    setIsValidating(true);
    validateUploadPayloadCandidate(stagedPayload)
      .then((validationResult) => {
        setValidationApiResponseBody(validationResult ?? { precheckResults: [], allSuccess: false });
      }).finally(() => setIsValidating(false));
  }, [stagedPayload, validateUploadPayloadCandidate]);

  const areAllStagedDocumentsValid = useMemo(() => {
    if (!stagedPayload) return undefined;
    const fileNamesThatAreFoldersSet = new Set(stagedPayload.fileNamesThatAreFolders);
    const payloadContainsFolder = stagedPayload.files.some((file) => fileNamesThatAreFoldersSet.has(file.name));
    return !!stagedPayload?.files.length && !!validationApiResponseBody?.allSuccess && !payloadContainsFolder;
  }, [stagedPayload, validationApiResponseBody?.allSuccess]);

  const stageFilesForUpload = (files: File[] | null) => {
    if (!files?.length) return;
    if (!stagedPayload) return;
    addToDocumentsStagedForUpload({
      ...stagedPayload,
      files,
    });
  };

  const discardInvalid = useCallback(() => {
    if (!stagedPayload) throw new Error('Dependency error');
    const invalidFileNames = validationApiResponseBody.precheckResults.filter((precheckResult) => !precheckResult.success).map((precheckResult) => precheckResult.fileName);
    discardStagedFiles(invalidFileNames.concat(stagedPayload.fileNamesThatAreFolders ?? []));
  }, [discardStagedFiles, stagedPayload, validationApiResponseBody]);

  return (
    <>
      <DialogTitle id="ValidateStagedUploadsPage" sx={{ display: 'flex' }}>
        <Box sx={{ flexGrow: 1 }}>{t('upload-wizard_validate-title', 'Upload {{count}} documents', { count: stagedPayload?.files.length ?? 0 })}</Box>
        <Link component="button" onClick={() => inputRef.current?.showPicker()} sx={{ fontWeight: 600 }}>{t('upload-wizard_validate-upload-more', 'Upload More')}</Link>
        <input ref={inputRef} hidden accept="*" multiple type="file" onChange={(e) => stageFilesForUpload(e.target?.files ? Array.from(e.target.files) : null)} />
      </DialogTitle>
      <DialogContent>
        <ValidateTable validationApiResponseBody={validationApiResponseBody} fileNamesThatAreFolders={stagedPayload?.fileNamesThatAreFolders} discardStagedFiles={discardStagedFiles} />
        {isValidating && <CenteredCircularProgress />}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" id="ValidateStagedUploadsPageCancelUploadButton" color="secondary" onClick={cancel}>{t('upload-wizard_cancel', 'Cancel')}</Button>
        <Button variant="contained" id="ValidateStagedUploadsPageDiscardInvalidButton" color="secondary" disabled={areAllStagedDocumentsValid || !validationApiResponseBody} onClick={discardInvalid} sx={{ ml: 'auto', whiteSpace: 'nowrap' }}>{t('upload-wizard_discard-invalid', 'Discard Invalid')}</Button>
        <Button variant="contained" id="ValidateStagedUploadsPageStartUploadButton" disabled={!areAllStagedDocumentsValid || isValidating} onClick={startUploadingStagedPayload} sx={{ ml: 1 }}>{t('upload-wizard_start_upload', 'Upload')}</Button>
      </DialogActions>
    </>
  );
}
