import React, { useCallback } from 'react';
import useDocumentFilterContext from 'documents/hooks/useDocumentFilterContext';
import { Box, Button, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Translation } from 'react-i18next';
import RightUtilityDrawer from 'documents/components/RightUtilityDrawer';
import TimeFilterSection from 'documents-filter/components/TimeFilterSection';
import ModelFilterSection from 'documents-filter/components/ModelFilterSection';
import LabelsFilterSection from 'documents-filter/components/LabelsFilterSection';
import DetailsFilterSection from 'documents-filter/components/DetailsFilterSection';
import IssuesFilterSection from 'documents-filter/components/IssuesFilterSection';
import FoldersFilterSection from 'documents-filter/components/FoldersFilterSection';
import RoundIconButton from 'common/components/RoundIconButton';
import FilterOptionsButtonMenu from 'documents-filter/components/FilterOptionsButtonMenu';
import LoadFilterSelect from 'documents-filter/components/LoadFilterSelect';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import useDocumentsViewNavigationContext from 'documents/hooks/useDocumentsViewNavigationContext';

export default function FilterDrawer() {
  const { documentScope, setDocumentScope } = useDocumentsViewNavigationContext();
  const {
    isFilterDrawerOpen,
    setIsFilterDrawerOpen,
    stagedFilterState,
    setStagedFilterState,
    applyStagedFilterState,
    resetFilterState,
    loadedQueryFilter,
  } = useDocumentFilterContext();

  const onClickApply = useCallback(() => {
    if (documentScope.key !== DocumentScopeKey.Filter) {
      setDocumentScope({ key: DocumentScopeKey.Filter, id: loadedQueryFilter?.id });
    }
    applyStagedFilterState();
  }, [applyStagedFilterState, documentScope.key, loadedQueryFilter, setDocumentScope]);

  const onClickReset = useCallback(() => {
    resetFilterState();
    if (loadedQueryFilter) setDocumentScope({ key: DocumentScopeKey.Filter });
  }, [loadedQueryFilter, resetFilterState, setDocumentScope]);

  return (
    <RightUtilityDrawer id="FilterDrawer" isOpen={isFilterDrawerOpen} openWidth={381} sx={{ py: 3, display: 'flex', flexDirection: 'column' }}>
      <Translation ns="documents-filter">
        {(t) => (isFilterDrawerOpen
          && (
          <>
            <Box sx={{ px: 2, pb: 1 }}>
              <Box sx={{ display: 'flex' }}>
                <Typography variant="h3">{t('filter-drawer_header', 'Documents Filter')}</Typography>
                <RoundIconButton Icon={CloseIcon} onClick={() => setIsFilterDrawerOpen(false)} sx={{ ml: 'auto' }} />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                <FilterOptionsButtonMenu />
                <LoadFilterSelect />
              </Box>
            </Box>
            <Box sx={{ flex: '1 1 0', overflowY: 'auto', px: 2 }} className="custom-scrollbar">
              <TimeFilterSection value={stagedFilterState} onChange={setStagedFilterState} />
              <ModelFilterSection value={stagedFilterState} onChange={setStagedFilterState} />
              <LabelsFilterSection value={stagedFilterState} onChange={setStagedFilterState} />
              <DetailsFilterSection value={stagedFilterState} onChange={setStagedFilterState} />
              <IssuesFilterSection value={stagedFilterState} onChange={setStagedFilterState} />
              <FoldersFilterSection value={stagedFilterState} onChange={setStagedFilterState} />
            </Box>
            <Box sx={{
              px: 2, pt: 3, display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '8px',
            }}
            >
              <Button variant="contained" color="primary" onClick={onClickApply}>{t('filter-drawer_apply-filters', 'Apply Filter')}</Button>
              <Button variant="contained" color="secondary" onClick={onClickReset}>{t('filter-drawer_reset-filters', 'Reset Filter')}</Button>
            </Box>
          </>
          )
        )}
      </Translation>
    </RightUtilityDrawer>
  );
}
