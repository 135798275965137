import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ISxProps from 'common/types/ISxProps';
import NamingSchemeGroupElementItem from 'naming-schemes/types/NamingSchemeGroupElementItem';

const numberRegex = /^[0-9]+$/;

interface MutateNamingSchemeVersionGroupPanelProps extends ISxProps {
  elementItems: NamingSchemeGroupElementItem[],
  onChange: (value: NamingSchemeGroupElementItem[]) => void,
}

enum VersionNumberFormat {
  Letters = 'letters',
  Digits = 'digits',
}

export default function MutateNamingSchemeVersionGroupPanel({
  sx,
  elementItems,
  onChange,
}: MutateNamingSchemeVersionGroupPanelProps) {
  const { t } = useTranslation('naming-schemes');
  const item = useMemo<NamingSchemeGroupElementItem>(() => (elementItems[0] ?? { uuid: crypto.randomUUID() }), [elementItems]);
  const [lengthInputValue, setLengthInputValue] = useState<string>(`${item?.length ?? ''}`);
  const lengthValidationErrorMessage = useMemo(() => (
    (!!lengthInputValue?.length && !lengthInputValue.match(numberRegex)?.length) || (parseInt(lengthInputValue, 10) === 0)
      ? t('mutate-naming-scheme-characters-group-panel_length-number-validation-error', 'Must be a positive number')
      : undefined
  ), [lengthInputValue, t]);
  const onChangeLength = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setLengthInputValue(event.target.value);
    if (event.target.value.match(numberRegex)?.length) {
      onChange([{ ...item, length: parseInt(event.target.value, 10) }]);
    } else {
      onChange([{ ...item, length: undefined }]);
    }
  }, [item, onChange]);
  const onChangeVersionAsLetter = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange([{ ...item, versionAsLetter: event.target.value === VersionNumberFormat.Letters }]);
  }, [item, onChange]);
  const versionNumberFormat = useMemo(() => (item.versionAsLetter ? VersionNumberFormat.Letters : VersionNumberFormat.Digits), [item.versionAsLetter]);
  return (
    <Box id="MutateNamingSchemeVersionGroupPanel" sx={{ display: 'flex', flexDirection: 'column', gap: 3, ...sx }}>
      <Typography variant="h5">{t('mutate-naming-scheme-version-group-panel_title', 'Document Version Group Options')}</Typography>
      <FormControl>
        <Typography id="mutate-naming-scheme-version-group-panel_version-as-letter-radiogroup-label" variant="h5">{t('mutate-naming-scheme-version-group-panel_version-as-letter-radiogroup-label', 'Format')}</Typography>
        <RadioGroup
          aria-labelledby="mutate-naming-scheme-version-group-panel_version-as-letter-radiogroup-label"
          value={versionNumberFormat}
          onChange={onChangeVersionAsLetter}
          name="mutate-naming-scheme-version-group-panel_version-as-letter-radiogroup"
        >
          <FormControlLabel value={VersionNumberFormat.Digits} control={<Radio />} label={t('mutate-naming-scheme-version-group-panel_version-number-format-digits', 'Digits')} />
          <FormControlLabel value={VersionNumberFormat.Letters} control={<Radio />} label={t('mutate-naming-scheme-version-group-panel_version-number-format-letters', 'Letters')} />
        </RadioGroup>
      </FormControl>
      <TextField
        id="mutate-naming-scheme-version-group-panel_length-textfield"
        label={t('mutate-naming-scheme-version-group-panel_length-textfield-label', 'Digit Count')}
        value={lengthInputValue}
        onChange={onChangeLength}
        error={!!lengthValidationErrorMessage}
        helperText={lengthValidationErrorMessage}
      />
    </Box>
  );
}
