import useViewpointsQuery from 'issues/hooks/useViewpointsQuery';
import ViewpointDto from 'issues/types/ViewpointDto';
import { useMemo } from 'react';
import useResourcesQuery from 'resources/hooks/useResourcesQuery';

export default function useIssueViewpointItems(issueId: string | undefined, onClickViewpointItem?: (viewpoint: ViewpointDto) => void) {
  const { data: linkedViewpoints } = useViewpointsQuery({ filter: { issueId } });
  const resourceIds = useMemo(() => linkedViewpoints?.filter((v) => Boolean(v.thumbnailImageId)).map((v) => v.thumbnailImageId!), [linkedViewpoints]);
  const { data: thumbnailObjectUrls } = useResourcesQuery(resourceIds);
  return useMemo(() => {
    if (!linkedViewpoints || !thumbnailObjectUrls || !resourceIds) return undefined;
    const objectUrlsByResourceId = new Map(thumbnailObjectUrls.map((objectUrl, index) => [resourceIds[index], objectUrl]));
    return linkedViewpoints.map((viewpoint) => ({
      viewpoint,
      imageDataUrl: viewpoint.thumbnailImageId ? objectUrlsByResourceId.get(viewpoint.thumbnailImageId) : undefined,
      onClick: onClickViewpointItem ? () => onClickViewpointItem(viewpoint) : undefined,
    }));
  }, [linkedViewpoints, onClickViewpointItem, resourceIds, thumbnailObjectUrls]);
}
