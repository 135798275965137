import { Dispatch, SetStateAction, createContext } from 'react';

export interface LegacySettingsNotificationDialogContextState {
  setHasUnsavedChanges: Dispatch<SetStateAction<boolean>>,
  saveChangesNotification: string,
  setSaveChangesNotification: Dispatch<SetStateAction<string>>,
  navigateWithConfirmation: (toPath: string) => void,
}

const defaultState: LegacySettingsNotificationDialogContextState = {
  setHasUnsavedChanges: undefined!,
  saveChangesNotification: undefined!,
  setSaveChangesNotification: undefined!,
  navigateWithConfirmation: undefined!,
};

const LegacySettingsNotificationDialogContext = createContext<LegacySettingsNotificationDialogContextState>(defaultState);
export default LegacySettingsNotificationDialogContext;
