import React from 'react';
import { CustomCellRendererProps } from '@ag-grid-community/react';
import IssueDto from 'issues/types/IssueDto';
import { Box, styled, Tooltip, tooltipClasses, TooltipProps, useTheme } from '@mui/material';
import useResourceQuery from 'resources/hooks/useResourceQuery';

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
    padding: '4px',
    borderRadius: '8px',
  },
});

export default function ThumbnailCell({
  data: issue,
  value,
}: CustomCellRendererProps<IssueDto, IssueDto['thumbnailImageId']>) {
  const theme = useTheme();
  const { data: thumbnailDataUrl } = useResourceQuery(value || undefined);
  return (
    <Box sx={{ width: '50px', height: '50px', flexShrink: 0, borderRadius: '4px', backgroundColor: theme.palette.grey[500] }}>
      {!!thumbnailDataUrl && (
        <NoMaxWidthTooltip
          placement="left"
          enterDelay={500}
          title={<img src={thumbnailDataUrl} style={{ maxWidth: '600px', maxHeight: '600px', borderRadius: '4px', display: 'block' }} alt={issue?.title} />}
        >
          <img src={thumbnailDataUrl} style={{ width: '50px', height: '50px', borderRadius: '4px', objectFit: 'cover' }} alt={issue?.title} />
        </NoMaxWidthTooltip>
      )}
    </Box>
  );
}
