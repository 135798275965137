import Folder from 'documents-folders/types/Folder';
import FolderAccessType from 'documents-folders/types/FolderAccessType';

export enum RowType {
  AccessTypeRow,
  FolderRow,
}

export interface AccessTypeRow {
  rowType: RowType.AccessTypeRow,
  accessType: FolderAccessType,
  path: string[],
  nameTranslationKey: string,
}

export interface FolderRow extends Folder {
  rowType: RowType.FolderRow,
}

type FolderTreeGridRow = FolderRow | AccessTypeRow;
export default FolderTreeGridRow;
