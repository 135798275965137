import React, { useCallback, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Alert, Box, CircularProgress } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { mdiDownload } from '@mdi/js';
import Icon from '@mdi/react';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import IssueExportDto from 'issues/types/IssueExportDto';
import { QueryOptions } from 'odata-query';
import IssueDto from 'issues/types/IssueDto';
import useIssuesIdsQuery from 'issues/hooks/useIssuesIdsQuery';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';

interface BcfExportDialogProps {
  odataQuery: Partial<QueryOptions<IssueDto>>,
  onClose: () => void,
}

export default function BcfExportDialog({
  odataQuery,
  onClose,
}: BcfExportDialogProps) {
  const { t } = useTranslation('issues');
  const axiosInstance = useAxiosInstance();
  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const { data: issueIds } = useIssuesIdsQuery(odataQuery);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const onClickConfirm = useCallback(async () => {
    if (!issueIds) return;
    try {
      setIsDownloading(true);
      const issueExportDto: IssueExportDto = { issueIds };
      const { data } = await axiosInstance.post<Blob>(ApiEndpoint.ExportBcf, issueExportDto, { responseType: 'blob', timeout: Number.POSITIVE_INFINITY });
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(data);
      a.download = `visoplan_issues-filter-result_${(new Date().toJSON().slice(0, 10))}_${new Date().toJSON().slice(11, 19).replaceAll(':', '-')}.bcf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      onClose();
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    } finally {
      setIsDownloading(false);
    }
  }, [axiosInstance, getRequestErrorMessage, issueIds, onClose]);
  return (
    <Dialog open id="BcfExportDialog">
      {!issueIds && <CenteredCircularProgress />}
      {!!issueIds && (
        <>
          <DialogTitle>{t('bcf-export-dialog_title', 'Export Issues to BCF')}</DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: 400 }}>
              <Alert severity="info">
                <Trans
                  t={t}
                  i18nKey="bcf-export-dialog_hint-message"
                  components={{
                    b: <b />,
                  }}
                  defaults="The BCF file will contain the <b>{{count}} issues</b> from the current filter result."
                  count={issueIds.length}
                />
              </Alert>
              {!!errorMessage && (
                <Alert severity="error" onClose={onCloseErrorMessage}>
                  {errorMessage}
                </Alert>
              )}
            </Box>
          </DialogContent>
          <DialogActions sx={{ gap: 2, justifyContent: 'space-between' }}>
            <Button variant="contained" color="secondary" onClick={onClose}>
              {t('bcf-export-dialog_cancel-button-label', 'Cancel')}
            </Button>
            <Button variant="contained" color="primary" onClick={onClickConfirm} sx={{ pl: 1, gap: 1 }} disabled={isDownloading}>
              {!isDownloading && <Icon path={mdiDownload} size={1} />}
              {!!isDownloading && <CircularProgress size={12} />}
              {t('bcf-export-dialog_confirm-button-label', 'Download')}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
