import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface FolderStructureCategory {
  category: string;
  users: never[];
  documents: never[];
  dateUpdated: string;
  parentFolderID: null;
  id: string;
  name: string;
  label: string;
}

interface FolderStructure {
  all: FolderStructureCategory,
  my_uploads: FolderStructureCategory,
  archive?: FolderStructureCategory,
}

const useStaticTranslations = () => {
  const { t } = useTranslation();

  const folderStructureForCategory = useMemo<FolderStructure>(() => ({
    all: {
      category: '0-All',
      users: [],
      documents: [],
      dateUpdated: '',
      parentFolderID: null,
      id: 'all',
      name: 'all',
      label: t('all_documents', { ns: 'docFields', defaultValue: 'All Documents' }),
    },
    my_uploads: {
      category: '1-Uploads',
      users: [],
      documents: [],
      dateUpdated: '',
      parentFolderID: null,
      id: 'my-uploads',
      name: 'my_uploads',
      label: t('my_uploads', { ns: 'docFields', defaultValue: 'My Uploads' }),
    },
    archive: {
      category: '2-Archive',
      users: [],
      documents: [],
      dateUpdated: '',
      parentFolderID: null,
      id: 'archive',
      name: 'archive',
      label: t('archive', { ns: 'docFields', defaultValue: 'Archive' }),
    },
  }), [t]);

  return {
    folderStructureForCategory,
  };
};

export default useStaticTranslations;
