import React from 'react';
import { Box } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import FilterDataGrid from 'documents-filter/components/FilterDataGrid';
import QueryFilterFilterToolbar from 'documents-filter/components/QueryFilterFilterToolbar';
import QueryFilterFilterContextProvider from 'documents-filter/contexts/QueryFilterFilterContextProvider';

interface FilterTreePanelProps extends ISxProps {
}

export default function FilterTreePanel({
  sx,
}: FilterTreePanelProps) {
  return (
    <Box sx={{ ...sx, pt: 2, pr: 1 }}>
      <QueryFilterFilterContextProvider>
        <QueryFilterFilterToolbar />
        <FilterDataGrid />
      </QueryFilterFilterContextProvider>
    </Box>
  );
}
