import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import LabelChip from 'labels/components/LabelChip';
import IssueDto from 'issues/types/IssueDto';
import useProjectIssueStatusesQuery from 'issues/hooks/useProjectIssueStatusesQuery';

export default function StatusChipCell({
  value,
}: ICellRendererParams<IssueDto, String>) {
  const { data: statuses } = useProjectIssueStatusesQuery();
  if (!statuses) return null;
  const status = statuses.find((s) => s.id === value);
  if (!status) return null;
  return <LabelChip sx={{ mr: 1 }} label={status} />;
}
