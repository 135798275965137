import { QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 300000, // 5 min
    },
  },
});

export default function useNewQueryClient() {
  return queryClient;
}
