import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { IssueService, IssueSettingService } from "../../Services";
import { DocumentOptionColumns } from "../Common";
import { sortArrayByKey } from "../utils";
import i18n from "i18next";
import useCurrentProjectId from 'projects/hooks/useCurrentProjectId';
import useProjectDetailsQuery from 'projects/hooks/useProjectDetailsQuery';
import useProjectWorkPhasesQuery from "labels/hooks/useProjectWorkPhasesQuery";
import useIssuesByProjectIdQuery from 'issues/hooks/useIssuesByProjectIdQuery';
import useProjectBuildingsQuery from 'labels/hooks/useProjectBuildingsQuery';
import useProjectDisciplinesQuery from 'labels/hooks/useProjectDisciplinesQuery';
import useProjectFloorsQuery from 'labels/hooks/useProjectFloorsQuery';

//removed users with external role
const getFilteredUsers = (current_project, users) => {
  const externalRole = current_project.collaboratorRoleDefinitions.find(
    (role) => role.name === "External"
  );

  const collaboratorExternalRoleIds = current_project.collaboratorRoles
    .filter((role) => role.roleDefinitionId === externalRole.id)
    .map((role) => role.collaboratorId);

  const filtredUsers = users.filter((user) => {
    const filters = collaboratorExternalRoleIds.filter((id) => id === user.value);
    if (!filters?.length) {
      return user;
    }
  });

  return filtredUsers;
};

export default function useIssueFilterItems(optionColumn = null) {
  // const currentProjectId = useCurrentProjectId();
  // const { data: currentProject } = useProjectDetailsQuery(currentProjectId);
  // const { data: disciplines } = useProjectDisciplinesQuery();
  // const { data: buildings } = useProjectBuildingsQuery();
  // const { data: floors } = useProjectFloorsQuery();
  // const { data: workphasesData } = useProjectWorkPhasesQuery();
  // const { data: issues } = useIssuesByProjectIdQuery();
  // const [workphases, setWorkphases] = useState([]);
  // const [statuses, setStatuses] = useState([]);
  // const [priorities, setPriorities] = useState([]);
  // const [types, setTypes] = useState([]);
  // const [issueFilterItems, setIssueFilterItems] = useState({
  //   statuses: [],
  //   priorities: [],
  //   types: [],
  //   sources: [],
  //   users: [],
  //   categories: [],
  //   buildings: [],
  //   floors: [],
  //   workphases: [],
  //   visibility: [],
  // });

  // useEffect(() => {
  //   if (!currentProject?.id) return;
  //   if (!workphasesData) return;
  //   const promises = {
  //     issueStatuses: IssueSettingService.getIssueStatus({
  //       projectId: currentProject.id,
  //     }),
  //     issuePriorities: IssueSettingService.getIssuePriority({
  //       projectId: currentProject.id,
  //     }),
  //     issueTypes: IssueSettingService.getIssueType({
  //       projectId: currentProject.id,
  //     }),
  //   };
  //   Promise.all([
  //     promises.issueStatuses,
  //     promises.issuePriorities,
  //     promises.issueTypes])
  //   .then(([issueStatuses, issuePriorities, issueTypes]) => {
  //       setStatuses(issueStatuses.map((status) => ({
  //         ...status,
  //         label: status.name,
  //         value: status.id,
  //       })));
  //       setPriorities(issuePriorities.map((priority) => ({
  //         ...priority,
  //         label: priority.name,
  //         value: priority.id,
  //       })));
  //       setTypes(issueTypes.map((type) => ({
  //         ...type,
  //         label: type.name,
  //         value: type.id,
  //       })));
  //       setWorkphases(workphasesData?.map((workphase) => ({
  //         ...workphase,
  //         label: workphase.name,
  //         value: workphase.id,
  //       })));
  //     })
  // }, [currentProject?.id, workphasesData]);

  // useEffect(() => {
  //   if (!currentProject || !disciplines || !buildings || !floors || !workphases || !issues) return;

  //   const {
  //     userPreviews,
  //     collaboratorRoleDefinitions,
  //   } = currentProject;

  //   const sources = [];
  //   issues.forEach((item) => {
  //     sources.indexOf(item["source"]) !== -1 || sources.push(item["source"]);
  //   });

  //   const visibilities = [
  //     { label: i18n.t("public", "Public"), value: 0 },
  //     { label: i18n.t("private", "Private"), value: 1 },
  //     { label: i18n.t("restricted", "Restricted"), value: 2 },
  //   ];

  //   let items = { ...issueFilterItems };
  //   const allUsers = userPreviews.map((item) => ({
  //     ...item,
  //     label: `${item.firstName} ${item.lastName}`,
  //     value: item.id,
  //   }));

  //   items = {
  //     collaboratorRoleDefinitions: collaboratorRoleDefinitions.filter(
  //       (r) => r.name !== "External"
  //     ),
  //     statuses,
  //     priorities,
  //     types,
  //     allUsers,
  //     users: getFilteredUsers(currentProject, allUsers),
  //     // label & value fields are necessary for select dropdown values
  //     // metaId is used in project encoding settings
  //     sources: sources.map((item) => ({
  //       label: item,
  //       value: item,
  //     })),
  //     categories: disciplines.map((item) => ({
  //       ...item,
  //       value: item.id,
  //       label:
  //         optionColumn === DocumentOptionColumns.FullLength
  //           ? item.name
  //           : item.abbreviation,
  //       metaId: item.id,
  //     })),
  //     buildings: buildings.map((item) => ({
  //       ...item,
  //       label:
  //         optionColumn === DocumentOptionColumns.FullLength
  //           ? item.name
  //           : item.abbreviation,
  //       value: item.id,
  //       metaId: item.id,
  //     })),
  //     floors: floors.map((item) => ({
  //       ...item,
  //       label:
  //         optionColumn === DocumentOptionColumns.FullLength
  //           ? item.name
  //           : item.abbreviation,
  //       value: item.id,
  //       metaId: item.id,
  //     })),
  //     visibility: visibilities,
  //     workphases,
  //   };
  //   setIssueFilterItems(items);
  // }, [currentProject, statuses, priorities, types, issues, workphases, optionColumn]);

  // return { issues, issueFilterItems };
  return useMemo(() => ({ issues: [], issueFilterItems: { floors: [], categories: [], statuses: [], types: [], priorities: [], buildings: [] } }), []);
}
