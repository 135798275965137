import { Vector3 } from "three";
import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer";
import { Bubble } from "./visoBubble";

const toolTipStyle = "bubble-Info";
const titleId = "Viso-Issue-Title";

export class BubbleTooltip extends CSS2DObject {
  private instanceId?: number;

  constructor(private bubble: Bubble) {
    const htmlSvg = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "svg"
    );
    htmlSvg.setAttribute("width", "7");
    htmlSvg.setAttribute("height", "7");
    htmlSvg.setAttribute("viewBox", "0 0 8 7");
    htmlSvg.setAttribute("fill", "none");

    const htmlPath1 = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path"
    );
    htmlPath1.setAttribute(
      "d",
      "M6.79851 0H0.201493C0.0895522 0 0 0.0895522 0 0.201493V3.36418C0 3.47612 0.0895522 3.56567 0.201493 3.56567C0.313433 3.56567 0.402985 3.47612 0.402985 3.36418V0.402985H6.59701V6.59701H3.61791C3.50597 6.59701 3.41642 6.68657 3.41642 6.79851C3.41642 6.91045 3.50597 7 3.61791 7H6.79851C6.91045 7 7 6.91045 7 6.79851V0.201493C7 0.0895522 6.91045 0 6.79851 0Z"
    );
    htmlPath1.setAttribute("fill", "#E70505");

    htmlSvg.appendChild(htmlPath1);

    const htmlPath2 = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path"
    );
    htmlPath2.setAttribute(
      "d",
      "M2.6209 4.17773H0.201493C0.0895522 4.17773 0 4.26729 0 4.37923V6.79863C0 6.91057 0.0895522 7.00012 0.201493 7.00012H2.6209C2.73284 7.00012 2.82239 6.91057 2.82239 6.79863V4.37923C2.82239 4.26878 2.73134 4.17773 2.6209 4.17773ZM2.4194 6.59714H0.402985V4.58072H2.4194V6.59714Z"
    );
    htmlPath2.setAttribute("fill", "#E70505");

    htmlSvg.appendChild(htmlPath2);

    const htmlPath3 = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path"
    );
    htmlPath3.setAttribute(
      "d",
      "M5.3791 1.90681V2.97397C5.3791 3.08591 5.46865 3.17547 5.58059 3.17547C5.69253 3.17547 5.78208 3.08591 5.78208 2.97397V1.42024C5.78208 1.3083 5.69253 1.21875 5.58059 1.21875H4.02686C3.91492 1.21875 3.82536 1.3083 3.82536 1.42024C3.82536 1.53218 3.91492 1.62174 4.02686 1.62174H5.09402L3.17163 3.54412C3.09253 3.62323 3.09253 3.75009 3.17163 3.8292C3.21044 3.868 3.26268 3.8889 3.31342 3.8889C3.36417 3.8889 3.41641 3.8695 3.45522 3.8292L5.3791 1.90681Z"
    );
    htmlPath3.setAttribute("fill", "#E70505");

    htmlSvg.appendChild(htmlPath3);

    const htmlTitle = document.createElement("label");
    htmlTitle.id = titleId;
    htmlTitle.textContent = "";

    const htmlDiv = document.createElement("div");
    htmlDiv.className = toolTipStyle;
    htmlDiv.appendChild(htmlTitle);
    htmlDiv.appendChild(htmlSvg);

    super(htmlDiv);
  }

  setContent(content: string, instanceId: number) {
    const titleElem = document.getElementById(titleId);
    if (titleElem) {
      titleElem.textContent = content;
    }

    this.instanceId = instanceId;
  }

  setPosition(position: Vector3) {
    this.position.copy(position);
  }

  connect(isVisible: boolean) {
    this.visible = isVisible;
    if (isVisible) {
      document.body.addEventListener("click", this.onClick);
    } else {
      document.body.removeEventListener("click", this.onClick);
    }
  }
  
  private onClick = () => {
    if (this.instanceId != undefined) {
      this.bubble.openIssue(this.instanceId);
    }
  };
}
