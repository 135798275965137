import React, { useCallback, useMemo } from 'react';
import { Checkbox } from '@mui/material';
import useIssuesSelectionContext from 'issues/hooks/useIssuesSelectionContext';
import useIssuesIdsQuery from 'issues/hooks/useIssuesIdsQuery';
import useIssuesFilterContext from 'issues/hooks/useIssuesFilterContext';

export default function CheckboxHeaderCell() {
  const { selectedIssueIds, setSelectedIssueIds } = useIssuesSelectionContext();
  const { odataQuery } = useIssuesFilterContext();
  const { data: ids } = useIssuesIdsQuery(odataQuery);
  const allSelected = useMemo(() => !!ids?.length && selectedIssueIds.length === ids.length, [ids, selectedIssueIds.length]);
  const indeterminate = useMemo(() => !!ids && selectedIssueIds.length > 0 && selectedIssueIds.length < ids.length, [ids, selectedIssueIds.length]);
  const disabled = useMemo(() => !ids?.length, [ids?.length]);

  const onClick = useCallback(() => {
    if (allSelected) setSelectedIssueIds([]);
    else setSelectedIssueIds(ids ?? []);
  }, [allSelected, ids, setSelectedIssueIds]);

  return <Checkbox checked={allSelected} disabled={disabled} indeterminate={indeterminate} sx={{ ml: '25px' }} onClick={onClick} />;
}
