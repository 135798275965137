import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import usePasswordApi from 'users/hooks/usePasswordApi';
import PasswordValidationResponseData from 'users/types/PasswordValidationResponseData';
import { debounce } from 'lodash';
import ISxProps from 'common/types/ISxProps';

interface RuleFeedbackItemProps {
  matched: boolean | undefined,
  title: string,
  minimumRulesMet?: boolean | undefined,
}

interface ValidationState {
  validationResponseData: PasswordValidationResponseData,
  validationDate: Date,
}

function RuleFeedbackItem({
  title,
  matched,
  minimumRulesMet,
}: RuleFeedbackItemProps) {
  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '48px auto', gap: 1, minHeight: 32 }}>
      {matched !== undefined
        ? matched
          ? <CheckIcon color="success" />
          : <ErrorIcon color={minimumRulesMet ? 'inherit' : 'error'} />
        : <CircularProgress size={12} />}
      <Typography>{title}</Typography>
    </Box>
  );
}

interface PasswordValidationPanelProps extends ISxProps {
  onChangeNewPasswordValid: (valid: boolean | undefined) => void,
  newPassword: string,
}

export default function PasswordValidationPanel({
  sx,
  onChangeNewPasswordValid,
  newPassword,
}: PasswordValidationPanelProps) {
  const { t } = useTranslation('users');
  const { validatePassword } = usePasswordApi();
  const [newPasswordValidationResult, setNewPasswordValidationResult] = useState<ValidationState | undefined>(undefined);
  const minimumRulesMet = useMemo(() => {
    if (!newPasswordValidationResult) return undefined;
    const metRules = [
      newPasswordValidationResult.validationResponseData.hasNumber,
      newPasswordValidationResult.validationResponseData.hasSymbol,
      newPasswordValidationResult.validationResponseData.hasUpperCase,
      newPasswordValidationResult.validationResponseData.hasLowerCase,
      newPasswordValidationResult.validationResponseData.isSuperLong,
    ].filter((rule) => rule);
    return metRules.length >= newPasswordValidationResult.validationResponseData.minimumRulesToApply;
  }, [newPasswordValidationResult]);

  const validateNewPasswordDebounced = useMemo(() => debounce(async (value: string) => {
    const now = new Date();
    const validationResponseData = await validatePassword(value);
    setNewPasswordValidationResult((current) => {
      if (current && current.validationDate > now) return current; // skip out-of-sync responses
      const inputValid = validationResponseData ? value.length > 0 && validationResponseData.isValid : undefined;
      onChangeNewPasswordValid(inputValid);
      return {
        validationDate: now,
        validationResponseData,
      };
    });
  }, 600), [onChangeNewPasswordValid, validatePassword]);

  useEffect(() => {
    validateNewPasswordDebounced(newPassword);
  }, [newPassword, validateNewPasswordDebounced]);

  return (
    <Alert id="PasswordRulesPanel" severity="info" sx={sx}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box>
          <Typography variant="h4" sx={{ mb: 1 }}>
            {t('password-validation-panel_rules-message', 'Required password properties:')}
          </Typography>
          <RuleFeedbackItem
            matched={newPasswordValidationResult?.validationResponseData.hasMinimumLength}
            title={t('password-validation-panel_rule-min-length', 'Has at least 8 characters')}
          />
          <RuleFeedbackItem
            matched={newPasswordValidationResult !== undefined ? !newPasswordValidationResult.validationResponseData.hasForbiddenSymbols : undefined}
            title={t('password-validation-panel_rule-no-invalid-symbols', 'Does not contain invalid symbol characters')}
          />
        </Box>
        <Box>
          <Typography variant="h5" sx={{ mb: 1 }}>
            {t('password-validation-panel_features-message', 'At least {{minFeatureCount}} of the following features:', { minFeatureCount: newPasswordValidationResult?.validationResponseData.minimumRulesToApply ?? '...' })}
          </Typography>
          <RuleFeedbackItem
            matched={newPasswordValidationResult?.validationResponseData.hasNumber}
            minimumRulesMet={minimumRulesMet}
            title={t('password-validation-panel_feature-contains-number', 'Contains a number')}
          />
          <RuleFeedbackItem
            matched={newPasswordValidationResult?.validationResponseData.hasSymbol}
            minimumRulesMet={minimumRulesMet}
            title={t('password-validation-panel_feature-contains-symbol', 'Contains a symbol')}
          />
          <RuleFeedbackItem
            matched={newPasswordValidationResult?.validationResponseData.hasUpperCase}
            minimumRulesMet={minimumRulesMet}
            title={t('password-validation-panel_feature-contains-upper-case', 'Contains an upper case character')}
          />
          <RuleFeedbackItem
            matched={newPasswordValidationResult?.validationResponseData.hasLowerCase}
            minimumRulesMet={minimumRulesMet}
            title={t('password-validation-panel_feature-contains-lower-case', 'Contains a lower case character')}
          />
          <RuleFeedbackItem
            matched={newPasswordValidationResult?.validationResponseData.isSuperLong}
            minimumRulesMet={minimumRulesMet}
            title={t('password-validation-panel_feature-super-long', 'Has 12 characters or more')}
          />
        </Box>
      </Box>
    </Alert>
  );
}
