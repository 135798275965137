import React, { useCallback, useMemo, useRef, useState } from 'react';
import ModelSelectionContext, { ModelSelectionContextState } from 'models/contexts/ModelSelectionContext';
import IChildren from 'common/types/IChildren';
import { isEqual } from 'lodash';
import ViewpointDto from 'issues/types/ViewpointDto';

interface ModelSelectionContextProviderProps extends IChildren {
  initiallySelectedViewpoint?: ViewpointDto | undefined,
  initiallySelectedModelFileIds?: string[] | undefined,
  selectedIssueId: string | undefined,
  setSelectedIssueId: (value: string | undefined) => void,
}

export default function ModelSelectionContextProvider({
  initiallySelectedViewpoint,
  initiallySelectedModelFileIds,
  selectedIssueId,
  setSelectedIssueId,
  children,
}: ModelSelectionContextProviderProps) {
  const [stagedModelFileIds, setStagedModelFileIds] = useState<string[]>(initiallySelectedModelFileIds ?? []);
  const stagedModelFileIdsSet = useMemo(() => new Set(stagedModelFileIds), [stagedModelFileIds]);
  const [modelFileIds, setModelFileIds] = useState<string[]>(initiallySelectedModelFileIds ?? []);

  const [selectedViewpoint, setSelectedViewpoint] = useState(initiallySelectedViewpoint);

  const isLoadingRef = useRef(false);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const setIsLoading = useCallback((value: boolean) => {
    isLoadingRef.current = value;
    setIsLoadingState(value);
  }, []);

  // this is indeed super sketchy but the way how the old viewer library is slapped on the old model view
  // does not allow for a solid asynchronous loading mechanism that allows us to await loading in order to e.g. load a viewpoint
  const getLoadingPromise = useCallback(() => new Promise<void>((resolve, reject) => {
    try {
      const interval = setInterval(() => {
        if (!isLoadingRef.current) {
          clearInterval(interval);
          resolve();
        }
      }, 500);
    } catch (error) {
      reject(error);
    }
  }), []);

  const stagedModelFileIdsChanged = useMemo(() => !isEqual(stagedModelFileIds, modelFileIds), [modelFileIds, stagedModelFileIds]);
  const stageModelFileId = useCallback((id: string) => setStagedModelFileIds((prev) => Array.from(new Set(prev.concat(id)))), []);
  const unstageModelFileId = useCallback((id: string) => setStagedModelFileIds((prev) => prev.filter((prevId) => prevId !== id)), []);
  const loadStagedModelFileIds = useCallback(() => {
    setModelFileIds(stagedModelFileIds);
  }, [stagedModelFileIds]);
  const loadModelFileIds = useCallback((ids: string[]) => {
    const nextIds = Array.from(new Set([...stagedModelFileIds, ...ids]));
    setStagedModelFileIds(nextIds);
    setModelFileIds(nextIds);
  }, [stagedModelFileIds]);

  const state = useMemo<ModelSelectionContextState>(() => ({
    modelFileIds,
    stageModelFileId,
    unstageModelFileId,
    stagedModelFileIdsSet,
    loadStagedModelFileIds,
    loadModelFileIds,
    stagedModelFileIdsChanged,
    isLoading: isLoadingState,
    setIsLoading,
    getLoadingPromise,
    selectedViewpoint,
    setSelectedViewpoint,
    selectedIssueId,
    setSelectedIssueId,
  }), [modelFileIds, stageModelFileId, unstageModelFileId, stagedModelFileIdsSet, loadStagedModelFileIds, loadModelFileIds, stagedModelFileIdsChanged, isLoadingState, setIsLoading, getLoadingPromise, selectedViewpoint, selectedIssueId, setSelectedIssueId]);
  return (
    <ModelSelectionContext.Provider value={state}>
      {children}
    </ModelSelectionContext.Provider>
  );
}
