import type { Theme, ComponentsProps, ComponentsVariants } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

interface MuiSwitchComponent {
  defaultProps?: ComponentsProps['MuiSwitch'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiSwitch'];
  variants?: ComponentsVariants['MuiSwitch'];
}

const VisoSwitch: MuiSwitchComponent = {
  styleOverrides: {
    root: ({ theme }) => ({
      width: 43,
      height: 24,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 3,
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: 'translateX(19px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundColor: '#4b6ffc',
            opacity: 1,
            border: 0,
          },
          '&.MuiSwitch-colorSuccess': {
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.success.main,
            },
          },
          '&.MuiSwitch-colorError': {
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.error.main,
            },
          },
          '&.MuiSwitch-colorWarning': {
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.warning.main,
            },
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.MuiSwitch-colorSuccess': {
          '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.success.light,
          },
        },
        '&.MuiSwitch-colorError': {
          '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.error.light,
          },
        },
        '&.MuiSwitch-colorWarning': {
          '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.warning.light,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: '#33cf4d',
          border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color:
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18,
        height: 18,
      },
      '& .MuiSwitch-track': {
        borderRadius: 24 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
          duration: 500,
        }),
      },
    }),
  },
};

export default VisoSwitch;
