import { Dispatch, SetStateAction, createContext } from 'react';

export interface IssuesBoardDragAndDropContextState {
  statusLabelIdByissueIdTempOverrideMap: Map<string, string>,
  setTemporaryStatusId: (issueId: string, labelId: string | undefined) => void,
  draggedIssueId: string | undefined,
  setDraggedIssueId: Dispatch<SetStateAction<string | undefined>>,
}

const IssuesBoardDragAndDropContext = createContext<IssuesBoardDragAndDropContextState>(undefined!);
export default IssuesBoardDragAndDropContext;
