import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import ISxProps from 'common/types/ISxProps';

export default function CenteredCircularProgress({
  sx,
}: ISxProps) {
  return (
    <Box
      id="CenteredCircularProgress"
      sx={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        ...sx,
      }}
    >
      <Box><CircularProgress /></Box>
    </Box>
  );
}
