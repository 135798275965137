import { ColDef, ICellRendererParams } from 'ag-grid-community';
import useEmailsDynamicLayout from 'dynamic-layout/hooks/useEmailsDynamicLayout';
import EmailAttachmentsCell from 'emails/components/cell-renderers/EmailAttachmentsCell';
import EmailBodyCell from 'emails/components/cell-renderers/EmailBodyCell';
import EmailDateCell from 'emails/components/cell-renderers/EmailDateCell';
import EmailTagsCell from 'emails/components/cell-renderers/EmailTagsCell';
import EmailsLinkedIssuesCell from 'emails/components/cell-renderers/EmailsLinkedIssuesCell';
import useColumnDisplayNamesByFieldName from 'emails/hooks/useColumnDisplayNamesByFieldName';
import InboxEmailDto from 'emails/types/InboxEmailDto';
import { ReactNode, useMemo } from 'react';

const columnNames = new Set<string>([
  'subject',
  'creationDate',
  'from',
  'to',
  'linkedIssueIds',
  'attachments',
  'tagIds',
  'emailBody',
  'cc',
  'editDate',
]);

interface CellRenderersByColumnName {
  [fieldName: string]: (params: ICellRendererParams<InboxEmailDto>) => ReactNode
}

const cellRendererByColumnName: CellRenderersByColumnName = {
  emailBody: EmailBodyCell,
  creationDate: EmailDateCell,
  editDate: EmailDateCell,
  linkedIssueIds: EmailsLinkedIssuesCell,
  attachments: EmailAttachmentsCell,
  tagIds: EmailTagsCell,
};

export default function useEmailsDataGridColumnDefinitions() {
  const headerNamesByColumnNames = useColumnDisplayNamesByFieldName();
  const { columnWidthsByColumnName, orderedVisibleColumnNames } = useEmailsDynamicLayout();
  const columnsFromDynamicLayout = useMemo<ColDef<InboxEmailDto>[]>(() => (orderedVisibleColumnNames && columnWidthsByColumnName
    ? orderedVisibleColumnNames
      .filter((columnName) => columnNames.has(columnName))
      .map((columnName) => ({
        colId: columnName,
        field: columnName as keyof InboxEmailDto,
        headerName: headerNamesByColumnNames[columnName as keyof InboxEmailDto],
        width: columnWidthsByColumnName.get(columnName),
        sortable: true,
        cellRenderer: cellRendererByColumnName[columnName],
      }))
    : []), [columnWidthsByColumnName, headerNamesByColumnNames, orderedVisibleColumnNames]);
  return columnsFromDynamicLayout;
}
