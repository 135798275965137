import React, { useCallback, useMemo, useState } from 'react';
import { Alert, Box, ButtonBase, CircularProgress, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import useDocumentVersionsQuery from 'documents/hooks/useDocumentVersionsQuery';
import FileType from 'documents/types/FileType';
import ImageGalleryDialog from 'resources/components/ImageGalleryDialog';
import useResourceQuery from 'resources/hooks/useResourceQuery';
import IssueSelectLinkedDocumentsDialog from 'issues/components/IssueSelectLinkedDocumentsDialog';
import useIssueUpdateMutation from 'issues/hooks/useIssueUpdateMutation';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';

interface IssueLinkedImagesPanelProps {
  issueId: string,
}

export default function IssueLinkedImagesPanel({
  issueId,
}: IssueLinkedImagesPanelProps) {
  const { t } = useTranslation('issues');
  const theme = useTheme();
  const { data: issue } = useIssueQuery(issueId);
  const odataQuery = useMemo(() => (issue ? { filter: { id: { in: issue.linkedDocumentVersionIds } } } : undefined), [issue]);
  const { data: linkedDocumentVersions } = useDocumentVersionsQuery(odataQuery);
  const imageResourceIds = useMemo(() => linkedDocumentVersions?.filter((v) => v.fileType === FileType.Image && !v.originalFileName.endsWith('.svg')).map((v) => v.fileId), [linkedDocumentVersions]);
  const [imageGalleryDialogOpen, setImageGalleryDialogOpen] = useState<boolean>(false);
  const onClickOpenImageGallery = useCallback(() => setImageGalleryDialogOpen(true), []);
  const onCloseImageGalleryDialog = useCallback(() => setImageGalleryDialogOpen(false), []);
  const { data: thumbnailDataUrl } = useResourceQuery(imageResourceIds?.[0]);

  const [issueSelectLinkedDocumentsDialog, setIssueSelectLinkedDocumentsDialog] = useState<boolean>(false);
  const { mutateAsync } = useIssueUpdateMutation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const getRequestErrorMessage = useRequestErrorMessage();

  const onCloseIssueSelectLinkedDocumentsDialog = useCallback(async (selectedDocumentVersionIds: string[] | undefined) => {
    if (!issue) throw new Error('dependency error');
    setIssueSelectLinkedDocumentsDialog(false);
    try {
      if (selectedDocumentVersionIds) {
        const nextLinkedDocumentVersionIds = Array.from(new Set((issue.linkedDocumentVersionIds ?? []).concat(...selectedDocumentVersionIds)));
        await mutateAsync({
          id: issueId,
          linkedDocumentVersionIds: { value: nextLinkedDocumentVersionIds },
        });
      }
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [getRequestErrorMessage, issue, issueId, mutateAsync]);

  if (!issue?.thumbnailImageId && !imageResourceIds?.length) return null;

  return (
    <Box id="IssueLinkedImagesPanel">
      <Box sx={{
        backgroundColor: theme.palette.background.default,
        borderRadius: '8px',
        boxShadow: '0px 1px 4px -1px rgba(0,0,0,0.3)',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography variant="h4">
            {t('issue-linked-images-panel_title', 'Images')}
            {!!imageResourceIds && ` (${imageResourceIds.length})`}
            {!imageResourceIds && <CircularProgress size={12} sx={{ ml: 1 }} />}
          </Typography>
          {!!issueSelectLinkedDocumentsDialog && <IssueSelectLinkedDocumentsDialog issueId={issueId} onClose={onCloseIssueSelectLinkedDocumentsDialog} />}
        </Box>
        {!!errorMessage && <Box sx={{ p: 2 }}><Alert severity="error" onClose={onCloseErrorMessage}>{errorMessage}</Alert></Box>}
        {!!imageResourceIds?.length && (
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            {!!thumbnailDataUrl && (
              <ButtonBase onClick={onClickOpenImageGallery} sx={{ width: '96px', height: '96px', borderRadius: '8px' }}>
                <img src={thumbnailDataUrl} style={{ width: '96px', height: '96px', borderRadius: '8px', objectFit: 'cover' }} alt="Thumbnail" />
              </ButtonBase>
            )}
            {imageResourceIds.length !== 1 && (
              <ButtonBase
                disabled={imageResourceIds.length === 0}
                onClick={onClickOpenImageGallery}
                sx={{ width: '96px', height: '96px', borderRadius: '8px', backgroundColor: theme.palette.grey[300], fontSize: 14, fontWeight: 700 }}
              >
                {imageResourceIds.length === 0 ? '0' : `+${imageResourceIds.length - 1}`}
              </ButtonBase>
            )}
          </Box>
        )}
      </Box>
      {!!imageGalleryDialogOpen && <ImageGalleryDialog resourceIds={imageResourceIds} onClose={onCloseImageGalleryDialog} />}
    </Box>
  );
}
