import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import LabelDto from 'labels/types/LabelDto';
import RestoreLabelDto from 'labels/types/RestoreLabelDto';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import { useCallback } from 'react';

interface LabelRestoreMutationContext {
  cachedEntities: LabelDto[] | undefined,
}

export default function useLabelRestoreMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.Label);
  const mutationFn = useCallback(async (dto: RestoreLabelDto) => {
    const { data } = await axiosInstance.patch<LabelDto>(`${ApiEndpoint.LabelRestore}`, dto);
    return data;
  }, [axiosInstance]);
  const onSuccess = useCallback(() => {
    queryKeyBases.forEach((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
  }, [queryKeyBases, queryClient]);
  return useMutation<LabelDto, unknown, RestoreLabelDto, LabelRestoreMutationContext>({
    mutationFn,
    onSuccess,
  });
}
