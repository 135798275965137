import { useMemo } from 'react';
import { ModelsPanelDockviewPanelId } from 'models/contexts/ModelsPanelDockviewContext';
import { useTranslation } from 'react-i18next';

export default function useModelsPanelDockviewPanelTitleTranslations() {
  const { t } = useTranslation('models');
  return useMemo(() => ({
    [ModelsPanelDockviewPanelId.Viewer3d]: t('models-panel_viewer-3d-panel-title', '3D View'),
    [ModelsPanelDockviewPanelId.ModelSelection]: t('models-panel_model-selection-panel-title', 'Model Selection'),
    [ModelsPanelDockviewPanelId.Issues]: t('models-panel_issues-list-panel-title', 'Issues'),
    [ModelsPanelDockviewPanelId.SectionPlanes]: t('models-panel_section-planes-panel-title', 'Section Planes'),
    [ModelsPanelDockviewPanelId.Measurements]: t('models-panel_measurements-panel-title', 'Measurements'),
    [ModelsPanelDockviewPanelId.Plan2d]: t('models-panel_plan-2d-panel-title', '2D Plan'),
    [ModelsPanelDockviewPanelId.ComponentDetails]: t('models-panel_component-details-panel-title', 'Component Details'),
  }), [t]);
}
