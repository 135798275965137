import React from 'react';
import {
  Box, FormControl, InputLabel, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DocumentFilterState from 'documents-filter/types/DocumentFilterState';
import ChipMultiselect from 'common/components/ChipMultiselect';
import useProjectWorkPhasesQuery from 'labels/hooks/useProjectWorkPhasesQuery';
import useProjectBuildingsQuery from 'labels/hooks/useProjectBuildingsQuery';
import useProjectDisciplinesQuery from 'labels/hooks/useProjectDisciplinesQuery';
import useProjectFloorsQuery from 'labels/hooks/useProjectFloorsQuery';

interface ModelFilterSectionProps {
  value: DocumentFilterState,
  onChange: (state: DocumentFilterState) => void,
}

export default function ModelFilterSection({
  value,
  onChange,
}: ModelFilterSectionProps) {
  const { t } = useTranslation('documents-filter');
  const { data: workphases } = useProjectWorkPhasesQuery();
  const { data: disciplines } = useProjectDisciplinesQuery();
  const { data: buildings } = useProjectBuildingsQuery();
  const { data: floors } = useProjectFloorsQuery();
  return (
    <>
      <Typography variant="h4" sx={{ mt: 3 }}>{t('documents-filter_model-header', 'Model')}</Typography>
      <Box sx={{
        display: 'grid', gridTemplateColumns: '.5fr .5fr', columnGap: '4px', rowGap: '8px', mt: 1,
      }}
      >
        <FormControl>
          <InputLabel id="documents-filter_discipline-label">{t('documents-filter_discipline-label', 'Discipline')}</InputLabel>
          <ChipMultiselect
            label={t('documents-filter_discipline-label', 'Discipline')}
            value={value.disciplineLabelIds ?? []}
            onChange={(ids) => onChange({ ...value, disciplineLabelIds: ids })}
            entities={disciplines}
          />
        </FormControl>
        <FormControl>
          <InputLabel id="documents-filter_building-label">{t('documents-filter_building-label', 'Building')}</InputLabel>
          <ChipMultiselect
            label={t('documents-filter_building-label', 'Building')}
            value={value.buildingLabelIds ?? []}
            onChange={(ids) => onChange({ ...value, buildingLabelIds: ids })}
            entities={buildings}
          />
        </FormControl>
        <FormControl>
          <InputLabel id="documents-filter_floor-label">{t('documents-filter_floor-label', 'Floor')}</InputLabel>
          <ChipMultiselect
            label={t('documents-filter_floor-label', 'Floor')}
            value={value.floorLabelIds ?? []}
            onChange={(ids) => onChange({ ...value, floorLabelIds: ids })}
            entities={floors}
          />
        </FormControl>
        <FormControl>
          <InputLabel id="documents-filter_workphase-label">{t('documents-filter_workphase-label', 'Workphase')}</InputLabel>
          <ChipMultiselect
            label={t('documents-filter_workphase-label', 'Workphase')}
            value={value.workPhaseLabelIds ?? []}
            onChange={(ids) => onChange({ ...value, workPhaseLabelIds: ids })}
            entities={workphases}
          />
        </FormControl>
      </Box>
    </>
  );
}
