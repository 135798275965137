import NamingSchemeGroupType from 'naming-schemes/types/NamingSchemeGroupType';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type NamingSchemeGroupTypeTranslations = {
  [key in NamingSchemeGroupType]: string;
};

export default function useNamingSchemeGroupTypeTranslations() {
  const { t } = useTranslation('naming-schemes');
  return useMemo<NamingSchemeGroupTypeTranslations>(() => ({
    [NamingSchemeGroupType.Disciplines]: t('use-naming-scheme-group-type-translations_disciplines', 'Disciplines'),
    [NamingSchemeGroupType.Building]: t('use-naming-scheme-group-type-translations_building', 'Building'),
    [NamingSchemeGroupType.Floor]: t('use-naming-scheme-group-type-translations_floor', 'Floor'),
    [NamingSchemeGroupType.WorkPhases]: t('use-naming-scheme-group-type-translations_workphases', 'Work phases'),
    [NamingSchemeGroupType.CreationDate]: t('use-naming-scheme-group-type-translations_creation-date', 'Creation date'),
    [NamingSchemeGroupType.Version]: t('use-naming-scheme-group-type-translations_version', 'Version'),
    [NamingSchemeGroupType.FreeText]: t('use-naming-scheme-group-type-translations_free-text', 'Free text'),
    [NamingSchemeGroupType.Project]: t('use-naming-scheme-group-type-translations_project', 'Project'),
    [NamingSchemeGroupType.Custom]: t('use-naming-scheme-group-type-translations_custom', 'Custom'),
    [NamingSchemeGroupType.DocStatus]: t('use-naming-scheme-group-type-translations_document-status', 'Document status'),
    [NamingSchemeGroupType.Number]: t('use-naming-scheme-group-type-translations_number', 'Number'),
    [NamingSchemeGroupType.Chars]: t('use-naming-scheme-group-type-translations_characters', 'Characters'),
    [NamingSchemeGroupType.FreeNumber]: t('use-naming-scheme-group-type-translations_free-number', 'Free number'),
    [NamingSchemeGroupType.NumbersSpecialChars]: t('use-naming-scheme-group-type-translations_numbers-and-special-characters', 'Numbers and special characters'),
  }), [t]);
}
