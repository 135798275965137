import { Select, Box, MenuItem, Switch, Typography, FormControl, InputLabel, SelectChangeEvent, RadioGroup, FormControlLabel, Radio, Alert } from '@mui/material';
import StampExample from 'Components/ProjectSettingConfig/StampExample';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useDmsSettingsQuery from 'documents/hooks/useDmsSettingsQuery';
import useDocumentStampSettingsUpdateMutation from 'documents/hooks/useDocumentStampSettingsUpdateMutation';
import StampPosition from 'documents/types/StampPosition';
import StampSettingsDto from 'documents/types/StampSettingsDto';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function DocumentSettingsDocumentStampSection() {
  const { t } = useTranslation('settings');
  const { data: dmsSettings } = useDmsSettingsQuery();

  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);

  const { mutateAsync: updateAsync } = useDocumentStampSettingsUpdateMutation();

  const stampSettings = useMemo(() => (dmsSettings ? dmsSettings.stampSettings : { isStampInsertionOnDownloadEnabled: false, position: StampPosition.BottomLeft, locale: 'en-US' } as StampSettingsDto), [dmsSettings]);

  const updateStampSettings = useCallback(async (update: Partial<StampSettingsDto>) => {
    if (!stampSettings) return;
    try {
      await updateAsync({ ...stampSettings, ...update });
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [getRequestErrorMessage, stampSettings, updateAsync]);

  const onChangeStampEnabled = useCallback(async () => {
    if (!stampSettings) return;
    await updateStampSettings({ isStampInsertionOnDownloadEnabled: !stampSettings.isStampInsertionOnDownloadEnabled });
  }, [stampSettings, updateStampSettings]);

  const onChangePosition = useCallback((event: SelectChangeEvent<StampPosition>) => {
    updateStampSettings({ position: event.target.value as StampPosition });
  }, [updateStampSettings]);

  const onChangeLanguage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    updateStampSettings({ locale: event.target.value as 'de-DE' | 'en-US' });
  }, [updateStampSettings]);

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h4">{t('document-settings-document-stamp-section_title', 'Document stamp')}</Typography>
        <Typography variant="subtitle2" sx={{ opacity: 0.7 }}>{`${t('document-settings-document-stamp-section_stamp-width', 'Width')}: 180mm, ${t('document-settings-document-stamp-section_stamp-height', 'Height')}: 64mm, ${t('document-settings-document-stamp-section_stamp-margin', 'Margin')}: 30mm`}</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 6 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Typography>{t('document-settings-document-stamp-section_switch-description', 'Add an export stamp:')}</Typography>
          <Typography>{t('document-settings-document-stamp-section_position-selector-description', 'Select position:')}</Typography>
          <Typography>{t('document-settings-document-stamp-section_language-selector-description', 'Select language:')}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Switch checked={stampSettings?.isStampInsertionOnDownloadEnabled} onChange={onChangeStampEnabled} />
          <FormControl disabled={!stampSettings?.isStampInsertionOnDownloadEnabled}>
            <InputLabel>{t('document-settings-document-stamp-section_position-selector-label', 'Position')}</InputLabel>
            <Select value={stampSettings?.position} label={t('document-settings-document-stamp-section_position-selector-label', 'Position')} onChange={onChangePosition}>
              <MenuItem value={StampPosition.TopLeft}>{t('document-settings-document-stamp-section_top-left-label', 'Top Left')}</MenuItem>
              <MenuItem value={StampPosition.TopRight}>{t('document-settings-document-stamp-section_top-right-label', 'Top Right')}</MenuItem>
              <MenuItem value={StampPosition.BottomLeft}>{t('document-settings-document-stamp-section_bottom-left-label', 'Bottom Left')}</MenuItem>
              <MenuItem value={StampPosition.BottomRight}>{t('document-settings-document-stamp-section_bottom-right-label', 'Bottom Right')}</MenuItem>
            </Select>
          </FormControl>
          <FormControl disabled={!stampSettings?.isStampInsertionOnDownloadEnabled}>
            <RadioGroup value={stampSettings?.locale} onChange={onChangeLanguage} row>
              <FormControlLabel value="de-DE" control={<Radio />} label={t('document-settings-document-stamp-section_german-label', 'German')} />
              <FormControlLabel value="en-US" control={<Radio />} label={t('document-settings-document-stamp-section_english-label', 'English')} />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box>
          <Typography variant="caption">{t('document-settings-document-stamp-section_stamp-caption', 'Example')}</Typography>
          <StampExample stampSettings={stampSettings ?? null} />
        </Box>
      </Box>
      {!!errorMessage && <Alert style={{ position: 'absolute' }} severity="error" onClose={onCloseErrorMessage}>{errorMessage}</Alert>}
    </Box>
  );
}
