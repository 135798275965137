import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import useResourceQuery from 'resources/hooks/useResourceQuery';
import IssueDto from 'issues/types/IssueDto';
import { Box } from '@mui/material';

export default function ThumbnailImageCell({
  data,
}: ICellRendererParams<IssueDto, String>) {
  const { data: image } = useResourceQuery(data?.thumbnailImageId);
  if (!image) return null;
  return (
    <Box id="ThumbnailImageCell" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <img src={image} width="90px" alt="Thumbnail" />
    </Box>
  );
}
