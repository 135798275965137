import { ComponentsProps, ComponentsVariants } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

interface MuiListSubheaderComponent {
  defaultProps?: ComponentsProps['MuiListSubheader'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiListSubheader'];
  variants?: ComponentsVariants['MuiListSubheader'];
}

const VisoListSubheader: MuiListSubheaderComponent = {
  styleOverrides: {
    root: () => ({
      lineHeight: 2,
    }),
  },
};

export default VisoListSubheader;
