import React, {
  useCallback, useState,
} from 'react';
import { Button, Tooltip } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import EditIcon from '@mui/icons-material/Edit';
import useCurrentFolderId from 'documents-folders/hooks/useCurrentFolderId';
import EditFolderDialog from 'documents-folders/components/EditFolderDialog';
import { useTranslation } from 'react-i18next';

interface EditFolderDialogButtonProps extends ISxProps {
}

export default function EditFolderDialogButton({
  sx,
}: EditFolderDialogButtonProps) {
  const { t } = useTranslation('documents-folders');
  const [isEditFolderDialogOpen, setIsEditFolderDialogOpen] = useState(false);
  const currentFolderId = useCurrentFolderId();

  const onClickEditFolder = useCallback(() => {
    setIsEditFolderDialogOpen(true);
  }, []);

  const onCloseEditFolderDialog = useCallback(() => {
    setIsEditFolderDialogOpen(false);
  }, []);

  return (
    <>
      <Tooltip title={t('edit-folder-menu-button_tooltip', 'Edit folder')}>
        <span>
          <Button
            onClick={onClickEditFolder}
            sx={{ ...sx, minWidth: 'unset', px: 1 }}
            variant="contained"
            id="EditFolderDialogButton"
            disabled={!currentFolderId}
          >
            <EditIcon />
          </Button>
        </span>
      </Tooltip>
      {isEditFolderDialogOpen && currentFolderId && <EditFolderDialog onClose={onCloseEditFolderDialog} folderId={currentFolderId} />}
    </>
  );
}
