import { useContext } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import DocumentUploadContext, { DocumentUploadContextState } from 'upload/contexts/DocumentUploadContext';
import useCurrentCollaboratorRole from 'collaborators/hooks/useCurrentCollaboratorRole';
import RoleAction from 'projects/types/RoleAction';
import useCurrentCollaboratorFolderAccessLevel from 'documents-folders/hooks/useCurrentCollaboratorFolderAccessLevel';
import CollaboratorFolderAccessLevel from 'documents-folders/types/CollaboratorFolderAccessLevel';

export default function useDocumentUploadDropRef(folderId: string | undefined) {
  const { openWizard, addToDocumentsStagedForUpload, uploadingFileHandles } = useContext<DocumentUploadContextState>(DocumentUploadContext);
  const currentUserRole = useCurrentCollaboratorRole();
  const accessLevel = useCurrentCollaboratorFolderAccessLevel(folderId);

  const [{ isActive: canDropDocuments }, documentUploadDropRef] = useDrop<{ files: File[] }, void, { isActive: boolean }>(() => ({
    accept: [NativeTypes.FILE],
    canDrop: () => !uploadingFileHandles?.length && !!folderId && !!currentUserRole?.allowedActions?.has(RoleAction.Document_Upload) && accessLevel === CollaboratorFolderAccessLevel.ReadWrite,
    collect: (monitor) => ({ isActive: monitor.canDrop() && monitor.isOver() }),
    drop(item: { files: File[], folderNames?: string[] } | undefined) {
      if (item?.files?.length && folderId) {
        addToDocumentsStagedForUpload({
          files: item.files,
          fileNamesThatAreFolders: item.folderNames,
          folderId,
        });
        openWizard();
      }
    },
  }), [folderId, uploadingFileHandles, currentUserRole, accessLevel]);

  if (!folderId) return { canDropDocuments: false, documentUploadDropRef: undefined };

  return { canDropDocuments, documentUploadDropRef };
}
