import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import CollaboratorGroupDto from 'collaborators-groups/types/CollaboratorGroupDto';
import { useCallback } from 'react';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import CollaboratorGroupAssignmentUpdateDto from 'collaborators/types/CollaboratorGroupAssignmentUpdateDto';
import CollaboratorDto from 'collaborators/types/CollaboratorDto';

interface CollaboratorGroupUpdateMutationContext {
  cachedEntities: CollaboratorGroupDto[] | undefined,
}

export default function useCollaboratorGroupAssignmentUpdateMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.Collaborator);
  const mutationFn = useCallback(async (dto: CollaboratorGroupAssignmentUpdateDto) => {
    const { data } = await axiosInstance.put<CollaboratorDto>(`${ApiEndpoint.CollaboratorGroups}`, dto);
    return data;
  }, [axiosInstance]);
  const onSettled = useCallback(() => {
    queryKeyBases.forEach((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
  }, [queryKeyBases, queryClient]);
  return useMutation<CollaboratorDto, unknown, CollaboratorGroupAssignmentUpdateDto, CollaboratorGroupUpdateMutationContext>({
    mutationFn,
    onSettled,
  });
}
