import React, { useMemo } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PluginsDownloadItem from 'projects/components/PluginsDownloadItem';
import PluginVersion from 'projects/types/PluginVersion';
import useApiConfig from 'api/hooks/useApiConfig';

interface PluginsDownloadDialogProps {
  onClose: () => void,
}

export default function PluginsDownloadDialog({
  onClose,
}: PluginsDownloadDialogProps) {
  const { t } = useTranslation('projects');
  const { environment } = useApiConfig();

  const solibriVersions: PluginVersion[] = useMemo(() => [
    { name: 'Solibri', url: `https://get.visoplan.de/solibri/${environment}/Solibri_Plugin.exe` },
  ], [environment]);

  const revitVersions: PluginVersion[] = useMemo(() => [
    { name: 'Revit 2024', url: `https://get.visoplan.de/revit/${environment}/Revit_Plugin_2024.exe` },
    { name: 'Revit 2023', url: `https://get.visoplan.de/revit/${environment}/Revit_Plugin_2023.exe` },
    { name: 'Revit 2022', url: `https://get.visoplan.de/revit/${environment}/Revit_Plugin_2022.exe` },
    { name: 'Revit 2021', url: `https://get.visoplan.de/revit/${environment}/Revit_Plugin_2021.exe` },
    { name: 'Revit 2020', url: `https://get.visoplan.de/revit/${environment}/Revit_Plugin_2020.exe` },
    { name: 'Revit 2019', url: `https://get.visoplan.de/revit/${environment}/Revit_Plugin_2019.exe` },
  ], [environment]);

  const archicadVersions: PluginVersion[] = useMemo(() => [
    { name: 'ArchiCAD 27 (Windows)', url: `https://get.visoplan.de/archicad/${environment}/ArchiCAD_Plugin_27.exe` },
    { name: 'ArchiCAD 27 (Mac Intel)', url: `https://get.visoplan.de/archicad/${environment}/ArchiCAD_Plugin_27_Mac.pkg` },
    { name: 'ArchiCAD 27 (Mac Silicon)', url: `https://get.visoplan.de/archicad/${environment}/ArchiCAD_Plugin_27_Mac_Apple_Silicon.pkg` },
    { name: 'ArchiCAD 26 (Windows)', url: `https://get.visoplan.de/archicad/${environment}/ArchiCAD_Plugin_26.exe` },
    { name: 'ArchiCAD 26 (Mac Intel)', url: `https://get.visoplan.de/archicad/${environment}/ArchiCAD_Plugin_26_Mac.pkg` },
    { name: 'ArchiCAD 26 (Mac Silicon)', url: `https://get.visoplan.de/archicad/${environment}/ArchiCAD_Plugin_26_Mac_Apple_Silicon.pkg` },
    { name: 'ArchiCAD 25 (Windows)', url: `https://get.visoplan.de/archicad/${environment}/ArchiCAD_Plugin_25.exe` },
    { name: 'ArchiCAD 25 (Mac Intel)', url: `https://get.visoplan.de/archicad/${environment}/ArchiCAD_Plugin_25_Mac.pkg` },
    { name: 'ArchiCAD 24 (Windows)', url: `https://get.visoplan.de/archicad/${environment}/ArchiCAD_Plugin_24.exe` },
    { name: 'ArchiCAD 24 (Mac Intel)', url: `https://get.visoplan.de/archicad/${environment}/ArchiCAD_Plugin_24_Mac.pkg` },
    { name: 'ArchiCAD 23 (Windows)', url: `https://get.visoplan.de/archicad/${environment}/ArchiCAD_Plugin_23.exe` },
    { name: 'ArchiCAD 23 (Mac Intel)', url: `https://get.visoplan.de/archicad/${environment}/ArchiCAD_Plugin_23_Mac.pkg` },
  ], [environment]);

  const navisworksVersions: PluginVersion[] = useMemo(() => [
    { name: 'Navisworks 2024', url: `https://get.visoplan.de/navisworks/${environment}/Navisworks_Plugin_2024.exe` },
    { name: 'Navisworks 2023', url: `https://get.visoplan.de/navisworks/${environment}/Navisworks_Plugin_2023.exe` },
    { name: 'Navisworks 2022', url: `https://get.visoplan.de/navisworks/${environment}/Navisworks_Plugin_2022.exe` },
    { name: 'Navisworks 2021', url: `https://get.visoplan.de/navisworks/${environment}/Navisworks_Plugin_2021.exe` },
    { name: 'Navisworks 2020', url: `https://get.visoplan.de/navisworks/${environment}/Navisworks_Plugin_2020.exe` },
    { name: 'Navisworks 2019', url: `https://get.visoplan.de/navisworks/${environment}/Navisworks_Plugin_2019.exe` },
  ], [environment]);

  return (
    <Dialog open id="PluginsDownloadDialog" PaperProps={{ sx: { maxWidth: 'unset' } }} onClose={onClose}>
      <DialogTitle>{t('plugins-download-dialog_title', 'Download Visoplan Plug-Ins')}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            py: 4,
          }}
        >
          <PluginsDownloadItem label="Solibri" svgPath="/img/solibri-logo.svg" versions={solibriVersions} />
          <PluginsDownloadItem label="Revit" svgPath="/img/revit-logo.svg" versions={revitVersions} />
          <PluginsDownloadItem label="ArchiCAD" svgPath="/img/archicad-logo.svg" versions={archicadVersions} />
          <PluginsDownloadItem label="Navisworks" svgPath="/img/navisworks-logo.svg" versions={navisworksVersions} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          {t('plugins-download-dialog_close-button-label', 'Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
