import React, { useMemo } from 'react';
import ISxProps from 'common/types/ISxProps';
import LabelDto from 'labels/types/LabelDto';
import IconChip from 'labels/components/IconChip';
import useLabelTypeTranslation from 'labels/hooks/useLabelTypeTranslation';
import useLabelIcons from 'settings/hooks/usLabelIcons';
import useLabelTypeProperties from 'settings/hooks/useLabelTypeProperties';

interface LabelChipProps extends ISxProps {
  id?: string | undefined,
  label: LabelDto | undefined,
  mdiIconPath?: string | undefined,
  tooltip?: string | undefined,
  abbreviated?: boolean,
  onDelete?: (event: any) => void,
  onClick?: (event: any) => void,
  suppressTooltip?: boolean | undefined,
  disabled?: boolean | undefined,
}

export default function LabelChip({
  sx,
  id,
  label,
  mdiIconPath,
  tooltip,
  abbreviated,
  onDelete,
  onClick,
  suppressTooltip,
  disabled,
}: LabelChipProps) {
  const getLabelTypeString = useLabelTypeTranslation();
  const labelTypeString = useMemo(() => (label?.type ? getLabelTypeString(label.type) : ''), [getLabelTypeString, label]);
  const { defaultIcons, otherIcons } = useLabelIcons();
  const { hasIcon, hasColor } = useLabelTypeProperties(label?.type);
  const path = useMemo(() => {
    if (mdiIconPath) return mdiIconPath;
    if (label?.icon && hasIcon) {
      return defaultIcons.get(label.icon) ?? otherIcons.get(label.icon);
    }
    return undefined;
  }, [defaultIcons, hasIcon, label?.icon, mdiIconPath, otherIcons]);
  const tooltipValue = useMemo(() => (!label || suppressTooltip ? undefined : tooltip ?? `${labelTypeString}: ${label.name}`), [label, labelTypeString, suppressTooltip, tooltip]);
  const text = useMemo(() => (abbreviated && label && label.abbreviation ? label.abbreviation : label?.name), [abbreviated, label]);
  const adjustedSx = useMemo(() => ({ ...sx, ...(label?.isDeleted ? { textDecoration: 'line-through' } : {}) }), [label?.isDeleted, sx]);
  if (!label || !getLabelTypeString) return null;
  return (
    <IconChip
      id={id ?? 'LabelChip'}
      sx={adjustedSx}
      text={text}
      tooltip={tooltipValue}
      color={hasColor ? label.color : undefined}
      mdiIconPath={path}
      onDelete={onDelete}
      onClick={onClick}
      disabled={disabled}
    />
  );
}
