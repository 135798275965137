import React from 'react';
import { Box } from '@mui/material';
import ModelIssuesListPanel from 'models/components/ModelIssuesListPanel';
import useModelSelectionContext from 'models/hooks/useModelSelectionContext';
import ModelsIssueDetailsPanel from 'models/components/ModelsIssueDetailsPanel';

export default function ModelIssuesDockviewPanel() {
  const { selectedIssueId } = useModelSelectionContext();
  return (
    <Box id="ModelIssuesDockviewPanel" sx={{ width: '100%', height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column', position: 'relative' }}>
      {!selectedIssueId && <ModelIssuesListPanel sx={{ flexGrow: 1 }} />}
      {!!selectedIssueId && <ModelsIssueDetailsPanel />}
    </Box>
  );
}
