import React, { useMemo } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import useLabelsOdataQuery from 'labels/hooks/useLabelsOdataQuery';
import LabelChip from 'labels/components/LabelChip';
import IssueDto from 'issues/types/IssueDto';

export default function LabelIdChipCell({
  value,
}: ICellRendererParams<IssueDto, string>) {
  const { data: labels } = useLabelsOdataQuery({});
  const label = useMemo(() => labels?.find((l) => l.id === value), [labels, value]);
  if (!label) return null;
  return (
    <LabelChip id="LabelChipCell" label={label} />
  );
}
