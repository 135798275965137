import { useCallback, useMemo, useState } from 'react';
import useDocumentVersionMassEditMutation from 'documents/hooks/useDocumentVersionMassEditMutation';
import UpdateManyDocumentVersionsDto from 'documents/types/DocumentVersionsUpdateManyDto';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import { useTranslation } from 'react-i18next';

export default function useMoveDocumentVersionsToFolder() {
  const { t } = useTranslation('documents-folders');
  const { mutateAsync } = useDocumentVersionMassEditMutation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const getRequestErrorMessage = useRequestErrorMessage();
  const clearErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const clearSuccessMessage = useCallback(() => setSuccessMessage(undefined), []);

  const moveDocumentVersionsToFolder = useCallback(async (documentVersionIds: string[], targetFolderId: string, targetFolderName: string) => {
    const updateDto: UpdateManyDocumentVersionsDto = {
      ids: documentVersionIds,
      folderId: { value: targetFolderId },
    };
    try {
      await mutateAsync(updateDto);
      setSuccessMessage(t('use-move-document-versions-to-folder_documents-moved-success-message', 'Successfully moved {{count}} documents to the folder {{folder}}.', { count: documentVersionIds.length, folder: targetFolderName }));
    } catch (e) {
      const reason = getRequestErrorMessage(e) ?? 'Unknown';
      setErrorMessage(t('use-move-document-versions-to-folder_documents-moved-error-message', 'Failed to move {{count}} documents. Reason: {{reason}}', { count: documentVersionIds.length, reason }));
    }
  }, [getRequestErrorMessage, mutateAsync, t]);

  return useMemo(() => ({
    moveDocumentVersionsToFolder,
    errorMessage,
    clearErrorMessage,
    successMessage,
    clearSuccessMessage,
  }), [clearErrorMessage, clearSuccessMessage, errorMessage, moveDocumentVersionsToFolder, successMessage]);
}
