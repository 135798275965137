import React, { useCallback, useState } from 'react';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RoundIconButton from 'common/components/RoundIconButton';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiDelete } from '@mdi/js';
import usePdfAnnotationDeleteMutation from 'documents-annotations/hooks/usePdfAnnotationDeleteMutation';

interface LegacyAnnotationPanelProps {
  pdfAnnotationIds: string[],
  onClose: () => void,
}

export default function LegacyAnnotationPanel({
  pdfAnnotationIds,
  onClose,
}: LegacyAnnotationPanelProps) {
  const { t } = useTranslation('documents-details');
  const { mutateAsync } = usePdfAnnotationDeleteMutation();
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState<boolean>(false);
  const onClickDelete = useCallback(() => {
    setConfirmDeleteDialogOpen(true);
  }, []);
  const onClickConfirmDelete = useCallback(async () => {
    await Promise.all(pdfAnnotationIds.map((id) => mutateAsync(id)));
    setConfirmDeleteDialogOpen(false);
    onClose();
  }, [mutateAsync, onClose, pdfAnnotationIds]);
  const onClickCancelDelete = useCallback(() => {
    setConfirmDeleteDialogOpen(false);
  }, []);
  return (
    <Box
      id="LegacyAnnotationPanel"
      sx={{
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ flex: '0 0 0', height: 73, display: 'flex', gap: 2, flexWrap: 'wrap', justifyItems: 'flexStart', alignItems: 'center', p: 3 }}>
        <RoundIconButton Icon={ArrowBackIcon} onClick={onClose} sx={{ flexShrink: 0 }} />
        <Typography variant="h5">{t('legacy-annotation-panel_title', 'Annotation')}</Typography>
      </Box>
      <Box sx={{ px: 2 }}>
        <Alert severity="info">
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'flex-start' }}>
            <Typography>{t('legacy-annotation-panel_hint-text', 'The selected annotation(s) are not linked to any issue.')}</Typography>
            <Button variant="contained" color="primary" sx={{ pl: 1, gap: 0.5 }} onClick={onClickDelete}>
              <Icon path={mdiDelete} size={0.75} />
              {t('legacy-annotation-panel_delete-button-label', 'Delete annotation')}
            </Button>
          </Box>
        </Alert>
      </Box>
      {!!confirmDeleteDialogOpen && (
        <Dialog open>
          <DialogTitle>
            {t('legacy-annotation-panel_confirm-delete-dialog-title', 'Delete annotation')}
          </DialogTitle>
          <DialogContent>
            {t('legacy-annotation-panel_confirm-delete-dialog-content', 'Are you sure that you want to delete the selected annotation(s)?')}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" sx={{ mr: 'auto' }} onClick={onClickCancelDelete}>
              {t('legacy-annotation-panel_confirm-delete-dialog-cancel-button-label', 'Cancel')}
            </Button>
            <Button variant="contained" color="primary" onClick={onClickConfirmDelete}>
              {t('legacy-annotation-panel_confirm-delete-dialog-confirm-button-label', 'Delete')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}
