import { createContext } from 'react';

export interface IssuesSelectionContextState {
  selectedIssueIds: string[],
  setSelectedIssueIds: (value: string[]) => void,
  multiselectActive?: boolean | undefined,
  setMultiselectActive?: ((value: boolean) => void) | undefined,
  suppressSingleselect?: boolean | undefined,
  suppressMultiselect?: boolean | undefined,

}

const IssuesSelectionContext = createContext<IssuesSelectionContextState>(undefined!);
export default IssuesSelectionContext;
