import { IfcState } from "./BaseDefinitions";
import { PropertyManager } from "./PropertyManager";

export class ItemsHider {
  public hiddenGlobalIds: string[] = [];
  public openingVisible: boolean = false;
  public spaceVisible: boolean = false;

  private state: IfcState;
  private solidCoordinates: { [modelID: string]: Float32Array } = {};
  private wireCoordinates: { [modelID: string]: Float32Array } = {};
  private expressIDSolidCoordinatesMap: {
    [modelID: string]: {
      [id: number]: number[];
    };
  } = {};
  private expressIDWireCoordinatesMap: {
    [modelID: string]: {
      [id: number]: number[];
    };
  } = {};

  constructor(state: IfcState) {
    this.state = state;
  }

  dispose() {
    this.solidCoordinates = {};
    this.wireCoordinates = {};
    this.expressIDSolidCoordinatesMap = {};
    this.expressIDWireCoordinatesMap = {};
    this.hiddenGlobalIds = [];
  }

  async processCoordinates(modelID: string): Promise<void> {
    this.processSolidCoordinates(modelID);
    this.processWireCoordinates(modelID);

    this.initializeCoordinates(modelID);

    this.refreshVisibleItems(modelID);
    return undefined;
  }

  hideItems(modelID: string, globalIds: string[]) {
    const filterIds = globalIds.filter(
      (i) => !this.hiddenGlobalIds.includes(i)
    );
    const ids = this.getExpressIds(modelID, filterIds);
    if (ids.length) {
      this.editCoordinates(modelID, ids, true);
    }

    if (filterIds.length) {
      this.editInstancedMeshes(modelID, filterIds, false);

      this.hiddenGlobalIds = this.hiddenGlobalIds.concat(filterIds);
    }
  }

  showItems(modelID: string, globalIds: string[]) {
    const filterIds = globalIds.filter((g) => this.hiddenGlobalIds.includes(g));

    const ids = this.getExpressIds(modelID, filterIds);
    if (ids.length) {
      this.editCoordinates(modelID, ids, false);
    }

    if (filterIds.length) {
      this.editInstancedMeshes(modelID, filterIds, true);

      for (let i = 0; i < filterIds.length; i++) {
        const index = this.hiddenGlobalIds.indexOf(filterIds[i]);
        this.hiddenGlobalIds.splice(index, 1);
      }
    }
  }

  showAllItems(modelID: string) {
    if (this.solidCoordinates[modelID]) {
      this.resetSolidCoordinates(modelID);
      this.updateSolidGeometry(modelID);
    }

    if (this.wireCoordinates[modelID]) {
      this.resetWireCoordinates(modelID);
      this.updateWireGeometry(modelID);
    }

    this.showAllInstances(modelID);
    this.refreshVisibleItems(modelID);
    this.hiddenGlobalIds = [];
  }

  hideAllItems(modelID: string) {
    this.getSolidCoordinates(modelID)?.fill(0);
    this.getWireCoordinates(modelID)?.fill(0);
    this.updateSolidGeometry(modelID);
    this.updateWireGeometry(modelID);

    this.hideAllInstances(modelID);

    Object.keys(this.state.modelData[modelID].nodeData).forEach((gId) => {
      if (!this.hiddenGlobalIds.includes(gId)) {
        this.hiddenGlobalIds.push(gId);
      }
    });
  }

  setVisible(opening: boolean, space: boolean) {
    this.openingVisible = opening;
    this.spaceVisible = space;
  }

  refreshVisibleItems(modelID: string) {
    this.handleOpenings(modelID);
    this.handleSpace(modelID);
  }

  private handleOpenings(modelID: string) {
    const model = this.state.models[modelID];
    const ids = this.getExpressIds(modelID, model.openingIds);
    if (ids.length) {
      this.editCoordinates(modelID, ids, !this.openingVisible);
      if (this.openingVisible) {
        this.hiddenGlobalIds = this.hiddenGlobalIds.filter(
          (id) => !model.openingIds.includes(id)
        );
      } else {
        this.hiddenGlobalIds = this.hiddenGlobalIds.concat(
          model.openingIds.filter((i) => !this.hiddenGlobalIds.includes(i))
        );
      }
    }

    model.instancedMeshes
      .filter((i) => i.isOpening)
      .forEach((i) => {
        if (this.openingVisible) {
          i.showAll();
          this.hiddenGlobalIds = this.hiddenGlobalIds.filter(
            (id) => !i.globalIds.includes(id)
          );
        } else {
          i.hideAll();
          this.hiddenGlobalIds = this.hiddenGlobalIds.concat(
            i.globalIds.filter((id) => !this.hiddenGlobalIds.includes(id))
          );
        }
      });
  }

  private handleSpace(modelID: string) {
    const model = this.state.models[modelID];
    const ids = this.getExpressIds(modelID, model.spaceIds);
    if (ids.length) {
      this.editCoordinates(modelID, ids, !this.spaceVisible);
      if (this.spaceVisible) {
        this.hiddenGlobalIds = this.hiddenGlobalIds.filter(
          (id) => !model.spaceIds.includes(id)
        );
      } else {
        this.hiddenGlobalIds = this.hiddenGlobalIds.concat(
          model.spaceIds.filter((i) => !this.hiddenGlobalIds.includes(i))
        );
      }
    }

    model.instancedMeshes
      .filter((i) => i.isSpace)
      .forEach((i) => {
        if (this.spaceVisible) {
          i.showAll();
          this.hiddenGlobalIds = this.hiddenGlobalIds.filter(
            (id) => !i.globalIds.includes(id)
          );
        } else {
          i.hideAll();
          this.hiddenGlobalIds = this.hiddenGlobalIds.concat(
            i.globalIds.filter((id) => !this.hiddenGlobalIds.includes(id))
          );
        }
      });
  }

  private editCoordinates(modelID: string, ids: number[], hide: boolean) {
    this.editSolidCoordinates(modelID, ids, hide);
    this.editWireCoordinates(modelID, ids, hide);
  }

  private editSolidCoordinates(modelID: string, ids: number[], hide: boolean) {
    const current = this.expressIDSolidCoordinatesMap[modelID];
    const indices: number[] = [];
    ids.forEach((id: number) => {
      if (current[id]) {
        for (let i = 0; i < current[id].length; i++) {
          indices.push(current[id][i]);
        }
      }
    });
    const coords = this.getSolidCoordinates(modelID);
    if(!coords)return;
    const initial = this.solidCoordinates[modelID];
    if (hide) indices.forEach((i) => coords.set([0, 0, 0], i));
    else
      indices.forEach((i) =>
        coords.set([initial[i], initial[i + 1], initial[i + 2]], i)
      );

    this.updateSolidGeometry(modelID);
  }

  private editWireCoordinates(modelID: string, ids: number[], hide: boolean) {
    const current = this.expressIDWireCoordinatesMap[modelID];
    const indices: number[] = [];
    ids.forEach((id: number) => {
      if (current[id]) {
        for (let i = 0; i < current[id].length; i++) {
          indices.push(current[id][i]);
        }
      }
    });
    const coords = this.getWireCoordinates(modelID);
    if(!coords)return;
    const initial = this.wireCoordinates[modelID];
    if (hide) indices.forEach((i) => coords.set([0, 0, 0], i));
    else
      indices.forEach((i) =>
        coords.set([initial[i], initial[i + 1], initial[i + 2]], i)
      );

    this.updateWireGeometry(modelID);
  }

  private processSolidCoordinates(modelID: string) {
    const attributes = this.getSolidAttributes(modelID);
    if(attributes?.expressID){
      const ids = Array.from(attributes.expressID.array);
      this.expressIDSolidCoordinatesMap[modelID] = {};
      for (let i = 0; i < ids.length; i++) {
        if (!this.expressIDSolidCoordinatesMap[modelID][ids[i]]) {
          this.expressIDSolidCoordinatesMap[modelID][ids[i]] = [];
        }
        const current = this.expressIDSolidCoordinatesMap[modelID];
        current[ids[i]].push(3 * i);
      }
    }
  }

  private processWireCoordinates(modelID: string) {
    const attributes = this.getWireAttributes(modelID);
    if(attributes?.expressID){
      const ids = Array.from(attributes.expressID.array);
      this.expressIDWireCoordinatesMap[modelID] = {};
      for (let i = 0; i < ids.length; i++) {
        if (!this.expressIDWireCoordinatesMap[modelID][ids[i]]) {
          this.expressIDWireCoordinatesMap[modelID][ids[i]] = [];
        }
        const current = this.expressIDWireCoordinatesMap[modelID];
        current[ids[i]].push(3 * i);
      }
    }
  }

  private initializeCoordinates(modelID: string) {
    const coordinates = this.getSolidCoordinates(modelID);
    if (coordinates && !this.solidCoordinates[modelID]) {
      this.solidCoordinates[modelID] = new Float32Array(coordinates);
    }

    const wireCoordinates = this.getWireCoordinates(modelID);
    if (wireCoordinates && !this.wireCoordinates[modelID]) {
      this.wireCoordinates[modelID] = new Float32Array(wireCoordinates);
    }
  }

  private resetSolidCoordinates(modelID: string) {
    const initial = this.solidCoordinates[modelID];
    this.getSolidCoordinates(modelID)?.set(initial);
  }

  private resetWireCoordinates(modelID: string) {
    const initial = this.wireCoordinates[modelID];
    this.getWireCoordinates(modelID)?.set(initial);
  }

  private getSolidCoordinates(modelID: string) {
    const positions = this.getSolidAttributes(modelID)?.position?.array;
    return positions? positions as Float32Array : null;
  }

  private getWireCoordinates(modelID: string) {
    const positions = this.getWireAttributes(modelID)?.position?.array;
    return positions? positions as Float32Array : null;
  }

  private getSolidAttributes(modelID: string) {
    return this.state.models[modelID].solid?.geometry.attributes;
  }

  private getWireAttributes(modelID: string) {
    return this.state.models[modelID].wireframe?.geometry.attributes;
  }

  private hideAllInstances(modelID: string) {
    this.state.models[modelID].instancedMeshes.forEach((i) => i.hideAll());
  }

  private showAllInstances(modelID: string) {
    this.state.models[modelID].instancedMeshes.forEach((i) => i.showAll());
  }

  private getExpressIds(modelID: string, globalIds: string[]) {
    const ids: number[] = [];
    globalIds.forEach((g) => {
      const id = PropertyManager.getExpressIdFromGlobalId(
        this.state.models[modelID].globalIds,
        g
      );
      if (id != Infinity) {
        ids.push(id);
      }
    });

    return ids;
  }

  private editInstancedMeshes(
    modelID: string,
    globalIds: string[],
    visible: boolean
  ) {
    this.state.models[modelID].instancedMeshes.forEach((i) =>
      i.setVisibilities(globalIds, visible)
    );
  }

  private updateSolidGeometry(modelId:string){
    const attributes = this.getSolidAttributes(modelId);
    if(attributes?.expressID){
      attributes.position.needsUpdate = true;
    }
  }

  private updateWireGeometry(modelId:string){
    const attributes = this.getWireAttributes(modelId);
    if(attributes?.expressID){
      attributes.position.needsUpdate = true;
    }
  }
}
