import useIssuesSelectionContext from 'issues/hooks/useIssuesSelectionContext';
import { useCallback } from 'react';

export default function useIssueItemClickHandler(disabledIssueIds?: Set<string> | undefined) {
  const {
    selectedIssueIds,
    setSelectedIssueIds,
    multiselectActive,
    setMultiselectActive,
    suppressMultiselect,
    suppressSingleselect,
  } = useIssuesSelectionContext();
  return useCallback(async (clickedIssueId: string | undefined, ctrlKey?: boolean | undefined) => {
    if (!clickedIssueId) return;
    if (disabledIssueIds?.has(clickedIssueId)) return;
    if (multiselectActive) {
      if (selectedIssueIds.some((id) => id === clickedIssueId)) {
        const nextSelection = selectedIssueIds.filter((prevId) => prevId !== clickedIssueId);
        setSelectedIssueIds(nextSelection);
      } else {
        setSelectedIssueIds(selectedIssueIds.concat(clickedIssueId));
      }
    } else if (ctrlKey || suppressSingleselect) {
      if (!suppressMultiselect && clickedIssueId) {
        setMultiselectActive?.(true);
        setSelectedIssueIds(Array.from(new Set([...selectedIssueIds, clickedIssueId])));
      }
    } else {
      setSelectedIssueIds([clickedIssueId]);
    }
  }, [disabledIssueIds, multiselectActive, suppressSingleselect, selectedIssueIds, setSelectedIssueIds, suppressMultiselect, setMultiselectActive]);
}
