import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import LabelChip from 'labels/components/LabelChip';
import IssueDto from 'issues/types/IssueDto';
import useProjectIssueTypesQuery from 'issues/hooks/useProjectIssueTypesQuery';

export default function TypeChipCell({
  value,
}: ICellRendererParams<IssueDto, String>) {
  const { data: types } = useProjectIssueTypesQuery();
  if (!types) return null;
  const type = types.find((s) => s.id === value);
  if (!type) return null;
  return <LabelChip key={type.id} sx={{ mr: 1 }} label={type} />;
}
