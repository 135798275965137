import React, { useCallback, useMemo } from 'react';
import { ListItem, ListItemButton, ListItemIcon, ListItemText, styled } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SettingsTab from 'settings/types/SettingsTab';
import useCurrentSettingsTab from 'settings/types/useCurrentSettingsTab';
import useLegacySettingsNotificationDialogContext from 'settings/hooks/useLegacySettingsNotificationDialogContext';
import useCurrentProjectId from 'projects/hooks/useCurrentProjectId';

interface EditProjectTabsItemProps extends ISxProps {
  tab: SettingsTab,
  primary: string,
  secondary: string,
}

const TabListItem = styled(ListItem)(({ theme }) => ({
  position: 'static',
  '&.active': {
    backgroundColor: theme.palette.background.default,
  },
  '.MuiListItemText-primary': {
    fontSize: 14,
    fontWeight: 800,
  },
  '.MuiListItemText-secondary': {
    color: theme.palette.text.primary,
  },
}));

export default function SettingsTabItem({
  sx,
  tab,
  primary,
  secondary,
}: EditProjectTabsItemProps) {
  const projectId = useCurrentProjectId();
  const { currentSettingsTab, isTopLevel } = useCurrentSettingsTab();
  const active = useMemo(() => currentSettingsTab === tab, [currentSettingsTab, tab]);
  const { navigateWithConfirmation } = useLegacySettingsNotificationDialogContext();
  const onClickButton = useCallback(() => {
    if (currentSettingsTab === tab && isTopLevel) return;
    navigateWithConfirmation(`/projects/${projectId}/settings/${tab}`);
  }, [currentSettingsTab, isTopLevel, navigateWithConfirmation, projectId, tab]);
  return (
    <TabListItem disablePadding sx={sx} className={active ? 'active' : undefined}>
      <ListItemButton onClick={onClickButton}>
        <ListItemIcon>
          <ChevronRightIcon fontSize="large" color={active ? 'primary' : 'inherit'} />
        </ListItemIcon>
        <ListItemText
          primary={primary}
          secondary={secondary}
        />
      </ListItemButton>
    </TabListItem>
  );
}
