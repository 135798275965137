import React, { useMemo } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import IssueDto from 'issues/types/IssueDto';

export default function DefaultCellRenderer({
  value,
  valueFormatted,
}: ICellRendererParams<IssueDto, any>) {
  const displayValue = useMemo(() => {
    const resultValue = valueFormatted ?? value;
    if (resultValue === undefined || resultValue === null) return undefined;
    return `${resultValue}`;
  }, [value, valueFormatted]);
  if (displayValue === undefined) return null;
  return <div title={displayValue}>{displayValue}</div>;
}
