import React, { useCallback } from 'react';
import {
  FormControl, InputLabel, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import EmailIntervalDisplayValue from 'emails/types/EmailIntervalDisplayValue';
import { useTranslation } from 'react-i18next';

interface EmailIntervalSelectProps extends ISxProps {
  label: string,
  value: EmailIntervalDisplayValue,
  onChange: (nextValue: EmailIntervalDisplayValue) => void,
}

export default function EmailIntervalSelect({
  sx,
  label,
  value,
  onChange,
}: EmailIntervalSelectProps) {
  const { t } = useTranslation('emails');
  const onChangeSelect = useCallback((e: SelectChangeEvent<EmailIntervalDisplayValue>) => onChange(e.target.value as EmailIntervalDisplayValue), [onChange]);
  return (
    <FormControl sx={sx}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        label={label}
        onChange={onChangeSelect}
      >
        <MenuItem value={EmailIntervalDisplayValue.Never}>{t('email-interval-never', 'Never')}</MenuItem>
        <MenuItem value={EmailIntervalDisplayValue.Daily}>{t('email-interval-daily', 'Daily')}</MenuItem>
        <MenuItem value={EmailIntervalDisplayValue.Weekly}>{t('email-interval-weekly', 'Weekly')}</MenuItem>
      </Select>
    </FormControl>
  );
}
