import { BufferGeometry, Material, Mesh } from "three";
import { VisoMesh } from "./BaseDefinitions";

export class VisoSolidMesh extends Mesh implements VisoMesh {
  modelId: string;

  constructor(
    geometry: BufferGeometry,
    material: Material | Material[],
    id: string
  ) {
    super(geometry, material);

    this.modelId = id;
  }
}
