import React, { ReactNode, Suspense } from 'react';
import { Dialog } from '@mui/material';
import useEmailsUploadContext from 'emails/hooks/useEmailsUploadContext';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import EmailsUploadProgressPage from 'emails/components/EmailsUploadWizard/EmailsUploadProgressPage';
import EmailsValidateStagedUploadsPage from 'emails/components/EmailsUploadWizard/EmailsValidateStagedUploadsPage';

interface EmailsUploadWizardModalProps {
  hideWizard: () => void,
}

export default function EmailsUploadWizardModal({
  hideWizard,
}: EmailsUploadWizardModalProps) {
  const { uploadingFileHandles, clear } = useEmailsUploadContext();

  let page: ReactNode | null = null;
  if (uploadingFileHandles.length > 0) {
    page = <EmailsUploadProgressPage onFinished={clear} onMinimize={hideWizard} />;
  } else {
    page = <EmailsValidateStagedUploadsPage onCancel={clear} />;
  }

  return (
    <Dialog
      id="EmailsUploadWizardModal"
      open
      scroll="paper"
      PaperProps={{ style: { width: '1000px', maxWidth: 'revert', height: '791px' } }}
    >
      <Suspense fallback={(<CenteredCircularProgress />)}>
        {page}
      </Suspense>
    </Dialog>
  );
}
