import React, { useMemo } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import CollaboratorsDataGridRow from 'collaborators/types/CollaboratorsDataGridRow';
import InlineTypography from 'common/components/InlineTypography';
import { Box, Avatar, useTheme } from '@mui/material';
import Color from 'color';
import { yellow } from '@mui/material/colors';
import useRolesOdataQuery from 'collaborators/hooks/useRolesOdataQuery';

export default function EmailCell({
  data,
}: ICellRendererParams<CollaboratorsDataGridRow, string>) {
  const theme = useTheme();
  const { data: roles } = useRolesOdataQuery({});
  const collaboratorRole = useMemo(() => roles?.find((r) => r.id === data?.roleId), [data, roles]);
  const initials = useMemo(() => `${data?.firstName?.[0]}${data?.lastName?.[0]}`, [data]);
  const color = useMemo(() => {
    if (collaboratorRole?.isAdmin) return Color(theme.palette.info.main).value(90).hex();
    if (collaboratorRole?.name === 'Collaborator') return Color(theme.palette.error.main).value(97).hex();
    if (collaboratorRole?.name === 'Member') return Color(theme.palette.warning.main).value(97).hex();
    return yellow[600];
  }, [collaboratorRole, theme]);
  if (!data) return null;
  return (
    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
      <Avatar sx={{
        width: 30, height: 30, mr: 1, fontSize: 12, fontWeight: 600, backgroundColor: color,
      }}
      >
        {initials}
      </Avatar>
      <InlineTypography sx={{ fontWeight: 600 }}>{`${data.firstName} ${data.lastName}`}</InlineTypography>
    </Box>
  );
}
