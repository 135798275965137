import { useMemo } from 'react';
import { QueryObserverOptions, useQuery } from '@tanstack/react-query';
import { QueryOptions } from 'odata-query';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys, { OdataQueryKey } from 'api/hooks/useQueryKeys';

type OdataQueryOptions<TEndpoint extends ApiEndpoint, TDto> = Omit<QueryObserverOptions<TDto[] | undefined, unknown, TDto[] | undefined, TDto[] | undefined, OdataQueryKey<TEndpoint, TDto> | readonly []>, 'queryFn'>;

export type OdataUseQueryArgs<TEndpoint extends ApiEndpoint, TDto> = [odataQuery: Partial<QueryOptions<TDto>> | undefined, options?: Partial<Omit<OdataQueryOptions<TEndpoint, TDto>, 'queryKey'>>];

export default function useOdataQuery<TEndpoint extends ApiEndpoint, TDto>(endpoint: TEndpoint, ...[odataQuery, options]: OdataUseQueryArgs<TEndpoint, TDto>) {
  const { getOdataQueryKey } = useDefaultEntityQueryKeys(endpoint);
  const queryKey = useMemo(() => (odataQuery ? getOdataQueryKey(odataQuery) : ([] as const)), [odataQuery, getOdataQueryKey]);
  const queryOptions = useMemo<OdataQueryOptions<TEndpoint, TDto>>(() => ({
    ...options,
    queryKey,
    enabled: (options?.enabled !== false) && (queryKey.length > 0),
  }), [queryKey, options]);
  return useQuery(queryOptions);
}
