import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import DocumentListDto from 'documents-lists/types/DocumentListDto';
import DocumentListUpdateNameDto from 'documents-lists/types/DocumentListUpdateNameDto';
import { useCallback } from 'react';

export default function useDocumentListUpdateNameMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentList);
  const mutationFn = useCallback(async (dto: DocumentListUpdateNameDto) => {
    const { data } = await axiosInstance.patch<DocumentListDto>(`${ApiEndpoint.DocumentList}/name`, dto);
    return data;
  }, [axiosInstance]);
  const onSuccess = useCallback(() => {
    queryKeyBases.forEach((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
  }, [queryKeyBases, queryClient]);
  return useMutation<DocumentListDto | undefined, unknown, DocumentListUpdateNameDto>({
    mutationFn,
    onSuccess,
  });
}
