import { useCallback, useMemo } from 'react';
import { ITEM_ROOT, OrderByOptions, PlainObject, QueryOptions } from 'odata-query';
import DocumentFilterState from 'documents-filter/types/DocumentFilterState';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import useCurrentCollaboratorQuery from 'collaborators/hooks/useCurrentCollaboratorQuery';
import DateRangeKind from 'common/types/DateRangeKind';
import useTimeFrameFromToDates from 'common/hooks/useTimeFrameDates';
import DateRange from 'common/types/DateRange';
import useFolderTreeQuery from 'documents-folders/hooks/useFolderTreeQuery';
import Folder from 'documents-folders/types/Folder';
import LinkedIssuesScope from 'documents/types/LinkedIssuesScope';
import DocumentSortDefinition from 'documents-filter/types/DocumentSortDefinition';
import ArchiveScopeId from 'documents/types/ArchiveScopeId';
import useDocumentListDetailsQuery from 'documents-lists/hooks/useDocumentListDetailsQuery';
import useCurrentPlanlistId from 'documents-lists/hooks/useCurrentPlanlistId';
import DocumentListMode from 'documents-filter/types/DocumentListMode';
import DocumentVersionDto from 'documents/types/DocumentVersionDto';
import CollectionComparisonMode from 'documents-filter/types/CollectionComparisonMode';
import useDocumentsViewNavigationContext from 'documents/hooks/useDocumentsViewNavigationContext';
import FileType from 'documents/types/FileType';

const DEFAULT_ORDERBY: OrderByOptions<DocumentVersionDto>[] = [['uploadDate', 'desc']]; // if no ordering is specified, order by upload date, descending

export default function useDocumentScopeOdataQuery(filterState: DocumentFilterState, searchString: string | undefined, sortDefinitons: DocumentSortDefinition[], documentListMode: DocumentListMode) {
  const { documentScope } = useDocumentsViewNavigationContext();
  const planlistId = useCurrentPlanlistId();
  const { data: currentPlanlist } = useDocumentListDetailsQuery(planlistId);
  const getTimeFrameDates = useTimeFrameFromToDates();
  const { data: folderTreeData } = useFolderTreeQuery();
  const { data: currentCollaborator } = useCurrentCollaboratorQuery();
  const onlyNewest = useMemo(() => (documentScope.key === DocumentScopeKey.Planlist ? false : documentListMode === DocumentListMode.CurrentVersionOnly), [documentListMode, documentScope.key]);

  const getOdataDateRangeFilter = useCallback((dateRange: DateRange) => {
    let from: Date | undefined;
    let to: Date | undefined;
    if (dateRange.dateRangeKind === DateRangeKind.Relative) {
      const { fromValue, toValue } = getTimeFrameDates(dateRange.timeFrame);
      from = fromValue;
      to = toValue;
    } else {
      from = dateRange.from;
      to = dateRange.to;
    }
    if (from || to) {
      const dateRangeFilter: PlainObject = {};
      if (from) dateRangeFilter.ge = from;
      if (to) dateRangeFilter.le = to;
      return dateRangeFilter;
    }
    return undefined;
  }, [getTimeFrameDates]);

  return useMemo<Partial<QueryOptions<DocumentVersionDto>> | undefined>(() => {
    const filter: PlainObject[] = [];
    if (documentScope.key === DocumentScopeKey.ProjectDocuments) {
      filter.push({ isArchived: false });
    }
    if (documentScope.key === DocumentScopeKey.MyUploads && !!currentCollaborator?.id) {
      filter.push({ isArchived: false, creator: { id: currentCollaborator.id } });
    }
    if (documentScope.key === DocumentScopeKey.Archive) {
      if (documentScope.id === ArchiveScopeId.Public) {
        filter.push({ isArchived: true, isPrivate: false });
      } else if (documentScope.id === ArchiveScopeId.Private) {
        filter.push({ isArchived: true, isPrivate: true });
      }
    }
    if (documentScope.key === DocumentScopeKey.Planlist) {
      filter.push({ isArchived: false, id: { in: currentPlanlist?.documentVersionIds ?? [] } });
    }
    if (documentScope.key === DocumentScopeKey.Folder && !!documentScope.id) {
      filter.push({ isArchived: false, folderId: documentScope.id });
    }
    if (documentScope.key === DocumentScopeKey.Filter) {
      filter.push({ isArchived: false });
      if (searchString) {
        filter.push({ 'tolower(originalFileName)': { contains: searchString.toLocaleLowerCase() } });
      }
      if (filterState.uploadDateRange) {
        const uploadDateFilter = getOdataDateRangeFilter(filterState.uploadDateRange);
        if (uploadDateFilter) filter.push({ uploadDate: uploadDateFilter });
      }
      if (filterState.creationDateRange) {
        const creationDateFilter = getOdataDateRangeFilter(filterState.creationDateRange);
        if (creationDateFilter) filter.push({ creationDate: creationDateFilter });
      }
      if (filterState.disciplineLabelIds.length) {
        filter.push({ disciplines: { any: { [ITEM_ROOT]: { in: filterState.disciplineLabelIds } } } });
      }
      if (filterState.buildingLabelIds.length) {
        filter.push({ building: { in: filterState.buildingLabelIds } });
      }
      if (filterState.floorLabelIds.length) {
        filter.push({ floor: { in: filterState.floorLabelIds } });
      }
      if (filterState.workPhaseLabelIds.length) {
        filter.push({ workPhase: { in: filterState.workPhaseLabelIds } });
      }
      if (filterState.creatorCollaboratorIds.length) {
        filter.push({ creator: { id: { in: filterState.creatorCollaboratorIds } } });
      }
      if (filterState.editorCollaboratorIds.length) {
        filter.push({ editor: { id: { in: filterState.editorCollaboratorIds } } });
      }
      if (filterState.fileTypes.length) {
        filter.push({ fileType: { in: filterState.fileTypes.map((n) => FileType[n]) } });
      }
      if (filterState.versionNumber) {
        filter.push({ versionNumber: { eq: filterState.versionNumber } });
      }
      if (filterState.tagLabelIds.length) {
        if (filterState.tagComparisonMode === undefined || filterState.tagComparisonMode === CollectionComparisonMode.ContainsAny) {
          filter.push({ tags: { any: { [ITEM_ROOT]: { in: filterState.tagLabelIds } } } });
        } else {
          filter.push(...filterState.tagLabelIds.map((tagLabelName) => ({ tags: { any: tagLabelName } })));
        }
      }
      if (filterState.documentStatusLabelIds.length) {
        filter.push({ status: { in: filterState.documentStatusLabelIds } });
      }
      if (filterState.linkedIssuesScope) {
        const hasLinkedIssuesFilter = { linkedIssueIds: { any: {} } };
        if (filterState.linkedIssuesScope === LinkedIssuesScope.OnlyWithLinkedIssues) {
          filter.push(hasLinkedIssuesFilter);
        } else {
          filter.push({ not: hasLinkedIssuesFilter });
        }
      }
      if (filterState.folderId) {
        if (filterState.includeSubfolders) {
          const folder = folderTreeData?.foldersById.get(filterState.folderId);
          if (folder) {
            // walk sub-tree to collect descendant folder ids
            const folderStack: Folder[] = [folder];
            const folderAndDescendants: Folder[] = [];
            while (folderStack.length > 0) {
              const pivotItem = folderStack.pop();
              if (pivotItem) {
                folderAndDescendants.push(pivotItem);
                if (pivotItem.childFolders) folderStack.push(...pivotItem.childFolders);
              }
            }
            filter.push({ folderId: { in: folderAndDescendants.map((f) => f.id) } });
          }
        } else {
          filter.push({ folderId: filterState.folderId });
        }
      }
    }
    if (onlyNewest) {
      filter.push({ isNewest: true });
    }
    const orderBy = sortDefinitons.length ? sortDefinitons.map<OrderByOptions<DocumentVersionDto>>((def) => [def.fieldName, def.descending ? 'desc' : 'asc']) : DEFAULT_ORDERBY;
    return { filter, orderBy };
  }, [documentScope, currentCollaborator, currentPlanlist, onlyNewest, sortDefinitons, folderTreeData?.foldersById, searchString, filterState, getOdataDateRangeFilter]);
}
