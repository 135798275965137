import React, { useCallback, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { mdiDelete } from '@mdi/js';
import DeleteProjectDialog from 'projects/components/DeleteProjectDialog';
import Icon from '@mdi/react';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';

export default function ProjectDeletionSection() {
  const { t } = useTranslation('settings');

  const { data: project } = useCurrentProjectQuery();
  const [deleteProjectDialogOpen, setDeleteProjectDialogOpen] = useState(false);
  const onClickDelete = useCallback(() => {
    setDeleteProjectDialogOpen(true);
  }, []);
  const onCloseDeleteProjectDialog = useCallback(() => setDeleteProjectDialogOpen(false), []);
  if (!project) return null;

  return (
    <Box
      id="ProjectDeletionSection"
      sx={{ display: 'flex', gap: 4, alignItems: 'center', ml: 4 }}
    >
      <Box sx={{ flex: '1 1 0', display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <Typography variant="h5">{t('project-deletion-section_delete-project-header', 'Delete project')}</Typography>
        <Typography>{t('project-deletion-section_delete-project-description', 'If the project is deleted all data and access to the project is lost. This action is irreversible')}</Typography>
      </Box>
      <Box sx={{ flex: '0 0 0', display: 'flex', alignItems: 'center', mt: 1, maxWidth: '520px' }}>
        <Button
          id="ProjectDeletionSectionDeleteButton"
          onClick={onClickDelete}
          variant="contained"
          sx={{ display: 'flex', gap: 1, pl: 1 }}
        >
          <Icon size={1} path={mdiDelete} />
          {t('project-deletion-section_delete-button-label', 'Delete')}
        </Button>
        {!!deleteProjectDialogOpen && <DeleteProjectDialog project={project} onClose={onCloseDeleteProjectDialog} />}
      </Box>
    </Box>
  );
}
