import { Theme } from '@emotion/react';
import {
  Box, Chip, SxProps, Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import InlineTypography from 'common/components/InlineTypography';
import 'react-multi-email/dist/style.css';

interface SelectExternalEmailsTextareaProps {
  onChange: (emailAddresses: string[]) => void,
  sx?: SxProps<Theme>,
}

const formatter = (email: string, index: number, removeEmail: (index: number) => void) => (
  <Chip data-tag key={email} label={email} onDelete={() => removeEmail(index)} />
);

export default function SelectExternalEmailsTextarea({
  onChange,
  sx,
}: SelectExternalEmailsTextareaProps) {
  const { t } = useTranslation('share');

  return (
    <Box sx={sx}>
      <Typography sx={{ flexGrow: '1', fontSize: '0.875rem' }}>
        <InlineTypography sx={{ pr: 1, fontWeight: 700, fontSize: '0.875rem' }}>
          {t('select-external-emails_label', 'External Collaborators')}
        </InlineTypography>
        {t('select-external-emails_separator-hint', 'Separate with comma')}
      </Typography>
      <ReactMultiEmail
        className="share-documents--multi-email custom-scrollbar"
        onChange={onChange}
        validateEmail={isEmail}
        getLabel={formatter}
      />
    </Box>
  );
}

SelectExternalEmailsTextarea.defaultProps = {
  sx: undefined,
};
