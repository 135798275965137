import { Box3, Camera, Vector3 } from "three";
import { CameraProjections } from "../../../base-types";
import { IfcCamera } from "./camera";
import { IfcContext } from "../context";

export class ProjectionManager {
  private currentProjection: CameraProjections;
  private currentCamera: Camera;
  private cameras: IfcCamera;

  get activeCamera() {
    return this.currentCamera;
  }

  get projection() {
    return this.currentProjection;
  }

  set projection(projection: CameraProjections) {
    if (this.projection === projection) return;
    if (projection === CameraProjections.Orthographic) {
      this.setOrthoCamera();
    } else {
      this.setPerspectiveCamera();
    }
  }

  setOrthoCamera() {
    // Matching orthographic camera to perspective camera
    // Resource: https://stackoverflow.com/questions/48758959/what-is-required-to-convert-threejs-perspective-camera-to-orthographic
    const { width, height } = this.getDims();
    this.setupOrthoCamera(height, width);
    this.currentCamera = this.cameras.orthographicCamera;
    this.currentProjection = CameraProjections.Orthographic;
  }

  private getDims() {
    const target = this.getTarget().clone();
    const distance = target
      .clone()
      .sub(this.cameras.perspectiveCamera.position);
    const depth = distance.length();
    const dims = this.context.getDimensions();
    const aspect = dims.x / dims.y;
    const fov = this.cameras.perspectiveCamera.fov;
    const height = depth * 2 * Math.atan((fov * (Math.PI / 180)) / 2);
    const width = height * aspect;
    return { width, height };
  }

  private getTarget() {
    const box = this.context.boundingBox.clone();
    return box.getCenter(new Vector3());
  }

  private setupOrthoCamera(height: number, width: number) {
    this.cameras.orthographicCamera.zoom = 1;
    this.cameras.orthographicCamera.left = width / -2;
    this.cameras.orthographicCamera.right = width / 2;
    this.cameras.orthographicCamera.top = height / 2;
    this.cameras.orthographicCamera.bottom = height / -2;
    this.cameras.orthographicCamera.updateProjectionMatrix();
    this.cameras.orthographicCamera.position.copy(
      this.cameras.perspectiveCamera.position
    );
    this.cameras.orthographicCamera.quaternion.copy(
      this.cameras.perspectiveCamera.quaternion
    );
  }

  private setPerspectiveCamera() {
    this.cameras.perspectiveCamera.position.copy(
      this.cameras.orthographicCamera.position
    );
    this.cameras.perspectiveCamera.quaternion.copy(
      this.cameras.orthographicCamera.quaternion
    );
    this.cameras.perspectiveCamera.updateProjectionMatrix();
    this.currentCamera = this.cameras.perspectiveCamera;
    this.currentProjection = CameraProjections.Perspective;
  }

  constructor(private context: IfcContext, ifcCamera: IfcCamera) {
    this.cameras = ifcCamera;
    this.currentCamera = ifcCamera.perspectiveCamera;
    this.currentProjection = CameraProjections.Perspective;
  }
}
