import {
  Vector3,
  Matrix3,
  Intersection,
  Mesh,
  Plane,
  LineSegments,
  Material,
  ShaderMaterial,
  MeshLambertMaterial,
} from "three";
import { IfcComponent } from "../../../base-types";
import { IfcPlane } from "./planes";
import { IfcManager } from "../../ifc";
import { IfcContext } from "../../context";
import { LiteEvent } from "../../../utils/LiteEvent";
import { RightToLeftHand } from "../../../utils/ThreeUtils";
import { Section } from "../../../viso-types";
import { IFCModel } from "../../../IFCModel";

export class IfcClipper extends IfcComponent {
  public readonly onClip = new LiteEvent<Array<IfcPlane>>();
  dragging: boolean;
  planes: IfcPlane[];
  intersection: Intersection | undefined;
  orthogonalY = true;
  toleranceOrthogonalY = 0.7;
  planeSize = 5;

  private enabled: boolean;
  private readonly context: IfcContext;
  private readonly ifc: IfcManager;

  constructor(context: IfcContext, ifc: IfcManager) {
    super(context);
    this.context = context;
    this.ifc = ifc;
    this.enabled = false;
    this.dragging = false;
    this.planes = [];
  }

  get active() {
    return this.enabled;
  }

  set active(state) {
    if (this.enabled === state) return;
    this.enabled = state;
    this.planes.forEach((plane) => {
      if (!plane.isPlan) {
        plane.visible = state;
        plane.active = state;
      }
    });

    this.updateMaterials();
  }

  createPlane = (hidePrev: boolean = false, isControllerVisible: boolean) => {
    if (!this.enabled) return;
    const intersects = this.context.castRayIfc();
    if (!intersects) return;
    if (hidePrev) this.hidePreviousPlanes();
    const plane = this.createPlaneFromIntersection(intersects);
    if (!!plane) plane.visible = isControllerVisible;
    this.intersection = undefined;

    return plane;
  };

  createFromNormalAndCoplanarPoint = (
    normal: Vector3,
    point: Vector3,
    isPlan = false,
    hidePrev: boolean = false
  ) => {
    if (hidePrev) this.hidePreviousPlanes();
    const plane = new IfcPlane(
      this.context,
      point,
      normal,
      this.activateDragging,
      this.deactivateDragging,
      this.planeSize
    );
    plane.isPlan = isPlan;
    this.planes.push(plane);
    this.context.addClippingPlane(plane.plane);
    this.updateMaterials();
    return plane;
  };

  deletePlane = (plane: IfcPlane) => {
    const index = this.planes.indexOf(plane);
    if (index === -1) return;
    plane.removeFromScene();
    this.planes.splice(index, 1);
    this.context.removeClippingPlane(plane.plane);
    this.updateMaterials();
  };

  deleteAllPlanes = () => {
    this.planes.forEach((plane) => {
      plane.removeFromScene();
    });

    this.context.removeAllCiipPlanes();
    this.planes = [];
    this.updateMaterials();
  };

  getIfcSections() {
    if (this.planes.length) {
      return this.planes.map((plane) => {
        return {
          location: RightToLeftHand(plane.origin),
          normal: RightToLeftHand(plane.normal).multiplyScalar(-1),
        } as Section;
      });
    }

    return [];
  }

  updateMaterials = () => {
    const planes = this.context.getClippingPlanes();
    // Applying clipping to IfcObjects only. This could be improved.
    this.context.items.ifcModels.forEach((model: IFCModel) => {
      if (model.solid) this.updateMaterial(model.solid.material, planes);
      if (model.wireframe)
        this.updateWireframeMaterial(model.wireframe, planes);
      for (let i = 0; i < model.instancedMeshes.length; i++) {
        this.updateMaterial(model.instancedMeshes[i].material, planes);
      }
    });

    this.ifc.visoLoader.ifcManager.subsets.setClippingPlanes(planes);
    this.onClip.trigger(this.planes);
  };

  updateSectionSpaces = () => {
    const bOrigin = this.context.getClippingPlanes().length == 0;
    this.context.items.pickableIfcModels.forEach((model) => {
      if (Array.isArray(model.material)) {
        model.material.forEach((mat) => this.updateClipSpace(mat, bOrigin));
      } else {
        this.updateClipSpace(model.material, bOrigin);
      }
    });
  };

  private hidePreviousPlanes() {
    this.planes.forEach((p) => {
      p.visible = false;
    });
  }

  private createPlaneFromIntersection = (intersection: Intersection) => {
    const constant = intersection.point.distanceTo(new Vector3(0, 0, 0));
    const normal = intersection.face?.normal;
    if (!constant || !normal) return null;
    const normalMatrix = new Matrix3().getNormalMatrix(
      intersection.object.matrixWorld
    );
    const worldNormal = normal.clone().applyMatrix3(normalMatrix).normalize();
    this.normalizePlaneDirectionY(worldNormal);
    const plane = this.newPlane(intersection, worldNormal);
    this.planes.push(plane);
    this.context.addClippingPlane(plane.plane);
    this.updateMaterials();

    return plane;
  };

  private normalizePlaneDirectionY(normal: Vector3) {
    if (this.orthogonalY) {
      if (normal.y > this.toleranceOrthogonalY) {
        normal.x = 0;
        normal.y = 1;
        normal.z = 0;
      }
      if (normal.y < -this.toleranceOrthogonalY) {
        normal.x = 0;
        normal.y = -1;
        normal.z = 0;
      }
    }
  }

  private newPlane(intersection: Intersection, worldNormal: Vector3) {
    return new IfcPlane(
      this.context,
      intersection.point,
      worldNormal,
      this.activateDragging,
      this.deactivateDragging,
      this.planeSize
    );
  }

  private activateDragging = () => {
    this.dragging = true;
  };

  private deactivateDragging = () => {
    this.dragging = false;
  };

  private updateMaterial(material: Material | Material[], planes: Plane[]) {
    if (!Array.isArray(material)) {
      material.clippingPlanes = planes;
      return;
    }
    material.forEach((m) => {
      m.clippingPlanes = planes;
    });
  }

  private updateClipSpace(material: Material, bOrigin: boolean) {
    if (material instanceof MeshLambertMaterial) {
      if (material.userData.shader) {
        const spaceColor = bOrigin ? material.userData.shader.uniforms.u_color.value : this.context.sectionSpaceColor;
        material.userData.shader.uniforms.u_section.value = spaceColor;
      }
    } else if (material instanceof ShaderMaterial) {
      const spaceColor = bOrigin ? material.uniforms.u_color.value : this.context.sectionSpaceColor;
      material.uniforms.u_section.value = spaceColor;
    }
  }

  private updateWireframeMaterial(wireframe: LineSegments, planes: Plane[]) {
    if (!Array.isArray(wireframe.material)) {
      wireframe.material.clippingPlanes = planes;
      return;
    }

    wireframe.material.forEach((m) => {
      m.clippingPlanes = planes;
    });
  }
}
