import IssueVisibility from 'issues/types/IssueVisibility';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type IssueVisibilityTranslations = {
  [P in IssueVisibility]?: string;
};

export default function useIssueVisibilityTranslations() {
  const { t } = useTranslation('issues');
  return useMemo<IssueVisibilityTranslations>(() => ({
    [IssueVisibility.Private]: t('use-issue-visibility-translations_private', 'Private'),
    [IssueVisibility.Public]: t('use-issue-visibility-translations_public', 'Public'),
    [IssueVisibility.Restricted]: t('use-issue-visibility-translations_group-based', 'Group Based'),
  }), [t]);
}
