import React, { useCallback, useState } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BcfImportProgressDialog from 'issues/components/BcfImportProgressDialog';
import { mdiApplicationImport } from '@mdi/js';
import Icon from '@mdi/react';

type BcfImportButtonProps = Omit<ButtonProps, 'onClick'>;

export default function BcfImportButton(props: BcfImportButtonProps) {
  const { t } = useTranslation('issues');
  const [progressDialogVisible, setProgressDialogVisible] = useState<boolean>(false);
  const onClick = useCallback(() => setProgressDialogVisible(true), []);
  const onCloseProgressDialog = useCallback(() => setProgressDialogVisible(false), []);
  return (
    <>
      <Button id="BcfImportButton" variant="outlined" sx={{ pl: 1.5, gap: 1 }} {...props} onClick={onClick}>
        <Icon path={mdiApplicationImport} size={0.8} />
        {t('bcf-import-button_label', 'Import from BCF')}
      </Button>
      {!!progressDialogVisible && <BcfImportProgressDialog onClose={onCloseProgressDialog} />}
    </>
  );
}
