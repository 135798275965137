enum NamingSchemeDateType {
  YYMMDD,
  YYYYMMDD,
  DDMMYY,
  DDMMYYYY,
  DD_MM_YYYY,
  YYYY_MM_DD,
  YY_MM_DD,
  DD_MM_YY,
}

export default NamingSchemeDateType;
