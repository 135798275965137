import React, { useCallback, useMemo, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Typography, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MutateNamingSchemeGroupPanel from 'naming-schemes/components/MutateNamingSchemeGroupPanel';
import ProjectNamingGroupSeparatorType from 'naming-schemes/types/ProjectNamingGroupSeparatorType';
import { LABEL_TYPE_BY_GROUP_TYPE } from 'naming-schemes/components/MutateNamingSchemeGroupLabelElementsPanel';
import NamingSchemeGroupItem from 'naming-schemes/types/NamingSchemeGroupItem';
import NamingSchemeGroupType from 'naming-schemes/types/NamingSchemeGroupType';
import Icon from '@mdi/react';
import { mdiDelete } from '@mdi/js';
import DeleteEncodingGroupConfirmDialog from 'naming-schemes/components/DeleteEncodingGroupConfirmDialog';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';

interface MutateNamingSchemeGroupDialogProps {
  title: string,
  initialValue?: NamingSchemeGroupItem | undefined,
  onClose: (value: NamingSchemeGroupItem | undefined) => Promise<void> | void,
  onRemove?: () => void,
  removeDisabled?: boolean,
  alreadyExistingGroupTypes: NamingSchemeGroupType[],
}

export default function MutateNamingSchemeGroupDialog({
  title,
  initialValue,
  onClose,
  onRemove,
  removeDisabled,
  alreadyExistingGroupTypes,
}: MutateNamingSchemeGroupDialogProps) {
  const { t } = useTranslation('naming-schemes');
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const getRequestErrorMessage = useRequestErrorMessage();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formState, setFormState] = useState<NamingSchemeGroupItem>(initialValue ?? {
    uuid: crypto.randomUUID(),
    name: '',
    type: NamingSchemeGroupType.FreeText,
    isOptional: false,
    excludeFromCleanName: false,
    length: undefined,
    groupElements: [],
    separator: ProjectNamingGroupSeparatorType.Dash,
  });

  const onChange = useCallback((value: NamingSchemeGroupItem) => {
    setFormState(value);
  }, []);

  const onClickCancel = useCallback(() => onClose(undefined), [onClose]);
  const onClickConfirm = useCallback(async () => {
    if (formState.type === undefined || !formState.name?.trim().length || !formState.groupElements) throw new Error('dependency error');
    try {
      const isLabelGroupType = LABEL_TYPE_BY_GROUP_TYPE.has(formState.type);
      if (isLabelGroupType) {
        const abbreviations = formState.groupElements.map((element) => element.abbreviation);
        if (new Set(abbreviations).size < abbreviations.length) {
          throw new Error(t('mutate-naming-scheme-group-dialog_duplicate-abbreviation-error-message', 'Each abbreviation must only occur once per group.'));
        }
      }
      setIsLoading(true);
      await onClose(formState);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  }, [formState, getRequestErrorMessage, onClose, t]);

  const [removeGroupConfirmDialogOpen, setRemoveGroupConfirmDialogOpen] = useState<boolean>(false);
  const onClickRemoveGroup = useCallback(() => setRemoveGroupConfirmDialogOpen(true), []);
  const onCloseRemoveGroupConfirmDialog = useCallback((confirmed: boolean) => {
    if (confirmed && onRemove) {
      onRemove();
    }
    setRemoveGroupConfirmDialogOpen(false);
  }, [onRemove]);

  const isInputValid = useMemo(() => {
    if (formState.type === undefined) return false;
    if (!formState.name?.trim().length) return false;
    if (formState.type === NamingSchemeGroupType.Number && !formState.length) return false;
    if (formState.type === NamingSchemeGroupType.Chars && !formState.length) return false;
    if (formState.type === NamingSchemeGroupType.Version && !formState.groupElements?.[0]?.length) return false;
    if (formState.type === NamingSchemeGroupType.Project && !formState.groupElements?.[0]?.abbreviation?.length) return false;
    if (formState.type === NamingSchemeGroupType.CreationDate && formState.groupElements?.[0]?.dateType === undefined) return false;
    if (formState.type === NamingSchemeGroupType.Custom && formState.groupElements.length === 0) return false;
    const isLabelGroupType = LABEL_TYPE_BY_GROUP_TYPE.has(formState.type);
    if (isLabelGroupType && formState.type !== NamingSchemeGroupType.Custom && (!formState.groupElements?.length || !formState.groupElements.every((element) => element.name?.trim().length && element.abbreviation?.trim().length))) return false;
    return true;
  }, [formState]);

  return (
    <Dialog open id="MutateNamingSchemeGroupDialog" PaperProps={{ sx: { maxWidth: 'calc(100vw - 64px)' } }}>
      <DialogTitle component="div" sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h4">{title}</Typography>
        {!!onRemove && (
          <>
            <Button variant="outlined" sx={{ pl: 1, gap: 0.5 }} onClick={onClickRemoveGroup} size="small" disabled={removeDisabled}>
              <Icon path={mdiDelete} size={0.75} />
              {t('mutate-naming-scheme-dialog_delete-group-button-label', 'Remove Group')}
            </Button>
            {!!removeGroupConfirmDialogOpen && <DeleteEncodingGroupConfirmDialog groupName={formState.name} onClose={onCloseRemoveGroupConfirmDialog} />}
          </>
        )}
      </DialogTitle>
      <DialogContent sx={{ overflowY: 'hidden', display: 'flex', flexDirection: 'column', gap: 2 }}>
        <MutateNamingSchemeGroupPanel
          sx={{ pt: 2, overflowY: 'hidden' }}
          formState={formState}
          alreadyExistingGroupTypes={alreadyExistingGroupTypes}
          onChange={onChange}
        />
        {!!errorMessage && <Alert severity="error" onClose={onCloseErrorMessage}>{errorMessage}</Alert>}
      </DialogContent>
      <DialogActions sx={{ gap: 2, justifyContent: 'space-between' }}>
        <Button variant="contained" color="secondary" onClick={onClickCancel}>
          {t('mutate-naming-scheme-group-dialog_cancel-button-label', 'Cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={onClickConfirm} disabled={!isInputValid || isLoading}>
          {!!isLoading && <CircularProgress size={12} sx={{ mr: 1 }} />}
          {t('mutate-naming-scheme-group-dialog_confirm-button-label', 'Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
