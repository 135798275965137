import { useCallback } from 'react';
import useApiConfig from 'api/hooks/useApiConfig';
import useVisoplanApiContext from 'api/hooks/useVisoplanApiContext';
import ApiEndpoint from 'api/types/ApiEndpoint';

export default function useResourceUploadHandler() {
  const { apiUrl } = useApiConfig();
  const { authProjectToken, authUserToken } = useVisoplanApiContext();

  const handleUploadResources = useCallback(async (files: (File | { blob: Blob, fileName: string })[]) => {
    if (!authProjectToken || !authUserToken) throw new Error('missing token');
    if (!files.length) return [];
    const uploadPromises = Array.from(files).map((file) => new Promise<string>((resolve, reject) => {
      const data = new FormData();
      if ('blob' in file) {
        data.append('file', file.blob, file.fileName);
      } else {
        data.append('file', file, file.name);
      }

      const xhr = new XMLHttpRequest();
      const url = `${apiUrl}/${ApiEndpoint.Resource}`;
      xhr.open('POST', url);
      xhr.setRequestHeader('Authorization', `Bearer ${authUserToken.bearer}`);
      xhr.setRequestHeader('X-Project-Authorization', `Bearer ${authProjectToken.bearer}`);
      xhr.setRequestHeader('Visoplan-Client-Id', 'Visoplan Webclient');
      xhr.setRequestHeader('Visoplan-Webclient-Request-Source', 'Current');

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.responseText);
        } else {
          reject(xhr.statusText);
        }
      };

      xhr.onerror = () => reject(xhr.statusText);

      xhr.send(data);
    }));

    return Promise.all(uploadPromises);
  }, [apiUrl, authProjectToken, authUserToken]);

  return handleUploadResources;
}
