import {
  DoubleSide,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  Plane,
  PlaneBufferGeometry,
  Quaternion,
  Vector3,
} from "three";
import { TransformControls } from "../../../viso-transformcontrol";
import { IfcComponent } from "../../../base-types";
import { IfcContext } from "../../context";
import { ClippingEdge } from "./clipping-edge";

export class IfcPlane extends IfcComponent {
  static planeMaterial = new MeshBasicMaterial({
    color: 0x0000ff,
    side: DoubleSide,
    transparent: true,
    opacity: 0.2,
  });
  readonly plane: Plane;
  readonly planeMesh: Mesh;

  isVisible = true;
  enabled = true;

  // Wether this plane is a section or floor plan
  isPlan = false;

  readonly controls: TransformControls;
  readonly normal: Vector3;
  readonly origin: Vector3;
  readonly helper: Object3D;
  readonly edge: ClippingEdge;

  private readonly planeSize: number;
  private readonly context: IfcContext;

  constructor(
    context: IfcContext,
    origin: Vector3,
    normal: Vector3,
    onStartDragging: Function,
    onEndDragging: Function,
    planeSize: number
  ) {
    super(context);
    this.planeSize = planeSize;
    this.context = context;
    this.plane = new Plane();
    this.planeMesh = this.getPlaneMesh();
    this.normal = normal;
    this.origin = origin;
    this.helper = this.createHelper();
    this.controls = this.newTransformControls();
    this.setupEvents(onStartDragging, onEndDragging);
    this.plane.setFromNormalAndCoplanarPoint(normal, origin);

    this.edge = new ClippingEdge(this.plane, this.context);
  }

  get active() {
    return this.enabled;
  }

  set active(state: boolean) {
    this.enabled = state;
  }

  get visible() {
    return this.isVisible;
  }

  set visible(state: boolean) {
    this.isVisible = state;
    this.controls.visible = state;
    this.helper.visible = state;
  }

  removeFromScene = () => {
    this.helper.removeFromParent();

    this.planeMesh.geometry.dispose();
    this.planeMesh.geometry = undefined;

    this.controls.removeFromParent();
    this.controls.dispose();

    this.edge.dispose();
  };

  updatePlane(quaternion: Quaternion) {
    this.helper.setRotationFromQuaternion(quaternion);
    this.controls.updateMatrixWorld();
    this.controls.update();
  }

  updateLineColor() {
    this.edge.updateColor();
  }

  private newTransformControls() {
    const camera = this.context.getCamera();
    const container = this.context.getDomElement();
    const controls = new TransformControls(camera, container);
    this.initializeControls(controls);
    const scene = this.context.getScene();
    scene.add(controls);
    return controls;
  }

  private initializeControls(controls: TransformControls) {
    controls.attach(this.helper);
    controls.setSpace("local");
    controls.setMode("trans_rotate");
  }

  private setupEvents(onStart: Function, onEnd: Function) {
    this.controls.addEventListener("change", () => {
      if (!this.enabled) return;
      this.setNormal();
      this.plane.setFromNormalAndCoplanarPoint(
        this.normal,
        this.helper.position
      );

      this.origin.copy(this.helper.position);

      this.edge.drawEdge();
    });

    this.controls.addEventListener("dragging-changed", (event: any) => {
      if (!this.enabled) return;
      this.isVisible = !event.value;
      this.context.ifcCamera.toggleCameraControls(this.isVisible);
      if (event.value) onStart();
      else onEnd();
    });
  }

  private setNormal() {
    const q = this.helper.quaternion;
    const x = 2 * (q.x * q.z + q.w * q.y);
    const y = 2 * (q.y * q.z - q.w * q.x);
    const z = 1 - 2 * (q.x * q.x + q.y * q.y);
    this.normal.set(this.round(x), this.round(y), this.round(z));
  }

  private round(data: number) {
    return parseFloat(data.toFixed(6));
  }

  private createHelper() {
    const helper = new Object3D();
    helper.lookAt(this.normal);
    helper.position.copy(this.origin);
    const scene = this.context.getScene();
    scene.add(helper);
    helper.add(this.planeMesh);
    return helper;
  }

  private getPlaneMesh() {
    const planeGeom = new PlaneBufferGeometry(
      this.planeSize,
      this.planeSize,
      1
    );
    return new Mesh(planeGeom, IfcPlane.planeMaterial);
  }
}
