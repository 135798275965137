import { ReactNode, useMemo } from 'react';
import { CellStyle, CellStyleFunc, ColDef, ICellRendererParams } from '@ag-grid-community/core';
import useDocumentsDynamicLayout from 'dynamic-layout/hooks/useDocumentsDynamicLayout';
import DocumentsDataGridRow from 'documents/types/DocumentDataGridRow';
import VersionNumberCell from 'documents/components/cell-renderers/VersionNumberCell';
import StatusCell from 'documents/components/cell-renderers/StatusCell';
import NameCell from 'documents/components/cell-renderers/NameCell';
import BuildingCell from 'documents/components/cell-renderers/BuildingCell';
import CommentButtonCell from 'documents/components/cell-renderers/CommentButtonCell';
import DisciplineCell from 'documents/components/cell-renderers/DisciplineCell';
import FloorCell from 'documents/components/cell-renderers/FloorCell';
import LinkedIssuesCell from 'documents/components/cell-renderers/LinkedIssuesCell';
import TagsCell from 'documents/components/cell-renderers/TagsCell';
import WorkPhaseCell from 'documents/components/cell-renderers/WorkPhaseCell';
import DocumentModelImportCell from 'documents/components/cell-renderers/DocumentModelImportCell';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import FolderCellRenderer from 'documents/components/cell-renderers/FolderCellRenderer';
import DocumentVersionDto from 'documents/types/DocumentVersionDto';
import CheckboxCell from 'documents/components/cell-renderers/CheckboxCell';
import CheckboxHeaderCell from 'documents/components/cell-renderers/CheckboxHeaderCell';
import useCurrentCollaboratorFolderAccessLevel from 'documents-folders/hooks/useCurrentCollaboratorFolderAccessLevel';
import CollaboratorFolderAccessLevel from 'documents-folders/types/CollaboratorFolderAccessLevel';
import useDocumentsViewNavigationContext from 'documents/hooks/useDocumentsViewNavigationContext';
import DragHandleCell from 'documents/components/cell-renderers/DragHandleCell';
import useColumnDisplayNamesByFieldName from 'documents/hooks/useColumnDisplayNamesByFieldName';
import useDocumentsDataGridValueFormatters from 'documents/hooks/useDocumentsDataGridValueFormatters';

const columnNames = new Set<string>([
  'name',
  'versionNumber',
  'fileType',
  'status',
  'uploadDate',
  'disciplines',
  'building',
  'floor',
  'workPhase',
  'linkedIssueIds',
  'editDate',
  'creator',
  'editor',
  'commentIds',
  'creationDate',
  'fileSize',
  'tags',
  'folderId',
]);

const sortingFieldNameByColumnName = new Map<string, string>([
  ['name', 'originalFileName'],
  ['versionNumber', 'versionNumber'],
  ['fileType', 'fileType'],
  ['status', 'status'],
  ['uploadDate', 'uploadDate'],
  ['building', 'building'],
  ['floor', 'floor'],
  ['workPhase', 'workPhase'],
  ['editDate', 'editDate'],
  ['creator', 'creator/id'],
  ['editor', 'editor/id'],
  ['creationDate', 'creationDate'],
  ['fileSize', 'fileSize'],
  ['folderId', 'folderId'],
]);

interface CellRenderersByColumnName {
  [fieldName: string]: (params: ICellRendererParams<DocumentsDataGridRow>) => ReactNode
}

const cellRendererByColumnName: CellRenderersByColumnName = {
  name: NameCell,
  versionNumber: VersionNumberCell,
  status: StatusCell,
  disciplines: DisciplineCell,
  building: BuildingCell,
  floor: FloorCell,
  workPhase: WorkPhaseCell,
  linkedIssueIds: LinkedIssuesCell,
  tags: TagsCell,
  folderId: FolderCellRenderer,
};

const cellStyleByColumnName = new Map<string, CellStyle | CellStyleFunc<DocumentVersionDto>>([
  ['versionNumber', { paddingLeft: '2px', paddingRight: '2px' }],
  ['linkedIssueIds', { paddingLeft: '2px', paddingRight: '2px' }],
]);

const minWidthByColumnName = new Map<string, number>([
  ['status', 75],
  ['discipline', 75],
  ['building', 75],
  ['floor', 75],
  ['workPhase', 75],
  ['tags', 75],
  ['folderId', 75],
]);

type DocumentsValueGetter = (params: { data: DocumentVersionDto | undefined }) => any;
export const valueGetterByColumnName = new Map<string, DocumentsValueGetter>([
  ['name', ({ data }) => data],
]);

export default function useDocumentsDataGridColumnDefinitions() {
  const headerNamesByColumnNames = useColumnDisplayNamesByFieldName();
  const valueFormatterByColumnName = useDocumentsDataGridValueFormatters();
  const { documentScope } = useDocumentsViewNavigationContext();
  const folderId = useMemo(() => (documentScope.key === DocumentScopeKey.Folder ? documentScope.id : undefined), [documentScope.id, documentScope.key]);
  const accessLevel = useCurrentCollaboratorFolderAccessLevel(folderId);
  const { columnWidthsByColumnName, orderedVisibleColumnNames } = useDocumentsDynamicLayout();
  const columnsFromDynamicLayout = useMemo<ColDef<DocumentsDataGridRow>[]>(() => (orderedVisibleColumnNames && columnWidthsByColumnName
    ? orderedVisibleColumnNames
      .filter((columnName) => columnNames.has(columnName))
      .map((columnName) => columnName as keyof DocumentVersionDto)
      .map((columnName) => ({
        colId: sortingFieldNameByColumnName.get(columnName) ?? columnName,
        field: columnName as keyof DocumentsDataGridRow,
        headerName: headerNamesByColumnNames[columnName],
        width: columnWidthsByColumnName.get(columnName),
        minWidth: minWidthByColumnName.get(columnName) ?? 50,
        sortable: sortingFieldNameByColumnName.get(columnName) !== undefined,
        cellRenderer: cellRendererByColumnName[columnName],
        cellStyle: cellStyleByColumnName.get(columnName),
        valueGetter: valueGetterByColumnName.get(columnName),
        valueFormatter: valueFormatterByColumnName.get(columnName),
      }))
    : []), [columnWidthsByColumnName, headerNamesByColumnNames, orderedVisibleColumnNames, valueFormatterByColumnName]);
  const isImportColumnVisible = useMemo(() => documentScope.key !== DocumentScopeKey.Archive && documentScope.key !== DocumentScopeKey.Planlist, [documentScope.key]);

  const columnDefs = useMemo<(ColDef<DocumentsDataGridRow>)[]>(() => {
    const rightPinnedColumns: (ColDef<DocumentsDataGridRow>)[] = [];
    if (isImportColumnVisible) {
      rightPinnedColumns.push({
        colId: 'import',
        pinned: 'right',
        lockPinned: true,
        resizable: false,
        lockPosition: true,
        sortable: false,
        width: 180,
        cellRenderer: DocumentModelImportCell,
        headerName: 'Import',
        headerClass: 'no-border no-hover',
        cellClass: 'no-separator',
        field: 'documentImportStatus',
      });
    }
    if (documentScope.key !== DocumentScopeKey.Archive) {
      rightPinnedColumns.push({
        colId: 'comments',
        pinned: 'right',
        lockPinned: true,
        resizable: false,
        lockPosition: true,
        sortable: false,
        width: 68,
        cellRenderer: CommentButtonCell,
        cellStyle: { textOverflow: 'unset' },
        headerClass: 'no-border no-hover',
        cellClass: 'no-separator',
      });
    }
    return [
      {
        colId: 'drag-handle',
        resizable: false,
        lockPosition: true,
        sortable: false,
        width: 48,
        minWidth: 48,
        headerClass: 'no-hover no-border no-padding no-separator',
        cellClass: 'no-separator',
        hide: documentScope.key === DocumentScopeKey.Archive || (documentScope.key === DocumentScopeKey.Folder && accessLevel !== CollaboratorFolderAccessLevel.ReadWrite),
        cellStyle: { paddingLeft: 0, paddingRight: 0 },
        cellRenderer: DragHandleCell,
      },
      {
        colId: 'checkbox',
        resizable: false,
        lockPosition: true,
        sortable: false,
        width: 26,
        minWidth: 26,
        headerClass: 'no-hover no-border no-padding',
        cellClass: 'no-separator',
        cellStyle: { paddingLeft: 0, paddingRight: 0 },
        cellRenderer: CheckboxCell,
        headerComponent: CheckboxHeaderCell,
      },
      ...columnsFromDynamicLayout,
      ...rightPinnedColumns,
    ];
  }, [accessLevel, columnsFromDynamicLayout, documentScope.key, isImportColumnVisible]);

  const defaultColumnDef = useMemo(() => ({
    resizable: true,
    initialWidth: 100,
    lockPinned: true,
  }), []);

  return { columnDefs, defaultColumnDef };
}
