import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Autocomplete, AutocompleteChangeReason, Box, TextField, Typography } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useLabelsOdataQuery from 'labels/hooks/useLabelsOdataQuery';
import { useTranslation } from 'react-i18next';
import LabelType from 'labels/types/LabelType';
import { mdiAccountBadge, mdiAccountCheck, mdiAccountEdit, mdiAccountHardHat, mdiCardBulleted, mdiFastForward, mdiFileDocumentArrowRight, mdiHome, mdiLayers, mdiTag, mdiThermometer, mdiWrench } from '@mdi/js';
import useCollaboratorsOdataQuery from 'collaborators/hooks/useCollaboratorsOdataQuery';
import useLabelTypeTranslation from 'labels/hooks/useLabelTypeTranslation';
import useIssuesFilterContext from 'issues/hooks/useIssuesFilterContext';

interface IssuesFilterSelectProps extends ISxProps {
  disabled?: boolean | undefined,
}

const iconsByLabelType = {
  [LabelType.Tag]: mdiTag,
  [LabelType.IssueStatus]: mdiFastForward,
  [LabelType.IssuePriority]: mdiThermometer,
  [LabelType.IssueType]: mdiCardBulleted,
  [LabelType.DocumentStatus]: mdiFileDocumentArrowRight,
  [LabelType.Discipline]: mdiWrench,
  [LabelType.Building]: mdiHome,
  [LabelType.Floor]: mdiLayers,
  [LabelType.WorkPhase]: mdiFastForward,
};

enum IssueFilterOptionKind {
  Assignee = 'assignee',
  Reviewer = 'reviewer',
  CreateAuthor = 'create-author',
  EditAuthor = 'edit-author',
  IssueStatus = 'issue-status',
  IssuePriority = 'issue-priority',
  IssueType = 'issue-type',
  Tag = 'tag',
  Discipline = 'discipline',
  Building = 'building',
  Floor = 'floor',
  WorkPhase = 'work-phase',
  Unknown = 'unknown',
}

interface IssueFilterOption {
  id: string,
  label: string,
  kind: IssueFilterOptionKind,
  mdiIconPath: string,
  deleted: boolean,
}

function getOptionKindByLabelType(labelType: LabelType) {
  if (labelType === LabelType.Tag) return IssueFilterOptionKind.Tag;
  if (labelType === LabelType.IssueStatus) return IssueFilterOptionKind.IssueStatus;
  if (labelType === LabelType.IssuePriority) return IssueFilterOptionKind.IssuePriority;
  if (labelType === LabelType.IssueType) return IssueFilterOptionKind.IssueType;
  if (labelType === LabelType.Discipline) return IssueFilterOptionKind.Discipline;
  if (labelType === LabelType.Building) return IssueFilterOptionKind.Building;
  if (labelType === LabelType.Floor) return IssueFilterOptionKind.Floor;
  if (labelType === LabelType.WorkPhase) return IssueFilterOptionKind.WorkPhase;
  return IssueFilterOptionKind.Unknown;
}

export default function IssuesFilterSelect({
  sx,
  disabled,
}: IssuesFilterSelectProps) {
  const { t } = useTranslation('issues');
  const getLabelTypeTranslation = useLabelTypeTranslation();
  const { data: labels } = useLabelsOdataQuery({ filter: { isDeleted: false }, orderBy: 'type' });
  const { data: collaborators } = useCollaboratorsOdataQuery({});
  const getTypeLabel = useCallback((optionType: IssueFilterOptionKind) => {
    if (optionType === IssueFilterOptionKind.Assignee) return t('issue-filter-select_option-type-assignee', 'Assignee');
    if (optionType === IssueFilterOptionKind.Reviewer) return t('issue-filter-select_option-type-reviewer', 'Reviewer');
    if (optionType === IssueFilterOptionKind.CreateAuthor) return t('issue-filter-select_option-type-create-author', 'Created By');
    if (optionType === IssueFilterOptionKind.EditAuthor) return t('issue-filter-select_option-type-edit-author', 'Last Edited By');
    if (optionType === IssueFilterOptionKind.IssueStatus) return getLabelTypeTranslation(LabelType.IssueStatus);
    if (optionType === IssueFilterOptionKind.IssuePriority) return getLabelTypeTranslation(LabelType.IssuePriority);
    if (optionType === IssueFilterOptionKind.IssueType) return getLabelTypeTranslation(LabelType.IssueType);
    if (optionType === IssueFilterOptionKind.Tag) return getLabelTypeTranslation(LabelType.Tag);
    if (optionType === IssueFilterOptionKind.Discipline) return getLabelTypeTranslation(LabelType.Discipline);
    if (optionType === IssueFilterOptionKind.Building) return getLabelTypeTranslation(LabelType.Building);
    if (optionType === IssueFilterOptionKind.Floor) return getLabelTypeTranslation(LabelType.Floor);
    if (optionType === IssueFilterOptionKind.WorkPhase) return getLabelTypeTranslation(LabelType.WorkPhase);
    return t('issue-filter-select_option-type-unknown', 'Unknown');
  }, [getLabelTypeTranslation, t]);
  const options = useMemo(() => {
    if (!labels || !collaborators) return [];
    const labelOptions: IssueFilterOption[] = labels.filter((label) => label.type !== LabelType.DocumentStatus).map((label) => ({
      id: label.id,
      label: label.name,
      kind: getOptionKindByLabelType(label.type),
      mdiIconPath: iconsByLabelType[label.type],
      deleted: false,
    }));
    const assigneeOptions: IssueFilterOption[] = collaborators.map((collaborator) => ({
      id: collaborator.id,
      label: t('issue-filter-select_assignee-label', '{{firstName}} {{lastName}}', { firstName: collaborator.firstName, lastName: collaborator.lastName }),
      kind: IssueFilterOptionKind.Assignee,
      mdiIconPath: mdiAccountHardHat,
      deleted: collaborator.isDeleted,
    }), []);
    const reviewerOptions: IssueFilterOption[] = collaborators.map((collaborator) => ({
      id: collaborator.id,
      label: t('issue-filter-select_reviewer-label', '{{firstName}} {{lastName}}', { firstName: collaborator.firstName, lastName: collaborator.lastName }),
      kind: IssueFilterOptionKind.Reviewer,
      mdiIconPath: mdiAccountCheck,
      deleted: collaborator.isDeleted,
    }), []);
    const createAuthorOptions: IssueFilterOption[] = collaborators.map((collaborator) => ({
      id: collaborator.id,
      label: t('issue-filter-select_create-author-label', '{{firstName}} {{lastName}}', { firstName: collaborator.firstName, lastName: collaborator.lastName }),
      kind: IssueFilterOptionKind.CreateAuthor,
      mdiIconPath: mdiAccountBadge,
      deleted: collaborator.isDeleted,
    }), []);
    const editAuthorOptions: IssueFilterOption[] = collaborators.map((collaborator) => ({
      id: collaborator.id,
      label: t('issue-filter-select_edit-author-label', '{{firstName}} {{lastName}}', { firstName: collaborator.firstName, lastName: collaborator.lastName }),
      kind: IssueFilterOptionKind.EditAuthor,
      mdiIconPath: mdiAccountEdit,
      deleted: collaborator.isDeleted,
    }), []);
    return [
      ...labelOptions,
      ...assigneeOptions,
      ...reviewerOptions,
      ...createAuthorOptions,
      ...editAuthorOptions,
    ];
  }, [collaborators, labels, t]);
  const { queryFilterState } = useIssuesFilterContext();
  const [inputValue, setInputValue] = useState<string>('');
  const onChangeAutocomplete = useCallback((
    event: React.SyntheticEvent<Element, Event>,
    value: IssueFilterOption | string | null,
    reason: AutocompleteChangeReason,
  ) => {
    if (!value || reason !== 'selectOption' || typeof value === 'string') return;
    let nextAppliedFilterState = { ...queryFilterState.appliedFilterState };
    queryFilterState.setQuickFilterString('');
    if (value.kind === IssueFilterOptionKind.Assignee) {
      nextAppliedFilterState = {
        ...nextAppliedFilterState,
        assignedCollaboratorIds: Array.from(new Set((queryFilterState.appliedFilterState.assignedCollaboratorIds ?? []).concat(value.id))),
      };
    } else if (value.kind === IssueFilterOptionKind.Reviewer) {
      nextAppliedFilterState = {
        ...nextAppliedFilterState,
        reviewer: value.id,
      };
    } else if (value.kind === IssueFilterOptionKind.CreateAuthor) {
      nextAppliedFilterState = {
        ...nextAppliedFilterState,
        createAuthor: value.id,
      };
    } else if (value.kind === IssueFilterOptionKind.EditAuthor) {
      nextAppliedFilterState = {
        ...nextAppliedFilterState,
        editAuthor: value.id,
      };
    } else if (value.kind === IssueFilterOptionKind.IssueStatus) {
      nextAppliedFilterState = {
        ...nextAppliedFilterState,
        status: Array.from(new Set((queryFilterState.appliedFilterState.status ?? []).concat(value.id))),
      };
    } else if (value.kind === IssueFilterOptionKind.IssuePriority) {
      nextAppliedFilterState = {
        ...nextAppliedFilterState,
        priority: value.id,
      };
    } else if (value.kind === IssueFilterOptionKind.IssueType) {
      nextAppliedFilterState = {
        ...nextAppliedFilterState,
        type: value.id,
      };
    } else if (value.kind === IssueFilterOptionKind.Tag) {
      nextAppliedFilterState = {
        ...nextAppliedFilterState,
        tags: Array.from(new Set((queryFilterState.appliedFilterState.tags ?? []).concat(value.id))),
      };
    } else if (value.kind === IssueFilterOptionKind.Discipline) {
      nextAppliedFilterState = {
        ...nextAppliedFilterState,
        disciplines: Array.from(new Set((queryFilterState.appliedFilterState.disciplines ?? []).concat(value.id))),
      };
    } else if (value.kind === IssueFilterOptionKind.Building) {
      nextAppliedFilterState = {
        ...nextAppliedFilterState,
        buildings: Array.from(new Set((queryFilterState.appliedFilterState.buildings ?? []).concat(value.id))),
      };
    } else if (value.kind === IssueFilterOptionKind.Floor) {
      nextAppliedFilterState = {
        ...nextAppliedFilterState,
        floors: Array.from(new Set((queryFilterState.appliedFilterState.floors ?? []).concat(value.id))),
      };
    } else if (value.kind === IssueFilterOptionKind.WorkPhase) {
      nextAppliedFilterState = {
        ...nextAppliedFilterState,
        workPhase: value.id,
      };
    }
    setInputValue('');
    queryFilterState.setAppliedFilterState(nextAppliedFilterState);
  }, [queryFilterState]);
  const onChangeAutocompleteInput = useCallback((event: React.SyntheticEvent<Element, Event>, value: string) => {
    setInputValue(value);
    // TODO: debounce setting the filter from input changes to we don't request on every keystroke
    queryFilterState.setQuickFilterString(value);
  }, [queryFilterState]);
  useEffect(() => {
    if (!queryFilterState.quickFilterString.length) {
      setInputValue('');
    }
  }, [queryFilterState.quickFilterString.length]);
  return (
    <Autocomplete
      id="IssuesFilterSelect"
      sx={{ ...sx }}
      options={options}
      inputValue={inputValue}
      value=""
      onChange={onChangeAutocomplete}
      onInputChange={onChangeAutocompleteInput}
      disabled={disabled}
      freeSolo
      autoHighlight
      groupBy={(option) => (typeof option === 'string' ? option : option.kind)}
      renderInput={(params) => (
        <TextField
          label={t('issue-filter-select_label', 'Quick Filter')}
          {...params}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} style={{ ...props.style, ...(option.deleted && { textDecoration: 'line-through' }) }}>
          {option.label}
        </li>
      )}
      renderGroup={(params) => (
        <Box key={params.key}>
          <Box sx={{ px: 1, pt: 1 }}>
            <Typography variant="caption">{getTypeLabel(params.group as IssueFilterOptionKind)}</Typography>
          </Box>
          <Box>{params.children}</Box>
        </Box>
      )}
    />
  );
}
