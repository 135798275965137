import { OdataUseQueryArgs } from 'api/hooks/useOdataQuery';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useQueryFiltersOdataQuery from 'query-filters/hooks/useQueryFiltersOdataQuery';
import QueryFilterDto from 'query-filters/types/QueryFilterDto';
import QueryFilterKind from 'query-filters/types/QueryFilterKind';

export default function useIssueQueryFiltersOdataQuery(
  ...args: OdataUseQueryArgs<ApiEndpoint.QueryFilterIssue, QueryFilterDto<QueryFilterKind.Issue>>
) {
  return useQueryFiltersOdataQuery<QueryFilterKind.Issue, ApiEndpoint.QueryFilterIssue>(ApiEndpoint.QueryFilterIssue, ...args);
}
