import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import ProjectUpdateAuthDto from 'projects/types/ProjectUpdateAuthDto';
import ProjectDto from 'projects/types/ProjectDto';

export default function useProjectUpdateAuthMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.ProjectAuth);
  const mutationFn = useCallback(async (dto: ProjectUpdateAuthDto) => {
    await axiosInstance.patch<ProjectDto>(`${ApiEndpoint.ProjectAuth}`, dto);
  }, [axiosInstance]);
  const onSuccess = useCallback(() => {
    queryKeyBases.forEach((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
  }, [queryClient, queryKeyBases]);
  return useMutation<void, unknown, ProjectUpdateAuthDto>({
    mutationFn,
    onSuccess,
  });
}
