import React from 'react';
import { List, useTheme } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import { useTranslation } from 'react-i18next';
import SettingsTabItem from 'settings/components/SettingsTabItem';
import SettingsTab from 'settings/types/SettingsTab';
import useVisibleSettingsTabs from 'settings/hooks/useVisibleSettingsTabs';

interface SettingsTabsProps extends ISxProps {
}

export default function SettingsTabs({
  sx,
}: SettingsTabsProps) {
  const theme = useTheme();
  const { t } = useTranslation('settings');
  const visibleTabs = useVisibleSettingsTabs();
  return (
    <List id="SettingsTabs" sx={{ position: 'static', backgroundColor: theme.palette.secondary.light, ...sx }}>
      {!!visibleTabs?.has(SettingsTab.Project) && (
      <SettingsTabItem
        tab={SettingsTab.Project}
        primary={t('settings-tabs_project-tab_primary', 'Project Settings')}
        secondary={t('settings-tabs_project-tab_secondary', 'Adminstrative preferences')}
      />
      )}
      {!!visibleTabs?.has(SettingsTab.ProjectMetadata) && (
      <SettingsTabItem
        tab={SettingsTab.ProjectMetadata}
        primary={t('settings-tabs_project-metadata-tab_primary', 'Project Metadata')}
        secondary={t('settings-tabs_project-metadata-tab_secondary', 'Edit model metadata, work phases and tags')}
      />
      )}
      {!!visibleTabs?.has(SettingsTab.Roles) && (
      <SettingsTabItem
        tab={SettingsTab.Roles}
        primary={t('settings-tabs_roles-tab_primary', 'Project Roles')}
        secondary={t('settings-tabs_roles-tab_secondary', 'Edit roles in the project')}
      />
      )}
      {!!visibleTabs?.has(SettingsTab.CollaboratorGroups) && (
      <SettingsTabItem
        tab={SettingsTab.CollaboratorGroups}
        primary={t('settings-tabs_user-groups-tab_primary', 'User Groups')}
        secondary={t('settings-tabs_user-groups-tab_secondary', 'Create user groups')}
      />
      )}
      {!!visibleTabs?.has(SettingsTab.Encoding) && (
      <SettingsTabItem
        tab={SettingsTab.Encoding}
        primary={t('settings-tabs_encoding-tab_primary', 'Encoding')}
        secondary={t('settings-tabs_encoding-tab_secondary', 'Define your structure')}
      />
      )}
      {!!visibleTabs?.has(SettingsTab.Documents) && (
      <SettingsTabItem
        tab={SettingsTab.Documents}
        primary={t('settings-tabs_documents-tab_primary', 'Document Settings')}
        secondary={t('settings-tabs_documents-tab_secondary', 'Set up your documents')}
      />
      )}
      {!!visibleTabs?.has(SettingsTab.Issues) && (
      <SettingsTabItem
        tab={SettingsTab.Issues}
        primary={t('settings-tabs_issues-tab_primary', 'Issue Settings')}
        secondary={t('settings-tabs_issues-tab_secondary', 'Set up your issue overview')}
      />
      )}
    </List>
  );
}
