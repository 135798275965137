import { Dispatch, SetStateAction, createContext } from 'react';

export interface EmailsFilterContextState {
  quickFilterString: string,
  setQuickFilterString: Dispatch<SetStateAction<string>>,
  filteredEmailIds: string[] | undefined,
  setFilteredEmailIds: Dispatch<SetStateAction<string[] | undefined>>,
}

const defaultState: EmailsFilterContextState = {
  quickFilterString: '',
  setQuickFilterString: () => {},
  filteredEmailIds: undefined,
  setFilteredEmailIds: () => {},
};

const EmailsFilterContext = createContext<EmailsFilterContextState>(defaultState);
export default EmailsFilterContext;
