import React, { useMemo } from 'react';
import { Box, Button } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import { useTranslation } from 'react-i18next';
import { mdiAccountArrowRight } from '@mdi/js';
import Icon from '@mdi/react';
import useRejectedIssueStatus from 'issues/hooks/useRejectedIssueStatus';

interface ReopenActionButtonProps extends ISxProps {
  issueId: string,
  onClickReopen: () => void,
}

export default function ReopenActionButton({
  sx,
  issueId,
  onClickReopen,
}: ReopenActionButtonProps) {
  const { t } = useTranslation('issues');
  const { data: issue, isLoading: isLoadingIssue } = useIssueQuery(issueId);
  const rejectedIssueStatus = useRejectedIssueStatus();
  const assigned = useMemo(() => issue?.assignedCollaboratorIds && issue.assignedCollaboratorIds.length > 0, [issue]);
  const canAssign = useMemo(() => {
    if (!issue?.issueStatus || !rejectedIssueStatus) return undefined;
    return issue.issueStatus === rejectedIssueStatus.id;
  }, [issue, rejectedIssueStatus]);

  if (!issueId || !canAssign) return null;
  return (
    <Box id="ReopenActionButton" sx={{ ...sx }}>
      <Button
        variant="contained"
        color="secondary"
        onClick={onClickReopen}
        disabled={!canAssign || isLoadingIssue}
        sx={{ gap: 1 }}
        size="small"
      >
        <Icon path={mdiAccountArrowRight} size={0.5} />
        {!assigned && t('assign-action-button_assign-reopen', 'Assign and re-open')}
        {!!assigned && t('assign-action-button_reassign-reopen', 'Re-assign and re-open')}
      </Button>
    </Box>
  );
}
