import React from 'react';
import ISxProps from 'common/types/ISxProps';
import HeaderBox from 'common/components/HeaderBox';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import ModelsPanelToolbar from 'models/components/ModelsPanelToolbar';

interface ModelsPageHeaderProps extends ISxProps {
}

export default function ModelsPageHeader({
  sx,
}: ModelsPageHeaderProps) {
  const { t } = useTranslation('models');
  return (
    <HeaderBox id="ModelsPageHeader" sx={{ ...sx, boxShadow: 'inset 2px 2px 4px -2px rgba(0,0,0,0.1)', display: 'grid', gridTemplateColumns: '1fr auto 1fr' }}>
      <Typography variant="h2" id="ModelsPageHeaderTitle" sx={{ width: 292, pl: 4 }}>{t('models-page-header_title', 'Models')}</Typography>
      <ModelsPanelToolbar />
    </HeaderBox>
  );
}
