export class VisoStyle {
  id: string;
  ambient: Array<any>;
  specular: Array<any>;
  diffuse: Array<any>;
  emissive: Array<any>;

  constructor(data: Array<any>) {
    this.id = data[0];
    this.ambient = data[3];
    this.specular = data[4];
    this.diffuse = data[5];
    this.emissive = data[6];
  }
}
