import React, { useContext } from 'react';
import IssuePrintContext from 'issues/contexts/IssuePrintContext';
import { Box, Typography } from '@mui/material';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';

interface PrintPreviewFooterProps {
  pageNumber: number;
}

export default function PrintPreviewFooter({ pageNumber }: PrintPreviewFooterProps) {
  const { issuesDocument } = useContext(IssuePrintContext);
  const { footerPosition, showPageNumbers, pageNumberPosition } = issuesDocument;
  const { data: project } = useCurrentProjectQuery();

  return (
    <Box>
      {footerPosition === 'right' && pageNumberPosition === 'left' && (
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {showPageNumbers && <Typography sx={{ fontSize: 12 }}>{pageNumber}</Typography>}
        <Typography sx={{ fontSize: 12 }}>{project?.name}</Typography>
      </Box>
      )}
      {footerPosition === 'left' && pageNumberPosition === 'right' && (
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography sx={{ fontSize: 12 }}>{project?.name}</Typography>
        {showPageNumbers && <Typography sx={{ fontSize: 12 }}>{pageNumber}</Typography>}
      </Box>
      )}
      {footerPosition === 'right' && pageNumberPosition === 'right' && (
        showPageNumbers
          ? (<Typography sx={{ fontSize: 12, textAlign: 'right' }}>{`${project?.name} ${pageNumber}`}</Typography>)
          : <Typography sx={{ fontSize: 12, textAlign: 'right' }}>{project?.name}</Typography>
      )}
      {footerPosition === 'left' && pageNumberPosition === 'left' && (
        showPageNumbers
          ? (<Typography sx={{ fontSize: 12, textAlign: 'left' }}>{`${pageNumber} ${project?.name}`}</Typography>)
          : <Typography sx={{ fontSize: 12, textAlign: 'left' }}>{project?.name}</Typography>
      )}
    </Box>
  );
}
