import React, { useCallback, useRef, useState } from 'react';
import { ButtonBase, FormControlLabel } from '@mui/material';
import ColorPicker from 'common/components/ColorPicker';
import ISxProps from 'common/types/ISxProps';

interface IssueSettingsColorPickerProps extends ISxProps {
  id?: string,
  label: string,
  value: string,
  onChange: (value: string) => void,
}

export default function ColorPickerControl({
  sx,
  id,
  label,
  value,
  onChange,
}: IssueSettingsColorPickerProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const onClickOpenColorPicker = useCallback(() => setOpen(true), []);
  const onCloseColorPicker = useCallback(() => setOpen(false), []);
  return (
    <>
      <FormControlLabel
        control={(
          <ButtonBase
            onClick={onClickOpenColorPicker}
            sx={{ backgroundColor: value, width: 24, height: 24, borderRadius: '4px', minWidth: 'unset', p: 0 }}
            ref={buttonRef}
          />
        )}
        label={label}
        sx={{ ml: 0, pt: 0, gap: 1, ...sx }}
      />
      <ColorPicker
        id={id ?? 'ColorPickerControl'}
        color={value}
        open={open}
        anchorEl={buttonRef.current}
        onChange={onChange}
        onClose={onCloseColorPicker}
      />
    </>
  );
}
