import { mdiBug, mdiLightningBoltOutline, mdiTrendingUp, mdiForum, mdiHelpCircleOutline, mdiLightbulbOutline, mdiCalendarMonthOutline, mdiVectorPolyline, mdiCheck, mdiMessageOutline, mdiAirplane, mdiAlertOctagonOutline, mdiAnchor, mdiBookmarkOutline, mdiBriefcaseOutline, mdiChartLine, mdiClockTimeFourOutline, mdiCloseOctagon, mdiCog, mdiConsolidate, mdiEarth, mdiEyeOutline, mdiFamilyTree, mdiFileTreeOutline, mdiFire, mdiFlagOutline, mdiHeart, mdiImageOutline, mdiLink, mdiLockPattern, mdiMapMarker, mdiMoonWaxingCrescent, mdiMulticast, mdiOpenInNew, mdiPlusCircleOutline, mdiRadar, mdiReload, mdiSecurity, mdiShieldCheckOutline, mdiStarOutline } from '@mdi/js';
import { useMemo } from 'react';

export default function useLabelIcons() {
  return useMemo(() => ({
    defaultIcons: new Map([
      ['icon-default-type-1', mdiBug],
      ['icon-default-type-2', mdiLightningBoltOutline],
      ['icon-default-type-3', mdiTrendingUp],
      ['icon-default-type-4', mdiForum],
      ['icon-default-type-5', mdiHelpCircleOutline],
      ['icon-default-type-6', mdiLightbulbOutline],
      ['icon-default-type-7', mdiCalendarMonthOutline],
      ['icon-default-type-8', mdiVectorPolyline],
      ['icon-default-type-9', mdiCheck],
      ['icon-default-type-10', mdiMessageOutline],
    ]),
    otherIcons: new Map([
      ['issue-other-type-1', mdiEyeOutline],
      ['issue-other-type-2', mdiBookmarkOutline],
      ['issue-other-type-4', mdiMapMarker],
      ['issue-other-type-3', mdiAirplane],
      ['issue-other-type-5', mdiShieldCheckOutline],
      ['issue-other-type-6', mdiPlusCircleOutline],
      ['issue-other-type-7', mdiOpenInNew],
      ['issue-other-type-8', mdiStarOutline],
      ['issue-other-type-9', mdiRadar],
      ['issue-other-type-10', mdiFileTreeOutline],
      ['issue-other-type-11', mdiFire],
      ['issue-other-type-12', mdiLockPattern],
      ['issue-other-type-13', mdiAlertOctagonOutline],
      ['issue-other-type-14', mdiSecurity],
      ['issue-other-type-15', mdiChartLine],
      ['issue-other-type-16', mdiClockTimeFourOutline],
      ['issue-other-type-17', mdiMulticast],
      ['issue-other-type-18', mdiMoonWaxingCrescent],
      ['issue-other-type-19', mdiImageOutline],
      ['issue-other-type-20', mdiEarth],
      ['issue-other-type-11', mdiReload],
      ['issue-other-type-12', mdiLink],
      ['issue-other-type-13', mdiAnchor],
      ['issue-other-type-14', mdiFamilyTree],
      ['issue-other-type-15', mdiBriefcaseOutline],
      ['issue-other-type-16', mdiCog],
      ['issue-other-type-17', mdiHeart],
      ['issue-other-type-18', mdiFlagOutline],
      ['issue-other-type-19', mdiConsolidate],
      ['issue-other-type-20', mdiCloseOctagon],
    ]),
  }), []);
}
