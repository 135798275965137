import React, { useCallback, useRef } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import NamingSchemeGroupItem from 'naming-schemes/types/NamingSchemeGroupItem';
import NamingSchemeGroupType from 'naming-schemes/types/NamingSchemeGroupType';

interface DraggableNamingSchemeGroupPanelDragAndDropPayload {
  index: number,
}

interface DraggableNamingSchemeGroupPanelProps extends ISxProps {
  groupIndex: number,
  groupItem: NamingSchemeGroupItem,
  onHover: (hoverIndex: number | undefined) => void,
  onDrop: (dragIndex: number) => void,
  onDragEnd: () => void,
}

export default function DraggableNamingSchemeGroupPanel({
  sx,
  groupIndex,
  groupItem,
  onHover,
  onDrop,
  onDragEnd,
}: DraggableNamingSchemeGroupPanelProps) {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const getTargetIndex = useCallback((hoverIndex: number, monitor: DropTargetMonitor) => {
    if (!ref.current) return undefined;
    const offsetX = monitor.getClientOffset()?.x;
    if (offsetX === undefined) return undefined;
    const hoverBoundingRect = ref.current.getBoundingClientRect();
    const indexOffset = offsetX < (hoverBoundingRect.width / 2) + hoverBoundingRect.x ? 0 : 1;
    const targetIndex = groupIndex + indexOffset;
    if (targetIndex !== hoverIndex && targetIndex !== hoverIndex + 1 && monitor.canDrop()) {
      return targetIndex;
    }
    return undefined;
  }, [groupIndex]);
  const [{ isDragging }, drag] = useDrag<DraggableNamingSchemeGroupPanelDragAndDropPayload, void, { isDragging: boolean }>(() => ({
    type: 'DraggableNamingSchemeGroupPanel',
    item: () => ({ index: groupIndex, uuid: groupItem.uuid }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => onDragEnd(),
  }), [groupIndex, groupItem]);
  const [, drop] = useDrop<DraggableNamingSchemeGroupPanelDragAndDropPayload>(() => ({
    accept: 'DraggableNamingSchemeGroupPanel',
    drop: async ({ index }) => {
      onDrop(index);
    },
    hover: async ({ index }, monitor) => {
      const targetIndex = getTargetIndex(index, monitor);
      onHover(targetIndex);
    },
  }), [getTargetIndex, onHover, onDrop]);
  drag(drop(ref));
  return (
    <Box
      id="DraggableNamingSchemeGroupPanel"
      ref={ref}
      sx={{
        opacity: isDragging ? 0.6 : 1,
        backgroundColor: theme.palette.background.default,
        borderRadius: '8px',
        boxShadow: '0px 0px 16px -4px rgba(0,0,0,0.4)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 1,
        p: 2,
        ...sx,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h5">{groupItem.name}</Typography>
        <Typography>{NamingSchemeGroupType[groupItem.type]}</Typography>
      </Box>
    </Box>
  );
}
