import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useIssueUpdateMutation from 'issues/hooks/useIssueUpdateMutation';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import IssueVisibility from 'issues/types/IssueVisibility';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import useIssueVisibilityTranslations from 'issues/hooks/useIssueVisibilityTranslations';
import useCollaboratorGroupsOdataQuery from 'collaborators-groups/hooks/useCollaboratorGroupsOdataQuery';

interface EditVisibilityDialogProps {
  issueId: string,
  onClose: () => void,
}

export default function EditVisibilityDialog({
  issueId,
  onClose,
}: EditVisibilityDialogProps) {
  const { t } = useTranslation('issues');
  const issueVisibilityTranslations = useIssueVisibilityTranslations();
  const { data: issue } = useIssueQuery(issueId);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const getRequestErrorMessage = useRequestErrorMessage();
  const { mutateAsync } = useIssueUpdateMutation();

  const [selectedVisibility, setSelectedVisibility] = useState<IssueVisibility | undefined>(undefined);
  useEffect(() => setSelectedVisibility((prev) => prev ?? issue?.visibility), [issue?.visibility]); // init form value
  const onChangeVisibilitySelect = useCallback((event: SelectChangeEvent<string>) => setSelectedVisibility(parseInt(event.target.value, 10) as IssueVisibility), []);

  const { data: groups } = useCollaboratorGroupsOdataQuery(selectedVisibility === IssueVisibility.Restricted ? { filter: { isDeleted: false } } : undefined);
  const [selectedGroupIds, setSelectedGroupIds] = useState<string[] | undefined>(undefined);
  useEffect(() => setSelectedGroupIds((prev) => prev ?? issue?.allowedCollaboratorGroups), [issue?.allowedCollaboratorGroups]); // init form value
  const onChangeGroupsSelect = useCallback((event: SelectChangeEvent<string[]>) => {
    if (Array.isArray(event.target.value)) {
      setSelectedGroupIds(event.target.value);
    }
  }, []);

  const canConfirm = useMemo(() => selectedVisibility !== IssueVisibility.Restricted || selectedGroupIds?.length, [selectedGroupIds?.length, selectedVisibility]);

  const onConfirm = useCallback(async () => {
    try {
      if (selectedVisibility !== undefined) {
        await mutateAsync({
          id: issueId,
          visibility: { value: selectedVisibility },
          ...(selectedVisibility === IssueVisibility.Restricted ? { allowedGroups: { value: selectedGroupIds ?? [] } } : {}),
        });
      }
      onClose();
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [getRequestErrorMessage, issueId, mutateAsync, onClose, selectedGroupIds, selectedVisibility]);

  return (
    <Dialog open id="EditVisibilityDialog">
      <DialogTitle>
        {t('edit-visibility-dialog_title', 'Edit Issue Visibility')}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 1, display: 'flex', flexDirection: 'column', gap: 2, minWidth: 350 }}>
          {!!issue && (
            <>
              <FormControl>
                <InputLabel id="edit-visibility-dialog_visibility-select-label">{t('edit-visibility-dialog_visibility-select-label', 'Visibility')}</InputLabel>
                <Select
                  labelId="edit-visibility-dialog_visibility-select-label"
                  label={t('edit-visibility-dialog_visibility-select-label', 'Visibility')}
                  value={`${selectedVisibility ?? ''}`}
                  disabled={selectedVisibility === undefined}
                  onChange={onChangeVisibilitySelect}
                >
                  <MenuItem value={`${IssueVisibility.Public}`}>{issueVisibilityTranslations[IssueVisibility.Public]}</MenuItem>
                  <MenuItem value={`${IssueVisibility.Restricted}`}>{issueVisibilityTranslations[IssueVisibility.Restricted]}</MenuItem>
                  <MenuItem value={`${IssueVisibility.Private}`}>{issueVisibilityTranslations[IssueVisibility.Private]}</MenuItem>
                </Select>
              </FormControl>
              {selectedVisibility === IssueVisibility.Restricted && !groups && <CircularProgress size={12} />}
              {selectedVisibility === IssueVisibility.Restricted && groups && (
                <FormControl>
                  <InputLabel id="edit-visibility-dialog_groups-select-label">{t('edit-visibility-dialog_groups-select-label', 'Groups')}</InputLabel>
                  <Select
                    labelId="edit-visibility-dialog_groups-select-label"
                    label={t('edit-visibility-dialog_groups-select-label', 'Groups')}
                    value={selectedGroupIds ?? []}
                    disabled={!selectedGroupIds}
                    onChange={onChangeGroupsSelect}
                    multiple
                  >
                    {groups.map((group) => (
                      <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </>
          )}
          {!!errorMessage && <Alert severity="error" onClose={onCloseErrorMessage}>{errorMessage}</Alert>}
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
        <Button onClick={onClose} color="secondary" variant="contained">{t('edit-visibility-dialog_cancel-button-label', 'Cancel')}</Button>
        <Button onClick={onConfirm} color="primary" variant="contained" disabled={!canConfirm}>{t('edit-visibility-dialog_confirm-button-label', 'Confirm')}</Button>
      </DialogActions>
    </Dialog>
  );
}
