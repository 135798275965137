import { Box, Button, Divider, FormControlLabel, Radio, RadioGroup, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography, styled } from '@mui/material';
import IssuePrintColumnSelection from 'issues/components/IssuePrintColumnSelection';
import useIssuePrintContext from 'issues/hooks/useIssuePrintContext';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import theme from 'theme';

interface IssuePrintOptionsDrawerProps {
  handlePrint: () => void;
}

export default function IssuePrintOptionsDrawer({ handlePrint }: IssuePrintOptionsDrawerProps) {
  const { t } = useTranslation('issues');
  const { issuesDocument, setIssuesDocument } = useIssuePrintContext();
  const [view, setView] = useState<string>('table');
  const onChangePageNrPos = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setIssuesDocument((prev) => ({ ...prev, pageNumberPosition: event.target.value as 'left' | 'right' }));
  }, [setIssuesDocument]);
  const onChangeFooterPos = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setIssuesDocument((prev) => ({ ...prev, footerPosition: event.target.value as 'left' | 'right' }));
  }, [setIssuesDocument]);
  const onChangeRightPageHead = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setIssuesDocument((prev) => ({ ...prev, rightPageHead: event.target.value }));
  }, [setIssuesDocument]);
  const onChangeLeftPageHead = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setIssuesDocument((prev) => ({ ...prev, leftPageHead: event.target.value }));
  }, [setIssuesDocument]);
  const onChangeHeaderTitle = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setIssuesDocument((prev) => ({ ...prev, headerTitle: event.target.value }));
  }, [setIssuesDocument]);
  const togglePageNumbers = useCallback(() => {
    setIssuesDocument((prev) => ({ ...prev, showPageNumbers: !prev.showPageNumbers }));
  }, [setIssuesDocument]);
  const onChangeView = useCallback((event: React.MouseEvent<HTMLElement>, newView: string) => {
    if (newView === null) return;
    setView(newView);
    setIssuesDocument((prev) => ({ ...prev, printTable: newView === 'table' }));
  }, [setIssuesDocument]);

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
    '& .MuiToggleButton-root': {
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
      },
    },
  }));

  return (
    <Box id="IssuePrintOptionsDrawer" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', borderLeft: '1px solid', borderColor: theme.palette.divider, p: 2, height: '96vh', overflowY: 'scroll' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
        <Typography variant="h1">{t('issue-print-options-drawer_title', 'Print options')}</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2 }}>
          <Typography variant="h2">{t('issue-print-options-drawer_header-label', 'Header')}</Typography>
          <TextField label={t('issue-print-options-drawer_left-page-head-label', 'Left page head')} onChange={onChangeLeftPageHead} fullWidth multiline minRows={2} />
          <TextField label={t('issue-print-options-drawer_right-page-head-label', 'Right page head')} onChange={onChangeRightPageHead} fullWidth multiline minRows={2} />
          <TextField label={t('issue-print-options-drawer_title-label', 'Title')} onChange={onChangeHeaderTitle} fullWidth />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2 }}>
          <Typography variant="h2">{t('issue-print-options-drawer_footer-label', 'Footer')}</Typography>
          <RadioGroup value={issuesDocument.footerPosition} onChange={onChangeFooterPos} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <FormControlLabel value="left" control={<Radio />} label={t('issue-print-options-drawer_footer-left-label', 'Left')} />
            <FormControlLabel value="right" control={<Radio />} label={t('issue-print-options-drawer_footer-right-label', 'Right')} />
          </RadioGroup>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h2">{t('issue-print-options-drawer_page-numbers-label', 'Page numbers')}</Typography>
            <Switch onChange={togglePageNumbers} defaultChecked />
          </Box>
          <RadioGroup value={issuesDocument.pageNumberPosition} onChange={onChangePageNrPos} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <FormControlLabel value="left" control={<Radio />} disabled={!issuesDocument.showPageNumbers} label={t('issue-print-options-drawer_page-numbers-left-label', 'Left')} />
            <FormControlLabel value="right" control={<Radio />} disabled={!issuesDocument.showPageNumbers} label={t('issue-print-options-drawer_page-numbers-right-label', 'Right')} />
          </RadioGroup>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, border: '1px solid', borderColor: theme.palette.grey[400], borderRadius: '10px', p: 2 }}>
          <Typography variant="h2">{t('issue-print-options-drawer_layout-label', 'Layout')}</Typography>
          <StyledToggleButtonGroup exclusive value={view} onChange={onChangeView} fullWidth>
            <ToggleButton value="table">{t('issue-print-options-drawer_table-view-button', 'Table view')}</ToggleButton>
            <ToggleButton value="details">{t('issue-print-options-drawer_details-view-button', 'Details view')}</ToggleButton>
          </StyledToggleButtonGroup>
          <Divider />
          <IssuePrintColumnSelection />
        </Box>
      </Box>
      <Button sx={{ mb: 2 }} fullWidth onClick={handlePrint} variant="contained">
        {t('issue-print-options-drawer_print-button', 'Print document')}
      </Button>
    </Box>
  );
}
