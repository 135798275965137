import { Camera, Euler, Vector2, Vector3, Matrix4, PerspectiveCamera, OrthographicCamera } from "three";
import {
  IfcComponent,
  NavigationMode,
  NavigationModes,
} from "../../../../base-types";
import { IfcCamera } from "../camera";
import { LiteEvent } from "../../../../utils/LiteEvent";
import { IfcContext } from "../../context";

const _movementVelocityInitial = 4.0; // 5 m/s = 14.4 km/h
const _movementVelocityCap = 50.0; // 50 m/s = 180 km/h
const _movementAcceleration = 5.0; // 5 m/s² (~ car)
const _movementAccelerationDelay = 1500; // 1.5 seconds after which, when continuously flying, we start accelerating
const _rotationVelocity = 0.002;
const _panVelocity = 0.025;
const _zoomVelocity = 0.018;
const _mouseInput = new Vector2();
const _euler = new Euler(0, 0, 0, "YXZ");
const _translation = new Vector3();
const _viewRotationMatrix = new Matrix4().identity();
const _PI_2 = Math.PI / 2;

export class StandardControl extends IfcComponent implements NavigationMode {
  readonly mode = NavigationModes.Standard;
  enabled = true;
  onChange = new LiteEvent<Boolean>();
  onChangeProjection = new LiteEvent<Camera>();
  camera: Camera;

  private readonly domElement: HTMLElement;
  private isLock: boolean = false;
  private pressTime: number = -1;
  private wheelInput: number = 0;
  private isWheelPressed = false;
  private movementInputX = 0; // we need integers to avoid floating point noise, so no Vector3
  private movementInputY = 0;
  private movementInputZ = 0;
  private currentMovementVelocity = _movementVelocityInitial;

  constructor(private context: IfcContext, ifcCamera: IfcCamera) {
    super(context);
    this.domElement = this.context.getDomElement();
    this.camera = ifcCamera.activeCamera;
    this.connect();
  }

  toggle(active: boolean) {
    if (this.enabled == active) return;
    this.enabled = active;
    if (this.isLock && !active) {
      this.unlock();
    }

    if (active) {
      this.connect();
    } else {
      this.disconnect();
    }
  }

  isLocked() {
    return this.isLock;
  }

  dispose(): void {
    super.dispose();
    this.disconnect();
  }

  setLookAt(position: Vector3, target: Vector3) {
    this.camera.position.copy(position);
    this.camera.lookAt(target);
    this.onChange?.trigger(true);
  }

  setZoom(zoom:number){
    if(this.camera instanceof PerspectiveCamera || this.camera instanceof OrthographicCamera){
      this.camera.zoom = zoom;
      this.camera.updateProjectionMatrix();
    }
  }

  private connect() {
    this.domElement.addEventListener("mousemove", this.onMouseMove);
    this.domElement.addEventListener("wheel", this.onMouseWheel);
    this.domElement.addEventListener("mousedown", this.onMouseDown);
    this.domElement.addEventListener("mouseup", this.onMouseUp);
    this.domElement.ownerDocument.addEventListener("keydown", this.onKeyDown);
    this.domElement.ownerDocument.addEventListener("keyup", this.onKeyUp);
    this.domElement.ownerDocument.addEventListener(
      "pointerlockchange",
      this.onPointerlockChange
    );
  }

  private disconnect() {
    this.isWheelPressed = false;
    this.movementInputX = this.movementInputY = this.movementInputZ = 0;
    _mouseInput.set(0, 0);
    this.domElement.removeEventListener("mousemove", this.onMouseMove);
    this.domElement.removeEventListener("wheel", this.onMouseWheel);
    this.domElement.removeEventListener("mousedown", this.onMouseDown);
    this.domElement.removeEventListener("mouseup", this.onMouseUp);
    this.domElement.ownerDocument.removeEventListener(
      "keydown",
      this.onKeyDown
    );
    this.domElement.ownerDocument.removeEventListener("keyup", this.onKeyUp);
    this.domElement.ownerDocument.removeEventListener(
      "pointerlockchange",
      this.onPointerlockChange
    );
  }

  private onKeyDown = (e: KeyboardEvent) => {
    if (e.repeat) return;

    const wasMoving = this.hasKeyboardMovementInput();

    switch (e.key) {
      case "w":
        this.movementInputZ -= 1;
        break;
      case "s":
        this.movementInputZ += 1;
        break;
      case "a":
        this.movementInputX -= 1;
        break;
      case "d":
        this.movementInputX += 1;
        break;
      case "q":
        this.movementInputY += 1;
        break;
      case "e":
        this.movementInputY -= 1;
        break;
    }

    if (!wasMoving && this.hasKeyboardMovementInput()) {
      this.lock();
      this.pressTime = performance.now();
    }
  };

  private onKeyUp = (e: KeyboardEvent) => {
    const wasMoving = this.hasKeyboardMovementInput();

    switch (e.key) {
      case "w":
        this.movementInputZ += 1;
        break;
      case "s":
        this.movementInputZ -= 1;
        break;
      case "a":
        this.movementInputX += 1;
        break;
      case "d":
        this.movementInputX -= 1;
        break;
      case "q":
        this.movementInputY -= 1;
        break;
      case "e":
        this.movementInputY += 1;
        break;
    }

    if (wasMoving && !this.hasKeyboardMovementInput()) {
      this.pressTime = -1;
    }
  };

  private onMouseDown = (e: MouseEvent) => {
    if (e.button == 1) {
      this.isWheelPressed = true;
    }
  };

  private onMouseUp = (e: MouseEvent) => {
    if (e.button == 2 && this.isLock) {
      this.unlock();
    }

    if (e.button == 1) {
      this.isWheelPressed = false;
    }
  };

  private onMouseWheel = (e: WheelEvent) => {
    this.wheelInput += e.deltaY;
  };

  private onMouseMove = (event: any) => {
    if (this.isLock || this.isWheelPressed) {
      _mouseInput.x =
        event.movementX || event.mozMovementX || event.webkitMovementX || 0;
      _mouseInput.y =
        event.movementY || event.mozMovementY || event.webkitMovementY || 0;
    }
  };

  private onPointerlockChange = () => {
    if (this.domElement.ownerDocument.pointerLockElement === this.domElement) {
      this.isLock = true;
    } else {
      this.isLock = false;
    }
  };

  private lock() {
    this.domElement.requestPointerLock();
  }

  private unlock() {
    document.exitPointerLock();
  }

  update(_delta: number): void {
    super.update(_delta);
    if (this.enabled) {
      if (this.hasKeyboardMovementInput()) {
        // WASD movement
        this.updateMovementVelocity(_delta);
        _translation.set(
          this.movementInputX * _delta * this.currentMovementVelocity,
          this.movementInputY * _delta * this.currentMovementVelocity,
          this.movementInputZ * _delta * this.currentMovementVelocity
        );

        this.moveCameraRelativeToView(_translation);
      }

      if (this.wheelInput != 0) {
        // scroll wheel zoom
        _translation.set(0, 0, this.wheelInput * _zoomVelocity);
        this.moveCameraRelativeToView(_translation);
        this.wheelInput = 0;
      }

      if (_mouseInput.lengthSq() > 0.0001) {
        if (this.isLocked()) {
          // mouse pan (while flying)
          _mouseInput.multiplyScalar(_rotationVelocity);
          this.rotate(_mouseInput);
          _mouseInput.set(0, 0);
        } else if (this.isWheelPressed) {
          // mouse pan (while pressing down wheel and not flying)
          _translation.set(
            -1 * _mouseInput.x * _panVelocity,
            _mouseInput.y * _panVelocity,
            0
          );
          this.moveCameraRelativeToView(_translation);
          _mouseInput.set(0, 0);
        }
      }
    }
  }

  private moveCameraRelativeToView(translation: Vector3) {
    this.camera.updateMatrixWorld();
    _viewRotationMatrix.extractRotation(this.camera.matrixWorld);
    translation.applyMatrix4(_viewRotationMatrix);
    this.camera.position.add(translation);

    this.onChange?.trigger(true);
  }

  private updateMovementVelocity(deltaSeconds: number) {
    // if we hold 'w' down long enough, we start accelerating. otherwise we just leave the current velocity at the initial value
    if (
      this.pressTime > 0 &&
      this.pressTime + _movementAccelerationDelay < performance.now()
    ) {
      this.currentMovementVelocity = Math.min(
        this.currentMovementVelocity + _movementAcceleration * deltaSeconds,
        _movementVelocityCap
      );
    } else {
      this.currentMovementVelocity = _movementVelocityInitial;
    }
  }

  private hasKeyboardMovementInput() {
    return (
      this.movementInputX != 0 ||
      this.movementInputY != 0 ||
      this.movementInputZ != 0
    );
  }

  private rotate(rotationAngles: Vector2) {
    _euler.setFromQuaternion(this.camera.quaternion);

    _euler.y -= rotationAngles.x;
    _euler.x -= rotationAngles.y;

    _euler.x = Math.max(-_PI_2, Math.min(_PI_2, _euler.x));

    this.camera.quaternion.setFromEuler(_euler);

    this.onChange?.trigger(true);
  }
}
