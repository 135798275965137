import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import CollaboratorRoleSelect from 'collaborators/components/CollaboratorRoleEditSelect';
import UserSearchBox from 'collaborators/components/UserSearchBox';
import CollaboratorDto from 'collaborators/types/CollaboratorDto';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useRolesOdataQuery from 'collaborators/hooks/useRolesOdataQuery';
import useCollaboratorsOdataQuery from 'collaborators/hooks/useCollaboratorsOdataQuery';
import useCollaboratorCreateMutation from 'collaborators/hooks/useCollaboratorCreateMutation';
import CollaboratorCreateDto from 'collaborators/types/CollaboratorCreateDto';

interface AddCollaboratorDialogProps {
  onClose: (addedCollaboratorsCount: number | undefined) => void,
}

export default function AddCollaboratorDialog({
  onClose,
}: AddCollaboratorDialogProps) {
  const { t } = useTranslation('collaborators');
  const { data: roles } = useRolesOdataQuery({});
  const { data: collaborators } = useCollaboratorsOdataQuery({ filter: { isDeleted: false } });
  const { mutateAsync, isPending: isLoadingMutation } = useCollaboratorCreateMutation();
  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [collaboratorCreateDtos, setCollaboratorCreateDtos] = useState<CollaboratorCreateDto[]>([]);
  const [stagedCollaborator, setStagedCollaborator] = useState<CollaboratorDto | undefined>();
  const [stagedRoleDefinitionId, setStagedRoleDefinitionId] = useState<string | undefined>();
  const [userSearchBoxText, setUserSearchBoxText] = useState<string>('');
  const [collaboratorsByEmail] = useState(new Map<string, CollaboratorDto>());

  const isStagedCollaboratorAlreadyInProject = useMemo(() => stagedCollaborator && collaborators?.some((c) => c.id === stagedCollaborator.id), [collaborators, stagedCollaborator]);
  const isStagedCollaboratorAlreadyListed = useMemo(() => stagedCollaborator && collaboratorCreateDtos.some((l) => l.email === stagedCollaborator.email), [collaboratorCreateDtos, stagedCollaborator]);
  const userSearchBoxHelperText = useMemo(() => {
    if (isStagedCollaboratorAlreadyInProject) return t('add-collaborator-dialog_collaborator-already-in-project', 'This collaborator is already in the project.');
    if (isStagedCollaboratorAlreadyListed) return t('add-collaborator-dialog_collaborator-already-in-list', 'This collaborator is already in the list below.');
    return undefined;
  }, [isStagedCollaboratorAlreadyInProject, isStagedCollaboratorAlreadyListed, t]);

  const collaboratorRoleMapsToAdd = useMemo<CollaboratorCreateDto[]>(() => {
    const stagedMap = stagedCollaborator && stagedRoleDefinitionId ? { email: stagedCollaborator.email, roleId: stagedRoleDefinitionId } : undefined;
    return stagedMap ? [stagedMap, ...collaboratorCreateDtos] : collaboratorCreateDtos;
  }, [collaboratorCreateDtos, stagedCollaborator, stagedRoleDefinitionId]);

  const collaboratorItems = useMemo(() => collaboratorCreateDtos.filter((dto) => collaboratorsByEmail.has(dto.email)).map((dto) => {
    const collaborator = collaboratorsByEmail.get(dto.email)!;
    return {
      email: dto.email,
      roleId: dto.roleId,
      name: `${collaborator.firstName} ${collaborator.lastName}`,
    };
  }), [collaboratorCreateDtos, collaboratorsByEmail]);

  const queryClient = useQueryClient();
  const { listQueryKeyBases: userGroupListQueryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.CollaboratorGroup);
  const onConfirm = useCallback(async () => {
    try {
      await Promise.all(collaboratorRoleMapsToAdd.map(async (createDto) => await mutateAsync(createDto)));
      await Promise.all(userGroupListQueryKeyBases.map((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase })));
      onClose(collaboratorRoleMapsToAdd.length);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [collaboratorRoleMapsToAdd, mutateAsync, queryClient, userGroupListQueryKeyBases, onClose, getRequestErrorMessage]);
  const onClickCancel = useCallback(() => onClose(undefined), [onClose]);

  const stageFoundCollaborator = useCallback(() => {
    if (!stagedCollaborator || !stagedRoleDefinitionId) return;
    collaboratorsByEmail.set(stagedCollaborator.email, stagedCollaborator);
    setCollaboratorCreateDtos((prev) => [...prev, { email: stagedCollaborator.email, roleId: stagedRoleDefinitionId }]);
    setUserSearchBoxText('');
  }, [collaboratorsByEmail, stagedCollaborator, stagedRoleDefinitionId]);

  const changeStagedRole = useCallback((email: string, nextRoleId: string) => {
    setCollaboratorCreateDtos((prev) => prev.map((m) => {
      if (m.email === email) return { email, roleId: nextRoleId };
      return m;
    }));
  }, []);

  const onChangeUserSearchBoxText = useCallback((nextValue: string) => {
    setUserSearchBoxText(nextValue);
    setErrorMessage(undefined);
  }, []);

  const onChangeUserSearchBoxCollaborator = useCallback((collaborator: CollaboratorDto | undefined) => setStagedCollaborator(collaborator), []);

  const addMoreButtonDisabled = useMemo(() => !stagedCollaborator || !stagedRoleDefinitionId || isStagedCollaboratorAlreadyInProject || isStagedCollaboratorAlreadyListed, [isStagedCollaboratorAlreadyInProject, isStagedCollaboratorAlreadyListed, stagedCollaborator, stagedRoleDefinitionId]);

  useEffect(() => {
    // set default role on init
    setStagedRoleDefinitionId((prev) => {
      if (prev || !roles) return prev;
      return roles.find((r) => r.isAdmin)?.id;
    });
  }, [roles, stagedRoleDefinitionId]);

  return (
    <Dialog
      id="AddCollaboratorDialog"
      open
      PaperProps={{
        sx: {
          maxWidth: 'unset', width: '650px', minHeight: '360px',
        },
      }}
    >
      <DialogTitle sx={{ display: 'flex', columnGap: 2 }}>
        {t('add-collaborator-dialog_title', 'Add Collaborators to the Project')}
        <Button
          variant="contained"
          color="secondary"
          onClick={stageFoundCollaborator}
          disabled={addMoreButtonDisabled}
          sx={{ minWidth: 'unset', pl: 1, ml: 'auto' }}
        >
          <PlaylistAddIcon sx={{ mr: 1 }} />
          {t('add-collaborator-dialog_add-more-button-label', 'Add more')}
        </Button>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 2 }}>
          <TableContainer>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '180px' }}>{t('add-collaborator-dialog_table-header-column-email', 'E-Mail')}</TableCell>
                  <TableCell>{t('add-collaborator-dialog_table-header-column-anem', 'Name')}</TableCell>
                  <TableCell sx={{ width: '125px' }}>{t('add-collaborator-dialog_table-header-column-role', 'Role')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <UserSearchBox
                      sx={{ width: '170px' }}
                      text={userSearchBoxText}
                      onChangeText={onChangeUserSearchBoxText}
                      onChangeCollaborator={onChangeUserSearchBoxCollaborator}
                      helperText={userSearchBoxHelperText}
                      error={isStagedCollaboratorAlreadyInProject || isStagedCollaboratorAlreadyListed}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography>{!!stagedCollaborator && `${stagedCollaborator?.firstName} ${stagedCollaborator?.lastName}`}</Typography>
                  </TableCell>
                  <TableCell>
                    {!!stagedRoleDefinitionId && <CollaboratorRoleSelect value={stagedRoleDefinitionId} onChange={setStagedRoleDefinitionId} sx={{ width: '120px' }} />}
                  </TableCell>
                </TableRow>
                {collaboratorItems.map(({ name, email, roleId }) => (
                  <TableRow key={email}>
                    <TableCell>
                      {email}
                    </TableCell>
                    <TableCell>
                      {name}
                    </TableCell>
                    <TableCell>
                      <CollaboratorRoleSelect
                        value={roleId}
                        onChange={(nextRoleId) => changeStagedRole(email, nextRoleId)}
                        sx={{ width: '120px' }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {!!errorMessage && <Alert severity="error" sx={{ mt: 2 }}>{errorMessage}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onClickCancel}>
          {t('add-collaborator-dialog_cancel', 'Cancel')}
        </Button>
        <Button id="AddCollaboratorDialogConfirmButton" variant="contained" color="primary" onClick={onConfirm} sx={{ ml: 'auto' }} disabled={!collaboratorRoleMapsToAdd.length || isStagedCollaboratorAlreadyInProject || isStagedCollaboratorAlreadyListed || isLoadingMutation}>
          {(isLoadingMutation) && <CircularProgress size={12} sx={{ mr: 1 }} />}
          {t('add-collaborator-dialog_confirm', 'Add {{count}} collaborators', { count: collaboratorRoleMapsToAdd.length })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
