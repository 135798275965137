import React from 'react';
import { Box } from '@mui/material';
import useFolderIconName from 'documents-folders/hooks/useFolderIconName';
import FolderAccessType from 'documents-folders/types/FolderAccessType';

interface FolderIconProps {
  accessType: FolderAccessType,
}

export default function FolderIcon({
  accessType,
}: FolderIconProps) {
  const folderIconName = useFolderIconName(accessType);
  return (
    <Box sx={{
      width: '32px', height: '32px', flexShrink: 0, ml: 0.5,
    }}
    >
      {folderIconName && (
      <svg viewBox="0 -6 32 32">
        <use xlinkHref={`/img/sprite.svg#${folderIconName}`} />
      </svg>
      )}
    </Box>
  );
}
