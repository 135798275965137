import React, { useCallback } from 'react';
import { Box, ButtonBase, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ProjectOverviewIcon from 'img/components/ProjectOverviewIcon';
import useLegacySettingsNotificationDialogContext from 'settings/hooks/useLegacySettingsNotificationDialogContext';

export default function MainHeaderLeaveProjectButton() {
  const { t } = useTranslation('projects');
  const theme = useTheme();

  const { navigateWithConfirmation } = useLegacySettingsNotificationDialogContext();

  const onClick = useCallback(() => {
    navigateWithConfirmation('/projects');
  }, [navigateWithConfirmation]);

  return (
    <ButtonBase
      id="MainHeaderLeaveProjectButton"
      onClick={onClick}
      title={t('main-header-leave-project-button_label', 'Project Overview')}
      sx={{
        justifyItems: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'left',
        '&:hover': {
          backgroundColor: theme.palette.grey[200],
        },
        width: 60,
        height: 60,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <ProjectOverviewIcon htmlColor={theme.palette.grey[600]} fontSize="small" />
      </Box>
    </ButtonBase>
  );
}
