import React from 'react';
import { Box, InputAdornment, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import RoundIconButton from 'common/components/RoundIconButton';
import { useTranslation } from 'react-i18next';
import useQueryFilterFilterContext from 'documents-filter/hooks/useQueryFilterFilterContext';
import QueryFilterSortButtonMenu from 'documents-filter/components/QueryFilterSortButtonMenu';

export default function QueryFilterFilterToolbar() {
  const { t } = useTranslation('documents-filter');
  const { searchString, setSearchString, isFilterActive } = useQueryFilterFilterContext();

  return (
    <Box sx={{ display: 'flex', px: 3, pb: 2 }}>
      <TextField
        label={t('filter-explorer_search-folders', 'Search Filters')}
        value={searchString ?? ''}
        onChange={(e) => setSearchString(e.target.value || undefined)}
        sx={{ flexGrow: 1 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isFilterActive
                ? <RoundIconButton Icon={CloseIcon} onClick={() => setSearchString(undefined)} />
                : <SearchIcon />}
            </InputAdornment>
          ),
        }}
      />
      <QueryFilterSortButtonMenu sx={{ ml: 1 }} />
    </Box>
  );
}
