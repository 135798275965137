import ApiEndpoint from 'api/types/ApiEndpoint';
import QueryArgs from 'api/types/QueryArgs';
import { QueryOptions } from 'odata-query';
import { useMemo } from 'react';

export type QueryKeyBase = ApiEndpoint;

export type EndpointQueryKey<TEndpoint> = Readonly<[TEndpoint]>;

export type ListQueryKey = Readonly<['list']>;
export type DetailsQueryKey = Readonly<['details']>;

export type SingletonDetailsQueryKeyRoot = Readonly<[...DetailsQueryKey, 'singleton']>;
export type SingletonDetailsQueryKeyBase<TEndpoint> = Readonly<[...SingletonDetailsQueryKeyRoot, TEndpoint]>;

export type SingletonListQueryKeyRoot = Readonly<[...ListQueryKey, 'singleton']>;
export type SingletonListQueryKeyBase<TEndpoint> = Readonly<[...SingletonListQueryKeyRoot, TEndpoint]>;

export type DetailsByIdQueryKeyRoot = Readonly<[...DetailsQueryKey, 'by-id']>;
export type DetailsByIdQueryKeyBase<TEndpoint> = Readonly<[...DetailsByIdQueryKeyRoot, TEndpoint]>;
export type DetailsByIdQueryKey<TEndpoint> = Readonly<[...DetailsByIdQueryKeyBase<TEndpoint>, string]>;

export type ListByIdsQueryKeyRoot = Readonly<[...ListQueryKey, 'by-ids']>;
export type ListByIdsQueryKeyBase<TEndpoint> = Readonly<[...ListByIdsQueryKeyRoot, TEndpoint]>;
export type ListByIdsQueryKey<TEndpoint> = Readonly<[...ListByIdsQueryKeyBase<TEndpoint>, string[]]>;

export type OdataQueryKeyRoot = Readonly<[...ListQueryKey, 'odata']>;
export type OdataQueryKeyBase<TEndpoint> = Readonly<[...OdataQueryKeyRoot, TEndpoint]>;
export type OdataQueryKey<TEndpoint, TQueryDto> = Readonly<[...OdataQueryKeyBase<TEndpoint>, Partial<QueryOptions<TQueryDto>>, QueryArgs]>;

export type OdataInfiniteQueryKeyRoot = Readonly<[...ListQueryKey, 'odata-infinite']>;
export type OdataInfiniteQueryKeyBase<TEndpoint> = Readonly<[...OdataInfiniteQueryKeyRoot, TEndpoint]>;
export type OdataInfiniteQueryKey<TEndpoint, TQueryDto> = Readonly<[...OdataInfiniteQueryKeyBase<TEndpoint>, Partial<QueryOptions<TQueryDto>>, QueryArgs]>;

export type IdsQueryKeyRoot = Readonly<[...ListQueryKey, 'ids']>;
export type IdsQueryKeyBase<TEndpoint> = Readonly<[...IdsQueryKeyRoot, TEndpoint]>;
export type IdsQueryKey<TEndpoint, TQueryDto extends { id: string }> = Readonly<[...IdsQueryKeyBase<TEndpoint>, Partial<QueryOptions<TQueryDto>>, QueryArgs]>;

export const singletonDetailsQueryKeyRoot = ['details', 'singleton'] as SingletonDetailsQueryKeyRoot;
export const singletonListQueryKeyRoot = ['list', 'singleton'] as SingletonListQueryKeyRoot;
export const odataQueryKeyRoot = ['list', 'odata'] as OdataQueryKeyRoot;
export const odataInfiniteQueryKeyRoot = ['list', 'odata-infinite'] as OdataInfiniteQueryKeyRoot;
export const idsQueryKeyRoot = ['list', 'ids'] as IdsQueryKeyRoot;

export const detailsByIdQueryKeyRoot = ['details', 'by-id'] as DetailsByIdQueryKeyRoot;
export const listByIdsQueryKeyRoot = ['list', 'by-ids'] as ListByIdsQueryKeyRoot;

export default function useDefaultEntityQueryKeys<TEndpoint extends QueryKeyBase>(endpoint: TEndpoint) {
  return useMemo(() => {
    const singletonDetailsQueryKey = [...singletonDetailsQueryKeyRoot, endpoint] as SingletonDetailsQueryKeyBase<TEndpoint>;
    const detailsByIdQueryKeyBase = [...detailsByIdQueryKeyRoot, endpoint] as DetailsByIdQueryKeyBase<TEndpoint>;
    const singletonListQueryKey = [...singletonListQueryKeyRoot, endpoint] as SingletonListQueryKeyBase<TEndpoint>;
    const listByIdsQueryKeyBase = [...listByIdsQueryKeyRoot, endpoint] as ListByIdsQueryKeyBase<TEndpoint>;
    const odataQueryKeyBase = [...odataQueryKeyRoot, endpoint] as OdataQueryKeyBase<TEndpoint>;
    const odataInfiniteQueryKeyBase = [...odataInfiniteQueryKeyRoot, endpoint] as OdataInfiniteQueryKeyBase<TEndpoint>;
    const idsQueryKeyBase = [...idsQueryKeyRoot, endpoint] as IdsQueryKeyBase<TEndpoint>;

    const detailsQueryKeyBases = {
      singletonDetailsQueryKey,
      detailsByIdQueryKeyBase,
    };
    const listQueryKeyBases = {
      singletonListQueryKey,
      listByIdsQueryKeyBase,
      odataQueryKeyBase,
      idsQueryKeyBase,
      odataInfiniteQueryKeyBase,
    };
    const queryKeyGetters = {
      getDetailsByIdQueryKey: (id: string) => [...detailsByIdQueryKeyBase, id] as DetailsByIdQueryKey<TEndpoint>,
      getListsByIdsQueryKey: (ids: string[]) => [...listByIdsQueryKeyBase, ids] as ListByIdsQueryKey<TEndpoint>,
      getOdataQueryKey: <TQueryDto>(odataQuery: Partial<QueryOptions<TQueryDto>>, args: QueryArgs = {}) => [...odataQueryKeyBase, odataQuery, args] as OdataQueryKey<TEndpoint, TQueryDto>,
      getOdataInfiniteQueryKey: <TQueryDto>(odataQuery: Partial<QueryOptions<TQueryDto>>, args: QueryArgs = {}) => [...odataInfiniteQueryKeyBase, odataQuery, args] as OdataInfiniteQueryKey<TEndpoint, TQueryDto>,
      getIdsQueryKey: <TQueryDto extends { id: string }>(odataQuery: Partial<QueryOptions<TQueryDto>>, args: QueryArgs = {}) => [...idsQueryKeyBase, odataQuery, args] as IdsQueryKey<TEndpoint, TQueryDto>,
    };
    const queryKeyBases = {
      ...detailsQueryKeyBases,
      ...listQueryKeyBases,
    };
    return {
      ...queryKeyBases,
      ...queryKeyGetters,
      detailsQueryKeyBases: Object.values(detailsQueryKeyBases),
      listQueryKeyBases: Object.values(listQueryKeyBases),
      queryKeyGetters: Object.values(queryKeyGetters),
      queryKeyBases: Object.values(queryKeyBases),
    };
  }, [endpoint]);
}
