import React, {
  forwardRef,
  useContext,
} from 'react';
import { EditorContent } from '@tiptap/react';
import RichTextEditorContext, { RichTextEditorContextState } from 'rich-text/contexts/RichTextEditorContext';

interface RichTextInputComponentProps {
  className?: string,
  disabled?: boolean,
}

export default forwardRef<HTMLDivElement, RichTextInputComponentProps>(({
  className,
  disabled,
}: RichTextInputComponentProps, ref) => {
  const { editor } = useContext<RichTextEditorContextState>(RichTextEditorContext);
  return (
    <div ref={ref} style={{ height: '100%', width: '100%' }}>
      <EditorContent editor={editor} className={className} disabled={disabled} style={{ height: '100%', overflow: 'auto', boxSizing: 'border-box' }} />
    </div>
  );
});
