enum RoleAction {
  // ActivityLog
  ActivityLog = 0,
  ActivityLog_Export = 1,
  // Project
  Project_Management = 100,
  Project_Copy = 101,
  Project_CollaboratorManagement = 102,
  Project_EditCollaborator = 103,
  Project_EditGroup = 104,
  Project_CreateGroups = 105,
  Project_DeleteGroups = 106,
  // Project_EditGroups = 107, deleted by migration DBM004ChangeEditRightToParent
  Project_Edit_2Fa = 108,
  Project_RoleManagement = 109,
  Project_Create_Roles = 110,
  // Project_Edit_Roles= 111, deleted by migration DBM004ChangeEditRightToParent
  Project_Delete_Roles = 112,
  Project_AssignToGroup = 113,
  Project_AssignRoles = 114,
  Project_AssignAdmin = 115,

  Project_Edit_DmsSettings = 116,
  // IssueManagement
  Issues = 200,
  Dashboard = 201,
  // IssueTimeLine = 202, deleted by migration DBM027RemoveIssueTimeline
  IssueManagement_Creation = 203,

  /// <summary>
  /// User can completely edit an issues except the status BUT is not allowed to edit closed issues.
  /// </summary>
  IssueManagement_Editing = 204,

  /// <summary>
  /// User can edit issues status BUT is not allowed to edit closed issues or set the status to closed.
  /// </summary>
  IssueManagement_StatusEditing = 205,
  IssueManagement_CreatingComments = 206,
  IssueManagement_BcfUpload = 207,
  IssueManagement_Export = 208,

  /// <summary>
  /// Assigned user can edit an issues except the status BUT is not allowed to edit closed issues.
  /// </summary>
  IssueManagement_Assigned_Editing = 209,

  /// <summary>
  /// Assigned user can edit issues status BUT is not allowed to edit closed issues or set the status to closed.
  /// </summary>
  IssueManagement_Assigned_StatusEditing = 210,

  /// <summary>
  /// Creator can edit an issues except the status BUT is not allowed to edit closed issues.
  /// </summary>
  IssueManagement_Creator_Editing = 211,

  /// <summary>
  /// Creator can edit issues status BUT is not allowed to edit closed issues or set the status to closed.
  /// </summary>
  IssueManagement_Creator_StatusEditing = 212,

  /// <summary>
  /// Reviewer can edit an issues except the status BUT is not allowed to edit closed issues.
  /// </summary>
  IssueManagement_Reviewer_Editing = 213,

  /// <summary>
  /// Reviewer can edit issues status BUT is not allowed to edit closed issues.
  /// </summary>
  IssueManagement_Reviewer_StatusEditing = 214,

  /// <summary>
  /// User can edit issues status TO closed, BUT is not allowed to change the status FROM closed to something else.
  /// </summary>
  IssueManagement_StatusEditing_SetClosed = 215,

  /// <summary>
  /// User can edit issues status FROM closed to something else, BUT is not allowed to change the status TO closed.
  /// </summary>
  IssueManagement_StatusEditing_UnsetClosed = 216,

  // Document
  Documents = 300,

  /// <summary>
  /// User can upload new documents.
  /// </summary>
  Document_Upload = 301,

  /// <summary>
  /// User can download documents.
  /// </summary>
  Document_Download = 302,

  /// <summary>
  /// User can edit the properties of a document.
  /// </summary>
  Document_Editing = 303,

  /// <summary>
  /// User can create sharelinks for documents.
  /// </summary>
  Document_Sharing = 304,

  /// <summary>
  /// User can create and delete comments.
  /// </summary>
  Document_CommentEditing = 305,

  /// <summary>
  /// User can archive (non-private) documents
  /// </summary>
  Document_Archiving = 306,

  /// <summary>
  /// User can access (non-private) archived documents
  /// </summary>
  Document_GetArchived = 307,

  /// <summary>
  /// User can restore (non-private) archived documents
  /// </summary>
  Document_RestoreArchived = 308,

  Document_Import = 310,
  /// <summary>
  /// User allow to run IFC import from document
  /// </summary>
  Document_IfcImport = 311,

  /// <summary>
  /// User allow to run 2D import from document
  /// </summary>
  Document_FloorPlanImport = 312,

  Document_StatusEditing = 313,

  // Folder

  /// <summary>
  /// User allowed to edit folder properties of folder where he is not the creator
  /// </summary>
  Folder_Edit = 400,

  /// <summary>
  /// User allowed to edit folder access list of folder where he is not the creator
  /// </summary>
  Folder_EditAccessList = 401,
  Folder_Create = 402,
  Folder_Delete = 403,

  // Labels
  Tag_Management = 500,
  Tag_Create = 501,
  // Tag_Update = 502, deleted by migration DBM004ChangeEditRightToParent
  Tag_Delete = 503,

  // Special Labels
  Label_Special = 550,
  Label_Special_Create = 551,
  // Label_Special_Update = 552, deleted by migration DBM004ChangeEditRightToParent
  Label_Special_Delete = 553,

  /// <summary>
  /// User able to create, edit naming scheme
  /// </summary>
  NamingScheme_Management = 800,
  NamingScheme_Create = 801,
  // NamingScheme_Update = 801, deleted by migration DBM004ChangeEditRightToParent
  NamingScheme_Delete = 803,

  DocumentList = 900,
  DocumentList_Create = 901,
  // DocumentList_Edit = 902, deleted by migration DBM004ChangeEditRightToParent
  /// <summary>
  /// User is able to delete public document lists
  /// </summary>
  DocumentList_Delete = 903,

  DocumentFilter = 950,
  DocumentFilter_Create = 951,
  // DocumentFilter_Edit = 952, deleted by migration DBM004ChangeEditRightToParent
  DocumentFilter_Delete = 953,

  /// <summary>
  /// User can change from any status to status "published"
  /// </summary>
  DocStatus_ChangeToPublished = 1103,

  /// <summary>
  /// User can change from status "published" to any other status
  /// </summary>
  DocStatus_ChangeFromPublished = 1104,

  /// <summary>
  /// User can enable, disable and configure document stamps (inserted on download)
  /// </summary>
  DocStamp_Edit = 1150,

  /// <summary>
  /// User can delete public smartViews created by different User.
  /// </summary>
  SmartView_DeleteFromDifferentCreator = 1300,

  /// <summary>
  /// User can rename inbox email address
  /// </summary>
  Inbox_Edit = 1400,

  _3DModel = 1500,
  _3DModel_Properties = 1501,
  _3DModel_Edit_Properties = 1502,
  _3DModel_3D_View = 1503,
  _3DModel_SmartView = 1504,

  _2DModel = 1600,

  Email_View = 1700,
  Email_Upload = 1701,

  // BCF
  BCF = 2000,
  BCF_Project_update = 2001,
  BCF_Project_createTopic = 2002,
  BCF_Project_createDocument = 2003,
  BCF_Topic_update = 2004,
  BCF_Topic_updateRelatedTopics = 2005,
  BCF_Topic_updateDocumentReferences = 2006,
  BCF_Topic_updateFiles = 2007,
  BCF_Topic_createComment = 2008,
  BCF_Topic_createViewpoint = 2009,
  BCF_Topic_delete = 2010,
  BCF_Viewpoint_delete = 2011,
  BCF_Comment_update = 2012,
  BCF_Comment_delete = 2013,
}

export default RoleAction;
