import CollaboratorGroupDto from 'collaborators-groups/types/CollaboratorGroupDto';
import CollaboratorDto from 'collaborators/types/CollaboratorDto';
import { createContext, Dispatch, SetStateAction } from 'react';
import ShareRecipientsSortMode from 'share/types/ShareRecipientsSortMode';

export interface ShareRecipientsFilterContextState {
  isFilterActive: boolean,
  searchString: string | undefined,
  setSearchString: Dispatch<SetStateAction<string | undefined>>,
  groupsFilterPredicate: (collaboratorGroup: CollaboratorGroupDto) => boolean,
  collaboratorsFilterPredicate: (collaborator: CollaboratorDto) => boolean,
  sortMode: ShareRecipientsSortMode,
  setSortMode: (mode: ShareRecipientsSortMode) => void,
}

const defaultValue: ShareRecipientsFilterContextState = {
  isFilterActive: false,
  searchString: undefined,
  setSearchString: () => {},
  groupsFilterPredicate: () => true,
  collaboratorsFilterPredicate: () => true,
  sortMode: ShareRecipientsSortMode.NameAsc,
  setSortMode: () => {},
};

const ShareRecipientsFilterContext = createContext(defaultValue);

export default ShareRecipientsFilterContext;
