import React from 'react';
import { Box } from '@mui/material';
import PageWrapper from 'common/components/PageWrapper';
import CollaboratorsPageHeader from 'collaborators/components/CollaboratorsPageHeader';
import CollaboratorsDataGrid from 'collaborators/components/CollaboratorsDataGrid';
import CollaboratorsSelectionContextProvider from 'collaborators/contexts/CollaboratorsSelectionContextProvider';
import CollaboratorsFilterContextProvider from 'collaborators/contexts/CollaboratorsFilterContextProvider';

export default function CollaboratorsPage() {
  return (
    <PageWrapper>
      <CollaboratorsSelectionContextProvider>
        <CollaboratorsFilterContextProvider>
          <Box sx={{
            display: 'grid',
            gridTemplateRows: '60px 1fr',
            height: '100%',
          }}
          >
            <CollaboratorsPageHeader />
            <CollaboratorsDataGrid />
          </Box>
        </CollaboratorsFilterContextProvider>
      </CollaboratorsSelectionContextProvider>
    </PageWrapper>
  );
}
