import {
  SET_IS_SAVE_ROLE_SETTING,
  SET_IS_SAVE_PROJECT_EMAIL_SETTING,
  SET_IS_SAVE_EMAIL_SETTING,
  SET_IS_SAVE_TAGS_SETTING,
  SET_IS_SAVE_ENCODING_SETTING,
  SET_IS_SAVE_DOCUMENTS_SETTING,
  SET_IS_SAVE_ISSUES_SETTING,
  SET_SIGNALR_CONNECTION_STATE,
  SET_MODEL_LEAVE_CONFIRM,
  SET_CANCEL_ISSUE_CREATION,
  SET_CONVERT_MODEL_PROGRESS,
  SET_SYNC_DATA,
  SET_FILTER_VALUES_FROM_OTHER_PAGE,
  SET_IS_CHATTING,
  SET_TO_IFC_FOLDER,
  SET_DOC_OPTION,
} from "./ActionTypes";

export const setIsSaveRoleSetting = (payload) => ({
  type: SET_IS_SAVE_ROLE_SETTING,
  payload,
});

export const setIsSaveProjectEmailSetting = (payload) => ({
  type: SET_IS_SAVE_PROJECT_EMAIL_SETTING,
  payload,
});

export const setIsSaveEmailSetting = (payload) => ({
  type: SET_IS_SAVE_EMAIL_SETTING,
  payload,
});

export const setIsSaveTagsSetting = (payload) => ({
  type: SET_IS_SAVE_TAGS_SETTING,
  payload,
});

export const setIsSaveEncodingSetting = (payload) => ({
  type: SET_IS_SAVE_ENCODING_SETTING,
  payload,
});

export const setIsSaveDocumentsSetting = (payload) => ({
  type: SET_IS_SAVE_DOCUMENTS_SETTING,
  payload,
});

export const setIsSaveIssueSetting = (payload) => ({
  type: SET_IS_SAVE_ISSUES_SETTING,
  payload,
});

export const setSignalRConnectionState = (payload) => ({
  type: SET_SIGNALR_CONNECTION_STATE,
  payload,
});

export const setModelLeaveConfirm = (payload) => ({
  type: SET_MODEL_LEAVE_CONFIRM,
  payload,
});

export const setCancelIssueCreation = (payload) => ({
  type: SET_CANCEL_ISSUE_CREATION,
  payload,
});

export const setConvertModelProgress = (payload) => ({
  type: SET_CONVERT_MODEL_PROGRESS,
  payload,
});

export const setSyncData = (payload) => ({
  type: SET_SYNC_DATA,
  payload,
});

export const setFilterValuesFromOtherPage = (payload) => ({
  type: SET_FILTER_VALUES_FROM_OTHER_PAGE,
  payload,
});

export const setIsChatting = (payload) => ({
  type: SET_IS_CHATTING,
  payload,
});

export const setToIFCFolder = (payload) => ({
  type: SET_TO_IFC_FOLDER,
  payload,
});

export const setDocOption = (payload) => ({
  type: SET_DOC_OPTION,
  payload,
});
