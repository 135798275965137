import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

export default function useChipVisibility(chipList: HTMLElement | null, chipListWrapper: HTMLElement | null, inputWrapper: HTMLElement | null, disabled?: boolean) {
  // detect and track which tags are visible and which are not, in order to display "+X"
  // references for a dynamic list of items, see https://react.dev/learn/manipulating-the-dom-with-refs#how-to-manage-a-list-of-refs-using-a-ref-callback
  const itemsMapRef = useRef<Map<string, HTMLDivElement> | null>(null);
  const getItemsMap = useCallback(() => {
    // Initialize the Map on first usage.
    if (!itemsMapRef.current) itemsMapRef.current = new Map<string, HTMLDivElement>();
    return itemsMapRef.current;
  }, []);
  const [visibleTagIdsSet, setVisibleTagIdsSet] = useState<ReadonlySet<string>>(() => new Set<string>());

  const intersectionObserver = useMemo(() => new IntersectionObserver((entries) => {
    setVisibleTagIdsSet((prev) => {
      const nextVisibleTagIdsSet = new Set<string>(Array.from(prev));
      entries.forEach((e) => {
        const tagId = e.target.getAttribute('data-id');
        if (tagId) {
          if (e.isIntersecting) nextVisibleTagIdsSet.add(tagId);
          else nextVisibleTagIdsSet.delete(tagId);
        }
      });
      return nextVisibleTagIdsSet;
    });
  }, { root: inputWrapper }), [inputWrapper]);

  useEffect(() => {
    if (disabled) return undefined;
    if (!itemsMapRef.current) return undefined;
    const itemNodes = Array.from(itemsMapRef.current.values());
    if (!itemNodes || !chipList || !inputWrapper) return undefined;
    itemNodes.forEach((n) => intersectionObserver.observe(n));
    return () => itemNodes.forEach((n) => intersectionObserver.unobserve(n));
  }, [chipList, disabled, inputWrapper, intersectionObserver]);

  const visibleTagsCount = useMemo(() => visibleTagIdsSet.size, [visibleTagIdsSet]);

  // detect and track if the control gets very small => don't show any tags but only the total count
  const [showOnlyCount, setShowOnlyCount] = useState(false);
  useEffect(() => {
    if (disabled) return undefined;
    if (!chipListWrapper) return undefined;
    const resizeObserver = new ResizeObserver(() => {
      setShowOnlyCount((prev) => {
        if (!chipListWrapper) return prev;
        if (!prev && chipListWrapper?.clientWidth < 12) return true;
        if (prev && chipListWrapper?.clientWidth > 26) return false;
        return prev;
      });
    });
    resizeObserver.observe(chipListWrapper);
    return () => resizeObserver.disconnect();
  }, [chipListWrapper, disabled]);

  const chipRefCallback = useCallback((id: string, node: HTMLDivElement | null) => {
    const map = getItemsMap();
    if (node && map) {
      map.set(id, node);
      intersectionObserver.observe(node); // when a new chip is selected, start observing it's visibility
    } else {
      map.delete(id);
    }
  }, [getItemsMap, intersectionObserver]);

  return useMemo(() => ({
    chipRefCallback,
    visibleTagsCount,
    showOnlyCount,
  }), [chipRefCallback, showOnlyCount, visibleTagsCount]);
}
