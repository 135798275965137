import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import FolderDto from 'documents-folders/types/FolderDto';
import FolderAccessUpdateDto from 'documents-folders/types/FolderAccessUpdateDto';
import useFolderTreeQueryKey from 'documents-folders/hooks/useFolderTreeQueryKey';

export default function useFolderUpdateAccessMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const folderTreeQueryKey = useFolderTreeQueryKey();
  return useMutation<FolderDto | undefined, unknown, FolderAccessUpdateDto>({
    mutationFn: async (dto: FolderAccessUpdateDto) => {
      const { data: folderDto } = await axiosInstance.put<FolderDto>(`${ApiEndpoint.Folder}/access`, dto);
      return folderDto;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: folderTreeQueryKey });
    },
  });
}
