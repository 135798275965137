import React, { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, AlertTitle, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Portal, Snackbar, Typography, useTheme } from '@mui/material';
import Color from 'color';
import { useDrop } from 'react-dnd';
import { ICellRendererParams } from '@ag-grid-community/core';
import FolderTreeGridRow, { RowType } from 'documents-folders/types/FolderTreeGridRow';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import useFolder from 'documents-folders/hooks/useFolder';
import useFolderIconName from 'documents-folders/hooks/useFolderIconName';
import useCurrentCollaboratorFolderAccessLevel from 'documents-folders/hooks/useCurrentCollaboratorFolderAccessLevel';
import CollaboratorFolderAccessLevel from 'documents-folders/types/CollaboratorFolderAccessLevel';
import useMoveDocumentVersionsToFolder from 'documents-folders/hooks/useMoveDocumentVersionsToFolder';
import DocumentVersionDragAndDropPayload from 'documents-folders/types/DocumentVersionDragAndDropPayload';
import FolderAccessType from 'documents-folders/types/FolderAccessType';

export default function FolderTreeCellRenderer({
  data,
  node,
}: ICellRendererParams<FolderTreeGridRow, string | undefined>) {
  const { t } = useTranslation('documents-folders');

  const accessTypeTranslations = useMemo(() => ({
    [FolderAccessType.Public]: t('folder-access-type_public', 'Public'),
    [FolderAccessType.Private]: t('folder-access-type_private', 'Private'),
    [FolderAccessType.Restricted]: t('folder-access-type_restricted', 'Restricted'),
  }), [t]);

  const theme = useTheme();
  const [, setForceRerenderFlip] = useState<boolean>(false);
  const folder = useFolder(data?.rowType === RowType.FolderRow ? data?.id : undefined);

  const {
    moveDocumentVersionsToFolder,
    errorMessage,
    clearErrorMessage,
    successMessage,
    clearSuccessMessage,
  } = useMoveDocumentVersionsToFolder();

  // AG Grid's way of passing data in here seems to not work well with react.
  // In short, this component does not re-render when node.expanded changes, and
  // its initial state cannot be trusted. That's why we subscribe to the expandedChanged external event
  // and then force re-rendering by calling setForceRerenderFlip. Not beautiful, but a required workaround.

  const forceRerender = useCallback(() => {
    setForceRerenderFlip((prev) => !prev);
  }, []);

  useEffect(() => {
    node.addEventListener('expandedChanged', forceRerender);
    return () => node.removeEventListener('expandedChanged', forceRerender);
  }, [forceRerender, node]);

  useEffect(() => {
    node.addEventListener('hasChildrenChanged', forceRerender);
    return () => node.removeEventListener('hasChildrenChanged', forceRerender);
  }, [forceRerender, node]);

  const wasOverRef = useRef<boolean>(false);
  const [isFolderCurrentlyDraggedOver, setIsFolderCurrentlyDraggedOver] = useState(false);

  const [, dropRef] = useDrop<DocumentVersionDragAndDropPayload>(() => ({
    accept: 'DocumentVersionDragAndDropPayload',
    drop: async ({ documentVersionIds }) => {
      if (folder) {
        moveDocumentVersionsToFolder(documentVersionIds, folder.id, folder.name);
      }
    },
    collect: (monitor) => {
      if (wasOverRef.current === monitor.isOver()) return;
      wasOverRef.current = monitor.isOver();
      setIsFolderCurrentlyDraggedOver(data?.rowType === RowType.FolderRow && monitor.isOver());
    },
  }), [moveDocumentVersionsToFolder, data, folder]);

  const label = useMemo(() => (folder ? folder.name : data && data.rowType === RowType.AccessTypeRow ? accessTypeTranslations[data.accessType] : ''), [folder, data, accessTypeTranslations]);
  const ExpandButtonIcon = node.isExpandable() ? (node.expanded ? ArrowDropDownIcon : ArrowRightIcon) : undefined;
  const indent = data?.rowType === RowType.FolderRow ? (node.level - 1) : 0;
  const countText = useMemo(() => {
    if (!folder) return undefined;
    if (!folder.documentCount && !folder.childFolderIds?.length) return t('empty', 'Empty');
    const counts: string[] = [];
    if (folder.documentCount) counts.push(`${t('n_documents', '{{count}} Documents', { count: folder.documentCount })}`);
    if (folder.childFolderIds?.length) counts.push(`${t('n_subfolders', '{{count}} Subfolders', { count: folder.childFolderIds.length })}`);
    return counts.join(' | ');
  }, [folder, t]);
  const folderIcon = useFolderIconName(folder?.accessType);
  const onClickExpandButton = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    node.setExpanded(!node.expanded);
    event.preventDefault();
  }, [node]);
  const accessLevel = useCurrentCollaboratorFolderAccessLevel(folder?.id);
  const backgroundColor = useMemo(() => {
    if (!isFolderCurrentlyDraggedOver) return 'unset';
    if (accessLevel === CollaboratorFolderAccessLevel.ReadWrite) return Color(theme.palette.secondary.light).lightness(98).hex();
    return Color(theme.palette.error.light).lightness(98).hex();
  }, [accessLevel, isFolderCurrentlyDraggedOver, theme.palette.error.light, theme.palette.secondary.light]);
  return (
    <Box
      id="FolderTreeCellRenderer"
      sx={{
        display: 'flex', pt: '7px', height: '100%', ml: indent * 4, backgroundColor,
      }}
      title={folder?.name}
      ref={dropRef}
    >
      <Box sx={{ width: 24, flexShrink: 0 }}>
        {!!ExpandButtonIcon && (
        <Button
          sx={{
            minWidth: 'revert',
            width: '16px',
            height: '16px',
            borderRadius: '8px',
            ml: '4px',
            mt: '8px',
            p: 0,
            background: 'transparent',
          }}
          variant="contained"
          color="secondary"
          onClick={onClickExpandButton}
        >
          <ExpandButtonIcon sx={{ width: 20, height: 20, color: data?.rowType === RowType.AccessTypeRow ? theme.palette.text.primary : theme.palette.text.secondary }} />
        </Button>
        )}
      </Box>
      {data?.rowType === RowType.FolderRow
      && (
      <>
        <Box sx={{
          width: '32px', height: '32px', flexShrink: 0, ml: 0.5,
        }}
        >
          {folderIcon && (
          <svg viewBox="0 -6 32 32">
            <use xlinkHref={`/img/sprite.svg#${folderIcon}`} />
          </svg>
          )}
        </Box>
        <Box sx={{ display: 'inline-grid', gridTemplateRows: 'auto 1fr', ml: 0.5 }}>
          <Typography
            title={folder?.name}
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              fontWeight: 600,
              color: !!folder && !folder.hasFolderAccess ? theme.palette.text.disabled : undefined,
            }}
          >
            {folder?.name}
          </Typography>
          {folder?.hasFolderAccess && (
            <Typography
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                fontSize: 12,
                color: theme.palette.text.secondary,
              }}
            >
              {countText}
            </Typography>
          )}
          {!!folder && !folder.hasFolderAccess && (
          <Typography sx={{
            fontSize: 12, color: theme.palette.text.disabled, mt: '2px', fontStyle: 'italic',
          }}
          >
            {t('folder-tree-cell-renderer_no-access', 'No Access')}
          </Typography>
          )}
        </Box>
      </>
      )}
      {data?.rowType === RowType.AccessTypeRow && <Typography sx={{ fontWeight: 700, mt: 1 }}>{label}</Typography>}
      {successMessage && (
      <Portal>
        <Snackbar open onClose={clearSuccessMessage} autoHideDuration={6000}>
          <Alert onClose={clearSuccessMessage} severity="success" sx={{ width: '100%' }}>
            <AlertTitle>{t('folder-tree-cell-renderer_success-title', 'Success')}</AlertTitle>
            {successMessage}
          </Alert>
        </Snackbar>
      </Portal>
      )}
      {errorMessage && (
      <Dialog open>
        <DialogTitle>{t('folder-tree-cell-renderer_error-title', 'Error')}</DialogTitle>
        <DialogContent>
          <Alert severity="error" sx={{ width: '100%' }}>{errorMessage}</Alert>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={clearErrorMessage}>{t('folder-tree-cell-renderer_error-close', 'Close')}</Button>
        </DialogActions>
      </Dialog>
      )}
    </Box>
  );
}
