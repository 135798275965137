import React, { useCallback, useMemo } from 'react';
import { useIssueFilterItems } from 'Helpers/Hooks';
import RoleAction from 'projects/types/RoleAction';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import PageWrapper from 'common/components/PageWrapper';
import { Box } from '@mui/material';
import DashboardPageHeader from 'dashboard/components/DashboardPageHeader';
import IssuesCalendar from 'issues/components/IssuesCalendar';
import DashboardSidebar from 'dashboard/components/DashboardSidebar';
import IssuesSelectionContext, { IssuesSelectionContextState } from 'issues/contexts/IssuesSelectionContext';
import { useNavigate, useParams } from 'react-router-dom';
import NotAllowed from 'error/NotAllowed';

export default function DashboardPage() {
  const allowedActions = useAllowedActions();
  const { data: currentProject } = useCurrentProjectQuery();
  const { issues } = useIssueFilterItems();

  const navigate = useNavigate();
  const { issueId } = useParams<'issueId'>();
  const selectedIssueIds = useMemo(() => (issueId ? [issueId] : []), [issueId]);
  const setSelectedIssueIds = useCallback((issueIds: string[]) => {
    if (issueIds.length > 1) throw new Error('multiselect is not supported here');
    if (issueIds.length === 0) {
      navigate('.');
    } else {
      navigate(`./issue/${issueIds[0]}`);
    }
  }, [navigate]);

  const selectionContextState = useMemo<IssuesSelectionContextState>(() => ({
    selectedIssueIds,
    setSelectedIssueIds,
    suppressMultiselect: true,
  }), [selectedIssueIds, setSelectedIssueIds]);

  if (!allowedActions || !currentProject || !issues) return <CenteredCircularProgress />;
  if (!allowedActions.has(RoleAction.Dashboard)) {
    if (allowedActions.has(RoleAction.Documents)) {
      navigate(`/projects/${currentProject.id}/documents`);
    } else if (allowedActions.has(RoleAction.Issues)) {
      navigate(`/projects/${currentProject.id}/issues`);
    } else if (allowedActions.has(RoleAction.Email_View)) {
      navigate(`/projects/${currentProject.id}/emails`);
    } else if (allowedActions.has(RoleAction._3DModel)) {
      navigate(`/projects/${currentProject.id}/models`);
    } else if (allowedActions.has(RoleAction.Project_CollaboratorManagement)) {
      navigate(`/projects/${currentProject.id}/collaborators`);
    } else if (allowedActions.has(RoleAction.Project_Management)) {
      navigate(`/projects/${currentProject.id}/settings`);
    } else {
      return <NotAllowed />;
    }
  }
  return (
    <PageWrapper>
      <IssuesSelectionContext.Provider value={selectionContextState}>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <DashboardPageHeader />
          <Box sx={{ flexGrow: 1, minHeight: 0, display: 'flex', boxShadow: 'inset 0px 0px 16px -8px rgba(0,0,0,0.2)' }}>
            <IssuesCalendar sx={{ flexGrow: 1, minWidth: 0 }} />
            <DashboardSidebar />
          </Box>
        </Box>
      </IssuesSelectionContext.Provider>
    </PageWrapper>
  );
}
