import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box, InputAdornment, TextField,
} from '@mui/material';
import RoundIconButton from 'common/components/RoundIconButton';
import ShareRecipientsFilterContext, { ShareRecipientsFilterContextState } from 'share/contexts/ShareRecipientsFilterContext';
import ShareRecipientsSortButtonMenu from 'share/components/ShareRecipientsSortButtonMenu';

export default function ShareRecipientsToolBar() {
  const { t } = useTranslation('share');
  const { isFilterActive, searchString, setSearchString } = useContext<ShareRecipientsFilterContextState>(ShareRecipientsFilterContext);

  return (
    <Box sx={{ display: 'flex' }}>
      <TextField
        label={t('share-recipients-toolbar_search-recipients', 'Search Recipients')}
        value={searchString ?? ''}
        onChange={(e) => setSearchString(e.target.value || undefined)}
        sx={{ flexGrow: 1 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isFilterActive
                ? <RoundIconButton Icon={CloseIcon} onClick={() => setSearchString(undefined)} />
                : <SearchIcon />}
            </InputAdornment>
          ),
        }}
      />
      <ShareRecipientsSortButtonMenu sx={{ ml: 1 }} />
    </Box>
  );
}
