import {
  Box3,
  Camera,
  MathUtils,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  MOUSE,
  Quaternion,
  Raycaster,
  Sphere,
  SphereBufferGeometry,
  Spherical,
  Vector2,
  Vector3,
  Vector4,
} from "three";
import CameraControls from "camera-controls";
import {
  IfcComponent,
  NavigationMode,
  NavigationModes,
} from "../../../../base-types";
import { LiteEvent } from "../../../../utils/LiteEvent";
import { IfcCamera } from "../camera";
import { IfcContext } from "../../context";
import { CameraProjections } from "../../..";

const subsetofTHREE = {
  MOUSE,
  Vector2,
  Vector3,
  Vector4,
  Quaternion,
  Matrix4,
  Spherical,
  Box3,
  Sphere,
  Raycaster,
  MathUtils: {
    DEG2RAD: MathUtils.DEG2RAD,
    clamp: MathUtils.clamp,
  },
};

const DEFAULT_CENTER_DISTANCE = 25;
const ROOM_DISTANCE_THRESHOLD_MIN = 2;
const ROOM_DISTANCE_THRESHOLD_MAX = 20;
const ROOM_CENTER_OF_ROTATION_FACTOR = 0.1;
const SPHERE_RADIUS = 0.02828;

export class MouseControl extends IfcComponent implements NavigationMode {
  enabled = false;

  readonly mode = NavigationModes.Mouse;
  onChange = new LiteEvent<Boolean>();
  readonly onUnlock = new LiteEvent();
  onChangeProjection = new LiteEvent<Camera>();

  private readonly sphere = new Mesh(
    new SphereBufferGeometry(1, 32, 32),
    new MeshBasicMaterial({
      color: 0xff0000,
      transparent: true,
      depthTest: false,
    })
  );

  private vec: Vector3 = new Vector3();
  private pos: Vector3 = new Vector3();
  private mousePressed = false;

  private readonly controls: CameraControls;
  private projection: CameraProjections;

  constructor(private context: IfcContext, private ifcCamera: IfcCamera) {
    super(context);
    CameraControls.install({ THREE: subsetofTHREE });
    this.controls = new CameraControls(
      this.ifcCamera.perspectiveCamera,
      context.getDomElement()
    );
    this.projection = CameraProjections.Perspective;

    this.setupControls();

    this.context.getScene().add(this.sphere);
    this.sphere.visible = false;
  }

  update(_delta: number): void {
    super.update(_delta);
    if (this.enabled) {
      this.controls.update(_delta);
    }
  }

  toggle(active: boolean) {
    if (this.enabled == active) return;
    this.enabled = active;
    this.controls.enabled = active;
    if (active) {
      const camera = this.ifcCamera.activeCamera;
      const target = new Vector3();
      camera.getWorldDirection(target);
      target.add(camera.position);
      this.setLookAt(camera.position, target);

      this.connect();
    } else {
      this.disconnect();
    }
  }

  setLookAt(
    position: Vector3,
    target: Vector3,
    enableTransition: boolean = false
  ) {
    this.controls.reset();
    this.controls.setLookAt(
      position.x,
      position.y,
      position.z,
      target.x,
      target.y,
      target.z,
      enableTransition
    );
  }

  setZoom(zoom:number){
    this.controls.zoom(zoom, true);
  }

  isLocked() {
    return this.mousePressed;
  }

  async fitToModel(sphere: Sphere) {
    await this.controls.fitToSphere(sphere, true);
  }

  setProjection(projection: CameraProjections) {
    this.projection = projection;
    this.controls.camera =
      projection == CameraProjections.Perspective
        ? this.ifcCamera.perspectiveCamera
        : this.ifcCamera.orthographicCamera;

    // fixes a bug with clipping when changing to orthographic mode while inside the model
    if (projection == CameraProjections.Orthographic) {
      const boundingSphere = new Sphere();
      this.context.boundingBox.getBoundingSphere(boundingSphere);
      this.controls.dollyTo(boundingSphere.radius * 2);
    }

    this.setMouseWheel();
  }

  private connect() {
    this.context
      .getDomElement()
      .addEventListener("mousedown", this.onMouseDown);
    this.context
      .getDomElement()
      .addEventListener("mousemove", this.onMouseMove);
    this.context.getDomElement().addEventListener("mouseup", this.onMouseUp);
    this.context.getDomElement().addEventListener("wheel", this.triggerEvent);

    this.controls.addEventListener("update", this.triggerEvent);
  }

  private disconnect() {
    this.context
      .getDomElement()
      .removeEventListener("mousedown", this.onMouseDown);
    this.context
      .getDomElement()
      .removeEventListener("mousemove", this.onMouseMove);
    this.context.getDomElement().removeEventListener("mouseup", this.onMouseUp);
    this.context
      .getDomElement()
      .removeEventListener("wheel", this.triggerEvent);

    this.controls.removeEventListener("update", this.triggerEvent);
  }

  private triggerEvent = () => {
    this.onChange?.trigger(true);
  };

  private onMouseDown = (e: MouseEvent) => {
    if (e.button == 0) {
      this.setOrbitPosition(e.offsetX, e.offsetY);
      this.mousePressed = true;
    }
  };

  private onMouseMove = () => {
    if (this.mousePressed) {
      this.showOrbitPoint(true);
    }
  };

  private onMouseUp = () => {
    this.showOrbitPoint(false);
    this.mousePressed = false;
  };

  private setOrbitPosition(x: number, y: number) {
    const item = this.context.castRayIfc();
    const camera = this.ifcCamera.activeCamera;
    if (item) {
      this.pos.copy(item.point);
      const distance = this.pos.distanceTo(camera.position);

      if (
        ROOM_DISTANCE_THRESHOLD_MIN < distance &&
        distance <= ROOM_DISTANCE_THRESHOLD_MAX
      ) {
        this.calculateNormalizeCameraToMousePointFarPlaneVector(x, y);
        this.pos
          .copy(camera.position)
          .add(
            this.vec.multiplyScalar(distance * ROOM_CENTER_OF_ROTATION_FACTOR)
          );
      }
    } else {
      this.calculateNormalizeCameraToMousePointFarPlaneVector(x, y);
      this.pos
        .copy(camera.position)
        .add(this.vec.multiplyScalar(DEFAULT_CENTER_DISTANCE));
    }

    this.sphere.position.copy(this.pos);
    this.controls.setOrbitPoint(this.pos.x, this.pos.y, this.pos.z);

    const scale =
      SPHERE_RADIUS * Math.sqrt(this.pos.distanceTo(camera.position));
    this.sphere.scale.x = scale;
    this.sphere.scale.y = scale;
    this.sphere.scale.z = scale;
  }

  private showOrbitPoint(visible: boolean) {
    if (this.sphere.visible == visible) return;
    this.sphere.visible = visible;
  }

  private calculateNormalizeCameraToMousePointFarPlaneVector(
    x: number,
    y: number
  ) {
    const size = this.context.getDimensions();
    this.vec.set((x / size.x) * 2 - 1, -(y / size.y) * 2 + 1, 0.5);

    const camera = this.ifcCamera.activeCamera;
    this.vec.unproject(camera);
    this.vec.sub(camera.position).normalize();
  }

  private setupControls() {
    this.controls.dampingFactor = 0.1;
    this.controls.infinityDolly = true;
    this.controls.dollyToCursor = true;
    this.controls.minDistance = 1;
    this.controls.maxDistance = 500;
    this.controls.dollySpeed = 1.5;

    this.controls.mouseButtons.right = CameraControls.ACTION.NONE;
    this.controls.mouseButtons.left = CameraControls.ACTION.ROTATE;
    this.controls.mouseButtons.middle = CameraControls.ACTION.TRUCK;
    this.setMouseWheel();

    this.controls.enabled = false;
  }

  private setMouseWheel() {
    this.controls.mouseButtons.wheel =
      this.projection == CameraProjections.Perspective
        ? CameraControls.ACTION.DOLLY
        : CameraControls.ACTION.ZOOM;
  }
}
