import React, { useMemo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';
import useProjectIssuePrioritiesQuery from 'issues/hooks/useProjectIssuePrioritiesQuery';
import useProjectIssueTypesQuery from 'issues/hooks/useProjectIssueTypesQuery';
import useProjectIssueStatusesQuery from 'issues/hooks/useProjectIssueStatusesQuery';
import LabelType from 'labels/types/LabelType';
import RoleAction from 'projects/types/RoleAction';
import LabelSettingsPanel from 'settings/components/LabelSettingsPanel';

export default function IssueSettingsTabPane() {
  const { t } = useTranslation('settings');
  const theme = useTheme();
  const allowedActions = useAllowedActions();
  const canCreate = useMemo(() => !!allowedActions && allowedActions.has(RoleAction.Label_Special_Create), [allowedActions]);
  const canEdit = useMemo(() => !!allowedActions && allowedActions.has(RoleAction.Label_Special), [allowedActions]);
  const canDelete = useMemo(() => !!allowedActions && allowedActions.has(RoleAction.Label_Special_Delete), [allowedActions]);
  const { data: issueTypes } = useProjectIssueTypesQuery();
  const { data: issuePriorities } = useProjectIssuePrioritiesQuery();
  const { data: issueStatuses } = useProjectIssueStatusesQuery();
  return (
    <Box id="IssueSettingsTabPane" sx={{ display: 'grid', gridTemplateRows: 'auto 1fr' }}>
      <Box sx={{ p: 4 }}>
        <Typography variant="h2">
          {t('issue-settings-tab-pane_title', 'Issue Settings')}
        </Typography>
      </Box>
      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 4, boxShadow: 'inset -4px 0px 16px -4px rgba(0,0,0,0.3)', backgroundColor: theme.palette.grey[300], p: 4 }}>
        <Box>
          <LabelSettingsPanel
            labelType={LabelType.IssueType}
            labels={issueTypes}
            canEdit={canEdit}
            canCreate={canCreate}
            canDelete={canDelete}
            variant="card"
          />
        </Box>
        <Box>
          <LabelSettingsPanel
            labelType={LabelType.IssuePriority}
            labels={issuePriorities}
            canEdit={canEdit}
            canCreate={canCreate}
            canDelete={canDelete}
            variant="card"
          />
        </Box>
        <Box>
          <LabelSettingsPanel
            labelType={LabelType.IssueStatus}
            labels={issueStatuses}
            canEdit={canEdit}
            canCreate={canCreate}
            canDelete={canDelete}
            variant="card"
          />
        </Box>
      </Box>
    </Box>
  );
}
