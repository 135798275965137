import ErrorDto from 'api/types/ErrorDto';
import axios from 'axios';
import { useCallback } from 'react';

function isErrorDto(error: any): error is ErrorDto {
  return 'message' in error
    && typeof error.message === 'string'
    && 'translatedMessage' in error
    && typeof error.translatedMessage === 'string'
    && (!('stackTrace' in error) || typeof error.stackTrace === 'string')
    && (!('details' in error) || typeof error.details === 'string');
}

export default function useRequestErrorMessage() {
  // errors can be anything, so we have to use the explicit any type
  return useCallback((error: any) => {
    if (axios.isAxiosError(error)) {
      if (error.response?.data && isErrorDto(error.response.data)) {
        if (error.response.data.details?.length) {
          return `${error.response.data.translatedMessage} (${error.response.data.details})`;
        }
        return error.response.data.translatedMessage;
      }
      if (!!error.response?.data?.errors && Array.isArray(error.response.data.errors) && error.response.data.errors.length > 0) {
        return error.response?.data?.errors.map((e: any) => `${e.errorMessage} (${e.propertyName})`).join(' \n');
      }
      return error.response?.data?.message ?? error.message ?? error;
    }
    if (typeof error?.message === 'string') return error.message;
    if (typeof error === 'string') return error;
    return undefined;
  }, []);
}
