import React, { useCallback, useMemo, useState } from 'react';
import { Box, Modal, Typography, useTheme } from '@mui/material';
import IssuesFilterContextProvider from 'issues/contexts/IssuesFilterContextProvider';
import IssuesSelectionContext, { IssuesSelectionContextState } from 'issues/contexts/IssuesSelectionContext';
import LinkExistingIssuePanel from 'issues/components/LinkExistingIssuePanel';
import IssuesFilterPanel from 'issues/components/IssuesFilterPanel';
import IssuesFilterSelect from 'issues/components/IssuesFilterSelect';
import CreateIssueButton from 'issues/components/CreateIssueButton';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';
import RoleAction from 'projects/types/RoleAction';

type LinkIssueModalProps = {
  title?: string | undefined,
  disabledIssueIds?: Set<string> | undefined,
  onClose: (value: string[]) => void,
  multiple?: boolean | undefined,
};

export default function LinkIssueModal({
  title,
  onClose,
  disabledIssueIds,
  multiple,
}: LinkIssueModalProps) {
  const theme = useTheme();
  const [queryFilterId, setQueryFilterId] = useState<string | undefined>();
  const [selectedIssueIds, setSelectedIssueIds] = useState<string[]>([]);
  const allowedActions = useAllowedActions();

  const issuesSelectionContextState = useMemo<IssuesSelectionContextState>(() => ({
    selectedIssueIds,
    setSelectedIssueIds,
    suppressMultiselect: !multiple,
    suppressSingleselect: multiple,
    multiselectActive: multiple,
  }), [multiple, selectedIssueIds]);

  const onConfirmCreateIssueDialog = useCallback((createdIssueId: string | undefined) => {
    if (createdIssueId) {
      if (multiple) {
        setSelectedIssueIds((prev) => (multiple ? Array.from(new Set([...prev, createdIssueId])) : [createdIssueId]));
      } else {
        onClose([createdIssueId]);
      }
    }
  }, [multiple, onClose]);

  return (
    <Modal id="LinkIssueModal" open disablePortal sx={{ height: '100%', display: 'flex', alignItems: 'stretch' }}>
      <Box sx={{
        flex: '1 1 0',
        m: 6,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.divider,
        borderRadius: 4,
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
      }}
      >
        <IssuesSelectionContext.Provider value={issuesSelectionContextState}>
          <IssuesFilterContextProvider queryFilterId={queryFilterId} setQueryFilterId={(setQueryFilterId)}>
            <Box sx={{ flex: '0 0 0', p: 2, px: 3, gap: 3, display: 'flex', alignItems: 'center' }}>
              <Typography variant="h3">{title ?? ''}</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
                <IssuesFilterSelect sx={{ maxWidth: 500, flexGrow: 1 }} />
              </Box>
              {!!allowedActions?.has(RoleAction.IssueManagement_Creation) && <CreateIssueButton onConfirmCreateIssueDialog={onConfirmCreateIssueDialog} />}
            </Box>
            <IssuesFilterPanel />
            <LinkExistingIssuePanel onClose={onClose} disabledIssueIds={disabledIssueIds} />
          </IssuesFilterContextProvider>
        </IssuesSelectionContext.Provider>
      </Box>
    </Modal>
  );
}
