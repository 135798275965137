import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export default function ExpandHorizontalIcon({
  htmlColor,
  fontSize,
}: Pick<SvgIconProps, 'htmlColor' | 'fontSize'>) {
  return (
    <SvgIcon htmlColor={htmlColor} fontSize={fontSize} viewBox="0 0 16 16">
      <path d="M 1.6,1.6 V 14.4 H 0 V 1.6 Z M 16,1.6 V 14.4 H 14.4 V 1.6 Z m -5.6,7.2 v 2.4 L 13.6,8 10.4,4.8 V 7.2 H 5.6 V 4.8 L 2.4,8 5.6,11.2 V 8.8 Z" />
    </SvgIcon>
  );
}
