import React, { useCallback, useMemo } from 'react';
import ChipMultiselect from 'common/components/ChipMultiselect';
import { useTheme } from '@mui/material';
import Color from 'color';
import useFileTypes from 'documents/hooks/useFileTypes';
import FileType from 'documents/types/FileType';

interface CollaboratorSelectProps {
  label: string,
  value: FileType[],
  onChange: (values: FileType[]) => void,
  disabled?: boolean,
}

export default function CollaboratorSelect({
  label,
  value,
  onChange,
  disabled,
}: CollaboratorSelectProps) {
  const theme = useTheme();
  const color = Color(theme.palette.grey[700]);
  const { fileTypes, getFileTypeTranslation } = useFileTypes();
  const entities = useMemo(() => fileTypes?.map((x) => ({ id: `${x}`, name: getFileTypeTranslation(x), colorParsed: color })), [color, fileTypes, getFileTypeTranslation]);
  const stringValue = useMemo(() => value.map((v) => `${v}`), [value]);
  const onChangeStrings = useCallback((nextValue: string[]) => onChange(nextValue.map((v) => parseInt(v, 10) as FileType)), [onChange]);

  return (
    <ChipMultiselect
      label={label}
      value={stringValue}
      onChange={onChangeStrings}
      disabled={disabled}
      entities={entities}
    />
  );
}
