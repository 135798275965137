import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import CollaboratorsDataGridRow from 'collaborators/types/CollaboratorsDataGridRow';
import { Link } from '@mui/material';

export default function EmailCell({
  data,
}: ICellRendererParams<CollaboratorsDataGridRow, string>) {
  if (!data) return null;
  return <Link href={`mailto:${data.email}`}>{data.email}</Link>;
}
