import { ComponentsProps, ComponentsVariants } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

interface MuiLinkComponent {
  defaultProps?: ComponentsProps['MuiLink'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiLink'];
  variants?: ComponentsVariants['MuiLink'];
}

const VisoLink: MuiLinkComponent = {
  styleOverrides: {
    root: () => ({
      textDecorationColor: 'unset',
    }),
  },
};

export default VisoLink;
