import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import axios from 'axios';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import { useCallback } from 'react';
import QueryFilterKind from 'query-filters/types/QueryFilterKind';
import QueryFilterEndpointMap from 'query-filters/types/QueryFilterEndpointMap';

export default function useQueryFilterDeleteMutation<TQueryFilterKind extends QueryFilterKind>(endpoint: QueryFilterEndpointMap[TQueryFilterKind]) {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases } = useDefaultEntityQueryKeys(endpoint);
  const mutationFn = useCallback(async (queryFilterId: string) => {
    try {
      await axiosInstance.delete(`${endpoint}/${queryFilterId}`);
      return undefined;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 400) return error.response.data?.errorMessages[0]?.errorMessage;
      return undefined;
    }
  }, [axiosInstance, endpoint]);
  const onSuccess = useCallback(() => {
    queryKeyBases.forEach((queryKey) => queryClient.invalidateQueries({ queryKey }));
  }, [queryClient, queryKeyBases]);
  return useMutation({
    mutationFn,
    onSuccess,
  });
}
