import type { Theme, ComponentsProps, ComponentsVariants } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import Color from 'color';

interface MuiAlertComponent {
  defaultProps?: ComponentsProps['MuiAlert'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiAlert'];
  variants?: ComponentsVariants['MuiAlert'];
}

const white = Color.rgb(255, 255, 255);

const VisoAlert: MuiAlertComponent = {
  styleOverrides: {
    root: () => ({
    }),
    standardSuccess: ({ theme }) => ({
      backgroundColor: white.mix(Color(theme.palette.success.main), 0.15).hex(),
    }),
    standardInfo: ({ theme }) => ({
      backgroundColor: white.mix(Color(theme.palette.info.main), 0.15).hex(),
    }),
    standardError: ({ theme }) => ({
      backgroundColor: white.mix(Color(theme.palette.error.main), 0.15).hex(),
    }),
    standardWarning: ({ theme }) => ({
      backgroundColor: white.mix(Color(theme.palette.warning.main), 0.15).hex(),
    }),
  },
};

export default VisoAlert;
