import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import IssueDto from 'issues/types/IssueDto';

export default function EmailDateCell({
  value,
}: ICellRendererParams<IssueDto, string | undefined>) {
  const date = useMemo(() => {
    if (!value) return undefined;
    return `${new Date(value).toLocaleDateString('de-DE')}`;
  }, [value]);
  return (
    <Box id="DateCell">
      {date}
    </Box>
  );
}
