import React, { useCallback, useContext, useMemo } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Checkbox } from '@mui/material';
import CollaboratorsSelectionContext, { CollaboratorsSelectionContextState } from 'collaborators/contexts/CollaboratorsSelectionContext';
import CollaboratorsDataGridRow from 'collaborators/types/CollaboratorsDataGridRow';

export default function CheckboxCell({
  data,
}: ICellRendererParams<CollaboratorsDataGridRow, string>) {
  const entityId = data?.id;
  const { selectedCollaboratorIds, setSelectedCollaboratorIds } = useContext<CollaboratorsSelectionContextState>(CollaboratorsSelectionContext);
  const selectedCollaboratorIdsSet = useMemo(() => new Set(selectedCollaboratorIds), [selectedCollaboratorIds]);
  const selected = useMemo(() => !!entityId && selectedCollaboratorIdsSet.has(entityId), [entityId, selectedCollaboratorIdsSet]);
  const onClick = useCallback(() => {
    if (!entityId) return;
    if (selected) setSelectedCollaboratorIds(selectedCollaboratorIds.filter((id) => id !== entityId));
    else setSelectedCollaboratorIds([...selectedCollaboratorIds, entityId]);
  }, [entityId, selected, selectedCollaboratorIds, setSelectedCollaboratorIds]);
  return <Checkbox checked={selected} sx={{ ml: 3, mb: '2px' }} onClick={onClick} />;
}
