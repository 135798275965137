import FileType from 'documents/types/FileType';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const fileTypes = [
  FileType.Video,
  FileType.Audio,
  FileType.Image,
  FileType.Word,
  FileType.PowerPoint,
  FileType.Pdf,
  FileType.Ifc,
  FileType.Excel,
  FileType.DWG,
  FileType.DXF,
  FileType.ArchiCAD,
  FileType.Revit,
  FileType.SMC,
  FileType.Navisworks,
  FileType.Misc,
];

const tArgsByFileType = new Map<FileType, [string, string]>([
  [FileType.Video, ['filetype_Video', 'Video']],
  [FileType.Audio, ['filetype_Audio', 'Audio']],
  [FileType.Image, ['filetype_Image', 'Image']],
  [FileType.Word, ['filetype_Word', 'Word']],
  [FileType.PowerPoint, ['filetype_PowerPoint', 'PowerPoint']],
  [FileType.Pdf, ['filetype_Pdf', 'Pdf']],
  [FileType.Ifc, ['filetype_Ifc', 'Ifc']],
  [FileType.Excel, ['filetype_Excel', 'Excel']],
  [FileType.DWG, ['filetype_DWG', 'DWG']],
  [FileType.DXF, ['filetype_DXF', 'DXF']],
  [FileType.ArchiCAD, ['filetype_ArchiCAD', 'ArchiCAD']],
  [FileType.Revit, ['filetype_Revit', 'Revit']],
  [FileType.SMC, ['filetype_SMC', 'SMC']],
  [FileType.Navisworks, ['filetype_Navisworks', 'Navisworks']],
  [FileType.Misc, ['filetype_Misc', 'Misc']],
]);

export default function useFileTypes() {
  const { t } = useTranslation('documents');
  const getFileTypeTranslation = useCallback((fileType: FileType) => {
    const [translationKey, placeholder] = tArgsByFileType.get(fileType)!;
    return t(translationKey, placeholder);
  }, [t]);
  return { getFileTypeTranslation, fileTypes };
}
