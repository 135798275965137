import React from 'react';
import {
  Box, SxProps, Theme, Typography, useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

interface DocumentDropOverlayProps {
  sx?: SxProps<Theme>,
}

export default function DocumentDropOverlay({
  sx,
}: DocumentDropOverlayProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box sx={{
      display: 'flex',
      backgroundColor: theme.palette.background.default,
      padding: 2,
      ...sx,
    }}
    >
      <Box sx={{
        borderWidth: '1px',
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        alignSelf: 'stretch',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <CloudUploadIcon fontSize="large" sx={{ color: theme.palette.primary.main }} />
        <Typography sx={{ fontSize: '1rem', fontWeight: 700 }}>{t('drag_and_drop_files', 'Drag and drop files')}</Typography>
      </Box>
    </Box>
  );
}

DocumentDropOverlay.defaultProps = {
  sx: undefined,
};
