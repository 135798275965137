import { useCallback } from 'react';
import ValidationApiResponse from 'upload/types/ValidationApiResponse';
import DocumentUploadPayloadCandidate from 'upload/types/DocumentUploadPayloadCandidate';
import useAxiosInstance from 'api/hooks/useAxiosInstance';

export default function useValidateDocumentUploads() {
  const axiosInstance = useAxiosInstance();
  const validateUploadPayloadCandidate = useCallback(async (uploadPayloadCandidate: DocumentUploadPayloadCandidate) => {
    if (!uploadPayloadCandidate.files.length) {
      return {
        precheckResults: [],
        allSuccess: false,
      };
    }
    const response = await axiosInstance.post<ValidationApiResponse>(`/document/check?folderId=${uploadPayloadCandidate.folderId}`, uploadPayloadCandidate.files.map((f) => f.name));
    let result: ValidationApiResponse;
    if (response.status === 200 && response.data) {
      result = {
        precheckResults: response.data.precheckResults,
        allSuccess: response.data.allSuccess,
      };
    } else {
      result = {
        precheckResults: uploadPayloadCandidate.files.map((file) => ({
          fileName: file.name,
          namingSchemeResults: [],
          success: false,
          predictedVersion: -1,
          predictedNamingScheme: '',
        })),
        allSuccess: false,
      };
    }
    return result;
  }, [axiosInstance]);
  return { validateUploadPayloadCandidate };
}
