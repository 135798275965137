import {
  BufferGeometry,
  DoubleSide,
  LineBasicMaterial,
  LineSegments,
  Material,
  MeshLambertMaterial,
  Plane,
} from "three";
import {
  GlobalIdsMap,
  IdAttrName,
  IfcState,
  Normal,
  Position,
  SELECT_ID,
  VisoNode,
} from "./BaseDefinitions";
import { BvhManager } from "./BvhManager";
import { IFCModel } from "./IFCModel";
import { VisoSolidMesh } from "./VisoSolideMesh";
import { ExpressIdMap, GeometryAttributes, SubsetUtils } from "./SubsetUtils";

const selectMat: Material = new MeshLambertMaterial({
  color: 0x92aaff,
  opacity: 0.8,
  transparent: true,
  side: DoubleSide,
});
const wireMat: Material = new LineBasicMaterial({
  color: 0x0000ff,
  depthTest: false,
  transparent: true,
});

export class PickSubset {
  selectedGlobalIds: GlobalIdsMap = {};
  private pickModel: IFCModel | null = null;

  constructor(private state: IfcState, private BVH: BvhManager) { }

  getModel() {
    return this.pickModel;
  }

  setClippingPlanes(planes: Plane[]) {
    selectMat.clippingPlanes = planes.length ? planes : null;
    wireMat.clippingPlanes = planes.length ? planes : null;
  }

  reset() {
    this.selectedGlobalIds = {};
    if (this.pickModel) {
      this.pickModel.globalIds = {};
      this.pickModel.visible = false;
      if (this.pickModel.solid) {
        this.pickModel.solid.geometry.setIndex([0]);
      }
    }
  }

  dispose() {
    this.selectedGlobalIds = {};
    if (this.pickModel) {
      this.pickModel.dispose();
      this.pickModel = null;
    }
  }

  processSelection(
    modelId: string,
    globalIds: string[],
    ctrl: boolean = false
  ) {
    this.refactorGlobalIds(modelId, globalIds, ctrl);

    if (Object.keys(this.selectedGlobalIds).length) {
      this.setGeometry();
    } else {
      if (this.pickModel) {
        this.pickModel.visible = false;
        if (this.pickModel.solid) {
          this.pickModel.solid.geometry.setIndex([0]);
        }
      }
    }

    return this.pickModel;
  }

  getModelData() {
    let components: Array<VisoNode> = [];
    const modelfileIds: Array<string> = [];
    Object.keys(this.selectedGlobalIds).forEach((key) => {
      const fileId = this.state.models[key].modelFileId;
      if (fileId) modelfileIds.push(fileId);
      this.selectedGlobalIds[key].forEach((globalId) => {
        const node = this.getElementInfo(globalId, key);
        if (node)
          components.push(node);
      });
    });

    return { components, modelfileIds };
  }

  handleIfcVisibleComponent(id: string, opening: boolean, space: boolean) {
    if (this.selectedGlobalIds[id]) {
      let items = this.selectedGlobalIds[id];
      if (!opening) {
        const openingIds = this.state.models[id].openingIds;
        items = items.filter((i) => !openingIds.includes(i));
      }

      if (!space) {
        const spaceIds = this.state.models[id].spaceIds;
        items = items.filter((i) => !spaceIds.includes(i));
      }

      if (items.length) {
        this.selectedGlobalIds[id] = items;
      } else {
        delete this.selectedGlobalIds[id];
      }

      this.setGeometry();
    }
  }

  refreshSubset() {
    if (Object.keys(this.selectedGlobalIds).length) {
      this.setGeometry();
    }
  }

  getAllSelectedIds() {
    const selectedIds = this.selectedGlobalIds;
    let result = new Set<string>();
    Object.keys(selectedIds).forEach((key) => {
      selectedIds[key].forEach((i) => result.add(i));
    });

    return result;
  }

  removeModels(modelIds: string[]) {
    modelIds.forEach((mId) => {
      if (this.selectedGlobalIds[mId]) {
        delete this.selectedGlobalIds[mId];
      }
    });

    this.setGeometry();
  }

  private setGeometry() {
    SubsetUtils.resetExpressId();

    const attributes: GeometryAttributes[] = [];
    Object.keys(this.selectedGlobalIds).forEach((modelId) => {
      const attribute = SubsetUtils.getGeometryAttributes(
        this.state,
        modelId,
        this.selectedGlobalIds[modelId],
        true
      );

      if (attribute)
        attributes.push(attribute);
    });

    if (attributes.length) {
      const attribute = SubsetUtils.mergeGeometryAttributes(attributes);
      if (this.pickModel == null) {
        this.pickModel = new IFCModel();
        this.pickModel.modelFileId = SELECT_ID;
        this.state.models[SELECT_ID] = this.pickModel;
      }

      if (this.pickModel.solid == null) {
        this.pickModel.solid = new VisoSolidMesh(
          new BufferGeometry(),
          selectMat,
          SELECT_ID
        );
        this.pickModel.solid.frustumCulled = false;
        this.pickModel.add(this.pickModel.solid);
      }

      this.pickModel.solid.geometry.setAttribute(Position, attribute.position);
      this.pickModel.solid.geometry.setAttribute(Normal, attribute.normal);
      this.pickModel.solid.geometry.setAttribute(
        IdAttrName,
        attribute.expressId
      );
      this.pickModel.solid.geometry.setIndex(attribute.index);

      if (this.BVH) this.BVH.applyThreeMeshBVH(this.pickModel.solid.geometry);

      if (this.pickModel.wireframe == null) {
        this.pickModel.wireframe = new LineSegments(
          new BufferGeometry(),
          wireMat
        );
        this.pickModel.wireframe.frustumCulled = false;
        this.pickModel.add(this.pickModel.wireframe);
      }

      this.pickModel.wireframe.geometry.setAttribute(
        Position,
        attribute.position
      );
      this.pickModel.wireframe.geometry.setIndex(attribute.wireframe);

      this.pickModel.visible = true;

      this.setGlobalIdsMap(attribute.expressIdMap);
    } else {
      if (this.pickModel) this.pickModel.visible = false;

      this.selectedGlobalIds = {};
    }
  }

  private refactorGlobalIds(
    modelId: string,
    globalIds: string[],
    ctrl: boolean
  ) {
    if (ctrl) {
      const item = this.selectedGlobalIds[modelId];
      if (item) {
        globalIds.forEach((gId) => {
          const index = item.indexOf(gId);
          if (index === -1) {
            item.push(gId);
          } else {
            item.splice(index, 1);
          }
        });

        if (item.length === 0) {
          delete this.selectedGlobalIds[modelId];
        }
      } else {
        this.selectedGlobalIds[modelId] = globalIds;
      }
    } else {
      this.selectedGlobalIds = { [modelId]: globalIds };
    }
  }

  private setGlobalIdsMap(expressIdMap: ExpressIdMap) {
    if (this.pickModel) {
      this.pickModel.globalIds = expressIdMap;
    }
  }

  private getElementInfo(globalId: string, id: string) {
    return this.state.modelData[id].nodeData[globalId];
  }
}
