import React from 'react';
import {
  Button, SvgIconTypeMap, SxProps, Theme, useTheme,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

interface RoundIconButtonProps {
  sx?: SxProps<Theme>,
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>,
  disabled?: boolean,
}

export default function RoundIconButton({
  sx,
  onClick,
  Icon,
  disabled,
}: RoundIconButtonProps) {
  const theme = useTheme();
  return (
    <Button
      sx={{
        minWidth: 'revert',
        width: '25px',
        height: '25px',
        p: 0,
        borderRadius: '10px',
        backgroundColor: disabled ? `${theme.palette.grey[100]} !important` : theme.palette.secondary.light,
        ...sx,
      }}
      variant="contained"
      color="secondary"
      onClick={onClick}
      disabled={disabled}
    >
      <Icon sx={{ width: '15px', height: '15px', color: disabled ? theme.palette.grey[400] : theme.palette.primary.main }} />
    </Button>
  );
}

RoundIconButton.defaultProps = {
  sx: undefined,
  onClick: undefined,
};
