import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DraggableNamingSchemeGroupPanel from 'naming-schemes/components/DraggableNamingSchemeGroupPanel';
import NamingSchemeGroupItem from 'naming-schemes/types/NamingSchemeGroupItem';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import { useDrop } from 'react-dnd';
import NamingSchemeGroupsReorderDropIndicator from 'naming-schemes/components/NamingSchemeGroupsReorderDropIndicator';

interface NamingSchemeGroupsReorderDialogProps {
  groupItems: NamingSchemeGroupItem[] | undefined,
  onClose: (reorderedGroupItems: NamingSchemeGroupItem[] | undefined) => void,
}

export default function NamingSchemeGroupsReorderDialog({
  groupItems,
  onClose,
}: NamingSchemeGroupsReorderDialogProps) {
  const { t } = useTranslation('naming-schemes');
  const theme = useTheme();
  const onClickCancel = useCallback(() => onClose(undefined), [onClose]);
  const [formState, setFormState] = useState<NamingSchemeGroupItem[] | undefined>(groupItems);
  useEffect(() => setFormState((prev) => prev ?? groupItems), [groupItems]);
  const onClickConfirm = useCallback(() => {
    onClose(formState);
  }, [formState, onClose]);
  const [targetIndex, setTargetIndex] = useState<number | undefined>(undefined);
  const onHoverGroupPanel = useCallback((index: number | undefined) => {
    setTargetIndex(index);
  }, []);
  const onDragEnd = useCallback(() => {
    setTargetIndex(undefined);
  }, []);
  const onDrop = useCallback((dragIndex: number) => {
    if (!formState || targetIndex === undefined) return;
    const toIndex = dragIndex > targetIndex ? targetIndex : targetIndex - 1;
    const nextGroupItems = [...formState];
    const element = nextGroupItems[dragIndex];
    nextGroupItems.splice(dragIndex, 1);
    nextGroupItems.splice(toIndex, 0, element);
    setFormState(nextGroupItems);
  }, [formState, targetIndex]);
  const [{ isHovered }, drop] = useDrop<unknown, unknown, { isHovered: boolean }>(() => ({
    accept: 'DraggableNamingSchemeGroupPanel',
    collect: (monitor) => ({
      isHovered: monitor.isOver({ shallow: false }),
    }),
  }), []);
  return (
    <Dialog open id="NamingSchemeGroupsReorderDialog" PaperProps={{ sx: { maxWidth: 'calc(100% - 64px)' } }}>
      <DialogTitle>{t('naming-scheme-groups-reorder-dialog_title', 'Reorder Encoding Scheme Groups')}</DialogTitle>
      <DialogContent>
        {!formState && <CenteredCircularProgress />}
        {!!formState && (
          <Box sx={{ backgroundColor: theme.palette.grey[300], py: 2, px: 1, borderRadius: '16px', boxShadow: 'inset 0 0 16px -4px rgba(0,0,0,0.3)', display: 'flex', overflow: 'hidden' }}>
            <Box
              sx={{ display: 'flex', justifyContent: 'flex-start', overflow: 'auto', gap: 0.5 }}
              ref={drop}
            >
              {formState.map((groupItem, index) => (
                <Fragment key={groupItem.uuid}>
                  <NamingSchemeGroupsReorderDropIndicator visible={targetIndex === index && isHovered} />
                  <DraggableNamingSchemeGroupPanel groupIndex={index} groupItem={groupItem} onHover={onHoverGroupPanel} onDrop={onDrop} onDragEnd={onDragEnd} />
                </Fragment>
              ))}
              <NamingSchemeGroupsReorderDropIndicator visible={targetIndex === formState.length && isHovered} />
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ gap: 2, justifyContent: 'space-between' }}>
        <Button variant="contained" color="secondary" onClick={onClickCancel}>
          {t('naming-scheme-groups-reorder-dialog_cancel-button-label', 'Cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={onClickConfirm}>
          {t('naming-scheme-groups-reorder-dialog_confirm-button-label', 'Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
