import { SetStateAction, useCallback, useState } from 'react';

/** Custom alternative to useState where the setter returns a promise that resolves when all side effects of setting the new value have been completed. Source: https://stackoverflow.com/a/74550344/1078146 */
export default function useAsyncState<T>(initialState: T): [state: T, asyncSetState: (value: SetStateAction<T>) => Promise<T>] {
  const [state, setState] = useState<T>(initialState);
  const asyncSetState = useCallback((value: SetStateAction<T>) => new Promise((resolve: (value: T) => void) => {
    setState(value);
    setState((current) => {
      resolve(current);
      return current;
    });
  }), []);
  return [state, asyncSetState];
}
