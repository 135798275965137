import {
  SET_CURRENT_USER_ROLE,
} from "./ActionTypes";

const projects = (state = [], action) => {
  switch (action.type) {
    case SET_CURRENT_USER_ROLE:
      return { ...state, current_user_role: action.payload };

    default:
      return state;
  }
};

export default projects;
