import React, { ChangeEvent, KeyboardEvent, useCallback } from 'react';
import { Box, GlobalStyles } from '@mui/material';
import ISxProps from 'common/types/ISxProps';

interface OtpInputProps extends ISxProps {
  value: string,
  onChange: (next: string) => void,
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void,
}

export default function OtpInput({
  sx,
  value,
  onChange,
  onKeyDown,
}: OtpInputProps) {
  const onChangeOtp = useCallback((event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value), [onChange]);
  return (
    <Box
      id="OtpInput"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pl: '30px',
        ...sx,
      }}
    >
      <Box className="otp-input-wrapper">
        <GlobalStyles styles={{
          '.otp-input-wrapper': {
            display: 'inline-block',
            input: {
              padding: 0,
              width: '275px',
              fontSize: '32px',
              fontWeight: 600,
              color: '#3e3e3e',
              backgroundColor: 'transparent',
              border: 0,
              letterSpacing: '27px',
              fontFamily: 'monospace !important',
              marginLeft: '2px',
              '&:focus': {
                boxShadow: 'none',
                outline: 'none',
              },
            },
            svg: {
              position: 'relative',
              display: 'block',
              width: '247px',
              height: '2px',
            },
          },
        }}
        />
        <input
          value={value}
          onChange={onChangeOtp}
          autoFocus
          name="otp-input"
          autoComplete="one-time-code"
          type="text"
          onKeyDown={onKeyDown}
          maxLength={6}
        />
        <svg viewBox="0 0 247 1" xmlns="http://www.w3.org/2000/svg">
          <line x1="0" y1="0" x2="247" y2="0" stroke="#3e3e3e" strokeWidth="2" strokeDasharray="24,20.5" />
        </svg>
      </Box>
    </Box>
  );
}
