import { Box, Paper } from '@mui/material';
import IssuePrintDataGrid from 'issues/components/IssuePrintDataGrid';
import PrintPreviewFooter from 'issues/components/PrintPreviewFooter';
import PrintPreviewHeader from 'issues/components/PrintPreviewHeader';
import IssuePrintContext from 'issues/contexts/IssuePrintContext';
import IssueDto from 'issues/types/IssueDto';
import React, { useContext, useMemo } from 'react';

interface IssueTablePrintPreviewProps {
  issues: IssueDto[],
  pageNumber: number,
}

export default function IssueTablePrintPreview({ issues, pageNumber }: IssueTablePrintPreviewProps) {
  const { issuesDocument } = useContext(IssuePrintContext);
  const height = useMemo(() => 1000 - issuesDocument.headerHeight, [issuesDocument.headerHeight]);
  return (
    <Paper
      sx={{ width: '210mm',
        height: '297mm',
        overflow: 'hidden',
        p: 2,
        '@media print': {
          width: '210mm',
          height: '297mm',
          pageBreakInside: 'avoid',
          pageBreakAfter: 'always',
        } }}
      elevation={3}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%', height: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <PrintPreviewHeader />
          <Box sx={{ height, width: '200mm' }}>
            <IssuePrintDataGrid rowData={issues} pageNumber={pageNumber} />
          </Box>
        </Box>
        <PrintPreviewFooter pageNumber={pageNumber + 1} />
      </Box>
    </Paper>
  );
}
