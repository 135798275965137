import React, { useCallback, useMemo, useState } from 'react';
import IssueLinkDocumentsDialog from 'issues/components/IssueLinkDocumentsDialog';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import DocumentSelectionContext, { DocumentSelectionContextState } from 'documents/contexts/DocumentSelectionContext';
import useDocumentVersionIdsQuery from 'documents/hooks/useDocumentVersionIdsQuery';

interface IssueSelectLinkedDocumentsDialogProps {
  issueId?: string | undefined,
  onClose: (selectedDocumentVersionIds: string[] | undefined) => void,
}

export default function IssueSelectLinkedDocumentsDialog({
  issueId,
  onClose,
}: IssueSelectLinkedDocumentsDialogProps) {
  const { data: issue } = useIssueQuery(issueId);
  const [selectedDocumentVersionIds, setSelectedDocumentVersionIds] = useState<string[]>(issue?.linkedDocumentVersionIds ?? []);
  const selectedDocumentVersionIdsSet = useMemo(() => new Set(selectedDocumentVersionIds), [selectedDocumentVersionIds]);
  const { data: linkedDocumentsDocumentVersionIds } = useDocumentVersionIdsQuery(issue ? { filter: { normalizedName: { in: issue.linkedDocuments } } } : undefined);
  const nonSelectableVersionIds = useMemo(() => {
    if (!linkedDocumentsDocumentVersionIds) {
      if (issueId) return undefined;
      return new Set<string>();
    }
    return new Set<string>(linkedDocumentsDocumentVersionIds.filter((id) => !selectedDocumentVersionIdsSet.has(id)));
  }, [issueId, linkedDocumentsDocumentVersionIds, selectedDocumentVersionIdsSet]);
  const nonDeselectableVersionIds = useMemo(() => new Set<string>(issue?.linkedDocumentVersionIds ?? []), [issue]);

  const onCloseIssueLinkDocumentsDialog = useCallback((confirmed: boolean) => onClose(confirmed ? selectedDocumentVersionIds : undefined), [onClose, selectedDocumentVersionIds]);

  const resetSelection = useCallback(() => {
    setSelectedDocumentVersionIds(issue?.linkedDocumentVersionIds ?? []);
  }, [issue?.linkedDocumentVersionIds]);

  const documentSelectionContextValue = useMemo<DocumentSelectionContextState>(() => ({
    selectedDocumentVersionIds,
    selectedDocumentVersionIdsSet,
    setSelectedDocumentVersionIds,
    nonDeselectableVersionIds,
    nonSelectableVersionIds,
    resetSelection,
  }), [selectedDocumentVersionIds, selectedDocumentVersionIdsSet, nonDeselectableVersionIds, nonSelectableVersionIds, resetSelection]);

  return (
    <DocumentSelectionContext.Provider value={documentSelectionContextValue}>
      <IssueLinkDocumentsDialog onClose={onCloseIssueLinkDocumentsDialog} />
    </DocumentSelectionContext.Provider>
  );
}
