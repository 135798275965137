import React, { useCallback, useState } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Alert, CircularProgress, Portal, Snackbar } from '@mui/material';
import CollaboratorsDataGridRow from 'collaborators/types/CollaboratorsDataGridRow';
import CollaboratorRoleEditSelect from 'collaborators/components/CollaboratorRoleEditSelect';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useCurrentCollaboratorRole from 'collaborators/hooks/useCurrentCollaboratorRole';
import RoleAction from 'projects/types/RoleAction';
import useCollaboratorUpdateMutation from 'collaborators/hooks/useCollaboratorUpdateMutation';
import CollaboratorUpdateDto from 'collaborators/types/CollaboratorUpdateDto';

export default function RoleCell({
  data,
}: ICellRendererParams<CollaboratorsDataGridRow, string>) {
  const [roleIdDisplayValue, setRoleIdDisplayValue] = useState(data?.roleId);
  const currentUserRole = useCurrentCollaboratorRole();
  const { mutateAsync, isPending: isLoadingMutation } = useCollaboratorUpdateMutation();
  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const onChangeSelect = useCallback(async (nextId: string) => {
    if (!roleIdDisplayValue || !data) return;
    try {
      setRoleIdDisplayValue(nextId);
      const persistDto: CollaboratorUpdateDto = {
        collaboratorId: data.id,
        roleId: nextId,
      };
      await mutateAsync(persistDto);
    } catch (error) {
      setRoleIdDisplayValue(data.roleId);
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [data, getRequestErrorMessage, mutateAsync, roleIdDisplayValue]);
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  if (!currentUserRole?.allowedActions?.has(RoleAction.Project_AssignRoles) && !currentUserRole?.isAdmin) return data?.role?.name;
  return (
    <>
      <CollaboratorRoleEditSelect value={roleIdDisplayValue} onChange={onChangeSelect} disabled={isLoadingMutation} />
      {isLoadingMutation && <CircularProgress size={12} sx={{ ml: 1 }} />}
      <Portal>
        <Snackbar
          open={!!errorMessage}
          autoHideDuration={6000}
          onClose={onCloseErrorMessage}
        >
          <Alert onClose={onCloseErrorMessage} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>
      </Portal>
    </>
  );
}
