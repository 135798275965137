import type { Theme, ComponentsProps, ComponentsVariants } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

interface MuiSelectComponent {
  defaultProps?: ComponentsProps['MuiSelect'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiSelect'];
  variants?: ComponentsVariants['MuiSelect'];
}

const VisoSelect: MuiSelectComponent = {
  styleOverrides: {
    outlined: {
      paddingTop: '10px',
    },
  },
};

export default VisoSelect;
