import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import { useTranslation } from 'react-i18next';

const numberRegex = /^[0-9]+$/;

interface MutateNamingSchemeCharactersGroupPanelProps extends ISxProps {
  title: string,
  length: number | undefined,
  onChange: (value: number | undefined) => void,
}

export default function MutateNamingSchemeCharactersGroupPanel({
  sx,
  title,
  length,
  onChange,
}: MutateNamingSchemeCharactersGroupPanelProps) {
  const { t } = useTranslation('naming-schemes');
  const [lengthInputValue, setLengthInputValue] = useState<string>(`${length ?? ''}`);
  const lengthValidationErrorMessage = useMemo(() => (
    (!!lengthInputValue?.length && !lengthInputValue.match(numberRegex)?.length) || (parseInt(lengthInputValue, 10) === 0)
      ? t('mutate-naming-scheme-characters-group-panel_length-number-validation-error', 'Must be a positive number')
      : undefined
  ), [lengthInputValue, t]);
  const onChangeLength = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setLengthInputValue(event.target.value);
    if (event.target.value.match(numberRegex)?.length) {
      onChange(parseInt(event.target.value, 10));
    } else {
      onChange(undefined);
    }
  }, [onChange]);
  return (
    <Box id="MutateNamingSchemeCharactersGroupPanel" sx={{ display: 'flex', flexDirection: 'column', gap: 3, ...sx }}>
      <Typography variant="h5">{title}</Typography>
      <TextField
        label={t('mutate-naming-scheme-characters-group-panel_length-textfield-label', 'Length')}
        id="mutate-naming-scheme-characters-group-panel_length-textfield"
        value={lengthInputValue}
        onChange={onChangeLength}
        error={!!lengthValidationErrorMessage}
        helperText={lengthValidationErrorMessage}
      />
    </Box>
  );
}
