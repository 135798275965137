import FileType from 'documents/types/FileType';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type FileTypeDisplayNamesByFileType = {
  [P in FileType]?: string;
};

export default function useDisplayNamesByFileType() {
  const { t } = useTranslation('documents');
  return useMemo<FileTypeDisplayNamesByFileType>(() => ({
    [FileType.Video]: t('use-display-names-by-file-type_file-type-video', 'Video'),
    [FileType.Audio]: t('use-display-names-by-file-type_file-type-audio', 'Audio'),
    [FileType.Image]: t('use-display-names-by-file-type_file-type-image', 'Image'),
    [FileType.Word]: t('use-display-names-by-file-type_file-type-word', 'Word'),
    [FileType.PowerPoint]: t('use-display-names-by-file-type_file-type-powerpoint', 'PowerPoint'),
    [FileType.Pdf]: t('use-display-names-by-file-type_file-type-pdf', 'PDF'),
    [FileType.Ifc]: t('use-display-names-by-file-type_file-type-ifc', 'IFC'),
    [FileType.Excel]: t('use-display-names-by-file-type_file-type-excel', 'Excel'),
    [FileType.DWG]: t('use-display-names-by-file-type_file-type-dwg', 'DWG'),
    [FileType.DXF]: t('use-display-names-by-file-type_file-type-dxf', 'DXF'),
    [FileType.ArchiCAD]: t('use-display-names-by-file-type_file-type-archicad', 'ArchiCAD'),
    [FileType.Revit]: t('use-display-names-by-file-type_file-type-revit', 'Revit'),
    [FileType.SMC]: t('use-display-names-by-file-type_file-type-smc', 'SMC'),
    [FileType.Navisworks]: t('use-display-names-by-file-type_file-type-navisworks', 'Navisworks'),
    [FileType.Misc]: t('use-display-names-by-file-type_file-type-misc', 'Miscellaneous'),
  }), [t]);
}
