import React, { useCallback, useState } from 'react';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import { useTranslation } from 'react-i18next';
import useCurrentUserLanguageMutation from 'users/hooks/useCurrentUserLanguageMutation';
import LangType from 'users/types/LangType';

interface LanguageToggleButtonGroupProps extends ISxProps {
}

export default function LanguageToggleButtonGroup({
  sx,
}: LanguageToggleButtonGroupProps) {
  const { i18n } = useTranslation();
  const [isLoadingLanguageChange, setIsLoadingLanguageChange] = useState(false);
  const { mutateAsync } = useCurrentUserLanguageMutation();
  const onChangeToggleButtonGroup = useCallback(async (event: React.MouseEvent<HTMLElement>, value: 'de-DE' | 'en-US') => {
    setIsLoadingLanguageChange(true);
    try {
      await Promise.all([
        i18n.changeLanguage(value),
        mutateAsync({ langType: value === 'de-DE' ? LangType.German : LangType.English }),
      ]);
    } finally {
      setIsLoadingLanguageChange(false);
    }
  }, [i18n, mutateAsync]);
  return (
    <ToggleButtonGroup
      id="LanguageSwitch"
      sx={{ ...sx }}
      size="small"
      fullWidth
      value={i18n.language}
      exclusive
      onChange={onChangeToggleButtonGroup}
      disabled={isLoadingLanguageChange}
    >
      <ToggleButton value="de-DE">
        DE
      </ToggleButton>
      <ToggleButton value="en-US">
        EN
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
