import { RefObject, useEffect, useRef } from 'react';

type UseResubscribingEventSubscriptionProps<T extends RefObject<HTMLElement> | Document, K extends (T extends HTMLElement ? keyof HTMLElementEventMap : keyof DocumentEventMap)> = [
  target: T | null,
  type: K,
  listener: (this: HTMLElement, ev: (K extends keyof HTMLElementEventMap ? HTMLElementEventMap[K] : DocumentEventMap[K])) => any,
];

/**
 * This hook re-subscribes the passed listener callback when it changes due to an updated dependency and unsubscribes the old callback.
 */
export default function useResubscribingEventSubscription<T extends RefObject<HTMLElement> | Document, K extends (T extends HTMLElement ? keyof HTMLElementEventMap : keyof DocumentEventMap)>(...[target, type, listener]: UseResubscribingEventSubscriptionProps<T, K>) {
  const previousSubscriptionAbortController = useRef<AbortController>();
  useEffect(() => {
    if (!target) return;
    if (previousSubscriptionAbortController.current) {
      previousSubscriptionAbortController.current.abort();
    }
    previousSubscriptionAbortController.current = new AbortController();
    const targetObject = target instanceof Document ? target : target.current;
    targetObject?.addEventListener(type, listener as any, { signal: previousSubscriptionAbortController.current.signal });
  }, [listener, target, type]);
}
