import React from 'react';
import { Button } from '@mui/material';
import useDocumentFilterContext from 'documents/hooks/useDocumentFilterContext';
import FilterIcon from 'icons/FilterIcon';
import { useTranslation } from 'react-i18next';

export default function FilterDrawerToggleButton() {
  const { t } = useTranslation('documents-filter');
  const { setIsFilterDrawerOpen } = useDocumentFilterContext();
  return (
    <Button
      id="FilterDrawerToggleButton"
      variant="contained"
      color="secondary"
      startIcon={<FilterIcon />}
      sx={{ mr: 4 }}
      onClick={() => setIsFilterDrawerOpen((prev) => !prev)}
    >
      {t('filter-drawer-toggle-button-label', 'Filter')}
    </Button>
  );
}
