import React, { useCallback, useMemo } from 'react';
import ISxProps from 'common/types/ISxProps';
import DateRangePicker from 'common/components/DateRangePicker';
import DateFilterSettingsDto from 'query-filters/types/DateFilterSettingsDto';
import DateRange from 'common/types/DateRange';
import DateRangeKind from 'common/types/DateRangeKind';

interface DateFilterPanelProps extends ISxProps {
  value: DateFilterSettingsDto | undefined,
  onChange: (value: DateFilterSettingsDto | undefined) => void,
}

export default function DateFilterPanel({
  sx,
  value,
  onChange,
}: DateFilterPanelProps) {
  const dateRangePickerValue = useMemo<DateRange | undefined>(() => {
    if (!value) return undefined;
    if (value.timeFrame !== undefined) {
      return {
        dateRangeKind: DateRangeKind.Relative,
        timeFrame: value.timeFrame,
      };
    }
    return {
      dateRangeKind: DateRangeKind.Custom,
      from: value.after ? new Date(value.after) : undefined,
      to: value.before ? new Date(value.before) : undefined,
    };
  }, [value]);
  const onChangeDateRangePicker = useCallback((nextValue: DateRange | undefined) => {
    if (!nextValue) return;
    if (nextValue.dateRangeKind === DateRangeKind.Relative) {
      onChange({
        timeFrame: nextValue.timeFrame,
      });
    } else if (nextValue.from || nextValue.to) {
      onChange({
        after: nextValue.from?.toISOString(),
        before: nextValue.to?.toISOString(),
      });
    }
  }, [onChange]);
  return (
    <DateRangePicker
      id="DateFilterPanel"
      sx={sx}
      value={dateRangePickerValue}
      onChange={onChangeDateRangePicker}
      hideAny
    />
  );
}
