import React, { ReactNode } from 'react';
import { SxProps, Theme } from '@mui/material/styles';
import { Box } from '@mui/material';

interface InlineTypographyProps {
  sx?: SxProps<Theme>,
  children?: ReactNode,
}

export default function InlineTypography({
  sx,
  children,
}: InlineTypographyProps) {
  return (
    <Box
      sx={{
        ...sx,
        display: 'inline',
      }}
      component="span"
    >
      {children}
    </Box>
  );
}

InlineTypography.defaultProps = {
  sx: undefined,
  children: undefined,
};
