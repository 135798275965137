import React from 'react';
import { Box, Typography } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import { useTranslation } from 'react-i18next';
import IssueLabelsDonutChart from 'dashboard/components/IssueLabelsDonutChart';
import LabelType from 'labels/types/LabelType';

interface DashboardStatisticsPanelProps extends ISxProps {
}

export default function DashboardStatisticsPanel({
  sx,
}: DashboardStatisticsPanelProps) {
  const { t } = useTranslation('dashboard');
  return (
    <Box id="DashboardStatisticsPanel" sx={{ ...sx, minHeight: 0, overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h4" sx={{ p: 2 }}>{t('dashboard-statistics-panel_title', 'Statistics')}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8, py: 2 }}>
        <IssueLabelsDonutChart labelType={LabelType.IssueStatus} title={t('dashboard-statistics-panel_status-count-donut-title', 'Issue Counts by Status')} />
        <IssueLabelsDonutChart labelType={LabelType.IssueType} title={t('dashboard-statistics-panel_type-count-donut-title', 'Issue Counts by Type')} />
        <IssueLabelsDonutChart labelType={LabelType.IssuePriority} title={t('dashboard-statistics-panel_priority-count-donut-title', 'Issue Counts by Priority')} />
      </Box>
    </Box>
  );
}
