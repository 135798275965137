import type { Theme, ComponentsProps, ComponentsVariants } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

interface MuiDialogActionsComponent {
  defaultProps?: ComponentsProps['MuiDialogActions'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiDialogActions'];
  variants?: ComponentsVariants['MuiDialogActions'];
}

const VisoDialogActions: MuiDialogActionsComponent = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontSize: theme.typography.fontSize,
      paddingBottom: '28px',
      paddingLeft: '32px',
      paddingRight: '32px',
    }),
  },
  defaultProps: {
    disableSpacing: true,
  },
};

export default VisoDialogActions;
