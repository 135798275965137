import React, {
  Suspense, useCallback, useEffect, useState,
} from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import TagCreateSelect from 'tags/components/TagCreateSelect';
import { CircularProgress } from '@mui/material';
import InboxEmailDto from 'emails/types/InboxEmailDto';
import InboxEmailUpdateTagsDto from 'emails/types/InboxEmailUpdateTagsDto';
import useInboxEmailUpdateTagsMutation from 'emails/hooks/useInboxEmailUpdateTagsMutation';

export default function EmailTagsCell({
  data, value,
}: ICellRendererParams<InboxEmailDto, string[] | undefined>) {
  const [displayValue, setDisplayValue] = useState<string[] | undefined>(value ?? undefined);
  const { mutateAsync } = useInboxEmailUpdateTagsMutation();

  const onChange = useCallback(async (nextSelectedTagNames: string[]) => {
    if (!data) return;
    setDisplayValue(nextSelectedTagNames);
    const persistDto: InboxEmailUpdateTagsDto = {
      id: data.id,
      tags: nextSelectedTagNames,
    };
    await mutateAsync(persistDto);
  }, [data, mutateAsync]);

  useEffect(() => setDisplayValue(value ?? []), [value]);

  return (
    <Suspense fallback={<CircularProgress size={12} />}>
      <TagCreateSelect
        id="TagsCellCreateSelect"
        value={displayValue}
        onChange={onChange}
        sx={{ width: '100%' }}
      />
    </Suspense>
  );
}
