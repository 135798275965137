import * as Request from "../Helpers/Request";
import i18n from "i18next";

export const workphaseList = [
  { value: null, label: i18n.t("all", "All") },
  { value: 21, label: "LPH 1" },
  { value: 22, label: "LPH 2" },
  { value: 23, label: "LPH 3" },
  { value: 24, label: "LPH 4" },
  { value: 25, label: "LPH 5" },
  { value: 26, label: "LPH 6" },
  { value: 27, label: "LPH 7" },
  { value: 28, label: "LPH 8" },
  { value: 29, label: "LPH 9" },
];

export const WorkphaseByValue = (workphases) =>
  workphases.reduce(
    (byValue, workphase) => ({ ...byValue, [workphase.value]: workphase }),
    {}
  );

export const getWorkphaseKinds = () => {
  return [
    { name: i18n.t("hoai", "HOAI 2021"), value: 0 },
    { name: i18n.t("sia", "SIA 102"), value: 2 },
    { name: i18n.t("riba", "RIBA Plan of Work"), value: 1 },
    { name: i18n.t("none_work_stages", "None"), value: 3 },
  ];
};

export const getWorkphases = async (ids) => {
  const result = await Request.GET(`api/workPhase/${ids.join(",")}`);
  return result.status === 200 ? result.body : [];
};
