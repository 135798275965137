import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import InboxEmailDto from 'emails/types/InboxEmailDto';

export default function EmailDateCell({
  value,
}: ICellRendererParams<InboxEmailDto, string | undefined>) {
  const creationDate = useMemo(() => {
    if (!value) return undefined;
    return `${new Date(value).toLocaleDateString('de-DE')} / ${new Date(value).toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })}`;
  }, [value]);
  return (
    <Box id="EmailCreationDateCell">
      {creationDate}
    </Box>
  );
}
