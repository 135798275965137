import React, { useContext, useRef, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import IssuePrintContext from 'issues/contexts/IssuePrintContext';

export default function PrintPreviewHeader() {
  const { issuesDocument } = useContext(IssuePrintContext);
  const { headerTitle, leftPageHead, rightPageHead } = issuesDocument;
  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!componentRef.current) return;
    issuesDocument.headerHeight = componentRef.current.offsetHeight;
  }, [issuesDocument]);

  return (
    <Box id="PrintPreviewHeader" ref={componentRef}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', whiteSpace: 'pre-wrap' }}>
          {leftPageHead}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', whiteSpace: 'pre-wrap' }}>
          {rightPageHead}
        </Box>
      </Box>
      <Typography sx={{ textAlign: 'center' }} variant="h2">{headerTitle}</Typography>
    </Box>
  );
}
