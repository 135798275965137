import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import { useCallback, useMemo } from 'react';

export default function useSingletonDetailsQuery<TEndpoint extends ApiEndpoint, TDto>(
  endpoint: TEndpoint,
  options?: Omit<UseQueryOptions<TDto, unknown, TDto, QueryKey>, 'queryKey' | 'queryFn'> | undefined,
) {
  const axiosInstance = useAxiosInstance();
  const { singletonDetailsQueryKey: queryKey } = useDefaultEntityQueryKeys(endpoint);
  const queryFn = useCallback(async () => {
    const { data } = await axiosInstance.get<TDto>(`/${endpoint}`);
    return data;
  }, [axiosInstance, endpoint]);
  const queryOptions = useMemo<UseQueryOptions<TDto, unknown, TDto, QueryKey>>(() => ({
    ...options,
    queryFn,
    queryKey,
    enabled: (options?.enabled !== false) && (queryKey.length > 0),
  }), [options, queryFn, queryKey]);
  return useQuery(queryOptions);
}
