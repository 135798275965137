import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import useCurrentProjectId from 'projects/hooks/useCurrentProjectId';
import UploadingEmailFileHandle from 'emails/types/UploadingEmailFileHandle';
import useEmailUploadHandler from 'emails/hooks/useEmailUploadHandler';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import EmailsUploadContext, { EmailsUploadContextState } from 'emails/contexts/EmailsUploadContext';
import EmailsUploadPayload from 'emails/types/EmailsUploadPayload';
import EmailsUploadWizardModal from 'emails/components/EmailsUploadWizard/EmailsUploadWizardModal';
import useValidEmailFileExtensions from 'emails/hooks/useValidEmailFileExtensions';

interface EmailsUploadContextProviderProps {
  children: ReactNode,
}

export default function EmailsUploadContextProvider({
  children,
}: EmailsUploadContextProviderProps) {
  const currentProjectId = useCurrentProjectId();
  const validFileExtensions = useValidEmailFileExtensions();
  const [stagedPayload, setStagedPayload] = useState<EmailsUploadPayload | null>(null);
  const [uploadingFileHandles, setUploadingFileHandles] = useState<UploadingEmailFileHandle[]>([]);
  const [isWizardVisible, setIsWizardVisible] = useState(false);
  const showWizard = () => setIsWizardVisible(true);
  const hideWizard = () => setIsWizardVisible(false);
  const handleUploadEmails = useEmailUploadHandler();
  const queryClient = useQueryClient();
  const { queryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.InboxEmail);
  const clear = useCallback(() => {
    if (uploadingFileHandles?.length) {
      uploadingFileHandles.forEach((handle) => {
        if (!handle.isCompleted) {
          handle.request.abort();
        }
      });
    }
    setUploadingFileHandles([]);
    setStagedPayload(null);
    hideWizard();
  }, [uploadingFileHandles]);

  useEffect(() => {
    if (!uploadingFileHandles?.length) return;
    // clear upload state when leaving/switching projects
    if (!currentProjectId || uploadingFileHandles.findIndex((h) => currentProjectId !== h.projectId) > -1) {
      clear();
    }
  }, [currentProjectId, uploadingFileHandles, clear]);

  const state = useMemo<EmailsUploadContextState>(() => ({
    stagedPayload,
    uploadingFileHandles,
    addToEmailsStagedForUpload: (payload: EmailsUploadPayload) => {
      setStagedPayload((currentlyStagedPayload) => {
        const {
          files: filesToStage,
          fileNamesThatAreFolders,
        } = payload;
        const currentFiles = currentlyStagedPayload?.files ?? [];

        // make sure we don't upload two files with the same name in the same upload process
        const currentFileNames = new Set(currentFiles.map((file) => file.name));
        const filesToStageNotAlreadyStaged = filesToStage.filter((file) => !currentFileNames.has(file.name));
        const files = [...currentFiles, ...filesToStageNotAlreadyStaged];
        const fileNamesThatAreInvalid = files.filter((file) => !validFileExtensions.some((ext) => file.name.endsWith(ext))).map((file) => file.name);
        return {
          ...currentlyStagedPayload,
          files,
          fileNamesThatAreFolders,
          fileNamesThatAreInvalid,
        };
      });
    },
    startUploadingStagedPayload: () => {
      if (!stagedPayload) return;
      // whenever one of the uploads triggers a progress event, touch the handle array to trigger a re-render of all consumers
      const onProgress = () => setUploadingFileHandles((currentUploadHandles) => Array.from(currentUploadHandles));
      const onFinish = () => {
        queryKeyBases.forEach((queryKey) => queryClient.invalidateQueries({ queryKey }));
      };
      const newUploadHandles = handleUploadEmails(stagedPayload, onProgress, onFinish);
      setUploadingFileHandles((currentUploadHandles) => [...currentUploadHandles, ...newUploadHandles]);
      setStagedPayload(null);
    },
    discardStagedFiles: (fileNames: string[]) => {
      const fileNamesSet = new Set<string>(fileNames);
      setStagedPayload((payload) => {
        if (!payload) return null;
        return {
          ...payload,
          files: payload?.files.filter((file) => !fileNamesSet.has(file.name)),
          fileNamesThatAreInvalid: payload?.fileNamesThatAreInvalid?.filter((fileName) => !fileNamesSet.has(fileName)),
        };
      });
    },
    openWizard: showWizard,
    clear,
  }), [stagedPayload, uploadingFileHandles, clear, validFileExtensions, handleUploadEmails, queryKeyBases, queryClient]);

  return (
    <EmailsUploadContext.Provider value={state}>
      {children}
      {isWizardVisible && <EmailsUploadWizardModal hideWizard={hideWizard} />}
    </EmailsUploadContext.Provider>
  );
}
