import React, { useCallback, useState } from 'react';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import LabelType from 'labels/types/LabelType';
import useLabelUpdateOrderMutation from 'labels/hooks/useLabelUpdateOrderMutation';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useLabelsOdataQuery from 'labels/hooks/useLabelsOdataQuery';
import { useTranslation } from 'react-i18next';

export enum ReorderLabelsDialogResult {
  Cancelled,
  Updated,
}

enum LabelSortMode {
  NameAsc = 'NameAsc',
  NameDesc = 'NameDesc',
}

interface ReorderLabelDialogProps {
  onClose: (Result: ReorderLabelsDialogResult) => void;
  labelType: LabelType;
}

export default function ReorderLabelsDialog({ onClose, labelType }: ReorderLabelDialogProps) {
  const { t } = useTranslation('settings');
  const { mutateAsync: updateAsync } = useLabelUpdateOrderMutation();

  const { data: labels } = useLabelsOdataQuery({ filter: { type: LabelType[labelType], isDeleted: false }, orderBy: 'order asc' });

  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const [sortMode, setSortMode] = useState('');

  const handleOrderChange = useCallback((e: SelectChangeEvent) => {
    const newSortMode = e.target.value as LabelSortMode;
    if (!labels) return;
    switch (newSortMode) {
      case LabelSortMode.NameAsc:
        labels.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }));
        break;
      case LabelSortMode.NameDesc:
        labels.sort((a, b) => b.name.localeCompare(a.name, undefined, { numeric: true, sensitivity: 'base' }));
        break;
      default:
        break;
    }
    setSortMode(newSortMode);
  }, [labels]);

  const onSave = useCallback(() => {
    if (!labels) return;
    try {
      updateAsync({ type: labelType, labelIds: labels.map((l) => l.id) });
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
    onClose(ReorderLabelsDialogResult.Updated);
  }, [getRequestErrorMessage, labelType, labels, onClose, updateAsync]);

  const onClickCancel = useCallback(() => onClose(ReorderLabelsDialogResult.Cancelled), [onClose]);

  return (
    <Dialog id="ReorderLabelsDialog" open>
      <DialogTitle>{t('reorder-label-dialog_title', 'Reorder')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('reorder-label-dialog_helper-text', 'Select how you want the items to be ordered.')}
        </DialogContentText>
      </DialogContent>
      <DialogActions id="ReorderLabelsDialogSelect">
        <FormControl fullWidth>
          <InputLabel>{t('reorder-label-dialog_reorder-dropdown-label', 'Order')}</InputLabel>
          <Select
            value={sortMode}
            onChange={handleOrderChange}
            label={t('reorder-label-dialog_reorder-dropdown-label', 'Order')}
          >
            <MenuItem value={LabelSortMode.NameAsc}>
              {t('reorder-label-dialog_order-alphabetically-asc', 'Order alphabetically ascending (A-Z)')}
            </MenuItem>
            <MenuItem value={LabelSortMode.NameDesc}>
              {t('reorder-label-dialog_order-alphabetically-desc', 'Order alphabetically descending (Z-A)')}
            </MenuItem>
          </Select>
        </FormControl>
      </DialogActions>
      <DialogActions>
        <Button id="ReorderLabelDialogCancelButton" sx={{ mr: 'auto' }} onClick={onClickCancel} variant="contained" color="secondary">
          {t('reorder-label-dialog_cancel-button-label', 'Cancel')}
        </Button>
        <Button id="ReorderLabelDialogSaveButton" onClick={onSave} variant="contained" color="primary">
          {t('reorder-label-dialog_save-button-label', 'Save')}
        </Button>
      </DialogActions>
      {!!errorMessage && <Alert severity="error" onClose={() => setErrorMessage(undefined)}>{errorMessage}</Alert>}
    </Dialog>
  );
}
