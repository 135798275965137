import React, { useCallback } from 'react';
import { Menu, MenuItem } from '@mui/material';
import SuggestionState from 'rich-text/types/SuggestionState';
import SuggestionItem from 'rich-text/types/SuggestionItem';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';

interface MentionListProps {
  suggestionState: SuggestionState,
  onCancel: () => void,
}

export default function MentionList({
  suggestionState,
  onCancel,
}: MentionListProps) {
  const onConfirm = useCallback((item: SuggestionItem) => {
    suggestionState.command(item);
  }, [suggestionState]);
  const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLLIElement>, item: SuggestionItem) => {
    if (e.key.toLowerCase() === 'tab') {
      onConfirm(item);
      return true;
    }
    return false;
  }, [onConfirm]);
  return (
    <Menu anchorEl={suggestionState.decorationNode} open sx={{}} onClose={onCancel}>
      {suggestionState.items.map((item) => (
        <MenuItem key={item.id} onKeyDown={(e) => onKeyDown(e, item)} onClick={() => onConfirm(item)}>
          {item.id.startsWith('CollaboratorGroup') ? <GroupIcon sx={{ mr: 1 }} /> : <PersonIcon sx={{ mr: 1 }} />}
          {item.label}
        </MenuItem>
      ))}
    </Menu>
  );
}
