import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ProjectMetaDataSettingsTab from 'settings/types/ProjectMetaDataSettingsTab';
import ModelMetadataSettingsTab from 'settings/components/ModelMetadataSettingsTab';
import WorkPhasesSettingsTab from 'settings/components/WorkPhasesSettingsTab';
import TagSettingsTab from 'settings/components/TagsSettingsTab';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';
import RoleAction from 'projects/types/RoleAction';

export default function ProjectMetaDataSettingsTabPane() {
  const { t } = useTranslation('settings');
  const theme = useTheme();
  const allowedActions = useAllowedActions();

  const availableTabs = useMemo(() => (allowedActions ? new Set([
    ...(allowedActions?.has(RoleAction.Label_Special) ? [
      ProjectMetaDataSettingsTab.ModelMetaData,
      ProjectMetaDataSettingsTab.WorkPhases,
    ] : []),
    ...(allowedActions?.has(RoleAction.Tag_Management) ? [
      ProjectMetaDataSettingsTab.Tags,
    ] : []),
  ]) : undefined), [allowedActions]);

  const modelMetaDataTabAvailable = useMemo(() => allowedActions?.has(RoleAction.Label_Special), [allowedActions]);
  const workPhasesTabAvailable = useMemo(() => allowedActions?.has(RoleAction.Label_Special), [allowedActions]);
  const tagsTapAvailable = useMemo(() => allowedActions?.has(RoleAction.Tag_Management), [allowedActions]);

  const [navigationScope, setNavigationScope] = useState<ProjectMetaDataSettingsTab | undefined>(undefined);
  useEffect(() => {
    const availableTab = availableTabs ? Array.from(availableTabs)[0] : undefined;
    setNavigationScope((prev) => (prev && availableTabs?.has(prev) ? prev : availableTab));
  }, [availableTabs]);

  const onChangeTab = useCallback((event: React.SyntheticEvent, newValue: ProjectMetaDataSettingsTab) => {
    setNavigationScope(newValue);
  }, []);

  return (
    <Box id="ProjectMetaDataSettingsTabPane" sx={{ display: 'grid', gridTemplateRows: 'auto 1fr' }}>
      <Box>
        <Typography variant="h2" sx={{ p: 4 }}>
          {t('project-metadata-settings-tab-pane_title', 'Project Metadata')}
        </Typography>
        <Tabs sx={{ ml: 4 }} value={navigationScope} onChange={onChangeTab}>
          {!!modelMetaDataTabAvailable && <Tab value={ProjectMetaDataSettingsTab.ModelMetaData} label={t('project-metadata-settings-tab-pane_model-metadata-tab-label', 'Model metadata')} />}
          {!!workPhasesTabAvailable && <Tab value={ProjectMetaDataSettingsTab.WorkPhases} label={t('project-metadata-settings-tab-pane_work-phases-tab-label', 'Work phases')} />}
          {!!tagsTapAvailable && <Tab value={ProjectMetaDataSettingsTab.Tags} label={t('project-metadata-settings-tab-pane_tags-tab-label', 'Tags')} />}
        </Tabs>
      </Box>
      <Box sx={{ boxShadow: 'inset -4px 0px 16px -4px rgba(0,0,0,0.3)', backgroundColor: theme.palette.grey[300], p: 4 }}>
        {navigationScope === ProjectMetaDataSettingsTab.ModelMetaData && <ModelMetadataSettingsTab />}
        {navigationScope === ProjectMetaDataSettingsTab.WorkPhases && <WorkPhasesSettingsTab />}
        {navigationScope === ProjectMetaDataSettingsTab.Tags && <TagSettingsTab />}
      </Box>
    </Box>
  );
}
