import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import IssueDto from 'issues/types/IssueDto';
import CreateIssueDto from 'issues/types/CreateIssueDto';
import { useCallback } from 'react';

export default function useIssuesCreateMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.Issue);
  const mutationFn = useCallback(async (dtos: CreateIssueDto[]) => {
    const { data: issueDtos } = await axiosInstance.post<IssueDto[]>(`${ApiEndpoint.Issue}`, dtos);
    return issueDtos;
  }, [axiosInstance]);
  const onSuccess = useCallback(() => {
    queryKeyBases.forEach((queryKey) => queryClient.invalidateQueries({ queryKey }));
  }, [queryClient, queryKeyBases]);
  return useMutation<IssueDto[], unknown, CreateIssueDto[]>({
    mutationFn,
    onSuccess,
  });
}
