import LabelType from 'labels/types/LabelType';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export default function useLabelTypeTranslation() {
  const { t } = useTranslation('labels');
  return useCallback((labelType: LabelType) => {
    if (labelType === LabelType.Building) return t('use-issue-label-translation_building', 'Building');
    if (labelType === LabelType.Discipline) return t('use-issue-label-translation_discipline', 'Discipline');
    if (labelType === LabelType.DocumentStatus) return t('use-issue-label-translation_document-status', 'Document Status');
    if (labelType === LabelType.Floor) return t('use-issue-label-translation_floor', 'Floor');
    if (labelType === LabelType.IssuePriority) return t('use-issue-label-translation_issue-priority', 'Issue Priority');
    if (labelType === LabelType.IssueStatus) return t('use-issue-label-translation_issue-status', 'Issue Status');
    if (labelType === LabelType.IssueType) return t('use-issue-label-translation_issue-type', 'Issue Type');
    if (labelType === LabelType.Tag) return t('use-issue-label-translation_tag', 'Tag');
    if (labelType === LabelType.WorkPhase) return t('use-issue-label-translation_work-phase', 'Work Phase');
    return '';
  }, [t]);
}
