import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';

export default function useCollaboratorDeleteMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.Collaborator);
  const mutationFn = useCallback(async (id: string) => {
    await axiosInstance.delete(`${ApiEndpoint.Collaborator}/${id}`);
  }, [axiosInstance]);
  const onSuccess = useCallback(async () => {
    await Promise.all(queryKeyBases.map((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase })));
  }, [queryClient, queryKeyBases]);
  return useMutation<void, unknown, string>({
    mutationFn,
    onSuccess,
  });
}
