import React from 'react';
import { SvgIcon } from '@mui/material';
import svgSprite from 'img/svg/sprite.svg';
import ISxProps from 'common/types/ISxProps';

interface SvgSpriteIconProps extends ISxProps {
  name: string,
  viewBox?: string,
}

export default function SvgSriteIcon({
  name,
  viewBox,
  sx,
}: SvgSpriteIconProps) {
  return <SvgIcon sx={sx} viewBox={viewBox}><use xlinkHref={`${svgSprite}#${name}`} /></SvgIcon>;
}

SvgSriteIcon.defaultProps = {
  viewBox: undefined,
};
