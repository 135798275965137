import React from 'react';
import useProjectIssueStatusesQuery from 'issues/hooks/useProjectIssueStatusesQuery';
import ChipMultiselect from 'common/components/ChipMultiselect';

interface IssueStatusMultiselectProps {
  label?: string,
  value: string[],
  onChange: (values: string[]) => void,
  disabled?: boolean,
}

export default function IssueStatusMultiselect({
  label,
  value,
  onChange,
  disabled,
}: IssueStatusMultiselectProps) {
  const { data: issueStatuses } = useProjectIssueStatusesQuery();
  return (
    <ChipMultiselect
      label={label}
      value={value}
      onChange={onChange}
      disabled={disabled}
      entities={issueStatuses}
    />
  );
}
