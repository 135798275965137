import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import UpdateLabelDto from 'labels/types/UpdateLabelDto';
import LabelType from 'labels/types/LabelType';
import useLabelUpdateMutation from 'labels/hooks/useLabelUpdateMutation';
import useLabelDeleteMutation from 'labels/hooks/useLabelDeleteMutation';
import useLabelsOdataQuery from 'labels/hooks/useLabelsOdataQuery';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import IssueSettingsIconPicker from 'settings/components/IssueSettingsIconPicker';
import IssueSettingsColorPicker from 'settings/components/IssueSettingsColorPicker';
import useLabelTypeProperties from 'settings/hooks/useLabelTypeProperties';
import useLabelTypeTranslation from 'labels/hooks/useLabelTypeTranslation';

export enum MutationMode {
  Create,
  Edit,
}

export enum MutateLabelDialogResult {
  Cancelled,
  Created,
  Updated,
  Deleted,
}

type MutateLabelDialogProps = {
  labelId?: string | undefined,
  labelType: LabelType,
  onClose: (result: MutateLabelDialogResult) => void,
  canDelete: boolean,
  canEdit: boolean,
};

export default function MutateLabelDialog({
  labelId,
  labelType,
  onClose,
  canDelete,
  canEdit,
}: MutateLabelDialogProps) {
  const { t } = useTranslation('settings');

  const { data: labelData } = useLabelsOdataQuery(labelId ? { filter: { id: labelId } } : undefined);
  const label = useMemo(() => labelData?.[0], [labelData]);

  const { data: currentProject } = useCurrentProjectQuery();

  const { mutateAsync: updateAsync, isPending: isLoadingUpdate } = useLabelUpdateMutation();
  const { mutateAsync: deleteAsync, isPending: isLoadingDelete } = useLabelDeleteMutation();

  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string | undefined>(undefined);
  const onChangeName = useCallback((e: ChangeEvent<HTMLInputElement>) => setName(e.target.value), []);
  const [abbreviation, setAbbreviation] = useState<string | undefined>(undefined);
  const onChangeAbbreviation = useCallback((e: ChangeEvent<HTMLInputElement>) => setAbbreviation(e.target.value), []);
  const [icon, setIcon] = useState<string | undefined>(undefined);
  const [color, setColor] = useState<string | undefined>(undefined);
  const { hasColor, hasIcon, hasAbbreviation } = useLabelTypeProperties(labelType);
  const confirmButtonDisabled = useMemo(() => {
    if (labelId && !label) return true;
    if (isLoadingUpdate) return true;
    if (!name?.trim().length) return true;
    if (!abbreviation?.trim().length && hasAbbreviation) return true;
    if (!icon?.trim().length && hasIcon) return true;
    if (!color?.trim().length && hasColor) return true;
    return false;
  }, [labelId, label, isLoadingUpdate, name, abbreviation, hasAbbreviation, icon, hasIcon, color, hasColor]);

  const canBeDeleted = useMemo(() => {
    if (labelType === LabelType.Discipline && labelId) {
      if (!label || !currentProject) return undefined;
      return label.id !== currentProject.architectureLabel;
    }
    if (labelType === LabelType.Floor && labelId) {
      if (!label) return undefined;
      return label.originalName !== 'All storeys';
    }
    return true;
  }, [labelType, labelId, label, currentProject]);

  const onClickConfirm = useCallback(async () => {
    if (!name || (!abbreviation && hasAbbreviation)) return;
    if (labelType === LabelType.IssueType && (!icon || !color)) return;
    setErrorMessage(undefined);
    try {
      if (labelId) {
        const updateDto: UpdateLabelDto = {
          id: labelId,
          name: name.trim(),
          abbreviation: abbreviation?.trim(),
          color,
          icon,
        };
        await updateAsync(updateDto);
      }
      onClose(MutateLabelDialogResult.Updated);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [name, abbreviation, hasAbbreviation, labelType, icon, color, labelId, onClose, updateAsync, getRequestErrorMessage]);

  const onClickCancel = useCallback(() => {
    onClose(MutateLabelDialogResult.Cancelled);
  }, [onClose]);

  const [isConfirmDeleteDialogVisible, setIsConfirmDeleteDialogVisible] = useState(false);
  const onClickDelete = useCallback(() => {
    setIsConfirmDeleteDialogVisible(true);
  }, []);
  const onClickConfirmDelete = useCallback(async () => {
    if (!labelId) throw new Error('Delete failed, missing id');
    try {
      await deleteAsync(labelId);
      onClose(MutateLabelDialogResult.Deleted);
    } catch (error) {
      setIsConfirmDeleteDialogVisible(false);
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [deleteAsync, labelId, onClose, getRequestErrorMessage]);
  const onClickCancelDelete = useCallback(() => {
    setIsConfirmDeleteDialogVisible(false);
  }, []);

  // init values
  useEffect(() => {
    if (!label) return;
    if (labelId) {
      setName((prev) => prev ?? label.name);
      setAbbreviation((prev) => prev ?? label.abbreviation);
      setColor((prev) => prev ?? label.color);
      setIcon((prev) => prev ?? label.icon);
    } else {
      setName('');
      setColor('');
      setIcon('');
    }
  }, [label, labelId]);

  const getLabelTypeTranslation = useLabelTypeTranslation();
  const labelTypeName = useMemo(() => getLabelTypeTranslation(labelType), [getLabelTypeTranslation, labelType]);

  return (
    <>
      <Dialog id="MutateLabelDialog" open>
        <DialogTitle sx={{ display: 'flex', gap: 2 }}>
          {!!labelId && t('mutate-label-dialog_edit-mode-title', 'Edit {{labelTypeName}}', { labelTypeName })}
          {!!labelId && !!canDelete && (
          <Button id="MutateLabelDialogDeleteButton" onClick={onClickDelete} variant="contained" color="secondary" disabled={canBeDeleted !== true} sx={{ pl: 1, ml: 'auto' }}>
            <DeleteIcon sx={{ mr: 1 }} />
            {t('mutate-label-dialog_delete-button-label', 'Delete')}
          </Button>
          )}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', py: 2, gap: 1 }}>
            {hasIcon && <IssueSettingsIconPicker icon={icon} onChange={setIcon} />}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, minWidth: 300 }}>
              <TextField id="MutateLabelDialogNameField" value={name ?? ''} onChange={onChangeName} disabled={!canEdit} label={t('mutate-label-dialog_name-textfield-label', 'Name')} />
              {hasAbbreviation && <TextField id="MutateLabelDialogAbbreviationField" value={abbreviation ?? ''} disabled={!canEdit} onChange={onChangeAbbreviation} label={t('mutate-label-dialog_abbreviation-textfield-label', 'Abbreviation')} />}
            </Box>
            {hasColor && <IssueSettingsColorPicker color={color} onChange={setColor} />}
          </Box>
          {!!errorMessage && <Alert severity="error" onClose={() => setErrorMessage(undefined)}>{errorMessage}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button id="MutateLabelDialogCancelButton" variant="contained" color="secondary" sx={{ mr: 'auto' }} onClick={onClickCancel}>{t('mutate-label-dialog_cancel-button-label', 'Cancel')}</Button>
          <Button id="MutateLabelDialogConfirmButton" variant="contained" color="primary" onClick={onClickConfirm} disabled={confirmButtonDisabled || !canEdit}>
            {(isLoadingUpdate) && <CircularProgress size={12} sx={{ mr: 1 }} />}
            {t('mutate-label-dialog_confirm-button-label', 'Save')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isConfirmDeleteDialogVisible}>
        <DialogTitle>{t('mutate-label-dialog_delete-dialog-title', 'Delete "{{name}}"', { name: label?.name })}</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Alert severity="warning">
            {t('mutate-label-dialog_delete-dialog-message', 'Are you sure that you want to delete "{{name}}"?', { name: label?.name })}
          </Alert>
          <Alert severity="info">
            <Trans
              t={t}
              i18nKey="mutate-label-dialog_delete-dialog-soft-delete-info"
              components={{ s: <strong />, br: <br /> }}
              defaults="<s>Deleting a label will not remove it from any object it is assigned to!</s><br>When a label is deleted, it cannot be assigned to any object, and it cannot be filtered by."
            />
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" sx={{ mr: 'auto' }} onClick={onClickCancelDelete}>{t('mutate-label-dialog_delete-dialog-cancel-button-label', 'Cancel')}</Button>
          <Button variant="contained" color="primary" onClick={onClickConfirmDelete} disabled={confirmButtonDisabled}>
            {(isLoadingDelete) && <CircularProgress size={12} sx={{ mr: 1 }} />}
            {t('mutate-label-dialog_delete-dialog-confirm-button-label', 'Delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
