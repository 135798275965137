import React from 'react';
import { Box, useTheme } from '@mui/material';
import ISxProps from 'common/types/ISxProps';

interface NamingSchemeGroupsReorderDropIndicatorProps extends ISxProps {
  visible: boolean,
}

export default function NamingSchemeGroupsReorderDropIndicator({
  sx,
  visible,
}: NamingSchemeGroupsReorderDropIndicatorProps) {
  const theme = useTheme();
  return (
    <Box
      id="NamingSchemeGroupsReorderDropIndicator"
      sx={{ ...sx, flexShrink: 0, width: 4, backgroundColor: visible ? theme.palette.primary.main : undefined, borderRadius: '2px' }}
    />
  );
}
