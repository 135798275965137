import { IFCParser, ParserAPI, ParserProgress } from "./IFCParser";
import { SubsetManager } from "./SubsetManager";
import {
  IfcState,
  VisoModel,
  SELECT_ID,
  COLORGROUP_ID,
  VisoNode,
} from "./BaseDefinitions";
import { Color, Intersection, Matrix4, Scene } from "three";
import { BvhManager } from "./BvhManager";
import { ItemsHider } from "./ItemsHider";
import { PropertyManager } from "./PropertyManager";

/**
 * Contains all the logic to work with the loaded IFC files (select, edit, etc).
 */
export class IFCManager {
  state: IfcState = {
    models: {},
    modelData: {},
    sectionColor: new Color(0xff0000),
  };

  private BVH = new BvhManager();
  private hider = new ItemsHider(this.state);
  private propertyManager = new PropertyManager(this.state);

  parser: ParserAPI = new IFCParser(this.state, this.BVH);
  subsets = new SubsetManager(this.state, this.BVH, this.hider);

  async loadVisoModel(model: VisoModel) {
    const ifc = this.parser.parseVisoModel(model);
    await this.hider.processCoordinates(ifc.modelFileId);
    return ifc;
  }

  /**
   * Makes object picking a lot faster
   * Courtesy of gkjohnson's [work](https://github.com/gkjohnson/three-mesh-bvh).
   * Import these objects from his library and pass them as arguments. IFC.js takes care of the rest!
   */
  setupThreeMeshBVH(
    computeBoundsTree: any,
    disposeBoundsTree: any,
    acceleratedRaycast: any
  ) {
    this.BVH.initializeMeshBVH(
      computeBoundsTree,
      disposeBoundsTree,
      acceleratedRaycast
    );
  }

  /**
   * Sets a callback function that is called every 10% of IFC loaded.
   * @onProgress callback function
   */
  setOnProgress(onProgress: (event: ParserProgress) => void) {
    this.state.onProgress = onProgress;
  }

  /**
   * Sets a coordination matrix to be applied when loading geometry.
   * @matrix THREE.Matrix4
   */
  setupCoordinationMatrix(matrix: Matrix4) {
    this.state.coordinationMatrix = matrix;
  }

  /**
   * Clears the coordination matrix that is applied when loading geometry.
   */
  clearCoordinationMatrix() {
    delete this.state.coordinationMatrix;
  }

  /**
   * Closes the specified model and deletes it from the [scene](https://threejs.org/docs/#api/en/scenes/Scene).
   * @modelID ID of the IFC model.
   * @scene Scene where the model is (if it's located in a scene).
   */
  close(modelID: string, scene?: Scene) {
    if (scene) scene.remove(this.state.models[modelID]);
    if (this.state.models[modelID]) {
      this.state.models[modelID].dispose();
      this.state.models[modelID].clear();
  
      delete this.state.models[modelID];
    }
  }

  /**
   * Hides the selected items in the specified model
   * @modelID ID of the IFC model.
   * @ids Express ID of the elements.
   */
  hideItems(modelID: string, ids: string[]) {
    this.hider.hideItems(modelID, ids);
  }

  /**
   * Hides all the items of the specified model
   * @modelID ID of the IFC model.
   */
  hideAllItems(modelID: string) {
    this.hider.hideAllItems(modelID);
  }

  /**
   * Shows all the items of the specified model
   * @modelID ID of the IFC model.
   * @ids Express ID of the elements.
   */
  showItems(modelID: string, ids: string[]) {
    this.hider.showItems(modelID, ids);
  }

  /**
   * Shows all the items of the specified model
   * @modelID ID of the IFC model.
   */
  showAllItems(modelID: string) {
    this.hider.showAllItems(modelID);
  }

  async disposeMemory() {
    this.hider.dispose();
  }

  /** *************************************** Visoplan **************************************/

  /**
   * Get IFC guid of instersected object.
   * @param item Intersected object by ray
   */
  getGlobalId(item: Intersection) {
    return this.propertyManager.getGlobalId(item);
  }

  hoverComponent(modelId: string, globalId: string) {
    return this.subsets.hoverComponent(modelId, globalId);
  }

  pickComponent(modelId: string, globalId: string, ctrl: boolean) {
    const model = this.subsets.pickComponent(modelId, globalId, ctrl);
    this.subsets.refreshColorComponents();

    return model;
  }

  hidePickComponent() {
    this.subsets.resetPickComponent();
  }

  resetManager(scene: Scene) {
    this.subsets.reset();
    this.hider.dispose();
    this.closeModels(scene);
  }

  closeManager(scene: Scene) {
    this.subsets.dispose();
    this.hider.dispose();
    this.closeModels(scene);
  }

  resetModel() {
    Object.keys(this.state.models).forEach((key) => {
      if (key !== SELECT_ID && key !== COLORGROUP_ID) {
        this.showAllItems(key);
      }
    });
    this.subsets.resetModel();
  }

  removeModels(modelIds: string[], scene: Scene) {
    this.subsets.removeModels(modelIds);
    modelIds.forEach((mId) => {
      const model = this.state.models[mId];
      if (model) {
        scene.remove(model);
        model.dispose();
        model.clear();

        delete this.state.models[mId];
      }

      if (this.state.modelData[mId])
        delete this.state.modelData[mId];
    });
  }

  setVisibleSetting(opening: boolean, space: boolean, sectionColor?: Color) {
    this.hider.setVisible(opening, space);
    this.state.sectionColor = sectionColor;
    this.subsets.handleColorVisibleComponent(opening, space);
    this.subsets.updateSectionColor();

    Object.keys(this.state.models).forEach((key) => {
      if (key) {
        this.hider.refreshVisibleItems(key);
        this.subsets.handleIfcVisibleComponent(key, opening, space);
      }
    });
  }

  setSectionSpaceColor(color?: Color) {
    this.state.sectionColor = color;
    this.subsets.updateSectionColor();
  }

  removeComponentFromSelection(component: VisoNode) {
    this.pickComponent(SELECT_ID, component.globalId, true);
  }

  getComponentInfos(globalIds: string[]) {
    let infos: VisoNode[] = [];
    Object.keys(this.state.modelData).forEach((key) => {
      globalIds.forEach((gId)=>{
        const node=this.state.modelData[key].nodeData[gId];
        if(node){
          infos.push(node);
        }
      });
    });

    return infos;
  }

  private closeModels(scene: Scene) {
    Object.keys(this.state.models).forEach((key) => {
      if (key) {
        const model = this.state.models[key];
        scene.remove(model);
        model.dispose();
        model.clear();
      }
    });

    this.state.models = {};
  }
}
