import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useCurrentUserQuery from 'users/hooks/useCurrentUserQuery';
import useCurrentUserMutation from 'users/hooks/useCurrentUserMutation';
import { useTranslation } from 'react-i18next';
import UserUpdateDto from 'users/types/UserUpdateDto';

interface ChangePersonalInfoDialogProps {
  onClose: () => void,
}

export default function ChangePersonalInfoDialog({
  onClose,
}: ChangePersonalInfoDialogProps) {
  const { t } = useTranslation('users');
  const getRequestErrorMessage = useRequestErrorMessage();
  const { data: currentUser } = useCurrentUserQuery();
  const { mutateAsync, isPending: isLoadingMutation } = useCurrentUserMutation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const [successFeedbackDialogOpen, setSuccessFeedbackDialogOpen] = useState(false);
  const onCloseSuccessFeedbackDialog = useCallback(() => onClose(), [onClose]);

  const [firstName, setFirstName] = useState<string | undefined>();
  const onChangeFirstName = useCallback((event: ChangeEvent<HTMLInputElement>) => setFirstName(event.target.value), []);
  const firstNameError = useMemo(() => (firstName?.length === 0 ? t('account-settings-dialog_first-name-empty-error', 'Please enter your first name') : undefined), [firstName?.length, t]);

  const [lastName, setLastName] = useState<string | undefined>();
  const onChangeLastName = useCallback((event: ChangeEvent<HTMLInputElement>) => setLastName(event.target.value), []);
  const lastNameError = useMemo(() => (lastName?.length === 0 ? t('account-settings-dialog_last-name-empty-error', 'Please enter your last name') : undefined), [lastName?.length, t]);

  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
  const onChangePhoneNumber = useCallback((event: ChangeEvent<HTMLInputElement>) => setPhoneNumber(event.target.value), []);

  const userDataModified = useMemo(() => {
    if (!currentUser) return undefined;
    return firstName !== currentUser.firstName || lastName !== currentUser.lastName || phoneNumber !== currentUser.phone;
  }, [currentUser, firstName, lastName, phoneNumber]);

  const onClickConfirm = useCallback(async () => {
    if (!userDataModified || firstName === undefined || lastName === undefined || !currentUser) return;
    try {
      const updateDto: UserUpdateDto = {
        langType: currentUser.langType,
        firstName,
        lastName,
        phone: phoneNumber ?? currentUser.phone ?? '',
      };
      await mutateAsync(updateDto);
      setSuccessFeedbackDialogOpen(true);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [currentUser, firstName, getRequestErrorMessage, lastName, mutateAsync, phoneNumber, userDataModified]);

  useEffect(() => {
    // initialize form values
    if (!currentUser) return;
    setFirstName((prev) => prev ?? currentUser.firstName);
    setLastName((prev) => prev ?? currentUser.lastName);
    setPhoneNumber((prev) => prev ?? currentUser.phone);
  }, [currentUser]);

  return (
    <Dialog open id="ChangePersonalDataDialog" PaperProps={{ sx: { minWidth: 'auto' } }}>
      <DialogTitle>{t('change-personal-info-dialog_title', 'Change Personal Info')}</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 1, display: 'flex', flexDirection: 'column', gap: 2, width: 300 }}>
          <TextField
            label={t('account-settings-dialog_first-name-label', 'First name')}
            value={firstName ?? ''}
            disabled={firstName === undefined || isLoadingMutation}
            onChange={onChangeFirstName}
            error={!!firstNameError}
            helperText={firstNameError}
            name="given-name"
            autoComplete="given-name"
          />
          <TextField
            label={t('account-settings-dialog_last-name-label', 'Last name')}
            value={lastName ?? ''}
            disabled={lastName === undefined || isLoadingMutation}
            onChange={onChangeLastName}
            error={!!lastNameError}
            helperText={lastNameError}
            name="family-name"
            autoComplete="family-name"
          />
          <TextField
            label={t('account-settings-dialog_phone-number-label', 'Phone number')}
            value={phoneNumber ?? ''}
            disabled={isLoadingMutation}
            onChange={onChangePhoneNumber}
            name="tel"
            autoComplete="tel"
          />
        </Box>
        {!!errorMessage && <Alert severity="error" sx={{ mt: 2 }} onClose={onCloseErrorMessage}>{errorMessage}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          id="ChangePersonalDataDialogCancelButton"
          variant="contained"
          color="secondary"
          onClick={onClose}
          sx={{ mr: 'auto' }}
        >
          {t('change-personal-info-dialog_cancel-button-label', 'Cancel')}
        </Button>
        <Button
          id="ChangePersonalDataDialogConfirmButton"
          variant="contained"
          color="primary"
          onClick={onClickConfirm}
        >
          {t('change-personal-info-dialog_confirm-button-label', 'Confirm')}
        </Button>
        {!!successFeedbackDialogOpen && (
          <Dialog open>
            <DialogTitle>{t('change-personal-info-dialog_success-feedback-dialog-title', 'Personal Info Changed')}</DialogTitle>
            <DialogContent>
              <Alert severity="success">{t('change-personal-info-dialog_success-feedback-dialog-title', 'Your changes to your personal info have been changed.')}</Alert>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" onClick={onCloseSuccessFeedbackDialog}>{t('change-personal-info-dialog_success-feedback-dialog-close-button-label', 'Close')}</Button>
            </DialogActions>
          </Dialog>
        )}
      </DialogActions>
    </Dialog>
  );
}
