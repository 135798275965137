import IChildren from 'common/types/IChildren';
import LegacyDiscardChangesDialog, { LegacyDiscardChangesDialogResult, LegacyDiscardChangesDialogVariant } from 'projects/components/LegacyDiscardChangesDialog';
import React, { useState, useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LegacySettingsNotificationDialogContext from 'settings/contexts/LegacySettingsNotificationDialogContext';

const modelsPathRegex = /\/projects\/[^/]+\/models/;

interface DiscardDialogState {
  variant: LegacyDiscardChangesDialogVariant,
  toPath: string,
}

// This context was implemented to make old code work after reworking the main menu. It prevents users navigating away from a view that has unsaved changes, by displaying a confirmation dialog.
// The old code used redux which was reworked into this context.
// TODO: rewrite all remaining settings pages and then remove this
export default function LegacySettingsNotificationDialogContextProvider({ children }: IChildren) {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveChangesNotification, setSaveChangesNotification] = useState<string>('');

  const navigate = useNavigate();
  const location = useLocation();
  const [discardChangesDialogState, setConfirmationDialogState] = useState<DiscardDialogState | undefined>(undefined);

  const navigateWithConfirmation = useCallback((toPath: string) => {
    if (hasUnsavedChanges) {
      if (location.pathname.match(modelsPathRegex)) {
        setConfirmationDialogState({ variant: LegacyDiscardChangesDialogVariant.DiscardModelSettings, toPath });
      } else {
        setConfirmationDialogState({ variant: LegacyDiscardChangesDialogVariant.DiscardUnsavedSettingsChanges, toPath });
      }
    } else {
      navigate(toPath);
    }
  }, [hasUnsavedChanges, navigate, location.pathname]);

  const onCloseDiscardChangesDialog = useCallback((result: LegacyDiscardChangesDialogResult, toPath: string) => {
    setConfirmationDialogState(undefined);
    if (result === LegacyDiscardChangesDialogResult.Discard) {
      setHasUnsavedChanges(false);
      setSaveChangesNotification(result);
      navigate(toPath);
    }
  }, [navigate]);

  const state = useMemo(() => ({
    setHasUnsavedChanges,
    saveChangesNotification,
    setSaveChangesNotification,
    navigateWithConfirmation,
  }), [saveChangesNotification, navigateWithConfirmation]);

  return (
    <LegacySettingsNotificationDialogContext.Provider value={state}>
      {children}
      {!!discardChangesDialogState && (
        <LegacyDiscardChangesDialog variant={discardChangesDialogState.variant} toPath={discardChangesDialogState.toPath} onClose={onCloseDiscardChangesDialog} />
      )}
    </LegacySettingsNotificationDialogContext.Provider>
  );
}
