import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';

export default function useRoleDeleteMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.Role);
  const mutationFn = useCallback(async (id: string) => {
    await axiosInstance.delete(`${ApiEndpoint.Role}/${id}`);
  }, [axiosInstance]);
  const onSuccess = useCallback(() => {
    queryKeyBases.forEach((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
  }, [queryClient, queryKeyBases]);
  return useMutation<void, unknown, string>({
    mutationFn,
    onSuccess,
  });
}
