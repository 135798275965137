import { useMemo } from 'react';
import { useMatches } from 'react-router-dom';
import SettingsTab from 'settings/types/SettingsTab';

const routeRegex = /\/projects\/[^/]+\/settings\/([^/]+)\/?(.+)?/i;

export default function useCurrentSettingsTab() {
  const matches = useMatches();
  return useMemo(() => {
    const match = matches.map((m) => m.pathname.match(routeRegex)).findLast((regexMatch) => !!regexMatch && regexMatch.length > 1);
    const currentSettingsTab = match?.[1] as SettingsTab | undefined;
    const isTopLevel = Boolean(currentSettingsTab && !match?.[2]);
    const subTab = match?.[2];
    return {
      currentSettingsTab,
      isTopLevel,
      subTab,
    };
  }, [matches]);
}
