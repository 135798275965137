import useApiConfig from 'api/hooks/useApiConfig';
import useVisoplanApiContext from 'api/hooks/useVisoplanApiContext';
import useCurrentProjectId from 'projects/hooks/useCurrentProjectId';
import { useCallback } from 'react';
import DocumentUploadPayload from 'upload/types/DocumentUploadPayload';
import UploadingFileHandle from 'upload/types/UploadingFileHandle';

// TODO: unify with actual API entity type
interface DocumentVersionDto {
  id: string,
}

export default function useDocumentUploadHandler() {
  const { apiUrl } = useApiConfig();
  const { authProjectToken, authUserToken } = useVisoplanApiContext();
  const projectId = useCurrentProjectId();

  const handleUploadDocuments = useCallback((payload: DocumentUploadPayload, onProgress?: (uploadHandle: UploadingFileHandle) => void, onFinish?: (uploadHandle: UploadingFileHandle) => void, onError?: (uploadHandle: UploadingFileHandle) => void) => {
    if (!authProjectToken || !authUserToken || !projectId) return [];
    const {
      files, folderId,
    } = payload;
    return Array.from(files).map((file) => {
      const data = new FormData();
      data.append('file', file, file.name);

      const xhr = new XMLHttpRequest();
      const url = `${apiUrl}/document?folderId=${folderId}`;
      xhr.open('POST', url);
      xhr.setRequestHeader('Authorization', `Bearer ${authUserToken.bearer}`);
      xhr.setRequestHeader('X-Project-Authorization', `Bearer ${authProjectToken.bearer}`);
      xhr.setRequestHeader('Visoplan-Client-Id', 'Visoplan Webclient');
      xhr.setRequestHeader('Visoplan-Webclient-Request-Source', 'Current');

      const uploadingFileHandle: UploadingFileHandle = {
        fileName: file.name,
        total: file.size,
        loaded: 0,
        request: xhr,
        isCompleted: false,
        isFailed: false,
        projectId,
        targetFolderId: payload.folderId,
      };
      xhr.upload.onprogress = (event) => {
        uploadingFileHandle.loaded = event.loaded;
        if (onProgress) onProgress(uploadingFileHandle);
      };
      xhr.upload.onerror = () => {
        uploadingFileHandle.isFailed = true;
        if (onError) onError(uploadingFileHandle);
      };
      xhr.onreadystatechange = () => {
        if (xhr.readyState === xhr.DONE) {
          const documentVersionDtos: DocumentVersionDto[] = JSON.parse(xhr.response);
          uploadingFileHandle.documentVersionId = documentVersionDtos[0]?.id;
          uploadingFileHandle.isCompleted = true;
          if (onProgress) onProgress(uploadingFileHandle);
          if (onFinish) onFinish(uploadingFileHandle);
        }
      };
      xhr.send(data);

      return uploadingFileHandle;
    });
  }, [apiUrl, authProjectToken, authUserToken, projectId]);

  return handleUploadDocuments;
}
