import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ICellRendererParams } from '@ag-grid-community/core';
import DocumentsDataGridRow from 'documents/types/DocumentDataGridRow';
import { useTranslation } from 'react-i18next';
import FileType from 'documents/types/FileType';
import DocumentImportStatus from 'documents/types/DocumentImportStatus';
import DocumentModelImportModal from 'documents/components/DocumentModelImportModal';
import useCurrentCollaboratorRole from 'collaborators/hooks/useCurrentCollaboratorRole';
import RoleAction from 'projects/types/RoleAction';
import useDocumentVersionQuery from 'documents/hooks/useDocumentVersionQuery';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';

const supportedFileTypes = new Set([
  FileType.Ifc,
  FileType.Pdf,
]);

export default function DocumentModelImportCell({
  data: row,
  value,
}: ICellRendererParams<DocumentsDataGridRow, DocumentImportStatus>) {
  const { t, i18n } = useTranslation('documents');
  const [isDocumentImportModalOpen, setIsDocumentImportModalOpen] = useState(false);
  const currentUserRole = useCurrentCollaboratorRole();
  const canImport = useMemo(() => {
    if (!row || !currentUserRole) return undefined;
    if (row.fileType === FileType.Ifc) {
      return currentUserRole.allowedActions.has(RoleAction.Document_IfcImport) && row.documentImportStatus !== DocumentImportStatus.ImportQueued && row.documentImportStatus !== DocumentImportStatus.ImportStarted;
    }
    if (row.fileType === FileType.Pdf) {
      return currentUserRole.allowedActions.has(RoleAction.Document_FloorPlanImport) && row.documentImportStatus === DocumentImportStatus.NotImported;
    }
    return false;
  }, [row, currentUserRole]);

  // while this cell's data comes from the data grid, we use an additional query with auto-refetch on this entity during import
  // this way we don't have to refetch all visible entities, but only this one and only during the timespan when it's necessary
  // (the actual row data is updated by this auto-refetching query because both share the same cache)
  const [isImporting, setIsImporting] = useState(false); // this flag enables/disables the auto-refetching query during import
  const { data: importingDocumentVersion } = useDocumentVersionQuery(isImporting ? row?.id : undefined, { refetchInterval: 10000 });
  useEffect(() => {
    if (!row) return;
    if (row.documentImportStatus === DocumentImportStatus.ImportStarted) {
      // as soon as the import starts, we enable the auto-refetching query
      setIsImporting(true);
    }
  }, [row]);
  useEffect(() => {
    if (!importingDocumentVersion) return;
    if (importingDocumentVersion.documentImportStatus !== DocumentImportStatus.ImportStarted) {
      // when the import is finished, we disable the auto-refetching query again
      setIsImporting(false);
    }
  }, [importingDocumentVersion]);

  if (!row?.fileType || !supportedFileTypes.has(row.fileType)) return null;

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
      {!!canImport && (
        <Button onClick={() => setIsDocumentImportModalOpen(true)} disabled={isDocumentImportModalOpen} size="small" variant="outlined" color="primary" sx={{ pl: 0.25, pr: 0.5 }}>
          {row.fileType === FileType.Ifc && (
            value === DocumentImportStatus.NotImported
              ? t('document-version-row_import-button', 'Import Model')
              : t('document-version-row_re-import-button', 'Re-import')
          )}
          {row.fileType !== FileType.Ifc && (
            <>
              <AddToHomeScreenIcon sx={{ mr: 0.5 }} fontSize="small" />
              {t('document-version-row_share-for-site-button', 'Share as plan')}
            </>
          )}
        </Button>
      )}
      {isDocumentImportModalOpen && <DocumentModelImportModal documentVersionId={row.id} onClose={() => setIsDocumentImportModalOpen(false)} />}
      {value === DocumentImportStatus.NotImported && row.fileType === FileType.Ifc && <Typography>{t('document-version-row_import-not-imported', 'Not imported')}</Typography>}
      {value === DocumentImportStatus.ImportQueued && <Typography>{t('document-version-row_import-queued', 'Import queued')}</Typography>}
      {value === DocumentImportStatus.ImportStarted && <Typography>{t('document-version-row_import-in-progress', 'Currently importing')}</Typography>}
      {value === DocumentImportStatus.ImportFailed && <Typography>{t('document-version-row_import-failed', 'Import failed')}</Typography>}
      {value === DocumentImportStatus.ImportCompleted && (
      <>
        <Typography sx={{ lineHeight: 1.1, fontSize: '12px' }}>
          {row.fileType === FileType.Ifc
            ? t('document-version-row_import-completed_ifc', 'Available in 3D Viewer')
            : t('document-version-row_import-completed_2d', 'Available in App')}
        </Typography>
        {!!row.importFinishDate && (
          <Typography sx={{ lineHeight: 1.1, fontSize: '12px' }}>{new Date(row.importFinishDate).toLocaleString(i18n.language, { dateStyle: 'medium', timeStyle: 'medium' })}</Typography>
        )}
      </>
      )}
    </Box>
  );
}
