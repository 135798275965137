import PlanlistFilterContext, { PlanlistFilterContextState } from 'documents-lists/contexts/PlanlistFilterContext';
import DocumentListDto from 'documents-lists/types/DocumentListDto';
import PlanlistSortMode from 'documents-lists/types/PlanlistSortMode';
import React, {
  ReactNode, useState, useMemo, useCallback, useEffect,
} from 'react';

interface PlanlistFilterContextProviderProps {
  children?: ReactNode,
}

export default function PlanlistFilterContextProvider({
  children,
}: PlanlistFilterContextProviderProps) {
  const [searchString, setSearchString] = useState<string | undefined>(undefined);
  const isFilterActive = useMemo(() => !!searchString?.length, [searchString]);
  const filterPredicate = useCallback((planlist: DocumentListDto) => !searchString || planlist.name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()), [searchString]);
  const [sortMode, setSortMode] = useState<PlanlistSortMode>(localStorage.getItem('planlistSortMode') as PlanlistSortMode ?? PlanlistSortMode.NameAsc);

  useEffect(() => {
    const storedSortMode = localStorage.getItem('planlistSortMode') as PlanlistSortMode | null;
    if (sortMode !== storedSortMode) localStorage.setItem('planlistSortMode', sortMode);
  }, [sortMode]);

  const state: PlanlistFilterContextState = useMemo(() => ({
    isFilterActive,
    searchString,
    setSearchString,
    filterPredicate,
    sortMode,
    setSortMode,
  }), [filterPredicate, searchString, isFilterActive, sortMode, setSortMode]);

  return (
    <PlanlistFilterContext.Provider value={state}>
      {children}
    </PlanlistFilterContext.Provider>
  );
}
