import React, { Suspense } from 'react';
import { Box, Link } from '@mui/material';
import { Translation } from 'react-i18next';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';

export default function NotFound() {
  return (
    <main className="main custom-scrollbar">
      <Suspense fallback={<CenteredCircularProgress />}>
        <Translation ns="error">
          {(t) => (
            <Box
              sx={{
                height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
              }}
            >
              <Box>{t('not_found_error_message', 'The page you are trying to reach can not be found.')}</Box>
              <Link href="/">{t('go_back_to_index', 'Go back to the front page')}</Link>
            </Box>
          )}
        </Translation>
      </Suspense>
    </main>
  );
}
