import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import UserUpdateDto from 'users/types/UserUpdateDto';
import ErrorResponseData from 'api/types/ErrorResponseData';
import useCurrentUserQueryKey from 'users/hooks/useCurrentUserQueryKey';

export default function useCurrentUserMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const queryKey = useCurrentUserQueryKey();
  return useMutation({
    mutationFn: async (dto: UserUpdateDto) => {
      await axiosInstance.put<undefined | ErrorResponseData>('/user/current', dto);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
}
