import React, { useCallback } from 'react';
import { Box, Button, useTheme } from '@mui/material';
import Icon from '@mdi/react';
import { mdiDeleteOutline } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import ISxProps from 'common/types/ISxProps';

interface ViewpointListItemProps extends ISxProps {
  viewpointItem: { id: string, imageDataUrl: string | undefined, onClickRemove?: () => void },
}

export default function ViewpointListItem({
  sx,
  viewpointItem,
}: ViewpointListItemProps) {
  const { t } = useTranslation('issues');
  const theme = useTheme();

  const onClickUnlinkButton = useCallback(() => {
    viewpointItem.onClickRemove?.();
  }, [viewpointItem]);

  return (
    <Box
      id="ViewpointListItem"
      sx={{
        ...sx,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        backgroundImage: `url(${viewpointItem.imageDataUrl ?? ''})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        borderRadius: '6px',
        aspectRatio: '16 / 9',
        p: 1,
        boxShadow: '0px 0px 8px -4px rgba(0,0,0,0.3)',
      }}
    >
      {!!viewpointItem.onClickRemove && (
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={onClickUnlinkButton}
          sx={{ px: 0.5, minWidth: 'unset', borderStyle: 'solid', borderWidth: 1, borderColor: theme.palette.secondary.contrastText }}
          title={t('viewpoint-list-item_remove-viewpoint-button-label', 'Delete Viewpoint')}
        >
          <Icon path={mdiDeleteOutline} size={0.75} />
        </Button>
      )}
    </Box>
  );
}
