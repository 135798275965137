import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, DateCalendar, DateCalendarProps } from '@mui/x-date-pickers';
import { deDE, enUS } from '@mui/x-date-pickers/locales';

interface LocalizedDateCalendarProps extends DateCalendarProps<Moment> {
}

export default function LocalizedDateCalendar(props: LocalizedDateCalendarProps) {
  const { i18n } = useTranslation();
  const adapterLocale = useMemo(() => i18n.language.split('-')[0], [i18n.language]);
  const localeText = useMemo(() => (adapterLocale === 'de' ? deDE : enUS).components.MuiLocalizationProvider.defaultProps.localeText, [adapterLocale]);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={adapterLocale} localeText={localeText}>
      <DateCalendar<Moment>
        {...props}
      />
    </LocalizationProvider>
  );
}
