import React from 'react';
import { Box } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import LabelDto from 'labels/types/LabelDto';
import LabelChip from 'labels/components/LabelChip';

interface LabelIdsChipsCellTooltipProps extends ISxProps {
  labels: LabelDto[] | undefined,
}

export default function LabelIdsChipsCellTooltip({
  sx,
  labels,
}: LabelIdsChipsCellTooltipProps) {
  return (
    <Box id="LabelIdsChipsCellTooltip" sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, p: 1, ...sx }}>
      {labels?.map((label) => (
        <LabelChip key={label.id} label={label} />
      ))}
    </Box>
  );
}
