import { OdataUseQueryArgs } from 'api/hooks/useOdataQuery';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useQueryFiltersOdataQuery from 'query-filters/hooks/useQueryFiltersOdataQuery';
import DocumentQueryFilterDto from 'query-filters/types/DocumentQueryFilterDto';
import QueryFilterKind from 'query-filters/types/QueryFilterKind';

export default function useDocumentQueryFiltersOdataQuery(
  ...args: OdataUseQueryArgs<ApiEndpoint.QueryFilterDocument, DocumentQueryFilterDto>
) {
  return useQueryFiltersOdataQuery<QueryFilterKind.Document, ApiEndpoint.QueryFilterDocument>(ApiEndpoint.QueryFilterDocument, ...args);
}
