import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { getFileTypes } from "../../Helpers/Common";
import { CompanyService, DocumentService } from "../../Services";

import { Modal } from "../../HOC";
import { AddMoreButton } from "../Buttons";
import CustomSelect from "../CustomSelect";
import IconButton from "../Buttons/IconButton";
import NotificationModal from "../NotificationModal";
import ConfirmModal from "../ConfirmModal";

const DocumentsPrintModal = ({ documents, currentCollaborator, users, onClose }) => {
  const { t } = useTranslation();
  const deliveryOptions = [
    { value: 0, label: t("courier", "Courier") },
    { value: 1, label: "A-Post" },
  ];
  const FILE_TYPES = useMemo(() => getFileTypes(), []);
  const processOptions = [
    { value: 0, label: t("with_fold", "With fold") },
    { value: 1, label: t("without_fold", "Without fold") },
  ];

  const [distributorOrders, setDistributorOrders] = useState([]);
  const [total, setTotal] = useState(documents.map(() => 0));
  const [processing, setProcessing] = useState(processOptions[0]);
  const [comment, setComment] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [notification, setNotification] = useState("");
  const [creatorOrder, setCreatorOrder] = useState({
    address: "",
    deliveryMethod: deliveryOptions[0],
    documentQuantities: documents.map(() => 1),
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [isVisibleDeleteDistributionModal, setVisibleDeleteDistributionModal] =
    useState(false);
  const [deleteDistributorIndex, setDeleteDistributorIndex] = useState(0);
  const distributors = useMemo(
    () =>
      users.filter(
        (u) =>
          u.id !== currentCollaborator.id &&
          !distributorOrders.some(
            (d) => d.userName === `${u.firstName} ${u.lastName}`
          )
      ),
    [users, currentCollaborator, distributorOrders]
  );

  useEffect(() => {
    const effect = async () => {
      const companyInfo = await CompanyService.getCompany();
      setCompanyName(companyInfo ? companyInfo.name : "");
    };
    effect();
  }, []);

  useEffect(() => {
    const documentQuantities = [];
    documents.forEach((_, index) => {
      let sum = parseInt(creatorOrder.documentQuantities[index]);
      distributorOrders.forEach((order) => {
        sum += parseInt(order.documentQuantities[index]);
      });
      documentQuantities.push(sum);
    });
    setTotal(documentQuantities);
  }, [documents, creatorOrder, distributorOrders]);

  const addDistributor = () => {
    setDistributorOrders([
      ...distributorOrders,
      {
        userName: "",
        companyName: "",
        address: "",
        documentQuantities: documents.map(() => 0),
      },
    ]);
  };

  const creatorOrderChange = (value, key) => {
    setCreatorOrder({
      ...creatorOrder,
      [key]: value,
    });
  };

  const creatorOrderQuantityChange = (e, index) => {
    const value = e.target.value;
    if (value < 0 || value > 99) return;

    const order = { ...creatorOrder };
    order.documentQuantities[index] = value;
    setCreatorOrder(order);
  };

  const distributorOrderChange = (e, index, key) => {
    const value = e.target.value;
    const orders = [...distributorOrders];
    orders[index][key] = value;
    setDistributorOrders(orders);
  };

  const distributorOrderQuantityChange = (e, index, docIndex) => {
    const value = e.target.value;
    if (value < 0 || value > 99) return;

    const orders = [...distributorOrders];
    orders[index].documentQuantities[docIndex] = value;
    setDistributorOrders(orders);
  };

  const onBack = useCallback(
    (e) => {
      e.preventDefault();

      if (currentStep === 0) {
        onClose();
      } else {
        setCurrentStep(currentStep - 1);
      }
    },
    [currentStep, onClose]
  );

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (currentStep === 0) {
        setCurrentStep(currentStep + 1);
      } else {
        const status = await DocumentService.printOrder({
          documentVersions: documents.map((doc) => doc.id),
          creator: {
            ...creatorOrder,
            deliveryMethod: creatorOrder.deliveryMethod.value,
          },
          distributors: distributorOrders,
          processing: processing.value,
          comment,
        });
        if (status) {
          setNotification({
            title: t("distribution_list_sent", "Distribution List Sent"),
            description: t(
              "successfully_distribution_list_sent",
              "You successfully sent the distribution list"
            ),
            doneText: t("done", "Done"),
          });
        }
      }
    },
    [currentStep, documents, creatorOrder, distributorOrders, comment, onClose]
  );

  const onClickRemove = useCallback((e, index) => {
    e.preventDefault();
    setDeleteDistributorIndex(index);
    setVisibleDeleteDistributionModal(true);
  }, []);

  const removeDistributor = useCallback(() => {
    const orders = [...distributorOrders];
    orders.splice(deleteDistributorIndex, 1);
    setDistributorOrders(orders);
    setVisibleDeleteDistributionModal(false);
  }, [deleteDistributorIndex, distributorOrders]);

  return (
    <>
      <Modal className="print-documents-modal">
        <form className="print-documents-modal--wrapper" onSubmit={onSubmit}>
          <div className="print-documents-modal--header">
            {t("print_documents", "Print Documents")}
          </div>
          {!currentStep ? (
            <div className="print-documents-modal--content">
              <div className="print-documents-modal--content--top">
                <div className="print-documents-modal--content--top-section name-section">
                  <h5>{t("distribution_list", "Distribution List")}</h5>
                  <p>
                    {t(
                      "distribution_add_address",
                      "Add adresses to the distributer list"
                    )}
                  </p>
                  <AddMoreButton onClick={addDistributor} />
                </div>
                <div className="print-documents-modal--content--top-section">
                  <div className="print-documents-modal--distributor--name">
                    {currentCollaborator.firstName} {currentCollaborator.lastName}
                  </div>
                  <div className="print-documents-modal--distributor--company">
                    <strong>{t("company", "Company")}</strong> {companyName}
                  </div>
                  <div className="print-documents-modal--distributor--address">
                    <label htmlFor="creator-address">
                      {t("address", "Address")}
                    </label>
                    <input
                      id="creator-address"
                      className="form-control"
                      value={creatorOrder.address}
                      placeholder={t("add_address", "Add Address")}
                      onChange={(event) =>
                        creatorOrderChange(event.target.value, "address")
                      }
                      required
                    />
                  </div>
                  <div className="print-documents-modal--distributor--delivery">
                    <CustomSelect
                      values={deliveryOptions}
                      name="role[]"
                      value={creatorOrder.deliveryMethod}
                      onChange={(value) =>
                        creatorOrderChange(value, "deliveryMethod")
                      }
                      isModel={true}
                    />
                  </div>
                </div>
                {distributorOrders.map((order, index) => (
                  <div
                    className="print-documents-modal--content--top-section"
                    key={`distributor_order_${index}`}
                  >
                    <div className="print-documents-modal--creator">
                      <div className="print-documents-modal--distributor--name">
                        <input
                          className="form-control"
                          list={"dist-list-" + index}
                          value={order.userName}
                          onChange={(event) =>
                            distributorOrderChange(event, index, "userName")
                          }
                          required
                        />
                        <datalist id={"dist-list-" + index}>
                          {distributors.map((user) => (
                            <option
                              key={user.id}
                              value={`${user.firstName} ${user.lastName}`}
                            >
                              {user.email}
                            </option>
                          ))}
                        </datalist>
                        <IconButton
                          className="btn-close"
                          onClick={(e) => onClickRemove(e, index)}
                          iconId="close"
                        />
                      </div>
                      <div className="print-documents-modal--distributor--company">
                        <input
                          className="form-control"
                          value={order.companyName}
                          placeholder={t("add_company", "Add Company")}
                          onChange={(event) =>
                            distributorOrderChange(event, index, "companyName")
                          }
                          required
                        />
                      </div>
                      <div className="print-documents-modal--distributor--address">
                        <label htmlFor="creator-address">
                          {t("address", "Address")}
                        </label>
                        <input
                          id="creator-address"
                          className="form-control"
                          value={order.address}
                          placeholder={t("add_address", "Add Address")}
                          onChange={(event) =>
                            distributorOrderChange(event, index, "address")
                          }
                          required
                        />
                      </div>
                      <div className="print-documents-modal--distributor--delivery">
                        {t("A-Post", "A-Post")}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="print-documents-modal--content--bottom">
                <table className="name-table">
                  <thead>
                    <tr>
                      <th>{t("document_name", "Document name")}</th>
                      <th className="text-center">{t("format", "Format")}</th>
                      <th className="text-center">{t("version", "Version")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documents.map((document) => (
                      <tr key={document.id}>
                        <td>{document.name}</td>
                        <td className="text-center">
                          {FILE_TYPES[document.fileType].label}
                        </td>
                        <td className="text-center">
                          {`v${document.versionNumber}`}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <table className="creator-table">
                  <thead>
                    <th>{t("add_amount", "Add Amount")}</th>
                  </thead>
                  <tbody>
                    {documents.map((document, index) => (
                      <tr key={document.id}>
                        <td className="text-center">
                          <input
                            type="number"
                            className="form-control"
                            value={creatorOrder.documentQuantities[index]}
                            onChange={(e) =>
                              creatorOrderQuantityChange(e, index)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {distributorOrders.map((order, orderIndex) => (
                  <table
                    key={`distributor_table_${orderIndex}`}
                    className="distributor-table"
                  >
                    <thead>
                      <th>{t("add_amount", "Add Amount")}</th>
                    </thead>
                    <tbody>
                      {documents.map((document, index) => (
                        <tr key={document.id}>
                          <td
                            className="text-center"
                            key={`distributor_order_column_${orderIndex}`}
                          >
                            <input
                              type="number"
                              className="form-control"
                              value={order.documentQuantities[index]}
                              onChange={(value) =>
                                distributorOrderQuantityChange(
                                  value,
                                  orderIndex,
                                  index
                                )
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ))}
                <table className="total-table">
                  <thead>
                    <th>{t("total", "Total")}</th>
                  </thead>
                  <tbody>
                    {documents.map((document, index) => (
                      <tr key={document.id}>
                        <td className="text-center">{total[index]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="print-documents-modal--delivery-information">
              <div className="print-documents-modal--delivery-information--left">
                <table>
                  <thead>
                    <tr>
                      <th>{t("document_name", "Document name")}</th>
                      <th className="text-center">{t("format", "Format")}</th>
                      <th className="text-center">{t("version", "Version")}</th>
                      <th className="text-center">{t("total", "Total")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documents.map((document, index) => (
                      <tr key={index}>
                        <td>{document.name}</td>
                        <td className="text-center">
                          {FILE_TYPES[document.fileType].label}
                        </td>
                        <td className="text-center">{`v${document.versionNumber}`}</td>
                        <td className="text-center">{total[index]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="print-documents-modal--delivery-information--right">
                <h5>{t("deliver_information", "Delivery Information")}</h5>
                <div className="print-documents-modal--delivery-information--processing">
                  <label>{t("finish", "Finish")}</label>
                  <CustomSelect
                    values={processOptions}
                    value={processing}
                    onChange={setProcessing}
                    isModel={true}
                  />
                </div>
                <div className="print-documents-modal--delivery-information--comments">
                  <label>{t("comments", "Comments")}</label>
                  <textarea
                    className="form-control"
                    cols={12}
                    rows={6}
                    value={comment}
                    onChange={(event) => setComment(event.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
          )}
          <div className="print-documents-modal--footer">
            <button className="btn btn--secondary" onClick={onBack}>
              {t(!currentStep ? "close" : "back")}
            </button>
            <input
              className="btn btn--primary"
              type="submit"
              value={t(!currentStep ? "next" : "send")}
            />
          </div>
        </form>
      </Modal>
      {!!notification && (
        <NotificationModal
          onDone={() => {
            setNotification(null);
            onClose();
          }}
          notification={notification}
        />
      )}
      {isVisibleDeleteDistributionModal && (
        <ConfirmModal
          title={t("delete_title_last", "Are you sure?")}
          confirmText={sprintf(
            t(
              "delete_distribution_list",
              "You are about to <b> delete %1$s </b> from the distribution list."
            ),
            distributorOrders[deleteDistributorIndex].userName
          )}
          description=""
          yesText={t("yes", "Yes")}
          noText={t("no", "No")}
          onYes={removeDistributor}
          onNo={() => setVisibleDeleteDistributionModal(false)}
          isButtonAlignCenter={true}
        />
      )}
    </>
  );
};

export default DocumentsPrintModal;
