import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import VerticalDivider from 'common/styled/VerticalDivider';
import HeaderBox from 'common/components/HeaderBox';
import EmailsSearchTextField from 'emails/components/EmailsSearchTextField';
import RoleAction from 'projects/types/RoleAction';
import useCurrentCollaboratorRole from 'collaborators/hooks/useCurrentCollaboratorRole';
import ProjectEmailInfoPanel from './ProjectEmailInfoPanel';
import EmailDropTarget from './EmailDropTarget';

export default function EmailsPageHeader() {
  const { t } = useTranslation('emails');
  const currentUserRole = useCurrentCollaboratorRole();

  return (
    <HeaderBox id="EmailsPageHeader">
      <Typography variant="h2" id="EmailsPageHeaderTitle" sx={{ width: 292, pl: 4 }}>{t('Emails-page-header_title', 'E-Mails')}</Typography>
      <VerticalDivider sx={{ height: 36 }} />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <EmailsSearchTextField />
        <ProjectEmailInfoPanel />
        {!!currentUserRole?.allowedActions?.has(RoleAction.Email_Upload) && <EmailDropTarget />}
      </Box>
    </HeaderBox>
  );
}
