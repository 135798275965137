import IssueDto from 'issues/types/IssueDto';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type ColumnDisplayNamesByFieldName = {
  [P in keyof IssueDto]?: string;
};

export default function useColumnDisplayNamesByFieldName() {
  const { t } = useTranslation('issues');
  return useMemo<ColumnDisplayNamesByFieldName>(() => ({
    issueNumber: t('use-data-grid-column-field-name_issue-number-column-header', 'No.'),
    thumbnailImageId: t('use-data-grid-column-field-name_thumbnail-image-id-column-header', 'Image'),
    title: t('use-data-grid-column-field-name_title-column-header', 'Title'),
    createAuthor: t('use-data-grid-column-field-name_create-author-column-header', 'Created By'),
    assignedUsers: t('use-data-grid-column-field-name_assigned-users-column-header', 'Assigned To'),
    editAuthor: t('use-data-grid-column-field-name_edit-author-column-header', 'Last Edited By'),
    reviewer: t('use-data-grid-column-field-name_reviewer-column-header', 'Reviewer'),
    creationDate: t('use-data-grid-column-field-name_creation-date-column-header', 'Creation Date'),
    editDate: t('use-data-grid-column-field-name_edit-date-column-header', 'Edit Date'),
    dueDate: t('use-data-grid-column-field-name_due-date-column-header', 'Due Date'),
    description: t('use-data-grid-column-field-name_description-column-header', 'Description'),
    disciplines: t('use-data-grid-column-field-name_disciplines-column-header', 'Disciplines'),
    buildings: t('use-data-grid-column-field-name_buildings-column-header', 'Buildings'),
    floors: t('use-data-grid-column-field-name_floors-column-header', 'Floors'),
    tags: t('use-data-grid-column-field-name_tags-column-header', 'Tags'),
    workPhase: t('use-data-grid-column-field-name_work-phase-column-header', 'Work Phase'),
    startingDate: t('use-data-grid-column-field-name_starting-date-column-header', 'Start Date'),
    issueStatus: t('use-data-grid-column-field-name_issue-status-column-header', 'Status'),
    issueType: t('use-data-grid-column-field-name_issue-type-column-header', 'Type'),
    issuePriority: t('use-data-grid-column-field-name_issue-priority-column-header', 'Priority'),
    linkedDocumentVersionIds: t('use-data-grid-column-field-name_linked-document-versions-column-header', 'Documents'),
    models: t('use-data-grid-column-field-name_models-column-header', 'Models'),
    viewpointIds: t('use-data-grid-column-field-name_viewpoints-column-header', 'Viewpoints'),
    source: t('use-data-grid-column-field-name_source-column-header', 'Source'),
    visibility: t('use-data-grid-column-field-name_visibility-column-header', 'Visibility'),
    linkedEmailIds: t('use-data-grid-column-field-name_linked-emails-column-header', 'Linked Emails'),
    commentIds: t('use-data-grid-column-field-name_comments-column-header', 'Comments'),
    linkedComponentsGlobalIds: t('use-data-grid-column-field-name_components-column-header', 'Components'),
  }), [t]);
}
