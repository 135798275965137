import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ButtonBase, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import DocumentUploadContext, { DocumentUploadContextState } from '../contexts/DocumentUploadContext';

interface UploadStatusDisplayProps {
  sx?: SxProps<Theme>;
}

export default function UploadStatusDisplay({
  sx,
}: UploadStatusDisplayProps) {
  const { uploadingFileHandles, openWizard } = useContext<DocumentUploadContextState>(DocumentUploadContext);
  const { t } = useTranslation('upload');

  const totalProgress = useMemo(() => {
    const totalSize = uploadingFileHandles.reduce((prev, curr) => prev + curr.total, 0);
    if (totalSize === 0) return uploadingFileHandles.length > 0 ? 99 : 0;
    const totalLoaded = uploadingFileHandles.reduce((prev, curr) => prev + curr.loaded, 0) || 0;
    return Math.min(99, (100 * totalLoaded) / totalSize); // we never show 100% until all uploads are actually done
  }, [uploadingFileHandles]);

  const allCompleted = useMemo(() => uploadingFileHandles.every((handle) => handle.isCompleted), [uploadingFileHandles]);
  const allSettled = useMemo(() => uploadingFileHandles.every((handle) => handle.isCompleted || handle.isFailed), [uploadingFileHandles]);

  // don't render if there is no upload in progress
  if (uploadingFileHandles.length === 0) return null;

  return (
    <ButtonBase
      onClick={openWizard}
      sx={{
        ...sx,
        px: 2,
        height: '100%',
        display: 'block',
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Typography>
          {allCompleted
            ? t('status-caption-progress-done', 'Uploading {{count}} file(s) done', { count: uploadingFileHandles.length })
            : allSettled
              ? t('status-caption-progress-errors', 'Uploading finished with errors')
              : t('status-caption-progress', 'Uploading {{count}} file(s) ({{percent}}%)', { count: uploadingFileHandles.length, percent: totalProgress.toFixed(0) })}
        </Typography>
      </Box>
      <Box sx={{ width: '100%' }}>
        <LinearProgress variant="determinate" value={totalProgress} />
      </Box>
    </ButtonBase>
  );
}

UploadStatusDisplay.defaultProps = {
  sx: undefined,
};
