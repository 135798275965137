import React from 'react';
import ISxProps from 'common/types/ISxProps';
import IssueDto from 'issues/types/IssueDto';
import useIssuesBoardDragAndDropContext from 'issues/hooks/useIssuesBoardDragAndDropContext';
import { useDrag } from 'react-dnd';
import { Box, useTheme } from '@mui/material';
import IssueItemCard from 'issues/components/IssueItemCard';
import useIssueStatusEditingRoleRight from 'issues/hooks/useIssueStatusEditingRoleRight';

interface DraggableIssueItemCardProps extends ISxProps {
  issue: IssueDto,
  onClick?: (issueId: string, ctrlKey?: boolean | undefined) => void,
  selected?: boolean | undefined,
  isDragSource?: boolean | undefined,
}

export default function DraggableIssueItemCard({
  sx,
  issue,
  onClick,
  selected,
  isDragSource,
}: DraggableIssueItemCardProps) {
  const theme = useTheme();
  const { setDraggedIssueId, draggedIssueId, setTemporaryStatusId } = useIssuesBoardDragAndDropContext();
  const canEditStatus = useIssueStatusEditingRoleRight(issue.id);
  const [, dragRef] = useDrag<IssueDto>(() => ({
    type: 'IssueItemCard',
    canDrag: canEditStatus ?? false,
    item: () => {
      setDraggedIssueId(issue.id);
      return issue;
    },
    end: () => {
      setTemporaryStatusId(issue.id, undefined);
      setDraggedIssueId(undefined);
    },
  }), [issue, setDraggedIssueId, canEditStatus]);
  return (
    <Box
      id="DraggableIssueItemCard"
      ref={dragRef}
      sx={{
        ...(issue.id === draggedIssueId ? {
          outlineWidth: '4px',
          outlineStyle: 'solid',
          outlineColor: `${theme.palette.primary.main}55`,
        } : {}),
        ...(isDragSource ? {
          opacity: 0.5,
        } : {}),
        borderRadius: '6px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <IssueItemCard issue={issue} onClick={onClick} sx={sx} selected={selected} />
    </Box>
  );
}
