import React from 'react';
import { Box, Typography } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import LabelChip from 'labels/components/LabelChip';
import BuildingModelFilesGroup from 'models/types/BuildingModelFilesGroup';
import FloorModelFilesGroupItem from 'models/components/FloorModelFilesGroupItem';

interface BuildingModelFilesGroupItemProps extends ISxProps {
  group: BuildingModelFilesGroup,
}

export default function BuildingModelFilesGroupItem({
  sx,
  group,
}: BuildingModelFilesGroupItemProps) {
  return (
    <Box id="BuildingModelFilesGroupItem" sx={{ ...sx, display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'baseline' }}>
        <LabelChip label={group.building} abbreviated />
        <Typography variant="h6">{group.building.name}</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, pl: 2 }}>
        {group.floorGroups.map((floorGroup) => <FloorModelFilesGroupItem key={floorGroup.floor.id} group={floorGroup} />)}
      </Box>
    </Box>
  );
}
