import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import ISxProps from 'common/types/ISxProps';
import useModelsInteractionContext from 'models/hooks/useModelsInteractionContext';
import { ModelsInteractionMode } from 'models/contexts/ModelsInteractionContext';
import CreateIssuePanel from 'issues/components/CreateIssuePanel';
import LinkedComponentListItem, { ComponentLinkAction } from 'issues/components/LinkedComponentListItem';

export default function CreateIssueLinkedComponentsPanel({
  sx,
}: ISxProps) {
  const { t } = useTranslation('issues');
  const { linkedComponentItems, setInteractionMode } = useModelsInteractionContext();
  const onClickAddComponents = useCallback(() => {
    setInteractionMode(ModelsInteractionMode.LinkedComponentManagement);
  }, [setInteractionMode]);
  return (
    <CreateIssuePanel id="CreateIssueLinkedComponentsPanel" sx={sx}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography variant="h4">
          {t('create-issue-linked-components-panel_components-panel-header', 'Components')}
        </Typography>
        <Button onClick={onClickAddComponents} variant="contained" color="secondary" sx={{ gap: 0.5, pl: 0.5 }} size="small">
          <Icon path={mdiPencil} size={0.75} />
          {t('create-issue-linked-components-panel_add-component-button-label', 'Edit')}
        </Button>
      </Box>
      <Box sx={{ pt: 1, display: 'flex', flexDirection: 'column' }}>
        {linkedComponentItems.map(({ globalId, elementName }) => (
          <LinkedComponentListItem
            key={globalId}
            globalId={globalId}
            elementName={elementName}
            nodeLoaded
            pendingAction={ComponentLinkAction.Add}
          />
        ))}
      </Box>
    </CreateIssuePanel>
  );
}
