import type { Theme, ComponentsProps, ComponentsVariants } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

interface MuiInputLabelComponent {
  defaultProps?: ComponentsProps['MuiInputLabel'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiInputLabel'];
  variants?: ComponentsVariants['MuiInputLabel'];
}

const VisoInputLabel: MuiInputLabelComponent = {
  styleOverrides: {
    root: ({ theme }) => ({
      transform: 'translate(10px, 8px)',
      fontSize: theme.typography.fontSize,
    }),
    shrink: () => ({
      transform: 'translate(10px, -9px)',
      fontSize: 12,
    }),
  },
};

export default VisoInputLabel;
