import React from 'react';
import { Box, Button, Divider, Modal, Typography, useTheme } from '@mui/material';
import ModelsPanelToolbar from 'models/components/ModelsPanelToolbar';
import ModelSelectionContextProvider from 'models/contexts/ModelSelectionContextProvider';
import ModelsPanelDockviewContextProvider from 'models/contexts/ModelsPanelDockviewContextProvider';
import Viewer3dContextProvider from 'models/contexts/Viewer3dContextProvider';
import ModelsPanel from 'models/components/ModelsPanel';
import { useTranslation } from 'react-i18next';
import ViewpointDto from 'issues/types/ViewpointDto';

interface ModelsDialogProps {
  title: string,
  initiallySelectedViewpoint?: ViewpointDto | undefined,
  initiallySelectedModelFileIds?: string[],
  selectedIssueId: string | undefined,
  setSelectedIssueId: (value: string | undefined) => void,
  onClose?: () => void,
}

export default function ModelsDialog({
  title,
  initiallySelectedViewpoint,
  initiallySelectedModelFileIds,
  selectedIssueId,
  setSelectedIssueId,
  onClose,
}: ModelsDialogProps) {
  const theme = useTheme();
  const { t } = useTranslation('models');
  return (
    <Modal id="ModelsDialog" open sx={{ height: '100%', display: 'flex', alignItems: 'stretch' }}>
      <ModelSelectionContextProvider
        initiallySelectedViewpoint={initiallySelectedViewpoint}
        initiallySelectedModelFileIds={initiallySelectedModelFileIds}
        selectedIssueId={selectedIssueId}
        setSelectedIssueId={setSelectedIssueId}
      >
        <ModelsPanelDockviewContextProvider>
          <Viewer3dContextProvider>
            <Box sx={{
              flex: '1 1 0',
              m: 6,
              borderStyle: 'solid',
              borderWidth: 1,
              borderColor: theme.palette.divider,
              borderRadius: 4,
              backgroundColor: theme.palette.background.default,
              display: 'flex',
              flexDirection: 'column',
            }}
            >
              <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ flex: '0 0 0', p: 2, px: 3, gap: 3, display: 'grid', gridTemplateColumns: '1fr auto 1fr', alignItems: 'center' }}>
                  <Typography variant="h3">{title}</Typography>
                  <ModelsPanelToolbar />
                  {!!onClose && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        variant="outlined"
                        onClick={onClose}
                      >
                        {t('models-dialog_close-button-label', 'Close')}
                      </Button>
                    </Box>
                  )}
                </Box>
                <Divider />
                <ModelsPanel sx={{ borderBottomLeftRadius: '16px', borderBottomRightRadius: '16px' }} />
              </Box>
            </Box>
          </Viewer3dContextProvider>
        </ModelsPanelDockviewContextProvider>
      </ModelSelectionContextProvider>
    </Modal>
  );
}
