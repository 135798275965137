import React, { Suspense, useCallback, useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import IssuesFilterableListMenu from 'issues/components/IssuesFilterableListMenu';
import theme from 'theme';
import Color from 'color';
import { ITEM_ROOT } from 'odata-query';
import { ICellRendererParams } from 'ag-grid-community';
import { useNavigate } from 'react-router-dom';
import InboxEmailDto from 'emails/types/InboxEmailDto';

export default function EmailsLinkedIssuesCell({
  data, value,
}: ICellRendererParams<InboxEmailDto, string[] | undefined>) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navigate = useNavigate();

  const onCloseMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const onClickIssueItem = useCallback((issueId: string) => {
    setIsMenuOpen(false);
    if (!data) return;
    navigate(`${data.id}/issues/${issueId}`);
  }, [data, navigate]);

  return value?.length ? (
    <Box id="EmailsLinkedIssueCell">
      <Box sx={{ textAlign: 'center' }}>
        <Button
          ref={buttonRef}
          variant="contained"
          color="secondary"
          onClick={() => setIsMenuOpen(true)}
          sx={{
            backgroundColor: Color(theme.palette.info.main).lightness(92).hex(),
            fontWeight: 600,
            height: '24px',
            minWidth: 'unset',
            px: 1,
            py: 0,
          }}
        >
          {`+${value.length}`}
        </Button>
      </Box>
      <Suspense>
        <IssuesFilterableListMenu
          issuesOdataQuery={isMenuOpen ? { filter: { linkedEmailIds: { any: { [ITEM_ROOT]: data?.id } } } } : undefined}
          totalIssueCount={value.length}
          open={isMenuOpen}
          onClose={onCloseMenu}
          anchorEl={buttonRef.current}
          sx={{ minWidth: '300px', maxWidth: '500px' }}
          onClickItem={onClickIssueItem}
        />
      </Suspense>
    </Box>
  ) : null;
}
