import React, { useCallback, useState } from 'react';
import { Box, Modal } from '@mui/material';
import DocumentViewerContextProvider from 'documents-details/contexts/DocumentViewerContextProvider';
import DocumentDetails from 'documents-details/components/DocumentDetails';
import SuspenseWithErrorBoundary from 'error/SuspenseWithErrorBoundary';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import IssuesFilterContextProvider from 'issues/contexts/IssuesFilterContextProvider';
import useDocumentDetailsSelectionContext from 'documents-details/hooks/useDocumentDetailsSelectionContext';

export default function DocumentDetailsModal() {
  const { setDocumentVersionAndIssueId } = useDocumentDetailsSelectionContext();
  const [queryFilterId, setQueryFilterId] = useState<string | undefined>();
  const onClose = useCallback(() => {
    setDocumentVersionAndIssueId({ issueId: null, documentVersionId: null });
  }, [setDocumentVersionAndIssueId]);

  return (
    <Modal id="DocumentDetailsModal" open disablePortal>
      <Box sx={{ height: '100%' }}>
        <SuspenseWithErrorBoundary suspenseFallback={<CenteredCircularProgress />}>
          <IssuesFilterContextProvider queryFilterId={queryFilterId} setQueryFilterId={setQueryFilterId}>
            <DocumentViewerContextProvider>
              <DocumentDetails onClose={onClose} />
            </DocumentViewerContextProvider>
          </IssuesFilterContextProvider>
        </SuspenseWithErrorBoundary>
      </Box>
    </Modal>
  );
}
