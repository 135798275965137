import React, { useCallback, useState } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiDownload } from '@mdi/js';
import PluginsDownloadDialog from 'projects/components/PluginsDownloadDialog';

export default function PluginsDownloadButton(props: ButtonProps) {
  const { sx, size } = props;
  const { t } = useTranslation('projects');
  const [dialogOpen, setDialogOpen] = useState(false);
  const onClickOpenDialog = useCallback(() => setDialogOpen(true), []);
  const onCloseDialog = useCallback(() => setDialogOpen(false), []);
  return (
    <>
      <Button
        id="PluginsDownloadButton"
        onClick={onClickOpenDialog}
        sx={{ gap: 0.5, pl: size === 'small' ? 0.5 : 1, pr: size === 'small' ? 0.5 : undefined, ...sx }}
        {...props}
      >
        <Icon path={mdiDownload} size={0.75} />
        {t('plugins-download-button-label', 'Plug-Ins')}
      </Button>
      {dialogOpen && <PluginsDownloadDialog onClose={onCloseDialog} />}
    </>
  );
}
