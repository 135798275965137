import { useMemo } from 'react';
import useFolderTreeQuery from 'documents-folders/hooks/useFolderTreeQuery';
import useFolderAccessLevel from 'documents-folders/hooks/useFolderAccessLevel';

export default function useCurrentCollaboratorFolderAccessLevel(folderId: string | undefined) {
  const { data: folderTreeData } = useFolderTreeQuery();
  const getFolderAccessLevel = useFolderAccessLevel();
  return useMemo(() => {
    if (!folderTreeData || !folderId) return undefined;
    const folder = folderTreeData.foldersById.get(folderId);
    if (!folder) return undefined;
    return getFolderAccessLevel(folder);
  }, [folderId, folderTreeData, getFolderAccessLevel]);
}
