import React, { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getGeneralSvg } from "../../Components/SVGs";

const visoplan_download_link = "https://update.visoplan.de/release/Setup.exe";

const OpenWindowsClientContainer = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const openVisoplanForWindows = useCallback(() => {
      const parameters = location.hash.substring(1);
      window.open(`visoplan://${parameters}`, '_self');
  }, []);

  useEffect(() => {
    openVisoplanForWindows();
  }, [openVisoplanForWindows]);

  return (
    <div className="open_windows_client">
      <div className="open_windows_client-card">
        <div className="open_windows_client-card-content">
          <div className="icon">
            {getGeneralSvg("model_open_windows_client")}
          </div>
          <h1>{t("full_3d_viewer", "Full 3D Viewer")}</h1>
          <span>
            <h2>
              {t(
                "open_explainer",
                "If the 3D Viewer of Visoplan did not open itself, download and install it from the link below and try again."
              )}
            </h2>
          </span>
          <button
            id="TryAgainWindowsClientButton"
            className="btn btn--primary"
            onClick={openVisoplanForWindows}
          >
            {t("try_again", "Try again")}
          </button>
        </div>
        <div className="open_windows_client-card-divider" />
        <div className="open_windows_client-card-footer">
          <h3
            dangerouslySetInnerHTML={{
              __html: sprintf(
                t(
                  "visoplan_download_link",
                  'Haven\'t downloaded Visoplan yet? You can use <a href="%1$s">this link</a> to download and install Visoplan.'
                ),
                visoplan_download_link
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OpenWindowsClientContainer;
