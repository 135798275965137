import React, {
  ReactNode, Suspense, useContext, useState,
} from 'react';
import { Dialog } from '@mui/material';
import DocumentUploadContext, { DocumentUploadContextState } from 'upload/contexts/DocumentUploadContext';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import InformUsersPage from './InformUsersPage';
import ValidateStagedUploadsPage from './ValidateStagedUploadsPage';
import UploadProgressPage from './UploadProgressPage';

interface DocumentUploadWizardModalProps {
  hideWizard: () => void,
}

export default function DocumentUploadWizardModal({
  hideWizard,
}: DocumentUploadWizardModalProps) {
  const { uploadingFileHandles, clear } = useContext<DocumentUploadContextState>(DocumentUploadContext);
  const [isOnInformUsersPage, setIsOnInformUsersPage] = useState(false);

  let page: ReactNode | null = null;
  if (isOnInformUsersPage) {
    page = <InformUsersPage onClose={() => setIsOnInformUsersPage(false)} />;
  } else if (uploadingFileHandles.length > 0) {
    page = <UploadProgressPage onFinished={clear} goToInformUsersPage={() => setIsOnInformUsersPage(true)} onMinimize={hideWizard} />;
  } else {
    page = <ValidateStagedUploadsPage cancel={clear} />;
  }

  return (
    <Dialog
      open
      scroll="paper"
      PaperProps={{ style: { minWidth: '1200px', maxWidth: 'revert', height: '791px' } }}
      className="document-upload-wizard-modal"
    >
      <Suspense fallback={(<CenteredCircularProgress />)}>
        {page}
      </Suspense>
    </Dialog>
  );
}
