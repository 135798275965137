import React, { useCallback, useContext, useMemo } from 'react';
import {
  Box, FormControl, InputLabel, Select, MenuItem, Button,
} from '@mui/material';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { useTranslation } from 'react-i18next';
import ISxProps from 'common/types/ISxProps';
import LabelDto from 'labels/types/LabelDto';
import DocumentOptionsContext, { DocumentOptionsContextState } from 'documents/contexts/DocumentOptionsContext';
import DocumentAbbreviationDisplayMode from 'documents/types/DocumentAbbreviationDisplayMode';
import ColoredChip from 'common/components/ColoredChip';

interface MassEditMetaDataSelectProps extends ISxProps {
  label: string,
  selectedId: string | undefined,
  onChange: (id: string | undefined) => void,
  disabled?: boolean,
  renderValuesAsChips?: boolean,
  availableEntities: LabelDto[] | undefined,
}

export default function MassEditSelectFormControl({
  renderValuesAsChips,
  availableEntities,
  label,
  selectedId,
  onChange,
  disabled,
  sx,
}: MassEditMetaDataSelectProps) {
  const { t } = useTranslation('documents');
  const entitiesById = useMemo(() => (availableEntities ? new Map(availableEntities.map((entity) => [entity.id, entity])) : undefined), [availableEntities]);
  const { abbreviationDisplayMode } = useContext<DocumentOptionsContextState>(DocumentOptionsContext);

  const onChangeSelectedId = useCallback((id: string) => {
    if (id === 'empty') onChange('');
    else onChange(id);
  }, [onChange]);
  const getLabel = useCallback((entity: LabelDto | undefined) => {
    if (!entity) return undefined;
    if (abbreviationDisplayMode === DocumentAbbreviationDisplayMode.Abbreviation && entity.abbreviation) return entity.abbreviation;
    return entity.name;
  }, [abbreviationDisplayMode]);
  return (
    <Box sx={{ ...sx, display: 'flex', alignItems: 'flex-end' }}>
      <FormControl sx={{ flexGrow: 1 }}>
        <InputLabel id="mass-edit-drawer_metadata-select-label" shrink>{label}</InputLabel>
        <Select
          labelId="mass-edit-drawer_metadata-select-label"
          label={label}
          value={selectedId ?? ''}
          disabled={disabled}
          onChange={(e) => onChangeSelectedId(e.target.value)}
          renderValue={(id) => (
            (selectedId === undefined)
              ? <em>{t('mass-edit-select-form-control_unchanged-label', 'Unchanged')}</em>
              : (selectedId.length === 0)
                ? <em>{t('mass-edit-select-form-control_empty-value-label', 'Set empty value')}</em>
                : renderValuesAsChips && entitiesById?.has(id)
                  ? <ColoredChip entity={entitiesById?.get(id)!} />
                  : getLabel(entitiesById?.get(id))
          )}
          displayEmpty
          notched
        >
          <MenuItem value="empty"><em>{t('mass-edit-select-form-control_empty-value-label', 'Set empty value')}</em></MenuItem>
          {availableEntities?.map((entity) => (
            <MenuItem value={entity.id} key={entity.id}>
              {renderValuesAsChips
                ? <ColoredChip entity={entity} />
                : getLabel(entity)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box title={t('mass-edit-drawer_metadata-select_set-unchanged-button-tooltip', 'Set field unchanged')}>
        <Button
          sx={{ minWidth: 'unset', ml: 1, px: 1 }}
          color="secondary"
          variant="contained"
          onClick={() => onChange(undefined)}
          disabled={selectedId === undefined}
        >
          <BackspaceIcon />
        </Button>
      </Box>
    </Box>
  );
}
