import { Box, styled } from '@mui/material';

const HeaderBox = styled(Box)(({ theme }) => ({
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  borderBottomColor: theme.palette.divider,
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  zIndex: 1,
}));

export default HeaderBox;
