import React, {
  useCallback, useContext, useMemo, useState,
} from 'react';
import {
  Alert,
  Box, Button, DialogActions, DialogContent, DialogTitle, Typography,
} from '@mui/material';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import { useTranslation } from 'react-i18next';
import CreateShareLinkMessageControl from 'share/components/CreateShareLinkMessageControl';
import ShareDocumentsFeedbackDialog from 'share/components/ShareDocumentsFeedbackDialog';
import useDocumentsShareLinkApi from 'share/hooks/useDocumentsShareLinkApi';
import ShareLinkMessage from 'share/types/ShareLinkMessage';
import DocumentUploadContext, { DocumentUploadContextState } from 'upload/contexts/DocumentUploadContext';

interface InformUsersPageProps {
  onClose: () => void,
}

export default function InformUsersPage({
  onClose,
}: InformUsersPageProps) {
  const { t } = useTranslation('upload');
  const { sendShareLinkMessage } = useDocumentsShareLinkApi();
  const { uploadingFileHandles } = useContext<DocumentUploadContextState>(DocumentUploadContext);
  const [shareLinkMessage, setShareLinkMessage] = useState<ShareLinkMessage | undefined>({
    collaboratorIds: [],
    userGroupIds: [],
    externalEmailAddresses: [],
    comment: '',
    expirationDurationDays: 1,
  });
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const getRequestErrorMessage = useRequestErrorMessage();
  const [isRequestInFlight, setIsRequestInFlight] = useState(false);

  const onSubmit = useCallback(async () => {
    if (uploadingFileHandles.some((handle) => !handle.documentVersionId)) return;
    if (!shareLinkMessage) return;
    const documentVersionIds = uploadingFileHandles.map((handle) => handle.documentVersionId!);
    setIsRequestInFlight(true);
    try {
      await sendShareLinkMessage(shareLinkMessage, documentVersionIds, 'sendinfomail');
      setIsSuccessDialogOpen(true);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    } finally {
      setIsRequestInFlight(false);
    }
  }, [getRequestErrorMessage, sendShareLinkMessage, shareLinkMessage, uploadingFileHandles]);

  const canConfirm = useMemo(() => {
    if (!shareLinkMessage || isRequestInFlight) return false;
    return shareLinkMessage.collaboratorIds.length > 0 || shareLinkMessage.externalEmailAddresses.length > 0 || shareLinkMessage.userGroupIds.length > 0;
  }, [isRequestInFlight, shareLinkMessage]);

  return (
    <>
      <DialogTitle
        id="InformUsersPage"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          py: 4,
        }}
      >
        <Box>{t('upload-wizard_inform-users_header', '{{count}} Documents uploaded', { count: uploadingFileHandles.length })}</Box>
        <Typography sx={{ width: '300px', mt: 2 }}>{t('upload-wizard_inform-users_prompt', 'You successfully uploaded your documents. Do you want to inform project users?')}</Typography>
      </DialogTitle>
      <DialogContent>
        <CreateShareLinkMessageControl value={shareLinkMessage} onChange={setShareLinkMessage} sx={{ height: '100%', overflow: 'revert' }} />
      </DialogContent>
      {!!errorMessage && <DialogContent sx={{ pt: 0, pb: 1, flexGrow: 0 }}><Alert severity="error">{errorMessage}</Alert></DialogContent>}
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={onClose}
          sx={{ mr: 'auto' }}
        >
          {t('upload-wizard_inform-users_abort', 'Back')}
        </Button>
        <Button
          variant="contained"
          onClick={onSubmit}
          disabled={!canConfirm}
        >
          {t('upload-wizard_inform-users_confirm', 'Inform Users')}
        </Button>
      </DialogActions>
      {isSuccessDialogOpen && <ShareDocumentsFeedbackDialog onClose={onClose} documentCount={uploadingFileHandles.length} />}
    </>
  );
}
