import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Box, Button, Menu, MenuItem, MenuList, Typography } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiDownload } from '@mdi/js';
import PluginVersion from 'projects/types/PluginVersion';

interface PluginsDownloadItemProps extends ISxProps {
  svgPath: string,
  label: string,
  versions: PluginVersion[],
}

export default function PluginsDownloadItem({
  sx,
  svgPath,
  label,
  versions,
}: PluginsDownloadItemProps) {
  const { t } = useTranslation('projects');
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const onClickOpenMenu = useCallback(() => setMenuOpen(true), []);
  const onCloseMenu = useCallback(() => setMenuOpen(false), []);
  const download = useCallback(async (url: string) => {
    const a = document.createElement('a');
    a.href = url;
    a.download = url;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }, []);
  const versionItems = useMemo(() => versions.map(({ name, url }) => ({
    name,
    onClick: () => {
      onCloseMenu();
      download(url);
    },
  })), [download, onCloseMenu, versions]);
  return (
    <Box
      id="PluginsDownloadItem"
      sx={{
        width: 230,
        borderRadius: '16px',
        boxShadow: '0 0 16px -8px rgba(0,0,0,0.4)',
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 2,
        ...sx,
      }}
    >
      <img src={svgPath} alt="logo" style={{ height: 50 }} />
      <Box>
        <Typography variant="h4">{label}</Typography>
        <Typography>{t('plugins-download-item_plugin-subtitle', 'Plug-In')}</Typography>
      </Box>
      {versionItems.length === 1 && (
        <Button variant="outlined" onClick={versionItems[0].onClick} ref={buttonRef} sx={{ alignSelf: 'stretch', gap: 1 }}>
          <Icon path={mdiDownload} size={0.75} />
          {t('plugins-download-item_download-button-label', 'Download')}
        </Button>
      )}
      {versionItems.length > 1 && (
        <>
          <Button variant="outlined" onClick={onClickOpenMenu} ref={buttonRef} sx={{ alignSelf: 'stretch' }}>
            {t('plugins-download-item_select-version-button-label', 'Select Version')}
          </Button>
          <Menu
            anchorEl={buttonRef.current}
            open={menuOpen}
            onClose={onCloseMenu}
          >
            <MenuList dense>
              {versionItems.map(({ name, onClick }) => (
                <MenuItem onClick={onClick} sx={{ display: 'flex', gap: 1, pl: 1 }}>
                  <Icon path={mdiDownload} size={0.75} />
                  {name}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </>
      )}
    </Box>
  );
}
