import React, { useCallback, useEffect, useState } from 'react';
import { useTheme, Box, Typography, Switch, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import ProjectUpdateAuthDto from 'projects/types/ProjectUpdateAuthDto';
import useProjectUpdateAuthMutation from 'projects/hooks/useProjectUpdateAuthMutation';

export default function ProjectSettingsTwoFactorAuthSection() {
  const { t } = useTranslation('settings');
  const theme = useTheme();
  const { data: currentProject } = useCurrentProjectQuery();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [displaySuccessIndicator, setDisplaySuccessIndicator] = useState<boolean>(false);
  const getRequestErrorMessage = useRequestErrorMessage();
  const { mutateAsync, isPending } = useProjectUpdateAuthMutation();

  const [displayValue, setDisplayValue] = useState(currentProject?.twoFactorRequired);

  useEffect(() => {
    if (!currentProject) return;
    setDisplayValue(currentProject.twoFactorRequired);
  }, [currentProject]);

  const onChangeSwitch = useCallback(async () => {
    if (!currentProject) return;
    setErrorMessage(undefined);
    const value = !currentProject.twoFactorRequired;
    const projectUpdateAuthDto: ProjectUpdateAuthDto = {
      id: currentProject.id,
      twoFactorRequired: value,
    };
    try {
      setDisplayValue(value);
      await mutateAsync(projectUpdateAuthDto);
      setDisplaySuccessIndicator(true);
      setTimeout(() => setDisplaySuccessIndicator(false), 1000);
    } catch (error) {
      setDisplayValue(currentProject.twoFactorRequired);
      const m = getRequestErrorMessage(error);
      setErrorMessage(m);
      setTimeout(() => setErrorMessage(undefined), 6000);
    }
  }, [currentProject, getRequestErrorMessage, mutateAsync]);

  return (
    <Box
      id="ProjectSettingsTwoFactorAuthSection"
      sx={{ display: 'flex', gap: 4, alignItems: 'center' }}
    >
      <Box sx={{ flex: '1 1 0', display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <Typography variant="h4">{t('project-settings-tab-pane_two-factor-auth-header', 'Require Two-Factor Authentication')}</Typography>
        <Typography>{t('project-settings-tab-pane_two-factor-auth-description', 'When enabled, collaborators must have set up two-factor authentication on their user account in order to access the project.')}</Typography>
      </Box>
      <Box sx={{ flex: '0 0 0', display: 'flex', alignItems: 'center', mt: 1, maxWidth: '520px' }}>
        {isPending && <CircularProgress size={12} sx={{ mr: 1 }} />}
        <Typography sx={{ color: theme.palette.error.main, mr: 1 }}>{errorMessage ?? ''}</Typography>
        <Switch color={displaySuccessIndicator ? 'success' : errorMessage ? 'error' : undefined} checked={displayValue} disabled={isPending} onChange={onChangeSwitch} />
      </Box>
    </Box>
  );
}
