enum DocumentScopeKey {
  ProjectDocuments = 'all',
  MyUploads = 'my-uploads',
  Archive = 'archive',
  Folder = 'folder',
  Filter = 'filter',
  Planlist = 'planlist',
}

export default DocumentScopeKey;
