import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export default function DashboardIcon({
  htmlColor,
  fontSize,
}: Pick<SvgIconProps, 'htmlColor' | 'fontSize'>) {
  return (
    <SvgIcon htmlColor={htmlColor} fontSize={fontSize} viewBox="0 0 16 14">
      <path d="M1.23077 0C0.550769 0 0 0.569545 0 1.27273V3.18182H6.76923H16V2.54545C16 1.84227 15.4492 1.27273 14.7692 1.27273H5.66106L5.28125 0.61772C5.0591 0.234629 4.65858 0 4.22596 0H1.23077ZM0.615385 4.45455C0.275692 4.45455 0 4.73964 0 5.09091V12.7273C0 13.4305 0.550769 14 1.23077 14H14.7692C15.4492 14 16 13.4305 16 12.7273V5.09091C16 4.73964 15.7243 4.45455 15.3846 4.45455H0.615385Z" />
    </SvgIcon>
  );
}
