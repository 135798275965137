import React, { useCallback, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import { useTranslation } from 'react-i18next';
import DocumentVersionDragAndDropPayload from 'documents-folders/types/DocumentVersionDragAndDropPayload';
import { useDrop } from 'react-dnd';
import CreatePlanlistDialog from 'documents-lists/components/CreatePlanlistDialog';

interface PlanlistDocumentDropZoneProps extends ISxProps {
}

export default function PlanlistDocumentDropZone({
  sx,
}: PlanlistDocumentDropZoneProps) {
  const { t } = useTranslation('documents-lists');
  const theme = useTheme();

  const [droppedDocumentVersionIds, setDroppedDocumentVersionIds] = useState<string[] | undefined>(undefined);
  const onCloseCreatePlanlistDialog = useCallback(() => setDroppedDocumentVersionIds(undefined), []);
  const [isOver, setIsOver] = useState(false);
  const [, dropRef] = useDrop<DocumentVersionDragAndDropPayload>(() => ({
    accept: 'DocumentVersionDragAndDropPayload',
    drop: (payload) => {
      setDroppedDocumentVersionIds(payload.documentVersionIds);
    },
    collect: (monitor) => {
      setIsOver(monitor.isOver());
    },
  }), []);

  return (
    <Box ref={dropRef} sx={{ ...sx, backgroundColor: theme.palette.background.default, borderRadius: '2px' }}>
      <Box sx={{
        m: 1,
        p: 1,
        height: '100px',
        width: '150px',
        borderWidth: '1px',
        borderStyle: 'dashed',
        borderColor: isOver ? theme.palette.primary.main : theme.palette.grey[400],
        backgroundColor: isOver ? theme.palette.secondary.light : undefined,
        display: 'flex',
        flexGrow: 1,
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
      >
        <Typography>{t('planlist_drop-to-create', 'Drop here to create a new list')}</Typography>
      </Box>
      {!!droppedDocumentVersionIds && (
        <CreatePlanlistDialog documentVersionIds={droppedDocumentVersionIds} onClose={onCloseCreatePlanlistDialog} />
      )}
    </Box>
  );
}
