import DocumentScope from 'documents/types/DocumentScope';
import React from 'react';

export interface DocumentViewNavigationContextState {
  documentScope: DocumentScope,
  setDocumentScope: (value: DocumentScope) => void,
}

const defaultValue: DocumentViewNavigationContextState = {
  documentScope: undefined!,
  setDocumentScope: () => {},
};

const DocumentViewNavigationContext = React.createContext(defaultValue);

export default DocumentViewNavigationContext;
