import React, { useCallback } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import DocumentsDataGridRow from 'documents/types/DocumentDataGridRow';
import DocumentVersionFloorUpdateDto from 'documents/types/DocumentVersionFloorUpdateDto';
import LabelCell from 'documents/components/cell-renderers/LabelCell';
import LabelType from 'labels/types/LabelType';
import ApiEndpoint from 'api/types/ApiEndpoint';

export default function FloorCell({
  data,
  value,
}: ICellRendererParams<DocumentsDataGridRow, string | undefined>) {
  const getUpdateDto = useCallback((labelId: string | null) => {
    if (!data) throw new Error('Missing data.id');
    return {
      id: data.id,
      floor: labelId,
    };
  }, [data]);

  return (
    <LabelCell<DocumentVersionFloorUpdateDto>
      data={data}
      value={value}
      labelType={LabelType.Floor}
      apiEndpoint={ApiEndpoint.DocumentVersionFloor}
      getUpdateDto={getUpdateDto}
    />
  );
}
