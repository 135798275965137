import React, { useCallback, useContext, useMemo, useRef } from 'react';
import { Box } from '@mui/material';
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, GetDataPath, ModuleRegistry, GetRowIdParams, RowGroupOpenedEvent, RowClickedEvent, RowClassRules, IRowNode } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import useFolderTreeQuery from 'documents-folders/hooks/useFolderTreeQuery';
import FolderAccessType from 'documents-folders/types/FolderAccessType';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import FolderTreeGridRow, { FolderRow, AccessTypeRow, RowType } from 'documents-folders/types/FolderTreeGridRow';
import FolderTreeCellRenderer from 'documents-folders/components/FolderTreeCellRenderer';
import FolderFilterContext, { FolderFilterContextState } from 'documents-folders/contexts/FolderFilterContext';
import FolderSortMode from 'documents-folders/types/FolderSortMode';
import ISxProps from 'common/types/ISxProps';
import useDocumentsViewNavigationContext from 'documents/hooks/useDocumentsViewNavigationContext';

ModuleRegistry.registerModules([ClientSideRowModelModule, RowGroupingModule]);

function getAccessTypeTranslationKey(accessType: FolderAccessType) {
  if (accessType === FolderAccessType.Public) return 'folder-access-type_public';
  if (accessType === FolderAccessType.Private) return 'folder-access-type_private';
  if (accessType === FolderAccessType.Restricted) return 'folder-access-type_restricted';
  return 'access-type_unknown';
}

export default function FolderTreeDataGrid({
  sx,
}: ISxProps) {
  const gridRef = useRef<AgGridReact<FolderTreeGridRow>>(null);
  const { data: folderTreeData } = useFolderTreeQuery();
  const { setDocumentScope, documentScope } = useDocumentsViewNavigationContext();
  const {
    isFilterActive, filterPredicate, sortMode,
  } = useContext<FolderFilterContextState>(FolderFilterContext);

  const rowData = useMemo<FolderTreeGridRow[] | undefined>(() => {
    if (!folderTreeData?.foldersById) return undefined;

    const folders = Array.from(folderTreeData.foldersById.values()).filter(filterPredicate);
    const folderRows: FolderRow[] = folders.map((folder) => ({
      rowType: RowType.FolderRow,
      ...folder,
      path: isFilterActive ? [getAccessTypeTranslationKey(folder.rootFolderAccessType), folder.path[folder.path.length - 1]] : [getAccessTypeTranslationKey(folder.rootFolderAccessType), ...folder.path],
    }));

    const accessTypes = Array.from(new Set(folders.map((folder) => folder.accessType)));
    const accessTypeRows: AccessTypeRow[] = accessTypes.map((accessType) => ({
      rowType: RowType.AccessTypeRow,
      accessType,
      path: [getAccessTypeTranslationKey(accessType)],
      nameTranslationKey: getAccessTypeTranslationKey(accessType),
    }));

    return [...folderRows, ...accessTypeRows];
  }, [folderTreeData, isFilterActive, filterPredicate]);

  const getRowId = useCallback((params: GetRowIdParams<FolderTreeGridRow>) => (params.data.rowType === RowType.FolderRow ? params.data.id : params.data.path.join('')), []);

  const autoGroupColumnDef = useMemo<ColDef<FolderTreeGridRow>>(() => ({
    cellRenderer: FolderTreeCellRenderer,
    flex: 1,
    suppressKeyboardEvent: () => true,
    sort: sortMode === FolderSortMode.NameDesc || sortMode === FolderSortMode.CreationDateDesc ? 'desc' : 'asc',
    comparator: (valueA: any, valueB: any, nodeA: IRowNode<FolderTreeGridRow>, nodeB: IRowNode<FolderTreeGridRow>, isDescending: boolean) => {
      if (!nodeA.data) return -1;
      if (!nodeB.data) return 1;
      if (nodeA.data.rowType === RowType.AccessTypeRow && nodeB.data.rowType === RowType.AccessTypeRow) {
        const d = isDescending ? -1 : 1; // neutralize asc/desc sorting for access type group order so they always keep the same order
        if (nodeA.data.accessType === FolderAccessType.Public && nodeB.data.accessType === FolderAccessType.Private) return d;
        if (nodeA.data.accessType === FolderAccessType.Private && nodeB.data.accessType === FolderAccessType.Public) return -d;
        if (nodeA.data.accessType === FolderAccessType.Public && nodeB.data.accessType === FolderAccessType.Restricted) return -d;
        if (nodeA.data.accessType === FolderAccessType.Restricted && nodeB.data.accessType === FolderAccessType.Public) return d;
        if (nodeA.data.accessType === FolderAccessType.Private && nodeB.data.accessType === FolderAccessType.Restricted) return -d;
        if (nodeA.data.accessType === FolderAccessType.Restricted && nodeB.data.accessType === FolderAccessType.Private) return d;
        return 0;
      }
      if (nodeA.data.rowType === RowType.FolderRow && nodeB.data.rowType === RowType.FolderRow) {
        if (sortMode === FolderSortMode.NameAsc || sortMode === FolderSortMode.NameDesc) {
          return nodeA.data.name.localeCompare(nodeB.data.name, undefined, { numeric: true, sensitivity: 'base' });
        }
        if (sortMode === FolderSortMode.CreationDateAsc || sortMode === FolderSortMode.CreationDateDesc) {
          return nodeA.data.creationDateParsed.getTime() - nodeB.data.creationDateParsed.getTime();
        }
      }
      return 0;
    },
  }), [sortMode]);

  const columnDefs = useMemo<ColDef<FolderTreeGridRow>[]>(() => [{
    ...autoGroupColumnDef,
    hide: true,
  }], [autoGroupColumnDef]);

  const updateDataGridSelection = useMemo(() => {
    const updateFunc = () => {
      if (!gridRef.current?.api) return;
      const { api } = gridRef.current;
      if (documentScope.key === DocumentScopeKey.Folder && !!documentScope.id) {
        const folderId = documentScope.id;
        const currentSelection = api.getSelectedRows();
        if (currentSelection?.length) {
          const selectedRow = currentSelection[0];
          if (selectedRow.rowType === RowType.FolderRow && selectedRow.id === folderId) {
            return;
          }
        }
        // reveal node
        const node = api.getRowNode(folderId);
        node?.setExpanded(true);
        if (node && !node.displayed) {
          if (node.childrenAfterSort?.length) {
            const reveal = async () => {
              if (!node.childrenAfterSort?.length) return;
              const lastChildRowIndex = node.childrenAfterSort[node.childrenAfterSort.length - 1].rowIndex;
              if (lastChildRowIndex !== null) {
                gridRef.current?.api.ensureIndexVisible(lastChildRowIndex);
              }
            };
            setTimeout(reveal, 500);
          } else if (node.rowIndex !== undefined && node.rowIndex !== null) {
            gridRef.current.api.ensureIndexVisible(node.rowIndex);
          } else {
            const ancestorPath = [];
            let pivot = node.parent;
            while (pivot) {
              ancestorPath.push(pivot);
              pivot = pivot?.parent;
            }
            // we expand starting from the root so that the last expansion directly reveals the selected folder
            // in order for onRowGroupOpened to kick in which is only implemented to bring the selection into view if it's a direct child
            ancestorPath.reverse().forEach((ancestor) => ancestor.setExpanded(true));
          }
        }
        node?.setSelected(true);
      } else {
        api.deselectAll();
      }
    };
    updateFunc(); // execute update when the scope key or scope id changes
    return updateFunc; // but also return it for imperative use by the data grid
  }, [documentScope]);

  const onRowGroupOpened = useCallback((event: RowGroupOpenedEvent<FolderTreeGridRow>) => {
    // scroll to selection if opening a group reveals it (primarily to programatically reveal the selection when navigating to a nested folder via URL or a page refresh)
    if (documentScope.key !== DocumentScopeKey.Folder || !documentScope.id || !event.node.rowIndex || !gridRef.current?.api) return;
    const folderId = documentScope.id;
    const currentFolderChildNode = event.node.childrenAfterSort?.filter(((c) => c.data?.rowType === RowType.FolderRow && c.data.id === folderId))?.[0];
    if (currentFolderChildNode) {
      const selectedFolderIndex = currentFolderChildNode.childIndex + event.node.rowIndex + 1;
      gridRef.current.api.ensureIndexVisible(selectedFolderIndex);
    }
  }, [documentScope.id, documentScope.key]);

  const onRowClicked = useCallback(({ data, event }: RowClickedEvent<FolderTreeGridRow>) => {
    if (event?.defaultPrevented) return;
    if (!data || data.rowType !== RowType.FolderRow) return;
    if (!data.hasFolderAccess) return;
    setDocumentScope({ key: DocumentScopeKey.Folder, id: data.id });
  }, [setDocumentScope]);

  const getDataPath = useCallback<GetDataPath<FolderTreeGridRow>>((row: FolderTreeGridRow) => row.path, []);

  const rowClassRules = useMemo<RowClassRules<FolderTreeGridRow>>(() => ({
    'no-hover': (params) => params.data?.rowType === RowType.AccessTypeRow || (params.data?.rowType === RowType.FolderRow && !params.data.hasFolderAccess),
  }), []);

  return (
    <Box sx={sx} className="ag-theme-material ag-theme-visoplan tree-view-data-grid">
      <AgGridReact<FolderTreeGridRow>
        rowHeight={52}
        ref={gridRef}
        rowData={rowData}
        getRowId={getRowId}
        treeData
        getDataPath={getDataPath}
        groupDisplayType="singleColumn"
        rowSelection="single"
        autoGroupColumnDef={autoGroupColumnDef}
        columnDefs={columnDefs}
        groupDefaultExpanded={1}
        suppressRowClickSelection
        suppressCellFocus
        onModelUpdated={updateDataGridSelection}
        onRowClicked={onRowClicked}
        onRowGroupOpened={onRowGroupOpened}
        rowClassRules={rowClassRules}
        suppressGroupRowsSticky
      />
    </Box>
  );
}
