import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { IDockviewPanelProps } from 'dockview';
import Viewer3d from 'models/components/Viewer3d';
import useViewer3dContext from 'models/hooks/useViewer3dContext';

export default function Viewer3dDockviewPanel({
  api,
}: IDockviewPanelProps) {
  const { refreshLoadedModelFiles } = useViewer3dContext();
  useEffect(() => {
    refreshLoadedModelFiles();
  }, [refreshLoadedModelFiles]);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  useEffect(() => {
    setIsVisible(api.isVisible);
    const handle = api.onDidVisibilityChange((e) => setIsVisible(e.isVisible));
    return () => {
      handle.dispose();
    };
  }, [api]);

  if (!isVisible) return null;
  return (
    <Box id="Viewer3dDockviewPanel" sx={{ width: '100%', height: '100%', position: 'relative' }}>
      <Viewer3d sx={{ height: '100%', width: '100%' }} />
    </Box>
  );
}
