import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Backdrop, Box, Button } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useModelSelectionContext from 'models/hooks/useModelSelectionContext';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import useViewer3dContext from 'models/hooks/useViewer3dContext';
import Viewer3dContextMenu, { Viewer3dContextMenuState } from 'models/components/Viewer3dContextMenu';
import Viewer3dInteractionMode from 'models/types/Viewer3dInteractionMode';
import { useTranslation } from 'react-i18next';
import useResubscribingEventSubscription from 'common/hooks/useResubscribingEventSubscription';
import ViewpointDto from 'issues/types/ViewpointDto';

interface Viewer3dProps extends ISxProps {
}

export default function Viewer3d({
  sx,
}: Viewer3dProps) {
  const { t } = useTranslation('models');
  const canvasContainerDivRef = useRef<HTMLDivElement>(null);
  const {
    viewer3dApiRef,
    initializeViewerInstance,
    setInteractionMode,
    interactionMode,
    isPlacingViewpointMarker,
    setIsPlacingViewpointMarker,
    setIsViewpointMarkerPlaced,
    createMeasurementPoint,
    selectComponentUnderCursor,
    highlightComponentUnderCursor,
    clearComponentHighlight,
    zoomToSelectedComponents,
    updateClippingPlanes,
  } = useViewer3dContext();
  const {
    selectedViewpoint,
    setSelectedViewpoint,
    getLoadingPromise,
    isLoading,
  } = useModelSelectionContext();

  useEffect(() => {
    if (!canvasContainerDivRef.current) return;
    initializeViewerInstance(canvasContainerDivRef);
  }, [initializeViewerInstance]);

  const onClick = useCallback(async (event: MouseEvent) => {
    if (!viewer3dApiRef.current) return;
    if (isPlacingViewpointMarker) {
      // by this point, an onClick handler will have run in visoBubble.ts for the same click, creating the temp bubble
      // and setting these two variables will casue the Viewer3dContext to change the viewer library's bubble state
      setIsPlacingViewpointMarker(false);
      setIsViewpointMarkerPlaced(true);
      return;
    }
    if (interactionMode === Viewer3dInteractionMode.CreateMeasurements) {
      createMeasurementPoint();
    } else {
      await selectComponentUnderCursor(event.ctrlKey);
    }
  }, [createMeasurementPoint, interactionMode, isPlacingViewpointMarker, selectComponentUnderCursor, setIsPlacingViewpointMarker, setIsViewpointMarkerPlaced, viewer3dApiRef]);
  useResubscribingEventSubscription(canvasContainerDivRef, 'click', onClick);

  const onDoubleClick = useCallback(async () => {
    if (interactionMode === Viewer3dInteractionMode.CreateMeasurements) {
      createMeasurementPoint();
    } else {
      zoomToSelectedComponents();
    }
  }, [createMeasurementPoint, interactionMode, zoomToSelectedComponents]);
  useResubscribingEventSubscription(canvasContainerDivRef, 'dblclick', onDoubleClick);

  const onMouseMove = useCallback(async () => {
    if (isPlacingViewpointMarker) {
      return;
    }
    if (interactionMode !== Viewer3dInteractionMode.CreateMeasurements) {
      await highlightComponentUnderCursor();
      viewer3dApiRef.current?.IFC.bubble.pickBubble();
    }
  }, [highlightComponentUnderCursor, interactionMode, isPlacingViewpointMarker, viewer3dApiRef]);
  useResubscribingEventSubscription(canvasContainerDivRef, 'mousemove', onMouseMove);

  const onKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key.toLocaleLowerCase() === 'escape' && interactionMode === Viewer3dInteractionMode.CreateMeasurements) {
      setInteractionMode(Viewer3dInteractionMode.View);
    }
  }, [interactionMode, setInteractionMode]);
  useResubscribingEventSubscription(document, 'keydown', onKeyDown);

  const onClickExitMeasureMode = useCallback(() => setInteractionMode(Viewer3dInteractionMode.View), [setInteractionMode]);

  const zoomToViewpoint = useCallback(async (viewpoint: ViewpointDto) => {
    viewer3dApiRef.current?.viewpoint.showViewpoint(viewpoint);
    updateClippingPlanes();
  }, [updateClippingPlanes, viewer3dApiRef]);

  useEffect(() => {
    if (selectedViewpoint) {
      setSelectedViewpoint(undefined);
      getLoadingPromise().then(() => zoomToViewpoint(selectedViewpoint));
    }
  }, [getLoadingPromise, selectedViewpoint, setSelectedViewpoint, zoomToViewpoint]);

  const [contextMenuState, setContextMenuState] = useState<Viewer3dContextMenuState | undefined>(undefined);
  const onCloseContextMenu = useCallback(async () => {
    setContextMenuState(undefined);
  }, []);
  const onContextMenu = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    setContextMenuState((prev) => {
      if (prev) {
        event.preventDefault();
        return undefined;
      }
      const clickedOnCanvas = 'tagName' in event.target && (event.target.tagName as string).toLocaleLowerCase() === 'canvas';
      if (clickedOnCanvas) {
        event.preventDefault();
        clearComponentHighlight();
        return { position: { left: event.clientX, top: event.clientY } };
      }
      return prev;
    });
  }, [clearComponentHighlight]);

  return (
    <Box id="Viewer3d" component="div" ref={canvasContainerDivRef} sx={{ ...sx }} onContextMenu={onContextMenu}>
      <Box sx={{ position: 'absolute', top: 0, right: 0, width: 150, height: 150 }} id="nav-cube-container" />
      {!!isLoading && <Backdrop open sx={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}><CenteredCircularProgress /></Backdrop>}
      {interactionMode === Viewer3dInteractionMode.CreateMeasurements && (
        <Box sx={{ position: 'absolute', bottom: 32, right: 32 }}>
          <Alert severity="info">
            {t('viewer-3d_mesaure-mode-alert', 'Measure Mode')}
            <Button onClick={onClickExitMeasureMode}>{t('viewer-3d_exit-measure-mode-button-label', 'Done')}</Button>
          </Alert>
        </Box>
      )}
      <Viewer3dContextMenu state={contextMenuState} onClose={onCloseContextMenu} />
    </Box>
  );
}
