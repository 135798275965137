import React, { useCallback, useMemo } from 'react';
import { Box, useTheme } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useIssuesOdataQuery from 'issues/hooks/useIssuesOdataQuery';
import useIssuesFilterContext from 'issues/hooks/useIssuesFilterContext';
import useIssueItemClickHandler from 'issues/hooks/useIssueItemClickHandler';
import { Gantt, Task } from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';

interface IssuesTimelineProps extends ISxProps {
}

export default function IssuesTimeline({
  sx,
}: IssuesTimelineProps) {
  const theme = useTheme();
  const { odataQuery } = useIssuesFilterContext();
  const { data: issues } = useIssuesOdataQuery(odataQuery);

  const onClickIssue = useIssueItemClickHandler();
  const onClickTask = useCallback((task: Task) => {
    onClickIssue(task.id);
  }, [onClickIssue]);

  const taskItems = useMemo<Task[] | undefined>(() => issues?.map((issue) => ({
    start: new Date(issue.startingDate ?? issue.creationDate),
    end: new Date(issue.dueDate ?? issue.creationDate),
    name: issue.title,
    id: issue.id,
    type: 'task',
    progress: 0,
    isDisabled: false,
  })), [issues]);

  return (
    <Box
      id="IssuesTimeline"
      sx={{
        flex: '1 1 0',
        overflow: 'auto',
        backgroundColor: theme.palette.secondary.light,
        boxShadow: 'inset 0px 24px 24px -24px rgba(0,0,0,0.2)',
        ...sx,
      }}
    >
      {!!taskItems && (
      <Gantt
        tasks={taskItems}
        onClick={onClickTask}
      />
      )}
    </Box>
  );
}
