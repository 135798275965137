import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Box, Chip, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Typography } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import useDocumentVersionQuery from 'documents/hooks/useDocumentVersionQuery';
import { useTranslation } from 'react-i18next';
import useDocumentVersionsQuery from 'documents/hooks/useDocumentVersionsQuery';
import InternalAnnotationsTab from 'documents-details/components/InternalAnnotationsTab';
import { CompareMode } from 'documents-details/components/DocumentViewer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RoundIconButton from 'common/components/RoundIconButton';
import useDocumentVersionNumberString from 'documents/hooks/useDocumentVersionNumberString';
import useDocumentDetailsSelectionContext from 'documents-details/hooks/useDocumentDetailsSelectionContext';
import useDocumentViewerContext from 'documents-details/hooks/useDocumentViewerContext';

interface DocumentVersionDetailsPanelProps extends ISxProps {
}

export default function DocumentVersionComparePanel({
  sx,
}: DocumentVersionDetailsPanelProps) {
  const { t } = useTranslation('documents-details');
  const { documentVersionId, setDocumentVersionId } = useDocumentDetailsSelectionContext();
  const { compareDocumentVersions, compareDocumentVersionId, loadDocumentVersion } = useDocumentViewerContext();
  const { data: documentVersion } = useDocumentVersionQuery(documentVersionId);
  const { data: otherDocumentVersions } = useDocumentVersionsQuery(documentVersion ? { filter: { normalizedName: documentVersion.normalizedName, isArchived: false }, orderBy: 'uploadDate desc' } : undefined);
  const [compareMode, setCompareMode] = useState<CompareMode>(CompareMode.Text);
  const compareDocumentVersion = useMemo(() => {
    if (!otherDocumentVersions || !compareDocumentVersionId) return undefined;
    return otherDocumentVersions.find((v) => v.id === compareDocumentVersionId);
  }, [compareDocumentVersionId, otherDocumentVersions]);
  const onChangeDocumentVersion = useCallback((event: SelectChangeEvent<string>) => {
    setDocumentVersionId(event.target.value);
  }, [setDocumentVersionId]);
  const onClickCancelCompare = useCallback(() => {
    if (!documentVersionId) return;
    loadDocumentVersion(documentVersionId);
  }, [documentVersionId, loadDocumentVersion]);
  const onChangeCompareDocumentVersion = useCallback((event: SelectChangeEvent<string>) => {
    if (!documentVersionId) return;
    const nextCompareDocumentVersionId = event.target.value;
    compareDocumentVersions(documentVersionId, nextCompareDocumentVersionId, compareMode);
  }, [compareDocumentVersions, compareMode, documentVersionId]);
  const onChangeCompareMode = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (!documentVersionId || !compareDocumentVersionId) return;
    const nextMode = event.target.value as CompareMode;
    setCompareMode(nextMode);
    compareDocumentVersions(documentVersionId, compareDocumentVersionId, nextMode);
  }, [compareDocumentVersionId, compareDocumentVersions, documentVersionId]);

  const compareLabelA = useMemo(() => (compareMode === CompareMode.Text ? t('document-version-details-panel_version-select-label-right', 'Reference Version (Right)') : t('document-version-details-panel_version-select-label', 'Reference Version')), [compareMode, t]);
  const compareLabelB = useMemo(() => (compareMode === CompareMode.Text ? t('document-version-details-panel_version-select-label-left', 'Compare Version (Left)') : t('document-version-details-panel_compare-version-select-label', 'Compare Version')), [compareMode, t]);

  const getVersionNumberString = useDocumentVersionNumberString();

  const compareDocumentVersionDisplayValue = useMemo(() => (otherDocumentVersions && compareDocumentVersionId ? compareDocumentVersionId : ''), [compareDocumentVersionId, otherDocumentVersions]);

  if (!documentVersion || !compareDocumentVersion) return <CenteredCircularProgress />;
  if (!compareDocumentVersionId) return null;
  return (
    <Box id="DocumentVersionComparePanel" sx={{ height: '100%', display: 'grid', gridTemplateRows: 'auto auto 1fr' }}>
      <Box sx={{ height: 73, display: 'flex', gap: 2, flexWrap: 'wrap', justifyItems: 'flexStart', alignItems: 'center', p: 2 }}>
        <RoundIconButton Icon={ArrowBackIcon} onClick={onClickCancelCompare} sx={{ flexShrink: 0 }} />
        <Typography variant="h5">{t('document-version-compare-panel_title', 'Compare Document Versions')}</Typography>
      </Box>
      <Box sx={{ ...sx, p: 2, pb: 4, overflow: 'auto', display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box>
          <Typography variant="h4">{documentVersion?.originalFileName}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <FormControl>
              <InputLabel id="document-version-details-panel_version-select-label">
                {compareLabelA}
              </InputLabel>
              <Select
                id="document-version-details-panel_version-select"
                value={otherDocumentVersions ? documentVersionId : ''}
                onChange={onChangeDocumentVersion}
                label={compareLabelA}
              >
                {!!otherDocumentVersions && otherDocumentVersions.map((otherDocumentVersion) => (
                  <MenuItem value={otherDocumentVersion.id} key={otherDocumentVersion.id}>
                    {t('document-version-details-panel_version-select-value', 'V. {{versionNumber}} ({{uploadDate}})', { versionNumber: getVersionNumberString(otherDocumentVersion.versionNumber), uploadDate: new Date(otherDocumentVersion.uploadDate).toLocaleString('de-DE') })}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="document-version-details-panel_compare-version-select-label">
                {compareLabelB}
              </InputLabel>
              <Select
                id="document-version-details-panel_compare-version-select"
                value={compareDocumentVersionDisplayValue}
                onChange={onChangeCompareDocumentVersion}
                label={compareLabelB}
              >
                {!!otherDocumentVersions && otherDocumentVersions.map((otherDocumentVersion) => (
                  <MenuItem value={otherDocumentVersion.id} key={otherDocumentVersion.id}>
                    {t('document-version-details-panel_compare-version-select-value', 'V. {{versionNumber}} ({{uploadDate}})', { versionNumber: getVersionNumberString(otherDocumentVersion.versionNumber), uploadDate: new Date(otherDocumentVersion.uploadDate).toLocaleString('de-DE') })}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box>
              <Typography variant="h5" id="document-version-details-panel_compare-mode-radio-group-label">
                {t('document-version-details-panel_compare-mode-radio-group-label', 'Compare Mode')}
              </Typography>
              <FormControl>
                <RadioGroup
                  aria-labelledby="document-version-details-panel_compare-mode-radio-group-label"
                  value={compareMode}
                  onChange={onChangeCompareMode}
                  name="document-version-details-panel_compare-mode-radio-group"
                >
                  <FormControlLabel value={CompareMode.Text} control={<Radio />} label={t('document-version-details-panel_compare-mode-radio-group-value-text', 'Text comparison')} />
                  <FormControlLabel value={CompareMode.Image} control={<Radio />} label={t('document-version-details-panel_compare-mode-radio-group-value-image', 'Image comparison')} />
                </RadioGroup>
              </FormControl>
            </Box>
            {compareMode === CompareMode.Image && (
              <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
                <Typography variant="h5">{t('document-version-details-panel_compare-colors-legend-header', 'Legend')}</Typography>
                <Box sx={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: 1 }}>
                  <Chip
                    color="success"
                    label={t('document-version-details-panel_compare-colors-legend-added-label', 'Added')}
                  />
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ lineHeight: 1.2 }}>
                      {t('document-version-details-panel_compare-colors-legend-added-hint', 'Present in reference (V.{{version}}) but not in comparison (V.{{compareVersion}})', { version: getVersionNumberString(documentVersion.versionNumber), compareVersion: getVersionNumberString(compareDocumentVersion.versionNumber) })}
                    </Typography>
                  </Box>
                  <Chip
                    color="error"
                    label={t('document-version-details-panel_compare-colors-legend-removed-label', 'Removed')}
                  />
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ lineHeight: 1.2 }}>
                      {t('document-version-details-panel_compare-colors-legend-removed-hint', 'Not present in reference (V.{{version}}) but in comparison (V.{{compareVersion}})', { version: getVersionNumberString(documentVersion.versionNumber), compareVersion: getVersionNumberString(compareDocumentVersion.versionNumber) })}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {compareMode === CompareMode.Text && (
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', boxShadow: 'inset 0px 24px 24px -24px rgba(0,0,0,0.1)', overflow: 'hidden', py: 2 }}>
          <Box sx={{ px: 2 }}>
            <Typography variant="h5">{t('document-version-details-panel_compare-differences-header', 'Differences')}</Typography>
          </Box>
          <InternalAnnotationsTab sx={{ flexGrow: 1 }} hideDate />
        </Box>
      )}
    </Box>
  );
}
