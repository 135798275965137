import React, { useCallback, useState } from 'react';
import { Button, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ISxProps from 'common/types/ISxProps';
import EditIcon from '@mui/icons-material/Edit';
import EditPlanlistDialog from 'documents-lists/components/EditPlanlistDialog';

interface EditPlanlistDialogButtonProps extends ISxProps {
}

export default function EditPlanlistDialogButton({
  sx,
}: EditPlanlistDialogButtonProps) {
  const { t } = useTranslation('documents-lists');
  const [isEditPlanlistDialogOpen, setIsEditPlanlistDialogOpen] = useState(false);
  const onClick = useCallback(() => setIsEditPlanlistDialogOpen(true), []);
  const onCloseEditPlanlistDialog = useCallback(() => setIsEditPlanlistDialogOpen(false), []);

  return (
    <>
      <Tooltip title={t('edit-planlist-dialog-button_tooltip', 'Edit Planlist')}>
        <span>
          <Button
            onClick={onClick}
            sx={{ ...sx, minWidth: 'unset', px: 1 }}
            variant="contained"
            id="EditPlanlistDialogButton"
          >
            <EditIcon />
          </Button>
        </span>
      </Tooltip>
      {isEditPlanlistDialogOpen && <EditPlanlistDialog onClose={onCloseEditPlanlistDialog} />}
    </>
  );
}
