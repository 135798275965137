import React, { useMemo } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Checkbox } from '@mui/material';
import IssueDto from 'issues/types/IssueDto';
import useIssuesSelectionContext from 'issues/hooks/useIssuesSelectionContext';

export default function CheckboxCell({
  data,
}: ICellRendererParams<IssueDto, string>) {
  const entityId = data?.id;
  const { selectedIssueIds } = useIssuesSelectionContext();
  const selectedIssueIdsSet = useMemo(() => new Set(selectedIssueIds), [selectedIssueIds]);
  const selected = useMemo(() => !!entityId && selectedIssueIdsSet.has(entityId), [entityId, selectedIssueIdsSet]);
  return <Checkbox checked={selected} disabled={!data} sx={{ ml: 3, mb: '2px' }} />;
}
