import React, { useMemo } from 'react';
import theme from 'theme';
import { Box, LinearProgress, Typography } from '@mui/material';
import UploadingFileHandle from 'upload/types/UploadingFileHandle';
import { useTranslation } from 'react-i18next';
import UploadingEmailFileHandle from 'emails/types/UploadingEmailFileHandle';

interface UploadingFileHandleProgressBarProps {
  uploadingFileHandle: UploadingFileHandle | UploadingEmailFileHandle
}

export default function UploadingFileHandleProgressBar({ uploadingFileHandle }: UploadingFileHandleProgressBarProps) {
  const { t } = useTranslation('upload');
  const progress = useMemo(() => (uploadingFileHandle.total === 0 ? 99 : Math.min(99, 100 * (uploadingFileHandle.loaded / uploadingFileHandle.total))), [uploadingFileHandle.loaded, uploadingFileHandle.total]); // never show 100% until all uploads are actually complete
  const text = useMemo(() => {
    if (uploadingFileHandle.isCompleted) return t('progress-done', 'Done');
    if (uploadingFileHandle.isFailed) return t('progress-failed', 'Failed');
    return `${progress.toFixed(0)}%`;
  }, [progress, t, uploadingFileHandle.isCompleted, uploadingFileHandle.isFailed]);
  const color = useMemo(() => {
    if (uploadingFileHandle.isCompleted) return 'success';
    if (uploadingFileHandle.isFailed) return 'error';
    return 'info';
  }, [uploadingFileHandle.isCompleted, uploadingFileHandle.isFailed]);
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <LinearProgress variant="determinate" value={progress} sx={{ width: '150px' }} color={color} />
      <Typography sx={{
        color: theme.palette[color].main,
        fontWeight: 500,
        pl: 1,
      }}
      >
        {text}
      </Typography>
    </Box>
  );
}
