import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import { useCallback } from 'react';
import UpdateManyIssuesDto from 'issues/types/UpdateManyIssuesDto';

export default function useIssuesUpdateManyMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.Issue);
  const mutationFn = useCallback(async (dto: UpdateManyIssuesDto) => {
    await axiosInstance.patch(`${ApiEndpoint.IssueMany}`, dto);
  }, [axiosInstance]);
  const onSuccess = useCallback(() => {
    queryKeyBases.forEach((queryKey) => queryClient.invalidateQueries({ queryKey }));
  }, [queryClient, queryKeyBases]);
  return useMutation<void, unknown, UpdateManyIssuesDto>({
    mutationFn,
    onSuccess,
  });
}
