import React, { useCallback, useState } from 'react';
import { Box, Button, Dialog, Typography } from '@mui/material';
import VerticalDivider from 'common/styled/VerticalDivider';
import { useTranslation } from 'react-i18next';
import createProjectSvg from 'img/svg/create-project.svg';
import copyProjectSvg from 'img/svg/copy-project-settings.svg';
import CopyProjectSelectDialog from 'projects/components/CopyProjectSelectDialog';
import CreateProjectDialog from 'projects/components/CreateProjectDialog';

interface NewProjectDialogProps {
  open: boolean,
  onClose: () => void,
}

export default function NewProjectDialog({
  open,
  onClose,
}: NewProjectDialogProps) {
  const { t } = useTranslation('projects');
  const [createProjectDialogOpen, setCreateProjectDialogOpen] = useState(false);
  const [copyProjectSelectDialogOpen, setCopyProjectSelectDialogOpen] = useState(false);

  const onClickNewProject = useCallback(() => {
    setCreateProjectDialogOpen(true);
  }, []);

  const onClickCopyProject = useCallback(() => {
    setCopyProjectSelectDialogOpen(true);
  }, []);

  const onCloseCreateProjectDialog = useCallback((confirmed: boolean) => {
    setCreateProjectDialogOpen(false);
    if (confirmed) {
      onClose();
    }
  }, [onClose]);

  const onCloseCopyProjectSelectDialog = useCallback((confirmed: boolean) => {
    setCopyProjectSelectDialogOpen(false);
    if (confirmed) {
      onClose();
    }
  }, [onClose]);

  return (
    <>
      <Dialog
        id="NewProjectDialog"
        open={open}
        onClose={onClose}
        PaperProps={{ sx: { maxWidth: 'unset' } }}
      >
        <Box sx={{ pt: 2, pr: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={onClose} variant="outlined">{t('new-project-dialog_close-button-label', 'Close')}</Button>
        </Box>
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr auto 1fr', px: 4, pb: 6 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: 6, gap: 6, maxWidth: '330px' }}>
            <Box sx={{ height: 107, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <img src={createProjectSvg} alt="Illustration for the option to create a new project" />
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h2">{t('new-project-dialog_create-new-project-header', 'Create New Project')}</Typography>
              <Typography sx={{ mt: 1, fontSize: 14 }}>{t('new-project-dialog_create-new-project-message', 'Create a new project and define all project settings by yourself.')}</Typography>
            </Box>
            <Button id="NewProjectDialogCreateButton" variant="contained" color="primary" onClick={onClickNewProject}>{t('new-project-dialog_create-new-project-button-label', 'Create New Project')}</Button>
          </Box>
          <VerticalDivider sx={{ marginX: 2 }} />
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: 6, gap: 6, maxWidth: '330px' }}>
            <Box sx={{ height: 107, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <img src={copyProjectSvg} alt="Illustration for the option to copy an existing project" />
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h2">{t('new-project-dialog_copy-project-header', 'Copy Project')}</Typography>
              <Typography sx={{ mt: 1, fontSize: 14 }}>{t('new-project-dialog_copy-project-message', 'Select a project and choose existing options you want to copy to your project.')}</Typography>
            </Box>
            <Button id="NewProjectDialogCopyButton" variant="contained" color="secondary" onClick={onClickCopyProject}>{t('new-project-dialog_copy-project-button-label', 'Select Project')}</Button>
          </Box>
        </Box>
      </Dialog>
      {createProjectDialogOpen && <CreateProjectDialog open onClose={onCloseCreateProjectDialog} />}
      {copyProjectSelectDialogOpen && <CopyProjectSelectDialog open onClose={onCloseCopyProjectSelectDialog} />}
    </>
  );
}
