import { useCallback } from 'react';
import useApiConfig from 'api/hooks/useApiConfig';
import useVisoplanApiContext from 'api/hooks/useVisoplanApiContext';
import ApiEndpoint from 'api/types/ApiEndpoint';
import ErrorDto from 'api/types/ErrorDto';

function parseErrorDtoJson(json: string) {
  try {
    const obj = JSON.parse(json);
    if ('message' in obj && 'translatedMessage' in obj && typeof obj.message === 'string' && typeof obj.translatedMessage === 'string') {
      return obj as ErrorDto;
    }
    return undefined;
  } catch {
    return undefined;
  }
}

export default function useBcfUploadHandler() {
  const { apiUrl } = useApiConfig();
  const { authProjectToken, authUserToken } = useVisoplanApiContext();

  const uploadBcfFiles = useCallback(async (files: File[], onProgress?: (progress: number) => void) => {
    if (!authProjectToken || !authUserToken) throw new Error('missing token');
    if (!files.length) return [];
    const uploadPromises = Array.from(files).map((file) => new Promise<string>((resolve, reject) => {
      const data = new FormData();
      data.append('file', file, file.name);
      const xhr = new XMLHttpRequest();
      const url = `${apiUrl}/${ApiEndpoint.Report}`;
      xhr.open('POST', url);
      xhr.setRequestHeader('Authorization', `Bearer ${authUserToken.bearer}`);
      xhr.setRequestHeader('X-Project-Authorization', `Bearer ${authProjectToken.bearer}`);
      xhr.setRequestHeader('Visoplan-Client-Id', 'Visoplan Webclient');
      xhr.setRequestHeader('Visoplan-Webclient-Request-Source', 'Current');

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.responseText);
        } else {
          const errorDto = parseErrorDtoJson(xhr.responseText);
          reject(errorDto ?? xhr.statusText);
        }
      };

      if (onProgress) {
        xhr.onprogress = (ev: ProgressEvent) => {
          onProgress(ev.total > 0 ? Math.min(99, ev.loaded / file.size) : 0);
        };
      }

      xhr.onerror = () => {
        const errorDto = parseErrorDtoJson(xhr.responseText);
        reject(errorDto ?? xhr.statusText);
      };

      xhr.send(data);
    }));

    return Promise.all(uploadPromises);
  }, [apiUrl, authProjectToken, authUserToken]);

  return uploadBcfFiles;
}
