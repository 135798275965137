import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { Box } from '@mui/material';
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, GetRowIdParams, IRowNode, Module, RowClickedEvent } from '@ag-grid-community/core';
import { ViewportRowModelModule } from '@ag-grid-enterprise/viewport-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import PlanlistTreeCellRenderer from 'documents-lists/components/PlanlistTreeCellRenderer';
import DocumentListDto from 'documents-lists/types/DocumentListDto';
import ISxProps from 'common/types/ISxProps';
import useDocumentListsOdataQuery from 'documents-lists/hooks/useDocumentListsOdataQuery';
import PlanlistFilterContext, { PlanlistFilterContextState } from 'documents-lists/contexts/PlanlistFilterContext';
import PlanlistSortMode from 'documents-lists/types/PlanlistSortMode';
import useDocumentsViewNavigationContext from 'documents/hooks/useDocumentsViewNavigationContext';

const MODULES: Module[] = [ViewportRowModelModule, RowGroupingModule];

interface PlanlistDataGridProps extends ISxProps {
}

export default function PlanlistDataGrid({
  sx,
}: PlanlistDataGridProps) {
  const gridRef = useRef<AgGridReact<DocumentListDto>>(null);
  const { data: documentLists } = useDocumentListsOdataQuery({});
  const { documentScope, setDocumentScope } = useDocumentsViewNavigationContext();
  const { filterPredicate, sortMode } = useContext<PlanlistFilterContextState>(PlanlistFilterContext);

  const rowData = useMemo(() => documentLists?.filter(filterPredicate), [documentLists, filterPredicate]);

  const updateSelection = useCallback(() => {
    if (!gridRef.current?.api) return;
    const { api } = gridRef.current;
    if (documentScope.key === DocumentScopeKey.Planlist && !!documentScope.id) {
      api.forEachNode((node) => {
        node.setSelected(node.id === documentScope.id);
      });
    } else {
      api.deselectAll();
    }
  }, [documentScope]);

  useEffect(() => {
    updateSelection();
  }, [updateSelection]);

  const onRowDataUpdated = useCallback(() => {
    updateSelection();
  }, [updateSelection]);

  const onRowClicked = useCallback(({ data }: RowClickedEvent<DocumentListDto>) => {
    if (!data) return;
    setDocumentScope({ key: DocumentScopeKey.Planlist, id: data.id });
  }, [setDocumentScope]);

  const columnDefs = useMemo<ColDef[]>(() => ([
    {
      cellRenderer: PlanlistTreeCellRenderer,
      suppressSizeToFit: true,
      suppressKeyboardEvent: () => true,
      flex: 1,
      resizable: false,
      sort: sortMode === PlanlistSortMode.NameDesc ? 'desc' : 'asc',
      comparator: (valueA: any, valueB: any, nodeA: IRowNode<DocumentListDto>, nodeB: IRowNode<DocumentListDto>) => {
        if (!nodeA.data) return -1;
        if (!nodeB.data) return 1;
        return nodeA.data.name.localeCompare(nodeB.data.name, undefined, { numeric: true, sensitivity: 'base' });
      },
    }]), [sortMode]);

  const getRowId = useCallback((params: GetRowIdParams<DocumentListDto>) => params.data.id ?? '', []);

  useEffect(() => {
    if (!gridRef.current?.api) return;
    if (documentLists === undefined) {
      gridRef.current!.api.showLoadingOverlay();
    } else if (documentLists.length === 0) {
      gridRef.current!.api.showNoRowsOverlay();
    } else {
      gridRef.current!.api.hideOverlay();
    }
  }, [documentLists]);

  return (
    <Box sx={sx} className="ag-theme-material ag-theme-visoplan tree-view-data-grid">
      <AgGridReact<DocumentListDto>
        ref={gridRef}
        rowData={rowData}
        getRowId={getRowId}
        groupDisplayType="singleColumn"
        rowSelection="single"
        columnDefs={columnDefs}
        suppressRowClickSelection
        suppressCellFocus
        onRowClicked={onRowClicked}
        modules={MODULES}
        onRowDataUpdated={onRowDataUpdated}
      />
    </Box>
  );
}
