import type { Theme, ComponentsProps, ComponentsVariants } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

interface MuiButtonBaseComponent {
  defaultProps?: ComponentsProps['MuiButtonBase'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiButtonBase'];
  variants?: ComponentsVariants['MuiButtonBase'];
}

const VisoButtonBase: MuiButtonBaseComponent = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontSize: theme.typography.fontSize,
      fontFamily: 'Open Sans, sans-serif ',
    }),
  },
};

export default VisoButtonBase;
