import {
  Box, TextField, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent,
} from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SelectShareRecipientsControl from 'share/components/SelectShareRecipientsControl';
import ShareRecipientsFilterContextProvider from 'share/contexts/ShareRecipientsFilterContextProvider';
import ShareLinkMessage from 'share/types/ShareLinkMessage';
import ShareRecipients from 'share/types/ShareRecipients';

interface CreateShareLinkMessageControlProps extends ISxProps {
  value: ShareLinkMessage | undefined,
  onChange: (message: ShareLinkMessage) => void,
}

export default function CreateShareLinkMessageControl({
  value,
  onChange,
  sx,
}: CreateShareLinkMessageControlProps) {
  const { t } = useTranslation('share');

  const shareRecipients = useMemo<ShareRecipients | undefined>(() => {
    if (!value) return undefined;
    return {
      userGroupIds: value.userGroupIds,
      projectCollaboratorIds: value.collaboratorIds,
      externalEmailAddresses: value.externalEmailAddresses,
    };
  }, [value]);

  const onChangeShareRecipients = useCallback((recipients: ShareRecipients) => {
    if (!value) return;
    onChange({
      ...value,
      collaboratorIds: recipients.projectCollaboratorIds,
      userGroupIds: recipients.userGroupIds,
      externalEmailAddresses: recipients.externalEmailAddresses,
    });
  }, [onChange, value]);

  const onChangeExpirationDays = useCallback((event: SelectChangeEvent<string>) => {
    if (!value) return;
    const parsedInt = parseInt(event.target.value, 10);
    if (Number.isNaN(parsedInt)) return;
    onChange({
      ...value,
      expirationDurationDays: parsedInt as 1 | 7 | 30,
    });
  }, [onChange, value]);

  const onChangeComment = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (!value) return;
    onChange({
      ...value,
      comment: event.target.value,
    });
  }, [onChange, value]);

  return (
    <Box sx={{
      ...sx, display: 'flex', flexDirection: 'column', flexGap: '8px',
    }}
    >
      <ShareRecipientsFilterContextProvider>
        <SelectShareRecipientsControl value={shareRecipients} onChange={onChangeShareRecipients} sx={{ flexGrow: 1 }} />
      </ShareRecipientsFilterContextProvider>
      <Box sx={{
        flexGrow: 0, display: 'flex', mt: 'auto', alignItems: 'flex-end',
      }}
      >
        <TextField
          label={t('message_comment-label', 'Comment')}
          value={value?.comment ?? ''}
          onChange={onChangeComment}
          sx={{ flexGrow: 1, mt: 1 }}
          rows="3"
          multiline
        />
        <FormControl
          size="small"
          sx={{ minWidth: '150px', ml: 1 }}
        >
          <InputLabel id="share-link-message_expiration-days-label">
            {t('message_expiration-days-label', 'Download available')}
          </InputLabel>
          <Select
            id="message_expiration-days-select"
            labelId="message_expiration-days-label"
            size="small"
            value={`${value?.expirationDurationDays ?? ''}`}
            onChange={onChangeExpirationDays}
            label={t('message_expiration-days-label', 'Download available')}
          >
            {[1, 7, 30].map((days) => (
              <MenuItem key={days} value={`${days}`}>{t('message_expiration-days-value', '{{count}} Days', { count: days })}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}
