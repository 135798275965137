import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import CreateDocumentCommentDto from 'documents/types/CreateDocumentCommentDto';
import DocumentCommentDto from 'documents/types/DocumentCommentDto';
import useCurrentCollaboratorQuery from 'collaborators/hooks/useCurrentCollaboratorQuery';
import { useMemo } from 'react';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import useCollaboratorsQuery from 'collaborators/hooks/useCollaboratorsQuery';

interface Context {
  previouslyCachedData: DocumentCommentDto[] | undefined,
  optimisticEntity: DocumentCommentDto,
  queryKey: QueryKey,
}

export default function useDocumentCommentCreateMutation() {
  const axiosInstance = useAxiosInstance();
  const { data: currentCollaborator } = useCurrentCollaboratorQuery();
  const { data: collaborators } = useCollaboratorsQuery();
  const currentUserCollaborator = useMemo(() => (currentCollaborator ? collaborators?.find((c) => c.id === currentCollaborator.id) : undefined), [collaborators, currentCollaborator]);
  const { getOdataQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentComment);
  const queryClient = useQueryClient();
  return useMutation<DocumentCommentDto | undefined, unknown, CreateDocumentCommentDto, Context>({
    mutationFn: async (dto: CreateDocumentCommentDto) => {
      const { data: documentCommentDto } = await axiosInstance.post<DocumentCommentDto>(`${ApiEndpoint.DocumentComment}`, dto);
      return documentCommentDto;
    },
    onMutate: (dto) => {
      if (!currentUserCollaborator) throw new Error('User missing');
      const queryKey = getOdataQueryKey({ filter: { documentVersionId: dto.documentVersionId }, orderBy: 'creationDate desc' });
      const previouslyCachedData = queryClient.getQueryData<DocumentCommentDto[] | undefined>(queryKey);
      const now = new Date();
      const optimisticEntity: DocumentCommentDto = {
        id: `temp_${crypto.randomUUID()}`,
        creationDate: now.toISOString(),
        creator: currentUserCollaborator,
        documentVersionId: dto.documentVersionId,
        text: dto.text,
        isRemoved: false,
        timeStamp: now.toISOString(),
      };
      const updatedData = [optimisticEntity, ...(previouslyCachedData ?? [])];
      queryClient.setQueryData(queryKey, updatedData); // insert an optimistically prediced entity into the cache
      return { previouslyCachedData, optimisticEntity, queryKey };
    },
    onSuccess: (data, dto, context) => {
      if (!context) return;
      const { optimisticEntity, queryKey } = context;
      const currentlyCachedData = queryClient.getQueryData<DocumentCommentDto[] | undefined>(queryKey);
      const updatedData = currentlyCachedData?.map((c) => (c.id === optimisticEntity.id ? data : c));
      queryClient.setQueryData(queryKey, updatedData); // replace optimistically updated entity with actual server result
    },
    onError: (dto, error, context) => {
      if (!context) return;
      const { previouslyCachedData, queryKey } = context;
      queryClient.setQueryData(queryKey, previouslyCachedData); // roll back
    },
  });
}
