import React, { useCallback } from 'react';
import PageWrapper from 'common/components/PageWrapper';
import CopyProjectDialog from 'projects/components/CopyProjectDialog';
import { useNavigate } from 'react-router-dom';

export default function CopyProjectPage() {
  const navigate = useNavigate();
  const onClose = useCallback(() => {
    navigate('/projects');
  }, [navigate]);

  return (
    <PageWrapper>
      <CopyProjectDialog open onClose={onClose} />
    </PageWrapper>
  );
}
