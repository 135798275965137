import React from 'react';
import DocumentsPrintModal from 'Components/DocumentsPrintModal';
import useDocumentVersionsQuery from 'documents/hooks/useDocumentVersionsQuery';
import useCurrentCollaboratorQuery from 'collaborators/hooks/useCurrentCollaboratorQuery';
import useCollaboratorsOdataQuery from 'collaborators/hooks/useCollaboratorsOdataQuery';

interface PrintWizardProps {
  documentVersionIds: string[],
  open: boolean,
  onClose: () => void,
}

export default function PrintWizard({
  documentVersionIds,
  open,
  onClose,
}: PrintWizardProps) {
  const { data: documentVersions } = useDocumentVersionsQuery(documentVersionIds ? { filter: { id: { in: documentVersionIds } } } : undefined);
  const { data: currentCollaborator } = useCurrentCollaboratorQuery();
  const { data: collaborators } = useCollaboratorsOdataQuery({ filter: { isDeleted: false } });
  if (!open || !documentVersions || !currentCollaborator || !collaborators) return null;
  return (
    <DocumentsPrintModal
      documents={documentVersions}
      currentCollaborator={currentCollaborator}
      users={collaborators}
      onClose={onClose}
    />
  );
}
