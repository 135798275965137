import React from "react";

import { Modal } from "../../HOC";

const ConfirmModal = ({
  title,
  confirmText,
  description,
  onYes,
  onNo,
  onCancel,
  yesText,
  noText,
  cancelText,
  isButtonAlignCenter = false,
  buttonId,
  className = "",
}) => {
  return (
    <Modal>
      <div className={`confirm-modal ${className}`}>
        <div></div>
        <div className="confirm-modal-content">
          <h2 className="confirm-modal-content--title">{title}</h2>
          <p
            className="confirm-modal-content--description"
            dangerouslySetInnerHTML={{ __html: confirmText }}
          />
          <p
            className="confirm-modal-content--description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
        <div
          className={`confirm-modal--action${
            isButtonAlignCenter ? " center" : ""
          }`}
        >
          <button className="btn btn--secondary" onClick={onNo} type="button">
            {noText}
          </button>
          <button
            className="btn btn--primary"
            id={buttonId}
            onClick={onYes}
            type="button"
          >
            {yesText}
          </button>
          {cancelText ? (
            <button
              className="btn btn--secondary"
              onClick={onCancel}
              type="button"
            >
              {cancelText}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
