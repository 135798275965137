import React, { useCallback, useMemo } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Checkbox } from '@mui/material';
import DocumentsDataGridRow from 'documents/types/DocumentDataGridRow';
import useDocumentSelectionContext from 'documents/hooks/useDocumentSelectionContext';

export default function CheckboxCell({
  data,
}: ICellRendererParams<DocumentsDataGridRow, string>) {
  const documentVersionId = data?.id;
  const {
    selectedDocumentVersionIds,
    selectedDocumentVersionIdsSet,
    nonDeselectableVersionIds,
    nonSelectableVersionIds,
    setSelectedDocumentVersionIds,
  } = useDocumentSelectionContext();
  const selected = useMemo(() => !!documentVersionId && selectedDocumentVersionIdsSet.has(documentVersionId) && !nonSelectableVersionIds?.has(documentVersionId), [documentVersionId, selectedDocumentVersionIdsSet, nonSelectableVersionIds]);
  const disabled = useMemo(() => !documentVersionId || nonDeselectableVersionIds?.has(documentVersionId) || nonSelectableVersionIds?.has(documentVersionId), [documentVersionId, nonDeselectableVersionIds, nonSelectableVersionIds]);
  const onClick = useCallback(() => {
    if (!documentVersionId || !nonDeselectableVersionIds || !nonSelectableVersionIds) {
      return;
    }
    if (selected) {
      setSelectedDocumentVersionIds(selectedDocumentVersionIds.filter((id) => id !== documentVersionId));
    } else {
      setSelectedDocumentVersionIds([...selectedDocumentVersionIds, documentVersionId]);
    }
  }, [documentVersionId, nonDeselectableVersionIds, nonSelectableVersionIds, selected, selectedDocumentVersionIds, setSelectedDocumentVersionIds]);
  return <Checkbox checked={selected} disabled={disabled} sx={{ ml: 0, mb: '5px' }} onClick={onClick} />;
}
