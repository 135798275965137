import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Box, Button, Checkbox, CircularProgress, FormControlLabel, useTheme } from '@mui/material';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import ISxProps from 'common/types/ISxProps';
import useIssuesOdataInfiniteQuery from 'issues/hooks/useIssuesOdataInfiniteQuery';
import useIssuesIdsQuery from 'issues/hooks/useIssuesIdsQuery';
import IssueItemCard from 'issues/components/IssueItemCard';
import { useTranslation } from 'react-i18next';
import CreateIssueDialog from 'issues/components/CreateIssueDialog';
import useModelSelectionContext from 'models/hooks/useModelSelectionContext';
import IssueQuickFilterBar from 'issues/components/IssueQuickFilterBar';
import useIssuesFilterContext from 'issues/hooks/useIssuesFilterContext';
import useModelsInteractionContext from 'models/hooks/useModelsInteractionContext';
import useViewer3dContext from 'models/hooks/useViewer3dContext';
import useViewpointsQuery from 'issues/hooks/useViewpointsQuery';
import useVector3Math from 'common/hooks/useVector3Math';
import ViewpointBubbleItem from 'models/types/ViewpointBubbleItem';
import IssueDto from 'issues/types/IssueDto';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';
import RoleAction from 'projects/types/RoleAction';

interface ModelIssuesListPanelProps extends ISxProps {
}

export default function ModelIssuesListPanel({
  sx,
}: ModelIssuesListPanelProps) {
  const { t } = useTranslation('models');
  const theme = useTheme();
  const { distanceSqr } = useVector3Math();
  const { modelFileIds, setSelectedIssueId } = useModelSelectionContext();

  const [filterIssuesByLoadedModels, setFilterIssuesByLoadedModels] = useState<boolean>(() => {
    const storedValue = localStorage.getItem('model-issues-list-panel_filter-by-loaded-models');
    return storedValue ? storedValue === 'true' : true;
  });
  const onChangeFilterIssuesByLoadedModels = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem('model-issues-list-panel_filter-by-loaded-models', event.target.checked.toString());
    setFilterIssuesByLoadedModels(event.target.checked);
  }, []);
  const { odataQuery } = useIssuesFilterContext();
  const modelIssuesOdataQuery = useMemo(() => {
    if (!filterIssuesByLoadedModels) return odataQuery;
    if (odataQuery && 'filter' in odataQuery && modelFileIds.length) {
      const { filter } = odataQuery;
      const modelFilter = { and: [filter, { or: modelFileIds.map((id) => ({ modelFileComponentIds: { any: { key: id } } })) }] };
      return { ...odataQuery, filter: modelFilter };
    }
    return undefined;
  }, [filterIssuesByLoadedModels, modelFileIds, odataQuery]);

  const { createViewpoint, setViewpointBubbles } = useViewer3dContext();
  const { data: issuesInfiniteData, isFetchingNextPage, fetchNextPage, hasNextPage } = useIssuesOdataInfiniteQuery(modelIssuesOdataQuery);
  const issues = useMemo(() => issuesInfiniteData?.pages.filter(Boolean).flatMap((page) => page).map((issue) => issue!), [issuesInfiniteData?.pages]);
  const { data: issueIds } = useIssuesIdsQuery(modelIssuesOdataQuery);
  const viewpointIds = useMemo(() => (issues ? Array.from(new Set(issues.flatMap((issue) => issue.viewpointIds))) : undefined), [issues]);
  const { data: viewpoints } = useViewpointsQuery({ filter: { id: { in: viewpointIds } } });
  const issuesById = useMemo(() => (issues ? new Map<string, IssueDto>(issues.map((issue) => [issue.id, issue])) : undefined), [issues]);
  const viewpointBubbleItems = useMemo<ViewpointBubbleItem[] | undefined>(() => {
    if (!issuesById || !viewpoints) return undefined;
    return viewpoints.flatMap((viewpoint) => viewpoint.lines.map((line) => ({ viewpoint, line }))
      .filter(({ line }) => distanceSqr(line.start, line.end) < 0.001))
      .map(({ viewpoint, line }) => {
        const issue = issuesById?.get(viewpoint.issueId);
        return {
          title: issue ? `[${issue.issueNumber}] ${issue.title}` : viewpoint.issueId,
          position: line.start,
          issueId: viewpoint.issueId,
          linkedComponentsGlobalIds: issue?.linkedComponentsGlobalIds ?? [],
        };
      });
  }, [distanceSqr, issuesById, viewpoints]);
  useEffect(() => {
    setViewpointBubbles(viewpointBubbleItems ?? []);
    return () => {
      setViewpointBubbles([]);
    };
  }, [setViewpointBubbles, viewpointBubbleItems]);

  const { interactionMode, setViewpointItems } = useModelsInteractionContext();
  const [createIssueDialogOpen, setCreateIssueDialogOpen] = useState(false);
  const onClickCreateIssueButton = useCallback(async () => {
    setCreateIssueDialogOpen(true);
    const viewpoint = await createViewpoint();
    if (!viewpoint) throw new Error('Failed to create viewpoint');
    setViewpointItems([viewpoint]);
  }, [createViewpoint, setViewpointItems]);
  const onCancelCreateIssueDialog = useCallback(() => {
    setCreateIssueDialogOpen(false);
  }, []);
  const onConfirmCreateIssueDialog = useCallback((createdIssueId: string | undefined, keepOpen: boolean) => {
    if (!keepOpen) {
      setCreateIssueDialogOpen(false);
    }
    setViewpointItems([]);
  }, [setViewpointItems]);

  const onClickIssue = useCallback((issueId: string) => {
    setSelectedIssueId(issueId);
  }, [setSelectedIssueId]);

  const allowedActions = useAllowedActions();
  const canCreateIssues = useMemo(() => allowedActions?.has(RoleAction.IssueManagement_Creation), [allowedActions]);

  const observerTarget = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && issues?.length !== issueIds?.length) {
        fetchNextPage();
      }
    }, { threshold: 1 });
    const target = observerTarget.current;
    if (target) {
      observer.observe(target);
    }
    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, [fetchNextPage, observerTarget, hasNextPage, issues?.length, issueIds?.length]);

  return (
    <Box id="ModelIssuesListPanel" sx={{ ...sx, flexGrow: 1, display: 'flex', flexDirection: 'column', pt: 1 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, gap: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FormControlLabel
            control={<Checkbox checked={filterIssuesByLoadedModels} onChange={onChangeFilterIssuesByLoadedModels} />}
            label={t('model-issues-list-panel_filter-issues-by-loaded-models-checkbox-label', 'Filter by loaded models')}
          />
          {!interactionMode && canCreateIssues && (
            <Button
              id="ModelIssuesListPanelCreateIssueButton"
              variant="contained"
              color="primary"
              sx={{ pl: 1.5, gap: 1, flexShrink: 0 }}
              onClick={onClickCreateIssueButton}
              disabled={!modelFileIds.length}
            >
              <Icon path={mdiPlus} size={1} />
              {t('create-issue-button_label', 'Create Issue')}
            </Button>
          )}
          <CreateIssueDialog
            open={createIssueDialogOpen && !interactionMode}
            onCancel={onCancelCreateIssueDialog}
            onConfirm={onConfirmCreateIssueDialog}
            isClash
          />
        </Box>
        <IssueQuickFilterBar />
      </Box>
      <Box sx={{ flex: '1 1 0', display: 'flex', background: theme.palette.grey[200], flexDirection: 'column', overflow: 'auto', p: 2, gap: 2, boxShadow: 'inset 0px 24px 24px -24px rgba(0,0,0,0.1)' }}>
        {filterIssuesByLoadedModels && !modelFileIds.length && <Alert severity="info" sx={{ boxShadow: '0px 2px 12px -4px rgba(0,0,0,0.3)' }}>{t('model-issues-list-panel_no-model-loaded-message', 'Issues related to loaded models are listet here.')}</Alert>}
        {!!issues && issues.map((issue) => <IssueItemCard key={issue.id} issue={issue} onClick={!interactionMode ? onClickIssue : undefined} />)}
        <Box ref={observerTarget} />
        {isFetchingNextPage && <Box sx={{ display: 'flex', justifyContent: 'center' }}><CircularProgress size={24} /></Box>}
      </Box>
    </Box>
  );
}
