import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, Box, DialogContent, DialogActions, Button, CircularProgress, Alert, AlertTitle, Checkbox, FormControlLabel, Typography, useTheme } from '@mui/material';
import FolderAccessType from 'documents-folders/types/FolderAccessType';
import useFolderCreateMutation from 'documents-folders/hooks/useFolderCreateMutation';
import useFolderTreeQuery from 'documents-folders/hooks/useFolderTreeQuery';
import useCurrentProjectId from 'projects/hooks/useCurrentProjectId';
import FolderFormValues from 'documents-folders/types/FolderFormValues';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import HorizontalDivider from 'common/styled/HorizontalDivider';
import FolderBreadcrumbs from 'documents-folders/components/FolderBreadcrumbs';
import FolderSettingsPanel from 'documents-folders/components/FolderSettingsPanel';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import useFolder from 'documents-folders/hooks/useFolder';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useCurrentCollaboratorQuery from 'collaborators/hooks/useCurrentCollaboratorQuery';
import useCollaboratorGroupsQuery from 'collaborators-groups/hooks/useCollaboratorGroupsQuery';
import useSiblingFolderNames from 'documents-folders/hooks/useSiblingFolderNames';
import InfoIcon from '@mui/icons-material/Info';
import EffectiveAccessRightsDialog from 'documents-folders/components/EffectiveAccessRightsDialog';
import FolderCreateDto from 'documents-folders/types/FolderCreateDto';
import useCollaboratorsQuery from 'collaborators/hooks/useCollaboratorsQuery';
import useDocumentsViewNavigationContext from 'documents/hooks/useDocumentsViewNavigationContext';

export interface CreateFolderDialogProps {
  parentFolderId: string | undefined,
  onClose: (successMessage?: string) => void;
}

export default function CreateFolderDialog({
  parentFolderId,
  onClose,
}: CreateFolderDialogProps) {
  const { t } = useTranslation('documents-folders');
  const theme = useTheme();
  const { setDocumentScope } = useDocumentsViewNavigationContext();
  const { data: collaborators } = useCollaboratorsQuery();
  const collaboratorIdsSet = useMemo(() => (collaborators ? new Set(collaborators.map((c) => c.id)) : undefined), [collaborators]);
  const { data: userGroups } = useCollaboratorGroupsQuery();
  const userGroupIdsSet = useMemo(() => (userGroups ? new Set(userGroups.map((g) => g.id)) : undefined), [userGroups]);
  const { mutateAsync, isPending: isLoadingCreate } = useFolderCreateMutation();
  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const projectId = useCurrentProjectId();
  const { data: folderTreeData, isLoading: isLoadingFolderTreeData } = useFolderTreeQuery();
  const parentFolder = useFolder(parentFolderId);
  const [folderFormValues, setFolderFormValues] = useState<FolderFormValues | undefined>();
  const [keepDialogOpen, setKeepDialogOpen] = useState(false);
  useEffect(() => {
    if (folderFormValues || (parentFolderId && !parentFolder) || !collaboratorIdsSet || !userGroupIdsSet) return; // init
    setFolderFormValues({
      name: '',
      inheritAccess: !!parentFolder,
      accessType: parentFolder?.accessType ?? FolderAccessType.Public,
      permissions: parentFolder?.accessType === FolderAccessType.Restricted ? {
        accessCollaboratorIds: parentFolder?.accessCollaboratorIds.filter((id) => collaboratorIdsSet.has(id)) ?? [],
        readOnlyCollaboratorIds: parentFolder?.readOnlyCollaboratorIds.filter((id) => collaboratorIdsSet.has(id)) ?? [],
        accessGroupsIds: parentFolder?.accessGroupsIds.filter((id) => userGroupIdsSet.has(id)) ?? [],
        readOnlyGroupsIds: parentFolder?.readOnlyGroupsIds.filter((id) => userGroupIdsSet.has(id)) ?? [],
      } : parentFolder?.accessType === FolderAccessType.Public ? {
        accessCollaboratorIds: [],
        readOnlyCollaboratorIds: parentFolder?.readOnlyCollaboratorIds.filter((id) => collaboratorIdsSet.has(id)) ?? [],
        accessGroupsIds: [],
        readOnlyGroupsIds: parentFolder?.readOnlyGroupsIds.filter((id) => userGroupIdsSet.has(id)) ?? [],
      } : {
        accessCollaboratorIds: [],
        readOnlyCollaboratorIds: [],
        accessGroupsIds: [],
        readOnlyGroupsIds: [],
      },
    });
  }, [collaboratorIdsSet, folderFormValues, parentFolder, parentFolderId, userGroupIdsSet]);
  const existingSiblingFolderNames = useSiblingFolderNames(parentFolderId);
  const isPermissionConfigRestrictedAndEmpty = useMemo(() => {
    if (!folderFormValues) return undefined;
    return folderFormValues.accessType === FolderAccessType.Restricted && (!folderFormValues.permissions.accessCollaboratorIds.length && !folderFormValues.permissions.accessGroupsIds.length);
  }, [folderFormValues]);
  const canConfirm = useMemo(() => (
    !isLoadingFolderTreeData
    && !isLoadingCreate
    && !!projectId
    && !isLoadingCreate
    && !!folderFormValues?.name.length
    && !isPermissionConfigRestrictedAndEmpty
    && existingSiblingFolderNames?.has(folderFormValues.name) === false
  ), [isLoadingFolderTreeData, isLoadingCreate, projectId, folderFormValues, existingSiblingFolderNames, isPermissionConfigRestrictedAndEmpty]);

  const onChangeFolderForm = useCallback((values: FolderFormValues) => {
    setFolderFormValues((prevValues) => {
      if (!prevValues) return prevValues;
      if (!prevValues.inheritAccess && values.inheritAccess && parentFolder) {
        // if we set inheritAccess to true, we want to display the parent folder's access config
        if (parentFolder.accessType === FolderAccessType.Restricted) {
          // if the parent is restricted, we want to display the parent's access id selection with disabled checkboxes
          return {
            ...values,
            accessType: parentFolder.accessType,
            accessCollaboratorIds: parentFolder.accessCollaboratorIds ?? [],
          };
        }
        // if the parent is not restricted, we deliberately keep the current accessCollaboratorIds list
        // to avoid clearing the user's selection. We have to clear this list from non-restricted folders on submitting the form (see submit handler).
        return {
          ...values,
          accessType: parentFolder.accessType,
        };
      }
      return values;
    });
  }, [parentFolder]);

  const resetForm = useCallback(() => {
    setFolderFormValues(undefined);
  }, []);

  const { data: currentCollaborator } = useCurrentCollaboratorQuery();
  const currentCollaboratorCollaboratorGroupIds = useMemo(() => {
    if (!currentCollaborator || !userGroups) return undefined;
    return new Set<string>(currentCollaborator.collaboratorGroupIds);
  }, [currentCollaborator, userGroups]);

  const hasReadAccessToNewFolder = useMemo(() => {
    if (!folderFormValues || !currentCollaborator || !currentCollaboratorCollaboratorGroupIds) return undefined;
    if (folderFormValues.accessType !== FolderAccessType.Restricted) return true;
    const userHasIndividualReadAccess = folderFormValues.permissions.accessCollaboratorIds.some((id) => id === currentCollaborator.id);
    const userHasGroupReadAccess = folderFormValues.permissions.accessGroupsIds.some((id) => currentCollaboratorCollaboratorGroupIds.has(id));
    return userHasIndividualReadAccess || userHasGroupReadAccess;
  }, [currentCollaborator, currentCollaboratorCollaboratorGroupIds, folderFormValues]);

  const showAccessWarning = useMemo(() => hasReadAccessToNewFolder === false && !isPermissionConfigRestrictedAndEmpty, [hasReadAccessToNewFolder, isPermissionConfigRestrictedAndEmpty]);

  const onConfirm = useCallback(async () => {
    if (!folderTreeData || !folderFormValues || !projectId || !currentCollaborator || !currentCollaboratorCollaboratorGroupIds) return;

    const {
      name,
      accessType,
      inheritAccess,
      permissions,
    } = folderFormValues;
    const persistFolderDto: FolderCreateDto = {
      name,
      inheritAccess,
      parentId: parentFolderId ?? undefined,
    };
    if (!inheritAccess) {
      persistFolderDto.accessType = accessType;
      if (accessType === FolderAccessType.Restricted) {
        persistFolderDto.accessCollaboratorIds = permissions.accessCollaboratorIds;
        persistFolderDto.accessGroupsIds = permissions.accessGroupsIds;

        // Switching to restricted we keep the read-only IDs from public in case user switches back. We don't want to send those dangling ids when restricted though.
        const accessCollaboratorIdsSet = new Set(permissions.accessCollaboratorIds);
        persistFolderDto.readOnlyCollaboratorIds = permissions.readOnlyCollaboratorIds.filter((id) => accessCollaboratorIdsSet.has(id));
        const accessGroupIdsSet = new Set(permissions.accessGroupsIds);
        persistFolderDto.readOnlyGroupsIds = permissions.readOnlyGroupsIds.filter((id) => accessGroupIdsSet.has(id));
      } else if (accessType === FolderAccessType.Public) {
        persistFolderDto.readOnlyCollaboratorIds = permissions.readOnlyCollaboratorIds;
        persistFolderDto.readOnlyGroupsIds = permissions.readOnlyGroupsIds;
      }
    }
    try {
      const createdFolder = await mutateAsync(persistFolderDto);
      if (keepDialogOpen) {
        resetForm();
      } else {
        if (createdFolder && hasReadAccessToNewFolder) {
          setDocumentScope({ key: DocumentScopeKey.Folder, id: createdFolder.id });
        }
        onClose(t('create-folder-dialog_success-message', 'Successfully created the folder {{folder}}', { folder: folderFormValues.name }));
      }
    } catch (error) {
      const reason = getRequestErrorMessage(error);
      setErrorMessage(t('create-folder-dialog_request-error-message', 'Creating the folder failed. Reason: {{reason}}', { reason }));
    }
  }, [folderTreeData, folderFormValues, projectId, currentCollaborator, currentCollaboratorCollaboratorGroupIds, parentFolderId, mutateAsync, keepDialogOpen, resetForm, hasReadAccessToNewFolder, onClose, t, setDocumentScope, getRequestErrorMessage]);

  const [isEffectiveAccessDialogOpen, setIsEffectiveAccessDialogOpen] = useState(false);
  const onClickShowEffectiveAccessRightsDialog = useCallback(() => setIsEffectiveAccessDialogOpen(true), []);
  const onCloseEffectiveAccessDialog = useCallback(() => setIsEffectiveAccessDialogOpen(false), []);

  return (
    <Dialog
      id="CreateFolderDialog"
      open
      PaperProps={{ sx: { width: '680px', maxWidth: 'unset', maxHeight: '100vh' } }}
    >
      <DialogTitle sx={{ display: 'flex', pb: 0, alignItems: 'flex-start' }}>
        <Box sx={{ width: '50px', height: '50px' }}>
          <svg viewBox="0 0 32 32">
            <use xlinkHref="/img/sprite.svg#folder" />
          </svg>
        </Box>
        {t('create-folder-dialog_title', 'Create Folder')}
        <FormControlLabel
          sx={{ ml: 'auto', maxWidth: '250px', textAlign: 'right' }}
          control={<Checkbox checked={keepDialogOpen} onChange={(e) => setKeepDialogOpen(e.target.checked)} />}
          label={t('create-folder-dialog_keep-dialog-open-checkbox-label', 'Keep dialog open to create another folder')}
          labelPlacement="start"
        />
      </DialogTitle>
      <HorizontalDivider />
      <DialogContent sx={{ pt: 1 }}>
        {!!parentFolder && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FolderBreadcrumbs folderId={parentFolder.id} suppressInteraction leafNodeSx={{ fontWeight: 400 }} sx={{ flexShrink: 1, minWidth: 0 }} />
          <ChevronRightIcon sx={{ my: 'auto' }} />
          <Typography sx={{
            color: theme.palette.text.primary, fontStyle: 'italic', pl: 0.5, pr: 1, flexShrink: 0,
          }}
          >
            {t('create-folder-dialog_new-folder-breadcrumb-leaf', 'New Folder')}
          </Typography>
        </Box>
        )}
        {(parentFolder || !parentFolderId) ? (
          <FolderSettingsPanel
            folderId={undefined}
            parentFolderId={parentFolder?.id}
            folderFormValues={folderFormValues}
            onChange={onChangeFolderForm}
            sx={{ mt: 1 }}
          />
        ) : <CenteredCircularProgress sx={{ height: '280px' }} />}
        {!!errorMessage && (
        <Alert severity="error" sx={{ mt: 1 }}>
          <AlertTitle>{t('create-folder-dialog_error-title', 'Error')}</AlertTitle>
          {errorMessage}
        </Alert>
        )}
        {!!showAccessWarning && (
        <Alert severity="warning" sx={{ mt: 1 }}>
          <AlertTitle>{t('create-folder-dialog_access-warning-title', 'Warning')}</AlertTitle>
          {t('create-folder-dialog_access-warning-message', 'With the current permission configuration you won\'t have access to the new folder.')}
        </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => onClose(undefined)}
          sx={{ mr: 'auto' }}
        >
          {t('create-folder-dialog_cancel-button-label', 'Cancel')}
        </Button>
        <Button
          variant="text"
          color="primary"
          disabled={!folderFormValues}
          onClick={onClickShowEffectiveAccessRightsDialog}
          sx={{ pl: 1, ml: 'auto' }}
        >
          <InfoIcon sx={{ mr: 0.5 }} />
          {t('folder-settings-panel_effective-access-rights-button-label', 'Show me who will have access')}
        </Button>
        {isEffectiveAccessDialogOpen && <EffectiveAccessRightsDialog folderFormValues={folderFormValues} onClose={onCloseEffectiveAccessDialog} />}
        <Button
          variant="contained"
          color="primary"
          onClick={onConfirm}
          disabled={!canConfirm || isLoadingCreate || isLoadingFolderTreeData}
          tabIndex={0}
          sx={{ ml: 1 }}
        >
          {t('create-folder-dialog_confirm-button-label', 'Create Folder')}
          {isLoadingCreate && <CircularProgress size={12} sx={{ ml: 1 }} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
