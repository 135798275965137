import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';
import RoleAction from 'projects/types/RoleAction';

enum QueryFilterVisibility {
  Private,
  Public,
}

export interface SaveFilterDialogResult {
  name: string,
  isPrivate: boolean,
}

interface SaveFilterDialogProps {
  onClose: (result: SaveFilterDialogResult | undefined) => void,
}

export default function SaveFilterDialogDocuments({
  onClose,
}: SaveFilterDialogProps) {
  const { t } = useTranslation('query-filters');

  const allowedActions = useAllowedActions();
  const canCreatePublicFilter = useMemo(() => allowedActions?.has(RoleAction.DocumentFilter_Create), [allowedActions]);

  const [name, setName] = useState('');
  const [visibility, setVisibility] = useState<QueryFilterVisibility>(QueryFilterVisibility.Private);

  const inputValid = useMemo(() => name?.length > 0, [name]);

  const onConfirm = useCallback(() => {
    onClose({
      name,
      isPrivate: visibility === QueryFilterVisibility.Private,
    });
  }, [name, onClose, visibility]);

  const onCancel = useCallback(() => {
    onClose(undefined);
  }, [onClose]);

  return (
    <Dialog open id="SaveFilterDialogDocuments">
      <DialogTitle>
        {t('save-filter-dialog_header', 'Save As New Filter')}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ py: 2, display: 'flex', alignItems: 'flex-end' }}>
          <TextField
            id="save-filter-dialog_filter-name-label"
            label={t('save-filter-dialog_filter-name-label', 'Filter Name')}
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ width: 200, mr: 6, mb: '2px' }}
          />
          <FormControl>
            <Typography id="save-filter-dialog_filter-query-visibility-header" variant="h5">{t('save-filter-dialog_filter-query-visibility-header', 'Visibility')}</Typography>
            <RadioGroup
              row
              aria-labelledby="save-filter-dialog_filter-query-visibility-header"
              value={visibility}
              onChange={(e) => setVisibility(parseInt(e.target.value, 10))}
              name="radio-buttons-group"
            >
              <FormControlLabel value={QueryFilterVisibility.Private} control={<Radio />} label={t('save-filter-dialog_filter-query-visibility-private', 'Private')} />
              <FormControlLabel value={QueryFilterVisibility.Public} control={<Radio disabled={!canCreatePublicFilter} />} label={t('save-filter-dialog_filter-query-visibility-public', 'Public')} />
            </RadioGroup>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button id="save-filter-dialog_cancel" onClick={onCancel} variant="contained" color="secondary" sx={{ mr: 'auto' }}>
          {t('save-filter-dialog_cancel-button-label', 'Cancel')}
        </Button>
        <Button id="save-filter-dialog_confirm" onClick={onConfirm} variant="contained" color="primary" disabled={!inputValid} sx={{ gap: 1 }}>
          {t('save-filter-dialog_confirm-button-label', 'Save Filter')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
