import React, { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Modal } from '@mui/material';
import DocumentViewerContextProvider from 'documents-details/contexts/DocumentViewerContextProvider';
import DocumentDetails from 'documents-details/components/DocumentDetails';
import SuspenseWithErrorBoundary from 'error/SuspenseWithErrorBoundary';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import IssuesFilterContextProvider from 'issues/contexts/IssuesFilterContextProvider';
import DocumentDetailsSelectionContextProvider from 'documents-details/contexts/DocumentDetailsSelectionContextProvider';
import { DocumentDetailsSelectionChange } from 'documents-details/contexts/DocumentDetailsSelectionContext';

export default function IssueLinkedDocumentVersionPreviewModal() {
  const navigate = useNavigate();
  const [queryFilterId, setQueryFilterId] = useState<string | undefined>(undefined);
  const { documentVersionId, documentVersionIssueId } = useParams<'documentVersionId' | 'documentVersionIssueId'>();
  const { state } = useLocation();

  const setDocumentVersionAndIssueId = useCallback((change: DocumentDetailsSelectionChange) => {
    const nextDocumentVersionId = change.documentVersionId === null ? undefined : (change.documentVersionId ?? documentVersionId);
    const nextIssueId = change.issueId === null ? undefined : (change.issueId ?? documentVersionIssueId);
    const parts = [`../document/${nextDocumentVersionId}`];
    if (nextIssueId) {
      parts.push(`issue/${nextIssueId}`);
    }
    const route = parts.join('/');
    navigate(route);
  }, [documentVersionId, documentVersionIssueId, navigate]);

  const onClose = useCallback(() => {
    navigate('../');
  }, [navigate]);

  const initiallySelectedAnnotationName = useMemo(() => {
    if (!state) return undefined;
    const annotationName = state.annotationName as string;
    return annotationName;
  }, [state]);

  return (
    <Modal id="DocumentDetailsModal" open disablePortal>
      <Box sx={{ height: '100%' }}>
        <SuspenseWithErrorBoundary suspenseFallback={<CenteredCircularProgress />}>
          <IssuesFilterContextProvider queryFilterId={queryFilterId} setQueryFilterId={setQueryFilterId}>
            <DocumentDetailsSelectionContextProvider
              documentVersionId={documentVersionId}
              issueId={documentVersionIssueId}
              setDocumentVersionAndIssueId={setDocumentVersionAndIssueId}
            >
              <DocumentViewerContextProvider initiallySelectedAnnotationName={initiallySelectedAnnotationName}>
                <DocumentDetails onClose={onClose} />
              </DocumentViewerContextProvider>
            </DocumentDetailsSelectionContextProvider>
          </IssuesFilterContextProvider>
        </SuspenseWithErrorBoundary>
      </Box>
    </Modal>
  );
}
