import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Skeleton, Typography, useTheme } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import IssueItemCard from 'issues/components/IssueItemCard';
import IssueDto from 'issues/types/IssueDto';

const COLLAPSE_THRESHOLD = 232;

export interface IssuesCalendarDayColumnProps extends ISxProps {
  dayName: string,
  dateString: string,
  dueIssues: IssueDto[] | undefined,
  isSelected: boolean,
  onSelect: () => void,
  onClickIssue: (issueId: string) => void,
  selectedIssueId: string | undefined,
}

export default function IssuesCalendarDayColumn({
  sx,
  dayName,
  dateString,
  dueIssues,
  isSelected,
  onSelect,
  onClickIssue,
  selectedIssueId,
}: IssuesCalendarDayColumnProps) {
  const theme = useTheme();
  const issueCardsContainerRef = useRef<HTMLDivElement>(null);
  const [collapsed, setCollapsed] = useState(() => (issueCardsContainerRef.current ? issueCardsContainerRef.current.clientWidth < COLLAPSE_THRESHOLD : false));
  useEffect(() => {
    if (!issueCardsContainerRef.current) return undefined;
    const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      setCollapsed(entries[0].borderBoxSize[0].inlineSize < COLLAPSE_THRESHOLD);
    });
    observer.observe(issueCardsContainerRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        gap: 1,
        p: 1,
        flex: '1 0 0',
        minWidth: isSelected ? 300 : 'auto',
      }}
    >
      <Button
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '8px',
          pointerEvents: isSelected ? 'none' : undefined,
        }}
        onClick={onSelect}
        variant="outlined"
        color={isSelected ? 'info' : 'primary'}
      >
        <Typography>{dayName}</Typography>
        <Typography sx={{ fontWeight: 600 }}>{dateString}</Typography>
      </Button>
      <Box
        ref={issueCardsContainerRef}
        sx={{
          background: isSelected ? theme.palette.secondary.light : theme.palette.grey[200],
          p: 2,
          borderRadius: '6px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          gap: 2,
          maxWidth: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          boxShadow: 'inset 0px 0px 8px -4px rgba(0,0,0,0.2)',
          ...sx,
        }}
      >
        {!dueIssues && <Skeleton variant="rectangular" sx={{ borderRadius: '6px', height: collapsed ? 29 : 96 }} />}
        {!!dueIssues && dueIssues.map((issue) => (
          <IssueItemCard
            key={issue.id}
            issue={issue}
            onClick={onClickIssue}
            selected={selectedIssueId === issue.id}
            collapsed={collapsed}
          />
        ))}
      </Box>
    </Box>
  );
}
