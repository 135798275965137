import {
  IfcState,
  VisoModel,
  IdAttrName,
  Position,
  Normal,
  OPENING,
  SPACE,
  VisoObjectMap,
  ModelData,
} from "./BaseDefinitions";
import {
  MeshLambertMaterial,
  DoubleSide,
  BufferGeometry,
  BufferAttribute,
  Material,
  LineSegments,
  LineBasicMaterial,
  Color,
  MeshBasicMaterial,
} from "three";
import { mergeBufferGeometries } from "three/examples/jsm/utils/BufferGeometryUtils";
import { BvhManager } from "./BvhManager";
import { IFCModel } from "./IFCModel";
import { VisoInstanceMesh } from "./VisoInstanceMesh";
import { VisoSolidMesh } from "./VisoSolideMesh";
import { SubsetUtils } from "./SubsetUtils";

const OPENMATKEY = "OPENING_MAT";
const OPENINGMAT: MeshLambertMaterial = new MeshLambertMaterial({
  color: 0x0096ff,
  opacity: 0.7,
  transparent: true,
});

export interface ParserProgress {
  loaded: number;
  total: number;
}

export interface ParserAPI {
  parseVisoModel(model: VisoModel): IFCModel;
}

interface MaterialMap {
  [materialID: string]: {
    material: Material;
    index: number;
  };
}

interface GeometryWithGroups {
  geometry: BufferGeometry;
  groupInfos: GroupInfo[];
}

interface GroupInfo {
  count: number;
  index: number;
}

/**
 * Reads all the geometry of the IFC file and generates an optimized `THREE.Mesh`.
 */
export class IFCParser implements ParserAPI {
  private expressId = 0;

  private instancedMeshes: VisoInstanceMesh[] = [];
  private wireBufferGeometries: BufferGeometry[] = [];

  private materialMap: MaterialMap = {};
  private geometryWithGroups: GeometryWithGroups[] = [];

  private wireframeMat: LineBasicMaterial = new LineBasicMaterial({
    color: 0x000000,
    opacity: 0.3,
    transparent: true,
  });

  // BVH is optional because when using workers we have to apply it in the main thread,
  // once the model has been serialized and reconstructed
  constructor(private state: IfcState, private BVH?: BvhManager) { }

  parseVisoModel(model: VisoModel) {
    this.initializeData(model.styles);
    const ifcModel = this.newIfcModelForViso();
    return this.loadVisoModel(model, ifcModel);
  }

  private newIfcModelForViso() {
    const model = new IFCModel();
    this.expressId = 0;

    return model;
  }

  private loadVisoModel(visoModel: VisoModel, model: IFCModel) {
    if (visoModel.center) {
      model.center = visoModel.center;
    }

    if (visoModel.size) {
      model.size = visoModel.size;
    }

    model.storeys = visoModel.storeys;
    model.modelFileId = visoModel.id;

    this.parseVisoGeometry(visoModel, model);

    const geometries = this.geometryWithGroups.map((g) => g.geometry);
    const materials = Object.keys(this.materialMap).map((key) => {
      return this.materialMap[key].material;
    });

    let combinedGeometry:BufferGeometry|null=null;
    if(geometries.length){
      combinedGeometry=mergeBufferGeometries(geometries);
    }

    let offset = 0;
    for (let i = 0, n = this.geometryWithGroups.length; i < n; i++) {
      const info = this.geometryWithGroups[i];
      for (let j = 0, l = info.groupInfos.length; j < l; j++) {
        const groupInfo = info.groupInfos[j];
        combinedGeometry?.addGroup(offset, groupInfo.count, groupInfo.index);

        offset += groupInfo.count;
      }
    }

    this.cleanUpGeometryMemory(geometries);
    this.cleanMaterialMemory();

    length = this.wireBufferGeometries.length;
    if (length) {
      const edges = mergeBufferGeometries(this.wireBufferGeometries);
      const wireframe = new LineSegments(edges, this.wireframeMat);

      for (let i = 0; i < length; i++) {
        this.wireBufferGeometries[i].dispose();
      }
      this.wireBufferGeometries = [];
      wireframe.renderOrder = 1;

      model.wireframe = wireframe;
      model.add(wireframe);
    }

    if (combinedGeometry && this.BVH) {
      this.BVH.applyThreeMeshBVH(combinedGeometry);
    }

    const solid =
      combinedGeometry != null
        ? new VisoSolidMesh(combinedGeometry, materials, model.modelFileId)
        : new VisoSolidMesh(
          new BufferGeometry(),
          new MeshBasicMaterial(),
          model.modelFileId
        );

    for (let i = 0, n = this.instancedMeshes.length; i < n; i++) {
      const mesh = this.instancedMeshes[i];
      solid.add(mesh);
      model.instancedMeshes.push(mesh);
      mesh.geometry.dispose();
      mesh.clear();
    }

    this.instancedMeshes = [];

    solid.renderOrder = 2;
    model.solid = solid;
    model.add(solid);

    this.state.models[model.modelFileId] = model;
    return model;
  }

  private parseVisoGeometry(visoModel: VisoModel, ifcModel: IFCModel) {
    const { shapes, model, nodeData } = visoModel;

    const keys = Object.keys(model);
    for (let i = 0, n = keys.length; i < n; i++) {
      const shapeId = keys[i];
      const nodes = model[shapeId];
      const shape = shapes[shapeId];

      if (shape?.triangles.length) {
        this.parseSolidEntity(nodes, shape, ifcModel);
        this.parseWireframeEntity(nodes, shape, ifcModel);

        this.expressId++;
      }
    }

    const modelNodeData:any = {};
    const globalIds:any={};
    nodeData.forEach((n)=>{
      modelNodeData[n.globalId]=n;
      globalIds[n.id]=n.globalId;
    });

    this.state.modelData[ifcModel.modelFileId] = { nodeData:modelNodeData, shapes, globalIds };
  }

  private parseWireframeEntity(nodes: any, shape: any, model: IFCModel) {
    let geometry = this.getWirebufferGeometry(shape);
    if (nodes.length > 1) {
      //TODO load instanced buffer geometry
    } else {
      const geo = geometry.clone();
      const node = nodes[0];
      const mat = node.transform;

      const idAttribute = new Uint32Array(shape.vertices.length / 3);
      idAttribute.fill(this.expressId);
      geo.setAttribute(IdAttrName, new BufferAttribute(idAttribute, 1));
      geo.applyMatrix4(SubsetUtils.getTransformMatrix(mat));
      this.wireBufferGeometries.push(geo);
    }

    geometry.dispose();
  }

  private parseSolidEntity(nodes: any, shape: any, ifcModel: IFCModel) {
    if (shape.vertices.length == shape.normals.length) {
      const firstNode = nodes[0];
      const isOpening = firstNode.elementType.toUpperCase() === OPENING;
      const isSpace = firstNode.elementType.toUpperCase() === SPACE;

      let bufferData = this.getGeneralBufferGeometry(
        shape,
        firstNode.styleIds,
        !isOpening
      );
      const geometry = bufferData.geometry.clone();

      const nodeLength = nodes.length;
      if (nodeLength > 1) {
        if (this.BVH) this.BVH.applyThreeMeshBVH(geometry);

        const materials: Material[] = [];
        for (let i = 0, n = shape.triangles.length; i < n; i++) {
          materials.push(this.materialMap[firstNode.styleIds[i]].material);
        }

        const instancedMesh = new VisoInstanceMesh(
          geometry,
          isOpening ? OPENINGMAT : materials,
          nodeLength,
          ifcModel.modelFileId
        );
        for (let i = 0; i < nodeLength; i++) {
          instancedMesh.setMatrixAt(
            i,
            SubsetUtils.getTransformMatrix(nodes[i].transform)
          );
          instancedMesh.setGlobalIdAt(i, nodes[i].globalId);
        }

        instancedMesh.isOpening = isOpening;
        instancedMesh.isSpace = isSpace;

        this.instancedMeshes.push(instancedMesh);
      } else {
        geometry.applyMatrix4(
          SubsetUtils.getTransformMatrix(firstNode.transform)
        );
        this.geometryWithGroups.push({
          geometry,
          groupInfos: bufferData.groupInfos,
        });

        ifcModel.globalIds[this.expressId] = firstNode.globalId;
        if (isOpening) {
          ifcModel.openingIds.push(firstNode.globalId);
        }

        if (isSpace) {
          ifcModel.spaceIds.push(firstNode.globalId);
        }
      }

      bufferData.geometry.dispose();
    }
  }

  private getColorInfo(color: any) {
    if (color[0] === 0 && color[1] === 0 && color[2] === 0 && color[3] === 0) {
      return { color: new Color(0.5, 0.5, 0.5), opacity: 1 };
    }

    return {
      color: new Color(color[0] / 255, color[1] / 255, color[2] / 255),
      opacity: color[3] / 255,
    };
  }

  private getWirebufferGeometry(shape: any) {
    const posData = shape.vertices;
    const indexData = shape.wireframe;
    const geometry = new BufferGeometry();

    geometry.setAttribute(Position, new BufferAttribute(posData, 3));
    geometry.setIndex(new BufferAttribute(indexData, 1));

    return geometry;
  }

  private getGeneralBufferGeometry(
    shape: any,
    styleIds: string[],
    isGroup: boolean
  ) {
    const posData = shape.vertices;
    const norData = shape.normals;
    const idAttribute = new Uint32Array(shape.vertices.length / 3);
    idAttribute.fill(this.expressId);

    const geometry = new BufferGeometry();
    geometry.setAttribute(Position, new BufferAttribute(posData, 3));
    geometry.setAttribute(Normal, new BufferAttribute(norData, 3));
    geometry.setAttribute(IdAttrName, new BufferAttribute(idAttribute, 1));

    let total = 0;
    const length = shape.triangles.length;
    for (let i = 0; i < length; i++) {
      total += shape.triangles[i].length;
    }

    const indexData = new Uint32Array(total);
    let offset = 0;

    const groupInfos: GroupInfo[] = [];
    for (let i = 0; i < length; i++) {
      indexData.set(shape.triangles[i], offset);
      const count = shape.triangles[i].length;

      if (isGroup) {
        geometry.addGroup(offset, count, i);
        groupInfos.push({ count, index: this.materialMap[styleIds[i]].index });
      }

      offset += count;
    }

    geometry.setIndex(new BufferAttribute(indexData, 1));

    return {
      geometry,
      groupInfos: isGroup
        ? groupInfos
        : [{ count: total, index: Object.keys(this.materialMap).length - 1 }],
    };
  }

  // Three.js geometry has to be manually deallocated
  private cleanUpGeometryMemory(geometries: BufferGeometry[]) {
    for (let i = 0, n = geometries.length; i < n; i++) {
      geometries[i].dispose();
    }

    for (let i = 0, n = this.geometryWithGroups.length; i < n; i++) {
      this.geometryWithGroups[i].geometry.dispose();
    }
    this.geometryWithGroups = [];
  }

  private cleanMaterialMemory() {
    Object.keys(this.materialMap).forEach((key) => {
      this.materialMap[key].material.dispose();
    });
    this.materialMap = {};
  }

  private initializeData(styles: VisoObjectMap) {
    this.cleanMaterialMemory();
    const styleIds = Object.keys(styles);
    const tempMat = new MeshLambertMaterial({ side: DoubleSide });

    let i = 0;
    for (let n = styleIds.length; i < n; i++) {
      const colID = styleIds[i];
      const material = tempMat.clone();

      const style = styles[colID];

      const { color, opacity } = this.getColorInfo(style.diffuse);
      material.color = color;
      material.color.convertSRGBToLinear();
      material.emissive = this.getColorInfo(style.emissive).color;
      material.emissive.convertSRGBToLinear();

      material.transparent = opacity !== 1;
      if (material.transparent) material.opacity = opacity;

      const scope = this;
      material.onBeforeCompile = function (shader) {
        shader.uniforms.u_color = { value: color };
        shader.uniforms.u_section = { value: scope.state.sectionColor ? scope.state.sectionColor : color };
        shader.fragmentShader = 'uniform vec3 u_section;\n' + shader.fragmentShader;
        shader.fragmentShader = shader.fragmentShader.replace(
          'vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + totalEmissiveRadiance;',
          [
            '#ifdef DOUBLE_SIDED',
            `vec3 outgoingLight = (gl_FrontFacing) ? u_section : reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + totalEmissiveRadiance;`,
            '#else',
            'vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + totalEmissiveRadiance;',
            '#endif'
          ].join('\n')
        );

        material.userData.shader = shader;
      };

      material.customProgramCacheKey = function () {
        return colID;
      }

      this.materialMap[colID] = { material, index: i };
    }

    this.materialMap[OPENMATKEY] = { material: OPENINGMAT, index: i };
  }
}
