import React from 'react';
import { Box, Typography } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import DisciplineModelFilesGroup from 'models/types/DisciplineModelFilesGroup';
import LabelChip from 'labels/components/LabelChip';
import BuildingModelFilesGroupItem from 'models/components/BuildingModelFilesGroupItem';

interface DisciplineModelFilesGroupItemProps extends ISxProps {
  group: DisciplineModelFilesGroup,
}

export default function DisciplineModelFilesGroupItem({
  sx,
  group,
}: DisciplineModelFilesGroupItemProps) {
  return (
    <Box id="DisciplineModelFilesGroupItem" sx={{ ...sx, display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'baseline' }}>
        <LabelChip label={group.discipline} abbreviated />
        <Typography variant="h6">{group.discipline.name}</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pl: 2 }}>
        {group.buildingGroups.map((buildingGroup) => <BuildingModelFilesGroupItem key={buildingGroup.building.id} group={buildingGroup} />)}
      </Box>
    </Box>
  );
}
