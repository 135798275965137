import { Color, DoubleSide, Mesh, MeshBasicMaterial, Plane } from "three";
import {
  IfcState,
  SELECT_ID,
  COLORGROUP_ID,
  SelectionData,
  VisualState,
  ColorIdsMap,
  ColorMatMap,
  GlobalIdsMap,
  OPENING,
  SPACE,
  VisoNode,
} from "./BaseDefinitions";
import { BvhManager } from "./BvhManager";
import { IFCModel } from "./IFCModel";
import { ItemsHider } from "./ItemsHider";
import { PropertyManager } from "./PropertyManager";
import { HoverSubset } from "./HoverSubset";
import { ItemsMap } from "./ItemsMap";
import { PickSubset } from "./PickSubset";
import { ColorSubset, TRANSCOLOR, transMat } from "./ColorSubset";
import { HighlightSubSet as HighlightSubset } from "./HighlightSubSet";

export interface Subset extends Mesh {
  modelID: number;
}

export type Subsets = {
  [subsetID: string]: { ids: Set<number>; mesh: Subset; bvh: boolean };
};

/**
 * Contains the logic to get, create and delete geometric subsets of an IFC model. For example,
 * this can extract all the items in a specific IfcBuildingStorey and create a new Mesh.
 */
export class SubsetManager {
  readonly items: ItemsMap;
  private readonly BVH: BvhManager;
  private state: IfcState;
  private hoverSubset: HoverSubset;
  private selectSubset: PickSubset;
  private colorSubset: ColorSubset;
  private highLightSubset: HighlightSubset;
  private isIfcBuildingElementPartParentSelectionEnabled = true;

  constructor(state: IfcState, BVH: BvhManager, private hider: ItemsHider) {
    this.state = state;
    this.items = new ItemsMap(state);
    this.BVH = BVH;
    this.hoverSubset = new HoverSubset(this.state, this.BVH);
    this.selectSubset = new PickSubset(this.state, this.BVH);
    this.colorSubset = new ColorSubset(this.state, this.BVH, this.selectSubset);
    this.highLightSubset = new HighlightSubset(this.state, this.BVH);
  }

  /** **************************************** Visoplan ********************************************* **/
  reset() {
    this.hoverSubset.hide();
    this.selectSubset.dispose();
    this.colorSubset.dispose();
  }

  dispose() {
    this.hoverSubset.removeHoverMesh();
    this.selectSubset.dispose();
    this.colorSubset.dispose();
    this.highLightSubset.dispose();
  }

  hoverComponent(modelId: string, globalId: string) {
    let modelID = modelId;
    if (modelID === COLORGROUP_ID) {
      modelID = this.getModelId(globalId);
    }

    return this.hoverSubset.hoverComponent(modelID, globalId);
  }

  pickComponent(modelId: string, globalId: string, ctrl: boolean) {
    let modelID = this.getModelId(globalId);
    if (!modelID) return null;
    const selectedIds = this.isIfcBuildingElementPartParentSelectionEnabled
      ? this.getFamilyGlobalIds(modelID, globalId)
      : [globalId];
    if (modelId === SELECT_ID) {
      if (modelID) {
        if (ctrl) {
          const colorIds = this.colorSubset.getAllColorIds();
          const containedColorIds = selectedIds.filter((s) => !colorIds.has(s));
          this.hider.showItems(modelID, containedColorIds);
        } else {
          this.showHiddenItems();
          this.hider.hideItems(modelID, selectedIds);
        }
      } else {
        throw new Error(`Could not find model with ${globalId}`);
      }
    } else {
      if (!ctrl) {
        this.showHiddenItems();
      }

      this.hider.hideItems(modelID, selectedIds);
    }

    if (modelID) {
      return this.selectSubset.processSelection(modelID, selectedIds, ctrl);
    }

    return null;
  }

  resetPickComponent() {
    this.showHiddenItems();
    this.selectSubset.reset();
    this.colorSubset.refresh();
  }

  setClippingPlanes(planes: Plane[]) {
    this.hoverSubset.setClippingPlanes(planes);
    this.selectSubset.setClippingPlanes(planes);
    this.colorSubset.setClippingPlanes(planes);
  }

  getSelectedData() {
    const data: SelectionData = { count: 0, isTrans: true, components: [] };
    const modelData = this.selectSubset.getModelData();

    data.components = modelData.components;
    data.modelFileIds = modelData.modelfileIds;
    data.count = data.components.length;
    data.isTrans = this.colorSubset.getTranslateState();
    return data;
  }

  hideSelectedComponent() {
    this.selectSubset.reset();
  }

  transparentElements(isOn: boolean) {
    return this.colorSubset.transparentElements(isOn);
  }

  setColorElements(color: string) {
    return this.colorSubset.addColorElements(color);
  }

  resetColorElements() {
    return this.colorSubset.resetColorElements();
  }

  refreshColorComponents() {
    this.colorSubset.refresh();
  }

  /**
   * @desc Show only selected items.
   *
   * First hide all items in model because selected components remain in PickSubset.
   * And then remove the items contained in selected components from ColorSubset.
   */
  isolateElements() {
    this.hideAllModels();
    this.colorSubset.isolateElements();
  }

  /**
   * @desc Show only selected items and change their material to transparent.
   *
   * First hide all items in model because selected components remain in PickSubset.
   * Second remove the items contained in selected components from ColorSubset.
   * And then change the material of remained items to transparent.
   */
  isolateAndTransparentElements() {
    this.hideAllModels();
    return this.colorSubset.isolateAndTranslateElements();
  }

  resetElements() {
    this.colorSubset.resetElements();

    this.showHiddenItems();
    this.selectSubset.reset();
  }

  resetModel() {
    this.selectSubset.reset();
    this.colorSubset.reset();
  }

  handleIfcVisibleComponent(id: string, opening: boolean, space: boolean) {
    this.selectSubset.handleIfcVisibleComponent(id, opening, space);
  }

  handleColorVisibleComponent(opening: boolean, space: boolean) {
    this.colorSubset.handleIfcVisibleComponent(opening, space);
  }

  updateSectionColor() {
    this.colorSubset.updateSectionColor();
  }

  handleVisualState(visualState: VisualState) {
    const colorMap: ColorIdsMap = {};
    const colorMat: ColorMatMap = {};
    Object.keys(this.state.modelData).forEach((key) => {
      if (key) {
        const globalIds = Object.keys(this.state.modelData[key].nodeData);
        const allGlobalIdsSet = new Set(globalIds);
        const nodesByNodeId = new Map(globalIds.map((globalId) => {
          const node = this.state.modelData[key].nodeData[globalId];
          return [node.id, node];
        }));
        const nodesByGlobalId = new Map(globalIds.map((globalId) => {
          const node = this.state.modelData[key].nodeData[globalId];
          return [globalId, node];
        }));
        if (visualState.exceptedIDs) {
          const rootNodes = visualState.exceptedIDs
            .filter((globalId) => nodesByGlobalId.has(globalId))
            .map((globalId) => nodesByGlobalId.get(globalId)!);
          
          const nodes = rootNodes.flatMap((rootNode) => {
            const stack = [rootNode];
            const descendantAndSelf = [rootNode];
            while (stack.length) {
              const pivot = stack.pop()!;
              const childNodeIds = pivot.isDecomposedByNodeIds;
              const childNodes = childNodeIds
                .filter((childNodeId) => nodesByNodeId.has(childNodeId))
                .map((childNodeId) => nodesByNodeId.get(childNodeId)!)
              stack.push(...childNodes);
              descendantAndSelf.push(...childNodes);
            }
            return descendantAndSelf;
          });

          const affectedNodesGlobalIds = nodes.map((node) => node.globalId);

          if(visualState.visibility){
            if(affectedNodesGlobalIds.length){
              this.hider.hideItems(key, affectedNodesGlobalIds);
            }
          }else{
            this.hider.hideAllItems(key);
            if(affectedNodesGlobalIds.length){
              this.hider.showItems(key, affectedNodesGlobalIds);
            }
          }
        }

        if (visualState.colorGroups) {
          visualState.colorGroups.forEach((g) => {
            const colorIds = g.componentIDs.filter((i) =>
            allGlobalIdsSet.has(i)
            );
            if (colorIds.length) {
              const colorName = g.color;
              if (!colorMat[colorName]) {
                const { color, opacity } = this.getColorInfo(g.color);
                const mat = new MeshBasicMaterial({ color, side: DoubleSide });
                if (opacity) {
                  mat.transparent = true;
                  mat.opacity = opacity;
                }

                colorMat[colorName] = mat;
              }

              if (!colorMap[colorName]) {
                colorMap[colorName] = {};
              }

              colorMap[colorName][key] = colorIds;
              this.hider.hideItems(key, colorIds);
            }
          });
        }

        if (visualState.transparentIDs) {
          const transIds = visualState.transparentIDs.filter((i) =>
            allGlobalIdsSet.has(i)
          );
          if (transIds.length) {
            if (!colorMat[TRANSCOLOR]) {
              colorMat[TRANSCOLOR] = transMat;
            }

            if (!colorMap[TRANSCOLOR]) {
              colorMap[TRANSCOLOR] = {};
            }

            colorMap[TRANSCOLOR][key] = transIds;
            this.hider.hideItems(key, transIds);
          }
        }

        if (visualState.selectedIDs) {
          const selectIds = visualState.selectedIDs.filter((i) =>
            allGlobalIdsSet.has(i)
          );
          if (selectIds.length) {
            this.selectSubset.selectedGlobalIds[key] = selectIds;
            this.hider.hideItems(key, selectIds);
          }
        }
      }
    });

    this.colorSubset.showColorSubset(colorMap, colorMat);
    this.selectSubset.refreshSubset();

    return {
      colorModel: this.colorSubset.getModel(),
      pickModel: this.selectSubset.getModel(),
    };
  }

  hideOtherType(components: VisoNode[]) {
    const types = this.getSelectedTypes(components);

    Object.keys(this.state.modelData).forEach((key) => {
      if (key) {
        const nodeData =this.state.modelData[key].nodeData;
        const globalIds = Object.keys(nodeData)
          .filter((gId) => !types.has(nodeData[gId].elementType));
        if (globalIds.length) {
          this.hider.hideItems(key, globalIds);
          this.colorSubset.hideOtherTypes(key, globalIds);
        }
      }
    });
  }

  isolateStoreys(components: VisoNode[]) {
    const storeyIds = this.getGlobalIdsInStorey(components);
    if (Object.keys(storeyIds).length) {
      Object.keys(this.state.modelData).forEach((key) => {
        if (key) {
          this.hider.hideAllItems(key);

          if (storeyIds[key]) {
            this.hider.showItems(key, storeyIds[key]);
          }
        }
      });

      this.resetModel();
      return true;
    }

    return false;
  }

  getColorIds() {
    return this.colorSubset.getColorIds();
  }

  getVisualState() {
    // selectedIDs
    const selectedIds = this.selectSubset.getAllSelectedIds();
    const selectedIDs = selectedIds.size ? Array.from(selectedIds) : [];

    // transprentIDs
    const transparentIds = this.colorSubset.getTransparentIds();
    const transparentIDs = transparentIds ? Array.from(transparentIds) : [];

    // colorGroups
    const colorGroups = this.colorSubset.getColorGroups();

    // Visibility and exceptedIds
    const { visibility, exceptedIDs } = this.getVisibilityAndExceptedIds();

    return {
      visibility,
      colorGroups,
      selectedIDs,
      transparentIDs,
      exceptedIDs,
    } as VisualState;
  }

  highLightComponents(globalIds: string[]) {
    return this.highLightSubset.highLightComponents(globalIds);
  }

  pickComponents(components: VisoNode[]) {
    components.forEach((component) => {
      if (component.shapeId) {
        this.pickNode(component);
      } else {
        const modelData = this.state.modelData[component.modelFileId];
        component.isDecomposedByNodeIds.forEach((nId) => {
          const node = modelData.nodeData[modelData.globalIds[nId]];
          if (node?.shapeId)
            this.pickNode(node);
        })
      }
    });

    this.selectSubset.refreshSubset();

    return this.selectSubset.getModel();
  }

  toggleParentSelection() {
    this.isIfcBuildingElementPartParentSelectionEnabled =
      !this.isIfcBuildingElementPartParentSelectionEnabled;
  }

  removeModels(modelIds: string[]) {
    this.selectSubset.removeModels(modelIds);
    this.colorSubset.removeModels(modelIds);
  }

  private hideAllModels() {
    Object.keys(this.state.modelData).forEach((key) => {
      this.hider.hideAllItems(key);
    });
  }

  private getVisibilityAndExceptedIds() {
    const selectedIds = Array.from(this.selectSubset.getAllSelectedIds());
    const colorIds = Array.from(this.colorSubset.getAllColorIds());

    const hiddenIds = this.hider.hiddenGlobalIds.filter(
      (id) => !selectedIds.includes(id) && !colorIds.includes(id)
    );

    const allIds = new Set<string>();
    Object.keys(this.state.modelData).forEach((key) => {
      const nodeData = this.state.modelData[key].nodeData;
      Object.values(nodeData).forEach(node=>{
        if (node.globalId){
          if (
          (!this.hider.openingVisible &&
            node.elementType?.toUpperCase() == OPENING) ||
          (!this.hider.spaceVisible && node.elementType?.toUpperCase() == SPACE)
        ) {
          const index = hiddenIds.indexOf(node.globalId);
          if (index > -1) {
            hiddenIds.splice(index, 1);
          }
        }else{
          allIds.add(node.globalId);
        }
        }
      });
    });

    const visibleIds = Array.from(allIds).filter(
      (id) => !hiddenIds.includes(id)
    );

    const visibility = visibleIds.length > hiddenIds.length;
    const exceptedIDs = visibility ? [...hiddenIds] : [...visibleIds];

    return { visibility, exceptedIDs };
  }

  private getGlobalIdsInStorey(components: VisoNode[]) {
    const globalIds = this.getComponentGlobalIds(components);
    const storeyIds: GlobalIdsMap = {};

    Object.keys(globalIds).forEach((key) => {
      if (key) {
        const storeys = this.state.models[key].storeys;
        let ids: Array<string> = [];
        if (storeys) {
          Object.keys(storeys).forEach((storeyName) => {
            const isIncluded = (element: any) =>
              storeys[storeyName].includes(element);
            if (globalIds[key].some(isIncluded)) {
              ids = [...ids, ...storeys[storeyName]];
            }
          });
        }

        if (ids.length) {
          storeyIds[key] = ids;
        }
      }
    });

    return storeyIds;
  }

  private getComponentGlobalIds(nodes: VisoNode[]) {
    const globalIds: GlobalIdsMap = {};
    for (let i = 0, n = nodes.length; i < n; i++) {
      let node: VisoNode | undefined = nodes[i];
      const key = node.modelFileId;
      if (node.elementType === "IfcBuildingElementPart" && node.decomposesNodeIds.length) {
        node = this.state.modelData[key].nodeData[this.state.modelData[key].globalIds[node.decomposesNodeIds[0]]];
      }

      if (node) {
        if (globalIds[key]) {
          if (!globalIds[key].includes(node.globalId))
            globalIds[key].push(node.globalId);
        } else {
          globalIds[key] = [node.globalId];
        }
      }
    }
    return globalIds;
  }

  private getSelectedTypes(components: VisoNode[]) {
    const globalIds = this.getComponentGlobalIds(components);
    let selectedTypes = new Set<any>();
    Object.keys(globalIds).forEach((key) => {
      if (key) {
        globalIds[key].forEach((gId)=>{
          const node = this.state.modelData[key].nodeData[gId];
          if(node){
            selectedTypes.add(node.elementType);
          }
        });
      }
    });

    return selectedTypes;
  }

  private getModelId(globalId: string) {
    const modelIds = Object.keys(this.state.models);
    for (let i = 0; i < modelIds.length; i++) {
      if (
        !modelIds[i] ||
        modelIds[i] == SELECT_ID ||
        modelIds[i] == COLORGROUP_ID
      )
        continue;

      const modelId = this.getModelIdFromModel(
        this.state.models[modelIds[i]],
        globalId
      );

      if (modelId)
        return modelId;
    }

    return "";
  }

  private showHiddenItems() {
    const colorIds = this.colorSubset.getAllColorIds();
    Object.keys(this.selectSubset.selectedGlobalIds).forEach((modelId) => {
      if (modelId && modelId !== COLORGROUP_ID) {
        const ids = this.selectSubset.selectedGlobalIds[modelId].filter(
          (i) => !colorIds.has(i)
        );
        this.hider.showItems(modelId, ids);
      }
    });
  }

  private getModelIdFromModel(model: IFCModel, globalId: string) {
    const expressId = PropertyManager.getExpressIdFromGlobalId(
      model.globalIds,
      globalId
    );

    if (expressId != Infinity) return model.modelFileId;

    for (let i = 0; i < model.instancedMeshes.length; i++) {
      const mesh = model.instancedMeshes[i];
      if (mesh.getIndexOf(globalId) > -1) {
        return model.modelFileId;
      }
    }

    return "";
  }

  private getColorInfo(visoColor: string) {
    let colorName = visoColor.replace("#", "").toLowerCase();
    let opacity: number | undefined = undefined;
    if (colorName.length > 6) {
      opacity = parseInt(colorName.substring(6, 2), 16) / 255.0;
      colorName = colorName.substring(0, 6);
    }
    const color = new Color(`#${colorName}`);

    return { color, opacity };
  }

  /**
   * @param id The id of model included a selected component
   * @param globalId The Ifc Guid of a selected component
   * @returns Ifc Guid of parent and sibling element with shape
   */
  private getFamilyGlobalIds(id: string, globalId: string) {
    const modelData = this.state.modelData[id];
    const node = modelData.nodeData[globalId];
    if (node) {
      if (node.elementType == "IfcBuildingElementPart") {
        const parent = modelData.nodeData[modelData.globalIds[node.decomposesNodeIds[0]]];
        if (parent) {
          const siblingIds:string[]=[];
          parent.isDecomposedByNodeIds.forEach((nId)=>{
            const subNode = modelData.nodeData[modelData.globalIds[nId]];
            if(subNode && subNode.shapeId){
              siblingIds.push(subNode.globalId);
            }
          });

          return parent.shapeId
            ? [...siblingIds, parent.globalId]
            : siblingIds;
        }
      }

      return [globalId];
    }

    throw new Error(`Could not find a node. IfcGuid: ${globalId}`);
  }

  private pickNode(node: VisoNode) {
    const { modelFileId, globalId } = node;
    if (!this.selectSubset.selectedGlobalIds[modelFileId]) {
      this.selectSubset.selectedGlobalIds[modelFileId] = [];
    }

    this.selectSubset.selectedGlobalIds[modelFileId].push(globalId);
    this.hider.hideItems(modelFileId, [globalId]);
  }
}
