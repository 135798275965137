import React from 'react';
import {
  Alert, AlertTitle, Box, Link,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export default function ErrorAlert() {
  const { t } = useTranslation('error');
  const { pathname } = useLocation();
  return (
    <Box>
      <Alert severity="error">
        <AlertTitle>{t('error-alert_title', 'Error')}</AlertTitle>
        <Trans
          t={t}
          i18nKey="error-alert_message"
          components={{
            r: <Link href={pathname} />,
            b: <Link href="/" />,
          }}
          defaults="An unexpected error occured in this part of the page. You can try to <r>reload the page</r> or go <b>back to the project overview</b>."
        />
      </Alert>
    </Box>
  );
}
