import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import PasswordResetDto from 'users/types/PasswordResetDto';

export default function usePasswordResetConfirmMutation() {
  const axiosInstance = useAxiosInstance();
  const mutationFn = useCallback(async (dto: PasswordResetDto) => {
    await axiosInstance.post(`${ApiEndpoint.ResetPassword}/confirm`, dto);
  }, [axiosInstance]);
  return useMutation<void, unknown, PasswordResetDto>({
    mutationFn,
  });
}
