import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Alert, AlertTitle, Box, Button, CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import RightUtilityDrawer from 'documents/components/RightUtilityDrawer';
import RoundIconButton from 'common/components/RoundIconButton';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import useProjectWorkPhasesQuery from 'labels/hooks/useProjectWorkPhasesQuery';
import MassEditSelectFormControl from 'documents/components/MassEditSelectFormControl';
import MassEditMultiSelectFormControl from 'documents/components/MassEditMultiSelectFormControl';
import useProjectDocumentStatusesQuery from 'documents/hooks/useProjectDocumentStatusesQuery';
import useProjectTagsQuery from 'tags/hooks/useProjectTagsQuery';
import MassEditFolderSelectFormControl from 'documents/components/MassEditFolderSelectFormControl';
import useDocumentSelectionContext from 'documents/hooks/useDocumentSelectionContext';
import useDocumentVersionMassEditMutation from 'documents/hooks/useDocumentVersionMassEditMutation';
import UpdateManyDocumentVersionsDto from 'documents/types/DocumentVersionsUpdateManyDto';
import axios from 'axios';
import useCurrentCollaboratorRole from 'collaborators/hooks/useCurrentCollaboratorRole';
import RoleAction from 'projects/types/RoleAction';
import useProjectBuildingsQuery from 'labels/hooks/useProjectBuildingsQuery';
import useProjectDisciplinesQuery from 'labels/hooks/useProjectDisciplinesQuery';
import useProjectFloorsQuery from 'labels/hooks/useProjectFloorsQuery';

enum TagEditMode {
  Add = 'add',
  Replace = 'replace',
}

interface MassEditDrawerProps {
  isOpen: boolean,
  onClose: () => void,
}

export default function MassEditDrawer({
  isOpen,
  onClose,
}: MassEditDrawerProps) {
  const { t } = useTranslation('documents');
  const { selectedDocumentVersionIds } = useDocumentSelectionContext();
  const { data: currentProject } = useCurrentProjectQuery();
  const { data: floors } = useProjectFloorsQuery();
  const { data: disciplines } = useProjectDisciplinesQuery();
  const { data: buildings } = useProjectBuildingsQuery();
  const { data: workPhases } = useProjectWorkPhasesQuery();
  const { data: documentStatuses } = useProjectDocumentStatusesQuery();
  const currentUserRole = useCurrentCollaboratorRole();
  const canSetStatusToPublished = useMemo(() => currentUserRole?.allowedActions?.has(RoleAction.DocStatus_ChangeToPublished), [currentUserRole?.allowedActions]);
  const visibleDocumentStatuses = useMemo(() => documentStatuses?.filter((s) => !(s.isDefault && (s.originalName === 'Archived' || s.originalName === 'Other' || (!canSetStatusToPublished && s.originalName === 'Published')))), [canSetStatusToPublished, documentStatuses]);
  const { data: tags } = useProjectTagsQuery();
  const [selectedFloorId, setSelectedFloorId] = useState<string | undefined>(undefined);
  const [selectedDisciplineIds, setSelectedDisciplineId] = useState<string[] | undefined>(undefined);
  const [selectedBuildingId, setSelectedBuildingId] = useState<string | undefined>(undefined);
  const [selectedWorkPhaseId, setSelectedWorkPhaseId] = useState<string | undefined>(undefined);
  const [selectedDocumentStatusId, setSelectedDocumentStatusId] = useState<string | undefined>(undefined);
  const [selectedTagIds, setSelectedTagIds] = useState<string[] | undefined>(undefined);
  const [selectedTagEditMode, setSelectedTagEditMode] = useState<TagEditMode>(TagEditMode.Add);
  const [selectedFolderId, setSelectedFolderId] = useState<string | undefined>(undefined);
  const { mutateAsync, isPending } = useDocumentVersionMassEditMutation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);

  const onChangeSelectedDisciplineIds = useCallback((ids: string[] | undefined) => setSelectedDisciplineId(ids), []);
  const onChangeSelectedBuildingId = useCallback((id: string | undefined) => setSelectedBuildingId(id), []);
  const onChangeSelectedFloorId = useCallback((id: string | undefined) => setSelectedFloorId(id), []);
  const onChangeSelectedWorkphaseId = useCallback((id: string | undefined) => setSelectedWorkPhaseId(id), []);
  const onChangeSelectedDocumentStatusId = useCallback((id: string | undefined) => setSelectedDocumentStatusId(id), []);
  const onChangeSelectedTagIds = useCallback((ids: string[] | undefined) => setSelectedTagIds(ids), []);
  const onChangeSelectedTagEditMode = useCallback((e: ChangeEvent<HTMLInputElement>) => setSelectedTagEditMode(e.target.value as TagEditMode), []);
  const onChangeSelectedFolderId = useCallback((id: string | undefined) => setSelectedFolderId(id), []);

  const resetForm = useCallback(() => {
    setSelectedFloorId(undefined);
    setSelectedDisciplineId(undefined);
    setSelectedBuildingId(undefined);
    setSelectedWorkPhaseId(undefined);
    setSelectedDocumentStatusId(undefined);
    setSelectedTagIds(undefined);
    setSelectedFolderId(undefined);
  }, []);

  const onConfirm = useCallback(async () => {
    setSuccessMessage(undefined);
    setErrorMessage(undefined);
    const dto: UpdateManyDocumentVersionsDto = {
      ids: selectedDocumentVersionIds,
    };
    if (selectedDisciplineIds !== undefined) dto.disciplines = { value: selectedDisciplineIds };
    if (selectedBuildingId !== undefined) dto.building = { value: selectedBuildingId };
    if (selectedWorkPhaseId !== undefined) dto.workPhaseId = { value: selectedWorkPhaseId };
    if (selectedTagIds !== undefined) {
      dto.tags = { value: selectedTagIds };
      dto.addTags = selectedTagEditMode === TagEditMode.Add;
    }
    if (selectedDocumentStatusId !== undefined) dto.documentStatusId = { value: selectedDocumentStatusId };
    if (selectedFloorId !== undefined) dto.floor = { value: selectedFloorId };
    if (selectedFolderId !== undefined) dto.folderId = { value: selectedFolderId };
    try {
      await mutateAsync(dto);
      setSuccessMessage(t('mass-edit-drawer_apply-success-message', 'Edits have been applied to {{count}} documents.', { count: selectedDocumentVersionIds.length }));
      resetForm();
    } catch (error) {
      setErrorMessage(
        (axios.isAxiosError(error)
          ? error.response?.data?.message
            ? error.response?.data?.message
            : error.message
          : t('mass-edit-drawer_apply-unknown-error-message', 'Editing failed due to an unknown problem.')),
      );
    }
  }, [selectedDocumentVersionIds, selectedDisciplineIds, selectedBuildingId, selectedWorkPhaseId, selectedTagIds, selectedDocumentStatusId, selectedFloorId, selectedFolderId, selectedTagEditMode, mutateAsync, t, resetForm]);

  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const onCloseSuccessMessage = useCallback(() => setSuccessMessage(undefined), []);

  return (
    <RightUtilityDrawer id="MassEditDrawer" isOpen={isOpen} openWidth={340} sx={{ py: 3, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ px: 2, pb: 1, display: 'flex' }}>
        <Box>
          <Typography variant="h3">{t('mass-edit-drawer_header', 'Edit Documents')}</Typography>
          <Typography>{t('mass-edit-drawer_subheader', '{{count}} documents selected', { count: selectedDocumentVersionIds.length })}</Typography>
        </Box>
        <RoundIconButton Icon={CloseIcon} onClick={onClose} sx={{ ml: 'auto' }} />
      </Box>
      <Box sx={{ flex: '1 1 0', overflowY: 'auto', px: 2 }} className="custom-scrollbar">
        <MassEditSelectFormControl
          sx={{ mt: 2 }}
          availableEntities={floors}
          label={t('mass-edit-drawer_floors-label', 'Floor')}
          selectedId={selectedFloorId}
          onChange={onChangeSelectedFloorId}
          disabled={!floors?.length || isPending}
        />
        <MassEditMultiSelectFormControl
          sx={{ mt: 2 }}
          availableEntities={disciplines}
          label={t('mass-edit-drawer_disciplines-label', 'Disciplines')}
          selectedIds={selectedDisciplineIds}
          onChange={onChangeSelectedDisciplineIds}
          disabled={!disciplines?.length || isPending}
        />
        <MassEditSelectFormControl
          sx={{ mt: 2 }}
          availableEntities={buildings}
          label={t('mass-edit-drawer_buildings-label', 'Building')}
          selectedId={selectedBuildingId}
          onChange={onChangeSelectedBuildingId}
          disabled={!buildings?.length || isPending}
        />
        <MassEditSelectFormControl
          sx={{ mt: 2 }}
          availableEntities={workPhases}
          label={t('mass-edit-drawer_workphase-label', 'Workphase')}
          selectedId={selectedWorkPhaseId}
          onChange={onChangeSelectedWorkphaseId}
          disabled={!workPhases?.length || isPending}
        />
        <MassEditSelectFormControl
          sx={{ mt: 2 }}
          availableEntities={visibleDocumentStatuses}
          label={t('mass-edit-drawer_status-label', 'Status')}
          selectedId={selectedDocumentStatusId}
          onChange={onChangeSelectedDocumentStatusId}
          disabled={!visibleDocumentStatuses?.length || isPending}
          renderValuesAsChips
        />
        <MassEditMultiSelectFormControl
          sx={{ mt: 4 }}
          availableEntities={tags}
          label={t('mass-edit-drawer_tags-label', 'Tags')}
          selectedIds={selectedTagIds}
          onChange={onChangeSelectedTagIds}
          disabled={!tags?.length || isPending}
        />
        <FormControl disabled={selectedTagIds === undefined || isPending} sx={{ mt: 1 }}>
          <RadioGroup
            aria-label={t('mass-edit-drawer_tags-edit-mode-label', 'Tag edit mode')}
            value={selectedTagEditMode}
            onChange={onChangeSelectedTagEditMode}
            row
          >
            <FormControlLabel value={TagEditMode.Add} control={<Radio />} label={t('mass-edit-drawer_tags-edit-mode-add', 'Add Tags')} disabled={!selectedTagIds?.length || undefined} />
            <FormControlLabel value={TagEditMode.Replace} control={<Radio />} label={t('mass-edit-drawer_tags-edit-mode-replace', 'Replace Tags')} />
          </RadioGroup>
        </FormControl>
        <MassEditFolderSelectFormControl
          sx={{ mt: 4 }}
          label={t('mass-edit-drawer_folder-label', 'Folder')}
          selectedId={selectedFolderId}
          onChange={onChangeSelectedFolderId}
          disabled={!currentProject || isPending}
        />
      </Box>
      <Box sx={{ px: 2, pt: 2 }}>
        {!!errorMessage && (
          <Alert severity="error" sx={{ my: 2 }} onClose={onCloseErrorMessage} title={t('mass-edit-drawer_apply-error-title', 'Error')}>{errorMessage}</Alert>
        )}
        {!!successMessage && (
          <Alert severity="success" sx={{ my: 2 }} onClose={onCloseSuccessMessage}>
            <AlertTitle>{t('mass-edit-drawer_apply-success-title', 'Success')}</AlertTitle>
            {successMessage}
          </Alert>
        )}
        <Button fullWidth variant="contained" color="primary" onClick={onConfirm} disabled={!selectedDocumentVersionIds?.length || isPending} sx={{ mt: 1 }}>
          {t('mass-edit-drawer_apply-changes', 'Apply changes')}
          {isPending && <CircularProgress size={12} sx={{ ml: 1 }} />}
        </Button>
      </Box>
    </RightUtilityDrawer>
  );
}
