import { Dispatch, SetStateAction, createContext } from 'react';

export interface CollaboratorsFilterContextState {
  quickFilterString: string,
  setQuickFilterString: Dispatch<SetStateAction<string>>,
  filteredCollaboratorIds: string[] | undefined,
  setFilteredCollaboratorIds: Dispatch<SetStateAction<string[] | undefined>>,
}

const defaultState: CollaboratorsFilterContextState = {
  quickFilterString: '',
  setQuickFilterString: () => {},
  filteredCollaboratorIds: undefined,
  setFilteredCollaboratorIds: () => {},
};

const CollaboratorsFilterContext = createContext<CollaboratorsFilterContextState>(defaultState);
export default CollaboratorsFilterContext;
