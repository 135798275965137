import { VisoModel } from "./BaseDefinitions";
import { IFCManager } from "./IFCManager";

class VisoplanLoader {
  ifcManager: IFCManager;

  constructor() {
    this.ifcManager = new IFCManager();
  }

  load(model: VisoModel) {
    return this.ifcManager.loadVisoModel(model);
  }
}

export { VisoplanLoader };
