import React, { useMemo } from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import useViewer3dContext from 'models/hooks/useViewer3dContext';
import Icon from '@mdi/react';
import { mdiPencil } from '@mdi/js';
import LinkedComponentListItem, { ComponentLinkAction } from 'issues/components/LinkedComponentListItem';
import useIssueEditingRoleRight from 'issues/hooks/useIssueEditingRoleRight';
import useClosedIssueStatus from 'issues/hooks/useClosedIssueStatus';

interface IssueLinkedComponentsPanelProps {
  issueId: string,
  onClickEdit?: () => void,
}

export default function IssueLinkedComponentsPanel({
  issueId,
  onClickEdit,
}: IssueLinkedComponentsPanelProps) {
  const { t } = useTranslation('issues');
  const theme = useTheme();
  const { data: issue } = useIssueQuery(issueId);
  const { getNode } = useViewer3dContext();

  const nodeItems = useMemo(() => {
    if (!issue?.linkedComponentsGlobalIds) return undefined;
    return issue.linkedComponentsGlobalIds.map((globalId) => {
      const node = getNode(globalId);
      return {
        globalId,
        node,
      };
    });
  }, [getNode, issue]);

  const canEdit = useIssueEditingRoleRight(issueId);

  const closedIssueStatus = useClosedIssueStatus();
  const editButtonDisabled = useMemo(() => issue?.issueStatus === closedIssueStatus?.id, [closedIssueStatus, issue?.issueStatus]);

  return (
    <Box id="IssueLinkedComponentsPanel">
      <Box sx={{
        backgroundColor: theme.palette.background.default,
        borderRadius: '8px',
        boxShadow: '0px 1px 4px -1px rgba(0,0,0,0.3)',
        display: 'flex',
        flexDirection: 'column',
      }}
      >
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-between', p: 2 }}>
          <Typography variant="h4">
            {t('issue-linked-components-panel_title', 'Components')}
            {!!issue?.linkedComponentsGlobalIds && ` (${issue.linkedComponentsGlobalIds.length})`}
          </Typography>
          {!!onClickEdit && !!canEdit && (
            <Button onClick={onClickEdit} variant="contained" color="secondary" disabled={editButtonDisabled} sx={{ gap: 0.5, pl: 0.5 }} size="small">
              <Icon path={mdiPencil} size={0.75} />
              {t('issue-linked-documents-panel_edit-button-label', 'Edit')}
            </Button>
          )}
        </Box>
        {!!nodeItems?.length && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {nodeItems.map(({ globalId, node }) => (
              <LinkedComponentListItem
                elementName={node?.elementName}
                globalId={globalId}
                nodeLoaded={node !== undefined}
                pendingAction={ComponentLinkAction.LeavePersisted}
                key={globalId}
              />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}
