import { Box } from '@mui/material';
import ModelsSelectionPanel from 'models/components/ModelsSelectionPanel';
import React from 'react';

export default function ModelSelectionDockviewPanel() {
  return (
    <Box id="ModelSelectionDockviewPanel" sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
      <ModelsSelectionPanel />
    </Box>
  );
}
