import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useFolderTreeQueryKey from 'documents-folders/hooks/useFolderTreeQueryKey';
import ArchiveOperation from 'documents/types/ArchiveOperation';

export default function useDocumentVersionArchiveOperationMutation(operation: ArchiveOperation) {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentVersion);
  const folderTreeQueryKey = useFolderTreeQueryKey();
  return useMutation<boolean | undefined, unknown, string[]>({
    mutationFn: async (documentVersionIds: string[]) => {
      if (documentVersionIds.length === 0) return true;
      const { data: patchResult } = await axiosInstance.patch<boolean>(`${ApiEndpoint.DocumentVersion}/${operation}/${documentVersionIds.join(',')}`);
      return patchResult;
    },
    onSuccess: () => {
      queryKeyBases.forEach((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
      queryClient.invalidateQueries({ queryKey: folderTreeQueryKey });
    },
  });
}
