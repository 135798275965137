import React, { ChangeEvent, useCallback } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import { useTranslation } from 'react-i18next';

interface MutateNamingSchemeProjectGroupPanelProps extends ISxProps {
  abbreviation: string,
  onChange: (value: string) => void,
}

export default function MutateNamingSchemeProjectGroupPanel({
  sx,
  abbreviation,
  onChange,
}: MutateNamingSchemeProjectGroupPanelProps) {
  const { t } = useTranslation('naming-schemes');
  const onChangeAbbreviation = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value.trim());
  }, [onChange]);
  return (
    <Box id="MutateNamingSchemeProjectGroupPanel" sx={{ display: 'flex', flexDirection: 'column', gap: 3, ...sx }}>
      <Typography variant="h5">{t('mutate-naming-scheme-project-group-panel_title', 'Project Group Options')}</Typography>
      <TextField
        label={t('mutate-naming-scheme-project-group-panel_abbreviation-textfield-label', 'Abbreviation')}
        id="mutate-naming-scheme-project-group-panel_abbreviation-textfield"
        value={abbreviation}
        onChange={onChangeAbbreviation}
      />
    </Box>
  );
}
