import useDmsSettingsQuery from 'documents/hooks/useDmsSettingsQuery';
import useLetterNumbering from 'documents/hooks/useLetterNumbering';
import { useCallback } from 'react';

export default function useDocumentVersionNumberString() {
  const { data: dmsSettings } = useDmsSettingsQuery();
  const { numberToLetter } = useLetterNumbering();
  return useCallback((versionNumber: number | undefined) => {
    if (!dmsSettings || versionNumber === undefined) return undefined;
    if (dmsSettings.useLetterVersioning) {
      return numberToLetter(versionNumber);
    }
    return versionNumber;
  }, [dmsSettings, numberToLetter]);
}
