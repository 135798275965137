import React, { useState, useMemo, useCallback } from 'react';
import {
  Alert,
  Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useCollaboratorGroupsQuery from 'collaborators-groups/hooks/useCollaboratorGroupsQuery';
import useCollaboratorGroupCreateMutation from 'collaborators-groups/hooks/useCollaboratorGroupCreateMutation';

interface CreateCollaboratorGroupDialogProps {
  onClose: () => void,
  onSuccess: (message: string) => void,
}

export default function CreateCollaboratorGroupDialog({
  onClose,
  onSuccess,
}: CreateCollaboratorGroupDialogProps) {
  const { t } = useTranslation('settings');
  const [name, setName] = useState<string>('');
  const { data: userGroups } = useCollaboratorGroupsQuery();
  const existingGroupNames = useMemo(() => (userGroups ? new Set<string>(userGroups.map((g) => g.name.trim())) : undefined), [userGroups]);
  const hasNamingConflict = useMemo(() => !!name.length && existingGroupNames?.has(name.trim()), [existingGroupNames, name]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const getRequestErrorMessage = useRequestErrorMessage();
  const { mutateAsync, isPending } = useCollaboratorGroupCreateMutation();

  const onClickConfirm = useCallback(async () => {
    setErrorMessage(undefined);
    try {
      await mutateAsync(name);
      onSuccess(t('create-user-group-dialog_success-message', 'User group "{{roleName}}" created.', { roleName: name }));
      setName('');
      onClose();
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [getRequestErrorMessage, mutateAsync, name, onClose, onSuccess, t]);

  const onClickCancel = useCallback(() => {
    setErrorMessage(undefined);
    setName('');
    onClose();
  }, [onClose]);

  return (
    <Dialog open>
      <DialogTitle>{t('create-user-group-dialog_title', 'Create New User Group')}</DialogTitle>
      <DialogContent sx={{ minHeight: '84px', width: '420px' }}>
        <Box sx={{ pt: 1 }}>
          <TextField
            id="CreateCollaboratorGroupNameTextField"
            fullWidth
            label={t('create-user-group-dialog_create-textfield-label', 'New User Group Name')}
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={hasNamingConflict}
            helperText={hasNamingConflict ? t('create-user-group-dialog_name-conflict-messge', 'A user group with this name already exists') : undefined}
          />
          {!!errorMessage && <Alert sx={{ mt: 1 }} severity="error">{errorMessage}</Alert>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button id="CreateCollaboratorGroupCancelButton" variant="contained" color="secondary" onClick={onClickCancel}>{t('create-user-group-dialog_cancel-button-label', 'Cancel')}</Button>
        <Button
          id="CreateCollaboratorGroupConfirmButton"
          variant="contained"
          color="primary"
          onClick={onClickConfirm}
          sx={{ ml: 2 }}
          disabled={!name.trim().length || hasNamingConflict || isPending}
        >
          {isPending && <CircularProgress size={12} sx={{ mr: 1 }} />}
          {t('create-user-group-dialog_confirm-button-label', 'Create User Group')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
