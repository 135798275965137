import React, { useMemo } from 'react';
import { Box, ButtonBase, useTheme } from '@mui/material';
import Icon from '@mdi/react';
import ISxProps from 'common/types/ISxProps';
import IssueDto from 'issues/types/IssueDto';
import { mdiLinkOff } from '@mdi/js';

interface LinkedIssuePanelItemProps extends ISxProps {
  issue: IssueDto,
  iconPath: string,
  onClick?: ((issueId: string) => void) | undefined,
  onClickRemove?: ((issueId: string) => void) | undefined,
  disabled?: boolean | undefined,
}

export default function LinkedIssuePanelItem({
  sx,
  issue,
  iconPath,
  onClick,
  onClickRemove,
  disabled,
}: LinkedIssuePanelItemProps) {
  const theme = useTheme();
  const onClickButton = useMemo(() => (onClick ? () => onClick(issue.id) : undefined), [issue, onClick]);
  const onClickRemoveButton = useMemo(() => (onClickRemove ? () => onClickRemove(issue.id) : undefined), [issue, onClickRemove]);
  return (
    <Box
      id="LinkedIssuePanelItem"
      sx={{
        ...sx,
        display: 'flex',
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: theme.palette.divider,
      }}
    >
      <ButtonBase
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'stretch',
          textAlign: 'left',
          p: 1,
          pl: 2,
          gap: 1,
          '&:hover': {
            backgroundColor: theme.palette.grey[200],
          },
          overflow: 'hidden',
        }}
        onClick={onClickButton}
        disabled={!onClickButton || disabled}
        title={issue.title}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Icon path={iconPath} size={0.75} color={theme.palette.grey[700]} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 1,
            flexGrow: 1,
            pointerEvents: 'none',
            fontSize: 14,
            overflow: 'hidden',
          }}
        >
          <Box sx={{ whiteSpace: 'nowrap', flexGrow: 0, flexShrink: 0, textOverflow: 'ellipsis', overflow: 'hidden' }}>{issue.title}</Box>
        </Box>
      </ButtonBase>
      {!!onClickRemove && (
        <ButtonBase
          sx={{
            ...sx,
            display: 'flex',
            alignItems: 'stretch',
            textAlign: 'left',
            p: 0.5,
            gap: 1,
            '&:hover': {
              backgroundColor: theme.palette.grey[200],
            },
          }}
          onClick={onClickRemoveButton}
          disabled={!onClickRemoveButton || disabled}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Icon path={mdiLinkOff} size={0.75} color={theme.palette.grey[700]} />
          </Box>
        </ButtonBase>
      )}
    </Box>
  );
}
