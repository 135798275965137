import React, { useCallback, useMemo, useState, KeyboardEvent } from 'react';
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSetupTwoFactorAuthQuery from 'users/hooks/useSetupTwoFactorAuthQuery';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useVisoplanApiContext from 'api/hooks/useVisoplanApiContext';
import useCurrentUserQueryKey from 'users/hooks/useCurrentUserQueryKey';
import { useQueryClient } from '@tanstack/react-query';
import OtpInput from 'users/components/OtpInput';

interface TwoFactorAuthSetupDialogProps {
  onClose: (confirmed: boolean) => void,
  hideBackdrop?: boolean,
}

const digitsRegex = /&digits=([0-9]+)/;

export default function TwoFactorAuthSetupDialog({
  onClose,
  hideBackdrop,
}: TwoFactorAuthSetupDialogProps) {
  const { t } = useTranslation('users');
  const { data: setupTwoFactorAuthSetupDto, isLoading: isLoadingSetupDto } = useSetupTwoFactorAuthQuery();
  const currentUserQueryKey = useCurrentUserQueryKey();
  const queryClient = useQueryClient();
  const { activateTwoFactorAuth } = useVisoplanApiContext();
  const qrCodeSrc = useMemo(() => (setupTwoFactorAuthSetupDto ? `data:image/png;base64,${setupTwoFactorAuthSetupDto.qrCode}` : undefined), [setupTwoFactorAuthSetupDto]);
  const otpLength = useMemo(() => {
    if (!setupTwoFactorAuthSetupDto) return undefined;
    const digitsMatch = setupTwoFactorAuthSetupDto.setupCode.match(digitsRegex);
    const digitsStr = digitsMatch?.[1];
    if (!digitsStr) return undefined;
    return Number.parseInt(digitsStr, 10);
  }, [setupTwoFactorAuthSetupDto]);
  const [oneTimePassword, setOneTimePassword] = useState('');
  const confirmDisabled = useMemo(() => isLoadingSetupDto || otpLength === undefined || oneTimePassword.length !== otpLength, [isLoadingSetupDto, oneTimePassword.length, otpLength]);
  const onChangeOneTimePassword = useCallback((value: string) => setOneTimePassword(value), []);

  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [isLoadingActivation, setIsLoadingActivation] = useState(false);
  const onConfirm = useCallback(async () => {
    try {
      setIsLoadingActivation(true);
      await activateTwoFactorAuth(oneTimePassword);
      await queryClient.invalidateQueries({ queryKey: currentUserQueryKey });
      setSuccessDialogOpen(true);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    } finally {
      setIsLoadingActivation(false);
    }
  }, [activateTwoFactorAuth, oneTimePassword, queryClient, currentUserQueryKey, getRequestErrorMessage]);
  const onOtpTextFieldKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key.toLowerCase() === 'enter') {
      onConfirm();
    }
  }, [onConfirm]);
  const onCancel = useCallback(() => onClose(false), [onClose]);
  const onCloseSuccessDialog = useCallback(() => onClose(true), [onClose]);
  return (
    <Dialog open id="TwoFactorAuthSetupDialog" hideBackdrop={hideBackdrop}>
      <DialogTitle>{t('two-factor-auth-setup-dialog_title', 'Two-Factor Authentication Setup')}</DialogTitle>
      <DialogContent>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 5,
          gap: 2,
        }}
        >
          {!setupTwoFactorAuthSetupDto && <CenteredCircularProgress />}
          {!!setupTwoFactorAuthSetupDto && (
            <>
              <Alert severity="info">{t('two-factor-auth-setup-dialog_help-text', 'Scan this QR-Code with any TOTP compatible mobile app and then enter a one-time password below.\nCompatible authenticator apps include Aegis Authenticator, Google Authenticator, Microsoft Authenticator and others.')}</Alert>
              <img src={qrCodeSrc} alt="2FR QR-Code" />
              <OtpInput value={oneTimePassword} onChange={onChangeOneTimePassword} onKeyDown={onOtpTextFieldKeyDown} />
            </>
          )}
        </Box>
        {!!errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        {!!successDialogOpen && (
        <Dialog open>
          <DialogTitle>{t('two-factor-auth-setup-dialog_success-dialog-title', 'Two-Factor Authentication Activated')}</DialogTitle>
          <DialogContent>
            <Alert severity="success">{t('two-factor-auth-setup-dialog_success-dialog-text', 'Two-factor authentication is now enabled for your account. From now on you will need to enter a one-time password when you log in.')}</Alert>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={onCloseSuccessDialog}>{t('two-factor-auth-setup-dialog_success-dialog-close-button-label', 'Close')}</Button>
          </DialogActions>
        </Dialog>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onCancel} sx={{ mr: 'auto' }}>
          {t('two-factor-auth-setup-dialog_cancel-button-label', 'Cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={onConfirm} disabled={confirmDisabled}>
          {!!isLoadingActivation && <CircularProgress size={12} sx={{ mr: 1 }} />}
          {t('two-factor-auth-setup-dialog_confirm-button-label', 'Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
