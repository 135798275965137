import { TOGGLE_SIDEBAR } from "./ActionTypes";

const sidebar = (state = [], action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return { ...state, show_sidebar: action.payload };

    default:
      return state;
  }
};

export default sidebar;
