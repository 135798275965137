import { IfcComponent } from "../../base-types";

export class IfcStats extends IfcComponent {
  stats?: any;
  private style: string = "";
  private visible = false;

  initializeStats(Stats: any) {
    this.stats = Stats;
    this.stats.showPanel(0);
  }

  update(_delta: number) {
    if (this.stats) {
      this.stats.update();
    }
  }

  addStats(css = "") {
    if (css.length > 0) {
      this.style = css;
    }

    this.hideStats();
    document.body.appendChild(this.stats.dom);
  }

  removeStats() {
    if (this.stats) {
      document.body.removeChild(this.stats.dom);
    }
  }

  toggleStats() {
    this.visible = !this.visible;
    if (this.visible) {
      this.showStats();
    } else {
      this.hideStats();
    }
  }

  private showStats() {
    if (this.style.length > 0) this.stats.dom.style.cssText = this.style;
  }

  private hideStats() {
    this.stats.dom.style.cssText = "display:none;";
  }
}
