enum SettingsTab {
  Project = 'project',
  ProjectMetadata = 'project-metadata',
  Roles = 'roles',
  CollaboratorGroups = 'user-groups',
  Encoding = 'encoding',
  Documents = 'documents',
  Issues = 'issues',
}
export default SettingsTab;
