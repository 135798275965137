import LinkedComponentItem from 'models/types/LinkedComponentItem';
import ViewpointItem from 'models/types/ViewpointItem';
import { createContext, Dispatch, SetStateAction } from 'react';

export enum ModelsInteractionMode {
  ViewpointManagement = 'viewpoint-management',
  LinkedComponentManagement = 'linked-component-management',
}

export interface ModelsInteractionContextState {
  interactionMode: ModelsInteractionMode | undefined,
  setInteractionMode: Dispatch<SetStateAction<ModelsInteractionMode | undefined>>,
  viewpointItems: ViewpointItem[],
  setViewpointItems: Dispatch<SetStateAction<ViewpointItem[]>>,
  linkedComponentItems: LinkedComponentItem[],
  setLinkedComponentItems: Dispatch<SetStateAction<LinkedComponentItem[]>>,
}

const defaultState: ModelsInteractionContextState = {
  interactionMode: undefined,
  setInteractionMode: () => { throw new Error('Using context default value'); },
  viewpointItems: [],
  setViewpointItems: () => { throw new Error('Using context default value'); },
  linkedComponentItems: [],
  setLinkedComponentItems: () => { throw new Error('Using context default value'); },
};

const ModelsInteractionContext = createContext<ModelsInteractionContextState>(defaultState);
export default ModelsInteractionContext;
