import React, { useCallback, useMemo, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, Select, InputLabel, SelectChangeEvent, MenuItem, Box, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LabelType from 'labels/types/LabelType';
import useLabelTypeTranslation from 'labels/hooks/useLabelTypeTranslation';
import useLabelsOdataQuery from 'labels/hooks/useLabelsOdataQuery';
import LabelChip from 'labels/components/LabelChip';
import useLabelRestoreMutation from 'labels/hooks/useLabelRestoreMutation';
import RestoreLabelDto from 'labels/types/RestoreLabelDto';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import RestoreLabelSuccessDialog from 'settings/components/RestoreLabelSuccessDialog';

interface RestoreLabelDialogProps {
  labelType: LabelType,
  onClose: () => void,
}

export default function RestoreLabelDialog({
  labelType,
  onClose,
}: RestoreLabelDialogProps) {
  const { t } = useTranslation('settings');
  const [selectedLabelId, setSelectedLabelId] = useState<string | undefined>(undefined);
  const onChangeSelect = useCallback((event: SelectChangeEvent<string>) => {
    setSelectedLabelId(event.target.value);
  }, []);
  const { data: deletedLabels } = useLabelsOdataQuery({ filter: { type: LabelType[labelType], isDeleted: true } });
  const labelsById = useMemo(() => (deletedLabels ? new Map(deletedLabels.map((l) => [l.id, l])) : undefined), [deletedLabels]);
  const { mutateAsync } = useLabelRestoreMutation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const getRequestErrorMessage = useRequestErrorMessage();
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const [successDialogOpen, setSuccessDialogOpen] = useState<boolean>(false);
  const getLabelTypeName = useLabelTypeTranslation();
  const labelTypeName = useMemo(() => getLabelTypeName(labelType), [getLabelTypeName, labelType]);
  const onClickCancel = useCallback(() => onClose(), [onClose]);
  const onClickConfirm = useCallback(async () => {
    if (!selectedLabelId || !labelsById?.has(selectedLabelId)) throw new Error('dependency error');
    const { name } = labelsById.get(selectedLabelId)!;
    try {
      const restoreDto: RestoreLabelDto = {
        id: selectedLabelId,
        name,
      };
      await mutateAsync(restoreDto);
      setSuccessDialogOpen(true);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [getRequestErrorMessage, labelsById, mutateAsync, selectedLabelId]);
  const onCloseSuccessDialog = useCallback(() => {
    setSuccessDialogOpen(false);
    onClose();
  }, [onClose]);
  return (
    <>
      <Dialog open id="RestoreLabelDialog">
        <DialogTitle>{t('restore-label-dialog_title', 'Restore {{labelTypeName}}', { labelTypeName })}</DialogTitle>
        <DialogContent>
          <Box sx={{ py: 2, minWidth: 400, display: 'flex', flexDirection: 'column', gap: 2 }}>
            {!!deletedLabels && (
            <FormControl fullWidth>
              <InputLabel>{labelTypeName}</InputLabel>
              <Select<string>
                value={selectedLabelId}
                label={labelTypeName}
                onChange={onChangeSelect}
              >
                {deletedLabels.map((label) => <MenuItem key={label.id} value={label.id}><LabelChip label={label} /></MenuItem>)}
              </Select>
            </FormControl>
            )}
            {!!errorMessage && (
              <Alert severity="error" onClose={onCloseErrorMessage}>{errorMessage}</Alert>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ gap: 2, justifyContent: 'space-between' }}>
          <Button variant="contained" color="secondary" onClick={onClickCancel}>
            {t('restore-label-dialog_cancel-button-label', 'Cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={onClickConfirm} disabled={!selectedLabelId}>
            {t('restore-label-dialog_confirm-button-label', 'Confirm')}
          </Button>
        </DialogActions>
      </Dialog>
      {!!successDialogOpen && <RestoreLabelSuccessDialog labelTypeName={labelTypeName} onClose={onCloseSuccessDialog} />}
    </>
  );
}
