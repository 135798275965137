import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import IChildren from 'common/types/IChildren';
import Viewer3dContext, { Viewer3dContextState } from 'models/contexts/Viewer3dContext';
import { IfcViewerAPI } from 'Components/Models/Types/ifc-viewer-api';
import { Color, Vector3 as ThreeVector3 } from 'three';
import { IfcPlane } from 'Components/Models/Types/components/display/clipping-planes/planes';
import Vector3 from 'common/types/Vector3';
import { CameraProjections, NavigationModes, ViewerSetting } from 'Components/Models/Types/base-types';
import FloorMapSetting from 'models/types/FloorMapSetting';
import Viewer3dInteractionMode from 'models/types/Viewer3dInteractionMode';
import { IfcDimensionLine } from 'Components/Models/Types/components/display/dimensions/dimension-line';
import useVisoplanApiContext from 'api/hooks/useVisoplanApiContext';
import useApiConfig from 'api/hooks/useApiConfig';
import { debounce } from 'lodash';
import ViewpointBubbleItem from 'models/types/ViewpointBubbleItem';
import { BubbleInfo, BubbleState } from 'Components/Models/Types/viso-types';
import useModelsInteractionContext from 'models/hooks/useModelsInteractionContext';
import { ModelsInteractionMode } from 'models/contexts/ModelsInteractionContext';
import useModelSelectionContext from 'models/hooks/useModelSelectionContext';
import { VisoModelFile } from 'Components/Models/Types/VisoModelFile';

const VIEWER_3D_SETTINGS_LOCAL_STORAGE_KEY = 'viewer-3d-settings';
export const DEFAULT_VIEWER_3D_SETTINGS: ViewerSetting = {
  control: NavigationModes.Mouse,
  camera: CameraProjections.Perspective,
  spaces: false,
  openings: false,
  grid: false,
  background: '#F2F3F5',
  navCube: true,
  sectionOutline: '#FFFF00',
  sectionSpace: '#FF0000',
};
const FLOOR_PLAN_SETTINGS_LOCAL_STORAGE_KEY = 'floor-plan-settings';
export const DEFAULT_FLOOR_PLAN_SETTINGS: FloorMapSetting = {
  visible: false,
  storey: undefined,
  floorMaps: [],
};

interface Viewer3dContextProviderProps extends IChildren {
}

export default function Viewer3dContextProvider({
  children,
}: Viewer3dContextProviderProps) {
  const viewer3dApiRef = useRef<IfcViewerAPI>();
  const { apiUrl } = useApiConfig();
  const { authProjectToken } = useVisoplanApiContext();
  const { interactionMode: modelsInteractionMode } = useModelsInteractionContext();
  const { setSelectedIssueId } = useModelSelectionContext();
  const { modelFileIds, setIsLoading } = useModelSelectionContext();

  const [selectedGlobalIds, setSelectedGlobalIds] = useState<string[]>([]);
  const [selectionContainsTransparentNodes, setSelectionContainsTransparentNodes] = useState(false);
  const [clippingPlanes, setClippingPlanes] = useState<IfcPlane[]>([]);
  const [selectedClippingPlane, setSelectedClippingPlane] = useState<IfcPlane | undefined>(undefined);
  const [interactionMode, setInteractionMode] = useState<Viewer3dInteractionMode>(Viewer3dInteractionMode.View);
  const [measurements, setMeasurements] = useState<IfcDimensionLine[]>([]);
  const [modelFiles, setModelFiles] = useState<VisoModelFile[] | undefined>(undefined);

  const [viewer3dSettings, setViewer3dSettings] = useState<ViewerSetting>(() => {
    const storedSettingsJson = localStorage.getItem(VIEWER_3D_SETTINGS_LOCAL_STORAGE_KEY);
    const settings = storedSettingsJson ? JSON.parse(storedSettingsJson) as ViewerSetting : undefined;
    return settings ?? DEFAULT_VIEWER_3D_SETTINGS;
  });

  const [floorPlanSettings, setFloorPlanSettings] = useState<FloorMapSetting>(() => {
    const storedSettingsJson = localStorage.getItem(FLOOR_PLAN_SETTINGS_LOCAL_STORAGE_KEY);
    const settings = storedSettingsJson ? JSON.parse(storedSettingsJson) as FloorMapSetting : undefined;
    return settings ?? DEFAULT_FLOOR_PLAN_SETTINGS;
  });

  useEffect(() => {
    if (viewer3dApiRef.current) {
      viewer3dApiRef.current.enableNavCube(Boolean(viewer3dSettings.navCube));
    }
  }, [viewer3dSettings.navCube]);

  const onChangeInteractionMode = useCallback((nextMode: Viewer3dInteractionMode) => {
    if (!viewer3dApiRef.current) return;
    setInteractionMode((prev) => {
      if (!viewer3dApiRef.current) return prev;
      if (prev === nextMode) return nextMode;

      if (nextMode === Viewer3dInteractionMode.CreateMeasurements) {
        viewer3dApiRef.current.dimensions.active = true;
        viewer3dApiRef.current.dimensions.previewActive = true;
      }

      if (prev === Viewer3dInteractionMode.CreateMeasurements) {
        viewer3dApiRef.current.dimensions.drawEnd();
        viewer3dApiRef.current.dimensions.previewActive = false;
        setMeasurements([...viewer3dApiRef.current.dimensions.dimensions]);
      }
      return nextMode;
    });
  }, []);

  const createMeasurementPoint = useCallback(() => {
    if (!viewer3dApiRef.current) return;
    viewer3dApiRef.current.dimensions.create();
  }, []);

  const deleteMeasurement = useCallback((measurement: IfcDimensionLine) => {
    if (!viewer3dApiRef.current) return;
    viewer3dApiRef.current.dimensions.deleteDimension(measurement);
    setMeasurements([...viewer3dApiRef.current.dimensions.dimensions]);
  }, []);

  const onChangeViewer3dSettings = useCallback((nextValue: ViewerSetting) => {
    if (viewer3dApiRef.current) {
      viewer3dApiRef.current.setViewerSetting(nextValue);
    }
    const json = JSON.stringify(nextValue);
    localStorage.setItem(VIEWER_3D_SETTINGS_LOCAL_STORAGE_KEY, json);
    setViewer3dSettings(nextValue);
  }, []);

  const onChangeFloorPlanSettings = useCallback((nextValue: FloorMapSetting) => {
    const json = JSON.stringify(nextValue);
    localStorage.setItem(FLOOR_PLAN_SETTINGS_LOCAL_STORAGE_KEY, json);
    if (nextValue.visible) {
      const { storey } = nextValue;
      if (storey) {
        viewer3dApiRef.current?.storey.setStorey(storey, authProjectToken);
      }
    } else {
      viewer3dApiRef.current?.storey.reset();
    }
    setFloorPlanSettings(nextValue);
  }, [authProjectToken]);

  const selectComponentUnderCursor = useCallback(async (multiselect: boolean) => {
    if (!viewer3dApiRef.current) return;
    await viewer3dApiRef.current.IFC.selector.pickIfcItem(multiselect);
  }, []);

  const highlightComponentUnderCursor = useCallback(async () => {
    if (!viewer3dApiRef.current) return;
    await viewer3dApiRef.current.IFC.selector.prePickIfcItem();
  }, []);

  const clearComponentHighlight = useCallback(() => {
    if (!viewer3dApiRef.current) return;
    viewer3dApiRef.current.IFC.selector.hideHoverSelection();
  }, []);

  const zoomToSelectedComponents = useCallback(async () => {
    if (!viewer3dApiRef.current) return;
    viewer3dApiRef.current.IFC.zoomSelectedComponent();
  }, []);

  const onSelectClippingPlane = useCallback((plane: IfcPlane | undefined) => {
    clippingPlanes.forEach((p) => {
      // justification: this is by design of the IFC viewer library
      // eslint-disable-next-line no-param-reassign
      p.visible = p === plane;
    });
    setSelectedClippingPlane(plane);
  }, [clippingPlanes]);

  const updateClippingPlanes = useCallback(() => {
    if (!viewer3dApiRef.current) return;
    setClippingPlanes(viewer3dApiRef.current.getClippingPlanes());
  }, []);

  const addClippingPlane = useCallback((normal: Vector3) => {
    if (!viewer3dApiRef.current) return;
    const plane = viewer3dApiRef.current.addClippingPlane(new ThreeVector3(normal.x, normal.y, normal.z));
    updateClippingPlanes();
    onSelectClippingPlane(plane);
  }, [onSelectClippingPlane, updateClippingPlanes]);

  const addClippingPlaneAtCursor = useCallback(() => {
    if (!viewer3dApiRef.current) return;
    viewer3dApiRef.current.clipper.active = true;
    const plane = viewer3dApiRef.current.addClippingPlaneAtCursor(true) ?? undefined;
    updateClippingPlanes();
    onSelectClippingPlane(plane);
  }, [onSelectClippingPlane, updateClippingPlanes]);

  const removeClippingPlane = useCallback((plane: IfcPlane) => {
    if (!viewer3dApiRef.current) return;
    viewer3dApiRef.current.deleteClippingPlane(plane);
    if (selectedClippingPlane === plane) {
      onSelectClippingPlane(undefined);
    }
    setClippingPlanes(viewer3dApiRef.current.getClippingPlanes().filter((p) => p !== plane));
  }, [onSelectClippingPlane, selectedClippingPlane]);

  const resetViewer = useCallback(() => {
    if (!viewer3dApiRef.current) return;
    viewer3dApiRef.current.deleteAllClippingPlanes();
    setClippingPlanes([]);
    setSelectedClippingPlane(undefined);
    viewer3dApiRef.current.IFC.resetModel();
  }, []);

  const hideSelectedComponents = useCallback(() => {
    if (!viewer3dApiRef.current) return;
    viewer3dApiRef.current.IFC.hideSelectedComponent();
  }, []);

  const isolateSelectedComponents = useCallback(() => {
    if (!viewer3dApiRef.current) return;
    viewer3dApiRef.current.IFC.isolateComponent();
  }, []);

  const toggleSelectedComponentsTransparent = useCallback(() => {
    if (!viewer3dApiRef.current) return;
    viewer3dApiRef.current.IFC.transparentComponent(!selectionContainsTransparentNodes);
  }, [selectionContainsTransparentNodes]);

  const resetSelectedComponentsPresentation = useCallback(() => {
    if (!viewer3dApiRef.current) return;
    viewer3dApiRef.current.IFC.resetComponent();
  }, []);

  const setSelectedComponentsColor = useCallback((hex: string) => {
    if (!viewer3dApiRef.current) return;
    viewer3dApiRef.current.IFC.setColorComponent(hex);
  }, []);

  const resetSelectedComponentsColor = useCallback(() => {
    if (!viewer3dApiRef.current) return;
    viewer3dApiRef.current.IFC.resetColorComponent();
  }, []);

  const hideComponentsOfDifferentTypeThanSelected = useCallback(() => {
    if (!viewer3dApiRef.current) return;
    const selectedNodes = viewer3dApiRef.current.IFC.getComponentInfos(selectedGlobalIds);
    viewer3dApiRef.current.IFC.hideOtherType(selectedNodes);
  }, [selectedGlobalIds]);

  const isolateSelectedComponentsFloors = useCallback(() => {
    if (!viewer3dApiRef.current) return;
    const selectedNodes = viewer3dApiRef.current.IFC.getComponentInfos(selectedGlobalIds);
    viewer3dApiRef.current.IFC.isolateStoreys(selectedNodes);
  }, [selectedGlobalIds]);

  const createViewpoint = useCallback(async () => {
    if (!viewer3dApiRef.current) return undefined;
    return await viewer3dApiRef.current.createViewpoint();
  }, []);

  const getNode = useCallback((globalId: string) => {
    if (!viewer3dApiRef.current || !modelFiles) return undefined;
    const nodes = viewer3dApiRef.current.IFC.getComponentInfos([globalId]);
    if (nodes.length !== 0) return nodes[0];
    return undefined;
  }, [modelFiles]);

  const [viewpointBubbles, setViewpointBubbles] = useState<ViewpointBubbleItem[]>([]);
  const [isViewpointMarkerPlaced, setIsViewpointMarkerPlaced] = useState<boolean>(false);
  const [isPlacingViewpointMarker, setIsPlacingViewpointMarker] = useState<boolean>(false);
  useEffect(() => {
    if (!viewer3dApiRef.current) return;
    viewer3dApiRef.current.IFC.bubble.pickBubble();
    if (modelsInteractionMode === undefined) {
      const bubbleInfos: BubbleInfo[] = viewpointBubbles.map((item) => ({
        title: item.title,
        position: new ThreeVector3(item.position.x, item.position.z, -item.position.y),
        issueId: item.issueId,
        color: new Color(1, 0, 0),
        linkedComponentsGlobalIds: item.linkedComponentsGlobalIds,
      }));
      viewer3dApiRef.current.IFC.bubble.setBubbleState(BubbleState.BUBBLE_NONE);
      viewer3dApiRef.current.IFC.bubble.showBubble(bubbleInfos);
    } else {
      viewer3dApiRef.current.IFC.bubble.showBubble([]);
    }
  }, [modelsInteractionMode, viewpointBubbles]);
  useEffect(() => {
    if (!viewer3dApiRef.current) return;
    if (modelsInteractionMode === ModelsInteractionMode.ViewpointManagement) {
      if (isPlacingViewpointMarker) {
        viewer3dApiRef.current.IFC.bubble.setBubbleState(BubbleState.BUBBLE_CREATING);
      } else if (isViewpointMarkerPlaced) {
        viewer3dApiRef.current.IFC.bubble.setBubbleState(BubbleState.BUBBLE_CREATED);
      } else {
        viewer3dApiRef.current.IFC.bubble.setBubbleState(BubbleState.BUBBLE_NONE);
      }
    }
  }, [isPlacingViewpointMarker, isViewpointMarkerPlaced, modelsInteractionMode, viewpointBubbles]);

  const deleteViewpointMarker = useCallback(() => {
    setIsViewpointMarkerPlaced(false);
  }, []);

  const updateLoadedModelFiles = useCallback(async (nextModelFileIds: string[]) => {
    // ugly workaround for the viewer loading process to be pretty much a black box
    // (wait up to 5 seconds for the viewer instance to be initialized)
    let fuse = 0;
    while (!viewer3dApiRef.current && fuse < 50) {
      // eslint-disable-next-line no-await-in-loop
      await new Promise<void>((resolve) => {
        setTimeout(() => resolve(), 100);
      });
      fuse += 1;
    }

    if (!viewer3dApiRef.current || !authProjectToken) return;
    try {
      setIsLoading(true);
      const viewerApi = viewer3dApiRef.current;
      const prevModelFileIds = viewerApi.getLoadedModelIds();
      const modelFileIdsSet = new Set(nextModelFileIds);
      const prevModelFileIdsSet = new Set(prevModelFileIds);
      const modelFileIdsToRemove = prevModelFileIds.filter((id) => !modelFileIdsSet.has(id));
      viewerApi.IFC.removeModels(modelFileIdsToRemove);
      const modelFileIdsToAdd = nextModelFileIds.filter((id) => !prevModelFileIdsSet.has(id));
      const addedModelFiles: VisoModelFile[] = [];
      await Promise.all(modelFileIdsToAdd.map(async (id) => {
        const result = await viewerApi.IFC.getModelFile(id, authProjectToken) as VisoModelFile[] | null;
        if (result) {
          const [modelFile] = result;
          await viewerApi.IFC.loadVisoModelFile(modelFile, authProjectToken);
          addedModelFiles.push(modelFile);
        }
      }));
      setModelFiles((prev) => [...(prev?.filter((modelFile) => modelFile.id && modelFileIdsSet.has(modelFile.id)) ?? []), ...addedModelFiles]);
      // Not a fix but a dirty hack of a workaround: Wait 1000ms after loading the model
      // to wait for the camera to settle the initial focus animation (which cannot be awaited).
      await new Promise<void>((resolve) => { setTimeout(() => { resolve(); }, 1000); });
    } finally {
      setIsLoading(false);
    }
  }, [authProjectToken, setIsLoading]);

  const refreshLoadedModelFiles = useCallback(() => {
    updateLoadedModelFiles(modelFileIds);
  }, [modelFileIds, updateLoadedModelFiles]);

  const initializeViewerInstance = useCallback((canvasContainerRef: RefObject<HTMLElement>) => {
    if (viewer3dApiRef.current || !canvasContainerRef.current) return;
    viewer3dApiRef.current = new IfcViewerAPI({ container: canvasContainerRef.current }, apiUrl);

    viewer3dApiRef.current.setViewerSetting(viewer3dSettings);

    // auto-resize the canvas whenever the container div changes size
    const updateAspectDebounced = debounce(() => viewer3dApiRef.current?.context.updateAspect(), 250, { maxWait: 1000 });
    const resizeObserver = new ResizeObserver(updateAspectDebounced);
    resizeObserver.observe(canvasContainerRef.current);

    // destroy viewer reference when the container div leaves the dom
    const mutationObserver = new MutationObserver(() => {
      if (!document.body.contains(canvasContainerRef.current)) {
        resizeObserver.disconnect();
        if (viewer3dApiRef.current) {
          const loadedModelIds = viewer3dApiRef.current.getLoadedModelIds();
          viewer3dApiRef.current.IFC.removeModels(loadedModelIds);
          viewer3dApiRef.current.dispose();
          viewer3dApiRef.current = undefined;
        }
        mutationObserver.disconnect();
      }
    });
    mutationObserver.observe(document.body, { childList: true, subtree: true });

    viewer3dApiRef.current.IFC.selector.onSelect.on((data) => {
      setSelectedGlobalIds(data?.components.map((c) => c.globalId) ?? []);
      setSelectionContainsTransparentNodes(!(data?.isTrans) ?? false); // yes, isTrans seems to mean "not transparent"
    });

    viewer3dApiRef.current.IFC.bubble.onBubbleClick.on((issueId) => {
      if (issueId) {
        setSelectedIssueId(issueId);
      }
    });

    viewer3dApiRef.current.addNavCube('');
    viewer3dApiRef.current.enableNavCube(Boolean(viewer3dSettings.navCube));
    refreshLoadedModelFiles();
  }, [apiUrl, setSelectedIssueId, refreshLoadedModelFiles, viewer3dSettings]);

  const state = useMemo<Viewer3dContextState>(() => ({
    viewer3dApiRef,
    initializeViewerInstance,
    interactionMode,
    setInteractionMode: onChangeInteractionMode,
    selectComponentUnderCursor,
    selectedGlobalIds,
    setSelectedGlobalIds,
    clippingPlanes,
    selectedClippingPlane,
    setSelectedClippingPlane: onSelectClippingPlane,
    addClippingPlane,
    addClippingPlaneAtCursor,
    removeClippingPlane,
    updateClippingPlanes,
    resetViewer,
    floorPlanSettings,
    setFloorPlanSettings: onChangeFloorPlanSettings,
    viewer3dSettings,
    setViewer3dSettings: onChangeViewer3dSettings,
    measurements,
    createMeasurementPoint,
    deleteMeasurement,
    highlightComponentUnderCursor,
    clearComponentHighlight,
    zoomToSelectedComponents,
    hideSelectedComponents,
    isolateSelectedComponents,
    toggleSelectedComponentsTransparent,
    resetSelectedComponentsPresentation,
    setSelectedComponentsColor,
    resetSelectedComponentsColor,
    hideComponentsOfDifferentTypeThanSelected,
    isolateSelectedComponentsFloors,
    createViewpoint,
    getNode,
    setViewpointBubbles,
    isViewpointMarkerPlaced,
    setIsViewpointMarkerPlaced,
    deleteViewpointMarker,
    isPlacingViewpointMarker,
    setIsPlacingViewpointMarker,
    modelFiles,
    setModelFiles,
    refreshLoadedModelFiles,
  }), [initializeViewerInstance, interactionMode, onChangeInteractionMode, selectComponentUnderCursor, selectedGlobalIds, clippingPlanes, selectedClippingPlane, onSelectClippingPlane, addClippingPlane, addClippingPlaneAtCursor, removeClippingPlane, updateClippingPlanes, resetViewer, floorPlanSettings, onChangeFloorPlanSettings, viewer3dSettings, onChangeViewer3dSettings, measurements, createMeasurementPoint, deleteMeasurement, highlightComponentUnderCursor, clearComponentHighlight, zoomToSelectedComponents, hideSelectedComponents, isolateSelectedComponents, toggleSelectedComponentsTransparent, resetSelectedComponentsPresentation, setSelectedComponentsColor, resetSelectedComponentsColor, hideComponentsOfDifferentTypeThanSelected, isolateSelectedComponentsFloors, createViewpoint, getNode, isViewpointMarkerPlaced, deleteViewpointMarker, isPlacingViewpointMarker, modelFiles, refreshLoadedModelFiles]);
  return (
    <Viewer3dContext.Provider value={state}>
      {children}
    </Viewer3dContext.Provider>
  );
}
