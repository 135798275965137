enum TimeFrame {
  Today,
  Yesterday,
  ThisWeek,
  LastWeek,
  ThisMonth,
  LastMonth,
  Past,
  Tomorrow,
  NextWeek,
  NextMonth,
  Future,
}

export default TimeFrame;
