import React from 'react';
import {
  FormControl, FormControlLabel, Radio, RadioGroup, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LinkedIssuesScope from 'documents/types/LinkedIssuesScope';
import DocumentFilterState from 'documents-filter/types/DocumentFilterState';

interface IssuesFilterSectionProps {
  value: DocumentFilterState,
  onChange: (state: DocumentFilterState) => void,
}

export default function IssuesFilterSection({
  value,
  onChange,
}: IssuesFilterSectionProps) {
  const { t } = useTranslation('documents-filter');
  return (
    <>
      <Typography id="documents-filter_issues_header" variant="h4" sx={{ mt: 3 }}>{t('filter-drawer_issues_header', 'Issues')}</Typography>
      <FormControl sx={{ mt: 1 }}>
        <RadioGroup
          aria-labelledby="documents-filter_issues_header"
          value={value.linkedIssuesScope ?? LinkedIssuesScope.All}
          onChange={(e) => onChange({ ...value, linkedIssuesScope: parseInt(e.target.value, 10) || undefined })}
          name="radio-buttons-group"
        >
          <FormControlLabel value={LinkedIssuesScope.All} control={<Radio />} label={t('filter-drawer_docs-any-linked-issues', 'Any')} />
          <FormControlLabel value={LinkedIssuesScope.OnlyWithLinkedIssues} control={<Radio />} label={t('filter-drawer_docs-with-linked-issues', 'Documents with linked issues')} />
          <FormControlLabel value={LinkedIssuesScope.OnlyWithoutIssues} control={<Radio />} label={t('filter-drawer_docs-without-linked-issues', 'Documents without linked issues')} />
        </RadioGroup>
      </FormControl>
    </>
  );
}
