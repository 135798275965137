import { useCallback } from 'react';

export default function useLetterNumbering() {
  const letterOrNumericStringToNumber = useCallback((versionNumberString: string) => {
    // numeric input
    const parsedNumber = parseInt(versionNumberString, 10);
    if (parsedNumber >= 0) {
      return parsedNumber;
    }
    // letter input
    let numericVersionNumber = 0;
    const str = versionNumberString.toLowerCase();
    if (str.match(/^[A-Za-z]+$/g) == null) return undefined; // ignore invalid input
    for (let i = 0; i < str.length; i += 1) {
      const digitValue = str.charCodeAt(str.length - 1 - i) - 96;
      const magnitude = 26 ** i;
      numericVersionNumber += digitValue * magnitude;
    }
    return numericVersionNumber;
  }, []);

  const numberToLetter = useCallback((versionNumber: number) => {
    // converts a number to a excel-column-name-style string
    const chars = [];
    let remainingValue = versionNumber - 1;
    if (remainingValue === -1) return '__';
    while (remainingValue >= 0) {
      chars.push((remainingValue % 26) + 65);
      remainingValue = Math.floor(remainingValue / 26) - 1;
    }
    return String.fromCharCode(...chars.reverse());
  }, []);

  return { letterOrNumericStringToNumber, numberToLetter };
}
