import React, { useMemo, useState } from 'react';
import IChildren from 'common/types/IChildren';
import CollaboratorsSelectionContext, { CollaboratorsSelectionContextState } from 'collaborators/contexts/CollaboratorsSelectionContext';

export default function CollaboratorsSelectionContextProvider({
  children,
}: IChildren) {
  const [selectedCollaboratorIds, setSelectedCollaboratorIds] = useState<string[]>([]);
  const value = useMemo<CollaboratorsSelectionContextState>(() => ({
    selectedCollaboratorIds,
    setSelectedCollaboratorIds,
  }), [selectedCollaboratorIds]);
  return <CollaboratorsSelectionContext.Provider value={value}>{children}</CollaboratorsSelectionContext.Provider>;
}
