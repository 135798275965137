import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';

export default function useNamingSchemeDeleteMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.NamingScheme);
  const mutationFn = useCallback(async (id: string) => {
    await axiosInstance.delete<void>(`${ApiEndpoint.NamingScheme}/${id}`);
  }, [axiosInstance]);
  const onSuccess = useCallback(() => {
    queryKeyBases.forEach((queryKey) => queryClient.invalidateQueries({ queryKey }));
  }, [queryClient, queryKeyBases]);
  return useMutation<void, unknown, string>({
    mutationFn,
    onSuccess,
  });
}
