import { DateString } from 'common/types/DateString';
import Folder from 'folders/types/Folder';

export interface DocumentVersionOld {
  id: string;
  timeStamp: DateString;
  versionNumber: number;
  disciplineMetaDataIds: string[];
  buildingMetaDataId: string;
  uploadDate: DateString;
  editDate: DateString;
  creator: {
    id: string;
    timeStamp: DateString;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    chatUserId?: number;
    companyId: string;
    companyName: string;
  };
  editor: {
    id: string;
    timeStamp: DateString;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    chatUserId?: number;
    companyId: string;
    companyName: string;
  };
  documentId: string;
  hasFolderAccess: boolean;
  commentIds: string[];
  tagIds: string[];
  linkedIssueIds: string[];
  isPrivate: boolean;
  previewFileId: string;
  isArchived: boolean;
  workPhaseId: string;
  creationDate: DateString;
  floorMetaId: string;
  folderId: string;
  name: string;
  fileType: FileType;
  folder: Folder;
  originalFileName: string;
  status: {
    id: string;
    projectId: string;
    name: string;
    color: string;
    order: number;
    isDefault: boolean;
    timeStamp: DateString;
    originalName: string;
  };
  fileId: string;
  pdfAnnotationIds: string[];
  fileSize: number;
  modelId: string;
  documentImportStatus: DocumentImportStatus;
  importStartDate?: DateString;
  importFinishDate?: DateString;
}

export enum FileType {
  Video = 0,
  Audio = 1,
  Image = 2,
  Word = 3,
  PowerPoint = 4,
  Pdf = 5,
  Ifc = 6,
  Excel = 7,
  DWG = 8,
  DXF = 9,
  ArchiCAD = 10,
  Revit = 11,
  SMC = 12,
  Navisworks = 13,
  Misc = 14,
}

export enum DocumentImportStatus {
  NotImported = 0,
  ImportStarted = 1,
  ImportCompleted = 2,
  ImportFailed = 3,
}
