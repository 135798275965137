import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import VerticalDivider from 'common/styled/VerticalDivider';
import DocumentDropTarget from 'upload/components/DocumentDropTarget';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import DocumentsSearchTextField from 'documents-filter/components/DocumentsSearchTextField';
import FilterActiveInidicator from 'documents-filter/components/FilterActiveIndicator';
import useCurrentFolderId from 'documents-folders/hooks/useCurrentFolderId';
import DocumentsOptionsButtonMenu from 'documents/components/DocumentsOptionsButtonMenu';
import useCurrentCollaboratorRole from 'collaborators/hooks/useCurrentCollaboratorRole';
import RoleAction from 'projects/types/RoleAction';
import HeaderBox from 'common/components/HeaderBox';
import FilterDrawerToggleButton from 'documents-filter/components/FilterDrawerToggleButton';
import CollaboratorFolderAccessLevel from 'documents-folders/types/CollaboratorFolderAccessLevel';
import useCurrentCollaboratorFolderAccessLevel from 'documents-folders/hooks/useCurrentCollaboratorFolderAccessLevel';

export default function DocumentsPageHeader() {
  const { t } = useTranslation('documents');
  const folderId = useCurrentFolderId();
  const currentUserRole = useCurrentCollaboratorRole();
  const currentFolderAccessLevel = useCurrentCollaboratorFolderAccessLevel(folderId);

  return (
    <HeaderBox id="DocumentsPageHeader">
      <Typography variant="h2" id="DocumentsPageHeaderTitle" sx={{ width: 292, pl: 4 }}>{t('documents-page-header_title', 'Documents')}</Typography>
      <VerticalDivider sx={{ height: 36 }} />
      <DocumentsSearchTextField />
      {!!folderId && !!currentUserRole?.allowedActions?.has(RoleAction.Document_Upload) && currentFolderAccessLevel === CollaboratorFolderAccessLevel.ReadWrite && (
      <Suspense fallback={<CenteredCircularProgress />}>
        <DocumentDropTarget
          folderId={folderId}
          sx={{ ml: 2, px: 1.5 }}
        />
      </Suspense>
      )}
      <Box sx={{ ml: 'auto', display: 'flex' }}>
        <FilterActiveInidicator sx={{ mr: 2 }} />
        <DocumentsOptionsButtonMenu />
        <FilterDrawerToggleButton />
      </Box>
    </HeaderBox>
  );
}
