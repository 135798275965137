import React, { useCallback, useState } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiApplicationExport } from '@mdi/js';
import BcfExportDialog from 'issues/components/BcfExportDialog';
import useIssuesFilterContext from 'issues/hooks/useIssuesFilterContext';

type BcfExportButtonProps = Omit<ButtonProps, 'onClick'>;

export default function BcfExportButton(props: BcfExportButtonProps) {
  const { t } = useTranslation('issues');
  const [bcfExportDialogOpen, setBcfExportDialogOpen] = useState<boolean>(false);
  const onClickExportToBcf = useCallback(() => setBcfExportDialogOpen(true), []);
  const onCloseBcfExportDialog = useCallback(() => setBcfExportDialogOpen(false), []);
  const { odataQuery } = useIssuesFilterContext();
  return (
    <>
      <Button id="BcfExportButton" variant="outlined" sx={{ pl: 1.5, gap: 1 }} {...props} onClick={onClickExportToBcf}>
        <Icon path={mdiApplicationExport} size={0.8} />
        {t('bcf-export-button_label', 'Export to BCF')}
      </Button>
      {!!bcfExportDialogOpen && odataQuery && <BcfExportDialog odataQuery={odataQuery} onClose={onCloseBcfExportDialog} />}
    </>
  );
}
