import React, { useCallback, useMemo, useState } from 'react';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import { Trans, useTranslation } from 'react-i18next';
import useCollaboratorDeleteMutation from 'collaborators/hooks/useCollaboratorDeleteMutation';
import CollaboratorDto from 'collaborators/types/CollaboratorDto';

interface ConfirmRemoveCollaboratorDialogProps {
  collaborator: CollaboratorDto,
  onClose: () => void,
}

export default function ConfirmRemoveCollaboratorDialog({
  collaborator,
  onClose,
}: ConfirmRemoveCollaboratorDialogProps) {
  const { t } = useTranslation('collaborators');
  const { mutateAsync, isPending: isLoadingMutation } = useCollaboratorDeleteMutation();
  const name = useMemo(() => `${collaborator.firstName} ${collaborator.lastName}`, [collaborator]);
  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const onConfirm = useCallback(async () => {
    try {
      await mutateAsync(collaborator.id);
      onClose();
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [collaborator, getRequestErrorMessage, mutateAsync, onClose]);

  return (
    <Dialog id="ConfirmRemoveCollaboratorDialog" open>
      <DialogTitle>{t('confirm-remove-collaborator-dialog_title', 'Remove {{collaborator}} from Project?', { collaborator: name })}</DialogTitle>
      <DialogContent>
        <Alert severity="warning" sx={{ py: 2 }}>
          <Trans
            t={t}
            i18nKey="confirm-remove-collaborator-dialog_content"
            components={{ n: <strong /> }}
            defaults="Are you sure that you want to remove the collaborator <n>{{name}}</n> from this project?"
            values={{ name }}
          />
        </Alert>
        {!!errorMessage && <Alert severity="error" sx={{ mt: 2 }}>{errorMessage}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button id="ConfirmRemoveCollaboratorDialogCofirmButton" variant="contained" color="secondary" onClick={onConfirm} disabled={isLoadingMutation}>{t('confirm-remove-collaborator-dialog_confirm', 'Yes, Remove')}</Button>
        <Button id="ConfirmRemoveCollaboratorDialogCancelButton" variant="contained" color="primary" onClick={onClose} sx={{ ml: 4, minWidth: '120px' }}>{t('confirm-remove-collaborator-dialog_cancel', 'Cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
}
