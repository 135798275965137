import React, { ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SxProps, Theme } from '@mui/material';
import { Moment } from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { deDE, enUS } from '@mui/x-date-pickers/locales';

interface LocalizedDatePickerProps {
  onChange: (value: Moment | undefined) => void,
  value: Moment | undefined,
  disabled?: boolean,
  label?: ReactNode,
  sx?: SxProps<Theme>,
  minDate?: Moment | undefined,
  maxDate?: Moment | undefined,
}

export default function LocalizedDatePicker({
  onChange,
  value,
  disabled,
  minDate,
  maxDate,
  label,
  sx,
}: LocalizedDatePickerProps) {
  const { i18n } = useTranslation();
  const adapterLocale = useMemo(() => i18n.language.split('-')[0], [i18n.language]);
  const localeText = useMemo(() => (adapterLocale === 'de' ? deDE : enUS).components.MuiLocalizationProvider.defaultProps.localeText, [adapterLocale]);
  const onChangeDatePicker = useCallback((nextValue: Moment | null) => {
    if (!nextValue || !nextValue.isValid()) {
      onChange(undefined);
      return;
    }
    onChange(nextValue);
  }, [onChange]);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={adapterLocale} localeText={localeText}>
      <DatePicker<Moment>
        sx={sx}
        onChange={onChangeDatePicker}
        value={value ?? null}
        minDate={minDate}
        maxDate={maxDate}
        format="DD.MM.YYYY"
        label={label}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
}
