import { t } from "i18next";
import {
    AmbientLight,
    BoxGeometry,
    DoubleSide,
    Material,
    MathUtils,
    Mesh,
    MeshBasicMaterial,
    MeshPhongMaterial,
    Object3D,
    PerspectiveCamera,
    PlaneGeometry,
    Raycaster,
    Scene,
    SpotLight,
    Texture,
    Vector2,
    Vector3,
    WebGLRenderer
} from "three";
import { CameraPose, IfcComponent } from "../..";
import { IfcContext } from "../context";

interface RotationData {
    axis: Vector3,
    angle: number
};

interface FaceDir {
    [face: string]: { dir: Vector3 }
};

const FACES = {
    TOP: "top",
    FRONT: "front",
    RIGHT: "right",
    BACK: "back",
    LEFT: "left",
    BOTTOM: "bottom",

    TOP_FRONT_EDGE: "top_front_edge",
    TOP_RIGHT_EDGE: "top_right_edge",
    TOP_BACK_EDGE: "top_back_edge",
    TOP_LEFT_EDGE: "top_left_edge",
    FRONT_RIGHT_EDGE: "front_right_edge",
    BACK_RIGHT_EDGE: "back_right_edge",
    BACK_LEFT_EDGE: "back_left_edge",
    FRONT_LEFT_EDGE: "front_left_edge",
    BOTTOM_FRONT_EDGE: "bottom_front_edge",
    BOTTOM_RIGHT_EDGE: "bottom_right_edge",
    BOTTOM_BACK_EDGE: "bottom_back_edge",
    BOTTOM_LEFT_EDGE: "bottom_left_edge",

    TOP_FRONT_RIGHT_CORNER: "top_front_right_corner",
    TOP_BACK_RIGHT_CORNER: "top_back_right_corner",
    TOP_BACK_LEFT_CORNER: "top_back_left_corner",
    TOP_FRONT_LEFT_CORNER: "top_front_left_corner",
    BOTTOM_FRONT_RIGHT_CORNER: "bottom_front_right_corner",
    BOTTOM_BACK_RIGHT_CORNER: "bottom_back_right_corner",
    BOTTOM_BACK_LEFT_CORNER: "bottom_back_left_corner",
    BOTTOM_FRONT_LEFT_CORNER: "bottom_front_left_corner"
};

const ANGLE = {
    ANG_0: 0,
    ANG_90: Math.PI / 2,
    ANG_180: Math.PI,
    ANG_270: Math.PI * 1.5,
    ANG_360: Math.PI * 2
};

const X_AXIS = new Vector3(1, 0, 0);
const Y_AXIS = new Vector3(0, 1, 0);
const Z_AXIS = new Vector3(0, 0, 1);

const HOVERCOLOR = 0x4a5f70;
const GENERALCOLOR = 0x9c9999;
const CAMERADISTANCE = 200;
const SENSITIVITY = -0.5;
const ACTIVEOPACITY = 0.4;
const DEACTIVEOPACITY = 0.8;

export class NavigationCube extends IfcComponent {
    private cube = new Object3D();
    private scene: Scene;
    private camera: PerspectiveCamera;
    private raycaster = new Raycaster();

    private renderer: WebGLRenderer;
    private container: HTMLElement;
    private style = "";
    private isVisible = false;

    private mousePos = new Vector2();
    private faceOpacity = 0.4;
    private mainMat = new MeshBasicMaterial({
        color: GENERALCOLOR,
        side: DoubleSide,
        opacity: DEACTIVEOPACITY,
        transparent: true
    });

    private mouseDown = false;
    private selectedFace?: Mesh;
    private faceDir: FaceDir = {};
    private lastX = 0;
    private lastY = 0;

    constructor(private context: IfcContext, style: string) {
        super(context);
        this.style = style;
        const container = document.getElementById('nav-cube-container');
        if (!container) throw new Error('missing nav cube container');
        this.container = container;
        this.container.style.cssText = style;
        this.setEvent();

        this.scene = new Scene();
        this.renderer = new WebGLRenderer({
            antialias: true,
            alpha: true
        });
        this.container.appendChild(this.renderer.domElement);

        this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);

        this.camera = new PerspectiveCamera(75, 1, 0.1, 1000);
        this.camera.position.set(0, 0, CAMERADISTANCE);

        this.setLight();
        this.setCube();

        this.context.ifcCamera.onChange.on((data: CameraPose) => {
            this.onChangeCamera(data);
        });

        this.setFaceDir();
        this.render();
    }

    get visible() {
        return this.isVisible;
    }

    set visible(visible: boolean) {
        if (this.isVisible == visible) return;
        this.isVisible = visible;
        this.refresh();
    }

    dispose(): void {
        this.container.ownerDocument.removeEventListener("mousemove", this.onMouseMove);
        this.container.ownerDocument.removeEventListener("mouseup", this.onMouseUp);
        this.container.removeEventListener("mousedown", this.onMouseDown);
        this.container.removeEventListener("mouseenter", this.onMouseEnter);
        this.container.removeEventListener("mouseleave", this.onMouseLeave);
    }

    private setFaceDir() {
        this.faceDir[FACES.FRONT] = { dir: new Vector3(0, 0, 1) };
        this.faceDir[FACES.TOP] = { dir: new Vector3(0, 1, 0) };
        this.faceDir[FACES.RIGHT] = { dir: new Vector3(1, 0, 0) };
        this.faceDir[FACES.BACK] = { dir: new Vector3(0, 0, -1) };
        this.faceDir[FACES.LEFT] = { dir: new Vector3(-1, 0, 0) };
        this.faceDir[FACES.BOTTOM] = { dir: new Vector3(0, -1, 0) };

        this.faceDir[FACES.TOP_FRONT_EDGE] = { dir: new Vector3(0, 1, 1).normalize() };
        this.faceDir[FACES.TOP_RIGHT_EDGE] = { dir: new Vector3(1, 1, 0).normalize() };
        this.faceDir[FACES.TOP_BACK_EDGE] = { dir: new Vector3(0, 1, -1).normalize() };
        this.faceDir[FACES.TOP_LEFT_EDGE] = { dir: new Vector3(-1, 1, 0).normalize() };
        this.faceDir[FACES.FRONT_RIGHT_EDGE] = { dir: new Vector3(1, 0, 1).normalize() };
        this.faceDir[FACES.BACK_RIGHT_EDGE] = { dir: new Vector3(1, 0, -1).normalize() };
        this.faceDir[FACES.BACK_LEFT_EDGE] = { dir: new Vector3(-1, 0, -1).normalize() };
        this.faceDir[FACES.FRONT_LEFT_EDGE] = { dir: new Vector3(-1, 0, 1).normalize() };
        this.faceDir[FACES.BOTTOM_FRONT_EDGE] = { dir: new Vector3(0, -1, 1).normalize() };
        this.faceDir[FACES.BOTTOM_RIGHT_EDGE] = { dir: new Vector3(1, -1, 0).normalize() };
        this.faceDir[FACES.BOTTOM_BACK_EDGE] = { dir: new Vector3(0, -1, -1).normalize() };
        this.faceDir[FACES.BOTTOM_LEFT_EDGE] = { dir: new Vector3(-1, -1, 0).normalize() };

        this.faceDir[FACES.TOP_FRONT_RIGHT_CORNER] = { dir: new Vector3(1, 1, 1).normalize() };
        this.faceDir[FACES.TOP_BACK_RIGHT_CORNER] = { dir: new Vector3(1, 1, -1).normalize() };
        this.faceDir[FACES.TOP_BACK_LEFT_CORNER] = { dir: new Vector3(-1, 1, -1).normalize() };
        this.faceDir[FACES.TOP_FRONT_LEFT_CORNER] = { dir: new Vector3(-1, 1, 1).normalize() };
        this.faceDir[FACES.BOTTOM_FRONT_RIGHT_CORNER] = { dir: new Vector3(1, -1, 1).normalize() };
        this.faceDir[FACES.BOTTOM_BACK_RIGHT_CORNER] = { dir: new Vector3(1, -1, -1).normalize() };
        this.faceDir[FACES.BOTTOM_BACK_LEFT_CORNER] = { dir: new Vector3(-1, -1, -1).normalize() };
        this.faceDir[FACES.BOTTOM_FRONT_LEFT_CORNER] = { dir: new Vector3(-1, -1, 1).normalize() };
    }

    private setEvent() {
        this.container.ownerDocument.addEventListener("mousemove", this.onMouseMove);
        this.container.ownerDocument.addEventListener("mouseup", this.onMouseUp);
        this.container.addEventListener("mousedown", this.onMouseDown);
        this.container.addEventListener("mouseenter", this.onMouseEnter);
        this.container.addEventListener("mouseleave", this.onMouseLeave);
    }

    private onChangeCamera(data: CameraPose) {
        const pos = data.direction.clone().normalize().multiplyScalar(-CAMERADISTANCE);
        this.camera.position.copy(pos);
        this.camera.lookAt(0, 0, 0);
        this.camera.updateProjectionMatrix();
    }

    private onMouseUp = (e: MouseEvent) => {
        if (e.button == 0) {
            this.mouseDown = false;

            if (!!this.selectedFace) {
                const dir = this.faceDir[this.selectedFace.name].dir.clone();
                if (!!dir) {
                    this.context.ifcCamera.setCameraDirection(dir);
                }
            }

            if (this.faceOpacity == DEACTIVEOPACITY) {
                this.refreshFace(this.cube);
            }
        }
    }

    private onMouseMove = (e: MouseEvent) => {
        const self = this;
        const items = this.pick(e);
        if (items?.length) {
            const object = items[0].object;
            this.selectFace(object);
        } else {
            clearFace();
        }

        if (this.mouseDown) {
            clearFace();

            const yaw = MathUtils.degToRad((e.clientX - this.lastX) * SENSITIVITY);
            const pitch = MathUtils.degToRad((e.clientY - this.lastY) * SENSITIVITY * 2);

            this.context.ifcCamera.rotateCamera(yaw, pitch);

            this.lastX = e.clientX;
            this.lastY = e.clientY;
        }

        function clearFace() {
            if (!!self.selectedFace && self.selectedFace.material instanceof MeshBasicMaterial) {
                self.selectedFace.material.color.setHex(GENERALCOLOR);
            }

            self.selectedFace = undefined;
        }
    }

    private onMouseDown = (e: MouseEvent) => {
        if (e.button == 0 && !!this.selectedFace) {
            this.mouseDown = true;
            this.lastX = e.clientX;
            this.lastY = e.clientY;
        }
    }

    private onMouseEnter = () => {
        this.faceOpacity = ACTIVEOPACITY;
        if (!this.mouseDown) {
            this.refreshFace(this.cube);
        }
    }

    private onMouseLeave = () => {
        this.faceOpacity = DEACTIVEOPACITY;
        if (!this.mouseDown) {
            this.refreshFace(this.cube);
        }
    }

    private refreshFace(object: Object3D) {
        for (let i = 0, len = object.children.length; i < len; i++) {
            const child = object.children[i];
            if (child instanceof Mesh && child.name != "labels") {
                child.material.opacity = this.faceOpacity;
            }

            if (child.children) {
                this.refreshFace(child);
            }
        }
    }

    private selectFace(object: Object3D) {
        if (object instanceof Mesh) {
            if (!!this.selectedFace) {
                if (this.selectedFace.name == object.name) return;
                if (this.selectedFace.material instanceof MeshBasicMaterial)
                    this.selectedFace.material.color.setHex(GENERALCOLOR);
            }

            this.selectedFace = object;
            if (this.selectedFace.material instanceof MeshBasicMaterial) {
                this.selectedFace.material.color.setHex(HOVERCOLOR);
            }
        }
    }

    private pick(e: MouseEvent) {
        if (this.isVisible && !!this.cube) {
            this.setupMousePosition(e);
            this.camera.updateMatrixWorld();
            this.raycaster.setFromCamera(this.mousePos, this.camera);

            return this.raycaster.intersectObjects(this.cube.children);
        }

        return null;
    }

    private setupMousePosition(e: MouseEvent) {
        const bounds = this.container.getBoundingClientRect();
        this.mousePos.x = ((e.clientX - bounds.left) / (bounds.right - bounds.left)) * 2 - 1;
        this.mousePos.y = -((e.clientY - bounds.top) / (bounds.bottom - bounds.top)) * 2 + 1;
    }

    private render = () => {
        this.renderer.render(this.scene, this.camera);

        requestAnimationFrame(this.render);
    }

    private refresh() {
        if (this.isVisible) {
            this.container.style.cssText = this.style;
        } else {
            this.container.style.cssText = "display:none;"
        }
    }

    private setLight() {
        const ambient = new AmbientLight(0xffffff, 0.85);
        this.scene.add(ambient);

        const topLeftSpot = new SpotLight(0xffffff);
        topLeftSpot.lookAt(0, 0, 0);
        topLeftSpot.position.set(200, -200, 200);
        this.scene.add(topLeftSpot);

        const topRightSpot = new SpotLight(0xffffff, 0.6);
        topRightSpot.lookAt(0, 0, 0);
        topRightSpot.position.set(200, 200, 200);
        this.scene.add(topRightSpot);
    }

    private setCube() {
        this.setLabels();
        this.setFaces();
        this.setEdges();
        this.setCorners();

        this.scene.add(this.cube);
    }

    private setFaces() {
        // Top
        const topFace = this.makeFace(FACES.TOP, 0, 50, 0, [{
            axis: X_AXIS,
            angle: ANGLE.ANG_90
        }]);

        this.cube.add(topFace);

        // Front
        const frontFace = this.makeFace(FACES.FRONT, 0, 0, 50, [{
            axis: X_AXIS,
            angle: ANGLE.ANG_0
        }]);

        this.cube.add(frontFace);

        // Right
        const rightFace = this.makeFace(FACES.RIGHT, 50, 0, 0, [{
            axis: Y_AXIS,
            angle: ANGLE.ANG_90
        }]);

        this.cube.add(rightFace);

        // Back
        const backFace = this.makeFace(FACES.BACK, 0, 0, -50, [{
            axis: X_AXIS,
            angle: ANGLE.ANG_180
        }]);

        this.cube.add(backFace);

        // Left
        const leftFace = this.makeFace(FACES.LEFT, -50, 0, 0, [{
            axis: Y_AXIS,
            angle: ANGLE.ANG_270
        }]);

        this.cube.add(leftFace);

        // Bottom
        const bottomFace = this.makeFace(FACES.BOTTOM, 0, -50, 0, [{
            axis: X_AXIS,
            angle: ANGLE.ANG_270
        }]);

        this.cube.add(bottomFace);
    }

    private makeFace(
        name: string,
        x: number,
        y: number,
        z: number,
        rotations: RotationData[]
    ) {
        const geometry = new PlaneGeometry(70, 70);
        const material = this.mainMat.clone();

        const face = new Mesh(geometry, material);
        face.name = name;
        face.position.set(x, y, z);
        rotations.forEach((rotation) => {
            face.rotateOnAxis(rotation.axis, rotation.angle);
        });

        return face;
    }

    private setLabels() {
        const materials: Material[] = [];
        const labelMat = new MeshPhongMaterial({
            color: GENERALCOLOR,
            transparent: true
        });

        // Right
        const rightMat = labelMat.clone();
        const rightMap = this.getLabelTexture(t("cube_right", "Right"));
        if (!!rightMap) rightMat.map = rightMap;
        materials.push(rightMat);

        // Left
        const leftMat = labelMat.clone();
        const leftMap = this.getLabelTexture(t("cube_left", "Left"));
        if (!!leftMap) leftMat.map = leftMap;
        materials.push(leftMat);

        // Top
        const topMat = labelMat.clone();
        const topMap = this.getLabelTexture(t("cube_top", "Top"));
        if (!!topMap) topMat.map = topMap;
        materials.push(topMat);

        // Bottom
        const bottomMat = labelMat.clone();
        const bottomMap = this.getLabelTexture(t("cube_bottom", "Bottom"));
        if (!!bottomMap) bottomMat.map = bottomMap;
        materials.push(bottomMat);

        // Front
        const frontMat = labelMat.clone();
        const frontMap = this.getLabelTexture(t("cube_front", "Front"));
        if (!!frontMap) frontMat.map = frontMap;
        materials.push(frontMat);

        // Back
        const backMat = labelMat.clone();
        const backMap = this.getLabelTexture(t("cube_back", "Back"));
        if (!!backMap) backMat.map = backMap;
        materials.push(backMat);

        const szLabel = 99;
        const geometry = new BoxGeometry(szLabel, szLabel, szLabel);
        const labels = new Mesh(geometry, materials);
        labels.name = "labels";

        this.cube.add(labels);
    }

    private getLabelTexture(label: string) {
        const canvas = document.createElement("canvas");
        canvas.width = canvas.height = 200;
        const context = canvas.getContext("2d");

        if (!context) return;

        context.fillStyle = "#d3d3d3";
        context.fillRect(0, 0, canvas.width, canvas.height);

        context.translate(canvas.width / 2, canvas.height / 2);

        context.font = "60px sans-serif";
        context.textAlign = "center";
        context.textBaseline = "middle";
        context.fillStyle = "black";
        context.fillText(label, 0, 0);
        context.strokeStyle = "black";

        const texture = new Texture(canvas);
        texture.needsUpdate = true;
        texture.anisotropy = 8;

        return texture;

    }

    private setCorners() {
        // Top-Front-Left
        const cornerTopFrontLeft = this.makeCorner(FACES.TOP_FRONT_LEFT_CORNER, -50, 50, 50, [{
            axis: Z_AXIS,
            angle: ANGLE.ANG_270
        }]);

        this.cube.add(cornerTopFrontLeft);

        // Top-Front-Right
        const cornerTopFrontRight = this.makeCorner(FACES.TOP_FRONT_RIGHT_CORNER, 50, 50, 50, [{
            axis: Z_AXIS,
            angle: ANGLE.ANG_180
        }]);

        this.cube.add(cornerTopFrontRight);

        // Top-Back-Right
        const cornerTopBackRight = this.makeCorner(FACES.TOP_BACK_RIGHT_CORNER, 50, 50, -50, [{
            axis: Z_AXIS,
            angle: ANGLE.ANG_180
        }, { axis: Y_AXIS, angle: ANGLE.ANG_270 }]);

        this.cube.add(cornerTopBackRight);

        // Top-Back-Left
        const cornerTopBackLeft = this.makeCorner(FACES.TOP_BACK_LEFT_CORNER, -50, 50, -50, [{
            axis: Z_AXIS,
            angle: ANGLE.ANG_270
        }, { axis: Y_AXIS, angle: ANGLE.ANG_270 }]);

        this.cube.add(cornerTopBackLeft);

        // Bottom-Front-Left
        const cornerBottomFrontLeft = this.makeCorner(FACES.BOTTOM_FRONT_LEFT_CORNER, -50, -50, 50, [{
            axis: Z_AXIS,
            angle: ANGLE.ANG_0
        }]);

        this.cube.add(cornerBottomFrontLeft);

        // Bottom-Front-Right
        const cornerBottomFrontRight = this.makeCorner(FACES.BOTTOM_FRONT_RIGHT_CORNER, 50, -50, 50, [{
            axis: Y_AXIS,
            angle: ANGLE.ANG_90
        }]);

        this.cube.add(cornerBottomFrontRight);

        // Bottom-Back-Right
        const cornerBottomBackRight = this.makeCorner(FACES.BOTTOM_BACK_RIGHT_CORNER, 50, -50, -50, [{
            axis: Y_AXIS,
            angle: ANGLE.ANG_180
        }]);

        this.cube.add(cornerBottomBackRight);

        // Bottom-Back-Left
        const cornerBottomBackLeft = this.makeCorner(FACES.BOTTOM_BACK_LEFT_CORNER, -50, -50, -50, [{
            axis: Y_AXIS,
            angle: ANGLE.ANG_270
        }]);

        this.cube.add(cornerBottomBackLeft);
    }

    private makeCorner(
        name: string,
        x: number,
        y: number,
        z: number,
        rotations: RotationData[]
    ) {
        const width = 15;
        const material = this.mainMat.clone();
        const geometry = new PlaneGeometry(width, width);

        const face1 = new Mesh(geometry.clone(), material);
        face1.name = name;
        face1.position.setX(width / 2);
        face1.position.setY(width / 2);

        const face2 = new Mesh(geometry.clone(), material);
        face2.name = name;
        face2.position.setX(width / 2);
        face2.position.setZ(-width / 2);
        face2.rotateOnAxis(X_AXIS, ANGLE.ANG_90);

        const face3 = new Mesh(geometry.clone(), material);
        face3.name = name;
        face3.position.setY(width / 2);
        face3.position.setZ(-width / 2);
        face3.rotateOnAxis(Y_AXIS, -ANGLE.ANG_90);

        const corner = new Object3D();
        corner.add(face1);
        corner.add(face2);
        corner.add(face3);

        corner.name = name;
        corner.position.set(x, y, z);
        rotations.forEach((rotation) => {
            corner.rotateOnAxis(rotation.axis, rotation.angle);
        });

        geometry.dispose();

        return corner;
    }

    private setEdges() {
        // Top-Front
        const edgeTopFront = this.makeEdge(FACES.TOP_FRONT_EDGE, 0, 50, 50, [{
            axis: X_AXIS,
            angle: ANGLE.ANG_270
        }]);

        this.cube.add(edgeTopFront);

        // Top-Right
        const edgeTopRight = this.makeEdge(FACES.TOP_RIGHT_EDGE, 50, 50, 0, [{
            axis: Y_AXIS,
            angle: ANGLE.ANG_90
        }, { axis: X_AXIS, angle: ANGLE.ANG_270 }]);

        this.cube.add(edgeTopRight);

        // Top-Back
        const edgeTopBack = this.makeEdge(FACES.TOP_BACK_EDGE, 0, 50, -50, [{
            axis: X_AXIS,
            angle: ANGLE.ANG_180
        }]);

        this.cube.add(edgeTopBack);

        // Top-Left
        const edgeTopLeft = this.makeEdge(FACES.TOP_LEFT_EDGE, -50, 50, 0, [{
            axis: Y_AXIS,
            angle: ANGLE.ANG_90
        }, { axis: X_AXIS, angle: ANGLE.ANG_180 }]);

        this.cube.add(edgeTopLeft);

        // Bottom-Front
        const edgeBottomFront = this.makeEdge(FACES.BOTTOM_FRONT_EDGE, 0, -50, 50, [{
            axis: Z_AXIS,
            angle: ANGLE.ANG_0
        }]);

        this.cube.add(edgeBottomFront);

        // Bottom-Right
        const edgeBottomRight = this.makeEdge(FACES.BOTTOM_RIGHT_EDGE, 50, -50, 0, [{
            axis: Y_AXIS,
            angle: ANGLE.ANG_90
        }]);

        this.cube.add(edgeBottomRight);

        // Bottom-Back
        const edgeBottomBack = this.makeEdge(FACES.BOTTOM_BACK_EDGE, 0, -50, -50, [{
            axis: Y_AXIS,
            angle: ANGLE.ANG_180
        }]);

        this.cube.add(edgeBottomBack);

        // Bottom-Left
        const edgeBottomLeft = this.makeEdge(FACES.BOTTOM_LEFT_EDGE, -50, -50, 0, [{
            axis: Y_AXIS,
            angle: ANGLE.ANG_270
        }]);

        this.cube.add(edgeBottomLeft);

        // Front-Right
        const edgeFrontRight = this.makeEdge(FACES.FRONT_RIGHT_EDGE, 50, 0, 50, [{
            axis: Z_AXIS,
            angle: ANGLE.ANG_90
        }]);

        this.cube.add(edgeFrontRight);

        // Back-Right
        const edgeBackRight = this.makeEdge(FACES.BACK_RIGHT_EDGE, 50, 0, -50, [{
            axis: Z_AXIS,
            angle: ANGLE.ANG_270
        }, { axis: X_AXIS, angle: ANGLE.ANG_180 }]);

        this.cube.add(edgeBackRight);

        // Back-Left
        const edgeBackLeft = this.makeEdge(FACES.BACK_LEFT_EDGE, -50, 0, -50, [{
            axis: Z_AXIS,
            angle: ANGLE.
                ANG_90
        }, { axis: X_AXIS, angle: ANGLE.ANG_180 }]);

        this.cube.add(edgeBackLeft);

        // Front-Left
        const edgeFrontLeft = this.makeEdge(FACES.FRONT_LEFT_EDGE, -50, 0, 50, [{
            axis: Z_AXIS,
            angle: ANGLE.ANG_270
        }]);

        this.cube.add(edgeFrontLeft);
    }

    private makeEdge(
        name: string,
        x: number,
        y: number,
        z: number,
        rotations: RotationData[]
    ) {
        const width = 70, height = 15;
        const material = this.mainMat.clone();
        const geometry = new PlaneGeometry(width, height);
        const face1 = new Mesh(geometry.clone(), material);

        face1.name = name;
        face1.position.setY(height / 2);

        const face2 = new Mesh(geometry.clone(), material);
        face2.name = name;
        face2.position.setZ(-height / 2);
        face2.rotateOnAxis(X_AXIS, ANGLE.ANG_90);

        const edge = new Object3D();
        edge.add(face1);
        edge.add(face2);
        edge.name = name;
        edge.position.set(x, y, z);

        rotations.forEach((rotation) => {
            edge.rotateOnAxis(rotation.axis, rotation.angle);
        });

        geometry.dispose();

        return edge;
    }
}