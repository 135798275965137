import React from 'react';
import { useTranslation } from 'react-i18next';
import IssueResponsibilityActionPanel from 'issues/components/IssueResponsibilityActionPanel';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';

interface IssueAssignPanelProps {
  issueId: string,
  onClose: () => void,
}

export default function IssueAssignPanel({
  issueId,
  onClose,
}: IssueAssignPanelProps) {
  const { t } = useTranslation('issues');
  const { data: issue } = useIssueQuery(issueId);
  if (!issue) return <CenteredCircularProgress />;
  return (
    <IssueResponsibilityActionPanel
      id="AssignPanel"
      issueId={issueId}
      title={t('issue-assign-panel_header', 'Assign')}
      onClose={onClose}
      confirmButtonLabel={t('issue-assign-panel_confirm-button-label', 'Assign')}
      commentDefaultText={t('issue-assign-panel_comment-default-text', 'Assignment changed.')}
      canSelectAssigneesAndReviewer
      defaultAssignedCollaboratorIds={issue.assignedCollaboratorIds}
      defaultReviewerId={issue.reviewerId}
    />
  );
}
