import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import DocumentVersionDto from 'documents/types/DocumentVersionDto';
import ImportFloorPlanDto from 'documents/types/ImportFloorPlanDto';

export default function useDocumentVersionPlanImportMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentVersion);

  return useMutation<DocumentVersionDto | undefined, unknown, ImportFloorPlanDto>({
    mutationFn: async (importFloorPlanDto: ImportFloorPlanDto) => {
      const { data } = await axiosInstance.post<DocumentVersionDto>(`${ApiEndpoint.DocumentVersion}/import-plan`, importFloorPlanDto);
      return data;
    },
    onSuccess: async () => {
      await Promise.all(queryKeyBases.map((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase })));
    },
  });
}
