import React, { useCallback, useMemo } from 'react';
import useIssuesSelectionContext from 'issues/hooks/useIssuesSelectionContext';
import IssueDetailsPanel from 'issues/components/IssueDetailsPanel';

export default function DashboardIssueDetailsPanel() {
  const { selectedIssueIds, setSelectedIssueIds } = useIssuesSelectionContext();
  const selectedIssueId = useMemo(() => (selectedIssueIds.length === 1 ? selectedIssueIds[0] : undefined), [selectedIssueIds]);
  const onClickIssue = useCallback((issueId: string) => setSelectedIssueIds([issueId]), [setSelectedIssueIds]);
  const onCloseIssueDetails = useCallback(() => setSelectedIssueIds([]), [setSelectedIssueIds]);

  return (
    <IssueDetailsPanel
      issueId={selectedIssueId}
      onClose={onCloseIssueDetails}
      onClickIssueItem={onClickIssue}
      nonInteractive
    />
  );
}
