import { BufferAttribute, Matrix3, Matrix4 } from "three";
import { mergeBufferAttributes } from "three/examples/jsm/utils/BufferGeometryUtils";
import { IfcState, VisoNode } from "./BaseDefinitions";

export interface ExpressIdMap {
  [expressId: number]: string;
}
export interface GeometryAttributes {
  position: BufferAttribute;
  normal: BufferAttribute;
  index: BufferAttribute;
  wireframe: BufferAttribute;
  expressId: BufferAttribute;
  expressIdMap: ExpressIdMap;
}

export class SubsetUtils {
  private static matrix = new Matrix4();

  private static expressId = 0;

  public static resetExpressId() {
    SubsetUtils.expressId = 0;
  }

  public static getTransformMatrix(e: any) {
    SubsetUtils.matrix.set(
      e[0],
      e[8],
      -e[4],
      e[12],
      e[2],
      e[10],
      -e[6],
      e[14],
      -e[1],
      -e[9],
      e[5],
      -e[13],
      e[3],
      e[11],
      e[7],
      e[15]
    );

    return SubsetUtils.matrix;
  }

  public static getGeometryAttributes(
    state: IfcState,
    modelID: string,
    globalIds: string[],
    writeExpressId: boolean = false
  ) {
    const nodes:VisoNode[]=[];
    globalIds.forEach((gId)=>{
      const node = state.modelData[modelID].nodeData[gId];
      if(node){
        nodes.push(node);
      }
    });

    const shapes = state.modelData[modelID].shapes;
    if (shapes == null || nodes.length == 0) {
      return null;
    }

    const attributes: GeometryAttributes[] = [];
    nodes.forEach((node) => {
      const shape = shapes[node.shapeId];
      if (shape) {
        let attribute = SubsetUtils.getGeometryAttribute(
          SubsetUtils.getTransformMatrix(node.transform),
          shape,
          writeExpressId
        );
        if (writeExpressId) {
          attribute.expressIdMap = {
            [attribute.expressId.getX(0)]: node.globalId,
          };
        }

        attributes.push(attribute);
      }
    });

    if (!attributes.length) return null;
    return SubsetUtils.mergeGeometryAttributes(attributes);
  }

  public static mergeGeometryAttributes(attributes: GeometryAttributes[]) {
    const position = mergeBufferAttributes(attributes.map((a) => a.position));
    const normal = mergeBufferAttributes(attributes.map((a) => a.normal));
    const index = SubsetUtils.mergeIndexes(
      attributes.map((a) => a.index),
      attributes.map((a) => a.position.count)
    );
    const wireframe = SubsetUtils.mergeIndexes(
      attributes.map((a) => a.wireframe),
      attributes.map((a) => a.position.count)
    );
    const expressId = mergeBufferAttributes(attributes.map((a) => a.expressId));
    const expressIdMap = SubsetUtils.mergeExpressIdMap(
      attributes.map((a) => a.expressIdMap)
    );

    return {
      position,
      normal,
      index,
      wireframe,
      expressId,
      expressIdMap,
    } as GeometryAttributes;
  }

  public static getGeometryAttribute(
    transform: Matrix4,
    shape: any,
    writeExpressId: boolean
  ) {
    const position = new BufferAttribute(shape.vertices.slice(), 3);
    position.applyMatrix4(transform);
    position.needsUpdate = true;

    const normal = new BufferAttribute(shape.normals.slice(), 3);
    normal.applyNormalMatrix(new Matrix3().getNormalMatrix(transform));
    normal.needsUpdate = true;

    const wireframe = new BufferAttribute(shape.wireframe.slice(), 1);

    let indexAtts: any[] = [];
    for (let i = 0; i < shape.triangles.length; i++) {
      // @ts-ignore
      indexAtts = indexAtts.concat(Array.from(shape.triangles[i]));
    }

    const index = new BufferAttribute(new Uint32Array(indexAtts), 1);

    let expressId: BufferAttribute;
    if (writeExpressId) {
      const idAttributes = new Uint32Array(shape.vertices.length / 3);
      idAttributes.fill(SubsetUtils.expressId++);
      expressId = new BufferAttribute(idAttributes, 1);
    } else {
      expressId = new BufferAttribute(new Uint32Array(), 0);
    }

    const expressIdMap = {};

    return {
      position,
      normal,
      wireframe,
      index,
      expressId,
      expressIdMap,
    } as GeometryAttributes;
  }

  private static mergeIndexes(indexes: BufferAttribute[], offsets: number[]) {
    let offset = 0;
    const mergedIndex = [];
    for (let i = 0; i < indexes.length; i++) {
      const index = indexes[i];
      for (let j = 0; j < index.count; j++) {
        mergedIndex.push(index.getX(j) + offset);
      }

      offset += offsets[i];
    }

    return new BufferAttribute(new Uint32Array(mergedIndex), 1);
  }

  private static mergeExpressIdMap(expressIdMaps: ExpressIdMap[]) {
    const map: ExpressIdMap = {};
    for (let i = 0; i < expressIdMaps.length; i++) {
      const item = expressIdMaps[i];
      Object.keys(item).forEach((key) => {
        const id = parseInt(key);
        if (!isNaN(id) && !map[id]) {
          map[id] = item[id];
        }
      });
    }

    return map as ExpressIdMap;
  }
}
