import React, { useCallback, useState } from 'react';
import { Box, Button, Popover, PopoverProps } from '@mui/material';
import { ColorResult, SketchPicker } from 'react-color';
import { useTranslation } from 'react-i18next';

const DEFAULT_COLOR = '#000000';

type ColorPickerProps = { color?: string } & Pick<PopoverProps, 'id' | 'open' | 'anchorEl' | 'anchorOrigin' | 'transformOrigin'> & (
  {
    onChange: (nextColor: string) => void,
    onClose: () => void,
    onConfirm?: undefined,
    onCancel?: undefined,
    onReset?: undefined,
  } | {
    onChange?: undefined,
    onClose?: undefined,
    onConfirm: (nextColor: string) => void,
    onCancel: () => void,
    onReset?: () => void,
  });

export default function ColorPicker({
  id,
  open,
  color,
  onChange,
  onClose,
  onConfirm,
  onCancel,
  onReset,
  anchorEl,
  anchorOrigin,
  transformOrigin,
}: ColorPickerProps) {
  const { t } = useTranslation('common');
  const [displayValue, setDisplayValue] = useState<string>(color ?? DEFAULT_COLOR);
  const onChangeSketchPickerComplete = useCallback((c: ColorResult) => {
    setDisplayValue(c.hex);
    if (onChange) {
      onChange(c.hex);
    }
  }, [onChange]);
  const onCloseColorPicker = useCallback(() => (onClose ?? onCancel)(), [onCancel, onClose]);
  const onClickConfirm = useCallback(() => {
    if (onConfirm) {
      onConfirm(displayValue);
    }
  }, [displayValue, onConfirm]);
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onCloseColorPicker}
      anchorOrigin={anchorOrigin ?? {
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={transformOrigin ?? {
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box sx={{ ...(onConfirm ? { p: 1, display: 'flex', flexDirection: 'column', gap: 1 } : {}) }}>
        {!!onReset && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Button variant="outlined" size="small" onClick={onReset}>{t('color-picker_reset-button-label', 'Reset')}</Button>
          </Box>
        )}
        <SketchPicker color={displayValue} onChangeComplete={onChangeSketchPickerComplete} />
        {!!onConfirm && !!onCancel && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
            <Button variant="contained" color="primary" size="small" onClick={onClickConfirm}>{t('color-picker_confirm-button-label', 'Confirm')}</Button>
            <Button variant="contained" color="secondary" size="small" onClick={onCancel}>{t('color-picker_cancel-button-label', 'Cancel')}</Button>
          </Box>
        )}
      </Box>
    </Popover>
  );
}
