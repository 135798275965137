import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useProjectCreateMutation from 'projects/hooks/useProjectCreateMutation';
import ProjectCreateDto from 'projects/types/ProjectCreateDto';
import WorkPhaseKind from 'projects/types/WorkPhaseKind';

interface CreateProjectDialogProps {
  open: boolean,
  onClose: (confirmed: boolean) => void,
}

export default function CreateProjectDialog({
  open,
  onClose,
}: CreateProjectDialogProps) {
  const { t } = useTranslation('projects');

  const [newProjectName, setNewProjectName] = useState<string | undefined>(undefined);
  const onChangeNewProjectName = useCallback((event: ChangeEvent<HTMLInputElement>) => setNewProjectName(event.target.value), []);
  const [newProjectAbbreviation, setNewProjectAbbreviation] = useState<string | undefined>(undefined);
  const onChangeNewProjectAbbreviation = useCallback((event: ChangeEvent<HTMLInputElement>) => setNewProjectAbbreviation(event.target.value), []);
  const [newProjectWorkPhaseKind, setNewProjectWorkPhaseKind] = useState<WorkPhaseKind>(WorkPhaseKind.Hoai);
  const onChangeNewProjectWorkPhaseKind = useCallback((event: ChangeEvent<HTMLInputElement>) => setNewProjectWorkPhaseKind(Number.parseInt(event.target.value, 10) as WorkPhaseKind), []);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const getRequestErrorMessage = useRequestErrorMessage();

  const { mutateAsync: mutateProjectCreateAsync, isPending } = useProjectCreateMutation();

  const onClickCancel = useCallback(() => {
    onClose(false);
  }, [onClose]);

  const canConfirm = useMemo(() => (
    newProjectName?.trim().length
    && newProjectAbbreviation?.trim().length
  ), [newProjectName, newProjectAbbreviation]);

  const onClickConfirm = useCallback(async () => {
    if (!newProjectName?.trim() || !newProjectAbbreviation?.trim()) return;
    try {
      const projectCreateDto: ProjectCreateDto = {
        name: newProjectName.trim(),
        abbreviation: newProjectAbbreviation.trim(),
        workPhaseKind: newProjectWorkPhaseKind,
      };
      await mutateProjectCreateAsync(projectCreateDto);
      onClose(true);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [newProjectName, newProjectAbbreviation, newProjectWorkPhaseKind, mutateProjectCreateAsync, onClose, getRequestErrorMessage]);

  return (
    <Dialog
      id="CreateProjectDialog"
      open={open}
      PaperProps={{ sx: { maxWidth: 'unset', minWidth: 480 } }}
    >
      <DialogTitle component="div" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="h2">
          {t('create-project-dialog_title', 'Create Project')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label={t('create-project-dialog_new-project-name-label', 'New Project Name')}
            id="create-project-dialog_new-project-name"
            value={newProjectName ?? ''}
            onChange={onChangeNewProjectName}
            sx={{ flex: '1 1 0' }}
          />
          <TextField
            label={t('create-project-dialog_new-project-abbreviation-label', 'New Project Abbreviation')}
            id="create-project-dialog_new-project-abbreviation"
            value={newProjectAbbreviation ?? ''}
            onChange={onChangeNewProjectAbbreviation}
          />
          <Typography variant="h5" id="create-project-dialog_new-project-workphase-kind-label">{t('create-project-dialog_new-project-workphase-kind-label', 'Work Phases')}</Typography>
          <FormControl>
            <RadioGroup
              aria-labelledby="create-project-dialog_new-project-workphase-kind-label"
              value={newProjectWorkPhaseKind}
              onChange={onChangeNewProjectWorkPhaseKind}
              name="create-project-dialog_new-project-workphase-kind-radiogroup"
            >
              <FormControlLabel value={WorkPhaseKind.Hoai} control={<Radio />} label={t('create-project-dialog_new-project-workphase-kind-hoai', 'HOAI 2021')} />
              <FormControlLabel value={WorkPhaseKind.Sia} control={<Radio />} label={t('create-project-dialog_new-project-workphase-kind-sia', 'SIA 102')} />
              <FormControlLabel value={WorkPhaseKind.Riba} control={<Radio />} label={t('create-project-dialog_new-project-workphase-kind-riba', 'RIBA Plan of Work')} />
              <FormControlLabel value={WorkPhaseKind.None} control={<Radio />} label={t('create-project-dialog_new-project-workphase-kind-none', 'None')} />
            </RadioGroup>
          </FormControl>
        </Box>
        {errorMessage && <Alert onClose={onCloseErrorMessage} severity="error" sx={{ width: '100%', mt: 2 }}>{errorMessage}</Alert>}
      </DialogContent>
      <DialogActions sx={{ gap: 2 }}>
        <Button onClick={onClickCancel} variant="contained" color="secondary">{t('create-project-dialog_cancel-button-label', 'Cancel')}</Button>
        <Button onClick={onClickConfirm} variant="contained" color="primary" sx={{ ml: 'auto' }} disabled={isPending || !canConfirm}>
          {isPending && <CircularProgress size={12} sx={{ mr: 1 }} />}
          {t('create-project-dialog_confirm-button-label', 'Create Project')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
