import { mdiSelectSearch } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Button, IconButton, Popover, useTheme } from '@mui/material';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useLabelIcons from 'settings/hooks/usLabelIcons';

type IssueSettingsIconPickerProps = {
  icon?: string,
  onChange: (icon: string) => void,
};

export default function IssueSettingsIconPicker({
  icon,
  onChange,
}: IssueSettingsIconPickerProps) {
  const { t } = useTranslation('settings');
  const theme = useTheme();

  const [open, setOpen] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { defaultIcons, otherIcons } = useLabelIcons();
  const defaultIconsKeyValuePairs = useMemo(() => Array.from(defaultIcons.entries()), [defaultIcons]);
  const otherIconsKeyValuePairs = useMemo(() => Array.from(otherIcons.entries()), [otherIcons]);

  const iconPath = useMemo(() => {
    if (!icon) return undefined;
    return defaultIcons.get(icon) ?? otherIcons.get(icon);
  }, [defaultIcons, icon, otherIcons]);

  const iconPathDisplayValue = useMemo(() => iconPath ?? mdiSelectSearch, [iconPath]);

  const handleIconClick = useCallback((iconName: string) => {
    onChange(iconName);
  }, [onChange]);

  const onClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Button ref={buttonRef} onClick={onClick} variant="outlined" sx={{ gap: 1, pl: 0.75, minWidth: 'fit-content' }}>
        <Box sx={{ width: 24, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '2px', border: `1px solid ${theme.palette.divider}` }}>
          <Icon path={iconPathDisplayValue} size={1} />
        </Box>
        {t('issue-settings-icon-picker_button-label', 'Icon')}
      </Button>
      <Popover
        id="IssueSettingsIconPicker"
        open={open}
        anchorEl={buttonRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <Box p={1} sx={{ maxWidth: '220px', overflowY: 'scroll', scrollbarWidth: 'none', border: '1px solid', borderRadius: 1, borderColor: '#c4c4c4' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box pl={1} py={1} sx={{ fontWeight: 'bold' }}>{t('issue-settings-icon-picker_standard-icons-section-header', 'Standard Icons')}</Box>
            <Box>
              {defaultIconsKeyValuePairs.map(([name, path]) => (
                <IconButton
                  key={name}
                  color={icon === name ? 'primary' : 'default'}
                  onClick={() => handleIconClick(name)}
                >
                  <Icon size={1} path={path} />
                </IconButton>
              ))}
            </Box>
          </Box>
          <hr />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box pl={1} py={1} sx={{ fontWeight: 'bold' }}>{t('issue-settings-icon-picker_other-icons-section-header', 'Other')}</Box>
            <Box>
              {otherIconsKeyValuePairs.map(([name, path]) => (
                <IconButton
                  key={name}
                  color={icon === name ? 'primary' : 'default'}
                  onClick={() => handleIconClick(name)}
                >
                  <Icon size={1} path={path} />
                </IconButton>
              ))}
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  );
}
