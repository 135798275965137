import React, {
  Suspense, useCallback, useRef, useState,
} from 'react';
import { Box, Button, useTheme } from '@mui/material';
import { ICellRendererParams } from '@ag-grid-community/core';
import DocumentsDataGridRow from 'documents/types/DocumentDataGridRow';
import IssuesFilterableListMenu from 'issues/components/IssuesFilterableListMenu';
import Color from 'color';
import { ITEM_ROOT } from 'odata-query';
import useDocumentDetailsSelectionContext from 'documents-details/hooks/useDocumentDetailsSelectionContext';

export default function LinkedIssuesCell({
  data: row,
}: ICellRendererParams<DocumentsDataGridRow, string[] | undefined>) {
  const theme = useTheme();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { setDocumentVersionAndIssueId } = useDocumentDetailsSelectionContext();
  const onClickIssueItem = useCallback((issueId: string) => {
    if (!row) throw new Error('Row data missing.');
    setDocumentVersionAndIssueId({ documentVersionId: row.id, issueId });
    setIsMenuOpen(false);
  }, [row, setDocumentVersionAndIssueId]);
  const onCloseMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);
  return row?.documentLinkedIssueIds?.length ? (
    <>
      <Box sx={{ textAlign: 'center' }}>
        <Button
          ref={buttonRef}
          variant="contained"
          color="secondary"
          onClick={() => setIsMenuOpen(true)}
          sx={{
            backgroundColor: Color(theme.palette.info.main).lightness(92).hex(),
            fontWeight: 600,
            height: '24px',
            minWidth: 'unset',
            px: 1,
            py: 0,
          }}
        >
          {`${row?.documentLinkedIssueIds.length ?? ''}`}
        </Button>
      </Box>
      <Suspense>
        {!!row && (
          <IssuesFilterableListMenu
            issuesOdataQuery={isMenuOpen ? { filter: { linkedDocuments: { any: { [ITEM_ROOT]: row.normalizedName } } } } : undefined}
            totalIssueCount={row?.documentLinkedIssueIds.length}
            open={isMenuOpen}
            onClose={onCloseMenu}
            anchorEl={buttonRef.current}
            sx={{ minWidth: '300px', maxWidth: '500px' }}
            onClickItem={onClickIssueItem}
          />
        )}
      </Suspense>
    </>
  ) : null;
}
