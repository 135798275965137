import React, { useMemo } from 'react';
import { Box, Button, ButtonBase, CircularProgress, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ViewpointDto from 'issues/types/ViewpointDto';
import Icon from '@mdi/react';
import { mdiPencil } from '@mdi/js';
import useIssueViewpointItems from 'issues/hooks/useIssueViewpointItems';
import useIssueEditingRoleRight from 'issues/hooks/useIssueEditingRoleRight';
import useClosedIssueStatus from 'issues/hooks/useClosedIssueStatus';
import useIssueQuery from 'issues/hooks/useIssueQuery';

interface IssueLinkedViewpointsPanelProps {
  issueId: string,
  onClickViewpointItem?: (viewpoint: ViewpointDto) => void,
  onClickEdit?: () => void,
}

export default function IssueLinkedViewpointsPanel({
  issueId,
  onClickViewpointItem,
  onClickEdit,
}: IssueLinkedViewpointsPanelProps) {
  const { t } = useTranslation('issues');
  const theme = useTheme();
  const { data: issue } = useIssueQuery(issueId);
  const viewpointItems = useIssueViewpointItems(issueId, onClickViewpointItem);
  const canEdit = useIssueEditingRoleRight(issueId);

  const closedIssueStatus = useClosedIssueStatus();
  const editButtonDisabled = useMemo(() => issue?.issueStatus === closedIssueStatus?.id, [closedIssueStatus, issue?.issueStatus]);

  return (
    <Box id="IssueLinkedViewpointsPanel">
      <Box sx={{
        backgroundColor: theme.palette.background.default,
        borderRadius: '8px',
        boxShadow: '0px 1px 4px -1px rgba(0,0,0,0.3)',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
      >
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-between' }}>
          <Typography variant="h4">
            {t('issue-linked-viewpoints-panel_title', 'Viewpoints')}
            {!!viewpointItems && ` (${viewpointItems.length})`}
            {!viewpointItems && <CircularProgress size={12} sx={{ ml: 1 }} />}
          </Typography>
          {!!onClickEdit && !!canEdit && (
            <Button onClick={onClickEdit} variant="contained" color="secondary" disabled={editButtonDisabled} sx={{ gap: 0.5, pl: 0.5 }} size="small">
              <Icon path={mdiPencil} size={0.75} />
              {t('issue-linked-viewpoints-panel_edit-button-label', 'Edit')}
            </Button>
          )}
        </Box>
        {!!viewpointItems?.length && (
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, flexWrap: 'wrap' }}>
            {viewpointItems.map(({ viewpoint, imageDataUrl, onClick }) => (
              <ButtonBase
                key={viewpoint.id}
                onClick={onClick}
                disabled={!onClick}
                sx={{ width: '96px', height: '96px', borderRadius: '8px', backgroundColor: theme.palette.grey[300], fontSize: 14, fontWeight: 700 }}
              >
                <img src={imageDataUrl} style={{ width: '96px', height: '96px', borderRadius: '8px', objectFit: 'cover' }} alt={viewpoint.imageName} />
              </ButtonBase>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}
