import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import QueryFilterUpdateDto from 'query-filters/types/QueryFilterUpdateDto';
import QueryFilterKind from 'query-filters/types/QueryFilterKind';
import QueryFilterEndpointMap from 'query-filters/types/QueryFilterEndpointMap';

export default function useQueryFilterUpdateMutation<TQueryFilterKind extends QueryFilterKind>(endpoint: QueryFilterEndpointMap[TQueryFilterKind]) {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases } = useDefaultEntityQueryKeys(endpoint);
  type TQueryFilterUpdateDto = QueryFilterUpdateDto<TQueryFilterKind>;
  return useMutation<void, unknown, TQueryFilterUpdateDto>({
    mutationFn: async (dto: TQueryFilterUpdateDto) => {
      await axiosInstance.patch<boolean>(endpoint, dto);
    },
    onSuccess: async () => {
      await Promise.all(queryKeyBases.map((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase })));
    },
  });
}
