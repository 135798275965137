import type { Theme, ComponentsProps, ComponentsVariants } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

interface MuiTypographyComponent {
  defaultProps?: ComponentsProps['MuiTypography'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTypography'];
  variants?: ComponentsVariants['MuiTypography'];
}

const VisoTypography: MuiTypographyComponent = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontSize: theme.typography.fontSize,
      '&.MuiTypography-h1': {
        fontSize: 25,
        fontWeight: 700,
      },
      '&.MuiTypography-h2': {
        fontSize: 22,
        fontWeight: 700,
      },
      '&.MuiTypography-h3': {
        fontSize: 20,
        fontWeight: 700,
      },
      '&.MuiTypography-h4': {
        fontSize: 18,
        fontWeight: 700,
      },
      '&.MuiTypography-h5': {
        fontSize: 16,
        fontWeight: 600,
      },
      '&.MuiTypography-caption': {
        fontWeight: 600,
      },
    }),
  },
};

export default VisoTypography;
