import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Backdrop, Box, Button, CircularProgress, Modal, TablePagination, Typography } from '@mui/material';
import VerticalDivider from 'common/styled/VerticalDivider';
import IssuePrintOptionsDrawer from 'issues/components/IssuePrintOptionsDrawer';
import IssueDetailsPrint from 'issues/components/IssueDetailsPrint';
import theme from 'theme';
import { CloseIcon } from 'yet-another-react-lightbox';
import IssueTablePrintPreview from 'issues/components/IssueTablePrintPreview';
import IssuePrintContext from 'issues/contexts/IssuePrintContext';
import useIssuesOdataQuery from 'issues/hooks/useIssuesOdataQuery';
import { Trans, useTranslation } from 'react-i18next';
import IssueDetailsPrintPreview from 'issues/components/IssueDetailsPrintPreview';
import { useReactToPrint } from 'react-to-print';
import IssueTablePrint from 'issues/components/IssueTablePrint';
import { useNavigate } from 'react-router-dom';
import useIssuesIdsQuery from 'issues/hooks/useIssuesIdsQuery';
import useIssuesFilterContext from 'issues/hooks/useIssuesFilterContext';

export default function IssuePrintViewModal() {
  const { odataQuery } = useIssuesFilterContext();
  const navigate = useNavigate();
  const numberIssuesToRequest = 1000;
  const { data: issueIds } = useIssuesIdsQuery(odataQuery);
  const issuesCount = useMemo(() => issueIds?.length, [issueIds]);
  const totalPages = useMemo(() => (issuesCount ? Math.ceil((issuesCount / numberIssuesToRequest)) : 0), [issuesCount]);
  const [page, setPage] = useState(0);

  const updatedOdataQuery = useMemo(() => ({
    ...odataQuery,
    top: numberIssuesToRequest,
    skip: page * numberIssuesToRequest,
  }), [odataQuery, numberIssuesToRequest, page]);
  const { data: issues } = useIssuesOdataQuery(updatedOdataQuery);

  const { issuesDocument, gridTotalPages, isPrinting, setIsPrinting } = useContext(IssuePrintContext);
  const printRef = useRef();
  const numberOfIssuesDisplayed = useMemo(() => (issuesCount ? (issuesCount > numberIssuesToRequest ? numberIssuesToRequest : issuesCount) : 0), [issuesCount]);
  const { t } = useTranslation('issues');

  const [numberOfGridInstancesLoaded, setNumberOfGridInstancesLoaded] = useState(0);
  const [numberOfDetailInstancesLoaded, setNumberOfDetailInstancesLoaded] = useState(0);

  const handlePrint = useReactToPrint({
    content: () => printRef.current || null,
    onAfterPrint: () => setIsPrinting(false),
  });

  const handlePageChange = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  }, []);

  const onFinishedLoadingTable = useCallback(() => {
    setNumberOfGridInstancesLoaded((prev) => prev + 1);
  }, []);

  const onFinishedLoadingDetails = useCallback(() => {
    setNumberOfDetailInstancesLoaded((prev) => prev + 1);
  }, []);

  // waits for dom to load before printing
  useEffect(() => {
    if (isPrinting && issues && (numberOfGridInstancesLoaded >= gridTotalPages || numberOfDetailInstancesLoaded >= numberOfIssuesDisplayed)) {
      handlePrint();
      setNumberOfGridInstancesLoaded(0);
      setNumberOfDetailInstancesLoaded(0);
    }
  }, [isPrinting, handlePrint, onFinishedLoadingTable, numberOfGridInstancesLoaded, gridTotalPages, numberOfDetailInstancesLoaded, issues, numberOfIssuesDisplayed]);

  const onClose = useCallback(() => {
    navigate('../');
  }, [navigate]);

  return (
    <Modal open disablePortal>
      <Box
        id="IssuePrintViewModal"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          backgroundColor: theme.palette.background.default,
          overflow: 'scroll',
        }}
      >
        <Box
          sx={{
            flex: '0 1 0',
            display: 'flex',
            alignItems: 'center',
            p: 1,
            pl: 2,
            gap: 3,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: theme.palette.divider,
            boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.1)',
            position: 'sticky',
            top: 0,
          }}
          id="IssuePrintViewModalHeader"
        >
          <img src="/img/visoplan-logo.svg" alt="logo" />
          <VerticalDivider sx={{ alignSelf: 'stretch' }} />
          <Typography variant="h3">{t('issue-print-view-modal_title', 'Print Issues')}</Typography>
          <Alert severity="info" sx={{ margin: 'auto' }}>
            <Trans
              t={t}
              i18nKey="issue-print-view-modal_hint-message"
              components={{
                b: <b />,
              }}
              defaults="The Print View contains the <b>{{count}} issues</b> from the current filter result."
              count={issuesCount}
            />
          </Alert>
          <Button onClick={onClose} variant="outlined" sx={{ pl: 1, gap: 1, ml: 'auto' }}>
            <CloseIcon />
            {t('issue-print-view-modal_close-button-label', 'Close')}
          </Button>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '80%' }}>
          <TablePagination
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            component="div"
            count={numberIssuesToRequest * totalPages}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={numberIssuesToRequest}
            rowsPerPageOptions={[numberIssuesToRequest]}
            labelDisplayedRows={({ from }) => `${t('issue-print-view-modal_label-pagination', 'Printing issues')} ${from}-${numberOfIssuesDisplayed * (page + 1)} ${t('issue-print-view-modal_label-pagination-of', 'of')} ${issuesCount}`}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 2,
              py: 2,
              '@media print': {
                gap: 0,
                p: 0,
              },
            }}
            ref={printRef}
          >
            <style type="text/css" media="print">{'@page {size: portrait; margin: 0 0 0 0}'}</style>
            {!isPrinting && !issuesDocument.printTable && <IssueDetailsPrintPreview />}
            {!!issues && isPrinting && !issuesDocument.printTable && issues.map((issue, i) => <IssueDetailsPrint key={issue.id} issue={issue} pageNumber={i + 1} onFinishedLoading={onFinishedLoadingDetails} />)}
            {!!issues && !isPrinting && issuesDocument.printTable && <IssueTablePrintPreview issues={issues} pageNumber={0} />}
            {!!issues && isPrinting && issuesDocument.printTable && <IssueTablePrint issues={issues} onFinishedLoading={onFinishedLoadingTable} />}
          </Box>
          <Box sx={{ position: 'fixed', right: 0, width: '20%' }}>
            <IssuePrintOptionsDrawer handlePrint={() => setIsPrinting(true)} />
          </Box>
        </Box>
        <Backdrop open={isPrinting} sx={{ zIndex: 1000 }}><CircularProgress /></Backdrop>
      </Box>
    </Modal>
  );
}
