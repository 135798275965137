import IChildren from 'common/types/IChildren';
import IssuePrintContext, { IssuePrintContextState } from 'issues/contexts/IssuePrintContext';
import IssuePrintViewColumns from 'issues/types/IssuePrintViewColumns';
import IssuesDocument from 'issues/types/IssuesDocument';
import React, { useState, useMemo } from 'react';

const defaultColumnsIncludedInPrint: Set<IssuePrintViewColumns> = new Set([
  IssuePrintViewColumns.IssueNumber,
  IssuePrintViewColumns.Title,
  IssuePrintViewColumns.Creator,
  IssuePrintViewColumns.Created,
  IssuePrintViewColumns.Due,
  IssuePrintViewColumns.Start,
  IssuePrintViewColumns.Description,
]);

export default function IssuePrintContextProvider({
  children,
}: IChildren) {
  const [issuesDocument, setIssuesDocument] = useState<IssuesDocument>({ headerTitle: '', leftPageHead: '', rightPageHead: '', showPageNumbers: true, footerPosition: 'left', pageNumberPosition: 'right', printTable: true, headerHeight: 0 });

  const [columnsIncludedInPrint, setColumnsIncludedInPrint] = useState<Set<IssuePrintViewColumns>>(defaultColumnsIncludedInPrint);

  const [gridTotalPages, setGridTotalPages] = useState<number>(0);

  const [isPrinting, setIsPrinting] = useState(false);

  const value = useMemo<IssuePrintContextState>(() => ({
    issuesDocument,
    setIssuesDocument,
    columnsIncludedInPrint,
    setColumnsIncludedInPrint,
    gridTotalPages,
    setGridTotalPages,
    isPrinting,
    setIsPrinting,
  }), [issuesDocument, columnsIncludedInPrint, gridTotalPages, isPrinting]);

  return <IssuePrintContext.Provider value={value}>{children}</IssuePrintContext.Provider>;
}
