import React from "react";
import { useTranslation } from "react-i18next";

const AddMoreButton = ({ name, label, onClick }) => {
  const { t } = useTranslation();
  return (
    <a
      className="btn btn-setting-item"
      name={name ?? "add_more"}
      onClick={onClick}
    >
      <span>+</span>
      {label ? t(label) : t("add_more", "Add more")}
    </a>
  );
};

export default AddMoreButton;
