import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, TextField, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import CopyButton from 'common/components/CopyButton';
import ChangeProjectInboxEmailDialog from 'settings/components/ChangeProjectInboxEmailDialog';

export default function ProjectSettingsProjectEmailAddressSection() {
  const { t } = useTranslation('settings');
  const theme = useTheme();
  const { data: currentProject } = useCurrentProjectQuery();
  const projectEmailAddress = useMemo(() => currentProject?.inboxEmailAddress ?? '', [currentProject]);
  const [changeProjectInboxEmailDialogOpen, setChangeProjectInboxEmailDialogOpen] = useState(false);
  const onClickChangeProjectEmailAddress = useCallback(() => setChangeProjectInboxEmailDialogOpen(true), []);
  const onCloseChangeProjectInboxEmailDialog = useCallback(() => setChangeProjectInboxEmailDialogOpen(false), []);
  if (!currentProject) return null;
  return (
    <Box
      id="ProjectSettingsProjectEmailAddressSection"
      sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <Typography variant="h4">{t('project-settings-tab-pane_project-inbox-email-address-header', 'Project Email Address')}</Typography>
        <Typography>{t('project-settings-tab-pane_project-inbox-email-address-description', 'Emails sent to this address will be available in Visoplan\'s email view.')}</Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: 2, maxWidth: 600 }}>
        <TextField
          label={t('project-settings-tab-pane_textfield-label', 'Project Email Address')}
          sx={{ flexGrow: 1, backgroundColor: theme.palette.grey[100] }}
          title={projectEmailAddress}
          value={projectEmailAddress}
          InputProps={{
            readOnly: true,
            endAdornment: <CopyButton value={projectEmailAddress} />,
            style: { paddingRight: '4px' },
          }}
        />
        <Button variant="contained" color="primary" onClick={onClickChangeProjectEmailAddress}>{t('project-settings-tab-pane_project-inbox-email-address-change-button-label', 'Change')}</Button>
        {!!changeProjectInboxEmailDialogOpen && <ChangeProjectInboxEmailDialog onClose={onCloseChangeProjectInboxEmailDialog} />}
      </Box>
    </Box>
  );
}
