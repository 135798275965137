import { useCallback } from 'react';
import NamingSchemeGroupDto from 'naming-schemes/types/NamingSchemeGroupDto';
import NamingSchemeGroupElementDto from 'naming-schemes/types/NamingSchemeGroupElementDto';
import NamingSchemeGroupItem from 'naming-schemes/types/NamingSchemeGroupItem';

export default function useNamingSchemeItemToDtoConversion() {
  // group items and element items have an extra uuid property for handling in the local UI.
  // although it does not seem to affect the API behaviour, we want to stick to the contract
  // so we remove those extra props here.
  return useCallback((groupItem: NamingSchemeGroupItem) => {
    const groupDto: NamingSchemeGroupDto & { uuid?: string } = {
      ...groupItem,
      groupElements: groupItem.groupElements.map((elementItem) => {
        const elementItemDto: NamingSchemeGroupElementDto & { uuid?: string } = {
          ...elementItem,
        };
        delete elementItemDto.uuid;
        return elementItemDto;
      }),
    };
    delete groupDto.uuid;
    return groupDto;
  }, []);
}
