import React from 'react';
import { Input, OutlinedInput } from '@mui/material';
import TagCreateSelectInputComponent from 'tags/components/TagCreateSelectInputComponent';
import ISxProps from 'common/types/ISxProps';

interface TagCreateSelectProps extends ISxProps {
  id?: string,
  value: string[] | undefined,
  onChange: (tagIds: string[]) => void,
  disabled?: boolean,
  label?: string,
  variant?: 'outlined' | 'standard',
}

export default function TagCreateSelect({
  id,
  sx,
  value,
  onChange,
  disabled,
  label,
  variant,
}: TagCreateSelectProps) {
  const Component = variant === 'outlined' ? OutlinedInput : Input;
  return (
    <Component
      id={id}
      value={value}
      onChange={onChange as any} // MUI does not expect us to use a non HTML-input component here so we have to sneak around the event handler type check
      disabled={disabled}
      sx={{
        ...(variant === 'outlined' ? { pl: 1 } : {}),
        ...sx,
      }}
      label={label}
      slots={{
        input: TagCreateSelectInputComponent,
      }}
    />
  );
}
