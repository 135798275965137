import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import ColorPicker from 'common/components/ColorPicker';
import { useTranslation } from 'react-i18next';
import Color from 'color';

interface IssueSettingsColorPickerProps {
  color?: string,
  onChange: (color: string) => void,
}

export default function IssueSettingsColorPicker({
  color,
  onChange,
}: IssueSettingsColorPickerProps) {
  const { t } = useTranslation('settings');
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const onClickOpenColorPicker = useCallback(() => setOpen(true), []);
  const onCloseColorPicker = useCallback(() => setOpen(false), []);
  const backgroundColor = useMemo(() => Color(color).alpha(0.2).hexa(), [color]);
  return (
    <Box id="IssueSettingsColorPicker">
      <Button ref={buttonRef} onClick={onClickOpenColorPicker} variant="outlined" sx={{ gap: 1, pl: 0.75, backgroundColor }}>
        <Box sx={{ width: 24, height: 24, borderRadius: '2px', backgroundColor: color }} />
        {t('issue-settings-color-picker_button-label', 'Color')}
      </Button>
      {!!color && (
        <ColorPicker
          id="IssueSettingsIconPicker"
          open={open}
          color={color}
          anchorEl={buttonRef.current}
          onClose={onCloseColorPicker}
          onChange={onChange}
        />
      )}
    </Box>
  );
}
