import { BufferGeometry, InstancedMesh, Material, Matrix4 } from "three";
import { VisoMesh } from "./BaseDefinitions";

export class VisoInstanceMesh extends InstancedMesh implements VisoMesh {
  modelId: string;
  isOpening: boolean = false;
  isSpace: boolean = false;
  globalIds: Array<string>;

  constructor(
    geometry: BufferGeometry,
    material: Material | Material[],
    count: number,
    id: string
  ) {
    super(geometry, material, count);

    this.modelId = id;
    this.globalIds = new Array<string>(count);
  }

  setGlobalIdAt(index: number, id: string) {
    this.globalIds[index] = id;
  }

  getGlobalIdAt(index: number) {
    return this.globalIds[index];
  }

  getIndexOf(globalId: string) {
    return this.globalIds.findIndex((gId) => gId == globalId);
  }

  showAll() {
    this.count = this.globalIds.length;
  }

  hideAll() {
    this.count = 0;
  }

  setVisibilities(globalIds: string[], visible: boolean) {
    globalIds.forEach((id) => this.setVisibility(id, visible));
    this.instanceMatrix.needsUpdate = true;
  }

  // https://github.com/BF3RM/MapEditor/blob/development/WebUI/src/script/modules/InstanceManager.ts
  private setVisibility(globalId: string, visible: boolean) {
    const n = this.getIndexOf(globalId);
    if (n === -1) {
      return;
    }

    if (visible) {
      if (n < this.count) {
        // Already visible
      } else {
        this.swapInstances(this.count, n);
        this.count++;
      }
    } else {
      if (n < this.count) {
        this.swapInstances(this.count - 1, n);
        this.count--;
      }
    }
  }

  private swapInstances(index1: number, index2: number) {
    const cachedMatrix = new Matrix4();
    this.getMatrixAt(index1, cachedMatrix);
    const cachedGlobalId = this.getGlobalIdAt(index1);

    const matrix = new Matrix4();
    this.getMatrixAt(index2, matrix);
    this.setMatrixAt(index1, matrix);
    this.setGlobalIdAt(index1, this.getGlobalIdAt(index2));

    this.setMatrixAt(index2, cachedMatrix);
    this.setGlobalIdAt(index2, cachedGlobalId);
  }
}
