import React, { useCallback, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { Resizable } from 're-resizable';
import { Direction } from 're-resizable/lib/resizer';
import { Outlet } from 'react-router-dom';

const ISSUES_CALENDAR_DETAILS_SIDEBAR_DEFAULT_WIDTH_STORAGE_KEY = 'issues-calendar_details-sidebar-default-width';

export default function DashboardSidebar() {
  const theme = useTheme();
  const [detailsSidebarDefaultWidth, setDetailsSidebarDefaultWidth] = useState(() => {
    const storedWidth = localStorage.getItem(ISSUES_CALENDAR_DETAILS_SIDEBAR_DEFAULT_WIDTH_STORAGE_KEY);
    if (storedWidth?.length) {
      const parsedWidth = parseInt(storedWidth, 10);
      if (!Number.isNaN(parsedWidth)) return parsedWidth;
    }
    return 350;
  });
  const onResizeStopDetailsSidebar = useCallback((event: MouseEvent | TouchEvent, direction: Direction, elementRef: HTMLElement) => {
    localStorage.setItem(ISSUES_CALENDAR_DETAILS_SIDEBAR_DEFAULT_WIDTH_STORAGE_KEY, `${elementRef.clientWidth}`);
    setDetailsSidebarDefaultWidth(elementRef.clientWidth);
  }, []);
  return (
    <Resizable
      style={{ overflow: 'auto', backgroundColor: theme.palette.background.default, borderLeft: `1px solid ${theme.palette.divider}`, boxShadow: '0px 0px 16px -8px rgba(0,0,0,0.4)' }}
      enable={{ top: false, right: false, bottom: false, left: true, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
      defaultSize={{ width: detailsSidebarDefaultWidth, height: 'auto' }}
      onResizeStop={onResizeStopDetailsSidebar}
      minWidth="330px"
      handleComponent={{ left: <Box sx={{ height: '100%', width: '8px', backgroundColor: 'transparent' }}><Box sx={{ height: '100%' }} /></Box> }}
      handleStyles={{ left: { left: 0 } }}
    >
      <Outlet />
    </Resizable>
  );
}
