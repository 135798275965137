import React, { useState, useMemo, useCallback } from 'react';
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useRolesOdataQuery from 'collaborators/hooks/useRolesOdataQuery';
import RoleActionCreateDto from 'collaborators/types/RoleActionCreateDto';
import RoleCreateDto from 'collaborators/types/RoleCreateDto';
import useRoleCreateMutation from 'collaborators/hooks/useRoleCreateMutation';

interface CreateRoleDialogProps {
  onClose: () => void,
  onSuccess: (message: string) => void,
}

export default function CreateRoleDialog({
  onClose,
  onSuccess,
}: CreateRoleDialogProps) {
  const { t } = useTranslation('settings');
  const [name, setName] = useState<string>('');
  const { data: roles } = useRolesOdataQuery({});
  const existingRoleDefinitionNames = useMemo(() => (roles ? new Set<string>(roles.map((d) => d.name.trim())) : undefined), [roles]);
  const hasNamingConflict = useMemo(() => !!name.length && existingRoleDefinitionNames?.has(name.trim()), [existingRoleDefinitionNames, name]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const getRequestErrorMessage = useRequestErrorMessage();
  const { mutateAsync: mutateCreateRole, isPending: isLoadingCreateRole } = useRoleCreateMutation();
  const isLoadingUpdateProjectRoles = false;
  const isLoading = useMemo(() => isLoadingCreateRole || isLoadingUpdateProjectRoles, [isLoadingCreateRole, isLoadingUpdateProjectRoles]);
  const initialRoleActions = useMemo<RoleActionCreateDto[] | undefined>(() => roles?.find((d) => d.isAdmin)?.actions.map((a) => ({ ...a, isAllowed: true })), [roles]);

  const onClickConfirm = useCallback(async () => {
    if (!roles || !initialRoleActions) return;
    setErrorMessage(undefined);
    const persistRoleDefinitionDto: RoleCreateDto = {
      name,
      actions: initialRoleActions,
    };
    try {
      const { id: createdRoleId } = await mutateCreateRole(persistRoleDefinitionDto);
      if (!createdRoleId) throw new Error(t('create-role-dialog_server-error-message', 'Creating the project failed due to a server error'));
      onSuccess(t('create-role-dialog_success-message', 'Role "{{roleName}}" created.', { roleName: name }));
      setName('');
      onClose();
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [getRequestErrorMessage, initialRoleActions, mutateCreateRole, name, onClose, onSuccess, roles, t]);

  const onClickCancel = useCallback(() => {
    setErrorMessage(undefined);
    setName('');
    onClose();
  }, [onClose]);

  return (
    <Dialog id="CreateRoleDialog" open>
      <DialogTitle>{t('create-role-dialog_title', 'Create New Role')}</DialogTitle>
      <DialogContent sx={{ minHeight: '84px', width: '420px' }}>
        <Box sx={{ pt: 1 }}>
          <TextField
            fullWidth
            label={t('role-options-button-menu_create-textfield-label', 'New Role Name')}
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={hasNamingConflict}
            helperText={hasNamingConflict ? t('create-role-dialog_name-conflict-messge', 'A role with this name already exists') : undefined}
          />
          {!!errorMessage && <Alert sx={{ mt: 1 }} severity="error">{errorMessage}</Alert>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onClickCancel} disabled={isLoading}>{t('create-role-dialog_cancel-button-label', 'Cancel')}</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onClickConfirm}
          sx={{ ml: 2 }}
          disabled={!name.trim().length || hasNamingConflict || isLoading}
        >
          {isLoading && <CircularProgress size={12} sx={{ mr: 1 }} />}
          {t('create-role-dialog_confirm-button-label', 'Create Role')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
