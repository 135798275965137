import { useCallback, useMemo, useState } from 'react';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import { useTranslation } from 'react-i18next';
import useDocumentListUpdateDocumentsMutation from 'documents-lists/hooks/useDocumentListUpdateDocumentsMutation';
import DocumentListUpdateDocumentsDto from 'documents-lists/types/DocumentListUpdateDocumentsDto';
import useDocumentListQueryData from 'documents-lists/hooks/useDocumentListQueryData';

export default function useAddDocumentVersionsToPlanlist() {
  const { t } = useTranslation('documents-lists');
  const { mutateAsync, isPending } = useDocumentListUpdateDocumentsMutation();
  const getDocumentList = useDocumentListQueryData();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const getRequestErrorMessage = useRequestErrorMessage();
  const clearErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const clearSuccessMessage = useCallback(() => setSuccessMessage(undefined), []);

  const addDocumentVersionsToPlanlist = useCallback(async (documentVersionIds: string[], targetPlanlistId: string) => {
    try {
      const targetPlanlist = await getDocumentList(targetPlanlistId);
      const nextDocumentVersionIds = Array.from(new Set([...targetPlanlist.documentVersionIds, ...documentVersionIds]));
      const newDocumentVersionIdsCount = nextDocumentVersionIds.length - targetPlanlist.documentVersionIds.length;
      if (newDocumentVersionIdsCount > 0) {
        const updateDto: DocumentListUpdateDocumentsDto = {
          id: targetPlanlistId,
          documentVersionIds: nextDocumentVersionIds,
        };
        await mutateAsync(updateDto);
        let successFeedbackMessage = t('use-move-document-versions-to-planlist_success-message', '{{count}} documents have been added to the planlist "{{planlist}}".', { count: newDocumentVersionIdsCount, planlist: targetPlanlist.name });
        if (newDocumentVersionIdsCount < documentVersionIds.length) {
          successFeedbackMessage += ` ${t('use-move-document-versions-to-planlist_already-on-list-message', 'The remaining {{count}} documents already are on the planlist "{{planlist}}".', { count: documentVersionIds.length - newDocumentVersionIdsCount, planlist: targetPlanlist.name })}`;
        }
        setSuccessMessage(successFeedbackMessage);
      } else {
        setSuccessMessage(t('use-move-document-versions-to-planlist_already-on-list-all-message', 'All {{count}} dropped documents are already on the planlist "{{planlist}}.', { count: documentVersionIds.length, planlist: targetPlanlist.name }));
      }
    } catch (e) {
      const reason = getRequestErrorMessage(e) ?? 'Unknown';
      setErrorMessage(t('use-move-document-versions-to-planlist_documents-moved-error-message', 'Failed to move {{count}} documents. Reason: {{reason}}', { count: documentVersionIds.length, reason }));
    }
  }, [getDocumentList, getRequestErrorMessage, mutateAsync, t]);

  return useMemo(() => ({
    addDocumentVersionsToPlanlist,
    errorMessage,
    clearErrorMessage,
    successMessage,
    clearSuccessMessage,
    isPending,
  }), [addDocumentVersionsToPlanlist, errorMessage, clearErrorMessage, successMessage, clearSuccessMessage, isPending]);
}
