import { createTheme } from '@mui/material/styles';
import typography from './typography';
import components from './components';
import palette from './palette';

const visoTheme = createTheme({
  typography,
  components,
  palette,
});

export default visoTheme;
