import React, { useMemo } from 'react';
import { Box, Button } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import { useTranslation } from 'react-i18next';
import useCurrentCollaboratorQuery from 'collaborators/hooks/useCurrentCollaboratorQuery';
import Icon from '@mdi/react';
import { mdiCheck, mdiRewind } from '@mdi/js';
import useInReviewIssuesStatus from 'issues/hooks/useInReviewIssueStatus';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';
import RoleAction from 'projects/types/RoleAction';

interface ReviewActionButtonsProps extends ISxProps {
  issueId: string,
  onClickRejectReview: () => void,
  onClickApproveReview: () => void,
}

export default function ReviewActionButtons({
  sx,
  issueId,
  onClickRejectReview,
  onClickApproveReview,
}: ReviewActionButtonsProps) {
  const { t } = useTranslation('issues');
  const { data: currentCollaborator } = useCurrentCollaboratorQuery();
  const { data: issue, isLoading: isLoadingIssue } = useIssueQuery(issueId);
  const isCurrentUserReviewer = useMemo(() => (issue?.reviewerId && currentCollaborator && issue.reviewerId === currentCollaborator.id), [currentCollaborator, issue]);
  const inReviewIssueStatus = useInReviewIssuesStatus();
  const allowedActions = useAllowedActions();

  const canDoReviewActions = useMemo(() => {
    if (!issue?.issueStatus || !inReviewIssueStatus) return undefined;
    return issue.issueStatus === inReviewIssueStatus.id && isCurrentUserReviewer;
  }, [isCurrentUserReviewer, issue, inReviewIssueStatus]);

  const canApproveReview = useMemo(() => allowedActions?.has(RoleAction.IssueManagement_StatusEditing_SetClosed), [allowedActions]);

  console.log(!canDoReviewActions);
  if (!issueId || !canDoReviewActions) return null;
  return (
    <Box id="ReviewActionButtons" sx={{ ...sx, display: 'flex', gap: 1 }}>
      <Button
        variant="contained"
        color="secondary"
        onClick={onClickRejectReview}
        disabled={isLoadingIssue}
        sx={{ gap: 1 }}
        size="small"
      >
        <Icon path={mdiRewind} size={0.5} />
        {t('review-action-buttons_reject-review', 'Reopen')}
      </Button>
      {!!canApproveReview && (
        <Button
          variant="contained"
          color="secondary"
          onClick={onClickApproveReview}
          disabled={isLoadingIssue}
          sx={{ gap: 1 }}
          size="small"
        >
          <Icon path={mdiCheck} size={0.5} />
          {t('review-action-buttons_accept-review', 'Complete')}
        </Button>
      )}
    </Box>
  );
}
