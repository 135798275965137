import { createContext } from 'react';

export interface DocumentDetailsSelectionChange {
  issueId?: string | null | undefined, // undefined = leave unchanged, null = close issue
  documentVersionId?: string | null | undefined, // undefined = leave unchanged, null = close document
}

export interface DocumentDetailsSelectionContextState {
  documentVersionId: string | undefined,
  setDocumentVersionId: (value: string | undefined) => void,
  issueId: string | undefined,
  setIssueId: (value: string | undefined) => void,
  setDocumentVersionAndIssueId: (change: DocumentDetailsSelectionChange) => void,
  queryFilterId: string | undefined,
  setQueryFilterId: (value: string | undefined) => void,
  highlightedDocumentCommentId: string | undefined,
  setHighlightedDocumentCommentId: (nextDocumentCommentId: string | undefined) => void,
}

const defaultValue: DocumentDetailsSelectionContextState = {
  documentVersionId: undefined,
  setDocumentVersionId: () => {},
  issueId: undefined,
  setIssueId: () => {},
  setDocumentVersionAndIssueId: () => {},
  highlightedDocumentCommentId: undefined,
  setHighlightedDocumentCommentId: () => {},
  queryFilterId: undefined,
  setQueryFilterId: () => {},
};

const DocumentDetailsSelectionContext = createContext<DocumentDetailsSelectionContextState>(defaultValue);
export default DocumentDetailsSelectionContext;
