import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import UpdateLabelDto from 'labels/types/UpdateLabelDto';
import LabelDto from 'labels/types/LabelDto';
import { useCallback } from 'react';

export default function useLabelUpdateMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.Label);
  const mutationFn = useCallback(async (dto: UpdateLabelDto) => {
    const { data: label } = await axiosInstance.put<LabelDto>(`${ApiEndpoint.Label}`, dto);
    return label;
  }, [axiosInstance]);
  const onSuccess = useCallback(() => {
    queryKeyBases.forEach((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
  }, [queryClient, queryKeyBases]);
  return useMutation<LabelDto, unknown, UpdateLabelDto>({
    mutationFn,
    onSuccess,
  });
}
