import { Editor } from '@tiptap/react';
import { Dispatch, SetStateAction, createContext } from 'react';

export interface RichTextEditorContextState {
  editor: Editor | null,
  isFocused: boolean,
  isDisabled: boolean,
  text: string,
  setIsDisabled: Dispatch<SetStateAction<boolean>>;
}

const defaultState: RichTextEditorContextState = {
  editor: null,
  isFocused: false,
  isDisabled: false,
  text: '',
  setIsDisabled: () => {},
};
const RichTextEditorContext = createContext<RichTextEditorContextState>(defaultState);

export default RichTextEditorContext;
