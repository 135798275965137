import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import Icon from '@mdi/react';
import { mdiPencil } from '@mdi/js';
import ISxProps from 'common/types/ISxProps';
import CreateIssuePanel from 'issues/components/CreateIssuePanel';
import ViewpointListItem from 'issues/components/ViewpointListItem';
import useModelsInteractionContext from 'models/hooks/useModelsInteractionContext';
import { ModelsInteractionMode } from 'models/contexts/ModelsInteractionContext';

interface CreateIssueLinkedViewpointsPanelProps extends ISxProps {
}

export default function CreateIssueLinkedViewpointsPanel({
  sx,
}: CreateIssueLinkedViewpointsPanelProps) {
  const { t } = useTranslation('issues');
  const { setInteractionMode, viewpointItems } = useModelsInteractionContext();
  const viewpointListItems = useMemo(() => viewpointItems.map((item) => ({
    id: item.uuid,
    imageDataUrl: item.imageDataUrl,
  })), [viewpointItems]);
  const onClickManageViewpoints = useCallback(() => {
    setInteractionMode(ModelsInteractionMode.ViewpointManagement);
  }, [setInteractionMode]);
  return (
    <CreateIssuePanel id="CreateIssueLinkedViewpointsPanel" sx={sx}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography variant="h4">
          {t('create-issue-linked-viewpoints-panel_viewpoints-panel-header', 'Viewpoints')}
        </Typography>
        <Button onClick={onClickManageViewpoints} variant="contained" color="secondary" sx={{ gap: 0.5, pl: 0.5 }} size="small">
          <Icon path={mdiPencil} size={0.75} />
          {t('create-issue-linked-viewpoints-panel_create-viewpoint-button-label', 'Edit')}
        </Button>
      </Box>
      <Box sx={{ pt: 1, display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
        {viewpointListItems.map((item) => (
          <ViewpointListItem
            key={item.id}
            viewpointItem={item}
            sx={{ flexShrink: 0, height: 100 }}
          />
        ))}
      </Box>
    </CreateIssuePanel>
  );
}
