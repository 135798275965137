import React, { useContext, useMemo } from 'react';
import IssueDto from 'issues/types/IssueDto';
import { Box, Paper } from '@mui/material';
import IssuePrintContext from 'issues/contexts/IssuePrintContext';
import IssuePrintDataGrid from 'issues/components/IssuePrintDataGrid';
import PrintPreviewFooter from 'issues/components/PrintPreviewFooter';
import PrintPreviewHeader from 'issues/components/PrintPreviewHeader';

interface IssueTablePrintProps {
  issues: IssueDto[],
  onFinishedLoading: () => void,
}

export default function IssueTablePrint({ issues, onFinishedLoading }: IssueTablePrintProps) {
  const { gridTotalPages } = useContext(IssuePrintContext);
  const uniqueKeys = useMemo(() => ([...Array(gridTotalPages)].map(() => crypto.randomUUID())), [gridTotalPages]);
  return (
    <Box>
      {uniqueKeys.map((key, i) => (
        <Paper
          sx={{ width: '210mm',
            height: '297mm',
            overflow: 'hidden',
            p: 2,
            '@media print': {
              width: '210mm',
              height: '297mm',
              pageBreakInside: 'avoid',
              pageBreakAfter: 'always',
            } }}
          elevation={3}
          key={key}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%', height: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <PrintPreviewHeader />
              <Box sx={{ height: '250mm', width: '200mm' }}>
                <IssuePrintDataGrid rowData={issues} pageNumber={i} onFinishedLoading={onFinishedLoading} />
              </Box>
            </Box>
            <PrintPreviewFooter pageNumber={i + 1} />
          </Box>
        </Paper>
      ))}
    </Box>
  );
}
