import React, { useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

export enum LegacyDiscardChangesDialogVariant {
  DiscardModelSettings = 'discard-model-settings',
  DiscardUnsavedSettingsChanges = 'discard-unsaved-settings',
}

export enum LegacyDiscardChangesDialogResult {
  Cancel = 'cancel',
  Discard = 'discard',
}

interface LegacyDiscardChangesDialogProps {
  variant: LegacyDiscardChangesDialogVariant,
  toPath: string,
  onClose: (result: LegacyDiscardChangesDialogResult, toPath: string) => void,
}

export default function LegacyDiscardChangesDialog({
  variant,
  toPath,
  onClose,
}: LegacyDiscardChangesDialogProps) {
  const { t } = useTranslation('projects');
  const onClickDiscard = useCallback(() => {
    onClose(LegacyDiscardChangesDialogResult.Discard, toPath);
  }, [onClose, toPath]);
  const onClickCancel = useCallback(() => {
    onClose(LegacyDiscardChangesDialogResult.Cancel, toPath);
  }, [onClose, toPath]);
  return (
    <Dialog open id="LegacyDiscardChangesDialog">
      <DialogTitle>
        {variant === LegacyDiscardChangesDialogVariant.DiscardUnsavedSettingsChanges && (
          t('confirm-discard-changes-dialog_title', 'Discard unsaved changes?')
        )}
        {variant === LegacyDiscardChangesDialogVariant.DiscardModelSettings && (
          t('confirm-discard-changes-dialog_model-settings-title', 'Are you sure?')
        )}
      </DialogTitle>
      <DialogContent sx={{ lineHeight: 2 }}>
        {variant === LegacyDiscardChangesDialogVariant.DiscardUnsavedSettingsChanges && (
          t('confirm-discard-changes-dialog_message', 'There are unsaved changes on the current settings tab. If you navigate away, those changes will be lost. Do you want to continue?')
        )}
        {variant === LegacyDiscardChangesDialogVariant.DiscardModelSettings && (
          t('confirm-discard-changes-dialog_model-settings-message', 'When you leave the 3D Viewer, your current model selection will be lost. Do you really want to leave the viewer?')
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onClickDiscard} sx={{ mr: 'auto' }}>
          {variant === LegacyDiscardChangesDialogVariant.DiscardUnsavedSettingsChanges && (
            t('confirm-discard-changes-dialog_confirm-button-label', 'Leave and discard changes')
          )}
          {variant === LegacyDiscardChangesDialogVariant.DiscardModelSettings && (
            t('confirm-discard-changes-dialog_model-settings-confirm-button-label', 'Leave and discard selection')
          )}
        </Button>
        <Button variant="contained" color="primary" onClick={onClickCancel}>
          {variant === LegacyDiscardChangesDialogVariant.DiscardUnsavedSettingsChanges && (
            t('confirm-discard-changes-dialog_cancel-button-label', 'Stay and keep changes')
          )}
          {variant === LegacyDiscardChangesDialogVariant.DiscardModelSettings && (
            t('confirm-discard-changes-dialog_model-settings-cancel-button-label', 'Stay and keep selection')
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
