import React from 'react';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import SuspenseWithErrorBoundary from 'error/SuspenseWithErrorBoundary';
import IChildren from 'common/types/IChildren';

interface PageWrapperProps extends IChildren {
  id?: string | undefined,
}

export default function PageWrapper({
  children,
  id,
}: PageWrapperProps) {
  return (
    <main className="main" id={id}>
      <SuspenseWithErrorBoundary suspenseFallback={<CenteredCircularProgress />}>
        {children}
      </SuspenseWithErrorBoundary>
    </main>
  );
}
