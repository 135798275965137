import React, { useMemo } from 'react';
import { Chip, useTheme } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import Color from 'color';

interface ColoredChipProps extends ISxProps {
  entity: { name: string, color?: string },
}

export default function ColoredChip({
  entity,
  sx,
}: ColoredChipProps) {
  const theme = useTheme();
  const color = useMemo(() => Color(entity.color ?? theme.palette.grey[700]), [entity.color, theme.palette.grey]);
  return (
    <Chip
      label={`${entity.name}`}
      size="small"
      sx={{
        color: color.hex(), WebkitTextFillColor: color.hex(), backgroundColor: color.alpha(0.18).hexa(), ...sx,
      }}
    />
  );
}
