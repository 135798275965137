import { useCallback, useMemo } from 'react';
import useDynamicLayoutQuery from 'dynamic-layout/hooks/useDynamicLayoutQuery';
import useDynamicLayoutUpsertMutation from 'dynamic-layout/hooks/useDynamicLayoutUpsertMutation';
import DynamicLayoutKey from 'dynamic-layout/types/DynamicLayoutKey';
import UpsertDynamicLayoutDto from 'dynamic-layout/types/UpsertDynamicLayoutDto';

export default function useDynamicLayout(key: DynamicLayoutKey, columnNamesForcedHidden?: Set<string> | undefined, columnNamesForcedVisible?: Set<string> | undefined, legacyColumnNameMap?: Map<string, string>) {
  const { data: dynamicLayout, isLoading: isLoadingQuery } = useDynamicLayoutQuery(key);
  const columns = useMemo(() => {
    if (!dynamicLayout) return undefined;
    const filteredColumns = dynamicLayout.columns.map((column) => ({ ...column, name: legacyColumnNameMap?.get(column.name) ?? column.name })).filter((column) => column.name.length > 0 && column.width > 0);
    const filteredColumnNamesSet = new Set(filteredColumns.map((f) => f.name));
    const missingColumnsForcedVisible = Array.from(columnNamesForcedVisible ?? []).filter((n) => !filteredColumnNamesSet.has(n)).map((n) => ({ name: n, width: 200 }));
    return [...missingColumnsForcedVisible, ...filteredColumns];
  }, [columnNamesForcedVisible, dynamicLayout, legacyColumnNameMap]);
  const columnWidthsByColumnName = useMemo(() => (columns ? new Map<string, number>(columns.map((c) => [c.name, c.width])) : undefined), [columns]);
  const orderedVisibleColumnNames = useMemo(() => columns?.map((c) => c?.name).filter((name) => name && !columnNamesForcedHidden?.has(name)), [columnNamesForcedHidden, columns]);
  const { mutateAsync, isPending: isLoadingMutation } = useDynamicLayoutUpsertMutation(key);
  const persistAsync = useCallback(async (items: { fieldName: string, width: number }[]) => {
    const upsertDto: UpsertDynamicLayoutDto = {
      key,
      columns: items.map((c) => ({ name: c.fieldName, width: c.width })).concat({ name: 'Name', width: 0 }),
    };
    await mutateAsync(upsertDto);
  }, [key, mutateAsync]);

  return {
    orderedVisibleColumnNames,
    columnWidthsByColumnName,
    persistAsync,
    isLoadingQuery,
    isLoadingMutation,
    columnNamesForcedHidden,
    columnNamesForcedVisible,
  };
}
