import React, { MouseEvent, useMemo } from 'react';
import {
  Box, ToggleButton, ToggleButtonGroup, Typography, styled,
} from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import FolderIcon from 'documents-folders/components/FolderIcon';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FolderAccessType from 'documents-folders/types/FolderAccessType';
import useFolder from 'documents-folders/hooks/useFolder';
import { useTranslation } from 'react-i18next';

const StyledToggleButton = styled(ToggleButton)(() => ({
  padding: 0,
  alignItems: 'stretch',
  textTransform: 'unset',
  '&.Mui-selected': {
    fontWeight: 600,
  },
  minHeight: '64px',
}));

const StyledToggleButtonContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing(1),
  columnGap: theme.spacing(1),
}));

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  // justification for prop spreading: documented 3rd party library pattern
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    borderColor: theme.palette.text.secondary,
    borderWidth: '1px',
    borderStyle: 'solid',
    padding: '18px 24px',
    boxShadow: '0 0 4px 4px rgba(141, 141, 141, 0.125)',
  },
}));

interface FolderAccessTypeToggleButtonGroupProps {
  folderId: string | undefined,
  parentFolderId: string | undefined,
  value: FolderAccessType | null,
  onChange: (event: MouseEvent<HTMLElement>, value: FolderAccessType) => void,
  disabled?: boolean,
}

export default function FolderAccessTypeToggleButtonGroup({
  folderId,
  parentFolderId,
  value,
  onChange,
  disabled,
}: FolderAccessTypeToggleButtonGroupProps) {
  const { t } = useTranslation('documents-folders');
  const folder = useFolder(folderId);
  const parentFolder = useFolder(parentFolderId);
  const isPublicAccessTypeAvailable = useMemo(() => {
    if ((folderId && !folder) || (parentFolderId && !parentFolder)) return false; // init
    if (folder?.accessType === FolderAccessType.Private) return false; // private folders can't be changed to public
    return true;
  }, [folder, folderId, parentFolder, parentFolderId]);
  const isRestrictedAccessTypeAvailable = useMemo(() => {
    if ((folderId && !folder) || (parentFolderId && !parentFolder)) return false; // init
    if (folder?.accessType === FolderAccessType.Private) return false; // private folders can't be changed to restricted
    return true;
  }, [folder, folderId, parentFolder, parentFolderId]);
  const isPrivateAccessTypeAvailable = useMemo(() => {
    if (folderId && !folder) return false; // init
    if (!parentFolderId) return false; // you can't create top-level private folders
    if (parentFolderId && parentFolder?.accessType !== FolderAccessType.Private) return false; // you can't create a private folder inside a public or restricted folder
    if (folderId && folder?.accessType !== FolderAccessType.Private) return false; // existing public or restricted folders can't be set to private
    return true;
  }, [folder, folderId, parentFolder, parentFolderId]);

  return (
    <ToggleButtonGroup
      exclusive
      onChange={onChange}
      value={value}
      disabled={disabled}
      sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}
    >
      <StyledToggleButton
        value={FolderAccessType.Public}
        id="folder-settings-panel_visibility-public-option-button"
        disabled={!isPublicAccessTypeAvailable || disabled}
      >
        <StyledTooltip
          disableInteractive
          enterDelay={250}
          enterNextDelay={250}
          title={(
            <Box>
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ width: '48px', flexShrink: 0 }}>
                  <VisibilityIcon />
                  <EditIcon />
                </Box>
                <Box>
                  <Typography sx={{ fontWeight: 600 }}>{t('folder-settings-panel_visibility-public-tooltip_default-title', 'Default: Unrestricted')}</Typography>
                  <Typography>{t('folder-settings-panel_visibility-public-tooltip_default-text', 'All collaborators have read and write access.')}</Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', mt: 2 }}>
                <Box sx={{ width: '48px', flexShrink: 0 }}>
                  <EditOffIcon />
                </Box>
                <Box>
                  <Typography sx={{ fontWeight: 600 }}>{t('folder-settings-panel_visibility-public-tooltip_manual-title', 'Manual configuration:')}</Typography>
                  <Typography>{t('folder-settings-panel_visibility-public-tooltip_manual-text', 'Access can be restricted to "read only" per collaborator or user group.')}</Typography>
                </Box>
              </Box>
            </Box>
        )}
        >
          <StyledToggleButtonContent>
            <FolderIcon accessType={FolderAccessType.Public} />
            {t('folder-settings-panel_visibility-public-title', 'Open Folder')}
          </StyledToggleButtonContent>
        </StyledTooltip>
      </StyledToggleButton>
      <StyledToggleButton
        value={FolderAccessType.Restricted}
        id="folder-settings-panel_visibility-restricted-option-button"
        disabled={!isRestrictedAccessTypeAvailable || disabled}
      >
        <StyledTooltip
          disableInteractive
          enterDelay={250}
          enterNextDelay={250}
          title={(
            <Box>
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ width: '48px', flexShrink: 0 }}>
                  <VisibilityOffIcon />
                  <EditOffIcon />
                </Box>
                <Box>
                  <Typography sx={{ fontWeight: 600 }}>{t('folder-settings-panel_visibility-restricted-tooltip_default-title', 'Default: No Access')}</Typography>
                  <Typography>{t('folder-settings-panel_visibility-restricted-tooltip_default-text', 'Invisible for all collaborators (incl. creator) unless manually granted.')}</Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', mt: 2 }}>
                <Box sx={{ width: '48px', flexShrink: 0 }}>
                  <VisibilityIcon />
                  <EditIcon />
                </Box>
                <Box>
                  <Typography sx={{ fontWeight: 600 }}>{t('folder-settings-panel_visibility-restricted-tooltip_manual-title', 'Manual Configuration:')}</Typography>
                  <Typography>{t('folder-settings-panel_visibility-restricted-tooltip_manual-text', 'Grant read and write rights individually per collaborator or user group.')}</Typography>
                </Box>
              </Box>
            </Box>
        )}
        >
          <StyledToggleButtonContent>
            <FolderIcon accessType={FolderAccessType.Restricted} />
            {t('folder-settings-panel_visibility-restricted-title', 'Restricted Folder')}
          </StyledToggleButtonContent>
        </StyledTooltip>
      </StyledToggleButton>

      <StyledToggleButton
        value={FolderAccessType.Private}
        id="folder-settings-panel_visibility-private-option-button"
        disabled={!isPrivateAccessTypeAvailable || disabled}
      >
        <StyledTooltip
          disableInteractive
          enterDelay={250}
          enterNextDelay={250}
          title={(
            <Box>
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ width: '48px', flexShrink: 0 }}>
                  <VisibilityIcon color="disabled" />
                  <EditIcon color="disabled" />
                </Box>
                <Box>
                  <Typography sx={{ fontWeight: 600 }}>{t('folder-settings-panel_visibility-private-tooltip_default-title', 'Default:')}</Typography>
                  <Typography>{t('folder-settings-panel_visibility-private-tooltip_default-text', 'Read and write rights for the creator. Invisible for everyone else.')}</Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', mt: 2 }}>
                <Box sx={{ width: '48px', flexShrink: 0 }} />
                <Box>
                  <Typography sx={{ fontWeight: 600 }}>{t('folder-settings-panel_visibility-private-tooltip_manual-title', 'Manual configuration:')}</Typography>
                  <Typography>{t('folder-settings-panel_visibility-private-tooltip_manual-text', 'None')}</Typography>
                </Box>
              </Box>
            </Box>
        )}
        >
          <StyledToggleButtonContent>
            <FolderIcon accessType={FolderAccessType.Private} />
            {t('folder-settings-panel_visibility-private-title', 'Private Folder')}
          </StyledToggleButtonContent>
        </StyledTooltip>
      </StyledToggleButton>
    </ToggleButtonGroup>
  );
}
