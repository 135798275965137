import React, { useMemo } from 'react';
import { MenuItem, Select } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useRolesOdataQuery from 'collaborators/hooks/useRolesOdataQuery';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';
import RoleAction from 'projects/types/RoleAction';

interface CollaboratorRoleSelectProps extends ISxProps {
  value: string | undefined,
  onChange: (nextValue: string) => void,
  disabled?: boolean,
}

export default function CollaboratorRoleEditSelect({
  sx,
  value,
  onChange,
  disabled,
}: CollaboratorRoleSelectProps) {
  const { data: roles, isLoading } = useRolesOdataQuery({ filter: { isDeleted: false } });
  const allowedActions = useAllowedActions();

  const roleItems = useMemo(() => {
    if (roles && allowedActions) {
      return roles.map((role) => ({
        role,
        disabled: role.isAdmin && !allowedActions.has(RoleAction.Project_AssignAdmin),
      }));
    }
    return undefined;
  }, [allowedActions, roles]);

  return (
    <Select
      id="CollaboratorRoleEditSelect"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled || isLoading}
      variant="standard"
      sx={sx}
    >
      {roleItems?.map(({ role, disabled: isDisabled }) => <MenuItem disabled={isDisabled} value={role.id} key={role.id}>{role.name}</MenuItem>)}
    </Select>
  );
}
