import {
  BufferGeometry,
  DoubleSide,
  LineBasicMaterial,
  LineSegments,
  Mesh,
  MeshBasicMaterial,
  Plane,
} from "three";
import { IfcState, Normal, Position } from "./BaseDefinitions";
import { BvhManager } from "./BvhManager";
import { GeometryAttributes, SubsetUtils } from "./SubsetUtils";

const solidMat = new MeshBasicMaterial({
  color: 0xde3163,
  opacity: 0.8,
  transparent: true,
  side: DoubleSide,
});
const wireMat = new LineBasicMaterial({
  color: 0xff5733,
  depthTest: false,
  transparent: true,
});

export class HighlightSubSet {
  private model?: Mesh;
  private wire?: LineSegments;

  constructor(private state: IfcState, private BVH: BvhManager) { }

  setClippingPlanes(planes: Plane[]) {
    solidMat.clippingPlanes = planes.length ? planes : null;
    wireMat.clippingPlanes = planes.length ? planes : null;
  }

  dispose() {
    if (this.model) {
      this.model.removeFromParent();
      this.model.geometry.dispose();
      this.model = undefined;
    }

    if (this.wire) {
      this.wire.geometry.dispose();
      this.wire = undefined;
    }
  }

  highLightComponents(globalIds: string[]) {
    if (!globalIds.length) {
      return null;
    }

    SubsetUtils.resetExpressId();

    const attributes: GeometryAttributes[] = [];

    Object.keys(this.state.modelData).forEach((key) => {
      const nodes = this.state.modelData[key].nodeData.filter((n) =>
        globalIds.includes(n.globalId)
      );
      const shapes = this.state.modelData[key].shapes;
      if (shapes && nodes.length) {
        const subAttributes = nodes.map((node) => {
          return SubsetUtils.getGeometryAttribute(
            SubsetUtils.getTransformMatrix(node.transform),
            shapes[node.shapeId],
            false
          );
        });

        attributes.push(SubsetUtils.mergeGeometryAttributes(subAttributes));
      }
    });

    return this.setGeometry(attributes);
  }

  private setGeometry(attributes: GeometryAttributes[]) {
    if (attributes.length) {
      const attribute = SubsetUtils.mergeGeometryAttributes(attributes);
      if (!this.model) {
        this.model = new Mesh(new BufferGeometry(), solidMat);
        this.model.renderOrder = Infinity;
        this.model.frustumCulled = false;
      }

      this.model.geometry.setAttribute(Position, attribute.position);
      this.model.geometry.setAttribute(Normal, attribute.normal);
      this.model.geometry.setIndex(attribute.index);

      if (this.BVH) this.BVH.applyThreeMeshBVH(this.model.geometry);

      if (!this.wire) {
        this.wire = new LineSegments(new BufferGeometry(), wireMat);
        this.wire.frustumCulled = false;

        this.model.add(this.wire);
      }

      this.wire.geometry.setAttribute(Position, attribute.position);
      this.wire.geometry.setIndex(attribute.wireframe);

      return this.model;
    } else {
      return null;
    }
  }
}
