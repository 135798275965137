import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import IssueDto from 'issues/types/IssueDto';

export default function ReviewerCell({
  data,
}: ICellRendererParams<IssueDto, string | undefined>) {
  const reviewer = useMemo(() => {
    if (!data || !data.reviewer) return undefined;
    return `${data.reviewer.firstName} ${data.reviewer.lastName}`;
  }, [data]);
  return (
    <Box id="ReviewerCell">
      {reviewer}
    </Box>
  );
}
