import React from 'react';
import MutateNamingSchemeGroupDialog from 'naming-schemes/components/MutateNamingSchemeGroupDialog';
import { useTranslation } from 'react-i18next';
import NamingSchemeGroupItem from 'naming-schemes/types/NamingSchemeGroupItem';
import NamingSchemeGroupType from 'naming-schemes/types/NamingSchemeGroupType';

interface CreateNamingSchemeGroupDialogProps {
  alreadyExistingGroupTypes: NamingSchemeGroupType[],
  onClose: (value: NamingSchemeGroupItem | undefined) => void,
}

export default function CreateNamingSchemeGroupDialog({
  alreadyExistingGroupTypes,
  onClose,
}: CreateNamingSchemeGroupDialogProps) {
  const { t } = useTranslation('naming-schemes');
  return (
    <MutateNamingSchemeGroupDialog
      alreadyExistingGroupTypes={alreadyExistingGroupTypes}
      onClose={onClose}
      title={t('create-naming-scheme-group-dialog_title', 'Add Group')}
    />
  );
}
