import React, { useCallback, useContext, useMemo } from 'react';
import { Box, FormControl, FormControlLabel, InputLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DocumentFilterState from 'documents-filter/types/DocumentFilterState';
import TagMultiselect from 'tags/components/TagMultiselect';
import CollectionComparisonMode from 'documents-filter/types/CollectionComparisonMode';
import DocumentOptionsContext, { DocumentOptionsContextState } from 'documents/contexts/DocumentOptionsContext';
import DocumentAbbreviationDisplayMode from 'documents/types/DocumentAbbreviationDisplayMode';

interface DetailsFilterSectionProps {
  value: DocumentFilterState,
  onChange: (state: DocumentFilterState) => void,
}

export default function LabelsFilterSection({
  value,
  onChange,
}: DetailsFilterSectionProps) {
  const { t } = useTranslation('documents-filter');
  const { abbreviationDisplayMode } = useContext<DocumentOptionsContextState>(DocumentOptionsContext);
  const abbreviated = useMemo(() => abbreviationDisplayMode === DocumentAbbreviationDisplayMode.Abbreviation, [abbreviationDisplayMode]);
  const onChangeFilterMode = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...value, tagComparisonMode: parseInt(e.target.value, 10) ?? undefined });
  }, [onChange, value]);
  const onChangeTagLabelNames = useCallback((tagLabelIds: string[]) => {
    onChange({ ...value, tagLabelIds });
  }, [onChange, value]);
  return (
    <>
      <Typography variant="h4" sx={{ mt: 3 }}>{t('filter-drawer_labels-header', 'Labels')}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControl sx={{ flexGrow: 1, mt: 1 }}>
          <InputLabel id="documents-filter_tags-label">{t('filter-drawer_tags-label', 'Tags')}</InputLabel>
          <TagMultiselect
            label={t('documents-tags-label', 'Tags')}
            value={value.tagLabelIds}
            onChange={onChangeTagLabelNames}
            abbreviated={abbreviated}
          />
        </FormControl>
        <FormControl sx={{ mt: 1 }}>
          <RadioGroup
            value={value.tagComparisonMode ?? CollectionComparisonMode.ContainsAny}
            onChange={onChangeFilterMode}
            name="radio-buttons-group"
          >
            <FormControlLabel value={CollectionComparisonMode.ContainsAny} control={<Radio />} label={t('filter-drawer_tags-comparison-mode-any', 'Any of the selected tags')} />
            <FormControlLabel value={CollectionComparisonMode.ContainsAll} control={<Radio />} label={t('filter-drawer_tags-comparison-mode-all', 'All of the selected tags')} />
          </RadioGroup>
        </FormControl>
      </Box>
    </>
  );
}
