import { ComponentsProps, ComponentsVariants } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

interface MuiTabsComponent {
  defaultProps?: ComponentsProps['MuiTabs'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTabs'];
  variants?: ComponentsVariants['MuiTabs'];
}

const VisoTabs: MuiTabsComponent = {
  styleOverrides: {
    root: ({ theme }) => ({
      minHeight: '24px',
      '.MuiTabs-indicator': {
        backgroundColor: theme.palette.primary.main,
        height: '4px',
      },
    }),
  },
};

export default VisoTabs;
