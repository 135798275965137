import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import CreatePdfAnnotationDto from 'documents-annotations/types/CreatePdfAnnotationDto';
import ApiEndpoint from 'api/types/ApiEndpoint';
import PdfAnnotationDto from 'documents-annotations/types/PdfAnnotationDto';
import { useCallback } from 'react';

export default function usePdfAnnotationCreateMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases: annotationQueryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.PdfAnnotation);
  const { queryKeyBases: issueQueryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.Issue);
  const { queryKeyBases: documentVersionQueryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentVersion);
  const mutationFn = useCallback(async (dto: CreatePdfAnnotationDto) => {
    const { data: updatedPdfAnnotationDto } = await axiosInstance.post<PdfAnnotationDto>(`${ApiEndpoint.PdfAnnotation}`, dto);
    if (!updatedPdfAnnotationDto) throw new Error('The server responded with an empty result.');
    return updatedPdfAnnotationDto;
  }, [axiosInstance]);
  const onSuccess = useCallback(() => {
    [
      ...annotationQueryKeyBases,
      ...issueQueryKeyBases,
      ...documentVersionQueryKeyBases,
    ].map((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
  }, [annotationQueryKeyBases, documentVersionQueryKeyBases, issueQueryKeyBases, queryClient]);
  return useMutation<PdfAnnotationDto, unknown, CreatePdfAnnotationDto>({ mutationFn, onSuccess });
}
