import type { Theme, ComponentsProps, ComponentsVariants } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

interface MuiCheckboxComponent {
  defaultProps?: ComponentsProps['MuiCheckbox'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiCheckbox'];
  variants?: ComponentsVariants['MuiCheckbox'];
}

const VisoCheckbox: MuiCheckboxComponent = {
  styleOverrides: {
    root: () => ({
      padding: '2px 2px',
      margin: '0 7px',
      '&.Mui-disabled:not(.Mui-checked)': {
        opacity: 0.4,
      },
    }),
  },
};

export default VisoCheckbox;
