import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Alert, Box, Button, Checkbox, Divider, Menu, MenuItem, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { mdiViewDashboardEditOutline, mdiRestore, mdiCogOutline, mdiWiper } from '@mdi/js';
import Icon from '@mdi/react';
import ISxProps from 'common/types/ISxProps';
import Viewer3dSettingsDialog from 'models/components/Viewer3dSettingsDialog';
import { ModelsPanelDockviewPanelId } from 'models/contexts/ModelsPanelDockviewContext';
import useModelsPanelDockviewContext from 'models/hooks/useModelsPanelDockviewContext';
import useModelsPanelDockviewPanelTitleTranslations from 'models/hooks/useModelsPanelDockviewPanelTitleTranslations';
import useViewer3dContext from 'models/hooks/useViewer3dContext';
import RoleAction from 'projects/types/RoleAction';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';

interface ModelsPanelToolbarProps extends ISxProps {
}

export default function ModelsPanelToolbar({
  sx,
}: ModelsPanelToolbarProps) {
  const { t } = useTranslation('models');
  const { visiblePanelIds, togglePanelVisible, resetLayoutToDefault } = useModelsPanelDockviewContext();
  const { resetViewer } = useViewer3dContext();
  const onClickResetViewer = useCallback(() => resetViewer(), [resetViewer]);

  const [viewer3dSettingsDialogOpen, setViewer3dSettingsDialogOpen] = useState<boolean>(false);
  const onClickViewer3dSettings = useCallback(() => setViewer3dSettingsDialogOpen(true), []);
  const [viewer3dSuccessSnackbarVisible, setViewer3dSuccessSnackbarVisible] = useState<boolean>(false);
  const onCloseViewer3dSuccessSnackbar = useCallback(() => setViewer3dSuccessSnackbarVisible(false), []);
  const onCloseViewer3dSettingsDialog = useCallback((confirmed: boolean) => {
    setViewer3dSettingsDialogOpen(false);
    if (confirmed) {
      setViewer3dSuccessSnackbarVisible(true);
    }
  }, []);

  const layoutButtonRef = useRef<HTMLButtonElement>(null);
  const [layoutMenuOpen, setLayoutMenuOpen] = useState<boolean>(false);
  const onClickOpenLayoutMenu = useCallback(() => setLayoutMenuOpen(true), []);
  const onCloseLayoutMenu = useCallback(() => setLayoutMenuOpen(false), []);
  const panelTitleTranslations = useModelsPanelDockviewPanelTitleTranslations();
  const allowedActions = useAllowedActions();
  const canViewIssues = useMemo(() => allowedActions && allowedActions.has(RoleAction.Issues), [allowedActions]);
  const panelItems = useMemo(() => (visiblePanelIds ? Object.keys(ModelsPanelDockviewPanelId).map((key) => ModelsPanelDockviewPanelId[key as keyof typeof ModelsPanelDockviewPanelId]).map((panelId) => ({
    key: panelId,
    selected: visiblePanelIds.has(panelId) && !(panelId === ModelsPanelDockviewPanelId.Issues && !canViewIssues),
    onClick: () => togglePanelVisible(panelId),
    disabled: panelId === ModelsPanelDockviewPanelId.Issues && !canViewIssues,
    label: panelTitleTranslations[panelId],
  })) : undefined), [canViewIssues, panelTitleTranslations, togglePanelVisible, visiblePanelIds]);
  const onClickResetLayout = useCallback(() => {
    resetLayoutToDefault();
    onCloseLayoutMenu();
  }, [onCloseLayoutMenu, resetLayoutToDefault]);

  return (
    <Box id="ModelsPanelToolbar" sx={{ display: 'flex', gap: 2, ...sx }}>
      <Button variant="outlined" onClick={onClickOpenLayoutMenu} sx={{ pl: 1, gap: 1 }} ref={layoutButtonRef}>
        <Icon path={mdiViewDashboardEditOutline} size={1} />
        {t('models-page-header_layout-menu-button-label', 'Layout')}
      </Button>
      {!!panelItems && (
      <Menu open={layoutMenuOpen} onClose={onCloseLayoutMenu} anchorEl={layoutButtonRef.current}>
        {panelItems.map(({ key, onClick, selected, label, disabled }) => (
          <MenuItem key={key} onClick={onClick} selected={selected} disabled={disabled} sx={{ pl: 1.5, gap: 1 }}>
            <Checkbox checked={selected} sx={{ mx: 0, px: 0 }} />
            {label}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem onClick={onClickResetLayout} sx={{ pl: 1.5, gap: 1 }}>
          <Icon path={mdiRestore} size={1} />
          {t('models-page-header_reset-layout-menu-item-label', 'Reset Layout')}
        </MenuItem>
      </Menu>
      )}
      <Button onClick={onClickViewer3dSettings} variant="outlined" sx={{ pl: 1, gap: 1 }}>
        <Icon path={mdiCogOutline} size={1} />
        {t('models-page-header_viewer-3d-settings-button-label', '3D View Settings')}
      </Button>
      {!!viewer3dSettingsDialogOpen && <Viewer3dSettingsDialog onClose={onCloseViewer3dSettingsDialog} />}
      {!!viewer3dSuccessSnackbarVisible && (
      <Snackbar open onClose={onCloseViewer3dSuccessSnackbar} autoHideDuration={3000} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
        <Alert severity="success" onClose={onCloseViewer3dSuccessSnackbar}>
          {t('models-page-header_viewer-3d-settings-dialog-success-snackbar-message', 'Your changes to the 3D viewer settings have been saved.')}
        </Alert>
      </Snackbar>
      )}
      <Button onClick={onClickResetViewer} variant="outlined" sx={{ pl: 1, gap: 1 }}>
        <Icon path={mdiWiper} size={1} />
        {t('models-page-header_viewpoint-filter-button-label', 'Reset Viewer')}
      </Button>
    </Box>
  );
}
