import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Box, Button, ButtonBase, Checkbox, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ISxProps from 'common/types/ISxProps';
import NamingSchemeGroupType from 'naming-schemes/types/NamingSchemeGroupType';
import LabelType from 'labels/types/LabelType';
import useLabelsOdataQuery from 'labels/hooks/useLabelsOdataQuery';
import LabelChip from 'labels/components/LabelChip';
import Icon from '@mdi/react';
import { mdiDelete, mdiPlus, mdiTransferRight } from '@mdi/js';
import NamingSchemeGroupElementItem from 'naming-schemes/types/NamingSchemeGroupElementItem';
import ChipMultiselect from 'common/components/ChipMultiselect';

export const LABEL_TYPE_BY_GROUP_TYPE = new Map<NamingSchemeGroupType, LabelType>([
  [NamingSchemeGroupType.Disciplines, LabelType.Discipline],
  [NamingSchemeGroupType.Building, LabelType.Building],
  [NamingSchemeGroupType.Floor, LabelType.Floor],
  [NamingSchemeGroupType.WorkPhases, LabelType.WorkPhase],
  [NamingSchemeGroupType.Custom, LabelType.Tag],
  [NamingSchemeGroupType.DocStatus, LabelType.DocumentStatus],
]);

enum AllSelectionState {
  All = 'all',
  Indeterminate = 'indeterminate',
  None = 'none',
}

interface MutateNamingSchemeGroupLabelElementsPanelProps extends ISxProps {
  groupType: NamingSchemeGroupType,
  elements: NamingSchemeGroupElementItem[],
  onChange: (value: NamingSchemeGroupElementItem[]) => void,
}

export default function MutateNamingSchemeGroupLabelElementsPanel({
  sx,
  groupType,
  elements,
  onChange,
}: MutateNamingSchemeGroupLabelElementsPanelProps) {
  const { t } = useTranslation('naming-schemes');
  const theme = useTheme();

  const { data: labels } = useLabelsOdataQuery(groupType !== undefined ? { filter: { isDeleted: false, type: LabelType[LABEL_TYPE_BY_GROUP_TYPE.get(groupType)!] }, orderBy: 'order asc' } : undefined);
  const { data: tags } = useLabelsOdataQuery({ filter: { isDeleted: false, type: LabelType[LabelType.Tag] }, orderBy: 'order asc' });

  const mappedLabelsSelectLabel = useMemo(() => {
    if (groupType === NamingSchemeGroupType.Disciplines) return t('mutate-naming-scheme-group-panel_mapped-disciplines', 'Disciplines');
    if (groupType === NamingSchemeGroupType.Building) return t('mutate-naming-scheme-group-panel_mapped-building', 'Building');
    if (groupType === NamingSchemeGroupType.Floor) return t('mutate-naming-scheme-group-panel_mapped-floor', 'Floor');
    if (groupType === NamingSchemeGroupType.WorkPhases) return t('mutate-naming-scheme-group-panel_mapped-work-phases', 'Work Phases');
    if (groupType === NamingSchemeGroupType.DocStatus) return t('mutate-naming-scheme-group-panel_mapped-doc-status', 'Document Status');
    return undefined;
  }, [groupType, t]);

  const tagsSelectLabel = useMemo(() => {
    if (groupType === NamingSchemeGroupType.Custom) return t('mutate-naming-scheme-group-panel_mapped-tags-select-label', 'Tags');
    return t('mutate-naming-scheme-group-panel_additional-labels-select-label', 'Additional Tags');
  }, [groupType, t]);

  const onChangeElement = useCallback((elementItemUuid: string, updateCallback: (element: NamingSchemeGroupElementItem) => NamingSchemeGroupElementItem) => {
    const nextGroupElementItems = [...elements];
    const elementItemIndex = nextGroupElementItems.findIndex((item) => item.uuid === elementItemUuid);
    if (elementItemIndex >= 0) {
      nextGroupElementItems[elementItemIndex] = updateCallback(nextGroupElementItems[elementItemIndex]);
    }
    onChange(nextGroupElementItems);
  }, [elements, onChange]);

  const groupElementListItems = useMemo(() => {
    if (groupType === undefined) return undefined;
    return elements.map((element, index) => ({
      element,
      onChangeAbbrevitationTextField: (event: ChangeEvent<HTMLInputElement>) => {
        onChangeElement(element.uuid, (g) => ({ ...g, abbreviation: event.target.value }));
      },
      onChangeNameTextField: (event: ChangeEvent<HTMLInputElement>) => {
        onChangeElement(element.uuid, (g) => ({ ...g, name: event.target.value }));
      },
      onChangeMappedLabelSelect: (event: SelectChangeEvent<string>) => {
        onChangeElement(element.uuid, (g) => ({ ...g, label: event.target.value }));
      },
      onChangeMappedTagsSelect: (value: string[]) => {
        onChangeElement(element.uuid, (g) => ({ ...g, labels: value }));
      },
      onClickRemove: () => {
        const nextElements = [...elements];
        nextElements.splice(index, 1);
        onChange(nextElements);
      },
    }));
  }, [elements, groupType, onChange, onChangeElement]);

  const [selectedLabelIds, setSelectedLabelIds] = useState<string[]>([]);
  const labelItems = useMemo(() => {
    if (!labels) return undefined;
    const alreadyMappedLabelIds = new Set(elements.filter((item) => item.label).map((item) => item.label!));
    const alreadyMappedTagIds = new Set(elements.flatMap((item) => item.labels ?? []));
    const selectedLabelIdsSet = new Set(selectedLabelIds);
    return labels.map((label) => {
      const selected = selectedLabelIdsSet.has(label.id) || alreadyMappedLabelIds.has(label.id) || alreadyMappedTagIds.has(label.id);
      return {
        label,
        onChangeCheckbox: (event: ChangeEvent<HTMLInputElement>) => setSelectedLabelIds(event.target.checked ? selectedLabelIds.concat(label.id) : selectedLabelIds.filter((id) => id !== label.id)),
        onClickChip: () => setSelectedLabelIds(selected ? selectedLabelIds.filter((id) => id !== label.id) : selectedLabelIds.concat(label.id)),
        selected,
        disabled: alreadyMappedLabelIds.has(label.id) || alreadyMappedTagIds.has(label.id),
      };
    });
  }, [elements, labels, selectedLabelIds]);

  const onClickCreateLabelElements = useCallback(() => {
    if (groupType === undefined) return;
    let newItems: NamingSchemeGroupElementItem[];
    if (groupType !== NamingSchemeGroupType.Custom) {
      if (!labels) return;
      const selectedLabelIdsSet = new Set(selectedLabelIds);
      const selectedLabels = labels.filter((label) => selectedLabelIdsSet.has(label.id));
      newItems = selectedLabels.map((label) => ({
        uuid: crypto.randomUUID(),
        name: label.name,
        label: label.id,
        abbreviation: label.abbreviation || label.name,
        versionAsLetter: false,
      }));
    } else {
      if (!tags) return;
      const selectedLabelIdsSet = new Set(selectedLabelIds);
      const selectedLabels = tags.filter((tagLabel) => selectedLabelIdsSet.has(tagLabel.id));
      newItems = selectedLabels.map((tagLabel) => ({
        uuid: crypto.randomUUID(),
        name: tagLabel.name,
        labels: [tagLabel.id],
        abbreviation: tagLabel.abbreviation || tagLabel.name,
        versionAsLetter: false,
      }));
    }
    onChange(elements.concat(newItems));
    setSelectedLabelIds([]);
  }, [elements, groupType, labels, onChange, selectedLabelIds, tags]);

  const onClickAddElement = useCallback(() => {
    if (groupType === undefined) return;
    const nextElements = elements.concat([{
      uuid: crypto.randomUUID(),
      versionAsLetter: false,
      name: '',
      abbreviation: '',
    }]);
    onChange(nextElements);
  }, [elements, groupType, onChange]);

  const allSelectedState = useMemo<AllSelectionState | undefined>(() => {
    if (!labels) return undefined;
    const mappedLabelsCount = new Set(elements.filter((item) => item.label).map((item) => item.label!)).size;
    if (selectedLabelIds.length === 0 || mappedLabelsCount === labels.length) return AllSelectionState.None;
    if (selectedLabelIds.length === labels.length - mappedLabelsCount) return AllSelectionState.All;
    return AllSelectionState.Indeterminate;
  }, [elements, labels, selectedLabelIds.length]);

  const onClickSelectAll = useCallback(() => {
    if (!labels) return;
    if (allSelectedState === AllSelectionState.All) {
      setSelectedLabelIds([]);
    } else {
      const mappedLabelIds = new Set(elements.filter((item) => item.label).map((item) => item.label!));
      const unmappedLabelIds = labels.map((label) => label.id).filter((id) => !mappedLabelIds.has(id));
      setSelectedLabelIds(unmappedLabelIds);
    }
  }, [allSelectedState, elements, labels]);

  return (
    <Box id="MutateNamingSchemeGroupLabelElementsPanel" sx={{ display: 'flex', flexDirection: 'column', gap: 3, ...sx }}>
      <Typography variant="h5">{t('mutate-naming-scheme-group-panel_element-manager-header', 'Manage Group Elements')}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'stretch', gap: 2, minHeight: 400 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: 200, pr: 1, overflowY: 'auto' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Checkbox disabled={!labelItems?.length} checked={allSelectedState === AllSelectionState.All} indeterminate={allSelectedState === AllSelectionState.Indeterminate} onChange={onClickSelectAll} sx={{ ml: '-4px' }} />
            <ButtonBase disabled={!labelItems?.length} onClick={onClickSelectAll} sx={{ fontStyle: 'italic' }}>{t('mutate-naming-scheme-group-panel_element-manager-select-all-button-label', 'Select All')}</ButtonBase>
          </Box>
          {!!labelItems && labelItems.map(({ label, selected, disabled, onChangeCheckbox, onClickChip }) => (
            <Box key={label.id} sx={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox disabled={disabled} checked={selected} onChange={onChangeCheckbox} sx={{ ml: '-4px' }} />
              <LabelChip disabled={disabled} label={label} sx={{ minWidth: 0, cursor: 'pointer' }} onClick={onClickChip} />
            </Box>
          ))}
        </Box>
        <Button onClick={onClickCreateLabelElements} disabled={!selectedLabelIds.length} variant="contained" color="secondary">
          <Icon path={mdiTransferRight} size={1} />
        </Button>
        <Box sx={{ display: 'flex', flexDirection: 'column', overflowY: 'auto', minWidth: 644, gap: 1, backgroundColor: theme.palette.grey[300], borderRadius: '16px', boxShadow: 'inset 0 0 16px -4px rgba(0,0,0,0.3)', p: 2 }}>
          {!!labels && !!groupElementListItems && groupElementListItems.map(({ element, onChangeAbbrevitationTextField, onChangeNameTextField, onChangeMappedLabelSelect, onChangeMappedTagsSelect, onClickRemove }) => (
            <Box key={element.uuid} sx={{ p: 1.5, backgroundColor: theme.palette.background.default, borderRadius: '8px', display: 'flex', gap: 1 }}>
              <TextField value={element.name} onChange={onChangeNameTextField} label={t('mutate-naming-scheme-group-panel_name-textfield-label', 'Name')} sx={{ width: 200 }} />
              <TextField value={element.abbreviation} onChange={onChangeAbbrevitationTextField} label={t('mutate-naming-scheme-group-panel_abbreviation-textfield-label', 'Abbreviation')} sx={{ width: 100 }} />
              {groupType !== NamingSchemeGroupType.Custom && (
                <FormControl sx={{ width: 200 }}>
                  <InputLabel id="mutate-naming-scheme-group-panel_group-type-select-label">{mappedLabelsSelectLabel}</InputLabel>
                  <Select<string>
                    value={element.label ?? ''}
                    label={mappedLabelsSelectLabel}
                    onChange={onChangeMappedLabelSelect}
                  >
                    {labels.map((label) => (
                      <MenuItem key={label.id} value={label.id}>{label.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <FormControl sx={{ width: 180 }}>
                <InputLabel id="mutate-naming-scheme-group-panel_additional-labels-select-label">{tagsSelectLabel}</InputLabel>
                <ChipMultiselect
                  value={element.labels ?? []}
                  label={tagsSelectLabel}
                  onChange={onChangeMappedTagsSelect}
                  entities={tags}
                />
              </FormControl>
              <Button variant="outlined" onClick={onClickRemove} size="small" sx={{ height: 36 }}>
                <Icon path={mdiDelete} size={1} />
              </Button>
            </Box>
          ))}
          <Button variant="outlined" onClick={onClickAddElement} sx={{ pl: 1, gap: 0.5 }}>
            <Icon path={mdiPlus} size={1} />
            {t('mutate-naming-scheme-group-panel_add-element-button-label', 'Add New Element')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
