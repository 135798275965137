import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Box, Button, Divider, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import { CustomHeaderProps } from '@ag-grid-community/react';
import useIssuesFilterContext from 'issues/hooks/useIssuesFilterContext';
import Icon from '@mdi/react';
import { mdiFilter, mdiFilterMinus, mdiFilterPlus, mdiFilterSettings, mdiSort, mdiSortVariantRemove } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import IssueDto from 'issues/types/IssueDto';
import IssueFilterDialog from 'issues/components/IssueFilterDialog';
import { defaultSortMode } from 'issues/contexts/IssuesFilterContextProvider';
import { isEqual } from 'lodash';
import useIssueFilterOptionsByFieldName from 'issues/hooks/useIssueFilterOptionsByFieldName';

export default function HeaderCell({
  column,
  displayName,
}: CustomHeaderProps<IssueDto>) {
  const { queryFilterState } = useIssuesFilterContext();
  const { appliedFilterState, emptyFilterState, setAppliedFilterState } = queryFilterState;
  const columnName = useMemo(() => column.getColId() as keyof IssueDto, [column]);
  const issueFilterOptions = useIssueFilterOptionsByFieldName();
  const sortPath = issueFilterOptions[columnName]?.sortPath;
  const { t } = useTranslation('issues');
  const theme = useTheme();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { setSortMode, sortMode } = useIssuesFilterContext();
  const activeSortMode = useMemo(() => (sortMode === `${sortPath} asc` ? 'asc' : sortMode === `${sortPath} desc` ? 'desc' : undefined), [sortMode, sortPath]);
  const filterOptionsByFieldName = useIssueFilterOptionsByFieldName();
  const filterOptions = useMemo(() => filterOptionsByFieldName[columnName], [columnName, filterOptionsByFieldName]);
  const filterActive = useMemo(() => {
    if (!filterOptions) return undefined;
    return !isEqual(appliedFilterState[filterOptions.filterField], emptyFilterState[filterOptions.filterField]);
  }, [appliedFilterState, emptyFilterState, filterOptions]);
  const [menuOpen, setMenuOpen] = useState(false);
  const onClickOpenMenu = useCallback(() => setMenuOpen(true), []);
  const onClickSortAscending = useCallback(() => {
    setSortMode(`${sortPath} asc`);
    setMenuOpen(false);
  }, [setSortMode, sortPath]);
  const onClickSortDescending = useCallback(() => {
    setSortMode(`${sortPath} desc`);
    setMenuOpen(false);
  }, [setSortMode, sortPath]);
  const onClickSortReset = useCallback(() => {
    setSortMode(defaultSortMode);
    setMenuOpen(false);
  }, [setSortMode]);
  const onCloseMenu = useCallback(() => setMenuOpen(false), []);

  const onClickRemoveFilterMenuButton = useCallback(() => {
    if (!filterOptions) return;
    setAppliedFilterState({ ...appliedFilterState, [filterOptions.filterField]: emptyFilterState[filterOptions.filterField] });
    setMenuOpen(false);
  }, [appliedFilterState, emptyFilterState, filterOptions, setAppliedFilterState]);

  const [filterDialogOpen, setFilterDialogOpen] = useState<boolean>(false);
  const onClickEditFilterMenuButton = useCallback(() => {
    setFilterDialogOpen(true);
    setMenuOpen(false);
  }, []);
  const onCloseFilterDialog = useCallback(() => setFilterDialogOpen(false), []);

  return (
    <Box id="HeaderCell" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      {(sortPath || filterOptions) ? (
        <Button
          ref={buttonRef}
          variant="text"
          color="inherit"
          size="small"
          sx={{ minWidth: 'unset', px: 0.5, fontWeight: 'bold', gap: 1 }}
          onClick={onClickOpenMenu}
        >
          {displayName}
          {!!activeSortMode && activeSortMode === 'asc' && <Icon path={mdiSort} size={0.75} color={theme.palette.primary.main} />}
          {!!activeSortMode && activeSortMode === 'desc' && <Icon path={mdiSort} vertical size={0.75} color={theme.palette.primary.main} />}
          {!!filterActive && <Icon path={mdiFilter} size={0.75} color={theme.palette.primary.main} />}
        </Button>
      ) : (
        <Typography sx={{ px: 0.5, fontWeight: 'bold' }}>
          {displayName}
        </Typography>
      )}
      {!!(sortPath || filterOptions) && (
        <Menu anchorEl={buttonRef.current} open={menuOpen} onClose={onCloseMenu}>
          {!!sortPath && (
            <MenuItem onClick={onClickSortAscending} sx={{ gap: 1 }} disabled={!!activeSortMode && activeSortMode === 'asc'}>
              {t('header-cell_sort-ascending-menu-button-label', 'Sort Ascending')}
              <Icon path={mdiSort} size={0.75} />
            </MenuItem>
          )}
          {!!sortPath && (
            <MenuItem onClick={onClickSortDescending} sx={{ gap: 1 }} disabled={!!activeSortMode && activeSortMode === 'desc'}>
              {t('header-cell_sort-descending-menu-button-label', 'Sort Descending')}
              <Icon path={mdiSort} vertical size={0.75} />
            </MenuItem>
          )}
          {!!sortPath && !!activeSortMode && (
            <MenuItem onClick={onClickSortReset} sx={{ gap: 1 }}>
              {t('header-cell_sort-resedt-menu-button-label', 'Reset Sort Order')}
              <Icon path={mdiSortVariantRemove} vertical size={0.75} />
            </MenuItem>
          )}
          {!!sortPath && <Divider />}
          {!!filterOptions && (
            <MenuItem onClick={onClickEditFilterMenuButton} sx={{ gap: 1 }}>
              {filterActive ? (
                <>
                  {t('header-cell_edit-filer-menu-button-label', 'Edit Filter')}
                  <Icon path={mdiFilterSettings} size={0.75} />
                </>
              ) : (
                <>
                  {t('header-cell_add-filer-menu-button-label', 'Add Filter')}
                  <Icon path={mdiFilterPlus} size={0.75} />
                </>
              )}
            </MenuItem>
          )}
          {!!filterActive && (
            <MenuItem onClick={onClickRemoveFilterMenuButton} sx={{ gap: 1 }}>
              {t('header-cell_remove-filer-menu-button-label', 'Remove Filter')}
              <Icon path={mdiFilterMinus} size={0.75} />
            </MenuItem>
          )}
        </Menu>
      )}
      {!!filterOptions && !!filterDialogOpen && (
        <IssueFilterDialog
          filterOptions={filterOptions}
          onClose={onCloseFilterDialog}
        />
      )}
    </Box>
  );
}
