import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import DocumentViewNavigationContext, { DocumentViewNavigationContextState } from 'documents/contexts/DocumentViewNavigationContext';
import { useNavigate } from 'react-router-dom';
import DocumentScope from 'documents/types/DocumentScope';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import ArchiveScopeId from 'documents/types/ArchiveScopeId';
import useCurrentCollaboratorRole from 'collaborators/hooks/useCurrentCollaboratorRole';
import RoleAction from 'projects/types/RoleAction';
import useFolderTreeQuery from 'documents-folders/hooks/useFolderTreeQuery';

export interface DocumentViewNavigationUrlParams {
  documentScope: DocumentScope,
}

interface DocumentFilterContextUrlProviderProps {
  children?: ReactNode,
  getRoute: (documentScope: DocumentScope) => string,
  documentScope: DocumentScope,
}

export default function DocumentViewNavigationContextProvider({
  children,
  getRoute,
  documentScope,
}: DocumentFilterContextUrlProviderProps) {
  const currentUserRole = useCurrentCollaboratorRole();
  const navigate = useNavigate();

  const [highlightedDocumentCommentId, setHighlightedDocumentCommentId] = useState<string | undefined>(undefined);
  const setDocumentScope = useCallback((value: DocumentScope) => {
    const route = getRoute(value);
    navigate(route);
  }, [getRoute, navigate]);

  useEffect(() => {
    if (documentScope.id === ArchiveScopeId.Public && !currentUserRole?.allowedActions?.has(RoleAction.Document_GetArchived)) {
      const route = getRoute({ key: DocumentScopeKey.Archive, id: ArchiveScopeId.Private });
      navigate(route);
    }
  }, [navigate, currentUserRole?.allowedActions, documentScope.id, getRoute]);

  const folderId = useMemo(() => (documentScope.key === DocumentScopeKey.Folder ? documentScope.id : undefined), [documentScope]);
  const { data: folderTreeData, isLoading: isLoadingFolderTreeData, isStale: isStaleFolderTreeData } = useFolderTreeQuery();
  useEffect(() => {
    if (!folderId || isLoadingFolderTreeData || isStaleFolderTreeData) return;
    if (folderTreeData && !folderTreeData.foldersById.has(folderId)) throw new Error('Folder not found');
  }, [folderId, folderTreeData, isLoadingFolderTreeData, isStaleFolderTreeData]);

  const state = useMemo<DocumentViewNavigationContextState>(() => ({
    documentScope,
    setDocumentScope,
    highlightedDocumentCommentId,
    setHighlightedDocumentCommentId,
  }), [documentScope, setDocumentScope, highlightedDocumentCommentId]);

  return (
    <DocumentViewNavigationContext.Provider value={state}>
      {children}
    </DocumentViewNavigationContext.Provider>
  );
}
