import { Palette } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/800.css';

const typography: TypographyOptions | ((palette: Palette) => TypographyOptions) = {
  fontFamily: 'Open Sans, sans-serif',
  fontSize: 14,
  htmlFontSize: 16,
};

export default typography;
