import { useMutation } from '@tanstack/react-query';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDocumentVersionUpdateMutationFn from 'documents/hooks/useDocumentVersionUpdateMutationFn';
import DocumentVersionDto from 'documents/types/DocumentVersionDto';
import DocumentVersionStatusUpdateDto from 'documents/types/DocumentVersionStatusUpdateDto';

export default function useDocumentVersionStatusUpdateMutation() {
  const mutationFn = useDocumentVersionUpdateMutationFn<DocumentVersionStatusUpdateDto>(ApiEndpoint.DocumentVersionStatus);
  return useMutation<DocumentVersionDto, unknown, DocumentVersionStatusUpdateDto>({ mutationFn });
}
