import React, { useEffect, useMemo, useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import useDocumentVersionQuery from 'documents/hooks/useDocumentVersionQuery';
import { useTranslation } from 'react-i18next';
import useDocumentViewerContext from 'documents-details/hooks/useDocumentViewerContext';
import DocumentDetailsTab from 'documents-details/types/DocumentDetailsTab';
import LinkedIssuesTab from 'documents-details/components/LinkedIssuesTab';
import InternalAnnotationsTab from 'documents-details/components/InternalAnnotationsTab';
import DocumentCommentsTab from 'documents-details/components/DocumentCommentsTab';
import useDocumentDetailsSelectionContext from 'documents-details/hooks/useDocumentDetailsSelectionContext';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';
import RoleAction from 'projects/types/RoleAction';

interface DocumentVersionDetailsPanelProps extends ISxProps {
  documentVersionId: string | undefined,
  onClickIssueItem: (issueId: string | undefined) => void,
}

export default function DocumentVersionDetailsPanel({
  sx,
  documentVersionId,
  onClickIssueItem,
}: DocumentVersionDetailsPanelProps) {
  const { t } = useTranslation('documents-details');
  const allowedActions = useAllowedActions();
  const canViewIssues = useMemo(() => allowedActions?.has(RoleAction.Issues), [allowedActions]);

  const { data: documentVersion } = useDocumentVersionQuery(documentVersionId);

  const [activeTab, setActiveTab] = useState<DocumentDetailsTab | undefined>(undefined);
  useEffect(() => {
    if (canViewIssues === undefined) return;
    setActiveTab((prev) => (prev !== undefined ? prev : canViewIssues ? DocumentDetailsTab.LinkedIssues : DocumentDetailsTab.InternalAnnotations));
  }, [canViewIssues]);
  const { selectedAnnotationNames, annotationItems } = useDocumentViewerContext();
  const { highlightedDocumentCommentId, setHighlightedDocumentCommentId } = useDocumentDetailsSelectionContext();
  const internalAnnotationNames = useMemo(() => (annotationItems ? new Set(annotationItems.filter((item) => !item.visoplanAnnotation).map((item) => item.viewerAnnotation.Id)) : undefined), [annotationItems]);
  const onChangeTab = (event: React.SyntheticEvent, newValue: DocumentDetailsTab) => {
    setActiveTab(newValue);
  };
  useEffect(() => {
    if (internalAnnotationNames && selectedAnnotationNames?.length) {
      if (selectedAnnotationNames.every((name) => internalAnnotationNames.has(name))) {
        setActiveTab(DocumentDetailsTab.InternalAnnotations);
      } else if (selectedAnnotationNames.every((name) => !internalAnnotationNames.has(name)) && canViewIssues) {
        setActiveTab(DocumentDetailsTab.LinkedIssues);
      }
    }
  }, [canViewIssues, internalAnnotationNames, selectedAnnotationNames, setActiveTab]);

  useEffect(() => {
    if (highlightedDocumentCommentId !== undefined) {
      setActiveTab(DocumentDetailsTab.Comments);
    }
  }, [highlightedDocumentCommentId, setActiveTab, setHighlightedDocumentCommentId]);

  if (!documentVersion) return <CenteredCircularProgress />;
  return (
    <Box id="DocumentVersionDetailsPanel" sx={{ height: '100%', display: 'grid', gridTemplateRows: 'auto 1fr' }}>
      <Box sx={{ ...sx, p: 2, pb: 4, overflow: 'auto', display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box>
          <Typography variant="h4">{documentVersion.originalFileName}</Typography>
        </Box>
      </Box>
      <Box
        id="DocumentsDetailsTabs"
        sx={{
          ...sx, display: 'flex', flexDirection: 'column', overflow: 'hidden',
        }}
      >
        <Box>
          {activeTab !== undefined && (
            <Tabs value={activeTab} onChange={onChangeTab} variant="fullWidth">
              {!!canViewIssues && <Tab id="DocumentsDetailsTabsIssues" value={DocumentDetailsTab.LinkedIssues} label={t('tabs_linked-issues-label', 'Issues')} />}
              <Tab id="DocumentsDetailsTabsAnnotations" value={DocumentDetailsTab.InternalAnnotations} label={t('tabs_internal-annotations-label', 'Annotations')} />
              <Tab id="DocumentsDetailsTabsComments" value={DocumentDetailsTab.Comments} label={t('tabs_comments-label', 'Comments')} />
            </Tabs>
          )}
        </Box>
        <Box sx={{
          flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden', boxShadow: 'inset 0px 24px 24px -24px rgba(0,0,0,0.1)',
        }}
        >
          {activeTab === DocumentDetailsTab.LinkedIssues && <LinkedIssuesTab sx={{ flexGrow: 1 }} onClickIssueItem={onClickIssueItem} />}
          {activeTab === DocumentDetailsTab.InternalAnnotations && <InternalAnnotationsTab sx={{ flexGrow: 1 }} />}
          {activeTab === DocumentDetailsTab.Comments && <DocumentCommentsTab sx={{ flexGrow: 1 }} />}
        </Box>
      </Box>
    </Box>
  );
}
