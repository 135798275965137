import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { Box, InputAdornment, TextField } from '@mui/material';
import PlanlistFilterContext, { PlanlistFilterContextState } from 'documents-lists/contexts/PlanlistFilterContext';
import RoundIconButton from 'common/components/RoundIconButton';
import PlanlistSortButtonMenu from 'documents-lists/components/PlanlistSortButtonMenu';

export default function PlanlistFilterToolBar() {
  const { t } = useTranslation('documents-lists');
  const { isFilterActive, searchString, setSearchString } = useContext<PlanlistFilterContextState>(PlanlistFilterContext);

  return (
    <Box sx={{ display: 'flex' }}>
      <TextField
        label={t('planlist-filter-toolbar_search-planlists', 'Search Planlists')}
        value={searchString ?? ''}
        onChange={(e) => setSearchString(e.target.value || undefined)}
        sx={{ flexGrow: 1 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isFilterActive
                ? <RoundIconButton Icon={CloseIcon} onClick={() => setSearchString(undefined)} />
                : <SearchIcon />}
            </InputAdornment>
          ),
        }}
      />
      <PlanlistSortButtonMenu sx={{ ml: 1 }} />
    </Box>
  );
}
