import React, { useCallback, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiTableArrowDown } from '@mdi/js';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useNamingSchemeQuery from 'naming-schemes/hooks/useNamingSchemeQuery';
import { max, range } from 'lodash';
import ProjectNamingGroupSeparatorType from 'naming-schemes/types/ProjectNamingGroupSeparatorType';

interface NamingSchemeExportButtonProps extends ISxProps {
  namingSchemeId: string,
  onError?: (message: string) => void,
}

export default function NamingSchemeExportButton({
  sx,
  namingSchemeId,
  onError,
}: NamingSchemeExportButtonProps) {
  const { t } = useTranslation('naming-schemes');
  const { data: namingScheme } = useNamingSchemeQuery(namingSchemeId);
  const [isWaitingForDownloadToStart, setIsWaitingForDownloadToStart] = useState(false);
  const getRequestErrorMessage = useRequestErrorMessage();

  const onClick = useCallback(async () => {
    if (!namingScheme) throw new Error('dependency error');
    setIsWaitingForDownloadToStart(true);
    try {
      const maxGroupElementCount = max(namingScheme.groups.map((group) => group.groupElements.length)) ?? 0;
      const formattedData: string[][] = range(0, maxGroupElementCount).map((index) => namingScheme.groups.flatMap((group) => [group.groupElements[index]?.name ?? '', group.groupElements[index]?.abbreviation ?? '', '']));
      const rows = [namingScheme.groups.flatMap((group) => [group.name, '', `${group.separator === ProjectNamingGroupSeparatorType.Dash ? '-' : group.separator === ProjectNamingGroupSeparatorType.Dot ? '.' : '_'}`]), [], ...formattedData];
      const csv = rows.map((row) => row.map((value) => `"${value.replace('"', '""')}"`).join(';')).join('\n');
      const element = document.createElement('a');
      element.setAttribute('href', `data:text/plain;charset=utf-8,%EF%BB%BF${encodeURIComponent(csv)}`);
      element.setAttribute('download', `${namingScheme.name} ${(new Date().toJSON().slice(0, 10))}.csv`);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      onError?.(getRequestErrorMessage(error));
    } finally {
      setIsWaitingForDownloadToStart(false);
    }
  }, [getRequestErrorMessage, namingScheme, onError]);
  return (
    <Button
      id="NamingSchemeExportButton"
      onClick={onClick}
      sx={{ ...sx, pl: 1, gap: 1 }}
      variant="outlined"
      disabled={isWaitingForDownloadToStart}
    >
      {!isWaitingForDownloadToStart && <Icon path={mdiTableArrowDown} size={1} />}
      {!!isWaitingForDownloadToStart && <CircularProgress size={12} />}
      {t('naming-scheme-export-button_label', 'Export as CSV')}
    </Button>
  );
}
