import useLabelsOdataQuery from 'labels/hooks/useLabelsOdataQuery';
import LabelDto from 'labels/types/LabelDto';
import LabelType from 'labels/types/LabelType';
import { QueryOptions } from 'odata-query';

export const PROJECT_ISSUE_PRIORITIES_ODATA_QUERY: Partial<QueryOptions<LabelDto>> = { filter: { type: LabelType[LabelType.IssuePriority], isDeleted: false }, orderBy: 'order asc' };

export default function useProjectIssuePrioritiesQuery() {
  return useLabelsOdataQuery(PROJECT_ISSUE_PRIORITIES_ODATA_QUERY);
}
