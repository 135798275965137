import { useCallback, useMemo } from 'react';
import { InfiniteData, UseQueryOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys, { DetailsByIdQueryKey } from 'api/hooks/useQueryKeys';

type DetailsQueryOptions<TEndpoint extends ApiEndpoint, TDto> = UseQueryOptions<TDto | undefined, unknown, TDto | undefined, DetailsByIdQueryKey<TEndpoint> | readonly []>;

export type DetailsUseQueryArgs<TEndpoint extends ApiEndpoint, TDto> = [id: string | undefined, options?: Omit<DetailsQueryOptions<TEndpoint, TDto>, 'intitialData' | 'queryKey'> | undefined];

export default function useDetailsQuery<TEndpoint extends ApiEndpoint, TDto extends { id: string } | string>(endpoint: TEndpoint, ...[id, options]: DetailsUseQueryArgs<TEndpoint, TDto>) {
  const { getDetailsByIdQueryKey, odataQueryKeyBase, odataInfiniteQueryKeyBase } = useDefaultEntityQueryKeys(endpoint);
  const queryKey = useMemo(() => (id ? getDetailsByIdQueryKey(id) : ([] as const)), [getDetailsByIdQueryKey, id]);
  const queryClient = useQueryClient();
  const initialData = useCallback(() => {
    const dtoFromOdata = queryClient
      .getQueriesData<TDto[] | undefined>({ queryKey: odataQueryKeyBase })
      .flatMap(([, cachedDtos]) => cachedDtos)
      .find((cachedDto) => typeof cachedDto !== 'string' && cachedDto?.id === id);
    if (dtoFromOdata) return dtoFromOdata;
    const dtoFromInfiniteOdata = queryClient
      .getQueriesData<InfiniteData<TDto[] | undefined>>({ queryKey: odataInfiniteQueryKeyBase })
      .flatMap(([, cachedInfiniteDatas]) => cachedInfiniteDatas?.pages.filter(Boolean).flatMap((dtos) => dtos))
      .find((infiniteData) => typeof infiniteData !== 'string' && infiniteData?.id === id);
    return dtoFromInfiniteOdata;
  }, [id, odataInfiniteQueryKeyBase, odataQueryKeyBase, queryClient]);
  const queryOptions = useMemo<DetailsQueryOptions<TEndpoint, TDto>>(() => ({
    ...options,
    queryKey,
    initialData,
    enabled: (options?.enabled !== false) && (queryKey.length > 0),
  }), [initialData, options, queryKey]);
  return useQuery(queryOptions);
}
