import { UseQueryResult } from '@tanstack/react-query';
import useOdataQuery, { OdataUseQueryArgs } from 'api/hooks/useOdataQuery';
import QueryFilterDto from 'query-filters/types/QueryFilterDto';
import QueryFilterEndpointMap from 'query-filters/types/QueryFilterEndpointMap';
import QueryFilterKind from 'query-filters/types/QueryFilterKind';

export default function useQueryFiltersOdataQuery<TQueryFilterKind extends QueryFilterKind, TEndpoint extends QueryFilterEndpointMap[TQueryFilterKind]>(
  endpoint: TEndpoint,
  ...args: OdataUseQueryArgs<TEndpoint, QueryFilterDto<TQueryFilterKind>>
): UseQueryResult<QueryFilterDto<TQueryFilterKind>[] | undefined, unknown> {
  return useOdataQuery<TEndpoint, QueryFilterDto<TQueryFilterKind>>(endpoint, ...args);
}
