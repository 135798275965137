import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';

export default function useSingletonQueryData<TEndpoint extends ApiEndpoint, TDto>(endpoint: TEndpoint) {
  const queryClient = useQueryClient();
  const { singletonDetailsQueryKey } = useDefaultEntityQueryKeys(endpoint);
  return useCallback(async () => await queryClient.fetchQuery<TDto>({ queryKey: singletonDetailsQueryKey }), [queryClient, singletonDetailsQueryKey]);
}
