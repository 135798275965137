import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import IssueDetailsPanel from 'issues/components/IssueDetailsPanel';
import useModelSelectionContext from 'models/hooks/useModelSelectionContext';
import ViewpointDto from 'issues/types/ViewpointDto';
import useViewer3dContext from 'models/hooks/useViewer3dContext';
import ViewpointBubbleItem from 'models/types/ViewpointBubbleItem';
import useViewpointsQuery from 'issues/hooks/useViewpointsQuery';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import useVector3Math from 'common/hooks/useVector3Math';
import EditViewpointsDialog from 'models/components/EditViewpointsDialog';
import useModelsInteractionContext from 'models/hooks/useModelsInteractionContext';
import EditComponentsDialog from 'models/components/EditComponentsDialog';
import { Outlet } from 'react-router-dom';

export default function ModelsIssueDetailsPanel() {
  const theme = useTheme();
  const { selectedIssueId, setSelectedIssueId, loadModelFileIds } = useModelSelectionContext();
  const { data: issue } = useIssueQuery(selectedIssueId);
  const { interactionMode } = useModelsInteractionContext();
  const onCloseIssueDetails = useCallback(() => setSelectedIssueId(undefined), [setSelectedIssueId]);

  const { setSelectedViewpoint } = useModelSelectionContext();
  const onClickViewpointItem = useCallback(async (nextViewpoint: ViewpointDto) => {
    if (issue) {
      loadModelFileIds(Object.keys(issue.modelFileComponentIds));
    }
    setSelectedViewpoint(nextViewpoint);
  }, [issue, loadModelFileIds, setSelectedViewpoint]);

  const [editViewpointsDialogOpen, setEditViewpointsDialogOpen] = useState<boolean>(false);
  const onClickEditViewpoints = useCallback(() => setEditViewpointsDialogOpen(true), []);
  const onCloseEditViewpointsDialog = useCallback(() => setEditViewpointsDialogOpen(false), []);

  const [editComponentsDialogOpen, setEditComponentsDialogOpen] = useState<boolean>(false);
  const onClickEditComponents = useCallback(() => setEditComponentsDialogOpen(true), []);
  const onCloseEditComponentsDialog = useCallback(() => setEditComponentsDialogOpen(false), []);

  const { distanceSqr } = useVector3Math();
  const { setViewpointBubbles } = useViewer3dContext();
  const { data: viewpoints } = useViewpointsQuery(issue ? { filter: { id: { in: issue.viewpointIds } } } : undefined);
  const viewpointBubbleItems = useMemo<ViewpointBubbleItem[] | undefined>(() => {
    if (!issue || !viewpoints) return undefined;
    return viewpoints.flatMap((viewpoint) => viewpoint.lines.map((line) => ({ viewpoint, line }))
      .filter(({ line }) => distanceSqr(line.start, line.end) < 0.001))
      .map(({ viewpoint, line }) => ({
        title: issue?.title ?? viewpoint.issueId,
        position: line.start,
        issueId: viewpoint.issueId,
        linkedComponentsGlobalIds: issue?.linkedComponentsGlobalIds ?? [],
      }));
  }, [distanceSqr, issue, viewpoints]);

  useEffect(() => {
    setViewpointBubbles(viewpointBubbleItems ?? []);
    return () => {
      setViewpointBubbles([]);
    };
  }, [setViewpointBubbles, viewpointBubbleItems]);

  return (
    <Box id="ModelsIssueDetailsPanel" sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: theme.palette.background.default }}>
      <IssueDetailsPanel
        onClose={onCloseIssueDetails}
        issueId={selectedIssueId}
        onClickViewpointItem={onClickViewpointItem}
        onClickEditViewpoints={onClickEditViewpoints}
        onClickEditComponents={onClickEditComponents}
      />
      {!!editViewpointsDialogOpen && !interactionMode && selectedIssueId && (
        <EditViewpointsDialog issueId={selectedIssueId} onClose={onCloseEditViewpointsDialog} />
      )}
      {!!editComponentsDialogOpen && !interactionMode && selectedIssueId && (
        <EditComponentsDialog issueId={selectedIssueId} onClose={onCloseEditComponentsDialog} />
      )}
      <Outlet />
    </Box>
  );
}
