import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash';

import { Modal } from '../../HOC';
import MentionView from '../MentionView';

import { Notification } from '../../Helpers/Folders';

interface NotificationModalProps {
  notification: Notification;
  isAutomationClose?: boolean;
  isRenderContent?: boolean;
  setAutomationClose?: React.Dispatch<React.SetStateAction<boolean>>;
  onDone: () => void;
}

export default function NotificationModal({
  notification,
  isAutomationClose,
  isRenderContent,
  setAutomationClose,
  onDone,
}: NotificationModalProps) {
  const { t } = useTranslation();
  const { title, description, doneText } = notification;

  useEffect(() => {
    if (isAutomationClose) {
      setTimeout(() => {
        onDone();
        if (setAutomationClose) setAutomationClose(false);
      }, 2000);
    }
  }, [isAutomationClose, setAutomationClose, onDone]);

  const descriptions = useMemo(() => (
    isArray(description) ? description : [description]
  ), [description]);

  return (
    // @ts-ignore
    <Modal>
      <div className="notification-modal--wrapper">
        <div className="notification-modal--title">{title}</div>
        {!isAutomationClose && isRenderContent && (
          <div className="notification-modal--error-description custom-scrollbar">
            {descriptions.map((desc) => (
              <p
                key={desc}
                className="notification-modal--description"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: desc }}
              />
            ))}
          </div>
        )}
        {!isAutomationClose && !isRenderContent && (
          <MentionView
            className="notification-modal--description"
            value={description}
            isRichText={undefined}
          />
        )}
        {!isAutomationClose && (
          <div className="notification-modal--action">
            <button
              type="button"
              className="btn btn--primary"
              onClick={onDone}
            >
              {doneText || t('done', 'Done')}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
}

NotificationModal.defaultProps = {
  isAutomationClose: false,
  isRenderContent: false,
  setAutomationClose: undefined,
};
