import DocumentAbbreviationDisplayMode from 'documents/types/DocumentAbbreviationDisplayMode';
import { Dispatch, SetStateAction, createContext } from 'react';

export interface DocumentOptionsContextState {
  abbreviationDisplayMode: DocumentAbbreviationDisplayMode,
  setAbbreviationDisplayMode: Dispatch<SetStateAction<DocumentAbbreviationDisplayMode>>,
}

const defaultValue: DocumentOptionsContextState = {
  abbreviationDisplayMode: DocumentAbbreviationDisplayMode.FullLength,
  setAbbreviationDisplayMode: () => {},
};

const DocumentOptionsContext = createContext<DocumentOptionsContextState>(defaultValue);
export default DocumentOptionsContext;
