import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import ProjectCopyDto from 'projects/types/ProjectCopyDto';
import ProjectDto from 'projects/types/ProjectDto';
import { useCallback } from 'react';

export default function useProjectCopyMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases: projectQueryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.Project);
  const { queryKeyBases: userQueryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.User);
  const { queryKeyBases: collaboratorsQueryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.Collaborator);
  const mutationFn = useCallback(async (projectCopyDto: ProjectCopyDto) => {
    await axiosInstance.post<ProjectDto>(`${ApiEndpoint.ProjectCopy}`, projectCopyDto);
  }, [axiosInstance]);
  const onSuccess = useCallback(() => {
    projectQueryKeyBases.forEach((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
    userQueryKeyBases.forEach((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
    collaboratorsQueryKeyBases.forEach((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
  }, [collaboratorsQueryKeyBases, projectQueryKeyBases, queryClient, userQueryKeyBases]);
  return useMutation<void, unknown, ProjectCopyDto>({
    mutationFn,
    onSuccess,
  });
}
