import { Dispatch, SetStateAction, createContext } from 'react';

export interface CollaboratorsSelectionContextState {
  selectedCollaboratorIds: string[],
  setSelectedCollaboratorIds: Dispatch<SetStateAction<string[]>>,
}

const defaultState: CollaboratorsSelectionContextState = {
  selectedCollaboratorIds: [],
  setSelectedCollaboratorIds: () => {},
};

const CollaboratorsSelectionContext = createContext<CollaboratorsSelectionContextState>(defaultState);
export default CollaboratorsSelectionContext;
