import {
  SET_IS_SAVE_ROLE_SETTING,
  SET_IS_SAVE_PROJECT_EMAIL_SETTING,
  SET_IS_SAVE_EMAIL_SETTING,
  SET_IS_SAVE_TAGS_SETTING,
  SET_IS_SAVE_ENCODING_SETTING,
  SET_IS_SAVE_DOCUMENTS_SETTING,
  SET_IS_SAVE_ISSUES_SETTING,
  SET_SIGNALR_CONNECTION_STATE,
  SET_MODEL_LEAVE_CONFIRM,
  SET_CANCEL_ISSUE_CREATION,
  SET_CONVERT_MODEL_PROGRESS,
  SET_SYNC_DATA,
  SET_FILTER_VALUES_FROM_OTHER_PAGE,
  SET_IS_CHATTING,
  SET_TO_IFC_FOLDER,
  SET_DOC_OPTION,
} from "./ActionTypes";

const initialState = {
  is_save_role_setting: false,
  is_save_project_email_setting: false,
  is_save_email_setting: false,
  is_save_tags_setting: false,
  is_save_encoding_setting: false,
  is_save_documents_setting: false,
  is_save_issues_setting: false,
  signalr_connection_state: false,
  is_model_leave_confirm: false,
  is_cancel_issue_creation: false,
  convert_model_progress: 0,
  filter_values_from_other_page: null,
  is_chatting: false,
  is_to_ifc_folder: false,
  sync_data: null,
};

const project = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_SAVE_ROLE_SETTING:
      return { ...state, is_save_role_setting: action.payload };
    case SET_IS_SAVE_PROJECT_EMAIL_SETTING:
      return { ...state, is_save_project_email_setting: action.payload };
    case SET_IS_SAVE_EMAIL_SETTING:
      return { ...state, is_save_email_setting: action.payload };
    case SET_IS_SAVE_TAGS_SETTING:
      return { ...state, is_save_tags_setting: action.payload };
    case SET_IS_SAVE_ENCODING_SETTING:
      return { ...state, is_save_encoding_setting: action.payload };
    case SET_IS_SAVE_DOCUMENTS_SETTING:
      return { ...state, is_save_documents_setting: action.payload };
    case SET_IS_SAVE_ISSUES_SETTING:
      return { ...state, is_save_issues_setting: action.payload };
    case SET_SIGNALR_CONNECTION_STATE:
      return { ...state, signalr_connection_state: action.payload };
    case SET_MODEL_LEAVE_CONFIRM:
      return { ...state, is_model_leave_confirm: action.payload };
    case SET_CANCEL_ISSUE_CREATION:
      return { ...state, is_cancel_issue_creation: action.payload };
    case SET_CONVERT_MODEL_PROGRESS:
      return { ...state, convert_model_progress: action.payload };
    case SET_SYNC_DATA:
      return { ...state, sync_data: action.payload };
    case SET_FILTER_VALUES_FROM_OTHER_PAGE:
      return { ...state, filter_values_from_other_page: action.payload };
    case SET_IS_CHATTING:
      return { ...state, is_chatting: action.payload };
    case SET_TO_IFC_FOLDER:
      return { ...state, is_to_ifc_folder: action.payload };
    case SET_DOC_OPTION:
      return { ...state, doc_option: action.payload };
    default:
      return state;
  }
};

export default project;
