import React, { useCallback } from 'react';
import {
  Box, FormControl, InputLabel, Button,
} from '@mui/material';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { useTranslation } from 'react-i18next';
import ISxProps from 'common/types/ISxProps';
import FolderSelect from 'documents-folders/components/FolderSelect';

interface MassEditFolderSelectProps extends ISxProps {
  label: string,
  selectedId: string | undefined,
  onChange: (id: string | undefined) => void,
  disabled?: boolean,
}

export default function MassEditFolderSelectFormControl({
  label,
  selectedId,
  onChange,
  disabled,
  sx,
}: MassEditFolderSelectProps) {
  const { t } = useTranslation('documents');
  const onChangeSelectedId = useCallback((id: string) => {
    if (id === 'empty') onChange('');
    else onChange(id);
  }, [onChange]);

  return (
    <Box sx={{ ...sx, display: 'flex', alignItems: 'flex-end' }}>
      <FormControl sx={{ flexGrow: 1 }}>
        <InputLabel id="mass-edit-drawer_folder-select-label" shrink>{label}</InputLabel>
        <FolderSelect
          labelId="mass-edit-drawer_folder-select-label"
          label={label}
          value={selectedId ?? ''}
          disabled={disabled}
          onChange={(e) => onChangeSelectedId(e.target.value)}
          renderValue={!selectedId?.length
            ? () => ((selectedId === undefined)
              ? <em>Unchanged</em>
              : <em>Set empty value</em>)
            : undefined}
          displayEmpty
          notched
        />
      </FormControl>
      <Box title={t('mass-edit-drawer_folder-select_set-unchanged-button-tooltip', 'Set field unchanged')}>
        <Button
          sx={{ minWidth: 'unset', ml: 1, px: 1 }}
          color="secondary"
          variant="contained"
          onClick={() => onChange(undefined)}
          disabled={selectedId === undefined}
        >
          <BackspaceIcon />
        </Button>
      </Box>
    </Box>
  );
}
