import React, { useCallback, useMemo } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import NamingSchemeGroupElementItem from 'naming-schemes/types/NamingSchemeGroupElementItem';
import NamingSchemeDateType from 'naming-schemes/types/NamingSchemeDateType';
import { useTranslation } from 'react-i18next';
import useNamingSchemeDateTypeTranslations from 'naming-schemes/hooks/useNamingSchemeDateTypeTranslations';

interface MutateNamingSchemeDateGroupPanelProps extends ISxProps {
  elementItems: NamingSchemeGroupElementItem[],
  onChange: (value: NamingSchemeGroupElementItem[]) => void,
}

export default function MutateNamingSchemeDateGroupPanel({
  sx,
  elementItems,
  onChange,
}: MutateNamingSchemeDateGroupPanelProps) {
  const { t } = useTranslation('naming-schemes');
  const namingSchemeDateTypeTranslations = useNamingSchemeDateTypeTranslations();
  const item = useMemo<NamingSchemeGroupElementItem>(() => (elementItems[0] ?? { uuid: crypto.randomUUID() }), [elementItems]);
  const onChangeType = useCallback((event: SelectChangeEvent<NamingSchemeDateType>) => {
    if (typeof event.target.value === 'string' || !item) return;
    onChange([{ ...item, dateType: event.target.value }]);
  }, [item, onChange]);
  return (
    <Box id="MutateNamingSchemeDateGroupPanel" sx={{ display: 'flex', flexDirection: 'column', gap: 3, ...sx }}>
      <Typography variant="h5">{t('mutate-naming-scheme-date-group-panel_title', 'Date Group Options')}</Typography>
      <FormControl>
        <InputLabel id="mutate-naming-scheme-date-group-panel_group-type-select-label">{t('mutate-naming-scheme-date-group-panel_date-type-select-label', 'Date Type')}</InputLabel>
        <Select<NamingSchemeDateType>
          value={elementItems[0]?.dateType ?? ''}
          label={t('mutate-naming-scheme-date-group-panel_date-type-select-label', 'Date Type')}
          onChange={onChangeType}
        >
          <MenuItem value={NamingSchemeDateType.DDMMYY}>{namingSchemeDateTypeTranslations[NamingSchemeDateType.DDMMYY]}</MenuItem>
          <MenuItem value={NamingSchemeDateType.DDMMYYYY}>{namingSchemeDateTypeTranslations[NamingSchemeDateType.DDMMYYYY]}</MenuItem>
          <MenuItem value={NamingSchemeDateType.DD_MM_YY}>{namingSchemeDateTypeTranslations[NamingSchemeDateType.DD_MM_YY]}</MenuItem>
          <MenuItem value={NamingSchemeDateType.DD_MM_YYYY}>{namingSchemeDateTypeTranslations[NamingSchemeDateType.DD_MM_YYYY]}</MenuItem>
          <MenuItem value={NamingSchemeDateType.YYMMDD}>{namingSchemeDateTypeTranslations[NamingSchemeDateType.YYMMDD]}</MenuItem>
          <MenuItem value={NamingSchemeDateType.YYYYMMDD}>{namingSchemeDateTypeTranslations[NamingSchemeDateType.YYYYMMDD]}</MenuItem>
          <MenuItem value={NamingSchemeDateType.YYYY_MM_DD}>{namingSchemeDateTypeTranslations[NamingSchemeDateType.YYYY_MM_DD]}</MenuItem>
          <MenuItem value={NamingSchemeDateType.YY_MM_DD}>{namingSchemeDateTypeTranslations[NamingSchemeDateType.YY_MM_DD]}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
