import React, {
  ReactNode, useEffect, useMemo, useState,
} from 'react';
import DocumentOptionsContext, { DocumentOptionsContextState } from 'documents/contexts/DocumentOptionsContext';
import DocumentAbbreviationDisplayMode from 'documents/types/DocumentAbbreviationDisplayMode';

interface DocumentOptionsContextProviderProps {
  children?: ReactNode,
}

export default function DocumentOptionsContextProvider({
  children,
}: DocumentOptionsContextProviderProps) {
  const [abbreviationDisplayMode, setAbbreviationDisplayMode] = useState<DocumentAbbreviationDisplayMode>(localStorage.getItem('documentAbbreviationDisplayMode') as DocumentAbbreviationDisplayMode ?? DocumentAbbreviationDisplayMode.FullLength);

  useEffect(() => {
    const storedAbbreviationDisplayMode = localStorage.getItem('documentAbbreviationDisplayMode') as DocumentAbbreviationDisplayMode | null;
    if (abbreviationDisplayMode !== storedAbbreviationDisplayMode) localStorage.setItem('documentAbbreviationDisplayMode', abbreviationDisplayMode);
  }, [abbreviationDisplayMode]);

  const state = useMemo<DocumentOptionsContextState>(() => ({
    abbreviationDisplayMode,
    setAbbreviationDisplayMode,
  }), [abbreviationDisplayMode]);

  return (
    <DocumentOptionsContext.Provider value={state}>
      {children}
    </DocumentOptionsContext.Provider>
  );
}
