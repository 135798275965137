import { QueryKey } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useListByIdsQuery, { LisyByIdsUseQueryArgs } from 'api/hooks/useListByIdsQuery';
import { ListByIdsQueryKey } from 'api/hooks/useQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import { useCallback } from 'react';

export default function useResourcesQuery(...[argIds, options]: LisyByIdsUseQueryArgs<ApiEndpoint.Resource, string>) {
  const axiosInstance = useAxiosInstance();
  const resourcesListByIdsQueryFn = useCallback(async ({ queryKey }: { queryKey: QueryKey }) => {
    const [, , , ids] = queryKey as ListByIdsQueryKey<ApiEndpoint.Resource>;
    return await Promise.all(ids.map(async (id) => {
      const url = `${ApiEndpoint.Resource}/${id}`;
      const response = await axiosInstance.get<Blob>(url, { responseType: 'blob' });
      const href = URL.createObjectURL(response.data);
      return href;
    }));
  }, [axiosInstance]);

  return useListByIdsQuery(
    ApiEndpoint.Resource,
    argIds,
    {
      ...options,
      queryFn: resourcesListByIdsQueryFn,
    },
  );
}
