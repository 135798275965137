import useEmailsUploadContext from 'emails/hooks/useEmailsUploadContext';
import useValidEmailFileExtensions from 'emails/hooks/useValidEmailFileExtensions';
import React, { RefObject, useCallback, useMemo } from 'react';

interface EmailUploadFileInputProps {
  inputRef: RefObject<HTMLInputElement>,
}

export default function EmailUploadFileInput({
  inputRef,
}: EmailUploadFileInputProps) {
  const {
    stagedPayload,
    openWizard,
    addToEmailsStagedForUpload,
  } = useEmailsUploadContext();

  const onChangeFileInput = useCallback(() => {
    if (!inputRef.current) throw new Error('File input missing');
    const files = inputRef.current?.files ? Array.from(inputRef.current.files) : null;

    // When selecting the same file a second time after cancelling, the change event wouldn't trigger
    // because the value did not change => clear the input value before staging.
    // eslint-disable-next-line no-param-reassign
    inputRef.current.value = null!;

    if (!files) return;
    addToEmailsStagedForUpload({
      ...(stagedPayload ?? {}),
      files,
    });
    openWizard();
  }, [addToEmailsStagedForUpload, inputRef, openWizard, stagedPayload]);

  const validFileExtensions = useValidEmailFileExtensions();
  const accept = useMemo(() => validFileExtensions.join(','), [validFileExtensions]);

  return <input ref={inputRef} hidden accept={accept} multiple type="file" onChange={onChangeFileInput} />;
}
