import { PaletteOptions } from '@mui/material';

const palette: PaletteOptions = {
  primary: {
    main: '#4B6FFC',
    contrastText: '#FFFFFF',
    light: '#DFE6FE',
  },
  secondary: {
    main: '#E7EEFF',
    light: '#EFF4FF',
    dark: '#D8E1F2',
    contrastText: '#4B6FFC',
  },
  text: {
    primary: '#434b59',
    secondary: '#A7A7A7',
    disabled: '#cecece',
  },
  info: {
    main: '#4B6FFC',
  },
  success: {
    main: '#42A563',
  },
  divider: '#E5E5E5',
};

export default palette;
