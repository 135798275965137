import { Box } from '@mui/material';
import { ColDef, FirstDataRenderedEvent } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import React, { useCallback, useMemo, useRef } from 'react';
import AG_GRID_LOCALE_DE from 'setup/agGrid.locale.de';
import AG_GRID_LOCALE_EN from 'setup/agGrid.locale.en';
import i18n from 'setup/i18n';
import IssueDto from 'issues/types/IssueDto';
import useIssuePrintContext from 'issues/hooks/useIssuePrintContext';
import DateCell from 'issues/components/cell-renderers/DateCell';
import CreatorCell from 'issues/components/cell-renderers/CreatorCell';
import ReviewerCell from 'issues/components/cell-renderers/ReviewerCell';
import AssignedUsersCell from 'issues/components/cell-renderers/AssignedUsersCell';
import LabelIdsChipsCell from 'issues/components/cell-renderers/LabelIdsChipsCell';
import StatusChipCell from 'issues/components/cell-renderers/StatusChipCell';
import TypeChipCell from 'issues/components/cell-renderers/TypeChipCell';
import PriorityChipCell from 'issues/components/cell-renderers/PriorityChipCell';
import ThumbnailImageCell from 'issues/components/cell-renderers/ThumbnailImageCell';
import IssuePrintViewColumns from 'issues/types/IssuePrintViewColumns';
import usePrintColumnTranslations from 'issues/hooks/usePrintColumnTranslations';

interface IssuePrintDataGridProps {
  rowData: IssueDto[],
  pageNumber: number,
  onFinishedLoading?: () => void,
}

export default function IssuePrintDataGrid({ rowData, pageNumber, onFinishedLoading }: IssuePrintDataGridProps) {
  const { columnsIncludedInPrint, setGridTotalPages } = useIssuePrintContext();
  const printColumnTranslations = usePrintColumnTranslations();

  const colDefs = useMemo<ColDef[]>(() => {
    const columns: ColDef[] = [];
    if (columnsIncludedInPrint.has(IssuePrintViewColumns.IssueNumber)) {
      columns.push({ field: 'issueNumber', headerName: '#', flex: 1 });
    }
    if (columnsIncludedInPrint.has(IssuePrintViewColumns.Title)) {
      columns.push({ field: 'title', headerName: printColumnTranslations[IssuePrintViewColumns.Title], flex: 1 });
    }
    if (columnsIncludedInPrint.has(IssuePrintViewColumns.Status)) {
      columns.push({ field: 'issueStatus', headerName: printColumnTranslations[IssuePrintViewColumns.Status], flex: 1, cellRenderer: StatusChipCell });
    }
    if (columnsIncludedInPrint.has(IssuePrintViewColumns.Type)) {
      columns.push({ field: 'issueType', headerName: printColumnTranslations[IssuePrintViewColumns.Type], flex: 1, cellRenderer: TypeChipCell });
    }
    if (columnsIncludedInPrint.has(IssuePrintViewColumns.Priority)) {
      columns.push({ field: 'issuePriority', headerName: printColumnTranslations[IssuePrintViewColumns.Priority], flex: 1, cellRenderer: PriorityChipCell });
    }
    if (columnsIncludedInPrint.has(IssuePrintViewColumns.Assignee)) {
      columns.push({ field: 'assignedUsers', headerName: printColumnTranslations[IssuePrintViewColumns.Assignee], flex: 1, cellRenderer: AssignedUsersCell });
    }
    if (columnsIncludedInPrint.has(IssuePrintViewColumns.Reviewer)) {
      columns.push({ field: 'reviewer', headerName: printColumnTranslations[IssuePrintViewColumns.Reviewer], flex: 1, cellRenderer: ReviewerCell });
    }
    if (columnsIncludedInPrint.has(IssuePrintViewColumns.Created)) {
      columns.push({ field: 'creationDate', headerName: printColumnTranslations[IssuePrintViewColumns.Created], flex: 1, cellRenderer: DateCell });
    }
    if (columnsIncludedInPrint.has(IssuePrintViewColumns.Updated)) {
      columns.push({ field: 'editDate', headerName: printColumnTranslations[IssuePrintViewColumns.Updated], flex: 1, cellRenderer: DateCell });
    }
    if (columnsIncludedInPrint.has(IssuePrintViewColumns.Due)) {
      columns.push({ field: 'dueDate', headerName: printColumnTranslations[IssuePrintViewColumns.Due], flex: 1, cellRenderer: DateCell });
    }
    if (columnsIncludedInPrint.has(IssuePrintViewColumns.Start)) {
      columns.push({ field: 'startingDate', headerName: printColumnTranslations[IssuePrintViewColumns.Start], flex: 1, cellRenderer: DateCell });
    }
    if (columnsIncludedInPrint.has(IssuePrintViewColumns.Creator)) {
      columns.push({ field: 'createAuthor', headerName: printColumnTranslations[IssuePrintViewColumns.Creator], flex: 1, cellRenderer: CreatorCell });
    }
    if (columnsIncludedInPrint.has(IssuePrintViewColumns.Tags)) {
      columns.push({ field: 'tags', headerName: printColumnTranslations[IssuePrintViewColumns.Tags], flex: 1, cellRenderer: LabelIdsChipsCell });
    }
    if (columnsIncludedInPrint.has(IssuePrintViewColumns.Disciplines)) {
      columns.push({ field: 'disciplines', headerName: printColumnTranslations[IssuePrintViewColumns.Disciplines], flex: 1, cellRenderer: LabelIdsChipsCell });
    }
    if (columnsIncludedInPrint.has(IssuePrintViewColumns.Buildings)) {
      columns.push({ field: 'buildings', headerName: printColumnTranslations[IssuePrintViewColumns.Buildings], flex: 1, cellRenderer: LabelIdsChipsCell });
    }
    if (columnsIncludedInPrint.has(IssuePrintViewColumns.Floors)) {
      columns.push({ field: 'floors', headerName: printColumnTranslations[IssuePrintViewColumns.Floors], flex: 1, cellRenderer: LabelIdsChipsCell });
    }
    if (columnsIncludedInPrint.has(IssuePrintViewColumns.ThumbnailImage)) {
      columns.push({ field: 'thumbnailImage', cellStyle: { justifyContent: 'center' }, headerName: printColumnTranslations[IssuePrintViewColumns.ThumbnailImage], flex: 1, cellRenderer: ThumbnailImageCell });
    }
    return columns;
  }, [columnsIncludedInPrint, printColumnTranslations]);

  const defaultColDef = useMemo<ColDef>(() => ({
    resizable: true,
    filterParams: {
      buttons: ['reset'],
    },
  }), []);

  const gridRef = useRef<AgGridReact>(null);

  const localeText = useMemo<{ [key: string]: string; }>(() => (i18n.language.toLocaleLowerCase().startsWith('de') ? AG_GRID_LOCALE_DE : AG_GRID_LOCALE_EN), []);

  const checkIfLoaded = useCallback(() => {
    const currentPage = gridRef.current?.api.paginationGetCurrentPage();
    const totalRows = gridRef.current?.api.getDisplayedRowCount();
    if (onFinishedLoading && pageNumber === currentPage && totalRows === rowData.length) {
      onFinishedLoading();
    } else {
      setTimeout(checkIfLoaded, 100);
    }
  }, [onFinishedLoading, pageNumber, rowData.length]);

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    setGridTotalPages(params.api.paginationGetTotalPages());
    params.api.paginationGoToPage(pageNumber);
    const state = localStorage.getItem('issue-print-data-grid_state');
    if (state) {
      params.api.applyColumnState({ state: JSON.parse(state), applyOrder: true });
    }
  }, [pageNumber, setGridTotalPages]);

  const onColumnsChanged = useCallback(() => {
    const state = gridRef.current?.api.getColumnState();
    localStorage.setItem('issue-print-data-grid_state', JSON.stringify(state)); // ag-302-start-page-number
  }, []);

  return (
    <Box
      id="IssuePrintDataGrid"
      className="ag-theme-material ag-theme-visoplan table-view-data-grid"
      sx={{ height: '100%', width: '100%' }}
    >
      <AgGridReact
        rowHeight={54}
        ref={gridRef}
        localeText={localeText}
        rowData={rowData}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        rowSelection="single"
        suppressHorizontalScroll
        pagination
        paginationAutoPageSize
        onRowDataUpdated={checkIfLoaded}
        onFirstDataRendered={onFirstDataRendered}
        onColumnResized={onColumnsChanged}
        onColumnMoved={onColumnsChanged}
        onSortChanged={onColumnsChanged}
        suppressPaginationPanel
        suppressRowClickSelection
      />
    </Box>
  );
}
