import { useMemo } from 'react';
import useProjectIssueStatusesQuery from 'issues/hooks/useProjectIssueStatusesQuery';
import LabelDto from 'labels/types/LabelDto';

export function closedIssueStatusFilterPredicate(issueStatus: LabelDto) {
  return issueStatus.originalName === 'Closed';
}

export default function useClosedIssueStatus() {
  const { data: issueStatuses } = useProjectIssueStatusesQuery();
  return useMemo(() => issueStatuses?.find((status) => status.originalName === 'Closed'), [issueStatuses]);
}
