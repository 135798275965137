import React, { useMemo } from 'react';
import { useTheme } from '@mui/material';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import useIssuesOdataQuery from 'issues/hooks/useIssuesOdataQuery';
import Color from 'color';
import useIsuesCalendarOdataQuery from 'issues/hooks/useIssuesCalendarOdataQuery';

export default function DueIssuesPickersDay(props: PickersDayProps<Moment>) {
  const { day } = props;
  const theme = useTheme();
  const issuesOdataQuery = useIsuesCalendarOdataQuery(day);
  const { data: issues } = useIssuesOdataQuery(issuesOdataQuery);
  const backgroundColor = useMemo(() => (issues?.some((issue) => issue.dueDate && moment(issue.dueDate).isSame(day, 'day')) ? new Color(theme.palette.error.main).alpha(0.15).hexa() : undefined), [day, issues, theme.palette.error.main]);
  return (
    <PickersDay {...props} sx={{ backgroundColor }} />
  );
}
