import React, { useCallback, useMemo } from 'react';
import { Checkbox } from '@mui/material';
import useCollaboratorsSelectionContext from 'collaborators/hooks/useCollaboratorSelectionContext';
import useCollaboratorsFilterContext from 'collaborators/hooks/useCollaboratorFilterContext';

export default function CheckboxHeaderCell() {
  const { selectedCollaboratorIds, setSelectedCollaboratorIds } = useCollaboratorsSelectionContext();
  const { filteredCollaboratorIds } = useCollaboratorsFilterContext();
  const allSelected = useMemo(() => !!filteredCollaboratorIds?.length && selectedCollaboratorIds.length === filteredCollaboratorIds.length, [filteredCollaboratorIds, selectedCollaboratorIds.length]);
  const indeterminate = useMemo(() => !!filteredCollaboratorIds && selectedCollaboratorIds.length > 0 && selectedCollaboratorIds.length < filteredCollaboratorIds.length, [filteredCollaboratorIds, selectedCollaboratorIds.length]);
  const disabled = useMemo(() => !filteredCollaboratorIds?.length, [filteredCollaboratorIds?.length]);

  const onClick = useCallback(() => {
    if (allSelected) setSelectedCollaboratorIds([]);
    else setSelectedCollaboratorIds(filteredCollaboratorIds ?? []);
  }, [allSelected, filteredCollaboratorIds, setSelectedCollaboratorIds]);

  return <Checkbox checked={allSelected} disabled={disabled} indeterminate={indeterminate} sx={{ ml: '25px' }} onClick={onClick} />;
}
