import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface RestoreLabelSuccessDialogProps {
  labelTypeName: string,
  onClose: () => void,
}

export default function RestoreLabelSuccessDialog({
  labelTypeName,
  onClose,
}: RestoreLabelSuccessDialogProps) {
  const { t } = useTranslation('settings');
  return (
    <Dialog open id="RestoreLabelSuccessDialog">
      <DialogTitle>{t('restore-label-success-dialog_title', '{{labelTypeName}} Restored', { labelTypeName })}</DialogTitle>
      <DialogContent>
        <Alert severity="success">{t('restore-label-success-dialog_success-message', 'The {{labelTypeName}} has been restored.', { labelTypeName })}</Alert>
      </DialogContent>
      <DialogActions sx={{ gap: 2, justifyContent: 'space-between' }}>
        <Button variant="contained" color="primary" onClick={onClose}>
          {t('restore-label-success-dialog_confirm-button-label', 'Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
