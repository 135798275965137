import React, { useMemo } from 'react';
import { Box, Button } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import { useTranslation } from 'react-i18next';
import { mdiAccountArrowRight } from '@mdi/js';
import Icon from '@mdi/react';
import useOpenIssueStatus from 'issues/hooks/useOpenIssueStatus';
import useCurrentCollaboratorQuery from 'collaborators/hooks/useCurrentCollaboratorQuery';

interface IssueAssignActionButtonProps extends ISxProps {
  issueId: string,
  onClickAssign: () => void,
}

export default function IssueAssignActionButton({
  sx,
  issueId,
  onClickAssign,
}: IssueAssignActionButtonProps) {
  const { t } = useTranslation('issues');
  const { data: issue, isLoading: isLoadingIssue } = useIssueQuery(issueId);
  const { data: currentCollaborator } = useCurrentCollaboratorQuery();
  const openIssueStatus = useOpenIssueStatus();
  const assigned = useMemo(() => issue?.assignedCollaboratorIds && issue.assignedCollaboratorIds.length > 0, [issue]);
  const isAssignedToCurrentUser = useMemo(() => (!!issue?.assignedCollaboratorIds?.length && !!currentCollaborator && issue.assignedCollaboratorIds.some((id) => id === currentCollaborator.id)), [currentCollaborator, issue]);
  const canAssign = useMemo(() => {
    if (!issue?.issueStatus || !openIssueStatus) return undefined;
    return issue.issueStatus === openIssueStatus.id && !isAssignedToCurrentUser;
  }, [isAssignedToCurrentUser, issue, openIssueStatus]);

  if (!issueId || !canAssign) return null;
  return (
    <Box sx={{ ...sx }}>
      <Button
        id="IssueAssignActionButton"
        variant="contained"
        color="secondary"
        onClick={onClickAssign}
        disabled={!canAssign || isLoadingIssue}
        sx={{ gap: 1 }}
        size="small"
      >
        <Icon path={mdiAccountArrowRight} size={0.5} />
        {!assigned && t('assign-action-button_assign', 'Assign')}
        {!!assigned && t('assign-action-button_reassign', 'Change')}
      </Button>
    </Box>
  );
}
