import React, { SyntheticEvent, useCallback, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent, Switch, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ColorPickerControl from 'common/components/ColorPickerControl';
import useViewer3dContext from 'models/hooks/useViewer3dContext';
import { CameraProjections, NavigationModes } from 'Components/Models/Types/base-types';
import { DEFAULT_FLOOR_PLAN_SETTINGS, DEFAULT_VIEWER_3D_SETTINGS } from 'models/contexts/Viewer3dContextProvider';

interface Viewer3dSettingsDialogProps {
  onClose: (confirmed: boolean) => void,
}

export default function Viewer3dSettingsDialog({
  onClose,
}: Viewer3dSettingsDialogProps) {
  const { t } = useTranslation('models');
  const {
    viewer3dSettings,
    setViewer3dSettings,
    floorPlanSettings,
    setFloorPlanSettings,
  } = useViewer3dContext();
  const [stagedViewer3dSettings, setStagedViewer3dSettings] = useState(viewer3dSettings);
  const [stagedFloorPlanSettings, setStagedFloorPlanSettings] = useState(floorPlanSettings);
  const onClickConfirm = useCallback(() => {
    setViewer3dSettings(stagedViewer3dSettings);
    setFloorPlanSettings(stagedFloorPlanSettings);
    onClose(true);
  }, [onClose, setFloorPlanSettings, setViewer3dSettings, stagedFloorPlanSettings, stagedViewer3dSettings]);
  const onClickCancel = useCallback(() => onClose(false), [onClose]);
  const onClickResetToDefaultsButtonLabel = useCallback(() => {
    setStagedViewer3dSettings(DEFAULT_VIEWER_3D_SETTINGS);
    setStagedFloorPlanSettings(DEFAULT_FLOOR_PLAN_SETTINGS);
  }, []);

  const onChangeNavigationModeSelect = useCallback((event: SelectChangeEvent<NavigationModes>) => {
    const { value } = event.target;
    if (typeof value === 'string') return;
    setStagedViewer3dSettings((prev) => ({ ...prev, control: value, camera: value === NavigationModes.Standard ? CameraProjections.Perspective : prev.camera }));
  }, [setStagedViewer3dSettings]);
  const onChangeCameraModeSelect = useCallback((event: SelectChangeEvent<CameraProjections>) => {
    const { value } = event.target;
    if (typeof value === 'string') return;
    setStagedViewer3dSettings((prev) => ({ ...prev, camera: value, control: value === CameraProjections.Orthographic ? NavigationModes.Mouse : prev.control }));
  }, [setStagedViewer3dSettings]);
  const onChangeShowNavCubeSwitch = useCallback((event: SyntheticEvent, checked: boolean) => {
    setStagedViewer3dSettings((prev) => ({ ...prev, navCube: checked }));
  }, []);
  const onChangeShowSpacesSwitch = useCallback((event: SyntheticEvent, checked: boolean) => {
    setStagedViewer3dSettings((prev) => ({ ...prev, spaces: checked }));
  }, []);
  const onChangeShowOpeningsSwitch = useCallback((event: SyntheticEvent, checked: boolean) => {
    setStagedViewer3dSettings((prev) => ({ ...prev, openings: checked }));
  }, []);
  const onChangeShowAxisGridSwitch = useCallback((event: SyntheticEvent, checked: boolean) => {
    setStagedViewer3dSettings((prev) => ({ ...prev, grid: checked }));
  }, []);
  const onChangeViewer3dBackgroundColor = useCallback((value: string) => {
    setStagedViewer3dSettings((prev) => ({ ...prev, background: value }));
  }, []);
  const onChangeViewer3dSectionOutlineColor = useCallback((value: string) => {
    setStagedViewer3dSettings((prev) => ({ ...prev, sectionOutline: value }));
  }, []);
  const onChangeViewer3dSectionAreasColor = useCallback((value: string) => {
    setStagedViewer3dSettings((prev) => ({ ...prev, sectionSpace: value }));
  }, []);

  const onChangeFloorPlanVisible = useCallback((event: SyntheticEvent, checked: boolean) => {
    setStagedFloorPlanSettings((prev) => ({ ...prev, visible: checked }));
  }, [setStagedFloorPlanSettings]);

  return (
    <Dialog open id="Viewer3dSettingsDialog">
      <DialogTitle component="div" sx={{ display: 'flex', gap: 6, justifyContent: 'space-between', alignItems: 'baseline' }}>
        <Typography variant="h3">{t('viewer-3d-settings-dialog_title', '3D View Settings')}</Typography>
        <Button variant="outlined" onClick={onClickResetToDefaultsButtonLabel} size="small">{t('viewer-3d-settings-dialog_reset-to-defaults-button-title', 'Load defaults')}</Button>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', py: 4, gap: 4 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="h4">
              {t('viewer-3d-settings-dialog_general-section-header', 'General')}
            </Typography>
            <FormControl>
              <InputLabel id="viewer-3d-settings-dialog_navigation-mode-select-label">
                {t('viewer-3d-settings-dialog_navigation-mode-select-label', 'Navigation Mode')}
              </InputLabel>
              <Select
                id="viewer-3d-settings-dialog_navigation-mode-select"
                labelId="viewer-3d-settings-dialog_navigation-mode-select-label"
                value={stagedViewer3dSettings.control}
                onChange={onChangeNavigationModeSelect}
                label={t('viewer-3d-settings-dialog_navigation-mode-select-label', 'Navigation Mode')}
              >
                <MenuItem value={NavigationModes.Standard}>{t('viewer-3d-settings-dialog_navigation-mode-fly', 'Fly (WASD Keys & Mouse)')}</MenuItem>
                <MenuItem value={NavigationModes.Mouse}>{t('viewer-3d-settings-dialog_navigation-mode-orbit', 'Orbit (Mouse)')}</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="viewer-3d-settings-dialog_camera-mode-select-label">
                {t('viewer-3d-settings-dialog_camera-mode-select-label', 'Camera Mode')}
              </InputLabel>
              <Select
                id="viewer-3d-settings-dialog_camera-mode-select"
                labelId="viewer-3d-settings-dialog_camera-mode-select-label"
                value={stagedViewer3dSettings.camera}
                onChange={onChangeCameraModeSelect}
                label={t('viewer-3d-settings-dialog_camera-mode-select-label', 'Camera Mode')}
              >
                <MenuItem value={CameraProjections.Perspective}>{t('viewer-3d-settings-dialog_camera-mode-perspective', 'Perspective')}</MenuItem>
                <MenuItem value={CameraProjections.Orthographic}>{t('viewer-3d-settings-dialog_camera-mode-perspective', 'Orthogonal')}</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              sx={{ ml: 0, pt: 0, gap: 1 }}
              checked={stagedViewer3dSettings.navCube}
              onChange={onChangeShowNavCubeSwitch}
              control={<Switch />}
              label={t('viewer-3d-settings-dialog_show-nav-cube-switch', 'Show Navigation Cube')}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="h4">
              {t('viewer-3d-settings-dialog_adjust-3d-view-section-header', 'Adjust 3D View')}
            </Typography>
            <FormControlLabel
              checked={stagedViewer3dSettings.spaces}
              onChange={onChangeShowSpacesSwitch}
              control={<Switch />}
              label={t('viewer-3d-settings-dialog_show-spaces-switch', 'Show Spaces')}
              sx={{ ml: 0, pt: 0, gap: 1 }}
            />
            <FormControlLabel
              checked={stagedViewer3dSettings.openings}
              onChange={onChangeShowOpeningsSwitch}
              control={<Switch />}
              label={t('viewer-3d-settings-dialog_show-openings-switch', 'Show Openings')}
              sx={{ ml: 0, pt: 0, gap: 1 }}
            />
            <FormControlLabel
              checked={stagedViewer3dSettings.grid}
              onChange={onChangeShowAxisGridSwitch}
              control={<Switch />}
              label={t('viewer-3d-settings-dialog_show-axis-grid-switch', 'Show Axis Grid')}
              sx={{ ml: 0, pt: 0, gap: 1 }}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="h4">
              {t('viewer-3d-settings-dialog_floor-plan-section-header', 'Floor Plan')}
            </Typography>
            <FormControlLabel
              checked={floorPlanSettings.visible}
              onChange={onChangeFloorPlanVisible}
              control={<Switch />}
              label={t('viewer-3d-settings-dialog_show-floor-plan-switch', 'Show Plan')}
              sx={{ ml: 0, pt: 0, gap: 1 }}
            />
            <FormControl>
              <InputLabel id="viewer-3d-settings-dialog_floor-plan-storey-select-label">
                {t('viewer-3d-settings-dialog_floor-plan-storey-select-label', 'Storey')}
              </InputLabel>
              <Select
                id="viewer-3d-settings-dialog_floor-plan-storey-select"
                labelId="viewer-3d-settings-dialog_floor-plan-storey-select-label"
                value=""
                onChange={() => {}}
                label={t('viewer-3d-settings-dialog_floor-plan-storey-select-label', 'Storey')}
              />
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="h4">
              {t('viewer-3d-settings-dialog_colors-section-header', 'Colors')}
            </Typography>
            <ColorPickerControl
              value={stagedViewer3dSettings.background}
              onChange={onChangeViewer3dBackgroundColor}
              label={t('viewer-3d-settings-dialog_viewer-3d-background-color', '3D View Background')}
            />
            <ColorPickerControl
              value={stagedViewer3dSettings.sectionOutline}
              onChange={onChangeViewer3dSectionOutlineColor}
              label={t('viewer-3d-settings-dialog_viewer-3d-section-outlines-color', 'Section Plane Cut Outlines')}
            />
            <ColorPickerControl
              value={stagedViewer3dSettings.sectionSpace}
              onChange={onChangeViewer3dSectionAreasColor}
              label={t('viewer-3d-settings-dialog_viewer-3d-section-spaces-color', 'Section Plane Cut Areas')}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={onClickCancel} variant="contained" color="secondary">
          {t('viewer-3d-settings-dialog_cancel-button-label', 'Cancel')}
        </Button>
        <Button onClick={onClickConfirm} variant="contained" color="primary">
          {t('viewer-3d-settings-dialog_confirm-button-label', 'Save Settings')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
