import useGetIssueStatusEditingRoleRight from 'issues/hooks/useGetIssueStatusEditingRoleRight';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import { useMemo } from 'react';

export default function useIssueStatusEditingRoleRight(issueId: string | undefined) {
  const { data: issue } = useIssueQuery(issueId);
  const getIssueStatusEditingRoleRight = useGetIssueStatusEditingRoleRight();
  const canEditStatus = useMemo(() => {
    if (!issue) return undefined;
    return getIssueStatusEditingRoleRight(issue);
  }, [getIssueStatusEditingRoleRight, issue]);

  return canEditStatus;
}
