import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import InfoIcon from '@mui/icons-material/Info';
import useDocumentListsOdataQuery from 'documents-lists/hooks/useDocumentListsOdataQuery';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import PlanlistPermissionsPanel, { DocumentListAccessIds } from 'documents-lists/components/PlanlistPermissionsPanel';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import EffectiveAccessRightsDialog from 'documents-lists/components/EffectiveAccessRightsDialog';
import useCurrentPlanlistId from 'documents-lists/hooks/useCurrentPlanlistId';
import useDocumentListDetailsQuery from 'documents-lists/hooks/useDocumentListDetailsQuery';
import useDocumentListUpdateNameMutation from 'documents-lists/hooks/useDocumentListUpdateNameMutation';
import useDocumentListUpdateAccessMutation from 'documents-lists/hooks/useDocumentListUpdateAccessMutation';
import DocumentListUpdateNameDto from 'documents-lists/types/DocumentListUpdateNameDto';
import DocumentListUpdateAccessDto from 'documents-lists/types/DocumentListUpdateAccessDto';

interface EditPlanlistDialogProps {
  documentVersionIds?: string[],
  onClose: () => void,
  open?: boolean,
}

export default function EditPlanlistDialog({
  documentVersionIds,
  onClose,
  open,
}: EditPlanlistDialogProps) {
  const { t } = useTranslation('documents-lists');
  const { data: planlists, isLoading: isLoadingPlanlists } = useDocumentListsOdataQuery({});
  const currentPlanlistId = useCurrentPlanlistId();
  const { data: currentPlanlist } = useDocumentListDetailsQuery(currentPlanlistId);
  const { mutateAsync: mutateNameAsync, isPending: isLoadingNameUpdate } = useDocumentListUpdateNameMutation();
  const { mutateAsync: mutateAccessAsync, isPending: isLoadingAccessUpdate } = useDocumentListUpdateAccessMutation();
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [requestErrorMessage, setRequestErrorMessage] = useState<string | undefined>(undefined);
  const [effectiveAccessRightsDialogOpen, setEffectiveAccessRightsDialogOpen] = useState(false);
  const [updateNameDto, setUpdateNameDto] = useState<DocumentListUpdateNameDto | undefined>(undefined);
  const [updateAccessDto, setUpdateAccessDto] = useState<DocumentListUpdateAccessDto | undefined>(undefined);
  const planlistNames = useMemo(() => (planlists ? new Set(planlists.map((p) => p.name)) : undefined), [planlists]);
  const nameError = useMemo(() => (
    planlistNames
      && !successDialogOpen
      && updateNameDto
      && currentPlanlist
      && currentPlanlist.name !== updateNameDto.name
      && planlistNames.has(updateNameDto.name)
      ? t('edit-planlist-dialog_already-exists-error', 'A planlist with this name already exists.')
      : undefined
  ), [planlistNames, updateNameDto, currentPlanlist, successDialogOpen, t]);

  useEffect(() => setUpdateNameDto((prev) => {
    // set initial default value for the name update dto
    if (prev || !currentPlanlist) return prev; // init
    return {
      id: currentPlanlist.id,
      name: currentPlanlist.name,
    };
  }), [currentPlanlist]);

  useEffect(() => setUpdateAccessDto((prev) => {
    // set initial default value for the access update dto
    if (prev || !currentPlanlist) return prev; // init
    return {
      id: currentPlanlist.id,
      readAccessGroupIds: currentPlanlist.readAccessGroupIds,
      writeAccessGroupIds: currentPlanlist.writeAccessGroupIds,
      readAccessCollaboratorIds: currentPlanlist.readAccessCollaboratorIds,
      writeAccessCollaboratorIds: currentPlanlist.writeAccessCollaboratorIds,
    };
  }), [currentPlanlist]);

  const getRequestErrorMessage = useRequestErrorMessage();
  const clearErrorMessage = useCallback(() => setRequestErrorMessage(undefined), []);

  const onChangeName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setUpdateNameDto((prev) => (prev ? { ...prev, name: event.target.value } : prev));
  }, []);

  const documentListAccessIds = useMemo(() => {
    if (!updateAccessDto) return undefined;
    return {
      readAccessCollaboratorIds: updateAccessDto.readAccessCollaboratorIds,
      readAccessGroupIds: updateAccessDto.readAccessGroupIds,
      writeAccessCollaboratorIds: updateAccessDto.writeAccessCollaboratorIds,
      writeAccessGroupIds: updateAccessDto.writeAccessGroupIds,
    };
  }, [updateAccessDto]);

  const onChangeDocumentListAccessIds = useCallback((value: DocumentListAccessIds) => {
    setUpdateAccessDto((prev) => (prev ? { ...prev, ...value } : prev));
  }, []);

  const onClickShowEffectiveAccessRightsDialog = useCallback(() => setEffectiveAccessRightsDialogOpen(true), []);
  const onCloseEffectiveAccessRightsDialog = useCallback(() => setEffectiveAccessRightsDialogOpen(false), []);

  const onConfirm = useCallback(async () => {
    if (!currentPlanlist || !updateAccessDto || !updateNameDto) return;

    const errorMessages: string[] = [];

    const accessIdsChanged = !isEqual(currentPlanlist.readAccessGroupIds, updateAccessDto.readAccessGroupIds)
    || !isEqual(currentPlanlist.writeAccessGroupIds, updateAccessDto.writeAccessGroupIds)
    || !isEqual(currentPlanlist.readAccessCollaboratorIds, updateAccessDto.readAccessCollaboratorIds)
    || !isEqual(currentPlanlist.writeAccessCollaboratorIds, updateAccessDto.writeAccessCollaboratorIds);
    if (accessIdsChanged) {
      try {
        await mutateAccessAsync(updateAccessDto);
        setSuccessDialogOpen(true);
      } catch (error) {
        errorMessages.push(getRequestErrorMessage(error));
      }
    }

    const nameChanged = currentPlanlist.name !== updateNameDto.name;
    if (nameChanged) {
      try {
        await mutateNameAsync(updateNameDto);
      } catch (error) {
        errorMessages.push(getRequestErrorMessage(error));
      }
    }
    if (errorMessages.length) {
      setRequestErrorMessage(errorMessages.join('\n'));
    } else {
      setSuccessDialogOpen(true);
    }
  }, [currentPlanlist, getRequestErrorMessage, mutateAccessAsync, mutateNameAsync, updateAccessDto, updateNameDto]);

  return (
    <Dialog
      id="EditPlanlistDialog"
      open={open ?? true}
      scroll="paper"
      PaperProps={{ style: { width: '600px', minHeight: '600px', maxHeight: '800px' } }}
    >
      <DialogTitle>{t('edit-planlist-dialog_title', 'Edit Planlist')}</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ pt: 1, display: 'flex', flexDirection: 'column', gap: 4, overflow: 'hidden' }}>
          <TextField
            id="EditPlanlistDialogNameInput"
            value={updateNameDto?.name || ''}
            disabled={!updateNameDto}
            onChange={onChangeName}
            label={t('edit-planlist-dialog_name-textfield-label', 'Name')}
            sx={{
              mt: 1, flexGrow: 1, flexShrink: 1, minWidth: '40px',
            }}
            error={!!nameError}
            helperText={nameError}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, overflow: 'auto', flexGrow: 1 }}>
            <Typography variant="h5">{t('edit-planlist-dialog_permissions-header', 'Access Rights')}</Typography>
            {!!documentListAccessIds && <PlanlistPermissionsPanel value={documentListAccessIds} onChange={onChangeDocumentListAccessIds} />}
            {!documentListAccessIds && <CenteredCircularProgress />}
          </Box>
          {!!requestErrorMessage && <Alert severity="error" onClose={clearErrorMessage}>{requestErrorMessage}</Alert>}
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'flex-start', gap: 2 }}>
        <Button id="EditPlanlistDialogCancelButton" variant="contained" color="secondary" onClick={onClose} sx={{ mr: 'auto' }}>
          {t('edit-planlist-dialog_cancel-button-label', 'Cancel')}
        </Button>
        <Button
          id="EditPlanlistDialogShowAccessButton"
          variant="text"
          color="primary"
          disabled={!updateAccessDto}
          onClick={onClickShowEffectiveAccessRightsDialog}
          sx={{ pl: 1, ml: 'auto' }}
        >
          <InfoIcon sx={{ mr: 0.5 }} />
          {t('edit-planlist-dialog_effective-access-rights-button-label', 'Show me who will have access')}
        </Button>
        <Button id="EditPlanlistDialogConfirmButton" variant="contained" color="primary" onClick={onConfirm} disabled={isLoadingPlanlists || isLoadingNameUpdate || isLoadingAccessUpdate || !updateNameDto?.name || !!nameError}>
          {t('edit-planlist-dialog_confirm-button-label', 'Edit Planlist')}
          {(isLoadingNameUpdate || isLoadingAccessUpdate) && <CircularProgress size={12} sx={{ ml: 1 }} />}
        </Button>
      </DialogActions>
      {!!successDialogOpen && (
        <Dialog open>
          <DialogTitle>{t('edit-planlist-dialog_success-dialog-title', 'Planlist Updated')}</DialogTitle>
          <DialogContent>
            <Alert severity="success">{t('edit-planlist-dialog_success-message', 'Your changes to the plan list {{planlist}} have been saved.', { count: documentVersionIds?.length ?? 0, planlist: updateNameDto?.name ?? '' })}</Alert>
          </DialogContent>
          <DialogActions>
            <Button id="EditPlanlistDialogCloseButton" variant="contained" color="primary" onClick={onClose}>{t('edit-planlist-dialog_success-dialog-close-button-label', 'Close')}</Button>
          </DialogActions>
        </Dialog>
      )}
      {!!effectiveAccessRightsDialogOpen && !!documentListAccessIds && (
        <EffectiveAccessRightsDialog accessIds={documentListAccessIds} onClose={onCloseEffectiveAccessRightsDialog} />
      )}
    </Dialog>
  );
}
