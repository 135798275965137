import { WebViewerInstance } from '@pdftron/webviewer';
import AnnotationItem from 'documents-annotations/types/AnnotationItem';
import { CompareMode } from 'documents-details/components/DocumentViewer';
import { Dispatch, SetStateAction, createContext } from 'react';

export interface DocumentViewerContextState {
  webViewerInstance: WebViewerInstance | undefined,
  initializeViewer: (container: HTMLDivElement, customHeaderItemsContainer: HTMLElement) => void,
  disposeViewer: () => void,
  annotationItems: AnnotationItem[] | undefined,
  setAnnotationItems: Dispatch<SetStateAction<AnnotationItem[] | undefined>>,
  selectedAnnotationNames: string[] | undefined,
  setSelectedAnnotationNames: (annotationNames: string[] | undefined) => void,
  issueIdCurrentlyLinking: string | undefined,
  setIssueIdCurrentlyLinking: Dispatch<SetStateAction<string | undefined>>,
  loadDocumentVersion: (documentVersionId: string) => Promise<void>,
  compareDocumentVersions: (documentVersionId: string, compareDocumentVersionId: string, mode: CompareMode) => Promise<void>,
  compareDocumentVersionId: string | undefined,
}

const defaultState: DocumentViewerContextState = {
  webViewerInstance: undefined,
  initializeViewer: () => {},
  disposeViewer: () => {},
  annotationItems: undefined,
  setAnnotationItems: undefined!,
  selectedAnnotationNames: undefined,
  setSelectedAnnotationNames: undefined!,
  issueIdCurrentlyLinking: undefined!,
  setIssueIdCurrentlyLinking: undefined!,
  loadDocumentVersion: () => Promise.reject(),
  compareDocumentVersions: () => Promise.reject(),
  compareDocumentVersionId: undefined,
};

const DocumentViewerContext = createContext<DocumentViewerContextState>(defaultState);
export default DocumentViewerContext;
