import { Box } from '@mui/material';
import { ColDef, ColumnMovedEvent, ColumnResizedEvent, IRowNode, GridApi, CellClickedEvent, RowClassRules } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useMemo, useRef } from 'react';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import AG_GRID_LOCALE_DE from 'setup/agGrid.locale.de';
import AG_GRID_LOCALE_EN from 'setup/agGrid.locale.en';
import i18n from 'setup/i18n';
import useEmailsQuery from 'emails/hooks/useEmailsQuery';
import useEmailsFilterContext from 'emails/hooks/useEmailFilterContext';
import InboxEmailDto from 'emails/types/InboxEmailDto';
import { Outlet } from 'react-router-dom';
import EmailDropOverlay from 'emails/components/EmailDropOverlay';
import useEmailUploadDropRef from 'emails/hooks/useEmailUploadDropRef';
import useEmailsDynamicLayout from 'dynamic-layout/hooks/useEmailsDynamicLayout';
import useEmailsDataGridColumnDefinitions from 'emails/hooks/useEmailsDataGridColumnDefinitions';

export enum SelectionSource {
  Route = 'route',
  Memory = 'memory',
}

const modules = [SetFilterModule];

interface EmailsDataGridProps {
  selectedEmailId: string | undefined,
  onChangeSelectedEmailId: (nextSelectedEmailId: string | undefined) => void,
  disabledEmailIds?: Set<string> | undefined,
}

export default function EmailsDataGrid({
  disabledEmailIds,
  selectedEmailId,
  onChangeSelectedEmailId,
}: EmailsDataGridProps) {
  const { data: rowData } = useEmailsQuery();

  const { quickFilterString, setFilteredEmailIds } = useEmailsFilterContext();

  const { canDropEmails, emailUploadDropRef } = useEmailUploadDropRef();

  const colDefs = useEmailsDataGridColumnDefinitions();

  const defaultColDef = useMemo<ColDef>(() => ({
    resizable: true,
    filterParams: {
      buttons: ['reset'],
    },
  }), []);

  const gridRef = useRef<AgGridReact>(null);

  const localeText = useMemo<{ [key: string]: string; }>(() => (i18n.language.toLocaleLowerCase().startsWith('de') ? AG_GRID_LOCALE_DE : AG_GRID_LOCALE_EN), []);

  const onCellClicked = useCallback((event: CellClickedEvent) => {
    if (disabledEmailIds?.has(event.data.id)) return;
    if (event.column.getColId() === 'linkedIssueIds' || event.column.getColId() === 'tagIds') {
      return;
    }
    onChangeSelectedEmailId(event.data.id);
  }, [disabledEmailIds, onChangeSelectedEmailId]);

  const onModelUpdated = useCallback(() => {
    // deselect rows that become hidden, e.g. by a filter
    const filteredEmailIds = new Set<string>();
    gridRef.current?.api.forEachNodeAfterFilterAndSort((rowNode: IRowNode<InboxEmailDto>) => {
      if (rowNode.data?.id) filteredEmailIds.add(rowNode.data.id);
    });
    setFilteredEmailIds(Array.from(filteredEmailIds));
  }, [setFilteredEmailIds]);

  const { persistAsync } = useEmailsDynamicLayout();
  const onColumnEvent = useCallback((columnApi: GridApi<InboxEmailDto>) => {
    const columns = columnApi.getAllDisplayedColumns();
    const updatedColumns = columns.filter((c) => !!c.getColDef().field).map((c) => ({ fieldName: c.getColDef().field!, width: c.getActualWidth() }));
    persistAsync(updatedColumns);
  }, [persistAsync]);

  const onColumnResized = useCallback(({ source, finished, api }: ColumnResizedEvent<InboxEmailDto>) => {
    if (source.startsWith('ui') && finished) onColumnEvent(api);
  }, [onColumnEvent]);

  const onColumnMoved = useCallback(({ source, finished, api }: ColumnMovedEvent<InboxEmailDto>) => {
    if (source.startsWith('ui') && finished) onColumnEvent(api);
  }, [onColumnEvent]);

  const rowStyle = useMemo(() => ({ cursor: 'pointer' }), []);
  const rowClassRules = useMemo<RowClassRules<InboxEmailDto>>(() => ({
    'viso-selected': ({ data }) => !!data?.id && !!selectedEmailId && data.id === selectedEmailId,
    ...(disabledEmailIds ? { 'viso-disabled': ({ data }) => ((data?.id ? disabledEmailIds.has(data.id) : undefined) ?? false) } : {}),
  }), [disabledEmailIds, selectedEmailId]);

  return (
    <Box
      id="EmailsDataGrid"
      sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}
      ref={emailUploadDropRef}
    >
      <Box
        sx={{ height: '100%', width: '100%' }}
        className="ag-theme-material ag-theme-visoplan table-view-data-grid"
      >
        <AgGridReact
          rowHeight={54}
          ref={gridRef}
          localeText={localeText}
          rowData={rowData}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          onCellClicked={onCellClicked}
          onModelUpdated={onModelUpdated}
          onColumnResized={onColumnResized}
          onColumnMoved={onColumnMoved}
          modules={modules}
          quickFilterText={quickFilterString}
          suppressCellFocus
          suppressRowClickSelection
          suppressRowHoverHighlight
          rowStyle={rowStyle}
          rowClassRules={rowClassRules}
        />
      </Box>
      <Outlet />
      {canDropEmails && (
        <EmailDropOverlay sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 3,
        }}
        />
      )}
    </Box>
  );
}
