import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import IssueDto from 'issues/types/IssueDto';

export default function AssignedUsersCell({
  data,
}: ICellRendererParams<IssueDto, string | undefined>) {
  const users = useMemo(() => {
    if (!data || !data.assignedUsers) return undefined;
    return data.assignedUsers.map((user) => `${user.firstName} ${user.lastName}`).join(', ');
  }, [data]);
  return (
    <Box id="AssignedUsersCell">
      {users}
    </Box>
  );
}
