import React from 'react';
import MutateNamingSchemeGroupDialog from 'naming-schemes/components/MutateNamingSchemeGroupDialog';
import { useTranslation } from 'react-i18next';
import NamingSchemeGroupItem from 'naming-schemes/types/NamingSchemeGroupItem';
import NamingSchemeGroupType from 'naming-schemes/types/NamingSchemeGroupType';

interface EditNamingSchemeGroupDialogProps {
  value: NamingSchemeGroupItem,
  onClose: (value: NamingSchemeGroupItem | undefined) => Promise<void> | void,
  onRemove: () => void,
  removeDisabled: boolean,
  alreadyExistingGroupTypes: NamingSchemeGroupType[],
}

export default function EditNamingSchemeGroupDialog({
  value,
  onClose,
  onRemove,
  removeDisabled,
  alreadyExistingGroupTypes,
}: EditNamingSchemeGroupDialogProps) {
  const { t } = useTranslation('naming-schemes');
  return (
    <MutateNamingSchemeGroupDialog
      onClose={onClose}
      onRemove={onRemove}
      removeDisabled={removeDisabled}
      title={t('edit-naming-scheme-group-dialog_title', 'Edit Group')}
      alreadyExistingGroupTypes={alreadyExistingGroupTypes}
      initialValue={value}
    />
  );
}
