import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Button, Checkbox, Menu, MenuList, MenuItem, useTheme,
} from '@mui/material';
import ListOptionsIcon from 'icons/ListOptionsIcon';
import ISxProps from 'common/types/ISxProps';
import useEmailsDynamicLayout from 'dynamic-layout/hooks/useEmailsDynamicLayout';
import useColumnDisplayNamesByFieldName from 'emails/hooks/useColumnDisplayNamesByFieldName';
import InboxEmailDto from 'emails/types/InboxEmailDto';

interface ColumnOptionsMenuButtonProps extends ISxProps {
}

const fieldNames = [
  'subject',
  'creationDate',
  'from',
  'to',
  'linkedIssueIds',
  'attachments',
  'tagIds',
  'emailBody',
  'cc',
  'editDate',
];

export default function ColumnOptionsButtonMenu({
  sx,
}: ColumnOptionsMenuButtonProps) {
  const headerNamesByColumnNames = useColumnDisplayNamesByFieldName();
  const theme = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const btnRef = useRef<HTMLButtonElement>(null);
  const {
    columnWidthsByColumnName: columnWidthByFieldName, columnNamesForcedVisible: fieldNamesForcedVisible, persistAsync, isLoadingMutation, isLoadingQuery,
  } = useEmailsDynamicLayout();
  const isLoading = useMemo(() => isLoadingMutation || isLoadingQuery, [isLoadingMutation, isLoadingQuery]);
  const [visibleColumnFieldNames, setVisibleColumnFieldNames] = useState<string[] | undefined>(columnWidthByFieldName ? fieldNames.filter((fieldName) => columnWidthByFieldName.has(fieldName)) : undefined);
  const selectionSet = useMemo<Set<string>>(() => new Set<string>(visibleColumnFieldNames), [visibleColumnFieldNames]);

  const toggleColumn = useCallback(async (fieldNameToToggle: string) => {
    if (!columnWidthByFieldName || !visibleColumnFieldNames) return;
    const updatedVisibleColumnFieldNames = columnWidthByFieldName.has(fieldNameToToggle) ? visibleColumnFieldNames.filter((f) => f !== fieldNameToToggle) : [...visibleColumnFieldNames, fieldNameToToggle];
    const updatedColumns = updatedVisibleColumnFieldNames.map((pivotFieldName) => ({ fieldName: pivotFieldName, width: columnWidthByFieldName.get(pivotFieldName) ?? 150 }));
    setVisibleColumnFieldNames(updatedVisibleColumnFieldNames);
    await persistAsync(updatedColumns);
  }, [columnWidthByFieldName, persistAsync, visibleColumnFieldNames]);

  useEffect(() => {
    setVisibleColumnFieldNames(columnWidthByFieldName ? Array.from(columnWidthByFieldName.keys()) : undefined);
  }, [columnWidthByFieldName]);

  return (
    <>
      <Button onClick={() => setIsMenuOpen(true)} sx={{ ...sx, color: theme.palette.text.primary, minWidth: 'unset' }} ref={btnRef} id="EmailColumnOptionsMenuButton">
        <ListOptionsIcon />
      </Button>
      <Menu
        id="EmailColumnOptionsMenu"
        anchorEl={btnRef.current}
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      >
        <MenuList
          dense
        >
          {fieldNames.map((fieldName) => (
            <MenuItem key={fieldName} onClick={() => toggleColumn(fieldName)} disabled={isLoading || fieldNamesForcedVisible?.has(fieldName)}>
              <Checkbox checked={selectionSet.has(fieldName) || fieldNamesForcedVisible?.has(fieldName)} />
              {headerNamesByColumnNames[fieldName as keyof InboxEmailDto]}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
}
