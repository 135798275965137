import React, {
  useContext,
  useRef, useState,
} from 'react';
import {
  Button, Menu, MenuItem, useTheme, Radio,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ISxProps from 'common/types/ISxProps';
import SortIcon from '@mui/icons-material/Sort';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import PlanlistFilterContext, { PlanlistFilterContextState } from 'documents-lists/contexts/PlanlistFilterContext';
import PlanlistSortMode from 'documents-lists/types/PlanlistSortMode';

interface PlanlistSortButtonMenuProps extends ISxProps {
}

export default function PlanlistSortButtonMenu({
  sx,
}: PlanlistSortButtonMenuProps) {
  const { t } = useTranslation('documents-lists');
  const theme = useTheme();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { sortMode, setSortMode } = useContext<PlanlistFilterContextState>(PlanlistFilterContext);

  return (
    <>
      <Button
        id="PlanlistSortMenuButton"
        onClick={() => setIsMenuOpen(true)}
        sx={{
          ...sx, color: theme.palette.text.primary, minWidth: 'unset', px: 1,
        }}
        ref={buttonRef}
        variant="outlined"
      >
        <SortIcon sx={{ mr: '-2px', color: theme.palette.text.secondary }} />
      </Button>
      <Menu
        anchorEl={buttonRef.current}
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      >
        <MenuItem onClick={() => setSortMode(PlanlistSortMode.NameAsc)}>
          <Radio checked={sortMode === PlanlistSortMode.NameAsc} sx={{ ml: 0 }} />
          {t('fplanlist-sort-button-menu_sort-select-name', 'Name')}
          <ArrowUpwardIcon fontSize="small" sx={{ ml: 1 }} />
        </MenuItem>
        <MenuItem onClick={() => setSortMode(PlanlistSortMode.NameDesc)}>
          <Radio checked={sortMode === PlanlistSortMode.NameDesc} sx={{ ml: 0 }} />
          {t('fplanlist-sort-button-menu_sort-select-name', 'Name')}
          <ArrowDownwardIcon fontSize="small" sx={{ ml: 1 }} />
        </MenuItem>
      </Menu>
    </>
  );
}
