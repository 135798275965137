import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Alert, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MutateNamingSchemeSettingsPanel, { NamingSchemeSettingsFormState } from 'naming-schemes/components/MutateNamingSchemeSettingsPanel';
import useNamingSchemeQuery from 'naming-schemes/hooks/useNamingSchemeQuery';
import useNamingSchemeUpdateMutation from 'naming-schemes/hooks/useNamingSchemeUpdateMutation';
import UpdateNamingSchemeDto from 'naming-schemes/types/UpdateNamingSchemeDto';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import DeleteNamingSchemeConfirmDialog from 'naming-schemes/components/DeleteNamingSchemeConfirmDialog';
import Icon from '@mdi/react';
import { mdiDelete } from '@mdi/js';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';
import RoleAction from 'projects/types/RoleAction';

interface EditNamingSchemeSettingsDialogProps {
  namingSchemeId: string,
  onClose: () => void,
  onDelete: () => void,
}

export default function EditNamingSchemeSettingsDialog({
  namingSchemeId,
  onClose,
  onDelete,
}: EditNamingSchemeSettingsDialogProps) {
  const { t } = useTranslation('naming-schemes');
  const { data: namingScheme } = useNamingSchemeQuery(namingSchemeId);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const getRequestErrorMessage = useRequestErrorMessage();
  const [formState, setFormState] = useState<NamingSchemeSettingsFormState | undefined>(undefined);
  const { mutateAsync } = useNamingSchemeUpdateMutation();
  const allowedActions = useAllowedActions();
  useEffect(() => {
    if (!namingScheme) return;
    setFormState((prev) => prev ?? {
      allowMetaMapping: namingScheme.allowMetaMapping,
      applyFileTypes: namingScheme.applyFileTypes ?? [],
      name: namingScheme.name,
      useFullFileName: namingScheme.useFullFileName,
    });
  }, [namingScheme]);
  const onChange = useCallback((value: NamingSchemeSettingsFormState) => {
    setErrorMessage(undefined);
    setFormState(value);
  }, []);
  const onClickConfirm = useCallback(async () => {
    if (!formState || !namingScheme) return;
    try {
      const updateDto: UpdateNamingSchemeDto = {
        id: namingSchemeId,
        allowMetaMapping: formState.allowMetaMapping,
        groups: namingScheme.groups,
        name: formState.name,
        useFullFileName: formState.useFullFileName,
        applyFileTypes: formState.applyFileTypes.length ? formState.applyFileTypes : undefined,
      };
      await mutateAsync(updateDto);
      onClose();
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [formState, getRequestErrorMessage, mutateAsync, namingScheme, namingSchemeId, onClose]);
  const [deleteNamingSchemeConfirmDialogOpen, setDeleteNamingSchemeConfirmDialogOpen] = useState<boolean>(false);
  const onClickDeleteNamingScheme = useCallback(() => setDeleteNamingSchemeConfirmDialogOpen(true), []);
  const onCloseDeleteNamingSchemeConfirmDialog = useCallback(async (confirmed: boolean) => {
    if (confirmed) {
      onDelete();
    } else {
      setDeleteNamingSchemeConfirmDialogOpen(false);
    }
  }, [onDelete]);
  const canDelete = useMemo(() => allowedActions?.has(RoleAction.NamingScheme_Delete), [allowedActions]);
  return (
    <Dialog open id="EditNamingSchemeSettingsDialog">
      <DialogTitle component="div" sx={{ display: 'flex', gap: 4, justifyContent: 'space-between', alignItems: 'baseline' }}>
        <Typography variant="h4">{t('edit-naming-scheme-settings-dialog_title', '"{{encodingName}}" Options', { encodingName: namingScheme?.name ?? '' })}</Typography>
        {canDelete && (
        <Button variant="outlined" onClick={onClickDeleteNamingScheme} sx={{ pl: 1, gap: 0.5, alignSelf: 'center' }} size="small">
          <Icon path={mdiDelete} size={0.75} />
          {t('encoding-scheme-tab-pane_delete-encoding-button-label', 'Delete')}
        </Button>
        )}
        {!!deleteNamingSchemeConfirmDialogOpen && !!namingScheme && <DeleteNamingSchemeConfirmDialog namingSchemeName={namingScheme.name} onClose={onCloseDeleteNamingSchemeConfirmDialog} />}
      </DialogTitle>
      <DialogContent>
        {!!formState && <MutateNamingSchemeSettingsPanel value={formState} onChange={onChange} sx={{ pt: 2 }} />}
        {!!errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </DialogContent>
      <DialogActions sx={{ gap: 2, justifyContent: 'space-between' }}>
        <Button variant="contained" color="secondary" onClick={onClose}>
          {t('edit-naming-scheme-settings-dialog_cancel-button-label', 'Cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={onClickConfirm}>
          {t('edit-naming-scheme-settings-dialog_confirm-button-label', 'Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
