import {
  BufferGeometry,
  Color,
  Float32BufferAttribute,
  Group,
  Line,
  LineBasicMaterial,
  MathUtils,
  Vector3,
} from "three";
import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer";
import { LiteEvent } from "../../../utils/LiteEvent";
import { IfcContext } from "../../context";

const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
const cssNormalColor = "#4B6FFC";
const normalColor = new Color(cssNormalColor);

export class IfcDimensionLine {
  public readonly onClosed = new LiteEvent<boolean>();

  private readonly context: IfcContext;
  private readonly labelClassName = "viewer-dimension_label";
  private readonly endpointClassName = "viewer-dimension_endpoint";
  private offset: number;

  // Elements
  private root = new Group();
  private readonly line: Line;
  private readonly textLabels: CSS2DObject[] = [];
  private readonly endpointLabels: CSS2DObject[] = [];
  private readonly angleLabels: CSS2DObject[] = [];

  // Geometries
  private points: Vector3[] = [];

  // Materials
  private lineMaterial: LineBasicMaterial;

  // Dimensions
  private selected = false;
  private isClosed = false;

  constructor(
    context: IfcContext,
    lineMaterial: LineBasicMaterial,
    offset: number
  ) {
    this.context = context;

    this.lineMaterial = lineMaterial;
    this.line = new Line(new BufferGeometry(), this.lineMaterial);
    this.line.frustumCulled = false;
    this.root.add(this.line);

    this.root.renderOrder = 2;
    this.context.getScene().add(this.root);

    this.offset = offset;
  }

  set visibility(visible: boolean) {
    this.root.visible = visible;
    this.textLabels.forEach((l) => (l.visible = visible));
    this.endpointLabels.forEach((e) => (e.visible = visible));
    this.angleLabels.forEach((a) => (a.visible = visible));
  }

  set endPoint(point: Vector3) {
    const length = this.points.length - 1;
    if (length < 1) return;
    const position = this.line.geometry.attributes.position;
    if (!position) return;
    position.setXYZ(length, point.x, point.y, point.z);
    position.needsUpdate = true;

    this.textLabels[length - 1].element.textContent = `${this.getLength(
      point,
      this.points[length - 1]
    )}`;
    const center = this.getCenter(point, this.points[length - 1]);
    this.textLabels[length - 1].position.set(center.x, center.y, center.z);

    if (length > 1) {
      const angleLabel = this.angleLabels[length - 2];

      if (angleLabel.element.lastChild) {
        angleLabel.element.lastChild.textContent = this.getAngle(
          this.points[length - 2],
          this.points[length - 1],
          point
        );
      }
      const pos = this.getAnglePosition(
        this.points[length - 2],
        this.points[length - 1],
        point
      );
      angleLabel.position.set(pos.x, pos.y, pos.z);
    }
  }

  get pointCount() {
    return this.points.length;
  }

  get dimensionLabel() {
    return `${this.endpointLabels[0].element.textContent} - ${
      this.endpointLabels[this.endpointLabels.length - 1].element.textContent
    }`;
  }

  get totalLength() {
    let total = 0;
    const length = this.points.length - 1;
    if (length > 0) {
      for (let i = 0; i < length; i++) {
        total += this.points[i].distanceTo(this.points[i + 1]);
      }

      if (this.isClosed) {
        total += this.points[0].distanceTo(this.points[this.pointCount - 1]);
      }
    }

    return total;
  }

  get isSelected() {
    return this.selected;
  }

  set isSelected(select: boolean) {
    this.selected = select;

    this.lineMaterial.color = normalColor;

    this.textLabels.forEach((t) => {
      t.element.style.backgroundColor = cssNormalColor;
    });

    this.endpointLabels.forEach((e) => {
      e.element.style.backgroundColor = cssNormalColor;
    });
  }

  removeFromScene() {
    this.context.getScene().remove(this.root);
    this.textLabels.forEach((t) => this.root.remove(t));
    this.endpointLabels.forEach((e) => this.root.remove(e));
    this.angleLabels.forEach((a) => this.root.remove(a));
  }

  addPoint(point: Vector3) {
    if (this.pointCount) {
      if (point.equals(this.points[this.pointCount - 1])) return;

      if (point.equals(this.points[0])) {
        if (this.pointCount > 3) {
          this.closeDimension();
        }

        return;
      }
    }

    this.removeLastPoint();
    this.addVertex(point);
    this.addVertex(point);
    this.updateLine();
  }

  removeLastPoint() {
    if (this.points.length) {
      this.points.pop();
      const endLabel = this.endpointLabels.pop();
      if (endLabel) this.root.remove(endLabel);

      this.updateLine();
    }

    if (this.textLabels.length) {
      const textLabel = this.textLabels.pop();
      if (textLabel) this.root.remove(textLabel);
    }

    if (this.angleLabels.length) {
      const angleLabel = this.angleLabels.pop();
      if (angleLabel) {
        this.root.remove(angleLabel);
      }
    }
  }

  /**
   * Get a start point for snapping.
   * this point is used to close a polygon for measurement. The number of points should be greater than 3. Because last point is temporary when measuring.
   * @returns {Vector3|null} if the count of points is greater than 3, return first index point, otherwise null.
   */
  getStartPoint() {
    if (this.pointCount > 3) {
      return this.points[0];
    }

    return null;
  }

  private closeDimension() {
    this.isClosed = true;
    this.points.pop();
    const endLabel = this.endpointLabels.pop();
    if (endLabel) this.root.remove(endLabel);

    this.textLabels[
      this.textLabels.length - 1
    ].element.textContent = `${this.getLength(
      this.points[0],
      this.points[this.pointCount - 1]
    )}m`;

    this.onClosed.trigger(true);
  }

  private updateLine() {
    const position = [];
    for (let i = 0; i < this.points.length; i++) {
      const point = this.points[i];
      position.push(point.x, point.y, point.z);
    }

    this.line.geometry.setAttribute(
      "position",
      new Float32BufferAttribute(position, 3)
    );
  }

  private addVertex(vertex: Vector3) {
    this.points.push(vertex);
    this.endpointLabels.push(this.newEndpointLabel(vertex));
    const length = this.points.length - 1;
    if (length > 0) {
      this.textLabels.push(
        this.newText(this.points[length], this.points[length - 1])
      );
    }

    if (length > 1) {
      this.angleLabels.push(
        this.newAngle(
          this.points[length - 2],
          this.points[length - 1],
          this.points[length]
        )
      );
    }
  }

  private newEndpointLabel(vertex: Vector3) {
    const htmlText = document.createElement("div");
    htmlText.className = this.endpointClassName;
    htmlText.textContent = this.getEndpointContent(
      this.points.length + this.offset
    )
      .split("")
      .reverse()
      .join("");
    const label = new CSS2DObject(htmlText);
    label.position.set(vertex.x, vertex.y, vertex.z);
    this.root.add(label);

    return label;
  }

  private newText(start: Vector3, end: Vector3) {
    const htmlText = document.createElement("div");
    htmlText.className = this.labelClassName;
    htmlText.textContent = `${this.getLength(start, end)}m`;
    const center = this.getCenter(start, end);
    const label = new CSS2DObject(htmlText);
    label.position.set(center.x, center.y, center.z);
    this.root.add(label);
    return label;
  }

  private newAngle(start: Vector3, middle: Vector3, end: Vector3) {
    const htmlSvg = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "svg"
    );
    htmlSvg.setAttribute("width", "7");
    htmlSvg.setAttribute("height", "7");
    htmlSvg.setAttribute("viewBox", "0 0 8 7");
    htmlSvg.setAttribute("fill", "none");

    const htmlPath = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path"
    );
    htmlPath.setAttribute("fill-rule", "evenodd");
    htmlPath.setAttribute("clip-rule", "evenodd");
    htmlPath.setAttribute(
      "d",
      "M4.95248 0.363288C5.08371 0.464445 5.10809 0.652821 5.00693 0.784046L0.901056 6.11057L7.51278 6.33906C7.67834 6.34478 7.80796 6.48368 7.80224 6.64924C7.79652 6.81486 7.65762 6.94442 7.49206 6.9387L0.296354 6.69003C0.183945 6.68614 0.0831459 6.61974 0.0352611 6.51795C-0.0126249 6.41615 0.000442842 6.29617 0.0691131 6.20705L4.53173 0.41774C4.63288 0.286516 4.82126 0.262137 4.95248 0.363288ZM6.18762 2.9913C6.19334 2.82572 6.33218 2.69612 6.4978 2.70184C6.66336 2.70756 6.79298 2.84644 6.78726 3.01202C6.78153 3.17761 6.64264 3.3072 6.47707 3.30148C6.31145 3.29576 6.18189 3.15689 6.18762 2.9913ZM5.81967 1.47769C5.65411 1.47197 5.51523 1.60156 5.50951 1.76715C5.50379 1.93274 5.63339 2.07161 5.79895 2.07733C5.96457 2.08306 6.10341 1.95346 6.10913 1.78787C6.11485 1.62229 5.98529 1.48341 5.81967 1.47769ZM6.74581 4.21131C6.75153 4.04572 6.89037 3.91612 7.05599 3.92185C7.22155 3.92757 7.35118 4.06645 7.34545 4.23203C7.33973 4.39762 7.20083 4.52721 7.03527 4.52149C6.86965 4.51577 6.74009 4.37689 6.74581 4.21131ZM7.49426 5.1377C7.3287 5.13198 7.1898 5.2616 7.18408 5.42716C7.17836 5.59273 7.30798 5.73163 7.47354 5.73735C7.63916 5.74307 7.778 5.61345 7.78372 5.44789C7.78944 5.28233 7.65988 5.14343 7.49426 5.1377Z"
    );
    htmlPath.setAttribute("fill", "white");

    htmlSvg.appendChild(htmlPath);

    const htmlAngle = document.createElement("div");
    htmlAngle.style.background = "transparent";
    htmlAngle.style.padding = "0 3px";
    htmlAngle.textContent = this.getAngle(start, middle, end);

    const htmlDiv = document.createElement("div");
    htmlDiv.className = this.labelClassName;
    htmlDiv.style.background = "#E56700";
    htmlDiv.appendChild(htmlSvg);
    htmlDiv.appendChild(htmlAngle);

    const label = new CSS2DObject(htmlDiv);
    const pos = this.getAnglePosition(start, middle, end);
    label.position.set(pos.x, pos.y, pos.z);
    this.root.add(label);

    return label;
  }

  private getAnglePosition(start: Vector3, middle: Vector3, end: Vector3) {
    const refS = start.clone().sub(middle);
    const refE = end.clone().sub(middle);
    const mid = refS.add(refE).multiplyScalar(0.1);

    return middle.clone().add(mid);
  }

  private getAngle(start: Vector3, middle: Vector3, end: Vector3) {
    const refS = start.clone().sub(middle);
    const refE = end.clone().sub(middle);

    return Math.abs(refS.angleTo(refE) * MathUtils.RAD2DEG).toFixed(0);
  }

  private getEndpointContent(count: number) {
    const index = (count - 1) % alphabet.length;
    const rest = ~~((count - 1) / alphabet.length);
    const result: any =
      rest > 0
        ? alphabet[index] + this.getEndpointContent(rest)
        : alphabet[index];
    return result;
  }

  private getLength(start: Vector3, end: Vector3) {
    return parseFloat(start.distanceTo(end).toFixed(2));
  }

  private getCenter(start: Vector3, end: Vector3) {
    let dir = end.clone().sub(start);
    const len = dir.length() * 0.5;
    dir = dir.normalize().multiplyScalar(len);
    return start.clone().add(dir);
  }
}
