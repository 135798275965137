import React, { useCallback, useMemo } from 'react';
import { Box, Button, useTheme } from '@mui/material';
import Icon from '@mdi/react';
import { mdiLinkPlus, mdiCubeOffOutline, mdiCubeOutline, mdiLinkOff, mdiUndo } from '@mdi/js';
import ISxProps from 'common/types/ISxProps';
import { useTranslation } from 'react-i18next';

export enum ComponentLinkAction {
  LeavePersisted = 'leave-persisted',
  Remove = 'remove',
  Add = 'add',
}

interface LinkedComponentListItemProps extends ISxProps {
  globalId: string,
  elementName: string | undefined,
  nodeLoaded: boolean,
  pendingAction: ComponentLinkAction,
  onClickRemove?: (globalId: string) => void,
  onClickRestore?: (globalId: string) => void,
}

export default function LinkedComponentListItem({
  sx,
  globalId,
  elementName,
  nodeLoaded,
  pendingAction,
  onClickRemove,
  onClickRestore,
}: LinkedComponentListItemProps) {
  const theme = useTheme();
  const { t } = useTranslation('issues');
  const caption = useMemo(() => (elementName ? `${elementName} (GUID: ${globalId})` : `${globalId} (GUID)`), [elementName, globalId]);
  const onClickUnlinkButton = useCallback(() => onClickRemove?.(globalId), [globalId, onClickRemove]);
  const onClickRestoreButton = useCallback(() => onClickRestore?.(globalId), [globalId, onClickRestore]);
  return (
    <Box
      id="LinkedComponentListItem"
      sx={{
        display: 'flex',
        alignItems: 'stretch',
        textAlign: 'left',
        p: 1,
        gap: 1,
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: theme.palette.divider,
        ...sx,
      }}
    >
      <Box
        sx={{ display: 'flex', alignItems: 'center' }}
        title={nodeLoaded ? t('linked-component-list-item_node-loaded-hint', 'Component is part of currently loaded models') : t('linked-component-list-item_node-not-loaded-hint', 'Component is not part of currently loaded models')}
      >
        <Icon path={nodeLoaded ? mdiCubeOutline : mdiCubeOffOutline} size={1} color={theme.palette.grey[700]} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          flexGrow: 1,
          overflow: 'hidden',
        }}
        title={caption}
      >
        {pendingAction === ComponentLinkAction.Add && <Icon path={mdiLinkPlus} size={0.75} color={theme.palette.success.main} />}
        <Box sx={{
          whiteSpace: 'nowrap',
          flexGrow: 0,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          fontFamily: !elementName ? 'monospace' : undefined,
          textDecoration: pendingAction === ComponentLinkAction.Remove ? 'line-through' : undefined,
          color: pendingAction === ComponentLinkAction.Add ? theme.palette.success.main : pendingAction === ComponentLinkAction.Remove ? theme.palette.error.main : undefined,
        }}
        >
          {caption}
        </Box>
      </Box>
      {pendingAction !== ComponentLinkAction.Remove && !!onClickRemove && (
      <Button
        variant="outlined"
        size="small"
        onClick={onClickUnlinkButton}
        sx={{ px: 0.5, flexShrink: 0, minWidth: 'unset' }}
        title={t('linked-component-list-item_remove-button-title', 'Remove')}
      >
        <Icon path={mdiLinkOff} size={0.75} />
      </Button>
      )}
      {pendingAction === ComponentLinkAction.Remove && !!onClickRestore && (
      <Button
        variant="outlined"
        size="small"
        onClick={onClickRestoreButton}
        sx={{ px: 0.5, flexShrink: 0, minWidth: 'unset' }}
        title={t('linked-component-list-item_restore-button-title', 'Restore')}
      >
        <Icon path={mdiUndo} size={0.75} />
      </Button>
      )}
    </Box>
  );
}
