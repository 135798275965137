import { ReactNode, useCallback, useMemo } from 'react';
import { CellClassFunc, ColDef, ICellRendererParams } from '@ag-grid-community/core';
import IssueDto from 'issues/types/IssueDto';
import HeaderCell from 'issues/components/cell-renderers/HeaderCell';
import LabelIdChipCell from 'issues/components/cell-renderers/LabelIdChipCell';
import LabelIdsChipsCell from 'issues/components/cell-renderers/LabelIdsChipsCell';
import useIssuesDynamicLayout from 'dynamic-layout/hooks/useIssuesDynamicLayout';
import useColumnDisplayNamesByFieldName from 'issues/hooks/useColumnDisplayNamesByFieldName';
import CollaboratorDto from 'collaborators/types/CollaboratorDto';
import DocumentsCell from 'issues/components/cell-renderers/DocumentsCell';
import ViewpointsCell from 'issues/components/cell-renderers/ViewpointsCell';
import EmailsCell from 'issues/components/cell-renderers/EmailsCell';
import CommentsCell from 'issues/components/cell-renderers/CommentsCell';
import ThumbnailCell from 'issues/components/cell-renderers/ThumbnailCell';
import VisibilityCell from 'issues/components/cell-renderers/VisibilityCell';
import DefaultCellRenderer from 'issues/components/cell-renderers/DefaultCellRenderer';

type CellRenderersByColumnName = {
  [P in keyof IssueDto]?: (params: ICellRendererParams<IssueDto>) => ReactNode
};

const cellRendererByColumnName: CellRenderersByColumnName = {
  disciplines: LabelIdsChipsCell,
  buildings: LabelIdsChipsCell,
  floors: LabelIdsChipsCell,
  tags: LabelIdsChipsCell,
  workPhase: LabelIdChipCell,
  issueStatus: LabelIdChipCell,
  issueType: LabelIdChipCell,
  issuePriority: LabelIdChipCell,
  linkedDocumentVersionIds: DocumentsCell,
  viewpointIds: ViewpointsCell,
  linkedEmailIds: EmailsCell,
  commentIds: CommentsCell,
  thumbnailImageId: ThumbnailCell,
  visibility: VisibilityCell,
};

type StringFormatter<TValue> = ({ data, value }: { data?: IssueDto, value?: TValue }) => string;

type ValueFormattersByColumnName = {
  [P in keyof IssueDto]?: StringFormatter<any>
};

type ClassNameByColumnName = {
  [P in keyof IssueDto]?: string | string[] | CellClassFunc<IssueDto, any>
};

const cellClassByColumnName: ClassNameByColumnName = {
  issueNumber: 'issue-number-cell',
  linkedDocumentVersionIds: 'center',
  viewpointIds: 'center',
  linkedEmailIds: 'center',
  commentIds: 'center',
  thumbnailImageId: 'center',
};

export default function useIssuesDataGridColumnDefinitions() {
  const headerNamesByColumnNames = useColumnDisplayNamesByFieldName();
  const dateValueFormatter = useCallback<StringFormatter<string | undefined>>(({ value }) => (value ? new Date(value).toLocaleDateString('de-DE', { timeZone: 'Europe/Berlin' }) : ''), []);
  const dateTimeValueFormatter = useCallback<StringFormatter<string | undefined>>(({ value }) => (value ? new Date(value).toLocaleString('de-DE', { timeZone: 'Europe/Berlin' }) : ''), []);
  const collaboratorValueFormatter = useCallback<StringFormatter<CollaboratorDto | undefined>>(({ value }) => (value ? `${value.firstName} ${value.lastName}` : ''), []);
  const collaboratorsValueFormatter = useCallback<StringFormatter<CollaboratorDto[] | undefined>>(({ value }) => (value ? value.map((c) => `${c.firstName} ${c.lastName}`).join(', ') : ''), []);
  const modelsValueGetter = useCallback<StringFormatter<string[] | undefined>>(({ value }) => `${value?.join(', ') ?? ''}`, []);
  const valueFormatterByColumnName = useMemo<ValueFormattersByColumnName>(() => ({
    editDate: dateTimeValueFormatter,
    creationDate: dateTimeValueFormatter,
    dueDate: dateValueFormatter,
    startingDate: dateValueFormatter,
    createAuthor: collaboratorValueFormatter,
    editAuthor: collaboratorValueFormatter,
    reviewer: collaboratorValueFormatter,
    assignedUsers: collaboratorsValueFormatter,
    models: modelsValueGetter,
  }), [collaboratorValueFormatter, collaboratorsValueFormatter, dateTimeValueFormatter, dateValueFormatter, modelsValueGetter]);
  const { columnWidthsByColumnName, orderedVisibleColumnNames } = useIssuesDynamicLayout();
  const columnsFromDynamicLayout = useMemo<ColDef<IssueDto>[]>(() => (orderedVisibleColumnNames && columnWidthsByColumnName
    ? orderedVisibleColumnNames
      .filter((columnName) => columnName in headerNamesByColumnNames)
      .map((columnName) => columnName as keyof IssueDto)
      .map<ColDef<IssueDto>>((fieldName) => ({
      colId: fieldName,
      field: fieldName as keyof IssueDto,
      headerName: headerNamesByColumnNames[fieldName],
      width: columnWidthsByColumnName.get(fieldName),
      sortable: false,
      headerComponent: HeaderCell,
      cellClass: cellClassByColumnName[fieldName],
      cellRenderer: cellRendererByColumnName[fieldName] ?? DefaultCellRenderer,
      valueFormatter: valueFormatterByColumnName[fieldName],
    }))
    : []), [columnWidthsByColumnName, headerNamesByColumnNames, orderedVisibleColumnNames, valueFormatterByColumnName]);
  return columnsFromDynamicLayout;
}
