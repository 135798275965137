import LabelType from 'labels/types/LabelType';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function useLabelTypeTranslationPlural(labelType: LabelType | undefined) {
  const { t } = useTranslation('labels');
  return useMemo(() => {
    if (labelType === LabelType.Building) return t('use-issue-label-translation_buildings', 'Buildings');
    if (labelType === LabelType.Discipline) return t('use-issue-label-translation_disciplines', 'Disciplines');
    if (labelType === LabelType.DocumentStatus) return t('use-issue-label-translation_document-states', 'Document States');
    if (labelType === LabelType.Floor) return t('use-issue-label-translation_floors', 'Floors');
    if (labelType === LabelType.IssuePriority) return t('use-issue-label-translation_issue-priorities', 'Issue Priorities');
    if (labelType === LabelType.IssueStatus) return t('use-issue-label-translation_issue-states', 'Issue States');
    if (labelType === LabelType.IssueType) return t('use-issue-label-translation_issue-types', 'Issue Types');
    if (labelType === LabelType.Tag) return t('use-issue-label-translation_tags', 'Tags');
    if (labelType === LabelType.WorkPhase) return t('use-issue-label-translation_work-phases', 'Work Phases');
    return undefined;
  }, [labelType, t]);
}
