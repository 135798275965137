import React, { useMemo } from 'react';
import { Box, Button } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import { useTranslation } from 'react-i18next';
import useCurrentCollaboratorQuery from 'collaborators/hooks/useCurrentCollaboratorQuery';
import useOpenIssueStatus from 'issues/hooks/useOpenIssueStatus';
import Icon from '@mdi/react';
import { mdiCheck, mdiRewind } from '@mdi/js';
import RoleAction from 'projects/types/RoleAction';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';

interface AssignmentActionButtonsProps extends ISxProps {
  issueId: string,
  onClickCompleteAssignment: () => void,
  onClickRejectAssignment: () => void,
}

export default function IssueAssignmentActionButtons({
  sx,
  issueId,
  onClickCompleteAssignment,
  onClickRejectAssignment,
}: AssignmentActionButtonsProps) {
  const { t } = useTranslation('issues');
  const { data: currentCollaborator } = useCurrentCollaboratorQuery();
  const { data: issue, isLoading: isLoadingIssue } = useIssueQuery(issueId);
  const isAssignedToCurrentUser = useMemo(() => (!!issue?.assignedCollaboratorIds?.length && !!currentCollaborator && issue.assignedCollaboratorIds.some((id) => id === currentCollaborator.id)), [currentCollaborator, issue]);
  const issueHasReviewer = useMemo(() => (issue ? Boolean(issue.reviewer) : undefined), [issue]);
  const openIssueStatus = useOpenIssueStatus();
  const allowedActions = useAllowedActions();

  const canDoAssignmentActions = useMemo(() => {
    if (!issue?.issueStatus || !openIssueStatus) return undefined;
    return issue.issueStatus === openIssueStatus.id && isAssignedToCurrentUser;
  }, [isAssignedToCurrentUser, issue, openIssueStatus]);

  const canRejectAssignment = useMemo(() => canDoAssignmentActions && issue?.createAuthorId, [canDoAssignmentActions, issue?.createAuthorId]);
  const canCompleteAssignment = useMemo(() => allowedActions?.has(RoleAction.IssueManagement_StatusEditing_SetClosed), [allowedActions]);

  if (!issueId || !canDoAssignmentActions) return null;
  return (
    <Box id="IssueActionButtons" sx={{ ...sx, display: 'flex', gap: 1 }}>
      {!!canRejectAssignment && (
        <Button
          variant="contained"
          color="secondary"
          onClick={onClickRejectAssignment}
          disabled={isLoadingIssue}
          sx={{ gap: 1, minWidth: 'fit-content' }}
          size="small"
        >
          <Icon path={mdiRewind} size={0.5} />
          {t('assignment-action-buttons_reject-assignment', 'Reject')}
        </Button>
      )}
      {!!canCompleteAssignment && (
        <Button
          variant="contained"
          color="secondary"
          onClick={onClickCompleteAssignment}
          sx={{ gap: 1, minWidth: 'fit-content' }}
          disabled={!canCompleteAssignment || isLoadingIssue}
          size="small"
        >
          <Icon path={mdiCheck} size={0.5} />
          {issueHasReviewer ? t('assignment-action-buttons_complete-assignment-for-review', 'Submit for review') : t('assignment-action-buttons_complete-assignment', 'Complete')}
        </Button>
      )}
    </Box>
  );
}
