import React, { useCallback, useMemo, useState } from 'react';
import { useMatches, useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import PageWrapper from 'common/components/PageWrapper';
import IssuesPageHeader from 'issues/components/IssuesPageHeader';
import IssuesFilterContextProvider from 'issues/contexts/IssuesFilterContextProvider';
import IssuesFilterPanel from 'issues/components/IssuesFilterPanel';
import IssuePrintContextProvider from 'issues/contexts/IssuePrintContextProvider';
import IssuesPanel from 'issues/components/IssuesPanel';
import IssuesSelectionContext, { IssuesSelectionContextState } from 'issues/contexts/IssuesSelectionContext';
import IssuesViewMode from 'issues/types/IssuesViewMode';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';
import RoleAction from 'projects/types/RoleAction';
import NotAllowed from 'error/NotAllowed';

const issuesPageMultiselectRouteRegex = /\/projects\/([^/]+)\/issues\/((?:list|board|timeline))(?:\/filter\/([^/]+))?\/multiselect\/?/i;

interface IssuesPageRouteParams {
  viewMode: string,
  filterId: string | null,
  issueId: string | null,
  multiselectActive: boolean
  printActive: boolean,
}

export default function IssuesPage() {
  const navigate = useNavigate();
  const allowedActions = useAllowedActions();

  const { viewMode, issueId, filterId: queryFilterId } = useParams<'viewMode' | 'issueId' | 'filterId'>();
  const matches = useMatches();
  const multiselectRoute = useMemo(() => matches.some((m) => Boolean(m.pathname.match(issuesPageMultiselectRouteRegex))), [matches]);

  const buildRoute = useCallback((params: Partial<IssuesPageRouteParams>) => {
    const nextViewMode = params.viewMode ?? viewMode ?? IssuesViewMode.List;
    const nextPrintActive = params.printActive ?? false;
    const nextMultiselectActive = params.multiselectActive ?? multiselectRoute;
    const nextFilterId = params.filterId === null ? undefined : (params.filterId ?? queryFilterId);
    const nextIssueId = params.issueId === null ? undefined : (params.issueId ?? issueId);

    const parts = [`../${nextViewMode}`];
    if (nextFilterId) {
      parts.push(`filter/${nextFilterId}`);
    }
    if (nextPrintActive) {
      parts.push('print');
    } else if (nextMultiselectActive) {
      parts.push('multiselect');
    } else if (nextIssueId) {
      parts.push(`issue/${nextIssueId}`);
    }

    return parts.join('/');
  }, [issueId, multiselectRoute, queryFilterId, viewMode]);

  const setFilterId = useCallback((value: string | undefined) => {
    const route = buildRoute({
      filterId: value ?? null,
    });
    navigate(route);
  }, [buildRoute, navigate]);

  const [multiselectedIssueIds, setMultiselectedIssueIds] = useState<string[]>([]);
  const setSelectedIssueIds = useCallback((value: string[]) => {
    if (value.length > 1 || multiselectRoute) {
      setMultiselectedIssueIds(value);
      if (!multiselectRoute && value.length > 1) {
        const route = buildRoute({ issueId: undefined, multiselectActive: true });
        navigate(route);
      } else if (multiselectRoute && value.length === 0) {
        const route = buildRoute({ multiselectActive: false });
        navigate(route);
      }
    } else {
      const route = buildRoute({
        issueId: value[0] ?? null,
        multiselectActive: false,
      });
      navigate(route);
    }
  }, [buildRoute, multiselectRoute, navigate]);

  const setMultiselectActive = useCallback((value: boolean | undefined) => {
    const route = buildRoute({ multiselectActive: value });
    navigate(route);
  }, [buildRoute, navigate]);

  const onSelectViewMode = useCallback((value: IssuesViewMode) => {
    const route = buildRoute({ viewMode: value });
    navigate(route);
  }, [buildRoute, navigate]);

  const onClickPrint = useCallback(() => {
    const route = buildRoute({ printActive: true });
    navigate(route);
  }, [buildRoute, navigate]);

  const issuesSelectionContextState = useMemo<IssuesSelectionContextState>(() => ({
    selectedIssueIds: multiselectRoute ? multiselectedIssueIds : issueId ? [issueId] : [],
    setSelectedIssueIds,
    multiselectActive: multiselectRoute,
    setMultiselectActive,
  }), [issueId, multiselectRoute, multiselectedIssueIds, setMultiselectActive, setSelectedIssueIds]);

  if (allowedActions && !allowedActions.has(RoleAction.Issues)) return <NotAllowed />;

  return (
    <PageWrapper id="IssuesPage">
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
      >
        <IssuesSelectionContext.Provider value={issuesSelectionContextState}>
          <IssuesFilterContextProvider queryFilterId={queryFilterId} setQueryFilterId={setFilterId}>
            <IssuePrintContextProvider>
              <IssuesPageHeader onClickPrint={onClickPrint} onSelectViewMode={onSelectViewMode} />
              <IssuesFilterPanel />
              <IssuesPanel />
            </IssuePrintContextProvider>
          </IssuesFilterContextProvider>
        </IssuesSelectionContext.Provider>
      </Box>
    </PageWrapper>
  );
}
