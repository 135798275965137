import DocumentListDto from 'documents-lists/types/DocumentListDto';
import PlanlistSortMode from 'documents-lists/types/PlanlistSortMode';
import React, { Dispatch, SetStateAction } from 'react';

export interface PlanlistFilterContextState {
  isFilterActive: boolean,
  searchString: string | undefined,
  setSearchString: Dispatch<SetStateAction<string | undefined>>,
  filterPredicate: (folder: DocumentListDto) => boolean,
  sortMode: PlanlistSortMode,
  setSortMode: Dispatch<SetStateAction<PlanlistSortMode>>,
}

const defaultValue: PlanlistFilterContextState = {
  isFilterActive: false,
  searchString: undefined,
  setSearchString: () => {},
  filterPredicate: () => true,
  sortMode: PlanlistSortMode.NameAsc,
  setSortMode: () => {},
};

const PlanlistFilterContext = React.createContext(defaultValue);

export default PlanlistFilterContext;
