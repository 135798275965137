import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import DocumentDetailsSelectionContext, { DocumentDetailsSelectionChange, DocumentDetailsSelectionContextState } from 'documents-details/contexts/DocumentDetailsSelectionContext';

interface DocumentDetailsSelectionContextProviderProps {
  children?: ReactNode,
  documentVersionId: string | undefined,
  issueId: string | undefined,
  setDocumentVersionAndIssueId: (change: DocumentDetailsSelectionChange) => void,
}

export default function DocumentDetailsSelectionContextProvider({
  children,
  documentVersionId,
  issueId,
  setDocumentVersionAndIssueId,
}: DocumentDetailsSelectionContextProviderProps) {
  const [queryFilterId, setQueryFilterId] = useState<string | undefined>(undefined);
  const [highlightedDocumentCommentId, setHighlightedDocumentCommentId] = useState<string | undefined>(undefined);

  const setDocumentVersionId = useCallback((value: string | undefined) => {
    setDocumentVersionAndIssueId({ documentVersionId: value ?? null });
  }, [setDocumentVersionAndIssueId]);

  const setIssueId = useCallback((value: string | undefined) => {
    setDocumentVersionAndIssueId({ issueId: value ?? null });
  }, [setDocumentVersionAndIssueId]);

  const state = useMemo<DocumentDetailsSelectionContextState>(() => ({
    documentVersionId,
    setDocumentVersionId,
    issueId,
    setIssueId,
    setDocumentVersionAndIssueId,
    highlightedDocumentCommentId,
    setHighlightedDocumentCommentId,
    queryFilterId,
    setQueryFilterId,
  }), [documentVersionId, setDocumentVersionId, issueId, setIssueId, setDocumentVersionAndIssueId, highlightedDocumentCommentId, queryFilterId]);

  return (
    <DocumentDetailsSelectionContext.Provider value={state}>
      {children}
    </DocumentDetailsSelectionContext.Provider>
  );
}
