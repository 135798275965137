import { Box, Typography, useTheme } from '@mui/material';
import InlineTypography from 'common/components/InlineTypography';
import React, {
  useState, useRef, useEffect, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

interface MentionViewProps {
  className?: string,
  value: string | undefined,
  isRichText: boolean | undefined,
}

const mentionSplitRegex = /(@(?:User|UserGroup)?\[[^\]]+\]\([^)]+\))/i;
const mentionLabelRegex = /@(?:User|UserGroup)?\[([^\]]+)\]\([^)]+\)/i;

export default function MentionView({
  className = '',
  value = '',
  isRichText,
}: MentionViewProps) {
  const { t } = useTranslation();
  const [isDescriptionMore, setDescriptionMore] = useState(true);
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  useEffect(() => {
    if (!descriptionRef.current) return;
    if (descriptionRef.current.offsetHeight < descriptionRef.current.scrollHeight) {
      setDescriptionMore(true);
    } else {
      setDescriptionMore(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descriptionRef.current]);

  const theme = useTheme();
  const commentComponents = useMemo(() => {
    if (!value) return undefined;
    const parts = value.split(mentionSplitRegex);
    return parts.map((part) => {
      const labelMatch = part.match(mentionLabelRegex);
      if (labelMatch) return <InlineTypography key={crypto.randomUUID()} sx={{ color: theme.palette.secondary.contrastText }}>{`@${labelMatch[1]}`}</InlineTypography>;
      return <InlineTypography key={crypto.randomUUID()}>{part}</InlineTypography>;
    });
  }, [value, theme]);

  return (isRichText ? (
    <Box className="accordion-content">
      <p
        ref={descriptionRef}
        className={`label-description pre-line${isDescriptionMore ? ' txt-maxline-5' : ''}`}
      >
        {commentComponents}
      </p>
      <Typography
        className="label-more"
        onClick={() => setDescriptionMore(!isDescriptionMore)}
      >
        {isDescriptionMore
          ? t('read_more', 'Read More')
          : value?.length > 600
            ? t('less_more', 'Less more')
            : ''}
      </Typography>
    </Box>
  ) : (
    <p className={`pre-line ${className}`}>
      {commentComponents}
    </p>
  )
  );
}
