import React, { useCallback } from 'react';
import { Button } from '@mui/material';
import { ICellRendererParams } from '@ag-grid-community/core';
import DocumentsDataGridRow from 'documents/types/DocumentDataGridRow';
import CommentIcon from '@mui/icons-material/Comment';
import useDocumentDetailsSelectionContext from 'documents-details/hooks/useDocumentDetailsSelectionContext';

export default function CommentButtonCell({
  data,
}: ICellRendererParams<DocumentsDataGridRow>) {
  const { setHighlightedDocumentCommentId, setDocumentVersionId } = useDocumentDetailsSelectionContext();
  const onClickButton = useCallback(() => {
    if (!data) return;
    setDocumentVersionId(data.id);
    setHighlightedDocumentCommentId(''); // empty string means we open comments but not to a specific comment
  }, [data, setDocumentVersionId, setHighlightedDocumentCommentId]);
  if (!data?.id || data?.isPrivate || data?.isArchived) return null;
  return (
    <Button
      onClick={onClickButton}
      color="secondary"
      variant="contained"
      sx={{ minWidth: 'unset', px: 1 }}
    >
      <CommentIcon />
    </Button>
  );
}
