import { BufferGeometry, Intersection } from "three";
import { IdAttrName, IfcState } from "./BaseDefinitions";
import { VisoInstanceMesh } from "./VisoInstanceMesh";
import { VisoSolidMesh } from "./VisoSolideMesh";

export class PropertyManager {
  private state: IfcState;

  constructor(state: IfcState) {
    this.state = state;
  }

  public static getExpressIdFromGlobalId(idMap: Object, globalId: string) {
    // @ts-ignore
    const id = Object.keys(idMap).find((key) => idMap[key] === globalId);
    // @ts-ignore
    const expressId = parseInt(id);
    return isNaN(expressId) ? Infinity : expressId;
  }

  public static getExpressId(geometry: BufferGeometry, faceIndex: number) {
    if (!geometry.index)
      throw new Error("Geometry does not have index information.");
    const geoIndex = geometry.index.array;
    return geometry.attributes[IdAttrName].getX(geoIndex[3 * faceIndex]);
  }

  getGlobalId(item: Intersection) {
    if (item.object instanceof VisoSolidMesh && item.faceIndex != null) {
      return this.getGlobalIdFromSolid(item.object, item.faceIndex);
    } else if (
      item.object instanceof VisoInstanceMesh &&
      item.instanceId != null
    ) {
      const instance = item.object as VisoInstanceMesh;
      return instance.getGlobalIdAt(item.instanceId);
    }

    return null;
  }

  private getGlobalIdFromSolid(solid: VisoSolidMesh, faceIndex: number) {
    const expressId = PropertyManager.getExpressId(solid.geometry, faceIndex);
    const model = this.state.models[solid.modelId];
    return model.globalIds[expressId];
  }
}
