import useAllowedActions from 'collaborators/hooks/useAllowedActions';
import useCurrentCollaboratorQuery from 'collaborators/hooks/useCurrentCollaboratorQuery';
import useClosedIssueStatus from 'issues/hooks/useClosedIssueStatus';
import IssueDto from 'issues/types/IssueDto';
import RoleAction from 'projects/types/RoleAction';
import { useCallback } from 'react';

export default function useGetIssueStatusEditingRoleRight() {
  const { data: currentCollaborator } = useCurrentCollaboratorQuery();
  const closedIssueStatus = useClosedIssueStatus();
  const allowedActions = useAllowedActions();
  const getCanEditStatus = useCallback((issue: IssueDto) => {
    if (!allowedActions || !closedIssueStatus) {
      return undefined;
    }
    if (!currentCollaborator) return undefined;
    if (issue.issueStatus === closedIssueStatus.id && !allowedActions.has(RoleAction.IssueManagement_StatusEditing_UnsetClosed)) {
      return false;
    }
    if (allowedActions.has(RoleAction.IssueManagement_Reviewer_StatusEditing) && issue.reviewerId && currentCollaborator.id === issue.reviewerId) {
      return true;
    }
    if (allowedActions.has(RoleAction.IssueManagement_Creator_StatusEditing) && issue.createAuthorId && currentCollaborator.id === issue.createAuthorId) {
      return true;
    }
    if (allowedActions.has(RoleAction.IssueManagement_Assigned_StatusEditing) && issue.assignedCollaboratorIds?.some((id) => id === currentCollaborator.id)) {
      return true;
    }
    if (allowedActions.has(RoleAction.IssueManagement_StatusEditing)) {
      return true;
    }
    return false;
  }, [allowedActions, closedIssueStatus, currentCollaborator]);

  return getCanEditStatus;
}
