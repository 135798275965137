import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import CollaboratorsDataGridRow from 'collaborators/types/CollaboratorsDataGridRow';
import useCollaboratorGroupsQuery from 'collaborators-groups/hooks/useCollaboratorGroupsQuery';
import ChipMultiselect from 'common/components/ChipMultiselect';
import useCurrentCollaboratorRole from 'collaborators/hooks/useCurrentCollaboratorRole';
import RoleAction from 'projects/types/RoleAction';
import CollaboratorGroupDto from 'collaborators-groups/types/CollaboratorGroupDto';
import CollaboratorGroupAssignmentUpdateDto from 'collaborators/types/CollaboratorGroupAssignmentUpdateDto';
import useCollaboratorGroupAssignmentUpdateMutation from 'collaborators/hooks/useCollaboratorGroupAssignmentUpdateMutation';

export default function GroupCell({
  data,
}: ICellRendererParams<CollaboratorsDataGridRow, string>) {
  const currentUserRole = useCurrentCollaboratorRole();
  const { data: currentProjectCollaboratorGroups } = useCollaboratorGroupsQuery();
  const defaultCollaboratorGroupIds = useMemo(() => currentProjectCollaboratorGroups?.filter((g) => g.isDefault).map((g) => g.id), [currentProjectCollaboratorGroups]);
  const userGroupsById = useMemo(() => (currentProjectCollaboratorGroups ? new Map<string, CollaboratorGroupDto>(currentProjectCollaboratorGroups.map((g) => [g.id, g])) : undefined), [currentProjectCollaboratorGroups]);
  const { mutateAsync, isPending } = useCollaboratorGroupAssignmentUpdateMutation();
  const [displayValue, setDisplayValue] = useState<string[]>(data?.userGroups?.map((g) => g.id) ?? []);
  useEffect(() => setDisplayValue(data?.userGroups?.map((g) => g.id) ?? []), [data?.userGroups]);

  const onChange = useCallback(async (selectedCollaboratorGroupIds: string[]) => {
    if (!data?.userGroups) return;
    setDisplayValue(selectedCollaboratorGroupIds);

    const previousCollaboratorGroupIds = new Set(data.userGroups.map((g) => g.id));
    const nextCollaboratorGroupIds = new Set(selectedCollaboratorGroupIds);
    const userGroupsAdded = selectedCollaboratorGroupIds.filter((id) => !previousCollaboratorGroupIds.has(id)).filter((id) => userGroupsById?.has(id)).map((id) => userGroupsById?.get(id)!);
    const userGroupsRemoved = data.userGroups.map((g) => g.id).filter((id) => !nextCollaboratorGroupIds.has(id)).filter((id) => userGroupsById?.has(id)).map((id) => userGroupsById?.get(id)!);
    const addUpdateDtos: CollaboratorGroupAssignmentUpdateDto[] = userGroupsAdded.map((group) => ({
      collaboratorId: data.id,
      collaboratorGroupIds: [...data.collaboratorGroupIds, group.id],
    }));
    const removeUpdateDtos: CollaboratorGroupAssignmentUpdateDto[] = userGroupsRemoved.map((group) => ({
      collaboratorId: data.id,
      collaboratorGroupIds: data.collaboratorGroupIds.filter((id) => id !== group.id),
    }));
    await Promise.all(addUpdateDtos.concat(removeUpdateDtos).map(async (dto) => {
      await mutateAsync(dto);
    }));
  }, [data, mutateAsync, userGroupsById]);
  const disabled = useMemo(() => !currentProjectCollaboratorGroups?.length || isPending, [currentProjectCollaboratorGroups?.length, isPending]);
  if (!currentUserRole?.allowedActions?.has(RoleAction.Project_AssignToGroup)) return data?.userGroups?.map((g) => g.name).join(', ');
  return (
    <ChipMultiselect
      entities={currentProjectCollaboratorGroups}
      disabledEntityIds={defaultCollaboratorGroupIds}
      value={displayValue}
      disabled={disabled}
      onChange={onChange}
      variant="standard"
      sx={{ width: '100%' }}
      suppressSelectAll
    />
  );
}
