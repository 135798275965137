import useDisplayNamesByFileType from 'documents/hooks/useDisplayNamesByFileType';
import DocumentVersionDto from 'documents/types/DocumentVersionDto';
import { FileType } from 'Entities/RestEntities/DocumentVersion';
import { useMemo } from 'react';

type DocumentsValueFormatter = (params: { data: DocumentVersionDto | undefined }) => string;

export default function useDocumentsDataGridValueFormatters() {
  const fileTypeDisplayNameMap = useDisplayNamesByFileType();
  const valueFormatterByColumnName = useMemo(() => new Map<string, DocumentsValueFormatter>([
    ['name', ({ data }) => data?.name ?? ''],
    ['fileType', ({ data }) => (data ? fileTypeDisplayNameMap[data.fileType] ?? FileType[data.fileType] : '')],
    ['uploadDate', ({ data }) => (data ? new Date(data.uploadDate).toLocaleString('de', { dateStyle: 'medium', timeStyle: 'medium' }) : '')],
    ['editDate', ({ data }) => (data ? new Date(data.editDate).toLocaleString('de', { dateStyle: 'medium', timeStyle: 'medium' }) : '')],
    ['creationDate', ({ data }) => (data?.creationDate ? new Date(data.creationDate).toLocaleString('de', { dateStyle: 'medium', timeStyle: 'medium' }) : '')],
    ['creator', ({ data }) => (data ? `${data.creator.firstName} ${data.creator.lastName}` : '')],
    ['editor', ({ data }) => (data?.editor ? `${data.editor.firstName} ${data.editor.lastName}` : '')],
    ['commentIds', ({ data }) => (data ? `${data.commentIds.length}` : '')],
    ['fileSize', ({ data }) => {
      if (!data) return '';
      if (data.fileSize < 1000000) return `${parseFloat((data.fileSize / 1000).toFixed(2))} kB`;
      if (data.fileSize < 1000000000) return `${parseFloat((data.fileSize / 1000000).toFixed(2))} MB`;
      if (data.fileSize < 1000000000000) return `${parseFloat((data.fileSize / 1000000000).toFixed(2))} GB`;
      return `${parseFloat((data.fileSize / 1000000000000).toPrecision(2))} TB`;
    }],
    ['status', ({ data }) => (data?.status ? data.status : '')],
    ['linkedIssueIds', ({ data }) => (data?.documentLinkedIssueIds ? `${data.documentLinkedIssueIds.length}` : '')],
  ]), [fileTypeDisplayNameMap]);

  return valueFormatterByColumnName;
}
