import { createContext } from 'react';

export interface DocumentUiConfigContextState {
  suppressFolderManipulation?: boolean,
  suppressFooterDrawer?: boolean,
}

const defaultValue: DocumentUiConfigContextState = {
  suppressFolderManipulation: undefined,
  suppressFooterDrawer: undefined,
};

const DocumentUiConfigContext = createContext<DocumentUiConfigContextState>(defaultValue);
export default DocumentUiConfigContext;
