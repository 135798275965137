import React, { useCallback, useMemo } from 'react';
import { Box, CircularProgress, useTheme } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useIssuesOdataQuery from 'issues/hooks/useIssuesOdataQuery';
import { ITEM_ROOT } from 'odata-query';
import IssueItemCard from 'issues/components/IssueItemCard';
import useModelsPanelDockviewContext from 'models/hooks/useModelsPanelDockviewContext';
import { ModelsPanelDockviewPanelId } from 'models/contexts/ModelsPanelDockviewContext';
import useModelSelectionContext from 'models/hooks/useModelSelectionContext';

interface ComponentDetailsLinkedIssuesPanelProps extends ISxProps {
  globalId: string,
}

export default function ComponentDetailsLinkedIssuesPanel({
  sx,
  globalId,
}: ComponentDetailsLinkedIssuesPanelProps) {
  const theme = useTheme();
  const { setPanelActive } = useModelsPanelDockviewContext();
  const { setSelectedIssueId } = useModelSelectionContext();
  const issuesOdataQuery = useMemo(() => ({
    filter: {
      or: [
        { linkedComponentsGlobalIds: { any: { [ITEM_ROOT]: globalId } } },
      ],
    },
  }), [globalId]);
  const { data: issues, isLoading: isLoadingIssues } = useIssuesOdataQuery(issuesOdataQuery);
  const onClickIssue = useCallback((issueId: string) => {
    setSelectedIssueId(issueId);
    setPanelActive(ModelsPanelDockviewPanelId.Issues);
  }, [setPanelActive, setSelectedIssueId]);
  return (
    <Box
      id="ComponentDetailsLinkedIssuesPanel"
      sx={{ flex: '1 1 0', display: 'flex', background: theme.palette.grey[200], flexDirection: 'column', overflow: 'auto', p: 2, gap: 2, boxShadow: 'inset 0px 24px 24px -24px rgba(0,0,0,0.1)', ...sx }}
    >
      {!!isLoadingIssues && <CircularProgress sx={{ mt: 4, alignSelf: 'center' }} />}
      {!!issues && issues.map((issue) => <IssueItemCard key={issue.id} issue={issue} onClick={onClickIssue} />)}
    </Box>
  );
}
