import type { Theme, ComponentsProps, ComponentsVariants } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

interface MuiDialogComponent {
  defaultProps?: ComponentsProps['MuiDialog'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiDialog'];
  variants?: ComponentsVariants['MuiDialog'];
}

const VisoDialog: MuiDialogComponent = {
  styleOverrides: {
    paper: ({ theme }) => ({
      borderRadius: '9px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
      boxShadow: 'none',
    }),
  },
};

export default VisoDialog;
