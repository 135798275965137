import type { Theme, ComponentsProps, ComponentsVariants } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import Color from 'color';

interface MuiChipComponent {
  defaultProps?: ComponentsProps['MuiChip'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiChip'];
  variants?: ComponentsVariants['MuiChip'];
}

const VisoButton: MuiChipComponent = {
  styleOverrides: {
    root: () => ({
      borderRadius: 8,
      fontSize: 14,
      fontWeight: 500,
    }),
    sizeSmall: () => ({
      fontSize: 14,
      height: 18,
      borderRadius: 6,
    }),
    colorSuccess: ({ theme }) => ({
      color: theme.palette.success.main,
      backgroundColor: Color(theme.palette.success.main).lightness(92).hex(),
    }),
    colorInfo: ({ theme }) => ({
      color: theme.palette.info.main,
      backgroundColor: Color(theme.palette.info.main).lightness(92).hex(),
    }),
    colorError: ({ theme }) => ({
      color: theme.palette.error.main,
      backgroundColor: Color(theme.palette.error.main).lightness(92).hex(),
    }),
    colorWarning: ({ theme }) => ({
      color: theme.palette.warning.main,
      backgroundColor: Color(theme.palette.warning.main).lightness(92).hex(),
    }),
    label: () => ({
      lineHeight: 1.2,
    }),
  },
};

export default VisoButton;
