import React, {
  useCallback, useRef, useState,
} from 'react';
import {
  Alert,
  AlertTitle,
  Button, Menu, MenuItem, Portal, Snackbar, Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ISxProps from 'common/types/ISxProps';
import AddIcon from '@mui/icons-material/Add';
import CreateFolderDialog from 'documents-folders/components/CreateFolderDialog';
import useCurrentFolderId from 'documents-folders/hooks/useCurrentFolderId';
import useFolder from 'documents-folders/hooks/useFolder';

interface CreateFolderMenuButtonProps extends ISxProps {
}

export default function CreateFolderMenuButton({
  sx,
}: CreateFolderMenuButtonProps) {
  const { t } = useTranslation('documents-folders');
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCreateSubfolderDialogOpen, setIsCreateSubfolderDialogOpen] = useState(false);
  const [isCreateRootfolderDialogOpen, setIsCreateRootfolderDialogOpen] = useState(false);
  const currentFolderId = useCurrentFolderId();
  const folder = useFolder(currentFolderId);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);

  const onClickCreateSubfolder = useCallback(() => {
    setIsCreateSubfolderDialogOpen(true);
    setIsMenuOpen(false);
  }, []);

  const onCloseCreateSubfolderDialog = useCallback((message: string | undefined) => {
    setIsCreateSubfolderDialogOpen(false);
    setSuccessMessage(message);
  }, []);

  const onClickCreateRootfolder = useCallback(() => {
    setIsCreateRootfolderDialogOpen(true);
    setIsMenuOpen(false);
  }, []);

  const onCloseCreateRootfolderDialog = useCallback((message: string | undefined) => {
    setIsCreateRootfolderDialogOpen(false);
    setSuccessMessage(message);
  }, []);

  return (
    <>
      <Tooltip title={t('create-folder-menu-button_tooltip', 'Create (sub-)folder')}>
        <span>
          <Button
            onClick={() => setIsMenuOpen(true)}
            ref={buttonRef}
            sx={{ ...sx, minWidth: 'unset', px: 1 }}
            variant="contained"
            id="CreateFolderMenuButton"
          >
            <AddIcon />
          </Button>
        </span>
      </Tooltip>
      <Menu
        anchorEl={buttonRef.current}
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      >
        <MenuItem id="CreateFolderMenuButtonCreateRootFolder" onClick={onClickCreateRootfolder}>
          {t('create-folder-menu-button_create-root-folder', 'Create root folder')}
        </MenuItem>
        {folder ? (
          <MenuItem id="CreateFolderMenuButtonCreateSubFolder" onClick={onClickCreateSubfolder}>
            {t('create-folder-menu-button_create-subfolder', 'Create subfolder in "{{currentFolderName}}"', { currentFolderName: folder.name })}
          </MenuItem>
        ) : (
          <MenuItem disabled>
            {t('create-folder-menu-button_create-subfolder-disabled', 'Create subfolder')}
          </MenuItem>
        )}
      </Menu>
      {isCreateSubfolderDialogOpen && currentFolderId && <CreateFolderDialog onClose={onCloseCreateSubfolderDialog} parentFolderId={currentFolderId} />}
      {isCreateRootfolderDialogOpen && <CreateFolderDialog onClose={onCloseCreateRootfolderDialog} parentFolderId={undefined} />}
      {!!successMessage && (
        <Portal>
          <Snackbar open onClose={() => setSuccessMessage(undefined)} autoHideDuration={5000}>
            <Alert severity="success">
              <AlertTitle>{t('create-folder-menu-button_success-alert-title', 'Success')}</AlertTitle>
              {successMessage}
            </Alert>
          </Snackbar>
        </Portal>
      )}
    </>
  );
}
