import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import { useCallback } from 'react';

export default function usePdfAnnotationDeleteMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases: annotationQueryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.PdfAnnotation);
  const { queryKeyBases: issueQueryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.Issue);
  const { queryKeyBases: documentVersionQueryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentVersion);
  const mutationFn = useCallback(async (id: string) => {
    await axiosInstance.delete<void>(`${ApiEndpoint.PdfAnnotation}/${id}`);
    queryClient.invalidateQueries({ queryKey: annotationQueryKeyBases });
    queryClient.invalidateQueries({ queryKey: issueQueryKeyBases });
    queryClient.invalidateQueries({ queryKey: documentVersionQueryKeyBases });
  }, [annotationQueryKeyBases, axiosInstance, documentVersionQueryKeyBases, issueQueryKeyBases, queryClient]);
  return useMutation<void, unknown, string>({ mutationFn });
}
