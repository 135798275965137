import RoleAction from 'projects/types/RoleAction';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type RoleActionTranslations = {
  [key in RoleAction]: string;
};

export default function useRoleActionTranslations() {
  const { t } = useTranslation('settings');
  return useMemo<RoleActionTranslations>(() => ({
    [RoleAction.ActivityLog]: t('use-role-action-translations_ActivityLog', 'Activity Log Management'),
    [RoleAction.ActivityLog_Export]: t('use-role-action-translations_ActivityLog_Export', 'can export the activity log'),
    [RoleAction.Project_Management]: t('use-role-action-translations_Project_Management', 'Project Management'),
    [RoleAction.Project_Copy]: t('use-role-action-translations_Project_Copy', 'can copy projects'),
    [RoleAction.Project_CollaboratorManagement]: t('use-role-action-translations_Project_CollaboratorManagement', 'can manage project collaborators'),
    [RoleAction.Project_CreateGroups]: t('use-role-action-translations_Project_CreateGroups', 'can create collaborator groups'),
    [RoleAction.Project_DeleteGroups]: t('use-role-action-translations_Project_DeleteGroups', 'can delete collaborator groups'),
    [RoleAction.Project_Edit_2Fa]: t('use-role-action-translations_Project_Edit_2Fa', 'can change project two-factor-authentication settings'),
    [RoleAction.Project_RoleManagement]: t('use-role-action-translations_Project_RoleManagement', 'Collaborator role management'),
    [RoleAction.Project_Create_Roles]: t('use-role-action-translations_Project_CreateRoles', 'can create collaborator roles'),
    [RoleAction.Project_Delete_Roles]: t('use-role-action-translations_Project_DeleteRoles', 'can delete collaborator roles'),
    [RoleAction.Issues]: t('use-role-action-translations_Issues', 'Issue Management'),
    [RoleAction.Dashboard]: t('use-role-action-translations_Dashboard', 'can access the dashboard'),
    [RoleAction.IssueManagement_Creation]: t('use-role-action-translations_IssueManagement_Creation', 'can create issues'),
    [RoleAction.IssueManagement_Editing]: t('use-role-action-translations_IssueManagement_Editing', 'can edit issues'),
    [RoleAction.IssueManagement_StatusEditing]: t('use-role-action-translations_IssueManagement_StatusEditing', 'can change an issue\'s status'),
    [RoleAction.IssueManagement_CreatingComments]: t('use-role-action-translations_IssueManagement_CreatingComments', 'can create issue comments'),
    [RoleAction.IssueManagement_BcfUpload]: t('use-role-action-translations_IssueManagement_BcfUpload', 'can import issues from BCF files'),
    [RoleAction.IssueManagement_Export]: t('use-role-action-translations_IssueManagement_Export', 'can export issues to BCF files'),
    [RoleAction.IssueManagement_Assigned_Editing]: t('use-role-action-translations_IssueManagement_Assigned_Editing', 'can edit issues they are assigned to'),
    [RoleAction.IssueManagement_Assigned_StatusEditing]: t('use-role-action-translations_IssueManagement_Assigned_StatusEditing', 'can edit the status of issues they are assigned to'),
    [RoleAction.IssueManagement_Creator_Editing]: t('use-role-action-translations_IssueManagement_Creator_Editing', 'can edit issues they created'),
    [RoleAction.IssueManagement_Creator_StatusEditing]: t('use-role-action-translations_IssueManagement_Creator_StatusEditing', 'can edit the status of issues they created'),
    [RoleAction.IssueManagement_Reviewer_Editing]: t('use-role-action-translations_IssueManagement_Reviewer_Editing', 'can edit issues where they are reviewer'),
    [RoleAction.IssueManagement_Reviewer_StatusEditing]: t('use-role-action-translations_IssueManagement_Reviewer_StatusEditing', 'can edit the status of issues where they are reviewer'),
    [RoleAction.IssueManagement_StatusEditing_SetClosed]: t('use-role-action-translations_IssueManagement_StatusEditing_SetClosed', 'can set an issue\'s status to \'closed\''),
    [RoleAction.IssueManagement_StatusEditing_UnsetClosed]: t('use-role-action-translations_IssueManagement_StatusEditing_UnsetClosed', 'can set an issue\'s status from \'closed\' to something else'),
    [RoleAction.Documents]: t('use-role-action-translations_Documents', 'Document Management'),
    [RoleAction.Document_Upload]: t('use-role-action-translations_Document_Upload', 'can upload documents'),
    [RoleAction.Document_Download]: t('use-role-action-translations_Document_Download', 'can download documents'),
    [RoleAction.Document_Editing]: t('use-role-action-translations_Document_Editing', 'can edit documents'),
    [RoleAction.Document_Sharing]: t('use-role-action-translations_Document_Sharing', 'can share documents'),
    [RoleAction.Document_CommentEditing]: t('use-role-action-translations_Document_CommentEditing', 'can edit document comments'),
    [RoleAction.Document_Archiving]: t('use-role-action-translations_Document_Archiving', 'can archive documents from public and restricted folders'),
    [RoleAction.Document_GetArchived]: t('use-role-action-translations_Document_GetArchived', 'can access the public tab of the archive'),
    [RoleAction.Document_RestoreArchived]: t('use-role-action-translations_Document_RestoreArchived', 'can restore archvied documents'),
    [RoleAction.Document_Import]: t('use-role-action-translations_Document_Import', 'Document Import'),
    [RoleAction.Document_IfcImport]: t('use-role-action-translations_Document_IfcImport', 'can import 3D models'),
    [RoleAction.Document_FloorPlanImport]: t('use-role-action-translations_Document_FloorPlanImport', 'can share 2D plans'),
    [RoleAction.Document_StatusEditing]: t('use-role-action-translations_Document_StatusEditing', 'can change a document\'s status'),
    [RoleAction.Folder_Edit]: t('use-role-action-translations_Folder_Edit', 'Folder Management'),
    [RoleAction.Folder_EditAccessList]: t('use-role-action-translations_Folder_EditAccessList', 'can edit folders access rights'),
    [RoleAction.Folder_Create]: t('use-role-action-translations_Folder_Create', 'can create folders'),
    [RoleAction.Folder_Delete]: t('use-role-action-translations_Folder_Delete', 'can delete folders'),
    [RoleAction.Tag_Management]: t('use-role-action-translations_Tag_Management', 'Tag Management'),
    [RoleAction.Tag_Create]: t('use-role-action-translations_Tag_Create', 'can create tags'),
    [RoleAction.Tag_Delete]: t('use-role-action-translations_Tag_Delete', 'can delete tags'),
    [RoleAction.Label_Special]: t('use-role-action-translations_Label_Special', 'can manage project metadata'),
    [RoleAction.Label_Special_Create]: t('use-role-action-translations_Label_Special_Create', 'can create project metadata'),
    [RoleAction.Label_Special_Delete]: t('use-role-action-translations_Label_Special_Delete', 'can delete project metadata'),
    [RoleAction.NamingScheme_Management]: t('use-role-action-translations_NamingScheme_Management', 'Naming Scheme Management'),
    [RoleAction.NamingScheme_Create]: t('use-role-action-translations_NamingScheme_Create', 'can create naming schemes'),
    [RoleAction.NamingScheme_Delete]: t('use-role-action-translations_NamingScheme_Delete', 'can delete naming schemes'),
    [RoleAction.DocumentList]: t('use-role-action-translations_DocumentList', 'Plan List Management'),
    [RoleAction.DocumentList_Create]: t('use-role-action-translations_DocumentList_Create', 'can create plan lists'),
    [RoleAction.DocumentList_Delete]: t('use-role-action-translations_DocumentList_Delete', 'can delete plan lists'),
    [RoleAction.DocumentFilter]: t('use-role-action-translations_DocumentFilter', 'Document Filter Management'),
    [RoleAction.DocumentFilter_Create]: t('use-role-action-translations_DocumentFilter_Create', 'can create public document filters'),
    [RoleAction.DocumentFilter_Delete]: t('use-role-action-translations_DocumentFilter_Delete', 'can delete public document filters'),
    [RoleAction.DocStatus_ChangeToPublished]: t('use-role-action-translations_DocStatus_ChangeToPublished', 'can set a document\'s status to \'published\''),
    [RoleAction.DocStatus_ChangeFromPublished]: t('use-role-action-translations_DocStatus_ChangeFromPublished', 'can set a document\'s status from \'published\' to something else'),
    [RoleAction.DocStamp_Edit]: t('use-role-action-translations_DocStamp_Edit', 'can edit document export stamp settings'),
    [RoleAction.SmartView_DeleteFromDifferentCreator]: t('use-role-action-translations_SmartView_DeleteFromDifferentCreator', 'can delete SmartViews created by another collaborator'),
    [RoleAction.Inbox_Edit]: t('use-role-action-translations_Inbox_Edit', 'can edit email inbox settings'),
    [RoleAction._3DModel]: t('use-role-action-translations__3DModel', '3D Model Management'),
    [RoleAction._3DModel_Properties]: t('use-role-action-translations__3DModel_Properties', '3D Model Property Management'),
    [RoleAction._3DModel_Edit_Properties]: t('use-role-action-translations__3DModel_Edit_Properties', 'can edit properties'),
    [RoleAction._3DModel_3D_View]: t('use-role-action-translations__3DModel_3D_View', 'can view 3D models'),
    [RoleAction._3DModel_SmartView]: t('use-role-action-translations__3DModel_SmartView', 'can use smart views'),
    [RoleAction._2DModel]: t('use-role-action-translations__2DModel', '2D Plans'),
    [RoleAction.Email_View]: t('use-role-action-translations_Email_View', 'Email View'),
    [RoleAction.Email_Upload]: t('use-role-action-translations_Email_Upload', 'can upload emails'),
    [RoleAction.BCF]: t('use-role-action-translations_BCF', 'BCF API'),
    [RoleAction.BCF_Project_update]: t('use-role-action-translations_BCF_Project_update', 'can update projects'),
    [RoleAction.BCF_Project_createTopic]: t('use-role-action-translations_BCF_Project_createTopic', 'can create topics'),
    [RoleAction.BCF_Project_createDocument]: t('use-role-action-translations_BCF_Project_createDocument', 'can create documents'),
    [RoleAction.BCF_Topic_update]: t('use-role-action-translations_BCF_Topic_update', 'can update topics'),
    [RoleAction.BCF_Topic_updateRelatedTopics]: t('use-role-action-translations_BCF_Topic_updateRelatedTopics', 'can update unrelated topics'),
    [RoleAction.BCF_Topic_updateDocumentReferences]: t('use-role-action-translations_BCF_Topic_updateDocumentReferences', 'can update document references'),
    [RoleAction.BCF_Topic_updateFiles]: t('use-role-action-translations_BCF_Topic_updateFiles', 'can update files'),
    [RoleAction.BCF_Topic_createComment]: t('use-role-action-translations_BCF_Topic_createComment', 'can create topic comments'),
    [RoleAction.BCF_Topic_createViewpoint]: t('use-role-action-translations_BCF_Topic_createViewpoint', 'can create viewpoints'),
    [RoleAction.BCF_Topic_delete]: t('use-role-action-translations_BCF_Topic_delete', 'can delete topics'),
    [RoleAction.BCF_Viewpoint_delete]: t('use-role-action-translations_BCF_Viewpoint_delete', 'can delete viewpoints'),
    [RoleAction.BCF_Comment_update]: t('use-role-action-translations_BCF_Comment_update', 'can edit comments'),
    [RoleAction.BCF_Comment_delete]: t('use-role-action-translations_BCF_Comment_delete', 'can delete comments'),
    [RoleAction.Project_EditCollaborator]: t('use-role-action-translations_Project_EditCollaborator', 'can edit collaborators'),
    [RoleAction.Project_EditGroup]: t('use-role-action-translations_Project_EditGroup', 'can edit collaborator groups'),
    [RoleAction.Project_AssignToGroup]: t('use-role-action-translations_Project_AssignToGroup', 'can assign collaborators to groups'),
    [RoleAction.Project_AssignRoles]: t('use-role-action-translations_Project_AssignRoles', 'can assign roles to collaborators'),
    [RoleAction.Project_AssignAdmin]: t('use-role-action-translations_Project_AssignAdmin', 'can assign project admin role to collaborators'),
    [RoleAction.Project_Edit_DmsSettings]: t('use-role-action-translations_Project_Edit_DmsSettings', 'can edit DMS settings'),
  }), [t]);
}
