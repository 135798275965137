import CollaboratorGroupDto from 'collaborators-groups/types/CollaboratorGroupDto';
import CollaboratorDto from 'collaborators/types/CollaboratorDto';
import React, {
  ReactNode, useState, useMemo,
  useCallback,
} from 'react';
import ShareRecipientsFilterContext, { ShareRecipientsFilterContextState } from 'share/contexts/ShareRecipientsFilterContext';
import ShareRecipientsSortMode from 'share/types/ShareRecipientsSortMode';

interface ShareRecipientsFilterContextProviderProps {
  children?: ReactNode,
}

export default function ShareRecipientsFilterContextProvider({
  children,
}: ShareRecipientsFilterContextProviderProps) {
  const [searchString, setSearchString] = useState<string | undefined>(undefined);
  const isFilterActive = useMemo(() => !!searchString?.length, [searchString]);
  const collaboratorsFilterPredicate = useCallback((collaborator: CollaboratorDto) => {
    if (!searchString) return true;
    return collaborator.firstName.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) || collaborator.lastName.toLocaleLowerCase().includes(searchString.toLocaleLowerCase());
  }, [searchString]);
  const groupsFilterPredicate = useCallback((collaboratorGroup: CollaboratorGroupDto) => {
    if (!searchString) return true;
    return collaboratorGroup.name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase());
  }, [searchString]);
  const [sortModeState, setSortModeState] = useState<ShareRecipientsSortMode>(localStorage.getItem('shareRecipientsSortMode') as ShareRecipientsSortMode ?? ShareRecipientsSortMode.NameAsc);

  const setSortMode = useCallback((state: ShareRecipientsSortMode) => {
    setSortModeState(state);
    localStorage.setItem('shareRecipientsSortMode', state);
  }, []);

  const state: ShareRecipientsFilterContextState = useMemo(() => ({
    isFilterActive,
    searchString,
    setSearchString,
    collaboratorsFilterPredicate,
    groupsFilterPredicate,
    sortMode: sortModeState,
    setSortMode,
  }), [isFilterActive, searchString, collaboratorsFilterPredicate, groupsFilterPredicate, sortModeState, setSortMode]);

  return (
    <ShareRecipientsFilterContext.Provider value={state}>
      {children}
    </ShareRecipientsFilterContext.Provider>
  );
}
