import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import useViewer3dContext from 'models/hooks/useViewer3dContext';
import LinkedComponentListItem, { ComponentLinkAction } from 'issues/components/LinkedComponentListItem';
import { useTranslation } from 'react-i18next';
import ISxProps from 'common/types/ISxProps';
import ComponentDetailsPropertiesPanel from 'models/components/ComponentDetailsPropertiesPanel';
import ComponentDetailsLinkedIssuesPanel from 'models/components/ComponentDetailsLinkedIssuesPanel';

enum ComponentDetailsTab {
  Properties = 'properties',
  Issues = 'issues',
}

interface ComponentDetailsPanelProps extends ISxProps {
}

export default function ComponentDetailsPanel({
  sx,
}: ComponentDetailsPanelProps) {
  const { t } = useTranslation('models');
  const [activeTab, setActiveTab] = useState<ComponentDetailsTab>(ComponentDetailsTab.Properties);
  const onChangeTab = useCallback((event: SyntheticEvent, value: ComponentDetailsTab) => setActiveTab(value), []);
  const { selectedGlobalIds, getNode } = useViewer3dContext();
  const selectedNodes = useMemo(() => selectedGlobalIds.map((globalId) => getNode(globalId)).filter(Boolean).map((node) => node!), [getNode, selectedGlobalIds]);
  // TODO #18444: when implementing component history, use this query:
  // const { data: nodeHistory } = useNodeHistoryQuery(selectedGlobalIds?.length === 1 ? selectedGlobalIds[0] : undefined);
  return (
    <Box id="ComponentDetailsPanel" sx={{ display: 'flex', flexDirection: 'column', ...sx }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {selectedNodes.map((node) => (
          <LinkedComponentListItem
            key={node.id}
            elementName={node.elementName}
            globalId={node.globalId}
            nodeLoaded
            pendingAction={ComponentLinkAction.LeavePersisted}
          />
        ))}
      </Box>
      <Box sx={{ boxShadow: 'inset 0px 0px 8px -4px rgba(0,0,0,0.4)', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        {selectedNodes.length === 0 && (
          <Typography sx={{ fontStyle: 'italic', p: 2 }}>{t('component-details-panel_nothing-selected-message', 'No component selected')}</Typography>
        )}
        {selectedNodes.length > 1 && (
          <Typography sx={{ fontStyle: 'italic', p: 2 }}>{t('component-details-panel_multiple-selected-message', 'Multiple components selected')}</Typography>
        )}
        {selectedNodes.length === 1 && (
          <>
            <Tabs value={activeTab} onChange={onChangeTab}>
              <Tab value={ComponentDetailsTab.Properties} label={t('component-details-panel_properties-tab-label', 'Properties')} />
              <Tab value={ComponentDetailsTab.Issues} label={t('component-details-panel_issues-tab-label', 'Linked Issues')} />
            </Tabs>
            {activeTab === ComponentDetailsTab.Properties && <ComponentDetailsPropertiesPanel sx={{ flexGrow: 1 }} propertyGroupIds={selectedNodes[0].propertyGroupIds} />}
            {activeTab === ComponentDetailsTab.Issues && <ComponentDetailsLinkedIssuesPanel sx={{ flexGrow: 1 }} globalId={selectedNodes[0].globalId} />}
          </>
        )}
      </Box>
    </Box>
  );
}
