import React, { ForwardedRef, forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import { Box, Button, Checkbox, Chip, CircularProgress, IconButton, Menu, MenuItem, Popover, TextField, useTheme } from '@mui/material';
import useProjectTagsQuery from 'tags/hooks/useProjectTagsQuery';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AddIcon from '@mui/icons-material/Add';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import ColoredChip from 'common/components/ColoredChip';
import { useTranslation } from 'react-i18next';
import Color from 'color';
import { SketchPicker } from 'react-color';
import useLabelCreateMutation from 'labels/hooks/useLabelCreateMutation';
import CreateLabelDto from 'labels/types/CreateLabelDto';
import useChipVisibility from 'common/hooks/useChipVisibility';
import useCurrentCollaboratorRole from 'collaborators/hooks/useCurrentCollaboratorRole';
import RoleAction from 'projects/types/RoleAction';
import LabelType from 'labels/types/LabelType';

const defaultTagColors = [
  Color('#002FE9'),
  Color('#56B977'),
  Color('#F88282'),
  Color('#FFAA66'),
  Color('#C46BCB'),
  Color('#6BC9F3'),
  Color('#E9D100'),
  Color('#00D449'),
  Color('#DD006A'),
  Color('#6456B9'),
];

interface TagCreateSelectProps {
  value: string[] | undefined,
  onChange: (tagIds: string[]) => void,
  disabled?: boolean,
}

const TagCreateSelectInputComponent = forwardRef((
  {
    value,
    onChange,
    disabled,
  }: TagCreateSelectProps,
  ref: ForwardedRef<unknown>,
) => {
  React.useImperativeHandle(ref, () => ({
    focus: () => {},
  }));

  const { t } = useTranslation('tags');
  const theme = useTheme();
  const currentUserRole = useCurrentCollaboratorRole();
  const { data: tags } = useProjectTagsQuery();
  const { mutateAsync, isPending: isLoadingCreateMutation } = useLabelCreateMutation();
  const inputWrapperRef = useRef<HTMLDivElement>(null);
  const chipListRef = useRef<HTMLDivElement>(null);
  const chipListWrapperRef = useRef<HTMLDivElement>(null);
  const openColorPickerButtonRef = React.useRef<HTMLButtonElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isColorPickerOpen, setIsColorPickerOpen] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');
  const selectedTagIdsSet = useMemo<ReadonlySet<string>>(() => new Set(value), [value]);
  const selectedTags = useMemo(() => tags?.filter((tag) => selectedTagIdsSet.has(tag.id)), [selectedTagIdsSet, tags]);
  const tagNames = useMemo<ReadonlySet<string> | undefined>(() => (tags ? new Set(tags.map((tag) => tag.name)) : undefined), [tags]);
  const isAllowedToCreate = useMemo(() => !!currentUserRole?.allowedActions?.has(RoleAction.Tag_Create), [currentUserRole?.allowedActions]);
  const inputStringIsValidNewName = useMemo(() => searchString.trim().length > 0 && searchString.trim().length <= 25 && tagNames && !tagNames.has(searchString), [searchString, tagNames]);
  const nextDefaultColor = useMemo(() => defaultTagColors[tags ? tags.length % defaultTagColors.length : 0], [tags]);
  const [newTagColor, setNewTagColor] = useState<Color | undefined>(undefined);
  const searchResultTags = useMemo(() => {
    if (!tags) return [];
    const lowerCaseSearchString = searchString.trim().toLocaleLowerCase();
    if (lowerCaseSearchString.length === 0) return tags;
    return tags.filter((tag) => tag.name.toLocaleLowerCase().includes(lowerCaseSearchString));
  }, [searchString, tags]);
  const newTag = useMemo(() => ({
    color: (newTagColor ?? nextDefaultColor).hex(),
    name: searchString,
  }), [newTagColor, nextDefaultColor, searchString]);

  const onRemoveTag = useCallback((tagNamesToRemove: string) => {
    if (!value) return;
    onChange(value.filter((tag) => tagNamesToRemove !== tag));
  }, [onChange, value]);

  const onAddTag = useCallback((tagNamesToAdd: string) => {
    if (!value) return;
    onChange(Array.from(new Set([...value, tagNamesToAdd])));
  }, [onChange, value]);

  const onToggleTag = useCallback((tagId: string) => {
    if (selectedTagIdsSet.has(tagId)) onRemoveTag(tagId);
    else onAddTag(tagId);
  }, [onAddTag, onRemoveTag, selectedTagIdsSet]);

  const onClickCreateNew = useCallback(async () => {
    try {
      const persistLabelDto: CreateLabelDto = {
        type: LabelType.Tag,
        color: newTagColor?.hex() ?? nextDefaultColor.hex(),
        name: searchString.trim(),
      };
      await mutateAsync(persistLabelDto);
    } catch (e) { /* empty */ }
  }, [mutateAsync, newTagColor, nextDefaultColor, searchString]);

  const onClickOpenMenu = useCallback(async () => {
    if (disabled) return;
    setIsMenuOpen(true);
  }, [disabled]);

  const { chipRefCallback, visibleTagsCount, showOnlyCount } = useChipVisibility(chipListRef.current, chipListWrapperRef.current, inputWrapperRef.current, !selectedTags?.length);

  return (
    <>
      <Box
        sx={{
          display: 'inline-flex', height: '22px', minWidth: '42px', width: '100%',
        }}
        onClick={onClickOpenMenu}
        ref={inputWrapperRef}
      >
        <Box
          sx={{
            minWidth: 0, width: '100%', overflow: 'hidden', boxSizing: 'border-box',
          }}
          ref={chipListWrapperRef}
        >
          <Box
            ref={chipListRef}
            sx={{
              display: 'inline-flex', columnGap: '4px', rowGap: '16px', flexWrap: 'wrap', visibility: showOnlyCount ? 'hidden' : undefined,
            }}
          >
            {selectedTags?.map((tag) => (
              <Chip
                data-id={tag.id}
                key={tag.id}
                label={tag.name}
                size="small"
                sx={{ color: Color(tag.color).hex(), backgroundColor: Color(tag.color).alpha(0.18).hexa(), minWidth: '42px' }}
                onDelete={() => onRemoveTag(tag.id)}
                disabled={disabled || isLoadingCreateMutation}
                ref={(node) => chipRefCallback(tag.id, node)}
              />
            )) ?? <Box />}
          </Box>
        </Box>
        {!!selectedTags && (visibleTagsCount < selectedTags.length || showOnlyCount) && <Chip color="info" label={showOnlyCount ? selectedTags.length : `+${selectedTags.length - visibleTagsCount}`} size="small" sx={{ ml: 0.5 }} />}
        <IconButton
          disableRipple
          sx={{
            px: 0, mb: '3px', ml: 'auto', cursor: disabled ? 'default' : undefined, color: disabled ? theme.palette.text.disabled : undefined,
          }}
          onClick={onClickOpenMenu}
        >
          {isMenuOpen
            ? <ArrowDropUpIcon />
            : <ArrowDropDownIcon />}
        </IconButton>
      </Box>
      <Menu anchorEl={inputWrapperRef.current} open={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
        <Box sx={{ p: 1 }}>
          <TextField
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
            fullWidth
            label={isAllowedToCreate ? t('tag-select_search-text-field-label', 'Find/Create') : t('tag-select_search-text-field-label_find-only', 'Find')}
          />
        </Box>
        {inputStringIsValidNewName && isAllowedToCreate && (
        <Box sx={{
          p: 1, pl: 2, display: 'flex', alignItems: 'center',
        }}
        >
          <ColoredChip entity={newTag} sx={{ mr: 2 }} />
          <Button
            color="secondary"
            variant="contained"
            size="small"
            sx={{ ml: 'auto', minWidth: 'unset' }}
            ref={openColorPickerButtonRef}
            onClick={() => setIsColorPickerOpen(true)}
          >
            <FormatColorFillIcon />
          </Button>
          <Button
            disabled={!inputStringIsValidNewName}
            color="primary"
            variant="contained"
            size="small"
            sx={{ ml: 1, minWidth: 'unset' }}
            onClick={onClickCreateNew}
          >
            <AddIcon />
          </Button>
          {isColorPickerOpen && (
          <Popover
            open={isColorPickerOpen}
            anchorEl={openColorPickerButtonRef.current}
            onClose={() => setIsColorPickerOpen(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <SketchPicker
              color={newTagColor?.hex() ?? nextDefaultColor.hex()}
              onChange={(color) => setNewTagColor(Color(color.hex))}
            />
          </Popover>
          )}
        </Box>
        )}
        {searchResultTags.map((tag) => (
          <MenuItem key={tag.id} value={tag.id} onClick={() => onToggleTag(tag.id)} disabled={disabled || isLoadingCreateMutation || !tag.id.length}>
            {!!tag.id.length && <Checkbox checked={selectedTagIdsSet.has(tag.id)} disabled={disabled || isLoadingCreateMutation} sx={{ ml: 0 }} />}
            {!tag.id.length && <CircularProgress size={14} sx={{ ml: '6px', mr: '12px' }} />}
            <ColoredChip entity={tag} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
});

export default TagCreateSelectInputComponent;
