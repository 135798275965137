import DocumentListMode from 'documents-filter/types/DocumentListMode';
import DocumentSortDefinition from 'documents-filter/types/DocumentSortDefinition';
import DocumentVersionDto from 'documents/types/DocumentVersionDto';
import DocumentFilterPersistableState from 'documents/types/DocumentFilterPersistableState';
import DocumentScope from 'documents/types/DocumentScope';
import { QueryOptions } from 'odata-query';
import React, { Dispatch, SetStateAction } from 'react';

export interface DocumentFilterContextState extends DocumentFilterPersistableState {
  isFilterDrawerOpen: boolean,
  setIsFilterDrawerOpen: Dispatch<SetStateAction<boolean>>,
  searchString: string | undefined,
  setSearchString: Dispatch<SetStateAction<string | undefined>>,
  odataQuery: Partial<QueryOptions<DocumentVersionDto>> | undefined,
  sortDefinitions: DocumentSortDefinition[],
  setSortDefinitions: Dispatch<SetStateAction<DocumentSortDefinition[]>>,
  documentListMode: DocumentListMode,
  setDocumentListMode: Dispatch<SetStateAction<DocumentListMode>>,
  lastDocumentScopeBeforeFilterBecameActive: DocumentScope | undefined,
}

const defaultValue: DocumentFilterContextState = {
  isFilterDrawerOpen: false,
  setIsFilterDrawerOpen: () => { },
  stagedFilterState: null!,
  appliedFilterState: null!,
  isAppliedFilterStateEmpty: false,
  setStagedFilterState: () => { },
  loadedQueryFilter: undefined,
  loadedQueryFilterHasLocalEdits: false,
  saveLocalEditsToLoadedQueryFilter: () => { },
  saveQueryFilterAsNew: () => Promise.resolve(undefined),
  deleteLoadedQueryFilter: () => { },
  odataQuery: undefined,
  applyStagedFilterState: () => { },
  resetFilterState: () => { },
  searchString: undefined,
  setSearchString: () => {},
  sortDefinitions: [],
  setSortDefinitions: () => {},
  documentListMode: DocumentListMode.CurrentVersionOnly,
  setDocumentListMode: () => {},
  lastDocumentScopeBeforeFilterBecameActive: undefined,
};

const DocumentFilterContext = React.createContext(defaultValue);

export default DocumentFilterContext;
