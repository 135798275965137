import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, FormControl, InputLabel, Typography } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useRevisionsOdataQuery from 'models/hooks/useRevisionsOdataQuery';
import useModelSelectionContext from 'models/hooks/useModelSelectionContext';
import useProjectDisciplinesQuery from 'labels/hooks/useProjectDisciplinesQuery';
import { groupBy, orderBy } from 'lodash';
import useProjectBuildingsQuery from 'labels/hooks/useProjectBuildingsQuery';
import useProjectFloorsQuery from 'labels/hooks/useProjectFloorsQuery';
import DisciplineModelFilesGroupItem from 'models/components/DisciplineModelFilesGroupItem';
import { useTranslation } from 'react-i18next';
import ChipMultiselect from 'common/components/ChipMultiselect';
import useCurrentProjectId from 'projects/hooks/useCurrentProjectId';

interface ModelFilterState {
  disciplineIds: string[],
  buildingIds: string[]
  floorIds: string[],
}

const MODEL_SELECTION_FILTER_PANEL_STORAGE_KEY = 'models-selection-panel_filter-state';

const storeFilterState = (value: ModelFilterState, projectId: string) => {
  localStorage.setItem(`${MODEL_SELECTION_FILTER_PANEL_STORAGE_KEY}-${projectId}`, JSON.stringify(value));
};

const loadFilterState = (projectId: string) => {
  const value = localStorage.getItem(`${MODEL_SELECTION_FILTER_PANEL_STORAGE_KEY}-${projectId}`);
  return value ? JSON.parse(value) as ModelFilterState : undefined;
};

interface ModelsSelectionPanelProps extends ISxProps {
}

export default function ModelsSelectionPanel({
  sx,
}: ModelsSelectionPanelProps) {
  const { t } = useTranslation('models');
  const { data: revisions } = useRevisionsOdataQuery({});
  const modelFiles = useMemo(() => revisions?.map((r) => r.changedFile), [revisions]);
  const { data: disciplines } = useProjectDisciplinesQuery();
  const { data: buildings } = useProjectBuildingsQuery();
  const { data: floors } = useProjectFloorsQuery();
  const disciplinesById = useMemo(() => (disciplines ? new Map(disciplines.map((d) => [d.id, d])) : undefined), [disciplines]);
  const buildingsById = useMemo(() => (buildings ? new Map(buildings.map((b) => [b.id, b])) : undefined), [buildings]);
  const floorsById = useMemo(() => (floors ? new Map(floors.map((f) => [f.id, f])) : undefined), [floors]);
  const currentProjectId = useCurrentProjectId();

  const [filterState, setFilterState] = useState(() => (currentProjectId ? loadFilterState(currentProjectId) : undefined));
  useEffect(() => {
    if (!disciplines || !buildings || !floors) return;
    setFilterState((prev) => {
      if (prev) return prev;
      return {
        disciplineIds: disciplines.map((d) => d.id),
        buildingIds: buildings.map((b) => b.id),
        floorIds: floors.map((f) => f.id),
      };
    });
  }, [buildings, disciplines, floors]);
  const onChangeDisciplineFilter = useCallback((disciplineIds: string[]) => {
    if (!currentProjectId) throw new Error('dependency error');
    setFilterState((prev) => {
      if (!prev) return prev;
      const next = { ...prev, disciplineIds };
      storeFilterState(next, currentProjectId);
      return next;
    });
  }, [currentProjectId]);
  const onChangeBuildingFilter = useCallback((buildingIds: string[]) => {
    if (!currentProjectId) throw new Error('dependency error');
    setFilterState((prev) => {
      if (!prev) return prev;
      const next = { ...prev, buildingIds };
      storeFilterState(next, currentProjectId);
      return next;
    });
  }, [currentProjectId]);
  const onChangeFloorFilter = useCallback((floorIds: string[]) => {
    if (!currentProjectId) throw new Error('dependency error');
    setFilterState((prev) => {
      if (!prev) return prev;
      const next = { ...prev, floorIds };
      storeFilterState(next, currentProjectId);
      return next;
    });
  }, [currentProjectId]);

  const disciplineGroups = useMemo(() => {
    if (!modelFiles || !disciplinesById || !buildingsById || !floorsById || !filterState) return undefined;
    const { disciplineIds, buildingIds, floorIds } = filterState;
    const disciplineFilterSet = new Set(disciplineIds);
    const buildingFilterSet = new Set(buildingIds);
    const floorFilterSet = new Set(floorIds);
    return Object.entries(groupBy(modelFiles, (mf) => mf.discipline)).filter(([disciplineId]) => disciplineFilterSet.has(disciplineId)).map(([disciplineId, disciplineFiles]) => ({
      discipline: disciplinesById.get(disciplineId)!,
      buildingGroups: Object.entries(groupBy(disciplineFiles, (mf) => mf.building)).filter(([buildingId]) => buildingFilterSet.has(buildingId)).map(([buildingId, buildingFiles]) => ({
        building: buildingsById.get(buildingId)!,
        floorGroups: Object.entries(groupBy(buildingFiles, (mf) => mf.floor)).filter(([floorId]) => floorFilterSet.has(floorId)).map(([floorId, floorFiles]) => ({
          floor: floorsById.get(floorId)!,
          floorModelFiles: orderBy(floorFiles, (mf) => mf.version, 'desc'),
        })),
      })),
    }));
  }, [buildingsById, disciplinesById, filterState, floorsById, modelFiles]);

  const { loadStagedModelFileIds, stagedModelFileIdsChanged, isLoading } = useModelSelectionContext();
  const onClickLoadStagedModelFiles = useCallback(() => {
    loadStagedModelFileIds();
  }, [loadStagedModelFileIds]);
  return (
    <Box id="ModelsSelectionPanel" sx={{ ...sx, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
        <Typography variant="h5" sx={{ mr: 'auto' }}>{t('models-selection-panel_header', 'Model Filter')}</Typography>
        <FormControl>
          <InputLabel id="models-selection-panel_discipline-filter-select-label">{t('models-selection-panel_discipline-filter-select-label', 'Disciplines')}</InputLabel>
          <ChipMultiselect
            id="models-selection-panel_discipline-filter-select"
            label={t('models-selection-panel_discipline-filter-select-label', 'Disciplines')}
            value={filterState?.disciplineIds ?? []}
            onChange={onChangeDisciplineFilter}
            entities={disciplines}
            abbreviated
          />
        </FormControl>
        <FormControl>
          <InputLabel id="models-selection-panel_building-filter-select-label">{t('models-selection-panel_building-filter-select-label', 'Buildings')}</InputLabel>
          <ChipMultiselect
            id="models-selection-panel_building-filter-select"
            label={t('models-selection-panel_building-filter-select-label', 'Buildings')}
            value={filterState?.buildingIds ?? []}
            onChange={onChangeBuildingFilter}
            entities={buildings}
            abbreviated
          />
        </FormControl>
        <FormControl>
          <InputLabel id="models-selection-panel_floor-filter-select-label">{t('models-selection-panel_floor-filter-select-label', 'Floors')}</InputLabel>
          <ChipMultiselect
            id="models-selection-panel_floor-filter-select"
            label={t('models-selection-panel_floor-filter-select-label', 'Floors')}
            value={filterState?.floorIds ?? []}
            onChange={onChangeFloorFilter}
            entities={floors}
            abbreviated
          />
        </FormControl>
      </Box>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 2, p: 2, boxShadow: 'inset 0px 3px 6px -1px rgba(0,0,0,0.1)' }}>
        <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
          <Typography variant="h5" sx={{ mr: 'auto' }}>{t('models-selection-panel_header', 'Model List')}</Typography>
          <Button
            onClick={onClickLoadStagedModelFiles}
            variant="contained"
            color="primary"
            disabled={!stagedModelFileIdsChanged}
            sx={{ gap: 1 }}
          >
            {!!isLoading && <CircularProgress size={12} />}
            {t('models-selection-panel_load-staged-model-files-button-label', 'Update Viewer')}
          </Button>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          {!!disciplineGroups && disciplineGroups.map((disciplineGroup) => <DisciplineModelFilesGroupItem key={disciplineGroup.discipline.id} group={disciplineGroup} />)}
        </Box>
      </Box>
    </Box>
  );
}
