import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import CollaboratorGroupDto from 'collaborators-groups/types/CollaboratorGroupDto';
import CollaboratorGroupNameUpdateDto from 'collaborators-groups/types/CollaboratorGroupNameUpdateDto';
import { useCallback } from 'react';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';

interface CollaboratorGroupUpdateMutationContext {
  cachedEntities: CollaboratorGroupDto[] | undefined,
}

export default function useCollaboratorGroupNameUpdateMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.CollaboratorGroup);
  const mutationFn = useCallback(async (dto: CollaboratorGroupNameUpdateDto) => {
    const { data } = await axiosInstance.put<CollaboratorGroupDto>(`${ApiEndpoint.CollaboratorGroupName}`, dto);
    return data;
  }, [axiosInstance]);
  const onSettled = useCallback(() => {
    queryKeyBases.forEach((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
  }, [queryKeyBases, queryClient]);
  return useMutation<CollaboratorGroupDto, unknown, CollaboratorGroupNameUpdateDto, CollaboratorGroupUpdateMutationContext>({
    mutationFn,
    onSettled,
  });
}
