import { IFCModel } from "../../../IFCModel";
import { LiteEvent } from "../../../utils/LiteEvent";
import { VisoplanLoader } from "../../../VisoplanLoader";
import { IfcContext } from "../../context";

export class ColorSelection {
  public readonly onColorSelect = new LiteEvent<Array<string>>();
  private model: IFCModel | null = null;

  constructor(private context: IfcContext, private loader: VisoplanLoader) {}

  transparentComponent(isOn: boolean) {
    const model = this.loader.ifcManager.subsets.transparentElements(isOn);
    this.setModel(model);
  }

  setColorComponent(color: string) {
    const model = this.loader.ifcManager.subsets.setColorElements(color);
    this.setModel(model);
    this.onColorSelect.trigger(
      Array.from(this.loader.ifcManager.subsets.getColorIds())
    );
  }

  resetColorComponent() {
    this.loader.ifcManager.subsets.resetColorElements();
    this.onColorSelect.trigger([]);
  }

  isolateAndTransparentElements() {
    const model =
      this.loader.ifcManager.subsets.isolateAndTransparentElements();
    this.setModel(model);
  }

  reset() {
    this.model = null;
    this.onColorSelect.trigger([]);
  }

  setVisualModel(model: IFCModel) {
    this.setModel(model);
    this.onColorSelect.trigger(
      Array.from(this.loader.ifcManager.subsets.getColorIds())
    );
  }

  private setModel(model: IFCModel | null) {
    if (this.model == null && model != null) {
      this.model = model;
      this.context.getScene().add(this.model);
      if (this.model.solid) {
        this.context.items.pickableIfcModels.push(this.model.solid);
      }
    }
  }
}
