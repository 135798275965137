import React, { useCallback, useMemo, useState } from 'react';
import IChildren from 'common/types/IChildren';
import IssuesBoardDragAndDropContext, { IssuesBoardDragAndDropContextState } from 'issues/contexts/IssuesBoardDragAndDropContext';

export default function IssuesBoardDragAndDropContextProvider({
  children,
}: IChildren) {
  const [statusLabelIdByissueIdTempOverrideMap, setStatusLabelIdByissueIdTempOverrideMap] = useState(new Map<string, string>());
  const [draggedIssueId, setDraggedIssueId] = useState<string | undefined>(undefined);
  const setTemporaryStatusId = useCallback((issueId: string, labelId: string | undefined) => {
    setStatusLabelIdByissueIdTempOverrideMap((prev) => {
      if (prev.get(issueId) === labelId) return prev;
      const nextMap = new Map<string, string>(prev);
      if (labelId) {
        nextMap.set(issueId, labelId);
      } else {
        nextMap.delete(issueId);
      }
      return nextMap;
    });
  }, [setStatusLabelIdByissueIdTempOverrideMap]);
  const value = useMemo<IssuesBoardDragAndDropContextState>(() => ({
    statusLabelIdByissueIdTempOverrideMap,
    setTemporaryStatusId,
    draggedIssueId,
    setDraggedIssueId,
  }), [draggedIssueId, setTemporaryStatusId, statusLabelIdByissueIdTempOverrideMap]);
  return <IssuesBoardDragAndDropContext.Provider value={value}>{children}</IssuesBoardDragAndDropContext.Provider>;
}
