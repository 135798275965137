import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import CollaboratorDto from 'collaborators/types/CollaboratorDto';

export default function useUserByEmailQuery(email: string | undefined, options?: Omit<UseQueryOptions<CollaboratorDto | undefined, unknown, CollaboratorDto | undefined, QueryKey>, 'queryKey' | 'queryFn'> | undefined) {
  const axiosInstance = useAxiosInstance();
  return useQuery<CollaboratorDto | undefined, unknown>({
    queryKey: ['collaborator', 'by-email', email],
    queryFn: async () => {
      if (!email) return undefined;
      const { data } = await axiosInstance.get<CollaboratorDto>(`/user/${email}`);
      return data;
    },
    ...options,
    enabled: (options?.enabled ?? true) && !!email,
  });
}
