import { useCallback } from 'react';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import SignUpRequesData from 'users/types/SignUpRequesData';
import ErrorResponseData from 'api/types/ErrorResponseData';
import AuthUserTokenDto from 'users/types/AuthUserTokenDto';
import useVisoplanApiContext from 'api/hooks/useVisoplanApiContext';

export default function useRegistrationApi() {
  const axiosInstance = useAxiosInstance();
  const { signInUsingTokenBearer } = useVisoplanApiContext();

  const signUp = useCallback((data: SignUpRequesData) => axiosInstance.post<undefined | ErrorResponseData>('/signup', data), [axiosInstance]);

  const signUpActivate = useCallback(async (activationCode: string) => {
    const response = await axiosInstance.post<AuthUserTokenDto>(`/signup/activate/${activationCode}`, undefined);
    await signInUsingTokenBearer(response.data.bearer);
  }, [axiosInstance, signInUsingTokenBearer]);

  return { signUp, signUpActivate };
}
