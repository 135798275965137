import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import React from 'react';
import { Navigate } from 'react-router-dom';
import useVisibleSettingsTabs from 'settings/hooks/useVisibleSettingsTabs';

export default function SettingsHub() {
  const visibleTabs = useVisibleSettingsTabs();
  if (!visibleTabs) return <CenteredCircularProgress />;
  if (visibleTabs?.size) {
    return <Navigate replace to={Array.from(visibleTabs)[0]} />;
  }
  return <Navigate replace to=".." />;
}
