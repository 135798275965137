import React, { useCallback, useRef, useState } from 'react';
import { CustomCellRendererProps } from '@ag-grid-community/react';
import IssueDto from 'issues/types/IssueDto';
import { Box, Button, Popover, useTheme } from '@mui/material';
import Color from 'color';
import ModelsDialog from 'models/components/ModelsDialog';
import ViewpointDto from 'issues/types/ViewpointDto';
import useModelFileIdsLinkedToIssue from 'issues/hooks/useModelFileIdsLinkedToIssue';
import IssueLinkedViewpointsPanel from 'issues/components/IssueLinkedViewpointsPanel';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import { useTranslation } from 'react-i18next';
import ModelsInteractionContextProvider from 'models/contexts/ModelsInteractionContextProvider';

export default function ViewpointsCell({
  value,
  data: issue,
}: CustomCellRendererProps<IssueDto, IssueDto['viewpointIds']>) {
  const { t } = useTranslation('issues');
  const theme = useTheme();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [menuOpen, setPopoverOpen] = useState<boolean>(false);
  const onClickOpenPopover = useCallback(() => setPopoverOpen(true), []);
  const onClosePopover = useCallback(() => setPopoverOpen(false), []);
  const getModelFileIdsLinkedToIssue = useModelFileIdsLinkedToIssue();
  const [modelsDialogData, setModelsDialogData] = useState<{ viewpoint: ViewpointDto, modelFileIds: string[], issueId: string | undefined } | undefined>(undefined);
  const setModelsViewSelectedIssueId = useCallback((issueId: string | undefined) => setModelsDialogData((prev) => (prev ? { ...prev, issueId } : undefined)), []);
  const onCloseModelsDialog = useCallback(() => setModelsDialogData(undefined), []);
  const onClickViewpointItem = useCallback(async (viewpoint: ViewpointDto) => {
    if (!issue) return;
    const modelFileIds = await getModelFileIdsLinkedToIssue(issue.id);
    setModelsDialogData({
      viewpoint,
      modelFileIds,
      issueId: issue.id,
    });
  }, [getModelFileIdsLinkedToIssue, issue]);
  return (
    <>
      <Button
        id="ViewpointsCell"
        variant="contained"
        color="secondary"
        ref={buttonRef}
        onClick={onClickOpenPopover}
        disabled={!value?.length}
        sx={{
          backgroundColor: Color(theme.palette.info.main).lightness(92).hex(),
          fontWeight: 600,
          height: '24px',
          minWidth: 'unset',
          px: 1,
          py: 0,
        }}
      >
        {`${value?.length ?? ''}`}
      </Button>
      <Popover open={menuOpen} anchorEl={buttonRef.current} onClose={onClosePopover} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
        <Box sx={{ maxWidth: 255, maxHeight: 400, overflow: 'auto' }}>
          {!issue && <CenteredCircularProgress sx={{ m: 2 }} />}
          {!!issue && <IssueLinkedViewpointsPanel issueId={issue.id} onClickViewpointItem={onClickViewpointItem} />}
        </Box>
      </Popover>
      {!!modelsDialogData && (
        <ModelsInteractionContextProvider>
          <ModelsDialog
            title={t('issue-details-sidebar_models-dialog-title', 'Model')}
            initiallySelectedViewpoint={modelsDialogData.viewpoint}
            initiallySelectedModelFileIds={modelsDialogData.modelFileIds}
            onClose={onCloseModelsDialog}
            selectedIssueId={modelsDialogData.issueId}
            setSelectedIssueId={setModelsViewSelectedIssueId}
          />
        </ModelsInteractionContextProvider>
      )}
    </>
  );
}
