import { Components } from '@mui/material';
import { Theme } from '@mui/material/styles';

import VisoSwitch from 'theme/components/VisoSwitch';
import VisoButton from 'theme/components/VisoButton';
import VisoButtonBase from 'theme/components/VisoButtonBase';
import VisoLinearProgress from 'theme/components/VisoLinearProgress';
import VisoTableCell from 'theme/components/VisoTableCell';
import VisoTableRow from 'theme/components/VisoTableRow';
import VisoInputBase from 'theme/components/VisoInputBase';
import VisoInputLabel from 'theme/components/VisoInputLabel';
import VisoTypography from 'theme/components/VisoTypography';
import VisoMenuItem from 'theme/components/VisoMenuItem';
import VisoOutlinedInput from 'theme/components/VisoOutlinedInput';
import VisoFormControlLabel from 'theme/components/VisoFormControlLabel';
import VisoRadio from 'theme/components/VisoRadio';
import VisoCheckbox from 'theme/components/VisoCheckbox';
import VisoDialog from 'theme/components/VisoDialog';
import VisoDialogTitle from 'theme/components/VisoDialogTitle';
import VisoDialogContent from 'theme/components/VisoDialogContent';
import VisoDialogActions from 'theme/components/VisoDialogActions';
import VisoBackdrop from 'theme/components/VisoBackdrop';
import VisoLink from 'theme/components/VisoLink';
import VisoChip from 'theme/components/VisoChip';
import VisoAutocomplete from 'theme/components/VisoAutocomplete';
import VisoAlert from 'theme/components/VisoAlert';
import VisoAlertTitle from 'theme/components/VisoAlertTitle';
import VisoListSubheader from 'theme/components/VisoListSubheader';
import VisoTabs from 'theme/components/VisoTabs';
import VisoTab from 'theme/components/VisoTab';
import VisoTooltip from 'theme/components/VisoTooltip';
import VisoSelect from 'theme/components/VisoSelect';

const components: Components<Omit<Theme, 'components'>> = {
  MuiInputBase: VisoInputBase,
  MuiOutlinedInput: VisoOutlinedInput,
  MuiInputLabel: VisoInputLabel,
  MuiFormControlLabel: VisoFormControlLabel,
  MuiButton: VisoButton,
  MuiButtonBase: VisoButtonBase,
  MuiTypography: VisoTypography,
  MuiMenuItem: VisoMenuItem,
  MuiSwitch: VisoSwitch,
  MuiRadio: VisoRadio,
  MuiCheckbox: VisoCheckbox,
  MuiLinearProgress: VisoLinearProgress,
  MuiTableCell: VisoTableCell,
  MuiTableRow: VisoTableRow,
  MuiDialog: VisoDialog,
  MuiDialogTitle: VisoDialogTitle,
  MuiDialogContent: VisoDialogContent,
  MuiDialogActions: VisoDialogActions,
  MuiBackdrop: VisoBackdrop,
  MuiLink: VisoLink,
  MuiChip: VisoChip,
  MuiAutocomplete: VisoAutocomplete,
  MuiAlert: VisoAlert,
  MuiAlertTitle: VisoAlertTitle,
  MuiListSubheader: VisoListSubheader,
  MuiTabs: VisoTabs,
  MuiTab: VisoTab,
  MuiTooltip: VisoTooltip,
  MuiSelect: VisoSelect,
};

export default components;
