import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import DocumentCommentDto from 'documents/types/DocumentCommentDto';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';

interface Context {
  previouslyCachedData: DocumentCommentDto[] | undefined,
  queryKey: QueryKey,
}

export default function useDocumentCommentDeleteMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { getOdataQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentComment);
  return useMutation<boolean | undefined, unknown, DocumentCommentDto, Context>({
    mutationFn: async (entity: DocumentCommentDto) => {
      const { data: success } = await axiosInstance.delete<boolean | undefined>(`${ApiEndpoint.DocumentComment}/${entity.id}`);
      return success;
    },
    onMutate: async (entity) => {
      // optimistic update
      const queryKey = getOdataQueryKey({ filter: { documentVersionId: entity.documentVersionId } });
      await queryClient.cancelQueries({ queryKey });
      const previouslyCachedData = queryClient.getQueryData<DocumentCommentDto[] | undefined>(queryKey);
      queryClient.setQueryData<DocumentCommentDto[] | undefined>(queryKey, (prev) => (prev ? prev.map((c) => (c.id === entity.id ? { ...c, text: '', isRemoved: true } : c)) : prev));
      return { previouslyCachedData, queryKey }; // return previous cache value with the context so we can roll back on error
    },
    onError: (err, persistDto, context) => {
      // roll back
      if (!context) return;
      const { queryKey, previouslyCachedData } = context;
      queryClient.setQueryData(queryKey, previouslyCachedData);
    },
  });
}
