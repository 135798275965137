import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Icon from '@mdi/react';
import { mdiDrag } from '@mdi/js';
import { Box, Portal, useTheme } from '@mui/material';
import { useDrag } from 'react-dnd';
import { ICellRendererParams } from '@ag-grid-community/core';
import DocumentVersionDto from 'documents/types/DocumentVersionDto';
import useDocumentSelectionContext from 'documents/hooks/useDocumentSelectionContext';
import DocumentVersionDragAndDropPayload from 'documents-folders/types/DocumentVersionDragAndDropPayload';
import DocumentVersionsDragAndDropPayloadPreview from 'documents/components/DocumentVersionsDragAndDropPayloadPreview';

export default function DragHandleCell({
  data,
}: ICellRendererParams<DocumentVersionDto, string>) {
  const theme = useTheme();
  const { selectedDocumentVersionIdsSet } = useDocumentSelectionContext();
  const disabled = useMemo(() => !data || (selectedDocumentVersionIdsSet.size !== 0 && !selectedDocumentVersionIdsSet.has(data.id)), [data, selectedDocumentVersionIdsSet]);

  const [payload, setPayload] = useState<DocumentVersionDragAndDropPayload | undefined>(undefined);
  const [mousePos, setMousePos] = useState<{ x: number, y: number } | undefined>(undefined);

  const handleDragOver = useCallback((e: DragEvent) => {
    setMousePos({ x: e.clientX, y: e.clientY });
  }, []);

  useEffect(() => {
    if (payload) {
      document.body.addEventListener('dragover', handleDragOver);
    } else {
      document.body.removeEventListener('dragover', handleDragOver);
    }
    return () => {
      document.body.removeEventListener('dragover', handleDragOver);
    };
  }, [handleDragOver, payload]);

  const [, dragRef] = useDrag<DocumentVersionDragAndDropPayload>(() => ({
    type: 'DocumentVersionDragAndDropPayload',
    item: () => {
      if (!data) return null;
      return {
        documentVersionIds: selectedDocumentVersionIdsSet.size ? Array.from(selectedDocumentVersionIdsSet) : [data.id],
        dragSourceDocumentVersionId: data.id,
      };
    },
    collect: (monitor) => {
      if (!monitor.isDragging()) return;
      const item = monitor.getItem();
      if (item && item.dragSourceDocumentVersionId === data?.id) {
        setPayload(item);
      }
    },
    end: () => {
      setPayload(undefined);
    },
  }), [selectedDocumentVersionIdsSet, data]);

  return (
    <Box
      ref={dragRef}
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: disabled ? 'default' : 'grab',
        position: 'relative',
      }}
    >
      <Icon path={mdiDrag} size={1} color={disabled ? theme.palette.grey[300] : undefined} />
      {!!payload && !!mousePos && (
        <Portal>
          <DocumentVersionsDragAndDropPayloadPreview
            documentVersionsCount={payload.documentVersionIds.length}
            sx={{ transform: `translate(${mousePos.x}px, ${mousePos.y}px)` }}
          />
        </Portal>
      )}
    </Box>
  );
}
