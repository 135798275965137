import React, { useMemo } from 'react';
import ChipMultiselect from 'common/components/ChipMultiselect';
import { useTheme } from '@mui/material';
import Color from 'color';
import useCollaboratorsQuery from 'collaborators/hooks/useCollaboratorsQuery';
import { sortBy } from 'lodash';

interface CollaboratorSelectProps {
  label: string,
  value: string[],
  onChange: (values: string[]) => void,
  disabled?: boolean,
}

export default function CollaboratorSelect({
  label,
  value,
  onChange,
  disabled,
}: CollaboratorSelectProps) {
  const theme = useTheme();
  const color = Color(theme.palette.grey[700]);
  const { data: collaborators } = useCollaboratorsQuery();
  const entities = useMemo(() => {
    if (!collaborators) return undefined;
    return sortBy(collaborators, 'lastName').map((x) => ({ id: x.id, name: `${x.firstName} ${x.lastName}`, color: color.hex() }));
  }, [color, collaborators]);
  return (
    <ChipMultiselect
      id="CollaboratorSelect"
      label={label}
      value={value}
      onChange={onChange}
      disabled={disabled}
      entities={entities}
    />
  );
}
