import React from 'react';
import { useTranslation } from 'react-i18next';
import useOpenIssueStatus from 'issues/hooks/useOpenIssueStatus';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import IssueResponsibilityActionPanel from 'issues/components/IssueResponsibilityActionPanel';

interface RejectReviewPanelProps {
  issueId: string,
  onClose: () => void,
}

export default function IssueRejectReviewPanel({
  issueId,
  onClose,
}: RejectReviewPanelProps) {
  const { t } = useTranslation('issues');
  const openIssueStatus = useOpenIssueStatus();
  if (!openIssueStatus) return <CenteredCircularProgress />;
  return (
    <IssueResponsibilityActionPanel
      id="RejectReviewPanel"
      issueId={issueId}
      title={t('reject-review-panel_header', 'Review: Reopen')}
      onClose={onClose}
      confirmButtonLabel={t('reject-review-panel_confirm-button-label', 'Reject and re-open')}
      commentDefaultText={t('reject-review-panel_comment-default-text', 'Review rejected and re-opened.')}
      targetStatusId={openIssueStatus.id}
      commentMandatory
    />
  );
}
