import React, { useCallback, useMemo, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Alert, CircularProgress, useTheme, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MutateNamingSchemeSettingsPanel, { NamingSchemeSettingsFormState } from 'naming-schemes/components/MutateNamingSchemeSettingsPanel';
import CreateNamingSchemeDto from 'naming-schemes/types/CreateNamingSchemeDto';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useNamingSchemeCreateMutation from 'naming-schemes/hooks/useNamingSchemeCreateMutation';
import NamingSchemeGroupsPanel from 'naming-schemes/components/NamingSchemeGroupsPanel';
import NamingSchemeGroupItem from 'naming-schemes/types/NamingSchemeGroupItem';
import { mdiPlusThick, mdiSwapHorizontalHidden } from '@mdi/js';
import Icon from '@mdi/react';
import CreateNamingSchemeGroupDialog from 'naming-schemes/components/CreateNamingSchemeGroupDialog';
import NamingSchemeGroupsReorderDialog from 'naming-schemes/components/NamingSchemeGroupsReorderDialog';
import useNamingSchemeItemToDtoConversion from 'naming-schemes/hooks/useNamingSchemeItemToDtoConversion';

interface CreateNamingSchemeDialogProps {
  onClose: () => void,
}

export default function CreateNamingSchemeDialog({
  onClose,
}: CreateNamingSchemeDialogProps) {
  const { t } = useTranslation('naming-schemes');
  const theme = useTheme();
  const { mutateAsync, isPending } = useNamingSchemeCreateMutation();
  const getRequestErrorMessage = useRequestErrorMessage();

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);

  const [groupItems, setGroupItems] = useState<NamingSchemeGroupItem[]>([]);
  const [settings, setSettings] = useState<NamingSchemeSettingsFormState>({
    name: '',
    allowMetaMapping: true,
    applyFileTypes: [],
    useFullFileName: false,
  });

  const onChangeSettings = useCallback((value: NamingSchemeSettingsFormState) => {
    setSettings(value);
    setErrorMessage(undefined);
  }, []);

  const onChangeGroups = useCallback((value: NamingSchemeGroupItem[]) => {
    setGroupItems(value);
    setErrorMessage(undefined);
  }, []);

  const [createGroupDialogOpen, setCreateGroupDialogOpen] = useState<boolean>(false);
  const onClickAddGroup = useCallback(() => setCreateGroupDialogOpen(true), []);
  const onCloseAddGroupDialog = useCallback(async (groupItem: NamingSchemeGroupItem | undefined) => {
    if (!groupItems) return;
    if (groupItem) {
      const nextGroupItems = groupItems.concat(groupItem);
      if (nextGroupItems.length > 2) {
        nextGroupItems[nextGroupItems.length - 2].separator = nextGroupItems[nextGroupItems.length - 3].separator;
      }
      await onChangeGroups(nextGroupItems);
    }
    setCreateGroupDialogOpen(false);
  }, [groupItems, onChangeGroups]);
  const alreadyExistingGroupTypes = useMemo(() => groupItems.map((item) => item.type), [groupItems]);

  const confirmDisabled = useMemo(() => groupItems.length < 2 || !settings.name.trim().length, [groupItems.length, settings.name]);
  const convertGroupItemToDto = useNamingSchemeItemToDtoConversion();
  const onClickConfirm = useCallback(async () => {
    try {
      setErrorMessage(undefined);
      const createNamingSchemeDto: CreateNamingSchemeDto = {
        groups: groupItems.map(convertGroupItemToDto),
        ...settings,
        name: settings.name.trim(),
        applyFileTypes: settings.applyFileTypes.length ? settings.applyFileTypes : undefined,
      };
      await mutateAsync(createNamingSchemeDto);
      onClose();
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [convertGroupItemToDto, getRequestErrorMessage, groupItems, mutateAsync, onClose, settings]);

  const [reorderGroupsDialogOpen, setReorderGroupsDialogOpen] = useState<boolean>(false);
  const onClickReorderGroups = useCallback(() => setReorderGroupsDialogOpen(true), []);
  const onCloseReorderGroupsDialog = useCallback((reorderedGroups: NamingSchemeGroupItem[] | undefined) => {
    if (reorderedGroups) {
      onChangeGroups(reorderedGroups);
    }
    setReorderGroupsDialogOpen(false);
  }, [onChangeGroups]);

  return (
    <Dialog open id="CreateNamingSchemeDialog" PaperProps={{ sx: { minWidth: 1000, minHeight: 700, maxWidth: 'calc(100vw - 64px)', maxHight: 'calc(100vh - 64px)' } }}>
      <DialogTitle>{t('create-naming-scheme-dialog_title', 'Create Naming Scheme')}</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <MutateNamingSchemeSettingsPanel
          value={settings}
          onChange={onChangeSettings}
          sx={{ pt: 2 }}
        />
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button variant="contained" color="secondary" onClick={onClickAddGroup} sx={{ pl: 1, gap: 0.5 }}>
            <Icon path={mdiPlusThick} size={0.75} />
            {t('encoding-settings-tab-pane_add-group-button-label', 'Add Group')}
          </Button>
          <Button variant="outlined" onClick={onClickReorderGroups} sx={{ pl: 1, gap: 0.5 }}>
            <Icon path={mdiSwapHorizontalHidden} size={0.75} />
            {t('encoding-settings-tab-pane_reorder-groups-button-label', 'Reorder Groups')}
          </Button>
        </Box>
        {!!reorderGroupsDialogOpen && <NamingSchemeGroupsReorderDialog groupItems={groupItems} onClose={onCloseReorderGroupsDialog} />}
        {!!createGroupDialogOpen && <CreateNamingSchemeGroupDialog onClose={onCloseAddGroupDialog} alreadyExistingGroupTypes={alreadyExistingGroupTypes} />}
        <Box sx={{ backgroundColor: theme.palette.grey[300], borderRadius: '16px', boxShadow: 'inset 0 0 16px -8px rgba(0,0,0,0.3)', overflow: 'auto', minHeight: 150 }}>
          <NamingSchemeGroupsPanel groupItems={groupItems} onChange={onChangeGroups} />
        </Box>
        {!!errorMessage && <Alert severity="error" onClose={onCloseErrorMessage}>{errorMessage}</Alert>}
      </DialogContent>
      <DialogActions sx={{ gap: 2, justifyContent: 'space-between' }}>
        <Button variant="contained" color="secondary" onClick={onClose}>
          {t('create-naming-scheme-dialog_cancel-button-label', 'Cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={onClickConfirm} disabled={confirmDisabled || isPending}>
          {!!isPending && <CircularProgress size={12} sx={{ mr: 1 }} />}
          {t('create-naming-scheme-dialog_confirm-button-label', 'Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
