import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import QueryFilterKind from 'query-filters/types/QueryFilterKind';
import QueryFilterDto from 'query-filters/types/QueryFilterDto';
import QueryFilterEndpointMap from 'query-filters/types/QueryFilterEndpointMap';
import QueryFilterCreateDto from 'query-filters/types/QueryFilterCreateDto';

export default function useQueryFilterCreateMutation<TQueryFilterKind extends QueryFilterKind>(endpoint: QueryFilterEndpointMap[TQueryFilterKind]) {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases } = useDefaultEntityQueryKeys(endpoint);
  type TQueryFilterCreateDto = QueryFilterCreateDto<TQueryFilterKind>;
  type TQueryFilterDto = QueryFilterDto<TQueryFilterKind>;
  return useMutation<TQueryFilterDto | undefined, unknown, TQueryFilterCreateDto>({
    mutationFn: async (dto: TQueryFilterCreateDto) => {
      const { data: responseDto } = await axiosInstance.post<TQueryFilterDto>(endpoint, dto);
      return responseDto;
    },
    onSuccess: async () => {
      await Promise.all(queryKeyBases.map((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase })));
    },
  });
}
