import { useMutation } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import { useCallback } from 'react';
import AuthUserTokenDto from 'users/types/AuthUserTokenDto';

export default function useDeactivateTwoFactorMutation() {
  const axiosInstance = useAxiosInstance();
  const mutationFn = useCallback(async () => {
    await axiosInstance.delete<AuthUserTokenDto>(ApiEndpoint.Totp);
  }, [axiosInstance]);
  return useMutation<void, unknown, void>({
    mutationFn,
  });
}
