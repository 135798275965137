import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import RoleDto from 'collaborators/types/RoleDto';
import RoleUpdateNameDto from 'collaborators/types/RoleUpdateNameDto';

export default function useRoleUpdateNameMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.Role);
  const mutationFn = useCallback(async (dto: RoleUpdateNameDto) => {
    const { data } = await axiosInstance.patch<RoleDto>(`${ApiEndpoint.Role}/name`, dto);
    return data;
  }, [axiosInstance]);
  const onSuccess = useCallback(() => {
    queryKeyBases.forEach((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
  }, [queryClient, queryKeyBases]);
  return useMutation<RoleDto, unknown, RoleUpdateNameDto>({
    mutationFn,
    onSuccess,
  });
}
