import React, { useMemo, useState } from 'react';
import IChildren from 'common/types/IChildren';
import EmailsFilterContext, { EmailsFilterContextState } from 'emails/contexts/EmailsFilterContext';

export default function EmailsFilterContextProvider({
  children,
}: IChildren) {
  const [quickFilterString, setQuickFilterString] = useState<string>('');
  const [filteredEmailIds, setFilteredEmailIds] = useState<string[] | undefined>(undefined);
  const value = useMemo<EmailsFilterContextState>(() => ({
    quickFilterString,
    setQuickFilterString,
    filteredEmailIds,
    setFilteredEmailIds,
  }), [filteredEmailIds, quickFilterString]);
  return <EmailsFilterContext.Provider value={value}>{children}</EmailsFilterContext.Provider>;
}
