import React, {
  useCallback, useEffect, useState,
} from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import DocumentsDataGridRow from 'documents/types/DocumentDataGridRow';
import DocumentVersionDisciplinesUpdateDto from 'documents/types/DocumentVersionDisciplinesUpdateDto';
import useDocumentVersionDisciplinesUpdateMutation from 'documents/hooks/useDocumentVersionDisciplinesUpdateMutation';
import ChipMultiselect from 'common/components/ChipMultiselect';
import RoleAction from 'projects/types/RoleAction';
import useCurrentCollaboratorRole from 'collaborators/hooks/useCurrentCollaboratorRole';
import useCurrentCollaboratorFolderAccessLevel from 'documents-folders/hooks/useCurrentCollaboratorFolderAccessLevel';
import CollaboratorFolderAccessLevel from 'documents-folders/types/CollaboratorFolderAccessLevel';
import useProjectDisciplinesQuery from 'labels/hooks/useProjectDisciplinesQuery';

export default function DisciplineCell({
  data,
  value,
}: ICellRendererParams<DocumentsDataGridRow, string[] | undefined>) {
  const currentUserRole = useCurrentCollaboratorRole();
  const { data: disciplines } = useProjectDisciplinesQuery();
  const { mutateAsync, isPending } = useDocumentVersionDisciplinesUpdateMutation();
  const [displayValue, setDisplayValue] = useState<string[]>(value ?? []);
  const accessLevel = useCurrentCollaboratorFolderAccessLevel(data?.folderId);

  const onChange = useCallback(async (selectedDisciplineIds: string[]) => {
    if (!data) return;
    setDisplayValue(selectedDisciplineIds);
    const persistDto: DocumentVersionDisciplinesUpdateDto = {
      id: data.id,
      disciplines: selectedDisciplineIds,
    };
    await mutateAsync(persistDto);
  }, [data, mutateAsync]);

  useEffect(() => setDisplayValue(value ?? []), [value]);

  return (
    <ChipMultiselect
      entities={disciplines}
      value={displayValue}
      disabled={!disciplines?.length || isPending || data?.isArchived || !currentUserRole?.allowedActions?.has(RoleAction.Document_Editing) || accessLevel !== CollaboratorFolderAccessLevel.ReadWrite}
      onChange={onChange}
      variant="standard"
      sx={{ width: '100%' }}
    />
  );
}
