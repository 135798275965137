import type { Theme, ComponentsProps, ComponentsVariants } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

interface MuiAutocompleteComponent {
  defaultProps?: ComponentsProps['MuiAutocomplete'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiAutocomplete'];
  variants?: ComponentsVariants['MuiAutocomplete'];
}

const VisoAutocomplete: MuiAutocompleteComponent = {
  styleOverrides: {
    inputRoot: {
      padding: '1px 12px 0 4px',
    },
  },
};

export default VisoAutocomplete;
