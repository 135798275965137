import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControlLabel, Checkbox, Alert, CircularProgress, FormControl, Box, InputLabel, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useNamingSchemeSettingsQuery from 'naming-schemes/hooks/useNamingSchemeSettingsQuery';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import CreateProjectNamingSchemeSettingsDto from 'naming-schemes/types/CreateProjectNamingSchemeSettingsDto';
import useUpdateNamingSchemeSettingsMutation from 'naming-schemes/hooks/useUpdateNamingSchemeSettingsMutation';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import FolderSelect from 'documents-folders/components/FolderSelect';
import useDmsSettingsQuery from 'documents/hooks/useDmsSettingsQuery';

interface GeneralEncodingSettingsDialogProps {
  onClose: () => void,
}

export default function GeneralEncodingSettingsDialog({
  onClose,
}: GeneralEncodingSettingsDialogProps) {
  const { t } = useTranslation('naming-schemes');
  const [formState, setFormState] = useState<CreateProjectNamingSchemeSettingsDto | undefined>(undefined);
  const [excludeFolders, setExcludeFolders] = useState<boolean | undefined>(undefined);
  const excludedFoldersDisplayValue = useMemo(() => (!!formState && excludeFolders && formState.forceUploadRestriction ? formState.excludedFolders : []), [excludeFolders, formState]);
  const { data: namingSchemeSettings } = useNamingSchemeSettingsQuery();
  const { data: dmsSettings } = useDmsSettingsQuery();
  const { mutateAsync, isPending } = useUpdateNamingSchemeSettingsMutation();
  useEffect(() => {
    // init form state
    if (!namingSchemeSettings || !dmsSettings) return;
    setFormState((prev) => prev ?? {
      allowUploadFilesWhenSchemaByTypeNotFound: namingSchemeSettings.allowUploadFilesWhenSchemaByTypeNotFound,
      bypassRestrictionForPrivateDocs: namingSchemeSettings.bypassRestrictionForPrivateDocs,
      excludedFolders: namingSchemeSettings.excludedFolders,
      forceUploadRestriction: namingSchemeSettings.forceUploadRestriction,
      useLetterVersioning: dmsSettings.useLetterVersioning,
    });
    setExcludeFolders(!!namingSchemeSettings.excludedFolders.length);
  }, [dmsSettings, namingSchemeSettings]);
  const onChangeForceUploadRestriction = useCallback((event: ChangeEvent<HTMLInputElement>) => setFormState((prev) => (prev ? { ...prev, forceUploadRestriction: event.target.checked } : prev)), []);
  const onChangeBypassRestrictionForPrivateDocs = useCallback((event: ChangeEvent<HTMLInputElement>) => setFormState((prev) => (prev ? { ...prev, bypassRestrictionForPrivateDocs: event.target.checked } : prev)), []);
  const onChangeAllowUploadFilesWhenSchemaByTypeNotFound = useCallback((event: ChangeEvent<HTMLInputElement>) => setFormState((prev) => (prev ? { ...prev, allowUploadFilesWhenSchemaByTypeNotFound: event.target.checked } : prev)), []);
  const onChangeExcludeFolders = useCallback((event: ChangeEvent<HTMLInputElement>) => setExcludeFolders(event.target.checked), []);
  const onChangeExcludedFolders = useCallback((event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (!Array.isArray(value)) return;
    setFormState((prev) => (prev ? { ...prev, excludedFolders: value } : prev));
  }, []);
  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const onClickConfirm = useCallback(async () => {
    if (!formState) return;
    try {
      const adjustedFormState: CreateProjectNamingSchemeSettingsDto = {
        ...formState,
        excludedFolders: excludedFoldersDisplayValue, // we don't clear excludedFolders when unchecking the checkbox for convenience, so we have to clear the value before persisting the form state
        bypassRestrictionForPrivateDocs: formState.forceUploadRestriction ? formState.bypassRestrictionForPrivateDocs : false,
        allowUploadFilesWhenSchemaByTypeNotFound: formState.forceUploadRestriction ? formState.allowUploadFilesWhenSchemaByTypeNotFound : false,
      };
      await mutateAsync(adjustedFormState);
      onClose();
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [excludedFoldersDisplayValue, formState, getRequestErrorMessage, mutateAsync, onClose]);
  return (
    <Dialog open id="GeneralEncodingSettingsDialog">
      <DialogTitle>{t('general-encoding-settings-dialog_title', 'General Encoding Settings')}</DialogTitle>
      <DialogContent>
        {!formState && <CenteredCircularProgress />}
        {!!formState && (
          <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
              <FormControlLabel
                control={<Checkbox checked={formState.forceUploadRestriction} onChange={onChangeForceUploadRestriction} />}
                label={t('general-encoding-settings-dialog_force-upload-restriction-checkbox-label', 'Reject documents that do not match any configured encoding scheme')}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, pl: 4 }}>
                <FormControlLabel
                  control={<Checkbox checked={(formState.forceUploadRestriction && formState.bypassRestrictionForPrivateDocs) ?? false} disabled={!formState.forceUploadRestriction} onChange={onChangeBypassRestrictionForPrivateDocs} />}
                  label={`${t('general-encoding-settings-dialog_exclude-private-folders-checkbox-label', 'Do not reject documents in private folders')}`}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <FormControlLabel
                    control={<Checkbox checked={(formState.forceUploadRestriction && excludeFolders) ?? false} disabled={!formState.forceUploadRestriction || excludeFolders === undefined} onChange={onChangeExcludeFolders} />}
                    label={`${t('general-encoding-settings-dialog_excluded-folders-select-header', 'Do not reject documents in specific folders')}:`}
                  />
                  <FormControl size="small" sx={{ ml: 4 }} disabled={!excludeFolders || !formState.forceUploadRestriction}>
                    <InputLabel id="general-encoding-settings-dialog_item-folder" shrink={excludeFolders && formState.forceUploadRestriction}>
                      {t('general-encoding-settings-dialog_excluded-folders-select-label', 'Exempted Folders')}
                    </InputLabel>
                    <FolderSelect<string[]>
                      id="general-encoding-settings-dialog_item-folder"
                      labelId="general-encoding-settings-dialog_excluded-folders-select-label"
                      multiple
                      value={excludedFoldersDisplayValue}
                      onChange={onChangeExcludedFolders}
                      label={t('general-encoding-settings-dialog_excluded-folders-select-label', 'Exempted Folders')}
                      notched={excludeFolders && formState.forceUploadRestriction}
                    />
                  </FormControl>
                </Box>
                <FormControlLabel
                  control={<Checkbox checked={formState.forceUploadRestriction && formState.allowUploadFilesWhenSchemaByTypeNotFound} disabled={!formState.forceUploadRestriction} onChange={onChangeAllowUploadFilesWhenSchemaByTypeNotFound} />}
                  label={t('general-encoding-settings-dialog_allow-upload-files-when-schema-by-type-not-found-checkbox-label', 'Do not reject documents of an unassigned file type')}
                />
              </Box>
            </Box>
          </Box>
        )}
        {!!errorMessage && <Alert severity="error" onClose={onCloseErrorMessage}>{errorMessage}</Alert>}
      </DialogContent>
      <DialogActions sx={{ gap: 2, justifyContent: 'space-between' }}>
        <Button variant="contained" color="secondary" onClick={onClose}>
          {t('general-encoding-settings-dialog_cancel-button-label', 'Cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={onClickConfirm} disabled={isPending} sx={{ gap: 1 }}>
          {!!isPending && <CircularProgress size={12} />}
          {t('general-encoding-settings-dialog_confirm-button-label', 'Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
