import { Box, styled } from '@mui/material';

const HorizontalDivider = styled(Box)(({ theme }) => ({
  height: 1,
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: theme.palette.divider,
}));

export default HorizontalDivider;
