import { useCallback, useMemo } from 'react';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDocumentVersionsQuery from 'documents/hooks/useDocumentVersionsQuery';

export default function useDocumentVersionsDownload(documentVersionIds: string[]) {
  const axios = useAxiosInstance();
  const { data: documentVersions } = useDocumentVersionsQuery(documentVersionIds.length === 1 ? { filter: { id: { eq: documentVersionIds[0] } } } : undefined);
  const fileName = useMemo(() => (documentVersions?.length === 1 ? documentVersions[0].originalFileName : 'export.zip'), [documentVersions]);
  return useCallback(async () => {
    const { data } = await axios.post<Blob>('/documentVersion/download', documentVersionIds, { responseType: 'blob', timeout: Number.POSITIVE_INFINITY });
    if (!data) throw new Error('Download failed');
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(data);
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }, [axios, documentVersionIds, fileName]);
}
