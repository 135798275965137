import React, { useCallback, useMemo } from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import useViewer3dContext from 'models/hooks/useViewer3dContext';
import Viewer3dInteractionMode from 'models/types/Viewer3dInteractionMode';
import IconChip from 'labels/components/IconChip';
import { mdiDeleteOutline, mdiPlus, mdiRuler } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';

export default function MeasurementsDockviewPanel() {
  const { t } = useTranslation('models');
  const theme = useTheme();
  const { measurements, deleteMeasurement, setInteractionMode } = useViewer3dContext();
  const onClickCreateMeasurements = useCallback(() => {
    setInteractionMode(Viewer3dInteractionMode.CreateMeasurements);
  }, [setInteractionMode]);
  const measurementItems = useMemo(() => measurements.map((ifcDimensionLine) => ({
    ifcDimensionLine,
    onClickDelete: () => deleteMeasurement(ifcDimensionLine),
  })), [deleteMeasurement, measurements]);
  return (
    <Box id="MeasurementsDockviewPanel" sx={{ width: '100%', height: '100%', overflow: 'auto', p: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Button variant="contained" color="secondary" onClick={onClickCreateMeasurements} sx={{ pl: 1.5, gap: 0.5 }}>
        <Icon path={mdiPlus} size={0.75} />
        {t('measurements-dockview-panel_create-button-label', 'Add Measurement')}
      </Button>
      <Box sx={{ display: 'grid', gridTemplateColumns: 'auto auto auto 1fr', columnGap: 2, rowGap: 1, alignItems: 'center' }}>
        {measurementItems.map(({ ifcDimensionLine, onClickDelete }) => (
          <>
            <IconChip text={ifcDimensionLine.dimensionLabel} mdiIconPath={mdiRuler} color={theme.palette.primary.main} />
            <Typography>{t('measurements-dockview-panel_measurement-point-count-label', '{{count}} points', { count: ifcDimensionLine.pointCount })}</Typography>
            <Typography sx={{ justifySelf: 'end' }}>{`${new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(ifcDimensionLine.totalLength)} m`}</Typography>
            <Button onClick={onClickDelete} sx={{ minWidth: 'unset', px: 1, justifySelf: 'end' }} variant="outlined">
              <Icon path={mdiDeleteOutline} size={1} />
            </Button>
          </>
        ))}
      </Box>
    </Box>
  );
}
