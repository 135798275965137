import React, { useMemo } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import InboxEmailAttachmentDto from 'emails/types/InboxEmailAttachmentDto';
import { Box } from '@mui/material';
import InboxEmailDto from 'emails/types/InboxEmailDto';

export default function EmailAttachmentsCell({
  value,
}: ICellRendererParams<InboxEmailDto, InboxEmailAttachmentDto[] | undefined>) {
  const attachmentNames = useMemo(() => {
    if (!value) return undefined;
    return value.map((attachment) => attachment.name).join(', ');
  }, [value]);
  return (
    <Box id="EmailAttachmentsCell">
      {attachmentNames}
    </Box>
  );
}
