export class VisoShape {
  id = "";
  vertices: Float32Array;
  normals: Float32Array;
  triangles: Array<Uint32Array> = [];
  wireframe: Uint32Array;

  constructor() {
    this.vertices = new Float32Array();
    this.normals = new Float32Array();
    this.wireframe = new Uint32Array();
  }
}
