const AG_GRID_LOCALE_DE = {
    // Set Filter
    selectAll: '(Alle auswählen)',
    selectAllSearchResults: '(Alle Ergebnisse auswählen)',
    searchOoo: 'Suchen...',
    blanks: '(Leer)',
    noMatches: 'Keine Treffer',

    // Number Filter & Text Filter
    filterOoo: 'Filter...',
    equals: 'Gleich',
    notEqual: 'Ungleich',
    blank: 'Leer',
    notBlank: 'Nicht Leer',
    empty: 'Operation wählen',

    // Number Filter
    lessThan: 'Kleiner als',
    greaterThan: 'Größer als',
    lessThanOrEqual: 'Kleiner oder gleich',
    greaterThanOrEqual: 'Größer oder gleich',
    inRange: 'Im Intervall',
    inRangeStart: 'von',
    inRangeEnd: 'bis',

    // Text Filter
    contains: 'Enthält',
    notContains: 'Enthält nicht',
    startsWith: 'Beginnt mit',
    endsWith: 'Endet auf',

    // Date Filter
    dateFormatOoo: 'dd.mm.yyyy',

    // Filter Conditions
    andCondition: 'UND',
    orCondition: 'ODER',

    // Filter Buttons
    applyFilter: 'Anwenden',
    resetFilter: 'Zurücksetzen',
    clearFilter: 'Leeren',
    cancelFilter: 'Abbrechen',

    // Filter Titles
    textFilter: 'Textfilter',
    numberFilter: 'Zahlenfilter',
    dateFilter: 'Datumsfilter',
    setFilter: 'Mengenfilter',

    // Group Column Filter
    groupFilterSelect: 'Feld wählen:',

    // Side Bar
    columns: 'Spalten',
    filters: 'Filter',

    // columns tool panel
    pivotMode: 'Pivot Modus',
    groups: 'Zeilengruppen',
    rowGroupColumnsEmptyMessage: 'Hierhin ziehen, um Zeilengruppen zu setzen',
    values: 'Werte',
    valueColumnsEmptyMessage: 'Hierhin ziehen, um zu aggregieren',
    pivots: 'Spaltenbeschriftungen',
    pivotColumnsEmptyMessage: 'Hierhin ziehen, um Spaltenbeschriftungen zu setzen',

    // Header of the Default Group Column
    group: 'Gruppe',

    // Row Drag
    rowDragRow: 'Zeile',
    rowDragRows:'Zeilen',

    // Other
    loadingOoo: 'Lade...',
    loadingError: 'FEHLER',
    noRowsToShow: 'Keine Einträge zur Anzeige',
    enabled: 'Bereit',

    // Menu
    pinColumn: 'Spalte anpinnen',
    pinLeft: 'Links anpinnen',
    pinRight: 'Rechts anpinnen',
    noPin: 'Nicht anpinnen',
    valueAggregation: 'Wertaggregation',
    noAggregation: 'Keine',
    autosizeThiscolumn: 'Automatische Spaltenbreite (diese Spalte)',
    autosizeAllColumns: 'Automatische Spaltenbreite (alle Spalten)',
    groupBy: 'Gruppieren nach',
    ungroupBy: 'Gruppierung aufheben nach',
    addToValues: '${variable} zu Werten hinzufügen',
    removeFromValues: '${variable} aus Werten entfernen',
    addToLabels: '${variable} zu Beschriftungen hinzufügen',
    removeFromLabels: '{variable} aus Beschriftungen entfernen',
    resetColumns: 'Spalten zurücksetzen',
    expandAll: 'Alle Zeilengruppen ausklappen',
    collapseAll: 'Alle Zeilengruppen einklappen',
    copy: 'Kopieren',
    ctrlC: 'Strg+C',
    ctrlX: 'Strg+X',
    copyWithHeaders: 'Mit Kopfzeilen kopieren',
    copyWithGroupHeaders: 'Copy with Gruppenköpfen kopieren',
    cut: 'Ausschneiden',
    paste: 'Einfügen',
    ctrlV: 'Strg+V',
    export: 'Exportieren',
    csvExport: 'CSV Export',
    excelExport: 'Excel Export',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'Summe',
    first: 'Erste',
    last: 'Letzte',
    min: 'Min',
    max: 'Max',
    none: 'Keine',
    count: 'Anzahl',
    avg: 'Durchschnitt',
    filteredRows: 'Gefiltert',
    selectedRows: 'Ausgewählt',
    totalRows: 'Zeilen (gesamt)',
    totalAndFilteredRows: 'Zeilen',
    more: 'Mehr',
    to: 'bis',
    of: 'von',
    page: 'Seite',
    pageLastRowUnknown: '?',
    nextPage: 'Nächste Seite',
    lastPage: 'Letzte Seite',
    firstPage: 'Erste Seite',
    previousPage: 'Vorherige Seite',

    // Pivoting
    pivotColumnGroupTotals: 'Gesamt',

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
    pivotChart: 'Pivot Chart',
    chartRange: 'Chart Range',

    columnChart: 'Column',
    groupedColumn: 'Grouped',
    stackedColumn: 'Stacked',
    normalizedColumn: '100% Stacked',

    barChart: 'Bar',
    groupedBar: 'Grouped',
    stackedBar: 'Stacked',
    normalizedBar: '100% Stacked',

    pieChart: 'Pie',
    pie: 'Pie',
    doughnut: 'Doughnut',

    line: 'Line',

    xyChart: 'X Y (Scatter)',
    scatter: 'Scatter',
    bubble: 'Bubble',

    areaChart: 'Area',
    area: 'Area',
    stackedArea: 'Stacked',
    normalizedArea: '100% Stacked',

    histogramChart: 'Histogram',
    histogramFrequency: "Frequency",

    combinationChart: 'Combination',
    columnLineCombo: 'Column & Line',
    AreaColumnCombo: 'Area & Column',

    // Charts
    pivotChartTitle: 'Pivot Chart',
    rangeChartTitle: 'Range Chart',
    settings: 'Settings',
    data: 'Data',
    format: 'Format',
    categories: 'Categories',
    defaultCategory: '(None)',
    series: 'Series',
    xyValues: 'X Y Values',
    paired: 'Paired Mode',
    axis: 'Axis',
    navigator: 'Navigator',
    color: 'Color',
    thickness: 'Thickness',
    xType: 'X Type',
    automatic: 'Automatic',
    category: 'Category',
    number: 'Number',
    time: 'Time',
    autoRotate: 'Auto Rotate',
    xRotation: 'X Rotation',
    yRotation: 'Y Rotation',
    ticks: 'Ticks',
    width: 'Width',
    height: 'Height',
    length: 'Length',
    padding: 'Padding',
    spacing: 'Spacing',
    chart: 'Chart',
    title: 'Title',
    titlePlaceholder: 'Chart title - double click to edit',
    background: 'Background',
    font: 'Font',
    top: 'Top',
    right: 'Right',
    bottom: 'Bottom',
    left: 'Left',
    labels: 'Labels',
    size: 'Size',
    minSize: 'Minimum Size',
    maxSize: 'Maximum Size',
    legend: 'Legend',
    position: 'Position',
    markerSize: 'Marker Size',
    markerStroke: 'Marker Stroke',
    markerPadding: 'Marker Padding',
    itemSpacing: 'Item Spacing',
    itemPaddingX: 'Item Padding X',
    itemPaddingY: 'Item Padding Y',
    layoutHorizontalSpacing: 'Horizontal Spacing',
    layoutVerticalSpacing: 'Vertical Spacing',
    strokeWidth: 'Stroke Width',
    lineDash: 'Line Dash',
    offset: 'Offset',
    offsets: 'Offsets',
    tooltips: 'Tooltips',
    callout: 'Callout',
    markers: 'Markers',
    shadow: 'Shadow',
    blur: 'Blur',
    xOffset: 'X Offset',
    yOffset: 'Y Offset',
    lineWidth: 'Line Width',
    normal: 'Normal',
    bold: 'Bold',
    italic: 'Italic',
    boldItalic: 'Bold Italic',
    predefined: 'Predefined',
    fillOpacity: 'Fill Opacity',
    strokeOpacity: 'Line Opacity',
    histogramBinCount: 'Bin count',
    columnGroup: 'Column',
    barGroup: 'Bar',
    pieGroup: 'Pie',
    lineGroup: 'Line',
    scatterGroup: 'X Y (Scatter)',
    areaGroup: 'Area',
    histogramGroup: 'Histogram',
    combinationGroup: 'Combination',
    groupedColumnTooltip: 'Grouped',
    stackedColumnTooltip: 'Stacked',
    normalizedColumnTooltip: '100% Stacked',
    groupedBarTooltip: 'Grouped',
    stackedBarTooltip: 'Stacked',
    normalizedBarTooltip: '100% Stacked',
    pieTooltip: 'Pie',
    doughnutTooltip: 'Doughnut',
    lineTooltip: 'Line',
    groupedAreaTooltip: 'Area',
    stackedAreaTooltip: 'Stacked',
    normalizedAreaTooltip: '100% Stacked',
    scatterTooltip: 'Scatter',
    bubbleTooltip: 'Bubble',
    histogramTooltip: 'Histogram',
    columnLineComboTooltip: 'Column & Line',
    areaColumnComboTooltip: 'Area & Column',
    customComboTooltip: 'Custom Combination',
    noDataToChart: 'No data available to be charted.',
    pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
    chartSettingsToolbarTooltip: 'Menu',
    chartLinkToolbarTooltip: 'Linked to Grid',
    chartUnlinkToolbarTooltip: 'Unlinked from Grid',
    chartDownloadToolbarTooltip: 'Download Chart',
    seriesChartType: 'Series Chart Type',
    seriesType: 'Series Type',
    secondaryAxis: 'Secondary Axis',

    // ARIA
    ariaChecked: 'checked',
    ariaColumn: 'Column',
    ariaColumnGroup: 'Column Group',
    ariaColumnList: 'Column List',
    ariaColumnSelectAll: 'Toggle Select All Columns',
    ariaDateFilterInput: 'Date Filter Input',
    ariaDefaultListName: 'List',
    ariaFilterColumnsInput: 'Filter Columns Input',
    ariaFilterFromValue: 'Filter from value',
    ariaFilterInput: 'Filter Input',
    ariaFilterList: 'Filter List',
    ariaFilterToValue: 'Filter to value',
    ariaFilterValue: 'Filter Value',
    ariaFilterMenuOpen: 'Open Filter Menu',
    ariaFilteringOperator: 'Filtering Operator',
    ariaHidden: 'hidden',
    ariaIndeterminate:'indeterminate',
    ariaInputEditor: 'Input Editor',
    ariaMenuColumn: 'Press CTRL ENTER to open column menu.',
    ariaRowDeselect: 'Press SPACE to deselect this row',
    ariaRowSelectAll: 'Press Space to toggle all rows selection',
    ariaRowToggleSelection: 'Press Space to toggle row selection',
    ariaRowSelect: 'Press SPACE to select this row',
    ariaSearch: 'Search',
    ariaSortableColumn: 'Press ENTER to sort',
    ariaToggleVisibility: 'Press SPACE to toggle visibility',
    ariaToggleCellValue: 'Press SPACE to toggle cell value',
    ariaUnchecked: 'unchecked',
    ariaVisible: 'visible',
    ariaSearchFilterValues: 'Search filter values',

    // ARIA Labels for Drop Zones

    ariaRowGroupDropZonePanelLabel: 'Row Groups',
    ariaValuesDropZonePanelLabel: 'Values',
    ariaPivotDropZonePanelLabel: 'Column Labels',
    ariaDropZoneColumnComponentDescription: 'Press DELETE to remove',
    ariaDropZoneColumnValueItemDescription: 'Press ENTER to change the aggregation type',
    ariaDropZoneColumnGroupItemDescription: 'Press ENTER to sort',
    // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeperator}{column name}
    ariaDropZoneColumnComponentAggFuncSeperator: ' of ',
    ariaDropZoneColumnComponentSortAscending: 'ascending',
    ariaDropZoneColumnComponentSortDescending: 'descending',

    // ARIA Labels for Dialogs
    ariaLabelColumnMenu: 'Column Menu',
    ariaLabelCellEditor: 'Cell Editor',
    ariaLabelDialog: 'Dialog',
    ariaLabelSelectField: 'Select Field',
    ariaLabelTooltip: 'Tooltip',
    ariaLabelContextMenu: 'Context Menu',
    ariaLabelSubMenu: 'SubMenu',
    ariaLabelAggregationFunction: 'Aggregation Function',

    // Number Format (Status Bar, Pagination Panel)
    thousandSeparator: ',',
    decimalSeparator: '.',

    // Data types
    true: 'True',
    false: 'False',
    invalidDate: 'Ungültiges Datum',
    invalidNumber: 'Ungültige Zahl',
    january: 'Januar',
    february: 'Februar',
    march: 'März',
    april: 'April',
    may: 'Mai',
    june: 'Juni',
    july: 'Juli',
    august: 'August',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'Dezember',
}

export default AG_GRID_LOCALE_DE;